import { Alert, Box, BoxProps, Button, Card, CardProps, Divider, Grid, Link, Typography } from "@mui/material";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { getProjectSubRouteTemplate, INTEGRATIONS_SUBROUTE, PROJECT_SUBROUTE } from "../app/AppRoutes";
import Email from "../../assets/integration_logos/email_icon.png";
import Placeholder from "../../assets/integration-placeholder.png";
import React, { ComponentType, ElementType } from "react";
import { observer } from "mobx-react-lite";
import { IntegrationConfigInfo, IntegrationModule } from "../../_proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { ActionConfig, ActionMenuButton } from "../../common/actions/CommonActions";
import DGS from "../../assets/integration_logos/dgs.png";
import Azure from "../../assets/integration_logos/azure.png";
import AWS from "../../assets/integration_logos/aws.png";
import Pure from "../../assets/integration_logos/pure.png";
import NetApp from "../../assets/integration_logos/netapp.png";
import DigitalOcean from "../../assets/integration_logos/digitalocean.png";
import GCP from "../../assets/integration_logos/gcp.png";
import Oracle from "../../assets/integration_logos/oracle.png";
import DellEmc from "../../assets/integration_logos/dell_emc.png";
import VMware from "../../assets/integration_logos/vmware.png";
import AwsFsxNetapp from "../../assets/integration_logos/aws_fsx_netapp.png";
import Silk from "../../assets/integration_logos/silk.png";
import { MdArrowBack } from "react-icons/md";
import { useAppServices } from "../app/services";
import { KnownArticle } from "../help/HelpCommon";
import { OperatorView } from "../auth/AuthenticatedViews";

export enum INTEGRATIONS_TYPE_SUBROUTE {
    AWS = "aws",
    AZURE = "azure",
    DGS = "dgs",
    PURE_CBS = "pure-cbs",
    EMAIL = "email",
    NETAPP = "netapp",
    GOOGLE = "google-cloud-platform",
    DIGITAL_OCEAN = "digital-ocean",
    ORACLE = "oracle",
    PURE_FLASH = "pure-flash-array",
    POWERSTORE = "dell-emc-powerstore",
    FC_POWERSTORE = "dell-emc-fc-powerstore",
    POWERMAX = "dell-emc-powermax",
    AWS_FSX_NETAPP = "aws-fsx-netapp",
    SILK = "silk",
}

const smallIconStyles = {
    width: 30,
    height: 30,
};

const normalIconStyles = {
    width: 100,
    height: 100,
};

interface IntegrationIconProps {
    size?: "small" | "large";
}

export const EmailIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;
    return <img src={Email} alt={"Email Integration"} {...attr} />;
};

export const PlaceholderIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;
    return <img src={Placeholder} alt={"Integration"} {...attr} />;
};

export const AzureIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;
    return <img src={Azure} alt={"Microsoft Azure Integration"} {...attr} />;
};

export const DgsIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;
    return <img src={DGS} alt={"Cirrus Data Cloud Integration"} {...attr} />;
};

export const AwsIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;

    return <img alt={"AWS Integration"} src={AWS} {...attr} />;
};

export const PureIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;

    return <img alt={"Pure Storage Integration"} src={Pure} {...attr} />;
};

export const NetAppIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;

    return <img alt={"NetApp Integration"} src={NetApp} {...attr} />;
};

export const GCPIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;

    return <img alt={"Google Cloud Platform Integration"} src={GCP} {...attr} />;
};

export const DigitalOceanIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;

    return <img alt={"DigitalOcean Integration"} src={DigitalOcean} {...attr} />;
};

export const OracleIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    return <img alt={"DigitalOcean Integration"} src={Oracle} height={"100"} />;
};

export const DellEmcIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    return <img alt={"Dell EMC Integration"} src={DellEmc} height={"100"} />;
};

export const VmwareIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    return <img alt={"VMware Integration"} src={VMware} height={"100"} />;
};

export const AwsFsxNetappIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    return <img alt={"Amazon FSx for NetApp Integration"} src={AwsFsxNetapp} height={"100"} />;
};

export const SilkIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    return <img alt={"Silk Integration"} src={Silk} height={"100"} />;
};

type ModuleIconProps<P = {}> = {
    as?: ComponentType<P> | ElementType;
} & P;

export const ModuleIcon = <P,>({ as: Component = "div", ...props }: ModuleIconProps<P>) => {
    return <Component {...props} />;
};

export enum IntegrationCategory {
    ALERT,
    PUBLIC_CLOUD,
    ISCSI_STORAGE_ARRAY,
    FC_STORAGE_ARRAY,
    COMPUTE_INTEGRATION,
}

export const getIntegrationCategoryDisplayName = (i: IntegrationCategory) => {
    if (i === IntegrationCategory.ALERT) {
        return "Alert";
    } else if (i === IntegrationCategory.ISCSI_STORAGE_ARRAY) {
        return "Cirrus Migrate Cloud Auto Allocation – iSCSI Storage Array";
    } else if (i === IntegrationCategory.PUBLIC_CLOUD) {
        return "Cirrus Migrate Cloud Auto Allocation – Public Cloud";
    } else if (i === IntegrationCategory.FC_STORAGE_ARRAY) {
        return "Cirrus Migrate Cloud Auto Allocation – FC Storage Array";
    } else if (i === IntegrationCategory.COMPUTE_INTEGRATION) {
        return "Compute Integration";
    }
};

export enum IntegrationCapabilities {
    AUTO_ALLOCATION,
    TAKE_SNAPSHOT,
    LIST_SNAPSHOTS,
    CLONE_SNAPSHOT,
    DELETE_SNAPSHOT,
}

export interface IntegrationModuleDef {
    module?: IntegrationModule;
    label: string;
    route?: INTEGRATIONS_TYPE_SUBROUTE;
    category: IntegrationCategory;
    icon: ComponentType<any> | ElementType;
    description: string;
    defaultEnabled?: boolean;
    disabled?: boolean;
    externalLink?: string;
    mainActionButtonLabel?: string;
    capabilities?: IntegrationCapabilities[];
}

export const getModuleDefByModule = (module: IntegrationModule) => {
    const defs = getIntegrationModuleDefs();
    return defs.find((d) => d.module === module);
};

export const getIntegrationModuleDefs = (): IntegrationModuleDef[] => [
    {
        module: IntegrationModule.AWS,
        label: "AWS",
        route: INTEGRATIONS_TYPE_SUBROUTE.AWS,
        category: IntegrationCategory.PUBLIC_CLOUD,
        icon: AwsIntegrationIcon,
        description: "Cloud Computing Platform by Amazon",
        capabilities: [IntegrationCapabilities.AUTO_ALLOCATION, IntegrationCapabilities.TAKE_SNAPSHOT],
    },
    {
        module: IntegrationModule.AZURE,
        label: "Azure Managed Disks",
        route: INTEGRATIONS_TYPE_SUBROUTE.AZURE,
        category: IntegrationCategory.PUBLIC_CLOUD,
        icon: AzureIntegrationIcon,
        description: "Public Cloud Computing Platform by Microsoft",
        capabilities: [
            IntegrationCapabilities.AUTO_ALLOCATION,
            IntegrationCapabilities.TAKE_SNAPSHOT,
            IntegrationCapabilities.LIST_SNAPSHOTS,
            IntegrationCapabilities.CLONE_SNAPSHOT,
            IntegrationCapabilities.DELETE_SNAPSHOT,
        ],
    },
    {
        module: IntegrationModule.AZURE,
        label: "Azure Elastic SAN (Preview)",
        route: INTEGRATIONS_TYPE_SUBROUTE.AZURE,
        category: IntegrationCategory.PUBLIC_CLOUD,
        icon: AzureIntegrationIcon,
        description: "Public Cloud Computing Platform by Microsoft",
        capabilities: [
            IntegrationCapabilities.AUTO_ALLOCATION,
            IntegrationCapabilities.TAKE_SNAPSHOT,
            IntegrationCapabilities.LIST_SNAPSHOTS,
            IntegrationCapabilities.CLONE_SNAPSHOT,
            IntegrationCapabilities.DELETE_SNAPSHOT,
        ],
        disabled: true,
    },
    {
        module: IntegrationModule.DGS,
        label: "Cirrus Protect Storage Server",
        route: INTEGRATIONS_TYPE_SUBROUTE.DGS,
        category: IntegrationCategory.ISCSI_STORAGE_ARRAY,
        icon: DgsIntegrationIcon,
        description: "Continuous Data Protection and Instant Snapshot/Cloning for all Block Storage from Cirrus Data Solutions Inc.  ",
        capabilities: [IntegrationCapabilities.AUTO_ALLOCATION],
    },
    {
        module: IntegrationModule.PURE,
        label: "Pure FlashArray (iSCSI) / Cloud Block Store",
        route: INTEGRATIONS_TYPE_SUBROUTE.PURE_CBS,
        category: IntegrationCategory.ISCSI_STORAGE_ARRAY,
        icon: PureIntegrationIcon,
        description: "Pure Storage",
        capabilities: [IntegrationCapabilities.AUTO_ALLOCATION, IntegrationCapabilities.TAKE_SNAPSHOT],
    },
    {
        module: IntegrationModule.NETAPP,
        label: "Cloud Volumes ONTAP",
        route: INTEGRATIONS_TYPE_SUBROUTE.NETAPP,
        category: IntegrationCategory.ISCSI_STORAGE_ARRAY,
        icon: NetAppIntegrationIcon,
        description: "NetApp",
        capabilities: [
            IntegrationCapabilities.AUTO_ALLOCATION,
            IntegrationCapabilities.TAKE_SNAPSHOT,
            IntegrationCapabilities.LIST_SNAPSHOTS,
            IntegrationCapabilities.CLONE_SNAPSHOT,
            IntegrationCapabilities.DELETE_SNAPSHOT,
        ],
    },
    {
        module: IntegrationModule.GCP,
        label: "Google Cloud Platform",
        route: INTEGRATIONS_TYPE_SUBROUTE.GOOGLE,
        category: IntegrationCategory.PUBLIC_CLOUD,
        icon: GCPIntegrationIcon,
        description: "Google",
        capabilities: [
            IntegrationCapabilities.AUTO_ALLOCATION,
            IntegrationCapabilities.TAKE_SNAPSHOT,
            IntegrationCapabilities.LIST_SNAPSHOTS,
            IntegrationCapabilities.CLONE_SNAPSHOT,
            IntegrationCapabilities.DELETE_SNAPSHOT,
        ],
    },
    {
        module: IntegrationModule.ORACLE,
        label: "Oracle Cloud Infrastructure",
        route: INTEGRATIONS_TYPE_SUBROUTE.ORACLE,
        category: IntegrationCategory.PUBLIC_CLOUD,
        icon: OracleIntegrationIcon,
        description: "Oracle",
        capabilities: [IntegrationCapabilities.AUTO_ALLOCATION],
    },
    {
        label: "DigitalOcean",
        route: INTEGRATIONS_TYPE_SUBROUTE.DIGITAL_OCEAN,
        category: IntegrationCategory.PUBLIC_CLOUD,
        icon: DigitalOceanIntegrationIcon,
        description: "Volumes Block Storage By DigitalOcean",
        capabilities: [
            IntegrationCapabilities.AUTO_ALLOCATION,
            IntegrationCapabilities.TAKE_SNAPSHOT,
            IntegrationCapabilities.LIST_SNAPSHOTS,
            IntegrationCapabilities.CLONE_SNAPSHOT,
            IntegrationCapabilities.DELETE_SNAPSHOT,
        ],
        module: IntegrationModule.DIGITALOCEAN,
    },
    {
        label: "Email",
        route: INTEGRATIONS_TYPE_SUBROUTE.EMAIL,
        category: IntegrationCategory.ALERT,
        icon: EmailIcon,
        description: "Email notifications for major project events.",
        defaultEnabled: true,
    },
    {
        module: IntegrationModule.FC_PURE,
        label: "Pure FlashArray (FC)",
        route: INTEGRATIONS_TYPE_SUBROUTE.PURE_FLASH,
        category: IntegrationCategory.FC_STORAGE_ARRAY,
        icon: PureIntegrationIcon,
        description: "Pure Storage",
        capabilities: [IntegrationCapabilities.AUTO_ALLOCATION, IntegrationCapabilities.TAKE_SNAPSHOT],
    },
    {
        module: IntegrationModule.POWERSTORE,
        label: "Dell EMC PowerStore (iSCSI)",
        route: INTEGRATIONS_TYPE_SUBROUTE.POWERSTORE,
        category: IntegrationCategory.ISCSI_STORAGE_ARRAY,
        icon: DellEmcIntegrationIcon,
        description: "Dell Technologies",
        capabilities: [IntegrationCapabilities.AUTO_ALLOCATION],
    },
    {
        module: IntegrationModule.FC_POWERSTORE,
        label: "Dell EMC PowerStore (FC)",
        route: INTEGRATIONS_TYPE_SUBROUTE.FC_POWERSTORE,
        category: IntegrationCategory.FC_STORAGE_ARRAY,
        icon: DellEmcIntegrationIcon,
        description: "Dell Technologies",
        capabilities: [IntegrationCapabilities.AUTO_ALLOCATION],
    },
    {
        module: IntegrationModule.FC_POWERMAX,
        label: "Dell EMC PowerMax (FC)",
        route: INTEGRATIONS_TYPE_SUBROUTE.POWERMAX,
        category: IntegrationCategory.FC_STORAGE_ARRAY,
        icon: DellEmcIntegrationIcon,
        description: "Dell Technologies",
        capabilities: [IntegrationCapabilities.AUTO_ALLOCATION],
    },
    {
        module: IntegrationModule.AWS_FSX_NETAPP,
        label: "Amazon FSx for NetApp ONTAP",
        category: IntegrationCategory.ISCSI_STORAGE_ARRAY,
        icon: AwsFsxNetappIntegrationIcon,
        description: "Fully managed shared storage built on NetApp’s popular ONTAP file system",
        route: INTEGRATIONS_TYPE_SUBROUTE.AWS_FSX_NETAPP,
        capabilities: [
            IntegrationCapabilities.AUTO_ALLOCATION,
            IntegrationCapabilities.TAKE_SNAPSHOT,
            IntegrationCapabilities.LIST_SNAPSHOTS,
            IntegrationCapabilities.CLONE_SNAPSHOT,
            IntegrationCapabilities.DELETE_SNAPSHOT,
        ],
    },
    {
        label: "VMware vCenter",
        category: IntegrationCategory.COMPUTE_INTEGRATION,
        icon: VmwareIntegrationIcon,
        description: "VMware, Inc.",
        externalLink: KnownArticle.CMC_HELPER_VMWARE,
        mainActionButtonLabel: "Deploy CMC Helper",
    },
    {
        module: IntegrationModule.SILKSDP,
        label: "Silk Data Pod",
        category: IntegrationCategory.ISCSI_STORAGE_ARRAY,
        icon: SilkIntegrationIcon,
        description: "Silk Cloud Data Platform",
        route: INTEGRATIONS_TYPE_SUBROUTE.SILK,
        capabilities: [IntegrationCapabilities.AUTO_ALLOCATION],
    },
];

// ======================
// IntegrationCard
// ======================

interface IntegrationCardProps {
    module: IntegrationModuleDef;
    instance?: IntegrationConfigInfo;
    mainActionButton?: React.ReactNode;
    cardProps?: Partial<CardProps>;
    cardActionsGetter?: (i: IntegrationConfigInfo) => ActionConfig[];
    dividerColor?: string;
}

export const IntegrationCard: React.FC<IntegrationCardProps> = observer((p) => {
    const goToDetails = useNavigateToIntegrationDetails(p.instance?.getId());

    return (
        <Card {...p.cardProps}>
            <Grid container direction={"column"} alignItems={"stretch"}>
                {!!p.cardActionsGetter && (
                    <OperatorView>
                        <Grid item>
                            <Box display={"flex"} justifyContent={"flex-end"} pr={1} pt={1}>
                                <ActionMenuButton actions={p.cardActionsGetter(p.instance)} />
                            </Box>
                        </Grid>
                    </OperatorView>
                )}
                <Grid item>
                    <Box display={"flex"} justifyContent={"center"} pb={4} pt={!!p.cardActionsGetter ? 0 : 4}>
                        <ModuleIcon as={p.module.icon} size={"large"} />
                    </Box>
                </Grid>
                <Grid item>
                    <Divider sx={{ borderColor: p.dividerColor }} />
                </Grid>
                <Grid item>
                    <Box p={2}>
                        <Box>
                            {!!p.instance && (
                                <Box pb={1}>
                                    <Typography variant={"body1"}>
                                        <Link onClick={goToDetails}>{p.instance.getFriendlyName()}</Link>
                                    </Typography>
                                </Box>
                            )}
                            <Box minHeight={100}>
                                <Typography variant={"body1"}>{p.module.label}</Typography>
                                <Typography variant={"body2"} color={"textSecondary"}>
                                    {p.module.description}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {!!p.mainActionButton && (
                    <Grid item>
                        <Box p={2} display={"flex"} justifyContent={"center"}>
                            {p.mainActionButton}
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Card>
    );
});

// ======================
// AddIntegrationButton
// ======================

interface AddIntegrationButtonProps {
    route: INTEGRATIONS_TYPE_SUBROUTE;
    defaultEnabled: boolean;
    disabled?: boolean;
}

export const AddIntegrationButton: React.FC<AddIntegrationButtonProps> = observer((p) => {
    const goToCreateForm = useNavigateToCreateIntegrationForm(p.route);

    return (
        <Button variant={"contained"} color={"primary"} onClick={goToCreateForm} disabled={p.disabled || p.defaultEnabled}>
            {p.defaultEnabled ? "Enabled" : "Add Integration"}
        </Button>
    );
});

export interface IntegrationAlertConfig {
    alertMessage: string;
    navigateBack: () => void;
    navigateBackLabel: string;
}

// ======================
// IntegrationRedirectAlert
// ======================

interface IntegrationRedirectAlertProps {
    alertConfig: IntegrationAlertConfig;
    boxProps: Partial<BoxProps>;
}

export const IntegrationRedirectAlert: React.FC<IntegrationRedirectAlertProps> = observer((p) => {
    const { integrationsService } = useAppServices();

    const resetIntegrationRedirect = () => {
        p.alertConfig.navigateBack();
        integrationsService.resetIntegrationRedirectState();
    };

    return (
        <>
            <Box {...p.boxProps}>
                <Alert
                    severity={"info"}
                    action={
                        <Button onClick={resetIntegrationRedirect} color={"inherit"} startIcon={<MdArrowBack />} size={"small"}>
                            {p.alertConfig.navigateBackLabel}
                        </Button>
                    }
                >
                    {p.alertConfig.alertMessage}
                </Alert>
            </Box>
        </>
    );
});

export const getIntegrationTypeFromRoute = (route: INTEGRATIONS_TYPE_SUBROUTE) => {
    if (route === INTEGRATIONS_TYPE_SUBROUTE.AWS) {
        return IntegrationModule.AWS;
    } else if (route === INTEGRATIONS_TYPE_SUBROUTE.AZURE) {
        return IntegrationModule.AZURE;
    } else if (route === INTEGRATIONS_TYPE_SUBROUTE.DGS) {
        return IntegrationModule.DGS;
    } else if (route === INTEGRATIONS_TYPE_SUBROUTE.PURE_CBS) {
        return IntegrationModule.PURE;
    } else if (route === INTEGRATIONS_TYPE_SUBROUTE.NETAPP) {
        return IntegrationModule.NETAPP;
    } else if (route === INTEGRATIONS_TYPE_SUBROUTE.PURE_FLASH) {
        return IntegrationModule.FC_PURE;
    } else if (route === INTEGRATIONS_TYPE_SUBROUTE.FC_POWERSTORE) {
        return IntegrationModule.FC_POWERSTORE;
    } else if (route === INTEGRATIONS_TYPE_SUBROUTE.POWERSTORE) {
        return IntegrationModule.POWERSTORE;
    } else if (route === INTEGRATIONS_TYPE_SUBROUTE.POWERMAX) {
        return IntegrationModule.FC_POWERMAX;
    } else if (route === INTEGRATIONS_TYPE_SUBROUTE.AWS_FSX_NETAPP) {
        return IntegrationModule.AWS_FSX_NETAPP;
    } else if (route === INTEGRATIONS_TYPE_SUBROUTE.GOOGLE) {
        return IntegrationModule.GCP;
    } else if (route === INTEGRATIONS_TYPE_SUBROUTE.ORACLE) {
        return IntegrationModule.ORACLE;
    } else if (route === INTEGRATIONS_TYPE_SUBROUTE.SILK) {
        return IntegrationModule.SILKSDP;
    } else if (route === INTEGRATIONS_TYPE_SUBROUTE.DIGITAL_OCEAN) {
        return IntegrationModule.DIGITALOCEAN;
    }
    return undefined;
};

export const INTEGRATIONS_REDIRECTED_QUERY_PARAM = "redirected";

export const generateIntegrationsSubRoutePath = (subroute: string, projectId: string, query?: boolean) => {
    if (query) {
        return generatePath(getProjectSubRouteTemplate(`${PROJECT_SUBROUTE.INTEGRATIONS}?${subroute}`), { projectId });
    }
    return generatePath(getProjectSubRouteTemplate(`${PROJECT_SUBROUTE.INTEGRATIONS}/${subroute}`), { projectId });
};

export const useRedirectToIntegrations = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generateIntegrationsSubRoutePath(`${INTEGRATIONS_REDIRECTED_QUERY_PARAM}=true`, projectId, true);
    return () => navigate(p);
};

export const useNavigateToIntegrations = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(`${PROJECT_SUBROUTE.INTEGRATIONS}`), { projectId });
    return () => navigate(p);
};

export const useNavigateToEnabledIntegrations = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generateIntegrationsSubRoutePath(INTEGRATIONS_SUBROUTE.ENABLED, projectId);
    return () => navigate(p);
};

export const useNavigateToCreateIntegrationForm = (route: INTEGRATIONS_TYPE_SUBROUTE) => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const subroute = `${INTEGRATIONS_SUBROUTE.CREATE}/${route}`;

    const p = generateIntegrationsSubRoutePath(subroute, projectId);
    return () => {
        navigate(p);
    };
};

export const useNavigateToEditIntegrationForm = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();

    return (id: string) => {
        const p = generateIntegrationsSubRoutePath(`${INTEGRATIONS_SUBROUTE.ENABLED}/${id}/edit`, projectId);
        navigate(p);
    };
};

export const useNavigateToIntegrationDetails = (id: number) => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generateIntegrationsSubRoutePath(`${INTEGRATIONS_SUBROUTE.ENABLED}/${id}`, projectId);
    return () => {
        navigate(p);
    };
};
