/**
 * @fileoverview gRPC-Web generated client stub for galaxycomplete.api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: galaxycompletepb/apipb/pelocal_api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as galaxycompletepb_apipb_pelocal_api_pb from '../../galaxycompletepb/apipb/pelocal_api_pb';


export class PrivateEditionLocalServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetPrivateEditionLocalStatus = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.PrivateEditionLocalService/GetPrivateEditionLocalStatus',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_pelocal_api_pb.GetPrivateEditionLocalStatus.Request,
    galaxycompletepb_apipb_pelocal_api_pb.GetPrivateEditionLocalStatus.Response,
    (request: galaxycompletepb_apipb_pelocal_api_pb.GetPrivateEditionLocalStatus.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_pelocal_api_pb.GetPrivateEditionLocalStatus.Response.deserializeBinary
  );

  getPrivateEditionLocalStatus(
    request: galaxycompletepb_apipb_pelocal_api_pb.GetPrivateEditionLocalStatus.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_pelocal_api_pb.GetPrivateEditionLocalStatus.Response>;

  getPrivateEditionLocalStatus(
    request: galaxycompletepb_apipb_pelocal_api_pb.GetPrivateEditionLocalStatus.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_pelocal_api_pb.GetPrivateEditionLocalStatus.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_pelocal_api_pb.GetPrivateEditionLocalStatus.Response>;

  getPrivateEditionLocalStatus(
    request: galaxycompletepb_apipb_pelocal_api_pb.GetPrivateEditionLocalStatus.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_pelocal_api_pb.GetPrivateEditionLocalStatus.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.PrivateEditionLocalService/GetPrivateEditionLocalStatus',
        request,
        metadata || {},
        this.methodDescriptorGetPrivateEditionLocalStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.PrivateEditionLocalService/GetPrivateEditionLocalStatus',
    request,
    metadata || {},
    this.methodDescriptorGetPrivateEditionLocalStatus);
  }

  methodDescriptorGeneratePeLicenseRequest = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.PrivateEditionLocalService/GeneratePeLicenseRequest',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_pelocal_api_pb.GeneratePeLicenseRequest.Request,
    galaxycompletepb_apipb_pelocal_api_pb.GeneratePeLicenseRequest.Response,
    (request: galaxycompletepb_apipb_pelocal_api_pb.GeneratePeLicenseRequest.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_pelocal_api_pb.GeneratePeLicenseRequest.Response.deserializeBinary
  );

  generatePeLicenseRequest(
    request: galaxycompletepb_apipb_pelocal_api_pb.GeneratePeLicenseRequest.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_pelocal_api_pb.GeneratePeLicenseRequest.Response>;

  generatePeLicenseRequest(
    request: galaxycompletepb_apipb_pelocal_api_pb.GeneratePeLicenseRequest.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_pelocal_api_pb.GeneratePeLicenseRequest.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_pelocal_api_pb.GeneratePeLicenseRequest.Response>;

  generatePeLicenseRequest(
    request: galaxycompletepb_apipb_pelocal_api_pb.GeneratePeLicenseRequest.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_pelocal_api_pb.GeneratePeLicenseRequest.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.PrivateEditionLocalService/GeneratePeLicenseRequest',
        request,
        metadata || {},
        this.methodDescriptorGeneratePeLicenseRequest,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.PrivateEditionLocalService/GeneratePeLicenseRequest',
    request,
    metadata || {},
    this.methodDescriptorGeneratePeLicenseRequest);
  }

  methodDescriptorGetPendingPeLicenseRequest = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.PrivateEditionLocalService/GetPendingPeLicenseRequest',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_pelocal_api_pb.GetPendingPeLicenseRequest.Request,
    galaxycompletepb_apipb_pelocal_api_pb.GetPendingPeLicenseRequest.Response,
    (request: galaxycompletepb_apipb_pelocal_api_pb.GetPendingPeLicenseRequest.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_pelocal_api_pb.GetPendingPeLicenseRequest.Response.deserializeBinary
  );

  getPendingPeLicenseRequest(
    request: galaxycompletepb_apipb_pelocal_api_pb.GetPendingPeLicenseRequest.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_pelocal_api_pb.GetPendingPeLicenseRequest.Response>;

  getPendingPeLicenseRequest(
    request: galaxycompletepb_apipb_pelocal_api_pb.GetPendingPeLicenseRequest.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_pelocal_api_pb.GetPendingPeLicenseRequest.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_pelocal_api_pb.GetPendingPeLicenseRequest.Response>;

  getPendingPeLicenseRequest(
    request: galaxycompletepb_apipb_pelocal_api_pb.GetPendingPeLicenseRequest.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_pelocal_api_pb.GetPendingPeLicenseRequest.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.PrivateEditionLocalService/GetPendingPeLicenseRequest',
        request,
        metadata || {},
        this.methodDescriptorGetPendingPeLicenseRequest,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.PrivateEditionLocalService/GetPendingPeLicenseRequest',
    request,
    metadata || {},
    this.methodDescriptorGetPendingPeLicenseRequest);
  }

  methodDescriptorCompletePeLicenseTransaction = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.PrivateEditionLocalService/CompletePeLicenseTransaction',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_pelocal_api_pb.CompletePeLicenseTransaction.Request,
    galaxycompletepb_apipb_pelocal_api_pb.CompletePeLicenseTransaction.Response,
    (request: galaxycompletepb_apipb_pelocal_api_pb.CompletePeLicenseTransaction.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_pelocal_api_pb.CompletePeLicenseTransaction.Response.deserializeBinary
  );

  completePeLicenseTransaction(
    request: galaxycompletepb_apipb_pelocal_api_pb.CompletePeLicenseTransaction.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_pelocal_api_pb.CompletePeLicenseTransaction.Response>;

  completePeLicenseTransaction(
    request: galaxycompletepb_apipb_pelocal_api_pb.CompletePeLicenseTransaction.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_pelocal_api_pb.CompletePeLicenseTransaction.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_pelocal_api_pb.CompletePeLicenseTransaction.Response>;

  completePeLicenseTransaction(
    request: galaxycompletepb_apipb_pelocal_api_pb.CompletePeLicenseTransaction.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_pelocal_api_pb.CompletePeLicenseTransaction.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.PrivateEditionLocalService/CompletePeLicenseTransaction',
        request,
        metadata || {},
        this.methodDescriptorCompletePeLicenseTransaction,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.PrivateEditionLocalService/CompletePeLicenseTransaction',
    request,
    metadata || {},
    this.methodDescriptorCompletePeLicenseTransaction);
  }

}

