import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import * as React from "react";
import { useEffect } from "react";
import { ProjectDetails } from "../../_proto/galaxycompletepb/apipb/domainpb/project_pb";
import { INTEGRATIONS_SUBROUTE, useQueryParams } from "../app/AppRoutes";
import { EnabledIntegrationsList } from "./EnabledIntegrationsList";
import { EnabledIntegrationsDetails } from "./EnabledIntegrationsDetails";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import {
    AddIntegrationButton,
    getIntegrationCategoryDisplayName,
    getIntegrationModuleDefs,
    IntegrationCard,
    IntegrationCategory,
    IntegrationModuleDef,
    IntegrationRedirectAlert,
    INTEGRATIONS_REDIRECTED_QUERY_PARAM,
} from "./IntegrationsCommon";
import { EditEnabledIntegration } from "./IntegrationForm/EditEnabledIntegration";
import { CreateNewIntegration } from "./IntegrationForm/CreateNewIntegration";
import { useAppServices } from "../app/services";
import { useIsFeatureEnabled } from "../core/featureflag/FeatureFlags";
import { FeatureFlag } from "../app/AppGlobalService";
import { useIsDesktop } from "../layout/MainLayout";
import { useOpenHelpArticle } from "../help/hooks/help_hooks";
import { KnownArticle } from "../help/HelpCommon";

// ======================
// ProjectIntegrationsScreen
// ======================

interface ProjectIntegrationsScreenProps {
    project: ProjectDetails;
}

export const ProjectIntegrationsScreens: React.FC<ProjectIntegrationsScreenProps> = observer((p) => {
    return (
        <Routes>
            <Route index element={<ProjectIntegrationsMainScreen />} />
            <Route path={INTEGRATIONS_SUBROUTE.CREATE}>
                <Route path={`:integrationType`} element={<CreateNewIntegration />} />
            </Route>
            <Route path={INTEGRATIONS_SUBROUTE.ENABLED}>
                <Route index element={<EnabledIntegrationsList />} />
                <Route path={`:integrationId`}>
                    <Route index element={<EnabledIntegrationsDetails />} />
                    <Route path={"edit"} element={<EditEnabledIntegration />} />
                </Route>
            </Route>
        </Routes>
    );
});

// ======================
// ProjectIntegrationsMainScreen
// ======================

interface ProjectIntegrationsMainScreenProps {}

export const ProjectIntegrationsMainScreen: React.FC<ProjectIntegrationsMainScreenProps> = observer((p) => {
    const { integrationsService } = useAppServices();
    const query = useQueryParams();
    const redirectState = integrationsService.integrationRedirectState;
    const isVMwareEnabled = useIsFeatureEnabled(FeatureFlag.COMPUTE_MIGRATION_VMWARE);

    const alertIntegrations = getIntegrationModuleDefs().filter((i) => i.category === IntegrationCategory.ALERT);
    const publicCloudIntegrations = getIntegrationModuleDefs().filter((i) => i.category === IntegrationCategory.PUBLIC_CLOUD);
    const iscsiStorageArrayIntegrations = getIntegrationModuleDefs().filter((i) => i.category === IntegrationCategory.ISCSI_STORAGE_ARRAY);
    const fcStorageArrayIntegrations = getIntegrationModuleDefs().filter((i) => i.category === IntegrationCategory.FC_STORAGE_ARRAY);
    const computeIntegrations = getIntegrationModuleDefs().filter((i) => i.category === IntegrationCategory.COMPUTE_INTEGRATION);

    useEffect(() => {
        if (!query.get(INTEGRATIONS_REDIRECTED_QUERY_PARAM)) {
            integrationsService.resetIntegrationRedirectState();
        }
    }, [query, integrationsService]);

    return (
        <ScreenContainer>
            {!!redirectState && <IntegrationRedirectAlert boxProps={{ pb: 2 }} alertConfig={redirectState.alertConfig} />}

            <ScreenTitleBar title={"Integration Goodies"} />
            <IntegrationsGoodiesSection title={getIntegrationCategoryDisplayName(IntegrationCategory.ALERT)} integrationsList={alertIntegrations} />
            <IntegrationsGoodiesSection
                title={getIntegrationCategoryDisplayName(IntegrationCategory.PUBLIC_CLOUD)}
                integrationsList={publicCloudIntegrations}
            />
            <IntegrationsGoodiesSection
                title={getIntegrationCategoryDisplayName(IntegrationCategory.ISCSI_STORAGE_ARRAY)}
                integrationsList={iscsiStorageArrayIntegrations}
            />
            <IntegrationsGoodiesSection
                title={getIntegrationCategoryDisplayName(IntegrationCategory.FC_STORAGE_ARRAY)}
                integrationsList={fcStorageArrayIntegrations}
            />
            {
                // isVMwareEnabled &&
                <IntegrationsGoodiesSection
                    title={getIntegrationCategoryDisplayName(IntegrationCategory.COMPUTE_INTEGRATION)}
                    integrationsList={computeIntegrations}
                />
            }
        </ScreenContainer>
    );
});

// ======================
// IntegrationsGoodiesSection
// ======================

interface IntegrationsGoodiesSectionProps {
    title: string;
    integrationsList: IntegrationModuleDef[];
}

export const IntegrationsGoodiesSection: React.FC<IntegrationsGoodiesSectionProps> = observer((p) => {
    const isDesktop = useIsDesktop();
    const numOfIntegrations = p.integrationsList.length;
    const { helpService } = useAppServices();
    const openHelpArticle = useOpenHelpArticle();

    if (numOfIntegrations > 0) {
        return (
            <Box pb={3}>
                <Typography variant={"h6"}>{`${p.title} (${numOfIntegrations})`}</Typography>
                <br />
                <Grid container spacing={2}>
                    {p.integrationsList.map((i, index) => {
                        const getMainActionButton = () => {
                            if (!!i.route) {
                                return <AddIntegrationButton route={i.route} disabled={i.disabled} defaultEnabled={i.defaultEnabled} />;
                            } else if (!!i.externalLink) {
                                return (
                                    <Button variant={"contained"} color={"primary"} onClick={() => openHelpArticle(i.externalLink as KnownArticle)}>
                                        {i.mainActionButtonLabel}
                                    </Button>
                                );
                            }
                        };
                        return (
                            <Grid item key={index} xs={isDesktop ? false : 12}>
                                <IntegrationCard module={i} mainActionButton={getMainActionButton()} cardProps={{ sx: { width: isDesktop ? 325 : "100%" } }} />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        );
    }
    return null;
});
