/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: GlobalDialogViews
 */
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useAppServices } from "../../app/services";
import { AlertDialogConfig, ConfirmDialogConfig } from "./DialogService";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useGlobalDialogState } from "./GlobalDialogState";

// ======================
// GlobalDialogViews
// ======================
interface GlobalDialogViewsProps {}

export const GlobalDialogViews: React.FC<GlobalDialogViewsProps> = observer(({ ...p }) => {
    const { dialogService } = useAppServices();
    const confirmDialogs = useGlobalDialogState((s) => s.confirmDialogs);
    const alertDialogs = useGlobalDialogState((s) => s.alertDialogs);

    return (
        <>
            {dialogService.alertDialogs.map((c) => (
                <AlertDialog alert={c} key={c.id} />
            ))}
            {dialogService.confirmDialogs.map((c) => (
                <ConfirmDialog confirmConfig={c} key={c.id} />
            ))}
            {alertDialogs.map((c) => (
                <AlertDialog alert={c} key={c.id} />
            ))}
            {confirmDialogs.map((c) => (
                <ConfirmDialog confirmConfig={c} key={c.id} />
            ))}
        </>
    );
});

// ======================
// AlertDialog
// ======================
interface AlertDialogProps {
    alert: AlertDialogConfig;
}

const AlertDialog: React.FC<AlertDialogProps> = observer(({ alert, ...p }) => {
    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth onClose={() => alert.onClose(true)}>
            <DialogTitle>{alert.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{alert.message}</DialogContentText>
                {alert.renderAdditionalContent ? alert.renderAdditionalContent() : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => alert.onClose(true)} size={"large"} color={"neutral"} {...alert.okButtonProps}>
                    {alert.okButtonLabel || `OK`}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

// ======================
// ConfirmDialog
// ======================
interface ConfirmDialogProps {
    confirmConfig: ConfirmDialogConfig;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = observer(({ confirmConfig, ...p }) => {
    const [confirmTextError, setConfirmTextError] = useState(true);
    const [confirmTextValue, setConfirmTextValue] = useState("");

    const onConfirmTextChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setConfirmTextValue(e.target.value);
        if (e.target.value === confirmConfig.typeToConfirm) {
            setConfirmTextError(false);
        } else {
            setConfirmTextError(true);
        }
    };

    return (
        <Dialog open={true} maxWidth={"sm"} fullWidth>
            <DialogTitle>{confirmConfig.title || "Confirmation"}</DialogTitle>
            <DialogContent>
                {confirmConfig.message && <DialogContentText>{confirmConfig.message}</DialogContentText>}
                {confirmConfig.renderAdditionalContent ? confirmConfig.renderAdditionalContent() : null}
                {confirmConfig.autoConfirmationQuestionLine && <DialogContentText>{"Are you sure you want to continue?"}</DialogContentText>}
                {!!confirmConfig.typeToConfirm && (
                    <Box pt={2}>
                        <DialogContentText>Please type [{confirmConfig.typeToConfirm}] to confirm.</DialogContentText>
                        <br />
                        <TextField
                            label={`Type [${confirmConfig.typeToConfirm}] to confirm`}
                            variant={"filled"}
                            required
                            fullWidth
                            value={confirmTextValue}
                            onChange={onConfirmTextChange}
                            error={confirmTextError}
                            helperText={confirmTextError ? "Please enter the text exactly as displayed to confirm" : null}
                        />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button color={"neutral"} size={"large"} {...confirmConfig.cancelButtonProps} onClick={() => confirmConfig.onClose(false)}>
                    {confirmConfig.cancelButtonLabel || `No`}
                </Button>
                <Button
                    disabled={!!confirmConfig.typeToConfirm ? confirmTextError : false}
                    onClick={() => confirmConfig.onClose(true)}
                    color={"primary"}
                    size={"large"}
                    {...confirmConfig.okButtonProps}
                >
                    {confirmConfig.okButtonLabel || `Yes`}
                </Button>
            </DialogActions>
        </Dialog>
    );
});
