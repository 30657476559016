import { UseMutationOptions } from "@tanstack/react-query/src/types";
import { useGlobalDialogState } from "../dialog/GlobalDialogState";
import { QueryObserverSuccessResult, useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useGlobalProgressState } from "../progress/GlobalProgressState";
import { QueryKey } from "@tanstack/query-core";

export const useQueryAlertError = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
    options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) => {
    const globalDialogState = useGlobalDialogState();

    return useQuery<TQueryFnData, TError, TData, TQueryKey>({
        ...options,
        onError: async (error) => {
            await globalDialogState.addErrorAlertDialog(error as unknown as Error, true);
        },
    });
};

export const useMutationTrackAndAlertError = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
    options: UseMutationOptions<TData, TError, TVariables, TContext>,
    loadingMessage?: string
) => {
    const globalDialogState = useGlobalDialogState();
    const track = useGlobalProgressState((s) => s.track);

    const mutationFn = async (variables: TVariables) => await track(options.mutationFn(variables), loadingMessage);

    return useMutation(options.mutationKey, mutationFn, {
        ...options,
        onError: async (error) => {
            await globalDialogState.addErrorAlertDialog(error as unknown as Error, true);
        },
    });
};
