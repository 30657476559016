// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: GalaxyMigrateDeploymentsList

import * as React from "react";
import { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useAppServices } from "../app/services";
import { renderServerDataWithLoadingList, useInitData } from "../core/data/DataLoaderHooks";
import { Box, Button, Card, Chip, Link, Tooltip, Typography, useTheme } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";
import { GalaxyMigrateDeploymentInfo } from "../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { ColumnDef, DataTable } from "../../common/table/DataTable";
import { getDeploymentConnectionStyle, HostOSIcon, RelayIcon } from "../deployment/DeploymentCommon";
import { FormattedDisplay } from "../../common/FormattedDisplay";
import { useIsOperatorView } from "../auth/AuthenticatedViews";
import { NewDeploymentButton } from "../deployment/NewDeploymentInstructions";
import { DeploymentHostEnvironment } from "../../_proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import { Duration } from "google-protobuf/google/protobuf/duration_pb";
import { AppHintID } from "../help/HelpCommon";
import { useCurrentProject } from "../project/CurrentProject";
import { HostLicenseInfo } from "../../_proto/galaxycompletepb/apipb/domainpb/license_pb";
import { DeleteIcon, HostEnvironmentIcon, LicenseIcon } from "../../common/CommonIcons";
import { getGalaxyMigrateHelperNodeOSName, getHostEnvDisplayName } from "./GalaxyMigrateCommon";
import { ConfigureGalaxyMigrateCmcHelperButton } from "./compute/GalaxyMigrateConfigureCmcHelper";
import { getLicenseCapacityColor, getLicenseDurationLeftString, getLicenseExpirationDateColor, useDeleteHostLicense } from "./hostLicense/HostLicenseCommon";
import { useCurrentProjectLicenseModel } from "../license/LicenseCommon";
import { LicenseModel } from "../../_proto/galaxycompletepb/apipb/domainpb/enumpb/license_model_pb";
import { useOpenHelpSearch } from "../help/hooks/help_hooks";

// ======================
// GalaxyMigrateDeploymentsList
// ======================
interface GalaxyMigrateDeploymentsListProps {}

export const GalaxyMigrateDeploymentsList: React.FC<GalaxyMigrateDeploymentsListProps> = observer((p) => {
    const { deploymentService } = useAppServices();
    useInitData({
        poll: () => deploymentService.galaxyMigrateDeployments.fetchData(),
        pollInterval: 10,
    });

    return renderServerDataWithLoadingList(deploymentService.galaxyMigrateDeployments, (data) => {
        return <GalaxyMigrateDeploymentTable />;
    });
});

// ======================
// GalaxyMmigrateCmcRelayEnabledDeploymentsList
// ======================

interface GalaxyMmigrateCmcRelayEnabledDeploymentsListProps {}

export const GalaxyMmigrateCmcRelayEnabledDeploymentsList: React.FC<GalaxyMmigrateCmcRelayEnabledDeploymentsListProps> = observer((p) => {
    const { deploymentService } = useAppServices();
    useInitData({
        poll: () => deploymentService.galaxyMigrateRelayDeployments.fetchData(),
        pollInterval: 10,
    });

    return renderServerDataWithLoadingList(deploymentService.galaxyMigrateRelayDeployments, (data) => {
        return <GalaxyMigrateDeploymentTable relayOnly />;
    });
});

// ======================
// GalaxyMigrateDeploymentTable
// ======================
interface GalaxyMigrateDeploymentTableProps {
    relayOnly?: boolean;
}

const GalaxyMigrateDeploymentTable: React.FC<GalaxyMigrateDeploymentTableProps> = observer((p) => {
    const { deploymentService, dialogService } = useAppServices();
    const serverData = p.relayOnly ? deploymentService.galaxyMigrateRelayDeployments : deploymentService.galaxyMigrateDeployments;
    const isOperator = useIsOperatorView();
    const projectLicenseModel = useCurrentProjectLicenseModel();
    const t = useTheme();
    const deleteLicense = useDeleteHostLicense("list");
    const openHelpSearch = useOpenHelpSearch();

    const getEmptyTableProps = useCallback(() => {
        if (p.relayOnly) {
            return {
                emptyTableIcon: <RelayIcon />,
                emptyTableTitle: "No Activated CDC Management Relay",
                emptyTableMessage: "CDC Management Relay allows other Cirrus Migrate Cloud hosts to be deployed and connect to Cirrus Data Cloud via a host.",
                emptyTableActionButton: (
                    <Button variant={"outlined"} onClick={() => openHelpSearch(AppHintID.CMC_RELAY)}>
                        Learn More
                    </Button>
                ),
            };
        } else {
            return {
                emptyTableTitle: "No Hosts Found",
                emptyTableActionButton: <NewDeploymentButton />,
            };
        }
    }, [p.relayOnly, openHelpSearch]);
    const cols: ColumnDef<GalaxyMigrateDeploymentInfo>[] = [
        {
            id: "systemName",
            label: "Name",
            getter: (r) => r.getDeployment().getSystemName(),
            renderer: (name, r) => {
                return (
                    <Box>
                        <Link component={RouterLink} to={r.getDeployment().getSystemId()}>
                            {name}
                        </Link>
                    </Box>
                );
            },
        },
        {
            id: "version",
            label: "Version",
            getter: (r) => r.getDeployment().getVersion(),
            renderer: (version, r) => {
                return (
                    <Box display={"flex"} alignItems={"center"}>
                        <Typography sx={getDeploymentConnectionStyle(r.getDeployment().getConnected())}>{version}</Typography>
                        {!!r.getDeployment().getCdcRelayServerAddress() && (
                            <Box pl={2}>
                                <Tooltip
                                    arrow
                                    title={
                                        <Box>
                                            <Typography variant={"subtitle2"}>CDC Management Relay</Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {r.getDeployment().getCdcRelayServerAddress().split("/")[0]}
                                            </Typography>
                                        </Box>
                                    }
                                >
                                    <Chip label={"Relay"} color={"success"} variant={"outlined"} />
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                );
            },
        },
        {
            id: "os",
            label: "OS",
            getter: (r) => `${r.getOsClass()} - ${r.getKernel()}`,
            renderer: (os: string, r) => {
                return <GalaxyMigrationDeploymentOSDisplay deploymentInfo={r} />;
            },
        },
        {
            id: "environment",
            label: "Host Environment",
            getter: (r) => r.getDeployment().getHostEnvironment().getValue(),
            renderer: (env: DeploymentHostEnvironment.DeploymentHostEnvironment, r) => {
                return <GalaxyMigrationDeploymentEnvironmentDisplay deploymentInfo={r} />;
            },
        },
        {
            id: "connected",
            label: "Connected",
            getter: (r) => r.getDeployment().getConnected(),
            renderer: (connected, r) => {
                return (
                    <Typography sx={getDeploymentConnectionStyle(r.getDeployment().getConnected())}>
                        {formatKnownDataType(connected, KnownDataType.BOOL)}
                    </Typography>
                );
            },
            //dataType: KnownDataType.BOOL,
        },
        {
            id: "lastCheckin",
            label: "Check-In",
            getter: (r) => {
                return r.getDeployment().getLastCheckin()?.toDate();
            },
            renderer: (d, r) => {
                return !d ? (
                    "Never"
                ) : (
                    <Box sx={getDeploymentConnectionStyle(r.getDeployment().getConnected())}>
                        <FormattedDisplay dataType={KnownDataType.DATE_RELATIVE} value={d} />
                    </Box>
                );
            },
        },
        {
            id: "latency",
            label: "Latency",
            getter: (r) => r.getDeployment().getConnectionLatency(),
            renderer: (latency: Duration, r) => {
                return (
                    <Typography sx={getDeploymentConnectionStyle(r.getDeployment().getConnected())}>
                        {formatKnownDataType(latency, KnownDataType.DURATION_MILLISECONDS)}
                    </Typography>
                );
            },
        },
        {
            id: "license",
            label: "License Status",
            getter: (r) => r.getDeployment().getLicense(),
            renderer: (license: HostLicenseInfo, r) => {
                if (!license) {
                    return "--";
                }
                return (
                    <Box>
                        <Typography color={getLicenseCapacityColor(license.getMigrationCapacityRemaining(), t, true)}>
                            {formatKnownDataType(license.getMigrationCapacityRemaining(), KnownDataType.CAPACITY)} left
                        </Typography>
                        <Tooltip
                            title={`${formatKnownDataType(license.getExpireAt().toDate(), KnownDataType.DATE)} (${formatKnownDataType(
                                license.getExpireAt().toDate(),
                                KnownDataType.DATE_RELATIVE
                            )})`}
                            arrow
                        >
                            <Typography color={getLicenseExpirationDateColor(license.getExpireAt().toDate(), t, true)}>
                                {getLicenseDurationLeftString(license.getExpireAt().toDate())}
                            </Typography>
                        </Tooltip>
                    </Box>
                );
            },
            hidden: projectLicenseModel !== LicenseModel.LicenseModel.HOST_BASED,
        },
    ];

    const getRowActions = (r: GalaxyMigrateDeploymentInfo) => [
        {
            id: "delete-license",
            name: "Delete Host License",
            action: async () => await deleteLicense(r.getDeployment()),
            icon: <LicenseIcon />,
            hidden: !r.getDeployment().getLicense(),
        },
        {
            id: "remove",
            name: `Remove Host From Project`,
            action: async () => {
                const confirmed = await dialogService.addConfirmDialog({
                    title: "Remove Host From Project",
                    message: (
                        <Box>
                            <Typography>Are you sure you want to remove this host from this project?</Typography>
                            <br />
                            {!!r.getDeployment().getLicense() && (
                                <Typography color={t.palette.warning.main}>
                                    WARNING: Any remaining host license capacity and time will not be recouped.
                                </Typography>
                            )}
                        </Box>
                    ),
                    autoConfirmationQuestionLine: false,
                    okButtonProps: {
                        color: "error",
                    },
                });
                if (confirmed) {
                    const removed = await deploymentService.removeDeployment(r.getDeployment().getSystemId());
                    if (removed) {
                        await serverData.fetchData();
                    }
                }
            },
            hidden: !isOperator,
            icon: <DeleteIcon />,
        },
    ];
    return (
        <Card elevation={2}>
            <DataTable
                rows={serverData?.data?.getItemsList()}
                cols={cols}
                {...getEmptyTableProps()}
                state={serverData.tableState}
                pagerMeta={serverData?.data?.getPagerMeta().toObject()}
                loading={serverData.loading}
                rowActions={(r) => getRowActions(r)}
                onTableStateChange={() => serverData.fetchData()}
            />
        </Card>
    );
});

// ======================
// GalaxyMigrationDeploymentOSDisplay
// ======================
interface GalaxyMigrationDeploymentOSDisplayProps {
    deploymentInfo: GalaxyMigrateDeploymentInfo;
    hideKernel?: boolean;
}

export const GalaxyMigrationDeploymentOSDisplay: React.FC<GalaxyMigrationDeploymentOSDisplayProps> = observer((p) => {
    const r = p.deploymentInfo;
    const os = `${r.getOsClass()}${!p.hideKernel ? ` - ${r.getKernel()}` : ""}`;
    const isHelper = r.getHelperNode();
    let content = null;
    if (!isHelper) {
        content = (
            <>
                <HostOSIcon os={os} /> &nbsp;&nbsp;&nbsp;&nbsp;
                <div>{os}</div>
            </>
        );
    } else {
        const helperName = getGalaxyMigrateHelperNodeOSName(r.getHelperNodeType().getValue());
        content = (
            <>
                {r.getHelperNodeConfigured() && <div>{helperName}</div>}
                {!r.getHelperNodeConfigured() && (
                    <div>
                        <ConfigureGalaxyMigrateCmcHelperButton
                            disabled={!r.getDeployment().getConnected()}
                            systemID={r.getDeployment().getSystemId()}
                            os={r.getHelperNodeType().getValue()}
                        />
                    </div>
                )}
            </>
        );
    }

    return (
        <Box display={"flex"} sx={getDeploymentConnectionStyle(r.getDeployment().getConnected())}>
            {content}
        </Box>
    );
});

// ======================
// GalaxyMigrationDeploymentEnvironmentDisplay
// ======================

interface GalaxyMigrationDeploymentEnvironmentDisplayProps {
    deploymentInfo: GalaxyMigrateDeploymentInfo;
}

export const GalaxyMigrationDeploymentEnvironmentDisplay: React.FC<GalaxyMigrationDeploymentEnvironmentDisplayProps> = observer((p) => {
    const r = p.deploymentInfo;
    const env = r.getDeployment().getHostEnvironment().getValue();

    return (
        <Box display={"flex"} sx={getDeploymentConnectionStyle(r.getDeployment().getConnected())}>
            <HostEnvironmentIcon env={env} /> &nbsp;&nbsp;&nbsp;&nbsp;
            <div>{getHostEnvDisplayName(env)}</div>
        </Box>
    );
});
