// Project: GalaxyComplete
// Created: 9/26/20 by sammy
// File: GalaxyMigrateDeploymentDetails

import * as React from "react";
import { observer } from "mobx-react-lite";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { GalaxyMigrateDeploymentDetails, GalaxyMigrateStorageConfig } from "../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import {
    Box,
    Button,
    Card,
    Divider,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    SvgIcon,
    Typography,
    useTheme,
} from "@mui/material";
import { formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { formatISO9075 } from "date-fns";
import { useAppServices } from "../app/services";
import { renderServerDataWithLoadingList, useInitData } from "../core/data/DataLoaderHooks";
import { DataTable } from "../../common/table/DataTable";
import { MigrationIcon } from "../project/ProjectCommon";
import { Link as RouterLink, useParams } from "react-router-dom";
import { DEPLOYMENT_DETAILS_SUBROUTE } from "../app/AppRoutes";
import {
    generateDeploymentDetailsPath,
    getGmStorageConfigDeviceFsInfo,
    getGmStorageConfigDeviceType,
    getHostEnvDisplayName,
    useNavigateToDeploymentLiveLog,
    useNavigateToDeploymentsList,
    useNavigateToDeploymentXrays,
} from "./GalaxyMigrateCommon";
import { generateMigrationSessionDetailsPath, getGmSessionIDSlug, useNavigateToMigrationWizardScreen } from "../migration/MigrationCommon";
import { ActionConfig, ActionMenuButton } from "../../common/actions/CommonActions";
import { OperatorView } from "../auth/AuthenticatedViews";
import { GmDeploymentDetailsSettings } from "./details/GalaxyMigrateDeploymentSettings";
import { isDeploymentConnected, isDeploymentGteVersion, renderIfConnectedDeployment } from "../deployment/DeploymentCommon";
import { RouterTabConfig, RouterTabs } from "../../common/tabs/TabComponents";
import { useCurrentProjectID } from "../project/CurrentProject";
import { GalaxyMigrateConnectionsTable } from "./GalaxyMigrateConnectionsList";
import { CreateGalaxyMigrateLinkButton } from "./links/CreateGalaxyMigrateLinkForm";
import { DeleteIcon, LiveLogIcon } from "../../common/CommonIcons";
import { FaXRay } from "react-icons/fa";
import { GmMigrationWizardStep } from "../migration/GmMigrationService";
import { DeploymentNetworkInterfaceInfo } from "../../_proto/galaxycompletepb/apipb/domainpb/deployment_pb";
import { BsCircleFill, BsFillHddNetworkFill } from "react-icons/bs";
import { GalaxyMigrateDeploymentRelayScreen } from "./relay/GalaxyMigrateDeploymentRelayScreens";
import { GalaxyMigrateDeploymentHostLicenseInfoCard } from "./hostLicense/GalaxyMigrateDeploymentHostLicenseInfoCard";
import { GalaxyMigrateDeploymentHostLicenseScreen } from "./hostLicense/GalaxyMigrateDeploymentHostLicenseScreen";
import {
    checkIfLicenseModelUndecided,
    getCounterTypeDisplayValue,
    LicensingLearnMoreLink,
    useCurrentProjectLicenseModel,
    useProjectHasAvailableHostLicense,
    useProjectHasLicenseTimeExtension,
} from "../license/LicenseCommon";
import { LicenseModel } from "../../_proto/galaxycompletepb/apipb/domainpb/enumpb/license_model_pb";

import { getHostLicenseExpiredNoExtension, getHostLicenseInsufficientForMigration } from "../migration/wizard/GmMigrationWizardUtils";
import { LicenseVaultCounterType } from "../../_proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";
import { AiFillFile } from "react-icons/ai";
import { RiRestartFill } from "react-icons/ri";
import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { GalaxyMigrateDeploymentPerformanceView } from "./performance/GalaxyMigrateDeploymentPerformance";
import { MTDIDeploymentInfo } from "../../_proto/galaxycompletepb/apipb/domainpb/mtdi_pb";
import { useDialogState } from "../core/dialog/DialogService";
import { useIsSupportUser } from "../support/SupportCommon";
import { GalaxyMigrateDeploymentCreateXrayParamsDialog } from "./details/GalaxyMigrateDeploymentCreateXRayParams";

// ======================
// GalaxyMigrateDeploymentDetailsScreen
// ======================
interface GalaxyMigrateDeploymentDetailsScreenProps {
    deployment: GalaxyMigrateDeploymentDetails;
}

export const GalaxyMigrateDeploymentDetailsScreen: React.FC<GalaxyMigrateDeploymentDetailsScreenProps> = observer((p) => {
    const t = useTheme();
    const deploymentInfo = p.deployment.getInfo();
    const { gmMigrationService, deploymentService, dialogService, supportService, licenseService, gmDeploymentService } = useAppServices();
    const navigateToWizard = useNavigateToMigrationWizardScreen();
    const navigateBackToList = useNavigateToDeploymentsList();
    const systemId = deploymentInfo.getDeployment().getSystemId();
    const isConnected = isDeploymentConnected(p.deployment);
    const isHelper = p.deployment.getInfo().getHelperNode();
    const currentPath = generateDeploymentDetailsPath(systemId, useCurrentProjectID());
    const goToLogPage = useNavigateToDeploymentLiveLog(deploymentInfo.getDeployment().getSystemId());
    const isHostLicenseAvailable = useProjectHasAvailableHostLicense();
    const isHostLicenseTimeExtensionAvailable = useProjectHasLicenseTimeExtension();
    const hostLicense = p.deployment.getInfo().getDeployment().getLicense();
    const projectLicenseModel = useCurrentProjectLicenseModel();
    const isPrivateEdition = getIsPrivateEdition();
    const xrayMinVersion = "1.0.17";
    const relayMinVersion = "4.0.0";
    const goToXrays = useNavigateToDeploymentXrays(deploymentInfo.getDeployment().getSystemId());
    const isRelayEnabled = isDeploymentGteVersion(p.deployment, relayMinVersion) && !isPrivateEdition;
    const projectId = useCurrentProjectID();
    const performanceMinVersion = "5.1.0";
    const isPerformanceEnabled = isDeploymentGteVersion(p.deployment, performanceMinVersion);

    const xrayParamsDialogState = useDialogState();

    const createXray = useIsSupportUser()
        ? () => xrayParamsDialogState.open()
        : async () => {
              const confirmed = await dialogService.addConfirmDialog({
                  title: "Create X-Ray",
                  message: (
                      <Box pb={1}>
                          An X-Ray diagnostic package will be created from your host and uploaded to our support team. This package includes system events, logs
                          and metrics useful for troubleshooting. NO application or I/O data is collected during this process.
                      </Box>
                  ),
                  autoConfirmationQuestionLine: true,
              });
              if (confirmed) {
                  const result = await gmDeploymentService.getMigrationXRay(systemId);
                  if (result) {
                      const xrayId = result.getXrayId();
                      await dialogService.addAlertDialog({
                          title: "X-Ray Created",
                          message: (
                              <>
                                  If you are not already in contact with our support team and require assistance, contact us and provide us with the following
                                  X-Ray ID:
                                  <br />
                                  {xrayId}
                              </>
                          ),
                      });
                  }
              }
          };

    const restartMTdiDaemon = async () => {
        const confirmed = await dialogService.addConfirmDialog({
            title: "Restarting mTDI Daemon",
            message: <Box pb={1}>This is a support only function. We must understand the consequence of restarting mtdi daemon prior to executing it.</Box>,
            autoConfirmationQuestionLine: true,
        });
        if (confirmed) {
            await gmDeploymentService.restartMTdiDaemon(systemId);
        }
    };

    const getActions = (): ActionConfig[] => [
        {
            name: "Create X-Ray",
            id: "xRay",
            icon: (
                <SvgIcon>
                    <FaXRay />
                </SvgIcon>
            ),
            action: createXray,
            hidden: !isDeploymentGteVersion(p.deployment, xrayMinVersion) || !isConnected,
        },
        {
            name: "Remove Host From Project",
            id: "remove",
            action: async () => {
                const confirmed = await dialogService.addConfirmDialog({
                    title: "Remove Host From Project",
                    message: (
                        <Box>
                            <Typography>Are you sure you want to remove this host from this project?</Typography>
                            <br />
                            {!!deploymentInfo.getDeployment().getLicense() && (
                                <Typography color={t.palette.warning.main}>
                                    WARNING: Any remaining host license capacity and time will not be recouped.
                                </Typography>
                            )}
                        </Box>
                    ),
                    autoConfirmationQuestionLine: false,
                    okButtonProps: {
                        color: "error",
                    },
                });
                if (confirmed) {
                    const removed = await deploymentService.removeDeployment(deploymentInfo.getDeployment().getSystemId());
                    if (removed) {
                        navigateBackToList();
                    }
                }
            },
            icon: <DeleteIcon />,
        },
        {
            name: "View Live Log",
            id: "log",
            action: goToLogPage,
            icon: <LiveLogIcon />,
            supportOnly: true,
        },
        {
            name: "View / Manage X-Rays",
            id: "manageXray",
            icon: (
                <SvgIcon>
                    <AiFillFile />
                </SvgIcon>
            ),
            action: goToXrays,
            supportOnly: true,
        },
        {
            name: "Restart mTDI Daemon",
            id: "restartMTDIDaemon",
            icon: (
                <SvgIcon>
                    <RiRestartFill />
                </SvgIcon>
            ),
            action: restartMTdiDaemon,
            supportOnly: true,
        },
    ];

    const migrateHostVolumes = async () => {
        const initWizard = () => {
            gmMigrationService.initWizard().initDeploymentId(systemId);
            gmMigrationService.wizardState.stepperState.setStartingStepIndex(GmMigrationWizardStep.MIGRATION_TYPE);
            navigateToWizard();
        };

        await checkIfLicenseModelUndecided(projectLicenseModel, dialogService, projectId);

        if (projectLicenseModel === LicenseModel.LicenseModel.HOST_BASED) {
            await licenseService.projectLicenseDetails.fetchData();
            if (getHostLicenseInsufficientForMigration(hostLicense, isHostLicenseAvailable)) {
                await dialogService.addAlertDialog({
                    title: "Insufficient Host License",
                    message: (
                        <>
                            Migration session cannot be created because this host is not currently licensed and this project's Project License Key does not have
                            any {getCounterTypeDisplayValue(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE)}s.
                            <br />
                            <br />
                            <LicensingLearnMoreLink />
                        </>
                    ),
                });
            } else if (getHostLicenseExpiredNoExtension(hostLicense, isHostLicenseTimeExtensionAvailable)) {
                await dialogService.addAlertDialog({
                    title: "Host License Expired",
                    message: (
                        <>
                            Migration session cannot be created because this host's license has expired and this project's Project License Key does not have any{" "}
                            {getCounterTypeDisplayValue(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE)} Extensions.
                            <br />
                            <br />
                            <LicensingLearnMoreLink />
                        </>
                    ),
                });
            } else {
                initWizard();
            }
        }

        if (projectLicenseModel === LicenseModel.LicenseModel.CAPACITY_BASED) {
            initWizard();
        }
    };

    const tabsConfig: RouterTabConfig[] = [
        {
            path: "",
            label: "Host Info",
            renderer: () => <HostInfoArea deployment={p.deployment} />,
            default: true,
        },
        {
            path: DEPLOYMENT_DETAILS_SUBROUTE.SETTINGS,
            label: "Settings",
            renderer: () => <SettingsArea deployment={p.deployment} />,
        },
        {
            path: DEPLOYMENT_DETAILS_SUBROUTE.RELAY,
            label: "Management Relay",
            renderer: () => <GalaxyMigrateDeploymentRelayScreen deployment={p.deployment} />,
            hidden: !isRelayEnabled,
        },
        {
            path: DEPLOYMENT_DETAILS_SUBROUTE.LICENSE,
            label: "License",
            renderer: () => <GalaxyMigrateDeploymentHostLicenseScreen deployment={p.deployment} />,
            hidden: projectLicenseModel !== LicenseModel.LicenseModel.HOST_BASED,
        },
        {
            path: DEPLOYMENT_DETAILS_SUBROUTE.PERFORMANCE,
            label: "Performance",
            renderer: () => <GalaxyMigrateDeploymentPerformanceView deployment={p.deployment} />,
            hidden: !isPerformanceEnabled,
        },
    ];

    const actions = (
        <Box display={"flex"} alignItems={"center"}>
            <OperatorView>
                <Box pr={1}>
                    {!isHelper && (
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            startIcon={<MigrationIcon />}
                            disabled={!isConnected}
                            onClick={async () => await migrateHostVolumes()}
                        >
                            Migrate Host Volumes
                        </Button>
                    )}
                </Box>
                <ActionMenuButton actions={getActions()} />
            </OperatorView>
        </Box>
    );

    return (
        <ScreenContainer>
            <ScreenTitleBar title={deploymentInfo.getDeployment().getSystemName()} actions={actions} />
            <RouterTabs path={currentPath} configs={tabsConfig} />
            <br />
            {xrayParamsDialogState.isOpen && (
                <GalaxyMigrateDeploymentCreateXrayParamsDialog
                    dialogState={xrayParamsDialogState}
                    systemId={p.deployment.getInfo().getDeployment().getSystemId()}
                />
            )}
        </ScreenContainer>
    );
});

// ======================
// HostInfoArea
// ======================

interface HostInfoAreaProps {
    deployment: GalaxyMigrateDeploymentDetails;
}

const HostInfoArea: React.FC<HostInfoAreaProps> = observer((props) => {
    const { deployment } = props;
    const deploymentInfo = deployment.getInfo();
    const getLastCheckIn = () => {
        const lastCheckIn = deploymentInfo.getDeployment().getLastCheckin();
        if (!lastCheckIn) {
            return "Never";
        } else {
            return formatISO9075(deploymentInfo.getDeployment().getLastCheckin().toDate());
        }
    };

    return (
        <Box pt={2}>
            <GalaxyMigrateDeploymentHostLicenseInfoCard data={deploymentInfo.getDeployment().getLicense()} />
            <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                    <Card
                        sx={{
                            height: "100%",
                        }}
                    >
                        <List>
                            <ListSubheader>{`Host Information`}</ListSubheader>
                            <ListItem divider>
                                <ListItemText primary={deploymentInfo.getDeployment().getSystemName()} secondary={"System Name"} />
                            </ListItem>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <ListItem divider>
                                    <ListItemText
                                        primary={getHostEnvDisplayName(deploymentInfo.getDeployment().getHostEnvironment().getValue())}
                                        secondary={"Host Environment"}
                                    />
                                </ListItem>
                                <Box height={75}>
                                    <Divider orientation={"vertical"} />
                                </Box>
                                <ListItem divider>
                                    <ListItemText primary={deploymentInfo.getDeployment().getSystemTimezone()} secondary={"System Timezone"} />
                                </ListItem>
                            </Box>

                            <ListItem divider>
                                <ListItemText primary={deploymentInfo.getCpu() || "N/A"} secondary={"CPU"} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={formatKnownDataType(deploymentInfo.getMemory(), KnownDataType.CAPACITY)} secondary={"Memory"} />
                            </ListItem>
                        </List>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Card
                        sx={{
                            height: "100%",
                        }}
                    >
                        <List>
                            <ListSubheader>{`Connection Information`}</ListSubheader>
                            <ListItem divider>
                                <ListItemText
                                    primary={
                                        <Box display={"flex"}>
                                            <Typography>{deploymentInfo.getDeployment().getCdcEndpoint() || "--"}</Typography>
                                            {!deploymentInfo.getDeployment().getConnected() && (
                                                <Typography color={"textSecondary"}>&nbsp; (Disconnected)</Typography>
                                            )}
                                        </Box>
                                    }
                                    secondary={"Connection Endpoint"}
                                />
                            </ListItem>
                            <ListItem divider>
                                <ListItemText primary={getLastCheckIn()} secondary={"Last Check-In"} />
                            </ListItem>
                            <ListItem divider>
                                <ListItemText
                                    primary={formatKnownDataType(deploymentInfo.getDeployment().getConnectionLatency(), KnownDataType.DURATION_MILLISECONDS)}
                                    secondary={"Latency"}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={formatISO9075(deploymentInfo.getDeployment().getRegisteredAt().toDate())} secondary={"Registered At"} />
                            </ListItem>
                        </List>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Card
                        sx={{
                            height: "100%",
                        }}
                    >
                        <List>
                            <ListSubheader>{`Software Information`}</ListSubheader>
                            <ListItem divider>
                                <ListItemText
                                    primary={`${deploymentInfo.getDeployment().getVersion()} / ${deploymentInfo.getMtdiVersion() || "N/A"}`}
                                    secondary={"CMC Version / mTDI Version"}
                                />
                            </ListItem>
                            <ListItem divider>
                                <ListItemText primary={deploymentInfo.getDeployment().getSystemId()} secondary={"System ID"} />
                            </ListItem>
                            <ListItem divider>
                                <ListItemText primary={deploymentInfo.getOsClass()} secondary={"Operating System"} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={deploymentInfo.getKernel()} secondary={"Kernel Version"} />
                            </ListItem>
                        </List>
                    </Card>
                </Grid>
            </Grid>
            <br />
            <Box>
                <Typography variant={"h5"}>Network Interfaces</Typography>
                <NetworkInterfacesList deployment={deployment} />
            </Box>
            <br />
            <Box>
                <Typography variant={"h5"}>Storage Devices</Typography>
                <StorageConfigArea deployment={deployment} />
            </Box>
        </Box>
    );
});

// ======================
// NetworkInterfacesList
// ======================

interface NetworkInterfacesListProps {
    deployment: GalaxyMigrateDeploymentDetails;
}

const NetworkInterfacesList: React.FC<NetworkInterfacesListProps> = observer((p) => {
    if (p.deployment.getNetworkInterfacesList().length === 0) {
        return (
            <Box pt={1}>
                <Card>
                    <Box p={4} display={"flex"} justifyContent={"center"}>
                        <Typography variant={"body2"} color={"textSecondary"}>
                            No available network interface information.
                        </Typography>
                    </Box>
                </Card>
            </Box>
        );
    }
    return (
        <Box pt={1}>
            <Grid container spacing={3}>
                {p.deployment.getNetworkInterfacesList().map((n, i) => {
                    return <NetworkInterfaceCard networkInfo={n} key={i} />;
                })}
            </Grid>
        </Box>
    );
});

// ======================
// NetworkInterfaceCard
// ======================

interface NetworkInterfaceCardProps {
    networkInfo: DeploymentNetworkInterfaceInfo;
}

const NetworkInterfaceCard: React.FC<NetworkInterfaceCardProps> = observer((p) => {
    const t = useTheme();
    const getStatusColor = () => {
        if (p.networkInfo.getConnected()) {
            return t.palette.success.main;
        } else {
            return t.palette.error.main;
        }
    };
    return (
        <Grid item xs={12} md={6} lg={3}>
            <Card>
                <Box p={2}>
                    <ListItem>
                        <ListItemIcon>
                            <SvgIcon>
                                <BsFillHddNetworkFill />
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Box display={"flex"} alignItems={"center"}>
                                    {p.networkInfo.getName()} &nbsp;
                                    <BsCircleFill size={6} color={getStatusColor()} />
                                </Box>
                            }
                            secondary={
                                <>
                                    {p.networkInfo.getAddr()}
                                    <br />
                                    {p.networkInfo.getMac()}
                                </>
                            }
                        />
                    </ListItem>
                </Box>
            </Card>
        </Grid>
    );
});

// ======================
// DeviceList
// ======================
interface StorageConfigAreaProps {
    deployment: GalaxyMigrateDeploymentDetails;
}

const StorageConfigArea: React.FC<StorageConfigAreaProps> = observer((p) => {
    const { gmDeploymentService } = useAppServices();

    useInitData({
        poll: () => gmDeploymentService.storageConfig.fetchData(p.deployment.getInfo().getDeployment().getSystemId()),
        pollInterval: 15,
        deinit: () => gmDeploymentService.storageConfig.resetData(),
    });
    return (
        <Box pt={1}>
            <>
                <>
                    {renderIfConnectedDeployment(
                        p.deployment,
                        renderServerDataWithLoadingList(gmDeploymentService.storageConfig, (data) => {
                            return <GalaxyMigrateStorageConfigDisplay config={data} />;
                        })
                    )}
                </>
            </>
        </Box>
    );
});

// ======================
// GalaxyMigrateStorageConfigDisplay
// ======================
interface GalaxyMigrateStorageConfigDisplayProps {
    config: GalaxyMigrateStorageConfig;
}

export const GalaxyMigrateStorageConfigDisplay: React.FC<GalaxyMigrateStorageConfigDisplayProps> = observer((p) => {
    const { projectId } = useParams();
    return (
        <>
            <DataTable
                state={null}
                onTableStateChange={null}
                rows={p.config.getDevicesList()}
                cols={[
                    {
                        id: "device",
                        label: "Device",
                        getter: (r) => r.getBlockDevice().getDeviceName(),
                    },
                    {
                        id: "capacity",
                        label: "Capacity",
                        dataType: KnownDataType.CAPACITY,
                        getter: (r) => r.getBlockDevice().getCapacity(),
                    },
                    {
                        id: "type",
                        label: "Type",
                        getter: getGmStorageConfigDeviceType,
                    },
                    {
                        id: "fs",
                        label: "FS",
                        getter: getGmStorageConfigDeviceFsInfo,
                    },
                    {
                        id: "role",
                        label: "Role",
                        getter: (r) => Object.keys(GalaxyMigrateStorageConfig.Device.RoleInfo.Role)[r?.getRole()?.getRole()] || "Discovered",
                    },
                    {
                        id: "migration",
                        label: "Migration",
                        getter: (r) => r.getRole()?.getMigrationSessionUuid(),
                        renderer: (sessionID, _) => {
                            if (!sessionID) {
                                return "--";
                            }
                            return (
                                <Link component={RouterLink} to={generateMigrationSessionDetailsPath(projectId, sessionID)}>
                                    {getGmSessionIDSlug(sessionID)}
                                </Link>
                            );
                        },
                    },
                ]}
            />
        </>
    );
});

// ======================
// SettingsArea
// ======================

interface SettingsAreaProps {
    deployment: GalaxyMigrateDeploymentDetails;
}

const SettingsArea: React.FC<SettingsAreaProps> = observer((props) => {
    const { deployment } = props;
    const { gmDeploymentService } = useAppServices();

    useInitData({
        poll: () => gmDeploymentService.galaxyMigrateLinks.fetchData(deployment.getInfo().getDeployment().getSystemId()),
        pollInterval: 10,
        deinit: () => gmDeploymentService.galaxyMigrateLinks.resetData(),
    });

    return renderServerDataWithLoadingList(gmDeploymentService.galaxyMigrateLinks, (data) => {
        return (
            <Box pt={2}>
                <Card>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Box>
                            <ListSubheader>Host-to-Host Connections</ListSubheader>
                        </Box>
                        <Box p={1}>
                            <CreateGalaxyMigrateLinkButton icon />
                        </Box>
                    </Box>
                    <GalaxyMigrateConnectionsTable />
                </Card>
                <br />
                <GmDeploymentDetailsSettings deployment={deployment} />
            </Box>
        );
    });
});
