/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: MainLayout
 */
import * as React from "react";
import { useMediaQuery, useTheme, Box, Fab, SvgIcon, Tooltip, Theme, Toolbar, Drawer } from "@mui/material";
import { observer } from "mobx-react-lite";
import { SideMenu } from "./SideMenu";
import { TopBar } from "./TopBar";
import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { useMatch } from "react-router-dom";
import { APP_ROUTES } from "../app/AppRoutes";
import { MdHeadsetMic } from "react-icons/md";
import { useState } from "react";
import { CloseIcon } from "../../common/CommonIcons";

import { useAppServices } from "../app/services";
import { HelpActionButton, HelpDrawer } from "../help/HelpDrawer";
import { useHelpDrawerState } from "../help/hooks/help_hooks";

const DESKTOP_BREAKPOINT = "md"; // beyond this consider desktop

export const useIsDesktop = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.up(DESKTOP_BREAKPOINT));
};

interface MainFrameLayoutProps {
    isDesktop: boolean;
    children?: React.ReactNode;
}

export const HELP_DRAWER_WIDTH = 500;
export const useHelpDrawerWidth = () => {
    const theme = useTheme();
    const greaterThanXL = useMediaQuery(theme.breakpoints.up("xl"));
    return greaterThanXL ? 700 : 500;
};
const SIDE_MENU_WIDTH = 256;

export const MainFrameLayout: React.FC<MainFrameLayoutProps> = observer((props) => {
    const theme = useTheme();
    const isPrivateEdition = getIsPrivateEdition();
    const { isDesktop } = props;
    const noMainFrame = useMatch(`${APP_ROUTES.REPORTS}/:reportId*`);
    const helpDrawerOpen = useHelpDrawerState((s) => s.helpDrawerOpen);
    const helpDrawerWidth = useHelpDrawerWidth();

    if (noMainFrame) {
        return <>{props.children}</>;
    }
    return (
        <Box width={"100%"} height={"100vh"}>
            <Box
                sx={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    background: theme.palette.background.default,
                    margin: "0 auto",
                }}
            >
                <TopBar isDesktop={isDesktop} isPrivateEdition={isPrivateEdition} isHelpDrawerOpen={helpDrawerOpen} />
                <Box height={"100%"} width={"100%"}>
                    <SideMenu isDesktop={isDesktop} isPrivateEdition={isPrivateEdition} />
                    <main
                        style={{
                            flexGrow: 1,
                            paddingTop: theme.spacing(10),
                            transition: theme.transitions.create(["margin", "width"], {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.leavingScreen,
                            }),
                            width: isDesktop ? `calc(100% - ${SIDE_MENU_WIDTH}px)` : "100%",
                            position: "absolute",
                            left: isDesktop ? SIDE_MENU_WIDTH : 0,
                            ...(helpDrawerOpen && {
                                transition: theme.transitions.create(["margin", "width"], {
                                    easing: theme.transitions.easing.easeOut,
                                    duration: theme.transitions.duration.enteringScreen,
                                }),
                                width: isDesktop ? `calc(100% - ${SIDE_MENU_WIDTH + helpDrawerWidth}px)` : "100%",
                            }),
                        }}
                    >
                        {props.children}
                    </main>
                    <HelpDrawer />
                </Box>
            </Box>
            <HelpActionButton />
        </Box>
    );
});
