import {observer} from "mobx-react-lite";
import {
    GalaxyMigrateMigrationEvent,
    GalaxyMigrateMigrationSessionInfo
} from "../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import {renderServerDataWithLoadingList, useInitData} from "../../core/data/DataLoaderHooks";
import {useAppServices} from "../../app/services";
import {useEffect, useMemo, useRef, useState} from "react";
import {
    Box, Button, ButtonGroup,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SvgIcon, Theme, Tooltip,
    Typography, useTheme
} from "@mui/material";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent, timelineOppositeContentClasses,
    TimelineSeparator
} from "@mui/lab";
import {convertTimestampObjectToDate, formatKnownDataType, KnownDataType} from "../../../common/utils/formatter";
import {SelectableBox} from "../../../common/card/SelectableCard";
import {MTDIDeploymentInfo} from "../../../_proto/galaxycompletepb/apipb/domainpb/mtdi_pb";
import {getMigrationDestinationVolumeLabel, getMigrationSourceVolumeLabel} from "../MigrationCommon";
import {FaAngleDoubleDown, FaAngleDoubleRight} from "react-icons/fa";
import * as React from "react";
import {renderBootVolumeChip, renderChipInfo} from "../../../common/chip/CommonChips";
import {getGmStorageConfigDeviceType} from "../../galaxymigrate/GalaxyMigrateCommon";
import {
    FetchNextPageOptions,
    useInfiniteQuery,
    UseInfiniteQueryResult,
    useQuery,
    useQueryClient
} from "@tanstack/react-query";
import {QueryResultWrapper} from "../../core/data/QueryResultWrapper";
import {ListMigrationEvents} from "../../../_proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import {RefreshButton} from "../../../common/CommonIcons";
import {BsArrowBarDown, BsArrowBarUp} from "react-icons/bs";

// ======================
// GmMigrationSessionDetailsEventsTimeline
// ======================


interface GmMigrationSessionDetailsEventsTimelineProps {
    sessionInfo: GalaxyMigrateMigrationSessionInfo
}

export const GmMigrationSessionDetailsEventsTimeline: React.FC<GmMigrationSessionDetailsEventsTimelineProps> = observer((p) => {
    const {sessionInfo} = p;
    const {gmMigrationService} = useAppServices();
    const pageSize = 500;
    const [volUuid, setVolUuid] = useState<string>(null);
    const vols = sessionInfo.getSessionVolumesList()

    const topScrollRef = useRef(null);

    const queryFn = async ({pageParam= 0}) => {
        return await gmMigrationService.listMigrationEvents(sessionInfo.getSessionId(), pageSize, pageParam, volUuid === 'allVolumes' ? null : volUuid)
    }

    const infiniteQueryResult = useInfiniteQuery({
        queryKey: ['listMigrationEvents', pageSize, volUuid],
        queryFn: queryFn,
        getNextPageParam: (lastPage, pages) => {
            const lastEventIndex = lastPage.eventsList[lastPage.eventsList.length - 1]?.localSequenceNumber;
            return lastEventIndex ?? undefined;
        }
    })

    const queryClient = useQueryClient();

    const refresh = async()=> {
        await queryClient.resetQueries({queryKey: ['listMigrationEvents'], refetchPage: (page, index)=>index===0})
    }

    return <Box pt={2}>
        <Card>
            <Box p={2}>
                <Box display={'flex'} justifyContent={'space-between'} ref={topScrollRef}>
                    <Typography variant={'h6'}>Event Timeline</Typography>
                    <ButtonGroup>
                        <RefreshButton variant={'outlined'} onClick={refresh}/>
                        <Button startIcon={<BsArrowBarDown/>} onClick={()=>window.scrollTo(0, document.body.scrollHeight)}>Scroll To Bottom</Button>

                    </ButtonGroup>
                </Box>
                <br/>
                <FormControl fullWidth>
                    <InputLabel>Filter By Volume</InputLabel>
                    <Select variant={'outlined'}
                            onChange={(e) => setVolUuid(e.target.value as string)}
                            label={'Filter By Volume'}>
                        <MenuItem value={'allVolumes'} sx={{borderBottom: '1px solid black'}}>
                            All Volumes
                        </MenuItem>
                        {vols.map(vol => {
                            return <MenuItem value={vol.getSource().getUuid()}
                                             sx={{borderBottom: '1px solid black'}}
                                             key={vol.getSource().getUuid()}>
                                <Box width={'100%'}>
                                    <Grid container alignItems={'center'} justifyContent={'space-around'}>
                                        <Grid item xs={12} md={4}>
                                            <Box textAlign={'left'}>
                                                <Box>
                                                    <Typography variant={'body1'}>
                                                        {vol.getSource().getName()} ({formatKnownDataType(vol.getSource().getCapacity(), KnownDataType.CAPACITY)})
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={1} justifyContent={'center'}>
                                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                <SvgIcon>
                                                    <FaAngleDoubleRight/>
                                                </SvgIcon>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Box textAlign={'left'}>
                                                <Box pb={1}>
                                                    <Typography variant={'body1'}>
                                                        {vol.getDestination().getName()} ({formatKnownDataType(vol.getDestination().getCapacity(), KnownDataType.CAPACITY)})
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                </Box>
                            </MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
                <QueryResultWrapper queryResult={infiniteQueryResult}>
                    <EventsTimeline data={infiniteQueryResult.data?.pages}
                                    fetchNextPage={infiniteQueryResult.fetchNextPage}
                                    hasNextPage={infiniteQueryResult.hasNextPage}
                                    isFetchingNextPage={infiniteQueryResult.isFetchingNextPage}
                                    scrollToTop={()=>topScrollRef.current.scrollIntoView({ behaviour: "smooth" })}
                                    vols={vols}/>
                </QueryResultWrapper>


            </Box>
        </Card>
    </Box>
});

// ======================
// EventsTimeline
// ======================


interface EventsTimelineProps {
    data: ListMigrationEvents.Response.AsObject[]
    vols: Array<GalaxyMigrateMigrationSessionInfo.Volume>,
    fetchNextPage: (options?: FetchNextPageOptions) => Promise<UseInfiniteQueryResult>,
    hasNextPage: boolean,
    isFetchingNextPage: boolean,
    scrollToTop: ()=>void;
}

export const EventsTimeline: React.FC<EventsTimelineProps> = observer((p) => {
    const {data, vols, fetchNextPage, isFetchingNextPage, hasNextPage, scrollToTop} = p;
    const [initialLoadedMore, setInitialLoadedMore] = useState(false);
    const theme = useTheme();
    const eventsList = useMemo(()=> {
        return data.map(page => page.eventsList).flat();
    },[data]);

    const bottomScrollRef = useRef(null);

    useEffect(() => {
        if (bottomScrollRef.current && initialLoadedMore) {
            bottomScrollRef.current.scrollIntoView({ behaviour: "smooth" });
        }
    }, [eventsList, bottomScrollRef, initialLoadedMore]);

    return <>
        <Timeline sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.1,
            },

        }}>
            {eventsList.map((migrationEvent, index) => {
                const volInfo = vols.find(v => v.getSource().getUuid() === migrationEvent.volumeUuid);
                return <TimelineItem key={migrationEvent.event} ref={index === eventsList.length -1 ? bottomScrollRef : null}>
                    <TimelineOppositeContent title={formatKnownDataType(convertTimestampObjectToDate(migrationEvent.time), KnownDataType.DATE)}>
                        {formatKnownDataType(convertTimestampObjectToDate(migrationEvent.time), KnownDataType.DATE_RELATIVE)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot sx={{padding: '10px'}} variant={'outlined'}
                                     color={getTimelineDotColor(migrationEvent.eventLevel)}/>
                        {index + 1 !== eventsList.length &&
                            <TimelineConnector/>
                        }
                    </TimelineSeparator>
                    <TimelineContent>
                        <Card sx={{backgroundColor: theme.palette.cirrus.main}}>
                            <Box width={'100%'} display={'flex'} justifyContent={'space-between'} p={2}>
                                <Box>
                                    <Tooltip title={migrationEvent.event} placement={'bottom-start'} arrow>
                                        <Typography variant={'body1'}>
                                            {migrationEvent.message}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                                {!!volInfo &&
                                    <Box>
                                        <Box display={'flex'}>
                                            {volInfo?.getSource().getName()}
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </Card>
                    </TimelineContent>
                </TimelineItem>
            })}
        </Timeline>
        <Grid container width={'100%'}>
            <Grid item xs={4}>

            </Grid>
            <Grid item xs={4}>
                {eventsList.length % 500 === 0 &&
                    <Box display={'flex'} justifyContent={'center'}>
                        <Button variant={'contained'}
                                disabled={!hasNextPage || isFetchingNextPage}
                                onClick={async() => {
                                    await fetchNextPage();
                                    setInitialLoadedMore(true)
                                }}>
                            {isFetchingNextPage
                                ? 'Loading more...'
                                : hasNextPage
                                    ? 'Load More'
                                    : 'Nothing more to load'}
                        </Button>
                    </Box>
                }
            </Grid>

            <Grid item xs={4}>
                <Box display={'flex'} justifyContent={'flex-end'}>
                    <Button onClick={scrollToTop} startIcon={<BsArrowBarUp/>} variant={'outlined'}>
                        Scroll To Top
                    </Button>
                </Box>

            </Grid>
        </Grid>

    </>

});

const getTimelineDotColor = (eventLevel: GalaxyMigrateMigrationEvent.EventLevel) => {
    if (eventLevel === GalaxyMigrateMigrationEvent.EventLevel.INFO) {
        return 'primary'
    } else if (eventLevel === GalaxyMigrateMigrationEvent.EventLevel.ERROR) {
        return 'error'
    } else if (eventLevel === GalaxyMigrateMigrationEvent.EventLevel.SUCCESS) {
        return 'success'
    }
}