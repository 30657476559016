// source: galaxycompletepb/apipb/gmapipb/galaxymigrate_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var galaxycompletepb_apipb_domainpb_compute_pb = require('../../../galaxycompletepb/apipb/domainpb/compute_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_compute_pb);
var galaxycompletepb_apipb_domainpb_galaxymigrate_pb = require('../../../galaxycompletepb/apipb/domainpb/galaxymigrate_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_galaxymigrate_pb);
var galaxycompletepb_apipb_domainpb_galaxymigratelink_pb = require('../../../galaxycompletepb/apipb/domainpb/galaxymigratelink_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_galaxymigratelink_pb);
var galaxycompletepb_apipb_domainpb_workflow_pb = require('../../../galaxycompletepb/apipb/domainpb/workflow_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_workflow_pb);
var galaxycompletepb_apipb_domainpb_checklist_pb = require('../../../galaxycompletepb/apipb/domainpb/checklist_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_checklist_pb);
var galaxycompletepb_commonpb_datafilter_pb = require('../../../galaxycompletepb/commonpb/datafilter_pb.js');
goog.object.extend(proto, galaxycompletepb_commonpb_datafilter_pb);
var galaxycompletepb_commonpb_common_pb = require('../../../galaxycompletepb/commonpb/common_pb.js');
goog.object.extend(proto, galaxycompletepb_commonpb_common_pb);
var galaxymigratepb_galaxy_migrate_qos_pb = require('../../../galaxymigratepb/galaxy_migrate_qos_pb.js');
goog.object.extend(proto, galaxymigratepb_galaxy_migrate_qos_pb);
var galaxymigratepb_galaxy_migrate_support_pb = require('../../../galaxymigratepb/galaxy_migrate_support_pb.js');
goog.object.extend(proto, galaxymigratepb_galaxy_migrate_support_pb);
var galaxymigratepb_galaxy_migrate_compute_pb = require('../../../galaxymigratepb/galaxy_migrate_compute_pb.js');
goog.object.extend(proto, galaxymigratepb_galaxy_migrate_compute_pb);
var galaxymigratepb_galaxy_migrate_autoalloc_pb = require('../../../galaxymigratepb/galaxy_migrate_autoalloc_pb.js');
goog.object.extend(proto, galaxymigratepb_galaxy_migrate_autoalloc_pb);
var galaxymigratepb_galaxy_migrate_migration_pb = require('../../../galaxymigratepb/galaxy_migrate_migration_pb.js');
goog.object.extend(proto, galaxymigratepb_galaxy_migrate_migration_pb);
var galaxymigratepb_galaxy_migrate_storage_pb = require('../../../galaxymigratepb/galaxy_migrate_storage_pb.js');
goog.object.extend(proto, galaxymigratepb_galaxy_migrate_storage_pb);
var google_api_annotations_pb = require('../../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var galaxycompletepb_apipb_domainpb_azure_pb = require('../../../galaxycompletepb/apipb/domainpb/azure_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_azure_pb);
var galaxycompletepb_apipb_domainpb_recommendation_pb = require('../../../galaxycompletepb/apipb/domainpb/recommendation_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_recommendation_pb);
var validate_validate_pb = require('../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.AutoAllocVolumes', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.AutoAllocVolumes.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.AutoAllocVolumes.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CancelMigrationSession', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CancelMigrationSession.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CancelMigrationSession.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CheckForOnlineUpgrade', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ConfigureAzureHelper', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ConfigureCdcRelayServer', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ConfigureVmwareHelper', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CreateMigrationSession', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CreateMigrationSession.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CreateMigrationSession.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CutoverMigrationSession', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CutoverMigrationSession.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.CutoverMigrationSession.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.DeleteMigrationSession', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.DeleteMigrationSession.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.DeleteMigrationSession.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.DeleteSnapshots', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.DeleteSnapshots.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.DeleteSnapshots.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.FinalCutoverMigrationSession', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetAzurePrice', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetAzurePrice.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetAzurePrice.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetAzureRecommendation', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetAzureRecommendation.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetAzureRecommendation.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetCdcRelayServerStatus', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetGMStorageConfig', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetGMStorageConfig.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetGMStorageConfig.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetGalaxyMigrateLink', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetIOStats', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetIOStats.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetIOStats.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetIOStatsLive', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetIOStatsLive.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetIOStatsLive.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetMigrationSessionDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetXRay', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetXRay.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.GetXRay.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListAutoAllocStatuses', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListAzureHelpers', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListAzureHelpers.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListAzureHelpers.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListAzureProducts', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListAzureProducts.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListAzureProducts.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListMigrationEvents', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListMigrationEvents.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListMigrationEvents.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListMigrationSessions', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListMigrationSessions.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListMigrationSessions.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListSnapshots', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListSnapshots.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListSnapshots.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListVmwareHelpers', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListVmwareHelpers.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ListVmwareHelpers.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.PerformOnlineUpgrade', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.PrepareForAutoAlloc', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.RestartMTdiDaemon', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.RestartMigrationSession', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.RestartMigrationSession.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.RestartMigrationSession.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.RevertCutoverMigrationSession', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.SuspendMigrationSession', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.SuspendMigrationSession.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.SuspendMigrationSession.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.SyncMigrationSession', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.SyncMigrationSession.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.SyncMigrationSession.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.TakeSnapshots', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.TakeSnapshots.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.TakeSnapshots.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.TestAutoAllocConnection', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.UpdateMigrationSession', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.UpdateMigrationSession.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.UpdateMigrationSession.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ViewLogs', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ViewLogs.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.gm.ViewLogs.Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetIOStatsLive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetIOStatsLive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetIOStatsLive.displayName = 'proto.galaxycomplete.api.gm.GetIOStatsLive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetIOStatsLive.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetIOStatsLive.Request.displayName = 'proto.galaxycomplete.api.gm.GetIOStatsLive.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetIOStatsLive.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetIOStatsLive.Response.displayName = 'proto.galaxycomplete.api.gm.GetIOStatsLive.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetIOStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetIOStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetIOStats.displayName = 'proto.galaxycomplete.api.gm.GetIOStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetIOStats.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.GetIOStats.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetIOStats.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetIOStats.Request.displayName = 'proto.galaxycomplete.api.gm.GetIOStats.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetIOStats.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetIOStats.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetIOStats.Response.displayName = 'proto.galaxycomplete.api.gm.GetIOStats.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.displayName = 'proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request.displayName = 'proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response.displayName = 'proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.displayName = 'proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request.displayName = 'proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.displayName = 'proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.displayName = 'proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.displayName = 'proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.displayName = 'proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListVmwareHelpers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListVmwareHelpers.displayName = 'proto.galaxycomplete.api.gm.ListVmwareHelpers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListVmwareHelpers.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListVmwareHelpers.Request.displayName = 'proto.galaxycomplete.api.gm.ListVmwareHelpers.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListVmwareHelpers.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.displayName = 'proto.galaxycomplete.api.gm.ListVmwareHelpers.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ConfigureVmwareHelper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ConfigureVmwareHelper.displayName = 'proto.galaxycomplete.api.gm.ConfigureVmwareHelper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.displayName = 'proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response.displayName = 'proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.displayName = 'proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request.displayName = 'proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response.displayName = 'proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListAzureHelpers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListAzureHelpers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListAzureHelpers.displayName = 'proto.galaxycomplete.api.gm.ListAzureHelpers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListAzureHelpers.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListAzureHelpers.Request.displayName = 'proto.galaxycomplete.api.gm.ListAzureHelpers.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.ListAzureHelpers.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListAzureHelpers.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListAzureHelpers.Response.displayName = 'proto.galaxycomplete.api.gm.ListAzureHelpers.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ConfigureAzureHelper, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ConfigureAzureHelper.displayName = 'proto.galaxycomplete.api.gm.ConfigureAzureHelper';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request.displayName = 'proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response.displayName = 'proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.displayName = 'proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request.displayName = 'proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response.displayName = 'proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.displayName = 'proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request.displayName = 'proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.displayName = 'proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.displayName = 'proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request.displayName = 'proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response.displayName = 'proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.displayName = 'proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.displayName = 'proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response.displayName = 'proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetGMStorageConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetGMStorageConfig.displayName = 'proto.galaxycomplete.api.gm.GetGMStorageConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetGMStorageConfig.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetGMStorageConfig.Request.displayName = 'proto.galaxycomplete.api.gm.GetGMStorageConfig.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetGMStorageConfig.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetGMStorageConfig.Response.displayName = 'proto.galaxycomplete.api.gm.GetGMStorageConfig.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CreateMigrationSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CreateMigrationSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CreateMigrationSession.displayName = 'proto.galaxycomplete.api.gm.CreateMigrationSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.CreateMigrationSession.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CreateMigrationSession.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CreateMigrationSession.Request.displayName = 'proto.galaxycomplete.api.gm.CreateMigrationSession.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.displayName = 'proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CreateMigrationSession.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CreateMigrationSession.Response.displayName = 'proto.galaxycomplete.api.gm.CreateMigrationSession.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListMigrationSessions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListMigrationSessions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListMigrationSessions.displayName = 'proto.galaxycomplete.api.gm.ListMigrationSessions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListMigrationSessions.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListMigrationSessions.Request.displayName = 'proto.galaxycomplete.api.gm.ListMigrationSessions.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.ListMigrationSessions.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListMigrationSessions.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListMigrationSessions.Response.displayName = 'proto.galaxycomplete.api.gm.ListMigrationSessions.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetMigrationSessionDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetMigrationSessionDetails.displayName = 'proto.galaxycomplete.api.gm.GetMigrationSessionDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request.displayName = 'proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response.displayName = 'proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.UpdateMigrationSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.UpdateMigrationSession.displayName = 'proto.galaxycomplete.api.gm.UpdateMigrationSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.UpdateMigrationSession.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.displayName = 'proto.galaxycomplete.api.gm.UpdateMigrationSession.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.UpdateMigrationSession.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.UpdateMigrationSession.Response.displayName = 'proto.galaxycomplete.api.gm.UpdateMigrationSession.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CancelMigrationSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CancelMigrationSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CancelMigrationSession.displayName = 'proto.galaxycomplete.api.gm.CancelMigrationSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CancelMigrationSession.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CancelMigrationSession.Request.displayName = 'proto.galaxycomplete.api.gm.CancelMigrationSession.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CancelMigrationSession.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CancelMigrationSession.Response.displayName = 'proto.galaxycomplete.api.gm.CancelMigrationSession.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.RestartMigrationSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.RestartMigrationSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.RestartMigrationSession.displayName = 'proto.galaxycomplete.api.gm.RestartMigrationSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.RestartMigrationSession.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.RestartMigrationSession.Request.displayName = 'proto.galaxycomplete.api.gm.RestartMigrationSession.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.RestartMigrationSession.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.RestartMigrationSession.Response.displayName = 'proto.galaxycomplete.api.gm.RestartMigrationSession.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.SuspendMigrationSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.SuspendMigrationSession.displayName = 'proto.galaxycomplete.api.gm.SuspendMigrationSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.SuspendMigrationSession.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.SuspendMigrationSession.Request.displayName = 'proto.galaxycomplete.api.gm.SuspendMigrationSession.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.SuspendMigrationSession.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.SuspendMigrationSession.Response.displayName = 'proto.galaxycomplete.api.gm.SuspendMigrationSession.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.SyncMigrationSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.SyncMigrationSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.SyncMigrationSession.displayName = 'proto.galaxycomplete.api.gm.SyncMigrationSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.SyncMigrationSession.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.SyncMigrationSession.Request.displayName = 'proto.galaxycomplete.api.gm.SyncMigrationSession.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.SyncMigrationSession.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.SyncMigrationSession.Response.displayName = 'proto.galaxycomplete.api.gm.SyncMigrationSession.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CutoverMigrationSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CutoverMigrationSession.displayName = 'proto.galaxycomplete.api.gm.CutoverMigrationSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CutoverMigrationSession.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CutoverMigrationSession.Request.displayName = 'proto.galaxycomplete.api.gm.CutoverMigrationSession.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CutoverMigrationSession.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CutoverMigrationSession.Response.displayName = 'proto.galaxycomplete.api.gm.CutoverMigrationSession.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.FinalCutoverMigrationSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.displayName = 'proto.galaxycomplete.api.gm.FinalCutoverMigrationSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request.displayName = 'proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response.displayName = 'proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.RevertCutoverMigrationSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.displayName = 'proto.galaxycomplete.api.gm.RevertCutoverMigrationSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request.displayName = 'proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response.displayName = 'proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.DeleteMigrationSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.DeleteMigrationSession.displayName = 'proto.galaxycomplete.api.gm.DeleteMigrationSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.DeleteMigrationSession.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.DeleteMigrationSession.Request.displayName = 'proto.galaxycomplete.api.gm.DeleteMigrationSession.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.DeleteMigrationSession.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.DeleteMigrationSession.Response.displayName = 'proto.galaxycomplete.api.gm.DeleteMigrationSession.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.displayName = 'proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.displayName = 'proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.displayName = 'proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListMigrationEvents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListMigrationEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListMigrationEvents.displayName = 'proto.galaxycomplete.api.gm.ListMigrationEvents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListMigrationEvents.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListMigrationEvents.Request.displayName = 'proto.galaxycomplete.api.gm.ListMigrationEvents.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.ListMigrationEvents.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListMigrationEvents.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListMigrationEvents.Response.displayName = 'proto.galaxycomplete.api.gm.ListMigrationEvents.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.displayName = 'proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request.displayName = 'proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response.displayName = 'proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.displayName = 'proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.displayName = 'proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.displayName = 'proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CheckForOnlineUpgrade, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.displayName = 'proto.galaxycomplete.api.gm.CheckForOnlineUpgrade';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request.displayName = 'proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response.displayName = 'proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.PerformOnlineUpgrade, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.PerformOnlineUpgrade.displayName = 'proto.galaxycomplete.api.gm.PerformOnlineUpgrade';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request.displayName = 'proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response.displayName = 'proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.displayName = 'proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request.displayName = 'proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response.displayName = 'proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.displayName = 'proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.displayName = 'proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response.displayName = 'proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.TestAutoAllocConnection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.TestAutoAllocConnection.displayName = 'proto.galaxycomplete.api.gm.TestAutoAllocConnection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.displayName = 'proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response.displayName = 'proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.PrepareForAutoAlloc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.PrepareForAutoAlloc.displayName = 'proto.galaxycomplete.api.gm.PrepareForAutoAlloc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.displayName = 'proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response.displayName = 'proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.AutoAllocVolumes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.AutoAllocVolumes.displayName = 'proto.galaxycomplete.api.gm.AutoAllocVolumes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.AutoAllocVolumes.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.displayName = 'proto.galaxycomplete.api.gm.AutoAllocVolumes.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.AutoAllocVolumes.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.AutoAllocVolumes.Response.displayName = 'proto.galaxycomplete.api.gm.AutoAllocVolumes.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.TakeSnapshots = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.TakeSnapshots, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.TakeSnapshots.displayName = 'proto.galaxycomplete.api.gm.TakeSnapshots';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.TakeSnapshots.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.TakeSnapshots.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.TakeSnapshots.Request.displayName = 'proto.galaxycomplete.api.gm.TakeSnapshots.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.TakeSnapshots.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.TakeSnapshots.Response.displayName = 'proto.galaxycomplete.api.gm.TakeSnapshots.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListSnapshots = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListSnapshots, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListSnapshots.displayName = 'proto.galaxycomplete.api.gm.ListSnapshots';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListSnapshots.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListSnapshots.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListSnapshots.Request.displayName = 'proto.galaxycomplete.api.gm.ListSnapshots.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListSnapshots.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListSnapshots.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListSnapshots.Response.displayName = 'proto.galaxycomplete.api.gm.ListSnapshots.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.displayName = 'proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.displayName = 'proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response.displayName = 'proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.DeleteSnapshots = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.DeleteSnapshots, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.DeleteSnapshots.displayName = 'proto.galaxycomplete.api.gm.DeleteSnapshots';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.DeleteSnapshots.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.DeleteSnapshots.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.DeleteSnapshots.Request.displayName = 'proto.galaxycomplete.api.gm.DeleteSnapshots.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.DeleteSnapshots.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.DeleteSnapshots.Response.displayName = 'proto.galaxycomplete.api.gm.DeleteSnapshots.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.displayName = 'proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.displayName = 'proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response.displayName = 'proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListAutoAllocStatuses, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListAutoAllocStatuses.displayName = 'proto.galaxycomplete.api.gm.ListAutoAllocStatuses';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request.displayName = 'proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response.displayName = 'proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.displayName = 'proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request.displayName = 'proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response.displayName = 'proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetXRay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetXRay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetXRay.displayName = 'proto.galaxycomplete.api.gm.GetXRay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetXRay.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.GetXRay.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetXRay.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetXRay.Request.displayName = 'proto.galaxycomplete.api.gm.GetXRay.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetXRay.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetXRay.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetXRay.Response.displayName = 'proto.galaxycomplete.api.gm.GetXRay.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ViewLogs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ViewLogs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ViewLogs.displayName = 'proto.galaxycomplete.api.gm.ViewLogs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ViewLogs.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ViewLogs.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ViewLogs.Request.displayName = 'proto.galaxycomplete.api.gm.ViewLogs.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ViewLogs.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ViewLogs.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ViewLogs.Response.displayName = 'proto.galaxycomplete.api.gm.ViewLogs.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.RestartMTdiDaemon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.RestartMTdiDaemon.displayName = 'proto.galaxycomplete.api.gm.RestartMTdiDaemon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request.displayName = 'proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response.displayName = 'proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.displayName = 'proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.displayName = 'proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response.displayName = 'proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.displayName = 'proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request.displayName = 'proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response.displayName = 'proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.displayName = 'proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.displayName = 'proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response.displayName = 'proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.displayName = 'proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request.displayName = 'proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response.displayName = 'proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.displayName = 'proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.displayName = 'proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.displayName = 'proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetGalaxyMigrateLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.displayName = 'proto.galaxycomplete.api.gm.GetGalaxyMigrateLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request.displayName = 'proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response.displayName = 'proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.displayName = 'proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request.displayName = 'proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response.displayName = 'proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ConfigureCdcRelayServer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.displayName = 'proto.galaxycomplete.api.gm.ConfigureCdcRelayServer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.displayName = 'proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response.displayName = 'proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetCdcRelayServerStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.displayName = 'proto.galaxycomplete.api.gm.GetCdcRelayServerStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request.displayName = 'proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response.displayName = 'proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListAzureProducts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListAzureProducts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListAzureProducts.displayName = 'proto.galaxycomplete.api.gm.ListAzureProducts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListAzureProducts.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListAzureProducts.Request.displayName = 'proto.galaxycomplete.api.gm.ListAzureProducts.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.ListAzureProducts.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.ListAzureProducts.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.ListAzureProducts.Response.displayName = 'proto.galaxycomplete.api.gm.ListAzureProducts.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetAzureRecommendation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetAzureRecommendation.displayName = 'proto.galaxycomplete.api.gm.GetAzureRecommendation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetAzureRecommendation.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.displayName = 'proto.galaxycomplete.api.gm.GetAzureRecommendation.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetAzureRecommendation.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.displayName = 'proto.galaxycomplete.api.gm.GetAzureRecommendation.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.displayName = 'proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.displayName = 'proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetAzurePrice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetAzurePrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetAzurePrice.displayName = 'proto.galaxycomplete.api.gm.GetAzurePrice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetAzurePrice.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetAzurePrice.Request.displayName = 'proto.galaxycomplete.api.gm.GetAzurePrice.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.gm.GetAzurePrice.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.gm.GetAzurePrice.Response.displayName = 'proto.galaxycomplete.api.gm.GetAzurePrice.Response';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetIOStatsLive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetIOStatsLive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetIOStatsLive}
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetIOStatsLive;
  return proto.galaxycomplete.api.gm.GetIOStatsLive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetIOStatsLive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetIOStatsLive}
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetIOStatsLive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetIOStatsLive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetIOStatsLive.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetIOStatsLive.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetIOStatsLive.Request}
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetIOStatsLive.Request;
  return proto.galaxycomplete.api.gm.GetIOStatsLive.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetIOStatsLive.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetIOStatsLive.Request}
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetIOStatsLive.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetIOStatsLive.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetIOStatsLive.Request} returns this
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetIOStatsLive.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetIOStatsLive.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    volumeStatsMap: (f = msg.getVolumeStatsMap()) ? f.toObject(includeInstance, proto.galaxymigrate.IOStatsCollector.LiveDataPoint.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetIOStatsLive.Response}
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetIOStatsLive.Response;
  return proto.galaxycomplete.api.gm.GetIOStatsLive.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetIOStatsLive.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetIOStatsLive.Response}
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getVolumeStatsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.galaxymigrate.IOStatsCollector.LiveDataPoint.deserializeBinaryFromReader, "", new proto.galaxymigrate.IOStatsCollector.LiveDataPoint());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetIOStatsLive.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetIOStatsLive.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVolumeStatsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.galaxymigrate.IOStatsCollector.LiveDataPoint.serializeBinaryToWriter);
  }
};


/**
 * map<string, galaxymigrate.IOStatsCollector.LiveDataPoint> volume_stats = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.galaxymigrate.IOStatsCollector.LiveDataPoint>}
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Response.prototype.getVolumeStatsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.galaxymigrate.IOStatsCollector.LiveDataPoint>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.galaxymigrate.IOStatsCollector.LiveDataPoint));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.galaxycomplete.api.gm.GetIOStatsLive.Response} returns this
 */
proto.galaxycomplete.api.gm.GetIOStatsLive.Response.prototype.clearVolumeStatsMap = function() {
  this.getVolumeStatsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetIOStats.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetIOStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetIOStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetIOStats.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetIOStats}
 */
proto.galaxycomplete.api.gm.GetIOStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetIOStats;
  return proto.galaxycomplete.api.gm.GetIOStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetIOStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetIOStats}
 */
proto.galaxycomplete.api.gm.GetIOStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetIOStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetIOStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetIOStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetIOStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.GetIOStats.Request.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetIOStats.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetIOStats.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetIOStats.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetIOStats.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timeDomain: (f = msg.getTimeDomain()) && galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.TimeDomain.toObject(includeInstance, f),
    domainsList: jspb.Message.toObjectList(msg.getDomainsList(),
    galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.Domain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetIOStats.Request}
 */
proto.galaxycomplete.api.gm.GetIOStats.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetIOStats.Request;
  return proto.galaxycomplete.api.gm.GetIOStats.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetIOStats.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetIOStats.Request}
 */
proto.galaxycomplete.api.gm.GetIOStats.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = new galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.TimeDomain;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.TimeDomain.deserializeBinaryFromReader);
      msg.setTimeDomain(value);
      break;
    case 3:
      var value = new galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.Domain;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.Domain.deserializeBinaryFromReader);
      msg.addDomains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetIOStats.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetIOStats.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetIOStats.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetIOStats.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimeDomain();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.TimeDomain.serializeBinaryToWriter
    );
  }
  f = message.getDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.Domain.serializeBinaryToWriter
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetIOStats.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetIOStats.Request} returns this
 */
proto.galaxycomplete.api.gm.GetIOStats.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxymigrate.IOStatsDataSet.TimeDomain time_domain = 2;
 * @return {?proto.galaxymigrate.IOStatsDataSet.TimeDomain}
 */
proto.galaxycomplete.api.gm.GetIOStats.Request.prototype.getTimeDomain = function() {
  return /** @type{?proto.galaxymigrate.IOStatsDataSet.TimeDomain} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.TimeDomain, 2));
};


/**
 * @param {?proto.galaxymigrate.IOStatsDataSet.TimeDomain|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetIOStats.Request} returns this
*/
proto.galaxycomplete.api.gm.GetIOStats.Request.prototype.setTimeDomain = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetIOStats.Request} returns this
 */
proto.galaxycomplete.api.gm.GetIOStats.Request.prototype.clearTimeDomain = function() {
  return this.setTimeDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetIOStats.Request.prototype.hasTimeDomain = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated galaxymigrate.IOStatsDataSet.Domain domains = 3;
 * @return {!Array<!proto.galaxymigrate.IOStatsDataSet.Domain>}
 */
proto.galaxycomplete.api.gm.GetIOStats.Request.prototype.getDomainsList = function() {
  return /** @type{!Array<!proto.galaxymigrate.IOStatsDataSet.Domain>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.Domain, 3));
};


/**
 * @param {!Array<!proto.galaxymigrate.IOStatsDataSet.Domain>} value
 * @return {!proto.galaxycomplete.api.gm.GetIOStats.Request} returns this
*/
proto.galaxycomplete.api.gm.GetIOStats.Request.prototype.setDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.galaxymigrate.IOStatsDataSet.Domain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.IOStatsDataSet.Domain}
 */
proto.galaxycomplete.api.gm.GetIOStats.Request.prototype.addDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.galaxymigrate.IOStatsDataSet.Domain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.GetIOStats.Request} returns this
 */
proto.galaxycomplete.api.gm.GetIOStats.Request.prototype.clearDomainsList = function() {
  return this.setDomainsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetIOStats.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetIOStats.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetIOStats.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetIOStats.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetIOStats.Response}
 */
proto.galaxycomplete.api.gm.GetIOStats.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetIOStats.Response;
  return proto.galaxycomplete.api.gm.GetIOStats.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetIOStats.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetIOStats.Response}
 */
proto.galaxycomplete.api.gm.GetIOStats.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetIOStats.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetIOStats.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetIOStats.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetIOStats.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.IOStatsDataSet data = 1;
 * @return {?proto.galaxymigrate.IOStatsDataSet}
 */
proto.galaxycomplete.api.gm.GetIOStats.Response.prototype.getData = function() {
  return /** @type{?proto.galaxymigrate.IOStatsDataSet} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet, 1));
};


/**
 * @param {?proto.galaxymigrate.IOStatsDataSet|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetIOStats.Response} returns this
*/
proto.galaxycomplete.api.gm.GetIOStats.Response.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetIOStats.Response} returns this
 */
proto.galaxycomplete.api.gm.GetIOStats.Response.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetIOStats.Response.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist;
  return proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request;
  return proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request} returns this
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request} returns this
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Request.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    checklist: (f = msg.getChecklist()) && galaxycompletepb_apipb_domainpb_checklist_pb.ChecklistInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response;
  return proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_checklist_pb.ChecklistInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_checklist_pb.ChecklistInfo.deserializeBinaryFromReader);
      msg.setChecklist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChecklist();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_checklist_pb.ChecklistInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.ChecklistInfo checklist = 1;
 * @return {?proto.galaxycomplete.domain.ChecklistInfo}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response.prototype.getChecklist = function() {
  return /** @type{?proto.galaxycomplete.domain.ChecklistInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_checklist_pb.ChecklistInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.ChecklistInfo|undefined} value
 * @return {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response} returns this
*/
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response.prototype.setChecklist = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response} returns this
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response.prototype.clearChecklist = function() {
  return this.setChecklist(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.StartMigrationSessionCutOverChecklist.Response.prototype.hasChecklist = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists}
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists;
  return proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists}
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request}
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request;
  return proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request}
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request} returns this
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    checklistDescriptorsList: jspb.Message.toObjectList(msg.getChecklistDescriptorsList(),
    galaxycompletepb_apipb_domainpb_checklist_pb.ChecklistInfo.Descriptor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response}
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response;
  return proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response}
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_checklist_pb.ChecklistInfo.Descriptor;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_checklist_pb.ChecklistInfo.Descriptor.deserializeBinaryFromReader);
      msg.addChecklistDescriptors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChecklistDescriptorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_checklist_pb.ChecklistInfo.Descriptor.serializeBinaryToWriter
    );
  }
};


/**
 * repeated galaxycomplete.domain.ChecklistInfo.Descriptor checklist_descriptors = 1;
 * @return {!Array<!proto.galaxycomplete.domain.ChecklistInfo.Descriptor>}
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.prototype.getChecklistDescriptorsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.ChecklistInfo.Descriptor>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_checklist_pb.ChecklistInfo.Descriptor, 1));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.ChecklistInfo.Descriptor>} value
 * @return {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response} returns this
*/
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.prototype.setChecklistDescriptorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Descriptor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Descriptor}
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.prototype.addChecklistDescriptors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxycomplete.domain.ChecklistInfo.Descriptor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response} returns this
 */
proto.galaxycomplete.api.gm.GetEligibleMigrationSessionCutOverChecklists.Response.prototype.clearChecklistDescriptorsList = function() {
  return this.setChecklistDescriptorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper;
  return proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceSystemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sourceDeviceNamesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    helperId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    datastore: jspb.Message.getFieldWithDefault(msg, 11, ""),
    preferredFolderName: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request;
  return proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceSystemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSourceDeviceNames(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelperId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatastore(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreferredFolderName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSourceDeviceNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getHelperId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDatastore();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPreferredFolderName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string source_system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.prototype.getSourceSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request} returns this
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.prototype.setSourceSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string source_device_names = 2;
 * @return {!Array<string>}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.prototype.getSourceDeviceNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request} returns this
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.prototype.setSourceDeviceNamesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request} returns this
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.prototype.addSourceDeviceNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request} returns this
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.prototype.clearSourceDeviceNamesList = function() {
  return this.setSourceDeviceNamesList([]);
};


/**
 * optional string helper_id = 10;
 * @return {string}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.prototype.getHelperId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request} returns this
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.prototype.setHelperId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string datastore = 11;
 * @return {string}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.prototype.getDatastore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request} returns this
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.prototype.setDatastore = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string preferred_folder_name = 12;
 * @return {string}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.prototype.getPreferredFolderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request} returns this
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Request.prototype.setPreferredFolderName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    newDeviceNamesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response;
  return proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addNewDeviceNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewDeviceNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string new_device_names = 1;
 * @return {!Array<string>}
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.prototype.getNewDeviceNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response} returns this
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.prototype.setNewDeviceNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response} returns this
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.prototype.addNewDeviceNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response} returns this
 */
proto.galaxycomplete.api.gm.AllocateDisksToVmwareHelper.Response.prototype.clearNewDeviceNamesList = function() {
  return this.setNewDeviceNamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListVmwareHelpers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListVmwareHelpers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListVmwareHelpers}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListVmwareHelpers;
  return proto.galaxycomplete.api.gm.ListVmwareHelpers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListVmwareHelpers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListVmwareHelpers}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListVmwareHelpers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListVmwareHelpers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListVmwareHelpers.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListVmwareHelpers.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    onlyIfValidMigrationTargetFrom: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListVmwareHelpers.Request}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListVmwareHelpers.Request;
  return proto.galaxycomplete.api.gm.ListVmwareHelpers.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListVmwareHelpers.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListVmwareHelpers.Request}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnlyIfValidMigrationTargetFrom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListVmwareHelpers.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListVmwareHelpers.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOnlyIfValidMigrationTargetFrom();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ListVmwareHelpers.Request} returns this
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string only_if_valid_migration_target_from = 2;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Request.prototype.getOnlyIfValidMigrationTargetFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ListVmwareHelpers.Request} returns this
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Request.prototype.setOnlyIfValidMigrationTargetFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListVmwareHelpers.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    helpersList: jspb.Message.toObjectList(msg.getHelpersList(),
    galaxycompletepb_apipb_domainpb_compute_pb.GmHelperNodeInfo.Vmware.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListVmwareHelpers.Response}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListVmwareHelpers.Response;
  return proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListVmwareHelpers.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListVmwareHelpers.Response}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_compute_pb.GmHelperNodeInfo.Vmware;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_compute_pb.GmHelperNodeInfo.Vmware.deserializeBinaryFromReader);
      msg.addHelpers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListVmwareHelpers.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHelpersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_compute_pb.GmHelperNodeInfo.Vmware.serializeBinaryToWriter
    );
  }
};


/**
 * repeated galaxycomplete.domain.GmHelperNodeInfo.Vmware helpers = 1;
 * @return {!Array<!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware>}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.prototype.getHelpersList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_compute_pb.GmHelperNodeInfo.Vmware, 1));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware>} value
 * @return {!proto.galaxycomplete.api.gm.ListVmwareHelpers.Response} returns this
*/
proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.prototype.setHelpersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware}
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.prototype.addHelpers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.ListVmwareHelpers.Response} returns this
 */
proto.galaxycomplete.api.gm.ListVmwareHelpers.Response.prototype.clearHelpersList = function() {
  return this.setHelpersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ConfigureVmwareHelper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ConfigureVmwareHelper;
  return proto.galaxycomplete.api.gm.ConfigureVmwareHelper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ConfigureVmwareHelper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    connInfo: (f = msg.getConnInfo()) && galaxymigratepb_galaxy_migrate_compute_pb.VmwareCompute.ConnInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request;
  return proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = new galaxymigratepb_galaxy_migrate_compute_pb.VmwareCompute.ConnInfo;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_compute_pb.VmwareCompute.ConnInfo.deserializeBinaryFromReader);
      msg.setConnInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConnInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxymigratepb_galaxy_migrate_compute_pb.VmwareCompute.ConnInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request} returns this
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxymigrate.VmwareCompute.ConnInfo conn_info = 2;
 * @return {?proto.galaxymigrate.VmwareCompute.ConnInfo}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.prototype.getConnInfo = function() {
  return /** @type{?proto.galaxymigrate.VmwareCompute.ConnInfo} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_compute_pb.VmwareCompute.ConnInfo, 2));
};


/**
 * @param {?proto.galaxymigrate.VmwareCompute.ConnInfo|undefined} value
 * @return {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request} returns this
*/
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.prototype.setConnInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request} returns this
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.prototype.clearConnInfo = function() {
  return this.setConnInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Request.prototype.hasConnInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    helperInfo: (f = msg.getHelperInfo()) && galaxymigratepb_galaxy_migrate_compute_pb.VmwareCompute.Vm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response;
  return proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_compute_pb.VmwareCompute.Vm;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_compute_pb.VmwareCompute.Vm.deserializeBinaryFromReader);
      msg.setHelperInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHelperInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_compute_pb.VmwareCompute.Vm.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.VmwareCompute.Vm helper_info = 1;
 * @return {?proto.galaxymigrate.VmwareCompute.Vm}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response.prototype.getHelperInfo = function() {
  return /** @type{?proto.galaxymigrate.VmwareCompute.Vm} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_compute_pb.VmwareCompute.Vm, 1));
};


/**
 * @param {?proto.galaxymigrate.VmwareCompute.Vm|undefined} value
 * @return {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response} returns this
*/
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response.prototype.setHelperInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response} returns this
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response.prototype.clearHelperInfo = function() {
  return this.setHelperInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.ConfigureVmwareHelper.Response.prototype.hasHelperInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections}
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections;
  return proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections}
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    helperId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request}
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request;
  return proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request}
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelperId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHelperId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string helper_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request.prototype.getHelperId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request} returns this
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Request.prototype.setHelperId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    selections: (f = msg.getSelections()) && galaxymigratepb_galaxy_migrate_compute_pb.VmwareCompute.ListHelperResourcesSelectionsResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response}
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response;
  return proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response}
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_compute_pb.VmwareCompute.ListHelperResourcesSelectionsResponse;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_compute_pb.VmwareCompute.ListHelperResourcesSelectionsResponse.deserializeBinaryFromReader);
      msg.setSelections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelections();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_compute_pb.VmwareCompute.ListHelperResourcesSelectionsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse selections = 1;
 * @return {?proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse}
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response.prototype.getSelections = function() {
  return /** @type{?proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_compute_pb.VmwareCompute.ListHelperResourcesSelectionsResponse, 1));
};


/**
 * @param {?proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response} returns this
*/
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response.prototype.setSelections = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response} returns this
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response.prototype.clearSelections = function() {
  return this.setSelections(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetVmwareHelperResourceSelections.Response.prototype.hasSelections = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListAzureHelpers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListAzureHelpers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListAzureHelpers}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListAzureHelpers;
  return proto.galaxycomplete.api.gm.ListAzureHelpers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListAzureHelpers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListAzureHelpers}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListAzureHelpers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListAzureHelpers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListAzureHelpers.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListAzureHelpers.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    onlyIfValidMigrationTargetFrom: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListAzureHelpers.Request}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListAzureHelpers.Request;
  return proto.galaxycomplete.api.gm.ListAzureHelpers.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListAzureHelpers.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListAzureHelpers.Request}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnlyIfValidMigrationTargetFrom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListAzureHelpers.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListAzureHelpers.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOnlyIfValidMigrationTargetFrom();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ListAzureHelpers.Request} returns this
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string only_if_valid_migration_target_from = 2;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Request.prototype.getOnlyIfValidMigrationTargetFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ListAzureHelpers.Request} returns this
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Request.prototype.setOnlyIfValidMigrationTargetFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Response.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListAzureHelpers.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListAzureHelpers.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    helpersList: jspb.Message.toObjectList(msg.getHelpersList(),
    galaxycompletepb_apipb_domainpb_compute_pb.GmHelperNodeInfo.Azure.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListAzureHelpers.Response}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListAzureHelpers.Response;
  return proto.galaxycomplete.api.gm.ListAzureHelpers.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListAzureHelpers.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListAzureHelpers.Response}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_compute_pb.GmHelperNodeInfo.Azure;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_compute_pb.GmHelperNodeInfo.Azure.deserializeBinaryFromReader);
      msg.addHelpers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListAzureHelpers.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListAzureHelpers.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHelpersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_compute_pb.GmHelperNodeInfo.Azure.serializeBinaryToWriter
    );
  }
};


/**
 * repeated galaxycomplete.domain.GmHelperNodeInfo.Azure helpers = 1;
 * @return {!Array<!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure>}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Response.prototype.getHelpersList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_compute_pb.GmHelperNodeInfo.Azure, 1));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure>} value
 * @return {!proto.galaxycomplete.api.gm.ListAzureHelpers.Response} returns this
*/
proto.galaxycomplete.api.gm.ListAzureHelpers.Response.prototype.setHelpersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure}
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Response.prototype.addHelpers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxycomplete.domain.GmHelperNodeInfo.Azure, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.ListAzureHelpers.Response} returns this
 */
proto.galaxycomplete.api.gm.ListAzureHelpers.Response.prototype.clearHelpersList = function() {
  return this.setHelpersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ConfigureAzureHelper.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ConfigureAzureHelper} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ConfigureAzureHelper}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ConfigureAzureHelper;
  return proto.galaxycomplete.api.gm.ConfigureAzureHelper.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ConfigureAzureHelper} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ConfigureAzureHelper}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ConfigureAzureHelper.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ConfigureAzureHelper} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request;
  return proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntegrationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request} returns this
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 integration_id = 2;
 * @return {number}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request.prototype.getIntegrationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request} returns this
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Request.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    helperInfo: (f = msg.getHelperInfo()) && galaxycompletepb_apipb_domainpb_compute_pb.GmHelperNodeInfo.Azure.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response;
  return proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_compute_pb.GmHelperNodeInfo.Azure;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_compute_pb.GmHelperNodeInfo.Azure.deserializeBinaryFromReader);
      msg.setHelperInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHelperInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_compute_pb.GmHelperNodeInfo.Azure.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.GmHelperNodeInfo.Azure helper_info = 1;
 * @return {?proto.galaxycomplete.domain.GmHelperNodeInfo.Azure}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response.prototype.getHelperInfo = function() {
  return /** @type{?proto.galaxycomplete.domain.GmHelperNodeInfo.Azure} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_compute_pb.GmHelperNodeInfo.Azure, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.GmHelperNodeInfo.Azure|undefined} value
 * @return {!proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response} returns this
*/
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response.prototype.setHelperInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response} returns this
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response.prototype.clearHelperInfo = function() {
  return this.setHelperInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.ConfigureAzureHelper.Response.prototype.hasHelperInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections}
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections;
  return proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections}
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    helperId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request}
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request;
  return proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request}
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHelperId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHelperId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string helper_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request.prototype.getHelperId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Request.prototype.setHelperId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    selections: (f = msg.getSelections()) && galaxycompletepb_apipb_domainpb_compute_pb.ComputeResourceSelection.Azure.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response}
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response;
  return proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response}
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_compute_pb.ComputeResourceSelection.Azure;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_compute_pb.ComputeResourceSelection.Azure.deserializeBinaryFromReader);
      msg.setSelections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelections();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_compute_pb.ComputeResourceSelection.Azure.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.ComputeResourceSelection.Azure selections = 1;
 * @return {?proto.galaxycomplete.domain.ComputeResourceSelection.Azure}
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response.prototype.getSelections = function() {
  return /** @type{?proto.galaxycomplete.domain.ComputeResourceSelection.Azure} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_compute_pb.ComputeResourceSelection.Azure, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.ComputeResourceSelection.Azure|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response} returns this
*/
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response.prototype.setSelections = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response} returns this
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response.prototype.clearSelections = function() {
  return this.setSelections(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetAzureHelperResourceSelections.Response.prototype.hasSelections = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness;
  return proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    targetEnvironment: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request;
  return proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {!proto.galaxycomplete.common.HostEnvironment} */ (reader.readEnum());
      msg.setTargetEnvironment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTargetEnvironment();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request} returns this
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.common.HostEnvironment target_environment = 2;
 * @return {!proto.galaxycomplete.common.HostEnvironment}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request.prototype.getTargetEnvironment = function() {
  return /** @type {!proto.galaxycomplete.common.HostEnvironment} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.galaxycomplete.common.HostEnvironment} value
 * @return {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request} returns this
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Request.prototype.setTargetEnvironment = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    reason: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ready: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    unsupported: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response;
  return proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReady(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnsupported(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReady();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getUnsupported();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string reason = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response} returns this
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool ready = 2;
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.prototype.getReady = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response} returns this
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.prototype.setReady = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool unsupported = 3;
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.prototype.getUnsupported = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response} returns this
 */
proto.galaxycomplete.api.gm.GetComputeMigrationSourceReadiness.Response.prototype.setUnsupported = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration}
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration;
  return proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration}
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    targetEnvironment: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request}
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request;
  return proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request}
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {!proto.galaxycomplete.common.HostEnvironment} */ (reader.readEnum());
      msg.setTargetEnvironment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTargetEnvironment();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request} returns this
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.common.HostEnvironment target_environment = 2;
 * @return {!proto.galaxycomplete.common.HostEnvironment}
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request.prototype.getTargetEnvironment = function() {
  return /** @type {!proto.galaxycomplete.common.HostEnvironment} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.galaxycomplete.common.HostEnvironment} value
 * @return {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request} returns this
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Request.prototype.setTargetEnvironment = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response}
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response;
  return proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response}
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PrepareSourceForComputeMigration.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec;
  return proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    computeMigrationSpec: (f = msg.getComputeMigrationSpec()) && galaxycompletepb_apipb_domainpb_compute_pb.ComputeMigrationSpec.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request;
  return proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_compute_pb.ComputeMigrationSpec;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_compute_pb.ComputeMigrationSpec.deserializeBinaryFromReader);
      msg.setComputeMigrationSpec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComputeMigrationSpec();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_compute_pb.ComputeMigrationSpec.serializeBinaryToWriter
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request} returns this
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.domain.ComputeMigrationSpec compute_migration_spec = 2;
 * @return {?proto.galaxycomplete.domain.ComputeMigrationSpec}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.prototype.getComputeMigrationSpec = function() {
  return /** @type{?proto.galaxycomplete.domain.ComputeMigrationSpec} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_compute_pb.ComputeMigrationSpec, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.ComputeMigrationSpec|undefined} value
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request} returns this
*/
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.prototype.setComputeMigrationSpec = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request} returns this
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.prototype.clearComputeMigrationSpec = function() {
  return this.setComputeMigrationSpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Request.prototype.hasComputeMigrationSpec = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response;
  return proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateMigrationSessionComputeSpec.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetGMStorageConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetGMStorageConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetGMStorageConfig}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetGMStorageConfig;
  return proto.galaxycomplete.api.gm.GetGMStorageConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetGMStorageConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetGMStorageConfig}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetGMStorageConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetGMStorageConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetGMStorageConfig.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetGMStorageConfig.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includeLvm: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetGMStorageConfig.Request}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetGMStorageConfig.Request;
  return proto.galaxycomplete.api.gm.GetGMStorageConfig.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetGMStorageConfig.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetGMStorageConfig.Request}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeLvm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetGMStorageConfig.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetGMStorageConfig.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeLvm();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetGMStorageConfig.Request} returns this
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool include_lvm = 2;
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Request.prototype.getIncludeLvm = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.gm.GetGMStorageConfig.Request} returns this
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Request.prototype.setIncludeLvm = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetGMStorageConfig.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetGMStorageConfig.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    storageConfig: (f = msg.getStorageConfig()) && galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateStorageConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetGMStorageConfig.Response}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetGMStorageConfig.Response;
  return proto.galaxycomplete.api.gm.GetGMStorageConfig.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetGMStorageConfig.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetGMStorageConfig.Response}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateStorageConfig;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateStorageConfig.deserializeBinaryFromReader);
      msg.setStorageConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetGMStorageConfig.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetGMStorageConfig.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStorageConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateStorageConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.GalaxyMigrateStorageConfig storage_config = 1;
 * @return {?proto.galaxycomplete.domain.GalaxyMigrateStorageConfig}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Response.prototype.getStorageConfig = function() {
  return /** @type{?proto.galaxycomplete.domain.GalaxyMigrateStorageConfig} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateStorageConfig, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.GalaxyMigrateStorageConfig|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetGMStorageConfig.Response} returns this
*/
proto.galaxycomplete.api.gm.GetGMStorageConfig.Response.prototype.setStorageConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetGMStorageConfig.Response} returns this
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Response.prototype.clearStorageConfig = function() {
  return this.setStorageConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetGMStorageConfig.Response.prototype.hasStorageConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CreateMigrationSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CreateMigrationSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CreateMigrationSession;
  return proto.galaxycomplete.api.gm.CreateMigrationSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CreateMigrationSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CreateMigrationSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CreateMigrationSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.repeatedFields_ = [20,35,51];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CreateMigrationSession.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    autoResyncInterval: (f = msg.getAutoResyncInterval()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    qosLevel: jspb.Message.getFieldWithDefault(msg, 4, 0),
    volsList: jspb.Message.toObjectList(msg.getVolsList(),
    proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.toObject, includeInstance),
    destinationSystemId: jspb.Message.getFieldWithDefault(msg, 30, ""),
    remoteBootMigrationPostCutoverScript: jspb.Message.getFieldWithDefault(msg, 31, ""),
    postSyncWorkflowList: jspb.Message.toObjectList(msg.getPostSyncWorkflowList(),
    galaxycompletepb_apipb_domainpb_workflow_pb.WorkflowStage.toObject, includeInstance),
    computeMigrationSpec: (f = msg.getComputeMigrationSpec()) && galaxycompletepb_apipb_domainpb_compute_pb.ComputeMigrationSpec.toObject(includeInstance, f),
    qosSchedule: (f = msg.getQosSchedule()) && galaxymigratepb_galaxy_migrate_migration_pb.MigrationSession.ConfigParams.QosSchedule.toObject(includeInstance, f),
    qosStartTime: (f = msg.getQosStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 51)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CreateMigrationSession.Request;
  return proto.galaxycomplete.api.gm.CreateMigrationSession.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setAutoResyncInterval(value);
      break;
    case 4:
      var value = /** @type {!proto.galaxymigrate.SyncQos.ImpactLevel} */ (reader.readEnum());
      msg.setQosLevel(value);
      break;
    case 20:
      var value = new proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam;
      reader.readMessage(value,proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.deserializeBinaryFromReader);
      msg.addVols(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationSystemId(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemoteBootMigrationPostCutoverScript(value);
      break;
    case 35:
      var value = new galaxycompletepb_apipb_domainpb_workflow_pb.WorkflowStage;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_workflow_pb.WorkflowStage.deserializeBinaryFromReader);
      msg.addPostSyncWorkflow(value);
      break;
    case 40:
      var value = new galaxycompletepb_apipb_domainpb_compute_pb.ComputeMigrationSpec;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_compute_pb.ComputeMigrationSpec.deserializeBinaryFromReader);
      msg.setComputeMigrationSpec(value);
      break;
    case 41:
      var value = new galaxymigratepb_galaxy_migrate_migration_pb.MigrationSession.ConfigParams.QosSchedule;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_migration_pb.MigrationSession.ConfigParams.QosSchedule.deserializeBinaryFromReader);
      msg.setQosSchedule(value);
      break;
    case 42:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQosStartTime(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CreateMigrationSession.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAutoResyncInterval();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getQosLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getVolsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.serializeBinaryToWriter
    );
  }
  f = message.getDestinationSystemId();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getRemoteBootMigrationPostCutoverScript();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getPostSyncWorkflowList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      35,
      f,
      galaxycompletepb_apipb_domainpb_workflow_pb.WorkflowStage.serializeBinaryToWriter
    );
  }
  f = message.getComputeMigrationSpec();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      galaxycompletepb_apipb_domainpb_compute_pb.ComputeMigrationSpec.serializeBinaryToWriter
    );
  }
  f = message.getQosSchedule();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      galaxymigratepb_galaxy_migrate_migration_pb.MigrationSession.ConfigParams.QosSchedule.serializeBinaryToWriter
    );
  }
  f = message.getQosStartTime();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      51,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceDeviceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    destinationDeviceName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam;
  return proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceDeviceName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationDeviceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceDeviceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDestinationDeviceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string source_device_name = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.prototype.getSourceDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.prototype.setSourceDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string destination_device_name = 2;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.prototype.getDestinationDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam.prototype.setDestinationDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Duration auto_resync_interval = 3;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.getAutoResyncInterval = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 3));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
*/
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.setAutoResyncInterval = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.clearAutoResyncInterval = function() {
  return this.setAutoResyncInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.hasAutoResyncInterval = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional galaxymigrate.SyncQos.ImpactLevel qos_level = 4;
 * @return {!proto.galaxymigrate.SyncQos.ImpactLevel}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.getQosLevel = function() {
  return /** @type {!proto.galaxymigrate.SyncQos.ImpactLevel} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.galaxymigrate.SyncQos.ImpactLevel} value
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.setQosLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * repeated VolumeParam vols = 20;
 * @return {!Array<!proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam>}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.getVolsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam, 20));
};


/**
 * @param {!Array<!proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam>} value
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
*/
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.setVolsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.addVols = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.galaxycomplete.api.gm.CreateMigrationSession.Request.VolumeParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.clearVolsList = function() {
  return this.setVolsList([]);
};


/**
 * optional string destination_system_id = 30;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.getDestinationSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.setDestinationSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string remote_boot_migration_post_cutover_script = 31;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.getRemoteBootMigrationPostCutoverScript = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.setRemoteBootMigrationPostCutoverScript = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * repeated galaxycomplete.domain.WorkflowStage post_sync_workflow = 35;
 * @return {!Array<!proto.galaxycomplete.domain.WorkflowStage>}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.getPostSyncWorkflowList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.WorkflowStage>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_workflow_pb.WorkflowStage, 35));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.WorkflowStage>} value
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
*/
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.setPostSyncWorkflowList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 35, value);
};


/**
 * @param {!proto.galaxycomplete.domain.WorkflowStage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.WorkflowStage}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.addPostSyncWorkflow = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 35, opt_value, proto.galaxycomplete.domain.WorkflowStage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.clearPostSyncWorkflowList = function() {
  return this.setPostSyncWorkflowList([]);
};


/**
 * optional galaxycomplete.domain.ComputeMigrationSpec compute_migration_spec = 40;
 * @return {?proto.galaxycomplete.domain.ComputeMigrationSpec}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.getComputeMigrationSpec = function() {
  return /** @type{?proto.galaxycomplete.domain.ComputeMigrationSpec} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_compute_pb.ComputeMigrationSpec, 40));
};


/**
 * @param {?proto.galaxycomplete.domain.ComputeMigrationSpec|undefined} value
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
*/
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.setComputeMigrationSpec = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.clearComputeMigrationSpec = function() {
  return this.setComputeMigrationSpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.hasComputeMigrationSpec = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional galaxymigrate.MigrationSession.ConfigParams.QosSchedule qos_schedule = 41;
 * @return {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.getQosSchedule = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_migration_pb.MigrationSession.ConfigParams.QosSchedule, 41));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule|undefined} value
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
*/
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.setQosSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.clearQosSchedule = function() {
  return this.setQosSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.hasQosSchedule = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional google.protobuf.Timestamp qos_start_time = 42;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.getQosStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 42));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
*/
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.setQosStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.clearQosStartTime = function() {
  return this.setQosStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.hasQosStartTime = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * repeated string tags = 51;
 * @return {!Array<string>}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 51));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 51, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 51, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Request.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CreateMigrationSession.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CreateMigrationSession.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionUuid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CreateMigrationSession.Response;
  return proto.galaxycomplete.api.gm.CreateMigrationSession.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CreateMigrationSession.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CreateMigrationSession.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CreateMigrationSession.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_uuid = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Response.prototype.getSessionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CreateMigrationSession.Response} returns this
 */
proto.galaxycomplete.api.gm.CreateMigrationSession.Response.prototype.setSessionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListMigrationSessions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListMigrationSessions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationSessions}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListMigrationSessions;
  return proto.galaxycomplete.api.gm.ListMigrationSessions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListMigrationSessions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationSessions}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListMigrationSessions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListMigrationSessions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListMigrationSessions.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListMigrationSessions.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageParams: (f = msg.getPageParams()) && galaxycompletepb_commonpb_datafilter_pb.PagerParams.toObject(includeInstance, f),
    tag: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationSessions.Request}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListMigrationSessions.Request;
  return proto.galaxycomplete.api.gm.ListMigrationSessions.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListMigrationSessions.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationSessions.Request}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerParams;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerParams.deserializeBinaryFromReader);
      msg.setPageParams(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListMigrationSessions.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListMigrationSessions.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerParams.serializeBinaryToWriter
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ListMigrationSessions.Request} returns this
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.common.PagerParams page_params = 2;
 * @return {?proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Request.prototype.getPageParams = function() {
  return /** @type{?proto.galaxycomplete.common.PagerParams} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerParams, 2));
};


/**
 * @param {?proto.galaxycomplete.common.PagerParams|undefined} value
 * @return {!proto.galaxycomplete.api.gm.ListMigrationSessions.Request} returns this
*/
proto.galaxycomplete.api.gm.ListMigrationSessions.Request.prototype.setPageParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationSessions.Request} returns this
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Request.prototype.clearPageParams = function() {
  return this.setPageParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Request.prototype.hasPageParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string tag = 3;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Request.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ListMigrationSessions.Request} returns this
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Request.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListMigrationSessions.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListMigrationSessions.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerMeta: (f = msg.getPagerMeta()) && galaxycompletepb_commonpb_datafilter_pb.PagerMeta.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationSessionBasicInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationSessions.Response}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListMigrationSessions.Response;
  return proto.galaxycomplete.api.gm.ListMigrationSessions.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListMigrationSessions.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationSessions.Response}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerMeta;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerMeta.deserializeBinaryFromReader);
      msg.setPagerMeta(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationSessionBasicInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationSessionBasicInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListMigrationSessions.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListMigrationSessions.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerMeta.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationSessionBasicInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.common.PagerMeta pager_meta = 1;
 * @return {?proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Response.prototype.getPagerMeta = function() {
  return /** @type{?proto.galaxycomplete.common.PagerMeta} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerMeta, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerMeta|undefined} value
 * @return {!proto.galaxycomplete.api.gm.ListMigrationSessions.Response} returns this
*/
proto.galaxycomplete.api.gm.ListMigrationSessions.Response.prototype.setPagerMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationSessions.Response} returns this
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Response.prototype.clearPagerMeta = function() {
  return this.setPagerMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Response.prototype.hasPagerMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo items = 2;
 * @return {!Array<!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo>}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Response.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationSessionBasicInfo, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo>} value
 * @return {!proto.galaxycomplete.api.gm.ListMigrationSessions.Response} returns this
*/
proto.galaxycomplete.api.gm.ListMigrationSessions.Response.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo}
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Response.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationSessions.Response} returns this
 */
proto.galaxycomplete.api.gm.ListMigrationSessions.Response.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetMigrationSessionDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetMigrationSessionDetails;
  return proto.galaxycomplete.api.gm.GetMigrationSessionDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetMigrationSessionDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request;
  return proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationSessionDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response;
  return proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationSessionDetails;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationSessionDetails.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationSessionDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails session = 1;
 * @return {?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response.prototype.getSession = function() {
  return /** @type{?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationSessionDetails, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response} returns this
*/
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionDetails.Response.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.UpdateMigrationSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.UpdateMigrationSession;
  return proto.galaxycomplete.api.gm.UpdateMigrationSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.UpdateMigrationSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.repeatedFields_ = [20,40];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 10, ""),
    autoResyncInterval: (f = msg.getAutoResyncInterval()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    qosLevel: jspb.Message.getFieldWithDefault(msg, 12, 0),
    postSyncWorkflowList: jspb.Message.toObjectList(msg.getPostSyncWorkflowList(),
    galaxycompletepb_apipb_domainpb_workflow_pb.WorkflowStage.toObject, includeInstance),
    qosSchedule: (f = msg.getQosSchedule()) && galaxymigratepb_galaxy_migrate_migration_pb.MigrationSession.ConfigParams.QosSchedule.toObject(includeInstance, f),
    qosStartTime: (f = msg.getQosStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    remoteBootMigrationPostCutoverScript: jspb.Message.getFieldWithDefault(msg, 30, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 40)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.UpdateMigrationSession.Request;
  return proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 11:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setAutoResyncInterval(value);
      break;
    case 12:
      var value = /** @type {!proto.galaxymigrate.SyncQos.ImpactLevel} */ (reader.readEnum());
      msg.setQosLevel(value);
      break;
    case 20:
      var value = new galaxycompletepb_apipb_domainpb_workflow_pb.WorkflowStage;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_workflow_pb.WorkflowStage.deserializeBinaryFromReader);
      msg.addPostSyncWorkflow(value);
      break;
    case 21:
      var value = new galaxymigratepb_galaxy_migrate_migration_pb.MigrationSession.ConfigParams.QosSchedule;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_migration_pb.MigrationSession.ConfigParams.QosSchedule.deserializeBinaryFromReader);
      msg.setQosSchedule(value);
      break;
    case 22:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQosStartTime(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemoteBootMigrationPostCutoverScript(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAutoResyncInterval();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getQosLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getPostSyncWorkflowList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      galaxycompletepb_apipb_domainpb_workflow_pb.WorkflowStage.serializeBinaryToWriter
    );
  }
  f = message.getQosSchedule();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      galaxymigratepb_galaxy_migrate_migration_pb.MigrationSession.ConfigParams.QosSchedule.serializeBinaryToWriter
    );
  }
  f = message.getQosStartTime();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRemoteBootMigrationPostCutoverScript();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      40,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.protobuf.Duration auto_resync_interval = 11;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.getAutoResyncInterval = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 11));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} returns this
*/
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.setAutoResyncInterval = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.clearAutoResyncInterval = function() {
  return this.setAutoResyncInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.hasAutoResyncInterval = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional galaxymigrate.SyncQos.ImpactLevel qos_level = 12;
 * @return {!proto.galaxymigrate.SyncQos.ImpactLevel}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.getQosLevel = function() {
  return /** @type {!proto.galaxymigrate.SyncQos.ImpactLevel} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.galaxymigrate.SyncQos.ImpactLevel} value
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.setQosLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * repeated galaxycomplete.domain.WorkflowStage post_sync_workflow = 20;
 * @return {!Array<!proto.galaxycomplete.domain.WorkflowStage>}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.getPostSyncWorkflowList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.WorkflowStage>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_workflow_pb.WorkflowStage, 20));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.WorkflowStage>} value
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} returns this
*/
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.setPostSyncWorkflowList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.galaxycomplete.domain.WorkflowStage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.WorkflowStage}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.addPostSyncWorkflow = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.galaxycomplete.domain.WorkflowStage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.clearPostSyncWorkflowList = function() {
  return this.setPostSyncWorkflowList([]);
};


/**
 * optional galaxymigrate.MigrationSession.ConfigParams.QosSchedule qos_schedule = 21;
 * @return {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.getQosSchedule = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_migration_pb.MigrationSession.ConfigParams.QosSchedule, 21));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule|undefined} value
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} returns this
*/
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.setQosSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.clearQosSchedule = function() {
  return this.setQosSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.hasQosSchedule = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional google.protobuf.Timestamp qos_start_time = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.getQosStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} returns this
*/
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.setQosStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.clearQosStartTime = function() {
  return this.setQosStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.hasQosStartTime = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional string remote_boot_migration_post_cutover_script = 30;
 * @return {string}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.getRemoteBootMigrationPostCutoverScript = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.setRemoteBootMigrationPostCutoverScript = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * repeated string tags = 40;
 * @return {!Array<string>}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 40));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 40, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 40, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Request.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.UpdateMigrationSession.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.UpdateMigrationSession.Response;
  return proto.galaxycomplete.api.gm.UpdateMigrationSession.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.UpdateMigrationSession.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.UpdateMigrationSession.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateMigrationSession.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CancelMigrationSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CancelMigrationSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CancelMigrationSession}
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CancelMigrationSession;
  return proto.galaxycomplete.api.gm.CancelMigrationSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CancelMigrationSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CancelMigrationSession}
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CancelMigrationSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CancelMigrationSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CancelMigrationSession.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CancelMigrationSession.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CancelMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CancelMigrationSession.Request;
  return proto.galaxycomplete.api.gm.CancelMigrationSession.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CancelMigrationSession.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CancelMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CancelMigrationSession.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CancelMigrationSession.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CancelMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CancelMigrationSession.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CancelMigrationSession.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CancelMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CancelMigrationSession.Response;
  return proto.galaxycomplete.api.gm.CancelMigrationSession.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CancelMigrationSession.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CancelMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CancelMigrationSession.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CancelMigrationSession.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CancelMigrationSession.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.RestartMigrationSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.RestartMigrationSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.RestartMigrationSession}
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.RestartMigrationSession;
  return proto.galaxycomplete.api.gm.RestartMigrationSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.RestartMigrationSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.RestartMigrationSession}
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.RestartMigrationSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.RestartMigrationSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.RestartMigrationSession.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.RestartMigrationSession.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.RestartMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.RestartMigrationSession.Request;
  return proto.galaxycomplete.api.gm.RestartMigrationSession.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.RestartMigrationSession.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.RestartMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.RestartMigrationSession.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.RestartMigrationSession.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.RestartMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.RestartMigrationSession.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.RestartMigrationSession.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.RestartMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.RestartMigrationSession.Response;
  return proto.galaxycomplete.api.gm.RestartMigrationSession.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.RestartMigrationSession.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.RestartMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.RestartMigrationSession.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.RestartMigrationSession.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RestartMigrationSession.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.SuspendMigrationSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.SuspendMigrationSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.SuspendMigrationSession}
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.SuspendMigrationSession;
  return proto.galaxycomplete.api.gm.SuspendMigrationSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.SuspendMigrationSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.SuspendMigrationSession}
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.SuspendMigrationSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.SuspendMigrationSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.SuspendMigrationSession.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.SuspendMigrationSession.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.SuspendMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.SuspendMigrationSession.Request;
  return proto.galaxycomplete.api.gm.SuspendMigrationSession.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.SuspendMigrationSession.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.SuspendMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.SuspendMigrationSession.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.SuspendMigrationSession.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.SuspendMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.SuspendMigrationSession.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.SuspendMigrationSession.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.SuspendMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.SuspendMigrationSession.Response;
  return proto.galaxycomplete.api.gm.SuspendMigrationSession.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.SuspendMigrationSession.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.SuspendMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.SuspendMigrationSession.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.SuspendMigrationSession.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.SuspendMigrationSession.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.SyncMigrationSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.SyncMigrationSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.SyncMigrationSession}
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.SyncMigrationSession;
  return proto.galaxycomplete.api.gm.SyncMigrationSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.SyncMigrationSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.SyncMigrationSession}
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.SyncMigrationSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.SyncMigrationSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.SyncMigrationSession.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.SyncMigrationSession.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.SyncMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.SyncMigrationSession.Request;
  return proto.galaxycomplete.api.gm.SyncMigrationSession.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.SyncMigrationSession.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.SyncMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.SyncMigrationSession.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.SyncMigrationSession.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.SyncMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.SyncMigrationSession.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.SyncMigrationSession.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.SyncMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.SyncMigrationSession.Response;
  return proto.galaxycomplete.api.gm.SyncMigrationSession.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.SyncMigrationSession.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.SyncMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.SyncMigrationSession.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.SyncMigrationSession.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.SyncMigrationSession.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CutoverMigrationSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CutoverMigrationSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CutoverMigrationSession}
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CutoverMigrationSession;
  return proto.galaxycomplete.api.gm.CutoverMigrationSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CutoverMigrationSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CutoverMigrationSession}
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CutoverMigrationSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CutoverMigrationSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CutoverMigrationSession.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CutoverMigrationSession.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CutoverMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CutoverMigrationSession.Request;
  return proto.galaxycomplete.api.gm.CutoverMigrationSession.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CutoverMigrationSession.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CutoverMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CutoverMigrationSession.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CutoverMigrationSession.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CutoverMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CutoverMigrationSession.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CutoverMigrationSession.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CutoverMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CutoverMigrationSession.Response;
  return proto.galaxycomplete.api.gm.CutoverMigrationSession.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CutoverMigrationSession.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CutoverMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CutoverMigrationSession.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CutoverMigrationSession.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CutoverMigrationSession.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession}
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.FinalCutoverMigrationSession;
  return proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession}
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request;
  return proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response;
  return proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.FinalCutoverMigrationSession.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession}
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.RevertCutoverMigrationSession;
  return proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession}
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request;
  return proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response;
  return proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RevertCutoverMigrationSession.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.DeleteMigrationSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.DeleteMigrationSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.DeleteMigrationSession}
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.DeleteMigrationSession;
  return proto.galaxycomplete.api.gm.DeleteMigrationSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.DeleteMigrationSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.DeleteMigrationSession}
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.DeleteMigrationSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.DeleteMigrationSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.DeleteMigrationSession.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.DeleteMigrationSession.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.DeleteMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.DeleteMigrationSession.Request;
  return proto.galaxycomplete.api.gm.DeleteMigrationSession.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.DeleteMigrationSession.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.DeleteMigrationSession.Request}
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.DeleteMigrationSession.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.DeleteMigrationSession.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.DeleteMigrationSession.Request} returns this
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.DeleteMigrationSession.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.DeleteMigrationSession.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.DeleteMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.DeleteMigrationSession.Response;
  return proto.galaxycomplete.api.gm.DeleteMigrationSession.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.DeleteMigrationSession.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.DeleteMigrationSession.Response}
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.DeleteMigrationSession.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.DeleteMigrationSession.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.DeleteMigrationSession.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView;
  return proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    volUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    numberOfPoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fromBytes: jspb.Message.getFieldWithDefault(msg, 4, 0),
    toBytes: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request;
  return proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolUuid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfPoints(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromBytes(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setToBytes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVolUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNumberOfPoints();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getFromBytes();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getToBytes();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vol_uuid = 2;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.prototype.getVolUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.prototype.setVolUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 number_of_points = 3;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.prototype.getNumberOfPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.prototype.setNumberOfPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 from_bytes = 4;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.prototype.getFromBytes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.prototype.setFromBytes = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 to_bytes = 5;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.prototype.getToBytes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Request.prototype.setToBytes = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    numberOfPoints: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bytesPerPoint: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fromLocation: jspb.Message.getFieldWithDefault(msg, 3, 0),
    toLocation: jspb.Message.getFieldWithDefault(msg, 4, 0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pointsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response;
  return proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfPoints(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBytesPerPoint(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromLocation(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setToLocation(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPoints(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumberOfPoints();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBytesPerPoint();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getFromLocation();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getToLocation();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 number_of_points = 1;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.getNumberOfPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.setNumberOfPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 bytes_per_point = 2;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.getBytesPerPoint = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.setBytesPerPoint = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 from_location = 3;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.getFromLocation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.setFromLocation = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 to_location = 4;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.getToLocation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.setToLocation = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response} returns this
*/
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated uint32 points = 6;
 * @return {!Array<number>}
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.getPointsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.setPointsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.addPoints = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationVolumeChangesDistributionView.Response.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListMigrationEvents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListMigrationEvents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationEvents}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListMigrationEvents;
  return proto.galaxycomplete.api.gm.ListMigrationEvents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListMigrationEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationEvents}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListMigrationEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListMigrationEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListMigrationEvents.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListMigrationEvents.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    volUuidFilter: jspb.Message.getFieldWithDefault(msg, 2, ""),
    maxEvents: jspb.Message.getFieldWithDefault(msg, 10, 0),
    pageSequenceToken: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationEvents.Request}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListMigrationEvents.Request;
  return proto.galaxycomplete.api.gm.ListMigrationEvents.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListMigrationEvents.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationEvents.Request}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolUuidFilter(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxEvents(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPageSequenceToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListMigrationEvents.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListMigrationEvents.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMaxEvents();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getPageSequenceToken();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ListMigrationEvents.Request} returns this
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vol_uuid_filter = 2;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.prototype.getVolUuidFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ListMigrationEvents.Request} returns this
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.prototype.setVolUuidFilter = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationEvents.Request} returns this
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.prototype.clearVolUuidFilter = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.prototype.hasVolUuidFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 max_events = 10;
 * @return {number}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.prototype.getMaxEvents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.ListMigrationEvents.Request} returns this
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.prototype.setMaxEvents = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint64 page_sequence_token = 11;
 * @return {number}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.prototype.getPageSequenceToken = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.ListMigrationEvents.Request} returns this
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Request.prototype.setPageSequenceToken = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Response.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListMigrationEvents.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListMigrationEvents.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationEvents.Response}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListMigrationEvents.Response;
  return proto.galaxycomplete.api.gm.ListMigrationEvents.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListMigrationEvents.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationEvents.Response}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationEvent;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListMigrationEvents.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListMigrationEvents.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated galaxycomplete.domain.GalaxyMigrateMigrationEvent events = 1;
 * @return {!Array<!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent>}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Response.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationEvent, 1));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent>} value
 * @return {!proto.galaxycomplete.api.gm.ListMigrationEvents.Response} returns this
*/
proto.galaxycomplete.api.gm.ListMigrationEvents.Response.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent}
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Response.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.ListMigrationEvents.Response} returns this
 */
proto.galaxycomplete.api.gm.ListMigrationEvents.Response.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState;
  return proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    volUuid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request;
  return proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVolUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vol_uuid = 2;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request.prototype.getVolUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Request.prototype.setVolUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    qosStateResponse: (f = msg.getQosStateResponse()) && galaxymigratepb_galaxy_migrate_qos_pb.GetMigrationSessionVolumeQosStateResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response;
  return proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_qos_pb.GetMigrationSessionVolumeQosStateResponse;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_qos_pb.GetMigrationSessionVolumeQosStateResponse.deserializeBinaryFromReader);
      msg.setQosStateResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQosStateResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_qos_pb.GetMigrationSessionVolumeQosStateResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.GetMigrationSessionVolumeQosStateResponse qos_state_response = 1;
 * @return {?proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response.prototype.getQosStateResponse = function() {
  return /** @type{?proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_qos_pb.GetMigrationSessionVolumeQosStateResponse, 1));
};


/**
 * @param {?proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response} returns this
*/
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response.prototype.setQosStateResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response.prototype.clearQosStateResponse = function() {
  return this.setQosStateResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosState.Response.prototype.hasQosStateResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog;
  return proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    volUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    maxRecentPoints: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request;
  return proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolUuid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxRecentPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVolUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMaxRecentPoints();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vol_uuid = 2;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.prototype.getVolUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.prototype.setVolUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 max_recent_points = 3;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.prototype.getMaxRecentPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Request.prototype.setMaxRecentPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    galaxymigratepb_galaxy_migrate_qos_pb.SyncQos.RollingAverageLogEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response;
  return proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_qos_pb.SyncQos.RollingAverageLogEntry;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_qos_pb.SyncQos.RollingAverageLogEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_qos_pb.SyncQos.RollingAverageLogEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated galaxymigrate.SyncQos.RollingAverageLogEntry entries = 1;
 * @return {!Array<!proto.galaxymigrate.SyncQos.RollingAverageLogEntry>}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.SyncQos.RollingAverageLogEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxymigratepb_galaxy_migrate_qos_pb.SyncQos.RollingAverageLogEntry, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.SyncQos.RollingAverageLogEntry>} value
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response} returns this
*/
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry}
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.SyncQos.RollingAverageLogEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response} returns this
 */
proto.galaxycomplete.api.gm.GetMigrationSessionVolQosRollingAvgLog.Response.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade}
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CheckForOnlineUpgrade;
  return proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade}
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request}
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request;
  return proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request}
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request} returns this
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkResponse: (f = msg.getCheckResponse()) && galaxymigratepb_galaxy_migrate_support_pb.CheckForOnlineUpgradeResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response}
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response;
  return proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response}
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_support_pb.CheckForOnlineUpgradeResponse;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_support_pb.CheckForOnlineUpgradeResponse.deserializeBinaryFromReader);
      msg.setCheckResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_support_pb.CheckForOnlineUpgradeResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.CheckForOnlineUpgradeResponse check_response = 1;
 * @return {?proto.galaxymigrate.CheckForOnlineUpgradeResponse}
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response.prototype.getCheckResponse = function() {
  return /** @type{?proto.galaxymigrate.CheckForOnlineUpgradeResponse} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_support_pb.CheckForOnlineUpgradeResponse, 1));
};


/**
 * @param {?proto.galaxymigrate.CheckForOnlineUpgradeResponse|undefined} value
 * @return {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response} returns this
*/
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response.prototype.setCheckResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response} returns this
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response.prototype.clearCheckResponse = function() {
  return this.setCheckResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.CheckForOnlineUpgrade.Response.prototype.hasCheckResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.PerformOnlineUpgrade.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade}
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.PerformOnlineUpgrade;
  return proto.galaxycomplete.api.gm.PerformOnlineUpgrade.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade}
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.PerformOnlineUpgrade.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request}
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request;
  return proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request}
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request} returns this
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response}
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response;
  return proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response}
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PerformOnlineUpgrade.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus}
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus;
  return proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus}
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request}
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request;
  return proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request}
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request} returns this
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastOnlineUpgradeStatusResponse: (f = msg.getLastOnlineUpgradeStatusResponse()) && galaxymigratepb_galaxy_migrate_support_pb.GetLastOnlineUpgradeStatusResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response}
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response;
  return proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response}
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new galaxymigratepb_galaxy_migrate_support_pb.GetLastOnlineUpgradeStatusResponse;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_support_pb.GetLastOnlineUpgradeStatusResponse.deserializeBinaryFromReader);
      msg.setLastOnlineUpgradeStatusResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastOnlineUpgradeStatusResponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxymigratepb_galaxy_migrate_support_pb.GetLastOnlineUpgradeStatusResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.GetLastOnlineUpgradeStatusResponse last_online_upgrade_status_response = 2;
 * @return {?proto.galaxymigrate.GetLastOnlineUpgradeStatusResponse}
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response.prototype.getLastOnlineUpgradeStatusResponse = function() {
  return /** @type{?proto.galaxymigrate.GetLastOnlineUpgradeStatusResponse} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_support_pb.GetLastOnlineUpgradeStatusResponse, 2));
};


/**
 * @param {?proto.galaxymigrate.GetLastOnlineUpgradeStatusResponse|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response} returns this
*/
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response.prototype.setLastOnlineUpgradeStatusResponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response} returns this
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response.prototype.clearLastOnlineUpgradeStatusResponse = function() {
  return this.setLastOnlineUpgradeStatusResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetLastOnlineUpgradeStatus.Response.prototype.hasLastOnlineUpgradeStatusResponse = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc;
  return proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    statusId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request;
  return proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntegrationId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getStatusId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request} returns this
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 integration_id = 3;
 * @return {number}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.prototype.getIntegrationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request} returns this
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string status_id = 4;
 * @return {string}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.prototype.getStatusId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request} returns this
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Request.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    readinessResponse: (f = msg.getReadinessResponse()) && galaxymigratepb_galaxy_migrate_autoalloc_pb.IsHostReadyForAllocationResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response;
  return proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_autoalloc_pb.IsHostReadyForAllocationResponse;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_autoalloc_pb.IsHostReadyForAllocationResponse.deserializeBinaryFromReader);
      msg.setReadinessResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReadinessResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_autoalloc_pb.IsHostReadyForAllocationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.IsHostReadyForAllocationResponse readiness_response = 1;
 * @return {?proto.galaxymigrate.IsHostReadyForAllocationResponse}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response.prototype.getReadinessResponse = function() {
  return /** @type{?proto.galaxymigrate.IsHostReadyForAllocationResponse} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_autoalloc_pb.IsHostReadyForAllocationResponse, 1));
};


/**
 * @param {?proto.galaxymigrate.IsHostReadyForAllocationResponse|undefined} value
 * @return {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response} returns this
*/
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response.prototype.setReadinessResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response} returns this
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response.prototype.clearReadinessResponse = function() {
  return this.setReadinessResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.IsPreparedForAutoAlloc.Response.prototype.hasReadinessResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.TestAutoAllocConnection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.TestAutoAllocConnection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.TestAutoAllocConnection}
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.TestAutoAllocConnection;
  return proto.galaxycomplete.api.gm.TestAutoAllocConnection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.TestAutoAllocConnection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.TestAutoAllocConnection}
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.TestAutoAllocConnection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.TestAutoAllocConnection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    statusId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request}
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request;
  return proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request}
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntegrationId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getStatusId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request} returns this
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 integration_id = 3;
 * @return {number}
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.prototype.getIntegrationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request} returns this
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string status_id = 4;
 * @return {string}
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.prototype.getStatusId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request} returns this
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Request.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response}
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response;
  return proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response}
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.TestAutoAllocConnection.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.PrepareForAutoAlloc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.PrepareForAutoAlloc;
  return proto.galaxycomplete.api.gm.PrepareForAutoAlloc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.PrepareForAutoAlloc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    statusId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request;
  return proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntegrationId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getStatusId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request} returns this
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 integration_id = 3;
 * @return {number}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.prototype.getIntegrationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request} returns this
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string status_id = 4;
 * @return {string}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.prototype.getStatusId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request} returns this
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Request.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    prepareResponse: (f = msg.getPrepareResponse()) && galaxymigratepb_galaxy_migrate_autoalloc_pb.PrepareForAllocationResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response;
  return proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_autoalloc_pb.PrepareForAllocationResponse;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_autoalloc_pb.PrepareForAllocationResponse.deserializeBinaryFromReader);
      msg.setPrepareResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrepareResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_autoalloc_pb.PrepareForAllocationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.PrepareForAllocationResponse prepare_response = 1;
 * @return {?proto.galaxymigrate.PrepareForAllocationResponse}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response.prototype.getPrepareResponse = function() {
  return /** @type{?proto.galaxymigrate.PrepareForAllocationResponse} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_autoalloc_pb.PrepareForAllocationResponse, 1));
};


/**
 * @param {?proto.galaxymigrate.PrepareForAllocationResponse|undefined} value
 * @return {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response} returns this
*/
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response.prototype.setPrepareResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response} returns this
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response.prototype.clearPrepareResponse = function() {
  return this.setPrepareResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.PrepareForAutoAlloc.Response.prototype.hasPrepareResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.AutoAllocVolumes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.AutoAllocVolumes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.AutoAllocVolumes}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.AutoAllocVolumes;
  return proto.galaxycomplete.api.gm.AutoAllocVolumes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.AutoAllocVolumes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.AutoAllocVolumes}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.AutoAllocVolumes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.AutoAllocVolumes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    volumesList: jspb.Message.toObjectList(msg.getVolumesList(),
    galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateDestinationVolumesRequest.Volume.toObject, includeInstance),
    statusId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Request}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.AutoAllocVolumes.Request;
  return proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Request}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntegrationId(value);
      break;
    case 4:
      var value = new galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateDestinationVolumesRequest.Volume;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateDestinationVolumesRequest.Volume.deserializeBinaryFromReader);
      msg.addVolumes(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateDestinationVolumesRequest.Volume.serializeBinaryToWriter
    );
  }
  f = message.getStatusId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Request} returns this
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 integration_id = 3;
 * @return {number}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.prototype.getIntegrationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Request} returns this
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated galaxymigrate.AllocateDestinationVolumesRequest.Volume volumes = 4;
 * @return {!Array<!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume>}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.prototype.getVolumesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateDestinationVolumesRequest.Volume, 4));
};


/**
 * @param {!Array<!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume>} value
 * @return {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Request} returns this
*/
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.prototype.setVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.prototype.addVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Request} returns this
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.prototype.clearVolumesList = function() {
  return this.setVolumesList([]);
};


/**
 * optional string status_id = 5;
 * @return {string}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.prototype.getStatusId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Request} returns this
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Request.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.AutoAllocVolumes.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocateResponse: (f = msg.getAllocateResponse()) && galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateDestinationVolumesResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Response}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.AutoAllocVolumes.Response;
  return proto.galaxycomplete.api.gm.AutoAllocVolumes.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Response}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateDestinationVolumesResponse;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateDestinationVolumesResponse.deserializeBinaryFromReader);
      msg.setAllocateResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.AutoAllocVolumes.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocateResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateDestinationVolumesResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.AllocateDestinationVolumesResponse allocate_response = 1;
 * @return {?proto.galaxymigrate.AllocateDestinationVolumesResponse}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Response.prototype.getAllocateResponse = function() {
  return /** @type{?proto.galaxymigrate.AllocateDestinationVolumesResponse} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateDestinationVolumesResponse, 1));
};


/**
 * @param {?proto.galaxymigrate.AllocateDestinationVolumesResponse|undefined} value
 * @return {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Response} returns this
*/
proto.galaxycomplete.api.gm.AutoAllocVolumes.Response.prototype.setAllocateResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.AutoAllocVolumes.Response} returns this
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Response.prototype.clearAllocateResponse = function() {
  return this.setAllocateResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.AutoAllocVolumes.Response.prototype.hasAllocateResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.TakeSnapshots.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.TakeSnapshots} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.TakeSnapshots.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.TakeSnapshots}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.TakeSnapshots;
  return proto.galaxycomplete.api.gm.TakeSnapshots.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.TakeSnapshots} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.TakeSnapshots}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.TakeSnapshots.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.TakeSnapshots} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.TakeSnapshots.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.TakeSnapshots.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.TakeSnapshots.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    volumesList: jspb.Message.toObjectList(msg.getVolumesList(),
    galaxymigratepb_galaxy_migrate_autoalloc_pb.TakeSnapshotsRequest.Volume.toObject, includeInstance),
    statusId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.TakeSnapshots.Request}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.TakeSnapshots.Request;
  return proto.galaxycomplete.api.gm.TakeSnapshots.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.TakeSnapshots.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.TakeSnapshots.Request}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntegrationId(value);
      break;
    case 4:
      var value = new galaxymigratepb_galaxy_migrate_autoalloc_pb.TakeSnapshotsRequest.Volume;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_autoalloc_pb.TakeSnapshotsRequest.Volume.deserializeBinaryFromReader);
      msg.addVolumes(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.TakeSnapshots.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.TakeSnapshots.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      galaxymigratepb_galaxy_migrate_autoalloc_pb.TakeSnapshotsRequest.Volume.serializeBinaryToWriter
    );
  }
  f = message.getStatusId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.TakeSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 integration_id = 3;
 * @return {number}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.prototype.getIntegrationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.TakeSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated galaxymigrate.TakeSnapshotsRequest.Volume volumes = 4;
 * @return {!Array<!proto.galaxymigrate.TakeSnapshotsRequest.Volume>}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.prototype.getVolumesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.TakeSnapshotsRequest.Volume>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxymigratepb_galaxy_migrate_autoalloc_pb.TakeSnapshotsRequest.Volume, 4));
};


/**
 * @param {!Array<!proto.galaxymigrate.TakeSnapshotsRequest.Volume>} value
 * @return {!proto.galaxycomplete.api.gm.TakeSnapshots.Request} returns this
*/
proto.galaxycomplete.api.gm.TakeSnapshots.Request.prototype.setVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.galaxymigrate.TakeSnapshotsRequest.Volume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.TakeSnapshotsRequest.Volume}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.prototype.addVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.galaxymigrate.TakeSnapshotsRequest.Volume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.TakeSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.prototype.clearVolumesList = function() {
  return this.setVolumesList([]);
};


/**
 * optional string status_id = 5;
 * @return {string}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.prototype.getStatusId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.TakeSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Request.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.TakeSnapshots.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.TakeSnapshots.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    snapshotResponse: (f = msg.getSnapshotResponse()) && galaxymigratepb_galaxy_migrate_autoalloc_pb.TakeSnapshotsResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.TakeSnapshots.Response}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.TakeSnapshots.Response;
  return proto.galaxycomplete.api.gm.TakeSnapshots.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.TakeSnapshots.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.TakeSnapshots.Response}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_autoalloc_pb.TakeSnapshotsResponse;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_autoalloc_pb.TakeSnapshotsResponse.deserializeBinaryFromReader);
      msg.setSnapshotResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.TakeSnapshots.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.TakeSnapshots.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSnapshotResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_autoalloc_pb.TakeSnapshotsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.TakeSnapshotsResponse snapshot_response = 1;
 * @return {?proto.galaxymigrate.TakeSnapshotsResponse}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Response.prototype.getSnapshotResponse = function() {
  return /** @type{?proto.galaxymigrate.TakeSnapshotsResponse} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_autoalloc_pb.TakeSnapshotsResponse, 1));
};


/**
 * @param {?proto.galaxymigrate.TakeSnapshotsResponse|undefined} value
 * @return {!proto.galaxycomplete.api.gm.TakeSnapshots.Response} returns this
*/
proto.galaxycomplete.api.gm.TakeSnapshots.Response.prototype.setSnapshotResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.TakeSnapshots.Response} returns this
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Response.prototype.clearSnapshotResponse = function() {
  return this.setSnapshotResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.TakeSnapshots.Response.prototype.hasSnapshotResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListSnapshots.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListSnapshots.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListSnapshots} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListSnapshots.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListSnapshots}
 */
proto.galaxycomplete.api.gm.ListSnapshots.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListSnapshots;
  return proto.galaxycomplete.api.gm.ListSnapshots.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListSnapshots} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListSnapshots}
 */
proto.galaxycomplete.api.gm.ListSnapshots.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListSnapshots.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListSnapshots.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListSnapshots} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListSnapshots.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListSnapshots.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListSnapshots.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListSnapshots.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListSnapshots.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    devicePath: jspb.Message.getFieldWithDefault(msg, 3, ""),
    statusId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListSnapshots.Request}
 */
proto.galaxycomplete.api.gm.ListSnapshots.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListSnapshots.Request;
  return proto.galaxycomplete.api.gm.ListSnapshots.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListSnapshots.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListSnapshots.Request}
 */
proto.galaxycomplete.api.gm.ListSnapshots.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntegrationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListSnapshots.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListSnapshots.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListSnapshots.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListSnapshots.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatusId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ListSnapshots.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ListSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.ListSnapshots.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 integration_id = 2;
 * @return {number}
 */
proto.galaxycomplete.api.gm.ListSnapshots.Request.prototype.getIntegrationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.ListSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.ListSnapshots.Request.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string device_path = 3;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ListSnapshots.Request.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ListSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.ListSnapshots.Request.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status_id = 4;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ListSnapshots.Request.prototype.getStatusId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ListSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.ListSnapshots.Request.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListSnapshots.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListSnapshots.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListSnapshots.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListSnapshots.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    listsnapshotsResponse: (f = msg.getListsnapshotsResponse()) && galaxymigratepb_galaxy_migrate_autoalloc_pb.ListSnapshotsResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListSnapshots.Response}
 */
proto.galaxycomplete.api.gm.ListSnapshots.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListSnapshots.Response;
  return proto.galaxycomplete.api.gm.ListSnapshots.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListSnapshots.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListSnapshots.Response}
 */
proto.galaxycomplete.api.gm.ListSnapshots.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_autoalloc_pb.ListSnapshotsResponse;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_autoalloc_pb.ListSnapshotsResponse.deserializeBinaryFromReader);
      msg.setListsnapshotsResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListSnapshots.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListSnapshots.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListSnapshots.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListSnapshots.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListsnapshotsResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_autoalloc_pb.ListSnapshotsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.ListSnapshotsResponse listsnapshots_response = 1;
 * @return {?proto.galaxymigrate.ListSnapshotsResponse}
 */
proto.galaxycomplete.api.gm.ListSnapshots.Response.prototype.getListsnapshotsResponse = function() {
  return /** @type{?proto.galaxymigrate.ListSnapshotsResponse} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_autoalloc_pb.ListSnapshotsResponse, 1));
};


/**
 * @param {?proto.galaxymigrate.ListSnapshotsResponse|undefined} value
 * @return {!proto.galaxycomplete.api.gm.ListSnapshots.Response} returns this
*/
proto.galaxycomplete.api.gm.ListSnapshots.Response.prototype.setListsnapshotsResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.ListSnapshots.Response} returns this
 */
proto.galaxycomplete.api.gm.ListSnapshots.Response.prototype.clearListsnapshotsResponse = function() {
  return this.setListsnapshotsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.ListSnapshots.Response.prototype.hasListsnapshotsResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots;
  return proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    volumesList: jspb.Message.toObjectList(msg.getVolumesList(),
    galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateVolumesFromSnapshotsRequest.Volume.toObject, includeInstance),
    statusId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request;
  return proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntegrationId(value);
      break;
    case 4:
      var value = new galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateVolumesFromSnapshotsRequest.Volume;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateVolumesFromSnapshotsRequest.Volume.deserializeBinaryFromReader);
      msg.addVolumes(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateVolumesFromSnapshotsRequest.Volume.serializeBinaryToWriter
    );
  }
  f = message.getStatusId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 integration_id = 3;
 * @return {number}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.prototype.getIntegrationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume volumes = 4;
 * @return {!Array<!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume>}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.prototype.getVolumesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateVolumesFromSnapshotsRequest.Volume, 4));
};


/**
 * @param {!Array<!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume>} value
 * @return {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request} returns this
*/
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.prototype.setVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.prototype.addVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.prototype.clearVolumesList = function() {
  return this.setVolumesList([]);
};


/**
 * optional string status_id = 5;
 * @return {string}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.prototype.getStatusId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Request.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocateSnapshotResponse: (f = msg.getAllocateSnapshotResponse()) && galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateVolumesFromSnapshotsResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response;
  return proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateVolumesFromSnapshotsResponse;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateVolumesFromSnapshotsResponse.deserializeBinaryFromReader);
      msg.setAllocateSnapshotResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocateSnapshotResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateVolumesFromSnapshotsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.AllocateVolumesFromSnapshotsResponse allocate_snapshot_response = 1;
 * @return {?proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response.prototype.getAllocateSnapshotResponse = function() {
  return /** @type{?proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_autoalloc_pb.AllocateVolumesFromSnapshotsResponse, 1));
};


/**
 * @param {?proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse|undefined} value
 * @return {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response} returns this
*/
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response.prototype.setAllocateSnapshotResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response} returns this
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response.prototype.clearAllocateSnapshotResponse = function() {
  return this.setAllocateSnapshotResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.AllocateVolumesFromSnapshots.Response.prototype.hasAllocateSnapshotResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.DeleteSnapshots.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.DeleteSnapshots} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.DeleteSnapshots}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.DeleteSnapshots;
  return proto.galaxycomplete.api.gm.DeleteSnapshots.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.DeleteSnapshots} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.DeleteSnapshots}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.DeleteSnapshots.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.DeleteSnapshots} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.DeleteSnapshots.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.DeleteSnapshots.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    snapshotIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    statusId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.DeleteSnapshots.Request}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.DeleteSnapshots.Request;
  return proto.galaxycomplete.api.gm.DeleteSnapshots.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.DeleteSnapshots.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.DeleteSnapshots.Request}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntegrationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSnapshotIds(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.DeleteSnapshots.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.DeleteSnapshots.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSnapshotIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getStatusId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.DeleteSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 integration_id = 2;
 * @return {number}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.prototype.getIntegrationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.DeleteSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string snapshot_ids = 3;
 * @return {!Array<string>}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.prototype.getSnapshotIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.api.gm.DeleteSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.prototype.setSnapshotIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.gm.DeleteSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.prototype.addSnapshotIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.DeleteSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.prototype.clearSnapshotIdsList = function() {
  return this.setSnapshotIdsList([]);
};


/**
 * optional string status_id = 4;
 * @return {string}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.prototype.getStatusId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.DeleteSnapshots.Request} returns this
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Request.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.DeleteSnapshots.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.DeleteSnapshots.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    deletesnapshotsResponse: (f = msg.getDeletesnapshotsResponse()) && galaxymigratepb_galaxy_migrate_autoalloc_pb.DeleteSnapshotsResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.DeleteSnapshots.Response}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.DeleteSnapshots.Response;
  return proto.galaxycomplete.api.gm.DeleteSnapshots.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.DeleteSnapshots.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.DeleteSnapshots.Response}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_autoalloc_pb.DeleteSnapshotsResponse;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_autoalloc_pb.DeleteSnapshotsResponse.deserializeBinaryFromReader);
      msg.setDeletesnapshotsResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.DeleteSnapshots.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.DeleteSnapshots.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeletesnapshotsResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_autoalloc_pb.DeleteSnapshotsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.DeleteSnapshotsResponse deletesnapshots_response = 1;
 * @return {?proto.galaxymigrate.DeleteSnapshotsResponse}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Response.prototype.getDeletesnapshotsResponse = function() {
  return /** @type{?proto.galaxymigrate.DeleteSnapshotsResponse} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_autoalloc_pb.DeleteSnapshotsResponse, 1));
};


/**
 * @param {?proto.galaxymigrate.DeleteSnapshotsResponse|undefined} value
 * @return {!proto.galaxycomplete.api.gm.DeleteSnapshots.Response} returns this
*/
proto.galaxycomplete.api.gm.DeleteSnapshots.Response.prototype.setDeletesnapshotsResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.DeleteSnapshots.Response} returns this
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Response.prototype.clearDeletesnapshotsResponse = function() {
  return this.setDeletesnapshotsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.DeleteSnapshots.Response.prototype.hasDeletesnapshotsResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot;
  return proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    targetId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    integrationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    devicePath: jspb.Message.getFieldWithDefault(msg, 4, ""),
    snapshotId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    statusId: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request;
  return proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntegrationId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnapshotId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTargetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIntegrationId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSnapshotId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatusId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request} returns this
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string target_id = 2;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.getTargetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request} returns this
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.setTargetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 integration_id = 3;
 * @return {number}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.getIntegrationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request} returns this
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string device_path = 4;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request} returns this
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string snapshot_id = 5;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.getSnapshotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request} returns this
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.setSnapshotId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request} returns this
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string status_id = 20;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.getStatusId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request} returns this
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Request.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicePath: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response;
  return proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_path = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response} returns this
 */
proto.galaxycomplete.api.gm.CloneMigrationSessionSnapshot.Response.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListAutoAllocStatuses.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses}
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListAutoAllocStatuses;
  return proto.galaxycomplete.api.gm.ListAutoAllocStatuses.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses}
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListAutoAllocStatuses.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request}
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request;
  return proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request}
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request} returns this
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusesResponse: (f = msg.getStatusesResponse()) && galaxymigratepb_galaxy_migrate_autoalloc_pb.ListAutoAllocStatusesResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response}
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response;
  return proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response}
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_autoalloc_pb.ListAutoAllocStatusesResponse;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_autoalloc_pb.ListAutoAllocStatusesResponse.deserializeBinaryFromReader);
      msg.setStatusesResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusesResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_autoalloc_pb.ListAutoAllocStatusesResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.ListAutoAllocStatusesResponse statuses_response = 1;
 * @return {?proto.galaxymigrate.ListAutoAllocStatusesResponse}
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response.prototype.getStatusesResponse = function() {
  return /** @type{?proto.galaxymigrate.ListAutoAllocStatusesResponse} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_autoalloc_pb.ListAutoAllocStatusesResponse, 1));
};


/**
 * @param {?proto.galaxymigrate.ListAutoAllocStatusesResponse|undefined} value
 * @return {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response} returns this
*/
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response.prototype.setStatusesResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response} returns this
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response.prototype.clearStatusesResponse = function() {
  return this.setStatusesResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.ListAutoAllocStatuses.Response.prototype.hasStatusesResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails;
  return proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statusId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request;
  return proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatusId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status_id = 2;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request.prototype.getStatusId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Request.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    detailsResponse: (f = msg.getDetailsResponse()) && galaxymigratepb_galaxy_migrate_autoalloc_pb.GetAutoAllocStatusDetailsResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response;
  return proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_autoalloc_pb.GetAutoAllocStatusDetailsResponse;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_autoalloc_pb.GetAutoAllocStatusDetailsResponse.deserializeBinaryFromReader);
      msg.setDetailsResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetailsResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_autoalloc_pb.GetAutoAllocStatusDetailsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.GetAutoAllocStatusDetailsResponse details_response = 1;
 * @return {?proto.galaxymigrate.GetAutoAllocStatusDetailsResponse}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response.prototype.getDetailsResponse = function() {
  return /** @type{?proto.galaxymigrate.GetAutoAllocStatusDetailsResponse} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_autoalloc_pb.GetAutoAllocStatusDetailsResponse, 1));
};


/**
 * @param {?proto.galaxymigrate.GetAutoAllocStatusDetailsResponse|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response} returns this
*/
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response.prototype.setDetailsResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response} returns this
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response.prototype.clearDetailsResponse = function() {
  return this.setDetailsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetAutoAllocStatusDetails.Response.prototype.hasDetailsResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetXRay.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetXRay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetXRay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetXRay.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetXRay}
 */
proto.galaxycomplete.api.gm.GetXRay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetXRay;
  return proto.galaxycomplete.api.gm.GetXRay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetXRay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetXRay}
 */
proto.galaxycomplete.api.gm.GetXRay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetXRay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetXRay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetXRay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetXRay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.GetXRay.Request.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetXRay.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetXRay.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetXRay.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetXRay.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    argumentsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetXRay.Request}
 */
proto.galaxycomplete.api.gm.GetXRay.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetXRay.Request;
  return proto.galaxycomplete.api.gm.GetXRay.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetXRay.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetXRay.Request}
 */
proto.galaxycomplete.api.gm.GetXRay.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addArguments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetXRay.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetXRay.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetXRay.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetXRay.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getArgumentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetXRay.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetXRay.Request} returns this
 */
proto.galaxycomplete.api.gm.GetXRay.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string arguments = 2;
 * @return {!Array<string>}
 */
proto.galaxycomplete.api.gm.GetXRay.Request.prototype.getArgumentsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.api.gm.GetXRay.Request} returns this
 */
proto.galaxycomplete.api.gm.GetXRay.Request.prototype.setArgumentsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.gm.GetXRay.Request} returns this
 */
proto.galaxycomplete.api.gm.GetXRay.Request.prototype.addArguments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.GetXRay.Request} returns this
 */
proto.galaxycomplete.api.gm.GetXRay.Request.prototype.clearArgumentsList = function() {
  return this.setArgumentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetXRay.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetXRay.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetXRay.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetXRay.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    xrayId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetXRay.Response}
 */
proto.galaxycomplete.api.gm.GetXRay.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetXRay.Response;
  return proto.galaxycomplete.api.gm.GetXRay.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetXRay.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetXRay.Response}
 */
proto.galaxycomplete.api.gm.GetXRay.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setXrayId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetXRay.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetXRay.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetXRay.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetXRay.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getXrayId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string xray_id = 3;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetXRay.Response.prototype.getXrayId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetXRay.Response} returns this
 */
proto.galaxycomplete.api.gm.GetXRay.Response.prototype.setXrayId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ViewLogs.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ViewLogs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ViewLogs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ViewLogs.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ViewLogs}
 */
proto.galaxycomplete.api.gm.ViewLogs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ViewLogs;
  return proto.galaxycomplete.api.gm.ViewLogs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ViewLogs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ViewLogs}
 */
proto.galaxycomplete.api.gm.ViewLogs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ViewLogs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ViewLogs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ViewLogs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ViewLogs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ViewLogs.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ViewLogs.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ViewLogs.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ViewLogs.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    params: (f = msg.getParams()) && galaxymigratepb_galaxy_migrate_support_pb.ViewLogsRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ViewLogs.Request}
 */
proto.galaxycomplete.api.gm.ViewLogs.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ViewLogs.Request;
  return proto.galaxycomplete.api.gm.ViewLogs.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ViewLogs.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ViewLogs.Request}
 */
proto.galaxycomplete.api.gm.ViewLogs.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = new galaxymigratepb_galaxy_migrate_support_pb.ViewLogsRequest;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_support_pb.ViewLogsRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ViewLogs.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ViewLogs.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ViewLogs.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ViewLogs.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxymigratepb_galaxy_migrate_support_pb.ViewLogsRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ViewLogs.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ViewLogs.Request} returns this
 */
proto.galaxycomplete.api.gm.ViewLogs.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxymigrate.ViewLogsRequest params = 2;
 * @return {?proto.galaxymigrate.ViewLogsRequest}
 */
proto.galaxycomplete.api.gm.ViewLogs.Request.prototype.getParams = function() {
  return /** @type{?proto.galaxymigrate.ViewLogsRequest} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_support_pb.ViewLogsRequest, 2));
};


/**
 * @param {?proto.galaxymigrate.ViewLogsRequest|undefined} value
 * @return {!proto.galaxycomplete.api.gm.ViewLogs.Request} returns this
*/
proto.galaxycomplete.api.gm.ViewLogs.Request.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.ViewLogs.Request} returns this
 */
proto.galaxycomplete.api.gm.ViewLogs.Request.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.ViewLogs.Request.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ViewLogs.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ViewLogs.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ViewLogs.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ViewLogs.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    logsResponse: (f = msg.getLogsResponse()) && galaxymigratepb_galaxy_migrate_support_pb.ViewLogsResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ViewLogs.Response}
 */
proto.galaxycomplete.api.gm.ViewLogs.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ViewLogs.Response;
  return proto.galaxycomplete.api.gm.ViewLogs.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ViewLogs.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ViewLogs.Response}
 */
proto.galaxycomplete.api.gm.ViewLogs.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_support_pb.ViewLogsResponse;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_support_pb.ViewLogsResponse.deserializeBinaryFromReader);
      msg.setLogsResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ViewLogs.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ViewLogs.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ViewLogs.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ViewLogs.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogsResponse();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_support_pb.ViewLogsResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.ViewLogsResponse logs_response = 1;
 * @return {?proto.galaxymigrate.ViewLogsResponse}
 */
proto.galaxycomplete.api.gm.ViewLogs.Response.prototype.getLogsResponse = function() {
  return /** @type{?proto.galaxymigrate.ViewLogsResponse} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_support_pb.ViewLogsResponse, 1));
};


/**
 * @param {?proto.galaxymigrate.ViewLogsResponse|undefined} value
 * @return {!proto.galaxycomplete.api.gm.ViewLogs.Response} returns this
*/
proto.galaxycomplete.api.gm.ViewLogs.Response.prototype.setLogsResponse = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.ViewLogs.Response} returns this
 */
proto.galaxycomplete.api.gm.ViewLogs.Response.prototype.clearLogsResponse = function() {
  return this.setLogsResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.ViewLogs.Response.prototype.hasLogsResponse = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.RestartMTdiDaemon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.RestartMTdiDaemon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.RestartMTdiDaemon}
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.RestartMTdiDaemon;
  return proto.galaxycomplete.api.gm.RestartMTdiDaemon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.RestartMTdiDaemon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.RestartMTdiDaemon}
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.RestartMTdiDaemon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.RestartMTdiDaemon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request}
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request;
  return proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request}
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request} returns this
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response}
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response;
  return proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response}
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RestartMTdiDaemon.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink;
  return proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientsystemid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serversystemid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serveraddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serverport: jspb.Message.getFieldWithDefault(msg, 4, 0),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    allowProxy: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request;
  return proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientsystemid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServersystemid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setServeraddress(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setServerport(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowProxy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientsystemid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServersystemid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getServeraddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServerport();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAllowProxy();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string clientSystemID = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.prototype.getClientsystemid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.prototype.setClientsystemid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string serverSystemID = 2;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.prototype.getServersystemid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.prototype.setServersystemid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string serverAddress = 3;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.prototype.getServeraddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.prototype.setServeraddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 serverPort = 4;
 * @return {number}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.prototype.getServerport = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.prototype.setServerport = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool allow_proxy = 6;
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.prototype.getAllowProxy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request} returns this
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Request.prototype.setAllowProxy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response;
  return proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.GMLinkInfo info = 1;
 * @return {?proto.galaxycomplete.domain.GMLinkInfo}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response.prototype.getInfo = function() {
  return /** @type{?proto.galaxycomplete.domain.GMLinkInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.GMLinkInfo|undefined} value
 * @return {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response} returns this
*/
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response} returns this
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.CreateGalaxyMigrateLink.Response.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink}
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink;
  return proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink}
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request}
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request;
  return proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request}
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinkid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string linkID = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request.prototype.getLinkid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request} returns this
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request.prototype.setLinkid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request} returns this
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Request.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response}
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response;
  return proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response}
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.UpdateGalaxyMigrateLink.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink;
  return proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serveraddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serverport: jspb.Message.getFieldWithDefault(msg, 3, 0),
    allowProxy: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request;
  return proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServeraddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setServerport(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowProxy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinkid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServeraddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getServerport();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getAllowProxy();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string linkID = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.prototype.getLinkid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request} returns this
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.prototype.setLinkid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string serverAddress = 2;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.prototype.getServeraddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request} returns this
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.prototype.setServeraddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 serverPort = 3;
 * @return {number}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.prototype.getServerport = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request} returns this
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.prototype.setServerport = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool allow_proxy = 4;
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.prototype.getAllowProxy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request} returns this
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Request.prototype.setAllowProxy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response;
  return proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ReconnectGalaxyMigrateLink.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink}
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink;
  return proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink}
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request}
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request;
  return proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request}
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinkid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string linkID = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request.prototype.getLinkid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request} returns this
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Request.prototype.setLinkid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response}
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response;
  return proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response}
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RemoveGalaxyMigrateLink.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks;
  return proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageParams: (f = msg.getPageParams()) && galaxycompletepb_commonpb_datafilter_pb.PagerParams.toObject(includeInstance, f),
    systemIdFilter: jspb.Message.getFieldWithDefault(msg, 3, ""),
    onlyConnected: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request;
  return proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerParams;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerParams.deserializeBinaryFromReader);
      msg.setPageParams(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemIdFilter(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyConnected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerParams.serializeBinaryToWriter
    );
  }
  f = message.getSystemIdFilter();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOnlyConnected();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request} returns this
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.common.PagerParams page_params = 2;
 * @return {?proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.prototype.getPageParams = function() {
  return /** @type{?proto.galaxycomplete.common.PagerParams} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerParams, 2));
};


/**
 * @param {?proto.galaxycomplete.common.PagerParams|undefined} value
 * @return {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request} returns this
*/
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.prototype.setPageParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request} returns this
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.prototype.clearPageParams = function() {
  return this.setPageParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.prototype.hasPageParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string system_id_filter = 3;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.prototype.getSystemIdFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request} returns this
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.prototype.setSystemIdFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool only_connected = 4;
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.prototype.getOnlyConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request} returns this
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Request.prototype.setOnlyConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerMeta: (f = msg.getPagerMeta()) && galaxycompletepb_commonpb_datafilter_pb.PagerMeta.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response;
  return proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerMeta;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerMeta.deserializeBinaryFromReader);
      msg.setPagerMeta(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerMeta.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.common.PagerMeta pager_meta = 1;
 * @return {?proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.prototype.getPagerMeta = function() {
  return /** @type{?proto.galaxycomplete.common.PagerMeta} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerMeta, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerMeta|undefined} value
 * @return {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response} returns this
*/
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.prototype.setPagerMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response} returns this
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.prototype.clearPagerMeta = function() {
  return this.setPagerMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.prototype.hasPagerMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated galaxycomplete.domain.GMLinkInfo items = 2;
 * @return {!Array<!proto.galaxycomplete.domain.GMLinkInfo>}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.GMLinkInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.GMLinkInfo>} value
 * @return {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response} returns this
*/
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.GMLinkInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.GMLinkInfo}
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.GMLinkInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response} returns this
 */
proto.galaxycomplete.api.gm.ListGalaxyMigrateLinks.Response.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink}
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetGalaxyMigrateLink;
  return proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink}
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request}
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request;
  return proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request}
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinkid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string linkID = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request.prototype.getLinkid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request} returns this
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Request.prototype.setLinkid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response}
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response;
  return proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response}
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.GMLinkInfo info = 1;
 * @return {?proto.galaxycomplete.domain.GMLinkInfo}
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response.prototype.getInfo = function() {
  return /** @type{?proto.galaxycomplete.domain.GMLinkInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.GMLinkInfo|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response} returns this
*/
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response} returns this
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetGalaxyMigrateLink.Response.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink}
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink;
  return proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink}
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request}
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request;
  return proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request}
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinkid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string linkID = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request.prototype.getLinkid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request} returns this
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Request.prototype.setLinkid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response}
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response;
  return proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response}
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.GMLinkInfo info = 1;
 * @return {?proto.galaxycomplete.domain.GMLinkInfo}
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response.prototype.getInfo = function() {
  return /** @type{?proto.galaxycomplete.domain.GMLinkInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigratelink_pb.GMLinkInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.GMLinkInfo|undefined} value
 * @return {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response} returns this
*/
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response} returns this
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.RefreshGalaxyMigrateLink.Response.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer}
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ConfigureCdcRelayServer;
  return proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer}
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    config: (f = msg.getConfig()) && galaxymigratepb_galaxy_migrate_support_pb.CdcRelayServerConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request}
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request;
  return proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request}
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = new galaxymigratepb_galaxy_migrate_support_pb.CdcRelayServerConfig;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_support_pb.CdcRelayServerConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxymigratepb_galaxy_migrate_support_pb.CdcRelayServerConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request} returns this
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxymigrate.CdcRelayServerConfig config = 2;
 * @return {?proto.galaxymigrate.CdcRelayServerConfig}
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.prototype.getConfig = function() {
  return /** @type{?proto.galaxymigrate.CdcRelayServerConfig} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_support_pb.CdcRelayServerConfig, 2));
};


/**
 * @param {?proto.galaxymigrate.CdcRelayServerConfig|undefined} value
 * @return {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request} returns this
*/
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request} returns this
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Request.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response}
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response;
  return proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response}
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ConfigureCdcRelayServer.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus}
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetCdcRelayServerStatus;
  return proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus}
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request}
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request;
  return proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request}
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request} returns this
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    relayServerInfo: (f = msg.getRelayServerInfo()) && galaxymigratepb_galaxy_migrate_support_pb.GetCdcRelayServerInfoResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response}
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response;
  return proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response}
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_support_pb.GetCdcRelayServerInfoResponse;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_support_pb.GetCdcRelayServerInfoResponse.deserializeBinaryFromReader);
      msg.setRelayServerInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRelayServerInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_support_pb.GetCdcRelayServerInfoResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxymigrate.GetCdcRelayServerInfoResponse relay_server_info = 1;
 * @return {?proto.galaxymigrate.GetCdcRelayServerInfoResponse}
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response.prototype.getRelayServerInfo = function() {
  return /** @type{?proto.galaxymigrate.GetCdcRelayServerInfoResponse} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_support_pb.GetCdcRelayServerInfoResponse, 1));
};


/**
 * @param {?proto.galaxymigrate.GetCdcRelayServerInfoResponse|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response} returns this
*/
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response.prototype.setRelayServerInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response} returns this
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response.prototype.clearRelayServerInfo = function() {
  return this.setRelayServerInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetCdcRelayServerStatus.Response.prototype.hasRelayServerInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListAzureProducts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListAzureProducts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAzureProducts.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListAzureProducts}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListAzureProducts;
  return proto.galaxycomplete.api.gm.ListAzureProducts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListAzureProducts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListAzureProducts}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListAzureProducts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListAzureProducts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAzureProducts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListAzureProducts.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListAzureProducts.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListAzureProducts.Request}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListAzureProducts.Request;
  return proto.galaxycomplete.api.gm.ListAzureProducts.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListAzureProducts.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListAzureProducts.Request}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntegrationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListAzureProducts.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListAzureProducts.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.ListAzureProducts.Request} returns this
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 integration_id = 2;
 * @return {number}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Request.prototype.getIntegrationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.ListAzureProducts.Request} returns this
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Request.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Response.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.ListAzureProducts.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.ListAzureProducts.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    galaxycompletepb_apipb_domainpb_azure_pb.AzureStorageProduct.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.ListAzureProducts.Response}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.ListAzureProducts.Response;
  return proto.galaxycomplete.api.gm.ListAzureProducts.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.ListAzureProducts.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.ListAzureProducts.Response}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_azure_pb.AzureStorageProduct;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_azure_pb.AzureStorageProduct.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.ListAzureProducts.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.ListAzureProducts.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_azure_pb.AzureStorageProduct.serializeBinaryToWriter
    );
  }
};


/**
 * repeated galaxycomplete.domain.AzureStorageProduct products = 1;
 * @return {!Array<!proto.galaxycomplete.domain.AzureStorageProduct>}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Response.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.AzureStorageProduct>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_azure_pb.AzureStorageProduct, 1));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.AzureStorageProduct>} value
 * @return {!proto.galaxycomplete.api.gm.ListAzureProducts.Response} returns this
*/
proto.galaxycomplete.api.gm.ListAzureProducts.Response.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxycomplete.domain.AzureStorageProduct=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.AzureStorageProduct}
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Response.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxycomplete.domain.AzureStorageProduct, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.ListAzureProducts.Response} returns this
 */
proto.galaxycomplete.api.gm.ListAzureProducts.Response.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetAzureRecommendation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetAzureRecommendation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetAzureRecommendation;
  return proto.galaxycomplete.api.gm.GetAzureRecommendation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetAzureRecommendation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetAzureRecommendation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetAzureRecommendation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    windowSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    storageGrowth: jspb.Message.getFieldWithDefault(msg, 3, 0),
    workloadGrowth: jspb.Message.getFieldWithDefault(msg, 4, 0),
    preference: jspb.Message.getFieldWithDefault(msg, 5, 0),
    devicePathsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    integrationId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    destinationSystemId: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Request}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetAzureRecommendation.Request;
  return proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Request}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWindowSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStorageGrowth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkloadGrowth(value);
      break;
    case 5:
      var value = /** @type {!proto.galaxycomplete.domain.RecommendationPreference} */ (reader.readEnum());
      msg.setPreference(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addDevicePaths(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntegrationId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWindowSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getStorageGrowth();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getWorkloadGrowth();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPreference();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getDevicePathsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getIntegrationId();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getDestinationSystemId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 window_size = 2;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.getWindowSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.setWindowSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 storage_growth = 3;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.getStorageGrowth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.setStorageGrowth = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 workload_growth = 4;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.getWorkloadGrowth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.setWorkloadGrowth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional galaxycomplete.domain.RecommendationPreference preference = 5;
 * @return {!proto.galaxycomplete.domain.RecommendationPreference}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.getPreference = function() {
  return /** @type {!proto.galaxycomplete.domain.RecommendationPreference} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.RecommendationPreference} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.setPreference = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated string device_paths = 15;
 * @return {!Array<string>}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.getDevicePathsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.setDevicePathsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.addDevicePaths = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.clearDevicePathsList = function() {
  return this.setDevicePathsList([]);
};


/**
 * optional uint32 integration_id = 16;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.getIntegrationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string destination_system_id = 17;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.getDestinationSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Request.prototype.setDestinationSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    recommendationsMap: (f = msg.getRecommendationsMap()) ? f.toObject(includeInstance, proto.galaxycomplete.domain.AzureStorageRecommendation.toObject) : [],
    statsMap: (f = msg.getStatsMap()) ? f.toObject(includeInstance, proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetAzureRecommendation.Response;
  return proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getRecommendationsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.galaxycomplete.domain.AzureStorageRecommendation.deserializeBinaryFromReader, "", new proto.galaxycomplete.domain.AzureStorageRecommendation());
         });
      break;
    case 2:
      var value = msg.getStatsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.deserializeBinaryFromReader, "", new proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecommendationsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.galaxycomplete.domain.AzureStorageRecommendation.serializeBinaryToWriter);
  }
  f = message.getStatsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.serializeBinaryToWriter);
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.toObject = function(includeInstance, msg) {
  var f, obj = {
    iops: (f = msg.getIops()) && proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.toObject(includeInstance, f),
    throughput: (f = msg.getThroughput()) && proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.toObject(includeInstance, f),
    latency: (f = msg.getLatency()) && proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats;
  return proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType;
      reader.readMessage(value,proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.deserializeBinaryFromReader);
      msg.setIops(value);
      break;
    case 2:
      var value = new proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType;
      reader.readMessage(value,proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.deserializeBinaryFromReader);
      msg.setThroughput(value);
      break;
    case 3:
      var value = new proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType;
      reader.readMessage(value,proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.deserializeBinaryFromReader);
      msg.setLatency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIops();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.serializeBinaryToWriter
    );
  }
  f = message.getThroughput();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.serializeBinaryToWriter
    );
  }
  f = message.getLatency();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.toObject = function(includeInstance, msg) {
  var f, obj = {
    max: jspb.Message.getFieldWithDefault(msg, 1, 0),
    avg: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stdev: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType;
  return proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMax(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAvg(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStdev(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMax();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getAvg();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getStdev();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional uint64 max = 1;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.prototype.setMax = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 avg = 2;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.prototype.getAvg = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.prototype.setAvg = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 stdev = 3;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.prototype.getStdev = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType.prototype.setStdev = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional StatType iops = 1;
 * @return {?proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.prototype.getIops = function() {
  return /** @type{?proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType, 1));
};


/**
 * @param {?proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats} returns this
*/
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.prototype.setIops = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.prototype.clearIops = function() {
  return this.setIops(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.prototype.hasIops = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StatType throughput = 2;
 * @return {?proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.prototype.getThroughput = function() {
  return /** @type{?proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType, 2));
};


/**
 * @param {?proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats} returns this
*/
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.prototype.setThroughput = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.prototype.clearThroughput = function() {
  return this.setThroughput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.prototype.hasThroughput = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StatType latency = 3;
 * @return {?proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.prototype.getLatency = function() {
  return /** @type{?proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType, 3));
};


/**
 * @param {?proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.StatType|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats} returns this
*/
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.prototype.setLatency = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.prototype.clearLatency = function() {
  return this.setLatency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats.prototype.hasLatency = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * map<string, galaxycomplete.domain.AzureStorageRecommendation> recommendations = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.galaxycomplete.domain.AzureStorageRecommendation>}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.prototype.getRecommendationsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.galaxycomplete.domain.AzureStorageRecommendation>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.galaxycomplete.domain.AzureStorageRecommendation));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.prototype.clearRecommendationsMap = function() {
  this.getRecommendationsMap().clear();
  return this;
};


/**
 * map<string, Stats> stats = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats>}
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.prototype.getStatsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.Stats));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.galaxycomplete.api.gm.GetAzureRecommendation.Response} returns this
 */
proto.galaxycomplete.api.gm.GetAzureRecommendation.Response.prototype.clearStatsMap = function() {
  this.getStatsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetAzurePrice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetAzurePrice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzurePrice.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetAzurePrice;
  return proto.galaxycomplete.api.gm.GetAzurePrice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetAzurePrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetAzurePrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetAzurePrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzurePrice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetAzurePrice.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetAzurePrice.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    storageType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    capacity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    iops: jspb.Message.getFieldWithDefault(msg, 5, 0),
    throughput: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice.Request}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetAzurePrice.Request;
  return proto.galaxycomplete.api.gm.GetAzurePrice.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetAzurePrice.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice.Request}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntegrationId(value);
      break;
    case 3:
      var value = /** @type {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.StorageType} */ (reader.readEnum());
      msg.setStorageType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCapacity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setIops(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setThroughput(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetAzurePrice.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetAzurePrice.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getStorageType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getIops();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getThroughput();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 integration_id = 2;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.prototype.getIntegrationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional galaxymigrate.AutoAlloc.VolumeParams.Azure.StorageType storage_type = 3;
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.StorageType}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.prototype.getStorageType = function() {
  return /** @type {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.StorageType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.StorageType} value
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.prototype.setStorageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint64 capacity = 4;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 iops = 5;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.prototype.getIops = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.prototype.setIops = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 throughput = 6;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.prototype.getThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice.Request} returns this
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Request.prototype.setThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.gm.GetAzurePrice.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.gm.GetAzurePrice.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricePerMonth: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    validationError: jspb.Message.getFieldWithDefault(msg, 2, ""),
    product: (f = msg.getProduct()) && galaxycompletepb_apipb_domainpb_azure_pb.AzureStorageProduct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice.Response}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.gm.GetAzurePrice.Response;
  return proto.galaxycomplete.api.gm.GetAzurePrice.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.gm.GetAzurePrice.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice.Response}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPricePerMonth(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationError(value);
      break;
    case 3:
      var value = new galaxycompletepb_apipb_domainpb_azure_pb.AzureStorageProduct;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_azure_pb.AzureStorageProduct.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.gm.GetAzurePrice.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.gm.GetAzurePrice.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricePerMonth();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getValidationError();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      galaxycompletepb_apipb_domainpb_azure_pb.AzureStorageProduct.serializeBinaryToWriter
    );
  }
};


/**
 * optional float price_per_month = 1;
 * @return {number}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Response.prototype.getPricePerMonth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice.Response} returns this
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Response.prototype.setPricePerMonth = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string validation_error = 2;
 * @return {string}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Response.prototype.getValidationError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice.Response} returns this
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Response.prototype.setValidationError = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional galaxycomplete.domain.AzureStorageProduct product = 3;
 * @return {?proto.galaxycomplete.domain.AzureStorageProduct}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Response.prototype.getProduct = function() {
  return /** @type{?proto.galaxycomplete.domain.AzureStorageProduct} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_azure_pb.AzureStorageProduct, 3));
};


/**
 * @param {?proto.galaxycomplete.domain.AzureStorageProduct|undefined} value
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice.Response} returns this
*/
proto.galaxycomplete.api.gm.GetAzurePrice.Response.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.gm.GetAzurePrice.Response} returns this
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Response.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.gm.GetAzurePrice.Response.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.galaxycomplete.api.gm);
