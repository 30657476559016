// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: LayoutCommon

import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {useAppServices} from "../app/services";
import {IconButton, SvgIcon,} from "@mui/material";
import SmallLogo from "../../assets/Cirrus_Data_Cloud_Logo_Small.png";
import LargeLogo from "../../assets/Cirrus_Data_Cloud_Logo_Large.png";

import {MdBrightness4, MdBrightnessHigh} from "react-icons/md";
import {CSSProperties, ImgHTMLAttributes} from "react";
import PrivateEditionLogo from "../../assets/Cirrus_Data_Cloud_Logo_Private_Edition.png";

export const getSideMenuListItemStyle = () => ({
    '&.Mui-selected': {
        backgroundColor: 'rgba(33, 150, 243, .2)',
        borderRight: `5px solid rgba(33, 150, 243)`,
        "&:hover": {
            backgroundColor: 'rgba(33, 150, 243, .4)',
        },
    },
    "&:hover": {
        backgroundColor: 'rgba(33, 150, 243, .4)',
    },
})

// ======================
// LightModeIcon
// ======================

interface LightModeIconProps {
}

export const LightModeIcon: React.FC<LightModeIconProps> = observer((props) => {

    const {appGlobalService} = useAppServices();

    const getIcon = () => {
        if (appGlobalService.isLightMode) {
            return <MdBrightness4/>
        } else {
            return <MdBrightnessHigh/>
        }
    }

    return <IconButton onClick={() => appGlobalService.toggleLightMode()}>
        <SvgIcon>
            {getIcon()}
        </SvgIcon>
    </IconButton>
})

// ======================
// CDSLogo
// ======================

interface CDSLogoProps {
    imageClass?: string;
    imageAttributes?: ImgHTMLAttributes<HTMLImageElement>
    size?: 'small' | 'large',
    style?: CSSProperties,
}

export const CDSLogo: React.FC<CDSLogoProps> = observer((props) => {

    return <img style={props.style} src={props.size === 'large' ? LargeLogo : SmallLogo} alt={'Cirrus Data Cloud'} {...props.imageAttributes} className={props.imageClass}/>
});

export const CDSPrivateEditionLogo: React.FC<CDSLogoProps> = observer((props) => {
    return <img src={PrivateEditionLogo} alt={'Cirrus Data Cloud Private Edition'} {...props.imageAttributes}
                className={props.imageClass} style={props.style}
    />
});