import {GRPCServices} from "../grpc/grpcapi";
import {DialogService} from "../core/dialog/DialogService";
import {ProgressService} from "../core/progress/ProgressService";
import {makeAutoObservable} from "mobx";
import {ServerListData} from "../core/data/ListData";
import {ListProjects} from "../../_proto/galaxycompletepb/apipb/project_api_pb";
import {ProjectInfo, ProjectListItem} from "../../_proto/galaxycompletepb/apipb/domainpb/project_pb";
import {getProjectListFilterConfig} from "../project/ProjectCommon";
import {DeleteXray, DownloadXrays, JoinProject, ListXrays} from "../../_proto/galaxycompletepb/apipb/support_api_pb";
import {FilterParams, PagerParams} from "../../_proto/galaxycompletepb/commonpb/datafilter_pb";
import {Xray} from "../../_proto/galaxycompletepb/apipb/domainpb/support_pb";

export class SupportService{
    private api: GRPCServices;
    private dialogService: DialogService;
    private progressService: ProgressService;

    supportableProjects = new ServerListData<ListProjects.Response.AsObject, ProjectListItem.AsObject, ListProjects.Request>(10, getProjectListFilterConfig()).setDataFetcher(this.listSupportableProjects.bind(this))
    mySupportProjects = new ServerListData<ListProjects.Response.AsObject, ProjectListItem.AsObject, ListProjects.Request>(10, getProjectListFilterConfig()).setDataFetcher(this.listMySupportProjects.bind(this))
    deploymentXrays = new ServerListData<ListXrays.Response.AsObject, Xray.AsObject>(30).setDataFetcher(this.listXrays.bind(this));

    constructor(api: GRPCServices, dialogService: DialogService, progressService: ProgressService){
        this.api = api;
        this.dialogService = dialogService;
        this.progressService = progressService

        makeAutoObservable(this)
    }

    async listMySupportProjects(){
        let req = new ListProjects.Request()
            .setPageParams(this.mySupportProjects.pagerParam)
            .setSortParams(this.mySupportProjects.sortParam || null)
            .setIsAdmin(false)

        this.mySupportProjects.addFilterParamsToRequest(req)
        req.addFilterParams(new ListProjects.Request.Filter().setIsSupporting(new FilterParams.BoolFilter().setValue(true).setOp(FilterParams.BoolFilter.Op.EQUAL)))

        const res = await this.api.projectService.listProjects(req, null)
        return res.toObject()
    }

    async listSupportableProjects(){
        let req = new ListProjects.Request()
            .setPageParams(this.supportableProjects.pagerParam)
            .setIsAdmin(false)
            .setSortParams(this.mySupportProjects.sortParam || null)

        this.supportableProjects.addFilterParamsToRequest(req)

        const res = await this.api.supportService.listSupportableProject(req, null)
        return res.toObject()
    }

    async joinProjectAsSupport(projectId: string){
        const req = new JoinProject.Request()
            .setProjectId(projectId)
        return await this.progressService.track(this.api.supportService.joinProject(req, null))
    }

    public async listXrays(deploymentId: string){
        const req = new ListXrays.Request()
            .setDeploymentId(deploymentId)
        const res = await this.api.supportService.listXrays(req, null)
        return res.toObject()
    }

    public async deleteXray(xrayId: string){
        const req = new DeleteXray.Request()
            .setXrayId(xrayId)
        return await this.progressService.track(this.api.supportService.deleteXray(req, null))
    }

    public async downloadXray(xrayId: string){
        const req = new DownloadXrays.Request()
            .setXrayId(xrayId)

        const res = await this.progressService.track(this.api.supportService.downloadXrays(req, null))
        return res.toObject()
    }

}