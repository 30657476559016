import {CirrusProtectDeploymentInfo} from "../../../_proto/galaxycompletepb/apipb/domainpb/cirrusprotect_pb";
import {Alert, Box} from "@mui/material";
import React from "react";

export const renderOfflineDeploymentMessage = (deployment: CirrusProtectDeploymentInfo) => {
    if (!deployment?.getDeployment().getConnected()){
        return <Box p={2}>
            <Alert severity={'warning'}>Unavailable when the host is offline.</Alert>
        </Box>
    }
}