import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateProject } from "../../_proto/galaxycompletepb/apipb/project_api_pb";
import { sleepMS } from "../../common/utils/util";
import { useAppServices } from "../app/services";
import { useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";

export const ProjectQueryKeys = {
    ListMyProjects: "listMyProjects",
};

export const useCreateNewProject = () => {
    const { projectService } = useAppServices();
    const queryClient = useQueryClient();

    return useMutationTrackAndAlertError(
        {
            mutationKey: ["CreateNewProject"],
            mutationFn: async (args: { name: string; description: string; emergencyEmail: string; emergencyPhone: string; deleteLock: boolean }) => {
                const req = new CreateProject.Request()
                    .setName(args.name)
                    .setEmergencyEmail(args.emergencyEmail)
                    .setEmergencyPhone(args.emergencyPhone)
                    .setDeleteLock(args.deleteLock)
                    .setDescription(args.description);
                await sleepMS(2000);
                return await projectService.createNewProjectWithHook(req);
            },
            onSuccess: async () => {
                await queryClient.refetchQueries([ProjectQueryKeys.ListMyProjects]);
            },
        },
        "Creating Project..."
    );
};
