import { GRPCServices } from "../grpc/grpcapi";
import { DialogService } from "../core/dialog/DialogService";
import { ProgressService } from "../core/progress/ProgressService";
import { makeAutoObservable } from "mobx";
import { HELP_CENTER_URL } from "./HelpCommon";

export class HelpService {
    private api: GRPCServices;
    private dialogService: DialogService;
    private progressService: ProgressService;

    constructor(api: GRPCServices, dialogService: DialogService, progressService: ProgressService) {
        this.api = api;
        this.dialogService = dialogService;
        this.progressService = progressService;
        makeAutoObservable(this);
    }

    getCustomerCenterAuthCallbackUrl(redirect?: string) {
        let u = `${HELP_CENTER_URL}/auth-callback?token=${this.api.getActiveToken()}`;
        if (!!redirect) {
            u += "&redirect=" + redirect;
        }
        return u;
    }
}
