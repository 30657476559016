// Project: GalaxyComplete
// Created: 9/29/20 by sammy
// File: ProjectActivities

import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    SvgIcon,
    Tooltip,
    Typography,
} from '@mui/material';
import {formatKnownDataType, KnownDataType} from '../../common/utils/formatter';
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator} from '@mui/lab';
import {FiMoreHorizontal} from 'react-icons/fi';
import {TiInfoLargeOutline} from 'react-icons/ti';
import {renderServerDataWithLoadingList, useInitData} from '../core/data/DataLoaderHooks';
import {useAppServices} from '../app/services';
import {ListProjectActivities} from '../../_proto/galaxycompletepb/apipb/project_api_pb';
import {ActivityInfo} from '../../_proto/galaxycompletepb/apipb/domainpb/activity_pb';
import {TableState} from '../../common/table/DataTable';
import {transformStringWithTemplate} from '../../common/utils/templateUtil';
import {getProjectMemberUserFullName, getUserFullName, UserAvatar} from '../settings/ProjectUsers';
import {LeftArrow, RightArrow} from "../../common/CommonIcons";
import {useIsDesktop} from "../layout/MainLayout";
import {TruncatedText} from "../../common/text/TruncatedText";


// ======================
// ProjectEventLog
// ======================
const useEventLogStyles = () => ({
    oppositeContent: {
        flex      : 0.15,
        display   : 'flex',
        alignItems: 'center',
        paddingLeft: 0
    },
    root           : {},
});

interface ProjectEventLogProps {
}

export const ProjectEventLog: React.FC<ProjectEventLogProps> = observer((p) => {

    const {projectManagementService} = useAppServices();
    const refresh                    = () => projectManagementService.projectActivities.fetchData();
    useInitData({
                    poll        : refresh,
                    pollInterval: 30,
                });

    return renderServerDataWithLoadingList(projectManagementService.projectActivities, data => {
        return <EventTimeline eventsList={data} refresh={refresh} listState={projectManagementService.projectActivities.tableState}/>;
    });
});

// ======================
// EventTimeline
// ======================
interface EventTimelineProps {
    eventsList: ListProjectActivities.Response;
    refresh: Function;
    listState: TableState<ActivityInfo>;
}

const EventTimeline: React.FC<EventTimelineProps> = observer((p) => {
    const isDesktop = useIsDesktop();

    const styles            = useEventLogStyles();
    const pageMeta          = p.eventsList.getPagerMeta();
    const hasMorePages      = pageMeta.getHasMore();
    const currentPageNumber = pageMeta.getPage();
    const itemsList         = p.eventsList.getItemsList();

    const nextPage = () => {
        p.listState.pager.setPage(currentPageNumber + 1);
        p.refresh();
    };
    const prevPage = () => {
        p.listState.pager.setPage(currentPageNumber - 1);
        p.refresh();
    };

    return (<>
            <CardHeader sx={{padding:0}} title={`Project Timeline`} subheader={`Contains informational and error events from all deployments from this project`}/>
            <Timeline sx={{padding:0}}>
                {itemsList.map((item, i, l) => {
                    const isLast = i === l.length - 1;
                    return <TimelineItem key={item.getId()}>
                        <TimelineOppositeContent sx={styles.oppositeContent}>
                            <Tooltip title={formatKnownDataType(item.getTime().toDate(), KnownDataType.DATE)} arrow>
                                <Typography align={'right'} variant={isDesktop ? 'body1' : 'caption'}>
                                    {formatKnownDataType(item.getTime().toDate(), KnownDataType.DATE_RELATIVE)}
                                </Typography>
                            </Tooltip>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector/>
                            <TimelineDot variant={'outlined'}>
                                <SvgIcon>
                                    <TiInfoLargeOutline/>
                                </SvgIcon>
                            </TimelineDot>
                            {<TimelineConnector/>}
                        </TimelineSeparator>
                        <TimelineContent sx={{paddingRight:0}}>
                            <Card>
                                <List><ProjectTimelineItem item={item}/></List>
                            </Card>
                        </TimelineContent>
                    </TimelineItem>;
                })}
                {pageMeta.getHasMore() && <TimelineItem>
                    <TimelineOppositeContent sx={styles.oppositeContent}>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot variant={'outlined'}>
                            <SvgIcon>
                                <FiMoreHorizontal/>
                            </SvgIcon>
                        </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                    </TimelineContent>
                </TimelineItem>}
            </Timeline>
            <Typography align={'center'}>
                <IconButton size={'medium'} disabled={currentPageNumber === 1} onClick={prevPage} aria-label={'projectActivityPrevButton'}>
                    <LeftArrow/>
                </IconButton>
                <IconButton size={'medium'} disabled={!hasMorePages} onClick={nextPage} data-testid={'projectActivityNextButton'}>
                    <RightArrow/>
                </IconButton>
            </Typography>
    </>);
});

// ======================
// ProjectTimelineItem
// ======================
interface ProjectTimelineItemProps {
    item: ActivityInfo
}

const ProjectTimelineItem: React.FC<ProjectTimelineItemProps> = observer((p) => {
    const isDesktop = useIsDesktop();
    const item    = p.item;
    const message = transformStringWithTemplate(item.getMessageTemplate(), item.getData().toJavaScript());
    return (<>
        <ListItem>
            <ListItemText primary={isDesktop ? item.getEventCode() : <TruncatedText characterLimit={20} text={item.getEventCode()}/>} secondary={message}/>
            {isDesktop &&
                <ListItemSecondaryAction>
                    {item.getUserInfo() &&  <UserAvatar user={item.getUserInfo().toObject()} tooltip/>}
                </ListItemSecondaryAction>
            }
        </ListItem>
        {
            !isDesktop &&
            <Box pl={2} pb={2}>
                <Typography variant={'body2'} color={'textSecondary'}>
                    by {getUserFullName(item.getUserInfo())}
                </Typography>
            </Box>

        }
    </>);
});