import React from "react";
import {observer} from "mobx-react-lite";
import {Button, Chip, Grid} from "@mui/material";
import {generatePath, useNavigate, useParams} from "react-router-dom";
import {
    CDM_POLICY_DETAILS_SUBROUTE,
    getProjectSubRouteTemplate,
    POLICIES_SUBROUTE,
    PROJECT_SUBROUTE, PROTECTION_HOST_DETAILS_SUBROUTE,
    PROTECTION_SUBROUTE
} from "../app/AppRoutes";
import {useCurrentProjectID} from "../project/CurrentProject";
import {useAppServices} from "../app/services";
import {CirrusProtectDeploymentInfo} from "../../_proto/galaxycompletepb/apipb/domainpb/cirrusprotect_pb";

export const generatePhoenixDeploymentsSubPath = (subPath: string, projectId: string) => {
    return generatePath(getProjectSubRouteTemplate(`${PROJECT_SUBROUTE.PROTECTION}/${PROTECTION_SUBROUTE.SERVERS}/${subPath}`), {projectId})
}

export const generatePhoenixDeploymentDetailsPath = (deploymentId: string, projectId: string) => {
    return generatePhoenixDeploymentsSubPath(deploymentId, projectId)
};

export const generateCdmPoliciesSubPath = (subPath: string, projectId: string) => {
    return generatePath(getProjectSubRouteTemplate(`${PROJECT_SUBROUTE.PROTECTION}/${PROTECTION_SUBROUTE.POLICIES}/${subPath}`), {projectId})
};

export const generateCirrusProtectDeploymentsSubPath = (subPath: string, projectId: string) => {
    return generatePath(getProjectSubRouteTemplate(`${PROJECT_SUBROUTE.PROTECTION}/${PROTECTION_SUBROUTE.DEPLOYMENTS}/${subPath}`), {projectId})
};

export const generateCirrusProtectDeploymentDetailsPath = (deploymentId: string, projectId: string) => {
    return generateCirrusProtectDeploymentsSubPath(deploymentId, projectId)
};

export const useNavigateToCdmPolicyList = () => {
    const projectId = useCurrentProjectID();
    const navigate  = useNavigate();
    const p = generateCdmPoliciesSubPath('', projectId);
    return () => navigate(p);
}

export const useNavigateToCdmPolicyDetails = (policyId: string) => {
    const projectId = useCurrentProjectID();
    const navigate  = useNavigate();
    const p = generateCdmPoliciesSubPath(policyId, projectId);
    return () => navigate(p);
};

export const useNavigateToPhoenixDeploymentsList = () => {
    const projectId = useCurrentProjectID();
    const navigate  = useNavigate();
    const p  = generatePhoenixDeploymentsSubPath('', projectId);
    return ()=>navigate(p);
};

export const useNavigateToPhoenixDeploymentDetails = (deploymentId: string) => {
    const projectId = useCurrentProjectID();
    const navigate  = useNavigate();
    const p  = generatePhoenixDeploymentDetailsPath(deploymentId, projectId);
    return ()=>navigate(p);
};

export const useNavigateToCdmPolicyWizard = () => {
    const projectId = useCurrentProjectID();
    const {deploymentId} = useParams();
    const navigate  = useNavigate();
    const p  = generateCirrusProtectDeploymentDetailsPath(`${deploymentId}/${PROTECTION_HOST_DETAILS_SUBROUTE.CREATE_NEW_POLICY}`, projectId);
    return ()=>navigate(p);
};

export const useNavigateToTimewalker = () => {
    const projectId = useCurrentProjectID();
    const navigate  = useNavigate();
    const {policyId} = useParams();
    const p  = generateCdmPoliciesSubPath(`${policyId}/${CDM_POLICY_DETAILS_SUBROUTE.TIMEWALKER}`, projectId);
    return ()=>navigate(p);
};

export const useNavigateToCpDeploymentsList = () => {
    const projectId = useCurrentProjectID();
    const navigate  = useNavigate();
    const p = generateCirrusProtectDeploymentsSubPath('', projectId);
    return () => navigate(p);
};

export const useNavigateToCpDeploymentDetails = (deploymentId: string) => {
    const projectId = useCurrentProjectID();
    const navigate  = useNavigate();
    const p = generateCirrusProtectDeploymentsSubPath(deploymentId, projectId);
    return () => navigate(p);
};

export const useNavigateToCpDeploymentDetailsSubPath = (deploymentId: string, subPath: PROTECTION_HOST_DETAILS_SUBROUTE) => {
    const projectId = useCurrentProjectID();
    const navigate  = useNavigate();
    const p = generateCirrusProtectDeploymentsSubPath(`${deploymentId}/${subPath}`, projectId);
    return () => navigate(p);
}


// ======================
// CreateNewCDMPolicyButton
// ======================

interface CreateNewCDMPolicyButtonProps {
    fromHost?: CirrusProtectDeploymentInfo;
    fromServer?: string;
}

export const CreateNewCDMPolicyButton: React.FC<CreateNewCDMPolicyButtonProps> = observer((p) => {
    const goToWizard = useNavigateToCdmPolicyWizard();
    const { cdmPolicyService } =  useAppServices();

    const onClick = () => {
        if (!!p.fromHost){
            const state = cdmPolicyService.initWizardState(1);
            state.setSelectedHost(p.fromHost);
        }
        else if (!!p.fromServer){
            const state = cdmPolicyService.initWizardState();
            state.setServer(p.fromServer);
        }
        else {
            cdmPolicyService.initWizardState()
        }
        goToWizard();
    }

    return <Button color={'secondary'} variant={'contained'} onClick={onClick}>Create New CDM Policy</Button>
});

export const renderChipInfo = (s: string) => {
    if (s) {
        return <Grid item><Chip size={'small'} label={s}/></Grid>
    }
}