import { GRPCServices } from "../grpc/grpcapi";
import { DialogService } from "../core/dialog/DialogService";
import { ProgressService } from "../core/progress/ProgressService";
import { makeAutoObservable } from "mobx";
import { AuthService } from "../auth/AuthService";
import {
    GenerateAPIToken,
    GetMyUserInfo,
    GetUserLicenseVaultDetails,
    ListMyUserSessions,
    ListUserLicenseTransactions,
    ReplaceMyAvatar,
    RequestMyAvatarUploadUrl,
    RevokeAPIToken,
    RevokeMyUserSession,
    UpdateMyUserInfo,
} from "../../_proto/galaxycompletepb/apipb/auth_api_pb";
import { ServerData } from "../core/data/ServerData";
import { HubUser } from "../../_proto/galaxycompletepb/apipb/domainpb/user_pb";
import { TransactionDetails, VaultDetails } from "../../_proto/galaxycompletepb/apipb/domainpb/license_pb";
import { ServerListData } from "../core/data/ListData";

export class UserService {
    private api: GRPCServices;
    protected dialogService: DialogService;
    private progressService: ProgressService;
    protected authService: AuthService;

    constructor(api: GRPCServices, authService: AuthService, dialogService: DialogService, progressService: ProgressService) {
        this.api = api;
        this.authService = authService;
        this.dialogService = dialogService;
        this.progressService = progressService;
        makeAutoObservable(this);
    }

    currentUser = new ServerData<GetMyUserInfo.Response>().setDataFetcher(this.fetchMyUserInfo.bind(this));
    apiToken = new ServerData<GenerateAPIToken.Response>().setDataFetcher(this.generateApiToken.bind(this));

    public async fetchMyUserInfo() {
        const req = new GetMyUserInfo.Request();
        return await this.api.authService.getMyUserInfo(req, null);
    }

    public async fetchNewAvatarLink(file: File) {
        const req = new RequestMyAvatarUploadUrl.Request().setFileType(file.type);
        return await this.api.authService.requestMyAvatarUploadUrl(req, null);
    }

    public async updateAvatar(file: File) {
        const res = await this.fetchNewAvatarLink(file);
        if (res) {
            const uploadUri = res.getUploadToThisUri();
            const request = new Request(uploadUri, {
                method: "PUT",
                body: file,
                headers: {
                    "Content-Type": file.type,
                    "x-amz-acl": "public-read",
                },
            });

            try {
                const response = await fetch(request);
                if (!response.ok) {
                    throw new Error("Failed to upload image.");
                }
                await this.replaceAvatar();
            } catch (e) {
                return await this.dialogService.addErrorAlertDialog(e as Error);
            }
        }
    }

    public async replaceAvatar() {
        const req = new ReplaceMyAvatar.Request().setRemove(false);
        await this.api.authService.replaceMyAvatar(req, null);
        await this.currentUser.fetchData();
        await this.authService.refreshToken();
    }

    public async removeAvatar() {
        const req = new ReplaceMyAvatar.Request().setRemove(true);
        await this.api.authService.replaceMyAvatar(req, null);
        await this.currentUser.fetchData();
        await this.authService.refreshToken();
    }

    public async updateCurrentUser(userInfo: HubUser) {
        const req = new UpdateMyUserInfo.Request()
            .setCompanyName(userInfo.getCompanyName())
            .setFirstName(userInfo.getFirstName())
            .setJobTitle(userInfo.getJobTitle())
            .setLastName(userInfo.getLastName())
            .setLocation(userInfo.getLocation())
            .setPhoneNumber(userInfo.getPhoneNumber());
        return await this.progressService.track(this.api.authService.updateMyUserInfo(req, null));
    }

    public async generateApiToken() {
        const req = new GenerateAPIToken.Request();
        return await this.api.authService.generateAPIToken(req, null);
    }

    public async revokeApiToken() {
        const req = new RevokeAPIToken.Request();
        return await this.api.authService.revokeAPIToken(req, null);
    }

    public async listUserSessions() {
        const req = new ListMyUserSessions.Request();
        const res = await this.api.authService.listMyUserSessions(req, null);
        return res.toObject();
    }

    public async revokeUserSession(sessionId: number) {
        const req = new RevokeMyUserSession.Request().setSessionId(sessionId);

        return await this.progressService.track(this.api.authService.revokeMyUserSession(req, null));
    }
}
