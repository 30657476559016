export const getBrowserTimezone = () => {
    return new Date()
        .toLocaleDateString('en-US', {
            day: '2-digit',
            timeZoneName: 'long',
        })
        .slice(4)
}

export const getMinutesFromTime = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return (hours*60) + minutes
}

export const createDateObjectFromMinutes = (minutes: number) => {
    const hours = minutes/60 === 24 ? 0 : Math.floor(minutes/60);
    const mins = minutes % 60;
    return new Date(0,0,0,hours, mins)
}