import { GRPCServices } from "../../../grpc/grpcapi";
import { DialogService } from "../../../core/dialog/DialogService";
import { ProgressService } from "../../../core/progress/ProgressService";
import { makeAutoObservable } from "mobx";
import { ServerData } from "../../../core/data/ServerData";
import { GetChecklist, MarkTaskCompleted, MarkTaskSkipped } from "../../../../_proto/galaxycompletepb/apipb/checklist_api_pb";
import { ChecklistInfo } from "../../../../_proto/galaxycompletepb/apipb/domainpb/checklist_pb";
import { mockChecklist } from "../../../core/testutils/fixtures/MockChecklistService";

export class ChecklistService {
    protected readonly api: GRPCServices;
    private readonly dialogService: DialogService;
    private readonly progressService: ProgressService;

    checklistId: number;
    currentChecklist = new ServerData<ChecklistInfo.AsObject>().setDataFetcher(this.getChecklist.bind(this));

    constructor(api: GRPCServices, dialogService: DialogService, progressService: ProgressService) {
        makeAutoObservable(this);

        this.api = api;
        this.dialogService = dialogService;
        this.progressService = progressService;
    }

    setCurrentChecklistId(id: number) {
        this.checklistId = id;
    }

    get currentChecklistId() {
        return this.checklistId;
    }

    async getChecklist(checklistId?: number) {
        const req = new GetChecklist.Request().setChecklistId(checklistId || this.currentChecklistId);

        //return mockChecklist.toObject()

        const res = await this.api.checklistService.getChecklist(req, null);
        return res.getChecklist().toObject();
    }

    async markTaskCompleted(taskId: number) {
        const req = new MarkTaskCompleted.Request().setTaskId(taskId);
        await this.progressService.track(this.api.checklistService.markTaskCompleted(req, null));
        await this.currentChecklist.fetchData();
    }

    async markTaskSkipped(taskId: number, reason?: string) {
        const req = new MarkTaskSkipped.Request().setTaskId(taskId).setReason(reason);
        await this.progressService.track(this.api.checklistService.markTaskSkipped(req, null));
        await this.currentChecklist.fetchData();
    }
}
