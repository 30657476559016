import {observer} from "mobx-react-lite";
import {
    DynamicHorizontalStepper,
    DynamicVerticalStepper,
    StepConfig,
    StepperState
} from "../../common/stepper/StepperComponents";
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    Divider,
    Grid,
    TextField,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import {useAppServices} from "../app/services";
import React from "react";
import {Alert} from "@mui/material";
import {DialogState, useShouldDialogFullScreen} from "../core/dialog/DialogService";
import {DialogTopBar} from "../core/dialog/DialogComponents";
import {useEffectOnce} from "react-use";
import {ClipboardButton, ClipboardText} from "../../common/clipboard/ClipboardComponents";
import {TwoFactorAuthState} from "./AuthService";
import {renderServerDataWithLoadingBox, useInitData} from "../core/data/DataLoaderHooks";

const use2FAStyles = () => {
    const t = useTheme();
    return {
        keyCard: {
            background: t.palette.background.default,
            width: '100%'
        },
    };
};

// ======================
// TwoFactorAuthDialog
// ======================

interface TwoFactorAuthDialogProps {
    dialogState: DialogState;
}

export const TwoFactorAuthDialog: React.FC<TwoFactorAuthDialogProps> = observer((p) => {
    const {authService} = useAppServices();

    useEffectOnce(() => {
        authService.initTwoFactorAuthState()
    });

    if (!!authService.twoFactorAuthState) {
        return <TwoFactorAuthDialogContent state={authService.twoFactorAuthState} dialogState={p.dialogState}/>
    }
    return null;
});

// ======================
// TwoFactorAuthDialogContent
// ======================

interface TwoFactorAuthDialogContentProps {
    state: TwoFactorAuthState;
    dialogState: DialogState
}

export const TwoFactorAuthDialogContent: React.FC<TwoFactorAuthDialogContentProps> = observer((p) => {
    const {userService} = useAppServices();
    const fullScreen = useShouldDialogFullScreen();
    const stepperState = p.state.enable2FAStepperState

    const onClickNext = async () => {
        if (!!stepperState.stepConfigs[stepperState.activeStep].onClickNext) {
            await stepperState.stepConfigs[stepperState.activeStep].onClickNext()
        }
        stepperState.goToNextStep();
    };

    const onFinish = async () => {
        p.dialogState.close();
        await userService.currentUser.fetchData();
    }

    return <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} maxWidth={'md'} fullWidth
                   fullScreen={fullScreen}>
        <DialogTopBar dialogState={p.dialogState} title={'Enable Two-Factor Authentication'}/>
        <EnableTwoFactorAuthStepper stepperState={stepperState} twoFactorAuthState={p.state}/>
        <Divider/>
        <Box display={'flex'} justifyContent={'space-between'} p={2}
        >
            <Button variant={'outlined'} disabled={!stepperState.hasPreviousStep}
                    onClick={() => {
                        stepperState.goBackOneStep()
                    }}>
                Back
            </Button>
            {!stepperState.isLastStep &&
            <Button variant={'contained'} color={'primary'} onClick={onClickNext}>
                Next
            </Button>
            }
            {stepperState.isLastStep &&
            <Button variant={'contained'} color={'primary'}
                    onClick={onFinish}>
                Finish
            </Button>
            }
        </Box>

    </Dialog>
})

// ======================
// EnableTwoFactorAuthStepper
// ======================


interface EnableTwoFactorAuthStepperProps {
    stepperState: StepperState;
    twoFactorAuthState: TwoFactorAuthState;
}

export const EnableTwoFactorAuthStepper: React.FC<EnableTwoFactorAuthStepperProps> = observer((p) => {
    const fullScreen = useShouldDialogFullScreen();
    return <DialogContent>
        {!fullScreen &&
        <Box minHeight={400}>
            <DynamicVerticalStepper stepConfigs={generate2FaStepConfigs(p.twoFactorAuthState)}
                                    stepperState={p.stepperState}/>
        </Box>
        }
        {fullScreen &&
        <DynamicHorizontalStepper stepConfigs={generate2FaStepConfigs(p.twoFactorAuthState)}
                                  stepperState={p.stepperState}/>
        }
    </DialogContent>
})

// ======================
// Prepare2FAStep
// ======================

interface Prepare2FAStepProps {

}

export const Prepare2FAStep: React.FC<Prepare2FAStepProps> = observer((p) => {

    return <><Box>
        <Typography variant={'h5'}>Prepare to enable 2FA</Typography>
        <Typography variant={'body1'}>Two Factor Authentication makes your account safer, by asking for a temporary code
            upon login.
            The code is generated by TOTP app on your mobile phone.</Typography>
        <br/>
        <Alert severity={'info'}>If you lose your phone, you will need a recovery code to access your account.</Alert>
    </Box>
    </>
});

// ======================
// Scan2FACodeStep
// ======================

interface Scan2FACodeStepProps {
    state: TwoFactorAuthState;
}

export const Scan2FACodeStep: React.FC<Scan2FACodeStepProps> = observer((p) => {
    const { state } = p;

    const styles = use2FAStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const keyId = 'authenticationKeyId';

    useInitData({
        init: () => state.twoFACode.fetchData(),
    });

    return <><Box>
        <Typography variant={'h5'}>Scan Code With Your Authentication App</Typography>
        <Typography variant={'body1'}>If you are unable to scan the code, you can also install the following private key
            in your authentication app.</Typography>
        {
            renderServerDataWithLoadingBox(state.twoFACode, data => {
                return <Box pt={2}>
                    <Grid container spacing={2} justifyContent={'center'}>
                        <Grid item xs={12} sm={3}>
                            <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                                <img src={`data:image/jpg;base64, ${data.getImage_asB64()}`} alt={'TOTP QR Code'}
                                     width={isMobile ? 200 : 150} height={isMobile ? 200 : 150}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Card sx={styles.keyCard}>
                                <CardContent>
                                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}
                                         width={'100%'}>
                                        <ClipboardText clipboardId={keyId}>
                                            {data.getSecret()}
                                        </ClipboardText>
                                        <ClipboardButton clipboardId={keyId} iconButton/>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>


                </Box>
            })
        }
    </Box>
    </>
});

// ======================
// Verify2FACodeStep
// ======================

interface Verify2FACodeStepProps {
    state: TwoFactorAuthState
}

export const Verify2FACodeStep: React.FC<Verify2FACodeStepProps> = observer((p) => {
    const { state } = p

    return <><Box>
        <Typography variant={'h5'}>Verify Your Code</Typography>
        <Typography variant={'body1'}>To ensure your private key is correctly installed. Please enter the 6 digit code
            that your authentication app generates below. </Typography>
        <br/>
        <TextField variant={'filled'}
                   value={state.verificationCode}
                   id={'verifyToken'}
                   label={'Verification Token'}
                   onChange={(e) => {
                       state.setVerificationCode(e.target.value)
                   }}
                   fullWidth
                   helperText={'Enter the 6 digit code'}
                   required
        />
    </Box>
    </>
});

// ======================
// RecoveryCodeStep
// ======================

interface RecoveryCodeStepProps {
    state: TwoFactorAuthState;
}

export const RecoveryCodeStep: React.FC<RecoveryCodeStepProps> = observer((p) => {

    return <><Box>
        <Typography variant={'h5'}>Copy Your Recovery Codes</Typography>
        <Typography variant={'body1'}>Copy your recovery codes and keep them safe. If you lose access to your device,
            using these codes will be the only way to recover your account. </Typography>
        <br/>
        <RecoveryCodeList state={p.state}/>
    </Box>

    </>
});

// ======================
// RecoveryCodeList
// ======================

interface RecoveryCodeListProps {
    state: TwoFactorAuthState;
}

export const RecoveryCodeList: React.FC<RecoveryCodeListProps> = observer((p) => {
    const { state } = p;

    const styles = use2FAStyles();

    const codesId = 'recoveryCodesId';

    useInitData({
        poll: () => state.recoveryCodes.fetchData(),
        pollInterval: 30
    });
    return renderServerDataWithLoadingBox(state.recoveryCodes, data => {
        return <Box pt={2}>
            <Card sx={styles.keyCard}>
                <CardContent>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}
                         width={'100%'}>
                        <ClipboardText clipboardId={codesId}>
                            <ul>
                                {
                                    data.getRecoveryCodesList().map((c, i) => <li key={i}>{c}</li>)
                                }
                            </ul>
                        </ClipboardText>
                        <ClipboardButton clipboardId={codesId} iconButton/>
                    </Box>
                </CardContent>
            </Card>


        </Box>
    })
})

export const generate2FaStepConfigs = (state?: TwoFactorAuthState): StepConfig[] => ([
    {
        id: 'prepare',
        label: 'Prepare 2FA',
        renderer: () =>
            <Prepare2FAStep/>
    },
    {
        id: 'scan',
        label: 'Scan Code',
        renderer: () => <Scan2FACodeStep state={state}/>
    },
    {
        id: 'verify',
        label: 'Verify Code',
        renderer: () => <Verify2FACodeStep state={state}/>,
        onClickNext: async () => await state.enable2Fa()
    },
    {
        id: 'copyRecoveryCodes',
        label: 'Copy Recovery Codes',
        renderer: () => <RecoveryCodeStep state={state}/>
    }
])