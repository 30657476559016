import {ServerData, ServerDataFetcherFunc} from './ServerData';
import {computed, makeAutoObservable, makeObservable, observable} from 'mobx';
import {FilterParams, PagerParams} from '../../../_proto/galaxycompletepb/commonpb/datafilter_pb';
import {FilterState, SortState, TableState} from '../../../common/table/DataTable';

export type FilterParamType =
    FilterParams.StringFilter
    | FilterParams.DurationFilter
    | FilterParams.DateFilter
    | FilterParams.NumberFilter
    | FilterParams.BoolFilter
    | FilterParams.SimpleStringFilter

export interface ColumnSortFilterConfig<RequestType = any, SortParamType = any> {
    sort: SortConfig<SortParamType>,
    filter: ListFilterableField[],
}

export interface SortConfig<SortParamType> {
    defaultSort: ListSortableField,
    defaultSortDesc?: boolean,
    sortFields: ListSortableField[],
    getSortParam: (s: SortState) => SortParamType;
}

export interface ListSortableField {
    fieldId: any, // enum sort field
    label: string,
}

export interface FilterableFieldBase {
    fieldId: any, // one of api's FieldCase
    label: string,
}

export type FilterType = 'string' | 'bool' | 'simpleString' | 'date' | 'duration' | 'number';

export interface StringFilterField<RequestType = any> extends FilterableFieldBase {
    filterType: 'string',
    addFilterToRequest: (request: RequestType, filterParam: FilterParams.StringFilter)=> void
}

export interface SimpleStringFilterField<RequestType = any> extends FilterableFieldBase {
    filterType: 'simpleString',
    addFilterToRequest: (request: RequestType, filterParam: FilterParams.SimpleStringFilter)=> void
}

export interface DateFilterField<RequestType = any> extends FilterableFieldBase {
    filterType: 'date',
    addFilterToRequest: (request: RequestType, filterParam: FilterParams.DateFilter)=> void
}
export interface BoolFilterField<RequestType = any> extends FilterableFieldBase {
    filterType: 'bool',
    addFilterToRequest: (request: RequestType, filterParam: FilterParams.BoolFilter)=> void
}

export interface DurationFilterField<RequestType = any> extends FilterableFieldBase {
    filterType: 'duration',
    addFilterToRequest: (request: RequestType, filterParam: FilterParams.DurationFilter)=> void
}

export interface NumberFilterField<RequestType = any> extends FilterableFieldBase {
    filterType: 'number',
    addFilterToRequest: (request: RequestType, filterParam: FilterParams.NumberFilter)=> void
}

export type ListFilterableField<RequestType = any> = StringFilterField<RequestType> | SimpleStringFilterField<RequestType> | DateFilterField<RequestType> | BoolFilterField<RequestType> | DurationFilterField<RequestType> | NumberFilterField<RequestType>


export class ServerListData<T, ItemType, RequestType = any,> extends ServerData<T> {
    tableState: TableState<ItemType>;

    constructor(perPage = 30, sortFilterConfig?: ColumnSortFilterConfig<RequestType>, selectedGetter?: (r: ItemType) => string, selectDisabledGetter?: (r: ItemType)=>boolean) {
        super();
        const pagerParam = new PagerParams().setPage(1).setPerPage(perPage);
        this.tableState  = new TableState<ItemType>(pagerParam, sortFilterConfig, selectedGetter, selectDisabledGetter);

        makeObservable(this,{
            'tableState' : observable,
            'pagerParam' : computed,
        });
    }

    get pagerParam() {
        return this.tableState.pager;
    }

    get sortParam(){
        return this.tableState.sortFilterConfig.sort.getSortParam(this.tableState.sortState)
    }

    addFilterParamsToRequest(request: RequestType){
        for (let filter of this.tableState.filters){
            filter.fieldConfig.addFilterToRequest(request, filter.param)
        }
    }

}
