import {useCallback, useState} from "react";

export const usePopoverState = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const open = useCallback((event: React.MouseEvent<any>)=>setAnchorEl(event.currentTarget), [setAnchorEl])
    const close = useCallback(()=>setAnchorEl(null), [setAnchorEl])

    const isOpen = Boolean(anchorEl);

    const [popoverProps, setPopoverProps] = useState(null)

    return {isOpen, open, close, popoverProps, setPopoverProps, anchorEl};
};

export type PopoverState = ReturnType<typeof usePopoverState>