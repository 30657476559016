// Project: GalaxyComplete
// Created: 9/28/20 by sammy
// File: MigrationCommon

import * as React from "react";
import {
    GalaxyMigrateDeploymentDetails,
    GalaxyMigrateManagedVolumeInfo,
    GalaxyMigrateMigrationSessionInfo,
    GalaxyMigrateStorageConfig,
} from "../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { Box, Button, SvgIcon, SvgIconProps, Tooltip, Typography } from "@mui/material";
import { getProjectSubRouteTemplate, MIGRATION_SUBROUTE, PROJECT_SUBROUTE } from "../app/AppRoutes";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { SyncQos } from "../../_proto/galaxymigratepb/galaxy_migrate_qos_pb";
import { useAppServices } from "../app/services";
import { useIsTableEmpty } from "../../common/table/TableCommon";
import { IoSync } from "react-icons/io5";
import { HiScissors } from "react-icons/hi";
import { GalaxyMigrateMigrationService, GmMigrationType, GmMigrationVolumeType } from "./GmMigrationService";
import { IoMdPause } from "react-icons/io";
import { MdDone, MdOutlineCancel, MdOutlineRestartAlt } from "react-icons/md";
import { isMinMtdiVersion } from "../deployment/DeploymentCommon";
import { DeploymentIdentityInfo, DeploymentInfo } from "../../_proto/galaxycompletepb/apipb/domainpb/deployment_pb";
import { formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MigrationQosScheduleType, QosDailyScheduleIntervalType } from "./wizard/GmMigrationSessionParametersForm";
import { MigrationSession } from "../../_proto/galaxymigratepb/galaxy_migrate_migration_pb";
import { createDateObjectFromMinutes, getMinutesFromTime } from "../../common/utils/dateUtil";
import { FormSelectableCardConfig } from "../../common/form/FormComponents";
import MinimumMode from "../../assets/migrationSession/minimum.png";
import ModerateMode from "../../assets/migrationSession/moderate.png";
import AggressiveMode from "../../assets/migrationSession/aggressive.png";
import RelentlessMode from "../../assets/migrationSession/relentless.png";
import { DialogService } from "../core/dialog/DialogService";
import { KnownArticle } from "../help/HelpCommon";
import { useOpenHelpArticle } from "../help/hooks/help_hooks";

export const CutoverIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <HiScissors />
        </SvgIcon>
    );
};

export const CancelIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <MdOutlineCancel />
        </SvgIcon>
    );
};

export const CompleteIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <MdDone />
        </SvgIcon>
    );
};

export const RestartIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <MdOutlineRestartAlt />
        </SvgIcon>
    );
};

export const SynchronizeIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <IoSync />
        </SvgIcon>
    );
};
export const SuspendSynchronizeIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <IoMdPause />
        </SvgIcon>
    );
};

export const renderGMSessionStatus = (s: GalaxyMigrateMigrationSessionInfo.Status): React.ReactNode => {
    let error = false;
    if ([GalaxyMigrateMigrationSessionInfo.Status.FAILED, GalaxyMigrateMigrationSessionInfo.Status.IN_ERROR].includes(s)) {
        error = true;
    }

    const text =
        {
            [GalaxyMigrateMigrationSessionInfo.Status.FAILED.valueOf()]: "Failed",
            [GalaxyMigrateMigrationSessionInfo.Status.CANCELLED.valueOf()]: "Canceled",
            [GalaxyMigrateMigrationSessionInfo.Status.HOLDING_IO.valueOf()]: "Holding I/O",
            [GalaxyMigrateMigrationSessionInfo.Status.IN_ERROR.valueOf()]: "In Error",
            [GalaxyMigrateMigrationSessionInfo.Status.MIRRORING.valueOf()]: "Mirroring",
            [GalaxyMigrateMigrationSessionInfo.Status.TRACKING.valueOf()]: "Tracking Changes",
            [GalaxyMigrateMigrationSessionInfo.Status.SYNCING.valueOf()]: "Synchronizing",
            [GalaxyMigrateMigrationSessionInfo.Status.SUSPENDED.valueOf()]: "Suspended",
            [GalaxyMigrateMigrationSessionInfo.Status.STANDING_IN.valueOf()]: "cMotion™",
            [GalaxyMigrateMigrationSessionInfo.Status.CUTOVER.valueOf()]: "Cutover w/cMotion™",
            [GalaxyMigrateMigrationSessionInfo.Status.COMPLETED.valueOf()]: "Completed",
        }[s] || "---";
    return (
        <Typography color={error ? "error" : s === GalaxyMigrateMigrationSessionInfo.Status.COMPLETED.valueOf() ? "primary" : undefined} variant={"inherit"}>
            {text}
        </Typography>
    );
};

export const getGmSessionIDSlug = (id: string) => id.slice(-4);

export const generateMigrationSubPathRoute = (projectId: string, migrationSubRoute: MIGRATION_SUBROUTE) => {
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATIONS) + `/${migrationSubRoute}`, { projectId });
    return p;
};
/**/

export const generateMigrationSessionDetailsPath = (projectId: string, sessionId: string) => {
    const p = generatePath(`${generateMigrationSubPathRoute(projectId, MIGRATION_SUBROUTE.SESSIONS)}/${sessionId}`, { projectId });
    return p;
};

export const generateMigrationSessionSubPathRoute = (projectId: string, sessionId: string, migrationSessionSubRoute: MIGRATION_SUBROUTE | "") => {
    const p = generatePath(`${generateMigrationSubPathRoute(projectId, MIGRATION_SUBROUTE.SESSIONS)}/${sessionId}/${migrationSessionSubRoute}`, { projectId });
    return p;
};

export const useNavigateToProjectMigrationListScreen = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATIONS) + `/${MIGRATION_SUBROUTE.SESSIONS}`, { projectId });
    return () => navigate(p);
};

export const useNavigateToMigrationWizardScreen = (replace = false) => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATIONS) + `/${MIGRATION_SUBROUTE.WIZARD}`, { projectId });
    return () => navigate(p, { replace });
};

export const useNavigateToSessionEditScreen = () => {
    const navigate = useNavigate();
    const { projectId, sessionId } = useParams();
    const p = generateMigrationSessionSubPathRoute(projectId, sessionId, MIGRATION_SUBROUTE.EDIT);
    return () => navigate(p);
};

export const useNavigateToSessionEditVmConfigScreen = () => {
    const navigate = useNavigate();
    const { projectId, sessionId } = useParams();
    const p = generateMigrationSessionSubPathRoute(projectId, sessionId, MIGRATION_SUBROUTE.EDIT_VM_CONFIG);
    return () => navigate(p);
};

export const useNavigateToMigrationSessionDetails = (id?: string) => {
    const navigate = useNavigate();
    const { projectId, sessionId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATIONS) + `/${MIGRATION_SUBROUTE.SESSIONS}/${sessionId || id}/`, { projectId });
    return () => navigate(p);
};

export const useNavigateToQosScreen = (sourceVolUUID: string) => {
    const navigate = useNavigate();
    const { projectId, sessionId } = useParams();
    const p = generatePath(
        generateMigrationSessionSubPathRoute(projectId, sessionId, MIGRATION_SUBROUTE.VOLUMES) + "/:sessionVolumeUUID/" + MIGRATION_SUBROUTE.QOS,
        { sessionVolumeUUID: sourceVolUUID }
    );
    return () => navigate(p);
};

export const generateMigrationSessionWorkflowRunRoute = (projectId: string, sessionId: string, workflowId: number) => {
    return generatePath(generateMigrationSessionSubPathRoute(projectId, sessionId, MIGRATION_SUBROUTE.WORKFLOW_RUNS) + `/${workflowId}`);
};

export const getSyncQosImpactLevelLabel = (impactLevel: string | SyncQos.ImpactLevel) => {
    if (impactLevel === SyncQos.ImpactLevel.RELENTLESS) {
        return "Relentless";
    } else if (impactLevel === SyncQos.ImpactLevel.MINIMUM) {
        return "Minimum";
    } else if (impactLevel === SyncQos.ImpactLevel.MODERATE) {
        return "Moderate";
    } else if (impactLevel === SyncQos.ImpactLevel.AGGRESSIVE) {
        return "Aggressive";
    } else {
        return "";
    }
};

export const useHasMigrationSessions = () => {
    const { gmMigrationService } = useAppServices();
    return useIsTableEmpty(gmMigrationService.sessions);
};

export const renderGmMigrationTypeLabel = (type: GmMigrationType) => {
    if (type === GmMigrationType.LOCAL) {
        return "Local Migration";
    } else if (type === GmMigrationType.REMOTE) {
        return "Remote Migration";
    } else if (type === GmMigrationType.COMPUTE_VMWARE) {
        return "Compute Migration VMware";
    }
    return null;
};

export const renderGmMigrationVolumeTypeLabel = (type: GmMigrationVolumeType) => {
    if (type === GmMigrationVolumeType.DATA) {
        return "Data Volumes";
    }
};

export const useDeleteMigrationSession = (id?: string) => {
    const { gmMigrationService, dialogService } = useAppServices();
    const sessionId = id || gmMigrationService.currentSessionID;
    const isBootSession = gmMigrationService.currentSession.data.getSessionInfo().getBootVolumeSession();
    const isCompleted = gmMigrationService.currentSession.data.getSessionInfo().getSessionStatus() === GalaxyMigrateMigrationSessionInfo.Status.COMPLETED;
    const returnToMigrationList = useNavigateToProjectMigrationListScreen();

    const message =
        isBootSession || isCompleted ? (
            "This action is not reversible."
        ) : (
            <Typography>
                The deletion of Migration Session will stop the cMotion redirection, allowing application access to the original source disk rather than the
                redirected destination disk. This will cause application level data corruption if the application is not yet stopped AND the disks are not
                already set to Offline (Windows) or unmounted (Linux). Please verify NOW that your host application is stopped and volumes are
                offline/unmounted.
                <br />
                <br />
                After the session is deleted, it is then safe to online/mounted (after a rescan) the destination disks and to restart the application. This
                action is not reversible.
            </Typography>
        );
    return async () => {
        const confirmed = await dialogService.addConfirmDialog({
            message,
            autoConfirmationQuestionLine: false,
            title: "Delete Migration Session Confirmation",
            typeToConfirm: "DELETE",
            cancelButtonProps: { variant: "outlined" },
            cancelButtonLabel: "Cancel",
            okButtonLabel: "Delete",
            okButtonProps: { variant: "contained", color: "error" },
        });
        if (confirmed) {
            const deleted = await gmMigrationService.deleteSession(sessionId);
            if (deleted) {
                returnToMigrationList();
            }
        }
        return confirmed;
    };
};

export const useSyncMigrationSession = (id?: string) => {
    const { gmMigrationService } = useAppServices();
    const sessionId = id || gmMigrationService.currentSessionID;
    return async () => {
        await gmMigrationService.syncSession(sessionId);
        await gmMigrationService.currentSession.fetchData();
        return true;
    };
};

export const useCutoverMigrationSession = (id?: string) => {
    const { gmMigrationService, dialogService } = useAppServices();
    const sessionId = id || gmMigrationService.currentSessionID;
    return async () => {
        const confirmed = await dialogService.addConfirmDialog({
            message: `By continuing, the volume will synchronize and production I/O will be redirected to the new storage.  The source volume and destination volumes are both masked.  Other than for performing tests on the destination storage, proceed to the "Finalize Cutover" state immediately.`,
        });
        if (confirmed) {
            await gmMigrationService.cutoverSession(sessionId);
        }
        await gmMigrationService.fetchSessionDetails(sessionId);
        return confirmed;
    };
};
export const useCompleteMigrationSession = (id?: string) => {
    const { gmMigrationService, dialogService } = useAppServices();
    const sessionId = id || gmMigrationService.currentSessionID;
    return async () => {
        const confirmed = await dialogService.addConfirmDialog({
            message:
                "WARNING! Once a migration session is completed, the redirection will stop and the source volume that was being redirected up to this point will no longer be online. This action should only be triggered after the host application is offline and the source disk is unmounted.",
        });
        if (confirmed) {
            await gmMigrationService.completeSession(sessionId);
        }
        await gmMigrationService.fetchSessionDetails(sessionId);
        return confirmed;
    };
};

export const useFinalizeCutoverMigrationSession = (id?: string) => {
    const { gmMigrationService, dialogService } = useAppServices();
    const sessionId = id || gmMigrationService.currentSessionID;
    return async () => {
        const confirmed = await dialogService.addConfirmDialog({
            message:
                'THIS ACTION IS IRREVERSIBLE.  By continuing, cutover will become permanent and cannot be reverted.  I/O will continue to be redirected to the destination until "Complete Session" is triggered. Therefore you must NOT mount (Linux) or online (Windows) the destination volume until then.  If a planned or unplanned reboot shall happen, then the migration session automatically enters the "Complete Session" state and the Destination volume can then be mounted.',
        });
        if (confirmed) {
            await gmMigrationService.finalCutoverSession(sessionId);
            await gmMigrationService.currentSession.fetchData();
        }
        return confirmed;
    };
};

export const validateNotExceededMaxOutstandingIOForBootCutover = async (
    gmMigrationService: GalaxyMigrateMigrationService,
    dialogService: DialogService,
    sessionId: string,
    totalDataRemaining: number,
    reviewMigrationGuideButton: React.ReactNode
) => {
    const MAX_OUTSTANDING_DATA_BEFORE_CUTOVER = 1 * 1000 * 1000 * 1000;
    if (totalDataRemaining > MAX_OUTSTANDING_DATA_BEFORE_CUTOVER) {
        const warningHeader = `Synchronization Required for Boot Volume Cutover`;
        const warningMsg = `This session currently has ${formatKnownDataType(
            totalDataRemaining,
            KnownDataType.CAPACITY
        )} unsynchronized data. In order to have a predictable cutover schedule to boot into the new volume, please synchronize your volumes first and try again when the amount of remaining data is less than ${formatKnownDataType(
            MAX_OUTSTANDING_DATA_BEFORE_CUTOVER,
            KnownDataType.CAPACITY
        )}.`;
        await dialogService.addAlertDialog({
            title: warningHeader,
            message: warningMsg,
            renderAdditionalContent: (closeFn) => {
                const triggerSyncButton = (
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        size={"small"}
                        onClick={async () => {
                            await gmMigrationService.syncSession(sessionId);
                            await gmMigrationService.fetchSessionDetails(sessionId);
                            closeFn();
                        }}
                    >
                        {"Trigger Synchronization Now"}
                    </Button>
                );
                return (
                    <>
                        <p>{reviewMigrationGuideButton}</p>
                        <p>{triggerSyncButton}</p>
                    </>
                );
            },
        });
        return false;
    }
    return true;
};

export const useCutoverBootSession = (id?: string) => {
    const { gmMigrationService, dialogService } = useAppServices();
    const sessionId = id || gmMigrationService.currentSessionID;
    const totalDataRemaining = gmMigrationService.currentSession.data?.getSessionInfo().getSessionStats().getTotalRemaining();
    const openHelpArticle = useOpenHelpArticle();

    return async () => {
        const reviewMigrationGuideButton = (
            <Button variant={"outlined"} color={"neutral"} size={"small"} onClick={() => openHelpArticle(KnownArticle.MIGRATING_BOOT_VOLUMES)}>
                {"Review Boot Volume Migration Guide"}
            </Button>
        );

        const valid = await validateNotExceededMaxOutstandingIOForBootCutover(
            gmMigrationService,
            dialogService,
            sessionId,
            totalDataRemaining,
            reviewMigrationGuideButton
        );
        if (!valid) {
            return;
        }

        const header = "Warning! Do NOT proceed unless you are ready to cutover your boot volumes and already stopped your applications.";
        let message = `Before continuing, please ensure that all your applications are stopped and all filesystem caches are flushed. New changes to the volumes in this session AFTER final cutover will not be migrated.`;

        let confirmed = await dialogService.addConfirmDialog({
            title: header,
            message,
            renderAdditionalContent: () => {
                return (
                    <>
                        <p>{message}</p>
                        <p>{"Once cutover is completed you should immediately shutdown your system, remove your source volume and boot into the new volume"}</p>
                        <p>
                            {
                                "By continuing, you acknowledge that you have read and understand our boot volume migration guide and posses proper boot volume configuration knowledge for this particular host."
                            }
                        </p>
                        <p>{reviewMigrationGuideButton}</p>
                    </>
                );
            },
        });
        if (confirmed) {
            confirmed = await dialogService.addConfirmDialog({
                message: "A final sync will be performed and cutover process will ensue. This process is IRREVERSIBLE. ",
            });
        }
        if (confirmed) {
            await gmMigrationService.cutoverSession(sessionId);
        }
        await gmMigrationService.fetchSessionDetails(sessionId);
        return confirmed;
    };
};

export const getChangeDataMapDisabledForSession = (status: GalaxyMigrateMigrationSessionInfo.Status, deployment: GalaxyMigrateDeploymentDetails) => {
    const enabledStatuses = [
        GalaxyMigrateMigrationSessionInfo.Status.TRACKING,
        GalaxyMigrateMigrationSessionInfo.Status.IN_ERROR,
        GalaxyMigrateMigrationSessionInfo.Status.STANDING_IN,
        GalaxyMigrateMigrationSessionInfo.Status.SYNCING,
    ];
    if (!enabledStatuses.includes(status)) {
        return true;
    } else if (!isMinMtdiVersion(deployment, "8.5.0")) {
        return true;
    } else {
        return false;
    }
};

export const getStorageConfigBlockDevice = (path: string, deviceList: Array<GalaxyMigrateStorageConfig.Device.AsObject>, isDeploymentWindows: boolean) => {
    if (!!deviceList) {
        for (const d of deviceList) {
            if (`${isDeploymentWindows ? "" : "/dev/"}${d.blockDevice.deviceName}` === path) {
                return d;
            }
            for (const link of d.blockDevice.deviceLinksList) {
                if (link === path) {
                    return d;
                }
            }
        }
    }

    return null;
};

export const getMigrationVolumeTooltip = (device: GalaxyMigrateStorageConfig.Device.AsObject, volume: GalaxyMigrateManagedVolumeInfo) => {
    if (!!device && !!volume) {
        return (
            <Box pt={1}>
                <Tooltip
                    title={
                        <Box>
                            <Box>Device: &nbsp; {device.blockDevice.deviceName}</Box>
                            <Box>Capacity: &nbsp; {formatKnownDataType(device.blockDevice.capacity, KnownDataType.CAPACITY)}</Box>
                            <Box>Type: &nbsp; {device.blockDevice.deviceType}</Box>
                            {!!device.blockDevice.fsType && <Box>FS Type: {device.blockDevice.fsType}</Box>}
                            {!!device.blockDevice.label && <Box>FS Label: &nbsp;{device.blockDevice.label} </Box>}
                            {!!device.blockDevice.mountPoint && <Box>Mount Point: &nbsp;{device.blockDevice.mountPoint}</Box>}
                            <Box sx={{ wordBreak: "break-all" }}>Path: &nbsp; {volume.getDevicePath()}</Box>
                        </Box>
                    }
                    arrow={true}
                >
                    <SvgIcon color={"primary"}>
                        <AiOutlineInfoCircle />
                    </SvgIcon>
                </Tooltip>
            </Box>
        );
    }
    return null;
};

export const getMigrationSourceVolumeLabel = (
    sourceVolume: GalaxyMigrateManagedVolumeInfo,
    storageConfig: GalaxyMigrateStorageConfig,
    isDeploymentWindows: boolean
) => {
    let tooltip;
    let label = (
        <Typography variant={"h6"} align={"center"}>
            {sourceVolume.getName()}
        </Typography>
    );
    if (!!storageConfig) {
        const device = getStorageConfigBlockDevice(sourceVolume.getDevicePath(), storageConfig.toObject().devicesList, isDeploymentWindows);
        if (!!device) {
            if (isDeploymentWindows && !!device?.blockDevice.fsType) {
                label = (
                    <Typography variant={"h6"} align={"center"}>
                        {`${device.blockDevice.fsType} (${device.blockDevice.mountPoint})`}
                    </Typography>
                );
            } else {
                label = (
                    <Typography variant={"h6"} align={"center"}>
                        {device.blockDevice.deviceName ?? sourceVolume.getName()}
                    </Typography>
                );
            }
            tooltip = getMigrationVolumeTooltip(device, sourceVolume);
        }
    }
    return (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            {label} &nbsp; {tooltip}
        </Box>
    );
};

export const getMigrationDestinationVolumeLabel = (
    destinationVolume: GalaxyMigrateManagedVolumeInfo,
    storageConfig: GalaxyMigrateStorageConfig,
    isDeploymentWindows: boolean,
    destinationDeployment: DeploymentIdentityInfo,
    remoteStorageConfig: GalaxyMigrateStorageConfig
) => {
    let tooltip;
    let label = (
        <Typography variant={"h6"} align={"center"}>
            {destinationVolume.getName()}
        </Typography>
    );
    let device;
    if (!!destinationDeployment && !!remoteStorageConfig) {
        device = getStorageConfigBlockDevice(destinationVolume.getDevicePath(), remoteStorageConfig.toObject().devicesList, isDeploymentWindows);
    } else if (!!storageConfig) {
        device = getStorageConfigBlockDevice(destinationVolume.getDevicePath(), storageConfig.toObject().devicesList, isDeploymentWindows);
    }

    if (!!device) {
        if (isDeploymentWindows && !!device?.blockDevice?.fsType) {
            label = (
                <Typography variant={"h6"} align={"center"}>
                    {`${device.blockDevice.fsType} (${device.blockDevice.mountPoint})`}
                </Typography>
            );
        } else {
            label = (
                <Typography variant={"h6"} align={"center"}>
                    {device.blockDevice.deviceName ?? destinationVolume.getName()}
                </Typography>
            );
        }
        tooltip = getMigrationVolumeTooltip(device, destinationVolume);
    }

    return (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            {label} &nbsp; {tooltip}
        </Box>
    );
};

export interface ActionTriggeredState {
    actionTriggered: boolean;
    setActionTriggered: (triggered: boolean) => void;
}

const getIntervalEndMinute = (start: Date, end: Date) => {
    const midnightIntervalEnd = 23 * 60 + 59;
    if (end.getHours() === 0 && end.getMinutes() === 0) {
        return midnightIntervalEnd;
    }
    return getMinutesFromTime(end) - 1;
};

export const createMigrationQosSchedule = (formValues: MigrationQosScheduleType): MigrationSession.ConfigParams.QosSchedule => {
    const qosSchedule = new MigrationSession.ConfigParams.QosSchedule();
    const dailyScheduleIntervalsList: Array<MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval> = [];
    const fridaysIntervalsList: Array<MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval> = [];
    const weekendsIntervalsList: Array<MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval> = [];

    if (formValues.daily.length > 0) {
        for (const timeSlot of formValues.daily) {
            const interval = new MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval()
                .setStart(getMinutesFromTime(timeSlot.start))
                .setEnd(getIntervalEndMinute(timeSlot.start, timeSlot.end))
                .setQosLevel(timeSlot.qosLevel);
            dailyScheduleIntervalsList.push(interval);
        }
        const dailySchedule = new MigrationSession.ConfigParams.QosSchedule.DailySchedule().setIntervalsList(dailyScheduleIntervalsList);
        qosSchedule.setMonday(dailySchedule);
        qosSchedule.setTuesday(dailySchedule);
        qosSchedule.setWednesday(dailySchedule);
        qosSchedule.setThursday(dailySchedule);
        qosSchedule.setFriday(dailySchedule);
        qosSchedule.setSaturday(dailySchedule);
        qosSchedule.setSunday(dailySchedule);
    }

    if (formValues.fridays.length > 0) {
        for (const timeSlot of formValues.fridays) {
            const interval = new MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval()
                .setStart(getMinutesFromTime(timeSlot.start))
                .setEnd(getIntervalEndMinute(timeSlot.start, timeSlot.end))
                .setQosLevel(timeSlot.qosLevel);
            fridaysIntervalsList.push(interval);
        }
        const fridaySchedule = new MigrationSession.ConfigParams.QosSchedule.DailySchedule().setIntervalsList(fridaysIntervalsList);
        qosSchedule.setFriday(fridaySchedule);
    }

    if (formValues.weekends.length > 0) {
        for (const timeSlot of formValues.weekends) {
            const interval = new MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval()
                .setStart(getMinutesFromTime(timeSlot.start))
                .setEnd(getIntervalEndMinute(timeSlot.start, timeSlot.end))
                .setQosLevel(timeSlot.qosLevel);
            weekendsIntervalsList.push(interval);
        }
        const weekendSchedule = new MigrationSession.ConfigParams.QosSchedule.DailySchedule().setIntervalsList(weekendsIntervalsList);
        qosSchedule.setSaturday(weekendSchedule);
        qosSchedule.setSunday(weekendSchedule);
    }

    return qosSchedule;
};

const checkIntervalListIsEqual = (
    a: Array<MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval>,
    b: Array<MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval>
) => {
    if (!a || !b) {
        return false;
    }
    if (a.length !== b.length) {
        return false;
    }
    for (let i = 0; i < a.length; i++) {
        if (a[i].getStart() !== b[i].getStart() || a[i].getEnd() !== b[i].getEnd() || a[i].getQosLevel() !== b[i].getQosLevel()) {
            return false;
        }
    }
    return true;
};

export const convertQosScheduleToFormValues = (qosSchedule: MigrationSession.ConfigParams.QosSchedule): MigrationQosScheduleType => {
    const formValues: MigrationQosScheduleType = {
        daily: [],
        fridays: [],
        weekends: [],
    };
    if (qosSchedule.hasMonday()) {
        for (const interval of qosSchedule.getMonday().getIntervalsList()) {
            const timeSlot: QosDailyScheduleIntervalType = {
                start: createDateObjectFromMinutes(interval.getStart()),
                end: createDateObjectFromMinutes(interval.getEnd() + 1),
                qosLevel: interval.getQosLevel(),
            };
            formValues.daily.push(timeSlot);
        }
    }
    if (qosSchedule.hasFriday() && !checkIntervalListIsEqual(qosSchedule.getMonday()?.getIntervalsList(), qosSchedule.getFriday().getIntervalsList())) {
        for (const interval of qosSchedule.getFriday().getIntervalsList()) {
            const timeSlot: QosDailyScheduleIntervalType = {
                start: createDateObjectFromMinutes(interval.getStart()),
                end: createDateObjectFromMinutes(interval.getEnd() + 1),
                qosLevel: interval.getQosLevel(),
            };
            formValues.fridays.push(timeSlot);
        }
    }
    if (qosSchedule.hasSaturday() && !checkIntervalListIsEqual(qosSchedule.getMonday()?.getIntervalsList(), qosSchedule.getSaturday().getIntervalsList())) {
        for (const interval of qosSchedule.getSaturday().getIntervalsList()) {
            const timeSlot: QosDailyScheduleIntervalType = {
                start: createDateObjectFromMinutes(interval.getStart()),
                end: createDateObjectFromMinutes(interval.getEnd() + 1),
                qosLevel: interval.getQosLevel(),
            };
            formValues.weekends.push(timeSlot);
        }
    }
    return formValues;
};

export const impactLevelOptions: FormSelectableCardConfig[] = [
    {
        title: getSyncQosImpactLevelLabel(SyncQos.ImpactLevel.MINIMUM),
        description: "Application will have absolute priority",
        icon: <img src={MinimumMode} height={72} alt={"Minimum Mode"} />,
        value: SyncQos.ImpactLevel.MINIMUM,
    },
    {
        title: getSyncQosImpactLevelLabel(SyncQos.ImpactLevel.MODERATE),
        description: "Migration will compete with Application for up to 30%",
        icon: <img src={ModerateMode} height={72} alt={"Moderate Mode"} />,
        value: SyncQos.ImpactLevel.MODERATE,
    },
    {
        title: getSyncQosImpactLevelLabel(SyncQos.ImpactLevel.AGGRESSIVE),
        description: "Migration will compete with Application for up to 75%",
        icon: <img src={AggressiveMode} height={72} alt={"Aggressive Mode"} />,
        value: SyncQos.ImpactLevel.AGGRESSIVE,
    },
    {
        title: getSyncQosImpactLevelLabel(SyncQos.ImpactLevel.RELENTLESS),
        description: "Migrate as fast as possible",
        icon: <img src={RelentlessMode} height={72} alt={"Relentless Mode"} />,
        value: SyncQos.ImpactLevel.RELENTLESS,
    },
];

export const getImpactLevelConfig = (impactLevel: SyncQos.ImpactLevel): FormSelectableCardConfig => {
    return impactLevelOptions.find((o) => o.value === impactLevel);
};
