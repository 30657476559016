import {DialogState} from "../../core/dialog/DialogService";
import React from "react";
import {
    Button,
    ButtonProps,
    Dialog,
    DialogContent,
    Card,
    Radio,
    Box,
    Typography,
    TextField,
    FormControlLabel, Chip, RadioGroup, Divider, DialogActions, Theme
} from "@mui/material";
import {observer} from "mobx-react-lite";
import {DialogTopBar} from "../../core/dialog/DialogComponents";
import {useAppServices} from "../../app/services";
import {renderServerDataWithLoadingList, useInitData} from "../../core/data/DataLoaderHooks";
import {getHostEnvDisplayName} from "../../galaxymigrate/GalaxyMigrateCommon";
import {ConnectedStatusChip} from "../../../common/chip/CommonChips";

// ======================
// CpDeploymentConnectToPhoenixButton
// ======================

interface CpDeploymentConnectToPhoenixButtonProps {
    cpDeployments: { id: string, name: string }[],
    dialogState: DialogState,
    label: string,
}

export const CpDeploymentConnectToPhoenixButton: React.FC<CpDeploymentConnectToPhoenixButtonProps & Partial<ButtonProps>> = observer((p) => {
    const {dialogState, label, cpDeployments, ...buttonProps} = p;
    const {cpDeploymentService} = useAppServices();

    const onClick = () => {
        cpDeploymentService.connectToPhoenixState.setCpDeployments(cpDeployments);
        p.dialogState.open()
    };

    return <Button {...buttonProps} onClick={onClick}>
        {label}
    </Button>;
})


// ======================
// CpDeploymentConnectToPhoenixDialog
// ======================

interface CpDeploymentConnectToPhoenixDialogProps {
    dialogState: DialogState;
}

export const CpDeploymentConnectToPhoenixDialog: React.FC<CpDeploymentConnectToPhoenixDialogProps> = observer((p) => {

    const {cpDeploymentService} = useAppServices();
    const state = cpDeploymentService.connectToPhoenixState;

    const onCancel = () => {
        state.resetConnectToPhoenixState();
        p.dialogState.close()
    };

    const onSubmit = async () => {
        const connected = await state.connectCpDeploymentToPhoenix();
        if (connected) {
            state.resetConnectToPhoenixState();
            p.dialogState.close()
        }
    };

    const getHelperText = () => {
        const numOfHosts = state.cirrusProtectDeployments.length;
        const hostNames = state.cirrusProtectDeployments.map(d => d.name).join(', ');
        const targetName = !!state.selectedPhoenixDeployment ? state.selectedPhoenixDeployment.name : 'the target';
        return `Host${numOfHosts > 1 ? 's' : ''} ${hostNames} willl use this address to establish a direct connection to protection target for data transfer. It is usually one of ${targetName}'s network interface addresses.`
    }

    return <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} fullWidth maxWidth={'md'}>
        <DialogTopBar dialogState={p.dialogState} title={'Pair Host With Protection Target'} divider
                      onDialogClosed={() => state.resetConnectToPhoenixState()}/>
        <DialogContent>
            <Box pb={1}>
                <Typography>
                    1. Select a protection target
                </Typography>
            </Box>
            <ProtectionTargetSelectionCard/>
            <br/>
            <Box pb={1}>
                <Typography>
                    2. Enter a data transfer address
                </Typography>
            </Box>
            <Box width={'100%'}>
                <TextField value={state.connectionAddress}
                           onChange={(e) => state.setConnectionAddress(e.target.value)} fullWidth variant={'filled'}
                           label={'Data Transfer Address'}
                           helperText={getHelperText()}/>
            </Box>
        </DialogContent>
        <Divider/>
        <DialogActions sx={{padding: 2}}>
            <Button variant={'outlined'} color={'neutral'} onClick={onCancel}>Cancel</Button>
            <Button variant={'contained'} color={'primary'} onClick={onSubmit}
                    disabled={state.isSubmitDisabled}>Connect</Button>
        </DialogActions>
    </Dialog>
});

// ======================
// ProtectionTargetSelectionCard
// ======================

interface ProtectionTargetSelectionCardProps {
}

const ProtectionTargetSelectionCard: React.FC<ProtectionTargetSelectionCardProps> = observer((p) => {
    const {cpDeploymentService, phoenixDeploymentService} = useAppServices();
    const state = cpDeploymentService.connectToPhoenixState;

    useInitData({
        poll: () => phoenixDeploymentService.phoenixDeployments.fetchData(),
        pollInterval: 30
    });

    return renderServerDataWithLoadingList(phoenixDeploymentService.phoenixDeployments, data => {
        return <Card sx={{backgroundColor: (t: Theme) => t.palette.background.default}}>
            <Box>
                <RadioGroup value={state.selectedPhoenixDeployment}>
                    {data.getItemsList().sort((x, y) => (x.getDeployment().getConnected() === y.getDeployment().getConnected()) ? 0 : x.getDeployment().getConnected() ? -1 : 1).map((d) => {
                        return <Box key={d.getDeployment().getSystemId()}>
                            <Box p={2}>
                                <FormControlLabel value={d.getDeployment().getSystemId()}
                                                  control={<Radio color={'secondary'}
                                                                  checked={d.getDeployment().getSystemId() === state.selectedPhoenixDeployment?.id}
                                                                  disabled={!d.getDeployment().getConnected()}
                                                                  onChange={(e) => state.selectPhoenixDeployment({
                                                                      id: d.getDeployment().getSystemId(),
                                                                      name: d.getDeployment().getSystemName()
                                                                  })}/>}
                                                  label={<Box pl={2}>
                                                      <Box pb={1}>
                                                          <Typography>{d.getDeployment().getSystemName()}</Typography>
                                                      </Box>
                                                      <ConnectedStatusChip
                                                          connected={d.getDeployment().getConnected()}/> &nbsp;
                                                      <Chip label={d.getDeployment().getVersion()}/> &nbsp;
                                                      <Chip
                                                          label={getHostEnvDisplayName(d.getDeployment().getHostEnvironment().getValue())}/>
                                                  </Box>}/>
                            </Box>
                            <Divider/>
                        </Box>
                    })

                    }
                </RadioGroup>
            </Box>
        </Card>
    })

})
