import React from "react";
import {observer} from "mobx-react-lite";
import {useParams, Routes, Route} from "react-router-dom";
import {renderServerDataWithLoadingBox, useInitData} from "../../core/data/DataLoaderHooks";
import {useAppServices} from "../../app/services";
import {ScreenContainer, ScreenTitleBar} from "../../layout/ScreenCommon";
import {
    Box,
    Button,
    Card,
    Chip,
    Divider,
    Grid,
    ListItem,
    ListItemText,
    ListSubheader,
    Typography
} from "@mui/material";
import {formatKnownDataType, KnownDataType} from "../../../common/utils/formatter";
import {getHostEnvDisplayName} from "../../galaxymigrate/GalaxyMigrateCommon";
import {generatePhoenixDeploymentDetailsPath, useNavigateToPhoenixDeploymentsList} from "../ProjectProtectionCommon";
import {PHOENIX_DEPLOYMENT_DETAILS_SUBROUTE} from "../../app/AppRoutes";
import {useCurrentProjectID} from "../../project/CurrentProject";
import {RouterTabConfig, RouterTabs} from "../../../common/tabs/TabComponents";
import {OperatorView} from "../../auth/AuthenticatedViews";
import {PhoenixDeploymentInfo} from "../../../_proto/galaxycompletepb/apipb/domainpb/phoenix_pb";
import {renderDeploymentStatusChip} from "./PhoenixDeploymentsCommon";
import {ActionMenuButton} from "../../../common/actions/CommonActions";
import {DeleteIcon} from "../../../common/CommonIcons";

// ======================
// PhoenixDeploymentDetails
// ======================

interface PhoenixDeploymentDetailsProps {
}

export const PhoenixDeploymentDetailsScreen: React.FC<PhoenixDeploymentDetailsProps> = observer((p) => {
    const {deploymentId} = useParams();
    const projectId = useCurrentProjectID();
    const {phoenixDeploymentService} = useAppServices();
    const currentPath = generatePhoenixDeploymentDetailsPath(deploymentId, projectId);

    const goBackToDeploymentList = useNavigateToPhoenixDeploymentsList();

    useInitData({
        poll: () => phoenixDeploymentService.phoenixDeploymentDetails.fetchData(deploymentId),
        pollInterval: 3
    });

    return renderServerDataWithLoadingBox(phoenixDeploymentService.phoenixDeploymentDetails, data => {
        const serverInfo = data.getInfo();
        const deployment = serverInfo.getDeployment();

        const tabsConfig: RouterTabConfig[] = [
            {
                path: '',
                label: 'Overview',
                renderer: () => <PhoenixDeploymentDetailsOverviewSection data={serverInfo}/>,
                default: true
            },
            // {
            //     path: PHOENIX_DEPLOYMENT_DETAILS_SUBROUTE.PHOENIX_STORAGE,
            //     label: 'Phoenix Storage',
            //     component: <PhoenixDeploymentDetailsStorageSection data={serverInfo}/>
            // },
            {
                path: PHOENIX_DEPLOYMENT_DETAILS_SUBROUTE.DATA_SOURCE,
                label: 'Data Source',
                renderer: () => <div>Data Source</div>,
                disabled: true
            }
        ];

        const getActions = () => (<OperatorView>
                <Box display={'flex'}>
                    <Box>
                        <ActionMenuButton actions={[
                            {
                                id: 'remove',
                                name: 'Remove Deployment',
                                action: async() => {
                                    await phoenixDeploymentService.removeDeployment(deploymentId);
                                    goBackToDeploymentList()
                                },
                                icon: <DeleteIcon/>
                            }
                        ]}/>
                    </Box>
                </Box>

            </OperatorView>
        );

        return <ScreenContainer>
            <ScreenTitleBar title={deployment.getSystemName()} actions={getActions()}/>
            <Box>
                <Typography variant={'body2'} color={'textSecondary'}>Last
                    check-in: {formatKnownDataType(deployment.getLastCheckin()?.toDate(), KnownDataType.DATE_RELATIVE)}</Typography>
                <Box pt={1} pb={1}>
                    <Chip label={deployment.getVersion()}/> &nbsp;
                    <Chip label={getHostEnvDisplayName(deployment.getHostEnvironment().getValue())}/> &nbsp;
                    {renderDeploymentStatusChip(deployment.getConnected())}
                </Box>
            </Box>
            <RouterTabs configs={tabsConfig} path={currentPath}/>
        </ScreenContainer>
    })
});

// ======================
// PhoenixDeploymentDetailsOverviewSection
// ======================

interface PhoenixDeploymentDetailsOverviewSectionProps {
    data: PhoenixDeploymentInfo
}

const PhoenixDeploymentDetailsOverviewSection: React.FC<PhoenixDeploymentDetailsOverviewSectionProps> = observer((p) => {

    return <Box pt={4}>
        <Grid container spacing={3}>
{/*            <Grid item xs={3}>
                <Card>
                    <Box p={2}>
                        <ListItem>
                            <ListItemText primary={p.data.get} secondary={'CDM Policies'}/>
                        </ListItem>
                    </Box>
                </Card>
            </Grid>*/}
            <Grid item xs={4}>
                <Card>
                    <Box p={2}>
                        <ListItem>
                            <ListItemText primary={p.data.getTotalSnapshot()} secondary={'Total Snapshots'}/>
                        </ListItem>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={4}>
                <Card>
                    <Box p={2}>
                        <ListItem>
                            <ListItemText primary={p.data.getMtdiPaired()} secondary={'mTDI Paired'}/>
                        </ListItem>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={4}>
                <Card>
                    <Box p={2}>
                        <ListItem>
                            <ListItemText primary={p.data.getTdiPaired()} secondary={'TDI Paired'}/>
                        </ListItem>
                    </Box>
                </Card>
            </Grid>
        </Grid>
        <br/>
        <Grid container spacing={3}>
            <Grid item xs={12} md={9}>
                <Card sx={{height:'100%'}}>
                    <ListSubheader>System Information</ListSubheader>
                    <ListItem>
                        <ListItemText primary={p.data.getCpu()} secondary={'CPU'}/>
                    </ListItem>
                    <Divider/>
                    <Box display={'flex'}>
                        <ListItem>
                            <ListItemText primary={formatKnownDataType(p.data.getMemory(), KnownDataType.CAPACITY)}
                                          secondary={'Total Memory'}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={formatKnownDataType(p.data.getMemoryUsed(), KnownDataType.CAPACITY)}
                                          secondary={'Memory Used'}/>
                        </ListItem>
                    </Box>
                    <Divider/>
                    <ListItem>
                        <ListItemText primary={p.data.getHealthMessage()} secondary={'Health Status'}/>
                    </ListItem>
                </Card>
            </Grid>
            <Grid item xs={12} md={3}>
                <Box pb={2} >
                    <Card>
                        <ListSubheader>Physical Storage</ListSubheader>
                        <Box display={'flex'}>
                            <ListItem>
                                <ListItemText
                                    primary={formatKnownDataType(p.data.getTotalStorage(), KnownDataType.CAPACITY)}
                                    secondary={'Total Storage'}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={formatKnownDataType(p.data.getTotalStorageUsed(), KnownDataType.CAPACITY)}
                                    secondary={'Storage Used'}/>
                            </ListItem>
                        </Box>
                    </Card>
                </Box>
                <Box pb={2}>
                    <Card>
                        <ListSubheader>Data Storage</ListSubheader>
                        <ListItem>
                            <ListItemText primary={formatKnownDataType(p.data.getTotalData(), KnownDataType.CAPACITY)}
                                          secondary={'Total User Data'}/>
                        </ListItem>
                    </Card>
                </Box>
                <Box pb={2}>
                    <Card>
                        <ListSubheader>Storage Efficiency</ListSubheader>
                        <Box display={'flex'}>
                            <ListItem>
                                <ListItemText
                                    primary={formatKnownDataType(p.data.getTotalData(), KnownDataType.CAPACITY)}
                                    secondary={'Volume Provisioned'}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={`${p.data.getReductionRatio()}:1`}
                                              secondary={'Data Reduction Ratio'}/>
                            </ListItem>
                        </Box>
                    </Card>
                </Box>
            </Grid>

        </Grid>
    </Box>
});

// ======================
// PhoenixDeploymentDetailsStorageSection
// ======================

interface PhoenixDeploymentDetailsStorageSectionProps {
    data: PhoenixDeploymentInfo

}

const PhoenixDeploymentDetailsStorageSection: React.FC<PhoenixDeploymentDetailsStorageSectionProps> = observer((p) => {

    return <Box pt={4}>
        <Box pb={4}>
            <Typography variant={'h5'}>
                Phoenix Hosts
            </Typography>
            <Typography variant={'body2'} color={'textSecondary'}>
                Client hosts that are provisioned storage from the PhoenixOS System
            </Typography>
        </Box>
        <Box pb={4}>
            <Typography variant={'h5'}>
                Phoenix Volumes
            </Typography>
            <Typography variant={'body2'} color={'textSecondary'}>
                Volumes contained within the PhoenixOS System
            </Typography>
        </Box>
    </Box>
})