// Project: GalaxyComplete
// Created: 9/29/20 by sammy
// File: ProjectMembersList

import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {ColumnDef, DataTable} from '../../common/table/DataTable';
import {ProjectMemberInfo} from '../../_proto/galaxycompletepb/apipb/domainpb/project_pb';
import {
    Box,
    Button,
    Card,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    SvgIcon, Theme,
    Typography
} from '@mui/material';
import {HubUser} from '../../_proto/galaxycompletepb/apipb/domainpb/user_pb';
import {getProjectMemberUserFullName, renderProjectUserRole, UserAvatar} from './ProjectUsers';
import {useAppServices} from '../app/services';
import {
    ConfirmDialogConfig,
    DialogState,
    useDialogState,
    useShouldDialogFullScreen
} from '../core/dialog/DialogService';
import {useInitData} from '../core/data/DataLoaderHooks';
import {Form, Formik} from 'formik';
import * as yup from 'yup';
import {FormRadioGroup, FormTextField} from '../../common/form/FormComponents';
import {AiOutlineSend} from 'react-icons/ai';
import {DeleteIcon} from "../../common/CommonIcons";
import {UserSettingsIcon} from "../project/ProjectCommon";
import {AdminView, useIsAdminView} from "../auth/AuthenticatedViews";
import {CloseDialogButton, DialogTopBar} from "../core/dialog/DialogComponents";
import {getUserIsLastAdmin} from "./ProjectSettingsCommon";
import {getIsPrivateEdition} from "../auth/PrivateEditionView";
import {HintButton, KnownArticle} from "../help/HelpCommon";
import {useCallback} from "react";

// ======================
// ProjectMembersArea
// ======================
interface ProjectMembersAreaProps {
}

export const ProjectMembersArea: React.FC<ProjectMembersAreaProps> = observer((p) => {
    const {projectManagementService, dialogService, projectService, userService} = useAppServices();
    const projectMembers = projectManagementService.projectMembers;
    const currentUser = userService.currentUser;

    const changeUserRoleDialogState = useDialogState();
    const currentProjectId = projectService.currentProjectID
    const isAdmin = useIsAdminView();
    const isPrivateEdition = getIsPrivateEdition();

    useInitData({
                    init: async () => {
                        await currentUser.fetchData();
                        await projectMembers.fetchData()
                    },
                    pollInterval: 30,
                    poll: () => projectMembers.fetchData(),
                });

    const renderUserStyle = (supportUser?: boolean)=>{
        if (supportUser){
            return {color: (t: Theme)=>t.palette.success.light }
        }
        return {}
    }

    const cols: ColumnDef<ProjectMemberInfo>[] = [
        {
            id: 'avatar',
            label: '',
            getter: (r: ProjectMemberInfo) => r.getUser(),
            renderer: (d: HubUser, _) => <UserAvatar user={d.toObject()}/>,
        },
        {
            id: 'email',
            label: 'Email',
            getter: (r: ProjectMemberInfo) => <Typography sx={renderUserStyle(r.getAsSupportStaff())}>{r.getUser().getEmail()}</Typography>,
        },
        {
            id: 'name',
            label: 'Name',
            getter: (r: ProjectMemberInfo) => <Typography sx={renderUserStyle(r.getAsSupportStaff())}>{r.getUser().getFirstName()} {r.getUser().getLastName()}</Typography>,
        },
        {
            id: 'role',
            label: 'Role',
            getter: (r: ProjectMemberInfo) => r,
            renderer: (d, r) => {
                if (r.getAsSupportStaff()){
                    return <Typography sx={{color: (t: Theme)=>t.palette.success.light }}>Support</Typography>
                }
                else {
                    return renderProjectUserRole(r.getRole())

                }
            },
        },
    ];

    const getRowActions = (r: ProjectMemberInfo) => ([
        {
            id: 'remove',
            name: isPrivateEdition ? 'Remove User Account' : `Remove ${getProjectMemberUserFullName(r)} From Project`,
            action: async () => {
                const userDisplayName = r.getUser().getFirstName() ? getProjectMemberUserFullName(r) : 'this user';
                const confirmConfig: ConfirmDialogConfig = isPrivateEdition ? {
                    title: 'Permanently Delete User Account Confirmation',
                    message: `By continuing, ${userDisplayName} will lose access, and member user account will be permanently deleted.`,
                    autoConfirmationQuestionLine: false,
                    typeToConfirm: 'DELETE'
                } : {
                    title:  'Remove User Confirmation',
                    message: `Are you sure you want to remove ${userDisplayName} from this project?`,
                    autoConfirmationQuestionLine: false,
                }
                const confirmed = await dialogService.addConfirmDialog(confirmConfig)
                if (confirmed) {
                    const removed = await projectManagementService.removeMemberFromCurrentProject(r)
                    if (removed) {
                        await projectMembers.fetchData();
                    }
                }
            },
            icon: <DeleteIcon/>,
            hidden: !isAdmin

        },
        {
            id: 'changeUserRole',
            name: `Change User Role`,
            action: async () => {
                changeUserRoleDialogState.setDialogProps({
                                                             userRole: r.getRole(),
                                                             userID: r.getUser().getUserId(),
                                                             projectID: currentProjectId,
                                                             userName: r.getUser().getFirstName()
                                                         })
                changeUserRoleDialogState.open()
            },
            icon: <UserSettingsIcon/>,
            hidden: !isAdmin || r.getAsSupportStaff()
        }
    ])

    return (<><Card>
        <CardHeader title={`Project Members`} subheader={`Access is granted to the following users`}
                    action={<InviteNewMemberButton/>}/>
        <DataTable rows={projectMembers?.data?.getItemsList()}
                   cols={cols}
                   loading={projectMembers.loading}
                   state={projectMembers.tableState}
                   pagerMeta={projectMembers?.data?.getPagerMeta().toObject()}
                   rowActions={(r) => getRowActions(r)}
                   onTableStateChange={() => projectMembers.fetchData()}/>
    </Card>

        {changeUserRoleDialogState.isOpen && <ChangeUserRoleDialog dialogState={changeUserRoleDialogState}/>}

    </>);
});


// ======================
// InviteNewMemberButton
// ======================
interface InviteNewMemberButtonProps {
}

const InviteNewMemberButton: React.FC<InviteNewMemberButtonProps> = observer((p) => {
    const dialogState = useDialogState();

    return (<AdminView><Box p={2}>
        <Button variant={'outlined'} size={'large'} onClick={dialogState.open}>{`Invite New Members`}</Button>
        <InviteNewMembersDialog dialogState={dialogState}/>
    </Box></AdminView>);
});


interface InviteNewMembersDialogProps {
    dialogState: DialogState;
}

const InviteNewMembersDialog: React.FC<InviteNewMembersDialogProps> = observer((p) => {
    const initialValues = {
        emails: [] as string[],
        role: ProjectMemberInfo.Role.OPERATOR
    };
    const {projectManagementService, projectService} = useAppServices();

    const schema = yup.object({
                                  emails: yup.array().required('at least one email address is required').transform(function (value, originalValue) {
                                          if (this.isType(value) && value !== null) {
                                              return value;
                                          }
                                          return originalValue ? originalValue.split(/[\s,]+/) : [];
                                      })
                                      .of(yup.string().email(({value}) => `${value} is not a valid email`)),
                                  role: yup.number()
                              }).strict(false);

    return (<Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} maxWidth={'sm'} fullWidth
                    fullScreen={useShouldDialogFullScreen()}>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={async (values) => {
            const data = await schema.validate(values);
            await projectManagementService.inviteNewMemberToCurrentProject(data.emails.filter(e => !!e).map(e => e.toLowerCase()), data.role);
            p.dialogState.close();
            await projectManagementService.projectMembers.fetchData();
        }}>
            {props => {
                return <Form>
                    <DialogTopBar dialogState={p.dialogState} title={`Invite New Members to Project`}/>
                    <DialogContent>
                        <Typography variant={'h6'}>
                            1. Invitee Email Addresses
                        </Typography>
                        <DialogContentText>
                            {'Enter one or more email addresses (comma-separated) of the invitees and we will send the account creation and access instructions'}
                        </DialogContentText>
                        <br/>
                        <FormTextField name={'emails'} label={'Type or copy and paste email addresses here'} multiline
                                       rows={4}/>
                        <br/>
                        <br/>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography variant={'h6'}>
                                2. User Role
                            </Typography>
                            <HintButton articleID={KnownArticle.PROJECT_MEMBER_ROLES}/>
                        </Box>

                        <DialogContentText>
                            {'Choose a user role for the invitee(s) above.'}
                        </DialogContentText>
                        <br/>
                        <FormRadioGroup label={''} name={'role'} options={[
                            {
                                label: 'Monitor',
                                value: ProjectMemberInfo.Role.MONITOR
                            },
                            {
                                label: 'Operator',
                                value: ProjectMemberInfo.Role.OPERATOR
                            },
                            {
                                label: 'Admin',
                                value: ProjectMemberInfo.Role.ADMIN
                            },
                        ]}/>
                    </DialogContent>
                    <DialogActions>
                        <Box p={2}>
                            <Button type={'submit'} variant={'outlined'}
                                    startIcon={<SvgIcon><AiOutlineSend/></SvgIcon>} size={'large'}>
                                {'Send Invitations'}
                            </Button>
                        </Box>

                    </DialogActions>
                </Form>;
            }}

        </Formik>
    </Dialog>);
});

// ======================
// ChangeUserRoleDialog
// ======================

interface ChangeUserRoleDialogProps {
    dialogState: DialogState;
}

export const ChangeUserRoleDialog: React.FC<ChangeUserRoleDialogProps> = observer((props) => {
    const {dialogState} = props;
    const {projectManagementService} = useAppServices();


    const initialValues = {
        userRole: dialogState.dialogProps?.userRole.toString()
    }

    const schema = yup.object({
                                  userRole: yup.string().required()
                              })

    const options = [
        {label: 'Monitor', value: ProjectMemberInfo.Role.MONITOR},
        {label: 'Operator', value: ProjectMemberInfo.Role.OPERATOR},
        {label: 'Admin', value: ProjectMemberInfo.Role.ADMIN},
    ];

    return (<Dialog open={dialogState.isOpen} onClose={dialogState.close} maxWidth={'sm'} fullWidth
                    fullScreen={useShouldDialogFullScreen()}>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={async (values) => {
            const validatedValues = await schema.validate(values);
            const newUserRole = parseInt(validatedValues.userRole);
            const userID = dialogState.dialogProps?.userID;
            const projectID = dialogState.dialogProps?.projectID;

            const changed = await projectManagementService.changeProjectMemberRole(projectID, userID, newUserRole)
            if (changed) {
                dialogState.close();
            }
            await projectManagementService.projectMembers.fetchData();
        }}>
            {props => {
                return <Form>
                    <DialogTopBar dialogState={dialogState} title={'Change User Role'}/>
                    <DialogContent>
                        <DialogContentText>
                            {`Choose A New User Role for ${dialogState.dialogProps?.userName}:`}
                        </DialogContentText>
                        <FormRadioGroup name={'userRole'} label={''} options={options}/>
                    </DialogContent>
                    <DialogActions>
                        <Box p={2}>
                            <Button type={'submit'} variant={'outlined'}
                                    size={'large'}>
                                {'Change User Role'}
                            </Button>
                        </Box>
                    </DialogActions>
                </Form>;
            }}
        </Formik>
    </Dialog>)
})
