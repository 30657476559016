/**
 * @fileoverview gRPC-Web generated client stub for galaxycomplete.api.phoenix
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: galaxycompletepb/apipb/phoenixapipb/phoenix_api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb from '../../../galaxycompletepb/apipb/phoenixapipb/phoenix_api_pb';


export class PhoenixServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListCDMPolicies = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.phoenix.PhoenixService/ListCDMPolicies',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicies.Request,
    galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicies.Response,
    (request: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicies.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicies.Response.deserializeBinary
  );

  listCDMPolicies(
    request: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicies.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicies.Response>;

  listCDMPolicies(
    request: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicies.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicies.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicies.Response>;

  listCDMPolicies(
    request: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicies.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicies.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.phoenix.PhoenixService/ListCDMPolicies',
        request,
        metadata || {},
        this.methodDescriptorListCDMPolicies,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.phoenix.PhoenixService/ListCDMPolicies',
    request,
    metadata || {},
    this.methodDescriptorListCDMPolicies);
  }

  methodDescriptorListCDMPolicyDisks = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.phoenix.PhoenixService/ListCDMPolicyDisks',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicyDisks.Request,
    galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicyDisks.Response,
    (request: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicyDisks.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicyDisks.Response.deserializeBinary
  );

  listCDMPolicyDisks(
    request: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicyDisks.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicyDisks.Response>;

  listCDMPolicyDisks(
    request: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicyDisks.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicyDisks.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicyDisks.Response>;

  listCDMPolicyDisks(
    request: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicyDisks.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.ListCDMPolicyDisks.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.phoenix.PhoenixService/ListCDMPolicyDisks',
        request,
        metadata || {},
        this.methodDescriptorListCDMPolicyDisks,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.phoenix.PhoenixService/ListCDMPolicyDisks',
    request,
    metadata || {},
    this.methodDescriptorListCDMPolicyDisks);
  }

  methodDescriptorGetCDMPolicyDetails = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.phoenix.PhoenixService/GetCDMPolicyDetails',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.GetCDMPolicyDetails.Request,
    galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.GetCDMPolicyDetails.Response,
    (request: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.GetCDMPolicyDetails.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.GetCDMPolicyDetails.Response.deserializeBinary
  );

  getCDMPolicyDetails(
    request: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.GetCDMPolicyDetails.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.GetCDMPolicyDetails.Response>;

  getCDMPolicyDetails(
    request: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.GetCDMPolicyDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.GetCDMPolicyDetails.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.GetCDMPolicyDetails.Response>;

  getCDMPolicyDetails(
    request: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.GetCDMPolicyDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_phoenixapipb_phoenix_api_pb.GetCDMPolicyDetails.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.phoenix.PhoenixService/GetCDMPolicyDetails',
        request,
        metadata || {},
        this.methodDescriptorGetCDMPolicyDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.phoenix.PhoenixService/GetCDMPolicyDetails',
    request,
    metadata || {},
    this.methodDescriptorGetCDMPolicyDetails);
  }

}

