import {observer} from "mobx-react-lite";
import React from "react";
import {DialogState} from "../../core/dialog/DialogService";
import {DialogTopBar} from "../../core/dialog/DialogComponents";
import *  as yup from 'yup';
import {useIsDesktop} from "../../layout/MainLayout";
import {
    Button,
    SvgIcon,
    Tooltip,
    IconButton,
    DialogContent,
    Typography,
    Box,
    DialogActions,
    Divider, Card, Switch, Chip, ListItem, ListItemText, Dialog
} from "@mui/material";
import {MdAdd} from "react-icons/md";
import {Formik, FormikValues} from "formik";
import {FormDateTimePicker, FormTextField} from "../../../common/form/FormComponents";
import {EditIcon} from "../../../common/CommonIcons";

// ======================
// CdmActionCard
// ======================

interface CdmActionCardProps {
    dialogState: DialogState;
}

export const CdmActionCard: React.FC<CdmActionCardProps> = observer((p) => {

    const onClick = () => {
        p.dialogState.setDialogProps({
            initialValues: {
                name: 'Hourly Snapshot',
                startTime: new Date(),
                repeatType: 'Minute',
                repeatInterval: 10,
                retentionValue: 4,
                retentionUnit: 'Hours'
            },
            type: 'edit'
        })
        p.dialogState.open()
    }
    return <Card>
        <Box p={2} display={'flex'} justifyContent={'space-between'}>
            <Box display={'flex'} alignItems={'center'}>
                <Box pr={1}>
                    <Switch/>

                </Box>
                <Box>
                    <Typography>Hourly Snapshot</Typography>
                    <Box pt={1}>
                        <Chip label={'Online'} color={'success'}/>
                    </Box>
                </Box>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
                <Tooltip title={'Edit CDM Action'}>
                    <IconButton onClick={onClick}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
            </Box>

        </Box>
        <Divider/>
        <ListItem>
            <ListItemText primary={'Every 1 Hour(s) from 2021-08-12 08:00:00 PM'} secondary={'Schedule'}/>
        </ListItem>
        <Divider/>
        <ListItem>
            <ListItemText primary={'1 Week'} secondary={'Snapshot Retention'}/>
        </ListItem>
        <Divider/>
        <ListItem>
            <ListItemText primary={'2'} secondary={'Snapshots Taken'}/>
        </ListItem>
    </Card>
});

// ======================
// AddCdmActionButton
// ======================

interface AddCdmActionButtonProps {
    dialogState: DialogState;
}

export const AddCdmActionButton: React.FC<AddCdmActionButtonProps> = observer((p) => {
    const onClick = () => {
        p.dialogState.setDialogProps({
            type: 'create'
        });
        p.dialogState.open()
    }
    const isDesktop = useIsDesktop();
    return isDesktop ? <Button startIcon={<SvgIcon><MdAdd/></SvgIcon>} onClick={onClick}>Add Action</Button> :
        <Tooltip title={'Add New CDM Action'}>
            <IconButton onClick={onClick}>
                <SvgIcon>
                    <MdAdd/>
                </SvgIcon>
            </IconButton>
        </Tooltip>
});

// ======================
// CdmActionDialog
// ======================

interface CdmActionDialogProps {
    dialogState: DialogState;
}

export const CdmActionDialog: React.FC<CdmActionDialogProps> = observer((p) => {
    const type = p.dialogState.dialogProps?.type;

    const validationSchema = yup.object({
        name: yup.string(),
        startTime: yup.date(),
        repeatType: yup.string(),
        repeatInterval: yup.number(),
        retentionValue: yup.number(),
        retentionUnit: yup.string()
    });

    const getInitialValues = () => {
        if (type === 'create') {
            return {
                name: '',
                startTime: new Date(),
                repeatType: '',
                repeatInterval: null,
                retentionValue: '',
                retentionUnit: ''
            }
        } else {
            return p.dialogState.dialogProps?.initialValues
        }
    };

    const onSubmit = async (values: FormikValues) => {

    }

    return <>
        {p.dialogState.isOpen &&
        <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} fullWidth maxWidth={'md'}>
            <DialogTopBar dialogState={p.dialogState} title={`${type === 'edit' ? 'Edit' : 'Add New'} CDM Action`}/>
            <Formik initialValues={getInitialValues()} onSubmit={onSubmit} validationSchema={validationSchema}>
                {props => <><DialogContent>
                    <Box pb={2}>
                        <Typography>
                            1. CDM Action Name
                        </Typography>
                        <br/>
                        <FormTextField label={'CDM Action Name'} name={'name'} required fullWidth/>
                    </Box>
                    <Box pb={2}>
                        <Typography>
                            2. Schedule-Based Trigger
                        </Typography>
                        <br/>
                        <Box pb={2}>
                            <FormDateTimePicker label={'Start Time'} name={'startTime'}/>
                        </Box>
                        <Box display={'flex'}>
                            <Box pr={1} width={'20%'}>
                                <FormTextField label={'Repeat Type'} name={'repeatType'} fullWidth/>
                            </Box>
                            <Box pl={1} width={'80%'}>
                                <FormTextField label={'Repeat Interval'} name={'repeatInterval'} fullWidth/>
                            </Box>
                        </Box>
                    </Box>
                    <Box pb={2}>
                        <Typography>
                            3. Snapshot Retention
                        </Typography>
                        <br/>
                        <Box display={'flex'}>
                            <Box pr={1} width={'20%'}>
                                <FormTextField label={'Unit'} name={'retentionUnit'} fullWidth/>
                            </Box>
                            <Box pl={1} width={'80%'}>
                                <FormTextField label={'Keep Policy Snapshots For'} name={'retentionValue'} fullWidth/>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                    <Divider/>
                    <DialogActions>
                        <Button onClick={p.dialogState.close} variant={'outlined'}>cancel</Button>
                        <Button type={'submit'} variant={'contained'}>submit</Button>

                    </DialogActions>
                </>
                }
            </Formik>
        </Dialog>
        }
    </>
})