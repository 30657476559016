// ======================
// Footer
// ======================

import { observer } from "mobx-react-lite";
import { BottomNavigation, Box, Theme, alpha, Link as MuiLink, Typography } from "@mui/material";
import React from "react";
import { ApiLink, EULALink, HelpCenterLink, PrivacyPolicyLink, TermsOfServiceLink, useNavigateToExternalLink } from "../help/HelpCommon";

interface FooterProps {}

export const UnauthenticatedViewFooter: React.FC<FooterProps> = observer((p) => {
    const currentYear = new Date(Date.now()).getFullYear();
    const navigateToCirrusWebsite = useNavigateToExternalLink("https://www.cirrusdata.com/");
    return (
        <Box
            width={"100%"}
            sx={{
                padding: 2,
                top: "auto",
                bottom: 0,
                position: "fixed",
                backgroundColor: (t: Theme) => t.palette.cirrus.main,
                boxShadow: "0px -1px 6px rgba(0,0,0,0.3)",
            }}
        >
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Box>
                    <TermsOfServiceLink variant={"caption"} />
                </Box>
                &nbsp; | &nbsp;
                <Box>
                    <PrivacyPolicyLink variant={"caption"} />
                </Box>
                &nbsp; | &nbsp;
                <Box>
                    <HelpCenterLink variant={"caption"} />
                </Box>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
                <MuiLink onClick={navigateToCirrusWebsite} color={"textSecondary"}>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        © {currentYear} Cirrus Data Solutions Inc.
                    </Typography>
                </MuiLink>
            </Box>{" "}
        </Box>
    );
});
