// source: galaxycompletepb/apipb/phoenixapipb/phoenix_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var galaxycompletepb_commonpb_datafilter_pb = require('../../../galaxycompletepb/commonpb/datafilter_pb.js');
goog.object.extend(proto, galaxycompletepb_commonpb_datafilter_pb);
var galaxycompletepb_apipb_domainpb_phoenix_pb = require('../../../galaxycompletepb/apipb/domainpb/phoenix_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_phoenix_pb);
var google_api_annotations_pb = require('../../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var validate_validate_pb = require('../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.phoenix.ListCDMPolicies', null, global);
goog.exportSymbol('proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks', null, global);
goog.exportSymbol('proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.phoenix.ListCDMPolicies, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.phoenix.ListCDMPolicies.displayName = 'proto.galaxycomplete.api.phoenix.ListCDMPolicies';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.displayName = 'proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.displayName = 'proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.displayName = 'proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.displayName = 'proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.displayName = 'proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.displayName = 'proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request.displayName = 'proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response.displayName = 'proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.phoenix.ListCDMPolicies.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicies} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicies}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.phoenix.ListCDMPolicies;
  return proto.galaxycomplete.api.phoenix.ListCDMPolicies.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicies} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicies}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.phoenix.ListCDMPolicies.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicies} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageParams: (f = msg.getPageParams()) && galaxycompletepb_commonpb_datafilter_pb.PagerParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request;
  return proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerParams;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerParams.deserializeBinaryFromReader);
      msg.setPageParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request} returns this
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.common.PagerParams page_params = 2;
 * @return {?proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.prototype.getPageParams = function() {
  return /** @type{?proto.galaxycomplete.common.PagerParams} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerParams, 2));
};


/**
 * @param {?proto.galaxycomplete.common.PagerParams|undefined} value
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request} returns this
*/
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.prototype.setPageParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request} returns this
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.prototype.clearPageParams = function() {
  return this.setPageParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Request.prototype.hasPageParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerMeta: (f = msg.getPagerMeta()) && galaxycompletepb_commonpb_datafilter_pb.PagerMeta.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicy.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response;
  return proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerMeta;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerMeta.deserializeBinaryFromReader);
      msg.setPagerMeta(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicy;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicy.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerMeta.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicy.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.common.PagerMeta pager_meta = 1;
 * @return {?proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.prototype.getPagerMeta = function() {
  return /** @type{?proto.galaxycomplete.common.PagerMeta} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerMeta, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerMeta|undefined} value
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response} returns this
*/
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.prototype.setPagerMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response} returns this
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.prototype.clearPagerMeta = function() {
  return this.setPagerMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.prototype.hasPagerMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated galaxycomplete.domain.CDMPolicy items = 2;
 * @return {!Array<!proto.galaxycomplete.domain.CDMPolicy>}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.CDMPolicy>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicy, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.CDMPolicy>} value
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response} returns this
*/
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.CDMPolicy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.CDMPolicy}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.CDMPolicy, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response} returns this
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicies.Response.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks;
  return proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageParams: (f = msg.getPageParams()) && galaxycompletepb_commonpb_datafilter_pb.PagerParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request;
  return proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyId(value);
      break;
    case 2:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerParams;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerParams.deserializeBinaryFromReader);
      msg.setPageParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string policy_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.prototype.getPolicyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request} returns this
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.prototype.setPolicyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.common.PagerParams page_params = 2;
 * @return {?proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.prototype.getPageParams = function() {
  return /** @type{?proto.galaxycomplete.common.PagerParams} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerParams, 2));
};


/**
 * @param {?proto.galaxycomplete.common.PagerParams|undefined} value
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request} returns this
*/
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.prototype.setPageParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request} returns this
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.prototype.clearPageParams = function() {
  return this.setPageParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Request.prototype.hasPageParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerMeta: (f = msg.getPagerMeta()) && galaxycompletepb_commonpb_datafilter_pb.PagerMeta.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDisk.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response;
  return proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerMeta;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerMeta.deserializeBinaryFromReader);
      msg.setPagerMeta(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDisk;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDisk.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerMeta.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDisk.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.common.PagerMeta pager_meta = 1;
 * @return {?proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.prototype.getPagerMeta = function() {
  return /** @type{?proto.galaxycomplete.common.PagerMeta} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerMeta, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerMeta|undefined} value
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response} returns this
*/
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.prototype.setPagerMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response} returns this
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.prototype.clearPagerMeta = function() {
  return this.setPagerMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.prototype.hasPagerMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated galaxycomplete.domain.CDMPolicyDisk items = 2;
 * @return {!Array<!proto.galaxycomplete.domain.CDMPolicyDisk>}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.CDMPolicyDisk>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDisk, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.CDMPolicyDisk>} value
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response} returns this
*/
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.CDMPolicyDisk=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk}
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.CDMPolicyDisk, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response} returns this
 */
proto.galaxycomplete.api.phoenix.ListCDMPolicyDisks.Response.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails}
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails;
  return proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails}
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request}
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request;
  return proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request}
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string policy_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request.prototype.getPolicyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request} returns this
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Request.prototype.setPolicyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    policy: (f = msg.getPolicy()) && galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response}
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response;
  return proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response}
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDetails;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDetails.deserializeBinaryFromReader);
      msg.setPolicy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.CDMPolicyDetails policy = 1;
 * @return {?proto.galaxycomplete.domain.CDMPolicyDetails}
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response.prototype.getPolicy = function() {
  return /** @type{?proto.galaxycomplete.domain.CDMPolicyDetails} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDetails, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.CDMPolicyDetails|undefined} value
 * @return {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response} returns this
*/
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response.prototype.setPolicy = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response} returns this
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response.prototype.clearPolicy = function() {
  return this.setPolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.phoenix.GetCDMPolicyDetails.Response.prototype.hasPolicy = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.galaxycomplete.api.phoenix);
