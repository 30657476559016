import {
    Alert,
    AlertTitle,
    Box,
    Card,
    CardContent,
    Link,
    ListSubheader,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import { ColumnDef, flexRender, getCoreRowModel, OnChangeFn, PaginationState, useReactTable } from "@tanstack/react-table";
import EmptyTable from "../../assets/image_empty_folder.png";
import * as React from "react";
import { TableLoadingIndicator } from "./DataTable";
import { HELP_CENTER_URL, useNavigateToExternalLink, useNavigateToHelpCenterKb } from "../../modules/help/HelpCommon";
import { RefreshButton } from "../CommonIcons";
import { QueryObserverResult, UseQueryResult } from "@tanstack/react-query";

interface EmptyTableConfig {
    emptyTableIcon?: React.ReactNode;
    emptyTableTitle?: React.ReactNode;
    emptyTableMessage?: React.ReactNode;
    emptyTableActionButton?: React.ReactNode;
}

interface QueryTableProps<TData, TError> {
    pagination?: PaginationState;
    setPagination?: OnChangeFn<PaginationState>;
    pageCount?: number;
    data: Array<TData>;
    isLoading?: boolean;
    isError?: boolean;
    columns: ColumnDef<TData, any>[];
    title?: string;
    emptyTableConfig?: EmptyTableConfig;
    error?: TError;
    refetch?: () => Promise<QueryObserverResult<any, TError>>;
}

export const QueryTable = <TData, TError>(p: QueryTableProps<TData, TError>) => {
    const theme = useTheme();
    const goToHelpCenter = useNavigateToHelpCenterKb();

    const { refetch, title, error, isLoading, isError, data, pageCount, columns, pagination, setPagination, emptyTableConfig } = p;
    const refreshButton = (
        <Box pr={2}>
            <RefreshButton onClick={() => refetch()} variant={"outlined"} color={"inherit"} />
        </Box>
    );
    const table = useReactTable<TData>({
        data: data || [],
        getCoreRowModel: getCoreRowModel(),
        columns: columns,
        pageCount: pageCount,
        state: {
            pagination,
        },
        onPaginationChange: setPagination,
        manualPagination: true,
    });

    if (isError) {
        return (
            <Box width={"100%"} p={2}>
                <Alert severity={"error"} action={refreshButton}>
                    <AlertTitle>Error Encountered</AlertTitle>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Box>{(error as unknown as Error).message}</Box>
                    </Box>
                </Alert>
            </Box>
        );
    }

    const tableContent = (
        <Table>
            <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <TableCell key={header.id} variant={"head"}>
                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody>
                {isLoading && <TableLoadingIndicator />}

                {table.getRowModel().rows.map((row) => (
                    <TableRow key={row.id} hover>
                        {row.getVisibleCells().map((cell) => (
                            <TableCell key={cell.id} variant={"body"}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
                {data?.length === 0 && (
                    <TableRow>
                        <TableCell colSpan={columns.length} align={"center"}>
                            <Box pt={6} pb={6}>
                                <Box pb={2}>{emptyTableConfig?.emptyTableIcon || <img src={EmptyTable} alt={"No Data Yet"} height={"50px"} />}</Box>
                                <Box pb={1}>
                                    <Typography variant={"h6"}>{emptyTableConfig?.emptyTableTitle || <>No Data Found</>}</Typography>
                                </Box>
                                <Box pb={2}>
                                    <Typography variant={"body1"}>
                                        {emptyTableConfig?.emptyTableMessage || (
                                            <>
                                                Get started now or visit our <Link onClick={goToHelpCenter}>Help Center</Link> for additional information.
                                            </>
                                        )}
                                    </Typography>
                                </Box>
                                <Box p={2}>{emptyTableConfig?.emptyTableActionButton}</Box>
                            </Box>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );

    // const paginationContent =
    //               (pagination && data?.length) > 0 ? (
    //                       <Group position={"right"}>
    //                           <Pagination
    //                                   total={table.getPageCount()}
    //                                   page={table.getState().pagination.pageIndex + 1}
    //                                   onChange={(page: number) => {
    //                                       table.setPageIndex(page - 1);
    //                                   }}
    //                                   withEdges
    //                           />
    //                       </Group>
    //               ) : null;

    return (
        <>
            <TableContainer component={Card}>
                {!!title && <ListSubheader>{title}</ListSubheader>}
                {tableContent}
            </TableContainer>
        </>
    );
};
