export const getDataByDotNotation = (obj: { [key: string]: any }, path: string): any => {
    const arr = path.split(/[.[]['"]?/);
    let o     = obj;
    while (arr.length && o)
    {
        o = o[arr.shift().replace(/['"]?]$/, '')];
    }
    return o;
};

const DEFAULT_OPEN_ANCHOR  = /{{/g;
const DEFAULT_CLOSE_ANCHOR = /}}/g;
const DEFAULT_ANCHOR_REGEX = /{{.*?}}/g;

export function transformStringWithTemplate<DataType extends {}>(template: string, data: DataType) {
    if (!template)
    {
        return template;
    }
    let out = template;
    template.match(DEFAULT_ANCHOR_REGEX)?.forEach(chunk => {
        const dataPath = chunk.replace(DEFAULT_OPEN_ANCHOR, '').replace(DEFAULT_CLOSE_ANCHOR, '').trim();
        const value    = getDataByDotNotation(data, dataPath) ?? chunk;
        out            = out.replace(chunk, `${value}`);
    });
    return out;
}