// source: galaxycompletepb/apipb/integration_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var galaxycompletepb_apipb_domainpb_integration_pb = require('../../galaxycompletepb/apipb/domainpb/integration_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_integration_pb);
var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var google_api_annotations_pb = require('../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.galaxycomplete.api.AddProjectIntegration', null, global);
goog.exportSymbol('proto.galaxycomplete.api.AddProjectIntegration.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.AddProjectIntegration.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetAllProjectIntegrations', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetAllProjectIntegrations.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetAllProjectIntegrations.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetEnabledIntegration', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetEnabledIntegration.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetEnabledIntegration.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.IntegrationParams', null, global);
goog.exportSymbol('proto.galaxycomplete.api.IntegrationParams.ConnParams', null, global);
goog.exportSymbol('proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS', null, global);
goog.exportSymbol('proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure', null, global);
goog.exportSymbol('proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS', null, global);
goog.exportSymbol('proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean', null, global);
goog.exportSymbol('proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax', null, global);
goog.exportSymbol('proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore', null, global);
goog.exportSymbol('proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP', null, global);
goog.exportSymbol('proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp', null, global);
goog.exportSymbol('proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle', null, global);
goog.exportSymbol('proto.galaxycomplete.api.IntegrationParams.ConnParams.ParamsCase', null, global);
goog.exportSymbol('proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS', null, global);
goog.exportSymbol('proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp', null, global);
goog.exportSymbol('proto.galaxycomplete.api.RemoveProjectIntegration', null, global);
goog.exportSymbol('proto.galaxycomplete.api.RemoveProjectIntegration.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.RemoveProjectIntegration.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.TestProjectIntegration', null, global);
goog.exportSymbol('proto.galaxycomplete.api.TestProjectIntegration.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.TestProjectIntegration.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.UpdateProjectIntegration', null, global);
goog.exportSymbol('proto.galaxycomplete.api.UpdateProjectIntegration.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.UpdateProjectIntegration.Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetEnabledIntegration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetEnabledIntegration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetEnabledIntegration.displayName = 'proto.galaxycomplete.api.GetEnabledIntegration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetEnabledIntegration.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetEnabledIntegration.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetEnabledIntegration.Request.displayName = 'proto.galaxycomplete.api.GetEnabledIntegration.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetEnabledIntegration.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetEnabledIntegration.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetEnabledIntegration.Response.displayName = 'proto.galaxycomplete.api.GetEnabledIntegration.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetAllProjectIntegrations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetAllProjectIntegrations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetAllProjectIntegrations.displayName = 'proto.galaxycomplete.api.GetAllProjectIntegrations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetAllProjectIntegrations.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetAllProjectIntegrations.Request.displayName = 'proto.galaxycomplete.api.GetAllProjectIntegrations.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.GetAllProjectIntegrations.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.GetAllProjectIntegrations.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetAllProjectIntegrations.Response.displayName = 'proto.galaxycomplete.api.GetAllProjectIntegrations.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.AddProjectIntegration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.AddProjectIntegration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.AddProjectIntegration.displayName = 'proto.galaxycomplete.api.AddProjectIntegration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.AddProjectIntegration.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.AddProjectIntegration.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.AddProjectIntegration.Request.displayName = 'proto.galaxycomplete.api.AddProjectIntegration.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.AddProjectIntegration.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.AddProjectIntegration.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.AddProjectIntegration.Response.displayName = 'proto.galaxycomplete.api.AddProjectIntegration.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.UpdateProjectIntegration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.UpdateProjectIntegration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.UpdateProjectIntegration.displayName = 'proto.galaxycomplete.api.UpdateProjectIntegration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.UpdateProjectIntegration.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.UpdateProjectIntegration.Request.displayName = 'proto.galaxycomplete.api.UpdateProjectIntegration.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.UpdateProjectIntegration.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.UpdateProjectIntegration.Response.displayName = 'proto.galaxycomplete.api.UpdateProjectIntegration.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.RemoveProjectIntegration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.RemoveProjectIntegration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.RemoveProjectIntegration.displayName = 'proto.galaxycomplete.api.RemoveProjectIntegration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.RemoveProjectIntegration.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.RemoveProjectIntegration.Request.displayName = 'proto.galaxycomplete.api.RemoveProjectIntegration.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.RemoveProjectIntegration.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.RemoveProjectIntegration.Response.displayName = 'proto.galaxycomplete.api.RemoveProjectIntegration.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.TestProjectIntegration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.TestProjectIntegration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.TestProjectIntegration.displayName = 'proto.galaxycomplete.api.TestProjectIntegration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.TestProjectIntegration.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.TestProjectIntegration.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.TestProjectIntegration.Request.displayName = 'proto.galaxycomplete.api.TestProjectIntegration.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.TestProjectIntegration.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.TestProjectIntegration.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.TestProjectIntegration.Response.displayName = 'proto.galaxycomplete.api.TestProjectIntegration.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.IntegrationParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.IntegrationParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.IntegrationParams.displayName = 'proto.galaxycomplete.api.IntegrationParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_);
};
goog.inherits(proto.galaxycomplete.api.IntegrationParams.ConnParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.IntegrationParams.ConnParams.displayName = 'proto.galaxycomplete.api.IntegrationParams.ConnParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.displayName = 'proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.displayName = 'proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.displayName = 'proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.displayName = 'proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.displayName = 'proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.displayName = 'proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.displayName = 'proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.displayName = 'proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP.displayName = 'proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.displayName = 'proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean.displayName = 'proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetEnabledIntegration.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetEnabledIntegration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetEnabledIntegration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetEnabledIntegration.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetEnabledIntegration}
 */
proto.galaxycomplete.api.GetEnabledIntegration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetEnabledIntegration;
  return proto.galaxycomplete.api.GetEnabledIntegration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetEnabledIntegration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetEnabledIntegration}
 */
proto.galaxycomplete.api.GetEnabledIntegration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetEnabledIntegration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetEnabledIntegration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetEnabledIntegration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetEnabledIntegration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetEnabledIntegration.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetEnabledIntegration.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetEnabledIntegration.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetEnabledIntegration.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetEnabledIntegration.Request}
 */
proto.galaxycomplete.api.GetEnabledIntegration.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetEnabledIntegration.Request;
  return proto.galaxycomplete.api.GetEnabledIntegration.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetEnabledIntegration.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetEnabledIntegration.Request}
 */
proto.galaxycomplete.api.GetEnabledIntegration.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetEnabledIntegration.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetEnabledIntegration.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetEnabledIntegration.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetEnabledIntegration.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.galaxycomplete.api.GetEnabledIntegration.Request.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.GetEnabledIntegration.Request} returns this
 */
proto.galaxycomplete.api.GetEnabledIntegration.Request.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetEnabledIntegration.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetEnabledIntegration.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetEnabledIntegration.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetEnabledIntegration.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetEnabledIntegration.Response}
 */
proto.galaxycomplete.api.GetEnabledIntegration.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetEnabledIntegration.Response;
  return proto.galaxycomplete.api.GetEnabledIntegration.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetEnabledIntegration.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetEnabledIntegration.Response}
 */
proto.galaxycomplete.api.GetEnabledIntegration.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetEnabledIntegration.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetEnabledIntegration.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetEnabledIntegration.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetEnabledIntegration.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.IntegrationConfigInfo info = 1;
 * @return {?proto.galaxycomplete.domain.IntegrationConfigInfo}
 */
proto.galaxycomplete.api.GetEnabledIntegration.Response.prototype.getInfo = function() {
  return /** @type{?proto.galaxycomplete.domain.IntegrationConfigInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.IntegrationConfigInfo|undefined} value
 * @return {!proto.galaxycomplete.api.GetEnabledIntegration.Response} returns this
*/
proto.galaxycomplete.api.GetEnabledIntegration.Response.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.GetEnabledIntegration.Response} returns this
 */
proto.galaxycomplete.api.GetEnabledIntegration.Response.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.GetEnabledIntegration.Response.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetAllProjectIntegrations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetAllProjectIntegrations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetAllProjectIntegrations}
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetAllProjectIntegrations;
  return proto.galaxycomplete.api.GetAllProjectIntegrations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetAllProjectIntegrations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetAllProjectIntegrations}
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetAllProjectIntegrations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetAllProjectIntegrations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetAllProjectIntegrations.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetAllProjectIntegrations.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetAllProjectIntegrations.Request}
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetAllProjectIntegrations.Request;
  return proto.galaxycomplete.api.GetAllProjectIntegrations.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetAllProjectIntegrations.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetAllProjectIntegrations.Request}
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetAllProjectIntegrations.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetAllProjectIntegrations.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.GetAllProjectIntegrations.Request} returns this
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetAllProjectIntegrations.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetAllProjectIntegrations.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetAllProjectIntegrations.Response}
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetAllProjectIntegrations.Response;
  return proto.galaxycomplete.api.GetAllProjectIntegrations.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetAllProjectIntegrations.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetAllProjectIntegrations.Response}
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetAllProjectIntegrations.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetAllProjectIntegrations.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated galaxycomplete.domain.IntegrationConfigInfo items = 2;
 * @return {!Array<!proto.galaxycomplete.domain.IntegrationConfigInfo>}
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Response.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.IntegrationConfigInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.IntegrationConfigInfo>} value
 * @return {!proto.galaxycomplete.api.GetAllProjectIntegrations.Response} returns this
*/
proto.galaxycomplete.api.GetAllProjectIntegrations.Response.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.IntegrationConfigInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.IntegrationConfigInfo}
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Response.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.IntegrationConfigInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.GetAllProjectIntegrations.Response} returns this
 */
proto.galaxycomplete.api.GetAllProjectIntegrations.Response.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.AddProjectIntegration.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.AddProjectIntegration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.AddProjectIntegration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.AddProjectIntegration.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.AddProjectIntegration}
 */
proto.galaxycomplete.api.AddProjectIntegration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.AddProjectIntegration;
  return proto.galaxycomplete.api.AddProjectIntegration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.AddProjectIntegration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.AddProjectIntegration}
 */
proto.galaxycomplete.api.AddProjectIntegration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.AddProjectIntegration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.AddProjectIntegration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.AddProjectIntegration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.AddProjectIntegration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.AddProjectIntegration.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.AddProjectIntegration.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    module: jspb.Message.getFieldWithDefault(msg, 2, 0),
    friendlyName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    connParams: (f = msg.getConnParams()) && proto.galaxycomplete.api.IntegrationParams.ConnParams.toObject(includeInstance, f),
    properties: (f = msg.getProperties()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    verboseLogging: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    deploymentIdForValidation: jspb.Message.getFieldWithDefault(msg, 20, ""),
    statusId: jspb.Message.getFieldWithDefault(msg, 21, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Request}
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.AddProjectIntegration.Request;
  return proto.galaxycomplete.api.AddProjectIntegration.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.AddProjectIntegration.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Request}
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {!proto.galaxycomplete.domain.IntegrationModule} */ (reader.readEnum());
      msg.setModule(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFriendlyName(value);
      break;
    case 11:
      var value = new proto.galaxycomplete.api.IntegrationParams.ConnParams;
      reader.readMessage(value,proto.galaxycomplete.api.IntegrationParams.ConnParams.deserializeBinaryFromReader);
      msg.setConnParams(value);
      break;
    case 12:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVerboseLogging(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentIdForValidation(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.AddProjectIntegration.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.AddProjectIntegration.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModule();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFriendlyName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getConnParams();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.galaxycomplete.api.IntegrationParams.ConnParams.serializeBinaryToWriter
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getVerboseLogging();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getDeploymentIdForValidation();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getStatusId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.domain.IntegrationModule module = 2;
 * @return {!proto.galaxycomplete.domain.IntegrationModule}
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.getModule = function() {
  return /** @type {!proto.galaxycomplete.domain.IntegrationModule} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.IntegrationModule} value
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.setModule = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string friendly_name = 10;
 * @return {string}
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.getFriendlyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.setFriendlyName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional IntegrationParams.ConnParams conn_params = 11;
 * @return {?proto.galaxycomplete.api.IntegrationParams.ConnParams}
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.getConnParams = function() {
  return /** @type{?proto.galaxycomplete.api.IntegrationParams.ConnParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.IntegrationParams.ConnParams, 11));
};


/**
 * @param {?proto.galaxycomplete.api.IntegrationParams.ConnParams|undefined} value
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Request} returns this
*/
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.setConnParams = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.clearConnParams = function() {
  return this.setConnParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.hasConnParams = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Struct properties = 12;
 * @return {?proto.google.protobuf.Struct}
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.getProperties = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 12));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Request} returns this
*/
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool verbose_logging = 13;
 * @return {boolean}
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.getVerboseLogging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.setVerboseLogging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string deployment_id_for_validation = 20;
 * @return {string}
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.getDeploymentIdForValidation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.setDeploymentIdForValidation = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string status_id = 21;
 * @return {string}
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.getStatusId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.AddProjectIntegration.Request.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.AddProjectIntegration.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.AddProjectIntegration.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.AddProjectIntegration.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.AddProjectIntegration.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Response}
 */
proto.galaxycomplete.api.AddProjectIntegration.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.AddProjectIntegration.Response;
  return proto.galaxycomplete.api.AddProjectIntegration.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.AddProjectIntegration.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Response}
 */
proto.galaxycomplete.api.AddProjectIntegration.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.AddProjectIntegration.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.AddProjectIntegration.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.AddProjectIntegration.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.AddProjectIntegration.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.IntegrationConfigInfo info = 1;
 * @return {?proto.galaxycomplete.domain.IntegrationConfigInfo}
 */
proto.galaxycomplete.api.AddProjectIntegration.Response.prototype.getInfo = function() {
  return /** @type{?proto.galaxycomplete.domain.IntegrationConfigInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.IntegrationConfigInfo|undefined} value
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Response} returns this
*/
proto.galaxycomplete.api.AddProjectIntegration.Response.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.AddProjectIntegration.Response} returns this
 */
proto.galaxycomplete.api.AddProjectIntegration.Response.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.AddProjectIntegration.Response.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.UpdateProjectIntegration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.UpdateProjectIntegration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.UpdateProjectIntegration.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.UpdateProjectIntegration;
  return proto.galaxycomplete.api.UpdateProjectIntegration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.UpdateProjectIntegration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.UpdateProjectIntegration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.UpdateProjectIntegration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.UpdateProjectIntegration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.UpdateProjectIntegration.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.UpdateProjectIntegration.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    friendlyName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    connParams: (f = msg.getConnParams()) && proto.galaxycomplete.api.IntegrationParams.ConnParams.toObject(includeInstance, f),
    properties: (f = msg.getProperties()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    verboseLogging: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    deploymentIdForValidation: jspb.Message.getFieldWithDefault(msg, 20, ""),
    statusId: jspb.Message.getFieldWithDefault(msg, 21, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration.Request}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.UpdateProjectIntegration.Request;
  return proto.galaxycomplete.api.UpdateProjectIntegration.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.UpdateProjectIntegration.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration.Request}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFriendlyName(value);
      break;
    case 11:
      var value = new proto.galaxycomplete.api.IntegrationParams.ConnParams;
      reader.readMessage(value,proto.galaxycomplete.api.IntegrationParams.ConnParams.deserializeBinaryFromReader);
      msg.setConnParams(value);
      break;
    case 12:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVerboseLogging(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentIdForValidation(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.UpdateProjectIntegration.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.UpdateProjectIntegration.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFriendlyName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getConnParams();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.galaxycomplete.api.IntegrationParams.ConnParams.serializeBinaryToWriter
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getVerboseLogging();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getDeploymentIdForValidation();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getStatusId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string friendly_name = 10;
 * @return {string}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.getFriendlyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.setFriendlyName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional IntegrationParams.ConnParams conn_params = 11;
 * @return {?proto.galaxycomplete.api.IntegrationParams.ConnParams}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.getConnParams = function() {
  return /** @type{?proto.galaxycomplete.api.IntegrationParams.ConnParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.IntegrationParams.ConnParams, 11));
};


/**
 * @param {?proto.galaxycomplete.api.IntegrationParams.ConnParams|undefined} value
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration.Request} returns this
*/
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.setConnParams = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.clearConnParams = function() {
  return this.setConnParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.hasConnParams = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Struct properties = 12;
 * @return {?proto.google.protobuf.Struct}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.getProperties = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 12));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration.Request} returns this
*/
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool verbose_logging = 13;
 * @return {boolean}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.getVerboseLogging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.setVerboseLogging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string deployment_id_for_validation = 20;
 * @return {string}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.getDeploymentIdForValidation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.setDeploymentIdForValidation = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string status_id = 21;
 * @return {string}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.getStatusId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Request.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.UpdateProjectIntegration.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.UpdateProjectIntegration.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration.Response}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.UpdateProjectIntegration.Response;
  return proto.galaxycomplete.api.UpdateProjectIntegration.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.UpdateProjectIntegration.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration.Response}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.UpdateProjectIntegration.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.UpdateProjectIntegration.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.IntegrationConfigInfo info = 1;
 * @return {?proto.galaxycomplete.domain.IntegrationConfigInfo}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Response.prototype.getInfo = function() {
  return /** @type{?proto.galaxycomplete.domain.IntegrationConfigInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_integration_pb.IntegrationConfigInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.IntegrationConfigInfo|undefined} value
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration.Response} returns this
*/
proto.galaxycomplete.api.UpdateProjectIntegration.Response.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.UpdateProjectIntegration.Response} returns this
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Response.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.UpdateProjectIntegration.Response.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.RemoveProjectIntegration.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.RemoveProjectIntegration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.RemoveProjectIntegration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveProjectIntegration.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.RemoveProjectIntegration}
 */
proto.galaxycomplete.api.RemoveProjectIntegration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.RemoveProjectIntegration;
  return proto.galaxycomplete.api.RemoveProjectIntegration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.RemoveProjectIntegration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.RemoveProjectIntegration}
 */
proto.galaxycomplete.api.RemoveProjectIntegration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.RemoveProjectIntegration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.RemoveProjectIntegration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.RemoveProjectIntegration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveProjectIntegration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.RemoveProjectIntegration.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.RemoveProjectIntegration.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.RemoveProjectIntegration.Request}
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.RemoveProjectIntegration.Request;
  return proto.galaxycomplete.api.RemoveProjectIntegration.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.RemoveProjectIntegration.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.RemoveProjectIntegration.Request}
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.RemoveProjectIntegration.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.RemoveProjectIntegration.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Request.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.RemoveProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Request.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.RemoveProjectIntegration.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.RemoveProjectIntegration.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.RemoveProjectIntegration.Response}
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.RemoveProjectIntegration.Response;
  return proto.galaxycomplete.api.RemoveProjectIntegration.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.RemoveProjectIntegration.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.RemoveProjectIntegration.Response}
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.RemoveProjectIntegration.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.RemoveProjectIntegration.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveProjectIntegration.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.TestProjectIntegration.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.TestProjectIntegration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.TestProjectIntegration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TestProjectIntegration.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.TestProjectIntegration}
 */
proto.galaxycomplete.api.TestProjectIntegration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.TestProjectIntegration;
  return proto.galaxycomplete.api.TestProjectIntegration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.TestProjectIntegration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.TestProjectIntegration}
 */
proto.galaxycomplete.api.TestProjectIntegration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.TestProjectIntegration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.TestProjectIntegration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.TestProjectIntegration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TestProjectIntegration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.TestProjectIntegration.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.TestProjectIntegration.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.TestProjectIntegration.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TestProjectIntegration.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    deploymentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statusId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.TestProjectIntegration.Request}
 */
proto.galaxycomplete.api.TestProjectIntegration.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.TestProjectIntegration.Request;
  return proto.galaxycomplete.api.TestProjectIntegration.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.TestProjectIntegration.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.TestProjectIntegration.Request}
 */
proto.galaxycomplete.api.TestProjectIntegration.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeploymentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.TestProjectIntegration.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.TestProjectIntegration.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.TestProjectIntegration.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TestProjectIntegration.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDeploymentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatusId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.galaxycomplete.api.TestProjectIntegration.Request.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.TestProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.TestProjectIntegration.Request.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string deployment_id = 2;
 * @return {string}
 */
proto.galaxycomplete.api.TestProjectIntegration.Request.prototype.getDeploymentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.TestProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.TestProjectIntegration.Request.prototype.setDeploymentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status_id = 3;
 * @return {string}
 */
proto.galaxycomplete.api.TestProjectIntegration.Request.prototype.getStatusId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.TestProjectIntegration.Request} returns this
 */
proto.galaxycomplete.api.TestProjectIntegration.Request.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.TestProjectIntegration.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.TestProjectIntegration.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.TestProjectIntegration.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TestProjectIntegration.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.TestProjectIntegration.Response}
 */
proto.galaxycomplete.api.TestProjectIntegration.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.TestProjectIntegration.Response;
  return proto.galaxycomplete.api.TestProjectIntegration.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.TestProjectIntegration.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.TestProjectIntegration.Response}
 */
proto.galaxycomplete.api.TestProjectIntegration.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.TestProjectIntegration.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.TestProjectIntegration.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.TestProjectIntegration.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TestProjectIntegration.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.IntegrationParams.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.IntegrationParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.IntegrationParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.IntegrationParams}
 */
proto.galaxycomplete.api.IntegrationParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.IntegrationParams;
  return proto.galaxycomplete.api.IntegrationParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.IntegrationParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.IntegrationParams}
 */
proto.galaxycomplete.api.IntegrationParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.IntegrationParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.IntegrationParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.IntegrationParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13]];

/**
 * @enum {number}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.ParamsCase = {
  PARAMS_NOT_SET: 0,
  PURECBS: 1,
  AZURE: 2,
  DGS: 3,
  AWS: 4,
  NETAPP: 5,
  FC_PUREFA: 6,
  POWERSTORE: 7,
  FC_POWERSTORE: 8,
  FC_POWERMAX: 9,
  GCP: 10,
  ORACLE: 11,
  SILKSDP: 12,
  DIGITALOCEAN: 13
};

/**
 * @return {proto.galaxycomplete.api.IntegrationParams.ConnParams.ParamsCase}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.getParamsCase = function() {
  return /** @type {proto.galaxycomplete.api.IntegrationParams.ConnParams.ParamsCase} */(jspb.Message.computeOneofCase(this, proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    purecbs: (f = msg.getPurecbs()) && proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.toObject(includeInstance, f),
    azure: (f = msg.getAzure()) && proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.toObject(includeInstance, f),
    dgs: (f = msg.getDgs()) && proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.toObject(includeInstance, f),
    aws: (f = msg.getAws()) && proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.toObject(includeInstance, f),
    netapp: (f = msg.getNetapp()) && proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.toObject(includeInstance, f),
    fcPurefa: (f = msg.getFcPurefa()) && proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.toObject(includeInstance, f),
    powerstore: (f = msg.getPowerstore()) && proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.toObject(includeInstance, f),
    fcPowerstore: (f = msg.getFcPowerstore()) && proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.toObject(includeInstance, f),
    fcPowermax: (f = msg.getFcPowermax()) && proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.toObject(includeInstance, f),
    gcp: (f = msg.getGcp()) && proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP.toObject(includeInstance, f),
    oracle: (f = msg.getOracle()) && proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.toObject(includeInstance, f),
    silksdp: (f = msg.getSilksdp()) && proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.toObject(includeInstance, f),
    digitalocean: (f = msg.getDigitalocean()) && proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean.toObject(includeInstance, f),
    verboseLogging: jspb.Message.getBooleanFieldWithDefault(msg, 100, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.IntegrationParams.ConnParams;
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS;
      reader.readMessage(value,proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.deserializeBinaryFromReader);
      msg.setPurecbs(value);
      break;
    case 2:
      var value = new proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure;
      reader.readMessage(value,proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.deserializeBinaryFromReader);
      msg.setAzure(value);
      break;
    case 3:
      var value = new proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS;
      reader.readMessage(value,proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.deserializeBinaryFromReader);
      msg.setDgs(value);
      break;
    case 4:
      var value = new proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS;
      reader.readMessage(value,proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.deserializeBinaryFromReader);
      msg.setAws(value);
      break;
    case 5:
      var value = new proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp;
      reader.readMessage(value,proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.deserializeBinaryFromReader);
      msg.setNetapp(value);
      break;
    case 6:
      var value = new proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS;
      reader.readMessage(value,proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.deserializeBinaryFromReader);
      msg.setFcPurefa(value);
      break;
    case 7:
      var value = new proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore;
      reader.readMessage(value,proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.deserializeBinaryFromReader);
      msg.setPowerstore(value);
      break;
    case 8:
      var value = new proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore;
      reader.readMessage(value,proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.deserializeBinaryFromReader);
      msg.setFcPowerstore(value);
      break;
    case 9:
      var value = new proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax;
      reader.readMessage(value,proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.deserializeBinaryFromReader);
      msg.setFcPowermax(value);
      break;
    case 10:
      var value = new proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP;
      reader.readMessage(value,proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP.deserializeBinaryFromReader);
      msg.setGcp(value);
      break;
    case 11:
      var value = new proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle;
      reader.readMessage(value,proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.deserializeBinaryFromReader);
      msg.setOracle(value);
      break;
    case 12:
      var value = new proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp;
      reader.readMessage(value,proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.deserializeBinaryFromReader);
      msg.setSilksdp(value);
      break;
    case 13:
      var value = new proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean;
      reader.readMessage(value,proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean.deserializeBinaryFromReader);
      msg.setDigitalocean(value);
      break;
    case 100:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVerboseLogging(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.IntegrationParams.ConnParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurecbs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.serializeBinaryToWriter
    );
  }
  f = message.getAzure();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.serializeBinaryToWriter
    );
  }
  f = message.getDgs();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.serializeBinaryToWriter
    );
  }
  f = message.getAws();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.serializeBinaryToWriter
    );
  }
  f = message.getNetapp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.serializeBinaryToWriter
    );
  }
  f = message.getFcPurefa();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.serializeBinaryToWriter
    );
  }
  f = message.getPowerstore();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.serializeBinaryToWriter
    );
  }
  f = message.getFcPowerstore();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.serializeBinaryToWriter
    );
  }
  f = message.getFcPowermax();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.serializeBinaryToWriter
    );
  }
  f = message.getGcp();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP.serializeBinaryToWriter
    );
  }
  f = message.getOracle();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.serializeBinaryToWriter
    );
  }
  f = message.getSilksdp();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.serializeBinaryToWriter
    );
  }
  f = message.getDigitalocean();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean.serializeBinaryToWriter
    );
  }
  f = message.getVerboseLogging();
  if (f) {
    writer.writeBool(
      100,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.toObject = function(includeInstance, msg) {
  var f, obj = {
    managementUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    apiToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS;
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagementUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagementUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApiToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string management_uri = 1;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.prototype.getManagementUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.prototype.setManagementUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string api_token = 2;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.prototype.getApiToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS.prototype.setApiToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenant: jspb.Message.getFieldWithDefault(msg, 1, ""),
    client: jspb.Message.getFieldWithDefault(msg, 2, ""),
    secret: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure;
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenant(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClient(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenant();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClient();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSecret();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string tenant = 1;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.prototype.getTenant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.prototype.setTenant = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client = 2;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.prototype.getClient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.prototype.setClient = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string secret = 3;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.prototype.getSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure.prototype.setSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endpoint: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS;
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndpoint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string endpoint = 3;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.prototype.getEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS.prototype.setEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    secretAccessKey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS;
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecretAccessKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecretAccessKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string access_key = 1;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.prototype.getAccessKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.prototype.setAccessKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secret_access_key = 2;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.prototype.getSecretAccessKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS.prototype.setSecretAccessKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endpoint: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp;
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndpoint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string endpoint = 3;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.prototype.getEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp.prototype.setEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endpoint: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp;
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndpoint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string endpoint = 3;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.prototype.getEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp.prototype.setEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endpoint: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore;
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndpoint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string endpoint = 3;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.prototype.getEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore.prototype.setEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endpoint: jspb.Message.getFieldWithDefault(msg, 3, ""),
    arrayid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax;
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpoint(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrayid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndpoint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getArrayid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string endpoint = 3;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.prototype.getEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.prototype.setEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string arrayID = 4;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.prototype.getArrayid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax.prototype.setArrayid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP.toObject = function(includeInstance, msg) {
  var f, obj = {
    credentials: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP;
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCredentials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCredentials();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string credentials = 1;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP.prototype.getCredentials = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP.prototype.setCredentials = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.toObject = function(includeInstance, msg) {
  var f, obj = {
    ocid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fingerprint: jspb.Message.getFieldWithDefault(msg, 2, ""),
    privateKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tenancy: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle;
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOcid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFingerprint(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenancy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOcid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFingerprint();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrivateKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTenancy();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string ocid = 1;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.prototype.getOcid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.prototype.setOcid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fingerprint = 2;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.prototype.getFingerprint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.prototype.setFingerprint = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string private_key = 3;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.prototype.getPrivateKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.prototype.setPrivateKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tenancy = 4;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.prototype.getTenancy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle.prototype.setTenancy = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean;
  return proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string api_token = 1;
 * @return {string}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean.prototype.getApiToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean.prototype.setApiToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PureCBS purecbs = 1;
 * @return {?proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.getPurecbs = function() {
  return /** @type{?proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS, 1));
};


/**
 * @param {?proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS|undefined} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
*/
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.setPurecbs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.clearPurecbs = function() {
  return this.setPurecbs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.hasPurecbs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Azure azure = 2;
 * @return {?proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.getAzure = function() {
  return /** @type{?proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure, 2));
};


/**
 * @param {?proto.galaxycomplete.api.IntegrationParams.ConnParams.Azure|undefined} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
*/
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.setAzure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.clearAzure = function() {
  return this.setAzure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.hasAzure = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DGS dgs = 3;
 * @return {?proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.getDgs = function() {
  return /** @type{?proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS, 3));
};


/**
 * @param {?proto.galaxycomplete.api.IntegrationParams.ConnParams.DGS|undefined} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
*/
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.setDgs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.clearDgs = function() {
  return this.setDgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.hasDgs = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AWS aws = 4;
 * @return {?proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.getAws = function() {
  return /** @type{?proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS, 4));
};


/**
 * @param {?proto.galaxycomplete.api.IntegrationParams.ConnParams.AWS|undefined} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
*/
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.setAws = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.clearAws = function() {
  return this.setAws(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.hasAws = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NetApp netapp = 5;
 * @return {?proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.getNetapp = function() {
  return /** @type{?proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp, 5));
};


/**
 * @param {?proto.galaxycomplete.api.IntegrationParams.ConnParams.NetApp|undefined} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
*/
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.setNetapp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.clearNetapp = function() {
  return this.setNetapp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.hasNetapp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PureCBS fc_purefa = 6;
 * @return {?proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.getFcPurefa = function() {
  return /** @type{?proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS, 6));
};


/**
 * @param {?proto.galaxycomplete.api.IntegrationParams.ConnParams.PureCBS|undefined} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
*/
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.setFcPurefa = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.clearFcPurefa = function() {
  return this.setFcPurefa(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.hasFcPurefa = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional EMCPowerStore powerstore = 7;
 * @return {?proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.getPowerstore = function() {
  return /** @type{?proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore, 7));
};


/**
 * @param {?proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore|undefined} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
*/
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.setPowerstore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.clearPowerstore = function() {
  return this.setPowerstore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.hasPowerstore = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EMCPowerStore fc_powerstore = 8;
 * @return {?proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.getFcPowerstore = function() {
  return /** @type{?proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore, 8));
};


/**
 * @param {?proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerStore|undefined} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
*/
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.setFcPowerstore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.clearFcPowerstore = function() {
  return this.setFcPowerstore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.hasFcPowerstore = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EMCPowerMax fc_powermax = 9;
 * @return {?proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.getFcPowermax = function() {
  return /** @type{?proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax, 9));
};


/**
 * @param {?proto.galaxycomplete.api.IntegrationParams.ConnParams.EMCPowerMax|undefined} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
*/
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.setFcPowermax = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.clearFcPowermax = function() {
  return this.setFcPowermax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.hasFcPowermax = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional GCP gcp = 10;
 * @return {?proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.getGcp = function() {
  return /** @type{?proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP, 10));
};


/**
 * @param {?proto.galaxycomplete.api.IntegrationParams.ConnParams.GCP|undefined} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
*/
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.setGcp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.clearGcp = function() {
  return this.setGcp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.hasGcp = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Oracle oracle = 11;
 * @return {?proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.getOracle = function() {
  return /** @type{?proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle, 11));
};


/**
 * @param {?proto.galaxycomplete.api.IntegrationParams.ConnParams.Oracle|undefined} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
*/
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.setOracle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.clearOracle = function() {
  return this.setOracle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.hasOracle = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional SilkSdp silksdp = 12;
 * @return {?proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.getSilksdp = function() {
  return /** @type{?proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp, 12));
};


/**
 * @param {?proto.galaxycomplete.api.IntegrationParams.ConnParams.SilkSdp|undefined} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
*/
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.setSilksdp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.clearSilksdp = function() {
  return this.setSilksdp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.hasSilksdp = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional DigitalOcean digitalocean = 13;
 * @return {?proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.getDigitalocean = function() {
  return /** @type{?proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean, 13));
};


/**
 * @param {?proto.galaxycomplete.api.IntegrationParams.ConnParams.DigitalOcean|undefined} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
*/
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.setDigitalocean = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.galaxycomplete.api.IntegrationParams.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.clearDigitalocean = function() {
  return this.setDigitalocean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.hasDigitalocean = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool verbose_logging = 100;
 * @return {boolean}
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.getVerboseLogging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.IntegrationParams.ConnParams} returns this
 */
proto.galaxycomplete.api.IntegrationParams.ConnParams.prototype.setVerboseLogging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 100, value);
};


goog.object.extend(exports, proto.galaxycomplete.api);
