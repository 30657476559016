import {
    WorkflowStage,
    WorkflowStageStatus,
    WorkflowStatus
} from "../../../_proto/galaxycompletepb/apipb/domainpb/workflow_pb";
import {Box, Link, Theme, Typography, TypographyProps, useTheme} from "@mui/material";
import * as React from "react";
import {observer} from "mobx-react-lite";
import {useAppServices} from "../../app/services";
import {useCurrentProjectID} from "../../project/CurrentProject";
import {useInitData} from "../../core/data/DataLoaderHooks";
import {Link as RouterLink} from "react-router-dom";
import {generateIntegrationsSubRoutePath} from "../../integrations/IntegrationsCommon";
import {INTEGRATIONS_SUBROUTE} from "../../app/AppRoutes";
import {KVTable} from "../../../common/table/KVTable";


export const usePostSyncActionStyles = () => {
    const t = useTheme();
    return {
        card: {
            backgroundColor: t.palette.cirrus.main,
            height: '100%'

        },
        stepper: {
            backgroundColor: t.palette.cirrus.main,
        },
        action: {
            backgroundColor: t.palette.cirrus.main
        },
        badge: {
            backgroundColor: t.palette.cirrus.main,
            height: t.spacing(3),
            width: t.spacing(3),
            borderRadius: t.spacing(3)

        },
        dialog: {
            height: '700px'
        },
        dialogContent: {
            height: '600px'
        },
        actionCard: {
            backgroundColor: t.palette.cirrus.main,
            width: '100%'
        },
        divider: {
            backgroundColor: 'rgba(0,0,0,.38)',
        }
    }
};

// ======================
// ActionConfiguredPropertiesTable
// ======================

interface ActionConfiguredPropertiesTableProps {
    workflowStage: WorkflowStage
    properties?: {[key:string]:any}
}

export const ActionConfiguredPropertiesTable: React.FC<ActionConfiguredPropertiesTableProps> = observer((p) => {
    const {integrationsService} = useAppServices();
    const projectId = useCurrentProjectID();

    useInitData({
        init: () => integrationsService.currentIntegration.fetchData(p.workflowStage.getIntegrationId())
    });

    const getPropertiesData = () => {
        const kvData = [];

        if (p.workflowStage.getIntegrationId()) {
            kvData.push({

                key: 'Integration',
                value: <Link component={RouterLink}
                             to={generateIntegrationsSubRoutePath(`${INTEGRATIONS_SUBROUTE.ENABLED}/${integrationsService.currentIntegration.data?.getInfo().getId()}`, projectId)}>
                    {integrationsService.currentIntegration.data?.getInfo().getFriendlyName()}
                </Link>
            })
        }


        if (p.workflowStage.getAction() === WorkflowStage.Action.SNAPSHOT) {
            kvData.push({
                key: 'Snapshot Name Prefix',
                value: <>{p.workflowStage.getProperties()?.getFieldsMap()?.get('volumes').getStructValue().getFieldsMap().getEntryList()[0][1] || p.properties.namePrefix}</>
            })
        }


        if (p.workflowStage.getAction() === WorkflowStage.Action.EXECUTE) {
            kvData.push({
                key: 'Command',
                value: <>{p.workflowStage.getProperties()?.getFieldsMap()?.get('command').getStringValue() || p.properties.command}</>
            })
            kvData.push({
                key: 'Timeout',
                value: <>{p.workflowStage.getTimeout()?.getSeconds() || p.properties.timeout}s</>
            })
        }

        return kvData;
    }
    return <Box>
        <KVTable data={getPropertiesData()}/>
    </Box>
});


export const getWorkflowStageStatusDisplayText = (status: WorkflowStageStatus, workflowStatus: WorkflowStatus) => {
    if (status.getPending()) {
        if (!!workflowStatus.getError()) {
            return 'Canceled'
        }
        return 'Pending'
    } else if (status.getError()) {
        return 'Failed'
    } else if (status.getEndedAt().getSeconds() > 0) {
        return 'Completed'
    } else {
        return 'In Progress'
    }
}

export const getWorkflowRunStatusDisplayText = (status: WorkflowStatus, t: Theme, typographyProps?: Partial<TypographyProps>) => {
    if (!!status.getError()) {
        return <Typography color={'error'} {...typographyProps}>Failed</Typography>
    } else if (!!status.getRunning()) {
        return <Typography color={'textPrimary'} {...typographyProps}>In Progress</Typography>
    } else if (!!status.getEndedAt()) {
        return <Box color={t.palette.success.main}><Typography
            color={'inherit'} {...typographyProps}>Completed</Typography></Box>
    }
}