/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AppGlobalService
 */
import { makeAutoObservable, observable } from "mobx";
import { GRPCServices } from "../grpc/grpcapi";
import { GetUIFeatures } from "../../_proto/galaxycompletepb/apipb/api_pb";

export enum FeatureFlag {
    PHOENIX_SUPPORT = "phoenix-support",
    INGORE_MFA_CODE = "ignore-mfa-code",
    COMPUTE_MIGRATION_VMWARE = "compute-migration-vmware",
    CUTOVER_CHECKLIST = "cutover-checklist",
    LICENSE_STORE = "license-store",
    CMC_SOFTWARE_UPGRADE = "cmc-software-upgrade",
    AZURE_RECOMMENDATION = "azure-recommendation",
    MIGRATE_TO_SMALLER_VOLS = "migrate-to-smaller-vols",
    CUSTOMER_CENTER = "customer-center",
    AZURE_COMPUTE_MIGRATION = "azure-compute-migration",
}

export class AppGlobalService {
    private api: GRPCServices;

    initialized = false;
    postAuthInitialized = false;
    sideBarOpen = false;
    isLightMode = false;
    appSubmenu: string = null;
    enabledFeatures: string[] = [];
    enabledFeaturesLoaded: boolean = false;

    constructor(api: GRPCServices) {
        this.api = api;
        makeAutoObservable(this);
    }

    get currentAppSubmenu() {
        return this.appSubmenu;
    }

    setAppSubmenu(submenu: string) {
        this.appSubmenu = submenu;
    }

    get isInternalUser() {
        //@ts-ignore
        return window.secret_support_mode;
    }

    get isAcmeStoragePlugin() {
        //@ts-ignore
        return window._acme_storage_plugin;
    }

    toggleSideBar() {
        this.sideBarOpen = !this.sideBarOpen;
    }

    openSideBar() {
        this.sideBarOpen = true;
    }

    closeSideBar() {
        this.sideBarOpen = false;
    }

    toggleLightMode() {
        this.isLightMode = !this.isLightMode;
    }

    async sleep(duration = 0) {
        await sleepMS(duration);
    }

    isActivePage(page: string) {
        return window.location.pathname === page;
    }

    setInitialized(initialized: boolean) {
        this.initialized = initialized;
    }
    setPostAuthInitialized(initialized: boolean) {
        this.postAuthInitialized = initialized;
    }

    // =================
    // feature flags
    // =================
    isFeatureEnabled(feature: FeatureFlag) {
        return this.enabledFeatures.includes(feature);
    }

    setEnabledFeaturesLoaded(loaded: boolean) {
        this.enabledFeaturesLoaded = loaded;
    }

    async reloadEnabledFeatureFlags() {
        try {
            const res = await this.api.generalService.getUIFeatures(new GetUIFeatures.Request().setFeaturesRequestedList(Object.values(FeatureFlag)), null);
            this.enabledFeatures = res.getEnabledFeaturesList();
            this.setEnabledFeaturesLoaded(true);
        } catch (e) {
            console.error("failed to load feature flags:");
            console.error(e);
            this.enabledFeatures = [];
            this.setEnabledFeaturesLoaded(false);
        }
    }
}

export const sleepMS = (duration = 0) => {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, duration);
    });
};
