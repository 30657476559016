// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: ProjectSettingsScreen

import * as React from 'react';
import {ScreenContainer, ScreenTitleBar} from '../layout/ScreenCommon';
import {ProjectDetails} from '../../_proto/galaxycompletepb/apipb/domainpb/project_pb';
import {observer} from 'mobx-react-lite';
import {ProjectMembersArea} from './ProjectMembersList';
import {AdvancedSettings} from "./AdvancedSettings";
import {TabConfig, TabGroup} from "../../common/tabs/TabComponents";
import {Box} from "@mui/material";
import {ProjectSettingsPrivateEditionSetupScreen} from "./ProjectSettingsPrivateEditionSetup";
import {getIsPrivateEdition} from "../auth/PrivateEditionView";

// ======================
// ProjectSettingsScreen
// ======================
interface ProjectSettingsScreenProps {
    project: ProjectDetails
}

export const ProjectSettingsScreens: React.FC<ProjectSettingsScreenProps> = observer((p) => {
    const isPrivateEdition = getIsPrivateEdition();
    const tabsConfigs: TabConfig[] = [
        {
            label: 'Settings',
            renderer: () => <SettingsSection/>
        },
        {
            label: 'Private Edition',
            renderer: () => <ProjectSettingsPrivateEditionSetupScreen/>,
            hidden: true
        }
    ]

    return (<ScreenContainer>
        <ScreenTitleBar title={`Project Settings`}/>
        <TabGroup configs={tabsConfigs}/>
    </ScreenContainer>);
});

// ======================
// SettingsSection
// ======================

interface SettingsSectionProps {
}

const SettingsSection: React.FC<SettingsSectionProps> = observer((p) => {

    return <Box pt={2}>
        <ProjectMembersArea/>
        <br/>
        <AdvancedSettings/>
    </Box>
})