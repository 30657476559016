// source: galaxycompletepb/commonpb/datafilter.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.galaxycomplete.common.FilterParams', null, global);
goog.exportSymbol('proto.galaxycomplete.common.FilterParams.BoolFilter', null, global);
goog.exportSymbol('proto.galaxycomplete.common.FilterParams.BoolFilter.Op', null, global);
goog.exportSymbol('proto.galaxycomplete.common.FilterParams.DateFilter', null, global);
goog.exportSymbol('proto.galaxycomplete.common.FilterParams.DateFilter.Op', null, global);
goog.exportSymbol('proto.galaxycomplete.common.FilterParams.DurationFilter', null, global);
goog.exportSymbol('proto.galaxycomplete.common.FilterParams.DurationFilter.Op', null, global);
goog.exportSymbol('proto.galaxycomplete.common.FilterParams.NumberFilter', null, global);
goog.exportSymbol('proto.galaxycomplete.common.FilterParams.NumberFilter.Op', null, global);
goog.exportSymbol('proto.galaxycomplete.common.FilterParams.SimpleStringFilter', null, global);
goog.exportSymbol('proto.galaxycomplete.common.FilterParams.SimpleStringFilter.Op', null, global);
goog.exportSymbol('proto.galaxycomplete.common.FilterParams.StringFilter', null, global);
goog.exportSymbol('proto.galaxycomplete.common.FilterParams.StringFilter.Op', null, global);
goog.exportSymbol('proto.galaxycomplete.common.PagerMeta', null, global);
goog.exportSymbol('proto.galaxycomplete.common.PagerParams', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.common.PagerParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.common.PagerParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.common.PagerParams.displayName = 'proto.galaxycomplete.common.PagerParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.common.PagerMeta = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.common.PagerMeta, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.common.PagerMeta.displayName = 'proto.galaxycomplete.common.PagerMeta';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.common.FilterParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.common.FilterParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.common.FilterParams.displayName = 'proto.galaxycomplete.common.FilterParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.common.FilterParams.StringFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.common.FilterParams.StringFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.common.FilterParams.StringFilter.displayName = 'proto.galaxycomplete.common.FilterParams.StringFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.common.FilterParams.SimpleStringFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.common.FilterParams.SimpleStringFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.common.FilterParams.SimpleStringFilter.displayName = 'proto.galaxycomplete.common.FilterParams.SimpleStringFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.common.FilterParams.NumberFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.common.FilterParams.NumberFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.common.FilterParams.NumberFilter.displayName = 'proto.galaxycomplete.common.FilterParams.NumberFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.common.FilterParams.DateFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.common.FilterParams.DateFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.common.FilterParams.DateFilter.displayName = 'proto.galaxycomplete.common.FilterParams.DateFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.common.FilterParams.DurationFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.common.FilterParams.DurationFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.common.FilterParams.DurationFilter.displayName = 'proto.galaxycomplete.common.FilterParams.DurationFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.common.FilterParams.BoolFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.common.FilterParams.BoolFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.common.FilterParams.BoolFilter.displayName = 'proto.galaxycomplete.common.FilterParams.BoolFilter';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.common.PagerParams.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.common.PagerParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.common.PagerParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.PagerParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.common.PagerParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.common.PagerParams;
  return proto.galaxycomplete.common.PagerParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.common.PagerParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.common.PagerParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.common.PagerParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.common.PagerParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.common.PagerParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.PagerParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.galaxycomplete.common.PagerParams.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.common.PagerParams} returns this
 */
proto.galaxycomplete.common.PagerParams.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 per_page = 2;
 * @return {number}
 */
proto.galaxycomplete.common.PagerParams.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.common.PagerParams} returns this
 */
proto.galaxycomplete.common.PagerParams.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.common.PagerMeta.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.common.PagerMeta.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.common.PagerMeta} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.PagerMeta.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalItems: jspb.Message.getFieldWithDefault(msg, 4, 0),
    nextPage: jspb.Message.getFieldWithDefault(msg, 5, 0),
    prevPage: jspb.Message.getFieldWithDefault(msg, 6, 0),
    hasMore: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.common.PagerMeta.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.common.PagerMeta;
  return proto.galaxycomplete.common.PagerMeta.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.common.PagerMeta} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.common.PagerMeta.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPerPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalItems(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNextPage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrevPage(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.common.PagerMeta.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.common.PagerMeta.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.common.PagerMeta} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.PagerMeta.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTotalItems();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getNextPage();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getPrevPage();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getHasMore();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional uint32 page = 1;
 * @return {number}
 */
proto.galaxycomplete.common.PagerMeta.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.common.PagerMeta} returns this
 */
proto.galaxycomplete.common.PagerMeta.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 per_page = 2;
 * @return {number}
 */
proto.galaxycomplete.common.PagerMeta.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.common.PagerMeta} returns this
 */
proto.galaxycomplete.common.PagerMeta.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 total_pages = 3;
 * @return {number}
 */
proto.galaxycomplete.common.PagerMeta.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.common.PagerMeta} returns this
 */
proto.galaxycomplete.common.PagerMeta.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 total_items = 4;
 * @return {number}
 */
proto.galaxycomplete.common.PagerMeta.prototype.getTotalItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.common.PagerMeta} returns this
 */
proto.galaxycomplete.common.PagerMeta.prototype.setTotalItems = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 next_page = 5;
 * @return {number}
 */
proto.galaxycomplete.common.PagerMeta.prototype.getNextPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.common.PagerMeta} returns this
 */
proto.galaxycomplete.common.PagerMeta.prototype.setNextPage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 prev_page = 6;
 * @return {number}
 */
proto.galaxycomplete.common.PagerMeta.prototype.getPrevPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.common.PagerMeta} returns this
 */
proto.galaxycomplete.common.PagerMeta.prototype.setPrevPage = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool has_more = 7;
 * @return {boolean}
 */
proto.galaxycomplete.common.PagerMeta.prototype.getHasMore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.common.PagerMeta} returns this
 */
proto.galaxycomplete.common.PagerMeta.prototype.setHasMore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.common.FilterParams.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.common.FilterParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.common.FilterParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.FilterParams.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.common.FilterParams}
 */
proto.galaxycomplete.common.FilterParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.common.FilterParams;
  return proto.galaxycomplete.common.FilterParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.common.FilterParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.common.FilterParams}
 */
proto.galaxycomplete.common.FilterParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.common.FilterParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.common.FilterParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.common.FilterParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.FilterParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.common.FilterParams.StringFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.common.FilterParams.StringFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.common.FilterParams.StringFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.FilterParams.StringFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    op: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.common.FilterParams.StringFilter}
 */
proto.galaxycomplete.common.FilterParams.StringFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.common.FilterParams.StringFilter;
  return proto.galaxycomplete.common.FilterParams.StringFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.common.FilterParams.StringFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.common.FilterParams.StringFilter}
 */
proto.galaxycomplete.common.FilterParams.StringFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.galaxycomplete.common.FilterParams.StringFilter.Op} */ (reader.readEnum());
      msg.setOp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.common.FilterParams.StringFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.common.FilterParams.StringFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.common.FilterParams.StringFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.FilterParams.StringFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOp();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxycomplete.common.FilterParams.StringFilter.Op = {
  INCLUDES: 0,
  EXCLUDES: 1,
  EQUAL: 2,
  NOT_EQUAL: 3
};

/**
 * optional string value = 1;
 * @return {string}
 */
proto.galaxycomplete.common.FilterParams.StringFilter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.common.FilterParams.StringFilter} returns this
 */
proto.galaxycomplete.common.FilterParams.StringFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Op op = 2;
 * @return {!proto.galaxycomplete.common.FilterParams.StringFilter.Op}
 */
proto.galaxycomplete.common.FilterParams.StringFilter.prototype.getOp = function() {
  return /** @type {!proto.galaxycomplete.common.FilterParams.StringFilter.Op} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.galaxycomplete.common.FilterParams.StringFilter.Op} value
 * @return {!proto.galaxycomplete.common.FilterParams.StringFilter} returns this
 */
proto.galaxycomplete.common.FilterParams.StringFilter.prototype.setOp = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.common.FilterParams.SimpleStringFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.common.FilterParams.SimpleStringFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.common.FilterParams.SimpleStringFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.FilterParams.SimpleStringFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    op: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.common.FilterParams.SimpleStringFilter}
 */
proto.galaxycomplete.common.FilterParams.SimpleStringFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.common.FilterParams.SimpleStringFilter;
  return proto.galaxycomplete.common.FilterParams.SimpleStringFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.common.FilterParams.SimpleStringFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.common.FilterParams.SimpleStringFilter}
 */
proto.galaxycomplete.common.FilterParams.SimpleStringFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.galaxycomplete.common.FilterParams.SimpleStringFilter.Op} */ (reader.readEnum());
      msg.setOp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.common.FilterParams.SimpleStringFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.common.FilterParams.SimpleStringFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.common.FilterParams.SimpleStringFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.FilterParams.SimpleStringFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOp();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxycomplete.common.FilterParams.SimpleStringFilter.Op = {
  INCLUDES: 0,
  EQUAL: 1
};

/**
 * optional string value = 1;
 * @return {string}
 */
proto.galaxycomplete.common.FilterParams.SimpleStringFilter.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.common.FilterParams.SimpleStringFilter} returns this
 */
proto.galaxycomplete.common.FilterParams.SimpleStringFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Op op = 2;
 * @return {!proto.galaxycomplete.common.FilterParams.SimpleStringFilter.Op}
 */
proto.galaxycomplete.common.FilterParams.SimpleStringFilter.prototype.getOp = function() {
  return /** @type {!proto.galaxycomplete.common.FilterParams.SimpleStringFilter.Op} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.galaxycomplete.common.FilterParams.SimpleStringFilter.Op} value
 * @return {!proto.galaxycomplete.common.FilterParams.SimpleStringFilter} returns this
 */
proto.galaxycomplete.common.FilterParams.SimpleStringFilter.prototype.setOp = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.common.FilterParams.NumberFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.common.FilterParams.NumberFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.common.FilterParams.NumberFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.FilterParams.NumberFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    op: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.common.FilterParams.NumberFilter}
 */
proto.galaxycomplete.common.FilterParams.NumberFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.common.FilterParams.NumberFilter;
  return proto.galaxycomplete.common.FilterParams.NumberFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.common.FilterParams.NumberFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.common.FilterParams.NumberFilter}
 */
proto.galaxycomplete.common.FilterParams.NumberFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.galaxycomplete.common.FilterParams.NumberFilter.Op} */ (reader.readEnum());
      msg.setOp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.common.FilterParams.NumberFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.common.FilterParams.NumberFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.common.FilterParams.NumberFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.FilterParams.NumberFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOp();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxycomplete.common.FilterParams.NumberFilter.Op = {
  EQUAL: 0,
  GREATER_THAN: 1,
  LESS_THAN: 2,
  NOT_EQUAL: 4
};

/**
 * optional int64 value = 1;
 * @return {number}
 */
proto.galaxycomplete.common.FilterParams.NumberFilter.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.common.FilterParams.NumberFilter} returns this
 */
proto.galaxycomplete.common.FilterParams.NumberFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Op op = 2;
 * @return {!proto.galaxycomplete.common.FilterParams.NumberFilter.Op}
 */
proto.galaxycomplete.common.FilterParams.NumberFilter.prototype.getOp = function() {
  return /** @type {!proto.galaxycomplete.common.FilterParams.NumberFilter.Op} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.galaxycomplete.common.FilterParams.NumberFilter.Op} value
 * @return {!proto.galaxycomplete.common.FilterParams.NumberFilter} returns this
 */
proto.galaxycomplete.common.FilterParams.NumberFilter.prototype.setOp = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.common.FilterParams.DateFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.common.FilterParams.DateFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.common.FilterParams.DateFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.FilterParams.DateFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    op: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.common.FilterParams.DateFilter}
 */
proto.galaxycomplete.common.FilterParams.DateFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.common.FilterParams.DateFilter;
  return proto.galaxycomplete.common.FilterParams.DateFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.common.FilterParams.DateFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.common.FilterParams.DateFilter}
 */
proto.galaxycomplete.common.FilterParams.DateFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.galaxycomplete.common.FilterParams.DateFilter.Op} */ (reader.readEnum());
      msg.setOp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.common.FilterParams.DateFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.common.FilterParams.DateFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.common.FilterParams.DateFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.FilterParams.DateFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOp();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxycomplete.common.FilterParams.DateFilter.Op = {
  SINCE: 0,
  AT: 1,
  BEFORE: 2
};

/**
 * optional google.protobuf.Timestamp value = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.common.FilterParams.DateFilter.prototype.getValue = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.common.FilterParams.DateFilter} returns this
*/
proto.galaxycomplete.common.FilterParams.DateFilter.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.common.FilterParams.DateFilter} returns this
 */
proto.galaxycomplete.common.FilterParams.DateFilter.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.common.FilterParams.DateFilter.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Op op = 2;
 * @return {!proto.galaxycomplete.common.FilterParams.DateFilter.Op}
 */
proto.galaxycomplete.common.FilterParams.DateFilter.prototype.getOp = function() {
  return /** @type {!proto.galaxycomplete.common.FilterParams.DateFilter.Op} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.galaxycomplete.common.FilterParams.DateFilter.Op} value
 * @return {!proto.galaxycomplete.common.FilterParams.DateFilter} returns this
 */
proto.galaxycomplete.common.FilterParams.DateFilter.prototype.setOp = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.common.FilterParams.DurationFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.common.FilterParams.DurationFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.common.FilterParams.DurationFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.FilterParams.DurationFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    op: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.common.FilterParams.DurationFilter}
 */
proto.galaxycomplete.common.FilterParams.DurationFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.common.FilterParams.DurationFilter;
  return proto.galaxycomplete.common.FilterParams.DurationFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.common.FilterParams.DurationFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.common.FilterParams.DurationFilter}
 */
proto.galaxycomplete.common.FilterParams.DurationFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.galaxycomplete.common.FilterParams.DurationFilter.Op} */ (reader.readEnum());
      msg.setOp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.common.FilterParams.DurationFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.common.FilterParams.DurationFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.common.FilterParams.DurationFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.FilterParams.DurationFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getOp();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxycomplete.common.FilterParams.DurationFilter.Op = {
  SHORTER_THAN: 0,
  LONGER_THAN: 1
};

/**
 * optional google.protobuf.Duration value = 1;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxycomplete.common.FilterParams.DurationFilter.prototype.getValue = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 1));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxycomplete.common.FilterParams.DurationFilter} returns this
*/
proto.galaxycomplete.common.FilterParams.DurationFilter.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.common.FilterParams.DurationFilter} returns this
 */
proto.galaxycomplete.common.FilterParams.DurationFilter.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.common.FilterParams.DurationFilter.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Op op = 2;
 * @return {!proto.galaxycomplete.common.FilterParams.DurationFilter.Op}
 */
proto.galaxycomplete.common.FilterParams.DurationFilter.prototype.getOp = function() {
  return /** @type {!proto.galaxycomplete.common.FilterParams.DurationFilter.Op} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.galaxycomplete.common.FilterParams.DurationFilter.Op} value
 * @return {!proto.galaxycomplete.common.FilterParams.DurationFilter} returns this
 */
proto.galaxycomplete.common.FilterParams.DurationFilter.prototype.setOp = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.common.FilterParams.BoolFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.common.FilterParams.BoolFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.common.FilterParams.BoolFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.FilterParams.BoolFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    op: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.common.FilterParams.BoolFilter}
 */
proto.galaxycomplete.common.FilterParams.BoolFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.common.FilterParams.BoolFilter;
  return proto.galaxycomplete.common.FilterParams.BoolFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.common.FilterParams.BoolFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.common.FilterParams.BoolFilter}
 */
proto.galaxycomplete.common.FilterParams.BoolFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.galaxycomplete.common.FilterParams.BoolFilter.Op} */ (reader.readEnum());
      msg.setOp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.common.FilterParams.BoolFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.common.FilterParams.BoolFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.common.FilterParams.BoolFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.common.FilterParams.BoolFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOp();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxycomplete.common.FilterParams.BoolFilter.Op = {
  EQUAL: 0,
  NOT_EQUAL: 1
};

/**
 * optional bool value = 1;
 * @return {boolean}
 */
proto.galaxycomplete.common.FilterParams.BoolFilter.prototype.getValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.common.FilterParams.BoolFilter} returns this
 */
proto.galaxycomplete.common.FilterParams.BoolFilter.prototype.setValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Op op = 2;
 * @return {!proto.galaxycomplete.common.FilterParams.BoolFilter.Op}
 */
proto.galaxycomplete.common.FilterParams.BoolFilter.prototype.getOp = function() {
  return /** @type {!proto.galaxycomplete.common.FilterParams.BoolFilter.Op} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.galaxycomplete.common.FilterParams.BoolFilter.Op} value
 * @return {!proto.galaxycomplete.common.FilterParams.BoolFilter} returns this
 */
proto.galaxycomplete.common.FilterParams.BoolFilter.prototype.setOp = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


goog.object.extend(exports, proto.galaxycomplete.common);
