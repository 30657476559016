import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {Box, CardContent, Skeleton} from '@mui/material';

// ======================
// LoadingListItems
// ======================
interface LoadingListItemsProps {
    count?: number
}

export const LoadingListItems: React.FC<LoadingListItemsProps> = observer(({count = 1, ...p}) => {
    return <CardContent>
        {Array(count).fill(null).map((_, index) => <Box key={index} pt={1}>
            <Skeleton/>
            <Skeleton width="60%"/>
            <br/>
        </Box>)
        }
    </CardContent>;
});

// ======================
// LoadingBox
// ======================
interface LoadingBoxProps {
    height?: number;
}

export const LoadingBox: React.FC<LoadingBoxProps> = observer(({height = 100, ...p}) => {
    return <Box p={2}>
        <Skeleton/>
        <Skeleton width="60%"/>
        <br/>
        <Skeleton variant={'rectangular'} height={height - 30}/>
    </Box>;
});