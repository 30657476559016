// Project: GalaxyComplete
// Created: 9/25/20 by sammy

import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Chip, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material';
import {useCurrentProject} from '../project/CurrentProject';
import {useAppServices} from '../app/services';
import {generatePath, Link, useMatch} from 'react-router-dom';
import {
    APP_ROUTES,
    DEPLOYMENT_SUBROUTE,
    getProjectSubRouteTemplate,
    INTEGRATIONS_SUBROUTE, MIGRATION_SUBROUTE,
    PROJECT_SUBROUTE, PROTECTION_SUBROUTE
} from '../app/AppRoutes';
import {
    DataProtectionIcon,
    IntegrationsIcon,
    MigrationIcon,
    ProjectManagementIcon,
    ProjectSettingsIcon,
    SubDirectoryIcon
} from '../project/ProjectCommon';

import {useIsFeatureEnabled} from "../core/featureflag/FeatureFlags";
import {FeatureFlag} from "../app/AppGlobalService";
import {getSideMenuListItemStyle} from "./LayoutCommon";
import {Theme} from "@mui/material/styles";
import {getIsPrivateEdition} from "../auth/PrivateEditionView";
import {ProjectDetails} from "../../_proto/galaxycompletepb/apipb/domainpb/project_pb";
import {useIsSupportProject} from "../support/SupportCommon";
import {ReportIcon} from "../../common/CommonIcons";

interface CurrentProjectInfoProps {
}

export const CurrentProjectSideMenu: React.FC<CurrentProjectInfoProps> = observer((p) => {
    const project = useCurrentProject();

    if (!project) {
        return null;
    }

    return (
        <>
            <ProjectMenuHeader currentProject={project}/>
            <CurrentProjectMenuOptions/>
        </>
    );
});

// ======================
// ProjectMenuHeader
// ======================

interface ProjectMenuHeaderProps {
    currentProject: ProjectDetails
}

const ProjectMenuHeader: React.FC<ProjectMenuHeaderProps> = observer((p) => {
    const isPrivateEdition = getIsPrivateEdition();

    if (isPrivateEdition) {
        return <Box pb={2} pt={1} pl={2} sx={{
            background: (t: Theme) => t.palette.background.paper,
        }}>
            <Typography variant={'overline'} color={'textSecondary'}>
                Project Name
            </Typography>
            <Typography variant={'subtitle2'}>
                {p.currentProject.getInfo().getName()}
            </Typography>
        </Box>
    }

    return <Box p={2} sx={{
        background: (t: Theme) => t.palette.background.paper,
    }}>
        <Typography variant={'overline'} color={'textSecondary'}>
            Current Project
        </Typography>
        <Typography variant={'subtitle2'}>
            {p.currentProject.getInfo().getName()}
        </Typography>
    </Box>
})

// ======================
// CurrentProjectMenuOptions
// ======================
interface CurrentProjectMenuOptionsProps {
}

export const CurrentProjectMenuOptions: React.FC<CurrentProjectMenuOptionsProps> = observer((p) => {
    const currentProject = useCurrentProject();
    if (!currentProject) {
        return null;
    }

    const isDataProtectionEnabled = useIsFeatureEnabled(FeatureFlag.PHOENIX_SUPPORT);

    const projectId = currentProject.getInfo().getProjectId();
    return (<>
        <ProjectSubMenuListItem projectId={projectId} icon={<ProjectManagementIcon/>} label={`Overview`}
                                route={PROJECT_SUBROUTE.MANAGEMENT}/>

        <ProjectSubMenuListItem projectId={projectId} icon={<MigrationIcon/>} label={`Data Migration`}
                                route={PROJECT_SUBROUTE.MIGRATIONS} submenu={<DataMigrationSubMenu/>}
                                forceOpen={!isDataProtectionEnabled}/>
        {isDataProtectionEnabled &&
            <ProjectSubMenuListItem projectId={projectId} label={'Data Protection'}
                                    route={PROJECT_SUBROUTE.PROTECTION}
                                    icon={<DataProtectionIcon/>}
                                    submenu={<DataProtectionSubMenu/>}
            />
        }
        <ProjectSubMenuListItem projectId={projectId} icon={<IntegrationsIcon/>} label={'Integrations'}
                                route={PROJECT_SUBROUTE.INTEGRATIONS}
                                submenu={<IntegrationsSubMenu/>}/>
        <ProjectSubMenuListItem projectId={projectId} icon={<ReportIcon/>} label={'Reports'}
                                route={PROJECT_SUBROUTE.REPORTS}/>
        <ProjectSubMenuListItem projectId={projectId} icon={<ProjectSettingsIcon/>} label={`Settings`}
                                route={PROJECT_SUBROUTE.SETTINGS}/>
    </>);
});

// ======================
// DataMigrationSubMenu
// ======================

interface DataMigrationSubMenuProps {
}

export const DataMigrationSubMenu: React.FC<DataMigrationSubMenuProps> = observer((p) => {
    const currentProject = useCurrentProject();

    const projectId = currentProject.getInfo().getProjectId();

    return <>
        <ProjectSubMenuListItem projectId={projectId} label={'Migration Hosts'} route={PROJECT_SUBROUTE.MIGRATIONS}
                                subroute={MIGRATION_SUBROUTE.DEPLOYMENTS} default/>
        <ProjectSubMenuListItem projectId={projectId} label={'H2H Connections'} route={PROJECT_SUBROUTE.MIGRATIONS}
                                subroute={MIGRATION_SUBROUTE.CONNECTIONS}/>
        <ProjectSubMenuListItem projectId={projectId} label={'Migration Sessions'}
                                subroute={MIGRATION_SUBROUTE.SESSIONS}
                                route={PROJECT_SUBROUTE.MIGRATIONS}/>
    </>
});

// ======================
// DataProtectionSubMenu
// ======================

interface DataProtectionSubMenuProps {
}

export const DataProtectionSubMenu: React.FC<DataProtectionSubMenuProps> = observer((p) => {
    const currentProject = useCurrentProject();

    const projectId = currentProject.getInfo().getProjectId();

    return <>
        <ProjectSubMenuListItem projectId={projectId} label={'Protection Hosts'} route={PROJECT_SUBROUTE.PROTECTION}
                                subroute={PROTECTION_SUBROUTE.DEPLOYMENTS} default/>
        <ProjectSubMenuListItem projectId={projectId} label={'Protection Targets'} route={PROJECT_SUBROUTE.PROTECTION}
                                subroute={PROTECTION_SUBROUTE.SERVERS}/>
        {/*        <ProjectSubMenuListItem projectId={projectId} label={'Policies'} route={PROJECT_SUBROUTE.PROTECTION}
                                subroute={PROTECTION_SUBROUTE.POLICIES}
        />*/}
    </>
})

// ======================
// IntegrationsSubMenu
// ======================

interface IntegrationsSubMenuProps {
}

export const IntegrationsSubMenu: React.FC<IntegrationsSubMenuProps> = observer((p) => {
    const currentProject = useCurrentProject();

    const projectId = currentProject.getInfo().getProjectId();
    return <>
        <ProjectSubMenuListItem projectId={projectId} label={'Goodies'} route={PROJECT_SUBROUTE.INTEGRATIONS} default/>
        <ProjectSubMenuListItem projectId={projectId} label={'Enabled'} route={PROJECT_SUBROUTE.INTEGRATIONS}
                                subroute={INTEGRATIONS_SUBROUTE.ENABLED}/>
    </>
})

// ======================
// DeploymentsSubMenu
// ======================

interface DeploymentsSubMenuProps {

}

export const DeploymentsSubMenu: React.FC<DeploymentsSubMenuProps> = observer((props) => {
    const currentProject = useCurrentProject();

    const projectId = currentProject.getInfo().getProjectId();
    return <>
        <ProjectSubMenuListItem projectId={projectId} label={'Deployed'} route={PROJECT_SUBROUTE.DEPLOYMENTS} default/>
        <ProjectSubMenuListItem projectId={projectId} label={'H2H Connections'} route={PROJECT_SUBROUTE.DEPLOYMENTS}
                                subroute={DEPLOYMENT_SUBROUTE.CONNECTIONS}/>
    </>
})

// ======================
// ProjectSubMenuListItem
// ======================
interface SideMenuListItemProps {
    projectId: string;
    label: string;
    icon?: React.ReactNode;
    route: string;
    subroute?: string;
    submenu?: React.ReactNode;
    default?: boolean;
    forceOpen?: boolean
}

const ProjectSubMenuListItem: React.FC<SideMenuListItemProps> = observer((p) => {
    const {appGlobalService} = useAppServices();
    const routeString = p.subroute ? `${p.route}/${p.subroute}` : p.route;
    const routeTemplate = getProjectSubRouteTemplate(routeString);
    const selected = (p.default || p.subroute) ? !!useMatch(routeTemplate) : !!useMatch(routeTemplate + "*");
    const link = generatePath(routeTemplate, {projectId: p.projectId});

    const onClick = () => {
        appGlobalService.setAppSubmenu(p.route)
    }

    return <>
        <ListItem button component={Link}
                  to={link}
                  selected={selected}
                  onClick={onClick}
                  sx={getSideMenuListItemStyle()}
        >
            {p.icon &&
                <ListItemIcon>
                    {p.icon}
                </ListItemIcon>
            }
            <Box pl={(p.subroute || p.default) ? 4 : 0} display={'flex'} alignItems={'center'}>
                {(p.subroute || p.default) &&
                    <>
                        <SubDirectoryIcon/>
                        &nbsp; &nbsp;

                    </>
                }
                <ListItemText primary={p.label}/>
            </Box>
        </ListItem>
        {(appGlobalService.currentAppSubmenu === p.route || p.forceOpen) && p.submenu}
    </>
});
