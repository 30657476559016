import { observer } from "mobx-react-lite";
import { getIntegrationModuleDefs, IntegrationCard } from "../IntegrationsCommon";
import { Alert, Box, Button, FormHelperText, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { FormAsyncAutocompleteField, FormCheckboxItem, FormRadioGroup, FormTextField, FormTextFileDropzone } from "../../../common/form/FormComponents";
import * as React from "react";
import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import { useAppServices } from "../../app/services";
import { IntegrationConfigInfo, IntegrationModule } from "../../../_proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { GalaxyMigrateDeploymentInfo } from "../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { PagerParams } from "../../../_proto/galaxycompletepb/commonpb/datafilter_pb";
import { IntegrationParams } from "../../../_proto/galaxycompletepb/apipb/integration_api_pb";
import { DeploymentHostEnvironment } from "../../../_proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import { GalaxyMigrateAutoAllocationProgressDialog } from "../../migration/autoallocation/GmAutoAllocationProgress";
import * as yup from "yup";

type EditCredentialsType = "yes" | "no";

interface FormFieldValues {
    name: string;
    deploymentId: string;
    module: IntegrationModule;
    connParams: ConnParamFields;
    editCredentials: EditCredentialsType;
    verboseLogging: boolean;
}

type ConnParamFields =
    | AwsFields
    | AzureFields
    | DGSFields
    | PureFields
    | NetappFields
    | PowerMaxFields
    | PowerStoreFields
    | GcpFields
    | OracleFields
    | DigitalOceanFields
    | SilkFields;

interface AwsFields {
    accessKey: string;
    secret: string;
}

interface AzureFields {
    tenant: string;
    client: string;
    secret: string;
}

interface DGSFields {
    username: string;
    password: string;
    endpoint: string;
}

interface PureFields {
    managementUri: string;
    apiToken: string;
}

interface NetappFields {
    username: string;
    password: string;
    endpoint: string;
}

interface PowerStoreFields {
    username: string;
    password: string;
    endpoint: string;
}

interface PowerMaxFields {
    username: string;
    password: string;
    endpoint: string;
    arrayId: string;
}

interface GcpFields {
    credentials: string;
}

interface OracleFields {
    ocid: string;
    fingerprint: string;
    privateKey: string;
    tenancy: string;
}

interface SilkFields {
    username: string;
    password: string;
    endpoint: string;
}

interface DigitalOceanFields {
    apiToken: string;
}

// ======================
// IntegrationForm
// ======================

interface IntegrationFormProps {
    currentIntegration?: IntegrationConfigInfo;
    newIntegrationType?: IntegrationModule;
    onCancel: () => void;
    onSubmit: (
        module: IntegrationModule,
        friendlyName: string,
        deploymentId?: string,
        connParams?: IntegrationParams.ConnParams,
        verboseLogging?: boolean
    ) => Promise<any>;
    onSubmitted?: () => void;
}

export const IntegrationForm: React.FC<IntegrationFormProps> = observer((p) => {
    const { integrationsService } = useAppServices();

    const redirectState = integrationsService.integrationRedirectState;

    const type = p.newIntegrationType || p.currentIntegration?.getModule();

    const getInitialValues = (): FormFieldValues => {
        const fields = {
            name: p.currentIntegration?.getFriendlyName() ?? "",
            deploymentId: redirectState?.preselectedHost ?? "",
            module: type,
            editCredentials: p.newIntegrationType ? ("yes" as EditCredentialsType) : ("no" as EditCredentialsType),
            verboseLogging: p.currentIntegration?.getVerboseLogging() ?? false,
        };
        if (type === IntegrationModule.AWS) {
            const awsFields = Object.assign(fields, {
                connParams: {
                    accessKey: "",
                    secret: "",
                },
            });
            return awsFields;
        } else if (type === IntegrationModule.AZURE) {
            const azureFields = Object.assign(fields, {
                connParams: {
                    tenant: "",
                    client: "",
                    secret: "",
                },
            });
            return azureFields;
        } else if (type === IntegrationModule.PURE || type === IntegrationModule.FC_PURE) {
            const pureFields = Object.assign(fields, {
                connParams: {
                    managementUri: "",
                    apiToken: "",
                },
            });
            return pureFields;
        } else if (type === IntegrationModule.DGS) {
            const dgsFields = Object.assign(fields, {
                connParams: {
                    username: "",
                    password: "",
                    endpoint: "",
                },
            });
            return dgsFields;
        } else if (type === IntegrationModule.NETAPP || type === IntegrationModule.AWS_FSX_NETAPP) {
            const netappFields = Object.assign(fields, {
                connParams: {
                    username: "",
                    password: "",
                    endpoint: "",
                },
            });
            return netappFields;
        } else if (type === IntegrationModule.POWERSTORE || type === IntegrationModule.FC_POWERSTORE) {
            const powerStoreFields = Object.assign(fields, {
                connParams: {
                    username: "",
                    password: "",
                    endpoint: "",
                },
            });
            return powerStoreFields;
        } else if (type === IntegrationModule.FC_POWERMAX) {
            const powerMaxFields = Object.assign(fields, {
                connParams: {
                    username: "",
                    password: "",
                    endpoint: "",
                    arrayId: "",
                },
            });
            return powerMaxFields;
        } else if (type === IntegrationModule.GCP) {
            const gcpFields = Object.assign(fields, {
                connParams: {
                    credentials: "",
                },
            });
            return gcpFields;
        } else if (type === IntegrationModule.ORACLE) {
            const oracleFields = Object.assign(fields, {
                connParams: {
                    ocid: "",
                    privateKey: "",
                    fingerprint: "",
                    tenancy: "",
                },
            });
            return oracleFields;
        } else if (type === IntegrationModule.SILKSDP) {
            const silkFields = Object.assign(fields, {
                connParams: {
                    username: "",
                    password: "",
                    endpoint: "",
                },
            });
            return silkFields;
        } else if (type === IntegrationModule.DIGITALOCEAN) {
            const digitalOceanFields = Object.assign(fields, {
                connParams: {
                    apiToken: "",
                },
            });
            return digitalOceanFields;
        }
    };

    const validationSchema = yup.object({
        name: yup.string().required("Enter a name"),
        deploymentId: yup.string().when("editCredentials", {
            is: "yes",
            then: yup.string().required("Choose a deployment"),
            otherwise: yup.string().notRequired(),
        }),
        verboseLogging: yup.boolean(),
        connParams: yup
            .mixed()
            .when(["module"], {
                is: IntegrationModule.AWS,
                then: yup.mixed().when(["editCredentials"], {
                    is: "yes",
                    then: yup.object({
                        accessKey: yup.string().required("Enter your access key"),
                        secret: yup.string().required("Enter the secret"),
                    }),
                    otherwise: yup.object({
                        accessKey: yup.string().notRequired(),
                        secret: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.AZURE,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        tenant: yup.string().required("Enter the tenant ID"),
                        client: yup.string().required("Enter the client ID"),
                        secret: yup.string().required("Enter the secret"),
                    }),
                    otherwise: yup.object({
                        tenant: yup.string().notRequired(),
                        client: yup.string().notRequired(),
                        secret: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.DGS,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        username: yup.string().required("Enter your username"),
                        password: yup.string().required("Enter your password"),
                        endpoint: yup.string().required("Enter the server address"),
                    }),
                    otherwise: yup.object({
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                        endpoint: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.PURE || IntegrationModule.FC_PURE,
                then: yup.mixed().when(["editCredentials"], {
                    is: "yes",
                    then: yup.object({
                        managementUri: yup.string().required("Enter the management URI"),
                        apiToken: yup.string().required("Enter your API token"),
                    }),
                    otherwise: yup.object({
                        managementUri: yup.string().notRequired(),
                        apiToken: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.NETAPP || IntegrationModule.AWS_FSX_NETAPP,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        username: yup.string().required("Enter your username"),
                        password: yup.string().required("Enter your password"),
                        endpoint: yup.string().required("Enter the server address"),
                    }),
                    otherwise: yup.object({
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                        endpoint: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.FC_POWERSTORE || IntegrationModule.POWERSTORE,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        username: yup.string().required("Enter your username"),
                        password: yup.string().required("Enter your password"),
                        endpoint: yup.string().required("Enter the server address"),
                    }),
                    otherwise: yup.object({
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                        endpoint: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.FC_POWERMAX,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        username: yup.string().required("Enter your username"),
                        password: yup.string().required("Enter your password"),
                        endpoint: yup.string().required("Enter the server address"),
                        arrayId: yup.string().required("Enter the array ID"),
                    }),
                    otherwise: yup.object({
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                        endpoint: yup.string().notRequired(),
                        arrayId: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.GCP,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        credentials: yup.string().required("Enter your credentials"),
                    }),
                    otherwise: yup.object({
                        credentials: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.ORACLE,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        ocid: yup.string().required("Enter the OCID"),
                        fingerprint: yup.string().required("Enter the fingerprint"),
                        privateKey: yup.string().required("Add the private key"),
                        tenancy: yup.string().required("Enter the tenancy"),
                    }),
                    otherwise: yup.object({
                        ocid: yup.string().notRequired(),
                        fingerprint: yup.string().notRequired(),
                        privateKey: yup.string().notRequired(),
                        tenancy: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.SILKSDP,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        username: yup.string().required("Enter your username"),
                        password: yup.string().required("Enter your password"),
                        endpoint: yup.string().required("Enter the server address"),
                    }),
                    otherwise: yup.object({
                        username: yup.string().notRequired(),
                        password: yup.string().notRequired(),
                        endpoint: yup.string().notRequired(),
                    }),
                }),
            })
            .when(["module"], {
                is: IntegrationModule.DIGITALOCEAN,
                then: yup.mixed().when("editCredentials", {
                    is: "yes",
                    then: yup.object({
                        apiToken: yup.string().required("Enter your token"),
                    }),
                    otherwise: yup.object({
                        apiToken: yup.string().notRequired(),
                    }),
                }),
            }),
    });

    return (
        <ScreenContainer>
            <ScreenTitleBar title={!p.currentIntegration ? `Add Integration` : "Edit Integration"} />
            <Typography variant={"body2"}>You will be able to use this integration within this project.</Typography>
            <br />
            <Grid container spacing={4}>
                <Grid item>
                    <IntegrationCard module={getIntegrationModuleDefs().find((d) => d.module === type)} cardProps={{ sx: { width: 325 } }} />
                </Grid>
                <Grid item>
                    <Formik
                        initialValues={getInitialValues()}
                        validationSchema={validationSchema}
                        onSubmit={async (values) => {
                            const connParams = getConnParamsByModule(values);
                            console.debug(connParams.toObject());
                            await p.onSubmit(type, values.name, values.deploymentId, connParams, values.verboseLogging);
                            if (!!p.onSubmitted) {
                                p.onSubmitted();
                            }
                        }}
                    >
                        {(props) => {
                            return (
                                <Form>
                                    <Box pb={2}>
                                        <Typography variant={"h5"}>1. General Information</Typography>
                                        <br />
                                        <FormTextField label={"Name"} name={"name"} required />
                                        <FormHelperText component={"span"}>{"Integration name"}</FormHelperText>
                                        <br />
                                        <Alert severity={"info"}>
                                            {" "}
                                            Use a descriptive name as you will not be able to retrieve other connection parameters set here once they are
                                            encrypted and stored
                                        </Alert>
                                    </Box>
                                    {!!p.currentIntegration && (
                                        <Box pb={2} pt={2}>
                                            <SelectEditCredentials />
                                        </Box>
                                    )}
                                    {(!!p.newIntegrationType || props.values.editCredentials === "yes") && (
                                        <>
                                            <Box pb={2}>
                                                <Typography variant={"h5"}>2. Credentials</Typography>
                                                <br />
                                                {renderConnectionParamFields(type, true)}
                                            </Box>
                                            {!redirectState && (
                                                <Box pb={2}>
                                                    <Typography variant={"h5"}>3. Verify Connection</Typography>
                                                    <br />
                                                    <VerifyIntegrationConnection required={!!p.newIntegrationType || props.values.editCredentials === "yes"} />
                                                </Box>
                                            )}
                                        </>
                                    )}
                                    <Box>
                                        <FormCheckboxItem
                                            label={"Verbose Logging"}
                                            name={"verboseLogging"}
                                            helperText={
                                                "If enabled, all interactions between the integration and the vendor will be logged. This should only be used for troubleshooting purposes"
                                            }
                                        />
                                    </Box>
                                    <Box display={"flex"} pt={2}>
                                        <Box pr={2}>
                                            <Button variant={"contained"} color={"primary"} type={"submit"}>
                                                Save
                                            </Button>
                                        </Box>
                                        <Box>
                                            <Button variant={"outlined"} onClick={p.onCancel}>
                                                Cancel
                                            </Button>
                                        </Box>
                                    </Box>
                                </Form>
                            );
                        }}
                    </Formik>
                </Grid>
            </Grid>
            <GalaxyMigrateAutoAllocationProgressDialog actionLabel={"Close"} />
        </ScreenContainer>
    );
});

interface ConnectionParamProps {
    required: boolean;
}

// ======================
// AwsConnectionParams
// ======================

export const AwsConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.accessKey"} label={"Access Key"} variant={"filled"} required={p.required} />
                    <FormHelperText component={"span"}>{"Your AWS access key"}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.secret"} label={"Secret"} variant={"filled"} required={p.required} type={"password"} />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// AzureConnectionParams
// ======================

export const AzureConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormTextField name={"connParams.tenant"} label={"Tenant ID"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Tenant ID from your Azure Active Directory"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.client"} label={"Client/Application ID"} variant={"filled"} required={p.required} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.secret"} label={"Secret"} type={"password"} variant={"filled"} required={p.required} />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// DGSConnectionParams
// ======================

export const DGSConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormTextField name={"connParams.endpoint"} label={"Server Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Server Address to connect to"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.username"} label={"Username"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Username"}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.password"} label={"Password"} variant={"filled"} required={p.required} type={"password"} />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// NetAppConnectionParams
// ======================

export const NetappConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormTextField name={"connParams.endpoint"} label={"Server Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Server Address to connect to"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.username"} label={"Username"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Username"}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.password"} label={"Password"} variant={"filled"} required={p.required} type={"password"} />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// PureConnectionParams
// ======================

export const PureConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.managementUri"} label={"Management Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Host / IP to access storage management APIs"}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.apiToken"} label={"FlashArray User API Token"} variant={"filled"} required={p.required} />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// PowerStoreConnectionParams
// ======================

export const PowerStoreConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormTextField name={"connParams.endpoint"} label={"Server Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Server Address to connect to"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.username"} label={"Username"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Username"}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.password"} label={"Password"} variant={"filled"} required={p.required} type={"password"} />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// PowerMaxConnectionParams
// ======================

export const PowerMaxConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.endpoint"} label={"Server Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Server Address to connect to"}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.arrayId"} label={"Array ID"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Array ID"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.username"} label={"Username"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Username"}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.password"} label={"Password"} variant={"filled"} required={p.required} type={"password"} />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// GcpConnectionParams
// ======================

export const GcpConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Typography>Upload your service account private key file.</Typography>
            <br />
            <FormTextFileDropzone label={"Credentials"} name={"connParams.credentials"} maxFiles={1} accept={[".json"]} />
        </>
    );
});

// ======================
// OracleConnectionParams
// ======================

export const OracleConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.ocid"} label={"OCID"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"OCID"}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.fingerprint"} label={"Fingerprint"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Fingerprint"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.tenancy"} label={"Tenancy"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Tenancy"}</FormHelperText>
                </Grid>
            </Grid>
            <Box pt={2}>
                <Typography>Upload your private key file.</Typography>
                <br />
                <FormTextFileDropzone label={"Private Key"} name={"connParams.privateKey"} maxFiles={1} accept={[".pem"]} />
            </Box>
        </>
    );
});

// ======================
// SilkSdpConnectionParams
// ======================

export const SilkConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormTextField name={"connParams.endpoint"} label={"Server Address"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Server Address to connect to"}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.username"} label={"Username"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Username"}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormTextField name={"connParams.password"} label={"Password"} variant={"filled"} required={p.required} type={"password"} />
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// DigitalOceanConnectionParams
// ======================

export const DigitalOceanConnectionParams: React.FC<ConnectionParamProps> = observer((p) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormTextField name={"connParams.apiToken"} label={"Token"} variant={"filled"} required={p.required} />
                    <FormHelperText>{"Your token"}</FormHelperText>
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// SelectEditCredentials
// ======================

interface SelectEditCredentialsProps {}

export const SelectEditCredentials: React.FC<SelectEditCredentialsProps> = observer((p) => {
    return (
        <>
            <Typography variant={"body1"}></Typography>
            <FormRadioGroup
                label={"Do you want to edit the integration credentials?"}
                name={"editCredentials"}
                options={[
                    { label: "No", value: "no" },
                    { label: "Yes", value: "yes" },
                ]}
            />
        </>
    );
});

// ======================
// VerifyIntegrationConnection
// ======================

interface VerifyIntegrationConnectionProps {
    required: boolean;
}

export const VerifyIntegrationConnection: React.FC<VerifyIntegrationConnectionProps> = observer((p) => {
    const { deploymentService } = useAppServices();
    return (
        <>
            <FormAsyncAutocompleteField
                label={"Deployment"}
                name={`deploymentId`}
                required={p.required}
                valueGetter={(value: GalaxyMigrateDeploymentInfo) => value.getDeployment().getSystemId()}
                dataFetcher={() => deploymentService.fetchGalaxyMigrateDeployments(true, true, new PagerParams().setPerPage(10000))}
                isOptionEqualToValue={(option: GalaxyMigrateDeploymentInfo, value: GalaxyMigrateDeploymentInfo) => {
                    return option.getDeployment().getSystemId() === value.getDeployment().getSystemId();
                }}
                autoSelect={false}
                getOptionLabel={(option: GalaxyMigrateDeploymentInfo) => option.getDeployment().getSystemName()}
                /*
                                                            getOptionDisabled={(option: GalaxyMigrateDeploymentInfo) => getHostDisabledByType(type, option.getDeployment().getHostEnvironment())}
                    */
            />
            {/*        <FormHelperText>
            {'Connectivity to this integration will be verified using the host selected above'}
        </FormHelperText>*/}
        </>
    );
});

export const renderConnectionParamFields = (type: IntegrationModule, required: boolean): React.ReactElement => {
    if (type === IntegrationModule.AWS) {
        return <AwsConnectionParams required={required} />;
    } else if (type === IntegrationModule.AZURE) {
        return <AzureConnectionParams required={required} />;
    } else if (type === IntegrationModule.DGS) {
        return <DGSConnectionParams required={required} />;
    } else if (type === IntegrationModule.PURE || type === IntegrationModule.FC_PURE) {
        return <PureConnectionParams required={required} />;
    } else if (type === IntegrationModule.NETAPP || type === IntegrationModule.AWS_FSX_NETAPP) {
        return <NetappConnectionParams required={required} />;
    } else if (type === IntegrationModule.POWERSTORE || type === IntegrationModule.FC_POWERSTORE) {
        return <PowerStoreConnectionParams required={required} />;
    } else if (type === IntegrationModule.FC_POWERMAX) {
        return <PowerMaxConnectionParams required={required} />;
    } else if (type === IntegrationModule.GCP) {
        return <GcpConnectionParams required={required} />;
    } else if (type === IntegrationModule.ORACLE) {
        return <OracleConnectionParams required={required} />;
    } else if (type === IntegrationModule.SILKSDP) {
        return <SilkConnectionParams required={required} />;
    } else if (type === IntegrationModule.DIGITALOCEAN) {
        return <DigitalOceanConnectionParams required={required} />;
    }
};

export const getConnParamsByModule = (values: FormFieldValues): IntegrationParams.ConnParams => {
    let connParams;
    if (values.module === IntegrationModule.AWS) {
        connParams = setAwsConnParams(values.connParams as AwsFields);
    } else if (values.module === IntegrationModule.AZURE) {
        connParams = setAzureConnParams(values.connParams as AzureFields);
    } else if (values.module === IntegrationModule.DGS) {
        connParams = setDgsConnParams(values.connParams as DGSFields);
    } else if (values.module === IntegrationModule.PURE) {
        connParams = setPureConnParams(values.connParams as PureFields);
    } else if (values.module === IntegrationModule.NETAPP || values.module === IntegrationModule.AWS_FSX_NETAPP) {
        connParams = setNetappConnParams(values.connParams as NetappFields);
    } else if (values.module === IntegrationModule.FC_PURE) {
        connParams = setFcPureConnParams(values.connParams as PureFields);
    } else if (values.module === IntegrationModule.FC_POWERSTORE) {
        connParams = setFcPowerStoreConnParams(values.connParams as PowerStoreFields);
    } else if (values.module === IntegrationModule.POWERSTORE) {
        connParams = setPowerStoreConnParams(values.connParams as PowerStoreFields);
    } else if (values.module === IntegrationModule.FC_POWERMAX) {
        connParams = setPowerMaxConnParams(values.connParams as PowerMaxFields);
    } else if (values.module === IntegrationModule.GCP) {
        connParams = setGcpConnParams(values.connParams as GcpFields);
    } else if (values.module === IntegrationModule.ORACLE) {
        connParams = setOracleConnParams(values.connParams as OracleFields);
    } else if (values.module === IntegrationModule.SILKSDP) {
        connParams = setSilkConnParams(values.connParams as SilkFields);
    } else if (values.module === IntegrationModule.DIGITALOCEAN) {
        connParams = setDigitalOceanConnParams(values.connParams as DigitalOceanFields);
    }

    return connParams;
};

const setAzureConnParams = (values: AzureFields) => {
    if (values.client && values.secret && values.tenant) {
        return new IntegrationParams.ConnParams().setAzure(
            new IntegrationParams.ConnParams.Azure().setClient(values.client).setTenant(values.tenant).setSecret(values.secret)
        );
    }
    return null;
};

const setAwsConnParams = (values: AwsFields) => {
    if (values.accessKey && values.secret) {
        return new IntegrationParams.ConnParams().setAws(
            new IntegrationParams.ConnParams.AWS().setAccessKey(values.accessKey).setSecretAccessKey(values.secret)
        );
    }
    return null;
};

const setDgsConnParams = (values: DGSFields) => {
    if (values.username && values.password && values.endpoint) {
        const endpoint = getEndpointFormat(values.endpoint);
        return new IntegrationParams.ConnParams().setDgs(
            new IntegrationParams.ConnParams.DGS().setUsername(values.username).setPassword(values.password).setEndpoint(endpoint)
        );
    }
    return null;
};

const setPureConnParams = (values: PureFields) => {
    if (values.apiToken && values.managementUri) {
        const endpoint = getEndpointFormat(values.managementUri);

        return new IntegrationParams.ConnParams().setPurecbs(
            new IntegrationParams.ConnParams.PureCBS().setManagementUri(endpoint).setApiToken(values.apiToken)
        );
    }
    return null;
};

const setFcPureConnParams = (values: PureFields) => {
    if (values.apiToken && values.managementUri) {
        const endpoint = getEndpointFormat(values.managementUri);

        return new IntegrationParams.ConnParams().setFcPurefa(
            new IntegrationParams.ConnParams.PureCBS().setManagementUri(endpoint).setApiToken(values.apiToken)
        );
    }
    return null;
};

const setNetappConnParams = (values: NetappFields) => {
    if (values.username && values.password && values.endpoint) {
        const endpoint = getEndpointFormat(values.endpoint);
        return new IntegrationParams.ConnParams().setNetapp(
            new IntegrationParams.ConnParams.NetApp().setUsername(values.username).setPassword(values.password).setEndpoint(endpoint)
        );
    }
    return null;
};

const setFcPowerStoreConnParams = (values: PowerStoreFields) => {
    if (values.username && values.password && values.endpoint) {
        const endpoint = getEndpointFormat(values.endpoint);
        return new IntegrationParams.ConnParams().setFcPowerstore(
            new IntegrationParams.ConnParams.EMCPowerStore().setUsername(values.username).setPassword(values.password).setEndpoint(endpoint)
        );
    }
    return null;
};

const setPowerStoreConnParams = (values: PowerStoreFields) => {
    if (values.username && values.password && values.endpoint) {
        const endpoint = getEndpointFormat(values.endpoint);
        return new IntegrationParams.ConnParams().setPowerstore(
            new IntegrationParams.ConnParams.EMCPowerStore().setUsername(values.username).setPassword(values.password).setEndpoint(endpoint)
        );
    }
    return null;
};

const setPowerMaxConnParams = (values: PowerMaxFields) => {
    if (values.username && values.password && values.endpoint && values.arrayId) {
        let endpoint = getEndpointFormat(values.endpoint);
        if (endpoint.split(":").length === 2) {
            endpoint = `${endpoint}:8443`;
        }
        return new IntegrationParams.ConnParams().setFcPowermax(
            new IntegrationParams.ConnParams.EMCPowerMax()
                .setArrayid(values.arrayId)
                .setEndpoint(endpoint)
                .setPassword(values.password)
                .setUsername(values.username)
        );
    }
    return null;
};

const setGcpConnParams = (values: GcpFields) => {
    if (values.credentials) {
        return new IntegrationParams.ConnParams().setGcp(new IntegrationParams.ConnParams.GCP().setCredentials(values.credentials));
    }
    return null;
};

const setOracleConnParams = (values: OracleFields) => {
    if (values.ocid && values.privateKey && values.fingerprint && values.tenancy) {
        return new IntegrationParams.ConnParams().setOracle(
            new IntegrationParams.ConnParams.Oracle()
                .setOcid(values.ocid)
                .setTenancy(values.tenancy)
                .setFingerprint(values.fingerprint)
                .setPrivateKey(values.privateKey)
        );
    }
    return null;
};

const setSilkConnParams = (values: SilkFields) => {
    if (values.username && values.password && values.endpoint) {
        const endpoint = getEndpointFormat(values.endpoint);
        return new IntegrationParams.ConnParams().setSilksdp(
            new IntegrationParams.ConnParams.SilkSdp().setUsername(values.username).setPassword(values.password).setEndpoint(endpoint)
        );
    }
    return null;
};

const setDigitalOceanConnParams = (values: DigitalOceanFields) => {
    if (values.apiToken) {
        return new IntegrationParams.ConnParams().setDigitalocean(new IntegrationParams.ConnParams.DigitalOcean().setApiToken(values.apiToken));
    }
    return null;
};

const getEndpointFormat = (endpoint: string) => {
    return endpoint.includes(`https://`) || endpoint.includes(`http://`) ? endpoint : `https://${endpoint}`;
};

const getHostDisabledByType = (type: IntegrationModule, hostEnv: DeploymentHostEnvironment) => {
    if (type === IntegrationModule.AZURE && hostEnv.getValue() !== DeploymentHostEnvironment.DeploymentHostEnvironment.AZURE) {
        return true;
    } else if (type === IntegrationModule.AWS && hostEnv.getValue() !== DeploymentHostEnvironment.DeploymentHostEnvironment.AWS) {
        return true;
    } else {
        return false;
    }
};

const getConnParamFieldsEmpty = (fields: ConnParamFields) => {
    return !Object.values(fields).join("");
};
