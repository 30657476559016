// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: ProjectDashboardScreen

import * as React from 'react';
import {ScreenContainer} from '../layout/ScreenCommon';
import {observer} from 'mobx-react-lite';
import {ProjectDetails} from '../../_proto/galaxycompletepb/apipb/domainpb/project_pb';


// ======================
// ProjectDashboardScreen
// ======================
interface ProjectDashboardScreenProps {
    project: ProjectDetails
}
export const ProjectDashboardScreens: React.FC<ProjectDashboardScreenProps> = observer((p) => {
    return (<ScreenContainer>
        dash
    </ScreenContainer>);
});