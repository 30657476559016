import {observer} from "mobx-react-lite";
import React from "react";
import {ScreenContainer, ScreenTitleBar} from "../../layout/ScreenCommon";
import {BackButton} from "../../../common/CommonButtons";
import {useDownloadXray, useNavigateToDeploymentDetails} from "../GalaxyMigrateCommon";
import {useParams} from "react-router-dom";
import {useAppServices} from "../../app/services";
import {renderServerDataWithLoadingBox, useInitData} from "../../core/data/DataLoaderHooks";
import {ColumnDef, DataTable} from "../../../common/table/DataTable";
import {Xray} from "../../../_proto/galaxycompletepb/apipb/domainpb/support_pb";
import {Box, Button, Card, IconButton, Link, ListSubheader, SvgIcon, Tooltip} from "@mui/material";
import {MdDownload, MdOutlineInsertDriveFile} from "react-icons/md";
import {FormattedDisplay} from "../../../common/FormattedDisplay";
import {convertTimestampObjectToDate, KnownDataType} from "../../../common/utils/formatter";
import {DeleteIcon} from "../../../common/CommonIcons";
import {BsFileEarmarkFill} from "react-icons/bs";
import {useDialogState} from "../../core/dialog/DialogService";
import {GalaxyMigrateDeploymentCreateXrayParamsDialog} from "./GalaxyMigrateDeploymentCreateXRayParams";

// ======================
// GmDeploymentXrayListScreen
// ======================


interface GmDeploymentXrayListScreenProps {
}

export const GmDeploymentXrayListScreen: React.FC<GmDeploymentXrayListScreenProps> = observer((p) => {
    const {deploymentID} = useParams();
    const {deploymentService} = useAppServices();
    const goBackToDetails = useNavigateToDeploymentDetails(deploymentID);
    const createXrayDialogState = useDialogState();

    useInitData({
        init: async () => {
            if (!deploymentService.currentDeploymentID) {
                await deploymentService.currentDeployment.fetchData(deploymentID)

            }
        }
    });

    const createXray = <Button variant={'contained'} color={'secondary'} onClick={createXrayDialogState.open}>
        Create X-Ray
    </Button>

    return renderServerDataWithLoadingBox(deploymentService.currentDeployment, data => {
        const deployment = data.getInfo()
        return <ScreenContainer>
            <BackButton navFunction={goBackToDetails} label={'Back To Host details'}/>
            <ScreenTitleBar title={deployment.getDeployment().getSystemName()} actions={createXray}/>
            <XrayList deploymentId={deploymentID}/>
            {createXrayDialogState.isOpen &&
                <GalaxyMigrateDeploymentCreateXrayParamsDialog dialogState={createXrayDialogState} systemId={deploymentID} supportOnly/>
            }
        </ScreenContainer>
    })
});

// ======================
// XrayList
// ======================

interface XrayListProps {
    deploymentId: string
}

export const XrayList: React.FC<XrayListProps> = observer((p) => {
    const {supportService, dialogService} = useAppServices();
    const poll = async () => await supportService.deploymentXrays.fetchData(p.deploymentId)
    const downloadXray = useDownloadXray();

    useInitData({
        poll: poll
    })

    const cols: ColumnDef<Xray.AsObject>[] = [
        {
            id: 'filename',
            label: 'File Name',
            getter: (r) => r,
            renderer: (r: Xray.AsObject, _) => {
                return <Box display={'flex'} alignItems={'center'}>
                    <Box pr={1}>
                        <SvgIcon>
                            <BsFileEarmarkFill/>
                        </SvgIcon>
                    </Box>
                    <Link onClick={async()=> await downloadXray(r.xrayId)}>{r.filename}</Link>
                </Box>
            }
        },
        {
            id: 'created',
            label: 'Created On',
            align: 'right',
            getter: (r) => r.uploadDate,
            renderer: (r, _) => {
                return <Box display={'flex'} justifyContent={'flex-end'}>
                    <FormattedDisplay dataType={KnownDataType.DATE} value={convertTimestampObjectToDate(r)}/>
                </Box>
            }
        },
        {
            id: 'actions',
            label: 'Actions',
            align: 'right',
            getter: (r) => r,
            renderer: (r: Xray.AsObject, _) => {
                return <Box display={'flex'} justifyContent={'flex-end'}>
                    <Tooltip arrow title={'Download X-Ray'}>
                        <IconButton onClick={async()=> await downloadXray(r.xrayId)}>
                            <SvgIcon>
                                <MdDownload/>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip arrow title={'Delete X-Ray'}>
                        <IconButton onClick={async()=> {
                            const confirmed = await dialogService.addConfirmDialog({
                                title: 'Delete X-Ray',
                                message: <Box pb={1}>By continuing, you will be deleting the selected x-ray. This action is irreversible.</Box>,
                                autoConfirmationQuestionLine: true
                            })
                            if (confirmed){
                                await supportService.deleteXray(r.xrayId)
                            }
                        }}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </Box>
            }
        }
    ]

    return renderServerDataWithLoadingBox(supportService.deploymentXrays, data => {
        return <Card>
            <ListSubheader>Uploaded X-Rays</ListSubheader>
            <DataTable rows={data.itemsList}
                          state={supportService.deploymentXrays.tableState}
                          loading={supportService.deploymentXrays.loading}
                          onTableStateChange={poll}
                          pagerMeta={data.pagerMeta}
                          cols={cols}
        />
        </Card>
    })
})