import {observer} from "mobx-react-lite";
import {Box, BoxProps, Card, CardProps, IconButton, SvgIcon} from "@mui/material";
import React, {useState} from "react";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";

// ======================
// CollapsibleCard
// ======================

interface CollapsibleCardProps {
    collapsibleElement: React.ReactNode
}

export const CollapsibleCard: React.FC<CollapsibleCardProps & Partial<CardProps>> = observer((p) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const icon = isExpanded ? <IoIosArrowUp/> : <IoIosArrowDown/>;

    const toggleExpansion = () => {
        if (isExpanded) {
            setIsExpanded(false)
        } else {
            setIsExpanded(true)
        }
    }

    return <Card {...p}>
        <Box display={'flex'} justifyContent={'space-between'}alignItems={'center'}>
            {p.children}
            <Box pr={2}>
                <IconButton onClick={toggleExpansion}>
                    <SvgIcon>
                        {icon}
                    </SvgIcon>
                </IconButton>
            </Box>
        </Box>
        {
            isExpanded &&
            <Box>
                {p.collapsibleElement}
            </Box>
        }

    </Card>
})

// ======================
// CollapsibleBox
// ======================


interface CollapsibleBoxProps {
    collapsibleElement: React.ReactNode
}

export const CollapsibleBox: React.FC<CollapsibleBoxProps & Partial<BoxProps>> = observer((p) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const icon = isExpanded ? <IoIosArrowUp/> : <IoIosArrowDown/>;

    const toggleExpansion = () => {
        if (isExpanded) {
            setIsExpanded(false)
        } else {
            setIsExpanded(true)
        }
    }

    return <Box {...p}>
        <Box display={'flex'} justifyContent={'space-between'}alignItems={'center'}>
            {p.children}
            <Box pr={2}>
                <IconButton onClick={toggleExpansion}>
                    <SvgIcon>
                        {icon}
                    </SvgIcon>
                </IconButton>
            </Box>
        </Box>
        {
            isExpanded &&
            <Box>
                {p.collapsibleElement}
            </Box>
        }

    </Box>

});