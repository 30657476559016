import {observer} from "mobx-react-lite";
import {FilterState, TableState} from "./DataTable";
import {DialogState, useDialogState, useShouldDialogFullScreen} from "../../modules/core/dialog/DialogService";
import {Box, Button, Chip, Dialog, DialogTitle, Divider, Grid, IconButton, SvgIcon} from "@mui/material";
import React, {ChangeEvent} from "react";
import {MdFilterList} from "react-icons/md";
import {CloseDialogButton} from "../../modules/core/dialog/DialogComponents";
import {FieldArray, Form, Formik} from "formik";
import {FormAutocompleteField, FormDateTimePicker, FormSelect, FormTextField} from "../form/FormComponents";
import {FilterParamType, FilterType, ListFilterableField,} from "../../modules/core/data/ListData";
import * as yup from 'yup';
import {formatKnownDataType, KnownDataType} from "../utils/formatter";
import {CloseIcon} from "../CommonIcons";
import {FilterParams} from "../../_proto/galaxycompletepb/commonpb/datafilter_pb";
import {AutocompleteInputChangeReason} from "@mui/material";
import {Timestamp} from "google-protobuf/google/protobuf/timestamp_pb";

// ======================
// TableFilter
// ======================


interface TableFilterProps<RowData> {
    state: TableState<RowData>;
    onTableStateChange?: (state: TableState<RowData>) => void;

}

export const TableFilter = observer(<RowData, >(props: TableFilterProps<RowData>) => {
    const {state, onTableStateChange} = props;
    const filterDialogState = useDialogState();

    if (!state?.sortFilterConfig) {
        return <Box/>;
    }

    const onDelete = (index: number) => {
        state.removeFilter(index);
        onTableStateChange(state)
    }

    return <>
        <Box>
            <Button color={'inherit'} startIcon={<SvgIcon><MdFilterList/></SvgIcon>} onClick={filterDialogState.open}>
                Filter
            </Button>
        </Box>
        {state.filters.length > 0 &&
        <Box display={'flex'} pb={2}>
            {state.filters.map((f, i) => {
                return <Box pr={1}><Chip label={getDisplayValueFromFilterState(f)}
                                         color={'primary'}
                                         onDelete={() => onDelete(i)}/></Box>
            })}
        </Box>
        }
        <TableFilterDialog state={state} dialogState={filterDialogState} onTableStateChange={onTableStateChange}/>

    </>
})

// ======================
// TableFilterDialog
// ======================

interface TableFilterDialogProps<RowData> {
    state: TableState<RowData>;
    dialogState: DialogState;
    onTableStateChange?: (state: TableState<RowData>) => void;

}

export const TableFilterDialog = observer(<RowData, >(props: TableFilterDialogProps<RowData>) => {
    const {state, dialogState, onTableStateChange} = props;

    return <Dialog fullScreen={useShouldDialogFullScreen()} fullWidth maxWidth={'md'}
                   open={dialogState.isOpen}
                   onClose={(event, reason) => {
                       if (reason !== 'backdropClick') {
                           dialogState.close()
                       }
                   }}>
        <Box display={'flex'} justifyContent={'space-between'}>
            <DialogTitle>
                Filter
            </DialogTitle>
            <CloseDialogButton dialogState={dialogState}/>
        </Box>
        <Divider/>
        <TableFilterForm state={state} closeDialog={dialogState.close} onTableStateChange={onTableStateChange}/>
    </Dialog>
})

// ======================
// TableFilterForm
// ======================

interface TableFilterFormProps<RowData> {
    state: TableState<RowData>;
    closeDialog: () => void;
    onTableStateChange?: (state: TableState<RowData>) => void;

}

export interface FilterFormValue {
    fieldConfig: ListFilterableField,
    op: {
        label: string,
        value: any,
    },
    value: any
}

export const TableFilterForm = observer(<RowData, >(props: TableFilterFormProps<RowData>) => {
    const {state, closeDialog, onTableStateChange} = props;

    const schema = yup.object({
        filters: yup.array(yup.object({
            fieldConfig: yup.object({
                label: yup.string().required('Select criteria.'),
                fieldId: yup.mixed(),
                filterType: yup.string(),
                addFilterToRequest: yup.mixed()
            }),
            op: yup.object({
                value: yup.mixed(),
                label: yup.string().required('Select condition.')
            }),
            value: yup.mixed().required('Enter a value.')
        }))
    })

    const getInitialFilterEntry = () => ({
        fieldConfig: {
            label: '',
            fieldId: null as any,
            filterType: null as FilterType,
            addFilterToRequest: null as (request: any, filterParam: FilterParamType) => void
        },
        op: {
            label: '',
            value: null as any,
        },
        value: ''
    })

    const _getInitialValues = (): { filters: FilterFormValue[] } => {
        if (state.filters.length === 0) {
            return {
                filters: [getInitialFilterEntry()]
            }
        } else {
            return {
                filters: state.filters.map(f => ({
                    fieldConfig: f.fieldConfig,
                    op: {
                        label: getOpLabelFromFilterState(f),
                        value: f.param.getOp(),
                    },
                    value: getFormValueFromParam(f)
                }))
            }
        }

    };

    const _getSubmitDisabled = (values: { filters: FilterFormValue[] }): boolean => {
        const lastIndex = values.filters.length - 1;
        return !values.filters[lastIndex]?.value;
    }

    return <Formik initialValues={_getInitialValues()}
                   validationSchema={schema}
                   enableReinitialize
                   onSubmit={async (values, helpers) => {
                       for (let filter of values.filters) {
                           if (getIsNewFilter(filter, state.filters)) {
                               state.setFilter({
                                   fieldConfig: filter.fieldConfig,
                                   param: getParamFromFormValue(filter)
                               })
                           }
                       }
                       //state.clearAlreadySubmittedFilters(values.filters);
                       closeDialog();
                       onTableStateChange(state);

                   }}>
        {props => {
            return <Form>
                <FieldArray name={'filters'} render={({push, remove}) => {
                    const addFilter = () => {
                        push(getInitialFilterEntry())
                    }

                    const removeFilter = (index: number) => {
                        if (!!props.values.filters[index].value) {
                            const existingFilterIndex = getExistingFilterIndex(props.values.filters[index], state.filters);
                            if (existingFilterIndex > -1) {
                                state.removeFilter(existingFilterIndex)
                            }
                        }
                        remove(index)
                    }

                    return <Box p={2}>
                        {props.values.filters.map((f, i) => {
                            const resetFieldsOnColumnChange = (event: ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
                                if (!state.filters[i]) {
                                    props.setFieldValue(`filters[${i}].op`, {label: '', value: null as number})
                                    props.setFieldValue(`filters[${i}].value`, '')

                                    props.setFieldTouched(`filters[${i}].op`, false)
                                    props.setFieldTouched(`filters[${i}].value`, false)
                                } else if (state.filters[i].fieldConfig.label !== value) {
                                    props.setFieldValue(`filters[${i}].op`, {label: '', value: null as number})
                                    props.setFieldValue(`filters[${i}].value`, '')

                                    props.setFieldTouched(`filters[${i}].op`, false)
                                    props.setFieldTouched(`filters[${i}].value`, false)

                                    state.removeFilter(i);
                                    onTableStateChange(state);

                                }
                            }
                            return <Box pb={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <FormAutocompleteField<ListFilterableField, false, true, false> label={'Criteria'} name={`filters[${i}].fieldConfig`}
                                                               options={state.sortFilterConfig.filter || []}
                                                               getOptionLabel={(o: ListFilterableField) => o.label}
                                                               errorGetter={(v) => v.label}
                                                               disableClearable
                                                               blurOnSelect
                                                               onInputChange={resetFieldsOnColumnChange}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        {renderOpField(`filters[${i}].op`, props.values.filters[i])}
                                    </Grid>
                                    <Grid item xs={5}>

                                        {renderValueField(`filters[${i}].value`, props.values.filters[i])}

                                    </Grid>
                                    <Grid item xs={1}>{
                                        i !== 0 &&
                                        <IconButton onClick={() => removeFilter(i)}><CloseIcon/></IconButton>
                                    }

                                    </Grid>
                                </Grid>
                            </Box>
                        })}
                        <Box>
                            <Button variant={'outlined'} color={'primary'} onClick={addFilter}>
                                Add Filter
                            </Button>
                        </Box>
                    </Box>
                }
                }/>
                <Divider/>
                <Box p={2}>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item>
                            <Button variant={'outlined'}
                                    color={'neutral'}
                                    onClick={() => {
                                        state.clearFilters();
                                        onTableStateChange(state);
                                        props.handleReset();
                                    }}
                                    disabled={!props.values.filters[0]?.fieldConfig.label}
                            >
                                Clear All Filters
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant={'contained'}
                                    color={'primary'}
                                    type={'submit'}
                                    onClick={() => {
                                        //console.debug(props.values)
                                        //console.debug(props.errors)
                                    }}
                                    disabled={_getSubmitDisabled(props.values)}
                            >
                                Apply All
                            </Button>
                        </Grid>
                    </Grid>
                </Box>


            </Form>
        }}

    </Formik>
})

interface OperationSelectionType {
    label: string,
    value: number
}

enum BooleanSelection {
    TRUE,
    FALSE
}

const getNumOpsSelectionLabel = (numOp: FilterParams.NumberFilter.Op) => {
    if (numOp === FilterParams.NumberFilter.Op.EQUAL) {
        return 'Equal To (=)';
    } else if (numOp === FilterParams.NumberFilter.Op.LESS_THAN) {
        return 'Less Than (<)';
    } else if (numOp === FilterParams.NumberFilter.Op.GREATER_THAN) {
        return 'Greater Than (>)'
    } else if (numOp === FilterParams.NumberFilter.Op.NOT_EQUAL) {
        return 'Not Equal To (!=)'
    }
}

const getNumOpsSelection = (): OperationSelectionType[] => ([
    {
        label: getNumOpsSelectionLabel(FilterParams.NumberFilter.Op.GREATER_THAN),
        value: FilterParams.NumberFilter.Op.GREATER_THAN
    },
    {
        label: getNumOpsSelectionLabel(FilterParams.NumberFilter.Op.LESS_THAN),
        value: FilterParams.NumberFilter.Op.LESS_THAN
    },
    {
        label: getNumOpsSelectionLabel(FilterParams.NumberFilter.Op.EQUAL),
        value: FilterParams.NumberFilter.Op.EQUAL
    },
    {
        label: getNumOpsSelectionLabel(FilterParams.NumberFilter.Op.NOT_EQUAL),
        value: FilterParams.NumberFilter.Op.NOT_EQUAL
    },
]);

const getStringOpsSelectionLabel = (stringOp: FilterParams.StringFilter.Op) => {
    if (stringOp === FilterParams.StringFilter.Op.INCLUDES) {
        return 'Includes';
    } else if (stringOp === FilterParams.StringFilter.Op.EXCLUDES) {
        return 'Excludes';
    } else if (stringOp === FilterParams.StringFilter.Op.EQUAL) {
        return 'Equals'
    } else if (stringOp === FilterParams.StringFilter.Op.NOT_EQUAL) {
        return 'Does Not Equal'
    }
}

const getStringOpsSelection = (): OperationSelectionType[] => ([
    {
        label: getStringOpsSelectionLabel(FilterParams.StringFilter.Op.INCLUDES),
        value: FilterParams.StringFilter.Op.INCLUDES
    },
    {
        label: getStringOpsSelectionLabel(FilterParams.StringFilter.Op.EXCLUDES),
        value: FilterParams.StringFilter.Op.EXCLUDES
    },
    {
        label: getStringOpsSelectionLabel(FilterParams.StringFilter.Op.EQUAL),
        value: FilterParams.StringFilter.Op.EQUAL
    },
    {
        label: getStringOpsSelectionLabel(FilterParams.StringFilter.Op.NOT_EQUAL),
        value: FilterParams.StringFilter.Op.NOT_EQUAL
    }
]);

const getSimpleStringOpsSelectionLabel = (stringOp: FilterParams.SimpleStringFilter.Op) => {
    if (stringOp === FilterParams.SimpleStringFilter.Op.INCLUDES) {
        return 'Includes';
    } else if (stringOp === FilterParams.SimpleStringFilter.Op.EQUAL) {
        return 'Equals'
    }
}

const getSimpleStringOpsSelection = (): OperationSelectionType[] => ([
    {
        label: getSimpleStringOpsSelectionLabel(FilterParams.SimpleStringFilter.Op.INCLUDES),
        value: FilterParams.SimpleStringFilter.Op.INCLUDES
    },
    {
        label: getSimpleStringOpsSelectionLabel(FilterParams.SimpleStringFilter.Op.EQUAL),
        value: FilterParams.SimpleStringFilter.Op.EQUAL
    },
]);

const getDateOpsSelectionLabel = (dateOp: FilterParams.DateFilter.Op) => {
    if (dateOp === FilterParams.DateFilter.Op.SINCE) {
        return 'Since';
    } else if (dateOp === FilterParams.DateFilter.Op.AT) {
        return 'At';
    } else if (dateOp === FilterParams.DateFilter.Op.BEFORE) {
        return 'Before'
    }
}

const getDateOpsSelection = (): OperationSelectionType[] => ([
    {
        label: getDateOpsSelectionLabel(FilterParams.DateFilter.Op.SINCE),
        value: FilterParams.DateFilter.Op.SINCE
    },
    {
        label: getDateOpsSelectionLabel(FilterParams.DateFilter.Op.AT),
        value: FilterParams.DateFilter.Op.AT
    },
    {
        label: getDateOpsSelectionLabel(FilterParams.DateFilter.Op.BEFORE),
        value: FilterParams.DateFilter.Op.BEFORE
    },

]);

const getBoolOpsSelectionLabel = (bool: BooleanSelection) => {
    if (bool === BooleanSelection.TRUE) {
        return 'Is';
    }
}

const getBoolOpsSelection = (): OperationSelectionType[] => ([
    {
        label: getBoolOpsSelectionLabel(BooleanSelection.TRUE),
        value: BooleanSelection.TRUE
    }
]);

const getDurationOpsSelectionLabel = (durationOp: FilterParams.DurationFilter.Op) => {
    if (durationOp === FilterParams.DurationFilter.Op.SHORTER_THAN) {
        return 'Shorter Than';
    } else if (durationOp === FilterParams.DurationFilter.Op.LONGER_THAN) {
        return 'Longer Than';
    }
}

const getDurationOpsSelection = (): OperationSelectionType[] => ([
    {
        label: getDurationOpsSelectionLabel(FilterParams.DurationFilter.Op.SHORTER_THAN),
        value: FilterParams.DurationFilter.Op.SHORTER_THAN
    },
    {
        label: getDurationOpsSelectionLabel(FilterParams.DurationFilter.Op.LONGER_THAN),
        value: FilterParams.DurationFilter.Op.LONGER_THAN
    }
]);

const getSelectionList = (t: FilterType): OperationSelectionType[] => {
    if (t === 'number') {
        return getNumOpsSelection();
    } else if (t === 'bool') {
        return getBoolOpsSelection();
    } else if (t === 'date') {
        return getDateOpsSelection();
    } else if (t === 'duration') {
        return getDurationOpsSelection();
    } else if (t === 'simpleString') {
        return getSimpleStringOpsSelection();
    } else {
        return getStringOpsSelection();
    }
}

const renderOpField = (name: string, filterValue: FilterFormValue) => {
    const isBool = filterValue.fieldConfig.filterType === 'bool';
    const defaultValue = isBool ? getBoolOpsSelection()[0] : null;
    return <FormAutocompleteField<OperationSelectionType, false, true, false> label={'Condition'} name={name}
                                  options={getSelectionList(filterValue.fieldConfig.filterType)}
                                  getOptionLabel={(o: OperationSelectionType) => o.label}
                                  defaultValue={defaultValue}
                                  isOptionEqualToValue={(o, v) => o.value === v.value}
                                  disableClearable
                                  blurOnSelect
                                  valueGetter={(v: OperationSelectionType) => v?.value || v}
                                  errorGetter={(v: OperationSelectionType) => v.label}
                                  disabled={isBool ? true : !filterValue.fieldConfig.label}
    />


}

const renderValueField = (name: string, filterValue: FilterFormValue) => {
    const filterType = filterValue.fieldConfig.filterType;
    if (filterType === 'bool') {
        return <FormSelect label={'Value'} name={name} selectionList={[{label: 'True', value: BooleanSelection.TRUE}, {
            label: 'False',
            value: BooleanSelection.FALSE
        }]} disabled={!filterValue.fieldConfig.label}
                           defaultValue={{label: 'True', value: BooleanSelection.TRUE}}/>
    } else if (filterType === 'date') {
        return <FormDateTimePicker label={'Value'} name={name} disabled={!filterValue.op.label}
                                   defaultValue={null}
        />
    } else {
        return <FormTextField label={'Value'} name={name} disabled={!filterValue.op.label}/>
    }
};

const getOpLabelFromFilterState = (s: FilterState) => {
    return getSelectionList(s.fieldConfig.filterType).find(v => v.value === s.param.getOp()).label
};

const getDisplayValueFromFilterState = (s: FilterState): string => {
    let value = s.param.getValue();
    let opLabel = getOpLabelFromFilterState(s)
    if (s.fieldConfig.filterType === 'date') {
        value = formatKnownDataType(new Date(value.getSeconds() * 1000), KnownDataType.DATE);
    }
    return `${s.fieldConfig.label} ${opLabel} ${value}`
};

const getIsNewFilter = (formValue: FilterFormValue, filterStates: FilterState[]) => {
    return !filterStates.find(filterState => getFormComparisonValue(formValue) === getFilterStateComparisonValue(filterState) && formValue.op.value === filterState.param.getOp() && formValue.fieldConfig.fieldId === filterState.fieldConfig.fieldId)
};

const getExistingFilterIndex = (formValue: FilterFormValue, filterStates: FilterState[]) => {
    return filterStates.findIndex(filterState => getFormComparisonValue(formValue) === getFilterStateComparisonValue(filterState) && formValue.op.value === filterState.param.getOp() && formValue.fieldConfig.fieldId === filterState.fieldConfig.fieldId)
};

export const getFilterParamValue = (f: FilterFormValue) => {
    if (f.fieldConfig.filterType === 'date') {
        return new Timestamp()
            .setSeconds(f.value.getTime() / 1000)
    } else if (f.fieldConfig.filterType === 'number') {
        return parseInt(f.value)
    } else {
        return f.value;
    }
};

export const getFormComparisonValue = (f: FilterFormValue) => {
    if (f.fieldConfig.filterType === 'date') {
        return new Timestamp()
            .setSeconds(f.value.getTime() / 1000).getSeconds()
    } else if (f.fieldConfig.filterType === 'number') {
        return parseInt(f.value)
    } else {
        return f.value;
    }
};

export const getFilterStateComparisonValue = (f: FilterState) => {
    if (f.fieldConfig.filterType === 'date') {
        return f.param.getValue().getSeconds()
    } else {
        return f.param.getValue()
    }
}

const getParamFromFormValue = (f: FilterFormValue) => {
    const value = getFilterParamValue(f);
    if (f.fieldConfig.filterType === 'number') {
        return new FilterParams.NumberFilter()
            .setValue(value)
            .setOp(f.op.value)
    } else if (f.fieldConfig.filterType === 'bool') {
        return new FilterParams.BoolFilter()
            .setValue(value)
    } else if (f.fieldConfig.filterType === 'string') {
        return new FilterParams.StringFilter()
            .setValue(value)
            .setOp(f.op.value)
    } else if (f.fieldConfig.filterType === 'simpleString') {
        return new FilterParams.SimpleStringFilter()
            .setValue(value)
            .setOp(f.op.value)
    } else if (f.fieldConfig.filterType === 'date') {
        return new FilterParams.DateFilter()
            .setValue(value)
            .setOp(f.op.value)
    } else if (f.fieldConfig.filterType === 'duration') {
        return new FilterParams.DurationFilter()
            .setValue(value)
            .setOp(f.op.value)
    }
};

const getFormValueFromParam = (f: FilterState) => {
    if (f.fieldConfig.filterType === 'date'){
        return new Date(f.param.getValue().getSeconds() * 1000)
    } else {
        return f.param.getValue()
    }
}