import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardHeader,
    Alert,
    Dialog,
    DialogContent,
    Typography,
    FormControl,
    FormLabel,
    FormControlLabel,
    FormHelperText,
    FormGroup,
    Switch,
    DialogActions,
} from "@mui/material";
import { useAppServices } from "../app/services";
import { useNavigateToProjectListScreen } from "../project/ProjectCommon";
import { AdminView } from "../auth/AuthenticatedViews";
import { EditProject } from "./EditProject";
import { ConfirmDialogConfig, useDialogState } from "../core/dialog/DialogService";
import { DialogTopBar } from "../core/dialog/DialogComponents";
import { useIsDesktop } from "../layout/MainLayout";
import { useCurrentProject } from "../project/CurrentProject";
import { getIsPrivateEdition, HideInPrivateEdition } from "../auth/PrivateEditionView";
import { useCurrentUser } from "../auth/AuthCommon";

// ======================
// AdvancedSettings
// ======================

interface AdvancedSettingsProps {}

export const AdvancedSettings: React.FC<AdvancedSettingsProps> = observer((props) => {
    return (
        <Box pb={6}>
            <Card>
                <CardHeader title={"Advanced"} subheader={"Adjust advanced settings"} />
                <AdminView>
                    <EditProject />
                    <EmailNotificationPreferences />
                    <CdsSupportPermission />
                </AdminView>
                <LeaveProject />
            </Card>
        </Box>
    );
});

// ======================
// LeaveProject
// ======================

interface LeaveProjectProps {}

export const LeaveProject: React.FC<LeaveProjectProps> = observer((p) => {
    const { projectManagementService, dialogService } = useAppServices();
    const goBackToProjectListScreen = useNavigateToProjectListScreen();
    const isPrivateEdition = getIsPrivateEdition();

    const handleLeaveProject = async () => {
        const config: ConfirmDialogConfig = isPrivateEdition
            ? {
                  title: "Permanently Delete User Account Confirmation",
                  message: "By continuing, you will no longer have access to the project, and your account will be permanently deleted.",
                  autoConfirmationQuestionLine: false,
                  typeToConfirm: "DELETE",
              }
            : {
                  title: "Leave Project Confirmation",
                  message: "By continuing, you will no longer have access to the project.",
                  autoConfirmationQuestionLine: true,
              };
        const confirmed = await dialogService.addConfirmDialog(config);
        if (confirmed) {
            await projectManagementService.leaveProject();
            goBackToProjectListScreen();
        }
    };

    return (
        <Box p={2}>
            <Card variant={"outlined"}>
                <CardHeader
                    title={isPrivateEdition ? "Leave and Delete My User Account" : "Leave Project"}
                    subheader={
                        isPrivateEdition
                            ? "You will no longer have access to the project, and your account will be permanently deleted."
                            : "You will no longer have access to this project"
                    }
                    action={
                        <Box p={2}>
                            <Button color={"error"} variant={"outlined"} onClick={handleLeaveProject}>
                                {isPrivateEdition ? "Delete Account" : "Leave Project"}
                            </Button>
                        </Box>
                    }
                />
            </Card>
        </Box>
    );
});

// ======================
// EmailNotificationPreferences
// ======================

interface EmailNotificationPreferencesProps {}

export const EmailNotificationPreferences: React.FC<EmailNotificationPreferencesProps> = observer((p) => {
    return (
        <Box p={2}>
            <Card variant={"outlined"}>
                <CardHeader
                    title={"Email Notification Preferences"}
                    subheader={"Here you can edit your email notification settings for this project"}
                    action={
                        <Box p={2}>
                            <EmailNotificationPreferencesButton />
                        </Box>
                    }
                />
            </Card>
        </Box>
    );
});

// ======================
// EmailNotificationPreferencesButton
// ======================

interface EmailNotificationPreferencesButtonProps {}

export const EmailNotificationPreferencesButton: React.FC<EmailNotificationPreferencesButtonProps> = observer((p) => {
    const dialogState = useDialogState();
    const { projectService } = useAppServices();

    const [state, setState] = useState({
        enableLicenseTransactionEmail: projectService.currentProject.data?.getInfo().getEnableLicenseTransactionEmailNotification() ?? true,
        enableNewHostEmail: projectService.currentProject.data?.getInfo().getEnableNewHostEmailNotification() ?? true,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setState({
            ...state,
            [event.target.name]: checked,
        });
    };

    const savePreferences = async () => {
        await projectService.setProjectEmailNotifications(state.enableLicenseTransactionEmail, state.enableNewHostEmail);
        await projectService.currentProject.fetchData();
        dialogState.close();
    };

    const dialogFullScreen = !useIsDesktop();

    return (
        <>
            <Button variant={"outlined"} onClick={dialogState.open}>
                Edit
            </Button>
            {dialogState.isOpen && (
                <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullScreen={dialogFullScreen} fullWidth maxWidth={"md"}>
                    <DialogTopBar dialogState={dialogState} title={"Email Notification Preferences"} divider />
                    <DialogContent>
                        <Typography>Email Notification Type</Typography>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            Choose whether or not to receive these email notifications from this project
                        </Typography>
                        <br />
                        <FormControl variant={"standard"}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch checked={state.enableLicenseTransactionEmail} onChange={handleChange} name={"enableLicenseTransactionEmail"} />
                                    }
                                    label={"License Transaction Notification"}
                                />
                                <FormControlLabel
                                    control={<Switch checked={state.enableNewHostEmail} onChange={handleChange} name={"enableNewHostEmail"} />}
                                    label={"New Host Added Notification"}
                                />
                            </FormGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Box display={"flex"} justifyContent={"flex-end"} pr={1} pb={1}>
                            <Button variant={"contained"} onClick={savePreferences}>
                                Save
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
});

// ======================
// CdsSupportPermission
// ======================

interface CdsSupportPermissionProps {}

export const CdsSupportPermission: React.FC<CdsSupportPermissionProps> = observer((p) => {
    const isSupportEnabled = useCurrentProject().getInfo().getAllowSupportStaff();
    const { projectService, dialogService } = useAppServices();
    const subheader = isSupportEnabled
        ? "Authorized Support Representatives can join this project for support purposes. \n" +
          "Once they join the project, they will show up in the project member list."
        : "Remote Assistance is disabled. Our support team does not have access to this project. ";

    const getAction = useCallback(() => {
        if (isSupportEnabled) {
            const onClick = async () => {
                const confirmed = await dialogService.addConfirmDialog({
                    title: "Disable Remote Assistance Permission",
                    message: (
                        <>
                            <Box pb={2}>By continuing, Support Representatives will no longer be able to join the project.</Box>
                            <Box pb={2}>NOTE: All existing Support Representatives will be removed from the project.</Box>
                        </>
                    ),
                    autoConfirmationQuestionLine: true,
                });
                if (confirmed) {
                    await projectService.setSupportPermissions(false);
                    await projectService.currentProject.fetchData();
                }
            };
            return (
                <Button onClick={onClick} variant={"outlined"} color={"error"}>
                    Disable
                </Button>
            );
        } else {
            const onClick = async () => {
                await projectService.setSupportPermissions(true);
                await projectService.currentProject.fetchData();
            };
            return (
                <Button onClick={onClick} variant={"outlined"}>
                    Enable
                </Button>
            );
        }
    }, [isSupportEnabled, dialogService, projectService]);

    return (
        <Box p={2}>
            <Card variant={"outlined"}>
                <CardHeader title={"Remote Assistance Permission"} subheader={subheader} action={<Box p={2}>{getAction()}</Box>} />
            </Card>
        </Box>
    );
});
