// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: ProjectMigrationsScreen

import * as React from "react";
import { observer } from "mobx-react-lite";
import { ScreenContainer } from "../layout/ScreenCommon";
import { ProjectDetails } from "../../_proto/galaxycompletepb/apipb/domainpb/project_pb";
import { Navigate, Route, Routes } from "react-router-dom";
import { DEPLOYMENT_DETAILS_SUBROUTE, DEPLOYMENT_SUBROUTE, MIGRATION_SUBROUTE } from "../app/AppRoutes";
import { GalaxyMigrateMigrationWizardScreen } from "./wizard/GmMigrationWizard";
import { ProjectMigrationSessionsListScreen } from "./ProjectMigrationSessionsListScreen";
import { GmMigrationSessionDetailsScreen } from "./sessionDetails/GmMigrationSessionDetails";
import { GmAutoAllocationScreen } from "./autoallocation/GmAutoAllocation";
import { GmSessionVolumeQosScreen } from "./sessionDetails/qosCharts/GmSessionVolumeQosScreen";
import { GmSessionDetailsEditScreen } from "./sessionDetails/GmMigrationSessionDetailsEditScreen";
import { GmMigrationSessionWorkflowRunDetailsView } from "./workflow/GmMigrationSessionWorkflowRunDetails";
import { DeploymentConnectionsListScreen, DeploymentDetailsScreen, MigrationDeploymentsListScreen } from "../deployment/MigrationDeploymentScreens";
import { GalaxyMigrateDeploymentLiveLog } from "../galaxymigrate/details/GalaxyMigrateDeploymentLiveLog";
import { useIsFeatureEnabled } from "../core/featureflag/FeatureFlags";
import { FeatureFlag } from "../app/AppGlobalService";
import { GmSessionDetailsEditVmScreen } from "./sessionDetails/GmSessionDetailsEditVmScreen";
import { GmDeploymentXrayListScreen } from "../galaxymigrate/details/GalaxyMigrateDeploymentXrayManagement";

// ======================
// ProjectMigrationsScreen
// ======================
interface ProjectMigrationsScreenProps {
    project: ProjectDetails;
}

export const ProjectMigrationsScreens: React.FC<ProjectMigrationsScreenProps> = observer((p) => {
    return (
        <Routes>
            <Route index element={<Navigate to={MIGRATION_SUBROUTE.DEPLOYMENTS} replace />} />
            <Route path={MIGRATION_SUBROUTE.DEPLOYMENTS}>
                <Route index element={<MigrationDeploymentsListScreen project={p.project} />} />
                <Route path={`:deploymentID/${DEPLOYMENT_DETAILS_SUBROUTE.LOG}`} element={<GalaxyMigrateDeploymentLiveLog />} />
                <Route path={`:deploymentID/${DEPLOYMENT_DETAILS_SUBROUTE.XRAYS}`} element={<GmDeploymentXrayListScreen />} />
                <Route path={":deploymentID/*"} element={<DeploymentDetailsScreen />} />
            </Route>
            <Route path={MIGRATION_SUBROUTE.CONNECTIONS} element={<DeploymentConnectionsListScreen />} />
            <Route path={MIGRATION_SUBROUTE.SESSIONS}>
                <Route index element={<ProjectMigrationSessionsListScreen />} />
                <Route path={":sessionId"}>
                    <Route index element={<GmMigrationSessionDetailsScreen />} />
                    <Route path={MIGRATION_SUBROUTE.VOLUMES + "/:sessionVolumeUUID"}>
                        <Route path={MIGRATION_SUBROUTE.QOS} element={<GmSessionVolumeQosScreen />} />
                    </Route>
                    <Route path={MIGRATION_SUBROUTE.WORKFLOW_RUNS + "/:workflowRunId"} element={<GmMigrationSessionWorkflowRunDetailsView />} />
                    <Route path={MIGRATION_SUBROUTE.EDIT} element={<GmSessionDetailsEditScreen />} />
                    <Route path={MIGRATION_SUBROUTE.EDIT_VM_CONFIG} element={<GmSessionDetailsEditVmScreen />} />
                </Route>
            </Route>
            <Route path={MIGRATION_SUBROUTE.WIZARD} element={<GalaxyMigrateMigrationWizardScreen />} />
            <Route path={MIGRATION_SUBROUTE.AUTO_ALLOCATION} element={<GmAutoAllocationScreen />} />
        </Routes>
    );
});
