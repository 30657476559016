import {generatePath, useNavigate} from "react-router-dom";
import {
    getProjectSubRouteTemplate,
    MIGRATION_SUBROUTE,
    PROJECT_SUBROUTE,
    PROTECTION_SUBROUTE
} from "../../app/AppRoutes";
import {useCurrentProjectID} from "../../project/CurrentProject";
import {Chip} from "@mui/material";
import React from "react";

export const renderDeploymentStatusChip = (connected: boolean) => {
    return connected ? <Chip color={'success'} label={'Online'}/> : <Chip color={'error'} label={'Offline'}/>
};
