// Project: GalaxyComplete
// Created: 10/19/20 by sammy
// File: GmAutoAllocation

import * as React from "react";
import { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    Grid,
    Link,
    ListSubheader,
    Step,
    StepLabel,
    Stepper,
    SvgIcon,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import { useAppServices } from "../../app/services";
import { GmAutoAllocationStep, GmMigrationAutoAllocationState } from "../GmMigrationService";
import { GiBigGear } from "react-icons/gi";
import { GoTools } from "react-icons/go";
import { useEffectOnce } from "react-use";
import { useNavigateToMigrationWizardScreen } from "../MigrationCommon";
import { AllocationProgressView, GalaxyMigrateAutoAllocationProgressDialog } from "./GmAutoAllocationProgress";
import { GalaxyMigrateDeploymentDetails } from "../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { isDeploymentGteVersion } from "../../deployment/DeploymentCommon";
import { useGmAutoAllocationState } from "./GmAutoAllocationCommon";
import { useNavigateToAutoAllocation } from "../wizard/GmMigrationWizardUtils";
import { PureCBSAllocateVolumesStep } from "./Integrations/PureStorageIntegration";
import { AzureAllocateVolumesStep } from "./Integrations/azure/AzureIntegration";
import { DGSAllocateVolumesStep } from "./Integrations/DGSIntegration";
import { AWSAllocateVolumesStep } from "./Integrations/AWSIntegration";
import { renderServerDataWithLoadingBox, useInitData } from "../../core/data/DataLoaderHooks";
import { IntegrationConfigInfo, IntegrationModule } from "../../../_proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { getModuleDefByModule, IntegrationAlertConfig, IntegrationCard, useRedirectToIntegrations } from "../../integrations/IntegrationsCommon";
import { NetappAllocateVolumesStep } from "./Integrations/NetappIntegration";
import { BackButton } from "../../../common/CommonButtons";
import { v4 as uuid } from "uuid";
import { PowerStoreAllocateVolumesStep } from "./Integrations/PowerStoreIntegration";
import { PowerMaxIntegration } from "./Integrations/PowerMaxIntegration";
import { GcpAllocateVolumesStep } from "./Integrations/GcpIntegration";
import { OracleAllocateVolumesStep } from "./Integrations/OracleIntegration";
import { SilkSdpAllocateVolumesStep } from "./Integrations/SilkSdpIntegration";
import { DigitalOceanAllocateVolumesStep } from "./Integrations/DigitalOceanIntegration";

// ======================
// GmAutoAllocationScreen
// ======================

export const GM_AUTO_ALLOC_MIN_VERSION = "1.0.9";

export const isGmAutoAllocSupported = (deployment: GalaxyMigrateDeploymentDetails) => {
    return isDeploymentGteVersion(deployment, GM_AUTO_ALLOC_MIN_VERSION);
};

interface GmAutoAllocationScreenProps {}

export const GmAutoAllocationScreen: React.FC<GmAutoAllocationScreenProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    const { gmMigrationService } = useAppServices();

    const navigateBackToWizard = useNavigateToMigrationWizardScreen(true);

    const exitWizard = () => {
        gmMigrationService.initAutoAllocationWizard();
        navigateBackToWizard();
    };

    useEffectOnce(() => {
        if (!state.deploymentDetails) {
            navigateBackToWizard();
        }
    });

    return (
        <ScreenContainer>
            <BackButton navFunction={exitWizard} label={"Back To Wizard"} />
            <ScreenTitleBar title={`Allocate Migration Session Volumes`} />
            <Typography paragraph>{`Automatically create and provision volumes from destination storage`}</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={2}>
                    <AutoAllocateStepper />
                </Grid>
                <Grid item xs={12} lg={10}>
                    {state.currentStep === GmAutoAllocationStep.SELECT_INTEGRATION && <IntegrationSelectionStep />}
                    {state.currentStep === GmAutoAllocationStep.PREPARE_HOST && <PrepareHostStep />}
                    {state.currentStep === GmAutoAllocationStep.ALLOCATE_VOLUMES && <AllocateVolumesStep />}
                </Grid>
            </Grid>
            <GalaxyMigrateAutoAllocationProgressDialog />
        </ScreenContainer>
    );
});

// ======================
// AutoAllocateStepper
// ======================

interface AutoAllocateStepperProps {}

const AutoAllocateStepper: React.FC<AutoAllocateStepperProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
    return (
        <Card>
            <ListSubheader>{"Allocate Dest. Volumes"}</ListSubheader>
            <Box p={2}>
                <Stepper activeStep={state.currentStep} orientation={isDesktop ? "vertical" : "horizontal"}>
                    <Step>
                        <StepLabel>{"Select Integration"}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{"Prepare Host"}</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>{"Allocate Volumes"}</StepLabel>
                    </Step>
                </Stepper>
            </Box>
        </Card>
    );
});

// ======================
// IntegrationSelectionStep
// ======================
interface IntegrationSelectionStepProps {}

const IntegrationSelectionStep: React.FC<IntegrationSelectionStepProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    const { integrationsService } = useAppServices();

    useInitData({
        poll: () => integrationsService.projectIntegrations.fetchData(),
        pollInterval: 3,
        deinit: () => integrationsService.projectIntegrations.resetData(),
    });

    const redirectToIntegrationsPage = useRedirectToIntegrations();
    const goBackToMigrationWizard = useNavigateToMigrationWizardScreen();
    const redirectBackToAutoAllocation = useNavigateToAutoAllocation();

    const addNewIntegration = useCallback(() => {
        const integrationAlertConfig: IntegrationAlertConfig = {
            alertMessage: "You will be redirected back to the auto allocation wizard once an integration is added.",
            navigateBack: goBackToMigrationWizard,
            navigateBackLabel: "Back To Migration Wizard",
        };
        integrationsService.initIntegrationRedirectState(
            redirectBackToAutoAllocation,
            integrationAlertConfig,
            state.deploymentDetails?.getInfo().getDeployment().getSystemId()
        );
        redirectToIntegrationsPage();
    }, [integrationsService, redirectBackToAutoAllocation, state, redirectToIntegrationsPage, goBackToMigrationWizard]);

    return renderServerDataWithLoadingBox(integrationsService.projectIntegrations, (data) => {
        if (!data.getItemsList().length) {
            return (
                <>
                    <Typography variant={"h4"}>{`Select Integration`}</Typography>
                    <Typography variant={"body1"}>No available integration found for allocation. Add one to get started.</Typography>
                    <br />
                    <Button variant={"outlined"} color={"primary"} onClick={addNewIntegration}>
                        Add Integration
                    </Button>
                </>
            );
        }

        return (
            <>
                <Typography variant={"h4"}>{`Select Integration`}</Typography>
                <Typography variant={"body1"}>{`Select an integration below to allocate migration destination volumes from`}</Typography>
                <Typography variant={"body1"}>
                    Please <Link onClick={addNewIntegration}>click here</Link> if you would like to enable an integration not listed below.
                </Typography>
                <br />
                <SelectIntegrationSection integrationsList={data.getItemsList()} />
            </>
        );
    });
});

// ======================
// SelectIntegrationSection
// ======================

interface SelectIntegrationSectionProps {
    integrationsList: IntegrationConfigInfo[];
}

export const SelectIntegrationSection: React.FC<SelectIntegrationSectionProps> = observer((p) => {
    return (
        <Grid container spacing={2}>
            {p.integrationsList.map((i) => {
                return (
                    <Grid item key={i.getId()}>
                        <IntegrationCard
                            module={getModuleDefByModule(i.getModule())}
                            instance={i}
                            cardProps={{ sx: { width: 325 } }}
                            mainActionButton={<SelectIntegrationButton integration={i} />}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
});

// ======================
// SelectIntegrationButton
// ======================

interface SelectIntegrationButtonProps {
    integration: IntegrationConfigInfo;
}

export const SelectIntegrationButton: React.FC<SelectIntegrationButtonProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    const { integrationsService } = useAppServices();

    const handleSelect = useCallback(async () => {
        const statusId = uuid();
        state.setNewStatusId(statusId);
        const connected = await integrationsService.testProjectIntegration(
            p.integration.getId(),
            state.systemID,
            statusId,
            "Checking integration connection..."
        );
        if (connected) {
            state.selectIntegration(p.integration);
            state.clearStatusId();
        }
    }, [state, integrationsService, p.integration]);

    return (
        <Button variant={"contained"} color={"primary"} onClick={handleSelect}>
            Select
        </Button>
    );
});

// ======================
// PrepareHostStep
// ======================
interface PrepareHostStepProps {}

const PrepareHostStep: React.FC<PrepareHostStepProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    const [firstResetDone, setFirstResetDone] = useState(false);

    useEffectOnce(() => {
        state.hostReadiness.resetData();
        setFirstResetDone(true);
    });

    return (
        <div>
            <Typography variant={"h4"}>{`Prepare Host`}</Typography>
            <Typography color={"textSecondary"}>
                {`Optionally, we can help you configure your host to connect to your destination storage if needed using our default best practices`}
            </Typography>
            <br />
            {firstResetDone && (
                <>
                    {!state.hostReadiness.ready && <HostReadinessCheck />}
                    {state.hostReadiness.ready && state.hostReadiness.data.getReady() && <HostAlreadyReadyForAutoAlloc />}
                    {state.hostReadiness.ready && !state.hostReadiness.data.getReady() && <HostConfigurationOptions />}
                </>
            )}
        </div>
    );
});

// ======================
// HostConfigurationOptions
// ======================
interface HostConfigurationOptionsProps {}

const HostConfigurationOptions: React.FC<HostConfigurationOptionsProps> = observer((p) => {
    const state = useGmAutoAllocationState();

    return (
        <>
            <Typography paragraph variant={"caption"}>
                {"Host is not ready for auto allocation: "} {state.hostReadiness.data.getReason()}
            </Typography>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6} xl={4}>
                    <Card sx={{ height: "100%" }}>
                        <Box p={2} height={"100%"}>
                            <Box height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"center"}>
                                <Box flexGrow={1}>
                                    <Typography align={"center"}>
                                        <SvgIcon style={{ fontSize: 100 }} viewBox={`0 0 100 100`}>
                                            <GiBigGear />
                                        </SvgIcon>
                                    </Typography>
                                    <Typography variant={"h5"} align={"center"}>
                                        {"Automatic Setup"}
                                    </Typography>
                                    <br />
                                    <Typography>
                                        {
                                            "We will automatically configure your host according to the storage vendors' default deployment configuration. Additional packages may be installed on your host."
                                        }
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography align={"center"}>
                                        <Button variant={"contained"} color={"primary"} onClick={() => state.autoConfigure()}>
                                            {"Configure Host for me"}
                                        </Button>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} xl={4}>
                    <Card sx={{ height: "100%" }}>
                        <Box p={2} height={"100%"}>
                            <Box height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"center"}>
                                <Box flexGrow={1}>
                                    <Typography align={"center"}>
                                        <SvgIcon style={{ fontSize: 100 }} viewBox={`0 0 100 100`}>
                                            <GoTools />
                                        </SvgIcon>
                                    </Typography>
                                    <Typography variant={"h5"} align={"center"}>
                                        {"Manual"}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography>
                                        {
                                            "You will configure your host on your own. Click below to continue when host connectivity to the storage is confirmed and host is in a state ready to provision volumes."
                                        }
                                    </Typography>
                                    <br />
                                    <Typography align={"center"}>
                                        <Button variant={"contained"} onClick={() => state.manualCheck()}>
                                            {"Yes, host is already configured"}
                                        </Button>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// HostAlreadyReadyForAutoAlloc
// ======================
interface HostAlreadyReadyForAutoAllocProps {}

const HostAlreadyReadyForAutoAlloc: React.FC<HostAlreadyReadyForAutoAllocProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    return (
        <>
            <Alert color={"success"}>
                <AlertTitle>{"Host is ready for auto allocation"}</AlertTitle>
            </Alert>
            <br />
            <Button color={"primary"} variant={"contained"} onClick={() => state.selectCurrentStep(GmAutoAllocationStep.ALLOCATE_VOLUMES)}>
                {"Continue"}
            </Button>
        </>
    );
});

// ======================
// HostReadinessCheck
// ======================
interface HostReadinessCheckProps {}

const HostReadinessCheck: React.FC<HostReadinessCheckProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    useEffectOnce(() => {
        state.hostReadiness.fetchData();
    });
    return (
        <>
            <AllocationProgressView noActions />
        </>
    );
});

// ======================
// AllocateVolumesStep
// ======================
interface AllocateVolumesStepProps {}

const AllocateVolumesStep: React.FC<AllocateVolumesStepProps> = observer((p) => {
    const state = useGmAutoAllocationState();
    const backToMigrationWizard = useNavigateToMigrationWizardScreen(true);
    const { progressService, dialogService } = useAppServices();
    const allocateNow = async () => {
        state.setShowProgressDialog(true);
        const allocatedVolumes = await dialogService.catchAndAlertError(state.allocateVolumes());
        await state.waitForProgressDialogToClose();
        await progressService.track(state.applyAllocatedVolumesToWizard(allocatedVolumes));
        backToMigrationWizard();
    };

    return (
        <>
            <Typography variant={"h4"}>{`Allocate Destination Volumes`}</Typography>
            {getVendorAllocateVolumesStep(state, allocateNow)}
        </>
    );
});

const getVendorAllocateVolumesStep = (state: GmMigrationAutoAllocationState, allocateFunc: () => Promise<void>) => {
    const selectedIntegrationModule = state.selectedIntegration.getModule();
    if (selectedIntegrationModule === IntegrationModule.PURE) {
        return <PureCBSAllocateVolumesStep state={state} allocateFunc={allocateFunc} />;
    } else if (selectedIntegrationModule === IntegrationModule.AZURE) {
        return <AzureAllocateVolumesStep state={state} allocateFunc={allocateFunc} />;
    } else if (selectedIntegrationModule === IntegrationModule.DGS) {
        return <DGSAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.AWS) {
        return <AWSAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.NETAPP || selectedIntegrationModule === IntegrationModule.AWS_FSX_NETAPP) {
        return <NetappAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.FC_PURE) {
        return <PureCBSAllocateVolumesStep state={state} allocateFunc={allocateFunc} />;
    } else if (selectedIntegrationModule === IntegrationModule.FC_POWERSTORE || selectedIntegrationModule === IntegrationModule.POWERSTORE) {
        return <PowerStoreAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.FC_POWERMAX) {
        return <PowerMaxIntegration allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.GCP) {
        return <GcpAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.ORACLE) {
        return <OracleAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.SILKSDP) {
        return <SilkSdpAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    } else if (selectedIntegrationModule === IntegrationModule.DIGITALOCEAN) {
        return <DigitalOceanAllocateVolumesStep allocateFunc={allocateFunc} state={state} />;
    }
};
