import {observer} from 'mobx-react-lite';
import React from 'react';
import {useAppServices} from '../app/services';
import {

    useInitData
} from '../core/data/DataLoaderHooks';
import {useNavigateToProjectListScreen} from '../project/ProjectCommon';

import {LicenseKeyMainScreen, } from './LicenseKeyMainScreen';


// ======================
// UserLicenseScreen
// ======================


interface UserLicenseScreenProps {
}

export const UserLicenseScreen: React.FC<UserLicenseScreenProps> = observer((p) => {
    const {licenseService, projectService} = useAppServices();
    const goToProjectsList                 = useNavigateToProjectListScreen();

    useInitData({
                    init        : () => projectService.clearCurrentProject(),
                    poll        : () => licenseService.userLicenseVaultDetails.fetchData(),
                    pollInterval: 30,
                    deinit      : () => licenseService.userLicenseVaultDetails.resetData(),
                });

    useInitData({
        poll: () => licenseService.userLicenseTransactions.fetchData(),
        pollInterval: 3
    })

    return <LicenseKeyMainScreen type={'user'}
                                 backButtonNavFunction={goToProjectsList}
                                 refresh={async() => await licenseService.userLicenseVaultDetails.fetchData()}

    />

});



