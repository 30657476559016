import {GRPCServices} from "../../grpc/grpcapi";
import {ProjectService} from "../../project/ProjectService";
import {DialogService} from "../../core/dialog/DialogService";
import {ProgressService} from "../../core/progress/ProgressService";
import {makeAutoObservable} from "mobx";
import {
    GetCirrusProtectDeploymentDetails,
    ListCirrusProtectDeployments, RemoveDeployment
} from "../../../_proto/galaxycompletepb/apipb/deployment_api_pb";
import {ServerListData} from "../../core/data/ListData";
import {SystemCheckinInfo} from "../../../_proto/galaxycompletepb/hubpb/systems_pb";
import {ServerData} from "../../core/data/ServerData";
import {
    CirrusProtectDeploymentDetails,
    CirrusProtectDeploymentInfo, CirrusProtectDeviceInfo
} from "../../../_proto/galaxycompletepb/apipb/domainpb/cirrusprotect_pb";
import {
    mockCpDeploymentDetails,
    mockListCpDeployments,
    mockListUnregisteredCpDeployments
} from "../../core/testutils/fixtures/MockCpDeploymentService";
import {CDMPolicyDetails, PhoenixDeploymentInfo} from "../../../_proto/galaxycompletepb/apipb/domainpb/phoenix_pb";
import {
    AddDisksToPolicy,
    Deregister,
    GetPolicyDetails, ListInsertionCandidates,
    RegisterToPhoenix, RemoveDisksFromPolicy
} from "../../../_proto/galaxycompletepb/apipb/cpapipb/cirrusprotect_api_pb";
import {DeRegisterFromPhoenixRequest} from "../../../_proto/galaxymigratepb/galaxy_migrate_cirrusprotect_pb";
import {DeploymentService} from "../../deployment/DeploymentService";

export class CirrusProtectDeploymentService{
    private api: GRPCServices;
    private projectService: ProjectService;
    private dialogService: DialogService;
    private progressService: ProgressService;
    private deploymentService: DeploymentService;

    connectToPhoenixState: CpDeploymentConnectToPhoenixState;

    constructor(api: GRPCServices, projectService: ProjectService, deploymentService: DeploymentService, dialogService: DialogService, progressService: ProgressService){
        this.api = api;
        this.projectService = projectService;
        this.dialogService = dialogService;
        this.progressService = progressService;

        this.initConnectToPhoenixState();

        makeAutoObservable(this)
    }

    cpDeployments = new ServerListData<ListCirrusProtectDeployments.Response, CirrusProtectDeploymentInfo>().setDataFetcher(this.fetchRegisteredCpDeployments.bind(this));
    cpDeploymentDetails = new ServerData<CirrusProtectDeploymentInfo>().setDataFetcher(this.fetchCpDeploymentDetails.bind(this));
    cpDeploymentPolicyDetails = new ServerData<GetPolicyDetails.Response>().setDataFetcher(this.fetchCpDeploymentPolicyDetails.bind(this))
    cpDeploymentPolicyId: string = null;
    unregisteredDeployments = new ServerListData<ListCirrusProtectDeployments.Response, CirrusProtectDeploymentInfo>(10000, null, r=>r.getDeployment().getSystemId(), r=>!r.getDeployment().getConnected()).setDataFetcher(this.fetchUnregisteredCpDeployments.bind(this))
    insertionCandidates = new ServerListData<ListInsertionCandidates.Response, CirrusProtectDeviceInfo>().setDataFetcher(this.listInsertionCandidates.bind(this))

    initConnectToPhoenixState() {
        this.connectToPhoenixState = new CpDeploymentConnectToPhoenixState(this.api, this.dialogService, this.progressService)
        return this.connectToPhoenixState;
    }

    setPolicyId(id: string){
        this.cpDeploymentPolicyId = id;
    }

    get currentPolicyId(){
        return this.cpDeploymentPolicyId;
    }

    get currentDeploymentId(){
        return this.cpDeploymentDetails.data?.getDeployment().getSystemId()
    }

    async fetchRegisteredCpDeployments(onlyConnected: boolean, onlyIfPhoenixRegisteredIsConnected: boolean, onlyIfRegisteredToPhoenix: boolean){
        const req = new ListCirrusProtectDeployments.Request()
            .setPageParams(this.cpDeployments.pagerParam)
            .setProjectId(this.projectService.currentProjectID)
            .setOnlyConnected(onlyConnected)
            .setOnlyIfPhoenixRegisteredIsConnected(onlyIfPhoenixRegisteredIsConnected)
            .setOnlyIfRegisteredToPhoenix(onlyIfRegisteredToPhoenix);

        //return mockListCpDeployments;
        return await this.api.deploymentService.listCirrusProtectDeployments(req, null);
    };

    async fetchUnregisteredCpDeployments(){
        const req = new ListCirrusProtectDeployments.Request()
            .setProjectId(this.projectService.currentProjectID)
            .setOnlyIfNotRegisteredToPhoenix(true)

        return await this.api.deploymentService.listCirrusProtectDeployments(req, null);
    }

    async fetchCpDeploymentDetails(deploymentId: string){
        const req = new GetCirrusProtectDeploymentDetails.Request()
            .setSystemId(deploymentId)
        const response = await this.api.deploymentService.getCirrusProtectDeploymentDetails(req, null);
        return response.getDeployment().getInfo();
    };

    async fetchCpDeploymentPolicyDetails(systemId?: string){
        const req = new GetPolicyDetails.Request()
            .setCirrusProtectId(this.currentDeploymentId || systemId)
        const response = await this.api.cpService.getPolicyDetails(req, null)
        this.setPolicyId(response.getPolicy().getPolicyInfo().getPolicyId())
        return response
    }

    async listInsertionCandidates(deploymentId?: string){
        const req = new ListInsertionCandidates.Request()
            .setCirrusProtectId(this.currentDeploymentId || deploymentId)
        return await this.api.cpService.listInsertionCandidates(req, null);
    }

    async addDisksToPolicy(devicePaths: string[], systemId?: string){
        const req = new AddDisksToPolicy.Request()
            .setCirrusProtectId(this.currentDeploymentId || systemId)
            .setDevicePathsList(devicePaths)
        return await this.progressService.track(this.api.cpService.addDisksToPolicy(req, null))
    }

    async removeDisksFromPolicy(devicePaths: string[], systemId?: string){
        const req = new RemoveDisksFromPolicy.Request()
            .setCirrusProtectId(this.currentDeploymentId || systemId)
            .setDevicePathsList(devicePaths)
        return await this.progressService.track(this.api.cpService.removeDisksFromPolicy(req, null))
    }

    async fetchCpDeploymentVolumes(deploymentId: string){

    }

    async unpairCpDeploymentFromPhoenix(deploymentId: string, forceDeregister: boolean = false){
        const req = new Deregister.Request()
            .setCirrusProtectId(deploymentId)
            .setForce(forceDeregister)

        return await this.progressService.track(this.api.cpService.deregister(req, null), 'Unpairing host. Please wait...');
    };

    async removeCpDeployment(deploymentId: string){
        const req = new RemoveDeployment.Request()
            .setSystemId(deploymentId)
        return await this.progressService.track(this.api.deploymentService.removeDeployment(req, null))
    }

};

export class CpDeploymentConnectToPhoenixState{
    private api: GRPCServices;
    private dialogService: DialogService;
    private progressService: ProgressService;

    cirrusProtectDeployments: {id: string, name: string}[] = [];
    selectedPhoenixDeployment: {id: string, name: string} = null;
    connectionAddress: string = '';

    constructor(api: GRPCServices, dialogService: DialogService, progressService: ProgressService){
        this.api = api;
        this.dialogService = dialogService;
        this.progressService = progressService;

        makeAutoObservable(this)
    }

    async connectCpDeploymentToPhoenix(){
        for (let d of this.cirrusProtectDeployments){
            const req = new RegisterToPhoenix.Request()
                .setCirrusProtectId(d.id)
                .setPhoenixAddress(this.connectionAddress)
                .setPhoenixId(this.selectedPhoenixDeployment.id)

            return await this.progressService.track(this.api.cpService.registerToPhoenix(req, null), 'Pairing host. Please wait...');
        }
    }

    setCpDeployments(d: {id: string, name: string}[]){
        this.cirrusProtectDeployments = d;
    }

    selectPhoenixDeployment(deployment: {id: string, name: string}){
        this.selectedPhoenixDeployment = deployment;
    }

    resetConnectToPhoenixState(){
        this.cirrusProtectDeployments = [];
        this.selectedPhoenixDeployment = null;
        this.connectionAddress = '';
    }

    get isSubmitDisabled(){
        return !this.cirrusProtectDeployments.length || !this.selectedPhoenixDeployment || !this.connectionAddress
    }

    setConnectionAddress(v: string){
        this.connectionAddress = v;
    }

}