import {Theme} from "@mui/material/styles/createTheme";
import {CdmPolicySyncStatus} from "../../../_proto/galaxycompletepb/apipb/domainpb/enumpb/cdm_policy_sync_status_pb";
import {CdmPolicyStatus} from "../../../_proto/galaxycompletepb/apipb/domainpb/enumpb/cdm_policy_status_pb";
import {CdmPolicyMode} from "../../../_proto/galaxycompletepb/apipb/domainpb/enumpb/cdm_policy_mode_pb";
import {CdmPolicyType} from "../../../_proto/galaxycompletepb/apipb/domainpb/enumpb/cdm_policy_type_pb";
import CDP from '../../../assets/protection/CDP.png';
import Snapshot from '../../../assets/protection/Snapshot.png';
import Rollback from '../../../assets/protection/Rollback.png';
import VolumeWiki from '../../../assets/protection/VolumeWiki.png';
import {ImgHTMLAttributes} from "react";
import {GalaxyMigrateStorageConfig} from "../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import {CirrusProtectDeviceInfo} from "../../../_proto/galaxycompletepb/apipb/domainpb/cirrusprotect_pb";

export const renderSyncStatusName = (status: CdmPolicySyncStatus.CdmPolicySyncStatus) => {
    if (status === CdmPolicySyncStatus.CdmPolicySyncStatus.TRACKING){
        return 'Tracking'
    } else if (status === CdmPolicySyncStatus.CdmPolicySyncStatus.SYNCING){
        return 'Syncing'
    } else if (status === CdmPolicySyncStatus.CdmPolicySyncStatus.FAILED){
        return 'Failed'
    } else if (status === CdmPolicySyncStatus.CdmPolicySyncStatus.IN_ERROR){
        return 'In Error'
    } else if (status === CdmPolicySyncStatus.CdmPolicySyncStatus.MIRRORING){
        return 'Mirroring'
    } else if (status === CdmPolicySyncStatus.CdmPolicySyncStatus.RESTORING){
        return 'Restoring'
    } else if (status === CdmPolicySyncStatus.CdmPolicySyncStatus.UNKNOWN){
        return 'Unknown'
    }
};

export const renderPolicyStatusName = (status: CdmPolicyStatus.CdmPolicyStatus) => {
    if (status === CdmPolicyStatus.CdmPolicyStatus.ACTIVE){
        return 'Active'
    } else if (status === CdmPolicyStatus.CdmPolicyStatus.NEW){
        return 'New'
    } else if (status === CdmPolicyStatus.CdmPolicyStatus.STOPPED){
        return 'Stopped'
    } else if (status === CdmPolicyStatus.CdmPolicyStatus.UNKNOWN){
        return 'Unknown'
    }
};

export const renderPolicyModeName = (mode: CdmPolicyMode.CdmPolicyMode) => {
    if (mode === CdmPolicyMode.CdmPolicyMode.PERIODIC){
        return 'Periodic Synchronization';
    } else if (mode === CdmPolicyMode.CdmPolicyMode.CONTINUOUS){
        return 'Continuous Synchronization';
    } else if (mode === CdmPolicyMode.CdmPolicyMode.CONTINUOUS_ASYNC){
        return 'Continuous Async';
    } else if (mode === CdmPolicyMode.CdmPolicyMode.UNKNOWN){
        return 'Unknown';
    }
};

export const renderPolicyTypeName = (type: CdmPolicyType.CdmPolicyType) => {
    if (type === CdmPolicyType.CdmPolicyType.MTDI_BACKUP){
        return 'mTDI Backup';
    } else if (type === CdmPolicyType.CdmPolicyType.LOCAL_PROTECTION){
        return 'Local Protection';
    } else if (type === CdmPolicyType.CdmPolicyType.TDI_BACKUP){
        return 'TDI Backup';
    } else if (type === CdmPolicyType.CdmPolicyType.VMWARE_BACKUP){
        return 'VMware Backup';
    } else if (type === CdmPolicyType.CdmPolicyType.UNKNOWN){
        return 'Unknown';
    }
};

export const getInsertionCandidateDeviceType = (d: CirrusProtectDeviceInfo) => {
    let deviceType = d.getDeviceType();
    if (d.getFsType()) {
        deviceType = `${deviceType} (${d.getFsType()})`;
    }
    return deviceType;
};

// ======================
// CdpIcon
// ======================

interface CdpIconProps{
    imageAttributes?: ImgHTMLAttributes<HTMLImageElement>
}

export const CdpIcon: React.FC<CdpIconProps> = (p)=>{

return <img src={CDP} alt={'Continuous Data Protection'} {...p.imageAttributes}/>
}

// ======================
// SnapshotIcon
// ======================

interface SnapshotIconProps{
    imageAttributes?: ImgHTMLAttributes<HTMLImageElement>
}

export const SnapshotIcon: React.FC<SnapshotIconProps> = (p)=>{

return <img src={Snapshot} alt={'Policy Snapshots'} {...p.imageAttributes}/>
}

// ======================
// RollbackIcon
// ======================

interface RollbackIconProps{
    imageAttributes?: ImgHTMLAttributes<HTMLImageElement>
}

export const RollbackIcon: React.FC<RollbackIconProps> = (p)=>{

return <img src={Rollback} alt={'Rollback Data'} {...p.imageAttributes}/>
}

// ======================
// VolumeWikiIcon
// ======================

interface VolumeWikiIconProps{
    imageAttributes?: ImgHTMLAttributes<HTMLImageElement>
}

export const VolumeWikiIcon: React.FC<VolumeWikiIconProps> = (p)=>{

    return <img src={VolumeWiki} alt={'Rollback Data'} {...p.imageAttributes}/>
}