// source: galaxycompletepb/apipb/deployment_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var galaxycompletepb_commonpb_datafilter_pb = require('../../galaxycompletepb/commonpb/datafilter_pb.js');
goog.object.extend(proto, galaxycompletepb_commonpb_datafilter_pb);
var galaxycompletepb_apipb_domainpb_galaxymigrate_pb = require('../../galaxycompletepb/apipb/domainpb/galaxymigrate_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_galaxymigrate_pb);
var galaxycompletepb_apipb_domainpb_cirrusprotect_pb = require('../../galaxycompletepb/apipb/domainpb/cirrusprotect_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_cirrusprotect_pb);
var galaxycompletepb_apipb_domainpb_enumpb_product_type_pb = require('../../galaxycompletepb/apipb/domainpb/enumpb/product_type_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_product_type_pb);
var galaxycompletepb_apipb_domainpb_phoenix_pb = require('../../galaxycompletepb/apipb/domainpb/phoenix_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_phoenix_pb);
var galaxycompletepb_apipb_domainpb_license_pb = require('../../galaxycompletepb/apipb/domainpb/license_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_license_pb);
var google_api_annotations_pb = require('../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.galaxycomplete.api.DeleteHostLicense', null, global);
goog.exportSymbol('proto.galaxycomplete.api.DeleteHostLicense.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.DeleteHostLicense.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetPhoenixDeploymentDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListCirrusProtectDeployments', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListCirrusProtectDeployments.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListCirrusProtectDeployments.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListGalaxyMigrateDeployments', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListHostLicenseTransactions', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListHostLicenseTransactions.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListHostLicenseTransactions.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListPhoenixDeployments', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListPhoenixDeployments.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListPhoenixDeployments.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.PingDeployment', null, global);
goog.exportSymbol('proto.galaxycomplete.api.PingDeployment.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.PingDeployment.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.RemoveDeployment', null, global);
goog.exportSymbol('proto.galaxycomplete.api.RemoveDeployment.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.RemoveDeployment.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.RemoveOfflineDeployments', null, global);
goog.exportSymbol('proto.galaxycomplete.api.RemoveOfflineDeployments.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.RemoveOfflineDeployments.Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListHostLicenseTransactions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListHostLicenseTransactions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListHostLicenseTransactions.displayName = 'proto.galaxycomplete.api.ListHostLicenseTransactions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListHostLicenseTransactions.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListHostLicenseTransactions.Request.displayName = 'proto.galaxycomplete.api.ListHostLicenseTransactions.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.ListHostLicenseTransactions.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.ListHostLicenseTransactions.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListHostLicenseTransactions.Response.displayName = 'proto.galaxycomplete.api.ListHostLicenseTransactions.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.DeleteHostLicense = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.DeleteHostLicense, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.DeleteHostLicense.displayName = 'proto.galaxycomplete.api.DeleteHostLicense';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.DeleteHostLicense.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.DeleteHostLicense.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.DeleteHostLicense.Request.displayName = 'proto.galaxycomplete.api.DeleteHostLicense.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.DeleteHostLicense.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.DeleteHostLicense.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.DeleteHostLicense.Response.displayName = 'proto.galaxycomplete.api.DeleteHostLicense.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListCirrusProtectDeployments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListCirrusProtectDeployments.displayName = 'proto.galaxycomplete.api.ListCirrusProtectDeployments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListCirrusProtectDeployments.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.displayName = 'proto.galaxycomplete.api.ListCirrusProtectDeployments.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.ListCirrusProtectDeployments.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.displayName = 'proto.galaxycomplete.api.ListCirrusProtectDeployments.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.displayName = 'proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request.displayName = 'proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response.displayName = 'proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListGalaxyMigrateDeployments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListGalaxyMigrateDeployments.displayName = 'proto.galaxycomplete.api.ListGalaxyMigrateDeployments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.displayName = 'proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.displayName = 'proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.displayName = 'proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request.displayName = 'proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response.displayName = 'proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListPhoenixDeployments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListPhoenixDeployments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListPhoenixDeployments.displayName = 'proto.galaxycomplete.api.ListPhoenixDeployments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListPhoenixDeployments.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListPhoenixDeployments.Request.displayName = 'proto.galaxycomplete.api.ListPhoenixDeployments.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.ListPhoenixDeployments.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.ListPhoenixDeployments.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListPhoenixDeployments.Response.displayName = 'proto.galaxycomplete.api.ListPhoenixDeployments.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetPhoenixDeploymentDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetPhoenixDeploymentDetails.displayName = 'proto.galaxycomplete.api.GetPhoenixDeploymentDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request.displayName = 'proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response.displayName = 'proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.PingDeployment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.PingDeployment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.PingDeployment.displayName = 'proto.galaxycomplete.api.PingDeployment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.PingDeployment.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.PingDeployment.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.PingDeployment.Request.displayName = 'proto.galaxycomplete.api.PingDeployment.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.PingDeployment.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.PingDeployment.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.PingDeployment.Response.displayName = 'proto.galaxycomplete.api.PingDeployment.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.RemoveDeployment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.RemoveDeployment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.RemoveDeployment.displayName = 'proto.galaxycomplete.api.RemoveDeployment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.RemoveDeployment.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.RemoveDeployment.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.RemoveDeployment.Request.displayName = 'proto.galaxycomplete.api.RemoveDeployment.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.RemoveDeployment.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.RemoveDeployment.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.RemoveDeployment.Response.displayName = 'proto.galaxycomplete.api.RemoveDeployment.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.RemoveOfflineDeployments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.RemoveOfflineDeployments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.RemoveOfflineDeployments.displayName = 'proto.galaxycomplete.api.RemoveOfflineDeployments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.RemoveOfflineDeployments.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.RemoveOfflineDeployments.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.RemoveOfflineDeployments.Request.displayName = 'proto.galaxycomplete.api.RemoveOfflineDeployments.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.RemoveOfflineDeployments.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.RemoveOfflineDeployments.Response.displayName = 'proto.galaxycomplete.api.RemoveOfflineDeployments.Response';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListHostLicenseTransactions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListHostLicenseTransactions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListHostLicenseTransactions}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListHostLicenseTransactions;
  return proto.galaxycomplete.api.ListHostLicenseTransactions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListHostLicenseTransactions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListHostLicenseTransactions}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListHostLicenseTransactions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListHostLicenseTransactions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListHostLicenseTransactions.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListHostLicenseTransactions.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageParams: (f = msg.getPageParams()) && galaxycompletepb_commonpb_datafilter_pb.PagerParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListHostLicenseTransactions.Request}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListHostLicenseTransactions.Request;
  return proto.galaxycomplete.api.ListHostLicenseTransactions.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListHostLicenseTransactions.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListHostLicenseTransactions.Request}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerParams;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerParams.deserializeBinaryFromReader);
      msg.setPageParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListHostLicenseTransactions.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListHostLicenseTransactions.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.ListHostLicenseTransactions.Request} returns this
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.common.PagerParams page_params = 2;
 * @return {?proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Request.prototype.getPageParams = function() {
  return /** @type{?proto.galaxycomplete.common.PagerParams} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerParams, 2));
};


/**
 * @param {?proto.galaxycomplete.common.PagerParams|undefined} value
 * @return {!proto.galaxycomplete.api.ListHostLicenseTransactions.Request} returns this
*/
proto.galaxycomplete.api.ListHostLicenseTransactions.Request.prototype.setPageParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListHostLicenseTransactions.Request} returns this
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Request.prototype.clearPageParams = function() {
  return this.setPageParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Request.prototype.hasPageParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListHostLicenseTransactions.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListHostLicenseTransactions.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerMeta: (f = msg.getPagerMeta()) && galaxycompletepb_commonpb_datafilter_pb.PagerMeta.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    galaxycompletepb_apipb_domainpb_license_pb.HostLicenseTxInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListHostLicenseTransactions.Response}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListHostLicenseTransactions.Response;
  return proto.galaxycomplete.api.ListHostLicenseTransactions.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListHostLicenseTransactions.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListHostLicenseTransactions.Response}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerMeta;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerMeta.deserializeBinaryFromReader);
      msg.setPagerMeta(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_license_pb.HostLicenseTxInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_license_pb.HostLicenseTxInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListHostLicenseTransactions.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListHostLicenseTransactions.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerMeta.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_license_pb.HostLicenseTxInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.common.PagerMeta pager_meta = 1;
 * @return {?proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Response.prototype.getPagerMeta = function() {
  return /** @type{?proto.galaxycomplete.common.PagerMeta} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerMeta, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerMeta|undefined} value
 * @return {!proto.galaxycomplete.api.ListHostLicenseTransactions.Response} returns this
*/
proto.galaxycomplete.api.ListHostLicenseTransactions.Response.prototype.setPagerMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListHostLicenseTransactions.Response} returns this
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Response.prototype.clearPagerMeta = function() {
  return this.setPagerMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Response.prototype.hasPagerMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated galaxycomplete.domain.HostLicenseTxInfo items = 2;
 * @return {!Array<!proto.galaxycomplete.domain.HostLicenseTxInfo>}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Response.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.HostLicenseTxInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_license_pb.HostLicenseTxInfo, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.HostLicenseTxInfo>} value
 * @return {!proto.galaxycomplete.api.ListHostLicenseTransactions.Response} returns this
*/
proto.galaxycomplete.api.ListHostLicenseTransactions.Response.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.HostLicenseTxInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo}
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Response.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.HostLicenseTxInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.ListHostLicenseTransactions.Response} returns this
 */
proto.galaxycomplete.api.ListHostLicenseTransactions.Response.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.DeleteHostLicense.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.DeleteHostLicense.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.DeleteHostLicense} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.DeleteHostLicense.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.DeleteHostLicense}
 */
proto.galaxycomplete.api.DeleteHostLicense.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.DeleteHostLicense;
  return proto.galaxycomplete.api.DeleteHostLicense.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.DeleteHostLicense} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.DeleteHostLicense}
 */
proto.galaxycomplete.api.DeleteHostLicense.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.DeleteHostLicense.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.DeleteHostLicense.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.DeleteHostLicense} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.DeleteHostLicense.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.DeleteHostLicense.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.DeleteHostLicense.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.DeleteHostLicense.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.DeleteHostLicense.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.DeleteHostLicense.Request}
 */
proto.galaxycomplete.api.DeleteHostLicense.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.DeleteHostLicense.Request;
  return proto.galaxycomplete.api.DeleteHostLicense.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.DeleteHostLicense.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.DeleteHostLicense.Request}
 */
proto.galaxycomplete.api.DeleteHostLicense.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.DeleteHostLicense.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.DeleteHostLicense.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.DeleteHostLicense.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.DeleteHostLicense.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.DeleteHostLicense.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.DeleteHostLicense.Request} returns this
 */
proto.galaxycomplete.api.DeleteHostLicense.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.DeleteHostLicense.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.DeleteHostLicense.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.DeleteHostLicense.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.DeleteHostLicense.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.DeleteHostLicense.Response}
 */
proto.galaxycomplete.api.DeleteHostLicense.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.DeleteHostLicense.Response;
  return proto.galaxycomplete.api.DeleteHostLicense.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.DeleteHostLicense.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.DeleteHostLicense.Response}
 */
proto.galaxycomplete.api.DeleteHostLicense.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.DeleteHostLicense.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.DeleteHostLicense.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.DeleteHostLicense.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.DeleteHostLicense.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListCirrusProtectDeployments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListCirrusProtectDeployments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListCirrusProtectDeployments;
  return proto.galaxycomplete.api.ListCirrusProtectDeployments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListCirrusProtectDeployments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListCirrusProtectDeployments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListCirrusProtectDeployments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageParams: (f = msg.getPageParams()) && galaxycompletepb_commonpb_datafilter_pb.PagerParams.toObject(includeInstance, f),
    onlyConnected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    onlyIfRegisteredToPhoenix: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    onlyIfPhoenixRegisteredIsConnected: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    onlyIfNotRegisteredToPhoenix: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Request}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListCirrusProtectDeployments.Request;
  return proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Request}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerParams;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerParams.deserializeBinaryFromReader);
      msg.setPageParams(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyConnected(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyIfRegisteredToPhoenix(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyIfPhoenixRegisteredIsConnected(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyIfNotRegisteredToPhoenix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerParams.serializeBinaryToWriter
    );
  }
  f = message.getOnlyConnected();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getOnlyIfRegisteredToPhoenix();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOnlyIfPhoenixRegisteredIsConnected();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOnlyIfNotRegisteredToPhoenix();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.common.PagerParams page_params = 2;
 * @return {?proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.getPageParams = function() {
  return /** @type{?proto.galaxycomplete.common.PagerParams} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerParams, 2));
};


/**
 * @param {?proto.galaxycomplete.common.PagerParams|undefined} value
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Request} returns this
*/
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.setPageParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.clearPageParams = function() {
  return this.setPageParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.hasPageParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool only_connected = 3;
 * @return {boolean}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.getOnlyConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.setOnlyConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool only_if_registered_to_phoenix = 4;
 * @return {boolean}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.getOnlyIfRegisteredToPhoenix = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.setOnlyIfRegisteredToPhoenix = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool only_if_phoenix_registered_is_connected = 5;
 * @return {boolean}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.getOnlyIfPhoenixRegisteredIsConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.setOnlyIfPhoenixRegisteredIsConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool only_if_not_registered_to_phoenix = 6;
 * @return {boolean}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.getOnlyIfNotRegisteredToPhoenix = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Request.prototype.setOnlyIfNotRegisteredToPhoenix = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerMeta: (f = msg.getPagerMeta()) && galaxycompletepb_commonpb_datafilter_pb.PagerMeta.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    galaxycompletepb_apipb_domainpb_cirrusprotect_pb.CirrusProtectDeploymentInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Response}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListCirrusProtectDeployments.Response;
  return proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Response}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerMeta;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerMeta.deserializeBinaryFromReader);
      msg.setPagerMeta(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_cirrusprotect_pb.CirrusProtectDeploymentInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_cirrusprotect_pb.CirrusProtectDeploymentInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerMeta.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_cirrusprotect_pb.CirrusProtectDeploymentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.common.PagerMeta pager_meta = 1;
 * @return {?proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.prototype.getPagerMeta = function() {
  return /** @type{?proto.galaxycomplete.common.PagerMeta} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerMeta, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerMeta|undefined} value
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Response} returns this
*/
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.prototype.setPagerMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Response} returns this
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.prototype.clearPagerMeta = function() {
  return this.setPagerMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.prototype.hasPagerMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated galaxycomplete.domain.CirrusProtectDeploymentInfo items = 2;
 * @return {!Array<!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo>}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_cirrusprotect_pb.CirrusProtectDeploymentInfo, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo>} value
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Response} returns this
*/
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo}
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.CirrusProtectDeploymentInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.ListCirrusProtectDeployments.Response} returns this
 */
proto.galaxycomplete.api.ListCirrusProtectDeployments.Response.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails}
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails;
  return proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails}
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request}
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request;
  return proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request}
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request} returns this
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    deployment: (f = msg.getDeployment()) && galaxycompletepb_apipb_domainpb_cirrusprotect_pb.CirrusProtectDeploymentDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response}
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response;
  return proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response}
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_cirrusprotect_pb.CirrusProtectDeploymentDetails;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_cirrusprotect_pb.CirrusProtectDeploymentDetails.deserializeBinaryFromReader);
      msg.setDeployment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeployment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_cirrusprotect_pb.CirrusProtectDeploymentDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.CirrusProtectDeploymentDetails deployment = 2;
 * @return {?proto.galaxycomplete.domain.CirrusProtectDeploymentDetails}
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response.prototype.getDeployment = function() {
  return /** @type{?proto.galaxycomplete.domain.CirrusProtectDeploymentDetails} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_cirrusprotect_pb.CirrusProtectDeploymentDetails, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.CirrusProtectDeploymentDetails|undefined} value
 * @return {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response} returns this
*/
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response.prototype.setDeployment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response} returns this
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response.prototype.clearDeployment = function() {
  return this.setDeployment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.GetCirrusProtectDeploymentDetails.Response.prototype.hasDeployment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListGalaxyMigrateDeployments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListGalaxyMigrateDeployments;
  return proto.galaxycomplete.api.ListGalaxyMigrateDeployments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListGalaxyMigrateDeployments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageParams: (f = msg.getPageParams()) && galaxycompletepb_commonpb_datafilter_pb.PagerParams.toObject(includeInstance, f),
    onlyConnected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    excludeHelperNodes: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    bySystemIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    onlyCdcRelayEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request;
  return proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerParams;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerParams.deserializeBinaryFromReader);
      msg.setPageParams(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyConnected(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeHelperNodes(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addBySystemIds(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyCdcRelayEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerParams.serializeBinaryToWriter
    );
  }
  f = message.getOnlyConnected();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getExcludeHelperNodes();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getBySystemIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getOnlyCdcRelayEnabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.common.PagerParams page_params = 2;
 * @return {?proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.getPageParams = function() {
  return /** @type{?proto.galaxycomplete.common.PagerParams} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerParams, 2));
};


/**
 * @param {?proto.galaxycomplete.common.PagerParams|undefined} value
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request} returns this
*/
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.setPageParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.clearPageParams = function() {
  return this.setPageParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.hasPageParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool only_connected = 3;
 * @return {boolean}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.getOnlyConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.setOnlyConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool exclude_helper_nodes = 4;
 * @return {boolean}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.getExcludeHelperNodes = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.setExcludeHelperNodes = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated string by_system_ids = 5;
 * @return {!Array<string>}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.getBySystemIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.setBySystemIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.addBySystemIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.clearBySystemIdsList = function() {
  return this.setBySystemIdsList([]);
};


/**
 * optional bool only_cdc_relay_enabled = 6;
 * @return {boolean}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.getOnlyCdcRelayEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Request.prototype.setOnlyCdcRelayEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerMeta: (f = msg.getPagerMeta()) && galaxycompletepb_commonpb_datafilter_pb.PagerMeta.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response;
  return proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerMeta;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerMeta.deserializeBinaryFromReader);
      msg.setPagerMeta(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerMeta.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.common.PagerMeta pager_meta = 1;
 * @return {?proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.prototype.getPagerMeta = function() {
  return /** @type{?proto.galaxycomplete.common.PagerMeta} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerMeta, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerMeta|undefined} value
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response} returns this
*/
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.prototype.setPagerMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response} returns this
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.prototype.clearPagerMeta = function() {
  return this.setPagerMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.prototype.hasPagerMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated galaxycomplete.domain.GalaxyMigrateDeploymentInfo items = 2;
 * @return {!Array<!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo>}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentInfo, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo>} value
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response} returns this
*/
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo}
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response} returns this
 */
proto.galaxycomplete.api.ListGalaxyMigrateDeployments.Response.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails}
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails;
  return proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails}
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request}
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request;
  return proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request}
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request} returns this
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    deployment: (f = msg.getDeployment()) && galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response}
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response;
  return proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response}
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentDetails;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentDetails.deserializeBinaryFromReader);
      msg.setDeployment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeployment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.GalaxyMigrateDeploymentDetails deployment = 2;
 * @return {?proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails}
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response.prototype.getDeployment = function() {
  return /** @type{?proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentDetails, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails|undefined} value
 * @return {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response} returns this
*/
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response.prototype.setDeployment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response} returns this
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response.prototype.clearDeployment = function() {
  return this.setDeployment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.GetGalaxyMigrateDeploymentDetails.Response.prototype.hasDeployment = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListPhoenixDeployments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListPhoenixDeployments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListPhoenixDeployments.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListPhoenixDeployments}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListPhoenixDeployments;
  return proto.galaxycomplete.api.ListPhoenixDeployments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListPhoenixDeployments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListPhoenixDeployments}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListPhoenixDeployments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListPhoenixDeployments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListPhoenixDeployments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListPhoenixDeployments.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListPhoenixDeployments.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageParams: (f = msg.getPageParams()) && galaxycompletepb_commonpb_datafilter_pb.PagerParams.toObject(includeInstance, f),
    onlyConnected: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListPhoenixDeployments.Request}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListPhoenixDeployments.Request;
  return proto.galaxycomplete.api.ListPhoenixDeployments.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListPhoenixDeployments.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListPhoenixDeployments.Request}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerParams;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerParams.deserializeBinaryFromReader);
      msg.setPageParams(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnlyConnected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListPhoenixDeployments.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListPhoenixDeployments.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerParams.serializeBinaryToWriter
    );
  }
  f = message.getOnlyConnected();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.ListPhoenixDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.common.PagerParams page_params = 2;
 * @return {?proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Request.prototype.getPageParams = function() {
  return /** @type{?proto.galaxycomplete.common.PagerParams} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerParams, 2));
};


/**
 * @param {?proto.galaxycomplete.common.PagerParams|undefined} value
 * @return {!proto.galaxycomplete.api.ListPhoenixDeployments.Request} returns this
*/
proto.galaxycomplete.api.ListPhoenixDeployments.Request.prototype.setPageParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListPhoenixDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Request.prototype.clearPageParams = function() {
  return this.setPageParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Request.prototype.hasPageParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool only_connected = 3;
 * @return {boolean}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Request.prototype.getOnlyConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.ListPhoenixDeployments.Request} returns this
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Request.prototype.setOnlyConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListPhoenixDeployments.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListPhoenixDeployments.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerMeta: (f = msg.getPagerMeta()) && galaxycompletepb_commonpb_datafilter_pb.PagerMeta.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    galaxycompletepb_apipb_domainpb_phoenix_pb.PhoenixDeploymentInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListPhoenixDeployments.Response}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListPhoenixDeployments.Response;
  return proto.galaxycomplete.api.ListPhoenixDeployments.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListPhoenixDeployments.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListPhoenixDeployments.Response}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerMeta;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerMeta.deserializeBinaryFromReader);
      msg.setPagerMeta(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_phoenix_pb.PhoenixDeploymentInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_phoenix_pb.PhoenixDeploymentInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListPhoenixDeployments.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListPhoenixDeployments.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerMeta.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_phoenix_pb.PhoenixDeploymentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.common.PagerMeta pager_meta = 1;
 * @return {?proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Response.prototype.getPagerMeta = function() {
  return /** @type{?proto.galaxycomplete.common.PagerMeta} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerMeta, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerMeta|undefined} value
 * @return {!proto.galaxycomplete.api.ListPhoenixDeployments.Response} returns this
*/
proto.galaxycomplete.api.ListPhoenixDeployments.Response.prototype.setPagerMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListPhoenixDeployments.Response} returns this
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Response.prototype.clearPagerMeta = function() {
  return this.setPagerMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Response.prototype.hasPagerMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated galaxycomplete.domain.PhoenixDeploymentInfo items = 2;
 * @return {!Array<!proto.galaxycomplete.domain.PhoenixDeploymentInfo>}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Response.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.PhoenixDeploymentInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_phoenix_pb.PhoenixDeploymentInfo, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.PhoenixDeploymentInfo>} value
 * @return {!proto.galaxycomplete.api.ListPhoenixDeployments.Response} returns this
*/
proto.galaxycomplete.api.ListPhoenixDeployments.Response.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.PhoenixDeploymentInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo}
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Response.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.PhoenixDeploymentInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.ListPhoenixDeployments.Response} returns this
 */
proto.galaxycomplete.api.ListPhoenixDeployments.Response.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetPhoenixDeploymentDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails}
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetPhoenixDeploymentDetails;
  return proto.galaxycomplete.api.GetPhoenixDeploymentDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails}
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetPhoenixDeploymentDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request}
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request;
  return proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request}
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request} returns this
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    deployment: (f = msg.getDeployment()) && galaxycompletepb_apipb_domainpb_phoenix_pb.PhoenixDeploymentDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response}
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response;
  return proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response}
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_phoenix_pb.PhoenixDeploymentDetails;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_phoenix_pb.PhoenixDeploymentDetails.deserializeBinaryFromReader);
      msg.setDeployment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeployment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_phoenix_pb.PhoenixDeploymentDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.PhoenixDeploymentDetails deployment = 1;
 * @return {?proto.galaxycomplete.domain.PhoenixDeploymentDetails}
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response.prototype.getDeployment = function() {
  return /** @type{?proto.galaxycomplete.domain.PhoenixDeploymentDetails} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_phoenix_pb.PhoenixDeploymentDetails, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.PhoenixDeploymentDetails|undefined} value
 * @return {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response} returns this
*/
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response.prototype.setDeployment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response} returns this
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response.prototype.clearDeployment = function() {
  return this.setDeployment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.GetPhoenixDeploymentDetails.Response.prototype.hasDeployment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.PingDeployment.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.PingDeployment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.PingDeployment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.PingDeployment.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.PingDeployment}
 */
proto.galaxycomplete.api.PingDeployment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.PingDeployment;
  return proto.galaxycomplete.api.PingDeployment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.PingDeployment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.PingDeployment}
 */
proto.galaxycomplete.api.PingDeployment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.PingDeployment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.PingDeployment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.PingDeployment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.PingDeployment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.PingDeployment.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.PingDeployment.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.PingDeployment.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.PingDeployment.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.PingDeployment.Request}
 */
proto.galaxycomplete.api.PingDeployment.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.PingDeployment.Request;
  return proto.galaxycomplete.api.PingDeployment.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.PingDeployment.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.PingDeployment.Request}
 */
proto.galaxycomplete.api.PingDeployment.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.PingDeployment.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.PingDeployment.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.PingDeployment.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.PingDeployment.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.PingDeployment.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.PingDeployment.Request} returns this
 */
proto.galaxycomplete.api.PingDeployment.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.PingDeployment.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.PingDeployment.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.PingDeployment.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.PingDeployment.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.PingDeployment.Response}
 */
proto.galaxycomplete.api.PingDeployment.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.PingDeployment.Response;
  return proto.galaxycomplete.api.PingDeployment.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.PingDeployment.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.PingDeployment.Response}
 */
proto.galaxycomplete.api.PingDeployment.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.PingDeployment.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.PingDeployment.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.PingDeployment.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.PingDeployment.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.RemoveDeployment.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.RemoveDeployment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.RemoveDeployment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveDeployment.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.RemoveDeployment}
 */
proto.galaxycomplete.api.RemoveDeployment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.RemoveDeployment;
  return proto.galaxycomplete.api.RemoveDeployment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.RemoveDeployment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.RemoveDeployment}
 */
proto.galaxycomplete.api.RemoveDeployment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.RemoveDeployment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.RemoveDeployment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.RemoveDeployment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveDeployment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.RemoveDeployment.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.RemoveDeployment.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.RemoveDeployment.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveDeployment.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.RemoveDeployment.Request}
 */
proto.galaxycomplete.api.RemoveDeployment.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.RemoveDeployment.Request;
  return proto.galaxycomplete.api.RemoveDeployment.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.RemoveDeployment.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.RemoveDeployment.Request}
 */
proto.galaxycomplete.api.RemoveDeployment.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.RemoveDeployment.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.RemoveDeployment.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.RemoveDeployment.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveDeployment.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.RemoveDeployment.Request.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.RemoveDeployment.Request} returns this
 */
proto.galaxycomplete.api.RemoveDeployment.Request.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.RemoveDeployment.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.RemoveDeployment.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.RemoveDeployment.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveDeployment.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.RemoveDeployment.Response}
 */
proto.galaxycomplete.api.RemoveDeployment.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.RemoveDeployment.Response;
  return proto.galaxycomplete.api.RemoveDeployment.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.RemoveDeployment.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.RemoveDeployment.Response}
 */
proto.galaxycomplete.api.RemoveDeployment.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.RemoveDeployment.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.RemoveDeployment.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.RemoveDeployment.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveDeployment.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.RemoveOfflineDeployments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.RemoveOfflineDeployments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.RemoveOfflineDeployments}
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.RemoveOfflineDeployments;
  return proto.galaxycomplete.api.RemoveOfflineDeployments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.RemoveOfflineDeployments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.RemoveOfflineDeployments}
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.RemoveOfflineDeployments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.RemoveOfflineDeployments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Request.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.RemoveOfflineDeployments.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.RemoveOfflineDeployments.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productTypesList: jspb.Message.toObjectList(msg.getProductTypesList(),
    galaxycompletepb_apipb_domainpb_enumpb_product_type_pb.ProductType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.RemoveOfflineDeployments.Request}
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.RemoveOfflineDeployments.Request;
  return proto.galaxycomplete.api.RemoveOfflineDeployments.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.RemoveOfflineDeployments.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.RemoveOfflineDeployments.Request}
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_enumpb_product_type_pb.ProductType;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_enumpb_product_type_pb.ProductType.deserializeBinaryFromReader);
      msg.addProductTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.RemoveOfflineDeployments.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.RemoveOfflineDeployments.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_enumpb_product_type_pb.ProductType.serializeBinaryToWriter
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.RemoveOfflineDeployments.Request} returns this
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated galaxycomplete.domain.enums.ProductType product_types = 2;
 * @return {!Array<!proto.galaxycomplete.domain.enums.ProductType>}
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Request.prototype.getProductTypesList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.enums.ProductType>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_enumpb_product_type_pb.ProductType, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.enums.ProductType>} value
 * @return {!proto.galaxycomplete.api.RemoveOfflineDeployments.Request} returns this
*/
proto.galaxycomplete.api.RemoveOfflineDeployments.Request.prototype.setProductTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.enums.ProductType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.enums.ProductType}
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Request.prototype.addProductTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.enums.ProductType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.RemoveOfflineDeployments.Request} returns this
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Request.prototype.clearProductTypesList = function() {
  return this.setProductTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.RemoveOfflineDeployments.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.RemoveOfflineDeployments.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.RemoveOfflineDeployments.Response}
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.RemoveOfflineDeployments.Response;
  return proto.galaxycomplete.api.RemoveOfflineDeployments.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.RemoveOfflineDeployments.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.RemoveOfflineDeployments.Response}
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.RemoveOfflineDeployments.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.RemoveOfflineDeployments.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveOfflineDeployments.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.galaxycomplete.api);
