import {red, yellow, green, blue, orange, purple, pink, teal} from "@mui/material/colors";
import {BlockDeviceInfo, IOStatsDataSet} from "../../../_proto/galaxymigratepb/galaxy_migrate_storage_pb";
import {GalaxyMigrateStorageConfig} from "../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import {Timestamp} from "google-protobuf/google/protobuf/timestamp_pb";
import {convertTimestampObjectToDate, formatKnownDataType, KnownDataType} from "../../../common/utils/formatter";
import {formatMicrosecond} from "../../../common/utils/statsUtil";

export enum IO_TYPE {
    ALL_IO,
    READ,
    WRITE
}

export const graphColorOptions = [
    red[300],
    orange[300],
    yellow[300],
    green[300],
    blue[300],
    purple[300],
    pink[300],
    teal[300]
];

export const generateChartValuesMap = (devicePathList: Array<string[]>, volList: Array<GalaxyMigrateStorageConfig.Device>) => {
    const map = new Map<string, { label: string, color: string }>();

    if (devicePathList.flat().length === 0){
        map.set('allVolumes', {
            label: 'All Volumes',
            color: pink[500]
        })
    }

    else{
        devicePathList.forEach((devicePath, index) => {
            if (devicePath.length > 1){
                map.set('aggregateValue', {
                    label: 'Aggregate Value',
                    color: pink[500]
                })
            }
            else {
                const vol = devicePath[0];
                const key = volList?.find(v=>v.getBlockDevice().getDeviceName() === vol)?.getBlockDevice().getDeviceName()
                console.log(key)
                map.set(key,
                    {
                        label: key,
                        color: graphColorOptions[index]
                    })
            }

        })
    }


    return map;
}

export const generateChartData = (statType: keyof IOStatsDataSet.Stats.AsObject, seriesList: Array<IOStatsDataSet.Series.AsObject>, volList: Array<GalaxyMigrateStorageConfig.Device>) => {
    const chartData: Array<{ [key: string]: any }> = [];
    for (let i = 0; i < seriesList[0].pointsList.length; i++) {
        const dataPoint: { [key: string]: any } = {
            time: convertTimestampObjectToDate(seriesList[0].pointsList[i].time),
        }
        seriesList.forEach(series => {
            if (series.domain.devicePathsList.length > 1){
                dataPoint['aggregateValue'] = series.pointsList[i].stats[statType];
            }
            else if (series.domain.devicePathsList.length === 0){
                dataPoint['allVolumes'] = series.pointsList[i].stats[statType];
            }
            else{
                const key = volList?.find(v=>v.getBlockDevice().getDeviceName() === series.domain.devicePathsList[0])?.getBlockDevice().getDeviceName()

                dataPoint[key] = series.pointsList[i].stats[statType];
            }
        })
        chartData.push(dataPoint)
    }
    return chartData;
}

export const generateDomainList = (volList: Array<GalaxyMigrateStorageConfig.Device>, aggregate: boolean) => {
    const domainList: Array<string[]> = [];

    if (aggregate){
        domainList.push(volList.map(vol=>vol.getBlockDevice().getDeviceName()))
    }
    else {
        volList.forEach(vol => {
            domainList.push([vol.getBlockDevice().getDeviceName()])
        })
    }

    return domainList;
}

interface PerformanceChartConfig {
    title: string,
    statType: keyof IOStatsDataSet.Stats.AsObject,
    yAxisFormatter: (value: number)=>string,

}

export const getPerformanceChartConfigs = (): PerformanceChartConfig[]=> {
    return [
        {
            title: 'IOPS',
            statType: 'iops',
            yAxisFormatter: (value: number) => `${value} IOPS`
        },
        {
            title: 'Throughput',
            statType: 'throughput',
            yAxisFormatter: (value: number) => formatKnownDataType(value, KnownDataType.THROUGHPUT)
        },
        {
            title: 'Latency',
            statType: 'avgLatency',
            yAxisFormatter: (value: number) => formatMicrosecond(value)
        },
        {
            title: 'Queue Depth',
            statType: 'pending',
            yAxisFormatter: (value: number) => `${value}`
        },
        {
            title: 'IO Size',
            statType: 'avgIoSize',
            yAxisFormatter: (value: number) => formatKnownDataType(value, KnownDataType.CAPACITY)
        },
    ]
}

export const getKnownDataTypeOfStatType = (statType: keyof IOStatsDataSet.Stats.AsObject) => {
    if (statType === 'throughput' || statType === 'avgIoSize'){
        return KnownDataType.THROUGHPUT
    }
    else if (statType === 'iops' || statType === 'pending'){
        return KnownDataType.NUMBER
    }
 else if (statType === 'avgLatency'){
     return KnownDataType.DURATION_MICROSECONDS
    }

}
