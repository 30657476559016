import {observer} from "mobx-react-lite";
import {DialogState, useDialogState} from "../../core/dialog/DialogService";
import {useAppServices} from "../../app/services";
import {
    Box,
    Button,
    Card,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    SvgIcon,
    Switch,
    Tooltip,
    Typography
} from "@mui/material";
import {EditIcon} from "../../../common/CommonIcons";
import {DialogTopBar} from "../../core/dialog/DialogComponents";
import React, {useState} from "react";
import {formatKnownDataType, KnownDataType} from "../../../common/utils/formatter";
import {getInsertionCandidateDeviceType} from "./CdmPolicyCommon";
import {Theme} from "@mui/material/styles";
import {renderServerDataWithLoadingList, useInitData} from "../../core/data/DataLoaderHooks";
import {MdAdd} from "react-icons/md";
import {renderChipInfo} from "../ProjectProtectionCommon";
import {CdmPolicySyncStatus} from "../../../_proto/galaxycompletepb/apipb/domainpb/enumpb/cdm_policy_sync_status_pb";


// ======================
// RemoveVolumesDialogButton
// ======================


interface RemoveVolumesDialogButtonProps {
}

export const RemoveVolumesDialogButton: React.FC<RemoveVolumesDialogButtonProps> = observer((p) => {
    const removeVolumesDialogState = useDialogState();

    return <>
        <Tooltip title={'Remove Policy Volumes'}>
            <IconButton onClick={removeVolumesDialogState.open}>
                <EditIcon/>
            </IconButton>
        </Tooltip>
        <RemoveVolumesDialog dialogState={removeVolumesDialogState}/>
    </>
})

// ======================
// RemoveVolumesDialog
// ======================

interface RemoveVolumesDialogProps {
    dialogState: DialogState
}

export const RemoveVolumesDialog: React.FC<RemoveVolumesDialogProps> = observer((p) => {
    const {cpDeploymentService, cdmPolicyService, dialogService} = useAppServices();
    const [selectedVolumes, setSelectedVolumes] = useState([]);

    const addVolumeToSelection = (pathName: string) => {
        setSelectedVolumes(selectedVolumes.concat([pathName]))
    }

    const removeVolumeFromSelection = (pathName: string) => {
        setSelectedVolumes(selectedVolumes.filter(v => v !== pathName))
    }

    const onConfirmSelection = async () => {
        const confirmed = await dialogService.addConfirmDialog({
            message: 'Are you sure you want to remove the selected volumes from the policy?',
            autoConfirmationQuestionLine: false
        })
        if (confirmed) {
            const removed = await cpDeploymentService.removeDisksFromPolicy(selectedVolumes);
            if (removed) {
                await cpDeploymentService.cpDeploymentPolicyDetails.fetchData();
                setSelectedVolumes([])
                p.dialogState.close()
            }
        }
    }

    return <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} fullWidth maxWidth={'md'}>
        <DialogTopBar dialogState={p.dialogState} title={'Remove Volumes From Policy'} divider/>

        <DialogContent>
            <Typography variant={'h5'}>Select to Remove</Typography>
            <Typography variant={'body1'}>Select volumes to be removed from this protection policy</Typography>
            <br/>
            { cpDeploymentService.cpDeploymentPolicyDetails.data?.getPolicyDisksList().length === 0 &&
                <Card sx={{backgroundColor: (t: Theme)=> t.palette.cirrus.main}}>
                    <Box p={2} display={'flex'} justifyContent={'center'}>
                        <Typography variant={'h6'}>
                            No Volumes Found
                        </Typography>
                    </Box>
                </Card>

            }
            {
                cpDeploymentService.cpDeploymentPolicyDetails.data?.getPolicyDisksList().map(v => {
                    const selected = selectedVolumes.includes(v.getMtdiDevicePath())
                    const onSelect = () => {
                        if (selected) {
                            removeVolumeFromSelection(v.getMtdiDevicePath())
                        } else {
                            addVolumeToSelection(v.getMtdiDevicePath())
                        }
                    }
                    return <Card key={v.getMtdiDevicePath()} variant={'outlined'} sx={{
                        backgroundColor: 'rgba(0,0,0,0)',
                        border: '2px solid',
                        borderColor: (t: Theme) => selected ? t.palette.secondary.main : '#40424F',
                        marginBottom: 2
                    }}>
                        <Box display={'flex'} alignItems={'center'} p={2}>
                            <Grid container alignItems={'center'}>
                                <Grid item xs={1}>
                                    <Checkbox color={'secondary'} checked={selected} onChange={onSelect} disabled={v.getSyncStatus() === CdmPolicySyncStatus.CdmPolicySyncStatus.SYNCING || v.getSyncStatus() === CdmPolicySyncStatus.CdmPolicySyncStatus.MIRRORING || v.getSyncStatus() === CdmPolicySyncStatus.CdmPolicySyncStatus.RESTORING }/>
                                    {/*<Switch color={'secondary'}
                                        checked={selected}
                                        onChange={onSelect}/>*/}
                                </Grid>
                                <Grid item xs={11}>
                                    <Box display={'flex'} justifyContent={'flex-start'}>
                                        <Box textAlign={'left'}>
                                            <Box pb={1}>
                                                <Typography variant={'body1'} sx={{overflowWrap: 'break-word'}}>
                                                    {v.getSourceDiskName() || v.getPolicyDiskName()}
                                                </Typography>
                                                <Typography variant={'caption'}>
                                                    {formatKnownDataType(v.getSourceDiskCapacity(), KnownDataType.CAPACITY)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                })
            }
        </DialogContent>
        <DialogActions sx={{padding: 2}}>
            <Box>
                <Button color={'neutral'} variant={'outlined'} onClick={p.dialogState.close}>Cancel</Button>
            </Box>
            <Box>
                <Button color={'primary'} variant={'contained'} onClick={onConfirmSelection}>Confirm</Button>
            </Box>
        </DialogActions>
    </Dialog>
});

// ======================
// AddVolumesDialogButton
// ======================

interface AddVolumesDialogButtonProps {
}

export const AddVolumesDialogButton: React.FC<AddVolumesDialogButtonProps> = observer((p) => {
    const addVolumesDialogState = useDialogState();

    return <>
        <Tooltip title={'Add Policy Volumes'}>
            <IconButton onClick={addVolumesDialogState.open}>
                <SvgIcon>
                    <MdAdd/>
                </SvgIcon>
            </IconButton>
        </Tooltip>
        {
            addVolumesDialogState.isOpen &&
            <AddVolumesDialog dialogState={addVolumesDialogState}/>
        }
    </>
})

// ======================
// AddVolumesDialog
// ======================

interface AddVolumesDialogProps {
    dialogState: DialogState
}

export const AddVolumesDialog: React.FC<AddVolumesDialogProps> = observer((p) => {
    const {cpDeploymentService, cdmPolicyService, dialogService} = useAppServices();
    const [selectedVolumes, setSelectedVolumes] = useState([]);

    useInitData({
        poll: () => cpDeploymentService.insertionCandidates.fetchData(),
        pollInterval: 30
    })

    const addVolumeToSelection = (pathName: string) => {
        setSelectedVolumes(selectedVolumes.concat([pathName]))
    }

    const removeVolumeFromSelection = (pathName: string) => {
        setSelectedVolumes(selectedVolumes.filter(v => v !== pathName))
    }

    const onConfirmSelection = async () => {

        const added = await cpDeploymentService.addDisksToPolicy(selectedVolumes);
        if (added) {
            await cpDeploymentService.cpDeploymentPolicyDetails.fetchData();
            setSelectedVolumes([]);
            p.dialogState.close();
        }
    }

    return <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} fullWidth maxWidth={'md'}>
        <DialogTopBar dialogState={p.dialogState} title={'Add Volumes To Policy'} divider/>

        <DialogContent>
            <Typography variant={'h5'}>Select to Add</Typography>
            <Typography variant={'body1'}>Select volumes to be added to this protection policy</Typography>
            <br/>
            {renderServerDataWithLoadingList(cpDeploymentService.insertionCandidates, data => {
                return <>
                    {data.getDevicesList().map(device => {
                        const selected = selectedVolumes.includes(device.getDevicePath())
                        const onSelect = () => {
                            if (selected) {
                                removeVolumeFromSelection(device.getDevicePath())
                            } else {
                                addVolumeToSelection(device.getDevicePath())
                            }
                        }
                        return <Card key={device.getDevicePath()} variant={'outlined'} sx={{
                            backgroundColor: 'rgba(0,0,0,0)',
                            border: '2px solid',
                            borderColor: (t: Theme) => selected ? t.palette.primary.main : '#40424F',
                            marginBottom: 2
                        }}>
                            <Box display={'flex'} alignItems={'center'} p={2}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={1}>
                                        <Switch color={'secondary'}
                                                checked={selected}
                                                onChange={onSelect}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box display={'flex'} justifyContent={'flex-start'}>
                                            <Box textAlign={'left'}>
                                                <Box pb={1}>
                                                    <Typography variant={'body1'}>
                                                        {device.getDevice()} ({formatKnownDataType(device.getCapacity(), KnownDataType.CAPACITY)})
                                                    </Typography>
                                                </Box>
                                                <Grid container spacing={1}>
                                                    {renderChipInfo(getInsertionCandidateDeviceType(device))}
                                                    {renderChipInfo(device.getFsType())}
                                                    {renderChipInfo(device.getLabel())}
                                                    {renderChipInfo(device.getMountPoint())}
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                        </Card>
                    })}
                </>
            })}
        </DialogContent>
        <DialogActions sx={{padding: 2}}>
            <Box>
                <Button color={'neutral'} variant={'outlined'} onClick={p.dialogState.close}>Cancel</Button>
            </Box>
            <Box>
                <Button color={'primary'} variant={'contained'} onClick={onConfirmSelection}>Confirm</Button>
            </Box>
        </DialogActions>
    </Dialog>
})