import React, { ChangeEvent, FormEvent, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import { useAppServices } from "../app/services";
import { Button, Card, Dialog, DialogActions, DialogContent, Grid, Link, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { FormCheckboxItem, FormTextField } from "../../common/form/FormComponents";
import { RefreshButton } from "../../common/CommonIcons";
import { useEffectOnce } from "react-use";
import { MdArrowBack } from "react-icons/md";
import { DialogTopBar } from "../core/dialog/DialogComponents";

import { DialogState, useDialogState } from "../core/dialog/DialogService";
import { useLogIn, useLoginStyles, useNavigateToSignUpScreen } from "./AuthCommon";
import { MfaAuthMethod } from "../../_proto/galaxycompletepb/apipb/domainpb/enumpb/mfa_auth_method_pb";
import { Alert } from "@mui/material";
import { CirrusGradient } from "../app/AppTheme";
import { UnauthenticatedViewFooter } from "../layout/Footer";
import { useQueryParams } from "../app/AppRoutes";
import { BackButton } from "../../common/CommonButtons";
import { getIsPrivateEdition, HideInPrivateEdition } from "./PrivateEditionView";
import { CDSLogo, CDSPrivateEditionLogo } from "../layout/LayoutCommon";
import { useNewLogIn, useRequestMfaToken, useRequestResetPassword } from "./auth_hooks";
import { useTrackAndAlertError } from "../core/data/useTrackAndAlertError";
import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { ForgotPassword, Login, RequestMfaToken } from "../../_proto/galaxycompletepb/apipb/auth_api_pb";

export const SAVED_USERNAME_KEY = "data_galaxy_username";

// ======================
// LoginView
// ======================

const LOGIN_CODE_LENGTH = 6;
const TOTP_RECOVERY_CODE_LENGTH = 11;

interface LoginViewProps {
    signUpRedirectFunction?: () => void;
    awsToken?: string;
    azureToken?: string;
    navigateBackFunction?: () => void;
    prefilledEmail?: string;
}

export const LoginView: React.FC<LoginViewProps> = observer((props) => {
    const isPrivateEdition = getIsPrivateEdition();
    const query = useQueryParams();
    const promo = query.get("promo");
    const fromApp = query.get("from-app");
    const getCdsLogo = () => {
        if (isPrivateEdition) {
            return <CDSPrivateEditionLogo imageAttributes={{ width: "100%" }} />;
        } else {
            return <CDSLogo imageAttributes={{ width: "100%" }} size={"large"} />;
        }
    };
    return (
        <Box
            sx={{
                background: CirrusGradient,
                height: "100vh",
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}
        >
            <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                direction={"row"}
                sx={{
                    height: "100%",
                }}
            >
                <Grid item xs={12} md={6} xl={4} pl={5} pr={5}>
                    <Box display={"flex"} justifyContent={"center"} width={"100%"}>
                        <Box maxWidth={500}>
                            {!!props.navigateBackFunction && (
                                <Box>
                                    <BackButton navFunction={props.navigateBackFunction} label={"Back"} />
                                </Box>
                            )}
                            <Box pt={3} pr={3} pl={3} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <Box width={"100%"} maxWidth={"330px"}>
                                    {getCdsLogo()}
                                </Box>
                            </Box>
                            {fromApp === "customer-portal" && (
                                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                    <Typography variant={"h5"} textAlign={"center"}>
                                        Customer Center
                                    </Typography>
                                    <Box pt={2} width={"80%"} display={"flex"} justifyContent={"center"}>
                                        <Typography textAlign={"center"} variant={"body2"}>
                                            Log in to Cirrus Data Cloud to continue.
                                        </Typography>
                                    </Box>

                                    {/*<Box pl={3} pr={3} pt={3}>*/}
                                    {/*    <Alert severity={"info"}>Sign in Via Cirrus Data Cloud to access the Customer Center. If you don't have a Cirrus Data Cloud account, please sign up using the link below.</Alert>*/}
                                    {/*</Box>*/}
                                </Box>
                            )}
                            {!!props.awsToken && (
                                <Box pl={3} pr={3} pt={3}>
                                    <Alert severity={"info"}>Once connected all usage will be billed through AWS Marketplace.</Alert>
                                </Box>
                            )}
                            {!!props.azureToken && (
                                <Box pl={3} pr={3} pt={3}>
                                    <Alert severity={"warning"}>By continuing, your account will be connected to your Azure Marketplace Subscription.</Alert>
                                </Box>
                            )}
                            <LoginForm
                                signUpRedirectFunction={props.signUpRedirectFunction}
                                awsToken={props.awsToken}
                                azureToken={props.azureToken}
                                prefilledEmail={props.prefilledEmail}
                            />
                        </Box>
                    </Box>
                </Grid>
                {!!promo && (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        xl={8}
                        sx={{
                            height: `100%`,
                            width: "100%",
                        }}
                    >
                        <iframe title={"Promotion"} src={`https://action.cirrusdata.com/${promo}`} height={"100%"} width={"100%"} style={{ border: "none" }} />
                    </Grid>
                )}
            </Grid>

            <UnauthenticatedViewFooter />
        </Box>
    );
});

// ======================
// LoginForm
// ======================

interface LoginFormProps {
    signUpRedirectFunction?: () => void;
    awsToken?: string;
    azureToken?: string;
    prefilledEmail?: string;
}

export const LoginForm: React.FC<LoginFormProps> = observer((props) => {
    const goToSignUpScreen = useNavigateToSignUpScreen(window.location.search);
    const forgotPasswordDialogState = useDialogState();
    const [username, setUsername] = useState(props.prefilledEmail || "");
    const [password, setPassword] = useState("");
    const [mfaMethodRequested, setMfaMethodRequested] = useState(false);
    const [mfaMethod, setMfaMethod] = useState(null);
    const loginService = useGrpcApiStore((s) => s.loginService);
    const requestMfaToken = useTrackAndAlertError(async (req: RequestMfaToken.Request) => {
        const res = await loginService.requestMfaToken(req, null);
        setMfaMethod(res.getMethod());
        return res;
    });

    return (
        <>
            {!username && (
                <EnterEmailStep
                    usernameState={{
                        username: username,
                        setUsername: setUsername,
                    }}
                />
            )}
            {!!username && !mfaMethodRequested && (
                <EnterPasswordStep
                    username={username}
                    passwordState={{
                        password: password,
                        setPassword: setPassword,
                    }}
                    setMfaMethodRequested={() => setMfaMethodRequested(true)}
                    forgotPasswordDialogState={forgotPasswordDialogState}
                    resetUsernameValue={() => {
                        setUsername(null);
                    }}
                    prefilledEmail={!!props.prefilledEmail}
                    requestMfaToken={requestMfaToken}
                />
            )}
            {!!mfaMethodRequested && (
                <MFAStep
                    username={username}
                    password={password}
                    awsToken={props.awsToken}
                    mfaMethod={mfaMethod}
                    requestMfaToken={requestMfaToken}
                    azureToken={props.azureToken}
                />
            )}
            {!username && (
                <HideInPrivateEdition>
                    <Box display={"flex"} justifyContent={"center"}>
                        <Typography variant={"body2"}>
                            Don't have an account?&nbsp;
                            <Link onClick={props.signUpRedirectFunction || goToSignUpScreen}>Sign Up</Link>
                        </Typography>
                    </Box>
                </HideInPrivateEdition>
            )}
            <ForgotPasswordDialog dialogState={forgotPasswordDialogState} username={username} />
        </>
    );
});

// ======================
// EnterEmailStep
// ======================

interface EnterEmailStepProps {
    usernameState: { username: string; setUsername: (username: string) => void };
}

export const EnterEmailStep: React.FC<EnterEmailStepProps> = observer((props) => {
    const { username, setUsername } = props.usernameState;
    const savedUsername = window.localStorage.getItem(SAVED_USERNAME_KEY);
    const [hasSavedUsername, setHasSavedUsername] = useState(!!savedUsername);
    const styles = useLoginStyles();

    useEffectOnce(() => {
        return () => setHasSavedUsername(false);
    });

    const initialValues = {
        username: savedUsername || username,
        rememberEmail: true,
    };

    const schema = yup.object({
        username: yup.string().required("Enter username.").label("Username"),
        rememberEmail: yup.boolean().notRequired(),
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={async (values, actions) => {
                const username = values.username.toLowerCase();
                setUsername(username);
                if (values.rememberEmail === true) {
                    window.localStorage.setItem(SAVED_USERNAME_KEY, username);
                    setHasSavedUsername(true);
                }
            }}
        >
            {(props) => {
                const removeSavedEmail = () => {
                    props.setFieldValue("username", "");
                    window.localStorage.removeItem(SAVED_USERNAME_KEY);
                    setHasSavedUsername(false);
                };
                return (
                    <Form>
                        <DialogContent>
                            {!hasSavedUsername && (
                                <>
                                    <Box pb={1}>
                                        <FormTextField name={"username"} label={"Enter your Email"} variant={"outlined"} />
                                    </Box>
                                    <Box pb={2}>
                                        <FormCheckboxItem
                                            label={"Remember this email"}
                                            name={"rememberEmail"}
                                            checkBoxProps={{ size: "small", color: "default" }}
                                            defaultChecked={true}
                                        />
                                    </Box>
                                </>
                            )}
                            {hasSavedUsername && (
                                <>
                                    <Box pb={1}>
                                        <Card variant={"outlined"} sx={styles.savedEmail}>
                                            <Box p={2}>
                                                <Typography variant={"overline"}>Email</Typography>
                                                <Typography variant={"body1"} color={"textSecondary"}>
                                                    {savedUsername}
                                                </Typography>
                                            </Box>
                                        </Card>
                                    </Box>
                                    <Box pb={2} display={"flex"} justifyContent={"center"}>
                                        <Link onClick={removeSavedEmail}>
                                            <Typography variant={"body2"}>Log in with a different email</Typography>
                                        </Link>
                                    </Box>
                                </>
                            )}
                            <Box pt={2} pb={3}>
                                <Button type={"submit"} variant={"contained"} size={"large"} fullWidth color={"primary"}>
                                    {`next`}
                                </Button>
                            </Box>
                        </DialogContent>
                    </Form>
                );
            }}
        </Formik>
    );
});

interface EnterPasswordStepProps {
    username: string;
    resetUsernameValue: () => void;
    passwordState: { password: string; setPassword: (username: string) => void };
    setMfaMethodRequested: () => void;
    forgotPasswordDialogState: DialogState;
    requestMfaToken: (req: RequestMfaToken.Request) => Promise<RequestMfaToken.Response>;
    prefilledEmail?: boolean;
}

const EnterPasswordStep: React.FC<EnterPasswordStepProps> = observer((p) => {
    const { progressService, authService, dialogService } = useAppServices();
    const { password, setPassword } = p.passwordState;
    const styles = useLoginStyles();
    const isPrivateEdition = getIsPrivateEdition();
    //const logIn = useLogIn();
    const logIn = useNewLogIn();

    const requestCode = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const req = new RequestMfaToken.Request().setUser(p.username).setPassword(password);
        const res = await p.requestMfaToken(req);

        //await progressService.track(authService.loginMethod.fetchData(p.username, password));
        if (res.getMethod() === MfaAuthMethod.MfaAuthMethod.EMAIL && isPrivateEdition) {
            const req = new Login.Request().setUser(p.username).setPassword(password).setMfaToken("").setAwsMarketplacePairToken(null);
            await logIn.mutate(req);
        } else {
            p.setMfaMethodRequested();
        }
    };

    return (
        <DialogContent>
            <form onSubmit={requestCode}>
                <Box pb={2}>
                    {p.prefilledEmail ? (
                        <Box pb={1}>
                            <Card variant={"outlined"} sx={styles.savedEmail}>
                                <Box p={2}>
                                    <Typography variant={"overline"}>Email</Typography>
                                    <Typography variant={"body1"} color={"textSecondary"}>
                                        {p.username}
                                    </Typography>
                                </Box>
                            </Card>
                        </Box>
                    ) : (
                        <Button startIcon={<MdArrowBack />} onClick={p.resetUsernameValue}>
                            <Typography variant={"body1"} sx={styles.lowerCaseButton}>
                                {p.username}
                            </Typography>
                        </Button>
                    )}
                </Box>
                <Box sx={styles.hiddenInput}>
                    <label htmlFor={"email"}>Username</label>
                    <input value={p.username} id={"email"} type={"email"} autoComplete={"username"} name={"email"} readOnly />
                </Box>
                <Box pt={1} pb={1}>
                    <TextField
                        fullWidth
                        id={"current-password"}
                        label={"Password"}
                        value={password}
                        variant={"filled"}
                        size={"medium"}
                        type={"password"}
                        autoComplete={"current-password"}
                        onChange={async (e) => {
                            const pwd = e.target.value;
                            setPassword(pwd);
                        }}
                    />
                </Box>

                <Box display={"flex"} justifyContent={"center"} pt={1} pb={1}>
                    <Typography variant={"body2"}>
                        <Link color={"primary"} onClick={p.forgotPasswordDialogState.open}>
                            Forgot Password?
                        </Link>
                    </Typography>
                </Box>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} pt={4} pb={3}>
                    <Button fullWidth disabled={!password} variant={"contained"} color={"primary"} size={"large"} type={"submit"}>
                        Login
                    </Button>
                </Box>
            </form>
        </DialogContent>
    );
});

// ======================
// ForgotPasswordDialog
// ======================

interface ForgotPasswordDialogProps {
    dialogState: DialogState;
    username: string;
}

export const ForgotPasswordDialog: React.FC<ForgotPasswordDialogProps> = observer((p) => {
    const requestResetPassword = useRequestResetPassword();
    const sendResetPasswordEmail = async () => {
        const req = new ForgotPassword.Request().setUser(p.username);
        await requestResetPassword.mutate(req);
        p.dialogState.close();
    };

    return (
        <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} maxWidth={"sm"} fullWidth>
            <DialogTopBar dialogState={p.dialogState} title={"Set New Password"} />
            <DialogContent>
                <Typography>Receive instructions to set a new password via email.</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={p.dialogState.close}>No</Button>
                <Button onClick={sendResetPasswordEmail} color={"primary"}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
});

// ======================
// MFAStep
// ======================

interface MFAStepProps {
    username: string;
    password: string;
    awsToken?: string;
    azureToken?: string;
    mfaMethod: MfaAuthMethod.MfaAuthMethod;
    requestMfaToken: (req: RequestMfaToken.Request) => Promise<RequestMfaToken.Response>;
}

export const MFAStep: React.FC<MFAStepProps> = observer((p) => {
    const { progressService, authService, dialogService } = useAppServices();
    const [enteredCode, setEnteredCode] = useState(null);
    const { mfaMethod, username, password, awsToken, requestMfaToken, azureToken } = p;
    const logIn = useNewLogIn();

    const styles = useLoginStyles();

    const resendCode = async () => {
        await requestMfaToken(new RequestMfaToken.Request().setUser(username).setPassword(password));
        await dialogService.addAlertDialog({
            title: "Login Code Resent",
            message: "Please check your email for a new login code. Please note that all previous codes are now expired.",
        });
    };

    const renderInstructions = () => {
        if (mfaMethod === MfaAuthMethod.MfaAuthMethod.EMAIL) {
            return (
                <>
                    <Alert severity={"info"}>Authenticator App 2FA is also available. You can enable this under "My Profile" once you log in.</Alert>
                    <br />
                    <Typography sx={styles.login}>
                        {`We've sent a ${LOGIN_CODE_LENGTH}-digit login code to ${username}.`}
                        <br />
                        {`Please enter it below before it expires.`}
                    </Typography>
                </>
            );
        } else if (mfaMethod === MfaAuthMethod.MfaAuthMethod.TOTP) {
            return <Typography sx={styles.login}>{`Enter the ${LOGIN_CODE_LENGTH}-digit login code from your 2FA app or a recovery code.`}</Typography>;
        }
    };

    const onEnterEmailCode = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const code = e.target.value.toUpperCase();
        if (code.length === LOGIN_CODE_LENGTH) {
            const req = new Login.Request()
                .setUser(username)
                .setPassword(password)
                .setAwsMarketplacePairToken(awsToken)
                .setAzureMarketplacePairToken(azureToken)
                .setMfaToken(code);
            await logIn.mutate(req);
        }
    };

    const onEnterTOTPCode = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const code = e.target.value;

        const req = new Login.Request().setUser(username).setPassword(password).setAzureMarketplacePairToken(azureToken).setAwsMarketplacePairToken(awsToken);

        if (code.length === LOGIN_CODE_LENGTH && !isNaN(parseInt(code))) {
            req.setMfaToken(code);
            await logIn.mutate(req);
        }
        if (code.length === TOTP_RECOVERY_CODE_LENGTH) {
            req.setMfaToken(code);

            await logIn.mutate(req);
        }
    };

    const getLoginMethod = () => {
        if (mfaMethod === MfaAuthMethod.MfaAuthMethod.EMAIL) {
            return onEnterEmailCode;
        } else if (mfaMethod === MfaAuthMethod.MfaAuthMethod.TOTP) {
            return onEnterTOTPCode;
        }
        return null;
    };

    return (
        <DialogContent>
            <Box pt={1} pb={1}>
                {renderInstructions()}
                <br />
                <TextField
                    fullWidth
                    label={"Confirmation Code"}
                    value={enteredCode}
                    variant={"filled"}
                    size={"medium"}
                    InputLabelProps={{ id: "confirmationCode" }}
                    inputProps={{ "aria-labelledby": "confirmationCode" }}
                    onChange={getLoginMethod()}
                />
                {mfaMethod === MfaAuthMethod.MfaAuthMethod.EMAIL && (
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} pt={4}>
                        <RefreshButton onClick={resendCode} variant={"outlined"}>
                            Resend Code
                        </RefreshButton>
                    </Box>
                )}
            </Box>
        </DialogContent>
    );
});
