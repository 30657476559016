// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: ProjectReportsScreen

import * as React from 'react';
import {ScreenContainer, ScreenTitleBar} from '../layout/ScreenCommon';
import {observer} from 'mobx-react-lite';
import {ProjectDetails} from '../../_proto/galaxycompletepb/apipb/domainpb/project_pb';
import {useInitData} from "../core/data/DataLoaderHooks";
import {useAppServices} from "../app/services";
import {useCurrentProjectID} from "../project/CurrentProject";
import {ListTable} from "../../common/table/ListTable";
import {ReportInfo} from "../../_proto/galaxycompletepb/apipb/domainpb/report_pb";
import {Avatar, Box, Card, Chip, Divider, Link, Typography, useTheme} from "@mui/material";
import {ReportType} from "../../_proto/galaxycompletepb/apipb/domainpb/enumpb/report_type_pb";
import {getReportTypeLabel, viewReport} from "./ReportHelpers";
import {convertTimestampObjectToDate, formatKnownDataType, KnownDataType} from "../../common/utils/formatter";
import {getUserFullNameFromObject} from "../settings/ProjectUsers";
import {cirrusColors} from "../app/AppTheme";
import {ReportIcon} from "../../common/CommonIcons";
import {alpha} from "@mui/material/styles";
import {
    getGmSessionIDSlug,
    renderGMSessionStatus,
    useNavigateToMigrationSessionDetails
} from "../migration/MigrationCommon";
import {KeyValuePairSubtitle} from "../../common/text/CommonTypeFormats";
import { Route, Routes } from 'react-router-dom';
import {ViewReportScreen} from "./ViewReportScreen";
import {useNavigateToDeploymentDetails} from "../deployment/DeploymentCommon";

// ======================
// ProjectReportsScreens
// ======================


interface ProjectReportsScreensProps {
}

export const ProjectReportsScreens: React.FC<ProjectReportsScreensProps> = observer((p) => {
    return <Routes>
        <Route index element={<ProjectReportsListScreen/>}/>
        <Route path={':reportId'} element={<ViewReportScreen/>}/>
    </Routes>

});

// ======================
// ProjectReportsScreen
// ======================
interface ProjectReportsScreenProps {
}

export const ProjectReportsListScreen: React.FC<ProjectReportsScreenProps> = observer((p) => {
    return (<ScreenContainer>
        <ScreenTitleBar title={`Project Reports`}/>
        <ProjectReportsList/>

    </ScreenContainer>);
});

// ======================
// ProjectReportsList
// ======================


interface ProjectReportsListProps {
}

const ProjectReportsList: React.FC<ProjectReportsListProps> = observer((p) => {
    const {reportService} = useAppServices();
    const projectId       = useCurrentProjectID();

    useInitData({
        poll: () => reportService.projectReports.fetchData(projectId, ReportType.ReportType.MIGRATION_SESSION_REPORT)
    })

    const serverData = reportService.projectReports;
    return <ListTable rows={serverData?.data?.reportsList}
                      state={serverData.tableState}
                      loading={serverData.loading}
                      pagerMeta={serverData?.data?.pagerMeta}
                      onTableStateChange={() => serverData.fetchData()}
                      listComponent={ProjectReportListCard}/>;

});

// ======================
// ProjectReportListCard
// ======================


interface ProjectReportListCardProps {
    data: ReportInfo.AsObject
}

const ProjectReportListCard: React.FC<ProjectReportListCardProps> = observer((p) => {
    const {data}             = p;
    const theme              = useTheme();
    const goToSessionDetails = useNavigateToMigrationSessionDetails(data.msrMeta.sessionId);
    const goToSourceDeployment = useNavigateToDeploymentDetails(data.msrMeta.sourceSystemId);
    const goToDestDeployment = useNavigateToDeploymentDetails(data.msrMeta.destSystemId);

    return <Box pt={2}>
        <Card>
            <Box p={2} display={'flex'} height={'100%'} alignItems={'center'}>
                <Box pr={1}>
                    <Avatar sx={{bgcolor: theme.palette.cirrus.main}}>
                        <ReportIcon htmlColor={alpha('#fff', .5)}/>
                    </Avatar>
                </Box>
                <Box sx={{paddingRight: 2, borderRight: `2px solid ${cirrusColors.primary}`}}>
                    <Box display={'flex'} alignItems={'center'}>
                        <Box pr={1}>
                            <Typography variant={'h6'}>
                                {`Report ID:`} &nbsp;
                                <Link onClick={() => viewReport(data.reportId)}>{data.reportId}</Link>
                            </Typography>
                        </Box>
                        <Chip label={getReportTypeLabel(data.reportType)} size={'small'}/>
                    </Box>
                    <Box>
                        <Typography variant={'caption'} color={'textSecondary'}>
                            {`Created ${formatKnownDataType(convertTimestampObjectToDate(data.createdAt), KnownDataType.DATE_RELATIVE)} by ${getUserFullNameFromObject(data.createdBy)}`}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Box pl={2} display={'flex'}>
                        <KeyValuePairSubtitle label={'Migration Session'} value={<Link onClick={goToSessionDetails}>
                            {getGmSessionIDSlug(data.msrMeta.sessionId)}
                        </Link>}/>
                        <Typography variant={'body2'} sx={{paddingRight:2, paddingLeft: 2}}>
                            {`|`}
                        </Typography>
                        <KeyValuePairSubtitle label={'Host'} value={<>
                            <Link onClick={goToSourceDeployment}>
                                {data.msrMeta.sourceSystemName}
                            </Link>
                            &nbsp;
                            {'>'}
                            &nbsp;
                            <Link onClick={goToDestDeployment}>
                                {data.msrMeta.destSystemName}
                            </Link>
                        </>}
                        />
                    </Box>
                    <Box pl={2} display={'flex'}>
                        <KeyValuePairSubtitle label={'Status'} value={renderGMSessionStatus(data.msrMeta.sessionStatus)}/>
                        <Typography variant={'body2'} sx={{paddingRight:2, paddingLeft: 2}}>
                            {`|`}
                        </Typography>
                        <KeyValuePairSubtitle label={'Volumes'} value={data.msrMeta.sessionVolumes}/>
                    </Box>
                    <Box pl={2} display={'flex'}>
                        <KeyValuePairSubtitle label={'Session Description'} value={data.msrMeta.sessionDesc || 'N/A'}/>
                    </Box>
                </Box>

            </Box>
            <Box pb={2} pr={2} pl={2} pt={0}>
                <Typography variant={'body2'} color={'textSecondary'}>
                    {data.systemNotes}
                </Typography>
                {!!data.userNotes &&
                    <Box display={'flex'} justifyContent={'flex-start'} pt={1}>
                        <Typography variant={'body2'} fontWeight={500}>
                            {`User Report Notes:`} &nbsp;
                        </Typography>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            {data.userNotes}
                        </Typography>
                    </Box>
                }
            </Box>
        </Card>
    </Box>
});

