import { observer } from "mobx-react-lite";
import {
    alpha,
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SvgIcon,
    SvgIconProps,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineDotProps,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from "@mui/lab";
import { useAppServices } from "../../../app/services";
import { renderServerDataWithLoadingList, useInitData } from "../../../core/data/DataLoaderHooks";
import { ChecklistInfo } from "../../../../_proto/galaxycompletepb/apipb/domainpb/checklist_pb";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { FcCheckmark } from "react-icons/fc";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../../../common/utils/formatter";
import { getUserFullNameFromObject } from "../../../settings/ProjectUsers";
import { IoPlayForwardSharp } from "react-icons/io5";
import { ChecklistTaskStatus } from "../../../../_proto/galaxycompletepb/apipb/domainpb/enumpb/checklist_task_status_pb";
import { ActionTriggeredState } from "../../MigrationCommon";
import { MdChecklist } from "react-icons/md";
import { GalaxyMigrateMigrationSessionDetails } from "../../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ChecklistTaskSpecialUIActionButton } from "./GmMigrationCutoverChecklistActionButtons";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../../core/dialog/DialogService";
import { DialogTopBar } from "../../../core/dialog/DialogComponents";
import { MarkdownText } from "../../../../common/text/MarkdownText";
import { QuickTipButton } from "../../../help/HelpCommon";
// ======================
// GmMigrationCutoverChecklistView
// ======================

interface GmMigrationCutoverChecklistViewProps {
    actionTriggeredState: ActionTriggeredState;
    sessionDetails: GalaxyMigrateMigrationSessionDetails;
}

export const GmMigrationCutoverChecklistView: React.FC<GmMigrationCutoverChecklistViewProps> = observer((p) => {
    const { checklistService, gmMigrationService } = useAppServices();

    useInitData({
        poll: () => checklistService.currentChecklist.fetchData(gmMigrationService.currentSessionCutoverChecklistId),
        pollInterval: 3,
    });

    return renderServerDataWithLoadingList(checklistService.currentChecklist, (data) => {
        return (
            <Box pt={2} width={"100%"}>
                <Box display={"flex"} alignItems={"center"}>
                    <Box pr={1}>
                        <Typography variant={"h6"}>{data.checklistDescriptor.title}</Typography>
                    </Box>

                    <QuickTipButton title={data.checklistDescriptor.description} />
                </Box>

                <Timeline sx={{ width: "100%" }}>
                    {data.tasksList.map((t, i) => {
                        const isActive = data.tasksList.find((t) => !t.finished)?.taskId === t.taskId;
                        const isLastItem = data.tasksList.length === i + 1;
                        return (
                            <ChecklistTaskTimelineItem
                                key={i}
                                task={t}
                                index={i}
                                isActive={isActive}
                                isLastItem={isLastItem}
                                checklist={data}
                                sessionDetails={p.sessionDetails}
                                actionTriggeredState={p.actionTriggeredState}
                            />
                        );
                    })}
                </Timeline>
            </Box>
        );
    });
});

// ======================
// ChecklistTaskTimelineItem
// ======================

interface ChecklistTaskTimelineItemProps {
    task: ChecklistInfo.Task.AsObject;
    index: number;
    isActive: boolean;
    isLastItem: boolean;
    actionTriggeredState: ActionTriggeredState;
    checklist: ChecklistInfo.AsObject;
    sessionDetails: GalaxyMigrateMigrationSessionDetails;
}

const ChecklistTaskTimelineItem: React.FC<ChecklistTaskTimelineItemProps> = observer((p) => {
    const taskDescriptor = p.task.taskDescriptor;

    if (!taskDescriptor) {
        return null;
    }

    const finishedAtRelativeDate = formatKnownDataType(convertTimestampObjectToDate(p.task.finishedAt), KnownDataType.DATE_RELATIVE);
    const finishedAtDate = formatKnownDataType(convertTimestampObjectToDate(p.task.finishedAt), KnownDataType.DATE);
    const finishedTooltipText = `${renderTaskStatusLabel(p.task.status)} by ${getUserFullNameFromObject(p.task.finishedBy)} at ${finishedAtDate}`;

    return (
        <TimelineItem key={p.task.taskId}>
            <TimelineOppositeContent sx={{ flex: 0, padding: 0 }} />
            <TimelineSeparator>
                <TimelineConnector sx={{ visibility: p.index === 0 ? "hidden" : "visible" }} />
                <TimelineDot {...getMigrationChecklistTimelineItemDotProps(p.task, p.isActive)}>
                    {getMigrationChecklistTimelineItemDotContent(p.task, p.index)}
                </TimelineDot>
                <TimelineConnector sx={{ visibility: p.isLastItem ? "hidden" : "visible" }} />
            </TimelineSeparator>
            <TimelineContent>
                <Card sx={{ opacity: p.task.status === ChecklistTaskStatus.ChecklistTaskStatus.PENDING && !p.isActive ? 0.5 : 1 }}>
                    <Box display={"flex"} alignItems={"center"} pt={1} pb={1} pr={4} pl={1}>
                        <Box display={"flex"} alignItems={"center"} flexGrow={1}>
                            <ListItem dense component={"div"}>
                                <ListItemIcon>{renderTaskIcon(taskDescriptor)}</ListItemIcon>
                                <ListItemText primary={<Typography variant={"h5"}>{taskDescriptor.title}</Typography>} secondary={taskDescriptor.subtitle} />
                            </ListItem>
                        </Box>
                        {p.isActive && p.actionTriggeredState.actionTriggered && (
                            <Box>
                                <Typography variant={"body2"} sx={{ color: (t: Theme) => t.palette.primary.main }}>
                                    In Progress...
                                </Typography>
                            </Box>
                        )}
                        {p.task.finished && (
                            <Box>
                                <Tooltip arrow title={finishedTooltipText}>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        {finishedAtRelativeDate}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                    {p.isActive && <ChecklistTaskActiveContent task={p.task} sessionDetails={p.sessionDetails} actionTriggeredState={p.actionTriggeredState} />}
                </Card>
            </TimelineContent>
        </TimelineItem>
    );
});

// ======================
// ChecklistTaskActiveContent
// ======================

interface ChecklistTaskActiveContentProps {
    task: ChecklistInfo.Task.AsObject;
    actionTriggeredState: ActionTriggeredState;
    sessionDetails: GalaxyMigrateMigrationSessionDetails;
}

const ChecklistTaskActiveContent: React.FC<ChecklistTaskActiveContentProps> = observer((p) => {
    const { task, actionTriggeredState, sessionDetails } = p;
    const { checklistService, dialogService } = useAppServices();
    const taskDescriptor = task.taskDescriptor;

    const skipReasonDialogState = useDialogState();

    const actionDescriptors = taskDescriptor?.uiActionsList || [];
    const automatedActions = actionDescriptors.filter((a) => !a.manual);
    const allowManualSkip = automatedActions.length === 0;
    const allowManualComplete = automatedActions.length === 0;
    return (
        <Box width={"100%"}>
            <Divider orientation={"horizontal"} />
            {/* pt = 0 because markdown <p> already has padding*/}
            <Box p={2} pt={0}>
                <ChecklistDescription descriptionMarkdown={task?.taskDescriptor?.description} />
                {/*todo add related links*/}
                <Box display={"flex"} pt={2} pb={2}>
                    {actionDescriptors.map((a) => (
                        <ChecklistTaskSpecialUIActionButton
                            key={a.action}
                            taskId={p.task.taskId}
                            action={a.action}
                            actionTriggeredState={p.actionTriggeredState}
                        />
                    ))}
                    {allowManualComplete && (
                        <Box pr={1} pl={1}>
                            <Button
                                variant={"outlined"}
                                onClick={async () => {
                                    await checklistService.markTaskCompleted(p.task.taskId);
                                }}
                            >
                                {`Mark as Completed`}
                            </Button>
                        </Box>
                    )}
                    {allowManualSkip && (
                        <Box pr={1} pl={1}>
                            <Button variant={"outlined"} color={"neutral"} onClick={skipReasonDialogState.open}>
                                {`Mark as Skipped`}
                            </Button>
                            {skipReasonDialogState.open && (
                                <SkipTaskDialog dialogState={skipReasonDialogState} taskId={task.taskId} title={taskDescriptor.title} />
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
});

// ======================
// SkipTaskDialog
// ======================

interface SkipTaskDialogProps {
    dialogState: DialogState;
    title: string;
    taskId: number;
}

export const SkipTaskDialog: React.FC<SkipTaskDialogProps> = observer((p) => {
    const { dialogState, title, taskId } = p;
    const { checklistService } = useAppServices();
    const [skipReason, setSkipReason] = useState("");

    return (
        <Dialog
            open={dialogState.isOpen}
            onClose={() => {
                setSkipReason("");
                dialogState.close();
            }}
            maxWidth={"sm"}
            fullWidth
            fullScreen={useShouldDialogFullScreen()}
        >
            <DialogTopBar dialogState={dialogState} title={title} />
            <DialogContent>
                <DialogContentText>
                    {`You are marking this action as skipped. THIS ACTION IS IRREVERSIBLE. You will not be able to return to previous steps.`}
                </DialogContentText>
                <br />
                <DialogContentText variant={"body2"} color={"textSecondary"}>
                    Reason for skipping:
                </DialogContentText>
                <TextField
                    variant={"filled"}
                    label={"Note (Optional)"}
                    fullWidth
                    type={"text"}
                    value={skipReason}
                    onChange={(e) => {
                        console.log(e.target.value);
                        setSkipReason(e.target.value);
                    }}
                />
                <Box pt={2} pb={2}>
                    <DialogContentText>{`Are you sure you want to continue?`}</DialogContentText>
                </Box>

                <DialogActions>
                    <Button onClick={dialogState.close} color={"neutral"}>
                        {"No"}
                    </Button>
                    <Button
                        onClick={async () => {
                            await checklistService.markTaskSkipped(taskId, skipReason);
                            dialogState.close();
                        }}
                    >
                        {"Yes"}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
});

// ======================
// ChecklistPrimaryText
// ======================

interface ChecklistDescriptionProps {
    descriptionMarkdown: string;
}

const ChecklistDescription: React.FC<ChecklistDescriptionProps> = observer((p) => {
    if (!p.descriptionMarkdown) {
        return null;
    }
    // todo, consider using default github css file instead
    return <MarkdownText markdown={p.descriptionMarkdown} />;
});

export const DefaultChecklistIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon>
            <MdChecklist />
        </SvgIcon>
    );
};

const renderTaskStatusLabel = (status: ChecklistTaskStatus.ChecklistTaskStatus) => {
    if (status === ChecklistTaskStatus.ChecklistTaskStatus.COMPLETED) {
        return "Completed";
    } else if (status === ChecklistTaskStatus.ChecklistTaskStatus.SKIPPED) {
        return "Skipped";
    }
};

const renderTaskIcon = (taskDescriptor: ChecklistInfo.Task.Descriptor.AsObject) => {
    if (taskDescriptor.icon === ChecklistInfo.Task.Descriptor.Icon.ICON_DEFAULT) {
        return <DefaultChecklistIcon />;
    }
    // else if
    return <DefaultChecklistIcon />;
};

const getMigrationChecklistTimelineItemDotContent = (task: ChecklistInfo.Task.AsObject, index: number) => {
    if (task.status === ChecklistTaskStatus.ChecklistTaskStatus.COMPLETED) {
        return <FcCheckmark />;
    } else if (task.status === ChecklistTaskStatus.ChecklistTaskStatus.SKIPPED) {
        return <IoPlayForwardSharp color={"#777777"} />;
    } else {
        return index + 1;
    }
};

const getMigrationChecklistTimelineItemDotProps = (task: ChecklistInfo.Task.AsObject, isActive: boolean): TimelineDotProps => {
    let sx: SxProps<Theme> = {
        width: 28,
        height: 28,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };
    if (task.status === ChecklistTaskStatus.ChecklistTaskStatus.COMPLETED || task.status === ChecklistTaskStatus.ChecklistTaskStatus.SKIPPED) {
        sx.backgroundColor = alpha("#000000", 0);
        return { sx };
    } else if (isActive) {
        return {
            color: "primary",
            sx,
        };
    } else if (!isActive && task.status === ChecklistTaskStatus.ChecklistTaskStatus.PENDING) {
        sx.backgroundColor = "#000000";
        return { sx };
    }
};
