import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router";
import { useAppServices } from "./services";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { isDevelopment } from "../../common/utils/util";
import { FullScreenLoading } from "../core/data/DataLoaders";
import { PROJECT_SUBROUTE } from "./AppRoutes";
import { useEffectOnce } from "react-use";
import * as Sentry from "@sentry/react";
import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { FeatureFlag } from "./AppGlobalService";
import { useAuthState } from "../auth/AuthState";
import {
    useInitAuthentication,
    useIsAuthenticated,
    useLoadPersistentEntitiesFromStorage,
    useRefreshToken,
    useRefreshTokenIfExpireSoon,
} from "../auth/auth_hooks";
import { useCurrentProjectID } from "../project/CurrentProject";
import { isAfter } from "date-fns";

// ======================
// AppInitialization
// ======================

interface AppInitializationProps {
    children?: React.ReactNode;
}

export const AppInitialization: React.FC<AppInitializationProps> = observer(({ ...p }) => {
    const { appGlobalService, authService, dialogService } = useAppServices();
    const refreshTokenIfExpireSoon = useRefreshTokenIfExpireSoon();
    const initAuthentication = useInitAuthentication();
    const authenticated = useIsAuthenticated();
    const location = useLocation();
    const init = useCallback(
        async function init() {
            appGlobalService.setInitialized(false);
            console.debug(`Initializing App. Development: ${isDevelopment()}`);

            Object.values(PROJECT_SUBROUTE).forEach((value) => {
                if (location.pathname.includes(value)) {
                    appGlobalService.setAppSubmenu(value);
                }
            });

            // give auth service the history
            await initAuthentication();

            // do something here if needed, check if already logged in, etc.

            //sleep
            await appGlobalService.sleep();
            console.debug("App Initialization Complete");
            appGlobalService.setInitialized(true);
        },
        [appGlobalService, location.pathname, initAuthentication]
    );

    const postAuthInit = useCallback(
        async function init() {
            Sentry.setUser({ email: authService.currentUser.getEmail() });
            appGlobalService.setPostAuthInitialized(false);
            console.debug(`Initializing App Post Authentication. Development: ${isDevelopment()}`);
            await appGlobalService.reloadEnabledFeatureFlags();

            appGlobalService.setPostAuthInitialized(true);
        },
        [appGlobalService, authService]
    );

    const postAuthLogout = useCallback(async function init() {
        Sentry.configureScope((scope) => scope.setUser(null));
        console.debug(`De-Initializing App Post Logout. Development: ${isDevelopment()}`);
    }, []);

    useEffectOnce(() => {
        init();
    });

    // =================
    // post auth init
    // =================
    useEffect(() => {
        if (authenticated) {
            postAuthInit();
        } else {
            postAuthLogout();
        }
    }, [authenticated, postAuthInit, postAuthLogout]);

    //set up auto refresh token
    useEffect(() => {
        console.debug("setup auto refresh token");
        const autoRefreshInterval = setInterval(() => {
            refreshTokenIfExpireSoon();
        }, 1000 * 60);
        return () => clearInterval(autoRefreshInterval);
    }, [refreshTokenIfExpireSoon]);

    if (!appGlobalService.initialized) {
        return <FullScreenLoading loading={true} message={"Starting Initialization..."} />;
    } else if (authenticated && !appGlobalService.postAuthInitialized) {
        return <FullScreenLoading loading={true} message={"Initializing..."} />;
    } else {
        return <>{p.children}</>;
    }
});
