import * as React from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import Dialog from "@mui/material/Dialog";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useAppServices } from "../../app/services";
import { DialogTopBar } from "../../core/dialog/DialogComponents";
import { Box, Button, ButtonProps, DialogActions, DialogContent, DialogContentText, Grid } from "@mui/material";
import { FormTextField } from "../../../common/form/FormComponents";
import { getGalaxyMigrateHelperNodeOSName } from "../GalaxyMigrateCommon";
import { DeploymentHostEnvironment } from "../../../_proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import { useCurrentProjectID } from "../../project/CurrentProject";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { useListIntegrations } from "../../integrations/integration_hooks";
import { getModuleDefByModule, IntegrationCard } from "../../integrations/IntegrationsCommon";
import { IntegrationModule } from "../../../_proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { SelectableBox, SelectableCard } from "../../../common/card/SelectableCard";
import { useConfigureAzureHelper } from "../hooks/gm_deployment_hooks";

interface ConfigureGalaxyMigrateCmcHelperButtonProps {
    systemID: string;
    os: DeploymentHostEnvironment.DeploymentHostEnvironment;
}

export const ConfigureGalaxyMigrateCmcHelperButton = (p: ConfigureGalaxyMigrateCmcHelperButtonProps & Partial<ButtonProps>) => {
    const { systemID, os } = p;

    const helperName = `${getGalaxyMigrateHelperNodeOSName(os)}`;
    const dialogState = useDialogState();
    return (
        <>
            <Button variant={"outlined"} color={"secondary"} onClick={() => dialogState.open()} {...p}>
                {`Configure ${helperName}`}
            </Button>
            <ConfigureCmcHelperDialog dialogState={dialogState} systemID={systemID} os={os} />
        </>
    );
};

// ======================
// ConfigureGalaxyMigrateVmwareHelperDialog
// ======================
interface ConfigureCmcHelperDialogProps {
    dialogState: DialogState;
    systemID: string;
    os: DeploymentHostEnvironment.DeploymentHostEnvironment;
}

export const ConfigureCmcHelperDialog: React.FC<ConfigureCmcHelperDialogProps> = observer((p) => {
    const { dialogState, systemID, os } = p;

    const getFormContent = (os: DeploymentHostEnvironment.DeploymentHostEnvironment) => {
        if (os === DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE) {
            return <ConfigureGalaxyMigrateVmwareHelperForm dialogState={dialogState} systemID={systemID} />;
        } else if (os === DeploymentHostEnvironment.DeploymentHostEnvironment.AZURE) {
            return <ConfigureGalaxyMigrateAzureHelperForm dialogState={dialogState} systemID={systemID} />;
        }
    };

    return (
        <Dialog open={dialogState.isOpen} fullScreen={useShouldDialogFullScreen()} maxWidth={"md"} fullWidth onClose={dialogState.close}>
            {dialogState.isOpen && systemID && <Box>{getFormContent(os)}</Box>}
        </Dialog>
    );
});

// ======================
// ConfigureGalaxyMigrateAzureHelperForm
// ======================

interface ConfigureGalaxyMigrateAzureHelperFormProps {
    dialogState: DialogState;
    systemID: string;
}

export const ConfigureGalaxyMigrateAzureHelperForm: React.FC<ConfigureGalaxyMigrateAzureHelperFormProps> = observer((p) => {
    const { dialogState, systemID } = p;
    const queryResult = useListIntegrations();

    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const configureAzureHelper = useConfigureAzureHelper();

    const configureHelper = async () => {
        const res = await configureAzureHelper.mutateAsync({
            systemId: systemID,
            integrationId: selectedIntegration,
        });
        if (!!res) {
            dialogState.close();
        }
    };

    return (
        <>
            <DialogTopBar dialogState={dialogState} divider title={"Configure CMC Helper For Azure Integration"} />
            <DialogContent>
                <DialogContentText>{`Please select the Azure Integration that this helper node belongs to.`}</DialogContentText>
                <QueryResultWrapper queryResult={queryResult}>
                    <Grid container spacing={3} pt={2}>
                        {queryResult.data?.getItemsList().map((i) => {
                            return (
                                <Grid item xs={12} md={4}>
                                    <SelectableBox
                                        selected={selectedIntegration === i.getId()}
                                        checkmark
                                        onSelect={() => setSelectedIntegration(i.getId())}
                                        darkBorder
                                    >
                                        <IntegrationCard
                                            module={getModuleDefByModule(IntegrationModule.AZURE)}
                                            instance={i}
                                            dividerColor={"rgba(255, 255, 255, .2)"}
                                            cardProps={{
                                                elevation: 0,
                                                sx: {
                                                    background: "none",
                                                },
                                            }}
                                        />
                                    </SelectableBox>
                                </Grid>
                            );
                        })}
                    </Grid>
                </QueryResultWrapper>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
                <Button variant={"contained"} disabled={selectedIntegration === null} onClick={configureHelper}>{`Configure`}</Button>
            </DialogActions>
        </>
    );
});

// ======================
// ConfigureGalaxyMigrateVmwareHelperForm
// ======================
interface ConfigureGalaxyMigrateVmwareHelperFormProps {
    systemID: string;
    dialogState: DialogState;
}

const ConfigureGalaxyMigrateVmwareHelperForm: React.FC<ConfigureGalaxyMigrateVmwareHelperFormProps> = observer((p) => {
    const initialValues = {
        ip: "",
        username: "",
        password: "",
    };
    const validationSchema = yup.object();

    const { progressService, gmDeploymentService, deploymentService } = useAppServices();
    const helperName = `${getGalaxyMigrateHelperNodeOSName(DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE)}`;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                await progressService.track(gmDeploymentService.configureVmwareHelper(p.systemID, values.ip, values.username, values.password));
                await deploymentService.galaxyMigrateDeployments.fetchData();
            }}
        >
            {(props) => {
                return (
                    <Form>
                        <DialogTopBar dialogState={p.dialogState} title={`Configure ${helperName} Integration`} divider />
                        <DialogContent>
                            <DialogContentText>
                                {
                                    "Please provide the ip address and user credentials to the VMware vCenter that this helper node belongs to. This step is required for VMware integration to work."
                                }
                            </DialogContentText>
                            <br />
                            <DialogContentText>
                                {`Credentials provided here will be encrypted and stored locally on the ${helperName}. They will not be stored or logged in this cloud portal.`}
                            </DialogContentText>
                            <br />
                            <Box pb={2}>
                                <FormTextField name={"ip"} label={"vCenter IP Address / Host"} />
                            </Box>
                            <Box pb={2}>
                                <FormTextField name={"username"} label={"vCenter User"} />
                            </Box>
                            <Box pb={2}>
                                <FormTextField name={"password"} type={"password"} label={"vCenter User Password"} />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Box p={2}>
                                <Button type={"submit"} variant={"outlined"}>
                                    {"Configure"}
                                </Button>
                            </Box>
                        </DialogActions>
                    </Form>
                );
            }}
        </Formik>
    );
});
