// Project: GalaxyComplete
// Created: 9/28/20 by sammy
// File: ProjectMigrationSessionsListScreen

import * as React from "react";
import { observer } from "mobx-react-lite";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { useInitData } from "../core/data/DataLoaderHooks";
import { useAppServices } from "../app/services";
import { ColumnDef, DataTable } from "../../common/table/DataTable";
import { GalaxyMigrateMigrationSessionBasicInfo } from "../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { Box, Button, Card, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { getGmSessionIDSlug, renderGMSessionStatus, useHasMigrationSessions, useNavigateToMigrationWizardScreen } from "./MigrationCommon";
import { MigrationIcon } from "../project/ProjectCommon";
import { OperatorView } from "../auth/AuthenticatedViews";
import { useCurrentProjectID } from "../project/CurrentProject";
import { generateDeploymentDetailsPath } from "../galaxymigrate/GalaxyMigrateCommon";
import { GmMigrationWizardStep } from "./GmMigrationService";
import { LicenseModel } from "../../_proto/galaxycompletepb/apipb/domainpb/enumpb/license_model_pb";
import { checkIfLicenseModelUndecided, useCurrentProjectLicenseModel } from "../license/LicenseCommon";

// ======================
// ProjectMigrationSessionsListScreen
// ======================
interface ProjectMigrationSessionsListScreenProps {}

export const ProjectMigrationSessionsListScreen: React.FC<ProjectMigrationSessionsListScreenProps> = observer((p) => {
    const { gmMigrationService, licenseService } = useAppServices();
    const hasMigrationSessions = useHasMigrationSessions();

    const poll = async () => {
        await gmMigrationService.sessions.fetchData();
        await licenseService.projectLicenseDetails.fetchData();
    };

    useInitData({
        poll: poll,
        pollInterval: 5,
    });
    return (
        <ScreenContainer>
            <ScreenTitleBar title={`Migration Sessions`} actions={<>{hasMigrationSessions && <NewSessionButton />}</>} />
            <SessionsTable />
        </ScreenContainer>
    );
});

// ======================
// SessionsTable
// ======================
interface SessionsTableProps {}

const SessionsTable: React.FC<SessionsTableProps> = observer((p) => {
    const { gmMigrationService } = useAppServices();
    const serverData = gmMigrationService.sessions;
    const projectId = useCurrentProjectID();

    const getHostConnectionStyle = (connected: boolean) => {
        if (!connected) {
            return {
                color: "#757575",
            };
        }
        return {};
    };

    const cols: ColumnDef<GalaxyMigrateMigrationSessionBasicInfo>[] = [
        {
            id: "session",
            label: "Session",
            getter: (r) => r.getSessionId(),
            renderer: (sessionID, r) => {
                const sessionName = !r.getDeploymentConnected() ? (
                    <Typography variant={"body2"} sx={getHostConnectionStyle(r.getDeploymentConnected())}>
                        {getGmSessionIDSlug(r.getSessionId())}
                    </Typography>
                ) : (
                    <Link component={RouterLink} to={r.getSessionId()}>
                        {getGmSessionIDSlug(r.getSessionId())}
                    </Link>
                );

                return (
                    <Box>
                        {sessionName}
                        <Typography variant={"body2"} color={r.getDeploymentConnected() ? "textSecondary" : "#757575"}>
                            {r.getDescription()}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            id: "deployment",
            label: "Host",
            getter: (r) => r.getDeployment().getSystemName(),
            renderer: (_, r) => {
                if (!!r.getDestinationDeployment()) {
                    return (
                        <span>
                            <Link component={RouterLink} to={generateDeploymentDetailsPath(r.getDeployment().getSystemId(), projectId)}>
                                {r.getDeployment().getSystemName()}
                            </Link>
                            &nbsp; &nbsp;
                            {`>>`}
                            &nbsp; &nbsp;
                            <Link component={RouterLink} to={generateDeploymentDetailsPath(r.getDestinationDeployment().getSystemId(), projectId)}>
                                {r.getDestinationDeployment().getSystemName()}
                            </Link>
                        </span>
                    );
                }
                return (
                    <Link component={RouterLink} to={generateDeploymentDetailsPath(r.getDeployment().getSystemId(), projectId)}>
                        {r.getDeployment().getSystemName()}
                    </Link>
                );
            },
        },
        {
            id: "volumes",
            label: "Volumes",
            getter: (r) => r.getTotalVolumes(),
            renderer: (volumes, r) => {
                return <Typography sx={getHostConnectionStyle(r.getDeploymentConnected())}>{formatKnownDataType(volumes, KnownDataType.NUMBER)}</Typography>;
            },
            dataType: KnownDataType.NUMBER,
        },
        {
            id: "totalCapacity",
            label: "Total Capacity",
            getter: (r) => r.getTotalCapacity(),
            renderer: (capacity, r) => {
                return <Typography sx={getHostConnectionStyle(r.getDeploymentConnected())}>{formatKnownDataType(capacity, KnownDataType.CAPACITY)}</Typography>;
            },
        },
        {
            id: "migrated",
            label: "Migrated",
            getter: (r) => r.getSessionStats().getTotalSynced(),
            renderer: (totalSynced, r) => {
                return (
                    <Typography sx={getHostConnectionStyle(r.getDeploymentConnected())}>{formatKnownDataType(totalSynced, KnownDataType.CAPACITY)}</Typography>
                );
            },
        },
        {
            id: "remaining",
            label: "Remaining",
            getter: (r) => r.getSessionStats().getTotalRemaining(),
            renderer: (remaining, r) => {
                return (
                    <Typography sx={getHostConnectionStyle(r.getDeploymentConnected())}>{formatKnownDataType(remaining, KnownDataType.CAPACITY)}</Typography>
                );
            },
        },
        {
            id: "status",
            label: "Status",
            getter: (r) => r.getSessionStatus(),
            renderer: (v, r) => {
                return (
                    <Typography component={"span"} sx={getHostConnectionStyle(r.getDeploymentConnected())}>
                        {renderGMSessionStatus(v)}
                    </Typography>
                );
            },
        },
    ];
    return (
        <Card elevation={2}>
            <DataTable
                rows={serverData?.data?.getItemsList()}
                cols={cols}
                state={serverData.tableState}
                pagerMeta={serverData?.data?.getPagerMeta().toObject()}
                loading={serverData.loading}
                emptyTableTitle={"No Sessions Found"}
                emptyTableActionButton={<NewSessionButton />}
                onTableStateChange={() => serverData.fetchData()}
            />
        </Card>
    );
});

// ======================
// NewSessionButton
// ======================

interface NewSessionButtonProps {}

const NewSessionButton: React.FC<NewSessionButtonProps> = observer((props) => {
    const { gmMigrationService, dialogService } = useAppServices();
    const navigateToWizard = useNavigateToMigrationWizardScreen();
    const projectLicenseModel = useCurrentProjectLicenseModel();
    const projectId = useCurrentProjectID();

    const createNewSession = async () => {
        await checkIfLicenseModelUndecided(projectLicenseModel, dialogService, projectId);

        if (projectLicenseModel !== LicenseModel.LicenseModel.UNDECIDED) {
            gmMigrationService.initWizard();
            gmMigrationService.wizardState.stepperState.setStartingStepIndex(GmMigrationWizardStep.SOURCE_DEPLOYMENT_SELECTION);
            navigateToWizard();
        }
    };

    return (
        <OperatorView>
            <Button variant={"contained"} color={"secondary"} startIcon={<MigrationIcon />} onClick={() => createNewSession()}>
                New Migration Session
            </Button>
        </OperatorView>
    );
});
