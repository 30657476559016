// ======================
// SelectVolumeForSnapshotsDialog
// ======================

import {DialogState, useDialogState} from "../../core/dialog/DialogService";
import {
    GalaxyMigrateMigrationSessionDetails,
    GalaxyMigrateMigrationSessionInfo
} from "../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import * as React from "react";
import {observer} from "mobx-react-lite";
import {useAppServices} from "../../app/services";
import {MTDIDeploymentInfo} from "../../../_proto/galaxycompletepb/apipb/domainpb/mtdi_pb";
import {
    Box,
    Chip,
    Dialog,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    SvgIcon,
    Typography
} from "@mui/material";
import {getStorageConfigBlockDevice} from "../MigrationCommon";
import {AiFillDatabase} from "react-icons/ai";
import {formatKnownDataType, KnownDataType} from "../../../common/utils/formatter";
import {useGmAutoAllocationState} from "../autoallocation/GmAutoAllocationCommon";
import {renderServerDataWithLoadingList, useInitData} from "../../core/data/DataLoaderHooks";
import {ListSnapshotsResponse} from "../../../_proto/galaxymigratepb/galaxy_migrate_autoalloc_pb";
import {ActionConfig} from "../../../common/actions/CommonActions";
import {DeleteIcon} from "../../../common/CommonIcons";
import {ColumnDef, DataTable} from "../../../common/table/DataTable";
import {Timestamp} from "google-protobuf/google/protobuf/timestamp_pb";
import {SelectableBox} from "../../../common/card/SelectableCard";
import {GalaxyMigrateAutoAllocationProgressDialog} from "../autoallocation/GmAutoAllocationProgress";



// ======================
// CurrentSessionVolumeSnapshots
// ======================

interface CurrentSessionVolumeSnapshotsProps {
    data: GalaxyMigrateMigrationSessionDetails;
}

export const GmMigrationSessionVolumeSnapshots: React.FC<CurrentSessionVolumeSnapshotsProps> = observer((p) => {
    const {gmMigrationService, deploymentService} = useAppServices();
    const volumes = p.data.getSessionInfo().getSessionVolumesList();
    const selectVolumeDialogStage = useDialogState();
    const selectedVolume = gmMigrationService.currentSessionSelectedSnapshotVolume;
    const isDeploymentWindows = deploymentService.currentDeploymentOS === MTDIDeploymentInfo.OSType.WINDOWS;

    const onClick = async () => {
        selectVolumeDialogStage.open();
    };

    const renderListItemText = () => {
        if (!!selectedVolume) {
            const device = getStorageConfigBlockDevice(selectedVolume.getDestination().getDevicePath(), gmMigrationService.storageConfig.data?.toObject().devicesList, isDeploymentWindows);

            return <ListItemText
                primary={`${selectedVolume.getDestination().getName()} (${formatKnownDataType(selectedVolume.getSource().getCapacity(), KnownDataType.CAPACITY)})`}
                secondary={
                    <Box>
                        <Box>
                            source
                            volume: {selectedVolume.getSource().getName()} ({formatKnownDataType(selectedVolume.getSource().getCapacity(), KnownDataType.CAPACITY)})
                        </Box>
                        <Chip label={device?.blockDevice.deviceType}/>
                        {!!device?.blockDevice.fsType &&
                            <Chip label={device?.blockDevice.fsType}/>
                        }
                        {!!device?.blockDevice.label &&
                            <Chip label={device?.blockDevice.label}/>
                        }
                        {!!device?.blockDevice.mountPoint &&
                            <Chip label={device?.blockDevice.mountPoint}/>
                        }
                        <Box pt={2}>
                            Click to select a different volume
                        </Box>
                    </Box>
                }/>;

        } else {
            return <ListItemText primary={'Select Volume'} secondary={'Click to select a volume'}/>;
        }
    };

    return <><Box pt={2}>
        <SelectableBox selected={!!selectedVolume} onSelect={onClick}>
            <Box p={2}>
                <ListItem>
                    <ListItemIcon>
                        <SvgIcon>
                            <AiFillDatabase/>
                        </SvgIcon>
                    </ListItemIcon>
                    {renderListItemText()}
                </ListItem>
            </Box>
        </SelectableBox>
    </Box>
        {!!selectedVolume &&
            <Box pt={2}>
                <SelectedVolumeSnapshotsList session={p.data} volume={selectedVolume}/>
            </Box>
        }
        <SelectVolumeForSnapshotsDialog dialogState={selectVolumeDialogStage} volumes={volumes}/>
        <GalaxyMigrateAutoAllocationProgressDialog actionLabel={'Close'}/>
    </>;
});

interface SelectVolumeForSnapshotsDialogProps {
    dialogState: DialogState;
    volumes: Array<GalaxyMigrateMigrationSessionInfo.Volume>;
}

export const SelectVolumeForSnapshotsDialog: React.FC<SelectVolumeForSnapshotsDialogProps> = observer((p) => {
    const {gmMigrationService, deploymentService} = useAppServices();

    const isDeploymentWindows = deploymentService.currentDeploymentOS === MTDIDeploymentInfo.OSType.WINDOWS;

    return <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} maxWidth={'sm'} fullWidth>
        <ListSubheader>Select a volume to view snapshots</ListSubheader>
        {p.volumes.map(v => {
            const device = getStorageConfigBlockDevice(v.getDestination().getDevicePath(), gmMigrationService.storageConfig.data?.toObject().devicesList, isDeploymentWindows);

            const onClick = () => {
                gmMigrationService.setSelectedSnapshotVolume(v);
                p.dialogState.close();
            };
            return <ListItem button onClick={onClick}>
                <ListItemIcon>
                    <SvgIcon>
                        <AiFillDatabase/>
                    </SvgIcon>
                </ListItemIcon>
                <ListItemText
                    primary={`${v.getDestination().getName()} (${formatKnownDataType(v.getDestination().getCapacity(), KnownDataType.CAPACITY)})`}
                    secondary={<Box>
                        <Box>
                            source
                            volume: {v.getSource().getName()} ({formatKnownDataType(v.getSource().getCapacity(), KnownDataType.CAPACITY)})
                        </Box>
                        {!!device &&
                            <Chip label={device?.blockDevice.deviceType}/>
                        }
                        {!!device?.blockDevice.fsType &&
                            <Chip label={device?.blockDevice.fsType}/>
                        }
                        {!!device?.blockDevice.label &&
                            <Chip label={device?.blockDevice.label}/>
                        }
                        {!!device?.blockDevice.mountPoint &&
                            <Chip label={device?.blockDevice.mountPoint}/>
                        }
                    </Box>}/>
            </ListItem>;
        })}
    </Dialog>;
});

// ======================
// SelectedVolumeSnapshotsList
// ======================

interface SelectedVolumeSnapshotsListProps {
    session: GalaxyMigrateMigrationSessionDetails;
    volume: GalaxyMigrateMigrationSessionInfo.Volume;
}

export const SelectedVolumeSnapshotsList: React.FC<SelectedVolumeSnapshotsListProps> = observer((p) => {
    const {gmMigrationService, deploymentService, progressService, dialogService} = useAppServices();
    const allocationState = useGmAutoAllocationState();
    const deployment = p.session.getSessionInfo().getDestinationDeployment() ||
        p.session.getSessionInfo().getDeployment();

    useInitData({
        poll: () => gmMigrationService.currentSessionVolumeSnapshots.fetchData(),
        pollInterval: 30,
        deinit: () => {
            gmMigrationService.currentSessionVolumeSnapshots.resetData();
            gmMigrationService.currentSessionSelectedSnapshotVolume = null;
        },
    });

    return renderServerDataWithLoadingList(gmMigrationService.currentSessionVolumeSnapshots, data => {
        const snapshots = data.getListsnapshotsResponse().getSnapshotsList();
        const tableState = gmMigrationService.currentSessionVolumeSnapshots.tableState;
        const getRowActions = (r: ListSnapshotsResponse.Snapshot): ActionConfig[] => {
            return [
                {
                    id: 'delete',
                    name: 'Delete Snapshot',
                    icon: <DeleteIcon/>,
                    action: async () => {
                        await gmMigrationService.deleteSnapshots([r.getSnapshotId()], deployment.getSystemId());
                        await gmMigrationService.currentSessionVolumeSnapshots.fetchData();
                    },
                },
                {
                    id: 'clone',
                    name: 'Clone Snapshot for Restore',
                    action: async () => {
                        allocationState.deploymentDetails = await deploymentService.fetchDeploymentDetails(deployment.getSystemId());
                        const confirmed = await dialogService.addConfirmDialog({
                            message: `By continuing, a new volume will be created based on the selected snapshot ${r.getSnapshotName()} and assigned back to host ${deployment.getSystemName()}`
                        });
                        if (confirmed) {
                            allocationState.setShowProgressDialog(true);
                            await gmMigrationService.cloneVolumeBackToHost(deployment.getSystemId(), p.volume.getDestination().getDevicePath(), r.getSnapshotId());
                            await allocationState.waitForProgressDialogToClose();
                        }
                    },
                },
            ];
        };

        const cols: ColumnDef<ListSnapshotsResponse.Snapshot>[] = [
            {
                id: 'name',
                label: 'Name',
                getter: (d) => d.getSnapshotName(),
            },
            {
                id: 'time',
                label: 'Time',
                getter: (d) => d.getTimestamp(),
                renderer: (v: Timestamp, d) => {
                    return <Box>
                        <Typography>
                            {formatKnownDataType(v.toDate(), KnownDataType.DATE)}
                        </Typography>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            {formatKnownDataType(v.toDate(), KnownDataType.DATE_RELATIVE)}
                        </Typography>
                    </Box>;
                },

            },
        ];

        return <DataTable state={tableState} rows={snapshots} cols={cols} rowActions={(r) => getRowActions(r)}/>;
    });
});