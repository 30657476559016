import {observer} from "mobx-react-lite";
import React from 'react';
import {useAppServices} from "../app/services";
import {ProjectMemberInfo} from "../../_proto/galaxycompletepb/apipb/domainpb/project_pb";

// ======================
// OperatorView
// ======================

export const useIsOperatorView = () => {
    const { projectService } = useAppServices();
    const myRole = projectService.currentProjectRole ? projectService.currentProjectRole : null;
    if (!!myRole){
        return myRole === ProjectMemberInfo.Role.OPERATOR || myRole === ProjectMemberInfo.Role.ADMIN
    }
    return false;
}

interface RestrictedViewProps{
    children: React.ReactNode
}



export const OperatorView: React.FC<RestrictedViewProps> = observer((props)=>{
    const isOperator = useIsOperatorView();
    return <>
        {isOperator &&
        props.children}
        </>
});

// ======================
// AdminView
// ======================

export const useIsAdminView = () => {
    const { projectService } = useAppServices();
    const myRole = projectService.currentProjectRole ? projectService.currentProjectRole : null;
    if (!!myRole){
        return myRole === ProjectMemberInfo.Role.ADMIN
    }
    return false;
}



export const AdminView: React.FC<RestrictedViewProps> = observer((props)=>{
    const isAdmin = useIsAdminView();
    return <>
        {isAdmin &&
            props.children
        }
    </>
})

// ======================
// MonitorView
// ======================

export const useIsMonitorView = () => {
    const { projectService } = useAppServices();
    return projectService.currentProjectRole !== ProjectMemberInfo.Role.ADMIN && projectService.currentProjectRole !== ProjectMemberInfo.Role.OPERATOR
}

export const MonitorView: React.FC<RestrictedViewProps> = observer((p)=>{
    const isMonitor = useIsMonitorView();
    return <>
        {
            isMonitor &&
            p.children
        }
    </>
})

export const useIsSupportView = () =>{
    const { appGlobalService } = useAppServices();
    return appGlobalService.isInternalUser
}
