import {observer} from "mobx-react-lite";
import React from "react";
import {ScreenContainer, ScreenTitleBar} from "../../layout/ScreenCommon";
import {useAppServices} from "../../app/services";
import {renderServerDataWithLoadingList, useInitData} from "../../core/data/DataLoaderHooks";
import {PhoenixDeploymentInfo} from "../../../_proto/galaxycompletepb/apipb/domainpb/phoenix_pb";
import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogContent,
    DialogContentText,
    Divider,
    Grid, Link,
    ListItem,
    ListItemText,
    ListSubheader,
    Typography, useTheme
} from "@mui/material";
import {DialogState, useDialogState} from "../../core/dialog/DialogService";
import {DialogTopBar} from "../../core/dialog/DialogComponents";
import {ClipboardButton, ClipboardText} from "../../../common/clipboard/ClipboardComponents";
import {getHostEnvDisplayName} from "../../galaxymigrate/GalaxyMigrateCommon";
import {formatKnownDataType, KnownDataType} from "../../../common/utils/formatter";
import {renderDeploymentStatusChip} from "./PhoenixDeploymentsCommon";
import {useNavigateToPhoenixDeploymentDetails} from "../ProjectProtectionCommon";
import {useCurrentProject} from "../../project/CurrentProject";
import {ListPhoenixDeployments} from "../../../_proto/galaxycompletepb/apipb/deployment_api_pb";
import {useIsTableEmpty} from "../../../common/table/TableCommon";
import {HELP_CENTER_URL} from "../../help/HelpCommon";
import EmptyTable from "../../../assets/image_empty_folder.png";

const usePhoenixDeploymentStyles = () => {
    const t = useTheme();
    return {
        command: {
            background: t.palette.background.default,
        },
    };
};

// ======================
// PhoenixDeploymentsListScreen
// ======================


interface PhoenixDeploymentsListScreenProps {
}

export const PhoenixDeploymentsListScreen: React.FC<PhoenixDeploymentsListScreenProps> = observer((p) => {
    const { phoenixDeploymentService } = useAppServices();
    const hasDeployments = useIsTableEmpty(phoenixDeploymentService.phoenixDeployments);

    const getActions = () => (<Box>
        {
            hasDeployments &&
            <RegisterNewPhoenixDeploymentButton/>
        }
    </Box>);

    return <ScreenContainer>
        <ScreenTitleBar title={`Protection Targets`} actions={getActions()}/>
        <PhoenixDeploymentsList/>
    </ScreenContainer>
});

// ======================
// PhoenixDeploymentsList
// ======================

interface PhoenixDeploymentsListProps {
}

const PhoenixDeploymentsList: React.FC<PhoenixDeploymentsListProps> = observer((p) => {
    const {phoenixDeploymentService} = useAppServices();
    useInitData({
        poll: () => phoenixDeploymentService.phoenixDeployments.fetchData(),
        pollInterval: 3
    })

    return renderServerDataWithLoadingList(phoenixDeploymentService.phoenixDeployments, (data: ListPhoenixDeployments.Response) => {
        if (data.getItemsList().length === 0) {
            return <Card>
                <Box pt={6} pb={6} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Box textAlign={'center'}>
                        <Box pb={2}>
                            <img src={EmptyTable} alt={'No Data Yet'} height={'50px'}/>
                        </Box>
                        <Typography variant={'h6'}>
                            No Protection Targets Found
                        </Typography>
                        <Typography>
                            Get started now or visit our <Link href={HELP_CENTER_URL} target={'_blank'}>Help Center</Link> for additional information.
                        </Typography>
                        <br/>
                        <RegisterNewPhoenixDeploymentButton/>
                    </Box>
                </Box>
                </Card>
        }

        return <>
            <Grid container spacing={2}>
                {data.getItemsList().map((d, i) => {
                    return <Grid item key={i} xs={12} md={6}>
                        <PhoenixDeploymentCard data={d}/>
                    </Grid>
                })
                }
            </Grid>
        </>
    })
});

// ======================
// PhoenixDeploymentCard
// ======================

interface PhoenixDeploymentCardProps {
    data: PhoenixDeploymentInfo;
}

const PhoenixDeploymentCard: React.FC<PhoenixDeploymentCardProps> = observer((p) => {


    const goToDetails = useNavigateToPhoenixDeploymentDetails(p.data.getDeployment().getSystemId());

    return <Card>
        <Box display={'flex'} justifyContent={'space-between'} p={2}>
            <Box>
                <Typography variant={'h5'}>{p.data.getDeployment().getSystemName()}</Typography>
                <Typography variant={'body2'} color={'textSecondary'}>Last
                    check-in: {formatKnownDataType(p.data.getDeployment().getLastCheckin()?.toDate(), KnownDataType.DATE_RELATIVE)}</Typography>
                <Box pt={1} pb={1}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Chip label={p.data.getDeployment().getVersion()}/>
                        </Grid>
                        <Grid item>
                            <Chip label={getHostEnvDisplayName(p.data.getDeployment().getHostEnvironment().getValue())}/>
                        </Grid>
                        <Grid item>
                            {renderDeploymentStatusChip(p.data.getDeployment().getConnected())}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box>
                <Button variant={'outlined'} onClick={goToDetails}>View Details</Button>
            </Box>
        </Box>
        <Divider/>
        <Box>
            <ListSubheader>CDM Policies</ListSubheader>
            <Box display={'flex'}>
{/*                <ListItem>
                    <ListItemText primary={1} secondary={`Active Policies`}/>
                </ListItem>*/}
                <ListItem>
                    <ListItemText primary={p.data.getTotalSnapshot()} secondary={`Total Snapshots`}/>
                </ListItem>
                <ListItem>
                    <ListItemText primary={formatKnownDataType(p.data.getTotalStorageUsed(), KnownDataType.CAPACITY)}
                                  secondary={`Total Capacity Used`}/>
                </ListItem>
            </Box>
            <Divider/>
            <Box>
                <ListSubheader>Phoenix (Local) Host</ListSubheader>
                <Box display={'flex'}>
                    <ListItem>
                        <ListItemText primary={2} secondary={`Hosts`}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={p.data.getTotalVolumes()} secondary={`Volumes`}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={formatKnownDataType(p.data.getTotalData(), KnownDataType.CAPACITY)}
                                      secondary={`Provisioned Capacity`}/>
                    </ListItem>
                </Box>

            </Box>
            <Divider/>
            <Box>
                <ListSubheader>mTDI Connection</ListSubheader>
                <Box display={'flex'}>
                    <ListItem>
                        <ListItemText primary={p.data.getMtdiPaired()} secondary={`mTDI Paired`}/>
                    </ListItem>
                </Box>

            </Box>
        </Box>
    </Card>
});

// ======================
// RegisterNewDeploymentButton
// ======================

interface RegisterNewPhoenixDeploymentButtonProps {
}

const RegisterNewPhoenixDeploymentButton: React.FC<RegisterNewPhoenixDeploymentButtonProps> = observer((p) => {
    const phoenixDeploymentDialogState = useDialogState();

    return <><Button variant={'contained'} color={'secondary'} onClick={phoenixDeploymentDialogState.open}>
        Register Protection Target
    </Button>
        <RegisterNewDeploymentDialog dialogState={phoenixDeploymentDialogState}/>
    </>
})

// ======================
// RegisterNewDeploymentDialog
// ======================

interface RegisterNewDeploymentDialogProps {
    dialogState: DialogState;
}

const RegisterNewDeploymentDialog: React.FC<RegisterNewDeploymentDialogProps> = observer((p) => {
    const registrationCode = useCurrentProject().getInfo().getSystemRegistrationCode();

    const newRegisterId = 'registerPhoenixServerCommand';
    const styles = usePhoenixDeploymentStyles();

    return <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} fullWidth maxWidth={'md'}>
        <DialogTopBar dialogState={p.dialogState} title={'Register Phoenix Server'}/>
        <Divider/>
        <DialogContent>
            <DialogContentText variant={'h4'} color={'textPrimary'}>
                Registration Key
            </DialogContentText>
            <DialogContentText variant={'caption'} color={'textPrimary'}>
                Use the project key on a Phoenix Server to complete registration on this project:
            </DialogContentText>
            <br/>
            <Card sx={styles.command}>
                <CardContent>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <ClipboardText clipboardId={newRegisterId}>
                            {registrationCode}
                        </ClipboardText>
                        <ClipboardButton clipboardId={newRegisterId} iconButton/>
                    </Box>
                </CardContent>
            </Card>
        </DialogContent>
    </Dialog>
})