/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: DataLoaderHooks
 */

import * as React from 'react';
import {Poller, startPolling, stopPolling} from '../../../common/poller/Poller';
import {useEffectOnce} from 'react-use';
import {ServerData} from './ServerData';
import {LoadingBox, LoadingListItems} from '../../../common/progress/LoadingIndicators';
import {Box, Theme} from "@mui/material";
import {observer} from "mobx-react-lite";
import {Alert, AlertTitle} from "@mui/material";
import {RefreshButton} from "../../../common/CommonIcons";

export interface UseInitDataConfig<T = any> {
    init?: Function;
    deinit?: Function;
    poll?: () => Promise<T | void>;
    pollInterval?: number;
}

export const useInitData = <T extends {}>(config: UseInitDataConfig<T>) => {
    return useEffectOnce(() => {
        // init
        if (typeof config.init === 'function') {
            config.init();
        }

        let poller: Poller;
        if (typeof config.poll === 'function') {
            poller = startPolling(config.poll, config.pollInterval || 1);
        }

        return () => {
            // deinit
            stopPolling(poller);
            if (typeof config.deinit === 'function') {
                config.deinit();
            }
        };
    });
};


export type UseServerDataRenderFunction<T> = (data: T) => React.ReactElement;
export type UseServerDataConfig<T> = {
    data: ServerData<T>;
    resetServerDataOnExit?: boolean;
} | ServerData<T> ;

// make a non hook version so it can be conditional
export const renderServerDataWhenReady = <T extends any>(config: UseServerDataConfig<T>,
                                                         renderLoaderOrLoader: React.ReactElement | (() => React.ReactElement),
                                                         renderReady: UseServerDataRenderFunction<T>): React.ReactElement => {
    const serverData = config instanceof ServerData ? config : config.data;
    const resetServerDataOnExit = config instanceof ServerData ? false : config.resetServerDataOnExit;

    if (resetServerDataOnExit) {
        throw new Error('reset server data on exit is not supported, use hook version instead');
    }

    if (serverData.inError) {
        return <ServerErrorCard serverData={serverData}/>
    }

    if (!serverData.ready) {
        return renderLoaderOrLoader && React.isValidElement(renderLoaderOrLoader) ? renderLoaderOrLoader :
            typeof renderLoaderOrLoader === 'function' ? renderLoaderOrLoader() : null;
    } else {
        return renderReady(serverData.data);
    }
};

export const useServerData = <T extends any>(config: UseServerDataConfig<T>,
                                             renderLoaderOrLoader: React.ReactElement | (() => React.ReactElement),
                                             renderReady: UseServerDataRenderFunction<T>): React.ReactElement => {
    const serverData = config instanceof ServerData ? config : config.data;
    const resetServerDataOnExit = config instanceof ServerData ? false : config.resetServerDataOnExit;
    useEffectOnce(() => {
        return () => {
            if (resetServerDataOnExit) {
                serverData.resetData();
            }
        };
    });

    return renderServerDataWhenReady(config, renderLoaderOrLoader, renderReady);

};


export const useServerDataWithLoadingList = <T extends any>(config: UseServerDataConfig<T>,
                                                            renderReady: UseServerDataRenderFunction<T>) => {
    return useServerData(config, <LoadingListItems count={2}/>, renderReady);
};

export const useServerDataWithLoadingBox = <T extends any>(config: UseServerDataConfig<T>,
                                                           renderReady: UseServerDataRenderFunction<T>) => {
    return useServerData(config, <LoadingBox/>, renderReady);
};


export const renderServerDataWithLoadingList = <T extends any>(serverData: ServerData<T>,
                                                               renderReady: UseServerDataRenderFunction<T>) => {
    return renderServerDataWhenReady(serverData, <LoadingListItems count={2}/>, renderReady);
};

export const renderServerDataWithLoadingBox = <T extends any>(serverData: ServerData<T>,
                                                              renderReady: UseServerDataRenderFunction<T>) => {
    return renderServerDataWhenReady(serverData, <LoadingBox/>, renderReady);
};


// ======================
// ServerErrorCard
// ======================

interface ServerErrorCardProps<T> {
    serverData: ServerData<T>,
}

export const ServerErrorCard: React.FC<ServerErrorCardProps<any>> = observer((props) => {
    const {serverData } = props;

    const refresh = async () => {
        serverData.resetData();
        await serverData.fetchData()
    }

    const refreshButton = <Box pr={2}>
        <RefreshButton onClick={refresh} variant={'outlined'} color={'inherit'}/>
    </Box>

    return <Box width={'100%'} p={2}>
        <Alert severity={'error'} action={refreshButton}>
        <AlertTitle>Error Encountered</AlertTitle>
        <Box display={'flex'} justifyContent={'space-between'}>
            <Box>
                {serverData.error.message}
            </Box>
        </Box>
        </Alert>
    </Box>
})