import { observer } from "mobx-react-lite";
import * as React from "react";
import { useState } from "react";
import { GmMigrationWizardVolumeState } from "../../../GmMigrationService";
import { Box, Button, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { VendorAllocateVolumesStepProps } from "../../GmAutoAllocationCommon";
import { useEffectOnce } from "react-use";
import { AutoAlloc } from "../../../../../_proto/galaxymigratepb/galaxy_migrate_autoalloc_pb";
import { ColumnDef } from "../../../../../common/table/DataTable";
import { formatKnownDataType, KnownDataType } from "../../../../../common/utils/formatter";
import { SimpleTable } from "../../../../../common/table/SimpleTable";
import { AzureCalculatedDiskAssignmentConfig, DiskAssignmentConfig, getDiskParamsEnabled, getDiskSizeInGiB } from "./AzureIntegrationHelpers";
import produce from "immer";
import { AzureRecommendationAlert } from "./AzureRecommendationWizard";

export const AzureAllocateVolumesStep: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {
    const state = p.state;
    const allocateNow = p.allocateFunc;

    useEffectOnce(() => {
        for (let device of state.sourceDevices) {
            device.autoAllocParams.setAzure(new AutoAlloc.VolumeParams.Azure().setStorageType(AutoAlloc.VolumeParams.Azure.StorageType.PREMIUM));
        }
    });

    const getInitalDiskAssignments = () => {
        const assignments: { [key: string]: DiskAssignmentConfig } = {};
        state.sourceDevices.forEach((device) => {
            assignments[device.source.getBlockDevice().getDeviceName()] = {
                storageType: AutoAlloc.VolumeParams.Azure.StorageType.PREMIUM,
                iops: 300,
                throughput: 125,
                capacity: getDiskSizeInGiB(device.source.getBlockDevice().getCapacity()),
            };
        });
        return assignments;
    };

    const [diskConfigs, setDiskConfigs] = useState<{ [key: string]: DiskAssignmentConfig }>(getInitalDiskAssignments());

    const setDiskCapacity = (diskName: string, capacity: number) => {
        setDiskConfigs(
            produce((draft) => {
                if (capacity > 0) {
                    draft[diskName].capacity = capacity;
                } else {
                    draft[diskName].capacity = null;
                }
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === diskName);
        sourceDeviceInAutoAllocState.autoAllocVolumeCapacity = capacity;
    };

    const setDiskIops = (diskName: string, iops: number) => {
        setDiskConfigs(
            produce((draft) => {
                if (iops > 0) {
                    draft[diskName].iops = iops;
                } else {
                    draft[diskName].iops = null;
                }
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === diskName);
        sourceDeviceInAutoAllocState.autoAllocParams.getAzure().setIops(iops);
    };

    const setDiskThroughput = (diskName: string, throughput: number) => {
        setDiskConfigs(
            produce((draft) => {
                if (throughput > 0) {
                    draft[diskName].throughput = throughput;
                } else {
                    draft[diskName].throughput = null;
                }
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === diskName);
        sourceDeviceInAutoAllocState.autoAllocParams.getAzure().setThroughput(throughput);
    };

    const setDiskStorageType = (diskName: string, storageType: AutoAlloc.VolumeParams.Azure.StorageType) => {
        setDiskConfigs(
            produce((draft) => {
                draft[diskName].storageType = storageType;
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === diskName);
        sourceDeviceInAutoAllocState.autoAllocParams.getAzure().setStorageType(storageType);
    };

    const handleConfirmAzureRecSelection = (deviceAssignments: { [key: string]: AzureCalculatedDiskAssignmentConfig }) => {
        const newDiskConfigs: { [key: string]: DiskAssignmentConfig } = {};
        for (let key in deviceAssignments) {
            newDiskConfigs[key] = {
                capacity: deviceAssignments[key].capacity,
                storageType: deviceAssignments[key].product.storageType,
                throughput: deviceAssignments[key].throughput,
                iops: deviceAssignments[key].iops,
            };
            const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === key);
            sourceDeviceInAutoAllocState.autoAllocParams.getAzure()?.setStorageType(deviceAssignments[key].product.storageType);
            sourceDeviceInAutoAllocState.autoAllocVolumeCapacity = deviceAssignments[key].capacity;
        }
        setDiskConfigs(newDiskConfigs);
    };

    const cols: ColumnDef<GmMigrationWizardVolumeState>[] = [
        {
            id: "source",
            label: "Source Volume",
            getter: (d) => `${d.source.getBlockDevice().getDeviceName()} (${d.source.getBlockDevice().getDeviceType()})`,
        },
        {
            id: "capacity",
            label: "Capacity",
            getter: (d) => d.source.getBlockDevice().getCapacity(),
            renderer: (v, r) => {
                const diskConfig = diskConfigs[r.source.getBlockDevice().getDeviceName()];
                const error = diskConfig.capacity < getDiskSizeInGiB(r.source.getBlockDevice().getCapacity());
                return (
                    <TextField
                        value={diskConfig.capacity}
                        type={"number"}
                        variant={"outlined"}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">GiB</InputAdornment>,
                        }}
                        error={error}
                        helperText={error ? `Must be at least ${formatKnownDataType(r.source.getBlockDevice().getCapacity(), KnownDataType.CAPACITY)}` : ""}
                        onChange={(e) => {
                            setDiskCapacity(r.source.getBlockDevice().getDeviceName(), Number(e.target.value));
                        }}
                    />
                );
            },
        },
        {
            id: "class",
            label: "New Disk Class",
            getter: (d) => null,
            renderer: (v, r) => {
                const onSelect = (e: SelectChangeEvent<AutoAlloc.VolumeParams.Azure.StorageType>) => {
                    setDiskStorageType(r.source.getBlockDevice().getDeviceName(), e.target.value as AutoAlloc.VolumeParams.Azure.StorageType);
                };
                return (
                    <Select
                        defaultValue={AutoAlloc.VolumeParams.Azure.StorageType.PREMIUM}
                        value={diskConfigs[r.source.getBlockDevice().getDeviceName()].storageType}
                        variant={"outlined"}
                        onChange={onSelect}
                    >
                        <MenuItem value={AutoAlloc.VolumeParams.Azure.StorageType.ULTRA_SSD}>{"Ultra Disk Storage"}</MenuItem>
                        <MenuItem value={AutoAlloc.VolumeParams.Azure.StorageType.PREMIUM_SSD_V2}>{"Premium SSD v2"}</MenuItem>
                        <MenuItem value={AutoAlloc.VolumeParams.Azure.StorageType.PREMIUM}>{"Premium SSD"}</MenuItem>
                        <MenuItem value={AutoAlloc.VolumeParams.Azure.StorageType.STANDARD_SSD}>{"Standard SSD"}</MenuItem>
                        <MenuItem value={AutoAlloc.VolumeParams.Azure.StorageType.STANDARD}>{"Standard HDD"}</MenuItem>
                    </Select>
                );
            },
        },
        {
            id: "iops",
            label: "Disk IOPS",
            getter: (d) => null,
            renderer: (v, r) => {
                const diskConfig = diskConfigs[r.source.getBlockDevice().getDeviceName()];
                return (
                    <TextField
                        value={diskConfig.iops}
                        type={"number"}
                        variant={"outlined"}
                        onChange={(e) => {
                            setDiskIops(r.source.getBlockDevice().getDeviceName(), Number(e.target.value));
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">IOPS</InputAdornment>,
                        }}
                        disabled={!getDiskParamsEnabled(r.autoAllocParams.getAzure()?.getStorageType())}
                    />
                );
            },
        },
        {
            id: "throughput",
            label: "Disk Throughput (MB/s)",
            getter: (d) => null,
            renderer: (v, r) => {
                const diskConfig = diskConfigs[r.source.getBlockDevice().getDeviceName()];
                return (
                    <TextField
                        value={diskConfig.throughput}
                        type={"number"}
                        variant={"outlined"}
                        onChange={(e) => {
                            setDiskThroughput(r.source.getBlockDevice().getDeviceName(), Number(e.target.value));
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">MB/s</InputAdornment>,
                        }}
                        disabled={!getDiskParamsEnabled(r.autoAllocParams.getAzure()?.getStorageType())}
                    />
                );
            },
        },
        {
            id: "encryption",
            label: "At-Rest Encryption",
            getter: (d) => null,
            renderer: (v, r) => {
                return (
                    <Select value={1} variant={"outlined"}>
                        <MenuItem value={1}>{"Platform-Managed Key"}</MenuItem>
                    </Select>
                );
            },
        },
    ];

    return (
        <>
            <Box pt={1} pb={1}>
                <AzureRecommendationAlert autoAllocState={state} handleConfirmAzureSelection={handleConfirmAzureRecSelection} />
            </Box>
            <Typography color={"textSecondary"}>
                {`Azure Data Disks matching the following source volumes will be created using the specified parameters and attached to the destination host`}
            </Typography>
            <br />
            <SimpleTable rows={state.sourceDevices} rowIdGetter={(r) => r.source.getBlockDevice().getDeviceName()} cols={cols} />
            <Box pt={2} pb={2}>
                <Button color={"primary"} variant={"contained"} onClick={allocateNow}>
                    {`Allocate Volumes (${state.sourceDevices.length})`}
                </Button>
            </Box>
        </>
    );
});
