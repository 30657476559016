// source: galaxycompletepb/apipb/domainpb/license.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var galaxycompletepb_apipb_domainpb_enumpb_license_vault_transaction_status_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/license_vault_transaction_status_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_license_vault_transaction_status_pb);
var galaxycompletepb_apipb_domainpb_enumpb_license_vault_transaction_type_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/license_vault_transaction_type_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_license_vault_transaction_type_pb);
var galaxycompletepb_apipb_domainpb_enumpb_license_vault_counter_type_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_license_vault_counter_type_pb);
var galaxycompletepb_apipb_domainpb_project_pb = require('../../../galaxycompletepb/apipb/domainpb/project_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_project_pb);
var galaxycompletepb_apipb_domainpb_user_pb = require('../../../galaxycompletepb/apipb/domainpb/user_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_user_pb);
var validate_validate_pb = require('../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.galaxycomplete.domain.HostLicenseInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.HostLicenseTxInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.LicenseStorePurchasableProduct', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.RedemptionCode', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.RedemptionCodeContent', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.RedemptionCodeContent.ContentCase', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.RedemptionCodeDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.RedemptionCodeIdentityInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.RedemptionDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.TransactionCounterInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.TransactionDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.VaultCounterInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.VaultDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.VaultIdentityInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.VaultIdentityInfo.OwnerCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.VaultIdentityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.galaxycomplete.domain.VaultIdentityInfo.oneofGroups_);
};
goog.inherits(proto.galaxycomplete.domain.VaultIdentityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.VaultIdentityInfo.displayName = 'proto.galaxycomplete.domain.VaultIdentityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.VaultDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.VaultDetails.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.VaultDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.VaultDetails.displayName = 'proto.galaxycomplete.domain.VaultDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.VaultCounterInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.VaultCounterInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.VaultCounterInfo.displayName = 'proto.galaxycomplete.domain.VaultCounterInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.TransactionDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.TransactionDetails.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.TransactionDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.TransactionDetails.displayName = 'proto.galaxycomplete.domain.TransactionDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.TransactionCounterInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.TransactionCounterInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.TransactionCounterInfo.displayName = 'proto.galaxycomplete.domain.TransactionCounterInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.LicenseStorePurchasableProduct.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.LicenseStorePurchasableProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.LicenseStorePurchasableProduct.displayName = 'proto.galaxycomplete.domain.LicenseStorePurchasableProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.HostLicenseInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.HostLicenseInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.HostLicenseInfo.displayName = 'proto.galaxycomplete.domain.HostLicenseInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.HostLicenseTxInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.HostLicenseTxInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.HostLicenseTxInfo.displayName = 'proto.galaxycomplete.domain.HostLicenseTxInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.RedemptionCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.RedemptionCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.RedemptionCode.displayName = 'proto.galaxycomplete.domain.RedemptionCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.RedemptionCodeContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.galaxycomplete.domain.RedemptionCodeContent.oneofGroups_);
};
goog.inherits(proto.galaxycomplete.domain.RedemptionCodeContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.RedemptionCodeContent.displayName = 'proto.galaxycomplete.domain.RedemptionCodeContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.displayName = 'proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.RedemptionCodeDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.RedemptionCodeDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.RedemptionCodeDetails.displayName = 'proto.galaxycomplete.domain.RedemptionCodeDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.RedemptionCodeIdentityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.RedemptionCodeIdentityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.displayName = 'proto.galaxycomplete.domain.RedemptionCodeIdentityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.RedemptionDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.RedemptionDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.RedemptionDetails.displayName = 'proto.galaxycomplete.domain.RedemptionDetails';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.galaxycomplete.domain.VaultIdentityInfo.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.galaxycomplete.domain.VaultIdentityInfo.OwnerCase = {
  OWNER_NOT_SET: 0,
  PROJECT: 2,
  USER: 3
};

/**
 * @return {proto.galaxycomplete.domain.VaultIdentityInfo.OwnerCase}
 */
proto.galaxycomplete.domain.VaultIdentityInfo.prototype.getOwnerCase = function() {
  return /** @type {proto.galaxycomplete.domain.VaultIdentityInfo.OwnerCase} */(jspb.Message.computeOneofCase(this, proto.galaxycomplete.domain.VaultIdentityInfo.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.VaultIdentityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.VaultIdentityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.VaultIdentityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.VaultIdentityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    vaultSerial: jspb.Message.getFieldWithDefault(msg, 1, ""),
    project: (f = msg.getProject()) && galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo.toObject(includeInstance, f),
    user: (f = msg.getUser()) && galaxycompletepb_apipb_domainpb_user_pb.HubUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.VaultIdentityInfo}
 */
proto.galaxycomplete.domain.VaultIdentityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.VaultIdentityInfo;
  return proto.galaxycomplete.domain.VaultIdentityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.VaultIdentityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.VaultIdentityInfo}
 */
proto.galaxycomplete.domain.VaultIdentityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVaultSerial(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new galaxycompletepb_apipb_domainpb_user_pb.HubUser;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_user_pb.HubUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.VaultIdentityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.VaultIdentityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.VaultIdentityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.VaultIdentityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVaultSerial();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      galaxycompletepb_apipb_domainpb_user_pb.HubUser.serializeBinaryToWriter
    );
  }
};


/**
 * optional string vault_serial = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.VaultIdentityInfo.prototype.getVaultSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.VaultIdentityInfo} returns this
 */
proto.galaxycomplete.domain.VaultIdentityInfo.prototype.setVaultSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ProjectIdentityInfo project = 2;
 * @return {?proto.galaxycomplete.domain.ProjectIdentityInfo}
 */
proto.galaxycomplete.domain.VaultIdentityInfo.prototype.getProject = function() {
  return /** @type{?proto.galaxycomplete.domain.ProjectIdentityInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.ProjectIdentityInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.VaultIdentityInfo} returns this
*/
proto.galaxycomplete.domain.VaultIdentityInfo.prototype.setProject = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.galaxycomplete.domain.VaultIdentityInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.VaultIdentityInfo} returns this
 */
proto.galaxycomplete.domain.VaultIdentityInfo.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.VaultIdentityInfo.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional HubUser user = 3;
 * @return {?proto.galaxycomplete.domain.HubUser}
 */
proto.galaxycomplete.domain.VaultIdentityInfo.prototype.getUser = function() {
  return /** @type{?proto.galaxycomplete.domain.HubUser} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_user_pb.HubUser, 3));
};


/**
 * @param {?proto.galaxycomplete.domain.HubUser|undefined} value
 * @return {!proto.galaxycomplete.domain.VaultIdentityInfo} returns this
*/
proto.galaxycomplete.domain.VaultIdentityInfo.prototype.setUser = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.galaxycomplete.domain.VaultIdentityInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.VaultIdentityInfo} returns this
 */
proto.galaxycomplete.domain.VaultIdentityInfo.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.VaultIdentityInfo.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.VaultDetails.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.VaultDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.VaultDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.VaultDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.VaultDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.galaxycomplete.domain.VaultCounterInfo.toObject, includeInstance),
    identity: (f = msg.getIdentity()) && proto.galaxycomplete.domain.VaultIdentityInfo.toObject(includeInstance, f),
    frozen: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.VaultDetails}
 */
proto.galaxycomplete.domain.VaultDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.VaultDetails;
  return proto.galaxycomplete.domain.VaultDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.VaultDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.VaultDetails}
 */
proto.galaxycomplete.domain.VaultDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.galaxycomplete.domain.VaultCounterInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.VaultCounterInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 3:
      var value = new proto.galaxycomplete.domain.VaultIdentityInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.VaultIdentityInfo.deserializeBinaryFromReader);
      msg.setIdentity(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFrozen(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.VaultDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.VaultDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.VaultDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.VaultDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.galaxycomplete.domain.VaultCounterInfo.serializeBinaryToWriter
    );
  }
  f = message.getIdentity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.galaxycomplete.domain.VaultIdentityInfo.serializeBinaryToWriter
    );
  }
  f = message.getFrozen();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * repeated VaultCounterInfo items = 2;
 * @return {!Array<!proto.galaxycomplete.domain.VaultCounterInfo>}
 */
proto.galaxycomplete.domain.VaultDetails.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.VaultCounterInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.VaultCounterInfo, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.VaultCounterInfo>} value
 * @return {!proto.galaxycomplete.domain.VaultDetails} returns this
*/
proto.galaxycomplete.domain.VaultDetails.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.VaultCounterInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo}
 */
proto.galaxycomplete.domain.VaultDetails.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.VaultCounterInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.VaultDetails} returns this
 */
proto.galaxycomplete.domain.VaultDetails.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional VaultIdentityInfo identity = 3;
 * @return {?proto.galaxycomplete.domain.VaultIdentityInfo}
 */
proto.galaxycomplete.domain.VaultDetails.prototype.getIdentity = function() {
  return /** @type{?proto.galaxycomplete.domain.VaultIdentityInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.VaultIdentityInfo, 3));
};


/**
 * @param {?proto.galaxycomplete.domain.VaultIdentityInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.VaultDetails} returns this
*/
proto.galaxycomplete.domain.VaultDetails.prototype.setIdentity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.VaultDetails} returns this
 */
proto.galaxycomplete.domain.VaultDetails.prototype.clearIdentity = function() {
  return this.setIdentity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.VaultDetails.prototype.hasIdentity = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool frozen = 4;
 * @return {boolean}
 */
proto.galaxycomplete.domain.VaultDetails.prototype.getFrozen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.VaultDetails} returns this
 */
proto.galaxycomplete.domain.VaultDetails.prototype.setFrozen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.VaultCounterInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.VaultCounterInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.VaultCounterInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    counterId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    counterType: (f = msg.getCounterType()) && galaxycompletepb_apipb_domainpb_enumpb_license_vault_counter_type_pb.LicenseVaultCounterType.toObject(includeInstance, f),
    currentBalance: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalConsumed: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalCredited: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalDebited: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lastConsumed: (f = msg.getLastConsumed()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastCredited: (f = msg.getLastCredited()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastDebited: (f = msg.getLastDebited()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo}
 */
proto.galaxycomplete.domain.VaultCounterInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.VaultCounterInfo;
  return proto.galaxycomplete.domain.VaultCounterInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.VaultCounterInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo}
 */
proto.galaxycomplete.domain.VaultCounterInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCounterId(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_enumpb_license_vault_counter_type_pb.LicenseVaultCounterType;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_enumpb_license_vault_counter_type_pb.LicenseVaultCounterType.deserializeBinaryFromReader);
      msg.setCounterType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentBalance(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalConsumed(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCredited(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalDebited(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastConsumed(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastCredited(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastDebited(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.VaultCounterInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.VaultCounterInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.VaultCounterInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCounterId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCounterType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_enumpb_license_vault_counter_type_pb.LicenseVaultCounterType.serializeBinaryToWriter
    );
  }
  f = message.getCurrentBalance();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTotalConsumed();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTotalCredited();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTotalDebited();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getLastConsumed();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastCredited();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastDebited();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 counter_id = 1;
 * @return {number}
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.getCounterId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo} returns this
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.setCounterId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional enums.LicenseVaultCounterType counter_type = 2;
 * @return {?proto.galaxycomplete.domain.enums.LicenseVaultCounterType}
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.getCounterType = function() {
  return /** @type{?proto.galaxycomplete.domain.enums.LicenseVaultCounterType} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_enumpb_license_vault_counter_type_pb.LicenseVaultCounterType, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.enums.LicenseVaultCounterType|undefined} value
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo} returns this
*/
proto.galaxycomplete.domain.VaultCounterInfo.prototype.setCounterType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo} returns this
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.clearCounterType = function() {
  return this.setCounterType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.hasCounterType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 current_balance = 3;
 * @return {number}
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.getCurrentBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo} returns this
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.setCurrentBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 total_consumed = 4;
 * @return {number}
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.getTotalConsumed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo} returns this
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.setTotalConsumed = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 total_credited = 5;
 * @return {number}
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.getTotalCredited = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo} returns this
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.setTotalCredited = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 total_debited = 6;
 * @return {number}
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.getTotalDebited = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo} returns this
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.setTotalDebited = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp last_consumed = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.getLastConsumed = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo} returns this
*/
proto.galaxycomplete.domain.VaultCounterInfo.prototype.setLastConsumed = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo} returns this
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.clearLastConsumed = function() {
  return this.setLastConsumed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.hasLastConsumed = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp last_credited = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.getLastCredited = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo} returns this
*/
proto.galaxycomplete.domain.VaultCounterInfo.prototype.setLastCredited = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo} returns this
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.clearLastCredited = function() {
  return this.setLastCredited(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.hasLastCredited = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp last_debited = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.getLastDebited = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo} returns this
*/
proto.galaxycomplete.domain.VaultCounterInfo.prototype.setLastDebited = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.VaultCounterInfo} returns this
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.clearLastDebited = function() {
  return this.setLastDebited(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.VaultCounterInfo.prototype.hasLastDebited = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.TransactionDetails.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.TransactionDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.TransactionDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.TransactionDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdByUser: (f = msg.getCreatedByUser()) && galaxycompletepb_apipb_domainpb_user_pb.HubUser.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fromVault: (f = msg.getFromVault()) && proto.galaxycomplete.domain.VaultIdentityInfo.toObject(includeInstance, f),
    toVault: (f = msg.getToVault()) && proto.galaxycomplete.domain.VaultIdentityInfo.toObject(includeInstance, f),
    notes: jspb.Message.getFieldWithDefault(msg, 7, ""),
    status: (f = msg.getStatus()) && galaxycompletepb_apipb_domainpb_enumpb_license_vault_transaction_status_pb.LicenseVaultTransactionStatus.toObject(includeInstance, f),
    type: (f = msg.getType()) && galaxycompletepb_apipb_domainpb_enumpb_license_vault_transaction_type_pb.LicenseVaultTransactionType.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.galaxycomplete.domain.TransactionCounterInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.TransactionDetails}
 */
proto.galaxycomplete.domain.TransactionDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.TransactionDetails;
  return proto.galaxycomplete.domain.TransactionDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.TransactionDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.TransactionDetails}
 */
proto.galaxycomplete.domain.TransactionDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new galaxycompletepb_apipb_domainpb_user_pb.HubUser;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_user_pb.HubUser.deserializeBinaryFromReader);
      msg.setCreatedByUser(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = new proto.galaxycomplete.domain.VaultIdentityInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.VaultIdentityInfo.deserializeBinaryFromReader);
      msg.setFromVault(value);
      break;
    case 6:
      var value = new proto.galaxycomplete.domain.VaultIdentityInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.VaultIdentityInfo.deserializeBinaryFromReader);
      msg.setToVault(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 8:
      var value = new galaxycompletepb_apipb_domainpb_enumpb_license_vault_transaction_status_pb.LicenseVaultTransactionStatus;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_enumpb_license_vault_transaction_status_pb.LicenseVaultTransactionStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 9:
      var value = new galaxycompletepb_apipb_domainpb_enumpb_license_vault_transaction_type_pb.LicenseVaultTransactionType;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_enumpb_license_vault_transaction_type_pb.LicenseVaultTransactionType.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 10:
      var value = new proto.galaxycomplete.domain.TransactionCounterInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.TransactionCounterInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.TransactionDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.TransactionDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.TransactionDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedByUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      galaxycompletepb_apipb_domainpb_user_pb.HubUser.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFromVault();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.galaxycomplete.domain.VaultIdentityInfo.serializeBinaryToWriter
    );
  }
  f = message.getToVault();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.galaxycomplete.domain.VaultIdentityInfo.serializeBinaryToWriter
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      galaxycompletepb_apipb_domainpb_enumpb_license_vault_transaction_status_pb.LicenseVaultTransactionStatus.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      galaxycompletepb_apipb_domainpb_enumpb_license_vault_transaction_type_pb.LicenseVaultTransactionType.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.galaxycomplete.domain.TransactionCounterInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string transaction_id = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.getTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
*/
proto.galaxycomplete.domain.TransactionDetails.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional HubUser created_by_user = 3;
 * @return {?proto.galaxycomplete.domain.HubUser}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.getCreatedByUser = function() {
  return /** @type{?proto.galaxycomplete.domain.HubUser} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_user_pb.HubUser, 3));
};


/**
 * @param {?proto.galaxycomplete.domain.HubUser|undefined} value
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
*/
proto.galaxycomplete.domain.TransactionDetails.prototype.setCreatedByUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.clearCreatedByUser = function() {
  return this.setCreatedByUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.hasCreatedByUser = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional VaultIdentityInfo from_vault = 5;
 * @return {?proto.galaxycomplete.domain.VaultIdentityInfo}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.getFromVault = function() {
  return /** @type{?proto.galaxycomplete.domain.VaultIdentityInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.VaultIdentityInfo, 5));
};


/**
 * @param {?proto.galaxycomplete.domain.VaultIdentityInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
*/
proto.galaxycomplete.domain.TransactionDetails.prototype.setFromVault = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.clearFromVault = function() {
  return this.setFromVault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.hasFromVault = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional VaultIdentityInfo to_vault = 6;
 * @return {?proto.galaxycomplete.domain.VaultIdentityInfo}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.getToVault = function() {
  return /** @type{?proto.galaxycomplete.domain.VaultIdentityInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.VaultIdentityInfo, 6));
};


/**
 * @param {?proto.galaxycomplete.domain.VaultIdentityInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
*/
proto.galaxycomplete.domain.TransactionDetails.prototype.setToVault = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.clearToVault = function() {
  return this.setToVault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.hasToVault = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string notes = 7;
 * @return {string}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional enums.LicenseVaultTransactionStatus status = 8;
 * @return {?proto.galaxycomplete.domain.enums.LicenseVaultTransactionStatus}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.getStatus = function() {
  return /** @type{?proto.galaxycomplete.domain.enums.LicenseVaultTransactionStatus} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_enumpb_license_vault_transaction_status_pb.LicenseVaultTransactionStatus, 8));
};


/**
 * @param {?proto.galaxycomplete.domain.enums.LicenseVaultTransactionStatus|undefined} value
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
*/
proto.galaxycomplete.domain.TransactionDetails.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional enums.LicenseVaultTransactionType type = 9;
 * @return {?proto.galaxycomplete.domain.enums.LicenseVaultTransactionType}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.getType = function() {
  return /** @type{?proto.galaxycomplete.domain.enums.LicenseVaultTransactionType} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_enumpb_license_vault_transaction_type_pb.LicenseVaultTransactionType, 9));
};


/**
 * @param {?proto.galaxycomplete.domain.enums.LicenseVaultTransactionType|undefined} value
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
*/
proto.galaxycomplete.domain.TransactionDetails.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.hasType = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated TransactionCounterInfo items = 10;
 * @return {!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.TransactionCounterInfo, 10));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>} value
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
*/
proto.galaxycomplete.domain.TransactionDetails.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.galaxycomplete.domain.TransactionCounterInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.TransactionCounterInfo}
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.galaxycomplete.domain.TransactionCounterInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.TransactionDetails} returns this
 */
proto.galaxycomplete.domain.TransactionDetails.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.TransactionCounterInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.TransactionCounterInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.TransactionCounterInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.TransactionCounterInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    counterType: (f = msg.getCounterType()) && galaxycompletepb_apipb_domainpb_enumpb_license_vault_counter_type_pb.LicenseVaultCounterType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.TransactionCounterInfo}
 */
proto.galaxycomplete.domain.TransactionCounterInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.TransactionCounterInfo;
  return proto.galaxycomplete.domain.TransactionCounterInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.TransactionCounterInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.TransactionCounterInfo}
 */
proto.galaxycomplete.domain.TransactionCounterInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_enumpb_license_vault_counter_type_pb.LicenseVaultCounterType;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_enumpb_license_vault_counter_type_pb.LicenseVaultCounterType.deserializeBinaryFromReader);
      msg.setCounterType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.TransactionCounterInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.TransactionCounterInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.TransactionCounterInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.TransactionCounterInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCounterType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_enumpb_license_vault_counter_type_pb.LicenseVaultCounterType.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 amount = 1;
 * @return {number}
 */
proto.galaxycomplete.domain.TransactionCounterInfo.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.TransactionCounterInfo} returns this
 */
proto.galaxycomplete.domain.TransactionCounterInfo.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional enums.LicenseVaultCounterType counter_type = 2;
 * @return {?proto.galaxycomplete.domain.enums.LicenseVaultCounterType}
 */
proto.galaxycomplete.domain.TransactionCounterInfo.prototype.getCounterType = function() {
  return /** @type{?proto.galaxycomplete.domain.enums.LicenseVaultCounterType} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_enumpb_license_vault_counter_type_pb.LicenseVaultCounterType, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.enums.LicenseVaultCounterType|undefined} value
 * @return {!proto.galaxycomplete.domain.TransactionCounterInfo} returns this
*/
proto.galaxycomplete.domain.TransactionCounterInfo.prototype.setCounterType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.TransactionCounterInfo} returns this
 */
proto.galaxycomplete.domain.TransactionCounterInfo.prototype.clearCounterType = function() {
  return this.setCounterType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.TransactionCounterInfo.prototype.hasCounterType = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.LicenseStorePurchasableProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    unit: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imageUrlsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    licenseCounterType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    licenseCounterAmountPerUnit: jspb.Message.getFieldWithDefault(msg, 11, 0),
    priceId: jspb.Message.getFieldWithDefault(msg, 31, ""),
    priceCents: jspb.Message.getFieldWithDefault(msg, 32, 0),
    priceCurrency: jspb.Message.getFieldWithDefault(msg, 33, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct}
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.LicenseStorePurchasableProduct;
  return proto.galaxycomplete.domain.LicenseStorePurchasableProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct}
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addImageUrls(value);
      break;
    case 10:
      var value = /** @type {!proto.galaxycomplete.domain.enums.LicenseVaultCounterType.LicenseVaultCounterType} */ (reader.readEnum());
      msg.setLicenseCounterType(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLicenseCounterAmountPerUnit(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceId(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPriceCents(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.LicenseStorePurchasableProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImageUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getLicenseCounterType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getLicenseCounterAmountPerUnit();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = message.getPriceId();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getPriceCents();
  if (f !== 0) {
    writer.writeUint64(
      32,
      f
    );
  }
  f = message.getPriceCurrency();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct} returns this
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct} returns this
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string unit = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct} returns this
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string image_urls = 4;
 * @return {!Array<string>}
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.getImageUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct} returns this
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.setImageUrlsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct} returns this
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.addImageUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct} returns this
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.clearImageUrlsList = function() {
  return this.setImageUrlsList([]);
};


/**
 * optional enums.LicenseVaultCounterType.LicenseVaultCounterType license_counter_type = 10;
 * @return {!proto.galaxycomplete.domain.enums.LicenseVaultCounterType.LicenseVaultCounterType}
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.getLicenseCounterType = function() {
  return /** @type {!proto.galaxycomplete.domain.enums.LicenseVaultCounterType.LicenseVaultCounterType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.enums.LicenseVaultCounterType.LicenseVaultCounterType} value
 * @return {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct} returns this
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.setLicenseCounterType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional uint64 license_counter_amount_per_unit = 11;
 * @return {number}
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.getLicenseCounterAmountPerUnit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct} returns this
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.setLicenseCounterAmountPerUnit = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string price_id = 31;
 * @return {string}
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.getPriceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct} returns this
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.setPriceId = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional uint64 price_cents = 32;
 * @return {number}
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.getPriceCents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct} returns this
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.setPriceCents = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional string price_currency = 33;
 * @return {string}
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.getPriceCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.LicenseStorePurchasableProduct} returns this
 */
proto.galaxycomplete.domain.LicenseStorePurchasableProduct.prototype.setPriceCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.HostLicenseInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.HostLicenseInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.HostLicenseInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    systemName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    projectName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    activatedAt: (f = msg.getActivatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expireAt: (f = msg.getExpireAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expired: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    timeRemaining: (f = msg.getTimeRemaining()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    migrationCapacityConsumed: jspb.Message.getFieldWithDefault(msg, 10, 0),
    migrationCapacityRemaining: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.HostLicenseInfo}
 */
proto.galaxycomplete.domain.HostLicenseInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.HostLicenseInfo;
  return proto.galaxycomplete.domain.HostLicenseInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.HostLicenseInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.HostLicenseInfo}
 */
proto.galaxycomplete.domain.HostLicenseInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setActivatedAt(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpireAt(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpired(value);
      break;
    case 8:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setTimeRemaining(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMigrationCapacityConsumed(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMigrationCapacityRemaining(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.HostLicenseInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.HostLicenseInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.HostLicenseInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSystemName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActivatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpireAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpired();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTimeRemaining();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getMigrationCapacityConsumed();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getMigrationCapacityRemaining();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.HostLicenseInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string system_name = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.getSystemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.HostLicenseInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.setSystemName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string project_id = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.HostLicenseInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string project_name = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.HostLicenseInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp activated_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.getActivatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.HostLicenseInfo} returns this
*/
proto.galaxycomplete.domain.HostLicenseInfo.prototype.setActivatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.HostLicenseInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.clearActivatedAt = function() {
  return this.setActivatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.hasActivatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp expire_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.getExpireAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.HostLicenseInfo} returns this
*/
proto.galaxycomplete.domain.HostLicenseInfo.prototype.setExpireAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.HostLicenseInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.clearExpireAt = function() {
  return this.setExpireAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.hasExpireAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool expired = 7;
 * @return {boolean}
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.getExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.HostLicenseInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.setExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.Duration time_remaining = 8;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.getTimeRemaining = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 8));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxycomplete.domain.HostLicenseInfo} returns this
*/
proto.galaxycomplete.domain.HostLicenseInfo.prototype.setTimeRemaining = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.HostLicenseInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.clearTimeRemaining = function() {
  return this.setTimeRemaining(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.hasTimeRemaining = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint64 migration_capacity_consumed = 10;
 * @return {number}
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.getMigrationCapacityConsumed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.HostLicenseInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.setMigrationCapacityConsumed = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint64 migration_capacity_remaining = 11;
 * @return {number}
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.getMigrationCapacityRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.HostLicenseInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseInfo.prototype.setMigrationCapacityRemaining = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.HostLicenseTxInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.HostLicenseTxInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    systemId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    migrationCapacityBefore: jspb.Message.getFieldWithDefault(msg, 10, 0),
    migrationCapacityChanged: jspb.Message.getFieldWithDefault(msg, 11, 0),
    migrationCapacityAfter: jspb.Message.getFieldWithDefault(msg, 12, 0),
    expirationBefore: (f = msg.getExpirationBefore()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expirationChanged: (f = msg.getExpirationChanged()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    expirationAfter: (f = msg.getExpirationAfter()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.HostLicenseTxInfo;
  return proto.galaxycomplete.domain.HostLicenseTxInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.HostLicenseTxInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {!proto.galaxycomplete.domain.enums.LicenseVaultTransactionType.LicenseVaultTransactionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMigrationCapacityBefore(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMigrationCapacityChanged(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMigrationCapacityAfter(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationBefore(value);
      break;
    case 21:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setExpirationChanged(value);
      break;
    case 22:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationAfter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.HostLicenseTxInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.HostLicenseTxInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMigrationCapacityBefore();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getMigrationCapacityChanged();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getMigrationCapacityAfter();
  if (f !== 0) {
    writer.writeUint64(
      12,
      f
    );
  }
  f = message.getExpirationBefore();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpirationChanged();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getExpirationAfter();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string system_id = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 user_id = 3;
 * @return {number}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional enums.LicenseVaultTransactionType.LicenseVaultTransactionType type = 4;
 * @return {!proto.galaxycomplete.domain.enums.LicenseVaultTransactionType.LicenseVaultTransactionType}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.getType = function() {
  return /** @type {!proto.galaxycomplete.domain.enums.LicenseVaultTransactionType.LicenseVaultTransactionType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.enums.LicenseVaultTransactionType.LicenseVaultTransactionType} value
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
*/
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint64 migration_capacity_before = 10;
 * @return {number}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.getMigrationCapacityBefore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.setMigrationCapacityBefore = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 migration_capacity_changed = 11;
 * @return {number}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.getMigrationCapacityChanged = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.setMigrationCapacityChanged = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint64 migration_capacity_after = 12;
 * @return {number}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.getMigrationCapacityAfter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.setMigrationCapacityAfter = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional google.protobuf.Timestamp expiration_before = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.getExpirationBefore = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
*/
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.setExpirationBefore = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.clearExpirationBefore = function() {
  return this.setExpirationBefore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.hasExpirationBefore = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.Duration expiration_changed = 21;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.getExpirationChanged = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 21));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
*/
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.setExpirationChanged = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.clearExpirationChanged = function() {
  return this.setExpirationChanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.hasExpirationChanged = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional google.protobuf.Timestamp expiration_after = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.getExpirationAfter = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
*/
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.setExpirationAfter = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.HostLicenseTxInfo} returns this
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.clearExpirationAfter = function() {
  return this.setExpirationAfter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.HostLicenseTxInfo.prototype.hasExpirationAfter = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.RedemptionCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.RedemptionCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.RedemptionCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 3, ""),
    emailRestrictions: jspb.Message.getFieldWithDefault(msg, 4, ""),
    maxRedemptions: jspb.Message.getFieldWithDefault(msg, 5, 0),
    validFrom: (f = msg.getValidFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    validTo: (f = msg.getValidTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.RedemptionCode}
 */
proto.galaxycomplete.domain.RedemptionCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.RedemptionCode;
  return proto.galaxycomplete.domain.RedemptionCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.RedemptionCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.RedemptionCode}
 */
proto.galaxycomplete.domain.RedemptionCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailRestrictions(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxRedemptions(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setValidFrom(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setValidTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.RedemptionCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.RedemptionCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.RedemptionCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmailRestrictions();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMaxRedemptions();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getValidFrom();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getValidTo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.RedemptionCode} returns this
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.RedemptionCode} returns this
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string notes = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.RedemptionCode} returns this
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email_restrictions = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.getEmailRestrictions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.RedemptionCode} returns this
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.setEmailRestrictions = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 max_redemptions = 5;
 * @return {number}
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.getMaxRedemptions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.RedemptionCode} returns this
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.setMaxRedemptions = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp valid_from = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.getValidFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.RedemptionCode} returns this
*/
proto.galaxycomplete.domain.RedemptionCode.prototype.setValidFrom = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.RedemptionCode} returns this
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.clearValidFrom = function() {
  return this.setValidFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.hasValidFrom = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp valid_to = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.getValidTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.RedemptionCode} returns this
*/
proto.galaxycomplete.domain.RedemptionCode.prototype.setValidTo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.RedemptionCode} returns this
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.clearValidTo = function() {
  return this.setValidTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.RedemptionCode.prototype.hasValidTo = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.galaxycomplete.domain.RedemptionCodeContent.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.galaxycomplete.domain.RedemptionCodeContent.ContentCase = {
  CONTENT_NOT_SET: 0,
  LICENSE: 1
};

/**
 * @return {proto.galaxycomplete.domain.RedemptionCodeContent.ContentCase}
 */
proto.galaxycomplete.domain.RedemptionCodeContent.prototype.getContentCase = function() {
  return /** @type {proto.galaxycomplete.domain.RedemptionCodeContent.ContentCase} */(jspb.Message.computeOneofCase(this, proto.galaxycomplete.domain.RedemptionCodeContent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.RedemptionCodeContent.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.RedemptionCodeContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.RedemptionCodeContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.RedemptionCodeContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    license: (f = msg.getLicense()) && proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.RedemptionCodeContent}
 */
proto.galaxycomplete.domain.RedemptionCodeContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.RedemptionCodeContent;
  return proto.galaxycomplete.domain.RedemptionCodeContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.RedemptionCodeContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.RedemptionCodeContent}
 */
proto.galaxycomplete.domain.RedemptionCodeContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent;
      reader.readMessage(value,proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.deserializeBinaryFromReader);
      msg.setLicense(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.RedemptionCodeContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.RedemptionCodeContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.RedemptionCodeContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.RedemptionCodeContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicense();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    countersList: jspb.Message.toObjectList(msg.getCountersList(),
    proto.galaxycomplete.domain.TransactionCounterInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent}
 */
proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent;
  return proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent}
 */
proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.TransactionCounterInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.TransactionCounterInfo.deserializeBinaryFromReader);
      msg.addCounters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxycomplete.domain.TransactionCounterInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TransactionCounterInfo counters = 1;
 * @return {!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>}
 */
proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.prototype.getCountersList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.TransactionCounterInfo, 1));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>} value
 * @return {!proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent} returns this
*/
proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.prototype.setCountersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxycomplete.domain.TransactionCounterInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.TransactionCounterInfo}
 */
proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.prototype.addCounters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxycomplete.domain.TransactionCounterInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent} returns this
 */
proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent.prototype.clearCountersList = function() {
  return this.setCountersList([]);
};


/**
 * optional LicenseContent license = 1;
 * @return {?proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent}
 */
proto.galaxycomplete.domain.RedemptionCodeContent.prototype.getLicense = function() {
  return /** @type{?proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.RedemptionCodeContent.LicenseContent|undefined} value
 * @return {!proto.galaxycomplete.domain.RedemptionCodeContent} returns this
*/
proto.galaxycomplete.domain.RedemptionCodeContent.prototype.setLicense = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.galaxycomplete.domain.RedemptionCodeContent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.RedemptionCodeContent} returns this
 */
proto.galaxycomplete.domain.RedemptionCodeContent.prototype.clearLicense = function() {
  return this.setLicense(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.RedemptionCodeContent.prototype.hasLicense = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.RedemptionCodeDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.RedemptionCodeDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.RedemptionCodeDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.RedemptionCodeDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: (f = msg.getCode()) && proto.galaxycomplete.domain.RedemptionCode.toObject(includeInstance, f),
    content: (f = msg.getContent()) && proto.galaxycomplete.domain.RedemptionCodeContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.RedemptionCodeDetails}
 */
proto.galaxycomplete.domain.RedemptionCodeDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.RedemptionCodeDetails;
  return proto.galaxycomplete.domain.RedemptionCodeDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.RedemptionCodeDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.RedemptionCodeDetails}
 */
proto.galaxycomplete.domain.RedemptionCodeDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.RedemptionCode;
      reader.readMessage(value,proto.galaxycomplete.domain.RedemptionCode.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    case 2:
      var value = new proto.galaxycomplete.domain.RedemptionCodeContent;
      reader.readMessage(value,proto.galaxycomplete.domain.RedemptionCodeContent.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.RedemptionCodeDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.RedemptionCodeDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.RedemptionCodeDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.RedemptionCodeDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.domain.RedemptionCode.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxycomplete.domain.RedemptionCodeContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional RedemptionCode code = 1;
 * @return {?proto.galaxycomplete.domain.RedemptionCode}
 */
proto.galaxycomplete.domain.RedemptionCodeDetails.prototype.getCode = function() {
  return /** @type{?proto.galaxycomplete.domain.RedemptionCode} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.RedemptionCode, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.RedemptionCode|undefined} value
 * @return {!proto.galaxycomplete.domain.RedemptionCodeDetails} returns this
*/
proto.galaxycomplete.domain.RedemptionCodeDetails.prototype.setCode = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.RedemptionCodeDetails} returns this
 */
proto.galaxycomplete.domain.RedemptionCodeDetails.prototype.clearCode = function() {
  return this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.RedemptionCodeDetails.prototype.hasCode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RedemptionCodeContent content = 2;
 * @return {?proto.galaxycomplete.domain.RedemptionCodeContent}
 */
proto.galaxycomplete.domain.RedemptionCodeDetails.prototype.getContent = function() {
  return /** @type{?proto.galaxycomplete.domain.RedemptionCodeContent} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.RedemptionCodeContent, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.RedemptionCodeContent|undefined} value
 * @return {!proto.galaxycomplete.domain.RedemptionCodeDetails} returns this
*/
proto.galaxycomplete.domain.RedemptionCodeDetails.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.RedemptionCodeDetails} returns this
 */
proto.galaxycomplete.domain.RedemptionCodeDetails.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.RedemptionCodeDetails.prototype.hasContent = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.RedemptionCodeIdentityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    codeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.RedemptionCodeIdentityInfo}
 */
proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.RedemptionCodeIdentityInfo;
  return proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.RedemptionCodeIdentityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.RedemptionCodeIdentityInfo}
 */
proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCodeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.RedemptionCodeIdentityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCodeId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 code_id = 1;
 * @return {number}
 */
proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.prototype.getCodeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.RedemptionCodeIdentityInfo} returns this
 */
proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.prototype.setCodeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.RedemptionCodeIdentityInfo} returns this
 */
proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.RedemptionDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.RedemptionDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.RedemptionDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.RedemptionDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && galaxycompletepb_apipb_domainpb_user_pb.HubUser.toObject(includeInstance, f),
    code: (f = msg.getCode()) && proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.toObject(includeInstance, f),
    redeemedAt: (f = msg.getRedeemedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.RedemptionDetails}
 */
proto.galaxycomplete.domain.RedemptionDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.RedemptionDetails;
  return proto.galaxycomplete.domain.RedemptionDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.RedemptionDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.RedemptionDetails}
 */
proto.galaxycomplete.domain.RedemptionDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_user_pb.HubUser;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_user_pb.HubUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new proto.galaxycomplete.domain.RedemptionCodeIdentityInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRedeemedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.RedemptionDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.RedemptionDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.RedemptionDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.RedemptionDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_user_pb.HubUser.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxycomplete.domain.RedemptionCodeIdentityInfo.serializeBinaryToWriter
    );
  }
  f = message.getRedeemedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional HubUser user = 1;
 * @return {?proto.galaxycomplete.domain.HubUser}
 */
proto.galaxycomplete.domain.RedemptionDetails.prototype.getUser = function() {
  return /** @type{?proto.galaxycomplete.domain.HubUser} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_user_pb.HubUser, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.HubUser|undefined} value
 * @return {!proto.galaxycomplete.domain.RedemptionDetails} returns this
*/
proto.galaxycomplete.domain.RedemptionDetails.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.RedemptionDetails} returns this
 */
proto.galaxycomplete.domain.RedemptionDetails.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.RedemptionDetails.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RedemptionCodeIdentityInfo code = 2;
 * @return {?proto.galaxycomplete.domain.RedemptionCodeIdentityInfo}
 */
proto.galaxycomplete.domain.RedemptionDetails.prototype.getCode = function() {
  return /** @type{?proto.galaxycomplete.domain.RedemptionCodeIdentityInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.RedemptionCodeIdentityInfo, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.RedemptionCodeIdentityInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.RedemptionDetails} returns this
*/
proto.galaxycomplete.domain.RedemptionDetails.prototype.setCode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.RedemptionDetails} returns this
 */
proto.galaxycomplete.domain.RedemptionDetails.prototype.clearCode = function() {
  return this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.RedemptionDetails.prototype.hasCode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp redeemed_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.RedemptionDetails.prototype.getRedeemedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.RedemptionDetails} returns this
*/
proto.galaxycomplete.domain.RedemptionDetails.prototype.setRedeemedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.RedemptionDetails} returns this
 */
proto.galaxycomplete.domain.RedemptionDetails.prototype.clearRedeemedAt = function() {
  return this.setRedeemedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.RedemptionDetails.prototype.hasRedeemedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.galaxycomplete.domain);
