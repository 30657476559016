import { GetAzureRecommendation } from "../../../../../_proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import { AzureStorageProduct } from "../../../../../_proto/galaxycompletepb/apipb/domainpb/azure_pb";
import { GalaxyMigrateStorageConfig } from "../../../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import xbytes from "xbytes";
import { AutoAlloc } from "../../../../../_proto/galaxymigratepb/galaxy_migrate_autoalloc_pb";

export interface DiskAssignmentConfig {
    storageType: AutoAlloc.VolumeParams.Azure.StorageType;
    iops: number;
    throughput: number;
    capacity: number;
}

export interface AzureCalculatedDiskAssignmentConfig {
    product: AzureStorageProduct.AsObject;
    iops: number;
    throughput: number;
    capacity: number;
    price: number;
}

export const getDiskSizeInGiB = (capacity: number) => {
    return Number(new xbytes.ByteUnitObject(capacity).convertTo("GiB").split(" ")[0]);
};

export const DiskClassesWithEnabledParams = [AutoAlloc.VolumeParams.Azure.StorageType.PREMIUM_SSD_V2, AutoAlloc.VolumeParams.Azure.StorageType.ULTRA_SSD];

export const getDiskParamsEnabled = (diskClass: AutoAlloc.VolumeParams.Azure.StorageType) => {
    return DiskClassesWithEnabledParams.includes(diskClass);
};
