// Project: GalaxyComplete
// Created: 9/27/20 by sammy
// File: GmMigrationWizard

import * as React from "react";
import { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import { useAppServices } from "../../app/services";

import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardHeader,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Radio,
    SvgIcon,
    Switch,
    Typography,
    useTheme,
} from "@mui/material";
import { GmMigrationType, GmMigrationVolumeType, GmMigrationWizardState, GmMigrationWizardStep } from "../GmMigrationService";
import { renderServerDataWithLoadingList, useInitData, useServerDataWithLoadingBox } from "../../core/data/DataLoaderHooks";
import { formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import {
    GalaxyMigrateDeploymentDetails,
    GalaxyMigrateDeploymentInfo,
    GalaxyMigrateStorageConfig,
} from "../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import {
    formatServerAddressWithoutDefaultPort,
    getGalaxyMigrateHelperNodeOSName,
    getGmStorageConfigDeviceType,
    useNavigateToDeploymentsList,
} from "../../galaxymigrate/GalaxyMigrateCommon";
import { MdArrowForward, MdOutlineCloud, MdStorage } from "react-icons/md";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { FormikValues } from "formik";
import { useNavigate } from "react-router-dom";
import { generateMigrationSessionDetailsPath } from "../MigrationCommon";
import { useCurrentProjectID } from "../../project/CurrentProject";
import { ColumnDef, DataTable } from "../../../common/table/DataTable";
import { getDeploymentConnectionStyle, isDeploymentGteVersion, isMinMtdiVersion } from "../../deployment/DeploymentCommon";
import { GmMigrationSessionParametersForm, MigrationParametersType } from "./GmMigrationSessionParametersForm";
import { isGmAutoAllocSupported } from "../autoallocation/GmAutoAllocation";
import { GMLinkInfo } from "../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigratelink_pb";
import { CreateGalaxyMigrateLinkButton } from "../../galaxymigrate/links/CreateGalaxyMigrateLinkForm";
import { FaAngleDoubleRight } from "react-icons/fa";
import { CloseIcon, EditIcon } from "../../../common/CommonIcons";
import { KnownArticle, QuickTipButton } from "../../help/HelpCommon";
import { FeatureFlag } from "../../app/AppGlobalService";
import { SelectableCard } from "../../../common/card/SelectableCard";
import { ImTree } from "react-icons/im";
import DataVolume from "../../../assets/migrationSession/volume.png";
import BootVolume from "../../../assets/migrationSession/boot_volume.png";
import { GalaxyMigrationDeploymentOSDisplay } from "../../galaxymigrate/GalaxyMigrateDeploymentsList";
import { GrVmware } from "react-icons/gr";
import {
    GmMigrationWizardComputeMigrationSourcePreparationStep,
    GmMigrationWizardVmwareVmConfigStep,
    GmVmwareHelperSelectionTable,
} from "./computeMigration/GmMigrationWizardVmware";
import { DeploymentHostEnvironment } from "../../../_proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import {
    getDeploymentSelectionContinueDisabled,
    getHostLicenseExpiredHasExtension,
    getHostLicenseExpiredNoExtension,
    getHostLicenseInsufficientForMigration,
    getHostWillConsumeProjectLicense,
    getIsComputeMigration,
    getMigrationTypeSelectionContinueDisabled,
    getNumOfExtensionsToBeConsumedByMigrationSession,
    getSelectedVolumeCapacityExceedsHostBalance,
    getSelectedVolumeCapacityExceedsProjectBalance,
    getTotalSelectedVolumeCapacity,
    getVolumeSelectionDisabled,
    getVolumesSelectionContinueDisabled,
    renderWizardStepText,
    useInitiateAutoAllocation,
} from "./GmMigrationWizardUtils";
import { DynamicHorizontalStepper, StepConfig } from "../../../common/stepper/StepperComponents";
import { SwitchCard } from "../../../common/card/SwitchCard";
import { useEffectOnce } from "react-use";
import { LicenseVaultCounterType } from "../../../_proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";
import {
    getCounterTypeDisplayValue,
    HostBasedLicenseView,
    LicensingLearnMoreLink,
    useCurrentProjectLicenseDetails,
    useCurrentProjectLicenseModel,
    useProjectHasAvailableHostLicense,
    useProjectHasLicenseTimeExtension,
} from "../../license/LicenseCommon";
import { DEFAULT_LICENSE_CAPACITY } from "../../galaxymigrate/hostLicense/HostLicenseCommon";
import { HostLicenseInfo, VaultDetails } from "../../../_proto/galaxycompletepb/apipb/domainpb/license_pb";
import { AlertColor } from "@mui/material/Alert/Alert";
import { renderBootVolumeChip, renderChipInfo } from "../../../common/chip/CommonChips";
import { useIsFeatureEnabled } from "../../core/featureflag/FeatureFlags";
import { VscAzure } from "react-icons/vsc";
import { GmAzureHelperSelectionTable, GmMigrationWizardAzureVmConfigStep } from "./computeMigration/GmMigrationWizardAzure";
import { GalaxyMigrateConnectionsTable } from "../../galaxymigrate/GalaxyMigrateConnectionsList";
import { useOpenHelpArticle } from "../../help/hooks/help_hooks";

// ======================
// GalaxyMigrateMigrationWizardScreen
// ======================
interface GalaxyMigrateMigrationWizardScreenProps {}

export const GalaxyMigrateMigrationWizardScreen: React.FC<GalaxyMigrateMigrationWizardScreenProps> = observer((p) => {
    return (
        <ScreenContainer>
            <ScreenTitleBar title={`New Migration Session`} />
            <GalaxyMigrateMigrationWizard />
        </ScreenContainer>
    );
});

// ======================
// GalaxyMigrateMigrationWizard
// ======================
interface GalaxyMigrateMigrationWizardProps {}

const GalaxyMigrateMigrationWizard: React.FC<GalaxyMigrateMigrationWizardProps> = observer((p) => {
    const { gmMigrationService } = useAppServices();
    const wizardState = gmMigrationService.wizardState;

    return <DynamicHorizontalStepper stepConfigs={getStepConfigs(wizardState)} stepperState={wizardState.stepperState} />;
});

// ======================
// DeploymentSelectionStep
// ======================
interface WizardStepProp {
    wizardState: GmMigrationWizardState;
}

const DeploymentSelectionStep: React.FC<WizardStepProp> = observer((p) => {
    const wizardState = p.wizardState;
    const { progressService } = useAppServices();
    const licenseModel = useCurrentProjectLicenseModel();
    const isHostLicenseAvailable = useProjectHasAvailableHostLicense();
    const isHostLicenseTimeExtensionAvailable = useProjectHasLicenseTimeExtension();
    const selectedHostLicense = wizardState.deployment.data?.getInfo().getDeployment().getLicense();
    const hostLicenseInsufficientForMigration = getHostLicenseInsufficientForMigration(selectedHostLicense, isHostLicenseAvailable);
    const hostLicenseForMigrationExpiredNoExtension = getHostLicenseExpiredNoExtension(selectedHostLicense, isHostLicenseTimeExtensionAvailable);
    const hostWillConsumeProjectLicense = getHostWillConsumeProjectLicense(selectedHostLicense, isHostLicenseAvailable);
    const hostLicenseForMigrationExpiredHasExtension = getHostLicenseExpiredHasExtension(selectedHostLicense, isHostLicenseTimeExtensionAvailable);
    const continueDisabled = getDeploymentSelectionContinueDisabled(
        licenseModel,
        wizardState,
        hostLicenseInsufficientForMigration,
        hostLicenseForMigrationExpiredNoExtension
    );

    const onContinue = async () => {
        await progressService.track(wizardState.confirmDeploymentSelection());
        wizardState.stepperState.goToNextStep();
    };

    return (
        <>
            <Grid container justifyContent={"space-between"}>
                <Typography paragraph>{"Select the source for this migration session"}</Typography>
                <br />
            </Grid>
            <DeploymentsSelectionTable wizardState={wizardState} />
            <br />
            <Grid container justifyContent={"center"}>
                <Box p={1}>
                    <Button variant={"contained"} color={"primary"} disabled={continueDisabled} onClick={onContinue}>
                        {"Continue"}
                    </Button>
                </Box>
            </Grid>
            <DeploymentLicenseAlert
                deployment={wizardState.deployment.data}
                hostLicenseInsufficient={hostLicenseInsufficientForMigration}
                hostWillConsumeProjectLicense={hostWillConsumeProjectLicense}
                hostLicenseExpiredNoExtension={hostLicenseForMigrationExpiredNoExtension}
                hostLicenseExpiredHasExtension={hostLicenseForMigrationExpiredHasExtension}
            />
        </>
    );
});

// ======================
// DeploymentLicensingAlert
// ======================

interface DeploymentLicenseAlertProps {
    deployment: GalaxyMigrateDeploymentDetails;
    hostLicenseInsufficient: boolean;
    hostLicenseExpiredNoExtension: boolean;
    hostLicenseExpiredHasExtension: boolean;
    hostWillConsumeProjectLicense: boolean;
}

const DeploymentLicenseAlert: React.FC<DeploymentLicenseAlertProps> = observer((p) => {
    const hostLicenseType = getCounterTypeDisplayValue(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE);
    if (!!p.deployment) {
        return (
            <HostBasedLicenseView>
                <Box pt={2}>
                    {p.hostLicenseInsufficient && (
                        <Alert severity={"error"}>
                            Migration session cannot be created because this host is not currently licensed and this project's Project License Key does not have
                            a {hostLicenseType}.
                            <br />
                            <br />
                            <LicensingLearnMoreLink />
                        </Alert>
                    )}
                    {p.hostLicenseExpiredNoExtension && (
                        <Alert severity={"error"}>
                            Migration session cannot be created because this host's license has expired and this project's Project License Key does not have any{" "}
                            {hostLicenseType} Extensions.
                            <br />
                            <br />
                            <LicensingLearnMoreLink />
                        </Alert>
                    )}
                    {p.hostLicenseExpiredHasExtension && (
                        <Alert severity={"warning"}>
                            This host's license is expired. A new {hostLicenseType} Extension will be consumed from this project's Project License Key when this
                            migration session is created.
                        </Alert>
                    )}
                    {p.hostWillConsumeProjectLicense && (
                        <Alert severity={"warning"}>
                            This host is not currently licensed. A new {hostLicenseType} will be consumed from this project's Project License Key when this
                            migration session is created.
                        </Alert>
                    )}
                </Box>
            </HostBasedLicenseView>
        );
    }

    return null;
});

// ======================
// MigrationTypeStep
// ======================

interface MigrationTypeStepProps {
    wizardState: GmMigrationWizardState;
}

const MigrationTypeStep: React.FC<MigrationTypeStepProps> = observer((props) => {
    const { wizardState } = props;

    const { progressService, appGlobalService, gmDeploymentService } = useAppServices();
    const openHelpArticle = useOpenHelpArticle();

    const onContinue = async () => {
        await progressService.track(wizardState.confirmMigrationTypeSelection());
        wizardState.stepperState.setStepConfigs(getStepConfigs(wizardState));
        wizardState.stepperState.goToNextStep();
    };

    const [selectedMigrationType, setSelectedMigrationType] = React.useState(wizardState.selectedMigrationType);

    const handleSelectMigrationType = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, type: GmMigrationType) => {
            setSelectedMigrationType(type);
            wizardState.selectMigrationType(type);
            wizardState.deselectRemoteDestination();

            if (type === GmMigrationType.COMPUTE_VMWARE) {
                wizardState.selectMigrationVolumeType(GmMigrationVolumeType.BOOT);
                wizardState.initVmwareState();
            }

            if (type === GmMigrationType.COMPUTE_AZURE) {
                wizardState.selectMigrationVolumeType(GmMigrationVolumeType.BOOT);
                wizardState.initAzureState();
            }

            if (type !== GmMigrationType.COMPUTE_AZURE) {
                wizardState.resetAzureState();
            }
        },
        [setSelectedMigrationType, wizardState]
    );

    const [selectedMigrationVolumeType, setSelectedMigrationVolumeType] = React.useState(wizardState.selectedMigrationVolumeType);

    const handleSelectMigrationVolumeType = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, type: GmMigrationVolumeType) => {
            setSelectedMigrationVolumeType(type);
            wizardState.selectMigrationVolumeType(type);
        },
        [setSelectedMigrationVolumeType, wizardState]
    );

    const openBootHelpArticle = useCallback(() => {
        openHelpArticle(KnownArticle.MIGRATING_BOOT_VOLUMES);
    }, [openHelpArticle]);

    const continueDisabled = getMigrationTypeSelectionContinueDisabled(wizardState);

    const isBootVolumeFeatureEnabled = isMinMtdiVersion(wizardState.deployment.data, "10.1.0");
    return (
        <>
            <Box>
                <Typography variant={"h5"}>1. Select Migration Session Type</Typography>
                <Box pt={2} pb={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                            <SelectableCard
                                selected={selectedMigrationType === GmMigrationType.LOCAL}
                                onSelect={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelectMigrationType(e, GmMigrationType.LOCAL)}
                                sx={{ height: "100%" }}
                                icon={<ImTree size={"6em"} />}
                                title={"Local Migration"}
                                description={"Migration between volumes on the same host"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <SelectableCard
                                selected={selectedMigrationType === GmMigrationType.REMOTE}
                                onSelect={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelectMigrationType(e, GmMigrationType.REMOTE)}
                                sx={{ height: "100%" }}
                                icon={<MdOutlineCloud size={"6em"} />}
                                title={"Remote Migration"}
                                description={"Migration to Remote Host via H2H Connections"}
                            />
                        </Grid>
                        {appGlobalService.isFeatureEnabled(FeatureFlag.COMPUTE_MIGRATION_VMWARE) && (
                            <Grid item xs={12} sm={6} md={4}>
                                <SelectableCard
                                    selected={selectedMigrationType === GmMigrationType.COMPUTE_VMWARE}
                                    onSelect={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelectMigrationType(e, GmMigrationType.COMPUTE_VMWARE)}
                                    sx={{ height: "100%" }}
                                    icon={<GrVmware size={"6em"} />}
                                    title={"Compute Migration - VMware"}
                                    description={"Migrate Entire Machine to VMware Environment"}
                                />
                            </Grid>
                        )}
                        {appGlobalService.isFeatureEnabled(FeatureFlag.AZURE_COMPUTE_MIGRATION) && (
                            <Grid item xs={12} sm={6} md={4}>
                                <SelectableCard
                                    selected={selectedMigrationType === GmMigrationType.COMPUTE_AZURE}
                                    onSelect={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelectMigrationType(e, GmMigrationType.COMPUTE_AZURE)}
                                    sx={{ height: "100%" }}
                                    icon={<VscAzure size={"6em"} />}
                                    title={"Compute Migration - Azure"}
                                    description={"Migrate Entire Machine to Azure Environment"}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Box>
            {selectedMigrationType === GmMigrationType.REMOTE && (
                <>
                    <Typography>{"Select a remote destination host for this session"}</Typography>
                    <br />
                    <RemoteDestinationSelectionTable wizardState={wizardState} />
                </>
            )}
            {selectedMigrationType === GmMigrationType.COMPUTE_VMWARE && (
                <>
                    <Typography>
                        Select a {getGalaxyMigrateHelperNodeOSName(DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE)} to receive data. To be eligible
                        for selection, helper VMs must have vCenter integration configured and have active H2H Connection with host{" "}
                        {wizardState.selectedDeploymentName}.
                    </Typography>
                    <br />
                    <GmVmwareHelperSelectionTable wizardState={wizardState} />
                </>
            )}
            {selectedMigrationType === GmMigrationType.COMPUTE_AZURE && (
                <>
                    <Typography>
                        {`Select a CMC Helper for Azure to receive data. To be eligible for selection,
                                    helpers must have an Azure integration configured and have active H2H Connection
                                     with host ${wizardState.selectedDeploymentName}.`}
                    </Typography>
                    <br />
                    <GmAzureHelperSelectionTable wizardState={wizardState} />
                </>
            )}
            {!wizardState.isComputeMigration && (
                <Box mt={2}>
                    <Typography variant={"h5"}>2. Select Migration Volume Type</Typography>
                    <Box pt={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                                <SelectableCard
                                    selected={selectedMigrationVolumeType === GmMigrationVolumeType.DATA}
                                    onSelect={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                                        handleSelectMigrationVolumeType(e, GmMigrationVolumeType.DATA)
                                    }
                                    sx={{ height: "100%" }}
                                    disabled={!(wizardState.selectedMigrationType >= 0)}
                                    icon={<img src={DataVolume} height={72} alt={"Data Volumes"} />}
                                    title={"Migrate Data Volumes Only"}
                                    description={"cMotion™ can be used to move your workload without downtime"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <SelectableCard
                                    disabled={!isBootVolumeFeatureEnabled || !(wizardState.selectedMigrationType >= 0)}
                                    selected={selectedMigrationVolumeType === GmMigrationVolumeType.BOOT}
                                    onSelect={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                                        handleSelectMigrationVolumeType(e, GmMigrationVolumeType.BOOT)
                                    }
                                    sx={{ height: "100%" }}
                                    icon={<img src={BootVolume} height={72} alt={"Boot Volumes"} />}
                                    title={"Migrate Boot Volumes"}
                                    description={"Move your boot volume without impacting production"}
                                    actions={[
                                        {
                                            id: "help",
                                            name: "View Boot Volume Migration Guide",
                                            action: openBootHelpArticle,
                                            buttonProps: { variant: "outlined", color: "neutral" },
                                        },
                                    ]}
                                    warning={"Note: Review migration guide before continuing."}
                                    tip={
                                        "Due to the nature of a boot volume migration, cMotion™ functionalities will not be available. You must be familiar with your system's boot volume swapping (cutover) procedure before proceeding."
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            )}
            <br />
            <Grid container justifyContent={"center"}>
                <Box p={1} display={"flex"}>
                    <Box pr={2}>
                        <Button variant={"outlined"} color={"neutral"} onClick={() => wizardState.stepperState.goBackOneStep()}>
                            {"Go Back"}
                        </Button>
                    </Box>
                    <Box>
                        <Button variant={"contained"} color={"primary"} disabled={continueDisabled} onClick={onContinue}>
                            {"Continue"}
                        </Button>
                    </Box>
                </Box>
            </Grid>
        </>
    );
});

// ======================
// VolumesSelectionStep
// ======================
const VolumesSelectionStep: React.FC<WizardStepProp> = observer((p) => {
    const { progressService, licenseService } = useAppServices();
    const projectLicenseModel = useCurrentProjectLicenseModel();
    const hostLicense = p.wizardState.deployment.data?.getInfo().getDeployment().getLicense();
    const projectLicense = useCurrentProjectLicenseDetails();
    const init = async () => {
        if (!!p.wizardState.selectedRemoteDestinationId) {
            await progressService.track(p.wizardState.remoteStorageConfig.fetchData());
            await progressService.track(p.wizardState.remoteDeployment.fetchData());
        }
        await progressService.track(p.wizardState.storageConfig.fetchData());
        await licenseService.projectLicenseDetails.fetchData();
    };

    useInitData({
        init: init,
    });

    const onContinue = () => {
        p.wizardState.confirmVolumeSelections();
        p.wizardState.stepperState.goToNextStep();
    };

    const totalSelectedVolumeCapacity = getTotalSelectedVolumeCapacity(p.wizardState.selectedVolumes);

    const continueDisabled = getVolumesSelectionContinueDisabled(projectLicenseModel, p.wizardState, totalSelectedVolumeCapacity, projectLicense);

    return renderServerDataWithLoadingList(p.wizardState.storageConfig, (storageConfig) => {
        const sourceVolumes = storageConfig.getDevicesList().filter((d) => {
            const isNotBootVolume = p.wizardState.selectedMigrationVolumeType === GmMigrationVolumeType.BOOT ? true : !d.getBlockDevice().getBoot();
            return (
                !d.getRole()?.getMigrationSessionUuid() &&
                (d.getRole()?.getRole() === GalaxyMigrateStorageConfig.Device.RoleInfo.Role.SOURCE || d.getCanInsertAsSource()) &&
                isNotBootVolume
            );
        });

        return (
            <>
                <Grid container justifyContent={"space-between"}>
                    <Typography paragraph>{"Select Volumes to be included in this migration session"}</Typography>
                </Grid>

                <Grid container justifyContent={"space-between"} alignContent={"center"}>
                    <Grid item lg={6} xs={12}>
                        <AllowSmallerDestinationsToggle wizardState={p.wizardState} /> &nbsp;
                        <LVMSourceSelectionDialogButton wizardState={p.wizardState} storageConfig={storageConfig} />
                    </Grid>
                    <Grid item>
                        <Box display={"flex"}>
                            <AutoAllocationButton wizardState={p.wizardState} />
                        </Box>
                    </Grid>
                </Grid>
                {p.wizardState.allowSmallerDestinations && (
                    <Box width={"100%"}>
                        <Alert severity={"info"} variant={"outlined"}>
                            {`You are migrating the source data to a disk that is smaller than the physical size of the source disk. Please make sure there is no useful data on the source disk that is residing beyond the size of the destination disk, during the migration, and before cutover. Data written outside of the migration range may be inconsistent on the source disk when cMotion is invoked.`}
                        </Alert>
                    </Box>
                )}

                <br />
                <VolumeCardsSection sourceVolumes={sourceVolumes} wizardState={p.wizardState} />
                {!sourceVolumes.length && (
                    <Card>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} p={4}>
                            <Typography color={"textSecondary"}>No eligible volumes found.</Typography>
                        </Box>
                    </Card>
                )}
                <br />
                <Grid container justifyContent={"center"}>
                    <Box p={1} display={"flex"}>
                        <Box pr={2}>
                            <Button variant={"outlined"} color={"neutral"} onClick={() => p.wizardState.stepperState.goBackOneStep()}>
                                {"Go Back"}
                            </Button>
                        </Box>
                        <Box>
                            <Button variant={"contained"} color={"primary"} disabled={continueDisabled} onClick={onContinue}>
                                {"Continue"}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <VolumeSelectionLicenseAlert
                    hostLicense={hostLicense}
                    projectLicense={projectLicense}
                    totalVolumeCapacity={totalSelectedVolumeCapacity}
                    hostName={p.wizardState.deployment.data?.getInfo().getDeployment().getSystemName()}
                />
            </>
        );
    });
});

// ======================
// VolumeSelectionLicenseAlert
// ======================

interface VolumeSelectionLicenseAlertProps {
    totalVolumeCapacity: number;
    hostLicense: HostLicenseInfo;
    projectLicense: VaultDetails.AsObject;
    hostName: string;
}

export const VolumeSelectionLicenseAlert: React.FC<VolumeSelectionLicenseAlertProps> = observer((p) => {
    const { totalVolumeCapacity, hostLicense, projectLicense, hostName } = p;
    const numOfExtensions = getNumOfExtensionsToBeConsumedByMigrationSession(totalVolumeCapacity, hostLicense, projectLicense);
    const volumeCapacityString = formatKnownDataType(totalVolumeCapacity, KnownDataType.CAPACITY);
    const hostLicenseRemainingCapacityString = formatKnownDataType(
        hostLicense?.getMigrationCapacityRemaining() || DEFAULT_LICENSE_CAPACITY,
        KnownDataType.CAPACITY
    );
    const projectLicenseRemainingCapacityString = formatKnownDataType(
        projectLicense.itemsList.find((c) => c.counterType.value === LicenseVaultCounterType.LicenseVaultCounterType.GALAXY_MIGRATE_LOCAL_MIGRATION)
            ?.currentBalance,
        KnownDataType.CAPACITY
    );
    const getAlertConfig = (): { severity: AlertColor; message: React.ReactNode } => {
        if (!hostLicense) {
            if (getSelectedVolumeCapacityExceedsProjectBalance(totalVolumeCapacity, projectLicense)) {
                return {
                    severity: "error",
                    message: (
                        <>
                            <Typography>
                                Migration session cannot be created because selected migration volume(s) require {volumeCapacityString} of migration capacity
                                license, but only {projectLicenseRemainingCapacityString} is available in your project license key.
                            </Typography>
                            <br />
                            <LicensingLearnMoreLink />
                        </>
                    ),
                };
            } else {
                return null;
            }
        } else {
            if (!getSelectedVolumeCapacityExceedsHostBalance(totalVolumeCapacity, hostLicense)) {
                return {
                    severity: "info",
                    message: (
                        <>
                            <Typography>
                                {formatKnownDataType(totalVolumeCapacity, KnownDataType.CAPACITY)} of Migration Capacity Quota will be deducted from {hostName}
                                's license when the session is created.
                            </Typography>
                        </>
                    ),
                };
            } else {
                if (numOfExtensions > 0) {
                    return {
                        severity: "warning",
                        message: (
                            <>
                                <Typography>
                                    {hostName}'s license only has {hostLicenseRemainingCapacityString} of remaining Migration Capacity Quota but
                                    {volumeCapacityString} is required to migrate the selected volumes.
                                    {numOfExtensions} additional{" "}
                                    {getCounterTypeDisplayValue(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION)}{" "}
                                    License{numOfExtensions > 1 ? "" : "s"} will be consumed from this project's Project License Key when this session is
                                    created.
                                </Typography>
                            </>
                        ),
                    };
                } else {
                    return {
                        severity: "error",
                        message: (
                            <>
                                <Typography>
                                    Migration session cannot be created because selected migration volumes ({volumeCapacityString}) are larger than the
                                    remaining Migration Capacity Quota ({hostLicenseRemainingCapacityString}) for this host and this project's Project License
                                    Key does not have sufficient
                                    {getCounterTypeDisplayValue(
                                        LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION
                                    )}{" "}
                                    Licenses.
                                </Typography>
                                <br />
                                <LicensingLearnMoreLink />
                            </>
                        ),
                    };
                }
            }
        }
    };

    return <Box pt={2}>{!!getAlertConfig() && <Alert severity={getAlertConfig().severity}>{getAlertConfig().message}</Alert>}</Box>;
});

// ======================
// VolumeCardsSection
// ======================

interface VolumeCardsSectionProps {
    sourceVolumes: Array<GalaxyMigrateStorageConfig.Device>;
    wizardState: GmMigrationWizardState;
}

export const VolumeCardsSection: React.FC<VolumeCardsSectionProps> = observer((p) => {
    useEffectOnce(() => {
        if (p.wizardState.isComputeMigration && !p.wizardState.hasSelectedVolumes) {
            p.sourceVolumes.forEach((v) => {
                if (!p.wizardState.selectedVolumes[v.getBlockDevice().getDeviceName()]) {
                    p.wizardState.addSelectedVolume(v);
                }
            });
        }
    });
    return (
        <>
            {p.sourceVolumes.map((v) => (
                <VolumeSelectionCard sourceVolume={v} wizardState={p.wizardState} />
            ))}
        </>
    );
});

// ======================
// AutoAllocationButton
// ======================

interface AutoAllocationButtonProps {
    wizardState: GmMigrationWizardState;
}

export const AutoAllocationButton: React.FC<AutoAllocationButtonProps> = observer((p) => {
    const { progressService } = useAppServices();

    const selectedSourceVolumes = p.wizardState.selectedVolumes;
    const autoAllocationSupported = !!p.wizardState.selectedRemoteDestinationId
        ? isGmAutoAllocSupported(p.wizardState.remoteDeployment?.data)
        : isGmAutoAllocSupported(p.wizardState.deployment?.data);

    const gotoAutoAllocation = useInitiateAutoAllocation(p.wizardState);

    const autoAllocateVmware = async () => {
        const newVolumesList = await progressService.track(p.wizardState.vmwareState.autoAllocateVmware(), "Auto-Allocating Disks To VMWare Helper...");
        if (!!newVolumesList) {
            await p.wizardState.vmwareState.applyAllocatedVmVolumesToWizard(newVolumesList);
        }
    };

    return (
        <>
            {autoAllocationSupported && (
                <Button
                    variant={"outlined"}
                    color={"secondary"}
                    onClick={p.wizardState.selectedMigrationType === GmMigrationType.COMPUTE_VMWARE ? autoAllocateVmware : gotoAutoAllocation}
                    disabled={!Object.keys(selectedSourceVolumes)?.length}
                >
                    {"Auto Allocate Destination Volumes"}
                </Button>
            )}
        </>
    );
});
// ======================
// AllowSmallerDestinationsToggle
// ======================

interface AllowSmallerDestinationsToggleProps {
    wizardState: GmMigrationWizardState;
}

export const AllowSmallerDestinationsToggle: React.FC<AllowSmallerDestinationsToggleProps> = observer((p) => {
    const { dialogService } = useAppServices();

    const supported = useIsFeatureEnabled(FeatureFlag.MIGRATE_TO_SMALLER_VOLS) && isDeploymentGteVersion(p.wizardState?.deployment?.data, "5.1.5");
    if (!supported) {
        return null;
    }

    const onClick = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            const confirmed = await dialogService.addConfirmDialog({
                title: "Allow Migration to Smaller Volumes",
                message: `You are migrating the source data to a disk that is smaller than the physical size of the source disk. Please make sure there is no useful data on the source disk that is residing beyond the size of the destination disk, during the migration, and before cutover. Data written outside of the migration range may be inconsistent on the source disk when cMotion is invoked.`,
                typeToConfirm: "CONFIRM",
            });
            if (confirmed) {
                p.wizardState.setAllowSmallerDestinations(checked);
            }
        } else {
            p.wizardState.setAllowSmallerDestinations(checked);
        }
    };

    const toggle = <Switch color={"secondary"} checked={p.wizardState.allowSmallerDestinations} onChange={onClick} />;
    return (
        <Box width={"100%"}>
            <Box display={"flex"} alignItems={"center"}>
                <FormControlLabel control={toggle} label={"Allow Migration to Smaller Volumes"} />
                <QuickTipButton
                    title={
                        "Source data beyond the maximum destination volume size will not be migrated. Ensure source partitions can fit into the destination volume in its entirety to avoid data corruption."
                    }
                />
            </Box>
        </Box>
    );
});

// ======================
// VolumeSelectionCard
// ======================

interface VolumeSelectionCardProps {
    sourceVolume: GalaxyMigrateStorageConfig.Device;
    wizardState: GmMigrationWizardState;
}

export const VolumeSelectionCard: React.FC<VolumeSelectionCardProps> = observer((p) => {
    const vol = p.sourceVolume;
    const device = vol.getBlockDevice();
    const selected = !!p.wizardState.selectedVolumes[device.getDeviceName()];
    const onSelect = useCallback(() => {
        if (selected) {
            p.wizardState.removeSelectedVolume(vol);
        } else {
            p.wizardState.addSelectedVolume(vol);
        }
    }, [selected, p.wizardState, vol]);

    const isVolumeSelectionDisabled = getVolumeSelectionDisabled(p.wizardState, vol);

    return (
        <SwitchCard
            selected={selected}
            disabled={isVolumeSelectionDisabled}
            onSelect={onSelect}
            cardContent={
                <Grid container alignItems={"center"}>
                    <Grid item xs={4}>
                        <Box display={"flex"} justifyContent={"flex-start"}>
                            <Box textAlign={"left"}>
                                <Box pb={1}>
                                    <Typography variant={"body1"}>
                                        {device.getDeviceName()} ({formatKnownDataType(device.getCapacity(), KnownDataType.CAPACITY)})
                                    </Typography>
                                </Box>
                                <Grid container spacing={1}>
                                    {renderChipInfo(getGmStorageConfigDeviceType(vol))}
                                    {renderChipInfo(device.getFsType())}
                                    {renderChipInfo(device.getLabel())}
                                    {renderChipInfo(device.getMountPoint())}
                                    {renderBootVolumeChip(device.getBoot())}
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        {selected && (
                            <Box>
                                <SvgIcon>
                                    <FaAngleDoubleRight />
                                </SvgIcon>
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {selected && (
                            <Box display={"flex"} justifyContent={"flex-end"}>
                                <DestinationSelectionMenu wizardState={p.wizardState} device={vol} />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            }
        />
    );
});

// ======================
// LVMSourceSelectionDialog
// ======================
interface LVMSourceSelectionDialogButtonProps {
    wizardState: GmMigrationWizardState;
    storageConfig: GalaxyMigrateStorageConfig;
}

const useLVMSourceSelectionDialogButtonStyles = () => {
    const t = useTheme();
    return {
        content: {
            background: t.palette.background.default,
        },
    };
};
const LVMSourceSelectionDialogButton: React.FC<LVMSourceSelectionDialogButtonProps> = observer((p) => {
    const styles = useLVMSourceSelectionDialogButtonStyles();
    const close = () => (p.wizardState.showLVMSourceSelectionDialog = false);
    const lvmConfig = p.storageConfig.getLvmConfig();

    if (!lvmConfig?.getVolumeGroupsList()?.length) {
        return null;
    }
    return (
        <>
            <Button variant={"outlined"} color={"primary"} onClick={() => (p.wizardState.showLVMSourceSelectionDialog = true)}>
                {"Select LVM Volumes as Source"}
            </Button>
            <Dialog fullScreen={useShouldDialogFullScreen()} maxWidth={"md"} fullWidth open={p.wizardState.showLVMSourceSelectionDialog} onClose={close}>
                {p.wizardState.showLVMSourceSelectionDialog && !!p.storageConfig && (
                    <>
                        <DialogTitle sx={styles.content}>{`Select and add LVM volumes to migration session`}</DialogTitle>
                        <DialogContent sx={styles.content}>
                            <Box p={2}>
                                {lvmConfig.getVolumeGroupsList().map((vg) => {
                                    const addFn = () => {
                                        // find the storage disk
                                        const pvDevicePaths = vg.getPvsList().map((pv) => pv.getDevice());
                                        p.storageConfig
                                            .getDevicesList()
                                            .filter((d) => {
                                                // no need to take care of windows this is lvm
                                                for (const devicePath of [`/dev/${d.getBlockDevice().getDeviceName()}`].concat(
                                                    d.getBlockDevice().getDeviceLinksList()
                                                )) {
                                                    if (pvDevicePaths.includes(devicePath)) {
                                                        return true;
                                                    }
                                                }
                                                return false;
                                            })
                                            .forEach((d) => p.wizardState.addSelectedVolume(d));

                                        close();
                                    };

                                    return <VGSelectionCard key={vg.getUuid()} vg={vg} addFn={addFn} />;
                                })}
                            </Box>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </>
    );
});

// ======================
// VGSelectionCard
// ======================
interface VGSelectionCardProps {
    vg: GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup;
    addFn: () => void;
}

const VGSelectionCard: React.FC<VGSelectionCardProps> = observer((p) => {
    const selectButton = (
        <Button variant={"contained"} color={"secondary"} size={"large"} onClick={p.addFn}>
            {`Add VG to Selection`}
        </Button>
    );
    return (
        <Box pb={1}>
            <Card>
                <CardHeader
                    title={`Volume Group: ${p.vg.getName()}`}
                    action={selectButton}
                    subheader={`${formatKnownDataType(p.vg.getCapacity(), KnownDataType.CAPACITY)}`}
                />
                <Divider />
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <List>
                            <ListSubheader>{`${p.vg.getLvsList().length} Logical Volumes`}</ListSubheader>
                            {p.vg.getLvsList().map((lv) => {
                                return (
                                    <ListItem key={lv.getUuid()} dense>
                                        <ListItemText
                                            primary={`${lv.getName()}  (${formatKnownDataType(lv.getCapacity(), KnownDataType.CAPACITY)})`}
                                            secondary={lv.getMountPoint() ? `Mounted: ` + lv.getMountPoint() + ` (${lv.getFsType()})` : null}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <List>
                            <ListSubheader>{`${p.vg.getPvsList().length} Physical Volumes`}</ListSubheader>
                            {p.vg.getPvsList().map((pv) => {
                                return (
                                    <ListItem key={pv.getUuid()} dense>
                                        <ListItemText primary={`${pv.getDevice()}  (${formatKnownDataType(pv.getCapacity(), KnownDataType.CAPACITY)})`} />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
});

// ======================
// DestinationSelectionMenu
// ======================
interface DestinationSelectionMenuProps {
    device: GalaxyMigrateStorageConfig.Device;
    wizardState: GmMigrationWizardState;
}

const DestinationSelectionMenu: React.FC<DestinationSelectionMenuProps> = observer((p) => {
    const blockDevice = p.device.getBlockDevice();
    const v = p.wizardState.selectedVolumes[blockDevice.getDeviceName()];
    const enabled = !!v;
    const hasDestination = !!v?.destination;
    const candidates = p.wizardState.getDestinationCandidatesForSourceVolume(p.device);

    const dialogState = useDialogState();

    return (
        <>
            <CurrentDestinationDisplay
                enabled={enabled}
                hasDestination={hasDestination}
                candidates={candidates}
                dialogState={dialogState}
                device={p.device}
                wizardState={p.wizardState}
            />
            <Dialog maxWidth={"sm"} fullWidth open={dialogState.isOpen} onClose={dialogState.close} fullScreen={useShouldDialogFullScreen()}>
                <List>
                    <ListSubheader>{`Select Destination Volume Below`}</ListSubheader>
                    {candidates.map((c) => {
                        return (
                            <ListItem
                                key={c.getBlockDevice().getDeviceName()}
                                button
                                onClick={() => {
                                    v.destination = c;
                                    dialogState.close();
                                }}
                            >
                                <ListItemIcon>
                                    <SvgIcon>
                                        <MdStorage />
                                    </SvgIcon>
                                </ListItemIcon>
                                <ListItemText
                                    primary={`${c.getBlockDevice().getDeviceName()} (${formatKnownDataType(
                                        c.getBlockDevice().getCapacity(),
                                        KnownDataType.CAPACITY
                                    )})`}
                                    secondary={
                                        <>
                                            {renderChipInfo(getGmStorageConfigDeviceType(c))}
                                            {renderChipInfo(c.getBlockDevice().getFsType())}
                                            {renderChipInfo(c.getBlockDevice().getLabel())}
                                            {renderChipInfo(c.getBlockDevice().getMountPoint())}
                                        </>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Dialog>
        </>
    );
});

// ======================
// CurrentDestinationDisplay
// ======================

interface CurrentDestinationDisplayProps {
    enabled: boolean;
    hasDestination: boolean;
    candidates: Array<GalaxyMigrateStorageConfig.Device>;
    dialogState: DialogState;
    device: GalaxyMigrateStorageConfig.Device;
    wizardState: GmMigrationWizardState;
}

export const CurrentDestinationDisplay: React.FC<CurrentDestinationDisplayProps> = observer((p) => {
    const { enabled, hasDestination, candidates, dialogState, device, wizardState } = p;

    const blockDevice = device.getBlockDevice();
    const v = wizardState.selectedVolumes[blockDevice.getDeviceName()];

    const getButtonText = () => {
        if (!enabled) {
            return `Not Selected for Migration`;
        } else if (enabled && !hasDestination && !!candidates.length) {
            return `Select Destination Volume`;
        } else if (enabled && !hasDestination && !candidates.length) {
            return `No Eligible Destination Found`;
        }
    };

    if (!hasDestination) {
        return (
            <Button variant={"outlined"} disabled={!enabled || !candidates.length} onClick={dialogState.open}>
                {getButtonText()}
            </Button>
        );
    } else {
        const d = v.destination;
        return (
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
                <Box pr={4} textAlign={"left"}>
                    <Box pb={1}>
                        <Typography variant={"body1"}>
                            {d.getBlockDevice().getDeviceName()} ({formatKnownDataType(d.getBlockDevice().getCapacity(), KnownDataType.CAPACITY)})
                        </Typography>
                    </Box>
                    <Grid container spacing={1}>
                        {renderChipInfo(getGmStorageConfigDeviceType(d))} &nbsp;
                        {renderChipInfo(d.getBlockDevice().getFsType())} &nbsp;
                        {renderChipInfo(d.getBlockDevice().getLabel())} &nbsp;
                        {renderChipInfo(d.getBlockDevice().getMountPoint())}
                    </Grid>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                    <Box p={0}>
                        <IconButton onClick={dialogState.open}>
                            <EditIcon />
                        </IconButton>
                    </Box>
                    <Box p={0}>
                        <IconButton onClick={() => (v.destination = null)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        );
    }
});

// ======================
// ParametersStep
// ======================
const ParametersStep: React.FC<WizardStepProp> = observer((p) => {
    const navigate = useNavigate();
    const initialValues = p.wizardState.migrationParameters;
    const hostTimezone = p.wizardState.deployment?.data?.getInfo().getDeployment().getSystemTimezone();
    const projectId = useCurrentProjectID();

    const { dialogService, gmMigrationService } = useAppServices();

    const _submit = async (values: FormikValues) => {
        p.wizardState.migrationParameters = values as MigrationParametersType;
        const req = p.wizardState.makeCreateSessionRequest();
        const res = await gmMigrationService.createSession(req);
        navigate(generateMigrationSessionDetailsPath(projectId, res?.getSessionUuid()));
    };

    const formActions = (
        <Grid container justifyContent={"center"}>
            <Box p={1} pt={2} display={"flex"}>
                <Box pr={2}>
                    <Button variant={"contained"} color={"neutral"} onClick={() => p.wizardState.stepperState.goBackOneStep()}>
                        {"Go Back"}
                    </Button>
                </Box>
                <Box>
                    <Button type={"submit"} variant={"contained"} color={"primary"}>{`Create Session`}</Button>
                </Box>
            </Box>
        </Grid>
    );

    return (
        <GmMigrationSessionParametersForm
            initialValues={initialValues}
            onSubmit={_submit}
            formActions={formActions}
            sourceDeployment={p.wizardState.deployment.data}
            isRemoteMigration={p.wizardState.isRemoteMigration}
            isComputeMigration={p.wizardState.isComputeMigration}
            isBootVolumeMigration={p.wizardState.bootVolumeSelected}
            actionsState={p.wizardState.postSyncActionsState}
        />
    );
});

// ======================
// RemoteDestinationSelectionTable
// ======================

interface RemoteDestinationSelectionTableProps {
    wizardState: GmMigrationWizardState;
}

export const RemoteDestinationSelectionTable: React.FC<RemoteDestinationSelectionTableProps> = observer((props) => {
    const { wizardState } = props;
    const { gmDeploymentService } = useAppServices();

    useInitData({
        poll: () => gmDeploymentService.galaxyMigrateLinks.fetchData(wizardState.deploymentId, true),
        pollInterval: 10,
    });

    const cols: ColumnDef<GMLinkInfo>[] = [
        {
            id: "select",
            label: "Select",
            getter: (r) => r,
            renderer: (_, r) => {
                const currentSelectedSource = wizardState.deploymentId;
                const deploymentId = currentSelectedSource === r.getServer().getSystemId() ? r.getClient().getSystemId() : r.getServer().getSystemId();
                const selected = wizardState.selectedRemoteDestinationId === deploymentId;
                const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (selected) {
                        wizardState.deselectRemoteDestination();
                    } else {
                        wizardState.selectRemoteDestination(deploymentId);
                    }
                };
                return <Radio color={"secondary"} checked={selected} onChange={onChange} />;
            },
        },
        {
            id: "systemName",
            label: "Remote Host",
            getter: (r) => (wizardState.deploymentId === r.getServer().getSystemId() ? r.getClient().getSystemName() : r.getServer().getSystemName()),
        },
        {
            id: "connectionAddress",
            label: "Connection Address",
            getter: (r) => r.getLastserveraddress(),
            renderer: (address, r) => {
                return <Typography>{`${formatServerAddressWithoutDefaultPort(address)} (${r.getServer().getSystemName()})`}</Typography>;
            },
        },
        {
            id: "latency",
            label: "Latency",
            getter: (r) => r.getLatency().getNanos(),
            renderer: (latency: number, r) => {
                return <Typography sx={getDeploymentConnectionStyle(r.getConnected())}>{(latency / 1000 / 1000).toFixed(2)}ms</Typography>;
            },
        },
    ];
    return renderServerDataWithLoadingList(gmDeploymentService.galaxyMigrateLinks, (data) => {
        const deployments = data.getItemsList();
        return (
            <>
                <Card>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Box>
                            <ListSubheader>Host-to-Host Connections</ListSubheader>
                        </Box>
                        <Box p={1}>
                            <CreateGalaxyMigrateLinkButton icon />
                        </Box>
                    </Box>
                    <DataTable
                        rows={deployments}
                        rowIdGetter={(r) => r.getLinkid()}
                        state={gmDeploymentService.galaxyMigrateLinks.tableState}
                        pagerMeta={data.getPagerMeta().toObject()}
                        emptyTableTitle={"No Remote Hosts Found"}
                        emptyTableActionButton={<CreateGalaxyMigrateLinkButton variant={"contained"} color={"secondary"} />}
                        onTableStateChange={() => gmDeploymentService.galaxyMigrateLinks.fetchData(wizardState.deploymentId, true)}
                        cols={cols}
                    />
                </Card>
            </>
        );
    });
});

// ======================
// DeploymentsSelectionTable
// ======================

interface DeploymentsSelectionTableProps {
    wizardState: GmMigrationWizardState;
}

const DeploymentsSelectionTable: React.FC<DeploymentsSelectionTableProps> = observer((props) => {
    const t = useTheme();
    const { wizardState } = props;

    const { deploymentService } = useAppServices();

    const navigateToDeploymentsList = useNavigateToDeploymentsList();

    useInitData({
        init: () => deploymentService.galaxyMigrateDeployments.resetData(),
        poll: () => deploymentService.galaxyMigrateDeployments.fetchData(true, true),
        pollInterval: 10,
        deinit: () => deploymentService.galaxyMigrateDeployments.resetData(),
    });

    const cols: ColumnDef<GalaxyMigrateDeploymentInfo>[] = [
        {
            id: "select",
            label: "Select",
            getter: (r) => r,
            renderer: (_, r) => {
                const deploymentId = r.getDeployment().getSystemId();
                const selected = wizardState.deploymentId === deploymentId;
                const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (selected) {
                        wizardState.deselectDeployment();
                    } else {
                        wizardState.selectDeployment(deploymentId);
                    }
                };
                return <Radio color={"secondary"} checked={selected} onChange={onChange} />;
            },
        },
        {
            id: "systemName",
            label: "Name",
            getter: (r) => r.getDeployment().getSystemName(),
            renderer: (name, r) => {
                const hasLicense = !!r.getDeployment().getLicense();
                return (
                    <>
                        <Box>
                            <Typography variant={"body1"}>{name}</Typography>
                        </Box>
                        <HostBasedLicenseView>
                            <Box>
                                <Typography variant={"body2"} color={hasLicense ? t.palette.success.main : "textSecondary"}>
                                    {hasLicense ? `Host License Activated` : `No Activated License`}
                                </Typography>
                            </Box>
                        </HostBasedLicenseView>
                    </>
                );
            },
        },
        {
            id: "version",
            label: "Version",
            getter: (r) => r.getDeployment().getVersion(),
            renderer: (version, r) => {
                return <Typography>{version}</Typography>;
            },
        },
        {
            id: "os",
            label: "OS",
            getter: (r) => `${r.getOsClass()} - ${r.getKernel()}`,
            renderer: (os: string, r) => {
                return (
                    <>
                        <GalaxyMigrationDeploymentOSDisplay deploymentInfo={r} />
                    </>
                );
            },
        },
    ];
    return renderServerDataWithLoadingList(deploymentService.galaxyMigrateDeployments, (data) => {
        const deployments = data.getItemsList();
        const emptyTableTitle = "No Eligible Hosts Found";
        const emptyTableMessage = <>Hosts must be online and connected in order to be selected for migration. </>;
        const goToDeploymentsButton = (
            <Button variant={"contained"} color={"primary"} endIcon={<MdArrowForward />} onClick={navigateToDeploymentsList}>
                Go To Hosts
            </Button>
        );

        return (
            <>
                <DataTable
                    rows={deployments}
                    rowIdGetter={(r) => r.getDeployment().getSystemId()}
                    state={deploymentService.galaxyMigrateDeployments.tableState}
                    pagerMeta={data.getPagerMeta().toObject()}
                    onTableStateChange={() => deploymentService.galaxyMigrateDeployments.fetchData()}
                    emptyTableTitle={emptyTableTitle}
                    emptyTableMessage={emptyTableMessage}
                    emptyTableActionButton={goToDeploymentsButton}
                    cols={cols}
                />
            </>
        );
    });
});

const getStepConfigs = (wizardState: GmMigrationWizardState): StepConfig[] => {
    const deploymentSelectionStepConfig: StepConfig = {
        id: "deploymentSelection",
        label: renderWizardStepText(GmMigrationWizardStep.SOURCE_DEPLOYMENT_SELECTION, wizardState),
        renderer: () => <DeploymentSelectionStep wizardState={wizardState} />,
    };
    const migrationTypeSelectionStepConfig: StepConfig = {
        id: "migrationTypeSelection",
        label: renderWizardStepText(GmMigrationWizardStep.MIGRATION_TYPE, wizardState),
        renderer: () => <MigrationTypeStep wizardState={wizardState} />,
    };

    let stepConfigs = [deploymentSelectionStepConfig, migrationTypeSelectionStepConfig];

    if (getIsComputeMigration(wizardState.selectedMigrationType)) {
        const computeMigrationSourcePrepStepConfig: StepConfig = {
            id: "computeMigrationSourcePrep",
            label: renderWizardStepText(GmMigrationWizardStep.COMPUTE_MIGRATION_SOURCE_PREP, wizardState),
            renderer: () => <GmMigrationWizardComputeMigrationSourcePreparationStep wizardState={wizardState} />,
        };

        stepConfigs.push(computeMigrationSourcePrepStepConfig);
    }

    if (wizardState.selectedMigrationType === GmMigrationType.COMPUTE_VMWARE) {
        const vmwareVmConfig: StepConfig = {
            id: "vmConfig",
            label: renderWizardStepText(GmMigrationWizardStep.COMPUTE_VMWARE_VM_CONFIG, wizardState),
            renderer: () => <GmMigrationWizardVmwareVmConfigStep wizardState={wizardState} />,
        };
        stepConfigs.push(vmwareVmConfig);
    }

    if (wizardState.selectedMigrationType === GmMigrationType.COMPUTE_AZURE) {
        const azureVmConfig: StepConfig = {
            id: "azureVmConfig",
            label: renderWizardStepText(GmMigrationWizardStep.COMPUTE_VMWARE_VM_CONFIG, wizardState),
            renderer: () => <GmMigrationWizardAzureVmConfigStep wizardState={wizardState} />,
        };
        stepConfigs.push(azureVmConfig);
    }

    const volumesSelectionStepConfig: StepConfig = {
        id: "volumesSelection",
        label: renderWizardStepText(GmMigrationWizardStep.VOLUMES_SELECTION, wizardState),
        renderer: () => <VolumesSelectionStep wizardState={wizardState} />,
    };

    const parametersStepConfig: StepConfig = {
        id: "migrationTypeSelection",
        label: renderWizardStepText(GmMigrationWizardStep.MIGRATION_PARAMETERS, wizardState),
        renderer: () => <ParametersStep wizardState={wizardState} />,
    };

    stepConfigs.push(volumesSelectionStepConfig, parametersStepConfig);

    return stepConfigs;
};
