// ======================
// GalaxyMigrateDeploymentRelayActivatedScreen
// ======================

import {observer} from "mobx-react-lite";
import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Link,
    Theme,
    Tooltip,
    Typography
} from "@mui/material";
import {AppHintID, HintButton} from "../../help/HelpCommon";
import React from "react";
import {
    CdcRelayServerClientInfo,
    GetCdcRelayServerInfoResponse
} from "../../../_proto/galaxymigratepb/galaxy_migrate_support_pb";
import {PortNumbers} from "../../../_proto/galaxymigratepb/galaxy_migrate_pb";
import {useAppServices} from "../../app/services";
import {useIsDesktop} from "../../layout/MainLayout";
import {ClipboardButton, ClipboardText} from "../../../common/clipboard/ClipboardComponents";
import {GalaxyMigrateDeploymentDetails} from "../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import {MTDIDeploymentInfo} from "../../../_proto/galaxycompletepb/apipb/domainpb/mtdi_pb";
import {useCurrentProject, useCurrentProjectID} from "../../project/CurrentProject";
import {DEFAULT_PORTAL_HOST} from "../../deployment/DeploymentCommon";
import {getApiHost} from "../../grpc/grpcCommon";
import {ColumnDef} from "../../../common/table/DataTable";
import {SimpleTable} from "../../../common/table/SimpleTable";
import {renderServerDataWithLoadingBox, useInitData} from "../../core/data/DataLoaderHooks";
import {GalaxyMigrationDeploymentOSDisplay} from "../GalaxyMigrateDeploymentsList";
import {convertTimestampObjectToDate, KnownDataType} from "../../../common/utils/formatter";
import {FormattedDisplay} from "../../../common/FormattedDisplay";
import {generateDeploymentDetailsPath, useNavigateToDeploymentDetails} from "../GalaxyMigrateCommon";
import {useNavigate, useParams} from "react-router-dom";
import {ListGalaxyMigrateDeployments} from "../../../_proto/galaxycompletepb/apipb/deployment_api_pb";
import {NewDeploymentInstructions} from "../../deployment/NewDeploymentInstructions";
import {OperatorView} from "../../auth/AuthenticatedViews";

interface GalaxyMigrateDeploymentRelayActivatedScreenProps {
    relayServerInfo: GetCdcRelayServerInfoResponse.AsObject,
    deployment: GalaxyMigrateDeploymentDetails
}

export const GalaxyMigrateDeploymentRelayActivatedScreen: React.FC<GalaxyMigrateDeploymentRelayActivatedScreenProps> = observer((p) => {
    const {gmDeploymentService, dialogService} = useAppServices();
    const isDesktop = useIsDesktop();

    const onDeactivate = async () => {
        const message = `Once CDC Management Relay is deactivated, all hosts connected to this 
        relay server will be disconnected from Cirrus Data Cloud until CDC Management Relay is re-activated.`

        const confirmed = await dialogService.addConfirmDialog({
            message,
            title: 'Deactivate CDC Management Relay Confirmation'
        });
        if (confirmed) {
            await gmDeploymentService.configureRelayServer(null, false);
            await gmDeploymentService.cdcRelayServerStatus.fetchData()
        }
    }

    const serverAddress = p.relayServerInfo.config.address.split('/')[0]

    const isDeploymentWindows = p.deployment?.getInfo()?.getOsType() === MTDIDeploymentInfo.OSType.WINDOWS;

    return <Box>
        <Box display={'flex'} justifyContent={'space-between'}>
            <Box>
                <Typography variant={'h5'}>
                    CDC Management Relay Information
                </Typography>
                <Typography variant={'body1'}>
                    Information for other Cirrus Migrate Cloud hosts to be deployed and connect to Cirrus Data Cloud via
                    this host
                </Typography>
            </Box>
            <Box>
                <HintButton hintID={AppHintID.CMC_RELAY}/>
            </Box>
        </Box>
        <Box pt={2}>
            <Card variant={'outlined'}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={6}>
                                <Box>
                                    <Box>
                                        <Typography variant={'overline'}>
                                            Server Address
                                        </Typography>
                                    </Box>
                                    <Typography variant={'caption'}>
                                        {serverAddress}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box>
                                    <Box>
                                        <Typography variant={'overline'}>
                                            Port
                                        </Typography>
                                    </Box>
                                    <Typography variant={'caption'}>
                                        {isDeploymentWindows ? PortNumbers.CDC_RELAY_HTTP : PortNumbers.CDC_RELAY}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <OperatorView>
                        <Grid item xs={12} md={8} borderLeft={isDesktop ? '2px solid' : 'none'}
                              borderColor={(t: Theme) => t.palette.cirrus.light}>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={2}>
                                <Box>
                                    <Typography variant={'h5'}>
                                        Deactivate CDC Management Relay
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        Stop all new and existing connections to Cirrus Data Cloud via this host
                                    </Typography>
                                </Box>
                                <Box>
                                    <Button variant={'outlined'} color={'error'} onClick={onDeactivate}>Deactivate</Button>
                                </Box>
                            </Box>

                        </Grid>
                    </OperatorView>
                </Grid>
            </Card>
        </Box>
        <Box pt={4}>
            <Box pb={2}>
                <Typography variant={'h5'}>
                    Cirrus Migrate Cloud Deployment
                </Typography>
                <Typography>
                    Use the following commands to install / uninstall Cirrus Migrate Cloud via Management Relay
                </Typography>
            </Box>
            <Card>
                <NewDeploymentInstructions relayAddress={serverAddress}/>
            </Card>
        </Box>
        <Box pt={2}>
            <ConnectedClientsTable clientsList={p.relayServerInfo.clientsList}/>
        </Box>
    </Box>
});

// ======================
// ConnectedClientsTable
// ======================

interface ConnectedClientsTableProps {
    clientsList: Array<CdcRelayServerClientInfo.AsObject>
}

const ConnectedClientsTable: React.FC<ConnectedClientsTableProps> = observer((p) => {
    const navigate = useNavigate();
    const { projectId } = useParams();

    const {deploymentService} = useAppServices();

    const refresh = async(): Promise<ListGalaxyMigrateDeployments.Response> => {
        if (p.clientsList.length > 0){
            await deploymentService.galaxyMigrateDeployments.fetchData(false, true, null, p.clientsList.map(c => c.systemId || null))
        }
        return new ListGalaxyMigrateDeployments.Response().setItemsList([]);
    }

    useInitData({
        poll: refresh,
        pollInterval: 3
    })

    return renderServerDataWithLoadingBox(deploymentService.galaxyMigrateDeployments, data => {
        const cols: ColumnDef<CdcRelayServerClientInfo.AsObject>[] = [
            {
                id: 'ip',
                label: 'IP',
                getter: r => r.ip
            },
            {
                id: 'host',
                label: 'Host Name',
                getter: r => r.systemId,
                renderer: (v, r) => {
                    const deployment = data.getItemsList().find(d => d.getDeployment().getSystemId() === v)
                    return deployment ? <Link onClick={()=>navigate(generateDeploymentDetailsPath(v, projectId))}>{deployment.getDeployment().getSystemName()}</Link> : '--'
                }
            },
            {
                id: 'os',
                label: 'OS',
                getter: r => r.systemId,
                renderer: (v, r) => {
                    const deployment = data.getItemsList().find(d => d.getDeployment().getSystemId() === v)
                    return deployment ? <GalaxyMigrationDeploymentOSDisplay deploymentInfo={deployment} hideKernel/> : '--'
                },
            },
            {
                id: 'installer-req-relayed',
                label: 'Installer Req. Relayed',
                getter: r => r.totalInstallationApiRequests,
                dataType: KnownDataType.NUMBER
            },
            {
                id: 'outbound-relayed',
                label: 'Total Outbound Req. Relayed',
                getter: r => r,
                renderer: (_, r) => {
                    return <Box textAlign={'right'}>
                        <Box display={'flex'} justifyContent={'flex-end'}>
                            <Tooltip title={'OK Response'} arrow>
                                <Typography sx={{color: (t: Theme) => t.palette.success.main}}>
                                    {r.totalConnRequestsOk}
                                </Typography>
                            </Tooltip>
                            <Typography>
                                &nbsp; | &nbsp;
                            </Typography>
                            <Tooltip title={'Error Response'}>
                                <Typography sx={{color: (t: Theme) => t.palette.error.main}}>
                                    {r.totalConnRequestsError}
                                </Typography>
                            </Tooltip>
                        </Box>

                    </Box>
                },
                dataType: KnownDataType.NUMBER
            },
            {
                id: 'last-req-time',
                label: 'Last Req. Time',
                getter: r => r,
                renderer: (_, r) => {
                    return <FormattedDisplay dataType={KnownDataType.DATE_RELATIVE}
                                             value={convertTimestampObjectToDate(r.lastRequest)}/>
                }
            },
            {
                id: 'latency',
                label: 'Total Latency',
                getter: r => r.systemId,
                renderer: (v, r) => {
                    const deployment = data.getItemsList().find(d => d.getDeployment().getSystemId() === v);
                    return deployment ? <FormattedDisplay dataType={KnownDataType.DURATION_MILLISECONDS}
                                                          value={deployment.getDeployment().getConnectionLatency()}/> : '--'
                }
            }

        ]

        return <Box pt={2}>
            <Box>
                <Typography variant={'h5'}>
                    Connected Clients
                </Typography>
                <Typography variant={'body1'}>
                    The following list of hosts are currently connected to Cirrus Data Cloud via this host
                </Typography>
            </Box>
            <br/>
            <SimpleTable cols={cols} rows={p.clientsList}/>
        </Box>
    })
})