import {observer} from "mobx-react-lite";
import {useAppServices} from "../../app/services";
import {useParams} from "react-router-dom";
import {renderServerDataWithLoadingBox, useInitData} from "../../core/data/DataLoaderHooks";
import {ScreenContainer, ScreenTitleBar} from "../../layout/ScreenCommon";
import * as React from "react";
import {convertQosScheduleToFormValues, getGmSessionIDSlug, useNavigateToMigrationSessionDetails} from "../MigrationCommon";
import {GmMigrationSessionParametersForm, MigrationParametersType} from "../wizard/GmMigrationSessionParametersForm";
import {Box, Button} from "@mui/material";
import {FormikValues} from "formik";
import {getPostSyncActionDefFromAction, PostSyncAction} from "../workflow/GmMigrationSessionPostSyncActions";

// ======================
// GmSessionDetailsEditScreen
// ======================


interface GmSessionDetailsEditScreenProps{
}

export const GmSessionDetailsEditScreen: React.FC<GmSessionDetailsEditScreenProps> = observer((props)=>{

    const {gmMigrationService, deploymentService, progressService} = useAppServices();
    const {sessionId}          = useParams();
    const navigateBackToSession = useNavigateToMigrationSessionDetails();

    const init = async() => {
        gmMigrationService.setCurrentSessionID(sessionId);
        await gmMigrationService.currentSession.fetchData();
        await deploymentService.currentDeployment.fetchData(gmMigrationService.currentSession.data?.getSessionInfo().getDeployment().getSystemId())
        gmMigrationService.currentSessionEditState.initEditState(sessionId, gmMigrationService.currentSession.data);
        gmMigrationService.currentSessionEditState.postSyncActionsState.setMigrationVolumeNames(gmMigrationService.currentSession.data.getSessionInfo().getSessionVolumesList().map(v=>v.getDestination().getName()));
        const workflowId = gmMigrationService.currentSession.data.getSessionInfo().getPostSyncWorkflowId();
        if (workflowId){
            await gmMigrationService.currentSessionWorkflow.fetchData(workflowId);
            gmMigrationService.currentSessionWorkflow.data.getStagesList().forEach((s)=> {
                const action : PostSyncAction = {
                    config: getPostSyncActionDefFromAction(s.getAction()),
                    workflowStage: s
                }
                gmMigrationService.currentSessionEditState.postSyncActionsState.addAction(action);
            });
        }
    }

    useInitData({
        init        : () => init(),
    });

    return (<ScreenContainer>

        {!!gmMigrationService.currentSessionEditState.postSyncActionsState && renderServerDataWithLoadingBox(gmMigrationService.currentSession, data => {
            let initialValues: MigrationParametersType = {
                qosLevel: data.getSessionInfo().getQosLevel(),
                remoteBootMigrationPostCutoverScript :data.getSessionInfo().getRemoteBootMigrationPostCutoverScript(),
                description: data.getSessionInfo().getDescription(),
                autoReSyncInterval: data.getSessionInfo().getAutoSyncMinutes(),
                qosStartTime: data.getSessionInfo().getQosStartTime()?.toDate() || null,
                qosSchedule: data.getSessionInfo().hasQosSchedule() ? convertQosScheduleToFormValues(data.getSessionInfo().getQosSchedule()) :
                        gmMigrationService.currentSessionEditState.migrationParameters.qosSchedule
            }

            const formActions = <Box display={'flex'} width={'100%'} justifyContent={'center'}>
                <Button type={'submit'} variant={'contained'} color={'primary'}>
                    Update Session Details
                </Button>
            </Box>

            const _submit = async(values: FormikValues) => {
                gmMigrationService.currentSessionEditState.migrationParameters = values as MigrationParametersType;
                await progressService.track(gmMigrationService.currentSessionEditState.updateMigrationSessionDetails());
                await gmMigrationService.currentSession.fetchData();
                const workflowId = gmMigrationService.currentSession.data.getSessionInfo().getPostSyncWorkflowId();
                if (!!workflowId){
                    await gmMigrationService.currentSessionWorkflow.fetchData(workflowId);
                } else{
                    gmMigrationService.currentSessionWorkflow.resetData();
                }
                navigateBackToSession()
            };
            const sessionInfo =gmMigrationService.currentSession.data.getSessionInfo().toObject();
            return <>
                <ScreenTitleBar title={`Edit Migration Session ${getGmSessionIDSlug(data.getSessionInfo().getSessionId())}`}/>
                <GmMigrationSessionParametersForm initialValues={initialValues}
                                                  onSubmit={_submit}
                                                  formActions={formActions}
                                                  actionsState={gmMigrationService.currentSessionEditState.postSyncActionsState}
                                                  sourceDeployment={deploymentService.currentDeployment.data}
                                                  isRemoteMigration={sessionInfo.deployment.systemId !== sessionInfo.destinationDeployment?.systemId}
                                                  isBootVolumeMigration={sessionInfo.bootVolumeSession}
                                                  isComputeMigration={sessionInfo.isComputeMigration}
                />
                </>
        })}
    </ScreenContainer>);
})

