import {observer} from "mobx-react-lite";
import {Box, Card, Grid, Typography, useTheme} from "@mui/material";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {format} from "date-fns";
import * as React from "react";
import {
    generateChartData,
    generateChartValuesMap,
    getKnownDataTypeOfStatType,
    getPerformanceChartConfigs,
    IO_TYPE
} from "./GalaxyMigrateDeploymentPerformanceHelpers";
import {GalaxyMigrateStorageConfig} from "../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import {IOStatsDataSet} from "../../../_proto/galaxymigratepb/galaxy_migrate_storage_pb";
import {IoSquare} from "react-icons/io5";
import {GetIOStats} from "../../../_proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import {getAxesMaxValue, getYAxisTickValues} from "../../../common/utils/statsUtil";

// ======================
// GalaxyMigrateDeploymentPerformanceChartSection
// ======================


interface GalaxyMigrateDeploymentPerformanceChartSectionProps {
    data: GetIOStats.Response.AsObject
    volList: GalaxyMigrateStorageConfig.Device[],
    ioType: IO_TYPE
}

export const GalaxyMigrateDeploymentPerformanceChartSection: React.FC<GalaxyMigrateDeploymentPerformanceChartSectionProps> = observer((p) => {
    const {volList, data, ioType} = p;

    const devicePathList = data.data.seriesListList.map(s=>s.domain.devicePathsList)

    const chartValuesMap = generateChartValuesMap(devicePathList, volList)
    return <>
        <GlobalChartLegend keyAttributesMap={chartValuesMap}/>
        {getPerformanceChartConfigs().filter(c=>{
            if (ioType !== IO_TYPE.ALL_IO){
                return c.statType !== 'pending'
            }
            return true;
        }).map(config=> {
            return <GalaxyMigrateDeploymentPerformanceChart title={config.title} keyAttributesMap={chartValuesMap}
                                                            seriesList={data.data.seriesListList}
                                                            ioType={ioType}
                                                            statType={config.statType}
                                                            yAxisFormatter={config.yAxisFormatter}
                                                            volList={volList}
                                                            live={data.data.timeDomain.live}
            />
        })}
        <GlobalChartLegend keyAttributesMap={chartValuesMap}/>

    </>

});

// ======================
// GlobalChartLegend
// ======================


interface GlobalChartLegendProps {
    keyAttributesMap: Map<string, { color: string, label: string }>,
}

export const GlobalChartLegend: React.FC<GlobalChartLegendProps> = observer((p) => {
    const {keyAttributesMap} = p;

    return <Grid container spacing={2} pt={2}>
        {Array.from(keyAttributesMap.keys()).map(key=>{
            return <Grid item key={key}>
                <Box display={'flex'} alignItems={'center'}>
                        <IoSquare color={keyAttributesMap.get(key).color}/>
                    <Typography>
                        &nbsp;{keyAttributesMap.get(key).label}
                    </Typography>
                </Box>

            </Grid>
        })

        }
    </Grid>

});

// ======================
// GalaxyMigrateDeploymentPerformanceChart
// ======================

interface GalaxyMigrateDeploymentPerformanceChartProps {
    title: string,
    keyAttributesMap: Map<string, { color: string, label: string }>,
    seriesList: Array<IOStatsDataSet.Series.AsObject>
    ioType: IO_TYPE,
    yAxisFormatter: (value: number) => string,
    statType: keyof IOStatsDataSet.Stats.AsObject //eventually keyOf Stat
    volList: Array<GalaxyMigrateStorageConfig.Device>,
    live: boolean
}

export const GalaxyMigrateDeploymentPerformanceChart: React.FC<GalaxyMigrateDeploymentPerformanceChartProps> = observer((p) => {
    const {title, keyAttributesMap, ioType, yAxisFormatter, statType, seriesList, volList, live} = p;
    const theme = useTheme();
    const keys = Array.from(keyAttributesMap.keys());
    const readAndWriteOptions = ioType === IO_TYPE.ALL_IO ? 'Read + Write' : (ioType === IO_TYPE.WRITE) ? 'Write Only' : 'Read Only'

    const chartData = generateChartData(statType, seriesList, volList)
    const allY = chartData.map(point=>{
        const array: number[] = []
        for (let key in point){

            if (typeof point[key] === 'number'){
                array.push(point[key])
            }
        }
        return array;
    }).flat();


    const yMax = getAxesMaxValue(getKnownDataTypeOfStatType(statType), allY);
    const tickDividend = statType === 'avgLatency' ? 5 : 4
    const yTicks = getYAxisTickValues(0, yMax, tickDividend)


    return <Card sx={{marginTop: theme.spacing(2), marginBottom: theme.spacing(2)}}>
        <Box p={2} width={'100%'} height={250}>
            <Typography variant={'h6'}>
                {title} ({readAndWriteOptions})
            </Typography>
            <ResponsiveContainer width={'100%'} height={'100%'}>
                <LineChart data={chartData} margin={{top: 10, right: 20, bottom: 20, left: 20}}
                           syncId={'deploymentPerformanceStats'}>
                    <XAxis dataKey={'time'}
                           stroke={'rgb(255,255,255,.6)'}
                           fontSize={10}
                           fontFamily={theme.typography.fontFamily}
                           tickFormatter={(value: Date, index) => format(value, 'HH:mm:ss')}
                           tickMargin={5}
                    />
                    <YAxis fontFamily={theme.typography.fontFamily}
                           color={'white'}
                           stroke={'rgb(255,255,255,.6)'}
                           scale={'linear'}
                           fontSize={10}
                           width={100}
                           type={'number'}
                           ticks={yTicks}
                           tickFormatter={yAxisFormatter}
                           domain={[0, 'dataMax']}

                    />
                    <CartesianGrid stroke={'rgb(255,255,255,.4)'}/>
                    <Tooltip cursor={{stroke: 'red', strokeWidth: 2}}
                             wrapperStyle={{fontFamily: theme.typography.fontFamily, outline: 'none'}}
                             contentStyle={{backgroundColor: theme.palette.cirrus.main}}
                             labelFormatter={(label) => {
                                 return live ? format(label, 'hh:mm:ss a') : format(label, 'MM/dd/yyyy hh:mm:ss a')

                             }}
                             formatter={(value, name, props) => [yAxisFormatter(value as number), keyAttributesMap.get(name as string).label]}

                    />
                    {keys.map(key => {
                        return <Line type="monotone" dataKey={key} key={key}
                                     activeDot={{r: 4}}
                                     stroke={keyAttributesMap.get(key).color}
                                     dot={false}
                                     isAnimationActive={!live}
                                     strokeWidth={3}/>

                    })
                    }
                </LineChart>
            </ResponsiveContainer>

        </Box>

    </Card>

});