import {observer} from "mobx-react-lite";
import {ScreenContainer, ScreenTitleBar} from "../layout/ScreenCommon";
import React from "react";
import {ActionConfig, ActionMenuButton} from "../../common/actions/CommonActions";
import {BackButton} from "../../common/CommonButtons";
import {
    getModuleDefByModule,
    IntegrationCard,
    useNavigateToEditIntegrationForm,
    useNavigateToEnabledIntegrations
} from "./IntegrationsCommon";
import {
    Box, Button,
    Card, CardContent,
    Dialog,
    DialogContent, DialogContentText,
    Divider, FormHelperText,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListSubheader, SvgIcon, Theme,
    Typography
} from "@mui/material";
import {IntegrationsIcon} from "../project/ProjectCommon";
import {DeleteIcon, EditIcon} from "../../common/CommonIcons";
import {renderServerDataWithLoadingBox, useInitData} from "../core/data/DataLoaderHooks";
import {useAppServices} from "../app/services";
import {useParams} from "react-router-dom";
import {IntegrationConfigInfo} from "../../_proto/galaxycompletepb/apipb/domainpb/integration_pb";
import {formatKnownDataType, KnownDataType} from "../../common/utils/formatter";
import {getUserFullName} from "../settings/ProjectUsers";
import {useGmAutoAllocationState} from "../migration/autoallocation/GmAutoAllocationCommon";
import {DialogState, useDialogState} from "../core/dialog/DialogService";
import {DialogTopBar} from "../core/dialog/DialogComponents";
import {Form, Formik} from "formik";
import {
    VerifyIntegrationConnection
} from "./IntegrationForm/IntegrationForm";
import {
    GalaxyMigrateAutoAllocationProgressDialog
} from "../migration/autoallocation/GmAutoAllocationProgress";
import {BsGearFill} from "react-icons/bs";
import {OperatorView} from "../auth/AuthenticatedViews";
import {v4 as uuid} from 'uuid';


// ======================
// EnabledIntegrationsDetails
// ======================


interface EnabledIntegrationsDetailsProps {
}

export const EnabledIntegrationsDetails: React.FC<EnabledIntegrationsDetailsProps> = observer((p) => {
    const {integrationsService, dialogService, progressService} = useAppServices();
    const goBackToEnabledIntegrations = useNavigateToEnabledIntegrations();
    const {integrationId} = useParams();
    const goToEditForm = useNavigateToEditIntegrationForm();
    const id = parseInt(integrationId);
    const testIntegrationDialogState = useDialogState();

    const editIntegration = async () => {
        await integrationsService.currentIntegration.fetchData();
        integrationsService.initEditIntegrationState();
        goToEditForm(integrationId);
    }

    const getActions = (): ActionConfig[] => {
        return [
            {
                id: 'edit',
                name: 'Edit Integration',
                action: editIntegration,
                icon: <EditIcon/>
            },
            {
                id: 'test',
                name: 'Test Integration',
                action: () => {
                    testIntegrationDialogState.setDialogProps({id: id})
                    testIntegrationDialogState.open()
                },
                icon: <IntegrationsIcon/>
            },
            {
                id: 'remove',
                name: 'Remove Integration',
                action: async () => {
                    const confirmed = await dialogService.addConfirmDialog({
                        message: 'Are you sure you want to remove this integration?',
                        autoConfirmationQuestionLine: false
                    });
                    if (confirmed) {
                        await integrationsService.removeProjectIntegration(id);
                        goBackToEnabledIntegrations()

                    }
                },
                icon: <DeleteIcon/>
            }
        ]
    }

    const refresh = async () => {
        await integrationsService.currentIntegration.fetchData(id);
    }

    useInitData({
        poll: refresh,
        pollInterval: 3,
        deinit: () => integrationsService.currentIntegration.resetData()
    })

    return renderServerDataWithLoadingBox(integrationsService.currentIntegration, data => {
        return <ScreenContainer>
            <BackButton navFunction={goBackToEnabledIntegrations} label={'Back to Enabled Integrations'}/>
            <ScreenTitleBar title={data.getInfo().getFriendlyName()}
                            actions={<OperatorView>
                                <ActionMenuButton actions={getActions()}
                                                  buttonLabel={'Integration Actions'}
                                                  buttonProps={{
                                                      endIcon: <SvgIcon><BsGearFill/></SvgIcon>,
                                                      color: 'secondary',
                                                      variant: 'contained'
                                                  }}/>
                            </OperatorView>}/>
            <IntegrationDetails data={data.getInfo()}/>
            <TestIntegrationDialog dialogState={testIntegrationDialogState}/>
            <GalaxyMigrateAutoAllocationProgressDialog actionLabel={'Close'}/>
        </ScreenContainer>
    })

});

// ======================
// IntegrationDetails
// ======================

interface IntegrationDetailsProps {
    data: IntegrationConfigInfo
}

export const IntegrationDetails: React.FC<IntegrationDetailsProps> = observer((p) => {

    return <Grid container spacing={3} justifyContent={'flex-start'}>
        <Grid item xs={12} sm={6} md={3} xl={2}>
            <IntegrationCard module={getModuleDefByModule(p.data.getModule())} cardProps={{sx: {height:'100%', width: '100%'}}}/>
        </Grid>
        <Grid item xs={12} sm={6} md={9} xl={10}>
            <Card sx={{height: '100%', width: '100%'}}>
                <CardContent>
                    <Grid container spacing={3} component={Box} p={2}>
                        <ListSubheader>Integration Information</ListSubheader>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <List>
                                    <ListItem>
                                        <ListItemText primary={p.data.getFriendlyName()} secondary={`Name`}/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={p.data.getDescription()} secondary={`Description`}/>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary={formatKnownDataType(p.data.getEnabledAt().toDate(), KnownDataType.DATE)}
                                            secondary={`Enabled At`}/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={getUserFullName(p.data.getEnabledBy())}
                                                      secondary={`Enabled By`}/>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>

            </Card>

        </Grid>
    </Grid>;
})

// ======================
// TestIntegrationDialog
// ======================

interface TestIntegrationDialogProps {
    dialogState: DialogState,
}

export const TestIntegrationDialog: React.FC<TestIntegrationDialogProps> = observer((p) => {
    const {deploymentService, progressService, integrationsService, gmMigrationService} = useAppServices();
    const state = useGmAutoAllocationState();
    const id = p.dialogState.dialogProps?.id;

    const initialValues = {
        deploymentId: ''
    }

    if (id >= 0) {
        return <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close}>
            <DialogTopBar dialogState={p.dialogState} title={'Test Integration Connection'}/>
            <DialogContent>
                <DialogContentText>
                    Choose a host to test the integration:
                </DialogContentText>
                <br/>
                <Formik initialValues={initialValues}
                        onSubmit={async (values) => {
                            const deploymentDetails = await deploymentService.fetchDeploymentDetails(values.deploymentId);
                            state.setDeployment(deploymentDetails);
                            const statusId = uuid();
                            state.setNewStatusId(statusId);
                            state.setShowProgressDialog(true);
                            await integrationsService.testProjectIntegration(id, values.deploymentId, statusId);
                            await state.waitForProgressDialogToClose();
                            p.dialogState.close()
                        }}>
                    {props => {
                        return <Form>
                            <Box pb={2}>
                                <VerifyIntegrationConnection required/>
                                <Box display={'flex'}>
                                </Box>

                                <Box  display={'flex'} justifyContent={'center'} pt={2}>
                                    <Button variant={'contained'} color={'primary'} type={'submit'}>
                                        Test Connection
                                    </Button>
                                </Box>
                            </Box>
                        </Form>;
                    }}
                </Formik>
            </DialogContent>


        </Dialog>
    }
    ;

    return null;


})