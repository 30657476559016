// source: galaxymigratepb/galaxy_migrate_compute.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var galaxymigratepb_galaxy_migrate_storage_pb = require('../galaxymigratepb/galaxy_migrate_storage_pb.js');
goog.object.extend(proto, galaxymigratepb_galaxy_migrate_storage_pb);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.ConnInfo', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.Datastore', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.FirmwareType', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.HelperAssignedDisk', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.Network', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.PowerOnVmRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.PowerOnVmResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.ResourcePool', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.ScsiControllerType', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.Vm', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.VmBuildSpec', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig', null, global);
goog.exportSymbol('proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.displayName = 'proto.galaxymigrate.VmwareCompute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest.displayName = 'proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse.displayName = 'proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest.displayName = 'proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse.displayName = 'proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest.displayName = 'proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.displayName = 'proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest.displayName = 'proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse.displayName = 'proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest.displayName = 'proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.displayName = 'proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.PowerOnVmRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.PowerOnVmRequest.displayName = 'proto.galaxymigrate.VmwareCompute.PowerOnVmRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.PowerOnVmResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.PowerOnVmResponse.displayName = 'proto.galaxymigrate.VmwareCompute.PowerOnVmResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.displayName = 'proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse.displayName = 'proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.displayName = 'proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.displayName = 'proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.displayName = 'proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.displayName = 'proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse.displayName = 'proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.ResourcePool = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.ResourcePool, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.ResourcePool.displayName = 'proto.galaxymigrate.VmwareCompute.ResourcePool';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.Datastore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.Datastore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.Datastore.displayName = 'proto.galaxymigrate.VmwareCompute.Datastore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.Network = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.Network, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.Network.displayName = 'proto.galaxymigrate.VmwareCompute.Network';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.Vm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.Vm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.Vm.displayName = 'proto.galaxymigrate.VmwareCompute.Vm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.HelperAssignedDisk = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.HelperAssignedDisk, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.displayName = 'proto.galaxymigrate.VmwareCompute.HelperAssignedDisk';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.ConnInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.ConnInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.ConnInfo.displayName = 'proto.galaxymigrate.VmwareCompute.ConnInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.VmwareCompute.VmBuildSpec.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.VmBuildSpec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.VmBuildSpec.displayName = 'proto.galaxymigrate.VmwareCompute.VmBuildSpec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.displayName = 'proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.displayName = 'proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute}
 */
proto.galaxymigrate.VmwareCompute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute;
  return proto.galaxymigrate.VmwareCompute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute}
 */
proto.galaxymigrate.VmwareCompute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.galaxymigrate.VmwareCompute.ScsiControllerType = {
  UNKNOWN_SCSI_CONTROLLER: 0,
  PVSCSI: 1,
  LSI: 2
};

/**
 * @enum {number}
 */
proto.galaxymigrate.VmwareCompute.FirmwareType = {
  BIOS: 0,
  EFI: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    conn: (f = msg.getConn()) && proto.galaxymigrate.VmwareCompute.ConnInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest}
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest;
  return proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest}
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.VmwareCompute.ConnInfo;
      reader.readMessage(value,proto.galaxymigrate.VmwareCompute.ConnInfo.deserializeBinaryFromReader);
      msg.setConn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.VmwareCompute.ConnInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ConnInfo conn = 1;
 * @return {?proto.galaxymigrate.VmwareCompute.ConnInfo}
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest.prototype.getConn = function() {
  return /** @type{?proto.galaxymigrate.VmwareCompute.ConnInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.VmwareCompute.ConnInfo, 1));
};


/**
 * @param {?proto.galaxymigrate.VmwareCompute.ConnInfo|undefined} value
 * @return {!proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest} returns this
*/
proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest.prototype.setConn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest} returns this
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest.prototype.clearConn = function() {
  return this.setConn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperRequest.prototype.hasConn = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    env: (f = msg.getEnv()) && proto.galaxymigrate.VmwareCompute.Vm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse}
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse;
  return proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse}
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.galaxymigrate.VmwareCompute.Vm;
      reader.readMessage(value,proto.galaxymigrate.VmwareCompute.Vm.deserializeBinaryFromReader);
      msg.setEnv(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnv();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.VmwareCompute.Vm.serializeBinaryToWriter
    );
  }
};


/**
 * optional Vm env = 2;
 * @return {?proto.galaxymigrate.VmwareCompute.Vm}
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse.prototype.getEnv = function() {
  return /** @type{?proto.galaxymigrate.VmwareCompute.Vm} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.VmwareCompute.Vm, 2));
};


/**
 * @param {?proto.galaxymigrate.VmwareCompute.Vm|undefined} value
 * @return {!proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse} returns this
*/
proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse.prototype.setEnv = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse} returns this
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse.prototype.clearEnv = function() {
  return this.setEnv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.VmwareCompute.ConfigureHelperResponse.prototype.hasEnv = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest}
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest;
  return proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest}
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    env: (f = msg.getEnv()) && proto.galaxymigrate.VmwareCompute.Vm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse}
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse;
  return proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse}
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.galaxymigrate.VmwareCompute.Vm;
      reader.readMessage(value,proto.galaxymigrate.VmwareCompute.Vm.deserializeBinaryFromReader);
      msg.setEnv(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnv();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.VmwareCompute.Vm.serializeBinaryToWriter
    );
  }
};


/**
 * optional Vm env = 2;
 * @return {?proto.galaxymigrate.VmwareCompute.Vm}
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse.prototype.getEnv = function() {
  return /** @type{?proto.galaxymigrate.VmwareCompute.Vm} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.VmwareCompute.Vm, 2));
};


/**
 * @param {?proto.galaxymigrate.VmwareCompute.Vm|undefined} value
 * @return {!proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse} returns this
*/
proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse.prototype.setEnv = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse} returns this
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse.prototype.clearEnv = function() {
  return this.setEnv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.VmwareCompute.IdentifyHelperResponse.prototype.hasEnv = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest}
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest;
  return proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest}
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reason: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ready: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    unsupported: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    scsiControllerType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    firmwareType: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse}
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse;
  return proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse}
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReady(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnsupported(value);
      break;
    case 10:
      var value = /** @type {!proto.galaxymigrate.VmwareCompute.ScsiControllerType} */ (reader.readEnum());
      msg.setScsiControllerType(value);
      break;
    case 11:
      var value = /** @type {!proto.galaxymigrate.VmwareCompute.FirmwareType} */ (reader.readEnum());
      msg.setFirmwareType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReady();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getUnsupported();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getScsiControllerType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getFirmwareType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string reason = 1;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse} returns this
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool ready = 2;
 * @return {boolean}
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.prototype.getReady = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse} returns this
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.prototype.setReady = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool unsupported = 3;
 * @return {boolean}
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.prototype.getUnsupported = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse} returns this
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.prototype.setUnsupported = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional ScsiControllerType scsi_controller_type = 10;
 * @return {!proto.galaxymigrate.VmwareCompute.ScsiControllerType}
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.prototype.getScsiControllerType = function() {
  return /** @type {!proto.galaxymigrate.VmwareCompute.ScsiControllerType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.galaxymigrate.VmwareCompute.ScsiControllerType} value
 * @return {!proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse} returns this
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.prototype.setScsiControllerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional FirmwareType firmware_type = 11;
 * @return {!proto.galaxymigrate.VmwareCompute.FirmwareType}
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.prototype.getFirmwareType = function() {
  return /** @type {!proto.galaxymigrate.VmwareCompute.FirmwareType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.galaxymigrate.VmwareCompute.FirmwareType} value
 * @return {!proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse} returns this
 */
proto.galaxymigrate.VmwareCompute.IsSourceReadyForMigrationResponse.prototype.setFirmwareType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest}
 */
proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest;
  return proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest}
 */
proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse}
 */
proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse;
  return proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse}
 */
proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.PrepareSourceForMigrationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest}
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest;
  return proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest}
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    networksList: jspb.Message.toObjectList(msg.getNetworksList(),
    proto.galaxymigrate.VmwareCompute.Network.toObject, includeInstance),
    resourcePoolsList: jspb.Message.toObjectList(msg.getResourcePoolsList(),
    proto.galaxymigrate.VmwareCompute.ResourcePool.toObject, includeInstance),
    foldersList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    datastoreListList: jspb.Message.toObjectList(msg.getDatastoreListList(),
    proto.galaxymigrate.VmwareCompute.Datastore.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse}
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse;
  return proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse}
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.VmwareCompute.Network;
      reader.readMessage(value,proto.galaxymigrate.VmwareCompute.Network.deserializeBinaryFromReader);
      msg.addNetworks(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.VmwareCompute.ResourcePool;
      reader.readMessage(value,proto.galaxymigrate.VmwareCompute.ResourcePool.deserializeBinaryFromReader);
      msg.addResourcePools(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addFolders(value);
      break;
    case 4:
      var value = new proto.galaxymigrate.VmwareCompute.Datastore;
      reader.readMessage(value,proto.galaxymigrate.VmwareCompute.Datastore.deserializeBinaryFromReader);
      msg.addDatastoreList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNetworksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxymigrate.VmwareCompute.Network.serializeBinaryToWriter
    );
  }
  f = message.getResourcePoolsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.galaxymigrate.VmwareCompute.ResourcePool.serializeBinaryToWriter
    );
  }
  f = message.getFoldersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getDatastoreListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.galaxymigrate.VmwareCompute.Datastore.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Network networks = 1;
 * @return {!Array<!proto.galaxymigrate.VmwareCompute.Network>}
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.getNetworksList = function() {
  return /** @type{!Array<!proto.galaxymigrate.VmwareCompute.Network>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.VmwareCompute.Network, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.VmwareCompute.Network>} value
 * @return {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse} returns this
*/
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.setNetworksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.VmwareCompute.Network=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.VmwareCompute.Network}
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.addNetworks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.VmwareCompute.Network, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse} returns this
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.clearNetworksList = function() {
  return this.setNetworksList([]);
};


/**
 * repeated ResourcePool resource_pools = 2;
 * @return {!Array<!proto.galaxymigrate.VmwareCompute.ResourcePool>}
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.getResourcePoolsList = function() {
  return /** @type{!Array<!proto.galaxymigrate.VmwareCompute.ResourcePool>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.VmwareCompute.ResourcePool, 2));
};


/**
 * @param {!Array<!proto.galaxymigrate.VmwareCompute.ResourcePool>} value
 * @return {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse} returns this
*/
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.setResourcePoolsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxymigrate.VmwareCompute.ResourcePool=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.VmwareCompute.ResourcePool}
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.addResourcePools = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxymigrate.VmwareCompute.ResourcePool, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse} returns this
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.clearResourcePoolsList = function() {
  return this.setResourcePoolsList([]);
};


/**
 * repeated string folders = 3;
 * @return {!Array<string>}
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.getFoldersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse} returns this
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.setFoldersList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse} returns this
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.addFolders = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse} returns this
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.clearFoldersList = function() {
  return this.setFoldersList([]);
};


/**
 * repeated Datastore datastore_list = 4;
 * @return {!Array<!proto.galaxymigrate.VmwareCompute.Datastore>}
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.getDatastoreListList = function() {
  return /** @type{!Array<!proto.galaxymigrate.VmwareCompute.Datastore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.VmwareCompute.Datastore, 4));
};


/**
 * @param {!Array<!proto.galaxymigrate.VmwareCompute.Datastore>} value
 * @return {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse} returns this
*/
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.setDatastoreListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.galaxymigrate.VmwareCompute.Datastore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.VmwareCompute.Datastore}
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.addDatastoreList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.galaxymigrate.VmwareCompute.Datastore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse} returns this
 */
proto.galaxymigrate.VmwareCompute.ListHelperResourcesSelectionsResponse.prototype.clearDatastoreListList = function() {
  return this.setDatastoreListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.PowerOnVmRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.PowerOnVmRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vmUuid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.PowerOnVmRequest}
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.PowerOnVmRequest;
  return proto.galaxymigrate.VmwareCompute.PowerOnVmRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.PowerOnVmRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.PowerOnVmRequest}
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVmUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.PowerOnVmRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.PowerOnVmRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVmUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string vm_uuid = 1;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmRequest.prototype.getVmUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.PowerOnVmRequest} returns this
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmRequest.prototype.setVmUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.PowerOnVmResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.PowerOnVmResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.PowerOnVmResponse}
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.PowerOnVmResponse;
  return proto.galaxymigrate.VmwareCompute.PowerOnVmResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.PowerOnVmResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.PowerOnVmResponse}
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.PowerOnVmResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.PowerOnVmResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.PowerOnVmResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    disksList: jspb.Message.toObjectList(msg.getDisksList(),
    proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.toObject, includeInstance),
    keepFiles: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest}
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest;
  return proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest}
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.galaxymigrate.VmwareCompute.HelperAssignedDisk;
      reader.readMessage(value,proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.deserializeBinaryFromReader);
      msg.addDisks(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKeepFiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.serializeBinaryToWriter
    );
  }
  f = message.getKeepFiles();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated HelperAssignedDisk disks = 2;
 * @return {!Array<!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk>}
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.prototype.getDisksList = function() {
  return /** @type{!Array<!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.VmwareCompute.HelperAssignedDisk, 2));
};


/**
 * @param {!Array<!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk>} value
 * @return {!proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest} returns this
*/
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.prototype.setDisksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk}
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.prototype.addDisks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxymigrate.VmwareCompute.HelperAssignedDisk, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest} returns this
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.prototype.clearDisksList = function() {
  return this.setDisksList([]);
};


/**
 * optional bool keep_files = 3;
 * @return {boolean}
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.prototype.getKeepFiles = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest} returns this
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperRequest.prototype.setKeepFiles = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse}
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse;
  return proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse}
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.RemoveDiskFromHelperResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    disksList: jspb.Message.toObjectList(msg.getDisksList(),
    proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest;
  return proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig;
      reader.readMessage(value,proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.deserializeBinaryFromReader);
      msg.addDisks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    capacity: jspb.Message.getFieldWithDefault(msg, 1, 0),
    datastore: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vmdkPath: jspb.Message.getFieldWithDefault(msg, 3, ""),
    uuid: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig;
  return proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCapacity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatastore(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVmdkPath(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCapacity();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDatastore();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVmdkPath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 capacity = 1;
 * @return {number}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig} returns this
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string datastore = 2;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.prototype.getDatastore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig} returns this
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.prototype.setDatastore = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vmdk_path = 3;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.prototype.getVmdkPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig} returns this
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.prototype.setVmdkPath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string uuid = 5;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig} returns this
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated DiskConfig disks = 2;
 * @return {!Array<!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig>}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.prototype.getDisksList = function() {
  return /** @type{!Array<!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig, 2));
};


/**
 * @param {!Array<!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig>} value
 * @return {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest} returns this
*/
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.prototype.setDisksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.prototype.addDisks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.DiskConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest} returns this
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperRequest.prototype.clearDisksList = function() {
  return this.setDisksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    newDisksList: jspb.Message.toObjectList(msg.getNewDisksList(),
    galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse;
  return proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo.deserializeBinaryFromReader);
      msg.addNewDisks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewDisksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BlockDeviceInfo new_disks = 1;
 * @return {!Array<!proto.galaxymigrate.BlockDeviceInfo>}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.prototype.getNewDisksList = function() {
  return /** @type{!Array<!proto.galaxymigrate.BlockDeviceInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.BlockDeviceInfo>} value
 * @return {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse} returns this
*/
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.prototype.setNewDisksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.BlockDeviceInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.BlockDeviceInfo}
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.prototype.addNewDisks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.BlockDeviceInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse} returns this
 */
proto.galaxymigrate.VmwareCompute.AllocateNewDisksToHelperResponse.prototype.clearNewDisksList = function() {
  return this.setNewDisksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    spec: (f = msg.getSpec()) && proto.galaxymigrate.VmwareCompute.VmBuildSpec.toObject(includeInstance, f),
    dryRun: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest}
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest;
  return proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest}
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.galaxymigrate.VmwareCompute.VmBuildSpec;
      reader.readMessage(value,proto.galaxymigrate.VmwareCompute.VmBuildSpec.deserializeBinaryFromReader);
      msg.setSpec(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDryRun(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpec();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.VmwareCompute.VmBuildSpec.serializeBinaryToWriter
    );
  }
  f = message.getDryRun();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional VmBuildSpec spec = 2;
 * @return {?proto.galaxymigrate.VmwareCompute.VmBuildSpec}
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.prototype.getSpec = function() {
  return /** @type{?proto.galaxymigrate.VmwareCompute.VmBuildSpec} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.VmwareCompute.VmBuildSpec, 2));
};


/**
 * @param {?proto.galaxymigrate.VmwareCompute.VmBuildSpec|undefined} value
 * @return {!proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest} returns this
*/
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.prototype.setSpec = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest} returns this
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.prototype.clearSpec = function() {
  return this.setSpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.prototype.hasSpec = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool dry_run = 3;
 * @return {boolean}
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.prototype.getDryRun = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest} returns this
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperRequest.prototype.setDryRun = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    newVm: (f = msg.getNewVm()) && proto.galaxymigrate.VmwareCompute.Vm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse}
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse;
  return proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse}
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.VmwareCompute.Vm;
      reader.readMessage(value,proto.galaxymigrate.VmwareCompute.Vm.deserializeBinaryFromReader);
      msg.setNewVm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewVm();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.VmwareCompute.Vm.serializeBinaryToWriter
    );
  }
};


/**
 * optional Vm new_vm = 1;
 * @return {?proto.galaxymigrate.VmwareCompute.Vm}
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse.prototype.getNewVm = function() {
  return /** @type{?proto.galaxymigrate.VmwareCompute.Vm} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.VmwareCompute.Vm, 1));
};


/**
 * @param {?proto.galaxymigrate.VmwareCompute.Vm|undefined} value
 * @return {!proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse} returns this
*/
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse.prototype.setNewVm = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse} returns this
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse.prototype.clearNewVm = function() {
  return this.setNewVm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.VmwareCompute.BuiltVmFromHelperResponse.prototype.hasNewVm = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.ResourcePool.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.ResourcePool.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.ResourcePool} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.ResourcePool.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.ResourcePool}
 */
proto.galaxymigrate.VmwareCompute.ResourcePool.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.ResourcePool;
  return proto.galaxymigrate.VmwareCompute.ResourcePool.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.ResourcePool} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.ResourcePool}
 */
proto.galaxymigrate.VmwareCompute.ResourcePool.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.ResourcePool.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.ResourcePool.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.ResourcePool} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.ResourcePool.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.ResourcePool.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.ResourcePool} returns this
 */
proto.galaxymigrate.VmwareCompute.ResourcePool.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.Datastore.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.Datastore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.Datastore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.Datastore.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.Datastore}
 */
proto.galaxymigrate.VmwareCompute.Datastore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.Datastore;
  return proto.galaxymigrate.VmwareCompute.Datastore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.Datastore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.Datastore}
 */
proto.galaxymigrate.VmwareCompute.Datastore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.Datastore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.Datastore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.Datastore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.Datastore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.Datastore.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.Datastore} returns this
 */
proto.galaxymigrate.VmwareCompute.Datastore.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.Network.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.Network.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.Network} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.Network.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.Network}
 */
proto.galaxymigrate.VmwareCompute.Network.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.Network;
  return proto.galaxymigrate.VmwareCompute.Network.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.Network} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.Network}
 */
proto.galaxymigrate.VmwareCompute.Network.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.Network.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.Network.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.Network} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.Network.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string path = 1;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.Network.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.Network} returns this
 */
proto.galaxymigrate.VmwareCompute.Network.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.Vm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.Vm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.Vm.toObject = function(includeInstance, msg) {
  var f, obj = {
    datacenter: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hostSystem: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vmPath: jspb.Message.getFieldWithDefault(msg, 4, ""),
    vmName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    vmUuid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    resourcePool: jspb.Message.getFieldWithDefault(msg, 7, ""),
    disksCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    vmFile: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.Vm}
 */
proto.galaxymigrate.VmwareCompute.Vm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.Vm;
  return proto.galaxymigrate.VmwareCompute.Vm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.Vm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.Vm}
 */
proto.galaxymigrate.VmwareCompute.Vm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatacenter(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostSystem(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVmPath(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVmName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVmUuid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourcePool(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDisksCount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setVmFile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.Vm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.Vm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.Vm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatacenter();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHostSystem();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVmPath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVmName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVmUuid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getResourcePool();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDisksCount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getVmFile();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string datacenter = 1;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.getDatacenter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.Vm} returns this
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.setDatacenter = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string host_system = 2;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.getHostSystem = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.Vm} returns this
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.setHostSystem = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vm_path = 4;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.getVmPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.Vm} returns this
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.setVmPath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string vm_name = 5;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.getVmName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.Vm} returns this
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.setVmName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string vm_uuid = 6;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.getVmUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.Vm} returns this
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.setVmUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string resource_pool = 7;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.getResourcePool = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.Vm} returns this
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.setResourcePool = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 disks_count = 8;
 * @return {number}
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.getDisksCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.VmwareCompute.Vm} returns this
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.setDisksCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string vm_file = 9;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.getVmFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.Vm} returns this
 */
proto.galaxymigrate.VmwareCompute.Vm.prototype.setVmFile = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockDevicePath: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk}
 */
proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.HelperAssignedDisk;
  return proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk}
 */
proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockDevicePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockDevicePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string block_device_path = 1;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.prototype.getBlockDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk} returns this
 */
proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.prototype.setBlockDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.ConnInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.ConnInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.ConnInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.ConnInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    host: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.ConnInfo}
 */
proto.galaxymigrate.VmwareCompute.ConnInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.ConnInfo;
  return proto.galaxymigrate.VmwareCompute.ConnInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.ConnInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.ConnInfo}
 */
proto.galaxymigrate.VmwareCompute.ConnInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.ConnInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.ConnInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.ConnInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.ConnInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string host = 1;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.ConnInfo.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.ConnInfo} returns this
 */
proto.galaxymigrate.VmwareCompute.ConnInfo.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user = 2;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.ConnInfo.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.ConnInfo} returns this
 */
proto.galaxymigrate.VmwareCompute.ConnInfo.prototype.setUser = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.ConnInfo.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.ConnInfo} returns this
 */
proto.galaxymigrate.VmwareCompute.ConnInfo.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.VmBuildSpec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.VmBuildSpec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    datastore: jspb.Message.getFieldWithDefault(msg, 5, ""),
    folder: jspb.Message.getFieldWithDefault(msg, 6, ""),
    resourcePool: jspb.Message.getFieldWithDefault(msg, 7, ""),
    config: (f = msg.getConfig()) && proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.toObject(includeInstance, f),
    bootDisk: (f = msg.getBootDisk()) && proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.toObject(includeInstance, f),
    dataDisksList: jspb.Message.toObjectList(msg.getDataDisksList(),
    proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.VmBuildSpec;
  return proto.galaxymigrate.VmwareCompute.VmBuildSpec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.VmBuildSpec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatastore(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFolder(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourcePool(value);
      break;
    case 10:
      var value = new proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig;
      reader.readMessage(value,proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    case 11:
      var value = new proto.galaxymigrate.VmwareCompute.HelperAssignedDisk;
      reader.readMessage(value,proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.deserializeBinaryFromReader);
      msg.setBootDisk(value);
      break;
    case 12:
      var value = new proto.galaxymigrate.VmwareCompute.HelperAssignedDisk;
      reader.readMessage(value,proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.deserializeBinaryFromReader);
      msg.addDataDisks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.VmBuildSpec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.VmBuildSpec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDatastore();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFolder();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getResourcePool();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.serializeBinaryToWriter
    );
  }
  f = message.getBootDisk();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.serializeBinaryToWriter
    );
  }
  f = message.getDataDisksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.galaxymigrate.VmwareCompute.HelperAssignedDisk.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    cpuCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cpuCoresPerSocket: jspb.Message.getFieldWithDefault(msg, 2, 0),
    memoryMib: jspb.Message.getFieldWithDefault(msg, 3, 0),
    guestId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    scsiType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    firmwareType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    nicsList: jspb.Message.toObjectList(msg.getNicsList(),
    proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig;
  return proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCpuCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCpuCoresPerSocket(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMemoryMib(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGuestId(value);
      break;
    case 10:
      var value = /** @type {!proto.galaxymigrate.VmwareCompute.ScsiControllerType} */ (reader.readEnum());
      msg.setScsiType(value);
      break;
    case 11:
      var value = /** @type {!proto.galaxymigrate.VmwareCompute.FirmwareType} */ (reader.readEnum());
      msg.setFirmwareType(value);
      break;
    case 20:
      var value = new proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic;
      reader.readMessage(value,proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.deserializeBinaryFromReader);
      msg.addNics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCpuCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCpuCoresPerSocket();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMemoryMib();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getGuestId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getScsiType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getFirmwareType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getNicsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.toObject = function(includeInstance, msg) {
  var f, obj = {
    network: (f = msg.getNetwork()) && proto.galaxymigrate.VmwareCompute.Network.toObject(includeInstance, f),
    customMacAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adapterType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic;
  return proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.VmwareCompute.Network;
      reader.readMessage(value,proto.galaxymigrate.VmwareCompute.Network.deserializeBinaryFromReader);
      msg.setNetwork(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomMacAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdapterType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNetwork();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.VmwareCompute.Network.serializeBinaryToWriter
    );
  }
  f = message.getCustomMacAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdapterType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Network network = 1;
 * @return {?proto.galaxymigrate.VmwareCompute.Network}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.prototype.getNetwork = function() {
  return /** @type{?proto.galaxymigrate.VmwareCompute.Network} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.VmwareCompute.Network, 1));
};


/**
 * @param {?proto.galaxymigrate.VmwareCompute.Network|undefined} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic} returns this
*/
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.prototype.setNetwork = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.prototype.clearNetwork = function() {
  return this.setNetwork(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.prototype.hasNetwork = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string custom_mac_address = 2;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.prototype.getCustomMacAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.prototype.setCustomMacAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string adapter_type = 3;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.prototype.getAdapterType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic.prototype.setAdapterType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 cpu_count = 1;
 * @return {number}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.getCpuCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.setCpuCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 cpu_cores_per_socket = 2;
 * @return {number}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.getCpuCoresPerSocket = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.setCpuCoresPerSocket = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 memory_mib = 3;
 * @return {number}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.getMemoryMib = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.setMemoryMib = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string guest_id = 4;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.getGuestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.setGuestId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ScsiControllerType scsi_type = 10;
 * @return {!proto.galaxymigrate.VmwareCompute.ScsiControllerType}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.getScsiType = function() {
  return /** @type {!proto.galaxymigrate.VmwareCompute.ScsiControllerType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.galaxymigrate.VmwareCompute.ScsiControllerType} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.setScsiType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional FirmwareType firmware_type = 11;
 * @return {!proto.galaxymigrate.VmwareCompute.FirmwareType}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.getFirmwareType = function() {
  return /** @type {!proto.galaxymigrate.VmwareCompute.FirmwareType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.galaxymigrate.VmwareCompute.FirmwareType} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.setFirmwareType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * repeated Nic nics = 20;
 * @return {!Array<!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic>}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.getNicsList = function() {
  return /** @type{!Array<!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic, 20));
};


/**
 * @param {!Array<!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic>} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig} returns this
*/
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.setNicsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.addNics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.Nic, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig.prototype.clearNicsList = function() {
  return this.setNicsList([]);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string datastore = 5;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.getDatastore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.setDatastore = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string folder = 6;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.getFolder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.setFolder = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string resource_pool = 7;
 * @return {string}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.getResourcePool = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.setResourcePool = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional VmConfig config = 10;
 * @return {?proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.getConfig = function() {
  return /** @type{?proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig, 10));
};


/**
 * @param {?proto.galaxymigrate.VmwareCompute.VmBuildSpec.VmConfig|undefined} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec} returns this
*/
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional HelperAssignedDisk boot_disk = 11;
 * @return {?proto.galaxymigrate.VmwareCompute.HelperAssignedDisk}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.getBootDisk = function() {
  return /** @type{?proto.galaxymigrate.VmwareCompute.HelperAssignedDisk} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.VmwareCompute.HelperAssignedDisk, 11));
};


/**
 * @param {?proto.galaxymigrate.VmwareCompute.HelperAssignedDisk|undefined} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec} returns this
*/
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.setBootDisk = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.clearBootDisk = function() {
  return this.setBootDisk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.hasBootDisk = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated HelperAssignedDisk data_disks = 12;
 * @return {!Array<!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk>}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.getDataDisksList = function() {
  return /** @type{!Array<!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.VmwareCompute.HelperAssignedDisk, 12));
};


/**
 * @param {!Array<!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk>} value
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec} returns this
*/
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.setDataDisksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.VmwareCompute.HelperAssignedDisk}
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.addDataDisks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.galaxymigrate.VmwareCompute.HelperAssignedDisk, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.VmwareCompute.VmBuildSpec} returns this
 */
proto.galaxymigrate.VmwareCompute.VmBuildSpec.prototype.clearDataDisksList = function() {
  return this.setDataDisksList([]);
};


goog.object.extend(exports, proto.galaxymigrate);
