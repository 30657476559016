import { observer } from "mobx-react-lite";
import {
    Box,
    Button,
    Card,
    Checkbox,
    Dialog,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    SvgIcon,
    Switch,
    Typography,
    useTheme,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { SelectableBox, SelectableCard } from "../../../common/card/SelectableCard";
import { DialogState, useDialogState } from "../../core/dialog/DialogService";
import { DialogTopBar } from "../../core/dialog/DialogComponents";
import { useAppServices } from "../../app/services";
import { renderServerDataWithLoadingList, useInitData } from "../../core/data/DataLoaderHooks";
import { GalaxyMigrateDeploymentDetails, GalaxyMigrateStorageConfig } from "../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { renderIfConnectedDeployment } from "../../deployment/DeploymentCommon";
import { formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { renderChipInfo } from "../../../common/chip/CommonChips";
import { getGmStorageConfigDeviceType } from "../GalaxyMigrateCommon";
import * as React from "react";
import { MdStorage } from "react-icons/md";
import { GalaxyMigrateDeploymentPerformanceChartSection } from "./GalaxyMigrateDeploymentPeformanceChart";
import { generateDomainList, IO_TYPE } from "./GalaxyMigrateDeploymentPerformanceHelpers";
import { useQuery } from "@tanstack/react-query";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";

// ======================
// GalaxyMigratePerformanceView
// ======================

interface GalaxyMigrateDeploymentPerformanceViewProps {
    deployment: GalaxyMigrateDeploymentDetails;
}

export const GalaxyMigrateDeploymentPerformanceView: React.FC<GalaxyMigrateDeploymentPerformanceViewProps> = observer((p) => {
    const { deployment } = p;
    const { gmDeploymentService } = useAppServices();
    const theme = useTheme();
    const [timeFrame, setTimeFrame] = useState<number>(300);

    const [volumeList, setVolumeList] = useState<Array<GalaxyMigrateStorageConfig.Device>>([]);
    const [ioType, setIoType] = useState<IO_TYPE>(IO_TYPE.ALL_IO);
    const [aggregateChart, setAggregateChart] = useState(false);
    const volumeSelectionDialogState = useDialogState();

    const fetchIo = useCallback(async () => {
        return await gmDeploymentService.getIoStats(timeFrame, generateDomainList(volumeList, aggregateChart), ioType);
    }, [timeFrame, aggregateChart, volumeList, ioType, gmDeploymentService]);

    useInitData({
        poll: () => gmDeploymentService.storageConfig.fetchData(deployment.getInfo().getDeployment().getSystemId()),
        pollInterval: 120,
    });

    const queryResult = useQuery(["gmDeploymentIoStats", timeFrame, aggregateChart, volumeList, ioType], fetchIo, {
        refetchInterval: timeFrame === 300 ? 1000 : 60000,
    });

    return (
        <Box pt={2}>
            <>
                {renderIfConnectedDeployment(
                    deployment,
                    <Box>
                        <Typography variant={"h4"}>Host Performance Statistics</Typography>
                        <Card variant={"outlined"} sx={{ background: "none", marginTop: theme.spacing(2) }}>
                            <Grid container>
                                <Grid item sx={{ borderRight: "2px solid", borderRightColor: theme.palette.cirrus.light }} xs={2}>
                                    <Box p={2}>
                                        <Typography>1. Time Frame:</Typography>
                                        <Box pt={1}>
                                            <FormControl fullWidth variant={"filled"}>
                                                <InputLabel id={"selectTimeFrame"}>Time Frame</InputLabel>
                                                <Select
                                                    value={timeFrame}
                                                    variant={"filled"}
                                                    fullWidth
                                                    labelId={"selectTimeFrame"}
                                                    onChange={(e) => {
                                                        setTimeFrame(e.target.value as number);
                                                    }}
                                                >
                                                    <MenuItem value={300}>Last 5 Minutes</MenuItem>
                                                    <MenuItem value={1440}>Last 24 Hours</MenuItem>
                                                    <MenuItem value={10080}>Last 7 Days</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item sx={{ borderRight: "2px solid", borderRightColor: theme.palette.cirrus.light }} xs={5}>
                                    <Box p={2}>
                                        <Typography>2. Volume:</Typography>
                                        <Grid container spacing={3} pt={1}>
                                            <Grid item>
                                                <SelectableBox selected={volumeList.length === 0} onSelect={() => setVolumeList([])}>
                                                    <Box p={2} color={volumeList.length === 0 ? theme.palette.primary.main : theme.palette.text.secondary}>
                                                        Include All Source Volumes
                                                    </Box>
                                                </SelectableBox>
                                            </Grid>
                                            <Grid item>
                                                <SelectableBox selected={volumeList.length > 0} onSelect={() => volumeSelectionDialogState.open()}>
                                                    {volumeList.length > 0 ? (
                                                        <Box p={2} color={theme.palette.primary.main}>
                                                            Update Volume Selection ({volumeList.length})
                                                        </Box>
                                                    ) : (
                                                        <Box p={2} color={theme.palette.text.secondary}>
                                                            Select Specific Volume(s)
                                                        </Box>
                                                    )}
                                                </SelectableBox>
                                            </Grid>
                                        </Grid>
                                        {volumeList.length > 0 && (
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        color={"secondary"}
                                                        checked={aggregateChart}
                                                        onChange={() => {
                                                            setAggregateChart(!aggregateChart);
                                                        }}
                                                    />
                                                }
                                                label={"Aggregate Chart"}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Box p={2}>
                                        <Typography>3. Select I/O Type To Be Included:</Typography>
                                        <Grid container pt={1} spacing={3}>
                                            <Grid item>
                                                <SelectableBox selected={ioType === IO_TYPE.ALL_IO} onSelect={() => setIoType(IO_TYPE.ALL_IO)}>
                                                    <Box p={2} color={ioType === IO_TYPE.ALL_IO ? theme.palette.primary.main : theme.palette.text.secondary}>
                                                        All IO
                                                    </Box>
                                                </SelectableBox>
                                            </Grid>
                                            <Grid item>
                                                <SelectableBox selected={ioType === IO_TYPE.READ} onSelect={() => setIoType(IO_TYPE.READ)}>
                                                    <Box p={2} color={ioType === IO_TYPE.READ ? theme.palette.primary.main : theme.palette.text.secondary}>
                                                        Read Only
                                                    </Box>
                                                </SelectableBox>
                                            </Grid>
                                            <Grid item>
                                                <SelectableBox selected={ioType === IO_TYPE.WRITE} onSelect={() => setIoType(IO_TYPE.WRITE)}>
                                                    <Box p={2} color={ioType === IO_TYPE.WRITE ? theme.palette.primary.main : theme.palette.text.secondary}>
                                                        Write Only
                                                    </Box>
                                                </SelectableBox>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Card>
                        {volumeSelectionDialogState.isOpen && (
                            <VolumePerformanceSelectionDialog
                                onConfirmVolumeSelection={(volumeList) => {
                                    setVolumeList(volumeList);
                                }}
                                dialogState={volumeSelectionDialogState}
                                deploymentId={deployment.getInfo().getDeployment().getSystemId()}
                                initialVolumeList={volumeList}
                            />
                        )}

                        <QueryResultWrapper queryResult={queryResult}>
                            <GalaxyMigrateDeploymentPerformanceChartSection
                                data={queryResult.data}
                                ioType={ioType}
                                volList={gmDeploymentService.storageConfig.data?.getDevicesList()}
                            />
                        </QueryResultWrapper>
                    </Box>
                )}
            </>
        </Box>
    );
});

// ======================
// VolumePerformanceSelectionDialog
// ======================

interface VolumePerformanceSelectionDialogProps {
    onConfirmVolumeSelection: (volumeList: Array<GalaxyMigrateStorageConfig.Device>) => void;
    dialogState: DialogState;
    deploymentId: string;
    initialVolumeList: Array<GalaxyMigrateStorageConfig.Device>;
}

const VolumePerformanceSelectionDialog: React.FC<VolumePerformanceSelectionDialogProps> = observer((p) => {
    const { onConfirmVolumeSelection, dialogState, deploymentId, initialVolumeList } = p;
    const theme = useTheme();
    const { gmDeploymentService } = useAppServices();

    const [selectedVolumes, setSelectedVolumes] = useState<Array<GalaxyMigrateStorageConfig.Device>>(initialVolumeList);

    const handleToggleVolumeSelection = (vol: GalaxyMigrateStorageConfig.Device) => () => {
        const currentIndex = selectedVolumes.findIndex((v) => v.getBlockDevice().getDeviceName() === vol.getBlockDevice().getDeviceName());
        const newChecked = [...selectedVolumes];

        if (currentIndex === -1) {
            newChecked.push(vol);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedVolumes(newChecked);
    };

    return (
        <Dialog open={dialogState.isOpen} onClose={dialogState.close}>
            <DialogTopBar dialogState={dialogState} title={`Select Volumes To Be Included (Maximum: 8)`} />
            <DialogContent>
                {renderServerDataWithLoadingList(gmDeploymentService.storageConfig, (data) => {
                    return (
                        <>
                            <Card sx={{ background: theme.palette.cirrus.main }}>
                                {data.getDevicesList().map((vol) => {
                                    const device = vol.getBlockDevice();
                                    return (
                                        <ListItem key={device.getDeviceName()} sx={{ padding: 0 }}>
                                            <ListItemButton
                                                onClick={handleToggleVolumeSelection(vol)}
                                                disabled={
                                                    selectedVolumes.length === 8 &&
                                                    !selectedVolumes.find((v) => v.getBlockDevice().getDeviceName() === device.getDeviceName())
                                                }
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge={"start"}
                                                        checked={!!selectedVolumes.find((v) => v.getBlockDevice().getDeviceName() === device.getDeviceName())}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemIcon>
                                                    <SvgIcon>
                                                        <MdStorage />
                                                    </SvgIcon>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={`${device.getDeviceName()} (${formatKnownDataType(device.getCapacity(), KnownDataType.CAPACITY)})`}
                                                    secondary={
                                                        <>
                                                            <Grid container spacing={1}>
                                                                {renderChipInfo(getGmStorageConfigDeviceType(vol))}
                                                                {renderChipInfo(device.getFsType())}
                                                                {renderChipInfo(device.getLabel())}
                                                                {renderChipInfo(device.getMountPoint())}
                                                            </Grid>
                                                        </>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </Card>
                        </>
                    );
                })}
            </DialogContent>
            <Divider />
            <Box display={"flex"} justifyContent={"flex-end"} p={2}>
                <Button
                    variant={"contained"}
                    onClick={() => {
                        onConfirmVolumeSelection(selectedVolumes);
                        dialogState.close();
                    }}
                >
                    Save
                </Button>
            </Box>
        </Dialog>
    );
});
