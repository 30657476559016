// ======================
// SupportWidget
// ======================

import { observer } from "mobx-react-lite";
import { Drawer, Fab, SvgIcon, Theme, Tooltip } from "@mui/material";
import * as React from "react";
import { useHelpDrawerWidth } from "../layout/MainLayout";
import { useAppServices } from "../app/services";
import { CloseIcon } from "../../common/CommonIcons";
import { MdHeadsetMic } from "react-icons/md";
import { useHelpDrawerState } from "./hooks/help_hooks";
import { shallow } from "zustand/shallow";
import { BiLinkExternal } from "react-icons/bi";

interface HelpDrawerProps {}

export const HelpDrawer: React.FC<HelpDrawerProps> = observer((p) => {
    const { setHelpDrawerOpen, helpDrawerOpen, helpCenterUrl } = useHelpDrawerState(
        (s) => ({
            setHelpDrawerOpen: s.setHelpDrawerOpen,
            helpDrawerOpen: s.helpDrawerOpen,
            helpCenterUrl: s.helpCenterUrl,
        }),
        shallow
    );
    const helpDrawerWidth = useHelpDrawerWidth();

    return (
        <>
            <Drawer
                variant={"persistent"}
                open={helpDrawerOpen}
                onClose={() => setHelpDrawerOpen(false)}
                sx={{
                    width: helpDrawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: helpDrawerWidth,
                        zIndex: (t: Theme) => t.zIndex.modal + 1,
                        background: (t: Theme) => t.palette.cirrus.medium,
                        borderLeftWidth: 2,
                        borderLeftColor: (t: Theme) => t.palette.cirrus.light,
                        borderLeftStyle: "solid",
                        marginTop: 0,
                        boxSizing: "border-box",
                    },
                }}
                elevation={0}
                anchor={"right"}
            >
                <iframe title={"Cirrus Data Customer Center"} src={helpCenterUrl} height={"100%"} width={"100%"} style={{ border: "none" }}></iframe>
            </Drawer>
        </>
    );
});

// ======================
// HelpActionButton
// ======================

export const HelpActionButton: React.FC<HelpDrawerProps> = observer((p) => {
    const { helpService } = useAppServices();
    const { setHelpCenterUrl, setHelpDrawerOpen, helpDrawerOpen } = useHelpDrawerState(
        (s) => ({
            setHelpCenterUrl: s.setHelpCenterUrl,
            setHelpDrawerOpen: s.setHelpDrawerOpen,
            helpDrawerOpen: s.helpDrawerOpen,
        }),
        shallow
    );

    const handleClick = () => {
        if (!helpDrawerOpen) {
            setHelpCenterUrl(helpService.getCustomerCenterAuthCallbackUrl("kb?from=cmc"));
            setHelpDrawerOpen(true);
        } else {
            setHelpDrawerOpen(false);
        }
    };

    return (
        <>
            <>
                {helpDrawerOpen && (
                    <Tooltip title={"Open In New Tab"}>
                        <Fab
                            color={"secondary"}
                            size={"medium"}
                            sx={{ zIndex: 5000, position: "fixed", bottom: 25, right: 80 }}
                            onClick={() => window.open(document.querySelector("iframe").contentWindow.location.href, "_blank")}
                        >
                            <BiLinkExternal size={20} />
                        </Fab>
                    </Tooltip>
                )}
                <Tooltip title={helpDrawerOpen ? "Close" : "Help"}>
                    <Fab
                        color={helpDrawerOpen ? "secondary" : "primary"}
                        size={"medium"}
                        sx={{ zIndex: 5000, position: "fixed", bottom: 25, right: 25 }}
                        onClick={handleClick}
                    >
                        {helpDrawerOpen ? (
                            <CloseIcon />
                        ) : (
                            <SvgIcon>
                                <MdHeadsetMic />
                            </SvgIcon>
                        )}
                    </Fab>
                </Tooltip>
            </>
        </>
    );
});
