import { useAppServices } from "../../app/services";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const GmDeploymentQueryKeys = {
    listAzureHelpers: "ListAzureHelpers",
    configureAzureHelper: "ConfigureAzureHelper",
};

export const useConfigureAzureHelper = () => {
    const { gmDeploymentService } = useAppServices();
    const mutationFn = async (args: { systemId: string; integrationId: number }) =>
        await gmDeploymentService.configureAzureHelper(args.systemId, args.integrationId);
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [`configureAzureHelper`],
        mutationFn: mutationFn,
    });
};
