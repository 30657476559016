import { useAppServices } from "../app/services";
import { useQuery } from "@tanstack/react-query";

export const MigrationQueryKeys = {
    ListAzureHelpers: "listAzureHelpers",
    GetAzureHelperResourceSelections: "getAzureHelperResourceSelections",
    GetVmwareHelperResourceSelections: "getVmwareHelperResourceSelections",
};

export const useListAzureHelpers = (projectId: string, targetFrom?: string) => {
    const { gmMigrationService } = useAppServices();
    const queryFn = async () => await gmMigrationService.listAzureHelpers(projectId, targetFrom);

    return useQuery({
        queryKey: [MigrationQueryKeys.ListAzureHelpers, projectId, targetFrom],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useGetAzureHelperResourceSelections = (helperId: string) => {
    const { gmMigrationService } = useAppServices();
    const queryFn = async () => await gmMigrationService.getAzureHelperResourceSelections(helperId);
    return useQuery({
        queryKey: [MigrationQueryKeys.GetAzureHelperResourceSelections, helperId],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useGetVmwareHelperResourceSelections = (helperId: string) => {
    const { gmMigrationService } = useAppServices();
    const queryFn = async () => await gmMigrationService.getVmwareHelperResourceSelections(helperId);
    return useQuery({
        queryKey: [MigrationQueryKeys.GetVmwareHelperResourceSelections, helperId],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};
