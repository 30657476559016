import React from "react";
import {observer} from "mobx-react-lite";
import {ProjectDetails} from "../../_proto/galaxycompletepb/apipb/domainpb/project_pb";
import {Navigate, Route, Routes} from "react-router-dom";
import {
    CDM_POLICY_DETAILS_SUBROUTE, DEPLOYMENT_DETAILS_SUBROUTE,
    POLICIES_SUBROUTE,
    PROTECTION_HOST_DETAILS_SUBROUTE,
    PROTECTION_SUBROUTE
} from "../app/AppRoutes";
import {PhoenixDeploymentsListScreen} from "./servers/PhoenixDeploymentsList";
import {PhoenixDeploymentDetailsScreen} from "./servers/PhoenixDeploymentDetails";
import {CirrusProtectDeploymentsListScreen} from "./hosts/CirrusProtectDeploymentsList";
import {CdmPoliciesListScreen} from "./policies/CdmPoliciesList";
import {CdmPolicyWizard} from "./policies/wizard/CreateCdmPolicyWizard";
import {CdmPolicyDetailsScreen} from "./policies/CdmPolicyDetails";
import {TimewalkerWizardScreen} from "./policies/CdmPolicyTimewalker";
import {CirrusProtectDeploymentDetailsScreen} from "./hosts/CirrusProtectDeploymentDetails";
import {CirrusProtectDeploymentDetailsPolicyScreen} from "./hosts/CirrusProtectDeploymentDetailsPolicy";
import {GalaxyMigrateDeploymentLiveLog} from "../galaxymigrate/details/GalaxyMigrateDeploymentLiveLog";
import {CirrusProtectDeploymentLiveLog} from "./hosts/CirrusProtectDeploymentLiveLog";

// ======================
// ProjectProtectionScreens
// ======================


interface ProjectProtectionScreensProps {
    project: ProjectDetails;
}

export const ProjectProtectionScreens: React.FC<ProjectProtectionScreensProps> = observer((p) => {

    return <Routes>
        <Route index element={<Navigate to={PROTECTION_SUBROUTE.DEPLOYMENTS} replace/>}/>
        <Route path={PROTECTION_SUBROUTE.DEPLOYMENTS}>
            <Route index element={<CirrusProtectDeploymentsListScreen/>}/>
            <Route path={`:deploymentId/${PROTECTION_HOST_DETAILS_SUBROUTE.LOG}`} element={<CirrusProtectDeploymentLiveLog/>}/>
            <Route path={`:deploymentId/${PROTECTION_HOST_DETAILS_SUBROUTE.CREATE_NEW_POLICY}`} element={<CdmPolicyWizard/>}/>
            <Route path={':deploymentId/*'} element={<CirrusProtectDeploymentDetailsScreen/>}/>
        </Route>
        <Route path={PROTECTION_SUBROUTE.SERVERS}>
            <Route index element={<PhoenixDeploymentsListScreen/>}/>
            <Route path={':deploymentId/*'} element={<PhoenixDeploymentDetailsScreen/>}/>
        </Route>
{/*        <Route path={PROTECTION_SUBROUTE.POLICIES}>
            <Route index element={<CdmPoliciesListScreen/>}/>
            <Route path={':policyId'}>
                <Route index element={<CdmPolicyDetailsScreen/>}/>
                <Route path={CDM_POLICY_DETAILS_SUBROUTE.SNAPSHOTS} element={<CdmPolicyDetailsScreen/>}/>
                <Route path={CDM_POLICY_DETAILS_SUBROUTE.TIMEWALKER} element={<TimewalkerWizardScreen/>}/>
            </Route>
            <Route path={POLICIES_SUBROUTE.CREATE} element={<CdmPolicyWizard/>}/>
        </Route>*/}
    </Routes>

})