// Project: GalaxyComplete
// Created: 9/27/20 by sammy
// File: NewDeploymentInstructions

import * as React from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useAppServices } from "../app/services";
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogContentText,
    Divider,
    Grid,
    ListItem,
    ListItemText,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import { useCurrentProject } from "../project/CurrentProject";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../core/dialog/DialogService";
import { ClipboardButton, ClipboardText } from "../../common/clipboard/ClipboardComponents";
import { OperatorView } from "../auth/AuthenticatedViews";
import { DialogTopBar } from "../core/dialog/DialogComponents";
import { DiLinux, DiWindows } from "react-icons/di";
import { TabConfig, TabGroup } from "../../common/tabs/TabComponents";
import { getApiHost } from "../grpc/grpcCommon";
import { AppHintID, HintButton } from "../help/HelpCommon";
import { DEFAULT_PORTAL_HOST, DirectIcon, RelayIcon } from "./DeploymentCommon";
import { PortNumbers } from "../../_proto/galaxymigratepb/galaxy_migrate_pb";
import { SelectableCard } from "../../common/card/SelectableCard";
import { useInitData } from "../core/data/DataLoaderHooks";
import { DeploymentInfo } from "../../_proto/galaxycompletepb/apipb/domainpb/deployment_pb";
import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { useHelpDrawerState } from "../help/hooks/help_hooks";
import { useHelpDrawerWidth, useIsDesktop } from "../layout/MainLayout";

// ======================
// NewDeploymentButton
// ======================
interface NewDeploymentButtonProps {}

export const NewDeploymentButton: React.FC<NewDeploymentButtonProps> = observer((p) => {
    const { deploymentService } = useAppServices();
    const newDeploymentDialogState = useDialogState();
    const newDeploymentOptionsDialogState = useDialogState();

    const onDeploy = async () => {
        await deploymentService.galaxyMigrateRelayDeployments.fetchData();
        if (deploymentService.galaxyMigrateRelayDeployments.data?.getItemsList().length > 0) {
            newDeploymentOptionsDialogState.open();
        } else {
            newDeploymentDialogState.open();
        }
    };

    return (
        <OperatorView>
            {newDeploymentDialogState.isOpen && <NewDeploymentDialog dialogState={newDeploymentDialogState} />}
            {newDeploymentOptionsDialogState.isOpen && <NewDeploymentOptionsDialog dialogState={newDeploymentOptionsDialogState} />}
            <Button color={"secondary"} variant={"contained"} onClick={onDeploy}>
                {`Deploy Cirrus Migrate Cloud`}
            </Button>
        </OperatorView>
    );
});

// ======================
// NewDeploymentOptionsDialog
// ======================

interface NewDeploymentOptionsDialogProps {
    dialogState: DialogState;
}

export const NewDeploymentOptionsDialog: React.FC<NewDeploymentOptionsDialogProps> = observer((p) => {
    const { dialogState } = p;
    const fullScreen = useShouldDialogFullScreen();
    const [showDirectDeploymentInstructions, setShowDirectDeploymentInstructions] = useState(false);
    const [showRelayDeploymentSelection, setShowRelayDeploymentSelection] = useState(false);
    const [relayDeploymentSelection, setRelayDeploymentSelection] = useState(null as DeploymentInfo);

    return (
        <Dialog open={dialogState.isOpen} maxWidth={"md"} fullWidth fullScreen={fullScreen} onClose={dialogState.close}>
            <DialogTopBar
                title={`Deploy Cirrus Migrate Cloud${!!relayDeploymentSelection ? ` via ${relayDeploymentSelection.getSystemName()}` : ""}`}
                dialogState={dialogState}
                actions={[<HintButton hintID={relayDeploymentSelection ? AppHintID.CMC_RELAY : AppHintID.DEPLOY_CIRRUS_MIGRATE} />]}
                divider
            />
            {!showDirectDeploymentInstructions && !showRelayDeploymentSelection && (
                <NewDeploymentOptions
                    fullScreen={fullScreen}
                    showDirectInstructions={() => setShowDirectDeploymentInstructions(true)}
                    showRelayInstructions={() => setShowRelayDeploymentSelection(true)}
                />
            )}
            {showDirectDeploymentInstructions && <NewDeploymentInstructions />}
            {showRelayDeploymentSelection && !relayDeploymentSelection && (
                <NewRelayServerAddressSelection setRelayDeployment={(deployment: DeploymentInfo) => setRelayDeploymentSelection(deployment)} />
            )}
            {!!relayDeploymentSelection && <NewDeploymentInstructions relayAddress={relayDeploymentSelection.getCdcRelayServerAddress().split("/")[0]} />}
        </Dialog>
    );
});

// ======================
// NewDeploymentOptions
// ======================

interface NewDeploymentOptionsProps {
    fullScreen: boolean;
    showDirectInstructions: () => void;
    showRelayInstructions: () => void;
}

export const NewDeploymentOptions: React.FC<NewDeploymentOptionsProps> = observer((p) => {
    return (
        <DialogContent>
            <DialogContentText>Please select Connectivity to Cirrus Data Cloud</DialogContentText>
            <br />
            <Grid container spacing={3} height={p.fullScreen ? "auto" : "100%"}>
                <Grid item xs={12} md={6}>
                    <SelectableCard
                        onSelect={() => {
                            p.showDirectInstructions();
                        }}
                        icon={<DirectIcon />}
                        elevation={0}
                        sx={{
                            height: p.fullScreen ? "auto" : "100%",
                            borderColor: "rgba(255, 255, 255, .1)",
                            border: "2px solid",
                        }}
                        selected={false}
                        title={"Direct Connect"}
                        description={
                            <span>
                                Connect from <b>Host</b> to <b>Cloud</b> directly (outgoing connection)
                            </span>
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SelectableCard
                        onSelect={() => {
                            p.showRelayInstructions();
                        }}
                        icon={<RelayIcon />}
                        elevation={0}
                        sx={{
                            height: p.fullScreen ? "auto" : "100%",
                            borderColor: "rgba(255, 255, 255, .1)",
                            border: "2px solid",
                        }}
                        selected={false}
                        title={"via Management Relay"}
                        description={
                            <span>
                                Connect from <b>Host</b> to <b>Cloud</b> via <b>another host with CDC Management Relay</b> enabled
                            </span>
                        }
                    />
                </Grid>
            </Grid>
        </DialogContent>
    );
});

// ======================
// NewRelayServerAddressSelection
// ======================

interface NewRelayServerAddressSelectionProps {
    setRelayDeployment: (deployment: DeploymentInfo) => void;
}

export const NewRelayServerAddressSelection: React.FC<NewRelayServerAddressSelectionProps> = observer((p) => {
    const { deploymentService } = useAppServices();

    useInitData({
        poll: () => deploymentService.galaxyMigrateRelayDeployments.fetchData(),
        pollInterval: 10,
    });

    return (
        <CardContent>
            <Typography>Please select a Management Relay</Typography>
            <br />
            <Card
                sx={{
                    backgroundColor: (t: Theme) => t.palette.cirrus.main,
                }}
            >
                {deploymentService.galaxyMigrateRelayDeployments.data.getItemsList().map((d, i) => {
                    return (
                        <ListItem button key={i} onClick={() => p.setRelayDeployment(d.getDeployment())}>
                            <ListItemText primary={d.getDeployment().getSystemName()} secondary={d.getDeployment().getCdcRelayServerAddress().split("/")[0]} />
                        </ListItem>
                    );
                })}
            </Card>
        </CardContent>
    );
});

// ======================
// NewDeploymentDialog
// ======================
const useNewDeploymentDialogStyles = () => {
    const t = useTheme();
    return {
        command: {
            background: t.palette.background.default,
        },
    };
};

interface NewDeploymentDialogProps {
    dialogState: DialogState;
}

const NewDeploymentDialog: React.FC<NewDeploymentDialogProps> = observer((p) => {
    const { dialogState } = p;
    const fullScreen = useShouldDialogFullScreen();
    const theme = useTheme();
    const helpDrawerOpen = useHelpDrawerState((s) => s.helpDrawerOpen);
    const helpDrawerWidth = useHelpDrawerWidth();
    const isDesktop = useIsDesktop();
    return (
        <Dialog
            open={dialogState.isOpen}
            maxWidth={"md"}
            fullWidth
            fullScreen={fullScreen}
            onClose={dialogState.close}
            sx={{
                "& .MuiDialog-container": {
                    ...(helpDrawerOpen && {
                        transition: theme.transitions.create(["margin", "width"], {
                            easing: theme.transitions.easing.easeOut,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                        width: isDesktop ? `calc(100% - ${helpDrawerWidth}px)` : "100%",
                    }),
                },
            }}
        >
            <DialogTopBar title={"Deploy Cirrus Migrate Cloud"} dialogState={dialogState} actions={[<HintButton hintID={AppHintID.DEPLOY_CIRRUS_MIGRATE} />]} />
            <Divider />
            <NewDeploymentInstructions />
        </Dialog>
    );
});

// ======================
// NewDeploymentInstructions
// ======================

interface NewDeploymentInstructionsProps {
    relayAddress?: string;
}

export const NewDeploymentInstructions: React.FC<NewDeploymentInstructionsProps> = observer((p) => {
    const currentProject = useCurrentProject();
    const systemRegistrationCode = currentProject.getInfo().getSystemRegistrationCode();
    const isPrivateEdition = getIsPrivateEdition();

    const tabConfigs: TabConfig[] = [
        {
            label: "Linux",
            icon: <DiLinux />,
            renderer: () => {
                return (
                    <LinuxDeploymentInstructions relayAddress={p.relayAddress} registrationCode={systemRegistrationCode} privateEdition={isPrivateEdition} />
                );
            },
        },
        {
            label: "Windows",
            icon: <DiWindows />,
            renderer: () => {
                return (
                    <WindowsDeploymentInstructions relayAddress={p.relayAddress} registrationCode={systemRegistrationCode} privateEdition={isPrivateEdition} />
                );
            },
        },
    ];
    return <TabGroup configs={tabConfigs} tabsProps={{ variant: "fullWidth", indicatorColor: "primary", textColor: "primary" }} />;
});

// ======================
// LinuxDeploymentInstructions
// ======================

interface LinuxDeploymentInstructionsProps {
    registrationCode: string;
    relayAddress?: string;
    privateEdition?: boolean;
}

export const LinuxDeploymentInstructions: React.FC<LinuxDeploymentInstructionsProps> = observer((p) => {
    const newInstallId = "newInstallDeploymentCommandLinux";
    const uninstallId = "uninstallDeploymentCommandLinux";

    const styles = useNewDeploymentDialogStyles();

    return (
        <CardContent>
            <Box pb={2}>
                <Typography variant={"h4"} color={"textPrimary"}>
                    Installation - Linux
                </Typography>
                <Typography variant={"caption"} color={"textPrimary"}>
                    Run the following Command to install Cirrus Migrate Cloud:
                </Typography>
            </Box>
            <Card sx={styles.command}>
                <CardContent>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <ClipboardText clipboardId={newInstallId}>
                            {generateDeploymentCommand("linux", "install", p.registrationCode, getApiHost(), false, p.relayAddress, p.privateEdition)}
                        </ClipboardText>
                        <ClipboardButton clipboardId={newInstallId} iconButton />
                    </Box>
                </CardContent>
            </Card>
            <br />
            <Divider />
            <br />
            <Box pb={2}>
                <Typography variant={"h4"} color={"textSecondary"}>
                    Uninstall - Linux
                </Typography>
                <Typography variant={"caption"} color={"textSecondary"}>
                    Run the following Command to uninstall Cirrus Migrate Cloud after migration is completed:
                </Typography>
            </Box>
            <Card sx={styles.command}>
                <CardContent>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <ClipboardText clipboardId={uninstallId}>
                            {generateDeploymentCommand("linux", "uninstall", p.registrationCode, getApiHost(), false, p.relayAddress, p.privateEdition)}
                        </ClipboardText>
                        <ClipboardButton clipboardId={uninstallId} iconButton />
                    </Box>
                </CardContent>
            </Card>
            <br />
        </CardContent>
    );
});

// ======================
// WindowsDeploymentInstructions
// ======================

interface WindowsDeploymentInstructionsProps {
    registrationCode: string;
    relayAddress?: string;
    privateEdition?: boolean;
}

export const WindowsDeploymentInstructions: React.FC<WindowsDeploymentInstructionsProps> = observer((p) => {
    const newInstallId = "newInstallDeploymentCommandWindows";
    const uninstallId = "uninstallDeploymentCommandWindows";

    const styles = useNewDeploymentDialogStyles();

    return (
        <CardContent>
            <Box pb={2}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant={"h4"} color={"textPrimary"}>
                        Installation - Windows
                    </Typography>
                </Box>
                <Typography variant={"caption"} color={"textPrimary"}>
                    In your Windows administrator account, run the following command in powershell to install Cirrus Migrate Cloud:
                </Typography>
            </Box>
            <Card sx={styles.command}>
                <CardContent>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <ClipboardText clipboardId={newInstallId}>
                            {generateDeploymentCommand("windows", "install", p.registrationCode, getApiHost(), false, p.relayAddress, p.privateEdition)}
                        </ClipboardText>
                        <ClipboardButton clipboardId={newInstallId} iconButton />
                    </Box>
                </CardContent>
            </Card>
            <br />
            <Divider />
            <br />
            <Box pb={2}>
                <Typography variant={"h4"} color={"textSecondary"}>
                    Uninstall - Windows
                </Typography>
                <Typography variant={"caption"} color={"textSecondary"}>
                    Run the following Command to uninstall Cirrus Migrate Cloud after migration is completed:
                </Typography>
            </Box>
            <Card sx={styles.command}>
                <CardContent>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <ClipboardText clipboardId={uninstallId}>
                            {generateDeploymentCommand("windows", "uninstall", p.registrationCode, getApiHost(), false, p.relayAddress, p.privateEdition)}
                        </ClipboardText>
                        <ClipboardButton clipboardId={uninstallId} iconButton />
                    </Box>
                </CardContent>
            </Card>
            <br />
        </CardContent>
    );
});

export const generateDeploymentCommand = (
    os: "linux" | "windows",
    command: "install" | "uninstall",
    registrationCode: string,
    hostString: string,
    cirrusProtect?: boolean,
    relayAddress?: string,
    privateEdition?: boolean
): string => {
    const getAddressString = () => {
        if (!!relayAddress) {
            if (os === "linux") {
                return `https://${relayAddress}:${PortNumbers.CDC_RELAY}/install-cmc`;
            } else if (os === "windows") {
                return `http://${relayAddress}:${PortNumbers.CDC_RELAY_HTTP}/install-cmc-win`;
            }
        } else if (privateEdition) {
            const currentHost = window.location.host;
            if (os === "linux") {
                return `${currentHost}/sw/install-cmc`;
            } else if (os === "windows") {
                return `http://${currentHost}/sw/install-cmc-win`;
            }
        } else {
            if (os === "linux") {
                return "get.cirrusdata.cloud/install-cmc";
            } else if (os === "windows") {
                return "https://get.cirrusdata.cloud/install-cmc-win";
            }
        }
    };

    const windowsBaseCommand = `iex "& { $(irm ${getAddressString()}) }`;
    const linuxBaseCommand = `curl ${!!relayAddress ? "-k" : ""} ${getAddressString()} | bash -s --`;

    const uninstall = " -uninstall";

    const cp = cirrusProtect ? " --cirrus-protect" : "";

    const portalHost = `${hostString}`.replace(/webapi/g, "portal");

    const gce = !!relayAddress || privateEdition ? "" : DEFAULT_PORTAL_HOST !== portalHost ? ` -gce ${portalHost}:443` : "";
    const rgc = ` -rgc ${registrationCode}`;

    const pkgMode = privateEdition ? "" : DEFAULT_PORTAL_HOST !== portalHost ? ` -pkg-mode PRE_RELEASE` : "";

    if (os === "linux") {
        if (command === "install") {
            return `${linuxBaseCommand}${rgc}${gce}${cp}${pkgMode}`;
        } else {
            return `${linuxBaseCommand}${cp}${uninstall}`;
        }
    } else if (os === "windows") {
        if (command === "install") {
            return `${windowsBaseCommand}${rgc}${gce}${cp}${pkgMode}"`;
        } else {
            return `${windowsBaseCommand}${cp}${uninstall}"`;
        }
    }
};
