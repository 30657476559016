import { observer } from "mobx-react-lite";
import * as yup from "yup";
import { useAppServices } from "../../app/services";
import { getImpactLevelConfig, impactLevelOptions } from "../MigrationCommon";
import { SyncQos } from "../../../_proto/galaxymigratepb/galaxy_migrate_qos_pb";
import { FieldArray, Form, Formik, FormikHelpers, FormikValues } from "formik";
import {
    Alert,
    Box,
    Button,
    Card,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    ListSubheader,
    SvgIcon,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { FormDateTimePicker, FormSelectableCardsGroup, FormTextField, FormTimePicker } from "../../../common/form/FormComponents";
import * as React from "react";
import { ReactElement, useState } from "react";
import { PostSyncActionSection } from "../workflow/GmMigrationSessionPostSyncActions";
import { GmMigrationSessionPostSyncActionsState } from "../GmMigrationService";
import { QuickTipButton } from "../../help/HelpCommon";
import { SelectableCard } from "../../../common/card/SelectableCard";
import EnableAutoResync from "../../../assets/migrationSession/enable_auto_resync.png";
import DisableAutoResync from "../../../assets/migrationSession/disable_auto_resync.png";
import { FaAngleDoubleRight } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { getBrowserTimezone } from "../../../common/utils/dateUtil";
import { isDeploymentGteVersion } from "../../deployment/DeploymentCommon";
import { GalaxyMigrateDeploymentDetails } from "../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { areIntervalsOverlapping, format } from "date-fns";
import { MTDIDeploymentInfo } from "../../../_proto/galaxycompletepb/apipb/domainpb/mtdi_pb";

// ======================
// GmMigrationSessionParametersForm
// ======================

export interface QosDailyScheduleIntervalType {
    start: Date;
    end: Date;
    qosLevel: SyncQos.ImpactLevel;
}

export interface MigrationQosScheduleType {
    daily: Array<QosDailyScheduleIntervalType>;
    weekends: Array<QosDailyScheduleIntervalType>;
    fridays: Array<QosDailyScheduleIntervalType>;
}

export interface MigrationParametersType {
    autoReSyncInterval: number;
    description: string;
    qosLevel: SyncQos.ImpactLevel;
    qosStartTime?: Date;
    qosSchedule?: MigrationQosScheduleType;
    remoteBootMigrationPostCutoverScript?: string;
}

interface GmMigrationSessionParametersFormProps {
    initialValues: MigrationParametersType;
    onSubmit: (values: FormikValues, formikHelpers?: FormikHelpers<MigrationParametersType>) => Promise<void>;
    formActions: ReactElement;
    actionsState: GmMigrationSessionPostSyncActionsState;
    sourceDeployment: GalaxyMigrateDeploymentDetails;
    isRemoteMigration: boolean;
    isComputeMigration: boolean;
    isBootVolumeMigration: boolean;
}

export const GmMigrationSessionParametersForm: React.FC<GmMigrationSessionParametersFormProps> = observer((props) => {
    const { initialValues, onSubmit, formActions, actionsState, sourceDeployment, isComputeMigration, isRemoteMigration, isBootVolumeMigration } = props;
    const { dialogService } = useAppServices();
    const [autoResync, setAutoResync] = React.useState(initialValues.autoReSyncInterval !== 0);
    const isScheduleInitiallyEnabled =
        !!initialValues.qosSchedule.daily.length || !!initialValues.qosSchedule.fridays.length || !!initialValues.qosSchedule.weekends.length;
    const [scheduleEnabled, setScheduleEnabled] = React.useState(isScheduleInitiallyEnabled);
    const isMinVer = isDeploymentGteVersion(sourceDeployment, "5.0.0");
    const hostTimezone = sourceDeployment.getInfo().getDeployment().getSystemTimezone();
    const supportPostCutoverStartupScript = isComputeMigration || (isRemoteMigration && isBootVolumeMigration);
    const isWindows = sourceDeployment.getInfo().getOsType() === MTDIDeploymentInfo.OSType.WINDOWS;

    const scheduleSchema = yup.array().of(
        yup.object().shape(
            {
                start: yup
                    .date()
                    .when("end", (end) => {
                        if (end.getHours() !== 0) {
                            return yup.date().max(end, "Start time must be before end time.");
                        }
                    })
                    .typeError("Enter a valid date."),
                end: yup
                    .date()
                    .when("start", (start, schema) => {
                        return schema.test({
                            name: "max 12:00am next day",
                            message: "End time must be after start time. Max 12:00am next day.",
                            test: (end: Date) => {
                                //if end hour is other than 12am;
                                if (end.getHours() !== 0) {
                                    return start < end;
                                }
                                //if end hour is 12am, end minute must be 0;
                                else {
                                    return end.getMinutes() === 0;
                                }
                            },
                        });
                    })
                    .typeError("Enter a valid date."),
                qosLevel: yup.number(),
            },
            [["end", "start"]]
        )
    );

    const schema = yup.object({
        description: yup.string().nullable().label("Description"),
        qosLevel: yup.number().nullable(),
        autoReSyncInterval: yup.number().nullable().moreThan(-1).label("Auto Sync Interval"),
        qosStartTime: yup.date().nullable(),
        qosSchedule: yup.object({
            daily: scheduleSchema,
            weekends: scheduleSchema,
            fridays: scheduleSchema,
        }),
        remoteBootMigrationPostCutoverScript: yup
            .string()
            .nullable()
            .max(256 * 1024)
            .label("Post Cutover Script"),
    });

    const submit = async (values: MigrationParametersType, formikHelpers: FormikHelpers<MigrationParametersType>) => {
        const overlapErrors = getQosScheduleOverlapErrors(values.qosSchedule);
        if (!!overlapErrors.daily.length || !!overlapErrors.fridays.length || !!overlapErrors.weekends.length) {
            await dialogService.addAlertDialog({
                title: "Error Encountered",
                message: (
                    <>
                        {!!overlapErrors.daily.length && (
                            <>
                                <Typography>Daily Schedule is Invalid:</Typography>
                                <>
                                    {overlapErrors.daily.map((v) => (
                                        <Typography>{v}</Typography>
                                    ))}
                                </>
                            </>
                        )}
                        {!!overlapErrors.fridays.length && (
                            <>
                                <Typography>Friday Schedule is Invalid:</Typography>
                                <>
                                    {overlapErrors.fridays.map((v) => (
                                        <Typography>{v}</Typography>
                                    ))}
                                </>
                            </>
                        )}
                        {!!overlapErrors.weekends.length && (
                            <>
                                <Typography>Weekend Schedule is Invalid:</Typography>
                                <>
                                    {overlapErrors.weekends.map((v) => (
                                        <Typography>{v}</Typography>
                                    ))}
                                </>
                            </>
                        )}
                    </>
                ),
            });
        } else {
            await onSubmit(values, formikHelpers);
        }
    };

    return (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submit}>
            {(props) => {
                const handleSelectAutoResyncOption = (autoResync: boolean) => {
                    setAutoResync(autoResync);
                    if (!!autoResync && !props.values.autoReSyncInterval) {
                        props.setFieldValue("autoReSyncInterval", initialValues.autoReSyncInterval);
                    }
                    if (!autoResync) {
                        props.setFieldValue("autoReSyncInterval", 0);
                    }
                };
                return (
                    <>
                        <Form>
                            <Box>
                                <Typography>Customize your migration session using the settings below </Typography>
                                <Box pt={2} pb={2}>
                                    <Typography variant={"h4"}>1. Description</Typography>
                                    <br />
                                    <FormTextField name={"description"} label={"Session Description"} variant={"filled"} />
                                </Box>
                                <Box pt={2} pb={2}>
                                    <Box display={"flex"} alignItems={"center"}>
                                        <Typography variant={"h4"}>
                                            {"2. Automatic Re-Synchronization"}
                                            &nbsp;
                                        </Typography>
                                        <QuickTipButton title={"Periodically trigger synchronization after initial migration"} />
                                    </Box>
                                    <br />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <SelectableCard
                                                onSelect={() => handleSelectAutoResyncOption(true)}
                                                selected={autoResync}
                                                sx={{ height: "100%" }}
                                                icon={<img src={EnableAutoResync} alt={"Enable Auto Resync"} height={72} />}
                                                title={"Enable Auto Re-sync"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <SelectableCard
                                                onSelect={() => handleSelectAutoResyncOption(false)}
                                                selected={!autoResync}
                                                sx={{ height: "100%" }}
                                                icon={<img src={DisableAutoResync} alt={"Disable Auto Resync"} height={72} />}
                                                title={"Disable Auto Re-sync"}
                                                description={"Disabling this will allow Manual Re-Sync only"}
                                            />
                                        </Grid>
                                    </Grid>
                                    <br />
                                    {autoResync && (
                                        <FormTextField
                                            helperText={`All volumes will be synchronized every ${(props.values.autoReSyncInterval / 60).toFixed(2)} hour(s)`}
                                            type={"number"}
                                            name={"autoReSyncInterval"}
                                            label={"Auto Re-sync Interval"}
                                            variant={"filled"}
                                            InputProps={{
                                                endAdornment: <Box pl={1}>Minutes</Box>,
                                            }}
                                        />
                                    )}
                                </Box>
                                <Box pt={2} pb={2}>
                                    <Box display={"flex"} alignItems={"center"}>
                                        <Typography variant={"h4"}>
                                            {"3. iQoS"}
                                            &nbsp;
                                        </Typography>
                                        <QuickTipButton title={"Intelligently scale migration activity to minimize impact to production workload"} />
                                    </Box>
                                    {isMinVer && (
                                        <Box pt={2} pb={2}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={scheduleEnabled}
                                                            onChange={(e) => {
                                                                setScheduleEnabled(e.target.checked);
                                                                if (e.target.checked) {
                                                                    if (!!props.initialValues.qosStartTime) {
                                                                        props.setFieldValue("qosStartTime", new Date());
                                                                    }
                                                                    props.setFieldValue("qosSchedule.daily", [
                                                                        {
                                                                            start: new Date(0, 0, 0, 0),
                                                                            end: new Date(0, 0, 0, 0),
                                                                            qosLevel: SyncQos.ImpactLevel.RELENTLESS,
                                                                        },
                                                                    ]);
                                                                } else {
                                                                    props.setFieldValue("qosLevel", SyncQos.ImpactLevel.RELENTLESS);
                                                                    props.setFieldValue("qosStartTime", null);
                                                                    props.setFieldValue("qosSchedule.daily", []);
                                                                    props.setFieldValue("qosSchedule.fridays", []);
                                                                    props.setFieldValue("qosSchedule.weekends", []);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label={<Typography variant={"h6"}>Enable Migration Schedule</Typography>}
                                                />
                                            </FormGroup>
                                            <Box pt={1}>
                                                <Alert severity={"info"}>
                                                    If enabled, you may arrange a scheduled migration start time and schedule specified time slots at various
                                                    iQoS levels.
                                                </Alert>
                                            </Box>
                                        </Box>
                                    )}
                                    {scheduleEnabled && (
                                        <Box pb={2}>
                                            <Box pb={3}>
                                                <Typography variant={"h4"}>3.1. Scheduled Migration Start Time</Typography>
                                                {!props.initialValues.qosStartTime && (
                                                    <Box pt={2}>
                                                        <Alert severity={"warning"}>Cannot set start time for a session that has already started.</Alert>
                                                    </Box>
                                                )}
                                                {!!props.initialValues.qosStartTime && (
                                                    <Box pt={1}>
                                                        <FormDateTimePicker
                                                            label={"Scheduled Start Time"}
                                                            name={"qosStartTime"}
                                                            helperText={`in ${getBrowserTimezone()} (Browser Timezone)`}
                                                            disabled={!props.initialValues.qosStartTime}
                                                        />
                                                    </Box>
                                                )}
                                            </Box>
                                            <Typography variant={"h4"}>3.2 Migration Time Slots </Typography>
                                            <Typography variant={"body2"} color={"textSecondary"}>
                                                Please select a time slot and ideal impact level. Note that migration will stop for time not included in the
                                                selected time slots.
                                            </Typography>
                                            <TimeSlotCard
                                                fieldName={"qosSchedule.daily"}
                                                values={props.values.qosSchedule.daily}
                                                setFieldValue={props.setFieldValue}
                                                title={"Daily Schedule"}
                                                hostTimezone={hostTimezone}
                                            />
                                            <TimeSlotCard
                                                fieldName={"qosSchedule.fridays"}
                                                values={props.values.qosSchedule.fridays}
                                                setFieldValue={props.setFieldValue}
                                                title={"Different Schedule For Fridays"}
                                                optional
                                                hostTimezone={hostTimezone}
                                            />
                                            <TimeSlotCard
                                                fieldName={"qosSchedule.weekends"}
                                                values={props.values.qosSchedule.weekends}
                                                setFieldValue={props.setFieldValue}
                                                title={"Different Schedule For Weekends"}
                                                optional
                                                hostTimezone={hostTimezone}
                                            />
                                        </Box>
                                    )}
                                    {!scheduleEnabled && <FormSelectableCardsGroup name={"qosLevel"} cardSize={"small"} options={impactLevelOptions} />}
                                </Box>
                                <Box pt={2}>
                                    <Typography variant={"h4"}>{"4. Customize Post-Synchronization Actions"}</Typography>
                                    <br />
                                    <PostSyncActionSection actionsState={actionsState} />
                                </Box>
                                {supportPostCutoverStartupScript && (
                                    <Box pt={4}>
                                        <Typography variant={"h4"}>{"5. Configure Post Cutover Startup Script"}</Typography>
                                        <br />
                                        <Typography variant={"body2"} paragraph>
                                            If configured, the following script will be executed by the destination host during{" "}
                                            <b>first system startup after cutover has been completed</b>. This script will only be executed once and only
                                            applies to remote/compute migration that involves boot volumes.
                                        </Typography>
                                        {isWindows && (
                                            <Typography variant={"body2"} paragraph>
                                                The script provided must be a valid <b>Powershell</b> script.
                                            </Typography>
                                        )}
                                        {!isWindows && (
                                            <Typography variant={"body2"} paragraph>
                                                The script provided must be a valid <b>Bash</b> script.
                                            </Typography>
                                        )}
                                        <FormTextField
                                            name={"remoteBootMigrationPostCutoverScript"}
                                            label={"Post-Migration Script"}
                                            minRows={10}
                                            multiline
                                            variant={"filled"}
                                        />
                                    </Box>
                                )}
                            </Box>
                            <br />
                            {formActions}
                        </Form>
                    </>
                );
            }}
        </Formik>
    );
});

// ======================
// TimeSlotCard
// ======================

interface TimeSlotCardProps {
    fieldName: string;
    values: Array<QosDailyScheduleIntervalType>;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    optional?: boolean;
    title: string;
    hostTimezone: string;
}

export const TimeSlotCard: React.FC<TimeSlotCardProps> = observer((p) => {
    const { fieldName, values, setFieldValue, optional, title, hostTimezone } = p;
    const isInitiallyEnabled = !optional ? true : !!values.length;

    const [enabled, setEnabled] = useState(isInitiallyEnabled);

    const handleTurnOptionalSchedulesOn = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            setFieldValue(fieldName, [
                {
                    start: new Date(0, 0, 0, 0),
                    end: new Date(0, 0, 0, 0),
                    qosLevel: SyncQos.ImpactLevel.RELENTLESS,
                },
            ]);
        } else {
            setFieldValue(fieldName, []);
        }
        setEnabled(checked);
    };

    return (
        <Box pt={2} pb={2}>
            <Card>
                <Box display={"flex"} alignItems={"center"}>
                    {optional && <Switch checked={enabled} onChange={handleTurnOptionalSchedulesOn} color={"secondary"} />}
                    <ListSubheader>{title}</ListSubheader>
                </Box>
                <Divider orientation={"horizontal"} />
                {enabled && (
                    <Box pb={2}>
                        <FieldArray name={fieldName}>
                            {({ insert, remove, push }) => (
                                <>
                                    {values.length > 0 &&
                                        values.map((v, i) => {
                                            return (
                                                <ScheduleTimeSlot
                                                    index={i}
                                                    value={v}
                                                    key={i}
                                                    setFieldValue={setFieldValue}
                                                    fieldName={fieldName}
                                                    deleteSlot={remove}
                                                    hostTimezone={hostTimezone}
                                                />
                                            );
                                        })}
                                    <Box p={2}>
                                        <Button
                                            variant={"outlined"}
                                            onClick={() => {
                                                const startHour = values[values.length - 1]?.end?.getHours() || 0;
                                                const startMinute = values[values.length - 1]?.end?.getMinutes() || 0;

                                                push({
                                                    start: new Date(0, 0, 0, startHour, startMinute),
                                                    end: new Date(0, 0, 0, 0, 0),
                                                    qosLevel: SyncQos.ImpactLevel.RELENTLESS,
                                                });
                                            }}
                                        >
                                            Add Time Slot
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </FieldArray>
                    </Box>
                )}
            </Card>
        </Box>
    );
});

// ======================
// ScheduleTimeSlot
// ======================

interface ScheduleTimeSlotProps {
    index: number;
    value: QosDailyScheduleIntervalType;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    deleteSlot: (index: number) => void;
    fieldName: string;
    hostTimezone: string;
}

export const ScheduleTimeSlot: React.FC<ScheduleTimeSlotProps> = observer((p) => {
    const { index, value, setFieldValue, deleteSlot, fieldName, hostTimezone } = p;
    const theme = useTheme();
    const isLg = useMediaQuery(theme.breakpoints.up("lg"));

    const getErrorMessage = (r: string, v: Date) => {
        if (r?.includes("shouldDisableTime")) {
            return "Time must be in 15 minute intervals";
        } else if (r?.includes("invalid")) {
            return "Invalid time format";
        }
        return null;
    };

    const shouldDisableTime = (timeValue: Date, clockType: string) => {
        return Boolean(clockType === "minutes" && timeValue.getMinutes() % 15);
    };

    const deleteSlotButton = (
        <Tooltip arrow title={"Remove Time Slot"}>
            <IconButton onClick={() => deleteSlot(index)}>
                <SvgIcon>
                    <MdClose />
                </SvgIcon>
            </IconButton>
        </Tooltip>
    );

    return (
        <Box pt={2} pb={2} sx={{ borderBottom: "1px solid", borderBottomColor: theme.palette.cirrus.main }}>
            {!isLg && (
                <Box display={"flex"} justifyContent={"flex-end"} pr={2}>
                    {deleteSlotButton}
                </Box>
            )}
            <Grid container spacing={3} alignItems={"center"}>
                <Grid item xs={12} lg={5}>
                    <Box pr={2} pl={2}>
                        <Box pb={1}>
                            <Typography variant={"body2"}>
                                <b>Time Slot:</b>
                            </Typography>
                            <Typography variant={"caption"}>Choose from 15 minute intervals (e.g. 12:00am, 12:15am, 12:30am, 12:45am)</Typography>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormTimePicker
                                    label={"From"}
                                    name={`${fieldName}.${index}.start`}
                                    helperText={`in ${hostTimezone} (Host System Timezone)`}
                                    getErrorMessage={getErrorMessage}
                                    shouldDisableTime={shouldDisableTime}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormTimePicker
                                    label={"To"}
                                    name={`${fieldName}.${index}.end`}
                                    helperText={`in ${hostTimezone} (Host System Timezone)`}
                                    getErrorMessage={getErrorMessage}
                                    shouldDisableTime={shouldDisableTime}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {isLg && (
                    <Grid item xs={12} sm={1} justifyContent={"center"}>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} pt={2}>
                            <SvgIcon>
                                <FaAngleDoubleRight />
                            </SvgIcon>
                        </Box>
                    </Grid>
                )}

                <Grid item xs={12} lg={5}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Box pr={2} pl={2}>
                            <Box pb={1}>
                                <Typography variant={"body2"}>
                                    <b>iQoS Level:</b>
                                </Typography>
                            </Box>

                            <ToggleButtonGroup
                                color={"secondary"}
                                exclusive
                                value={value.qosLevel}
                                onChange={(e, v) => {
                                    if (v !== null) {
                                        setFieldValue(`${fieldName}.${index}.qosLevel`, v);
                                    }
                                }}
                            >
                                {impactLevelOptions.map((option) => {
                                    return (
                                        <ToggleButton value={option.value} key={option.value}>
                                            {option.title}
                                        </ToggleButton>
                                    );
                                })}
                            </ToggleButtonGroup>
                            <Box pt={1}>
                                <Typography variant={"caption"}>
                                    {getImpactLevelConfig(value.qosLevel).title}: &nbsp;
                                    {getImpactLevelConfig(value.qosLevel).description}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {isLg && (
                    <Grid item xs={1}>
                        <Box pt={2}>{deleteSlotButton}</Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
});

const getQosScheduleOverlapErrors = (qosSchedule: MigrationQosScheduleType) => {
    const convertMidnightEndTime = (end: Date) => {
        if (end.getHours() === 0 && end.getMinutes() === 0) {
            return new Date(0, 0, 1, 0, 0);
        } else {
            return end;
        }
    };

    let allOverlappingIntervals: { daily: Array<string>; fridays: Array<string>; weekends: Array<string> } = {
        daily: [],
        fridays: [],
        weekends: [],
    };

    if (!!qosSchedule.daily.length) {
        for (let i = 0; i < qosSchedule.daily.length; i++) {
            for (let j = i + 1; j < qosSchedule.daily.length; j++) {
                const firstInterval = {
                    start: qosSchedule.daily[i].start,
                    end: convertMidnightEndTime(qosSchedule.daily[i].end),
                };
                const secondInterval = {
                    start: qosSchedule.daily[j].start,
                    end: convertMidnightEndTime(qosSchedule.daily[j].end),
                };
                const intervalsOverlap = areIntervalsOverlapping(firstInterval, secondInterval);
                if (intervalsOverlap) {
                    allOverlappingIntervals.daily.push(
                        `Timeslot ${i + 1} (${format(firstInterval.start, "hh:mm aa")}-${format(firstInterval.end, "hh:mm aa")}) overlaps with Timeslot ${
                            j + 1
                        } (${format(secondInterval.start, "hh:mm aa")}-${format(secondInterval.end, "hh:mm aa")})`
                    );
                }
            }
        }
    }

    if (!!qosSchedule.fridays.length) {
        for (let i = 0; i < qosSchedule.fridays.length; i++) {
            for (let j = i + 1; j < qosSchedule.fridays.length; j++) {
                const firstInterval = {
                    start: qosSchedule.fridays[i].start,
                    end: convertMidnightEndTime(qosSchedule.fridays[i].end),
                };
                const secondInterval = {
                    start: qosSchedule.fridays[j].start,
                    end: convertMidnightEndTime(qosSchedule.fridays[j].end),
                };
                const intervalsOverlap = areIntervalsOverlapping(firstInterval, secondInterval);
                if (intervalsOverlap) {
                    allOverlappingIntervals.fridays.push(
                        `Timeslot ${i + 1} (${format(firstInterval.start, "hh:mm aa")}-${format(firstInterval.end, "hh:mm aa")}) overlaps with Timeslot ${
                            j + 1
                        } (${format(secondInterval.start, "hh:mm aa")}-${format(secondInterval.end, "hh:mm aa")})`
                    );
                }
            }
        }
    }

    if (!!qosSchedule.weekends.length) {
        for (let i = 0; i < qosSchedule.weekends.length; i++) {
            for (let j = i + 1; j < qosSchedule.weekends.length; j++) {
                const firstInterval = {
                    start: qosSchedule.weekends[i].start,
                    end: convertMidnightEndTime(qosSchedule.weekends[i].end),
                };
                const secondInterval = {
                    start: qosSchedule.weekends[j].start,
                    end: convertMidnightEndTime(qosSchedule.weekends[j].end),
                };
                const intervalsOverlap = areIntervalsOverlapping(firstInterval, secondInterval);
                if (intervalsOverlap) {
                    allOverlappingIntervals.weekends.push(
                        `Timeslot ${i + 1} (${format(firstInterval.start, "hh:mm aa")}-${format(firstInterval.end, "hh:mm aa")}) overlaps with Timeslot ${
                            j + 1
                        } (${format(secondInterval.start, "hh:mm aa")}-${format(secondInterval.end, "hh:mm aa")})`
                    );
                }
            }
        }
    }

    return allOverlappingIntervals;
};
