// ======================
// CallbackScreen
// ======================

import {observer} from 'mobx-react-lite';
import {ScreenContainer} from '../layout/ScreenCommon';
import {useAppServices} from '../app/services';
import {useEffectOnce} from 'react-use';
import {CallbackConfig} from './CallbackConfigs';
import {generatePath, useNavigate} from 'react-router-dom';
import React, {useCallback} from 'react';

interface CallbackScreenProps {
    config: CallbackConfig;
}

export const CallbackScreen: React.FC<CallbackScreenProps> = observer((p) => {
    const {dialogService} = useAppServices();
    const navigate        = useNavigate();
    const redirect        = useCallback(() => navigate(generatePath(p.config.redirectRoute)), [p.config.redirectRoute, navigate]);

    const confirmAndRedirect = useCallback(async () => {
        await dialogService.addAlertDialog({
            title: p.config.dialogTitle,
            renderAdditionalContent: p.config.dialogMessage,
            okButtonLabel: p.config.buttonText});
        redirect();
    }, [p.config, dialogService, redirect]);

    useEffectOnce(() => {
        confirmAndRedirect();
    });
    return <ScreenContainer/>;
});