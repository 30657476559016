import {GalaxyMigrateMigrationSessionDetails} from "../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    Badge,
    Box,
    Button,
    Card, Chip,
    Dialog,
    Divider, Grid, Link,
    ListItem,
    ListItemIcon, ListItemText,
    ListSubheader,
    SvgIcon, Theme, Tooltip, Typography, TypographyProps, useTheme
} from "@mui/material";
import {DialogState, useDialogState, useShouldDialogFullScreen} from "../../core/dialog/DialogService";
import {useAppServices} from "../../app/services";
import {renderServerDataWithLoadingList, useInitData} from "../../core/data/DataLoaderHooks";
import {DialogTopBar} from "../../core/dialog/DialogComponents";
import {getActionDisplayName, getPostSyncActionDefFromAction} from "./GmMigrationSessionPostSyncActions";
import {CollapsibleCard} from "../../../common/collapsible/CollapsibleCard";
import {
    WorkflowStage,
    WorkflowStageStatus,
    WorkflowStatus
} from "../../../_proto/galaxycompletepb/apipb/domainpb/workflow_pb";
import {Link as RouterLink, useParams} from "react-router-dom";
import {useIsDesktop} from "../../layout/MainLayout";
import {ActionConfig} from "../../../common/actions/CommonActions";
import {TiCancel} from "react-icons/ti";
import {ColumnDef, DataTable} from "../../../common/table/DataTable";
import {generateMigrationSessionWorkflowRunRoute, useNavigateToSessionEditScreen} from "../MigrationCommon";
import {KnownDataType} from "../../../common/utils/formatter";
import {CgChevronDoubleRightO, CgTimelapse} from "react-icons/cg";
import {AiFillCloseCircle} from "react-icons/ai";
import {FaCheckCircle} from "react-icons/fa";
import {
    ActionConfiguredPropertiesTable,
    getWorkflowRunStatusDisplayText,
    getWorkflowStageStatusDisplayText, usePostSyncActionStyles
} from "./PostSyncActionsCommon";

// ======================
// PostSyncActionsSection
// ======================


interface SessionDetailsPostSyncActionsSectionProps {
    data: GalaxyMigrateMigrationSessionDetails
}

export const SessionDetailsPostSyncActionsSection: React.FC<SessionDetailsPostSyncActionsSectionProps> = observer((p) => {

    return <Box pt={2}><Card>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListSubheader>Activities</ListSubheader>
            <Box p={2}>
                <ViewPostSyncActionsConfigButton workflowId={p.data.getSessionInfo().getPostSyncWorkflowId()}/>
            </Box>
        </Box>
        <WorkflowActivitiesList workflowId={p.data.getSessionInfo().getPostSyncWorkflowId()}/>
    </Card>
    </Box>


});

// ======================
// ViewPostSyncActionsConfigButton
// ======================

interface ViewPostSyncActionsConfigButtonProps {
    workflowId: number
}

export const ViewPostSyncActionsConfigButton: React.FC<ViewPostSyncActionsConfigButtonProps> = observer((p) => {
    const dialogState = useDialogState();

    return <>
        {
            !!p.workflowId &&
            <Button variant={'outlined'} onClick={dialogState.open}>View Actions Config</Button>
        }
        {dialogState.isOpen && <ViewPostSyncActionsConfigDialog dialogState={dialogState} workflowId={p.workflowId}/>}
    </>
});

// ======================
// ViewPostSyncActionsConfigDialog
// ======================

interface ViewPostSyncActionsConfigDialogProps {
    dialogState: DialogState
    workflowId: number
}

export const ViewPostSyncActionsConfigDialog: React.FC<ViewPostSyncActionsConfigDialogProps> = observer((p) => {
    const styles = usePostSyncActionStyles();

    const {gmMigrationService} = useAppServices();

    const fullScreen = useShouldDialogFullScreen();

    if (!!p.workflowId) {
        useInitData({
            poll: () => gmMigrationService.currentSessionWorkflow.fetchData(p.workflowId),
            pollInterval: 60

        })

        return <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} fullWidth maxWidth={'sm'}
                       fullScreen={fullScreen}>
            <DialogTopBar dialogState={p.dialogState} title={'Post Synchronization Actions'}/>
            <Box pb={2} pr={2}>
                {
                    renderServerDataWithLoadingList(gmMigrationService.currentSessionWorkflow, data => {
                        const actions = data.getStagesList();
                        return <Box p={2}>
                            {actions.map((a, i) => {
                                const actionDef = getPostSyncActionDefFromAction(a.getAction());
                                return <>
                                    <Box display={'flex'} alignItems={'center'} width={'100%'}>
                                        <CollapsibleCard sx={styles.actionCard}
                                                         collapsibleElement={<ActionConfigDetails workflowStage={a}/>}>
                                            <Box display={'flex'} alignItems={'center'} height={72}>
                                                <Box pl={4} pr={4} display={'flex'}>
                                                    <Badge badgeContent={i + 1} color={'primary'}
                                                           sx={{
                                                               '& .MuiBadge-badge': styles.badge
                                                           }}/>
                                                </Box>

                                                <Box height={72}>
                                                    <Divider orientation={'vertical'} sx={styles.divider}/>
                                                </Box>
                                                <Box>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <SvgIcon>
                                                                {actionDef.icon}
                                                            </SvgIcon>
                                                        </ListItemIcon>
                                                        <ListItemText primary={actionDef.label}
                                                                      secondary={actionDef.description}/>
                                                    </ListItem>
                                                </Box>
                                            </Box>

                                        </CollapsibleCard>

                                    </Box>
                                    {(i !== actions.length - 1) &&
                                    <svg width={'100%'} height={40}>
                                        <line x1={'50%'} y1={5} x2={'50%'} y2={40} strokeWidth={3} stroke={'white'}/>
                                    </svg>
                                    }


                                </>
                            })
                            }
                        </Box>
                    })
                }
            </Box>
        </Dialog>

    }

    return <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} fullWidth maxWidth={'md'}
                   fullScreen={fullScreen}>
        <DialogTopBar dialogState={p.dialogState} title={'Post Synchronization Actions'}/>
        <Box pt={2}>
            <Card>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} p={2}>
                    <Typography variant={'body2'} color={'textSecondary'}>
                        No actions specified.
                    </Typography>
                </Box>
            </Card>
        </Box>
    </Dialog>

});

// ======================
// ActionConfigDetails
// ======================

interface ActionConfigDetailsProps {
    workflowStage: WorkflowStage
}

export const ActionConfigDetails: React.FC<ActionConfigDetailsProps> = observer((p) => {
    const styles = usePostSyncActionStyles();

    return <Box width={'100%'}>
        <Divider sx={styles.divider}/>
        <Box p={2}>
            <Card>
                <ListSubheader>
                    Configured Properties
                </ListSubheader>
                <ActionConfiguredPropertiesTable workflowStage={p.workflowStage}/>
            </Card>
        </Box>


    </Box>
});

// ======================
// ActionsList
// ======================

interface ActionsListProps {
    workflowId: number
}

export const WorkflowActivitiesList: React.FC<ActionsListProps> = observer((p) => {
    const {gmMigrationService} = useAppServices();
    const t = useTheme();
    const {projectId, sessionId} = useParams();
    const navigateToEditSession = useNavigateToSessionEditScreen();


    const isMobile = !useIsDesktop();

    if (!p.workflowId){
        return <Box display={'flex'} justifyContent={'center'} pt={4} pb={6}>
            <Box textAlign={'center'}>

                <Typography variant={'h6'}>No Activity</Typography>

                <Typography variant={'body2'} color={'textSecondary'}>To set up post-synchronization actions, click below.</Typography>
                <br/>
                <Button variant={'outlined'} onClick={navigateToEditSession}>Edit Session</Button>
            </Box>
        </Box>
    }

    useInitData({
        poll: () => gmMigrationService.currentSessionWorkflowRuns.fetchData(p.workflowId),
        pollInterval: 5
    });

    const getRowActions = (r: WorkflowStatus): ActionConfig[] => {
        return [{
            id: 'cancel',
            name: 'Abort Task',
            icon: <SvgIcon><TiCancel/></SvgIcon>,
            action: () => gmMigrationService.cancelWorkflowRun(r.getId()),
            hidden: !r.getRunning()
        }
        ]
    }

    return renderServerDataWithLoadingList(gmMigrationService.currentSessionWorkflowRuns, data => {
        const cols: ColumnDef<WorkflowStatus>[] = [
            {
                id: 'id',
                label: 'ID',
                getter: d => d.getId(),
                renderer: (v) => {
                    return <Link component={RouterLink}
                                 to={generateMigrationSessionWorkflowRunRoute(projectId, sessionId, v)}>{v}</Link>
                }
            },
            {
                id: 'tasks',
                label: 'Tasks',
                getter: d => d.getStagesList(),
                renderer: (d: Array<WorkflowStageStatus>, r) => {
                    return <Grid container spacing={0}>
                        {d.map((s, i) => {
                            return <Grid item key={i}>
                                <Box pt={1} pb={1} display={'flex'} alignItems={'center'}>
                                    {s.getIndex() !== 0 &&
                                    <svg height={10} width={5}>
                                        <line x1={0} y1={5} x2={5} y2={5} stroke={t.palette.grey['500']}/>
                                    </svg>
                                    }
                                    <StageProgressChip status={s} isMobile={isMobile} workflowLength={d.length}
                                                       workflowStatus={r}/>
                                    {d.length - 1 !== s.getIndex() &&
                                    <svg height={10} width={5}>
                                        <line x1={0} y1={5} x2={5} y2={5} stroke={t.palette.grey['500']}/>
                                    </svg>
                                    }
                                </Box>
                            </Grid>
                        })
                        }
                    </Grid>
                }
            },
            {
                id: 'triggerTime',
                label: 'Start Time',
                getter: d => d.getStartedAt().toDate(),
                dataType: KnownDataType.DATE
            },
            {
                id: 'status',
                label: 'Status',
                getter: d => d,
                renderer: (_, v) => getWorkflowRunStatusDisplayText(v, t)
            }
        ]
        return <DataTable state={gmMigrationService.currentSessionWorkflowRuns.tableState}
                          onTableStateChange={()=>gmMigrationService.currentSessionWorkflowRuns.fetchData(p.workflowId)}
                          rows={data.getItemsList()}
                          cols={cols}
                          pagerMeta={gmMigrationService.currentSessionWorkflowRuns.data.getPagerMeta().toObject()}
                          rowActions={getRowActions}/>
    })
});


const useProgressChipStyles = (props: StageProgressChipProps) => {
    const t = useTheme();
    return {
        chip: {
            color: () => {
                if (props.status.getPending()) {
                    return t.palette.grey["500"]
                } else if (props.status.getError()) {
                    return t.palette.error.main
                } else if (props.status.getEndedAt().getSeconds() > 0) {
                    return t.palette.success.main
                } else {
                    return t.palette.primary.main
                }
            },
            borderColor: () => {
                if (props.status.getPending()) {
                    return t.palette.grey["500"]
                } else if (props.status.getError()) {
                    return t.palette.error.main
                } else if (props.status.getEndedAt().getSeconds() > 0) {
                    return t.palette.success.main
                } else {
                    return t.palette.primary.main
                }
            }
        },
        icon: {
            color: () => {
                if (props.status.getPending()) {
                    return t.palette.grey["500"]
                } else if (props.status.getError()) {
                    return t.palette.error.main
                } else if (props.status.getEndedAt().getSeconds() > 0) {
                    return t.palette.success.main
                } else {
                    return t.palette.primary.main
                }
            },
        },
        svgIcon: {
            color: () => {
                if (props.status.getPending()) {
                    return t.palette.grey["500"]
                } else if (props.status.getError()) {
                    return t.palette.error.main
                } else if (props.status.getEndedAt().getSeconds() > 0) {
                    return t.palette.success.main
                } else {
                    return t.palette.primary.main
                }
            },
            padding: 1,
            borderColor: () => {
                if (props.status.getPending()) {
                    return t.palette.grey["500"]
                } else if (props.status.getError()) {
                    return t.palette.error.main
                } else if (props.status.getEndedAt().getSeconds() > 0) {
                    return t.palette.success.main
                } else {
                    return t.palette.primary.main
                }
            },
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '50px'
        }
    }

}

// ======================
// StageProgressChip
// ======================

interface StageProgressChipProps {
    status: WorkflowStageStatus,
    workflowLength: number,
    workflowStatus: WorkflowStatus
    isMobile: boolean
}

export const StageProgressChip: React.FC<StageProgressChipProps> = observer((p) => {
    const styles = useProgressChipStyles(p);
    const getIcon = () => {
        if (p.status.getPending()) {
            if (!!p.workflowStatus.getError()) {
                return <TiCancel/>
            }
            return <CgChevronDoubleRightO/>
        } else if (p.status.getError()) {
            return <AiFillCloseCircle/>
        } else if (p.status.getEndedAt().getSeconds() > 0) {
            return <FaCheckCircle/>
        } else {
            return <CgTimelapse/>
        }
    };

    const getProgressDisplay = () => {
        if (p.workflowLength > 4 || p.isMobile) {
            return <SvgIcon sx={styles.svgIcon}>
                {getIcon()}
            </SvgIcon>

        } else {
            return <Chip icon={<SvgIcon>
                             {getIcon()}
                         </SvgIcon>}
                         sx={{
                             ...styles.chip,
                             '& .MuiChip-icon': styles.icon
                         }}
                         variant={'outlined'}
                         label={getActionDisplayName(p.status.getInfo().getAction())}/>
        }
    }

    return <Tooltip arrow
                    title={`${getActionDisplayName(p.status.getInfo().getAction())}: ${getWorkflowStageStatusDisplayText(p.status, p.workflowStatus)}`}>
        {getProgressDisplay()}
    </Tooltip>
});

