/**
 * @fileoverview gRPC-Web generated client stub for galaxycomplete.api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: galaxycompletepb/apipb/auth_api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as galaxycompletepb_apipb_auth_api_pb from '../../galaxycompletepb/apipb/auth_api_pb';
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb';


export class LoginServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorRequestMfaToken = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.LoginService/RequestMfaToken',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.RequestMfaToken.Request,
    galaxycompletepb_apipb_auth_api_pb.RequestMfaToken.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.RequestMfaToken.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.RequestMfaToken.Response.deserializeBinary
  );

  requestMfaToken(
    request: galaxycompletepb_apipb_auth_api_pb.RequestMfaToken.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.RequestMfaToken.Response>;

  requestMfaToken(
    request: galaxycompletepb_apipb_auth_api_pb.RequestMfaToken.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.RequestMfaToken.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.RequestMfaToken.Response>;

  requestMfaToken(
    request: galaxycompletepb_apipb_auth_api_pb.RequestMfaToken.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.RequestMfaToken.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.LoginService/RequestMfaToken',
        request,
        metadata || {},
        this.methodDescriptorRequestMfaToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.LoginService/RequestMfaToken',
    request,
    metadata || {},
    this.methodDescriptorRequestMfaToken);
  }

  methodDescriptorLogin = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.LoginService/Login',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.Login.Request,
    galaxycompletepb_apipb_auth_api_pb.Login.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.Login.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.Login.Response.deserializeBinary
  );

  login(
    request: galaxycompletepb_apipb_auth_api_pb.Login.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.Login.Response>;

  login(
    request: galaxycompletepb_apipb_auth_api_pb.Login.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.Login.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.Login.Response>;

  login(
    request: galaxycompletepb_apipb_auth_api_pb.Login.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.Login.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.LoginService/Login',
        request,
        metadata || {},
        this.methodDescriptorLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.LoginService/Login',
    request,
    metadata || {},
    this.methodDescriptorLogin);
  }

  methodDescriptorLogout = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.LoginService/Logout',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    google_protobuf_empty_pb.Empty,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  logout(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  logout(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  logout(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.LoginService/Logout',
        request,
        metadata || {},
        this.methodDescriptorLogout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.LoginService/Logout',
    request,
    metadata || {},
    this.methodDescriptorLogout);
  }

  methodDescriptorForgotPassword = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.LoginService/ForgotPassword',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.ForgotPassword.Request,
    galaxycompletepb_apipb_auth_api_pb.ForgotPassword.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.ForgotPassword.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.ForgotPassword.Response.deserializeBinary
  );

  forgotPassword(
    request: galaxycompletepb_apipb_auth_api_pb.ForgotPassword.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.ForgotPassword.Response>;

  forgotPassword(
    request: galaxycompletepb_apipb_auth_api_pb.ForgotPassword.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.ForgotPassword.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.ForgotPassword.Response>;

  forgotPassword(
    request: galaxycompletepb_apipb_auth_api_pb.ForgotPassword.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.ForgotPassword.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.LoginService/ForgotPassword',
        request,
        metadata || {},
        this.methodDescriptorForgotPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.LoginService/ForgotPassword',
    request,
    metadata || {},
    this.methodDescriptorForgotPassword);
  }

  methodDescriptorResetPassword = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.LoginService/ResetPassword',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.ResetPassword.Request,
    galaxycompletepb_apipb_auth_api_pb.ResetPassword.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.ResetPassword.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.ResetPassword.Response.deserializeBinary
  );

  resetPassword(
    request: galaxycompletepb_apipb_auth_api_pb.ResetPassword.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.ResetPassword.Response>;

  resetPassword(
    request: galaxycompletepb_apipb_auth_api_pb.ResetPassword.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.ResetPassword.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.ResetPassword.Response>;

  resetPassword(
    request: galaxycompletepb_apipb_auth_api_pb.ResetPassword.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.ResetPassword.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.LoginService/ResetPassword',
        request,
        metadata || {},
        this.methodDescriptorResetPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.LoginService/ResetPassword',
    request,
    metadata || {},
    this.methodDescriptorResetPassword);
  }

  methodDescriptorRefreshToken = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.LoginService/RefreshToken',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.RefreshToken.Request,
    galaxycompletepb_apipb_auth_api_pb.RefreshToken.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.RefreshToken.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.RefreshToken.Response.deserializeBinary
  );

  refreshToken(
    request: galaxycompletepb_apipb_auth_api_pb.RefreshToken.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.RefreshToken.Response>;

  refreshToken(
    request: galaxycompletepb_apipb_auth_api_pb.RefreshToken.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.RefreshToken.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.RefreshToken.Response>;

  refreshToken(
    request: galaxycompletepb_apipb_auth_api_pb.RefreshToken.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.RefreshToken.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.LoginService/RefreshToken',
        request,
        metadata || {},
        this.methodDescriptorRefreshToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.LoginService/RefreshToken',
    request,
    metadata || {},
    this.methodDescriptorRefreshToken);
  }

  methodDescriptorSignup = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.LoginService/Signup',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.Signup.Request,
    galaxycompletepb_apipb_auth_api_pb.Signup.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.Signup.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.Signup.Response.deserializeBinary
  );

  signup(
    request: galaxycompletepb_apipb_auth_api_pb.Signup.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.Signup.Response>;

  signup(
    request: galaxycompletepb_apipb_auth_api_pb.Signup.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.Signup.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.Signup.Response>;

  signup(
    request: galaxycompletepb_apipb_auth_api_pb.Signup.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.Signup.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.LoginService/Signup',
        request,
        metadata || {},
        this.methodDescriptorSignup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.LoginService/Signup',
    request,
    metadata || {},
    this.methodDescriptorSignup);
  }

  methodDescriptorResolveAzureMarketplaceSubscription = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.LoginService/ResolveAzureMarketplaceSubscription',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.ResolveAzureMarketplaceSubscription.Request,
    galaxycompletepb_apipb_auth_api_pb.ResolveAzureMarketplaceSubscription.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.ResolveAzureMarketplaceSubscription.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.ResolveAzureMarketplaceSubscription.Response.deserializeBinary
  );

  resolveAzureMarketplaceSubscription(
    request: galaxycompletepb_apipb_auth_api_pb.ResolveAzureMarketplaceSubscription.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.ResolveAzureMarketplaceSubscription.Response>;

  resolveAzureMarketplaceSubscription(
    request: galaxycompletepb_apipb_auth_api_pb.ResolveAzureMarketplaceSubscription.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.ResolveAzureMarketplaceSubscription.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.ResolveAzureMarketplaceSubscription.Response>;

  resolveAzureMarketplaceSubscription(
    request: galaxycompletepb_apipb_auth_api_pb.ResolveAzureMarketplaceSubscription.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.ResolveAzureMarketplaceSubscription.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.LoginService/ResolveAzureMarketplaceSubscription',
        request,
        metadata || {},
        this.methodDescriptorResolveAzureMarketplaceSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.LoginService/ResolveAzureMarketplaceSubscription',
    request,
    metadata || {},
    this.methodDescriptorResolveAzureMarketplaceSubscription);
  }

}

export class AuthenticationServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCompleteOnboard = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/CompleteOnboard',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.CompleteOnboard.Request,
    galaxycompletepb_apipb_auth_api_pb.CompleteOnboard.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.CompleteOnboard.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.CompleteOnboard.Response.deserializeBinary
  );

  completeOnboard(
    request: galaxycompletepb_apipb_auth_api_pb.CompleteOnboard.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.CompleteOnboard.Response>;

  completeOnboard(
    request: galaxycompletepb_apipb_auth_api_pb.CompleteOnboard.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.CompleteOnboard.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.CompleteOnboard.Response>;

  completeOnboard(
    request: galaxycompletepb_apipb_auth_api_pb.CompleteOnboard.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.CompleteOnboard.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/CompleteOnboard',
        request,
        metadata || {},
        this.methodDescriptorCompleteOnboard,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/CompleteOnboard',
    request,
    metadata || {},
    this.methodDescriptorCompleteOnboard);
  }

  methodDescriptorUpdateMyUserInfo = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/UpdateMyUserInfo',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.UpdateMyUserInfo.Request,
    galaxycompletepb_apipb_auth_api_pb.UpdateMyUserInfo.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.UpdateMyUserInfo.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.UpdateMyUserInfo.Response.deserializeBinary
  );

  updateMyUserInfo(
    request: galaxycompletepb_apipb_auth_api_pb.UpdateMyUserInfo.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.UpdateMyUserInfo.Response>;

  updateMyUserInfo(
    request: galaxycompletepb_apipb_auth_api_pb.UpdateMyUserInfo.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.UpdateMyUserInfo.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.UpdateMyUserInfo.Response>;

  updateMyUserInfo(
    request: galaxycompletepb_apipb_auth_api_pb.UpdateMyUserInfo.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.UpdateMyUserInfo.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/UpdateMyUserInfo',
        request,
        metadata || {},
        this.methodDescriptorUpdateMyUserInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/UpdateMyUserInfo',
    request,
    metadata || {},
    this.methodDescriptorUpdateMyUserInfo);
  }

  methodDescriptorGetMyUserInfo = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/GetMyUserInfo',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.GetMyUserInfo.Request,
    galaxycompletepb_apipb_auth_api_pb.GetMyUserInfo.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.GetMyUserInfo.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.GetMyUserInfo.Response.deserializeBinary
  );

  getMyUserInfo(
    request: galaxycompletepb_apipb_auth_api_pb.GetMyUserInfo.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.GetMyUserInfo.Response>;

  getMyUserInfo(
    request: galaxycompletepb_apipb_auth_api_pb.GetMyUserInfo.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.GetMyUserInfo.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.GetMyUserInfo.Response>;

  getMyUserInfo(
    request: galaxycompletepb_apipb_auth_api_pb.GetMyUserInfo.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.GetMyUserInfo.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/GetMyUserInfo',
        request,
        metadata || {},
        this.methodDescriptorGetMyUserInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/GetMyUserInfo',
    request,
    metadata || {},
    this.methodDescriptorGetMyUserInfo);
  }

  methodDescriptorRequestMyAvatarUploadUrl = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/RequestMyAvatarUploadUrl',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.RequestMyAvatarUploadUrl.Request,
    galaxycompletepb_apipb_auth_api_pb.RequestMyAvatarUploadUrl.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.RequestMyAvatarUploadUrl.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.RequestMyAvatarUploadUrl.Response.deserializeBinary
  );

  requestMyAvatarUploadUrl(
    request: galaxycompletepb_apipb_auth_api_pb.RequestMyAvatarUploadUrl.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.RequestMyAvatarUploadUrl.Response>;

  requestMyAvatarUploadUrl(
    request: galaxycompletepb_apipb_auth_api_pb.RequestMyAvatarUploadUrl.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.RequestMyAvatarUploadUrl.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.RequestMyAvatarUploadUrl.Response>;

  requestMyAvatarUploadUrl(
    request: galaxycompletepb_apipb_auth_api_pb.RequestMyAvatarUploadUrl.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.RequestMyAvatarUploadUrl.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/RequestMyAvatarUploadUrl',
        request,
        metadata || {},
        this.methodDescriptorRequestMyAvatarUploadUrl,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/RequestMyAvatarUploadUrl',
    request,
    metadata || {},
    this.methodDescriptorRequestMyAvatarUploadUrl);
  }

  methodDescriptorReplaceMyAvatar = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/ReplaceMyAvatar',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.ReplaceMyAvatar.Request,
    galaxycompletepb_apipb_auth_api_pb.ReplaceMyAvatar.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.ReplaceMyAvatar.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.ReplaceMyAvatar.Response.deserializeBinary
  );

  replaceMyAvatar(
    request: galaxycompletepb_apipb_auth_api_pb.ReplaceMyAvatar.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.ReplaceMyAvatar.Response>;

  replaceMyAvatar(
    request: galaxycompletepb_apipb_auth_api_pb.ReplaceMyAvatar.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.ReplaceMyAvatar.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.ReplaceMyAvatar.Response>;

  replaceMyAvatar(
    request: galaxycompletepb_apipb_auth_api_pb.ReplaceMyAvatar.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.ReplaceMyAvatar.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/ReplaceMyAvatar',
        request,
        metadata || {},
        this.methodDescriptorReplaceMyAvatar,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/ReplaceMyAvatar',
    request,
    metadata || {},
    this.methodDescriptorReplaceMyAvatar);
  }

  methodDescriptorGenerateAPIToken = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/GenerateAPIToken',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.GenerateAPIToken.Request,
    galaxycompletepb_apipb_auth_api_pb.GenerateAPIToken.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.GenerateAPIToken.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.GenerateAPIToken.Response.deserializeBinary
  );

  generateAPIToken(
    request: galaxycompletepb_apipb_auth_api_pb.GenerateAPIToken.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.GenerateAPIToken.Response>;

  generateAPIToken(
    request: galaxycompletepb_apipb_auth_api_pb.GenerateAPIToken.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.GenerateAPIToken.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.GenerateAPIToken.Response>;

  generateAPIToken(
    request: galaxycompletepb_apipb_auth_api_pb.GenerateAPIToken.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.GenerateAPIToken.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/GenerateAPIToken',
        request,
        metadata || {},
        this.methodDescriptorGenerateAPIToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/GenerateAPIToken',
    request,
    metadata || {},
    this.methodDescriptorGenerateAPIToken);
  }

  methodDescriptorRevokeAPIToken = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/RevokeAPIToken',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.RevokeAPIToken.Request,
    galaxycompletepb_apipb_auth_api_pb.RevokeAPIToken.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.RevokeAPIToken.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.RevokeAPIToken.Response.deserializeBinary
  );

  revokeAPIToken(
    request: galaxycompletepb_apipb_auth_api_pb.RevokeAPIToken.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.RevokeAPIToken.Response>;

  revokeAPIToken(
    request: galaxycompletepb_apipb_auth_api_pb.RevokeAPIToken.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.RevokeAPIToken.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.RevokeAPIToken.Response>;

  revokeAPIToken(
    request: galaxycompletepb_apipb_auth_api_pb.RevokeAPIToken.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.RevokeAPIToken.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/RevokeAPIToken',
        request,
        metadata || {},
        this.methodDescriptorRevokeAPIToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/RevokeAPIToken',
    request,
    metadata || {},
    this.methodDescriptorRevokeAPIToken);
  }

  methodDescriptorChangeMyPassword = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/ChangeMyPassword',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.ChangeMyPassword.Request,
    galaxycompletepb_apipb_auth_api_pb.ChangeMyPassword.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.ChangeMyPassword.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.ChangeMyPassword.Response.deserializeBinary
  );

  changeMyPassword(
    request: galaxycompletepb_apipb_auth_api_pb.ChangeMyPassword.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.ChangeMyPassword.Response>;

  changeMyPassword(
    request: galaxycompletepb_apipb_auth_api_pb.ChangeMyPassword.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.ChangeMyPassword.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.ChangeMyPassword.Response>;

  changeMyPassword(
    request: galaxycompletepb_apipb_auth_api_pb.ChangeMyPassword.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.ChangeMyPassword.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/ChangeMyPassword',
        request,
        metadata || {},
        this.methodDescriptorChangeMyPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/ChangeMyPassword',
    request,
    metadata || {},
    this.methodDescriptorChangeMyPassword);
  }

  methodDescriptorGenerateMfaTotpSecret = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/GenerateMfaTotpSecret',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.GenerateMfaTotpSecret.Request,
    galaxycompletepb_apipb_auth_api_pb.GenerateMfaTotpSecret.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.GenerateMfaTotpSecret.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.GenerateMfaTotpSecret.Response.deserializeBinary
  );

  generateMfaTotpSecret(
    request: galaxycompletepb_apipb_auth_api_pb.GenerateMfaTotpSecret.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.GenerateMfaTotpSecret.Response>;

  generateMfaTotpSecret(
    request: galaxycompletepb_apipb_auth_api_pb.GenerateMfaTotpSecret.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.GenerateMfaTotpSecret.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.GenerateMfaTotpSecret.Response>;

  generateMfaTotpSecret(
    request: galaxycompletepb_apipb_auth_api_pb.GenerateMfaTotpSecret.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.GenerateMfaTotpSecret.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/GenerateMfaTotpSecret',
        request,
        metadata || {},
        this.methodDescriptorGenerateMfaTotpSecret,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/GenerateMfaTotpSecret',
    request,
    metadata || {},
    this.methodDescriptorGenerateMfaTotpSecret);
  }

  methodDescriptorEnableMfaTotp = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/EnableMfaTotp',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.EnableMfaTotp.Request,
    galaxycompletepb_apipb_auth_api_pb.EnableMfaTotp.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.EnableMfaTotp.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.EnableMfaTotp.Response.deserializeBinary
  );

  enableMfaTotp(
    request: galaxycompletepb_apipb_auth_api_pb.EnableMfaTotp.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.EnableMfaTotp.Response>;

  enableMfaTotp(
    request: galaxycompletepb_apipb_auth_api_pb.EnableMfaTotp.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.EnableMfaTotp.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.EnableMfaTotp.Response>;

  enableMfaTotp(
    request: galaxycompletepb_apipb_auth_api_pb.EnableMfaTotp.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.EnableMfaTotp.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/EnableMfaTotp',
        request,
        metadata || {},
        this.methodDescriptorEnableMfaTotp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/EnableMfaTotp',
    request,
    metadata || {},
    this.methodDescriptorEnableMfaTotp);
  }

  methodDescriptorDisableMfaTotp = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/DisableMfaTotp',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.DisableMfaTotp.Request,
    galaxycompletepb_apipb_auth_api_pb.DisableMfaTotp.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.DisableMfaTotp.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.DisableMfaTotp.Response.deserializeBinary
  );

  disableMfaTotp(
    request: galaxycompletepb_apipb_auth_api_pb.DisableMfaTotp.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.DisableMfaTotp.Response>;

  disableMfaTotp(
    request: galaxycompletepb_apipb_auth_api_pb.DisableMfaTotp.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.DisableMfaTotp.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.DisableMfaTotp.Response>;

  disableMfaTotp(
    request: galaxycompletepb_apipb_auth_api_pb.DisableMfaTotp.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.DisableMfaTotp.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/DisableMfaTotp',
        request,
        metadata || {},
        this.methodDescriptorDisableMfaTotp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/DisableMfaTotp',
    request,
    metadata || {},
    this.methodDescriptorDisableMfaTotp);
  }

  methodDescriptorGenerateRecoveryCodes = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/GenerateRecoveryCodes',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.GenerateRecoveryCodes.Request,
    galaxycompletepb_apipb_auth_api_pb.GenerateRecoveryCodes.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.GenerateRecoveryCodes.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.GenerateRecoveryCodes.Response.deserializeBinary
  );

  generateRecoveryCodes(
    request: galaxycompletepb_apipb_auth_api_pb.GenerateRecoveryCodes.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.GenerateRecoveryCodes.Response>;

  generateRecoveryCodes(
    request: galaxycompletepb_apipb_auth_api_pb.GenerateRecoveryCodes.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.GenerateRecoveryCodes.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.GenerateRecoveryCodes.Response>;

  generateRecoveryCodes(
    request: galaxycompletepb_apipb_auth_api_pb.GenerateRecoveryCodes.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.GenerateRecoveryCodes.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/GenerateRecoveryCodes',
        request,
        metadata || {},
        this.methodDescriptorGenerateRecoveryCodes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/GenerateRecoveryCodes',
    request,
    metadata || {},
    this.methodDescriptorGenerateRecoveryCodes);
  }

  methodDescriptorListMyUserSessions = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/ListMyUserSessions',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.ListMyUserSessions.Request,
    galaxycompletepb_apipb_auth_api_pb.ListMyUserSessions.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.ListMyUserSessions.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.ListMyUserSessions.Response.deserializeBinary
  );

  listMyUserSessions(
    request: galaxycompletepb_apipb_auth_api_pb.ListMyUserSessions.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.ListMyUserSessions.Response>;

  listMyUserSessions(
    request: galaxycompletepb_apipb_auth_api_pb.ListMyUserSessions.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.ListMyUserSessions.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.ListMyUserSessions.Response>;

  listMyUserSessions(
    request: galaxycompletepb_apipb_auth_api_pb.ListMyUserSessions.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.ListMyUserSessions.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/ListMyUserSessions',
        request,
        metadata || {},
        this.methodDescriptorListMyUserSessions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/ListMyUserSessions',
    request,
    metadata || {},
    this.methodDescriptorListMyUserSessions);
  }

  methodDescriptorRevokeMyUserSession = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/RevokeMyUserSession',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.RevokeMyUserSession.Request,
    galaxycompletepb_apipb_auth_api_pb.RevokeMyUserSession.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.RevokeMyUserSession.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.RevokeMyUserSession.Response.deserializeBinary
  );

  revokeMyUserSession(
    request: galaxycompletepb_apipb_auth_api_pb.RevokeMyUserSession.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.RevokeMyUserSession.Response>;

  revokeMyUserSession(
    request: galaxycompletepb_apipb_auth_api_pb.RevokeMyUserSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.RevokeMyUserSession.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.RevokeMyUserSession.Response>;

  revokeMyUserSession(
    request: galaxycompletepb_apipb_auth_api_pb.RevokeMyUserSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.RevokeMyUserSession.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/RevokeMyUserSession',
        request,
        metadata || {},
        this.methodDescriptorRevokeMyUserSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/RevokeMyUserSession',
    request,
    metadata || {},
    this.methodDescriptorRevokeMyUserSession);
  }

  methodDescriptorGetUserLicenseVaultDetails = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/GetUserLicenseVaultDetails',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.GetUserLicenseVaultDetails.Request,
    galaxycompletepb_apipb_auth_api_pb.GetUserLicenseVaultDetails.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.GetUserLicenseVaultDetails.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.GetUserLicenseVaultDetails.Response.deserializeBinary
  );

  getUserLicenseVaultDetails(
    request: galaxycompletepb_apipb_auth_api_pb.GetUserLicenseVaultDetails.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.GetUserLicenseVaultDetails.Response>;

  getUserLicenseVaultDetails(
    request: galaxycompletepb_apipb_auth_api_pb.GetUserLicenseVaultDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.GetUserLicenseVaultDetails.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.GetUserLicenseVaultDetails.Response>;

  getUserLicenseVaultDetails(
    request: galaxycompletepb_apipb_auth_api_pb.GetUserLicenseVaultDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.GetUserLicenseVaultDetails.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/GetUserLicenseVaultDetails',
        request,
        metadata || {},
        this.methodDescriptorGetUserLicenseVaultDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/GetUserLicenseVaultDetails',
    request,
    metadata || {},
    this.methodDescriptorGetUserLicenseVaultDetails);
  }

  methodDescriptorListUserLicenseTransactions = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/ListUserLicenseTransactions',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.ListUserLicenseTransactions.Request,
    galaxycompletepb_apipb_auth_api_pb.ListUserLicenseTransactions.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.ListUserLicenseTransactions.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.ListUserLicenseTransactions.Response.deserializeBinary
  );

  listUserLicenseTransactions(
    request: galaxycompletepb_apipb_auth_api_pb.ListUserLicenseTransactions.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.ListUserLicenseTransactions.Response>;

  listUserLicenseTransactions(
    request: galaxycompletepb_apipb_auth_api_pb.ListUserLicenseTransactions.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.ListUserLicenseTransactions.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.ListUserLicenseTransactions.Response>;

  listUserLicenseTransactions(
    request: galaxycompletepb_apipb_auth_api_pb.ListUserLicenseTransactions.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.ListUserLicenseTransactions.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/ListUserLicenseTransactions',
        request,
        metadata || {},
        this.methodDescriptorListUserLicenseTransactions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/ListUserLicenseTransactions',
    request,
    metadata || {},
    this.methodDescriptorListUserLicenseTransactions);
  }

  methodDescriptorRedeemCode = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.AuthenticationService/RedeemCode',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_auth_api_pb.RedeemCode.Request,
    galaxycompletepb_apipb_auth_api_pb.RedeemCode.Response,
    (request: galaxycompletepb_apipb_auth_api_pb.RedeemCode.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_auth_api_pb.RedeemCode.Response.deserializeBinary
  );

  redeemCode(
    request: galaxycompletepb_apipb_auth_api_pb.RedeemCode.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_auth_api_pb.RedeemCode.Response>;

  redeemCode(
    request: galaxycompletepb_apipb_auth_api_pb.RedeemCode.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.RedeemCode.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_auth_api_pb.RedeemCode.Response>;

  redeemCode(
    request: galaxycompletepb_apipb_auth_api_pb.RedeemCode.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_auth_api_pb.RedeemCode.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.AuthenticationService/RedeemCode',
        request,
        metadata || {},
        this.methodDescriptorRedeemCode,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.AuthenticationService/RedeemCode',
    request,
    metadata || {},
    this.methodDescriptorRedeemCode);
  }

}

