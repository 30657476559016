import * as React from "react";
import { IconButton, IconButtonProps, Link, LinkProps, SvgIcon, SvgIconProps, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { HiOutlineLightBulb } from "react-icons/hi";
import { observer } from "mobx-react-lite";
import { useAppServices } from "../app/services";
import { MdOutlineInfo } from "react-icons/md";
import { Theme, styled } from "@mui/material/styles";
import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { useCallback } from "react";
import { useOpenHelpArticle, useOpenHelpSearch } from "./hooks/help_hooks";

export const HELP_CENTER_URL = `${process.env.REACT_APP_CUSTOMER_CENTER_URL}/cdc`;
export const TERMS_OF_SERVICE_URL = "https://support.cirrusdata.cloud/en/article/terms-and-conditions-1ldq1ai/";
export const PRIVACY_POLICY_URL = "https://support.cirrusdata.cloud/en/article/privacy-policy-rs3858/";
export const EULA_URL = "https://support.cirrusdata.cloud/en/article/end-user-license-agreement-x6oe79/";
export const API_DOCS_ROUTE = "/apidocs";

export enum AppHintID {
    DEPLOY_CIRRUS_MIGRATE = "deploy cirrus migrate",
    CREATE_H2H_LINK_FORM = "h2h",
    LICENSE_KEY = "license key",
    CMC_RELAY = "cdc relay",
}

export enum KnownArticle {
    MIGRATING_BOOT_VOLUMES = "nYNrq8d0MO",
    CMC_HELPER_VMWARE = "kAH0QPZVtq",
    TRANSFER_LICENSE_CREDITS = "b2c7hcIbQR",
    PROJECT_MEMBER_ROLES = "07bfahosUn",
    MIGRATION_SESSION_IN_ERROR = "n5Vb9Vvnte",
    REDEEM_LICENSE = "jEZzAVwoR3",
}

export const navigateToHelpCenter = (articleSlug?: string, type?: "search" | "article") => {
    window.open(`${HELP_CENTER_URL}/kb/${!!articleSlug ? (type === "article" ? `articles/${articleSlug}` : `search?q=${articleSlug}`) : ""}`, "_blank");
};

export const getExternalLinkConfirmDialog = (link: string) => {
    return {
        title: `🔗   External Content`,
        message: (
            <>
                <Typography>You are about to view external content.</Typography>
                <Typography>If this browser does not have access to the internet, visit the following url from an internet-enabled browser:</Typography>
                <br />
                {link}
            </>
        ),
        autoConfirmationQuestionLine: false,
        okButtonLabel: "Open in Browser",
        cancelButtonLabel: "Close",
    };
};

export const useNavigateToExternalLink = (link: string) => {
    const { dialogService } = useAppServices();
    const isPrivateEdition = getIsPrivateEdition();

    const navFunc = () => window.open(link, "_blank");

    if (isPrivateEdition) {
        return async () => {
            const confirmed = await dialogService.addConfirmDialog(getExternalLinkConfirmDialog(link));
            if (confirmed) {
                navFunc();
            }
        };
    } else {
        return navFunc;
    }
};

export const useNavigateToHelpCenterKb = () => {
    return useNavigateToExternalLink(`${HELP_CENTER_URL}/kb`);
};

interface NavLinkProps {
    label?: string;
}

export const EULALink: React.FC<NavLinkProps & Partial<LinkProps>> = (props) => {
    const nav = useNavigateToExternalLink(EULA_URL);
    return (
        <Link onClick={nav} {...props}>
            EULA
        </Link>
    );
};

export const TermsOfServiceLink: React.FC<NavLinkProps & Partial<LinkProps>> = (props) => {
    const nav = useNavigateToExternalLink(TERMS_OF_SERVICE_URL);

    return (
        <Link onClick={nav} {...props}>
            {props.label || `Terms of Service`}
        </Link>
    );
};

export const PrivacyPolicyLink: React.FC<NavLinkProps & Partial<LinkProps>> = (props) => {
    const nav = useNavigateToExternalLink(PRIVACY_POLICY_URL);

    return (
        <Link onClick={nav} {...props}>
            Privacy Policy
        </Link>
    );
};

export const HintIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <HiOutlineLightBulb />
        </SvgIcon>
    );
};

export const ApiLink: React.FC<NavLinkProps & Partial<LinkProps>> = (props) => {
    const nav = useNavigateToExternalLink(API_DOCS_ROUTE);

    return (
        <Link onClick={nav} {...props}>
            {props.label || `API`}
        </Link>
    );
};

export const HelpCenterLink: React.FC<NavLinkProps & Partial<LinkProps>> = (props) => {
    const nav = useNavigateToExternalLink(HELP_CENTER_URL);

    return (
        <Link onClick={nav} {...props}>
            {props.label || `Help Center`}
        </Link>
    );
};

interface HintButtonProps {
    hintID?: AppHintID;
    articleID?: KnownArticle;
    buttonProps?: Partial<IconButtonProps>;
    icon?: React.ReactNode;
    tooltipTitle?: string;
}

export const HintButton: React.FC<HintButtonProps> = observer((p) => {
    const openHelpArticle = useOpenHelpArticle();
    const openHelpSearch = useOpenHelpSearch();

    const handleClick = useCallback(async () => {
        if (!!p.hintID) {
            await openHelpSearch(p.hintID);
        } else if (!!p.articleID) {
            await openHelpArticle(p.articleID);
        }
    }, [p.hintID, p.articleID, openHelpArticle, openHelpSearch]);
    return (
        <Tooltip title={p.tooltipTitle ?? "Hint"}>
            <IconButton {...p.buttonProps} color={"default"} onClick={handleClick}>
                {p.icon ?? <HintIcon />}
            </IconButton>
        </Tooltip>
    );
});

// ======================
// QuickTipButton
// ======================

interface QuickTipButtonProps {
    disabled?: boolean;
}

export const QuickTipButton: React.FC<QuickTipButtonProps & Partial<TooltipProps>> = observer((p) => {
    const { disabled, ...props } = p;
    if (disabled) {
        return (
            <SvgIcon sx={{ color: (t: Theme) => t.palette.primary.main, ...p.sx }}>
                <MdOutlineInfo />
            </SvgIcon>
        );
    }
    return (
        <BlueTooltip title={p.title} arrow {...props}>
            <SvgIcon sx={{ color: (t: Theme) => t.palette.primary.main }}>
                <MdOutlineInfo />
            </SvgIcon>
        </BlueTooltip>
    );
});

const BlueTooltip: React.FC<TooltipProps> = styled(<TooltipProps,>({ ...props }) => (
    <Tooltip classes={{ popper: props.className }} children={props.children} title={props.title} {...props} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        boxShadow: theme.shadows[1],
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
}));
