import * as React from "react";
import {VendorAllocateVolumesStepProps} from "../GmAutoAllocationCommon";
import {observer} from "mobx-react-lite";
import {useEffectOnce} from "react-use";
import {AutoAlloc} from "../../../../_proto/galaxymigratepb/galaxy_migrate_autoalloc_pb";
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import * as yup from "yup";
import {FieldArray, Form, Formik, FormikValues} from "formik";
import {formatKnownDataType, KnownDataType} from "../../../../common/utils/formatter";
import {FormSelect, FormTextField} from "../../../../common/form/FormComponents";

export const GcpAllocateVolumesStep: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {
        const state = p.state;
        const allocateNow = p.allocateFunc;

        useEffectOnce(() => {
                for (let device of state.sourceDevices) {
                    device.autoAllocParams.setGcp(new AutoAlloc.VolumeParams.GCP()
                        .setDiskType(AutoAlloc.VolumeParams.GCP.DiskType.PD_STANDARD)
                    )
                }

            }
        )


        return <>
            <Typography color={'textSecondary'}>
                {`GCP Data Disks matching the following source volumes will be created using the specified parameters and attached to the destination host`}
            </Typography>
            <br/>
            <GcpVolumeTableForm state={state} allocateFunc={allocateNow}/>
        </>


    })
;

export const GcpVolumeTableForm: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {

    const getTableCellStyle = () => ({
        verticalAlign: 'top',
        paddingTop: 2
    })

    const getInitialValues = () => {
        const vols = p.state.sourceDevices.map(d => {
            return {
                volumeType: AutoAlloc.VolumeParams.GCP.DiskType.PD_STANDARD,
                iops: 'Not Available'
            }
        })
        return {
            vols: vols
        }
    };

    const validationSchema = yup.object({
        vols: yup.array(yup.object({
            volumeType: yup.mixed(),
            iops: yup.mixed()
                .when(
                    ['volumeType'], {
                        is: AutoAlloc.VolumeParams.GCP.DiskType.PD_EXTREME,
                        then: yup.number().typeError('Enter a valid number').max(120000, 'IOPS must be less than or equal to 120,000').min(10000, 'IOPS must be greater than or equal to 10,000')
                    }
                )
        }))
    });


    return <Box pt={2}>
        <Formik initialValues={getInitialValues()} validationSchema={validationSchema}
                onSubmit={async (values: FormikValues) => {
                    p.state.sourceDevices.forEach((d, i) => {
                        d.autoAllocParams.getGcp()
                            .setDiskType(values.vols[i].volumeType)

                        const iops = parseInt(values.vols[i].iops);
                        if (!isNaN(iops)) {
                            d.autoAllocParams.getGcp().setIops(iops)
                        }
                    });
                    await p.allocateFunc()
                }
                }>
            {props => {
                const getDefaultIopsValue = (i: number) => {
                    if (props.values.vols[i].volumeType === AutoAlloc.VolumeParams.GCP.DiskType.PD_EXTREME) {
                        return 10000;
                    } else {
                        return 'Not Available';
                    }
                };

                const getIopsDisabled = (i: number) => {
                    return props.values.vols[i].volumeType !== AutoAlloc.VolumeParams.GCP.DiskType.PD_EXTREME
                };

                return <Form>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Source Volume
                                    </TableCell>
                                    <TableCell>
                                        Capacity
                                    </TableCell>
                                    <TableCell>
                                        New Disk Class
                                    </TableCell>
                                    <TableCell>
                                        IOPS
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FieldArray name={'vols'} render={helpers => {
                                    return p.state.sourceDevices.map((d, i) => {
                                        return <TableRow>
                                            <TableCell>
                                                {d.source.getBlockDevice().getDeviceName()}
                                            </TableCell>
                                            <TableCell>
                                                {formatKnownDataType(d.source.getBlockDevice().getCapacity(), KnownDataType.CAPACITY)}
                                            </TableCell>
                                            <TableCell sx={getTableCellStyle()}>

                                                <FormSelect label={'Disk Class'} name={`vols[${i}].volumeType`}
                                                            onClose={() => props.setFieldTouched(`vols[${i}].iops`, false)}
                                                            selectionList={volumeTypesSelection}
                                                            defaultValue={volumeTypesSelection[0]}/>

                                            </TableCell>
                                            <TableCell sx={getTableCellStyle()}>
                                                <FormTextField label={'IOPS'} name={`vols[${i}].iops`}
                                                               defaultValue={getDefaultIopsValue(i)}
                                                               overrideInitialValue
                                                               disabled={getIopsDisabled(i)}
                                                               type={getIopsDisabled(i) ? 'string' : 'number'}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    })
                                }}/>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box pt={2} pb={2}>
                        <Button color={'primary'} variant={'contained'}
                                type={'submit'}
                        >
                            {`Allocate Volumes (${p.state.sourceDevices.length})`}
                        </Button>
                    </Box>
                </Form>
            }
            }
        </Formik>
    </Box>
})

const getVolumeTypeLabel = (type: AutoAlloc.VolumeParams.GCP.DiskType) => {
    if (type === AutoAlloc.VolumeParams.GCP.DiskType.PD_STANDARD) {
        return 'Standard Persistent Disk';
    }
    else if (type === AutoAlloc.VolumeParams.GCP.DiskType.PD_BALANCED) {
        return 'Balanced Persistent Disk';
    }
    else if (type === AutoAlloc.VolumeParams.GCP.DiskType.PD_SSD) {
        return 'SSD Persistent Disk';
    }
    else if (type === AutoAlloc.VolumeParams.GCP.DiskType.PD_EXTREME) {
        return 'Extreme Persistent Disk';
    }
}

const volumeTypesSelection = [
    {
        label: getVolumeTypeLabel(AutoAlloc.VolumeParams.GCP.DiskType.PD_STANDARD),
        value: AutoAlloc.VolumeParams.GCP.DiskType.PD_STANDARD
    },
    {
        label: getVolumeTypeLabel(AutoAlloc.VolumeParams.GCP.DiskType.PD_BALANCED),
        value: AutoAlloc.VolumeParams.GCP.DiskType.PD_BALANCED
    },
    {
        label: getVolumeTypeLabel(AutoAlloc.VolumeParams.GCP.DiskType.PD_SSD),
        value: AutoAlloc.VolumeParams.GCP.DiskType.PD_SSD
    },

    {
        label: getVolumeTypeLabel(AutoAlloc.VolumeParams.GCP.DiskType.PD_EXTREME),
        value: AutoAlloc.VolumeParams.GCP.DiskType.PD_EXTREME
    },
]