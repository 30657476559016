import * as React from "react";
import { observer } from "mobx-react-lite";
import { GmMigrationAutoAllocationState } from "../../GmMigrationService";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffectOnce } from "react-use";
import { AutoAlloc } from "../../../../_proto/galaxymigratepb/galaxy_migrate_autoalloc_pb";
import { formatKnownDataType, KnownDataType } from "../../../../common/utils/formatter";
import * as yup from "yup";
import { FieldArray, Form, Formik, FormikValues } from "formik";
import { FormSelect, FormTextField } from "../../../../common/form/FormComponents";

// ======================
// CirrusDataCloudAllocateVolumesStep
// ======================
interface VendorAllocateVolumesStepProps {
    allocateFunc: () => Promise<void>;
    state: GmMigrationAutoAllocationState;
}

export const PowerMaxIntegration: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {
    const state = p.state;
    const allocateNow = p.allocateFunc;

    useEffectOnce(() => {
        for (let device of state.sourceDevices) {
            device.autoAllocParams.setPowermax(new AutoAlloc.VolumeParams.EMCPowerMax());
        }
    });

    return (
        <>
            <Typography color={"textSecondary"}>
                {`Destination Volumes matching the following source volumes will be created using the specified parameters and attached to the destination host`}
            </Typography>
            <br />
            <PowerMaxVolumesTable allocateFunc={allocateNow} state={state} />
        </>
    );
});

// ======================
// PowerMaxVolumesTable
// ======================

interface PowerMaxVolumesTableProps {
    allocateFunc: () => Promise<void>;
    state: GmMigrationAutoAllocationState;
}

export const PowerMaxVolumesTable: React.FC<PowerMaxVolumesTableProps> = observer((p) => {
    const getTableCellStyle = () => ({
        verticalAlign: "top",
        paddingTop: 2,
    });

    const getInitialValues = () => {
        const vols = p.state.sourceDevices.map((d) => {
            return {
                name: d.source.getBlockDevice().getDeviceName(),
                serviceLevel: AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.DIAMOND,
                storagePool: "",
                capacity: (d.source.getBlockDevice().getCapacity() / Math.pow(1024, 3)).toString(),
            };
        });
        return {
            vols: vols,
        };
    };

    const validationSchema = yup.object({
        vols: yup.array(
            yup.object({
                name: yup.string(),
                serviceLevel: yup.mixed(),
                storagePool: yup.string(),
                capacity: yup.string().test({
                    name: "smaller-destination",
                    message: p.state.allowSmallerDestinations ? "Must be greater than 0" : "Must be greater than or equal to source volume size.",
                    test: (value, context) => {
                        if (p.state.allowSmallerDestinations) {
                            return Number(value) > 0;
                        } else {
                            const originalCapacity = p.state.sourceDevices
                                .find((s) => s.source.getBlockDevice().getDeviceName() === context.parent.name)
                                .source.getBlockDevice()
                                .getCapacity();
                            console.debug(context.parent.name);
                            console.debug(originalCapacity);
                            console.debug(value);
                            return Number(value) >= originalCapacity / Math.pow(1024, 3);
                        }
                    },
                }),
            })
        ),
    });

    return (
        <Box pt={2}>
            <Formik
                initialValues={getInitialValues()}
                validationSchema={validationSchema}
                onSubmit={async (values: FormikValues, formikHelpers) => {
                    p.state.sourceDevices.forEach((d, i) => {
                        d.autoAllocVolumeCapacity = values.vols[i].capacity;
                        d.autoAllocParams.getPowermax().setServiceLevel(values.vols[i].serviceLevel);

                        if (!!values.vols[i].storagePool) {
                            d.autoAllocParams.getPowermax().setStoragePool(values.vols[i].storagePool);
                        }
                    });
                    await p.allocateFunc();
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Source Volume</TableCell>
                                            <TableCell>Capacity</TableCell>
                                            <TableCell>Service Level</TableCell>
                                            <TableCell>Storage Pool</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <FieldArray
                                            name={"vols"}
                                            render={(helpers) => {
                                                return p.state.sourceDevices.map((d, i) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell>{d.source.getBlockDevice().getDeviceName()}</TableCell>
                                                            <TableCell>
                                                                <FormTextField
                                                                    label={"Capacity"}
                                                                    name={`vols[${i}].capacity`}
                                                                    overrideInitialValue
                                                                    type={"number"}
                                                                    helperText={"Custom disk size"}
                                                                    InputProps={{ endAdornment: "GiB" }}
                                                                />{" "}
                                                            </TableCell>
                                                            <TableCell sx={getTableCellStyle()}>
                                                                <FormSelect
                                                                    label={"Service Level"}
                                                                    name={`vols[${i}].serviceLevel`}
                                                                    selectionList={serviceLevelSelection}
                                                                    defaultValue={serviceLevelSelection[0]}
                                                                />
                                                            </TableCell>
                                                            <TableCell sx={getTableCellStyle()}>
                                                                <FormTextField
                                                                    label={"Storage Pool Name"}
                                                                    name={`vols[${i}].storagePool`}
                                                                    defaultValue={""}
                                                                    overrideInitialValue
                                                                    helperText={"Leave empty to default to first available"}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                });
                                            }}
                                        />
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Box pt={2} pb={2}>
                                <Button color={"primary"} variant={"contained"} type={"submit"} disabled={Object.keys(props.errors).length > 0}>
                                    {`Allocate Volumes (${p.state.sourceDevices.length})`}
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
});

const getServiceLevelLabel = (type: AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel) => {
    if (type === AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.DIAMOND) {
        return "Diamond";
    } else if (type === AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.PLATINUM) {
        return "Platinum";
    } else if (type === AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.GOLD) {
        return "Gold";
    } else if (type === AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.SILVER) {
        return "Silver";
    } else if (type === AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.BRONZE) {
        return "Bronze";
    } else if (type === AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.OPTIMIZED) {
        return "Optimized";
    } else if (type === AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.NONE) {
        return "None";
    }
};

const serviceLevelSelection = [
    {
        label: getServiceLevelLabel(AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.DIAMOND),
        value: AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.DIAMOND,
    },
    {
        label: getServiceLevelLabel(AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.PLATINUM),
        value: AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.PLATINUM,
    },
    {
        label: getServiceLevelLabel(AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.GOLD),
        value: AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.GOLD,
    },

    {
        label: getServiceLevelLabel(AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.SILVER),
        value: AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.SILVER,
    },
    {
        label: getServiceLevelLabel(AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.BRONZE),
        value: AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.BRONZE,
    },
    {
        label: getServiceLevelLabel(AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.OPTIMIZED),
        value: AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.OPTIMIZED,
    },
    {
        label: getServiceLevelLabel(AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.NONE),
        value: AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.NONE,
    },
];
