/**
 * @fileoverview gRPC-Web generated client stub for galaxycomplete.api.cirrusprotect
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: galaxycompletepb/apipb/cpapipb/cirrusprotect_api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb from '../../../galaxycompletepb/apipb/cpapipb/cirrusprotect_api_pb';


export class CirrusProtectServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorRegisterToPhoenix = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.cirrusprotect.CirrusProtectService/RegisterToPhoenix',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RegisterToPhoenix.Request,
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RegisterToPhoenix.Response,
    (request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RegisterToPhoenix.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RegisterToPhoenix.Response.deserializeBinary
  );

  registerToPhoenix(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RegisterToPhoenix.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RegisterToPhoenix.Response>;

  registerToPhoenix(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RegisterToPhoenix.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RegisterToPhoenix.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RegisterToPhoenix.Response>;

  registerToPhoenix(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RegisterToPhoenix.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RegisterToPhoenix.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.cirrusprotect.CirrusProtectService/RegisterToPhoenix',
        request,
        metadata || {},
        this.methodDescriptorRegisterToPhoenix,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.cirrusprotect.CirrusProtectService/RegisterToPhoenix',
    request,
    metadata || {},
    this.methodDescriptorRegisterToPhoenix);
  }

  methodDescriptorDeregister = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.cirrusprotect.CirrusProtectService/Deregister',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.Deregister.Request,
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.Deregister.Response,
    (request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.Deregister.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.Deregister.Response.deserializeBinary
  );

  deregister(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.Deregister.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.Deregister.Response>;

  deregister(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.Deregister.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.Deregister.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.Deregister.Response>;

  deregister(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.Deregister.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.Deregister.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.cirrusprotect.CirrusProtectService/Deregister',
        request,
        metadata || {},
        this.methodDescriptorDeregister,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.cirrusprotect.CirrusProtectService/Deregister',
    request,
    metadata || {},
    this.methodDescriptorDeregister);
  }

  methodDescriptorListInsertionCandidates = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.cirrusprotect.CirrusProtectService/ListInsertionCandidates',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.ListInsertionCandidates.Request,
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.ListInsertionCandidates.Response,
    (request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.ListInsertionCandidates.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.ListInsertionCandidates.Response.deserializeBinary
  );

  listInsertionCandidates(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.ListInsertionCandidates.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.ListInsertionCandidates.Response>;

  listInsertionCandidates(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.ListInsertionCandidates.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.ListInsertionCandidates.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.ListInsertionCandidates.Response>;

  listInsertionCandidates(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.ListInsertionCandidates.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.ListInsertionCandidates.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.cirrusprotect.CirrusProtectService/ListInsertionCandidates',
        request,
        metadata || {},
        this.methodDescriptorListInsertionCandidates,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.cirrusprotect.CirrusProtectService/ListInsertionCandidates',
    request,
    metadata || {},
    this.methodDescriptorListInsertionCandidates);
  }

  methodDescriptorCreatePolicy = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.cirrusprotect.CirrusProtectService/CreatePolicy',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.CreatePolicy.Request,
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.CreatePolicy.Response,
    (request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.CreatePolicy.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.CreatePolicy.Response.deserializeBinary
  );

  createPolicy(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.CreatePolicy.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.CreatePolicy.Response>;

  createPolicy(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.CreatePolicy.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.CreatePolicy.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.CreatePolicy.Response>;

  createPolicy(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.CreatePolicy.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.CreatePolicy.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.cirrusprotect.CirrusProtectService/CreatePolicy',
        request,
        metadata || {},
        this.methodDescriptorCreatePolicy,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.cirrusprotect.CirrusProtectService/CreatePolicy',
    request,
    metadata || {},
    this.methodDescriptorCreatePolicy);
  }

  methodDescriptorAddDisksToPolicy = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.cirrusprotect.CirrusProtectService/AddDisksToPolicy',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.AddDisksToPolicy.Request,
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.AddDisksToPolicy.Response,
    (request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.AddDisksToPolicy.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.AddDisksToPolicy.Response.deserializeBinary
  );

  addDisksToPolicy(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.AddDisksToPolicy.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.AddDisksToPolicy.Response>;

  addDisksToPolicy(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.AddDisksToPolicy.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.AddDisksToPolicy.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.AddDisksToPolicy.Response>;

  addDisksToPolicy(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.AddDisksToPolicy.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.AddDisksToPolicy.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.cirrusprotect.CirrusProtectService/AddDisksToPolicy',
        request,
        metadata || {},
        this.methodDescriptorAddDisksToPolicy,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.cirrusprotect.CirrusProtectService/AddDisksToPolicy',
    request,
    metadata || {},
    this.methodDescriptorAddDisksToPolicy);
  }

  methodDescriptorRemoveDisksFromPolicy = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.cirrusprotect.CirrusProtectService/RemoveDisksFromPolicy',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RemoveDisksFromPolicy.Request,
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RemoveDisksFromPolicy.Response,
    (request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RemoveDisksFromPolicy.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RemoveDisksFromPolicy.Response.deserializeBinary
  );

  removeDisksFromPolicy(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RemoveDisksFromPolicy.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RemoveDisksFromPolicy.Response>;

  removeDisksFromPolicy(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RemoveDisksFromPolicy.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RemoveDisksFromPolicy.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RemoveDisksFromPolicy.Response>;

  removeDisksFromPolicy(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RemoveDisksFromPolicy.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.RemoveDisksFromPolicy.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.cirrusprotect.CirrusProtectService/RemoveDisksFromPolicy',
        request,
        metadata || {},
        this.methodDescriptorRemoveDisksFromPolicy,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.cirrusprotect.CirrusProtectService/RemoveDisksFromPolicy',
    request,
    metadata || {},
    this.methodDescriptorRemoveDisksFromPolicy);
  }

  methodDescriptorGetPolicyDetails = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.cirrusprotect.CirrusProtectService/GetPolicyDetails',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.GetPolicyDetails.Request,
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.GetPolicyDetails.Response,
    (request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.GetPolicyDetails.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.GetPolicyDetails.Response.deserializeBinary
  );

  getPolicyDetails(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.GetPolicyDetails.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.GetPolicyDetails.Response>;

  getPolicyDetails(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.GetPolicyDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.GetPolicyDetails.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.GetPolicyDetails.Response>;

  getPolicyDetails(
    request: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.GetPolicyDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_cpapipb_cirrusprotect_api_pb.GetPolicyDetails.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.cirrusprotect.CirrusProtectService/GetPolicyDetails',
        request,
        metadata || {},
        this.methodDescriptorGetPolicyDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.cirrusprotect.CirrusProtectService/GetPolicyDetails',
    request,
    metadata || {},
    this.methodDescriptorGetPolicyDetails);
  }

}

