/**
 * @fileoverview gRPC-Web generated client stub for galaxycomplete.api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: galaxycompletepb/apipb/project_api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as galaxycompletepb_apipb_project_api_pb from '../../galaxycompletepb/apipb/project_api_pb';


export class ProjectServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateProject = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/CreateProject',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.CreateProject.Request,
    galaxycompletepb_apipb_project_api_pb.CreateProject.Response,
    (request: galaxycompletepb_apipb_project_api_pb.CreateProject.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.CreateProject.Response.deserializeBinary
  );

  createProject(
    request: galaxycompletepb_apipb_project_api_pb.CreateProject.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.CreateProject.Response>;

  createProject(
    request: galaxycompletepb_apipb_project_api_pb.CreateProject.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.CreateProject.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.CreateProject.Response>;

  createProject(
    request: galaxycompletepb_apipb_project_api_pb.CreateProject.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.CreateProject.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/CreateProject',
        request,
        metadata || {},
        this.methodDescriptorCreateProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/CreateProject',
    request,
    metadata || {},
    this.methodDescriptorCreateProject);
  }

  methodDescriptorRegenerateRegistrationCode = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/RegenerateRegistrationCode',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.RegenerateRegistrationCode.Request,
    galaxycompletepb_apipb_project_api_pb.RegenerateRegistrationCode.Response,
    (request: galaxycompletepb_apipb_project_api_pb.RegenerateRegistrationCode.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.RegenerateRegistrationCode.Response.deserializeBinary
  );

  regenerateRegistrationCode(
    request: galaxycompletepb_apipb_project_api_pb.RegenerateRegistrationCode.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.RegenerateRegistrationCode.Response>;

  regenerateRegistrationCode(
    request: galaxycompletepb_apipb_project_api_pb.RegenerateRegistrationCode.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.RegenerateRegistrationCode.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.RegenerateRegistrationCode.Response>;

  regenerateRegistrationCode(
    request: galaxycompletepb_apipb_project_api_pb.RegenerateRegistrationCode.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.RegenerateRegistrationCode.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/RegenerateRegistrationCode',
        request,
        metadata || {},
        this.methodDescriptorRegenerateRegistrationCode,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/RegenerateRegistrationCode',
    request,
    metadata || {},
    this.methodDescriptorRegenerateRegistrationCode);
  }

  methodDescriptorListProjects = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/ListProjects',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.ListProjects.Request,
    galaxycompletepb_apipb_project_api_pb.ListProjects.Response,
    (request: galaxycompletepb_apipb_project_api_pb.ListProjects.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.ListProjects.Response.deserializeBinary
  );

  listProjects(
    request: galaxycompletepb_apipb_project_api_pb.ListProjects.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.ListProjects.Response>;

  listProjects(
    request: galaxycompletepb_apipb_project_api_pb.ListProjects.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ListProjects.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.ListProjects.Response>;

  listProjects(
    request: galaxycompletepb_apipb_project_api_pb.ListProjects.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ListProjects.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/ListProjects',
        request,
        metadata || {},
        this.methodDescriptorListProjects,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/ListProjects',
    request,
    metadata || {},
    this.methodDescriptorListProjects);
  }

  methodDescriptorGetProjectDetails = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/GetProjectDetails',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.GetProjectDetails.Request,
    galaxycompletepb_apipb_project_api_pb.GetProjectDetails.Response,
    (request: galaxycompletepb_apipb_project_api_pb.GetProjectDetails.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.GetProjectDetails.Response.deserializeBinary
  );

  getProjectDetails(
    request: galaxycompletepb_apipb_project_api_pb.GetProjectDetails.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.GetProjectDetails.Response>;

  getProjectDetails(
    request: galaxycompletepb_apipb_project_api_pb.GetProjectDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.GetProjectDetails.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.GetProjectDetails.Response>;

  getProjectDetails(
    request: galaxycompletepb_apipb_project_api_pb.GetProjectDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.GetProjectDetails.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/GetProjectDetails',
        request,
        metadata || {},
        this.methodDescriptorGetProjectDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/GetProjectDetails',
    request,
    metadata || {},
    this.methodDescriptorGetProjectDetails);
  }

  methodDescriptorDeleteProject = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/DeleteProject',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.DeleteProject.Request,
    galaxycompletepb_apipb_project_api_pb.DeleteProject.Response,
    (request: galaxycompletepb_apipb_project_api_pb.DeleteProject.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.DeleteProject.Response.deserializeBinary
  );

  deleteProject(
    request: galaxycompletepb_apipb_project_api_pb.DeleteProject.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.DeleteProject.Response>;

  deleteProject(
    request: galaxycompletepb_apipb_project_api_pb.DeleteProject.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.DeleteProject.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.DeleteProject.Response>;

  deleteProject(
    request: galaxycompletepb_apipb_project_api_pb.DeleteProject.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.DeleteProject.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/DeleteProject',
        request,
        metadata || {},
        this.methodDescriptorDeleteProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/DeleteProject',
    request,
    metadata || {},
    this.methodDescriptorDeleteProject);
  }

  methodDescriptorUpdateProject = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/UpdateProject',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.UpdateProject.Request,
    galaxycompletepb_apipb_project_api_pb.UpdateProject.Response,
    (request: galaxycompletepb_apipb_project_api_pb.UpdateProject.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.UpdateProject.Response.deserializeBinary
  );

  updateProject(
    request: galaxycompletepb_apipb_project_api_pb.UpdateProject.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.UpdateProject.Response>;

  updateProject(
    request: galaxycompletepb_apipb_project_api_pb.UpdateProject.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.UpdateProject.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.UpdateProject.Response>;

  updateProject(
    request: galaxycompletepb_apipb_project_api_pb.UpdateProject.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.UpdateProject.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/UpdateProject',
        request,
        metadata || {},
        this.methodDescriptorUpdateProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/UpdateProject',
    request,
    metadata || {},
    this.methodDescriptorUpdateProject);
  }

  methodDescriptorListProjectMembers = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/ListProjectMembers',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.ListProjectMembers.Request,
    galaxycompletepb_apipb_project_api_pb.ListProjectMembers.Response,
    (request: galaxycompletepb_apipb_project_api_pb.ListProjectMembers.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.ListProjectMembers.Response.deserializeBinary
  );

  listProjectMembers(
    request: galaxycompletepb_apipb_project_api_pb.ListProjectMembers.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.ListProjectMembers.Response>;

  listProjectMembers(
    request: galaxycompletepb_apipb_project_api_pb.ListProjectMembers.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ListProjectMembers.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.ListProjectMembers.Response>;

  listProjectMembers(
    request: galaxycompletepb_apipb_project_api_pb.ListProjectMembers.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ListProjectMembers.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/ListProjectMembers',
        request,
        metadata || {},
        this.methodDescriptorListProjectMembers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/ListProjectMembers',
    request,
    metadata || {},
    this.methodDescriptorListProjectMembers);
  }

  methodDescriptorGetMyProjectRole = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/GetMyProjectRole',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.GetMyProjectRole.Request,
    galaxycompletepb_apipb_project_api_pb.GetMyProjectRole.Response,
    (request: galaxycompletepb_apipb_project_api_pb.GetMyProjectRole.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.GetMyProjectRole.Response.deserializeBinary
  );

  getMyProjectRole(
    request: galaxycompletepb_apipb_project_api_pb.GetMyProjectRole.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.GetMyProjectRole.Response>;

  getMyProjectRole(
    request: galaxycompletepb_apipb_project_api_pb.GetMyProjectRole.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.GetMyProjectRole.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.GetMyProjectRole.Response>;

  getMyProjectRole(
    request: galaxycompletepb_apipb_project_api_pb.GetMyProjectRole.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.GetMyProjectRole.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/GetMyProjectRole',
        request,
        metadata || {},
        this.methodDescriptorGetMyProjectRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/GetMyProjectRole',
    request,
    metadata || {},
    this.methodDescriptorGetMyProjectRole);
  }

  methodDescriptorInviteNewProjectMembers = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/InviteNewProjectMembers',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.InviteNewProjectMembers.Request,
    galaxycompletepb_apipb_project_api_pb.InviteNewProjectMembers.Response,
    (request: galaxycompletepb_apipb_project_api_pb.InviteNewProjectMembers.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.InviteNewProjectMembers.Response.deserializeBinary
  );

  inviteNewProjectMembers(
    request: galaxycompletepb_apipb_project_api_pb.InviteNewProjectMembers.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.InviteNewProjectMembers.Response>;

  inviteNewProjectMembers(
    request: galaxycompletepb_apipb_project_api_pb.InviteNewProjectMembers.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.InviteNewProjectMembers.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.InviteNewProjectMembers.Response>;

  inviteNewProjectMembers(
    request: galaxycompletepb_apipb_project_api_pb.InviteNewProjectMembers.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.InviteNewProjectMembers.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/InviteNewProjectMembers',
        request,
        metadata || {},
        this.methodDescriptorInviteNewProjectMembers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/InviteNewProjectMembers',
    request,
    metadata || {},
    this.methodDescriptorInviteNewProjectMembers);
  }

  methodDescriptorRemoveProjectMember = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/RemoveProjectMember',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.RemoveProjectMember.Request,
    galaxycompletepb_apipb_project_api_pb.RemoveProjectMember.Response,
    (request: galaxycompletepb_apipb_project_api_pb.RemoveProjectMember.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.RemoveProjectMember.Response.deserializeBinary
  );

  removeProjectMember(
    request: galaxycompletepb_apipb_project_api_pb.RemoveProjectMember.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.RemoveProjectMember.Response>;

  removeProjectMember(
    request: galaxycompletepb_apipb_project_api_pb.RemoveProjectMember.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.RemoveProjectMember.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.RemoveProjectMember.Response>;

  removeProjectMember(
    request: galaxycompletepb_apipb_project_api_pb.RemoveProjectMember.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.RemoveProjectMember.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/RemoveProjectMember',
        request,
        metadata || {},
        this.methodDescriptorRemoveProjectMember,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/RemoveProjectMember',
    request,
    metadata || {},
    this.methodDescriptorRemoveProjectMember);
  }

  methodDescriptorLeaveProject = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/LeaveProject',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.LeaveProject.Request,
    galaxycompletepb_apipb_project_api_pb.LeaveProject.Response,
    (request: galaxycompletepb_apipb_project_api_pb.LeaveProject.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.LeaveProject.Response.deserializeBinary
  );

  leaveProject(
    request: galaxycompletepb_apipb_project_api_pb.LeaveProject.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.LeaveProject.Response>;

  leaveProject(
    request: galaxycompletepb_apipb_project_api_pb.LeaveProject.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.LeaveProject.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.LeaveProject.Response>;

  leaveProject(
    request: galaxycompletepb_apipb_project_api_pb.LeaveProject.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.LeaveProject.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/LeaveProject',
        request,
        metadata || {},
        this.methodDescriptorLeaveProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/LeaveProject',
    request,
    metadata || {},
    this.methodDescriptorLeaveProject);
  }

  methodDescriptorChangeProjectMemberRole = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/ChangeProjectMemberRole',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.ChangeProjectMemberRole.Request,
    galaxycompletepb_apipb_project_api_pb.ChangeProjectMemberRole.Response,
    (request: galaxycompletepb_apipb_project_api_pb.ChangeProjectMemberRole.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.ChangeProjectMemberRole.Response.deserializeBinary
  );

  changeProjectMemberRole(
    request: galaxycompletepb_apipb_project_api_pb.ChangeProjectMemberRole.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.ChangeProjectMemberRole.Response>;

  changeProjectMemberRole(
    request: galaxycompletepb_apipb_project_api_pb.ChangeProjectMemberRole.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ChangeProjectMemberRole.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.ChangeProjectMemberRole.Response>;

  changeProjectMemberRole(
    request: galaxycompletepb_apipb_project_api_pb.ChangeProjectMemberRole.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ChangeProjectMemberRole.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/ChangeProjectMemberRole',
        request,
        metadata || {},
        this.methodDescriptorChangeProjectMemberRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/ChangeProjectMemberRole',
    request,
    metadata || {},
    this.methodDescriptorChangeProjectMemberRole);
  }

  methodDescriptorListProjectActivities = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/ListProjectActivities',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.ListProjectActivities.Request,
    galaxycompletepb_apipb_project_api_pb.ListProjectActivities.Response,
    (request: galaxycompletepb_apipb_project_api_pb.ListProjectActivities.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.ListProjectActivities.Response.deserializeBinary
  );

  listProjectActivities(
    request: galaxycompletepb_apipb_project_api_pb.ListProjectActivities.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.ListProjectActivities.Response>;

  listProjectActivities(
    request: galaxycompletepb_apipb_project_api_pb.ListProjectActivities.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ListProjectActivities.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.ListProjectActivities.Response>;

  listProjectActivities(
    request: galaxycompletepb_apipb_project_api_pb.ListProjectActivities.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ListProjectActivities.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/ListProjectActivities',
        request,
        metadata || {},
        this.methodDescriptorListProjectActivities,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/ListProjectActivities',
    request,
    metadata || {},
    this.methodDescriptorListProjectActivities);
  }

  methodDescriptorGetProjectLicenseVaultDetails = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/GetProjectLicenseVaultDetails',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.GetProjectLicenseVaultDetails.Request,
    galaxycompletepb_apipb_project_api_pb.GetProjectLicenseVaultDetails.Response,
    (request: galaxycompletepb_apipb_project_api_pb.GetProjectLicenseVaultDetails.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.GetProjectLicenseVaultDetails.Response.deserializeBinary
  );

  getProjectLicenseVaultDetails(
    request: galaxycompletepb_apipb_project_api_pb.GetProjectLicenseVaultDetails.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.GetProjectLicenseVaultDetails.Response>;

  getProjectLicenseVaultDetails(
    request: galaxycompletepb_apipb_project_api_pb.GetProjectLicenseVaultDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.GetProjectLicenseVaultDetails.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.GetProjectLicenseVaultDetails.Response>;

  getProjectLicenseVaultDetails(
    request: galaxycompletepb_apipb_project_api_pb.GetProjectLicenseVaultDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.GetProjectLicenseVaultDetails.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/GetProjectLicenseVaultDetails',
        request,
        metadata || {},
        this.methodDescriptorGetProjectLicenseVaultDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/GetProjectLicenseVaultDetails',
    request,
    metadata || {},
    this.methodDescriptorGetProjectLicenseVaultDetails);
  }

  methodDescriptorListProjectLicenseTransactions = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/ListProjectLicenseTransactions',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.ListProjectLicenseTransactions.Request,
    galaxycompletepb_apipb_project_api_pb.ListProjectLicenseTransactions.Response,
    (request: galaxycompletepb_apipb_project_api_pb.ListProjectLicenseTransactions.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.ListProjectLicenseTransactions.Response.deserializeBinary
  );

  listProjectLicenseTransactions(
    request: galaxycompletepb_apipb_project_api_pb.ListProjectLicenseTransactions.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.ListProjectLicenseTransactions.Response>;

  listProjectLicenseTransactions(
    request: galaxycompletepb_apipb_project_api_pb.ListProjectLicenseTransactions.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ListProjectLicenseTransactions.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.ListProjectLicenseTransactions.Response>;

  listProjectLicenseTransactions(
    request: galaxycompletepb_apipb_project_api_pb.ListProjectLicenseTransactions.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ListProjectLicenseTransactions.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/ListProjectLicenseTransactions',
        request,
        metadata || {},
        this.methodDescriptorListProjectLicenseTransactions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/ListProjectLicenseTransactions',
    request,
    metadata || {},
    this.methodDescriptorListProjectLicenseTransactions);
  }

  methodDescriptorTransferLicenseToProject = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/TransferLicenseToProject',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.TransferLicenseToProject.Request,
    galaxycompletepb_apipb_project_api_pb.TransferLicenseToProject.Response,
    (request: galaxycompletepb_apipb_project_api_pb.TransferLicenseToProject.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.TransferLicenseToProject.Response.deserializeBinary
  );

  transferLicenseToProject(
    request: galaxycompletepb_apipb_project_api_pb.TransferLicenseToProject.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.TransferLicenseToProject.Response>;

  transferLicenseToProject(
    request: galaxycompletepb_apipb_project_api_pb.TransferLicenseToProject.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.TransferLicenseToProject.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.TransferLicenseToProject.Response>;

  transferLicenseToProject(
    request: galaxycompletepb_apipb_project_api_pb.TransferLicenseToProject.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.TransferLicenseToProject.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/TransferLicenseToProject',
        request,
        metadata || {},
        this.methodDescriptorTransferLicenseToProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/TransferLicenseToProject',
    request,
    metadata || {},
    this.methodDescriptorTransferLicenseToProject);
  }

  methodDescriptorTransferLicenseFromProject = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/TransferLicenseFromProject',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.TransferLicenseFromProject.Request,
    galaxycompletepb_apipb_project_api_pb.TransferLicenseFromProject.Response,
    (request: galaxycompletepb_apipb_project_api_pb.TransferLicenseFromProject.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.TransferLicenseFromProject.Response.deserializeBinary
  );

  transferLicenseFromProject(
    request: galaxycompletepb_apipb_project_api_pb.TransferLicenseFromProject.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.TransferLicenseFromProject.Response>;

  transferLicenseFromProject(
    request: galaxycompletepb_apipb_project_api_pb.TransferLicenseFromProject.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.TransferLicenseFromProject.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.TransferLicenseFromProject.Response>;

  transferLicenseFromProject(
    request: galaxycompletepb_apipb_project_api_pb.TransferLicenseFromProject.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.TransferLicenseFromProject.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/TransferLicenseFromProject',
        request,
        metadata || {},
        this.methodDescriptorTransferLicenseFromProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/TransferLicenseFromProject',
    request,
    metadata || {},
    this.methodDescriptorTransferLicenseFromProject);
  }

  methodDescriptorListAllProjectManagementTagNames = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/ListAllProjectManagementTagNames',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.ListAllProjectManagementTagNames.Request,
    galaxycompletepb_apipb_project_api_pb.ListAllProjectManagementTagNames.Response,
    (request: galaxycompletepb_apipb_project_api_pb.ListAllProjectManagementTagNames.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.ListAllProjectManagementTagNames.Response.deserializeBinary
  );

  listAllProjectManagementTagNames(
    request: galaxycompletepb_apipb_project_api_pb.ListAllProjectManagementTagNames.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.ListAllProjectManagementTagNames.Response>;

  listAllProjectManagementTagNames(
    request: galaxycompletepb_apipb_project_api_pb.ListAllProjectManagementTagNames.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ListAllProjectManagementTagNames.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.ListAllProjectManagementTagNames.Response>;

  listAllProjectManagementTagNames(
    request: galaxycompletepb_apipb_project_api_pb.ListAllProjectManagementTagNames.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ListAllProjectManagementTagNames.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/ListAllProjectManagementTagNames',
        request,
        metadata || {},
        this.methodDescriptorListAllProjectManagementTagNames,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/ListAllProjectManagementTagNames',
    request,
    metadata || {},
    this.methodDescriptorListAllProjectManagementTagNames);
  }

  methodDescriptorListManagementTags = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ProjectService/ListManagementTags',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.ListManagementTags.Request,
    galaxycompletepb_apipb_project_api_pb.ListManagementTags.Response,
    (request: galaxycompletepb_apipb_project_api_pb.ListManagementTags.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.ListManagementTags.Response.deserializeBinary
  );

  listManagementTags(
    request: galaxycompletepb_apipb_project_api_pb.ListManagementTags.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.ListManagementTags.Response>;

  listManagementTags(
    request: galaxycompletepb_apipb_project_api_pb.ListManagementTags.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ListManagementTags.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.ListManagementTags.Response>;

  listManagementTags(
    request: galaxycompletepb_apipb_project_api_pb.ListManagementTags.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ListManagementTags.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ProjectService/ListManagementTags',
        request,
        metadata || {},
        this.methodDescriptorListManagementTags,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ProjectService/ListManagementTags',
    request,
    metadata || {},
    this.methodDescriptorListManagementTags);
  }

}

