import {observer} from "mobx-react-lite";
import {SnackbarProps, Snackbar, Button, IconButton, SvgIcon, Box, Theme, useTheme} from "@mui/material";
import {NotificationConfig} from "./NotificationService";
import React, {useState} from "react";
import {Alert, AlertProps, AlertTitle} from "@mui/material";
import {MdClose} from "react-icons/md";

const useNotificationStyles = () => {
    const t = useTheme();
    return {
        alert: {
            padding: '14px 24px'
        },
        infoCard: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: t.palette.background.paper,
            color: t.palette.text.primary

        }
    }

}


// ======================
// BasicNotification
// ======================

interface BasicNotificationProps {
    config: NotificationConfig;
}

export const BasicNotification: React.FC<BasicNotificationProps> = observer((props) => {
    const {config} = props;

    const styles = useNotificationStyles();

    const [open, setOpen] = useState(true)

    const onActionClick = () => {
        config.action();
        setOpen(false)
        config.onClose(true)
    }
    const action = <Box display={'flex'} alignItems={'center'}>
        {config.action &&
        <Box pr={2}>
            <Button onClick={onActionClick} variant={'outlined'}>{config.actionLabel}</Button>
        </Box>
        }
        <Box>
            <IconButton onClick={() => setOpen(false)} size={'small'}><SvgIcon><MdClose/></SvgIcon></IconButton>
        </Box>
    </Box>;

    return <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={open}
                     onClose={() => config.onClose(true)}>
        <Alert severity={config.type} action={action} sx={styles.alert}>
            <Box width={'30vw'}>
                {config.message}
            </Box>
        </Alert>
    </Snackbar>
});

// ======================
// InfoCard
// ======================

interface InfoCardProps {
    title: string,
    message: string | React.ReactNode,
}

export const InfoCard: React.FC<InfoCardProps & Partial<AlertProps>> = observer((props) => {
    const {title, message} = props;
    const styles = useNotificationStyles();

    return <Alert severity={'info'} sx={styles.infoCard}>
        <AlertTitle>{title}</AlertTitle>
        {message}
    </Alert>
})