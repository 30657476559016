// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: ProjectCommon

import * as React from 'react';
import {Box, SvgIcon, SvgIconProps, Theme} from '@mui/material';
import {generatePath, useNavigate, useParams} from "react-router-dom";
import {APP_ROUTES, getProjectSubRouteTemplate} from "../app/AppRoutes";
import {AiOutlineCloudServer, AiOutlineProject} from 'react-icons/ai';
import {MdDashboard, MdOutlineSupportAgent, MdVpnKey} from "react-icons/md";
import {RiSettings5Line} from "react-icons/ri";
import {GoSettings} from "react-icons/go";
import {HiOutlineDocumentReport} from "react-icons/hi";
import {GiMineTruck} from "react-icons/gi";
import {FaUser} from "react-icons/fa";
import {FiServer} from "react-icons/fi";
import {IoIosLink} from "react-icons/io";
import {ColumnSortFilterConfig,} from "../core/data/ListData";
import {ListProjects} from "../../_proto/galaxycompletepb/apipb/project_api_pb";
import {FilterParams} from "../../_proto/galaxycompletepb/commonpb/datafilter_pb";
import {SortState} from "../../common/table/DataTable";
import {useAppServices} from "../app/services";
import {useIsTableEmpty} from "../../common/table/TableCommon";
import {VscLink} from "react-icons/vsc";
import {BiShieldQuarter, BiSubdirectoryRight} from "react-icons/bi";
import {useCurrentProject} from "./CurrentProject";
import {MtdiOsType} from "../../_proto/galaxycompletepb/apipb/domainpb/enumpb/mtdi_os_type_pb";
import {observer} from "mobx-react-lite";
import {SiLinux, SiWindows} from "react-icons/si";
import {UnknownIcons} from "../../common/CommonIcons";

// ======================
// ProjectIcon
// ======================
interface ProjectIconProps {

}

export const ProjectIcon: React.FC<ProjectIconProps & Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <AiOutlineProject/>
    </SvgIcon>;
};

export const SupportIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <MdOutlineSupportAgent/>
    </SvgIcon>;
};

export const DashboardIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <MdDashboard/>
    </SvgIcon>;
};
export const DeploymentIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <AiOutlineCloudServer/>
    </SvgIcon>;
};

export const DeployedDeploymentsIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <FiServer/>
    </SvgIcon>;
};

export const DeploymentConnectionsIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <IoIosLink/>
    </SvgIcon>;
};

export const ProjectManagementIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <RiSettings5Line/>
    </SvgIcon>;
};
export const ProjectSettingsIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <GoSettings/>
    </SvgIcon>;
};
export const ReportsIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <HiOutlineDocumentReport/>
    </SvgIcon>;
};
export const MigrationIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <GiMineTruck/>
    </SvgIcon>;
};

export const IntegrationsIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <VscLink/>
    </SvgIcon>
    };

export const UserSettingsIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <FaUser/>
    </SvgIcon>;
};

export const SubDirectoryIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p} sx={{color: `rgba(255,255,255,.3)`}}>
        <BiSubdirectoryRight/>
    </SvgIcon>;
};

export const DataProtectionIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <BiShieldQuarter/>
    </SvgIcon>;
};

export const LicenseKeyIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <MdVpnKey/>
    </SvgIcon>;
};

export const useNavigateToProjectListScreen = () => {
    const navigate = useNavigate();
    const p = generatePath(APP_ROUTES.PROJECTS);
    return () => navigate(p)
}

export const useNavigateToProjectDetails = (projectId: string) => {
    const navigate = useNavigate();
    const p = generatePath(`${APP_ROUTES.PROJECTS}/${projectId}`);
    return () => navigate(p)
}

export const useNavigateToProjectSubRoute = (sub: string) => {
    const navigate = useNavigate();
    const { projectId} = useParams();
    const p = generatePath(getProjectSubRouteTemplate(sub), {projectId})
    return () => navigate(p)
}


const getListSortFieldLabel = (field: ListProjects.Request.Sort.Field) => {
    if (field === ListProjects.Request.Sort.Field.NAME) {
        return 'Project Name'
    } else if (field === ListProjects.Request.Sort.Field.CREATION_TIME) {
        return 'Creation Time'
    }
}

const getListFilterFieldLabel = (field: ListProjects.Request.Filter.FieldCase) => {
    if (field === ListProjects.Request.Filter.FieldCase.NAME) {
        return 'Project Name';
    } else if (field === ListProjects.Request.Filter.FieldCase.CREATION_TIME) {
        return 'Creation Time';
    } else if (field === ListProjects.Request.Filter.FieldCase.PROJECT_MEMBER) {
        return 'Project Member';
    } else if (field === ListProjects.Request.Filter.FieldCase.DESCRIPTION) {
        return 'Description';
    } else if (field === ListProjects.Request.Filter.FieldCase.DEPLOYMENT_NAME){
        return 'Host Name';
    }
}

export const getProjectListFilterConfig: () => ColumnSortFilterConfig<ListProjects.Request, ListProjects.Request.Sort> = () => {
    return {
        sort: {
            defaultSort: {
                fieldId: ListProjects.Request.Sort.Field.NAME,
                label: getListSortFieldLabel(ListProjects.Request.Sort.Field.NAME),
            },
            sortFields: [
                {
                    fieldId: ListProjects.Request.Sort.Field.NAME,
                    label: getListSortFieldLabel(ListProjects.Request.Sort.Field.NAME),
                },
                {
                    fieldId: ListProjects.Request.Sort.Field.CREATION_TIME,
                    label: getListSortFieldLabel(ListProjects.Request.Sort.Field.CREATION_TIME)
                }],
            getSortParam: (s: SortState) => new ListProjects.Request.Sort()
                .setField(s.field.fieldId)
                .setDesc(s.descending.value)

        },
        filter: [
            {
                fieldId: ListProjects.Request.Filter.FieldCase.NAME,
                label: getListFilterFieldLabel(ListProjects.Request.Filter.FieldCase.NAME),
                filterType: 'string',
                addFilterToRequest: (r: ListProjects.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListProjects.Request.Filter()
                        .setName(f))
                }
            },
            {
                fieldId: ListProjects.Request.Filter.FieldCase.DESCRIPTION,
                label: getListFilterFieldLabel(ListProjects.Request.Filter.FieldCase.DESCRIPTION),
                filterType: 'string',
                addFilterToRequest: (r: ListProjects.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListProjects.Request.Filter()
                        .setDescription(f))
                }
            },
            {
                fieldId: ListProjects.Request.Filter.FieldCase.DEPLOYMENT_NAME,
                label: getListFilterFieldLabel(ListProjects.Request.Filter.FieldCase.DEPLOYMENT_NAME),
                filterType: "string",
                addFilterToRequest: (r: ListProjects.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListProjects.Request.Filter()
                        .setDeploymentName(f)
                    )
                }
            },
            {
                fieldId: ListProjects.Request.Filter.FieldCase.PROJECT_MEMBER,
                label: getListFilterFieldLabel(ListProjects.Request.Filter.FieldCase.PROJECT_MEMBER),
                filterType: 'simpleString',
                addFilterToRequest: (r: ListProjects.Request, f: FilterParams.SimpleStringFilter) => {
                    r.addFilterParams(new ListProjects.Request.Filter()
                        .setProjectMember(f))
                }
            },
            {
                fieldId: ListProjects.Request.Filter.FieldCase.CREATION_TIME,
                label: getListFilterFieldLabel(ListProjects.Request.Filter.FieldCase.CREATION_TIME),
                filterType: 'date',
                addFilterToRequest: (r: ListProjects.Request, f: FilterParams.DateFilter) => {
                    r.addFilterParams(new ListProjects.Request.Filter()
                        .setCreationTime(f))
                }
            },
        ],
    };
};

export const useHasProjects = () => {
    const {projectService} = useAppServices();
    const d =projectService.myProjects;
    return d.ready && d.data?.itemsList?.length > 0;
}

export const getOsDisplayName = (os: MtdiOsType.MtdiOsType) => {
    if (os === MtdiOsType.MtdiOsType.LINUX) {
        return 'Linux';
    } else if (os === MtdiOsType.MtdiOsType.WINDOWS) {
        return 'Windows';
    } else {
        return 'Unknown';
    }
};

interface HostOSIconProps {
    os: MtdiOsType.MtdiOsType;
}

export const HostOSIcon: React.FC<HostOSIconProps & Partial<SvgIconProps>> = observer((p) => {
    const {os, ...props} = p;
    const sizeStyle = {
        size: 20
    }
    return (<Box pt={1}>
        {
            os === (MtdiOsType.MtdiOsType.LINUX) ? <SiLinux {...sizeStyle}/> :
                os === (MtdiOsType.MtdiOsType.WINDOWS) ? <SiWindows {...sizeStyle}/> :
                    <UnknownIcons/>
        }

    </Box>);
});