import {Box, Typography} from "@mui/material";

export const formatErrorMessage = (error: string) => {
    let formattedError = error.split(';;');
    return <Box>
        {formattedError.map((line, index)=>{
            return <Typography sx={{paddingLeft: `${20*index}px`, wordBreak: 'break-word'}} key={index}>
                {line}
            </Typography>
        })

        }
    </Box>
}