import {observer} from "mobx-react-lite";
import {Link, Theme, Tooltip, Typography, TypographyProps, useTheme} from "@mui/material";
import React from "react";
import {TooltipText} from "./TooltipText";

// ======================
// TruncatedText
// ======================


interface TruncatedTextProps{
    characterLimit: number,
    text: string
}

export const TruncatedText: React.FC<TruncatedTextProps &Partial<TypographyProps>> = observer((p)=>{
    const { characterLimit, text, ...typographyProps} = p;
    const t = useTheme();

    if (text.length <= characterLimit){
        return <Typography {...typographyProps}>
            {text}
        </Typography>
    }

    const truncatedText = text.slice(0, (characterLimit-1))

    return <Typography {...typographyProps}>
        {truncatedText}
        <TooltipText label={'...'} tooltipText={text} style={{color:t.palette.primary.main}}/>
    </Typography>
})