/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: DataLoaders
 */
import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {Backdrop, Box, Card, CircularProgress, Theme, Typography, useTheme} from '@mui/material';

// ======================
// FullScreenLoading
// ======================
interface FullScreenLoadingProps {
    loading?: boolean,
    message?: string | React.ReactNode
}

const useFullScreenLoadingStyles = () => {
    const t = useTheme();
    return {
        backdrop: {
            zIndex: t.zIndex.modal + 1,
        }
    }
}

export const FullScreenLoading: React.FC<FullScreenLoadingProps> = observer(({loading, ...p}) => {
    const styles = useFullScreenLoadingStyles();
    const {message} = p;

    if (message) {
        return <Backdrop open={loading} sx={styles.backdrop}>
            <Card>
                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} p={4}>
                    <Box pb={4}>
                        <CircularProgress variant={'indeterminate'} color={'primary'}/>
                    </Box>
                    <Box>
                        {message}
                    </Box>
                </Box>
            </Card>
        </Backdrop>;
    }

    return <Backdrop open={loading} sx={styles.backdrop}>
        <CircularProgress variant={'indeterminate'} color={'primary'}/>
    </Backdrop>;
});