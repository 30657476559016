import { observer } from "mobx-react-lite";
import { AzureSpecType, GmMigrationWizardAzureConfigState, GmMigrationWizardState, GmMigrationWizardVmwareState, VmSpecType } from "../../GmMigrationService";
import { ComputeResourceSelection, GmHelperNodeInfo } from "../../../../_proto/galaxycompletepb/apipb/domainpb/compute_pb";
import * as React from "react";
import {
    Box,
    Button,
    Card,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    MenuItem,
    Radio,
    Select,
    SvgIcon,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useCurrentProjectID } from "../../../project/CurrentProject";
import { QueryTable } from "../../../../common/table/QueryTable";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useGetAzureHelperResourceSelections, useListAzureHelpers } from "../../migration_hooks";
import { QueryResultWrapper } from "../../../core/data/QueryResultWrapper";
import { useAppServices } from "../../../app/services";
import { useNavigateToMigrationSessionDetails } from "../../MigrationCommon";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { FormAutocompleteField, FormSelect, FormTextField } from "../../../../common/form/FormComponents";
import { VmwareCompute } from "../../../../_proto/galaxymigratepb/galaxy_migrate_compute_pb";
import { useEffectOnce } from "react-use";
import { SwitchCard } from "../../../../common/card/SwitchCard";
import { BsFillHddNetworkFill } from "react-icons/bs";
import { FaAngleDoubleRight } from "react-icons/fa";
import { useDialogState } from "../../../core/dialog/DialogService";
import { CloseIcon, EditIcon } from "../../../../common/CommonIcons";
import { AzureCompute } from "../../../../_proto/galaxymigratepb/galaxy_migrate_azure_compute_pb";
import { CreateGalaxyMigrateLinkButton } from "../../../galaxymigrate/links/CreateGalaxyMigrateLinkForm";
import { DataTable } from "../../../../common/table/DataTable";
import { TruncatedText } from "../../../../common/text/TruncatedText";

// ======================
// GmAzureHelperSelectionTable
// ======================

interface GmAzureHelperSelectionTableProps {
    wizardState: GmMigrationWizardState;
}

export const GmAzureHelperSelectionTable: React.FC<GmAzureHelperSelectionTableProps> = observer((p) => {
    const { wizardState } = p;
    const currentProjectId = useCurrentProjectID();

    const queryResult = useListAzureHelpers(currentProjectId, wizardState.deploymentId);

    const columnHelper = createColumnHelper<GmHelperNodeInfo.Azure.AsObject>();

    const [selectedHelper, setSelectedHelper] = useState(null);

    const cols = [
        columnHelper.display({
            id: "select",
            cell: (props) => {
                const selected = selectedHelper === props.row.original.systemId;
                const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    setSelectedHelper(props.row.original.systemId);
                    wizardState.selectRemoteDestination(props.row.original.systemId);
                    wizardState.azureConfigState.setSelectedAzureHelper(props.row.original);
                };
                return <Radio color={"secondary"} checked={selected} onChange={onChange} />;
            },
        }),
        columnHelper.accessor((r) => r.hostName, {
            id: "hostName",
            cell: (props) => props.getValue(),
            header: "Host Name",
        }),
        columnHelper.accessor((r) => r.resourceGroupName, {
            id: "resourceGroup",
            cell: (props) => props.getValue(),
            header: "Resource Group",
        }),
        columnHelper.accessor((r) => r.location, {
            id: "location",
            cell: (props) => props.getValue(),
            header: "Location",
        }),
    ];
    return (
        <>
            <Card>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Box>
                        <ListSubheader>Azure Helpers</ListSubheader>
                    </Box>
                    <Box p={1}>
                        <CreateGalaxyMigrateLinkButton icon refetchFn={async () => await queryResult.refetch()} />
                    </Box>
                </Box>
                <QueryTable
                    data={queryResult.data?.helpersList}
                    isLoading={queryResult.isLoading}
                    isError={queryResult.isError}
                    error={queryResult.error}
                    emptyTableConfig={{
                        emptyTableActionButton: (
                            <CreateGalaxyMigrateLinkButton variant={"contained"} color={"secondary"} refetchFn={async () => await queryResult.refetch()} />
                        ),
                    }}
                    refetch={async () => await queryResult.refetch()}
                    columns={cols}
                />
            </Card>
        </>
    );
});

// ======================
// GmMigrationWizardAzureVmConfigStep
// ======================

interface GmMigrationWizardAzureVmConfigStepProps {
    wizardState: GmMigrationWizardState;
}

export const GmMigrationWizardAzureVmConfigStep: React.FC<GmMigrationWizardAzureVmConfigStepProps> = observer((p) => {
    const { wizardState } = p;

    return (
        <>
            <Typography variant={"body1"}>Please provide the following information for the configuration</Typography>
            <br />
            <AzureVmConfigForm wizardState={wizardState} type={"create"} />
        </>
    );
});

// ======================
// AzureVmConfigForm
// ======================

interface AzureVmConfigFormProps {
    wizardState: GmMigrationWizardState;
    type: "edit" | "create";
}

export const AzureVmConfigForm: React.FC<AzureVmConfigFormProps> = observer((p) => {
    const { wizardState, type } = p;
    const { gmMigrationService } = useAppServices();
    const goBackToSessionDetails = useNavigateToMigrationSessionDetails();
    const azureConfigState = wizardState.azureConfigState;
    const azureSpec = azureConfigState.azureSpec?.getAzureSpec();
    const queryResult = useGetAzureHelperResourceSelections(wizardState.selectedRemoteDestinationId);
    const theme = useTheme();

    const schema = yup.object({
        hostName: yup.string().required(),
        hostDescription: yup.string().required(),
        resourceGroupName: yup.string().required(),
        machineType: yup.string().required(),
    });

    const sourceDeploymentInfo = p.wizardState.deployment?.data?.toObject()?.info;

    const resourceGroupNameSelections = useMemo(() => {
        return queryResult.data?.selections.resourceGroupsList.map((r) => r.name);
    }, [queryResult]);

    const machineTypesSelections = useMemo((): Array<ComputeResourceSelection.Azure.MachineType.AsObject & { label: string; value: string }> => {
        return queryResult.data?.selections.machineTypesList.map((r) => ({
            value: r.name,
            label: r.name,
            ...r,
        }));
    }, [queryResult]);

    const initialValues: AzureSpecType = useMemo(
        () => ({
            hostName: azureSpec?.getHostName() || sourceDeploymentInfo.deployment.systemName,
            hostDescription: azureSpec?.getHostDescription() || "",
            resourceGroupName:
                azureSpec?.getResourceGroupName() ||
                resourceGroupNameSelections?.find((o) => o.includes(wizardState.azureConfigState.selectedAzureHelper?.resourceGroupName)),
            machineType: azureSpec?.getMachineType() || machineTypesSelections?.find((o) => o.name === "Standard_D2s_v3")?.value,
        }),
        [
            sourceDeploymentInfo,
            azureSpec,
            resourceGroupNameSelections,
            machineTypesSelections,
            wizardState.azureConfigState.selectedAzureHelper?.resourceGroupName,
        ]
    );

    const disableContinue = useMemo(() => {
        return azureConfigState.isAnySourceNetworkUnpaired || !azureConfigState.hasSourceNetworks;
    }, [azureConfigState.isAnySourceNetworkUnpaired, azureConfigState.hasSourceNetworks]);

    const getFormActions = () => {
        if (p.type === "edit") {
            return (
                <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                    <Box pr={1}>
                        <Button color={"neutral"} variant={"outlined"} onClick={goBackToSessionDetails}>
                            {"Cancel"}
                        </Button>
                    </Box>
                    <Box pl={1}>
                        <Button color={"primary"} variant={"contained"} type={"submit"} disabled={false}>
                            {`Save`}
                        </Button>
                    </Box>
                </Box>
            );
        }
        return (
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                <Box pr={1}>
                    <Button color={"neutral"} variant={"outlined"} onClick={() => p.wizardState.stepperState.goBackOneStep()}>
                        {`Go Back`}
                    </Button>
                </Box>
                <Box pl={1}>
                    <Button color={"primary"} variant={"contained"} type={"submit"} disabled={disableContinue}>
                        {`Continue`}
                    </Button>
                </Box>
            </Box>
        );
    };

    return (
        <QueryResultWrapper queryResult={queryResult}>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={async (values, formikHelpers) => {
                    const spec = values as AzureSpecType;
                    azureConfigState.setAzureSpec(spec);
                    if (p.type === "edit") {
                        await gmMigrationService.updateSessionComputeSpec(azureConfigState.azureSpec);
                        goBackToSessionDetails();
                    } else {
                        p.wizardState.stepperState.goToNextStep();
                    }
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            <Box pb={2}>
                                <Typography variant={"h5"}>VM Information</Typography>
                                <Grid container pb={1} pt={2}>
                                    <Grid item xs={12}>
                                        <FormTextField label={"Host Name"} name={"hostName"} required />
                                    </Grid>
                                </Grid>
                                <Grid container pb={1} pt={1}>
                                    <Grid item xs={12}>
                                        <FormTextField label={"Host Description"} name={"hostDescription"} />
                                    </Grid>
                                </Grid>
                                <Grid container pt={1}>
                                    <Grid item xs={12} md={6} pr={1}>
                                        <FormSelect label={"Resource Group Name"} name={"resourceGroupName"} selectionList={resourceGroupNameSelections} />
                                    </Grid>
                                    <Grid item xs={12} md={6} pl={1}>
                                        <FormAutocompleteField<string, false, true, false>
                                            name={"machineType"}
                                            label={"Machine Type"}
                                            options={machineTypesSelections?.map((o) => o.value)}
                                            renderOption={(props, option) => {
                                                const machineType = machineTypesSelections?.find((m) => m.value === option);
                                                return (
                                                    <Box
                                                        component={"li"}
                                                        {...props}
                                                        sx={{ borderBottom: "1px solid", borderBottomColor: theme.palette.cirrus.main }}
                                                    >
                                                        <Box>
                                                            <Box>
                                                                <Typography fontWeight={600}>{machineType?.label}</Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography variant={"caption"}>
                                                                    {machineType?.cpu} CPU(s) | {machineType?.memoryMb}mb memory | {machineType?.maxDataDisks}{" "}
                                                                    max disks
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <NetworkInterfacesSection
                                wizardState={p.wizardState}
                                subnetList={queryResult.data?.selections.subnetsList}
                                securityGroupsList={queryResult.data?.selections.securityGroupsList}
                                type={p.type}
                            />
                            {getFormActions()}
                        </Form>
                    );
                }}
            </Formik>
        </QueryResultWrapper>
    );
});

// ======================
// NetworkInterfacesSection
// ======================

interface NetworkInterfacesSectionProps {
    wizardState: GmMigrationWizardState;
    subnetList: Array<ComputeResourceSelection.Azure.Subnet.AsObject>;
    securityGroupsList: Array<ComputeResourceSelection.Azure.NetworkSecurityGroup.AsObject>;
    type: "edit" | "create";
}

const NetworkInterfacesSection: React.FC<NetworkInterfacesSectionProps> = observer((p) => {
    const azureConfigState = p.wizardState.azureConfigState;

    useEffectOnce(() => {
        if (p.type === "edit") {
            for (let network of azureConfigState.azureSpec.getAzureSpec().getNetworksList()) {
                if (!azureConfigState.getIsNetworkPaired(network.getName())) {
                    azureConfigState.pairSourceNetwork(network.getName(), network.getSubnetId());
                }
            }
        }
    });

    return (
        <Box pb={2}>
            <Typography variant={"h5"}>3. Network Interfaces</Typography>
            <br />
            {p.type === "create" ? (
                p.wizardState.deployment.data?.getNetworkInterfacesList().map((n, i) => {
                    const networkInfo = n.toObject();
                    const selected = azureConfigState.getIsNetworkSelected(networkInfo.name);
                    return (
                        <SwitchCard
                            selected={selected}
                            onSelect={(e, c) => {
                                if (selected) {
                                    azureConfigState.removeSourceNetwork(networkInfo.name);
                                } else {
                                    azureConfigState.setSourceNetwork(networkInfo.name);
                                }
                            }}
                            cardContent={
                                <Grid container alignItems={"center"}>
                                    <Grid item lg={3} xs={12}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <SvgIcon>
                                                    <BsFillHddNetworkFill />
                                                </SvgIcon>
                                            </ListItemIcon>
                                            <ListItemText primary={`${networkInfo.name} (${networkInfo.addr})`} secondary={networkInfo.mac} />
                                        </ListItem>
                                    </Grid>
                                    <Grid item lg={1} xs={12}>
                                        {selected && (
                                            <Box>
                                                <SvgIcon>
                                                    <FaAngleDoubleRight />
                                                </SvgIcon>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} lg={5} alignItems={"center"}>
                                        {selected && (
                                            <SubnetSelectionMenu
                                                networkName={networkInfo.name}
                                                subnetList={p.subnetList}
                                                azureConfigState={azureConfigState}
                                                type={"create"}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item lg={3} xs={12} alignItems={"center"}>
                                        {selected && azureConfigState.getIsNetworkPaired(networkInfo.name) && (
                                            <NetworkSelectionCustomizationOptions
                                                securityGroupsList={p.securityGroupsList}
                                                networkName={networkInfo.name}
                                                azureConfigState={azureConfigState}
                                                type={"create"}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            }
                            key={i}
                        />
                    );
                })
            ) : (
                <>
                    {azureConfigState.azureSpec
                        .getAzureSpec()
                        .getNetworksList()
                        .map((network, index) => {
                            return (
                                <Card variant={"outlined"} key={index}>
                                    <Grid container p={2} alignItems={"center"}>
                                        <Grid item xs={12} md={6}>
                                            <SubnetSelectionMenu
                                                subnetList={p.subnetList}
                                                networkName={network.getName()}
                                                azureConfigState={azureConfigState}
                                                type={"edit"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {azureConfigState.getIsNetworkPaired(network.getName()) && (
                                                <NetworkSelectionCustomizationOptions
                                                    networkName={network.getName()}
                                                    azureConfigState={azureConfigState}
                                                    securityGroupsList={p.securityGroupsList}
                                                    type={"edit"}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Card>
                            );
                        })}
                </>
            )}
        </Box>
    );
});

// ======================
// NetworkSelectionCustomizationOptions
// ======================
interface NetworkSelectionCustomizationOptionsProps {
    networkName: string;
    azureConfigState: GmMigrationWizardAzureConfigState;
    type: "edit" | "create";
    securityGroupsList: Array<ComputeResourceSelection.Azure.NetworkSecurityGroup.AsObject>;
}

const NetworkSelectionCustomizationOptions: React.FC<NetworkSelectionCustomizationOptionsProps> = observer((p) => {
    const [securityGroup, setSecurityGroup] = useState(
        p.azureConfigState.pairedNetworks[p.networkName]?.getNetworkSecurityGroupId() || p.securityGroupsList?.find((g) => !!g).id
    );
    const [publicIp, setPublicIp] = useState(p.azureConfigState.pairedNetworks[p.networkName]?.getCreatePublicIp() || false);

    useEffectOnce(() => {
        p.azureConfigState.pairedNetworks[p.networkName]?.setNetworkSecurityGroupId(securityGroup);
    });

    useEffect(() => {
        p.azureConfigState.pairedNetworks[p.networkName]?.setNetworkSecurityGroupId(securityGroup);
    }, [securityGroup, p.networkName, p.azureConfigState.pairedNetworks]);
    useEffect(() => {
        p.azureConfigState.pairedNetworks[p.networkName]?.setCreatePublicIp(publicIp);
    }, [publicIp, p.networkName, p.azureConfigState.pairedNetworks]);

    return (
        <>
            <Box display={p.type === "edit" ? "flex" : null}>
                <FormControl fullWidth variant={"filled"} sx={p.type === "edit" ? { paddingRight: 2 } : {}}>
                    <InputLabel id={"security-group-" + p.networkName}>Security Group</InputLabel>
                    <Select
                        value={securityGroup}
                        labelId={"security-group-" + p.networkName}
                        fullWidth
                        variant={"filled"}
                        onChange={(e) => {
                            p.azureConfigState.pairedNetworks[p.networkName].setNetworkSecurityGroupId(e.target.value);
                            setSecurityGroup(e.target.value);
                        }}
                    >
                        {p.securityGroupsList?.map((group) => {
                            return (
                                <MenuItem value={group.id} key={group.id}>
                                    {group.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <br />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={publicIp}
                            onChange={(e) => {
                                setPublicIp(e.target.checked);
                            }}
                        />
                    }
                    label={"Create Public IP"}
                />
            </Box>
        </>
    );
});

// ======================
// MatchingNetworkSelectionMenu
// ======================

interface SubnetSelectionMenuProps {
    subnetList: Array<ComputeResourceSelection.Azure.Subnet.AsObject>;
    networkName: string;
    azureConfigState: GmMigrationWizardAzureConfigState;
    type: "edit" | "create";
}

const SubnetSelectionMenu: React.FC<SubnetSelectionMenuProps> = observer((p) => {
    const dialogState = useDialogState();
    return (
        <>
            {!p.azureConfigState.getIsNetworkPaired(p.networkName) && (
                <Button variant={"outlined"} color={"secondary"} onClick={dialogState.open}>
                    {`Select Subnet`}
                </Button>
            )}
            {p.azureConfigState.getIsNetworkPaired(p.networkName) && (
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item>
                        <ListItem>
                            <ListItemIcon>
                                <SvgIcon>
                                    <BsFillHddNetworkFill />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText primary={p.subnetList.find((n) => p.azureConfigState.pairedNetworks[p.networkName].getSubnetId() === n.id)?.name} />
                        </ListItem>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={dialogState.open}>
                            <EditIcon />
                        </IconButton>
                        {p.type === "create" && (
                            <IconButton onClick={() => p.azureConfigState.removePairedNetworkFromSource(p.networkName)}>
                                <CloseIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            )}

            {dialogState.isOpen && (
                <Dialog fullWidth maxWidth={"sm"} open={dialogState.isOpen} onClose={dialogState.close}>
                    <ListSubheader>{"Select Subnet"}</ListSubheader>
                    {p.subnetList.map((n) => (
                        <ListItem
                            button
                            onClick={() => {
                                p.azureConfigState.pairSourceNetwork(p.networkName, n.id);
                                dialogState.close();
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon>
                                    <BsFillHddNetworkFill />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText primary={n.name} />
                        </ListItem>
                    ))}
                </Dialog>
            )}
        </>
    );
});
