/**
 * @fileoverview gRPC-Web generated client stub for galaxycomplete.api.gm
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: galaxycompletepb/apipb/gmapipb/galaxymigrate_api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb from '../../../galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb';


export class GalaxyMigrateServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetStorageConfig = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetStorageConfig',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGMStorageConfig.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGMStorageConfig.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGMStorageConfig.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGMStorageConfig.Response.deserializeBinary
  );

  getStorageConfig(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGMStorageConfig.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGMStorageConfig.Response>;

  getStorageConfig(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGMStorageConfig.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGMStorageConfig.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGMStorageConfig.Response>;

  getStorageConfig(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGMStorageConfig.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGMStorageConfig.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetStorageConfig',
        request,
        metadata || {},
        this.methodDescriptorGetStorageConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetStorageConfig',
    request,
    metadata || {},
    this.methodDescriptorGetStorageConfig);
  }

  methodDescriptorCreateMigrationSession = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/CreateMigrationSession',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateMigrationSession.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateMigrationSession.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateMigrationSession.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateMigrationSession.Response.deserializeBinary
  );

  createMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateMigrationSession.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateMigrationSession.Response>;

  createMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateMigrationSession.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateMigrationSession.Response>;

  createMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateMigrationSession.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/CreateMigrationSession',
        request,
        metadata || {},
        this.methodDescriptorCreateMigrationSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/CreateMigrationSession',
    request,
    metadata || {},
    this.methodDescriptorCreateMigrationSession);
  }

  methodDescriptorListMigrationSessions = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/ListMigrationSessions',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationSessions.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationSessions.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationSessions.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationSessions.Response.deserializeBinary
  );

  listMigrationSessions(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationSessions.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationSessions.Response>;

  listMigrationSessions(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationSessions.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationSessions.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationSessions.Response>;

  listMigrationSessions(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationSessions.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationSessions.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/ListMigrationSessions',
        request,
        metadata || {},
        this.methodDescriptorListMigrationSessions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/ListMigrationSessions',
    request,
    metadata || {},
    this.methodDescriptorListMigrationSessions);
  }

  methodDescriptorGetMigrationSessionDetails = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetMigrationSessionDetails',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionDetails.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionDetails.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionDetails.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionDetails.Response.deserializeBinary
  );

  getMigrationSessionDetails(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionDetails.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionDetails.Response>;

  getMigrationSessionDetails(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionDetails.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionDetails.Response>;

  getMigrationSessionDetails(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionDetails.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetMigrationSessionDetails',
        request,
        metadata || {},
        this.methodDescriptorGetMigrationSessionDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetMigrationSessionDetails',
    request,
    metadata || {},
    this.methodDescriptorGetMigrationSessionDetails);
  }

  methodDescriptorRestartMigrationSession = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/RestartMigrationSession',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMigrationSession.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMigrationSession.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMigrationSession.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMigrationSession.Response.deserializeBinary
  );

  restartMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMigrationSession.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMigrationSession.Response>;

  restartMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMigrationSession.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMigrationSession.Response>;

  restartMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMigrationSession.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/RestartMigrationSession',
        request,
        metadata || {},
        this.methodDescriptorRestartMigrationSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/RestartMigrationSession',
    request,
    metadata || {},
    this.methodDescriptorRestartMigrationSession);
  }

  methodDescriptorCancelMigrationSession = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/CancelMigrationSession',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CancelMigrationSession.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CancelMigrationSession.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CancelMigrationSession.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CancelMigrationSession.Response.deserializeBinary
  );

  cancelMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CancelMigrationSession.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CancelMigrationSession.Response>;

  cancelMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CancelMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CancelMigrationSession.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CancelMigrationSession.Response>;

  cancelMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CancelMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CancelMigrationSession.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/CancelMigrationSession',
        request,
        metadata || {},
        this.methodDescriptorCancelMigrationSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/CancelMigrationSession',
    request,
    metadata || {},
    this.methodDescriptorCancelMigrationSession);
  }

  methodDescriptorUpdateMigrationSession = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/UpdateMigrationSession',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSession.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSession.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSession.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSession.Response.deserializeBinary
  );

  updateMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSession.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSession.Response>;

  updateMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSession.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSession.Response>;

  updateMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSession.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/UpdateMigrationSession',
        request,
        metadata || {},
        this.methodDescriptorUpdateMigrationSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/UpdateMigrationSession',
    request,
    metadata || {},
    this.methodDescriptorUpdateMigrationSession);
  }

  methodDescriptorSyncMigrationSession = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/SyncMigrationSession',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SyncMigrationSession.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SyncMigrationSession.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SyncMigrationSession.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SyncMigrationSession.Response.deserializeBinary
  );

  syncMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SyncMigrationSession.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SyncMigrationSession.Response>;

  syncMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SyncMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SyncMigrationSession.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SyncMigrationSession.Response>;

  syncMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SyncMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SyncMigrationSession.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/SyncMigrationSession',
        request,
        metadata || {},
        this.methodDescriptorSyncMigrationSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/SyncMigrationSession',
    request,
    metadata || {},
    this.methodDescriptorSyncMigrationSession);
  }

  methodDescriptorSuspendMigrationSession = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/SuspendMigrationSession',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SuspendMigrationSession.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SuspendMigrationSession.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SuspendMigrationSession.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SuspendMigrationSession.Response.deserializeBinary
  );

  suspendMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SuspendMigrationSession.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SuspendMigrationSession.Response>;

  suspendMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SuspendMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SuspendMigrationSession.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SuspendMigrationSession.Response>;

  suspendMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SuspendMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.SuspendMigrationSession.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/SuspendMigrationSession',
        request,
        metadata || {},
        this.methodDescriptorSuspendMigrationSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/SuspendMigrationSession',
    request,
    metadata || {},
    this.methodDescriptorSuspendMigrationSession);
  }

  methodDescriptorCutoverMigrationSession = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/CutoverMigrationSession',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CutoverMigrationSession.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CutoverMigrationSession.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CutoverMigrationSession.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CutoverMigrationSession.Response.deserializeBinary
  );

  cutoverMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CutoverMigrationSession.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CutoverMigrationSession.Response>;

  cutoverMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CutoverMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CutoverMigrationSession.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CutoverMigrationSession.Response>;

  cutoverMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CutoverMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CutoverMigrationSession.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/CutoverMigrationSession',
        request,
        metadata || {},
        this.methodDescriptorCutoverMigrationSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/CutoverMigrationSession',
    request,
    metadata || {},
    this.methodDescriptorCutoverMigrationSession);
  }

  methodDescriptorFinalCutoverMigrationSession = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/FinalCutoverMigrationSession',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.FinalCutoverMigrationSession.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.FinalCutoverMigrationSession.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.FinalCutoverMigrationSession.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.FinalCutoverMigrationSession.Response.deserializeBinary
  );

  finalCutoverMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.FinalCutoverMigrationSession.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.FinalCutoverMigrationSession.Response>;

  finalCutoverMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.FinalCutoverMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.FinalCutoverMigrationSession.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.FinalCutoverMigrationSession.Response>;

  finalCutoverMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.FinalCutoverMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.FinalCutoverMigrationSession.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/FinalCutoverMigrationSession',
        request,
        metadata || {},
        this.methodDescriptorFinalCutoverMigrationSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/FinalCutoverMigrationSession',
    request,
    metadata || {},
    this.methodDescriptorFinalCutoverMigrationSession);
  }

  methodDescriptorRevertCutoverMigrationSession = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/RevertCutoverMigrationSession',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RevertCutoverMigrationSession.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RevertCutoverMigrationSession.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RevertCutoverMigrationSession.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RevertCutoverMigrationSession.Response.deserializeBinary
  );

  revertCutoverMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RevertCutoverMigrationSession.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RevertCutoverMigrationSession.Response>;

  revertCutoverMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RevertCutoverMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RevertCutoverMigrationSession.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RevertCutoverMigrationSession.Response>;

  revertCutoverMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RevertCutoverMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RevertCutoverMigrationSession.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/RevertCutoverMigrationSession',
        request,
        metadata || {},
        this.methodDescriptorRevertCutoverMigrationSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/RevertCutoverMigrationSession',
    request,
    metadata || {},
    this.methodDescriptorRevertCutoverMigrationSession);
  }

  methodDescriptorDeleteMigrationSession = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/DeleteMigrationSession',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteMigrationSession.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteMigrationSession.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteMigrationSession.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteMigrationSession.Response.deserializeBinary
  );

  deleteMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteMigrationSession.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteMigrationSession.Response>;

  deleteMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteMigrationSession.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteMigrationSession.Response>;

  deleteMigrationSession(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteMigrationSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteMigrationSession.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/DeleteMigrationSession',
        request,
        metadata || {},
        this.methodDescriptorDeleteMigrationSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/DeleteMigrationSession',
    request,
    metadata || {},
    this.methodDescriptorDeleteMigrationSession);
  }

  methodDescriptorGetMigrationVolumeChangesDistributionView = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetMigrationVolumeChangesDistributionView',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationVolumeChangesDistributionView.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationVolumeChangesDistributionView.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationVolumeChangesDistributionView.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationVolumeChangesDistributionView.Response.deserializeBinary
  );

  getMigrationVolumeChangesDistributionView(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationVolumeChangesDistributionView.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationVolumeChangesDistributionView.Response>;

  getMigrationVolumeChangesDistributionView(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationVolumeChangesDistributionView.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationVolumeChangesDistributionView.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationVolumeChangesDistributionView.Response>;

  getMigrationVolumeChangesDistributionView(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationVolumeChangesDistributionView.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationVolumeChangesDistributionView.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetMigrationVolumeChangesDistributionView',
        request,
        metadata || {},
        this.methodDescriptorGetMigrationVolumeChangesDistributionView,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetMigrationVolumeChangesDistributionView',
    request,
    metadata || {},
    this.methodDescriptorGetMigrationVolumeChangesDistributionView);
  }

  methodDescriptorListMigrationEvents = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/ListMigrationEvents',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationEvents.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationEvents.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationEvents.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationEvents.Response.deserializeBinary
  );

  listMigrationEvents(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationEvents.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationEvents.Response>;

  listMigrationEvents(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationEvents.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationEvents.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationEvents.Response>;

  listMigrationEvents(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationEvents.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListMigrationEvents.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/ListMigrationEvents',
        request,
        metadata || {},
        this.methodDescriptorListMigrationEvents,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/ListMigrationEvents',
    request,
    metadata || {},
    this.methodDescriptorListMigrationEvents);
  }

  methodDescriptorTestAutoAllocConnection = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/TestAutoAllocConnection',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TestAutoAllocConnection.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TestAutoAllocConnection.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TestAutoAllocConnection.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TestAutoAllocConnection.Response.deserializeBinary
  );

  testAutoAllocConnection(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TestAutoAllocConnection.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TestAutoAllocConnection.Response>;

  testAutoAllocConnection(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TestAutoAllocConnection.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TestAutoAllocConnection.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TestAutoAllocConnection.Response>;

  testAutoAllocConnection(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TestAutoAllocConnection.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TestAutoAllocConnection.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/TestAutoAllocConnection',
        request,
        metadata || {},
        this.methodDescriptorTestAutoAllocConnection,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/TestAutoAllocConnection',
    request,
    metadata || {},
    this.methodDescriptorTestAutoAllocConnection);
  }

  methodDescriptorIsPreparedForAutoAlloc = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/IsPreparedForAutoAlloc',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.IsPreparedForAutoAlloc.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.IsPreparedForAutoAlloc.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.IsPreparedForAutoAlloc.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.IsPreparedForAutoAlloc.Response.deserializeBinary
  );

  isPreparedForAutoAlloc(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.IsPreparedForAutoAlloc.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.IsPreparedForAutoAlloc.Response>;

  isPreparedForAutoAlloc(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.IsPreparedForAutoAlloc.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.IsPreparedForAutoAlloc.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.IsPreparedForAutoAlloc.Response>;

  isPreparedForAutoAlloc(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.IsPreparedForAutoAlloc.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.IsPreparedForAutoAlloc.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/IsPreparedForAutoAlloc',
        request,
        metadata || {},
        this.methodDescriptorIsPreparedForAutoAlloc,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/IsPreparedForAutoAlloc',
    request,
    metadata || {},
    this.methodDescriptorIsPreparedForAutoAlloc);
  }

  methodDescriptorPrepareForAutoAlloc = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/PrepareForAutoAlloc',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareForAutoAlloc.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareForAutoAlloc.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareForAutoAlloc.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareForAutoAlloc.Response.deserializeBinary
  );

  prepareForAutoAlloc(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareForAutoAlloc.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareForAutoAlloc.Response>;

  prepareForAutoAlloc(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareForAutoAlloc.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareForAutoAlloc.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareForAutoAlloc.Response>;

  prepareForAutoAlloc(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareForAutoAlloc.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareForAutoAlloc.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/PrepareForAutoAlloc',
        request,
        metadata || {},
        this.methodDescriptorPrepareForAutoAlloc,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/PrepareForAutoAlloc',
    request,
    metadata || {},
    this.methodDescriptorPrepareForAutoAlloc);
  }

  methodDescriptorAutoAllocVolumes = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/AutoAllocVolumes',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AutoAllocVolumes.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AutoAllocVolumes.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AutoAllocVolumes.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AutoAllocVolumes.Response.deserializeBinary
  );

  autoAllocVolumes(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AutoAllocVolumes.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AutoAllocVolumes.Response>;

  autoAllocVolumes(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AutoAllocVolumes.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AutoAllocVolumes.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AutoAllocVolumes.Response>;

  autoAllocVolumes(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AutoAllocVolumes.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AutoAllocVolumes.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/AutoAllocVolumes',
        request,
        metadata || {},
        this.methodDescriptorAutoAllocVolumes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/AutoAllocVolumes',
    request,
    metadata || {},
    this.methodDescriptorAutoAllocVolumes);
  }

  methodDescriptorTakeSnapshots = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/TakeSnapshots',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TakeSnapshots.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TakeSnapshots.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TakeSnapshots.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TakeSnapshots.Response.deserializeBinary
  );

  takeSnapshots(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TakeSnapshots.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TakeSnapshots.Response>;

  takeSnapshots(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TakeSnapshots.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TakeSnapshots.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TakeSnapshots.Response>;

  takeSnapshots(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TakeSnapshots.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.TakeSnapshots.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/TakeSnapshots',
        request,
        metadata || {},
        this.methodDescriptorTakeSnapshots,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/TakeSnapshots',
    request,
    metadata || {},
    this.methodDescriptorTakeSnapshots);
  }

  methodDescriptorListSnapshots = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/ListSnapshots',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListSnapshots.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListSnapshots.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListSnapshots.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListSnapshots.Response.deserializeBinary
  );

  listSnapshots(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListSnapshots.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListSnapshots.Response>;

  listSnapshots(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListSnapshots.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListSnapshots.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListSnapshots.Response>;

  listSnapshots(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListSnapshots.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListSnapshots.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/ListSnapshots',
        request,
        metadata || {},
        this.methodDescriptorListSnapshots,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/ListSnapshots',
    request,
    metadata || {},
    this.methodDescriptorListSnapshots);
  }

  methodDescriptorAllocateVolumesFromSnapshots = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/AllocateVolumesFromSnapshots',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateVolumesFromSnapshots.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateVolumesFromSnapshots.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateVolumesFromSnapshots.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateVolumesFromSnapshots.Response.deserializeBinary
  );

  allocateVolumesFromSnapshots(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateVolumesFromSnapshots.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateVolumesFromSnapshots.Response>;

  allocateVolumesFromSnapshots(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateVolumesFromSnapshots.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateVolumesFromSnapshots.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateVolumesFromSnapshots.Response>;

  allocateVolumesFromSnapshots(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateVolumesFromSnapshots.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateVolumesFromSnapshots.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/AllocateVolumesFromSnapshots',
        request,
        metadata || {},
        this.methodDescriptorAllocateVolumesFromSnapshots,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/AllocateVolumesFromSnapshots',
    request,
    metadata || {},
    this.methodDescriptorAllocateVolumesFromSnapshots);
  }

  methodDescriptorDeleteSnapshots = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/DeleteSnapshots',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteSnapshots.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteSnapshots.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteSnapshots.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteSnapshots.Response.deserializeBinary
  );

  deleteSnapshots(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteSnapshots.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteSnapshots.Response>;

  deleteSnapshots(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteSnapshots.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteSnapshots.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteSnapshots.Response>;

  deleteSnapshots(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteSnapshots.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.DeleteSnapshots.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/DeleteSnapshots',
        request,
        metadata || {},
        this.methodDescriptorDeleteSnapshots,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/DeleteSnapshots',
    request,
    metadata || {},
    this.methodDescriptorDeleteSnapshots);
  }

  methodDescriptorCloneMigrationSessionSnapshot = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/CloneMigrationSessionSnapshot',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CloneMigrationSessionSnapshot.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CloneMigrationSessionSnapshot.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CloneMigrationSessionSnapshot.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CloneMigrationSessionSnapshot.Response.deserializeBinary
  );

  cloneMigrationSessionSnapshot(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CloneMigrationSessionSnapshot.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CloneMigrationSessionSnapshot.Response>;

  cloneMigrationSessionSnapshot(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CloneMigrationSessionSnapshot.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CloneMigrationSessionSnapshot.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CloneMigrationSessionSnapshot.Response>;

  cloneMigrationSessionSnapshot(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CloneMigrationSessionSnapshot.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CloneMigrationSessionSnapshot.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/CloneMigrationSessionSnapshot',
        request,
        metadata || {},
        this.methodDescriptorCloneMigrationSessionSnapshot,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/CloneMigrationSessionSnapshot',
    request,
    metadata || {},
    this.methodDescriptorCloneMigrationSessionSnapshot);
  }

  methodDescriptorListAutoAllocStatuses = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/ListAutoAllocStatuses',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAutoAllocStatuses.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAutoAllocStatuses.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAutoAllocStatuses.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAutoAllocStatuses.Response.deserializeBinary
  );

  listAutoAllocStatuses(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAutoAllocStatuses.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAutoAllocStatuses.Response>;

  listAutoAllocStatuses(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAutoAllocStatuses.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAutoAllocStatuses.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAutoAllocStatuses.Response>;

  listAutoAllocStatuses(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAutoAllocStatuses.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAutoAllocStatuses.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/ListAutoAllocStatuses',
        request,
        metadata || {},
        this.methodDescriptorListAutoAllocStatuses,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/ListAutoAllocStatuses',
    request,
    metadata || {},
    this.methodDescriptorListAutoAllocStatuses);
  }

  methodDescriptorGetAutoAllocStatusDetails = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetAutoAllocStatusDetails',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAutoAllocStatusDetails.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAutoAllocStatusDetails.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAutoAllocStatusDetails.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAutoAllocStatusDetails.Response.deserializeBinary
  );

  getAutoAllocStatusDetails(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAutoAllocStatusDetails.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAutoAllocStatusDetails.Response>;

  getAutoAllocStatusDetails(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAutoAllocStatusDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAutoAllocStatusDetails.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAutoAllocStatusDetails.Response>;

  getAutoAllocStatusDetails(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAutoAllocStatusDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAutoAllocStatusDetails.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetAutoAllocStatusDetails',
        request,
        metadata || {},
        this.methodDescriptorGetAutoAllocStatusDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetAutoAllocStatusDetails',
    request,
    metadata || {},
    this.methodDescriptorGetAutoAllocStatusDetails);
  }

  methodDescriptorGetMigrationSessionVolQosState = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetMigrationSessionVolQosState',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosState.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosState.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosState.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosState.Response.deserializeBinary
  );

  getMigrationSessionVolQosState(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosState.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosState.Response>;

  getMigrationSessionVolQosState(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosState.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosState.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosState.Response>;

  getMigrationSessionVolQosState(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosState.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosState.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetMigrationSessionVolQosState',
        request,
        metadata || {},
        this.methodDescriptorGetMigrationSessionVolQosState,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetMigrationSessionVolQosState',
    request,
    metadata || {},
    this.methodDescriptorGetMigrationSessionVolQosState);
  }

  methodDescriptorGetMigrationSessionVolQosRollingAvgLog = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetMigrationSessionVolQosRollingAvgLog',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosRollingAvgLog.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosRollingAvgLog.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosRollingAvgLog.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosRollingAvgLog.Response.deserializeBinary
  );

  getMigrationSessionVolQosRollingAvgLog(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosRollingAvgLog.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosRollingAvgLog.Response>;

  getMigrationSessionVolQosRollingAvgLog(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosRollingAvgLog.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosRollingAvgLog.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosRollingAvgLog.Response>;

  getMigrationSessionVolQosRollingAvgLog(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosRollingAvgLog.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetMigrationSessionVolQosRollingAvgLog.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetMigrationSessionVolQosRollingAvgLog',
        request,
        metadata || {},
        this.methodDescriptorGetMigrationSessionVolQosRollingAvgLog,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetMigrationSessionVolQosRollingAvgLog',
    request,
    metadata || {},
    this.methodDescriptorGetMigrationSessionVolQosRollingAvgLog);
  }

  methodDescriptorCheckForOnlineUpgrade = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/CheckForOnlineUpgrade',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CheckForOnlineUpgrade.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CheckForOnlineUpgrade.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CheckForOnlineUpgrade.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CheckForOnlineUpgrade.Response.deserializeBinary
  );

  checkForOnlineUpgrade(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CheckForOnlineUpgrade.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CheckForOnlineUpgrade.Response>;

  checkForOnlineUpgrade(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CheckForOnlineUpgrade.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CheckForOnlineUpgrade.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CheckForOnlineUpgrade.Response>;

  checkForOnlineUpgrade(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CheckForOnlineUpgrade.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CheckForOnlineUpgrade.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/CheckForOnlineUpgrade',
        request,
        metadata || {},
        this.methodDescriptorCheckForOnlineUpgrade,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/CheckForOnlineUpgrade',
    request,
    metadata || {},
    this.methodDescriptorCheckForOnlineUpgrade);
  }

  methodDescriptorPerformOnlineUpgrade = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/PerformOnlineUpgrade',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PerformOnlineUpgrade.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PerformOnlineUpgrade.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PerformOnlineUpgrade.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PerformOnlineUpgrade.Response.deserializeBinary
  );

  performOnlineUpgrade(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PerformOnlineUpgrade.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PerformOnlineUpgrade.Response>;

  performOnlineUpgrade(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PerformOnlineUpgrade.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PerformOnlineUpgrade.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PerformOnlineUpgrade.Response>;

  performOnlineUpgrade(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PerformOnlineUpgrade.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PerformOnlineUpgrade.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/PerformOnlineUpgrade',
        request,
        metadata || {},
        this.methodDescriptorPerformOnlineUpgrade,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/PerformOnlineUpgrade',
    request,
    metadata || {},
    this.methodDescriptorPerformOnlineUpgrade);
  }

  methodDescriptorGetLastOnlineUpgradeStatus = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetLastOnlineUpgradeStatus',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetLastOnlineUpgradeStatus.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetLastOnlineUpgradeStatus.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetLastOnlineUpgradeStatus.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetLastOnlineUpgradeStatus.Response.deserializeBinary
  );

  getLastOnlineUpgradeStatus(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetLastOnlineUpgradeStatus.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetLastOnlineUpgradeStatus.Response>;

  getLastOnlineUpgradeStatus(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetLastOnlineUpgradeStatus.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetLastOnlineUpgradeStatus.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetLastOnlineUpgradeStatus.Response>;

  getLastOnlineUpgradeStatus(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetLastOnlineUpgradeStatus.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetLastOnlineUpgradeStatus.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetLastOnlineUpgradeStatus',
        request,
        metadata || {},
        this.methodDescriptorGetLastOnlineUpgradeStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetLastOnlineUpgradeStatus',
    request,
    metadata || {},
    this.methodDescriptorGetLastOnlineUpgradeStatus);
  }

  methodDescriptorGetXRay = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetXRay',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetXRay.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetXRay.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetXRay.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetXRay.Response.deserializeBinary
  );

  getXRay(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetXRay.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetXRay.Response>;

  getXRay(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetXRay.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetXRay.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetXRay.Response>;

  getXRay(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetXRay.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetXRay.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetXRay',
        request,
        metadata || {},
        this.methodDescriptorGetXRay,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetXRay',
    request,
    metadata || {},
    this.methodDescriptorGetXRay);
  }

  methodDescriptorViewLogs = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/ViewLogs',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ViewLogs.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ViewLogs.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ViewLogs.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ViewLogs.Response.deserializeBinary
  );

  viewLogs(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ViewLogs.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ViewLogs.Response>;

  viewLogs(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ViewLogs.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ViewLogs.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ViewLogs.Response>;

  viewLogs(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ViewLogs.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ViewLogs.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/ViewLogs',
        request,
        metadata || {},
        this.methodDescriptorViewLogs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/ViewLogs',
    request,
    metadata || {},
    this.methodDescriptorViewLogs);
  }

  methodDescriptorRestartMTdiDaemon = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/RestartMTdiDaemon',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMTdiDaemon.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMTdiDaemon.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMTdiDaemon.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMTdiDaemon.Response.deserializeBinary
  );

  restartMTdiDaemon(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMTdiDaemon.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMTdiDaemon.Response>;

  restartMTdiDaemon(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMTdiDaemon.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMTdiDaemon.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMTdiDaemon.Response>;

  restartMTdiDaemon(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMTdiDaemon.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RestartMTdiDaemon.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/RestartMTdiDaemon',
        request,
        metadata || {},
        this.methodDescriptorRestartMTdiDaemon,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/RestartMTdiDaemon',
    request,
    metadata || {},
    this.methodDescriptorRestartMTdiDaemon);
  }

  methodDescriptorConfigureCdcRelayServer = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/ConfigureCdcRelayServer',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureCdcRelayServer.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureCdcRelayServer.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureCdcRelayServer.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureCdcRelayServer.Response.deserializeBinary
  );

  configureCdcRelayServer(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureCdcRelayServer.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureCdcRelayServer.Response>;

  configureCdcRelayServer(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureCdcRelayServer.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureCdcRelayServer.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureCdcRelayServer.Response>;

  configureCdcRelayServer(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureCdcRelayServer.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureCdcRelayServer.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/ConfigureCdcRelayServer',
        request,
        metadata || {},
        this.methodDescriptorConfigureCdcRelayServer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/ConfigureCdcRelayServer',
    request,
    metadata || {},
    this.methodDescriptorConfigureCdcRelayServer);
  }

  methodDescriptorGetCdcRelayServerStatus = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetCdcRelayServerStatus',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetCdcRelayServerStatus.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetCdcRelayServerStatus.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetCdcRelayServerStatus.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetCdcRelayServerStatus.Response.deserializeBinary
  );

  getCdcRelayServerStatus(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetCdcRelayServerStatus.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetCdcRelayServerStatus.Response>;

  getCdcRelayServerStatus(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetCdcRelayServerStatus.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetCdcRelayServerStatus.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetCdcRelayServerStatus.Response>;

  getCdcRelayServerStatus(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetCdcRelayServerStatus.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetCdcRelayServerStatus.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetCdcRelayServerStatus',
        request,
        metadata || {},
        this.methodDescriptorGetCdcRelayServerStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetCdcRelayServerStatus',
    request,
    metadata || {},
    this.methodDescriptorGetCdcRelayServerStatus);
  }

  methodDescriptorCreateGalaxyMigrateLink = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/CreateGalaxyMigrateLink',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateGalaxyMigrateLink.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateGalaxyMigrateLink.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateGalaxyMigrateLink.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateGalaxyMigrateLink.Response.deserializeBinary
  );

  createGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateGalaxyMigrateLink.Response>;

  createGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateGalaxyMigrateLink.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateGalaxyMigrateLink.Response>;

  createGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.CreateGalaxyMigrateLink.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/CreateGalaxyMigrateLink',
        request,
        metadata || {},
        this.methodDescriptorCreateGalaxyMigrateLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/CreateGalaxyMigrateLink',
    request,
    metadata || {},
    this.methodDescriptorCreateGalaxyMigrateLink);
  }

  methodDescriptorUpdateGalaxyMigrateLink = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/UpdateGalaxyMigrateLink',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateGalaxyMigrateLink.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateGalaxyMigrateLink.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateGalaxyMigrateLink.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateGalaxyMigrateLink.Response.deserializeBinary
  );

  updateGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateGalaxyMigrateLink.Response>;

  updateGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateGalaxyMigrateLink.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateGalaxyMigrateLink.Response>;

  updateGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateGalaxyMigrateLink.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/UpdateGalaxyMigrateLink',
        request,
        metadata || {},
        this.methodDescriptorUpdateGalaxyMigrateLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/UpdateGalaxyMigrateLink',
    request,
    metadata || {},
    this.methodDescriptorUpdateGalaxyMigrateLink);
  }

  methodDescriptorReconnectGalaxyMigrateLink = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/ReconnectGalaxyMigrateLink',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ReconnectGalaxyMigrateLink.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ReconnectGalaxyMigrateLink.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ReconnectGalaxyMigrateLink.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ReconnectGalaxyMigrateLink.Response.deserializeBinary
  );

  reconnectGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ReconnectGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ReconnectGalaxyMigrateLink.Response>;

  reconnectGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ReconnectGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ReconnectGalaxyMigrateLink.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ReconnectGalaxyMigrateLink.Response>;

  reconnectGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ReconnectGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ReconnectGalaxyMigrateLink.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/ReconnectGalaxyMigrateLink',
        request,
        metadata || {},
        this.methodDescriptorReconnectGalaxyMigrateLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/ReconnectGalaxyMigrateLink',
    request,
    metadata || {},
    this.methodDescriptorReconnectGalaxyMigrateLink);
  }

  methodDescriptorRemoveGalaxyMigrateLink = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/RemoveGalaxyMigrateLink',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RemoveGalaxyMigrateLink.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RemoveGalaxyMigrateLink.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RemoveGalaxyMigrateLink.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RemoveGalaxyMigrateLink.Response.deserializeBinary
  );

  removeGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RemoveGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RemoveGalaxyMigrateLink.Response>;

  removeGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RemoveGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RemoveGalaxyMigrateLink.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RemoveGalaxyMigrateLink.Response>;

  removeGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RemoveGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RemoveGalaxyMigrateLink.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/RemoveGalaxyMigrateLink',
        request,
        metadata || {},
        this.methodDescriptorRemoveGalaxyMigrateLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/RemoveGalaxyMigrateLink',
    request,
    metadata || {},
    this.methodDescriptorRemoveGalaxyMigrateLink);
  }

  methodDescriptorListGalaxyMigrateLinks = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/ListGalaxyMigrateLinks',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListGalaxyMigrateLinks.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListGalaxyMigrateLinks.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListGalaxyMigrateLinks.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListGalaxyMigrateLinks.Response.deserializeBinary
  );

  listGalaxyMigrateLinks(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListGalaxyMigrateLinks.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListGalaxyMigrateLinks.Response>;

  listGalaxyMigrateLinks(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListGalaxyMigrateLinks.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListGalaxyMigrateLinks.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListGalaxyMigrateLinks.Response>;

  listGalaxyMigrateLinks(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListGalaxyMigrateLinks.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListGalaxyMigrateLinks.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/ListGalaxyMigrateLinks',
        request,
        metadata || {},
        this.methodDescriptorListGalaxyMigrateLinks,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/ListGalaxyMigrateLinks',
    request,
    metadata || {},
    this.methodDescriptorListGalaxyMigrateLinks);
  }

  methodDescriptorGetGalaxyMigrateLink = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetGalaxyMigrateLink',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGalaxyMigrateLink.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGalaxyMigrateLink.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGalaxyMigrateLink.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGalaxyMigrateLink.Response.deserializeBinary
  );

  getGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGalaxyMigrateLink.Response>;

  getGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGalaxyMigrateLink.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGalaxyMigrateLink.Response>;

  getGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetGalaxyMigrateLink.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetGalaxyMigrateLink',
        request,
        metadata || {},
        this.methodDescriptorGetGalaxyMigrateLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetGalaxyMigrateLink',
    request,
    metadata || {},
    this.methodDescriptorGetGalaxyMigrateLink);
  }

  methodDescriptorRefreshGalaxyMigrateLink = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/RefreshGalaxyMigrateLink',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RefreshGalaxyMigrateLink.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RefreshGalaxyMigrateLink.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RefreshGalaxyMigrateLink.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RefreshGalaxyMigrateLink.Response.deserializeBinary
  );

  refreshGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RefreshGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RefreshGalaxyMigrateLink.Response>;

  refreshGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RefreshGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RefreshGalaxyMigrateLink.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RefreshGalaxyMigrateLink.Response>;

  refreshGalaxyMigrateLink(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RefreshGalaxyMigrateLink.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.RefreshGalaxyMigrateLink.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/RefreshGalaxyMigrateLink',
        request,
        metadata || {},
        this.methodDescriptorRefreshGalaxyMigrateLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/RefreshGalaxyMigrateLink',
    request,
    metadata || {},
    this.methodDescriptorRefreshGalaxyMigrateLink);
  }

  methodDescriptorGetEligibleMigrationSessionCutOverChecklists = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetEligibleMigrationSessionCutOverChecklists',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetEligibleMigrationSessionCutOverChecklists.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetEligibleMigrationSessionCutOverChecklists.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetEligibleMigrationSessionCutOverChecklists.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetEligibleMigrationSessionCutOverChecklists.Response.deserializeBinary
  );

  getEligibleMigrationSessionCutOverChecklists(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetEligibleMigrationSessionCutOverChecklists.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetEligibleMigrationSessionCutOverChecklists.Response>;

  getEligibleMigrationSessionCutOverChecklists(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetEligibleMigrationSessionCutOverChecklists.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetEligibleMigrationSessionCutOverChecklists.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetEligibleMigrationSessionCutOverChecklists.Response>;

  getEligibleMigrationSessionCutOverChecklists(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetEligibleMigrationSessionCutOverChecklists.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetEligibleMigrationSessionCutOverChecklists.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetEligibleMigrationSessionCutOverChecklists',
        request,
        metadata || {},
        this.methodDescriptorGetEligibleMigrationSessionCutOverChecklists,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetEligibleMigrationSessionCutOverChecklists',
    request,
    metadata || {},
    this.methodDescriptorGetEligibleMigrationSessionCutOverChecklists);
  }

  methodDescriptorStartMigrationSessionCutOverChecklist = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/StartMigrationSessionCutOverChecklist',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.StartMigrationSessionCutOverChecklist.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.StartMigrationSessionCutOverChecklist.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.StartMigrationSessionCutOverChecklist.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.StartMigrationSessionCutOverChecklist.Response.deserializeBinary
  );

  startMigrationSessionCutOverChecklist(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.StartMigrationSessionCutOverChecklist.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.StartMigrationSessionCutOverChecklist.Response>;

  startMigrationSessionCutOverChecklist(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.StartMigrationSessionCutOverChecklist.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.StartMigrationSessionCutOverChecklist.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.StartMigrationSessionCutOverChecklist.Response>;

  startMigrationSessionCutOverChecklist(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.StartMigrationSessionCutOverChecklist.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.StartMigrationSessionCutOverChecklist.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/StartMigrationSessionCutOverChecklist',
        request,
        metadata || {},
        this.methodDescriptorStartMigrationSessionCutOverChecklist,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/StartMigrationSessionCutOverChecklist',
    request,
    metadata || {},
    this.methodDescriptorStartMigrationSessionCutOverChecklist);
  }

  methodDescriptorGetComputeMigrationSourceReadiness = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetComputeMigrationSourceReadiness',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetComputeMigrationSourceReadiness.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetComputeMigrationSourceReadiness.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetComputeMigrationSourceReadiness.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetComputeMigrationSourceReadiness.Response.deserializeBinary
  );

  getComputeMigrationSourceReadiness(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetComputeMigrationSourceReadiness.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetComputeMigrationSourceReadiness.Response>;

  getComputeMigrationSourceReadiness(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetComputeMigrationSourceReadiness.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetComputeMigrationSourceReadiness.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetComputeMigrationSourceReadiness.Response>;

  getComputeMigrationSourceReadiness(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetComputeMigrationSourceReadiness.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetComputeMigrationSourceReadiness.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetComputeMigrationSourceReadiness',
        request,
        metadata || {},
        this.methodDescriptorGetComputeMigrationSourceReadiness,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetComputeMigrationSourceReadiness',
    request,
    metadata || {},
    this.methodDescriptorGetComputeMigrationSourceReadiness);
  }

  methodDescriptorPrepareSourceForComputeMigration = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/PrepareSourceForComputeMigration',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareSourceForComputeMigration.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareSourceForComputeMigration.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareSourceForComputeMigration.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareSourceForComputeMigration.Response.deserializeBinary
  );

  prepareSourceForComputeMigration(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareSourceForComputeMigration.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareSourceForComputeMigration.Response>;

  prepareSourceForComputeMigration(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareSourceForComputeMigration.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareSourceForComputeMigration.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareSourceForComputeMigration.Response>;

  prepareSourceForComputeMigration(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareSourceForComputeMigration.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.PrepareSourceForComputeMigration.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/PrepareSourceForComputeMigration',
        request,
        metadata || {},
        this.methodDescriptorPrepareSourceForComputeMigration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/PrepareSourceForComputeMigration',
    request,
    metadata || {},
    this.methodDescriptorPrepareSourceForComputeMigration);
  }

  methodDescriptorUpdateMigrationSessionComputeSpec = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/UpdateMigrationSessionComputeSpec',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSessionComputeSpec.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSessionComputeSpec.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSessionComputeSpec.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSessionComputeSpec.Response.deserializeBinary
  );

  updateMigrationSessionComputeSpec(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSessionComputeSpec.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSessionComputeSpec.Response>;

  updateMigrationSessionComputeSpec(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSessionComputeSpec.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSessionComputeSpec.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSessionComputeSpec.Response>;

  updateMigrationSessionComputeSpec(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSessionComputeSpec.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.UpdateMigrationSessionComputeSpec.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/UpdateMigrationSessionComputeSpec',
        request,
        metadata || {},
        this.methodDescriptorUpdateMigrationSessionComputeSpec,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/UpdateMigrationSessionComputeSpec',
    request,
    metadata || {},
    this.methodDescriptorUpdateMigrationSessionComputeSpec);
  }

  methodDescriptorListVmwareHelpers = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/ListVmwareHelpers',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListVmwareHelpers.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListVmwareHelpers.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListVmwareHelpers.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListVmwareHelpers.Response.deserializeBinary
  );

  listVmwareHelpers(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListVmwareHelpers.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListVmwareHelpers.Response>;

  listVmwareHelpers(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListVmwareHelpers.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListVmwareHelpers.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListVmwareHelpers.Response>;

  listVmwareHelpers(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListVmwareHelpers.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListVmwareHelpers.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/ListVmwareHelpers',
        request,
        metadata || {},
        this.methodDescriptorListVmwareHelpers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/ListVmwareHelpers',
    request,
    metadata || {},
    this.methodDescriptorListVmwareHelpers);
  }

  methodDescriptorConfigureVmwareHelper = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/ConfigureVmwareHelper',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureVmwareHelper.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureVmwareHelper.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureVmwareHelper.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureVmwareHelper.Response.deserializeBinary
  );

  configureVmwareHelper(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureVmwareHelper.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureVmwareHelper.Response>;

  configureVmwareHelper(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureVmwareHelper.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureVmwareHelper.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureVmwareHelper.Response>;

  configureVmwareHelper(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureVmwareHelper.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureVmwareHelper.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/ConfigureVmwareHelper',
        request,
        metadata || {},
        this.methodDescriptorConfigureVmwareHelper,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/ConfigureVmwareHelper',
    request,
    metadata || {},
    this.methodDescriptorConfigureVmwareHelper);
  }

  methodDescriptorGetVmwareHelperResourceSelections = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetVmwareHelperResourceSelections',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetVmwareHelperResourceSelections.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetVmwareHelperResourceSelections.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetVmwareHelperResourceSelections.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetVmwareHelperResourceSelections.Response.deserializeBinary
  );

  getVmwareHelperResourceSelections(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetVmwareHelperResourceSelections.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetVmwareHelperResourceSelections.Response>;

  getVmwareHelperResourceSelections(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetVmwareHelperResourceSelections.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetVmwareHelperResourceSelections.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetVmwareHelperResourceSelections.Response>;

  getVmwareHelperResourceSelections(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetVmwareHelperResourceSelections.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetVmwareHelperResourceSelections.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetVmwareHelperResourceSelections',
        request,
        metadata || {},
        this.methodDescriptorGetVmwareHelperResourceSelections,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetVmwareHelperResourceSelections',
    request,
    metadata || {},
    this.methodDescriptorGetVmwareHelperResourceSelections);
  }

  methodDescriptorAllocateDisksToVmwareHelper = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/AllocateDisksToVmwareHelper',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateDisksToVmwareHelper.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateDisksToVmwareHelper.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateDisksToVmwareHelper.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateDisksToVmwareHelper.Response.deserializeBinary
  );

  allocateDisksToVmwareHelper(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateDisksToVmwareHelper.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateDisksToVmwareHelper.Response>;

  allocateDisksToVmwareHelper(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateDisksToVmwareHelper.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateDisksToVmwareHelper.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateDisksToVmwareHelper.Response>;

  allocateDisksToVmwareHelper(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateDisksToVmwareHelper.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.AllocateDisksToVmwareHelper.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/AllocateDisksToVmwareHelper',
        request,
        metadata || {},
        this.methodDescriptorAllocateDisksToVmwareHelper,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/AllocateDisksToVmwareHelper',
    request,
    metadata || {},
    this.methodDescriptorAllocateDisksToVmwareHelper);
  }

  methodDescriptorListAzureHelpers = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/ListAzureHelpers',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureHelpers.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureHelpers.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureHelpers.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureHelpers.Response.deserializeBinary
  );

  listAzureHelpers(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureHelpers.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureHelpers.Response>;

  listAzureHelpers(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureHelpers.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureHelpers.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureHelpers.Response>;

  listAzureHelpers(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureHelpers.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureHelpers.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/ListAzureHelpers',
        request,
        metadata || {},
        this.methodDescriptorListAzureHelpers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/ListAzureHelpers',
    request,
    metadata || {},
    this.methodDescriptorListAzureHelpers);
  }

  methodDescriptorConfigureAzureHelper = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/ConfigureAzureHelper',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureAzureHelper.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureAzureHelper.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureAzureHelper.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureAzureHelper.Response.deserializeBinary
  );

  configureAzureHelper(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureAzureHelper.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureAzureHelper.Response>;

  configureAzureHelper(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureAzureHelper.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureAzureHelper.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureAzureHelper.Response>;

  configureAzureHelper(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureAzureHelper.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ConfigureAzureHelper.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/ConfigureAzureHelper',
        request,
        metadata || {},
        this.methodDescriptorConfigureAzureHelper,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/ConfigureAzureHelper',
    request,
    metadata || {},
    this.methodDescriptorConfigureAzureHelper);
  }

  methodDescriptorGetAzureHelperResourceSelections = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetAzureHelperResourceSelections',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureHelperResourceSelections.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureHelperResourceSelections.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureHelperResourceSelections.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureHelperResourceSelections.Response.deserializeBinary
  );

  getAzureHelperResourceSelections(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureHelperResourceSelections.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureHelperResourceSelections.Response>;

  getAzureHelperResourceSelections(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureHelperResourceSelections.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureHelperResourceSelections.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureHelperResourceSelections.Response>;

  getAzureHelperResourceSelections(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureHelperResourceSelections.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureHelperResourceSelections.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetAzureHelperResourceSelections',
        request,
        metadata || {},
        this.methodDescriptorGetAzureHelperResourceSelections,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetAzureHelperResourceSelections',
    request,
    metadata || {},
    this.methodDescriptorGetAzureHelperResourceSelections);
  }

  methodDescriptorGetIOStats = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetIOStats',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStats.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStats.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStats.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStats.Response.deserializeBinary
  );

  getIOStats(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStats.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStats.Response>;

  getIOStats(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStats.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStats.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStats.Response>;

  getIOStats(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStats.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStats.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetIOStats',
        request,
        metadata || {},
        this.methodDescriptorGetIOStats,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetIOStats',
    request,
    metadata || {},
    this.methodDescriptorGetIOStats);
  }

  methodDescriptorGetIOStatsLive = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetIOStatsLive',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStatsLive.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStatsLive.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStatsLive.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStatsLive.Response.deserializeBinary
  );

  getIOStatsLive(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStatsLive.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStatsLive.Response>;

  getIOStatsLive(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStatsLive.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStatsLive.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStatsLive.Response>;

  getIOStatsLive(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStatsLive.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetIOStatsLive.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetIOStatsLive',
        request,
        metadata || {},
        this.methodDescriptorGetIOStatsLive,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetIOStatsLive',
    request,
    metadata || {},
    this.methodDescriptorGetIOStatsLive);
  }

  methodDescriptorListAzureProducts = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/ListAzureProducts',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureProducts.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureProducts.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureProducts.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureProducts.Response.deserializeBinary
  );

  listAzureProducts(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureProducts.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureProducts.Response>;

  listAzureProducts(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureProducts.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureProducts.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureProducts.Response>;

  listAzureProducts(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureProducts.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.ListAzureProducts.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/ListAzureProducts',
        request,
        metadata || {},
        this.methodDescriptorListAzureProducts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/ListAzureProducts',
    request,
    metadata || {},
    this.methodDescriptorListAzureProducts);
  }

  methodDescriptorGetAzureRecommendation = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetAzureRecommendation',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureRecommendation.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureRecommendation.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureRecommendation.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureRecommendation.Response.deserializeBinary
  );

  getAzureRecommendation(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureRecommendation.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureRecommendation.Response>;

  getAzureRecommendation(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureRecommendation.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureRecommendation.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureRecommendation.Response>;

  getAzureRecommendation(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureRecommendation.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzureRecommendation.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetAzureRecommendation',
        request,
        metadata || {},
        this.methodDescriptorGetAzureRecommendation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetAzureRecommendation',
    request,
    metadata || {},
    this.methodDescriptorGetAzureRecommendation);
  }

  methodDescriptorGetAzurePrice = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.gm.GalaxyMigrateService/GetAzurePrice',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzurePrice.Request,
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzurePrice.Response,
    (request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzurePrice.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzurePrice.Response.deserializeBinary
  );

  getAzurePrice(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzurePrice.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzurePrice.Response>;

  getAzurePrice(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzurePrice.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzurePrice.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzurePrice.Response>;

  getAzurePrice(
    request: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzurePrice.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_gmapipb_galaxymigrate_api_pb.GetAzurePrice.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.gm.GalaxyMigrateService/GetAzurePrice',
        request,
        metadata || {},
        this.methodDescriptorGetAzurePrice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.gm.GalaxyMigrateService/GetAzurePrice',
    request,
    metadata || {},
    this.methodDescriptorGetAzurePrice);
  }

}

