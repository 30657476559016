// ======================
// GalaxyMigrateDeploymentLiveLog
// ======================

import React from "react";
import {observer} from "mobx-react-lite";
import {useAppServices} from "../../app/services";
import {useParams} from "react-router-dom";
import {useNavigateToDeploymentDetails} from "../../galaxymigrate/GalaxyMigrateCommon";
import {renderServerDataWithLoadingBox, useInitData} from "../../core/data/DataLoaderHooks";
import {TabConfig, TabGroup} from "../../../common/tabs/TabComponents";
import {ViewLogsRequest} from "../../../_proto/galaxymigratepb/galaxy_migrate_support_pb";
import {ScreenContainer, ScreenTitleBar} from "../../layout/ScreenCommon";
import {BackButton} from "../../../common/CommonButtons";
import {LogSection} from "../../galaxymigrate/details/GalaxyMigrateDeploymentLiveLog";
import {useNavigateToCpDeploymentDetails} from "../ProjectProtectionCommon";

interface CirrusProtectDeploymentLiveLogProps {
}

export const CirrusProtectDeploymentLiveLog: React.FC<CirrusProtectDeploymentLiveLogProps> = observer((p) => {
    const {gmDeploymentService, cpDeploymentService} = useAppServices();
    const {deploymentId} = useParams();
    const goBackToDetails = useNavigateToCpDeploymentDetails(deploymentId);

    useInitData({
        init: async () => {
            if (!cpDeploymentService.currentDeploymentId) {
                await cpDeploymentService.cpDeploymentDetails.fetchData(deploymentId)

            }
        }
    });

    return renderServerDataWithLoadingBox(cpDeploymentService.cpDeploymentDetails, data => {
        const deployment = data.getDeployment();
        const tabConfigs: TabConfig[] = [
            {
                label: 'Application Log',
                renderer: () => <LogSection deploymentId={deploymentId}
                                            source={ViewLogsRequest.LogSource.GALAXY_MIGRATE}/>
            },
            {
                label: 'System Log',
                renderer: () => <LogSection deploymentId={deploymentId} source={ViewLogsRequest.LogSource.SYSLOG}/>
            },
            {
                label: 'MTDI Log',
                renderer: () => <LogSection deploymentId={deploymentId} source={ViewLogsRequest.LogSource.MTDI}/>
            }
        ]

        return <ScreenContainer>
            <BackButton navFunction={goBackToDetails} label={'Back To Host Details'}/>
            <ScreenTitleBar title={deployment.getSystemName()}/>
            <br/>
            <TabGroup configs={tabConfigs}
                      border
                      onChange={() => gmDeploymentService.deploymentLogs.resetData()}
                      tabProps={{
                          sx: {
                              width: 200
                          }
                      }}/>
        </ScreenContainer>
    })

});