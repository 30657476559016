import { create } from "zustand";
import { useAppServices } from "../../app/services";
import { getIsPrivateEdition } from "../../auth/PrivateEditionView";
import { AppHintID, HELP_CENTER_URL, KnownArticle, navigateToHelpCenter } from "../HelpCommon";

interface HelpDrawerState {
    helpDrawerOpen: boolean;
    helpCenterUrl: string;
    setHelpDrawerOpen: (open: boolean) => void;
    setHelpCenterUrl: (url: string) => void;
}

export const useHelpDrawerState = create<HelpDrawerState>((set) => ({
    helpDrawerOpen: false,
    helpCenterUrl: "",
    setHelpDrawerOpen: (open: boolean) =>
        set({
            helpDrawerOpen: open,
        }),
    setHelpCenterUrl: (url: string) =>
        set({
            helpCenterUrl: url,
        }),
}));

export const useOpenHelpArticle = () => {
    const { helpService, dialogService } = useAppServices();
    const helpDrawerState = useHelpDrawerState();
    const isPrivateEdition = getIsPrivateEdition();

    return async (articleSlug: KnownArticle) => {
        if (isPrivateEdition) {
            const confirmed = await dialogService.addConfirmDialog({
                title: "Open in Browser",
                message: `${HELP_CENTER_URL}/cdc/kb/articles/${articleSlug}`,
                autoConfirmationQuestionLine: false,
            });
            if (confirmed) {
                navigateToHelpCenter(articleSlug);
            }
        } else {
            const redirectString = `kb/articles/${articleSlug}?from=cmc`;
            helpDrawerState.setHelpCenterUrl(helpService.getCustomerCenterAuthCallbackUrl(redirectString));
            helpDrawerState.setHelpDrawerOpen(true);
        }
    };
};

export const useOpenHelpSearch = () => {
    const { helpService, dialogService } = useAppServices();
    const helpDrawerState = useHelpDrawerState();
    const isPrivateEdition = getIsPrivateEdition();

    return async (searchTerm: AppHintID) => {
        if (isPrivateEdition) {
            const confirmed = await dialogService.addConfirmDialog({
                title: "Open in Browser",
                message: `${HELP_CENTER_URL}/kb/search?q=${searchTerm}`,
                autoConfirmationQuestionLine: false,
            });
            if (confirmed) {
                navigateToHelpCenter(searchTerm);
            }
        } else {
            const redirectString = `kb/search?q=${searchTerm}&from=cmc`;
            helpDrawerState.setHelpCenterUrl(helpService.getCustomerCenterAuthCallbackUrl(redirectString));
            helpDrawerState.setHelpDrawerOpen(true);
        }
    };
};
