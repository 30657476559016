// ======================
// ExportLicenseButton
// ======================

import { TransactionCounterInfo, VaultCounterInfo, VaultDetails } from "../../_proto/galaxycompletepb/apipb/domainpb/license_pb";
import * as React from "react";
import { ChangeEvent, useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { useAppServices } from "../app/services";
import { DialogState, useDialogState } from "../core/dialog/DialogService";
import { renderServerDataWithLoadingBox, useInitData } from "../core/data/DataLoaderHooks";
import {
    Alert,
    alpha,
    Avatar,
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    SvgIcon,
    Switch,
    TextField,
    Theme,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from "@mui/material";
import { DialogTopBar } from "../core/dialog/DialogComponents";
import { FaAngleDoubleRight } from "react-icons/fa";
import { HubUser } from "../../_proto/galaxycompletepb/apipb/domainpb/user_pb";
import { getUserFullNameFromObject, UserAvatar } from "../settings/ProjectUsers";
import { ProjectIcon } from "../project/ProjectCommon";
import { CloseIcon, EditIcon, LicenseIcon } from "../../common/CommonIcons";
import { getCounterTypeDisplayValue, getLicenseValueFormat, getTransactionCounterTypeDef, LicenseVaultCounterUnitType } from "./LicenseCommon";
import { LightDivider } from "../../common/misc";
import { LicenseVaultCounterType } from "../../_proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";
import xbytes from "xbytes";
import { useIsAdminView } from "../auth/AuthenticatedViews";
import { AppHintID, HintButton } from "../help/HelpCommon";
import { PagerParams } from "../../_proto/galaxycompletepb/commonpb/datafilter_pb";
import { useCurrentProjectID } from "../project/CurrentProject";
import { useIsSupportUser } from "../support/SupportCommon";

interface ExportLicenseButtonProps {
    vaultDetails: VaultDetails.AsObject;
}

export const ExportLicenseButton: React.FC<ExportLicenseButtonProps> = observer((p) => {
    const { licenseService } = useAppServices();
    const exportLicenseDialogState = useDialogState();

    const isUserLicense = !!p.vaultDetails.identity.user;
    const isSupportUser = useIsSupportUser();

    useInitData({
        init: () => licenseService.userLicenseVaultDetails.fetchData(),
    });

    const hasAnyRemainingBalance = !!p.vaultDetails.itemsList.find((counter) => counter.currentBalance > 0);

    return (
        <>
            {isUserLicense && (
                <Button variant={"contained"} disabled={!hasAnyRemainingBalance} color={"secondary"} onClick={exportLicenseDialogState.open}>
                    Export to Project
                </Button>
            )}
            {isSupportUser && !isUserLicense && (
                <Button variant={"contained"} disabled={!hasAnyRemainingBalance} color={"secondary"} onClick={exportLicenseDialogState.open}>
                    Export To My User Vault
                </Button>
            )}
            {exportLicenseDialogState.isOpen &&
                renderServerDataWithLoadingBox(licenseService.userLicenseVaultDetails, (data) => {
                    return (
                        <ExportLicenseDialog
                            currentVaultDetails={p.vaultDetails}
                            userLicenseVaultDetails={data}
                            dialogState={exportLicenseDialogState}
                            isSourceUserLicense={isUserLicense}
                        />
                    );
                })}
        </>
    );
});

// ======================
// ExportLicenseDialog
// ======================

interface ExportLicenseDialogProps {
    currentVaultDetails: VaultDetails.AsObject;
    userLicenseVaultDetails: VaultDetails.AsObject;
    dialogState: DialogState;
    isSourceUserLicense: boolean;
}

interface SelectedLicenseType {
    counterType: LicenseVaultCounterType.LicenseVaultCounterType;
    amount: number;
    displayAmount: number;
    remainingBalance: number;
}

const ExportLicenseDialog: React.FC<ExportLicenseDialogProps> = observer((p) => {
    const { licenseService } = useAppServices();
    const [selectedTarget, setSelectedTarget] = useState(p.isSourceUserLicense ? null : p.userLicenseVaultDetails);
    const [description, setDescription] = useState("");
    const [licenseTypeList, setLicenseTypeList] = useState<SelectedLicenseType[]>([] as SelectedLicenseType[]);
    const [exportAllRemainingBalance, setExportAllRemainingBalance] = useState(false);

    const handleExportAllRemainingBalance = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setExportAllRemainingBalance(e.target.checked);
            if (e.target.checked) {
                for (let counter of p.currentVaultDetails.itemsList) {
                    if (counter.currentBalance > 0) {
                        let list = licenseTypeList;
                        const selectedLicenseType: SelectedLicenseType = {
                            counterType: counter.counterType.value,
                            amount: counter.currentBalance,
                            displayAmount: counter.currentBalance,
                            remainingBalance: counter.currentBalance,
                        };
                        list.push(selectedLicenseType);
                        setLicenseTypeList([...list]);
                    }
                }
            } else {
                setLicenseTypeList([]);
            }
        },
        [setExportAllRemainingBalance, p.currentVaultDetails, setLicenseTypeList, licenseTypeList]
    );

    const handleSelectTarget = useCallback(
        async (projectId: string) => {
            const projectLicenseVaultDetails = await licenseService.fetchProjectLicenseDetails(projectId);
            setSelectedTarget(projectLicenseVaultDetails);
        },
        [licenseService, setSelectedTarget]
    );

    const handleSelectLicenseType = useCallback(
        (vaultCounterInfo: VaultCounterInfo.AsObject) => {
            let list = licenseTypeList;
            const selectedLicenseType: SelectedLicenseType = {
                counterType: vaultCounterInfo.counterType.value,
                amount: 0,
                displayAmount: 0,
                remainingBalance: vaultCounterInfo.currentBalance,
            };
            list.push(selectedLicenseType);
            setLicenseTypeList([...list]);
        },
        [licenseTypeList, setLicenseTypeList]
    );

    const handleChangeLicenseTypeAmount = useCallback(
        (amount: number, displayAmount: number, licenseTypeIndex: number) => {
            let list = licenseTypeList;
            list[licenseTypeIndex].amount = amount;
            list[licenseTypeIndex].displayAmount = displayAmount;
            setLicenseTypeList([...list]);
        },
        [setLicenseTypeList, licenseTypeList]
    );

    const handleDeleteLicenseType = useCallback(
        (licenseTypeIndex: number) => {
            let list = licenseTypeList;
            list.splice(licenseTypeIndex, 1);
            setLicenseTypeList([...list]);
        },
        [licenseTypeList, setLicenseTypeList]
    );

    const exportLicense = async () => {
        const countersList = licenseTypeList.map((l) => {
            return new TransactionCounterInfo().setCounterType(new LicenseVaultCounterType().setValue(l.counterType)).setAmount(l.amount);
        });
        if (p.isSourceUserLicense) {
            await licenseService.exportLicenseToProject(selectedTarget.identity.project.projectId, description, countersList);
            await licenseService.userLicenseVaultDetails.fetchData();
            await licenseService.userLicenseTransactions.fetchData();
            await licenseService.projectLicenseDetails.fetchData(selectedTarget.identity.project.projectId);
        } else {
            await licenseService.exportLicenseToUserVault(p.currentVaultDetails.identity.project.projectId, description, countersList);
            await licenseService.projectLicenseDetails.fetchData();
            await licenseService.projectLicenseTransactions.fetchData();
        }

        p.dialogState.close();
    };

    return (
        <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} fullWidth maxWidth={"md"}>
            <DialogTopBar dialogState={p.dialogState} title={"Export License"} actions={[<HintButton hintID={AppHintID.LICENSE_KEY} />]} divider />
            <DialogContent>
                <Typography variant={"h6"}>1. Export Target</Typography>
                <br />
                <Grid container spacing={2} alignItems={"center"} pb={1}>
                    <Grid item xs={5}>
                        <Box>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                From:
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5}>
                        <Box>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                To:
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={5}>
                        <Box>
                            <LicenseCard vaultDetails={p.currentVaultDetails} />
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Box display={"flex"} justifyContent={"center"}>
                            <SvgIcon>
                                <FaAngleDoubleRight />
                            </SvgIcon>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box>
                            {p.isSourceUserLicense ? (
                                <SelectTargetLicenseMenu selectedTarget={selectedTarget} handleSelect={handleSelectTarget} />
                            ) : (
                                <LicenseCard vaultDetails={p.userLicenseVaultDetails} />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogContent>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant={"h6"}>2. Export License Type</Typography>
                    {!!p.currentVaultDetails.itemsList.length && (
                        <FormControlLabel
                            labelPlacement={"start"}
                            componentsProps={{
                                typography: {
                                    color: "textSecondary",
                                    variant: "body2",
                                },
                            }}
                            control={<Switch color={"secondary"} checked={exportAllRemainingBalance} onChange={handleExportAllRemainingBalance} />}
                            label={"Export All License Remaining"}
                        />
                    )}
                </Box>

                <SelectLicenseTypeSection
                    vaultDetails={p.currentVaultDetails}
                    selectedLicenseTypes={licenseTypeList}
                    handleChangeLicenseTypeAmount={handleChangeLicenseTypeAmount}
                    handleDeleteLicenseType={handleDeleteLicenseType}
                    handleSelectLicenseType={handleSelectLicenseType}
                    exportAllRemainingBalance={exportAllRemainingBalance}
                />
            </DialogContent>
            <Divider />
            <DialogContent>
                <Typography variant={"h6"}>3. Transaction Description (Optional)</Typography>
                <Box pt={2}>
                    <TextField label={"Description"} onChange={(e) => setDescription(e.target.value)} variant={"filled"} fullWidth />
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <Box>
                    <Button variant={"outlined"} color={"neutral"} onClick={p.dialogState.close}>
                        Cancel
                    </Button>
                </Box>
                <Box>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        disabled={getLicenseExportDisabled(selectedTarget, licenseTypeList)}
                        onClick={exportLicense}
                    >
                        Export
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
});

// ======================
// LicenseCard
// ======================

interface LicenseCardProps {
    vaultDetails: VaultDetails.AsObject;
    selected?: boolean;
    editSelection?: () => void;
}

const LicenseCard: React.FC<LicenseCardProps> = observer((p) => {
    const isUserLicense = !!p.vaultDetails.identity.user;
    return (
        <Card
            variant={"outlined"}
            sx={{
                borderColor: (t: Theme) => (p.selected ? t.palette.primary.main : t.palette.cirrus.main),
            }}
        >
            <Box p={2} display={"flex"} alignItems={"center"}>
                {isUserLicense && (
                    <Box pr={2}>
                        <UserAvatar user={p.vaultDetails.identity.user} tooltip />
                    </Box>
                )}
                <Box flexGrow={1}>
                    <Typography variant={"body1"}>
                        {isUserLicense ? getUserFullNameFromObject(p.vaultDetails.identity.user) : p.vaultDetails.identity.project.name}
                    </Typography>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        {p.vaultDetails.identity.vaultSerial}
                    </Typography>
                </Box>
                {!!p.editSelection && (
                    <Tooltip title={"Change Projects"}>
                        <IconButton onClick={p.editSelection}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Card>
    );
});

// ======================
// SelectTargetLicenseButton
// ======================

interface SelectTargetLicenseMenuProps {
    handleSelect: (projectId: string) => Promise<void>;
    selectedTarget: VaultDetails.AsObject;
}

const SelectTargetLicenseMenu: React.FC<SelectTargetLicenseMenuProps> = observer((p) => {
    const selectTargetLicenseDialogState = useDialogState();

    return (
        <>
            {!!p.selectedTarget ? (
                <LicenseCard vaultDetails={p.selectedTarget} selected editSelection={selectTargetLicenseDialogState.open} />
            ) : (
                <Button variant={"contained"} color={"secondary"} onClick={selectTargetLicenseDialogState.open}>
                    Select Project
                </Button>
            )}
            {selectTargetLicenseDialogState.isOpen && <SelectTargetLicenseDialog dialogState={selectTargetLicenseDialogState} handleSelect={p.handleSelect} />}
        </>
    );
});

// ======================
// SelectTargetLicenseDialog
// ======================

interface SelectTargetLicenseDialogProps {
    dialogState: DialogState;
    handleSelect: (projectId: string) => Promise<void>;
}

const SelectTargetLicenseDialog: React.FC<SelectTargetLicenseDialogProps> = observer((p) => {
    const { projectService } = useAppServices();

    useInitData({
        poll: () => projectService.myProjects.fetchData(true, new PagerParams().setPerPage(100000000)),
        pollInterval: 30,
    });

    return renderServerDataWithLoadingBox(projectService.myProjects, (data) => {
        return (
            <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} fullWidth maxWidth={"sm"}>
                <List>
                    <ListSubheader>Select Export Target</ListSubheader>
                    <Box p={2}>
                        <Alert severity={"info"}>Only projects with admin access can be selected.</Alert>
                    </Box>
                    <Box
                        sx={{
                            maxHeight: "50vh",
                            overflowY: "auto",
                        }}
                    >
                        {data.itemsList.map((project, i) => {
                            return (
                                <ListItem
                                    key={i}
                                    button
                                    onClick={async () => {
                                        await p.handleSelect(project.projectInfo.projectId);
                                        p.dialogState.close();
                                    }}
                                >
                                    <ListItemIcon>
                                        <ProjectIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={project.projectInfo.name} secondary={project.projectInfo.description} />
                                </ListItem>
                            );
                        })}
                    </Box>
                </List>
            </Dialog>
        );
    });
});

// ======================
// SelectLicenseTypeMenu
// ======================

interface SelectLicenseTypeSectionProps {
    selectedLicenseTypes: SelectedLicenseType[];
    handleSelectLicenseType: (counterInfo: VaultCounterInfo.AsObject) => void;
    handleChangeLicenseTypeAmount: (amount: number, displayAmount: number, licenseTypeIndex: number) => void;
    handleDeleteLicenseType: (licenseTypeIndex: number) => void;
    vaultDetails: VaultDetails.AsObject;
    exportAllRemainingBalance: boolean;
}

const SelectLicenseTypeSection: React.FC<SelectLicenseTypeSectionProps> = observer((p) => {
    const selectLicenseTypeDialogState = useDialogState();
    if (p.exportAllRemainingBalance) {
        return (
            <Box pt={2}>
                {p.selectedLicenseTypes.map((selectedLicenseType: SelectedLicenseType, i) => {
                    return <RemainingLicenseBalanceAmountCard key={i} selectedLicenseInfo={selectedLicenseType} />;
                })}
            </Box>
        );
    }
    return (
        <Box pt={2}>
            {p.selectedLicenseTypes.map((selectedLicenseType: SelectedLicenseType, i) => {
                return (
                    <SelectedLicenseAmountField
                        handleDeleteLicenseType={p.handleDeleteLicenseType}
                        selectedLicenseType={selectedLicenseType}
                        index={i}
                        key={i}
                        handleChangeLicenseTypeAmount={p.handleChangeLicenseTypeAmount}
                    />
                );
            })}
            {selectLicenseTypeDialogState.isOpen && (
                <SelectLicenseTypeDialog
                    selectedLicenseTypes={p.selectedLicenseTypes}
                    handleSelectLicenseType={p.handleSelectLicenseType}
                    vaultDetails={p.vaultDetails}
                    dialogState={selectLicenseTypeDialogState}
                />
            )}
            <Box>
                <Button variant={"outlined"} color={"secondary"} onClick={selectLicenseTypeDialogState.open}>
                    Add License Type
                </Button>
            </Box>
        </Box>
    );
});

// ======================
// RemainingLicenseBalanceAmountCard
// ======================

interface RemainingLicenseBalanceAmountCardProps {
    selectedLicenseInfo: SelectedLicenseType;
}

const RemainingLicenseBalanceAmountCard: React.FC<RemainingLicenseBalanceAmountCardProps> = observer((p) => {
    return (
        <Box pb={2}>
            <Card sx={{ backgroundColor: (t: Theme) => t.palette.cirrus.main }}>
                <Box p={2}>
                    <Typography variant={"h6"}>{getCounterTypeDisplayValue(p.selectedLicenseInfo.counterType)}</Typography>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        Available Balance: {getLicenseValueFormat(p.selectedLicenseInfo.remainingBalance, p.selectedLicenseInfo.counterType)}
                    </Typography>
                </Box>
            </Card>
        </Box>
    );
});

// ======================
// SelectedLicenseAmountField
// ======================

interface SelectedLicenseAmountFieldProps {
    selectedLicenseType: SelectedLicenseType;
    handleDeleteLicenseType: (licenseTypeIndex: number) => void;
    handleChangeLicenseTypeAmount: (amount: number, displayAmount: number, licenseTypeIndex: number) => void;
    index: number;
}

const SelectedLicenseAmountField: React.FC<SelectedLicenseAmountFieldProps> = observer((p) => {
    const counterTypeDef = getTransactionCounterTypeDef(p.selectedLicenseType.counterType);

    const [selectedUnit, setSelectedUnit] = useState(counterTypeDef.unitType === LicenseVaultCounterUnitType.CAPACITY ? "GiB" : null);
    const [currentDisplayAmount, setCurrentDisplayAmount] = useState(p.selectedLicenseType.amount);
    const [error, setError] = useState(null);

    const handleSelectUnit = useCallback(
        (event: React.MouseEvent<HTMLElement>, newUnit: string) => {
            if (newUnit !== null) {
                setSelectedUnit(newUnit);
                const newAmount = xbytes.parseSize(`${currentDisplayAmount}${newUnit}`);
                setError(getAmountError(newAmount, currentDisplayAmount, p.selectedLicenseType.remainingBalance));
                p.handleChangeLicenseTypeAmount(newAmount, currentDisplayAmount, p.index);
            }
        },
        [setSelectedUnit, setError, p, currentDisplayAmount]
    );

    const handleChangeAmount = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            let displayAmount = parseFloat(e.target.value);

            let convertedAmount = parseInt(e.target.value);
            if (counterTypeDef.unitType === LicenseVaultCounterUnitType.CAPACITY) {
                convertedAmount = xbytes.parseSize(`${displayAmount}${selectedUnit}`);
            }
            setCurrentDisplayAmount(displayAmount);
            setError(getAmountError(convertedAmount, displayAmount, p.selectedLicenseType.remainingBalance));
            p.handleChangeLicenseTypeAmount(convertedAmount, displayAmount, p.index);
        },
        [setCurrentDisplayAmount, setError, p, selectedUnit, counterTypeDef.unitType]
    );

    return (
        <Box pb={2}>
            <Card sx={{ backgroundColor: (t: Theme) => t.palette.cirrus.main }}>
                <Box p={2} display={"flex"} justifyContent={"space-between"}>
                    <Box>
                        <Typography>{counterTypeDef.name}</Typography>
                        <Typography variant={"body2"} color={"textSecondary"}>
                            Available Balance: {counterTypeDef.getValueFormat(p.selectedLicenseType.remainingBalance)}
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={() => p.handleDeleteLicenseType(p.index)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <LightDivider />
                <Box p={2} display={"flex"}>
                    <TextField label={"Export Amount"} type={"number"} fullWidth onChange={handleChangeAmount} error={!!error} helperText={error} />
                    {counterTypeDef.unitType === LicenseVaultCounterUnitType.CAPACITY && (
                        <ToggleButtonGroup
                            value={selectedUnit}
                            exclusive
                            onChange={handleSelectUnit}
                            sx={{
                                paddingLeft: 2,
                                "& .MuiToggleButtonGroup-grouped": {
                                    borderColor: alpha("#fff", 0.3),
                                },
                            }}
                        >
                            {counterTypeDef.unitOptions.map((o, i) => {
                                return (
                                    <ToggleButton value={o} key={i}>
                                        {o}
                                    </ToggleButton>
                                );
                            })}
                        </ToggleButtonGroup>
                    )}
                </Box>
            </Card>
        </Box>
    );
});

// ======================
// SelectLicenseTypeDialog
// ======================

interface SelectLicenseTypeDialogProps {
    selectedLicenseTypes: SelectedLicenseType[];
    dialogState: DialogState;
    vaultDetails: VaultDetails.AsObject;
    handleSelectLicenseType: (counterInfo: VaultCounterInfo.AsObject) => void;
}

const SelectLicenseTypeDialog: React.FC<SelectLicenseTypeDialogProps> = observer((p) => {
    const options = p.vaultDetails.itemsList.filter((c) => !p.selectedLicenseTypes.find((s) => s.counterType === c.counterType.value));

    return (
        <Dialog fullWidth maxWidth={"sm"} open={p.dialogState.isOpen} onClose={p.dialogState.close}>
            <List>
                <ListSubheader>Select License Type</ListSubheader>
                {options.length === 0 && (
                    <Box width={"100%"} display={"flex"} justifyContent={"center"} p={2}>
                        <Typography variant={"body2"} color={"textSecondary"}>
                            No available options.
                        </Typography>
                    </Box>
                )}
                {options.map((counterInfo, i) => {
                    const counterTypeDef = getTransactionCounterTypeDef(counterInfo.counterType.value);
                    return (
                        <ListItem
                            button
                            key={i}
                            onClick={() => {
                                p.handleSelectLicenseType(counterInfo);
                                p.dialogState.close();
                            }}
                        >
                            <ListItemIcon>
                                <LicenseIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={counterTypeDef.name}
                                secondary={`Available Balance: ${counterTypeDef.getValueFormat(counterInfo.currentBalance)}`}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Dialog>
    );
});

const getAmountError = (amount: any, displayAmount: any, availableBalance: number) => {
    if (isNaN(displayAmount) || !Number.isInteger(displayAmount) || displayAmount <= 0) {
        return "Amount must be a positive integer.";
    } else if (amount > availableBalance) {
        return "Amount cannot exceed available balance.";
    } else {
        return null;
    }
};

const getLicenseExportDisabled = (selectedTarget: VaultDetails.AsObject, licenseTypeList: Array<SelectedLicenseType>) => {
    if (!selectedTarget) {
        return true;
    } else if (licenseTypeList.length === 0) {
        return true;
    } else if (!!licenseTypeList.find((l) => !!getAmountError(l.amount, l.displayAmount, l.remainingBalance))) {
        return true;
    } else {
        return false;
    }
};
