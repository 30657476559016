import {observer} from "mobx-react-lite";
import React from "react";
import {useAppServices} from "../../app/services";
import {renderServerDataWithLoadingBox, useInitData} from "../../core/data/DataLoaderHooks";
import {useParams} from "react-router-dom";
import {ScreenContainer, ScreenTitleBar} from "../../layout/ScreenCommon";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    Divider,
    Grid,
    Link,
    ListItem,
    ListItemText,
    ListSubheader,
    SvgIcon,
    Theme,
    Typography
} from "@mui/material";
import {DialogState, useDialogState} from "../../core/dialog/DialogService";
import {PROTECTION_HOST_DETAILS_SUBROUTE} from "../../app/AppRoutes";
import {CirrusProtectDeploymentInfo} from "../../../_proto/galaxycompletepb/apipb/domainpb/cirrusprotect_pb";
import {RouterTabConfig, RouterTabs} from "../../../common/tabs/TabComponents";
import {
    generateCirrusProtectDeploymentDetailsPath,
    useNavigateToCdmPolicyWizard,
    useNavigateToCpDeploymentDetails,
    useNavigateToCpDeploymentDetailsSubPath, useNavigateToCpDeploymentsList,
    useNavigateToPhoenixDeploymentDetails
} from "../ProjectProtectionCommon";
import {formatKnownDataType, KnownDataType} from "../../../common/utils/formatter";
import {CirrusProtectDeploymentSettingsSection} from "./CirrusProtectDeploymentDetailsSettings";
import {
    CpDeploymentConnectToPhoenixButton,
    CpDeploymentConnectToPhoenixDialog
} from "./CirrusProtectDeploymentConnectToPhoenix";
import {BiShieldQuarter, BiTargetLock} from "react-icons/bi";
import {CirrusProtectDeploymentDetailsPolicyScreen} from "./CirrusProtectDeploymentDetailsPolicy";
import {ActionConfig, ActionMenuButton} from "../../../common/actions/CommonActions";
import {MdDescription} from "react-icons/md";
import {DeleteIcon} from "../../../common/CommonIcons";
import {OperatorView} from "../../auth/AuthenticatedViews";
import {MigrationIcon} from "../../project/ProjectCommon";
import {renderOfflineDeploymentMessage} from "./CirrusProtectDeploymentCommon";

// ======================
// CirrusProtectDeploymentDetailsScreen
// ======================

interface CirrusProtectDeploymentDetailsScreenProps {
}

export const CirrusProtectDeploymentDetailsScreen: React.FC<CirrusProtectDeploymentDetailsScreenProps> = observer((p) => {
    const {cpDeploymentService, dialogService} = useAppServices();
    const {deploymentId, projectId} = useParams();
    const connectToPhoenixDialogState = useDialogState();
    const currentPath = generateCirrusProtectDeploymentDetailsPath(deploymentId, projectId);
    const goToLogPage = useNavigateToCpDeploymentDetailsSubPath(deploymentId, PROTECTION_HOST_DETAILS_SUBROUTE.LOG)
    const navigateBackToList = useNavigateToCpDeploymentsList();

    useInitData({
        poll: () => cpDeploymentService.cpDeploymentDetails.fetchData(deploymentId),
        pollInterval: 3,
        deinit: () => cpDeploymentService.cpDeploymentDetails.resetData()
    });

    const getActions = (): ActionConfig[] => ([
        {
            name: 'View Live Log',
            id: 'log',
            action: goToLogPage,
            icon: <SvgIcon>
                <MdDescription/>
            </SvgIcon>,
            hidden: true

        },
        {
            name: 'Remove Host From Project',
            id: 'remove',
            action: async () => {
                const confirmed = await dialogService.addConfirmDialog({
                    message:'Are you sure you want to remove this host from the project?',
                    autoConfirmationQuestionLine: false
                })
                if (confirmed){
                    const removed = await cpDeploymentService.removeCpDeployment(deploymentId)
                    if (removed) {
                        navigateBackToList()
                    }
                }

            },
            icon: <DeleteIcon/>
        },

    ]);

    const actions = <Box display={'flex'} alignItems={'center'}>
        <OperatorView>
            <ActionMenuButton actions={getActions()}/>
        </OperatorView>
    </Box>


    return renderServerDataWithLoadingBox(cpDeploymentService.cpDeploymentDetails, data => {

        const configs: RouterTabConfig[] = [
            {
                path: '',
                label: 'Host Info',
                renderer: () => <CirrusProtectDeploymentInfoSection data={data} dialogState={connectToPhoenixDialogState}/>,
                default: true
            },
            {
                path: PROTECTION_HOST_DETAILS_SUBROUTE.POLICY_INFO,
                label: 'Protection Policy',
                renderer: () => <CirrusProtectDeploymentDetailsPolicyScreen/>
            },
            {
                path: PROTECTION_HOST_DETAILS_SUBROUTE.SETTINGS,
                label: 'Settings',
                renderer: () => <CirrusProtectDeploymentSettingsSection data={data}/>,
                //disabled if no settings to configure
                disabled: !data.hasRegisteredPhoenixDeployment()
            },
        ];

        return <ScreenContainer>
            <ScreenTitleBar title={data.getDeployment().getSystemName()} actions={actions}/>
            <RouterTabs configs={configs} path={currentPath}/>
            <CpDeploymentConnectToPhoenixDialog dialogState={connectToPhoenixDialogState}/>
        </ScreenContainer>
    })
});

// ======================
// CirrusProtectDeploymentInfoSection
// ======================

interface CirrusProtectDeploymentInfoSectionProps {
    data: CirrusProtectDeploymentInfo;
    dialogState: DialogState;
}

const CirrusProtectDeploymentInfoSection: React.FC<CirrusProtectDeploymentInfoSectionProps> = observer((p) => {

    return <Box pt={4}>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <ProtectionTargetCard dialogState={p.dialogState} cpDeploymentInfo={p.data}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <ProtectionPolicyCard cpDeploymentInfo={p.data}/>
            </Grid>
        </Grid>
        <br/>
        <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
                <Card sx={{height: '100%'}}>
                    <ListSubheader>
                        Host Information
                    </ListSubheader>
                    <ListItem>
                        <ListItemText primary={p.data.getDeployment().getSystemName()} secondary={'System Name'}/>
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemText primary={p.data.getDeployment().getSystemTimezone()}
                                      secondary={'System Timezone'}/>
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemText primary={p.data.getCpu()} secondary={'CPU'}/>
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemText primary={formatKnownDataType(p.data.getMemory(), KnownDataType.CAPACITY)}
                                      secondary={'Memory'}/>
                    </ListItem>
                </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Card sx={{height: '100%'}}>
                    <ListSubheader>
                        Software Information
                    </ListSubheader>
                    <ListItem>
                        <ListItemText primary={p.data.getDeployment().getVersion()}
                                      secondary={'Galaxy Migrate Version'}/>
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemText primary={p.data.getMtdiVersion()} secondary={'mTDI Version'}/>
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemText primary={p.data.getDeployment().getSystemId()} secondary={'System ID'}/>
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemText primary={p.data.getKernel()} secondary={'Kernel Version'}/>
                    </ListItem>
                </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Card sx={{height: '100%'}}>
                    <ListSubheader>
                        Cirrus Data Cloud Connection
                    </ListSubheader>
                    <ListItem>
                        <ListItemText
                            primary={formatKnownDataType(p.data.getDeployment().getConnected(), KnownDataType.BOOL)}
                            secondary={'Connected'}/>
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemText
                            primary={formatKnownDataType(p.data.getDeployment().getLastCheckin().toDate(), KnownDataType.DATE)}
                            secondary={'Last Check In'}/>
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemText
                            primary={formatKnownDataType(p.data.getDeployment().getConnectionLatency(), KnownDataType.DURATION_MILLISECONDS)}
                            secondary={'Latency'}/>
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemText
                            primary={formatKnownDataType(p.data.getDeployment().getRegisteredAt().toDate(), KnownDataType.DATE)}
                            secondary={'Registered At'}/>
                    </ListItem>
                </Card>
            </Grid>

        </Grid>
        <br/>
        {/*<ProtectionStorageDevicesCard/>*/}
    </Box>
});

// ======================
// ProtectionTargetCard
// ======================

interface ProtectionTargetCardProps {
    dialogState: DialogState;
    cpDeploymentInfo: CirrusProtectDeploymentInfo;
}

const ProtectionTargetCard: React.FC<ProtectionTargetCardProps> = observer((p) => {
    const goToProtectionTargetPage = useNavigateToPhoenixDeploymentDetails(p.cpDeploymentInfo.getRegisteredPhoenixDeployment()?.getDeployment().getSystemId());

    const getConnectionStatusDisplay = () => {
        if (!!p.cpDeploymentInfo.getRegisteredPhoenixDeployment()) {
            return <Box width={'100%'}>
                <Box display={'flex'} justifyContent={'center'} pb={4}>
                    <Button variant={'outlined'} color={'success'} disableRipple
                            sx={{
                                cursor: 'default',
                                borderColor: (t: Theme) => t.palette.success.main,
                                "&:hover": {
                                    backgroundColor: 'rgba(0,0,0,0)'
                                }
                            }}
                    >Protection Target Paired</Button>
                </Box>
                <Box width={'100%'}>
                    <Divider/>
                    <Box display={'flex'} justifyContent={'center'} pt={2} pb={2}>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            Paired
                            to: <Link
                            onClick={goToProtectionTargetPage}>{p.cpDeploymentInfo.getRegisteredPhoenixDeployment().getDeployment().getSystemName()}</Link>
                        </Typography>
                    </Box>
                    {!!p.cpDeploymentInfo.getPhoenixConnectionError() &&
                <Box pb={2} pr={2} pl={2}>
                    <Alert severity={'warning'}>
                        <AlertTitle>
                            Connection Error
                        </AlertTitle>
                        {p.cpDeploymentInfo.getPhoenixConnectionError()}
                    </Alert>
                </Box>
            }
                    <Divider/>
                </Box>
            </Box>
        } else {
            return <Box pb={4}>
                <CpDeploymentConnectToPhoenixButton
                variant={'outlined'} color={'secondary'}
                disabled={!p.cpDeploymentInfo.hasRegisteredPhoenixDeployment()}
                cpDeployments={[{
                    id: p.cpDeploymentInfo.getDeployment().getSystemId(),
                    name: p.cpDeploymentInfo.getDeployment().getSystemName()
                }]}
                dialogState={p.dialogState} label={'Pair Protection Target'}/>
            </Box>
        }
    }

    const listItemStyle = {
        height: '100%',
        borderRight: (t:Theme)=>['none', 'none', 'none', `1px solid ${t.palette.cirrus.main}`],
        borderBottom: (t:Theme)=>[`1px solid ${t.palette.cirrus.main}`,`1px solid ${t.palette.cirrus.main}`, `1px solid ${t.palette.cirrus.main}`, 'none']
    }

    return <Card sx={{height: '100%'}}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} pt={4} pb={2}>
            <Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <BiTargetLock size={64}/>
                </Box>
                <Box textAlign={'center'}>
                    <Typography variant={'h4'}>
                        Protection Target
                    </Typography>
                    <Typography variant={'caption'}>
                        A protection target must be paired to begin data protection
                    </Typography>
                </Box>
            </Box>
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
            {getConnectionStatusDisplay()}
        </Box>

        {!!p.cpDeploymentInfo.getRegisteredPhoenixDeployment() && <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                    <ListItem sx={listItemStyle}>
                        <ListItemText
                            primary={formatKnownDataType(p.cpDeploymentInfo.getPhoenixRegistrationTime().toDate(), KnownDataType.DATE)}
                            secondary={'Registered At'}/>
                    </ListItem>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <ListItem sx={listItemStyle}>
                        <ListItemText
                            primary={p.cpDeploymentInfo.getPhoenixRegistrationUrl()}
                            secondary={'Connection Address'}/>
                    </ListItem>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <ListItem>
                        <ListItemText
                            primary={formatKnownDataType(p.cpDeploymentInfo.getPhoenixConnectionLatency(), KnownDataType.DURATION_MILLISECONDS)}
                            secondary={'Latency'}/>
                    </ListItem>
                </Grid>

            </Grid>
        </>
        }
        {
            !p.cpDeploymentInfo.hasRegisteredPhoenixDeployment() &&
            renderOfflineDeploymentMessage(p.cpDeploymentInfo)
        }
        </Box>
    </Card>
});

// ======================
// ProtectionPolicyCard
// ======================

interface ProtectionPolicyCardProps{
    cpDeploymentInfo: CirrusProtectDeploymentInfo;
}

const ProtectionPolicyCard: React.FC<ProtectionPolicyCardProps> = observer((p)=>{
    const { deploymentId } = useParams();
    const { cpDeploymentService } = useAppServices();
    const goToPolicyTab = useNavigateToCpDeploymentDetails(`${deploymentId}/${PROTECTION_HOST_DETAILS_SUBROUTE.POLICY_INFO}`)

    useInitData({
        poll: () => cpDeploymentService.cpDeploymentPolicyDetails.fetchData(deploymentId),
        pollInterval: 30,
        deinit: async() => {
            await cpDeploymentService.cpDeploymentPolicyDetails.resetData();
        }
    });


    return <Card sx={{height: '100%'}}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} pt={4} pb={2} width={'100%'}>
            <Box>
                <Box display={'flex'} justifyContent={'center'}>
                    <BiShieldQuarter size={64}/>
                </Box>
                <Box textAlign={'center'}>
                    <Typography variant={'h4'}>
                        Protection Policy
                    </Typography>
                    <Typography variant={'caption'}>
                        Protect host volumes by starting a new Protection Policy
                    </Typography>
                </Box>
                <Box display={'flex'} justifyContent={'center'} pt={2} pb={4}>
                    <ProtectHostButton cpDeploymentInfo={p.cpDeploymentInfo}/>
                </Box>
            </Box>

        </Box>
        {(p.cpDeploymentInfo.getCdmPolicyId() >0 && !cpDeploymentService.cpDeploymentPolicyDetails.inError) &&
            renderServerDataWithLoadingBox(cpDeploymentService.cpDeploymentPolicyDetails, data=> {
                return <Box width={'100%'} >
                    <Divider/>
                    <Box width={'100%'} pt={4} pb={4} display={'flex'} justifyContent={'center'}>
                    <Typography variant={'body2'} color={'textSecondary'}>
                        Policy Name:&nbsp;
                        <Link onClick={goToPolicyTab}>
                            {data.getPolicy().getPolicyInfo().getName()}
                        </Link>
                    </Typography>
                </Box>
                </Box>
            })

        }
            {renderOfflineDeploymentMessage(p.cpDeploymentInfo)}
        </Box>
    </Card>
})

// ======================
// ProtectionStorageDevicesCard
// ======================

interface ProtectionStorageDevicesCardProps {
}

const ProtectionStorageDevicesCard: React.FC<ProtectionStorageDevicesCardProps> = observer((p) => {

    return <Card>
        <ListSubheader>Storage Devices</ListSubheader>
    </Card>
})

// ======================
// ProtectHostButton
// ======================

interface ProtectHostButtonProps {
    cpDeploymentInfo: CirrusProtectDeploymentInfo;
}

export const ProtectHostButton: React.FC<ProtectHostButtonProps> = observer((p) => {
    const goToCdmWizard = useNavigateToCdmPolicyWizard();
    const {cdmPolicyService} = useAppServices();

    const onClick = () => {
        cdmPolicyService.initWizardState(1);
        cdmPolicyService.policyWizardState.setSelectedHost(p.cpDeploymentInfo);
        goToCdmWizard()
    };

    return <Button variant={'contained'}
                   color={'secondary'}
                   disabled={!p.cpDeploymentInfo.getRegisteredPhoenixDeployment() || p.cpDeploymentInfo.getCdmPolicyId() > 0 || !p.cpDeploymentInfo.getDeployment().getConnected()}
                   onClick={onClick}>Start Protection</Button>
})