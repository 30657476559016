// source: galaxycompletepb/apipb/domainpb/deployment.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var galaxycompletepb_apipb_domainpb_project_pb = require('../../../galaxycompletepb/apipb/domainpb/project_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_project_pb);
var galaxycompletepb_apipb_domainpb_license_pb = require('../../../galaxycompletepb/apipb/domainpb/license_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_license_pb);
var galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb);
var galaxycompletepb_apipb_domainpb_enumpb_boot_mode_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/boot_mode_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_boot_mode_pb);
goog.exportSymbol('proto.galaxycomplete.domain.DeploymentIdentityInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.DeploymentInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.DeploymentInfo.ProductType', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.DeploymentIdentityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.DeploymentIdentityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.DeploymentIdentityInfo.displayName = 'proto.galaxycomplete.domain.DeploymentIdentityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.DeploymentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.DeploymentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.DeploymentInfo.displayName = 'proto.galaxycomplete.domain.DeploymentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.displayName = 'proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.DeploymentIdentityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.DeploymentIdentityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.DeploymentIdentityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.DeploymentIdentityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    systemName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.DeploymentIdentityInfo}
 */
proto.galaxycomplete.domain.DeploymentIdentityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.DeploymentIdentityInfo;
  return proto.galaxycomplete.domain.DeploymentIdentityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.DeploymentIdentityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.DeploymentIdentityInfo}
 */
proto.galaxycomplete.domain.DeploymentIdentityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemName(value);
      break;
    case 3:
      var value = /** @type {!proto.galaxycomplete.domain.DeploymentInfo.ProductType} */ (reader.readEnum());
      msg.setProductType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.DeploymentIdentityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.DeploymentIdentityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.DeploymentIdentityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.DeploymentIdentityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSystemName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.DeploymentIdentityInfo.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.DeploymentIdentityInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentIdentityInfo.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string system_name = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.DeploymentIdentityInfo.prototype.getSystemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.DeploymentIdentityInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentIdentityInfo.prototype.setSystemName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DeploymentInfo.ProductType product_type = 3;
 * @return {!proto.galaxycomplete.domain.DeploymentInfo.ProductType}
 */
proto.galaxycomplete.domain.DeploymentIdentityInfo.prototype.getProductType = function() {
  return /** @type {!proto.galaxycomplete.domain.DeploymentInfo.ProductType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.DeploymentInfo.ProductType} value
 * @return {!proto.galaxycomplete.domain.DeploymentIdentityInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentIdentityInfo.prototype.setProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.DeploymentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.DeploymentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.DeploymentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    systemName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    systemTimezone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    productType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    registeredAt: (f = msg.getRegisteredAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    connected: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    version: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lastCheckin: (f = msg.getLastCheckin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    connectionLatency: (f = msg.getConnectionLatency()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    hostEnvironment: (f = msg.getHostEnvironment()) && galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb.DeploymentHostEnvironment.toObject(includeInstance, f),
    cdcEndpoint: jspb.Message.getFieldWithDefault(msg, 11, ""),
    cdcRelayServerAddress: jspb.Message.getFieldWithDefault(msg, 12, ""),
    bootMode: (f = msg.getBootMode()) && galaxycompletepb_apipb_domainpb_enumpb_boot_mode_pb.BootMode.toObject(includeInstance, f),
    project: (f = msg.getProject()) && galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo.toObject(includeInstance, f),
    license: (f = msg.getLicense()) && galaxycompletepb_apipb_domainpb_license_pb.HostLicenseInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.DeploymentInfo}
 */
proto.galaxycomplete.domain.DeploymentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.DeploymentInfo;
  return proto.galaxycomplete.domain.DeploymentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.DeploymentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.DeploymentInfo}
 */
proto.galaxycomplete.domain.DeploymentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemTimezone(value);
      break;
    case 4:
      var value = /** @type {!proto.galaxycomplete.domain.DeploymentInfo.ProductType} */ (reader.readEnum());
      msg.setProductType(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRegisteredAt(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConnected(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastCheckin(value);
      break;
    case 9:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setConnectionLatency(value);
      break;
    case 10:
      var value = new galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb.DeploymentHostEnvironment;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb.DeploymentHostEnvironment.deserializeBinaryFromReader);
      msg.setHostEnvironment(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCdcEndpoint(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCdcRelayServerAddress(value);
      break;
    case 13:
      var value = new galaxycompletepb_apipb_domainpb_enumpb_boot_mode_pb.BootMode;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_enumpb_boot_mode_pb.BootMode.deserializeBinaryFromReader);
      msg.setBootMode(value);
      break;
    case 20:
      var value = new galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 30:
      var value = new galaxycompletepb_apipb_domainpb_license_pb.HostLicenseInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_license_pb.HostLicenseInfo.deserializeBinaryFromReader);
      msg.setLicense(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.DeploymentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.DeploymentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.DeploymentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSystemName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSystemTimezone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProductType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getRegisteredAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getConnected();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLastCheckin();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getConnectionLatency();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getHostEnvironment();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb.DeploymentHostEnvironment.serializeBinaryToWriter
    );
  }
  f = message.getCdcEndpoint();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCdcRelayServerAddress();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBootMode();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      galaxycompletepb_apipb_domainpb_enumpb_boot_mode_pb.BootMode.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo.serializeBinaryToWriter
    );
  }
  f = message.getLicense();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      galaxycompletepb_apipb_domainpb_license_pb.HostLicenseInfo.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxycomplete.domain.DeploymentInfo.ProductType = {
  DMS: 0,
  PHOENIX: 1,
  GALAXY_MIGRATE: 2,
  CIRRUS_PROTECT: 3
};

/**
 * optional string system_id = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string system_name = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.getSystemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.setSystemName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string system_timezone = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.getSystemTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.setSystemTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ProductType product_type = 4;
 * @return {!proto.galaxycomplete.domain.DeploymentInfo.ProductType}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.getProductType = function() {
  return /** @type {!proto.galaxycomplete.domain.DeploymentInfo.ProductType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.DeploymentInfo.ProductType} value
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.setProductType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp registered_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.getRegisteredAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
*/
proto.galaxycomplete.domain.DeploymentInfo.prototype.setRegisteredAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.clearRegisteredAt = function() {
  return this.setRegisteredAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.hasRegisteredAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool connected = 6;
 * @return {boolean}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.getConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.setConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string version = 7;
 * @return {string}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp last_checkin = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.getLastCheckin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
*/
proto.galaxycomplete.domain.DeploymentInfo.prototype.setLastCheckin = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.clearLastCheckin = function() {
  return this.setLastCheckin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.hasLastCheckin = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Duration connection_latency = 9;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.getConnectionLatency = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 9));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
*/
proto.galaxycomplete.domain.DeploymentInfo.prototype.setConnectionLatency = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.clearConnectionLatency = function() {
  return this.setConnectionLatency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.hasConnectionLatency = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional enums.DeploymentHostEnvironment host_environment = 10;
 * @return {?proto.galaxycomplete.domain.enums.DeploymentHostEnvironment}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.getHostEnvironment = function() {
  return /** @type{?proto.galaxycomplete.domain.enums.DeploymentHostEnvironment} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb.DeploymentHostEnvironment, 10));
};


/**
 * @param {?proto.galaxycomplete.domain.enums.DeploymentHostEnvironment|undefined} value
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
*/
proto.galaxycomplete.domain.DeploymentInfo.prototype.setHostEnvironment = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.clearHostEnvironment = function() {
  return this.setHostEnvironment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.hasHostEnvironment = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string cdc_endpoint = 11;
 * @return {string}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.getCdcEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.setCdcEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string cdc_relay_server_address = 12;
 * @return {string}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.getCdcRelayServerAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.setCdcRelayServerAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional enums.BootMode boot_mode = 13;
 * @return {?proto.galaxycomplete.domain.enums.BootMode}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.getBootMode = function() {
  return /** @type{?proto.galaxycomplete.domain.enums.BootMode} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_enumpb_boot_mode_pb.BootMode, 13));
};


/**
 * @param {?proto.galaxycomplete.domain.enums.BootMode|undefined} value
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
*/
proto.galaxycomplete.domain.DeploymentInfo.prototype.setBootMode = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.clearBootMode = function() {
  return this.setBootMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.hasBootMode = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional ProjectIdentityInfo project = 20;
 * @return {?proto.galaxycomplete.domain.ProjectIdentityInfo}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.getProject = function() {
  return /** @type{?proto.galaxycomplete.domain.ProjectIdentityInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo, 20));
};


/**
 * @param {?proto.galaxycomplete.domain.ProjectIdentityInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
*/
proto.galaxycomplete.domain.DeploymentInfo.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.hasProject = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional HostLicenseInfo license = 30;
 * @return {?proto.galaxycomplete.domain.HostLicenseInfo}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.getLicense = function() {
  return /** @type{?proto.galaxycomplete.domain.HostLicenseInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_license_pb.HostLicenseInfo, 30));
};


/**
 * @param {?proto.galaxycomplete.domain.HostLicenseInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
*/
proto.galaxycomplete.domain.DeploymentInfo.prototype.setLicense = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.DeploymentInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.clearLicense = function() {
  return this.setLicense(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.DeploymentInfo.prototype.hasLicense = function() {
  return jspb.Message.getField(this, 30) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    addr: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mtu: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mac: jspb.Message.getFieldWithDefault(msg, 4, ""),
    connected: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    order: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo}
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo;
  return proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo}
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddr(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMtu(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMac(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConnected(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAddr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMtu();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMac();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getConnected();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string addr = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.prototype.getAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.prototype.setAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 mtu = 3;
 * @return {number}
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.prototype.getMtu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.prototype.setMtu = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string mac = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.prototype.getMac = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.prototype.setMac = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool connected = 5;
 * @return {boolean}
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.prototype.getConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.prototype.setConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 order = 6;
 * @return {number}
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo} returns this
 */
proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


goog.object.extend(exports, proto.galaxycomplete.domain);
