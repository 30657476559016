import React, { useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { BrowserRouter, Route } from "react-router-dom";
import "typeface-roboto";
import { LightModeTheme, AppTheme, DarkModeTheme } from "./AppTheme";
import { AppServicesProvider, useAppServices } from "./services";
import { AppView } from "./AppView";
import { AppInitialization } from "./AppInitialization";
import { AppBackgroundViews } from "./AppBackgroundViews";
import { AppDaemons } from "./AppDaemons";
import * as Sentry from "@sentry/react";

import { StyledEngineProvider, Theme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

declare module "@mui/material/styles" {
    interface Palette {
        cirrus?: Palette["primary"];
        neutral?: Palette["primary"];
        cirrusGray?: Palette["primary"];
    }
    interface PaletteOptions {
        cirrus?: PaletteOptions["primary"];
        neutral?: PaletteOptions["primary"];
        cirrusGray?: PaletteOptions["primary"];
    }
    interface PaletteColor {
        medium?: string;
    }
    interface SimplePaletteColorOptions {
        medium?: string;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}

const queryClient = new QueryClient();

const App: React.FC = () => {
    useEffect(() => {
        if (process.env.NODE_ENV !== "development") {
            document.domain = "cirrusdata.com";
        }
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <AppServicesProvider>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <AppTheme>
                            <AppInitialization>
                                <CssBaseline />
                                <AppView />
                                <AppBackgroundViews />
                                <AppDaemons />
                                <ReactQueryDevtools initialIsOpen={false} />
                            </AppInitialization>
                        </AppTheme>
                    </BrowserRouter>
                </QueryClientProvider>
            </AppServicesProvider>
        </StyledEngineProvider>
    );
};

export default Sentry.withProfiler(App);
