// source: galaxymigratepb/galaxy_migrate_autoalloc.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.galaxymigrate.AllocateDestinationVolumesRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume', null, global);
goog.exportSymbol('proto.galaxymigrate.AllocateDestinationVolumesResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume', null, global);
goog.exportSymbol('proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume', null, global);
goog.exportSymbol('proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.ConnParams', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.ConnParams.AWS', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.ConnParams.Azure', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.ConnParams.DGS', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.ConnParams.GCP', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.ConnParams.NetApp', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.ConnParams.Oracle', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.ConnParams.ParamsCase', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.PluginID', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.AWS', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.VolumeType', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.Azure', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.StorageType', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.DGS', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.GCP', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.DiskType', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.ParamsCase', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAllocStatus', null, global);
goog.exportSymbol('proto.galaxymigrate.AutoAllocStatus.ProgressLine', null, global);
goog.exportSymbol('proto.galaxymigrate.DeleteSnapshotsRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.DeleteSnapshotsResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.GetAutoAllocStatusDetailsRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.GetAutoAllocStatusDetailsResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.GetHostEnvironmentInfoRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.GetHostEnvironmentInfoResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo', null, global);
goog.exportSymbol('proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS', null, global);
goog.exportSymbol('proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure', null, global);
goog.exportSymbol('proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS', null, global);
goog.exportSymbol('proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean', null, global);
goog.exportSymbol('proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax', null, global);
goog.exportSymbol('proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore', null, global);
goog.exportSymbol('proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP', null, global);
goog.exportSymbol('proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.InfoCase', null, global);
goog.exportSymbol('proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp', null, global);
goog.exportSymbol('proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle', null, global);
goog.exportSymbol('proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS', null, global);
goog.exportSymbol('proto.galaxymigrate.GetVolumeDetailsRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.GetVolumeDetailsResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.IsHostReadyForAllocationRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.IsHostReadyForAllocationResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.ListAutoAllocStatusesRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.ListAutoAllocStatusesResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.ListSnapshotsRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.ListSnapshotsResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.ListSnapshotsResponse.Snapshot', null, global);
goog.exportSymbol('proto.galaxymigrate.PrepareForAllocationRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.PrepareForAllocationResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.TakeSnapshotsRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.TakeSnapshotsRequest.Volume', null, global);
goog.exportSymbol('proto.galaxymigrate.TakeSnapshotsResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.TakeSnapshotsResponse.Snapshot', null, global);
goog.exportSymbol('proto.galaxymigrate.TestAutoAllocConnectionRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.TestAutoAllocConnectionResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.TestAutoAllocConnectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.TestAutoAllocConnectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.TestAutoAllocConnectionRequest.displayName = 'proto.galaxymigrate.TestAutoAllocConnectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.TestAutoAllocConnectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.TestAutoAllocConnectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.TestAutoAllocConnectionResponse.displayName = 'proto.galaxymigrate.TestAutoAllocConnectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.PrepareForAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.PrepareForAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.PrepareForAllocationRequest.displayName = 'proto.galaxymigrate.PrepareForAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.PrepareForAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.PrepareForAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.PrepareForAllocationResponse.displayName = 'proto.galaxymigrate.PrepareForAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.IsHostReadyForAllocationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.IsHostReadyForAllocationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.IsHostReadyForAllocationRequest.displayName = 'proto.galaxymigrate.IsHostReadyForAllocationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.IsHostReadyForAllocationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.IsHostReadyForAllocationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.IsHostReadyForAllocationResponse.displayName = 'proto.galaxymigrate.IsHostReadyForAllocationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.AllocateDestinationVolumesRequest.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.AllocateDestinationVolumesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AllocateDestinationVolumesRequest.displayName = 'proto.galaxymigrate.AllocateDestinationVolumesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.displayName = 'proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.AllocateDestinationVolumesResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.AllocateDestinationVolumesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AllocateDestinationVolumesResponse.displayName = 'proto.galaxymigrate.AllocateDestinationVolumesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.displayName = 'proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.TakeSnapshotsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.TakeSnapshotsRequest.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.TakeSnapshotsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.TakeSnapshotsRequest.displayName = 'proto.galaxymigrate.TakeSnapshotsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.TakeSnapshotsRequest.Volume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.TakeSnapshotsRequest.Volume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.TakeSnapshotsRequest.Volume.displayName = 'proto.galaxymigrate.TakeSnapshotsRequest.Volume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.TakeSnapshotsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.TakeSnapshotsResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.TakeSnapshotsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.TakeSnapshotsResponse.displayName = 'proto.galaxymigrate.TakeSnapshotsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.TakeSnapshotsResponse.Snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.displayName = 'proto.galaxymigrate.TakeSnapshotsResponse.Snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.displayName = 'proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.displayName = 'proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.displayName = 'proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.displayName = 'proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.DeleteSnapshotsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.DeleteSnapshotsRequest.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.DeleteSnapshotsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.DeleteSnapshotsRequest.displayName = 'proto.galaxymigrate.DeleteSnapshotsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.DeleteSnapshotsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.DeleteSnapshotsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.DeleteSnapshotsResponse.displayName = 'proto.galaxymigrate.DeleteSnapshotsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.ListSnapshotsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.ListSnapshotsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.ListSnapshotsRequest.displayName = 'proto.galaxymigrate.ListSnapshotsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.ListSnapshotsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.ListSnapshotsResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.ListSnapshotsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.ListSnapshotsResponse.displayName = 'proto.galaxymigrate.ListSnapshotsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.ListSnapshotsResponse.Snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.ListSnapshotsResponse.Snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.ListSnapshotsResponse.Snapshot.displayName = 'proto.galaxymigrate.ListSnapshotsResponse.Snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetVolumeDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetVolumeDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetVolumeDetailsRequest.displayName = 'proto.galaxymigrate.GetVolumeDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetVolumeDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetVolumeDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetVolumeDetailsResponse.displayName = 'proto.galaxymigrate.GetVolumeDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.displayName = 'proto.galaxymigrate.AutoAlloc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.ConnParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.ConnParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.ConnParams.displayName = 'proto.galaxymigrate.AutoAlloc.ConnParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.displayName = 'proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Azure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.ConnParams.Azure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.ConnParams.Azure.displayName = 'proto.galaxymigrate.AutoAlloc.ConnParams.Azure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DGS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.ConnParams.DGS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.ConnParams.DGS.displayName = 'proto.galaxymigrate.AutoAlloc.ConnParams.DGS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.ConnParams.AWS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.ConnParams.AWS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.ConnParams.AWS.displayName = 'proto.galaxymigrate.AutoAlloc.ConnParams.AWS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.ConnParams.NetApp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.ConnParams.NetApp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.displayName = 'proto.galaxymigrate.AutoAlloc.ConnParams.NetApp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.displayName = 'proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.displayName = 'proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.displayName = 'proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.ConnParams.GCP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.ConnParams.GCP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.ConnParams.GCP.displayName = 'proto.galaxymigrate.AutoAlloc.ConnParams.GCP';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Oracle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.ConnParams.Oracle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.displayName = 'proto.galaxymigrate.AutoAlloc.ConnParams.Oracle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean.displayName = 'proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.VolumeParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.galaxymigrate.AutoAlloc.VolumeParams.oneofGroups_);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.VolumeParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.VolumeParams.displayName = 'proto.galaxymigrate.AutoAlloc.VolumeParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS.displayName = 'proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp.displayName = 'proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Azure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.VolumeParams.Azure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.displayName = 'proto.galaxymigrate.AutoAlloc.VolumeParams.Azure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DGS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.VolumeParams.DGS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.VolumeParams.DGS.displayName = 'proto.galaxymigrate.AutoAlloc.VolumeParams.DGS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.AWS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.VolumeParams.AWS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.displayName = 'proto.galaxymigrate.AutoAlloc.VolumeParams.AWS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp.displayName = 'proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.displayName = 'proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.displayName = 'proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.GCP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.VolumeParams.GCP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.displayName = 'proto.galaxymigrate.AutoAlloc.VolumeParams.GCP';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle.displayName = 'proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean.displayName = 'proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAllocStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.AutoAllocStatus.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.AutoAllocStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAllocStatus.displayName = 'proto.galaxymigrate.AutoAllocStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.AutoAllocStatus.ProgressLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.AutoAllocStatus.ProgressLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.AutoAllocStatus.ProgressLine.displayName = 'proto.galaxymigrate.AutoAllocStatus.ProgressLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.ListAutoAllocStatusesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.ListAutoAllocStatusesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.ListAutoAllocStatusesRequest.displayName = 'proto.galaxymigrate.ListAutoAllocStatusesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.ListAutoAllocStatusesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.ListAutoAllocStatusesResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.ListAutoAllocStatusesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.ListAutoAllocStatusesResponse.displayName = 'proto.galaxymigrate.ListAutoAllocStatusesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetAutoAllocStatusDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetAutoAllocStatusDetailsRequest.displayName = 'proto.galaxymigrate.GetAutoAllocStatusDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetAutoAllocStatusDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetAutoAllocStatusDetailsResponse.displayName = 'proto.galaxymigrate.GetAutoAllocStatusDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetHostEnvironmentInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetHostEnvironmentInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetHostEnvironmentInfoRequest.displayName = 'proto.galaxymigrate.GetHostEnvironmentInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetHostEnvironmentInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.displayName = 'proto.galaxymigrate.GetHostEnvironmentInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.oneofGroups_);
};
goog.inherits(proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.displayName = 'proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.displayName = 'proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.displayName = 'proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS.displayName = 'proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS.displayName = 'proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp.displayName = 'proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.displayName = 'proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax.displayName = 'proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP.displayName = 'proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle.displayName = 'proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean.displayName = 'proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.TestAutoAllocConnectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.TestAutoAllocConnectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.TestAutoAllocConnectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.TestAutoAllocConnectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    conn: (f = msg.getConn()) && proto.galaxymigrate.AutoAlloc.ConnParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.TestAutoAllocConnectionRequest}
 */
proto.galaxymigrate.TestAutoAllocConnectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.TestAutoAllocConnectionRequest;
  return proto.galaxymigrate.TestAutoAllocConnectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.TestAutoAllocConnectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.TestAutoAllocConnectionRequest}
 */
proto.galaxymigrate.TestAutoAllocConnectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.deserializeBinaryFromReader);
      msg.setConn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.TestAutoAllocConnectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.TestAutoAllocConnectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.TestAutoAllocConnectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.TestAutoAllocConnectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutoAlloc.ConnParams conn = 1;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams}
 */
proto.galaxymigrate.TestAutoAllocConnectionRequest.prototype.getConn = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams|undefined} value
 * @return {!proto.galaxymigrate.TestAutoAllocConnectionRequest} returns this
*/
proto.galaxymigrate.TestAutoAllocConnectionRequest.prototype.setConn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.TestAutoAllocConnectionRequest} returns this
 */
proto.galaxymigrate.TestAutoAllocConnectionRequest.prototype.clearConn = function() {
  return this.setConn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.TestAutoAllocConnectionRequest.prototype.hasConn = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.TestAutoAllocConnectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.TestAutoAllocConnectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.TestAutoAllocConnectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.TestAutoAllocConnectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.TestAutoAllocConnectionResponse}
 */
proto.galaxymigrate.TestAutoAllocConnectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.TestAutoAllocConnectionResponse;
  return proto.galaxymigrate.TestAutoAllocConnectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.TestAutoAllocConnectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.TestAutoAllocConnectionResponse}
 */
proto.galaxymigrate.TestAutoAllocConnectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.TestAutoAllocConnectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.TestAutoAllocConnectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.TestAutoAllocConnectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.TestAutoAllocConnectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.PrepareForAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.PrepareForAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.PrepareForAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.PrepareForAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    conn: (f = msg.getConn()) && proto.galaxymigrate.AutoAlloc.ConnParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.PrepareForAllocationRequest}
 */
proto.galaxymigrate.PrepareForAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.PrepareForAllocationRequest;
  return proto.galaxymigrate.PrepareForAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.PrepareForAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.PrepareForAllocationRequest}
 */
proto.galaxymigrate.PrepareForAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.deserializeBinaryFromReader);
      msg.setConn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.PrepareForAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.PrepareForAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.PrepareForAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.PrepareForAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutoAlloc.ConnParams conn = 1;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams}
 */
proto.galaxymigrate.PrepareForAllocationRequest.prototype.getConn = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams|undefined} value
 * @return {!proto.galaxymigrate.PrepareForAllocationRequest} returns this
*/
proto.galaxymigrate.PrepareForAllocationRequest.prototype.setConn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.PrepareForAllocationRequest} returns this
 */
proto.galaxymigrate.PrepareForAllocationRequest.prototype.clearConn = function() {
  return this.setConn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.PrepareForAllocationRequest.prototype.hasConn = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.PrepareForAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.PrepareForAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.PrepareForAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.PrepareForAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoAllocStatus: (f = msg.getAutoAllocStatus()) && proto.galaxymigrate.AutoAllocStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.PrepareForAllocationResponse}
 */
proto.galaxymigrate.PrepareForAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.PrepareForAllocationResponse;
  return proto.galaxymigrate.PrepareForAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.PrepareForAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.PrepareForAllocationResponse}
 */
proto.galaxymigrate.PrepareForAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAllocStatus;
      reader.readMessage(value,proto.galaxymigrate.AutoAllocStatus.deserializeBinaryFromReader);
      msg.setAutoAllocStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.PrepareForAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.PrepareForAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.PrepareForAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.PrepareForAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoAllocStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAllocStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutoAllocStatus auto_alloc_status = 1;
 * @return {?proto.galaxymigrate.AutoAllocStatus}
 */
proto.galaxymigrate.PrepareForAllocationResponse.prototype.getAutoAllocStatus = function() {
  return /** @type{?proto.galaxymigrate.AutoAllocStatus} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAllocStatus, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAllocStatus|undefined} value
 * @return {!proto.galaxymigrate.PrepareForAllocationResponse} returns this
*/
proto.galaxymigrate.PrepareForAllocationResponse.prototype.setAutoAllocStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.PrepareForAllocationResponse} returns this
 */
proto.galaxymigrate.PrepareForAllocationResponse.prototype.clearAutoAllocStatus = function() {
  return this.setAutoAllocStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.PrepareForAllocationResponse.prototype.hasAutoAllocStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.IsHostReadyForAllocationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.IsHostReadyForAllocationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.IsHostReadyForAllocationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IsHostReadyForAllocationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    conn: (f = msg.getConn()) && proto.galaxymigrate.AutoAlloc.ConnParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.IsHostReadyForAllocationRequest}
 */
proto.galaxymigrate.IsHostReadyForAllocationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.IsHostReadyForAllocationRequest;
  return proto.galaxymigrate.IsHostReadyForAllocationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.IsHostReadyForAllocationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.IsHostReadyForAllocationRequest}
 */
proto.galaxymigrate.IsHostReadyForAllocationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.deserializeBinaryFromReader);
      msg.setConn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.IsHostReadyForAllocationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.IsHostReadyForAllocationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.IsHostReadyForAllocationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IsHostReadyForAllocationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutoAlloc.ConnParams conn = 1;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams}
 */
proto.galaxymigrate.IsHostReadyForAllocationRequest.prototype.getConn = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams|undefined} value
 * @return {!proto.galaxymigrate.IsHostReadyForAllocationRequest} returns this
*/
proto.galaxymigrate.IsHostReadyForAllocationRequest.prototype.setConn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.IsHostReadyForAllocationRequest} returns this
 */
proto.galaxymigrate.IsHostReadyForAllocationRequest.prototype.clearConn = function() {
  return this.setConn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.IsHostReadyForAllocationRequest.prototype.hasConn = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.IsHostReadyForAllocationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.IsHostReadyForAllocationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.IsHostReadyForAllocationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IsHostReadyForAllocationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ready: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.IsHostReadyForAllocationResponse}
 */
proto.galaxymigrate.IsHostReadyForAllocationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.IsHostReadyForAllocationResponse;
  return proto.galaxymigrate.IsHostReadyForAllocationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.IsHostReadyForAllocationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.IsHostReadyForAllocationResponse}
 */
proto.galaxymigrate.IsHostReadyForAllocationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReady(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.IsHostReadyForAllocationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.IsHostReadyForAllocationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.IsHostReadyForAllocationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IsHostReadyForAllocationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReady();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool ready = 1;
 * @return {boolean}
 */
proto.galaxymigrate.IsHostReadyForAllocationResponse.prototype.getReady = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.IsHostReadyForAllocationResponse} returns this
 */
proto.galaxymigrate.IsHostReadyForAllocationResponse.prototype.setReady = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.galaxymigrate.IsHostReadyForAllocationResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.IsHostReadyForAllocationResponse} returns this
 */
proto.galaxymigrate.IsHostReadyForAllocationResponse.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AllocateDestinationVolumesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AllocateDestinationVolumesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    conn: (f = msg.getConn()) && proto.galaxymigrate.AutoAlloc.ConnParams.toObject(includeInstance, f),
    volumesList: jspb.Message.toObjectList(msg.getVolumesList(),
    proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesRequest}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AllocateDestinationVolumesRequest;
  return proto.galaxymigrate.AllocateDestinationVolumesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AllocateDestinationVolumesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesRequest}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.deserializeBinaryFromReader);
      msg.setConn(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume;
      reader.readMessage(value,proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.deserializeBinaryFromReader);
      msg.addVolumes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AllocateDestinationVolumesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AllocateDestinationVolumesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.serializeBinaryToWriter
    );
  }
  f = message.getVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    capacity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    params: (f = msg.getParams()) && proto.galaxymigrate.AutoAlloc.VolumeParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume;
  return proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCapacity(value);
      break;
    case 4:
      var value = new proto.galaxymigrate.AutoAlloc.VolumeParams;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.VolumeParams.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.galaxymigrate.AutoAlloc.VolumeParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume} returns this
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume} returns this
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 capacity = 3;
 * @return {number}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume} returns this
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional AutoAlloc.VolumeParams params = 4;
 * @return {?proto.galaxymigrate.AutoAlloc.VolumeParams}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.prototype.getParams = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.VolumeParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.VolumeParams, 4));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.VolumeParams|undefined} value
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume} returns this
*/
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume} returns this
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume.prototype.hasParams = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AutoAlloc.ConnParams conn = 1;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.prototype.getConn = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams|undefined} value
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesRequest} returns this
*/
proto.galaxymigrate.AllocateDestinationVolumesRequest.prototype.setConn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesRequest} returns this
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.prototype.clearConn = function() {
  return this.setConn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.prototype.hasConn = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Volume volumes = 2;
 * @return {!Array<!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume>}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.prototype.getVolumesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume, 2));
};


/**
 * @param {!Array<!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume>} value
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesRequest} returns this
*/
proto.galaxymigrate.AllocateDestinationVolumesRequest.prototype.setVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume}
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.prototype.addVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxymigrate.AllocateDestinationVolumesRequest.Volume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesRequest} returns this
 */
proto.galaxymigrate.AllocateDestinationVolumesRequest.prototype.clearVolumesList = function() {
  return this.setVolumesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AllocateDestinationVolumesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AllocateDestinationVolumesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoAllocStatus: (f = msg.getAutoAllocStatus()) && proto.galaxymigrate.AutoAllocStatus.toObject(includeInstance, f),
    allocatedVolumesList: jspb.Message.toObjectList(msg.getAllocatedVolumesList(),
    proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesResponse}
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AllocateDestinationVolumesResponse;
  return proto.galaxymigrate.AllocateDestinationVolumesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AllocateDestinationVolumesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesResponse}
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.galaxymigrate.AutoAllocStatus;
      reader.readMessage(value,proto.galaxymigrate.AutoAllocStatus.deserializeBinaryFromReader);
      msg.setAutoAllocStatus(value);
      break;
    case 1:
      var value = new proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume;
      reader.readMessage(value,proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.deserializeBinaryFromReader);
      msg.addAllocatedVolumes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AllocateDestinationVolumesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AllocateDestinationVolumesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoAllocStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.AutoAllocStatus.serializeBinaryToWriter
    );
  }
  f = message.getAllocatedVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    devicePath: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume}
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume;
  return proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume}
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume} returns this
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device_path = 2;
 * @return {string}
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume} returns this
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AutoAllocStatus auto_alloc_status = 2;
 * @return {?proto.galaxymigrate.AutoAllocStatus}
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.prototype.getAutoAllocStatus = function() {
  return /** @type{?proto.galaxymigrate.AutoAllocStatus} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAllocStatus, 2));
};


/**
 * @param {?proto.galaxymigrate.AutoAllocStatus|undefined} value
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesResponse} returns this
*/
proto.galaxymigrate.AllocateDestinationVolumesResponse.prototype.setAutoAllocStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesResponse} returns this
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.prototype.clearAutoAllocStatus = function() {
  return this.setAutoAllocStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.prototype.hasAutoAllocStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Volume allocated_volumes = 1;
 * @return {!Array<!proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume>}
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.prototype.getAllocatedVolumesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume>} value
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesResponse} returns this
*/
proto.galaxymigrate.AllocateDestinationVolumesResponse.prototype.setAllocatedVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume}
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.prototype.addAllocatedVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.AllocateDestinationVolumesResponse.Volume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.AllocateDestinationVolumesResponse} returns this
 */
proto.galaxymigrate.AllocateDestinationVolumesResponse.prototype.clearAllocatedVolumesList = function() {
  return this.setAllocatedVolumesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.TakeSnapshotsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.TakeSnapshotsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.TakeSnapshotsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.TakeSnapshotsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.TakeSnapshotsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    conn: (f = msg.getConn()) && proto.galaxymigrate.AutoAlloc.ConnParams.toObject(includeInstance, f),
    volumesList: jspb.Message.toObjectList(msg.getVolumesList(),
    proto.galaxymigrate.TakeSnapshotsRequest.Volume.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.TakeSnapshotsRequest}
 */
proto.galaxymigrate.TakeSnapshotsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.TakeSnapshotsRequest;
  return proto.galaxymigrate.TakeSnapshotsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.TakeSnapshotsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.TakeSnapshotsRequest}
 */
proto.galaxymigrate.TakeSnapshotsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.deserializeBinaryFromReader);
      msg.setConn(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.TakeSnapshotsRequest.Volume;
      reader.readMessage(value,proto.galaxymigrate.TakeSnapshotsRequest.Volume.deserializeBinaryFromReader);
      msg.addVolumes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.TakeSnapshotsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.TakeSnapshotsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.TakeSnapshotsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.TakeSnapshotsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.serializeBinaryToWriter
    );
  }
  f = message.getVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.galaxymigrate.TakeSnapshotsRequest.Volume.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.TakeSnapshotsRequest.Volume.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.TakeSnapshotsRequest.Volume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.TakeSnapshotsRequest.Volume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.TakeSnapshotsRequest.Volume.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    devicePath: jspb.Message.getFieldWithDefault(msg, 2, ""),
    snapshotNamePrefix: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.TakeSnapshotsRequest.Volume}
 */
proto.galaxymigrate.TakeSnapshotsRequest.Volume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.TakeSnapshotsRequest.Volume;
  return proto.galaxymigrate.TakeSnapshotsRequest.Volume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.TakeSnapshotsRequest.Volume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.TakeSnapshotsRequest.Volume}
 */
proto.galaxymigrate.TakeSnapshotsRequest.Volume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnapshotNamePrefix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.TakeSnapshotsRequest.Volume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.TakeSnapshotsRequest.Volume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.TakeSnapshotsRequest.Volume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.TakeSnapshotsRequest.Volume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSnapshotNamePrefix();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.galaxymigrate.TakeSnapshotsRequest.Volume.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.TakeSnapshotsRequest.Volume} returns this
 */
proto.galaxymigrate.TakeSnapshotsRequest.Volume.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device_path = 2;
 * @return {string}
 */
proto.galaxymigrate.TakeSnapshotsRequest.Volume.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.TakeSnapshotsRequest.Volume} returns this
 */
proto.galaxymigrate.TakeSnapshotsRequest.Volume.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string snapshot_name_prefix = 3;
 * @return {string}
 */
proto.galaxymigrate.TakeSnapshotsRequest.Volume.prototype.getSnapshotNamePrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.TakeSnapshotsRequest.Volume} returns this
 */
proto.galaxymigrate.TakeSnapshotsRequest.Volume.prototype.setSnapshotNamePrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AutoAlloc.ConnParams conn = 1;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams}
 */
proto.galaxymigrate.TakeSnapshotsRequest.prototype.getConn = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams|undefined} value
 * @return {!proto.galaxymigrate.TakeSnapshotsRequest} returns this
*/
proto.galaxymigrate.TakeSnapshotsRequest.prototype.setConn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.TakeSnapshotsRequest} returns this
 */
proto.galaxymigrate.TakeSnapshotsRequest.prototype.clearConn = function() {
  return this.setConn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.TakeSnapshotsRequest.prototype.hasConn = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Volume volumes = 2;
 * @return {!Array<!proto.galaxymigrate.TakeSnapshotsRequest.Volume>}
 */
proto.galaxymigrate.TakeSnapshotsRequest.prototype.getVolumesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.TakeSnapshotsRequest.Volume>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.TakeSnapshotsRequest.Volume, 2));
};


/**
 * @param {!Array<!proto.galaxymigrate.TakeSnapshotsRequest.Volume>} value
 * @return {!proto.galaxymigrate.TakeSnapshotsRequest} returns this
*/
proto.galaxymigrate.TakeSnapshotsRequest.prototype.setVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxymigrate.TakeSnapshotsRequest.Volume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.TakeSnapshotsRequest.Volume}
 */
proto.galaxymigrate.TakeSnapshotsRequest.prototype.addVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxymigrate.TakeSnapshotsRequest.Volume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.TakeSnapshotsRequest} returns this
 */
proto.galaxymigrate.TakeSnapshotsRequest.prototype.clearVolumesList = function() {
  return this.setVolumesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.TakeSnapshotsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.TakeSnapshotsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.TakeSnapshotsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.TakeSnapshotsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.TakeSnapshotsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoAllocStatus: (f = msg.getAutoAllocStatus()) && proto.galaxymigrate.AutoAllocStatus.toObject(includeInstance, f),
    allocatedSnapshotsList: jspb.Message.toObjectList(msg.getAllocatedSnapshotsList(),
    proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.TakeSnapshotsResponse}
 */
proto.galaxymigrate.TakeSnapshotsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.TakeSnapshotsResponse;
  return proto.galaxymigrate.TakeSnapshotsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.TakeSnapshotsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.TakeSnapshotsResponse}
 */
proto.galaxymigrate.TakeSnapshotsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.galaxymigrate.AutoAllocStatus;
      reader.readMessage(value,proto.galaxymigrate.AutoAllocStatus.deserializeBinaryFromReader);
      msg.setAutoAllocStatus(value);
      break;
    case 1:
      var value = new proto.galaxymigrate.TakeSnapshotsResponse.Snapshot;
      reader.readMessage(value,proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.deserializeBinaryFromReader);
      msg.addAllocatedSnapshots(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.TakeSnapshotsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.TakeSnapshotsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.TakeSnapshotsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.TakeSnapshotsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoAllocStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.AutoAllocStatus.serializeBinaryToWriter
    );
  }
  f = message.getAllocatedSnapshotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.TakeSnapshotsResponse.Snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    snapshotId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    snapshotName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.TakeSnapshotsResponse.Snapshot}
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.TakeSnapshotsResponse.Snapshot;
  return proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.TakeSnapshotsResponse.Snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.TakeSnapshotsResponse.Snapshot}
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnapshotId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnapshotName(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.TakeSnapshotsResponse.Snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSnapshotId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSnapshotName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.TakeSnapshotsResponse.Snapshot} returns this
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string snapshot_id = 2;
 * @return {string}
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.prototype.getSnapshotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.TakeSnapshotsResponse.Snapshot} returns this
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.prototype.setSnapshotId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string snapshot_name = 3;
 * @return {string}
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.prototype.getSnapshotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.TakeSnapshotsResponse.Snapshot} returns this
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.prototype.setSnapshotName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.TakeSnapshotsResponse.Snapshot} returns this
*/
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.TakeSnapshotsResponse.Snapshot} returns this
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.TakeSnapshotsResponse.Snapshot.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AutoAllocStatus auto_alloc_status = 2;
 * @return {?proto.galaxymigrate.AutoAllocStatus}
 */
proto.galaxymigrate.TakeSnapshotsResponse.prototype.getAutoAllocStatus = function() {
  return /** @type{?proto.galaxymigrate.AutoAllocStatus} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAllocStatus, 2));
};


/**
 * @param {?proto.galaxymigrate.AutoAllocStatus|undefined} value
 * @return {!proto.galaxymigrate.TakeSnapshotsResponse} returns this
*/
proto.galaxymigrate.TakeSnapshotsResponse.prototype.setAutoAllocStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.TakeSnapshotsResponse} returns this
 */
proto.galaxymigrate.TakeSnapshotsResponse.prototype.clearAutoAllocStatus = function() {
  return this.setAutoAllocStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.TakeSnapshotsResponse.prototype.hasAutoAllocStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Snapshot allocated_snapshots = 1;
 * @return {!Array<!proto.galaxymigrate.TakeSnapshotsResponse.Snapshot>}
 */
proto.galaxymigrate.TakeSnapshotsResponse.prototype.getAllocatedSnapshotsList = function() {
  return /** @type{!Array<!proto.galaxymigrate.TakeSnapshotsResponse.Snapshot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.TakeSnapshotsResponse.Snapshot, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.TakeSnapshotsResponse.Snapshot>} value
 * @return {!proto.galaxymigrate.TakeSnapshotsResponse} returns this
*/
proto.galaxymigrate.TakeSnapshotsResponse.prototype.setAllocatedSnapshotsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.TakeSnapshotsResponse.Snapshot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.TakeSnapshotsResponse.Snapshot}
 */
proto.galaxymigrate.TakeSnapshotsResponse.prototype.addAllocatedSnapshots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.TakeSnapshotsResponse.Snapshot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.TakeSnapshotsResponse} returns this
 */
proto.galaxymigrate.TakeSnapshotsResponse.prototype.clearAllocatedSnapshotsList = function() {
  return this.setAllocatedSnapshotsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    conn: (f = msg.getConn()) && proto.galaxymigrate.AutoAlloc.ConnParams.toObject(includeInstance, f),
    volumesList: jspb.Message.toObjectList(msg.getVolumesList(),
    proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest;
  return proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.deserializeBinaryFromReader);
      msg.setConn(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume;
      reader.readMessage(value,proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.deserializeBinaryFromReader);
      msg.addVolumes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.serializeBinaryToWriter
    );
  }
  f = message.getVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    snapshotId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    params: (f = msg.getParams()) && proto.galaxymigrate.AutoAlloc.VolumeParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume;
  return proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnapshotId(value);
      break;
    case 4:
      var value = new proto.galaxymigrate.AutoAlloc.VolumeParams;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.VolumeParams.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSnapshotId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.galaxymigrate.AutoAlloc.VolumeParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume} returns this
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume} returns this
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string snapshot_id = 3;
 * @return {string}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.prototype.getSnapshotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume} returns this
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.prototype.setSnapshotId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AutoAlloc.VolumeParams params = 4;
 * @return {?proto.galaxymigrate.AutoAlloc.VolumeParams}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.prototype.getParams = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.VolumeParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.VolumeParams, 4));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.VolumeParams|undefined} value
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume} returns this
*/
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume} returns this
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume.prototype.hasParams = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AutoAlloc.ConnParams conn = 1;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.prototype.getConn = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams|undefined} value
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest} returns this
*/
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.prototype.setConn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest} returns this
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.prototype.clearConn = function() {
  return this.setConn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.prototype.hasConn = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Volume volumes = 2;
 * @return {!Array<!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume>}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.prototype.getVolumesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume, 2));
};


/**
 * @param {!Array<!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume>} value
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest} returns this
*/
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.prototype.setVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.prototype.addVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.Volume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest} returns this
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsRequest.prototype.clearVolumesList = function() {
  return this.setVolumesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoAllocStatus: (f = msg.getAutoAllocStatus()) && proto.galaxymigrate.AutoAllocStatus.toObject(includeInstance, f),
    allocatedVolumesList: jspb.Message.toObjectList(msg.getAllocatedVolumesList(),
    proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse;
  return proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.galaxymigrate.AutoAllocStatus;
      reader.readMessage(value,proto.galaxymigrate.AutoAllocStatus.deserializeBinaryFromReader);
      msg.setAutoAllocStatus(value);
      break;
    case 1:
      var value = new proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume;
      reader.readMessage(value,proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.deserializeBinaryFromReader);
      msg.addAllocatedVolumes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoAllocStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.AutoAllocStatus.serializeBinaryToWriter
    );
  }
  f = message.getAllocatedVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    devicePath: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume;
  return proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume} returns this
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device_path = 2;
 * @return {string}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume} returns this
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AutoAllocStatus auto_alloc_status = 2;
 * @return {?proto.galaxymigrate.AutoAllocStatus}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.prototype.getAutoAllocStatus = function() {
  return /** @type{?proto.galaxymigrate.AutoAllocStatus} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAllocStatus, 2));
};


/**
 * @param {?proto.galaxymigrate.AutoAllocStatus|undefined} value
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse} returns this
*/
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.prototype.setAutoAllocStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse} returns this
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.prototype.clearAutoAllocStatus = function() {
  return this.setAutoAllocStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.prototype.hasAutoAllocStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Volume allocated_volumes = 1;
 * @return {!Array<!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume>}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.prototype.getAllocatedVolumesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume>} value
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse} returns this
*/
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.prototype.setAllocatedVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume}
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.prototype.addAllocatedVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.Volume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse} returns this
 */
proto.galaxymigrate.AllocateVolumesFromSnapshotsResponse.prototype.clearAllocatedVolumesList = function() {
  return this.setAllocatedVolumesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.DeleteSnapshotsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.DeleteSnapshotsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.DeleteSnapshotsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.DeleteSnapshotsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.DeleteSnapshotsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    conn: (f = msg.getConn()) && proto.galaxymigrate.AutoAlloc.ConnParams.toObject(includeInstance, f),
    snapshotIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.DeleteSnapshotsRequest}
 */
proto.galaxymigrate.DeleteSnapshotsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.DeleteSnapshotsRequest;
  return proto.galaxymigrate.DeleteSnapshotsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.DeleteSnapshotsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.DeleteSnapshotsRequest}
 */
proto.galaxymigrate.DeleteSnapshotsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.deserializeBinaryFromReader);
      msg.setConn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addSnapshotIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.DeleteSnapshotsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.DeleteSnapshotsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.DeleteSnapshotsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.DeleteSnapshotsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.serializeBinaryToWriter
    );
  }
  f = message.getSnapshotIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional AutoAlloc.ConnParams conn = 1;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams}
 */
proto.galaxymigrate.DeleteSnapshotsRequest.prototype.getConn = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams|undefined} value
 * @return {!proto.galaxymigrate.DeleteSnapshotsRequest} returns this
*/
proto.galaxymigrate.DeleteSnapshotsRequest.prototype.setConn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.DeleteSnapshotsRequest} returns this
 */
proto.galaxymigrate.DeleteSnapshotsRequest.prototype.clearConn = function() {
  return this.setConn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.DeleteSnapshotsRequest.prototype.hasConn = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string snapshot_ids = 2;
 * @return {!Array<string>}
 */
proto.galaxymigrate.DeleteSnapshotsRequest.prototype.getSnapshotIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxymigrate.DeleteSnapshotsRequest} returns this
 */
proto.galaxymigrate.DeleteSnapshotsRequest.prototype.setSnapshotIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.DeleteSnapshotsRequest} returns this
 */
proto.galaxymigrate.DeleteSnapshotsRequest.prototype.addSnapshotIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.DeleteSnapshotsRequest} returns this
 */
proto.galaxymigrate.DeleteSnapshotsRequest.prototype.clearSnapshotIdsList = function() {
  return this.setSnapshotIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.DeleteSnapshotsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.DeleteSnapshotsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.DeleteSnapshotsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.DeleteSnapshotsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoAllocStatus: (f = msg.getAutoAllocStatus()) && proto.galaxymigrate.AutoAllocStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.DeleteSnapshotsResponse}
 */
proto.galaxymigrate.DeleteSnapshotsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.DeleteSnapshotsResponse;
  return proto.galaxymigrate.DeleteSnapshotsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.DeleteSnapshotsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.DeleteSnapshotsResponse}
 */
proto.galaxymigrate.DeleteSnapshotsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.galaxymigrate.AutoAllocStatus;
      reader.readMessage(value,proto.galaxymigrate.AutoAllocStatus.deserializeBinaryFromReader);
      msg.setAutoAllocStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.DeleteSnapshotsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.DeleteSnapshotsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.DeleteSnapshotsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.DeleteSnapshotsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoAllocStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.AutoAllocStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutoAllocStatus auto_alloc_status = 2;
 * @return {?proto.galaxymigrate.AutoAllocStatus}
 */
proto.galaxymigrate.DeleteSnapshotsResponse.prototype.getAutoAllocStatus = function() {
  return /** @type{?proto.galaxymigrate.AutoAllocStatus} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAllocStatus, 2));
};


/**
 * @param {?proto.galaxymigrate.AutoAllocStatus|undefined} value
 * @return {!proto.galaxymigrate.DeleteSnapshotsResponse} returns this
*/
proto.galaxymigrate.DeleteSnapshotsResponse.prototype.setAutoAllocStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.DeleteSnapshotsResponse} returns this
 */
proto.galaxymigrate.DeleteSnapshotsResponse.prototype.clearAutoAllocStatus = function() {
  return this.setAutoAllocStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.DeleteSnapshotsResponse.prototype.hasAutoAllocStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.ListSnapshotsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.ListSnapshotsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.ListSnapshotsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListSnapshotsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    conn: (f = msg.getConn()) && proto.galaxymigrate.AutoAlloc.ConnParams.toObject(includeInstance, f),
    devicePath: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.ListSnapshotsRequest}
 */
proto.galaxymigrate.ListSnapshotsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.ListSnapshotsRequest;
  return proto.galaxymigrate.ListSnapshotsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.ListSnapshotsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.ListSnapshotsRequest}
 */
proto.galaxymigrate.ListSnapshotsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.deserializeBinaryFromReader);
      msg.setConn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.ListSnapshotsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.ListSnapshotsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.ListSnapshotsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListSnapshotsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.serializeBinaryToWriter
    );
  }
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional AutoAlloc.ConnParams conn = 1;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams}
 */
proto.galaxymigrate.ListSnapshotsRequest.prototype.getConn = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams|undefined} value
 * @return {!proto.galaxymigrate.ListSnapshotsRequest} returns this
*/
proto.galaxymigrate.ListSnapshotsRequest.prototype.setConn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.ListSnapshotsRequest} returns this
 */
proto.galaxymigrate.ListSnapshotsRequest.prototype.clearConn = function() {
  return this.setConn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.ListSnapshotsRequest.prototype.hasConn = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string device_path = 2;
 * @return {string}
 */
proto.galaxymigrate.ListSnapshotsRequest.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.ListSnapshotsRequest} returns this
 */
proto.galaxymigrate.ListSnapshotsRequest.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.ListSnapshotsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.ListSnapshotsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.ListSnapshotsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.ListSnapshotsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListSnapshotsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoAllocStatus: (f = msg.getAutoAllocStatus()) && proto.galaxymigrate.AutoAllocStatus.toObject(includeInstance, f),
    snapshotsList: jspb.Message.toObjectList(msg.getSnapshotsList(),
    proto.galaxymigrate.ListSnapshotsResponse.Snapshot.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.ListSnapshotsResponse}
 */
proto.galaxymigrate.ListSnapshotsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.ListSnapshotsResponse;
  return proto.galaxymigrate.ListSnapshotsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.ListSnapshotsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.ListSnapshotsResponse}
 */
proto.galaxymigrate.ListSnapshotsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.galaxymigrate.AutoAllocStatus;
      reader.readMessage(value,proto.galaxymigrate.AutoAllocStatus.deserializeBinaryFromReader);
      msg.setAutoAllocStatus(value);
      break;
    case 1:
      var value = new proto.galaxymigrate.ListSnapshotsResponse.Snapshot;
      reader.readMessage(value,proto.galaxymigrate.ListSnapshotsResponse.Snapshot.deserializeBinaryFromReader);
      msg.addSnapshots(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.ListSnapshotsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.ListSnapshotsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.ListSnapshotsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListSnapshotsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoAllocStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.AutoAllocStatus.serializeBinaryToWriter
    );
  }
  f = message.getSnapshotsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxymigrate.ListSnapshotsResponse.Snapshot.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.ListSnapshotsResponse.Snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.ListSnapshotsResponse.Snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.ListSnapshotsResponse.Snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListSnapshotsResponse.Snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    snapshotId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    snapshotName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.ListSnapshotsResponse.Snapshot}
 */
proto.galaxymigrate.ListSnapshotsResponse.Snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.ListSnapshotsResponse.Snapshot;
  return proto.galaxymigrate.ListSnapshotsResponse.Snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.ListSnapshotsResponse.Snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.ListSnapshotsResponse.Snapshot}
 */
proto.galaxymigrate.ListSnapshotsResponse.Snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnapshotId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnapshotName(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.ListSnapshotsResponse.Snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.ListSnapshotsResponse.Snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.ListSnapshotsResponse.Snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListSnapshotsResponse.Snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSnapshotId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSnapshotName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string snapshot_id = 2;
 * @return {string}
 */
proto.galaxymigrate.ListSnapshotsResponse.Snapshot.prototype.getSnapshotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.ListSnapshotsResponse.Snapshot} returns this
 */
proto.galaxymigrate.ListSnapshotsResponse.Snapshot.prototype.setSnapshotId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string snapshot_name = 3;
 * @return {string}
 */
proto.galaxymigrate.ListSnapshotsResponse.Snapshot.prototype.getSnapshotName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.ListSnapshotsResponse.Snapshot} returns this
 */
proto.galaxymigrate.ListSnapshotsResponse.Snapshot.prototype.setSnapshotName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.ListSnapshotsResponse.Snapshot.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.ListSnapshotsResponse.Snapshot} returns this
*/
proto.galaxymigrate.ListSnapshotsResponse.Snapshot.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.ListSnapshotsResponse.Snapshot} returns this
 */
proto.galaxymigrate.ListSnapshotsResponse.Snapshot.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.ListSnapshotsResponse.Snapshot.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AutoAllocStatus auto_alloc_status = 2;
 * @return {?proto.galaxymigrate.AutoAllocStatus}
 */
proto.galaxymigrate.ListSnapshotsResponse.prototype.getAutoAllocStatus = function() {
  return /** @type{?proto.galaxymigrate.AutoAllocStatus} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAllocStatus, 2));
};


/**
 * @param {?proto.galaxymigrate.AutoAllocStatus|undefined} value
 * @return {!proto.galaxymigrate.ListSnapshotsResponse} returns this
*/
proto.galaxymigrate.ListSnapshotsResponse.prototype.setAutoAllocStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.ListSnapshotsResponse} returns this
 */
proto.galaxymigrate.ListSnapshotsResponse.prototype.clearAutoAllocStatus = function() {
  return this.setAutoAllocStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.ListSnapshotsResponse.prototype.hasAutoAllocStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Snapshot snapshots = 1;
 * @return {!Array<!proto.galaxymigrate.ListSnapshotsResponse.Snapshot>}
 */
proto.galaxymigrate.ListSnapshotsResponse.prototype.getSnapshotsList = function() {
  return /** @type{!Array<!proto.galaxymigrate.ListSnapshotsResponse.Snapshot>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.ListSnapshotsResponse.Snapshot, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.ListSnapshotsResponse.Snapshot>} value
 * @return {!proto.galaxymigrate.ListSnapshotsResponse} returns this
*/
proto.galaxymigrate.ListSnapshotsResponse.prototype.setSnapshotsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.ListSnapshotsResponse.Snapshot=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.ListSnapshotsResponse.Snapshot}
 */
proto.galaxymigrate.ListSnapshotsResponse.prototype.addSnapshots = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.ListSnapshotsResponse.Snapshot, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.ListSnapshotsResponse} returns this
 */
proto.galaxymigrate.ListSnapshotsResponse.prototype.clearSnapshotsList = function() {
  return this.setSnapshotsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetVolumeDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetVolumeDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetVolumeDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetVolumeDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    conn: (f = msg.getConn()) && proto.galaxymigrate.AutoAlloc.ConnParams.toObject(includeInstance, f),
    devicePath: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetVolumeDetailsRequest}
 */
proto.galaxymigrate.GetVolumeDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetVolumeDetailsRequest;
  return proto.galaxymigrate.GetVolumeDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetVolumeDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetVolumeDetailsRequest}
 */
proto.galaxymigrate.GetVolumeDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.deserializeBinaryFromReader);
      msg.setConn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetVolumeDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetVolumeDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetVolumeDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetVolumeDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.serializeBinaryToWriter
    );
  }
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional AutoAlloc.ConnParams conn = 1;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams}
 */
proto.galaxymigrate.GetVolumeDetailsRequest.prototype.getConn = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams|undefined} value
 * @return {!proto.galaxymigrate.GetVolumeDetailsRequest} returns this
*/
proto.galaxymigrate.GetVolumeDetailsRequest.prototype.setConn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetVolumeDetailsRequest} returns this
 */
proto.galaxymigrate.GetVolumeDetailsRequest.prototype.clearConn = function() {
  return this.setConn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetVolumeDetailsRequest.prototype.hasConn = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string device_path = 2;
 * @return {string}
 */
proto.galaxymigrate.GetVolumeDetailsRequest.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetVolumeDetailsRequest} returns this
 */
proto.galaxymigrate.GetVolumeDetailsRequest.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetVolumeDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetVolumeDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetVolumeDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoAllocStatus: (f = msg.getAutoAllocStatus()) && proto.galaxymigrate.AutoAllocStatus.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    capacity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    params: (f = msg.getParams()) && proto.galaxymigrate.AutoAlloc.VolumeParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetVolumeDetailsResponse}
 */
proto.galaxymigrate.GetVolumeDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetVolumeDetailsResponse;
  return proto.galaxymigrate.GetVolumeDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetVolumeDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetVolumeDetailsResponse}
 */
proto.galaxymigrate.GetVolumeDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAllocStatus;
      reader.readMessage(value,proto.galaxymigrate.AutoAllocStatus.deserializeBinaryFromReader);
      msg.setAutoAllocStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCapacity(value);
      break;
    case 5:
      var value = new proto.galaxymigrate.AutoAlloc.VolumeParams;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.VolumeParams.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetVolumeDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetVolumeDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetVolumeDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoAllocStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAllocStatus.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.galaxymigrate.AutoAlloc.VolumeParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutoAllocStatus auto_alloc_status = 1;
 * @return {?proto.galaxymigrate.AutoAllocStatus}
 */
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.getAutoAllocStatus = function() {
  return /** @type{?proto.galaxymigrate.AutoAllocStatus} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAllocStatus, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAllocStatus|undefined} value
 * @return {!proto.galaxymigrate.GetVolumeDetailsResponse} returns this
*/
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.setAutoAllocStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetVolumeDetailsResponse} returns this
 */
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.clearAutoAllocStatus = function() {
  return this.setAutoAllocStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.hasAutoAllocStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetVolumeDetailsResponse} returns this
 */
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetVolumeDetailsResponse} returns this
 */
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 capacity = 4;
 * @return {number}
 */
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.GetVolumeDetailsResponse} returns this
 */
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional AutoAlloc.VolumeParams params = 5;
 * @return {?proto.galaxymigrate.AutoAlloc.VolumeParams}
 */
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.getParams = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.VolumeParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.VolumeParams, 5));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.VolumeParams|undefined} value
 * @return {!proto.galaxymigrate.GetVolumeDetailsResponse} returns this
*/
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetVolumeDetailsResponse} returns this
 */
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetVolumeDetailsResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc}
 */
proto.galaxymigrate.AutoAlloc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc;
  return proto.galaxymigrate.AutoAlloc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc}
 */
proto.galaxymigrate.AutoAlloc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.galaxymigrate.AutoAlloc.PluginID = {
  PURE_CBS: 0,
  AZURE: 1
};


/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13]];

/**
 * @enum {number}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.ParamsCase = {
  PARAMS_NOT_SET: 0,
  PURECBS: 1,
  AZURE: 2,
  DGS: 3,
  AWS: 4,
  NETAPP: 5,
  FC_PUREFA: 6,
  POWERSTORE: 7,
  FC_POWERSTORE: 8,
  FC_POWERMAX: 9,
  GCP: 10,
  ORACLE: 11,
  SILKSDP: 12,
  DIGITALOCEAN: 13
};

/**
 * @return {proto.galaxymigrate.AutoAlloc.ConnParams.ParamsCase}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getParamsCase = function() {
  return /** @type {proto.galaxymigrate.AutoAlloc.ConnParams.ParamsCase} */(jspb.Message.computeOneofCase(this, proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.ConnParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    purecbs: (f = msg.getPurecbs()) && proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.toObject(includeInstance, f),
    azure: (f = msg.getAzure()) && proto.galaxymigrate.AutoAlloc.ConnParams.Azure.toObject(includeInstance, f),
    dgs: (f = msg.getDgs()) && proto.galaxymigrate.AutoAlloc.ConnParams.DGS.toObject(includeInstance, f),
    aws: (f = msg.getAws()) && proto.galaxymigrate.AutoAlloc.ConnParams.AWS.toObject(includeInstance, f),
    netapp: (f = msg.getNetapp()) && proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.toObject(includeInstance, f),
    fcPurefa: (f = msg.getFcPurefa()) && proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.toObject(includeInstance, f),
    powerstore: (f = msg.getPowerstore()) && proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.toObject(includeInstance, f),
    fcPowerstore: (f = msg.getFcPowerstore()) && proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.toObject(includeInstance, f),
    fcPowermax: (f = msg.getFcPowermax()) && proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.toObject(includeInstance, f),
    gcp: (f = msg.getGcp()) && proto.galaxymigrate.AutoAlloc.ConnParams.GCP.toObject(includeInstance, f),
    oracle: (f = msg.getOracle()) && proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.toObject(includeInstance, f),
    silksdp: (f = msg.getSilksdp()) && proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.toObject(includeInstance, f),
    digitalocean: (f = msg.getDigitalocean()) && proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean.toObject(includeInstance, f),
    debugLogging: jspb.Message.getBooleanFieldWithDefault(msg, 100, false),
    progressId: jspb.Message.getFieldWithDefault(msg, 101, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.ConnParams;
  return proto.galaxymigrate.AutoAlloc.ConnParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.deserializeBinaryFromReader);
      msg.setPurecbs(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams.Azure;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.Azure.deserializeBinaryFromReader);
      msg.setAzure(value);
      break;
    case 3:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams.DGS;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.DGS.deserializeBinaryFromReader);
      msg.setDgs(value);
      break;
    case 4:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams.AWS;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.AWS.deserializeBinaryFromReader);
      msg.setAws(value);
      break;
    case 5:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams.NetApp;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.deserializeBinaryFromReader);
      msg.setNetapp(value);
      break;
    case 6:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.deserializeBinaryFromReader);
      msg.setFcPurefa(value);
      break;
    case 7:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.deserializeBinaryFromReader);
      msg.setPowerstore(value);
      break;
    case 8:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.deserializeBinaryFromReader);
      msg.setFcPowerstore(value);
      break;
    case 9:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.deserializeBinaryFromReader);
      msg.setFcPowermax(value);
      break;
    case 10:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams.GCP;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.GCP.deserializeBinaryFromReader);
      msg.setGcp(value);
      break;
    case 11:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams.Oracle;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.deserializeBinaryFromReader);
      msg.setOracle(value);
      break;
    case 12:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.deserializeBinaryFromReader);
      msg.setSilksdp(value);
      break;
    case 13:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean.deserializeBinaryFromReader);
      msg.setDigitalocean(value);
      break;
    case 100:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDebugLogging(value);
      break;
    case 101:
      var value = /** @type {string} */ (reader.readString());
      msg.setProgressId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.ConnParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurecbs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.serializeBinaryToWriter
    );
  }
  f = message.getAzure();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.Azure.serializeBinaryToWriter
    );
  }
  f = message.getDgs();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.DGS.serializeBinaryToWriter
    );
  }
  f = message.getAws();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.AWS.serializeBinaryToWriter
    );
  }
  f = message.getNetapp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.serializeBinaryToWriter
    );
  }
  f = message.getFcPurefa();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.serializeBinaryToWriter
    );
  }
  f = message.getPowerstore();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.serializeBinaryToWriter
    );
  }
  f = message.getFcPowerstore();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.serializeBinaryToWriter
    );
  }
  f = message.getFcPowermax();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.serializeBinaryToWriter
    );
  }
  f = message.getGcp();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.GCP.serializeBinaryToWriter
    );
  }
  f = message.getOracle();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.serializeBinaryToWriter
    );
  }
  f = message.getSilksdp();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.serializeBinaryToWriter
    );
  }
  f = message.getDigitalocean();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean.serializeBinaryToWriter
    );
  }
  f = message.getDebugLogging();
  if (f) {
    writer.writeBool(
      100,
      f
    );
  }
  f = message.getProgressId();
  if (f.length > 0) {
    writer.writeString(
      101,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.toObject = function(includeInstance, msg) {
  var f, obj = {
    managementUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    apiToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS;
  return proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagementUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManagementUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApiToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string management_uri = 1;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.prototype.getManagementUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.prototype.setManagementUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string api_token = 2;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.prototype.getApiToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS.prototype.setApiToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Azure.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.ConnParams.Azure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.Azure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Azure.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenant: jspb.Message.getFieldWithDefault(msg, 1, ""),
    client: jspb.Message.getFieldWithDefault(msg, 2, ""),
    secret: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.Azure}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Azure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.ConnParams.Azure;
  return proto.galaxymigrate.AutoAlloc.ConnParams.Azure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.Azure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.Azure}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Azure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenant(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClient(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Azure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.ConnParams.Azure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.Azure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Azure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenant();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClient();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSecret();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string tenant = 1;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Azure.prototype.getTenant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.Azure} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Azure.prototype.setTenant = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string client = 2;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Azure.prototype.getClient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.Azure} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Azure.prototype.setClient = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string secret = 3;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Azure.prototype.getSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.Azure} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Azure.prototype.setSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DGS.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.ConnParams.DGS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.DGS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DGS.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endpoint: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.DGS}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DGS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.ConnParams.DGS;
  return proto.galaxymigrate.AutoAlloc.ConnParams.DGS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.DGS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.DGS}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DGS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DGS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.ConnParams.DGS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.DGS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DGS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndpoint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DGS.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.DGS} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DGS.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DGS.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.DGS} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DGS.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string endpoint = 3;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DGS.prototype.getEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.DGS} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DGS.prototype.setEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.AWS.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.ConnParams.AWS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.AWS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.AWS.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    secretAccessKey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.AWS}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.AWS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.ConnParams.AWS;
  return proto.galaxymigrate.AutoAlloc.ConnParams.AWS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.AWS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.AWS}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.AWS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecretAccessKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.AWS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.ConnParams.AWS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.AWS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.AWS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecretAccessKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string access_key = 1;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.AWS.prototype.getAccessKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.AWS} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.AWS.prototype.setAccessKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secret_access_key = 2;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.AWS.prototype.getSecretAccessKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.AWS} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.AWS.prototype.setSecretAccessKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.NetApp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endpoint: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.NetApp}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.ConnParams.NetApp;
  return proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.NetApp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.NetApp}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.NetApp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndpoint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.NetApp} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.NetApp} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string endpoint = 3;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.prototype.getEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.NetApp} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.NetApp.prototype.setEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endpoint: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp;
  return proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndpoint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string endpoint = 3;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.prototype.getEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp.prototype.setEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endpoint: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore;
  return proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndpoint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string endpoint = 3;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.prototype.getEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore.prototype.setEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endpoint: jspb.Message.getFieldWithDefault(msg, 3, ""),
    arrayid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax;
  return proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndpoint(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrayid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndpoint();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getArrayid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string endpoint = 3;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.prototype.getEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.prototype.setEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string arrayID = 4;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.prototype.getArrayid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax.prototype.setArrayid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.GCP.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.ConnParams.GCP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.GCP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.GCP.toObject = function(includeInstance, msg) {
  var f, obj = {
    credentials: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.GCP}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.GCP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.ConnParams.GCP;
  return proto.galaxymigrate.AutoAlloc.ConnParams.GCP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.GCP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.GCP}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.GCP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCredentials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.GCP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.ConnParams.GCP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.GCP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.GCP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCredentials();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string credentials = 1;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.GCP.prototype.getCredentials = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.GCP} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.GCP.prototype.setCredentials = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.Oracle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.toObject = function(includeInstance, msg) {
  var f, obj = {
    ocid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fingerprint: jspb.Message.getFieldWithDefault(msg, 2, ""),
    privateKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tenancy: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.Oracle}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.ConnParams.Oracle;
  return proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.Oracle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.Oracle}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOcid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFingerprint(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenancy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.Oracle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOcid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFingerprint();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrivateKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTenancy();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string ocid = 1;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.prototype.getOcid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.Oracle} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.prototype.setOcid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fingerprint = 2;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.prototype.getFingerprint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.Oracle} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.prototype.setFingerprint = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string private_key = 3;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.prototype.getPrivateKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.Oracle} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.prototype.setPrivateKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tenancy = 4;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.prototype.getTenancy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.Oracle} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.Oracle.prototype.setTenancy = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean;
  return proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string api_token = 1;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean.prototype.getApiToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean.prototype.setApiToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PureCBS purecbs = 1;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getPurecbs = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
*/
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.setPurecbs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.clearPurecbs = function() {
  return this.setPurecbs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.hasPurecbs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Azure azure = 2;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams.Azure}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getAzure = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams.Azure} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams.Azure, 2));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams.Azure|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
*/
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.setAzure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.clearAzure = function() {
  return this.setAzure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.hasAzure = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DGS dgs = 3;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams.DGS}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getDgs = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams.DGS} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams.DGS, 3));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams.DGS|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
*/
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.setDgs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.clearDgs = function() {
  return this.setDgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.hasDgs = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AWS aws = 4;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams.AWS}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getAws = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams.AWS} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams.AWS, 4));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams.AWS|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
*/
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.setAws = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.clearAws = function() {
  return this.setAws(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.hasAws = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NetApp netapp = 5;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams.NetApp}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getNetapp = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams.NetApp} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams.NetApp, 5));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams.NetApp|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
*/
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.setNetapp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.clearNetapp = function() {
  return this.setNetapp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.hasNetapp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PureCBS fc_purefa = 6;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getFcPurefa = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS, 6));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams.PureCBS|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
*/
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.setFcPurefa = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.clearFcPurefa = function() {
  return this.setFcPurefa(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.hasFcPurefa = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional EMCPowerStore powerstore = 7;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getPowerstore = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore, 7));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
*/
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.setPowerstore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.clearPowerstore = function() {
  return this.setPowerstore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.hasPowerstore = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EMCPowerStore fc_powerstore = 8;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getFcPowerstore = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore, 8));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerStore|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
*/
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.setFcPowerstore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.clearFcPowerstore = function() {
  return this.setFcPowerstore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.hasFcPowerstore = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EMCPowerMax fc_powermax = 9;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getFcPowermax = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax, 9));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams.EMCPowerMax|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
*/
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.setFcPowermax = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.clearFcPowermax = function() {
  return this.setFcPowermax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.hasFcPowermax = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional GCP gcp = 10;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams.GCP}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getGcp = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams.GCP} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams.GCP, 10));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams.GCP|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
*/
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.setGcp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.clearGcp = function() {
  return this.setGcp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.hasGcp = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Oracle oracle = 11;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams.Oracle}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getOracle = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams.Oracle} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams.Oracle, 11));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams.Oracle|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
*/
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.setOracle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.clearOracle = function() {
  return this.setOracle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.hasOracle = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional SilkSdp silksdp = 12;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getSilksdp = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp, 12));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams.SilkSdp|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
*/
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.setSilksdp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.clearSilksdp = function() {
  return this.setSilksdp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.hasSilksdp = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional DigitalOcean digitalocean = 13;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getDigitalocean = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean, 13));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams.DigitalOcean|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
*/
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.setDigitalocean = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.galaxymigrate.AutoAlloc.ConnParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.clearDigitalocean = function() {
  return this.setDigitalocean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.hasDigitalocean = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool debug_logging = 100;
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getDebugLogging = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.setDebugLogging = function(value) {
  return jspb.Message.setProto3BooleanField(this, 100, value);
};


/**
 * optional string progress_id = 101;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.getProgressId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 101, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.ConnParams} returns this
 */
proto.galaxymigrate.AutoAlloc.ConnParams.prototype.setProgressId = function(value) {
  return jspb.Message.setProto3StringField(this, 101, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11]];

/**
 * @enum {number}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.ParamsCase = {
  PARAMS_NOT_SET: 0,
  PURECBS: 1,
  AZURE: 2,
  DGS: 3,
  AWS: 4,
  NETAPP: 5,
  POWERSTORE: 6,
  POWERMAX: 7,
  GCP: 8,
  ORACLE: 9,
  SILKSDP: 10,
  DIGITALOCEAN: 11
};

/**
 * @return {proto.galaxymigrate.AutoAlloc.VolumeParams.ParamsCase}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.getParamsCase = function() {
  return /** @type {proto.galaxymigrate.AutoAlloc.VolumeParams.ParamsCase} */(jspb.Message.computeOneofCase(this, proto.galaxymigrate.AutoAlloc.VolumeParams.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.VolumeParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    purecbs: (f = msg.getPurecbs()) && proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS.toObject(includeInstance, f),
    azure: (f = msg.getAzure()) && proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.toObject(includeInstance, f),
    dgs: (f = msg.getDgs()) && proto.galaxymigrate.AutoAlloc.VolumeParams.DGS.toObject(includeInstance, f),
    aws: (f = msg.getAws()) && proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.toObject(includeInstance, f),
    netapp: (f = msg.getNetapp()) && proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp.toObject(includeInstance, f),
    powerstore: (f = msg.getPowerstore()) && proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.toObject(includeInstance, f),
    powermax: (f = msg.getPowermax()) && proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.toObject(includeInstance, f),
    gcp: (f = msg.getGcp()) && proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.toObject(includeInstance, f),
    oracle: (f = msg.getOracle()) && proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle.toObject(includeInstance, f),
    silksdp: (f = msg.getSilksdp()) && proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp.toObject(includeInstance, f),
    digitalocean: (f = msg.getDigitalocean()) && proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.VolumeParams;
  return proto.galaxymigrate.AutoAlloc.VolumeParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS.deserializeBinaryFromReader);
      msg.setPurecbs(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.AutoAlloc.VolumeParams.Azure;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.deserializeBinaryFromReader);
      msg.setAzure(value);
      break;
    case 3:
      var value = new proto.galaxymigrate.AutoAlloc.VolumeParams.DGS;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.VolumeParams.DGS.deserializeBinaryFromReader);
      msg.setDgs(value);
      break;
    case 4:
      var value = new proto.galaxymigrate.AutoAlloc.VolumeParams.AWS;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.deserializeBinaryFromReader);
      msg.setAws(value);
      break;
    case 5:
      var value = new proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp.deserializeBinaryFromReader);
      msg.setNetapp(value);
      break;
    case 6:
      var value = new proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.deserializeBinaryFromReader);
      msg.setPowerstore(value);
      break;
    case 7:
      var value = new proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.deserializeBinaryFromReader);
      msg.setPowermax(value);
      break;
    case 8:
      var value = new proto.galaxymigrate.AutoAlloc.VolumeParams.GCP;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.deserializeBinaryFromReader);
      msg.setGcp(value);
      break;
    case 9:
      var value = new proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle.deserializeBinaryFromReader);
      msg.setOracle(value);
      break;
    case 10:
      var value = new proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp.deserializeBinaryFromReader);
      msg.setSilksdp(value);
      break;
    case 11:
      var value = new proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean.deserializeBinaryFromReader);
      msg.setDigitalocean(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.VolumeParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurecbs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS.serializeBinaryToWriter
    );
  }
  f = message.getAzure();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.serializeBinaryToWriter
    );
  }
  f = message.getDgs();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.galaxymigrate.AutoAlloc.VolumeParams.DGS.serializeBinaryToWriter
    );
  }
  f = message.getAws();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.serializeBinaryToWriter
    );
  }
  f = message.getNetapp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp.serializeBinaryToWriter
    );
  }
  f = message.getPowerstore();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.serializeBinaryToWriter
    );
  }
  f = message.getPowermax();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.serializeBinaryToWriter
    );
  }
  f = message.getGcp();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.serializeBinaryToWriter
    );
  }
  f = message.getOracle();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle.serializeBinaryToWriter
    );
  }
  f = message.getSilksdp();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp.serializeBinaryToWriter
    );
  }
  f = message.getDigitalocean();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS;
  return proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp.toObject = function(includeInstance, msg) {
  var f, obj = {
    volumeGroupName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp;
  return proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolumeGroupName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVolumeGroupName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string volume_group_name = 1;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp.prototype.getVolumeGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp.prototype.setVolumeGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.toObject = function(includeInstance, msg) {
  var f, obj = {
    storageType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    iops: jspb.Message.getFieldWithDefault(msg, 2, 0),
    throughput: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.VolumeParams.Azure;
  return proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.StorageType} */ (reader.readEnum());
      msg.setStorageType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIops(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setThroughput(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStorageType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIops();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getThroughput();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.StorageType = {
  STANDARD: 0,
  PREMIUM: 1,
  STANDARD_SSD: 2,
  ULTRA_SSD: 4,
  PREMIUM_SSD_V2: 5
};

/**
 * optional StorageType storage_type = 1;
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.StorageType}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.prototype.getStorageType = function() {
  return /** @type {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.StorageType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.StorageType} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.prototype.setStorageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 iops = 2;
 * @return {number}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.prototype.getIops = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.prototype.setIops = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 throughput = 3;
 * @return {number}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.prototype.getThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.Azure} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Azure.prototype.setThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DGS.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.VolumeParams.DGS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.DGS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DGS.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.DGS}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DGS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.VolumeParams.DGS;
  return proto.galaxymigrate.AutoAlloc.VolumeParams.DGS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.DGS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.DGS}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DGS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DGS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.VolumeParams.DGS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.DGS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DGS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.AWS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.toObject = function(includeInstance, msg) {
  var f, obj = {
    volumeType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    iops: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.AWS}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.VolumeParams.AWS;
  return proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.AWS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.AWS}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.VolumeType} */ (reader.readEnum());
      msg.setVolumeType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIops(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.AWS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVolumeType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIops();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.VolumeType = {
  GP2: 0,
  GP3: 1,
  IO1: 2,
  IO2: 3,
  ST1: 4,
  SC1: 5,
  STANDARD: 6
};

/**
 * optional VolumeType volume_type = 1;
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.VolumeType}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.prototype.getVolumeType = function() {
  return /** @type {!proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.VolumeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.VolumeType} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.AWS} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.prototype.setVolumeType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 IOPs = 2;
 * @return {number}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.prototype.getIops = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.AWS} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.AWS.prototype.setIops = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp.toObject = function(includeInstance, msg) {
  var f, obj = {
    qosPolicyName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp;
  return proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQosPolicyName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQosPolicyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string qos_policy_name = 1;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp.prototype.getQosPolicyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp.prototype.setQosPolicyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.toObject = function(includeInstance, msg) {
  var f, obj = {
    protectionPolicyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    performancePolicyName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore;
  return proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtectionPolicyName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPerformancePolicyName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProtectionPolicyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPerformancePolicyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string protection_policy_name = 1;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.prototype.getProtectionPolicyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.prototype.setProtectionPolicyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string performance_policy_name = 2;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.prototype.getPerformancePolicyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore.prototype.setPerformancePolicyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceLevel: jspb.Message.getFieldWithDefault(msg, 1, 0),
    storagePool: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax;
  return proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel} */ (reader.readEnum());
      msg.setServiceLevel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStoragePool(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getStoragePool();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel = {
  NONE: 0,
  DIAMOND: 1,
  PLATINUM: 2,
  GOLD: 3,
  SILVER: 4,
  BRONZE: 5,
  OPTIMIZED: 6
};

/**
 * optional ServiceLevel service_level = 1;
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.prototype.getServiceLevel = function() {
  return /** @type {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.prototype.setServiceLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string storage_pool = 2;
 * @return {string}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.prototype.getStoragePool = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax.prototype.setStoragePool = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.GCP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.toObject = function(includeInstance, msg) {
  var f, obj = {
    diskType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    iops: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.GCP}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.VolumeParams.GCP;
  return proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.GCP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.GCP}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.DiskType} */ (reader.readEnum());
      msg.setDiskType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIops(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.GCP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiskType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getIops();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.DiskType = {
  PD_STANDARD: 0,
  PD_BALANCED: 1,
  PD_SSD: 2,
  PD_EXTREME: 3
};

/**
 * optional DiskType disk_type = 1;
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.DiskType}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.prototype.getDiskType = function() {
  return /** @type {!proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.DiskType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.DiskType} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.GCP} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.prototype.setDiskType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 iops = 2;
 * @return {number}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.prototype.getIops = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.GCP} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.GCP.prototype.setIops = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle.toObject = function(includeInstance, msg) {
  var f, obj = {
    vpus: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle;
  return proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVpus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVpus();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 vpus = 1;
 * @return {number}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle.prototype.getVpus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle.prototype.setVpus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean.toObject = function(includeInstance, msg) {
  var f, obj = {
    sizeGib: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean;
  return proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSizeGib(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSizeGib();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 size_gib = 1;
 * @return {number}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean.prototype.getSizeGib = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean.prototype.setSizeGib = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional PureCBS purecbs = 1;
 * @return {?proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.getPurecbs = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.VolumeParams.PureCBS|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
*/
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.setPurecbs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.galaxymigrate.AutoAlloc.VolumeParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.clearPurecbs = function() {
  return this.setPurecbs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.hasPurecbs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Azure azure = 2;
 * @return {?proto.galaxymigrate.AutoAlloc.VolumeParams.Azure}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.getAzure = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.VolumeParams.Azure} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.VolumeParams.Azure, 2));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.VolumeParams.Azure|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
*/
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.setAzure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.galaxymigrate.AutoAlloc.VolumeParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.clearAzure = function() {
  return this.setAzure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.hasAzure = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DGS dgs = 3;
 * @return {?proto.galaxymigrate.AutoAlloc.VolumeParams.DGS}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.getDgs = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.VolumeParams.DGS} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.VolumeParams.DGS, 3));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.VolumeParams.DGS|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
*/
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.setDgs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.galaxymigrate.AutoAlloc.VolumeParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.clearDgs = function() {
  return this.setDgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.hasDgs = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AWS aws = 4;
 * @return {?proto.galaxymigrate.AutoAlloc.VolumeParams.AWS}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.getAws = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.VolumeParams.AWS} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.VolumeParams.AWS, 4));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.VolumeParams.AWS|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
*/
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.setAws = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.galaxymigrate.AutoAlloc.VolumeParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.clearAws = function() {
  return this.setAws(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.hasAws = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NetApp netapp = 5;
 * @return {?proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.getNetapp = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp, 5));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.VolumeParams.NetApp|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
*/
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.setNetapp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.galaxymigrate.AutoAlloc.VolumeParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.clearNetapp = function() {
  return this.setNetapp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.hasNetapp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional EMCPowerStore powerstore = 6;
 * @return {?proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.getPowerstore = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore, 6));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerStore|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
*/
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.setPowerstore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.galaxymigrate.AutoAlloc.VolumeParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.clearPowerstore = function() {
  return this.setPowerstore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.hasPowerstore = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional EMCPowerMax powermax = 7;
 * @return {?proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.getPowermax = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax, 7));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.VolumeParams.EMCPowerMax|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
*/
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.setPowermax = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.galaxymigrate.AutoAlloc.VolumeParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.clearPowermax = function() {
  return this.setPowermax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.hasPowermax = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional GCP gcp = 8;
 * @return {?proto.galaxymigrate.AutoAlloc.VolumeParams.GCP}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.getGcp = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.VolumeParams.GCP} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.VolumeParams.GCP, 8));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.VolumeParams.GCP|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
*/
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.setGcp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.galaxymigrate.AutoAlloc.VolumeParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.clearGcp = function() {
  return this.setGcp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.hasGcp = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Oracle oracle = 9;
 * @return {?proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.getOracle = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle, 9));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.VolumeParams.Oracle|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
*/
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.setOracle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.galaxymigrate.AutoAlloc.VolumeParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.clearOracle = function() {
  return this.setOracle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.hasOracle = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional SilkSdp silksdp = 10;
 * @return {?proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.getSilksdp = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp, 10));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.VolumeParams.SilkSdp|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
*/
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.setSilksdp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.galaxymigrate.AutoAlloc.VolumeParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.clearSilksdp = function() {
  return this.setSilksdp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.hasSilksdp = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional DigitalOcean digitalocean = 11;
 * @return {?proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.getDigitalocean = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean, 11));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.VolumeParams.DigitalOcean|undefined} value
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
*/
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.setDigitalocean = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.galaxymigrate.AutoAlloc.VolumeParams.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAlloc.VolumeParams} returns this
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.clearDigitalocean = function() {
  return this.setDigitalocean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAlloc.VolumeParams.prototype.hasDigitalocean = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.AutoAllocStatus.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAllocStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAllocStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAllocStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAllocStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    started: (f = msg.getStarted()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    completed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    progressLinesList: jspb.Message.toObjectList(msg.getProgressLinesList(),
    proto.galaxymigrate.AutoAllocStatus.ProgressLine.toObject, includeInstance),
    connParams: (f = msg.getConnParams()) && proto.galaxymigrate.AutoAlloc.ConnParams.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 5, ""),
    id: jspb.Message.getFieldWithDefault(msg, 6, ""),
    plugin: jspb.Message.getFieldWithDefault(msg, 7, ""),
    action: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAllocStatus}
 */
proto.galaxymigrate.AutoAllocStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAllocStatus;
  return proto.galaxymigrate.AutoAllocStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAllocStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAllocStatus}
 */
proto.galaxymigrate.AutoAllocStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStarted(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompleted(value);
      break;
    case 3:
      var value = new proto.galaxymigrate.AutoAllocStatus.ProgressLine;
      reader.readMessage(value,proto.galaxymigrate.AutoAllocStatus.ProgressLine.deserializeBinaryFromReader);
      msg.addProgressLines(value);
      break;
    case 4:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.deserializeBinaryFromReader);
      msg.setConnParams(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlugin(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAllocStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAllocStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAllocStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAllocStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStarted();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCompleted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getProgressLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.galaxymigrate.AutoAllocStatus.ProgressLine.serializeBinaryToWriter
    );
  }
  f = message.getConnParams();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPlugin();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.AutoAllocStatus.ProgressLine.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.AutoAllocStatus.ProgressLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.AutoAllocStatus.ProgressLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAllocStatus.ProgressLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.AutoAllocStatus.ProgressLine}
 */
proto.galaxymigrate.AutoAllocStatus.ProgressLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.AutoAllocStatus.ProgressLine;
  return proto.galaxymigrate.AutoAllocStatus.ProgressLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.AutoAllocStatus.ProgressLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.AutoAllocStatus.ProgressLine}
 */
proto.galaxymigrate.AutoAllocStatus.ProgressLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.AutoAllocStatus.ProgressLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.AutoAllocStatus.ProgressLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.AutoAllocStatus.ProgressLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.AutoAllocStatus.ProgressLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.AutoAllocStatus.ProgressLine.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.AutoAllocStatus.ProgressLine} returns this
*/
proto.galaxymigrate.AutoAllocStatus.ProgressLine.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAllocStatus.ProgressLine} returns this
 */
proto.galaxymigrate.AutoAllocStatus.ProgressLine.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAllocStatus.ProgressLine.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.galaxymigrate.AutoAllocStatus.ProgressLine.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAllocStatus.ProgressLine} returns this
 */
proto.galaxymigrate.AutoAllocStatus.ProgressLine.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp started = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.AutoAllocStatus.prototype.getStarted = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.AutoAllocStatus} returns this
*/
proto.galaxymigrate.AutoAllocStatus.prototype.setStarted = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAllocStatus} returns this
 */
proto.galaxymigrate.AutoAllocStatus.prototype.clearStarted = function() {
  return this.setStarted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAllocStatus.prototype.hasStarted = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool completed = 2;
 * @return {boolean}
 */
proto.galaxymigrate.AutoAllocStatus.prototype.getCompleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.AutoAllocStatus} returns this
 */
proto.galaxymigrate.AutoAllocStatus.prototype.setCompleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated ProgressLine progress_lines = 3;
 * @return {!Array<!proto.galaxymigrate.AutoAllocStatus.ProgressLine>}
 */
proto.galaxymigrate.AutoAllocStatus.prototype.getProgressLinesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.AutoAllocStatus.ProgressLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.AutoAllocStatus.ProgressLine, 3));
};


/**
 * @param {!Array<!proto.galaxymigrate.AutoAllocStatus.ProgressLine>} value
 * @return {!proto.galaxymigrate.AutoAllocStatus} returns this
*/
proto.galaxymigrate.AutoAllocStatus.prototype.setProgressLinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.galaxymigrate.AutoAllocStatus.ProgressLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.AutoAllocStatus.ProgressLine}
 */
proto.galaxymigrate.AutoAllocStatus.prototype.addProgressLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.galaxymigrate.AutoAllocStatus.ProgressLine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.AutoAllocStatus} returns this
 */
proto.galaxymigrate.AutoAllocStatus.prototype.clearProgressLinesList = function() {
  return this.setProgressLinesList([]);
};


/**
 * optional AutoAlloc.ConnParams conn_params = 4;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams}
 */
proto.galaxymigrate.AutoAllocStatus.prototype.getConnParams = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams, 4));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams|undefined} value
 * @return {!proto.galaxymigrate.AutoAllocStatus} returns this
*/
proto.galaxymigrate.AutoAllocStatus.prototype.setConnParams = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.AutoAllocStatus} returns this
 */
proto.galaxymigrate.AutoAllocStatus.prototype.clearConnParams = function() {
  return this.setConnParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.AutoAllocStatus.prototype.hasConnParams = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string error = 5;
 * @return {string}
 */
proto.galaxymigrate.AutoAllocStatus.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAllocStatus} returns this
 */
proto.galaxymigrate.AutoAllocStatus.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string id = 6;
 * @return {string}
 */
proto.galaxymigrate.AutoAllocStatus.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAllocStatus} returns this
 */
proto.galaxymigrate.AutoAllocStatus.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string plugin = 7;
 * @return {string}
 */
proto.galaxymigrate.AutoAllocStatus.prototype.getPlugin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAllocStatus} returns this
 */
proto.galaxymigrate.AutoAllocStatus.prototype.setPlugin = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string action = 8;
 * @return {string}
 */
proto.galaxymigrate.AutoAllocStatus.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.AutoAllocStatus} returns this
 */
proto.galaxymigrate.AutoAllocStatus.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.ListAutoAllocStatusesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.ListAutoAllocStatusesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.ListAutoAllocStatusesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListAutoAllocStatusesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.ListAutoAllocStatusesRequest}
 */
proto.galaxymigrate.ListAutoAllocStatusesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.ListAutoAllocStatusesRequest;
  return proto.galaxymigrate.ListAutoAllocStatusesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.ListAutoAllocStatusesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.ListAutoAllocStatusesRequest}
 */
proto.galaxymigrate.ListAutoAllocStatusesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.ListAutoAllocStatusesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.ListAutoAllocStatusesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.ListAutoAllocStatusesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListAutoAllocStatusesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.ListAutoAllocStatusesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.ListAutoAllocStatusesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.ListAutoAllocStatusesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.ListAutoAllocStatusesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListAutoAllocStatusesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.galaxymigrate.AutoAllocStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.ListAutoAllocStatusesResponse}
 */
proto.galaxymigrate.ListAutoAllocStatusesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.ListAutoAllocStatusesResponse;
  return proto.galaxymigrate.ListAutoAllocStatusesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.ListAutoAllocStatusesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.ListAutoAllocStatusesResponse}
 */
proto.galaxymigrate.ListAutoAllocStatusesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAllocStatus;
      reader.readMessage(value,proto.galaxymigrate.AutoAllocStatus.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.ListAutoAllocStatusesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.ListAutoAllocStatusesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.ListAutoAllocStatusesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListAutoAllocStatusesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxymigrate.AutoAllocStatus.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AutoAllocStatus items = 1;
 * @return {!Array<!proto.galaxymigrate.AutoAllocStatus>}
 */
proto.galaxymigrate.ListAutoAllocStatusesResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxymigrate.AutoAllocStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.AutoAllocStatus, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.AutoAllocStatus>} value
 * @return {!proto.galaxymigrate.ListAutoAllocStatusesResponse} returns this
*/
proto.galaxymigrate.ListAutoAllocStatusesResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.AutoAllocStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.AutoAllocStatus}
 */
proto.galaxymigrate.ListAutoAllocStatusesResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.AutoAllocStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.ListAutoAllocStatusesResponse} returns this
 */
proto.galaxymigrate.ListAutoAllocStatusesResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetAutoAllocStatusDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetAutoAllocStatusDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetAutoAllocStatusDetailsRequest}
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetAutoAllocStatusDetailsRequest;
  return proto.galaxymigrate.GetAutoAllocStatusDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetAutoAllocStatusDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetAutoAllocStatusDetailsRequest}
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetAutoAllocStatusDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetAutoAllocStatusDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetAutoAllocStatusDetailsRequest} returns this
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetAutoAllocStatusDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetAutoAllocStatusDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && proto.galaxymigrate.AutoAllocStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetAutoAllocStatusDetailsResponse}
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetAutoAllocStatusDetailsResponse;
  return proto.galaxymigrate.GetAutoAllocStatusDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetAutoAllocStatusDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetAutoAllocStatusDetailsResponse}
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAllocStatus;
      reader.readMessage(value,proto.galaxymigrate.AutoAllocStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetAutoAllocStatusDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetAutoAllocStatusDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAllocStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutoAllocStatus status = 1;
 * @return {?proto.galaxymigrate.AutoAllocStatus}
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsResponse.prototype.getStatus = function() {
  return /** @type{?proto.galaxymigrate.AutoAllocStatus} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAllocStatus, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAllocStatus|undefined} value
 * @return {!proto.galaxymigrate.GetAutoAllocStatusDetailsResponse} returns this
*/
proto.galaxymigrate.GetAutoAllocStatusDetailsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetAutoAllocStatusDetailsResponse} returns this
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetAutoAllocStatusDetailsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetHostEnvironmentInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetHostEnvironmentInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    conn: (f = msg.getConn()) && proto.galaxymigrate.AutoAlloc.ConnParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoRequest}
 */
proto.galaxymigrate.GetHostEnvironmentInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetHostEnvironmentInfoRequest;
  return proto.galaxymigrate.GetHostEnvironmentInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoRequest}
 */
proto.galaxymigrate.GetHostEnvironmentInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAlloc.ConnParams;
      reader.readMessage(value,proto.galaxymigrate.AutoAlloc.ConnParams.deserializeBinaryFromReader);
      msg.setConn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetHostEnvironmentInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetHostEnvironmentInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAlloc.ConnParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutoAlloc.ConnParams conn = 1;
 * @return {?proto.galaxymigrate.AutoAlloc.ConnParams}
 */
proto.galaxymigrate.GetHostEnvironmentInfoRequest.prototype.getConn = function() {
  return /** @type{?proto.galaxymigrate.AutoAlloc.ConnParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAlloc.ConnParams, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAlloc.ConnParams|undefined} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoRequest} returns this
*/
proto.galaxymigrate.GetHostEnvironmentInfoRequest.prototype.setConn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoRequest} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoRequest.prototype.clearConn = function() {
  return this.setConn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoRequest.prototype.hasConn = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    autoAllocStatus: (f = msg.getAutoAllocStatus()) && proto.galaxymigrate.AutoAllocStatus.toObject(includeInstance, f),
    info: (f = msg.getInfo()) && proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetHostEnvironmentInfoResponse;
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.AutoAllocStatus;
      reader.readMessage(value,proto.galaxymigrate.AutoAllocStatus.deserializeBinaryFromReader);
      msg.setAutoAllocStatus(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo;
      reader.readMessage(value,proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutoAllocStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.AutoAllocStatus.serializeBinaryToWriter
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,13]];

/**
 * @enum {number}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.InfoCase = {
  INFO_NOT_SET: 0,
  PURECBS: 1,
  AZURE: 2,
  DGS: 3,
  AWS: 4,
  NETAPP: 5,
  FC_PUREFA: 6,
  POWERSTORE: 7,
  FC_POWERSTORE: 8,
  FC_POWERMAX: 9,
  GCP: 10,
  ORACLE: 11,
  DIGITALOCEAN: 13
};

/**
 * @return {proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.InfoCase}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.getInfoCase = function() {
  return /** @type {proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.InfoCase} */(jspb.Message.computeOneofCase(this, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    purecbs: (f = msg.getPurecbs()) && proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.toObject(includeInstance, f),
    azure: (f = msg.getAzure()) && proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.toObject(includeInstance, f),
    dgs: (f = msg.getDgs()) && proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS.toObject(includeInstance, f),
    aws: (f = msg.getAws()) && proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS.toObject(includeInstance, f),
    netapp: (f = msg.getNetapp()) && proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp.toObject(includeInstance, f),
    fcPurefa: (f = msg.getFcPurefa()) && proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.toObject(includeInstance, f),
    powerstore: (f = msg.getPowerstore()) && proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.toObject(includeInstance, f),
    fcPowerstore: (f = msg.getFcPowerstore()) && proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.toObject(includeInstance, f),
    fcPowermax: (f = msg.getFcPowermax()) && proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax.toObject(includeInstance, f),
    gcp: (f = msg.getGcp()) && proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP.toObject(includeInstance, f),
    oracle: (f = msg.getOracle()) && proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle.toObject(includeInstance, f),
    digitalocean: (f = msg.getDigitalocean()) && proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo;
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS;
      reader.readMessage(value,proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.deserializeBinaryFromReader);
      msg.setPurecbs(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure;
      reader.readMessage(value,proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.deserializeBinaryFromReader);
      msg.setAzure(value);
      break;
    case 3:
      var value = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS;
      reader.readMessage(value,proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS.deserializeBinaryFromReader);
      msg.setDgs(value);
      break;
    case 4:
      var value = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS;
      reader.readMessage(value,proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS.deserializeBinaryFromReader);
      msg.setAws(value);
      break;
    case 5:
      var value = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp;
      reader.readMessage(value,proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp.deserializeBinaryFromReader);
      msg.setNetapp(value);
      break;
    case 6:
      var value = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS;
      reader.readMessage(value,proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.deserializeBinaryFromReader);
      msg.setFcPurefa(value);
      break;
    case 7:
      var value = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore;
      reader.readMessage(value,proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.deserializeBinaryFromReader);
      msg.setPowerstore(value);
      break;
    case 8:
      var value = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore;
      reader.readMessage(value,proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.deserializeBinaryFromReader);
      msg.setFcPowerstore(value);
      break;
    case 9:
      var value = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax;
      reader.readMessage(value,proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax.deserializeBinaryFromReader);
      msg.setFcPowermax(value);
      break;
    case 10:
      var value = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP;
      reader.readMessage(value,proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP.deserializeBinaryFromReader);
      msg.setGcp(value);
      break;
    case 11:
      var value = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle;
      reader.readMessage(value,proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle.deserializeBinaryFromReader);
      msg.setOracle(value);
      break;
    case 13:
      var value = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean;
      reader.readMessage(value,proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean.deserializeBinaryFromReader);
      msg.setDigitalocean(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPurecbs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.serializeBinaryToWriter
    );
  }
  f = message.getAzure();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.serializeBinaryToWriter
    );
  }
  f = message.getDgs();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS.serializeBinaryToWriter
    );
  }
  f = message.getAws();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS.serializeBinaryToWriter
    );
  }
  f = message.getNetapp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp.serializeBinaryToWriter
    );
  }
  f = message.getFcPurefa();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.serializeBinaryToWriter
    );
  }
  f = message.getPowerstore();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.serializeBinaryToWriter
    );
  }
  f = message.getFcPowerstore();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.serializeBinaryToWriter
    );
  }
  f = message.getFcPowermax();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax.serializeBinaryToWriter
    );
  }
  f = message.getGcp();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP.serializeBinaryToWriter
    );
  }
  f = message.getOracle();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle.serializeBinaryToWriter
    );
  }
  f = message.getDigitalocean();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS;
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.toObject = function(includeInstance, msg) {
  var f, obj = {
    location: jspb.Message.getFieldWithDefault(msg, 1, ""),
    zonesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    ultraSsdEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    subscriptionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    resourceGroupName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure;
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addZones(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUltraSsdEnabled(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceGroupName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getZonesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getUltraSsdEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getResourceGroupName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string location = 1;
 * @return {string}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string zones = 2;
 * @return {!Array<string>}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.getZonesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.setZonesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.addZones = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.clearZonesList = function() {
  return this.setZonesList([]);
};


/**
 * optional bool ultra_ssd_enabled = 3;
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.getUltraSsdEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.setUltraSsdEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string subscription_id = 4;
 * @return {string}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.getSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.setSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string resource_group_name = 6;
 * @return {string}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.getResourceGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure.prototype.setResourceGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS;
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS;
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp;
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore;
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax;
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP;
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle;
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean;
  return proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * optional PureCBS purecbs = 1;
 * @return {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.getPurecbs = function() {
  return /** @type{?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS, 1));
};


/**
 * @param {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS|undefined} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
*/
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.setPurecbs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.clearPurecbs = function() {
  return this.setPurecbs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.hasPurecbs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Azure azure = 2;
 * @return {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.getAzure = function() {
  return /** @type{?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure, 2));
};


/**
 * @param {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Azure|undefined} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
*/
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.setAzure = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.clearAzure = function() {
  return this.setAzure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.hasAzure = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DGS dgs = 3;
 * @return {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.getDgs = function() {
  return /** @type{?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS, 3));
};


/**
 * @param {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DGS|undefined} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
*/
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.setDgs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.clearDgs = function() {
  return this.setDgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.hasDgs = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AWS aws = 4;
 * @return {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.getAws = function() {
  return /** @type{?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS, 4));
};


/**
 * @param {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.AWS|undefined} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
*/
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.setAws = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.clearAws = function() {
  return this.setAws(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.hasAws = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NetApp netapp = 5;
 * @return {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.getNetapp = function() {
  return /** @type{?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp, 5));
};


/**
 * @param {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.NetApp|undefined} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
*/
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.setNetapp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.clearNetapp = function() {
  return this.setNetapp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.hasNetapp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PureCBS fc_purefa = 6;
 * @return {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.getFcPurefa = function() {
  return /** @type{?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS, 6));
};


/**
 * @param {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.PureCBS|undefined} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
*/
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.setFcPurefa = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.clearFcPurefa = function() {
  return this.setFcPurefa(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.hasFcPurefa = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional EMCPowerStore powerstore = 7;
 * @return {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.getPowerstore = function() {
  return /** @type{?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore, 7));
};


/**
 * @param {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore|undefined} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
*/
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.setPowerstore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.clearPowerstore = function() {
  return this.setPowerstore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.hasPowerstore = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional EMCPowerStore fc_powerstore = 8;
 * @return {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.getFcPowerstore = function() {
  return /** @type{?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore, 8));
};


/**
 * @param {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerStore|undefined} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
*/
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.setFcPowerstore = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.clearFcPowerstore = function() {
  return this.setFcPowerstore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.hasFcPowerstore = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EMCPowerMax fc_powermax = 9;
 * @return {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.getFcPowermax = function() {
  return /** @type{?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax, 9));
};


/**
 * @param {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.EMCPowerMax|undefined} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
*/
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.setFcPowermax = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.clearFcPowermax = function() {
  return this.setFcPowermax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.hasFcPowermax = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional GCP gcp = 10;
 * @return {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.getGcp = function() {
  return /** @type{?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP, 10));
};


/**
 * @param {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.GCP|undefined} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
*/
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.setGcp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.clearGcp = function() {
  return this.setGcp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.hasGcp = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Oracle oracle = 11;
 * @return {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.getOracle = function() {
  return /** @type{?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle, 11));
};


/**
 * @param {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.Oracle|undefined} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
*/
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.setOracle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.clearOracle = function() {
  return this.setOracle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.hasOracle = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional DigitalOcean digitalocean = 13;
 * @return {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.getDigitalocean = function() {
  return /** @type{?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean, 13));
};


/**
 * @param {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.DigitalOcean|undefined} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
*/
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.setDigitalocean = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.clearDigitalocean = function() {
  return this.setDigitalocean(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo.prototype.hasDigitalocean = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional AutoAllocStatus auto_alloc_status = 1;
 * @return {?proto.galaxymigrate.AutoAllocStatus}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.prototype.getAutoAllocStatus = function() {
  return /** @type{?proto.galaxymigrate.AutoAllocStatus} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.AutoAllocStatus, 1));
};


/**
 * @param {?proto.galaxymigrate.AutoAllocStatus|undefined} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse} returns this
*/
proto.galaxymigrate.GetHostEnvironmentInfoResponse.prototype.setAutoAllocStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.prototype.clearAutoAllocStatus = function() {
  return this.setAutoAllocStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.prototype.hasAutoAllocStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional HostEnvironmentInfo info = 2;
 * @return {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.prototype.getInfo = function() {
  return /** @type{?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo, 2));
};


/**
 * @param {?proto.galaxymigrate.GetHostEnvironmentInfoResponse.HostEnvironmentInfo|undefined} value
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse} returns this
*/
proto.galaxymigrate.GetHostEnvironmentInfoResponse.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetHostEnvironmentInfoResponse} returns this
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetHostEnvironmentInfoResponse.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.galaxymigrate);
