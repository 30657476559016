/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: FormComponents
 */
import * as React from "react";
import {
    Box,
    Checkbox,
    CheckboxProps,
    FilledTextFieldProps,
    FormControl,
    InputAdornment,
    InputLabel,
    SelectProps,
    TextField,
    Theme,
    Select,
    OutlinedInput,
    FormHelperText,
    MenuItem,
    Chip,
    FilledInput,
    StandardTextFieldProps,
    OutlinedTextFieldProps,
    Radio,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Typography,
    CircularProgress,
    SelectChangeEvent,
    AutocompleteRenderInputParams,
    AutocompleteChangeReason,
    AutocompleteChangeDetails,
    Autocomplete,
    Grid,
    Card,
    IconButton,
} from "@mui/material";
import { FieldProps, useField } from "formik";
import { observer } from "mobx-react-lite";
import { PagerMeta } from "../../_proto/galaxycompletepb/commonpb/datafilter_pb";
import { ServerListData } from "../../modules/core/data/ListData";
import { ChangeEvent, KeyboardEvent, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { LocalizationProvider, MobileTimePicker, MobileTimePickerProps, MobileDateTimePicker, MobileDateTimePickerProps } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffectOnce } from "react-use";
import { AutocompleteProps } from "@mui/material";
import { SelectableCard, SelectableCardProps } from "../card/SelectableCard";
import Dropzone, { useDropzone } from "react-dropzone";
import { CloseIcon } from "../CommonIcons";

// ======================
// FormikTextField
// ======================
interface FormTextFieldProps {
    label: string;
    name: string;
    icon?: React.ReactElement;
    helperText?: string;
    overrideInitialValue?: boolean;
}

export const FormTextField: React.FC<
    FormTextFieldProps & Partial<OutlinedTextFieldProps | FilledTextFieldProps | StandardTextFieldProps> & Partial<FieldProps>
> = observer(({ ...p }) => {
    const [field, meta, helpers] = useField(p.name);
    const error = meta.touched ? meta.error : null;
    const inputProps = {
        ...(p.InputProps || {}),
        startAdornment: p.icon ? <InputAdornment position={"start"}>{p.icon}</InputAdornment> : null,
    };

    const { current: hlprs } = useRef(helpers);

    useEffect(() => {
        const setValue = hlprs.setValue;

        if (p.defaultValue !== undefined) {
            if (p.overrideInitialValue || !meta.initialValue) {
                setValue(p.defaultValue);
            }
        }
    }, [meta.initialValue, p.defaultValue, p.overrideInitialValue, hlprs]);

    const forceLowerCase = {
        onKeyUp: (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            helpers.setValue(meta.value.toLowerCase());
        },
    };

    const getHelperText = () => {
        if (!!error) {
            return `${error}`;
        } else {
            return `${p.helperText || ""}`;
        }
    };

    return (
        <TextField
            fullWidth
            {...p}
            {...field}
            value={meta.value}
            error={!!error}
            label={p.label}
            InputLabelProps={{ id: p.name }}
            inputProps={{
                "aria-labelledby": p.name,
            }}
            helperText={getHelperText()}
            variant={"filled"}
            InputProps={inputProps}
        />
    );
});

interface FormCheckboxItemProps {
    label: string;
    name: string;
    helperText?: React.ReactNode;
    checkBoxProps?: Partial<CheckboxProps>;
    defaultChecked?: boolean;
}

export const FormCheckboxItem: React.FC<FormCheckboxItemProps & Partial<CheckboxProps> & Partial<FieldProps>> = observer(({ label, helperText, ...p }) => {
    const [field, meta] = useField(p.name);
    const error = meta.touched ? meta.error : null;

    return (
        <Box pt={1} pb={1}>
            <FormControlLabel
                id={p.name}
                control={
                    <Checkbox
                        inputProps={{ "aria-labelledby": p.name, id: p.name }}
                        {...p.checkBoxProps}
                        value={meta.value}
                        defaultChecked={p.defaultChecked}
                        checked={meta.value}
                        onChange={field.onChange}
                    />
                }
                label={
                    <Typography variant={"body2"} color={"textSecondary"}>
                        {label}
                    </Typography>
                }
            />
            <FormHelperText>{helperText}</FormHelperText>
        </Box>
    );
});

// ======================
// FormSelectableCardsGroup
// ======================

export interface FormSelectableCardConfig extends Omit<SelectableCardProps, "onSelect" | "selected"> {
    value: any;
}

interface FormSelectableCardsGroupProps {
    options: FormSelectableCardConfig[];
    name: string;
    cardSize?: "small" | "medium";
}

export const FormSelectableCardsGroup: React.FC<FormSelectableCardsGroupProps> = observer((p) => {
    const [field, meta, helpers] = useField(p.name);

    return (
        <Box>
            <Grid container spacing={3}>
                {p.options.map((o) => {
                    const selected = o.value === field.value;
                    const onSelect = () => {
                        helpers.setValue(o.value);
                    };
                    return (
                        <Grid item xs={12} sm={6} md={p.cardSize === "small" ? 3 : 4}>
                            <SelectableCard selected={selected} onSelect={onSelect} {...o} sx={{ height: "100%" }} />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
});

// ======================
// FormRadioGroup
// ======================

interface RadioGroupOptionConfig {
    label: string;
    value: any;
    helperText?: React.ReactNode;
    disabled?: boolean;
}

interface FormRadioGroupProps {
    label: string;
    name: string;
    options: RadioGroupOptionConfig[];
    helperText?: React.ReactNode;
    required?: boolean;
}

export const FormRadioGroup: React.FC<FormRadioGroupProps & Partial<FieldProps>> = observer((props) => {
    const [field, meta, helpers] = useField(props.name);
    const error = meta.touched ? meta.error : null;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        helpers.setValue(event.target.value.toString());
    };
    return (
        <FormControl component={"fieldset"} error={!!error} variant={"filled"} fullWidth>
            <FormLabel component={"legend"} error={!!error} required={!!props.required} id={props.name}>
                {props.label}
            </FormLabel>
            <RadioGroup name={props.name} value={field.value.toString()} onChange={handleChange}>
                {props.options.map((option, index) => {
                    return (
                        <Box key={index}>
                            <FormControlLabel
                                disabled={!!option.disabled}
                                id={option.label}
                                value={option.value.toString()}
                                control={<Radio disabled={!!option.disabled} inputProps={{ "aria-labelledby": option.label }} />}
                                label={option.label}
                            />
                            {option.helperText && (
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {option.helperText}
                                </Typography>
                            )}
                        </Box>
                    );
                })}
            </RadioGroup>
            <FormHelperText error={!!error} variant={"outlined"}>
                {error}
            </FormHelperText>
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
    );
});

// ======================
// FormSelect
// ======================

interface SelectItemType {
    label: string;
    value: string | number;
}

interface FormSelectProps {
    label: string;
    name: string;
    selectionList: Array<SelectItemType> | Array<string>;
    // render menu item
    listRenderer?: (array: Array<SelectItemType> | Array<string>) => React.ReactNode;
    listFilterer?: (array: Array<SelectItemType> | Array<string>) => Array<SelectItemType> | Array<string>;
    disabled?: boolean;
    required?: boolean;
}

export const FormSelect = observer((props: FormSelectProps & Partial<SelectProps>) => {
    const [field, meta, helpers] = useField(props.name);
    const error = meta.touched || !!meta.value ? meta.error : null;
    const { value } = meta;
    const { setValue } = helpers;

    const initialValue = typeof meta.initialValue === "string" ? meta.initialValue : meta.initialValue?.toString();
    const defaultValue = props.defaultValue
        ? typeof props.defaultValue === "string"
            ? props.defaultValue
            : (props.defaultValue as SelectItemType).value.toString()
        : null;

    useEffect(() => {
        if (!initialValue && !!defaultValue) {
            helpers.setValue(defaultValue);
        }
    }, [defaultValue, initialValue, helpers]);

    const filteredList = !!props.listFilterer ? props.listFilterer(props.selectionList) : props.selectionList;

    const renderList = (list: Array<SelectItemType> | Array<string>) => {
        return list.map((item: SelectItemType | string) => {
            if (typeof item === "string") {
                return (
                    <MenuItem key={item as string} value={item as string}>
                        {item}
                    </MenuItem>
                );
            } else {
                return (
                    <MenuItem key={(item as SelectItemType).value} value={(item as SelectItemType).value}>
                        {(item as SelectItemType).label}
                    </MenuItem>
                );
            }
        });
    };

    const handleChange = (event: SelectChangeEvent<any>, child: ReactNode) => {
        setValue(event.target.value as string);
    };

    return (
        <>
            <FormControl fullWidth>
                <InputLabel variant={"filled"} required={props.required}>
                    {props.label}
                </InputLabel>

                <Select
                    multiple={props.multiple}
                    fullWidth
                    {...props}
                    sx={{
                        minWidth: 200,
                    }}
                    value={value}
                    onChange={handleChange}
                    variant={"filled"}
                    error={!!error}
                    input={<FilledInput disabled={props.disabled} required={props.required} />}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                    }}
                    inputProps={{
                        "aria-label": props.label,
                    }}
                >
                    {!!props.listRenderer ? props.listRenderer(filteredList) : renderList(filteredList)}
                </Select>
                <FormHelperText error={!!error} variant={"outlined"}>
                    {error}
                </FormHelperText>
            </FormControl>
        </>
    );
});

// ======================
// FormAutocompleteField
// ======================

interface FormAutocompleteFieldProps<OptionType> {
    label: string;
    name: string;
    errorGetter?: (value: any) => string;
    valueGetter?: (value: any) => any;
    customOptionsFilter?: (options: Array<OptionType>) => Promise<Array<OptionType>> | ((options: Array<OptionType>) => Array<OptionType>);
}

export const FormAutocompleteField = observer(
    <OptionType, Multiple extends boolean | undefined, DisableClearable extends boolean | undefined, FreeSolo extends boolean | undefined>(
        props: FormAutocompleteFieldProps<OptionType> & Partial<AutocompleteProps<OptionType, Multiple, DisableClearable, FreeSolo>>
    ) => {
        const { label, name, valueGetter, customOptionsFilter } = props;
        const [field, meta, helpers] = useField(name);
        const error = meta.touched ? (meta.error ? (props.errorGetter ? props.errorGetter(meta.error) : meta.error) : null) : null;
        const { value } = meta;

        const initialValue = meta.initialValue ? (props.valueGetter ? props.valueGetter(meta.initialValue).toString() : meta.initialValue) : null;
        const defaultValue = props.defaultValue ? (props.valueGetter ? props.valueGetter(props.defaultValue).toString() : props.defaultValue) : null;

        useEffect(() => {
            if (!initialValue && !!defaultValue) {
                helpers.setValue(props.defaultValue);
            }
        }, [defaultValue, initialValue, props.defaultValue, helpers]);

        const handleChange = (event: ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => {
            if (reason === "selectOption") {
                helpers.setValue(value);
            }
            if (reason === "clear" || reason === "removeOption") {
                helpers.setValue("");
            }
        };

        return (
            <Autocomplete
                noOptionsText={"No available options."}
                defaultValue={props.defaultValue}
                {...props}
                id={name}
                options={props.options}
                value={value}
                onChange={handleChange}
                renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                        {...params}
                        label={label}
                        helperText={error}
                        error={!!error}
                        variant="filled"
                        InputProps={{
                            ...params.InputProps,
                        }}
                    />
                )}
            />
        );
    }
);

interface FormAsyncAutocompleteFieldProps<T, K> {
    label: string;
    name: string;
    dataFetcher: () => Promise<T>;
    errorGetter?: (value: any) => string;
    valueGetter?: (value: any) => any;
    customOptionsFilter?: (options: Array<K>) => Promise<Array<K>> | ((options: Array<K>) => Array<K>);
    required?: boolean;
}

export const FormAsyncAutocompleteField: React.FC<FormAsyncAutocompleteFieldProps<any, any> & Partial<AutocompleteProps<any, any, any, any>>> = observer(
    (props) => {
        const { label, name, dataFetcher, valueGetter, customOptionsFilter, required, ...rest } = props;
        const [field, meta, helpers] = useField(name);
        const error = meta.touched ? (meta.error ? (props.errorGetter ? props.errorGetter(meta.error) : meta.error) : null) : null;

        const handleChange = (event: ChangeEvent<{}>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => {
            if (reason === "selectOption") {
                if (!!valueGetter) {
                    helpers.setValue(valueGetter(value));
                } else {
                    helpers.setValue(value);
                }
            }
            if (reason === "clear" || reason === "removeOption") {
                helpers.setValue("");
            }
        };

        const [open, setOpen] = React.useState(false);
        const [options, setOptions] = React.useState([]);
        const [loaded, setLoaded] = React.useState(false);
        const loading = open && !loaded;

        React.useEffect(() => {
            let active = true;
            setLoaded(false);

            (async () => {
                const ListData = new ServerListData<{ getItemsList: () => Array<any>; getPagerMeta: () => PagerMeta }, any>(10000).setDataFetcher(dataFetcher);
                const response = await ListData.fetchData();
                if (active && response) {
                    setLoaded(true);
                    if (!!customOptionsFilter) {
                        let filteredOptions = await customOptionsFilter(ListData.data.getItemsList());
                        setOptions(filteredOptions);
                    } else {
                        setOptions(ListData.data.getItemsList());
                    }
                }
            })();

            return () => {
                active = false;
                setLoaded(false);
            };
        }, [open, customOptionsFilter, dataFetcher]);

        React.useEffect(() => {
            if (!open) {
                setOptions([]);
            }
        }, [open]);

        return (
            <>
                <Autocomplete
                    noOptionsText={"No available options."}
                    freeSolo={false}
                    autoSelect={true}
                    {...rest}
                    id={name}
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    options={options}
                    onChange={handleChange}
                    loading={loading}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                            {...params}
                            {...field}
                            required={required}
                            label={label}
                            helperText={error}
                            error={!!error}
                            variant={"filled"}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
            </>
        );
    }
);

// ======================
// FormDateTimePicker
// ======================
interface FormDateTimePickerProps {
    label: string;
    name: string;
    helperText?: string;
    defaultValue?: Date;
    getErrorMessage?: (reason: string, value: Date) => string;
}

export const FormDateTimePicker: React.FC<FormTimePickerProps & Partial<MobileDateTimePickerProps<any>>> = observer(({ ...p }) => {
    const { label, name, helperText, defaultValue, getErrorMessage, ...dateTimePickerProps } = p;

    const [field, meta, helpers] = useField(p.name);
    const [dateTimePickerError, setDateTimePickerError] = useState("");

    useEffectOnce(() => {
        if (!meta.initialValue) {
            if (!!p.defaultValue || p.defaultValue === null) {
                helpers.setValue(p.defaultValue);
            }
        }
    });

    const onChange = (date: unknown) => {
        helpers.setValue(date);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
                slotProps={{
                    textField: {
                        error: !!meta.error || !!dateTimePickerError,
                        helperText: dateTimePickerError || meta.error || p.helperText,
                        fullWidth: true,
                        variant: "filled",
                    },
                }}
                label={p.label}
                value={meta.value}
                onChange={onChange}
                onError={(reason, value) => {
                    let errorMessage: string = reason;
                    if (!!getErrorMessage) {
                        errorMessage = getErrorMessage(reason, value);
                    }
                    setDateTimePickerError(errorMessage);
                }}
                {...dateTimePickerProps}
            />
        </LocalizationProvider>
    );
});

// ======================
// FormDateTimePicker
// ======================
interface FormTimePickerProps {
    label: string;
    name: string;
    helperText?: string;
    defaultValue?: Date;
    getErrorMessage?: (reason: string, value: Date) => string;
}

export const FormTimePicker: React.FC<FormTimePickerProps & Partial<MobileTimePickerProps<any, any>>> = observer((p) => {
    const { label, name, helperText, defaultValue, getErrorMessage, ...timePickerProps } = p;
    const [field, meta, helpers] = useField(p.name);
    const [timePickerError, setTimePickerError] = useState("");

    useEffectOnce(() => {
        if (!meta.initialValue) {
            if (!!p.defaultValue || p.defaultValue === null) {
                helpers.setValue(p.defaultValue);
            }
        }
    });

    const onChange = (date: unknown) => {
        helpers.setValue(date);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileTimePicker
                value={meta.value}
                onChange={onChange}
                onError={(reason, value) => {
                    let errorMessage: string = reason;
                    if (!!getErrorMessage) {
                        errorMessage = getErrorMessage(reason, value);
                    }
                    setTimePickerError(errorMessage);
                }}
                slotProps={{
                    textField: {
                        error: !!meta.error || !!timePickerError,
                        helperText: timePickerError || meta.error || p.helperText,
                        fullWidth: true,
                        variant: "filled",
                    },
                }}
                label={p.label}
                {...timePickerProps}
            />
        </LocalizationProvider>
    );
});

// ======================
// FormTextFileDropzone
// ======================

interface FormTextFileDropzoneProps {
    label: string;
    name: string;
    maxFiles: number;
    accept: string[];
    instructions?: string;
}

export const FormTextFileDropzone: React.FC<FormTextFileDropzoneProps> = observer((p) => {
    const [field, meta, helpers] = useField(p.name);
    const [files, setFiles] = useState<File[]>([]);

    const cardStyle = {
        backgroundColor: "rgba(255, 255, 255, .09)",
        border: "1px solid",
        borderColor: "rgba(255, 255, 255, .7)",
        transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        cursor: "pointer",
    };

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            setFiles(acceptedFiles);
            acceptedFiles.forEach((file: File) => {
                const reader = new FileReader();
                reader.addEventListener(
                    "load",
                    () => {
                        if (p.maxFiles > 1) {
                            const newArray = (meta.value as any[]).concat([reader.result]);
                            helpers.setValue(newArray);
                        } else {
                            helpers.setValue(reader.result);
                        }
                    },
                    false
                );

                if (file) {
                    reader.readAsText(file);
                }
            });
        },
        [helpers, meta.value, p.maxFiles]
    );

    const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
        accept: p.accept,
        maxFiles: p.maxFiles,
        onDrop,
        maxSize: 5242880,
    });

    const onDeleteFile = (acceptedFiles: File[], index: number) => {
        acceptedFiles.splice(index, 1);
        setFiles([...acceptedFiles]);
        if (!!meta.value.length) {
            helpers.setValue(meta.value.splice(index, 1));
        } else {
            helpers.setValue(null);
        }
    };

    return (
        <>
            {(!files.length || p.maxFiles > 1) && (
                <Box {...getRootProps()} width={"100%"}>
                    <Card
                        sx={{
                            ...cardStyle,
                            "&:hover": {
                                backgroundColor: "rgba(255,255,255,.1)",
                            },
                        }}
                    >
                        <Box p={2}>
                            <input {...getInputProps()} />
                            <Typography sx={{ color: "rgba(255,255,255,.7)" }}>Drag and drop or click to select file{p.maxFiles > 1 ? "s" : ""}</Typography>
                            <Typography variant={"body2"} sx={{ color: "rgba(255,255,255,.7)" }}>
                                (Only {p.accept.join(", ")} files will be accepted)
                            </Typography>
                        </Box>
                    </Card>
                </Box>
            )}
            {fileRejections.length > 0 && (
                <Box>
                    {fileRejections.map((f, i) => {
                        return (
                            <Box key={i}>
                                <Typography variant={"caption"} sx={{ color: (t: Theme) => t.palette.error.main }}>
                                    {f.file.name} cannot be uploaded. &nbsp;
                                    {f.errors.map((e) => e.message).join(". ")}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            )}
            {files.map((f, i) => (
                <Card sx={cardStyle}>
                    <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography>{f.name}</Typography>
                        <IconButton onClick={() => onDeleteFile(acceptedFiles, i)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Card>
            ))}
        </>
    );
});
