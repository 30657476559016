import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import {Box, Button, Collapse, Divider, Grid, IconButton, Stack, SvgIcon, Typography, useTheme} from "@mui/material";

import * as React from "react";
import {useQuery} from "@tanstack/react-query";
import {useAppServices} from "../app/services";
import {QueryResultWrapper} from "../core/data/QueryResultWrapper";
import {ReportData, ReportInfo} from "../../_proto/galaxycompletepb/apipb/domainpb/report_pb";
import {getLicenseModelLabel} from "../license/LicenseCommon";
import {getHostEnvDisplayName} from "../galaxymigrate/GalaxyMigrateCommon";
import {
    convertTimestampObjectToDate,
    formatBool,
    formatDurationFromSeconds,
    formatKnownDataType,
    formatTitleCase,
    KnownDataType
} from "../../common/utils/formatter";
import {format, formatISO} from "date-fns";
import {getUserFullNameFromObject} from "../settings/ProjectUsers";
import {getImpactLevelConfig, getStorageConfigBlockDevice, renderGMSessionStatus} from "../migration/MigrationCommon";
import {MigrationSession} from "../../_proto/galaxymigratepb/galaxy_migrate_migration_pb";
import {MTDIDeploymentInfo} from "../../_proto/galaxycompletepb/apipb/domainpb/mtdi_pb";
import {useState} from "react";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {MdPrint} from "react-icons/md";
import CdsCloudLogo from '../../assets/Cirrus_Data_Cloud_Logo_Large_Dark.png';
import CdsLogo from '../../assets/logo300dark.png'

// ======================
// ViewReportScreen
// ======================


interface ViewReportScreenProps {
}

export const ViewReportScreen: React.FC<ViewReportScreenProps> = observer((p) => {
    const {reportId} = useParams();
    const theme      = useTheme();

    const {reportService} = useAppServices();
    const queryResult     = useQuery({
        queryKey: ['getReportData'],
        queryFn : async () => await reportService.getReportData(Number(reportId))
    })

    return <QueryResultWrapper queryResult={queryResult}>
        <Box sx={{
            '@media screen': {
                width: '100vw',
                height       : '100%',
            backgroundColor : theme.palette.cirrusGray.main,
            margin          : '0 auto',
                display: 'flex',
                justifyContent: 'center'
        },
            '@media print': {
                width: 'auto',
                border: 0,
                margin: '5%',
                padding: 0,
                float: 'none',
                position: 'static',
                overflow: 'visible'
            }
        }}
             >
            <Box pt={4} sx={{
                color           : 'black',
                '@media screen': {
                    width: '900px',
                    height: '100%',
                    backgroundColor: 'white',
                    borderLeft     : `1px solid ${theme.palette.cirrusGray.dark}`,
                    borderRight    : `1px solid ${theme.palette.cirrusGray.dark}`,
                },
            }}>
                <Box display={'flex'} justifyContent={'flex-end'} pr={6} sx={{
                    '@media print': {
                        display: 'none'
                    }
                }}>
                    <Button variant={'outlined'} onClick={()=>window.print()} startIcon={<SvgIcon><MdPrint/></SvgIcon>}>
                        {`Print Report`}
                    </Button>
                </Box>
                <MigrationSessionReport reportData={queryResult.data?.reportData}
                                        reportInfo={queryResult.data?.reportInfo}/>
            </Box>
        </Box>
    </QueryResultWrapper>
});

// ======================
// CollapsibleHeader
// ======================


interface CollapsibleHeaderProps {
    title: string,
    children: React.ReactNode
}

const CollapsibleHeader: React.FC<CollapsibleHeaderProps> = observer((p) => {
    const {title, children} = p;
    const theme            = useTheme();

    const [expanded, setExpanded] = useState(true);
    const icon                    = expanded ? <IoIosArrowUp/> : <IoIosArrowDown/>;

    return <Box>
        <Box sx={{
            backgroundColor: theme.palette.cirrus.main,
            color: 'white',
            '@media print': {
                color:'black',
            },
            '@media screen': {
                padding: 2,
                marginRight: 4
            }
        }}>
            <Box sx={{
                '@media print': {
                    display: 'block'
                },
                '@media screen': {
                    display: 'flex',
                    justifyContent:'space-between'
                }
            }}>
                <Typography variant={'h5'}>
                    {title}
                </Typography>
                <Box sx={{
                    '@media print': {
                        display: 'none'
                    }
                }}>
                    <IconButton onClick={() => setExpanded(!expanded)}>
                        {icon}
                    </IconButton>
                </Box>
            </Box>
        </Box>
        <Collapse in={expanded} orientation={'vertical'}>
            {children}
        </Collapse>
    </Box>
});

// ======================
// CollapsibleSubheader
// ======================

export const CollapsibleSubheader: React.FC<CollapsibleHeaderProps> = observer((p) => {
    const {title, children} = p;
    const theme            = useTheme();

    const [expanded, setExpanded] = useState(true);
    const icon                    = expanded ? <IoIosArrowUp/> : <IoIosArrowDown/>;

    return <Box>
        <Box sx={{
            '@media screen': {
                padding: 2,
                marginRight: 2,
                backgroundColor: theme.palette.cirrusGray.main,
                border         : `1px solid ${theme.palette.cirrusGray.dark}`
            },

        }}>
            <Box sx={{
                '@media print': {
                    display: 'block'
                },
                '@media screen': {
                    display: 'flex',
                    justifyContent:'space-between'
                }
            }}>
                <Typography variant={'h6'}>
                    {title}
                </Typography>
                <Box sx={{
                    '@media print': {
                        display: 'none'
                    }
                }}>
                    <IconButton onClick={() => setExpanded(!expanded)}>
                        <SvgIcon htmlColor={'black'}>
                            {icon}
                        </SvgIcon>
                    </IconButton>
                </Box>
            </Box>
        </Box>
        <Collapse in={expanded} orientation={'vertical'}>
            {children}
        </Collapse>
    </Box>

});

// ======================
// MigrationSessionReport
// ======================


interface MigrationSessionReportProps {
    reportData: ReportData.AsObject,
    reportInfo: ReportInfo.AsObject
}

export const MigrationSessionReport: React.FC<MigrationSessionReportProps> = observer((p) => {
    const {reportData, reportInfo}  = p;
    const isSourceDeploymentWindows = reportData.msr.sourceSystem?.info.osType === MTDIDeploymentInfo.OSType.WINDOWS;
    const isDestDeploymentWindows   = reportData.msr.destSystem?.info.osType === MTDIDeploymentInfo.OSType.WINDOWS;

    return <Box>
        <Box p={4} display={'flex'} justifyContent={'space-between'}>
            <Box>
                <Typography variant={'h4'}>
                    {`Cirrus Data Cloud`}
                </Typography>
                <Typography variant={'h4'}>
                    {`Migration Session Report`}
                </Typography>
            </Box>
            <Box>
                <Box>
                    <img src={CdsCloudLogo} width={'200'} height={'auto'} alt={'Cirrus Data Cloud'}/>
                </Box>
            </Box>
        </Box>
        <Box>
            <CollapsibleHeader title={'1. Environment'}>
                <Stack p={4} spacing={4}>
                    <CollapsibleSubheader title={'1.1 Project'}>
                        {!!reportData.msr.project ?
                                <Box pl={2} pt={1}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {`Project:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.project?.info.name}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Project Description:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.project?.info.description || 'N/A'}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`License Model:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {getLicenseModelLabel(reportData.msr.project?.info.licenseModel) || 'N/A'}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Project ID:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.project?.info.projectId}
                                        </Grid>
                                    </Grid>
                                </Box> :
                                <Typography>
                                    {'N/A'}
                                </Typography>
                        }
                    </CollapsibleSubheader>
                    <CollapsibleSubheader title={`1.2 ${reportData.msr.sourceSystem?.info.deployment.systemId === reportData.msr.destSystem?.info.deployment.systemId ? 'Migration Host' : 'Source Host'}`}>
                        {!!reportData.msr.sourceSystem ?
                                <Box pl={2} pt={1}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {`System Name:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.sourceSystem?.info.deployment.systemName}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Environment:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {getHostEnvDisplayName(reportData.msr.sourceSystem?.info.deployment.hostEnvironment.value)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Timezone:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.sourceSystem?.info.deployment.systemTimezone}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`CPU:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.sourceSystem?.info.cpu}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Memory:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(reportData.msr.sourceSystem?.info.memory, KnownDataType.CAPACITY)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Operating System:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.sourceSystem?.info.osClass}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Kernel Version:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.sourceSystem?.info.kernel}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`CMC / mTDI Software Version:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.sourceSystem?.info.deployment.version} {`/`} {reportData.msr.sourceSystem?.info.mtdiVersion}
                                        </Grid>
                                    </Grid>
                                </Box> :
                                <Typography>
                                    {'N/A'}
                                </Typography>

                        }
                    </CollapsibleSubheader>
                    {reportData.msr.sourceSystem?.info.deployment.systemId !== reportData.msr.destSystem?.info.deployment.systemId &&
                        <CollapsibleSubheader title={'1.3 Destination Host'}>
                            <Box pl={2} pt={1}>
                                <Grid container>
                                    <Grid item xs={4}>
                                        {`System Name:`}
                                    </Grid>
                                    <Grid item xs={8}>
                                        {reportData.msr.destSystem?.info.deployment.systemName}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {`Environment:`}
                                    </Grid>
                                    <Grid item xs={8}>
                                        {getHostEnvDisplayName(reportData.msr.destSystem?.info.deployment.hostEnvironment.value)}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {`Timezone:`}
                                    </Grid>
                                    <Grid item xs={8}>
                                        {reportData.msr.destSystem?.info.deployment.systemTimezone}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {`CPU:`}
                                    </Grid>
                                    <Grid item xs={8}>
                                        {reportData.msr.destSystem?.info.cpu}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {`Memory:`}
                                    </Grid>
                                    <Grid item xs={8}>
                                        {formatKnownDataType(reportData.msr.destSystem?.info.memory, KnownDataType.CAPACITY)}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {`Operating System:`}
                                    </Grid>
                                    <Grid item xs={8}>
                                        {reportData.msr.destSystem?.info.osClass}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {`Kernel Version:`}
                                    </Grid>
                                    <Grid item xs={8}>
                                        {reportData.msr.destSystem?.info.kernel}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {`CMC / mTDI Software Version:`}
                                    </Grid>
                                    <Grid item xs={8}>
                                        {reportData.msr.destSystem?.info.deployment.version} {`/`} {reportData.msr.destSystem?.info.mtdiVersion}
                                    </Grid>
                                </Grid>
                            </Box>
                        </CollapsibleSubheader>
                    }
                </Stack>

            </CollapsibleHeader>
        </Box>
        <Box>
            <CollapsibleHeader title={'2. Migration Session'}>
                <Stack p={4} spacing={4}>
                    <CollapsibleSubheader title={'2.1 Session Information'}>
                        {!!reportData.msr.session ?

                                <Box pl={2} pt={1}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {`Session ID:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.session?.sessionInfo.sessionId}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Description:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.session?.sessionInfo.description || 'N/A'}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Created At:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatISO(convertTimestampObjectToDate(reportData.msr.session?.sessionInfo.createdAt))}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Created By:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {getUserFullNameFromObject(reportData.msr.session?.createdBy) || 'N/A'} {!!reportData.msr.session?.createdBy && `(${reportData.msr.session?.createdBy?.email})`}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Total Capacity:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(reportData.msr.session?.totalCapacity, KnownDataType.CAPACITY)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Total Volumes:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {reportData.msr.session?.totalVolumes}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Boot Volume Migration:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatBool(reportData.msr.session?.sessionInfo.bootVolumeSession)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Compute Migration:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {!!reportData.msr.session?.sessionInfo.computeMigrationType ? getHostEnvDisplayName(reportData.msr.session?.sessionInfo.computeMigrationType) : 'No'}
                                        </Grid>
                                    </Grid>
                                </Box> :
                                <Typography>
                                    {'N/A'}
                                </Typography>
                        }
                    </CollapsibleSubheader>
                    <CollapsibleSubheader title={'2.2 Session Configuration'}>
                        {!!reportData.msr.session ?

                                <Stack pt={2} pr={2} pl={2} spacing={2}>
                                    <Box>
                                        <Typography fontWeight={600}>
                                            {`Auto Re-Sync`}
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                {'Re-Sync Interval:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {!!reportData.msr.session?.sessionInfo.autoSyncMinutes ? `${reportData.msr.session?.sessionInfo.autoSyncMinutes} Minutes` : 'Disabled'}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    {!reportData.msr.session?.sessionInfo.qosSchedule &&
                                            <Box>
                                                <Typography fontWeight={600}>
                                                    {`iQoS`}
                                                </Typography>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        {'iQoS Setting:'}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {`${getImpactLevelConfig(reportData.msr.session?.sessionInfo.qosLevel).title} – ${getImpactLevelConfig(reportData.msr.session?.sessionInfo.qosLevel).description}`}
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                    }
                                    <Box>
                                        <Typography fontWeight={600}>
                                            {`Migration Schedule`}
                                        </Typography>
                                        {!!reportData.msr.session?.sessionInfo.qosSchedule ?
                                                <Grid container>
                                                    {Object.keys(reportData.msr.session?.sessionInfo.qosSchedule).map(key => {
                                                        const schedule = reportData.msr.session?.sessionInfo.qosSchedule[key as keyof MigrationSession.ConfigParams.QosSchedule.AsObject]
                                                        return <React.Fragment key={key}>
                                                            <Grid item xs={4}>
                                                                {`${formatTitleCase(key)}:`}
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {schedule.intervalsList.map((interval, index) => {
                                                                    return <Typography key={index}>
                                                                        {format(new Date(2000, 1, 1, interval.start / 60, interval.start % 60), 'hh:mm aa')} - {format(new Date(2000, 1, 1, interval.end / 60, interval.end % 60 + 1), 'hh:mm aa',)} {getImpactLevelConfig(interval.qosLevel).title}
                                                                    </Typography>
                                                                })}
                                                            </Grid>
                                                        </React.Fragment>
                                                    })

                                                    }
                                                </Grid> :
                                                <Typography>
                                                    {'Migration schedule not enabled.'}
                                                </Typography>
                                        }
                                    </Box>
                                    {!!reportData.msr.session?.sessionInfo.computeMigrationSpec &&
                                            <Box>
                                                <Typography fontWeight={600}>
                                                    {`Compute Migration Specification (${getHostEnvDisplayName(reportData.msr.session?.sessionInfo.computeMigrationType)})`}
                                                </Typography>
                                                <Typography>
                                                    Upon cutover, the new virtual machine is made based on the following specification:
                                                </Typography>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        {'Virtual Machine Name:'}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.vmName}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {'Virtual Machine Description:'}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.vmDescription}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {'Datastore:'}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.datastore}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {'VM Folder:'}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.vmFolder || 'Default'}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {'Resource Pool:'}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.resourcePool || 'Default'}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {'CPU Sockets:'}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.cpuCount}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {'CPU Cores per Socket:'}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.cpuCoresPerSocket}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {'Memory:'}
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.memoryMib} {'MiB'}
                                                    </Grid>
                                                    {reportData.msr.session?.sessionInfo.computeMigrationSpec.vmwareSpec.networksList.map((network, index) => {
                                                        return <React.Fragment key={index}>
                                                            <Grid item xs={4}>
                                                                {`Network ${index + 1}`}
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <Typography>
                                                                    {`${network.networkName} (${network.adapterType})`}
                                                                </Typography>
                                                                {!!network.customMacAddress &&
                                                                        <Typography>
                                                                            {network.customMacAddress}
                                                                        </Typography>
                                                                }

                                                            </Grid>
                                                        </React.Fragment>
                                                    })
                                                    }
                                                </Grid>
                                            </Box>
                                    }

                                </Stack> :
                                <Typography>
                                    {'N/A'}
                                </Typography>
                        }
                    </CollapsibleSubheader>
                    <CollapsibleSubheader title={'2.3 Session State'}>
                        {!!reportData.msr.session ?
                                <Stack pt={2} pr={2} pl={2} spacing={2}>
                                    <Box>
                                        <Typography fontWeight={600}>
                                            {'Status'}
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                {'Current Status:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {renderGMSessionStatus(reportData.msr.session?.sessionInfo.sessionStatus)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {'Current iQoS:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {getImpactLevelConfig(reportData.msr.session?.sessionInfo.qosLevel)?.title || 'N/A'}
                                            </Grid>
                                            {!!reportData.msr.session?.sessionInfo.errorText &&
                                                    <>
                                                        <Grid item xs={4}>
                                                            {'Migration Error:'}
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            {reportData.msr.session?.sessionInfo.errorText}
                                                        </Grid>
                                                    </>
                                            }
                                            <Grid item xs={4}>
                                                {'Last Completed Synchronization:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatISO(convertTimestampObjectToDate(reportData.msr.session?.sessionInfo.lastSynchronizedTime))}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {'Total Sync Time:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatDurationFromSeconds(reportData.msr.session?.sessionInfo.sessionStats.totalSyncTime?.seconds)}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Typography fontWeight={600}>
                                            {'Migration Progress'}
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                {'Total Base Data:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.totalBase, KnownDataType.CAPACITY)} {`(${reportData.msr.session?.sessionInfo.sessionStats.totalBase} bytes)`}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {'Total Changed Data:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.totalChanged, KnownDataType.CAPACITY)}
                                                {!!reportData.msr.session?.sessionInfo.sessionStats.currentChanged ? `(${formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.currentChanged, KnownDataType.CAPACITY)} New)` : ''}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {'Total Data Migrated:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.totalSynced, KnownDataType.CAPACITY)} {`(${reportData.msr.session?.sessionInfo.sessionStats.totalSynced} bytes)`}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {'Remaining Data:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.totalRemaining, KnownDataType.CAPACITY)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {'Progress:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {`${reportData.msr.session?.sessionInfo.sessionStats.progressPercentage}%`}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Typography fontWeight={600}>
                                            {'Migration Rate'}
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                {'Current Migration Rate:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.currentThroughput, KnownDataType.THROUGHPUT)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {'Current Transfer Rate:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.currentTransferThroughput, KnownDataType.THROUGHPUT)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {'Recent Migration Rate (60s):'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.recentThroughput, KnownDataType.THROUGHPUT)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {'Recent Transfer Rate (60s):'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.recentTransferThroughput, KnownDataType.THROUGHPUT)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {'Average Migration Rate:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.averageThroughput, KnownDataType.THROUGHPUT)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {'Average Transfer Rate:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.averageTransferThroughput, KnownDataType.THROUGHPUT)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {'Estimated Time Remaining:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {!!reportData.msr.session?.sessionInfo.sessionStats.currentThroughput ? formatDurationFromSeconds(Math.round(reportData.msr.session.sessionInfo.sessionStats.totalRemaining / reportData.msr.session.sessionInfo.sessionStats.currentThroughput)) : `N/A`}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Typography fontWeight={600}>
                                            {'Data Transfer Reduction'}
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                {'Thin Data Ratio:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.thinPercentage, KnownDataType.PERCENT)}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {'Total Thin Data:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.totalThinData, KnownDataType.CAPACITY)}

                                            </Grid>
                                            <Grid item xs={4}>
                                                {'Total Data Transferred:'}
                                            </Grid>
                                            <Grid item xs={8}>
                                                {formatKnownDataType(reportData.msr.session?.sessionInfo.sessionStats.totalTransferred, KnownDataType.CAPACITY)}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box>
                                        <Typography fontWeight={600}>
                                            {'Session Timeline'}
                                        </Typography>
                                        {
                                                reportData.msr.allEventsList?.filter(e=>!e.volumeUuid).map((eventItem, index) => {
                                                    return <Grid container key={index} pb={1} sx={{
                                                        '@media print': {
                                                            width: 'auto',
                                                            border: 0,
                                                            margin: 0,
                                                            padding: 0,
                                                            float: 'none',
                                                            position: 'static',
                                                            overflow: 'visible',
                                                        }
                                                    }}>
                                                        <Grid item xs={4} >
                                                            <Typography >
                                                                {formatISO(convertTimestampObjectToDate(eventItem.time))}

                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Typography>
                                                                {eventItem.message}

                                                            </Typography>

                                                        </Grid>
                                                    </Grid>
                                                })
                                                || 'N/A'}
                                    </Box>
                                </Stack> :
                                <Typography>
                                    {'N/A'}
                                </Typography>

                        }
                    </CollapsibleSubheader>
                </Stack>
            </CollapsibleHeader>

        </Box>
        <Box>
            <CollapsibleHeader title={'3. Migration Session Volumes'}>
                <Stack p={4} spacing={4}>
                    {reportData.msr.session?.sessionInfo.sessionVolumesList.map((vol, index) => {
                        const sourceDeviceInfo      = getStorageConfigBlockDevice(vol.source.devicePath, reportData.msr.sourceVolumesList, isSourceDeploymentWindows);
                        const destinationDeviceInfo = getStorageConfigBlockDevice(vol.destination.devicePath, reportData.msr.destinationVolumesList, isDestDeploymentWindows);
                        return <CollapsibleSubheader title={`${index + 1}. ${vol.source.name} –> ${vol.destination.name}`} key={index}>
                            <Stack p={2} spacing={2}>
                                <Box>
                                    <Typography fontWeight={600}>
                                        {`Source Volume`}
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {`Volume:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {vol.source.name}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Capacity:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(vol.source.capacity, KnownDataType.CAPACITY)} {`(${vol.source.capacity} bytes)`}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Type:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {sourceDeviceInfo?.blockDevice.deviceType || 'N/A'}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`FS Type:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {sourceDeviceInfo?.blockDevice.fsType || 'N/A'}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`FS Label:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {sourceDeviceInfo?.blockDevice.label || 'N/A'}
                                        </Grid>
                                        {!!sourceDeviceInfo?.blockDevice.mountPoint &&
                                            <>
                                                <Grid item xs={4}>
                                                    {`Mount Point:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {sourceDeviceInfo?.blockDevice.mountPoint || 'N/A'}
                                                </Grid>
                                            </>
                                        }
                                        <Grid item xs={4}>
                                            {`Path:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {vol.source.devicePath}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Typography fontWeight={600}>
                                        {`Destination Volume`}
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {`Volume:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {vol.destination.name}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Capacity:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(vol.destination.capacity, KnownDataType.CAPACITY)} {`(${vol.destination.capacity} bytes)`}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Type:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {destinationDeviceInfo?.blockDevice.deviceType || 'N/A'}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`FS Type:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {destinationDeviceInfo?.blockDevice.fsType || 'N/A'}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`FS Label:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {destinationDeviceInfo?.blockDevice.label || 'N/A'}
                                        </Grid>
                                        {!!destinationDeviceInfo?.blockDevice.mountPoint &&
                                            <>
                                                <Grid item xs={4}>
                                                    {`Mount Point:`}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {destinationDeviceInfo?.blockDevice.mountPoint || 'N/A'}
                                                </Grid>
                                            </>
                                        }
                                        <Grid item xs={4}>
                                            {`Path:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {vol.destination.devicePath}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Typography fontWeight={600}>
                                        {'Migration Status'}
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {`Current Status:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {renderGMSessionStatus(vol.volumeStatus)}
                                        </Grid>
                                        {!!vol.volumeErrorText &&
                                            <>
                                                <Grid item xs={4}>
                                                    {'Migration Error:'}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    {vol.volumeErrorText}
                                                </Grid>
                                            </>
                                        }
                                        <Grid item xs={4}>
                                            {`Last Completed Synchronization:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {!!vol.lastSynchronizedTime ? formatISO(convertTimestampObjectToDate(vol.lastSynchronizedTime)) : 'N/A'}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {`Total Sync Time:`}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatDurationFromSeconds(vol.volumeStats.totalSyncTime.seconds)}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Typography fontWeight={600}>
                                        {'Migration Progress'}
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {'Total Base Data:'}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(vol.volumeStats.totalBase, KnownDataType.CAPACITY)} {`(${vol.volumeStats.totalBase} bytes)`}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {'Total Changed Data:'}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(vol.volumeStats.totalChanged, KnownDataType.CAPACITY)}
                                            {!!vol.volumeStats.currentChanged ? `(${formatKnownDataType(vol.volumeStats.currentChanged, KnownDataType.CAPACITY)} New)` : ''}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {'Total Data Migrated:'}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(vol.volumeStats.totalSynced, KnownDataType.CAPACITY)} {`(${vol.volumeStats.totalSynced} bytes)`}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {'Remaining Data:'}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(vol.volumeStats.totalRemaining, KnownDataType.CAPACITY)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {'Progress:'}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {`${vol.volumeStats.progressPercentage}%`}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Typography fontWeight={600}>
                                        {'Migration Rate'}
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {'Current Migration Rate:'}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(vol.volumeStats.currentThroughput, KnownDataType.THROUGHPUT)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {'Current Transfer Rate:'}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(vol.volumeStats.currentTransferThroughput, KnownDataType.THROUGHPUT)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {'Recent Migration Rate (60s):'}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(vol.volumeStats.recentThroughput, KnownDataType.THROUGHPUT)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {'Recent Transfer Rate (60s):'}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(vol.volumeStats.recentTransferThroughput, KnownDataType.THROUGHPUT)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {'Average Migration Rate:'}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(vol.volumeStats.averageThroughput, KnownDataType.THROUGHPUT)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {'Average Transfer Rate:'}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(vol.volumeStats.averageTransferThroughput, KnownDataType.THROUGHPUT)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {'Estimated Time Remaining:'}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {!!vol.volumeStats.currentThroughput ? formatDurationFromSeconds(Math.round(vol.volumeStats.totalRemaining / vol.volumeStats.currentThroughput)) : `N/A`}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Typography fontWeight={600}>
                                        {'Data Transfer Reduction'}
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            {'Thin Data Ratio:'}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(vol.volumeStats.thinPercentage, KnownDataType.PERCENT)}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {'Total Thin Data:'}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(vol.volumeStats.totalThinData, KnownDataType.CAPACITY)}

                                        </Grid>
                                        <Grid item xs={4}>
                                            {'Total Data Transferred:'}
                                        </Grid>
                                        <Grid item xs={8}>
                                            {formatKnownDataType(vol.volumeStats.totalTransferred, KnownDataType.CAPACITY)}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Typography fontWeight={600}>
                                        {'Migration Timeline'}
                                    </Typography>
                                    {
                                        reportData.msr.allEventsList?.filter(e=>e.volumeUuid === vol.source.uuid).map((eventItem, index) => {
                                            return <Grid container key={index} pb={1} sx={{
                                                '@media print': {
                                                    width: 'auto',
                                                    border: 0,
                                                    margin: 0,
                                                    padding: 0,
                                                    float: 'none',
                                                    position: 'static',
                                                    overflow: 'visible',
                                                }
                                            }}>
                                                <Grid item xs={4} >
                                                    <Typography >
                                                        {formatISO(convertTimestampObjectToDate(eventItem.time), {format: 'extended'})}

                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Typography>
                                                        {eventItem.message}

                                                    </Typography>

                                                </Grid>
                                            </Grid>
                                        })
                                     || 'N/A'}
                                </Box>
                            </Stack>
                        </CollapsibleSubheader>
                    }) || 'N/A'}
                </Stack>
            </CollapsibleHeader>
        </Box>
        <Box pr={4} pl={4} pt={2}>
            <Divider/>
        </Box>
        <Box pr={6} pl={6} pb={6} pt={2}>

            <Typography variant={'caption'}>
                <Grid container>
                    <Grid xs={2}>
                        {'Report ID:'}
                    </Grid>
                    <Grid xs={10}>
                        {reportInfo.reportId}
                    </Grid>
                    <Grid xs={2}>
                        {'Generated At:'}
                    </Grid>
                    <Grid xs={10}>
                        {formatISO(convertTimestampObjectToDate(reportInfo?.createdAt))}
                    </Grid>
                    <Grid xs={2}>
                        {'Generated By:'}
                    </Grid>
                    <Grid xs={10}>
                        {getUserFullNameFromObject(reportInfo?.createdBy)} {`(${reportInfo?.createdBy.email})`}
                    </Grid>
                    <Grid xs={2}>
                        {'Notes:'}
                    </Grid>
                    <Grid xs={10}>
                        {reportInfo?.systemNotes}
                    </Grid>
                    <Grid xs={2}>
                        {'User Notes:'}
                    </Grid>
                    <Grid xs={10}>
                        {reportInfo?.userNotes}
                    </Grid>
                </Grid>
            </Typography>
            <Box pt={2} textAlign={'center'}>
                <Box pb={1}>
                    <Typography variant={'body2'} fontWeight={600}>
                        {'End of Report'}
                    </Typography>
                </Box>

                <img src={CdsLogo} alt={'Cirrus Data Solutions Inc.'} width={'150'} height={'auto'}/>
            </Box>
        </Box>
    </Box>

});