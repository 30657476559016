// Project: GalaxyComplete
// Created: 5/20/21 by sammy
// File: FeatureFlags

import * as React from 'react';
import {FeatureFlag} from '../../app/AppGlobalService';
import {useAppServices} from '../../app/services';

export const useIsFeatureEnabled = (feat: FeatureFlag) => {
    const {appGlobalService} = useAppServices();
    return appGlobalService.isFeatureEnabled(feat);
};


