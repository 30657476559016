import {observer} from "mobx-react-lite";
import {Box, Typography} from "@mui/material";
import * as React from "react";
// ======================
// KeyValuePair
// ======================


interface KeyValuePairProps {
    label: string,
    value: string | React.ReactNode
}

export const KeyValuePair: React.FC<KeyValuePairProps> = observer((p) => {

    return <Box display={'flex'}>
        <Typography color={'textSecondary'}>{p.label}:&nbsp;&nbsp;&nbsp;</Typography>
        <Typography>{p.value}</Typography>
    </Box>;
})

// ======================
// KeyValuePairSubtitle
// ======================


interface KeyValuePairSubtitleProps {
}

export const KeyValuePairSubtitle: React.FC<KeyValuePairProps> = observer((p) => {
    return <Box display={'flex'} alignItems={'flex-start'}>
        <Typography fontWeight={500} variant={'body2'}>{p.label}:&nbsp;&nbsp;&nbsp;</Typography>
        <Typography color={'textSecondary'} variant={'body2'}>{p.value}</Typography>
    </Box>;
});