// ======================
// EditEnabledIntegration
// ======================

import {observer} from "mobx-react-lite";
import {useEffectOnce} from "react-use";
import {useAppServices} from "../../app/services";
import {IntegrationForm} from "./IntegrationForm";
import {useNavigateToIntegrationDetails} from "../IntegrationsCommon";
import {FormikValues} from "formik";
import {useInitData} from "../../core/data/DataLoaderHooks";
import {useParams} from "react-router-dom";
import React from "react";
import {IntegrationModule} from "../../../_proto/galaxycompletepb/apipb/domainpb/integration_pb";
import {IntegrationParams} from "../../../_proto/galaxycompletepb/apipb/integration_api_pb";
import {useGmAutoAllocationState} from "../../migration/autoallocation/GmAutoAllocationCommon";
import {v4 as uuid} from 'uuid';


interface EditEnabledIntegrationProps{
}

export const EditEnabledIntegration: React.FC<EditEnabledIntegrationProps> = observer((p)=>{
    const {integrationsService, deploymentService, progressService} = useAppServices();

    const { integrationId } = useParams();

    useInitData({
        init: async()=> {
            await integrationsService.currentIntegration.fetchData(integrationId);
            integrationsService.initEditIntegrationState();

        },
        deinit: async()=> {
            await integrationsService.currentIntegration.resetData();
        }
    })

    const goBackToIntegrationDetails = useNavigateToIntegrationDetails(integrationsService.currentIntegrationId);

    const autoAllocState = useGmAutoAllocationState();
    const editState = integrationsService.editIntegrationState;

    const onSubmit = async(module: IntegrationModule, friendlyName: string, deploymentId: string, connParams?: IntegrationParams.ConnParams, verboseLogging?: boolean)=>{
        const statusId = uuid();
        if (!!deploymentId){
            const deploymentDetails = await deploymentService.fetchDeploymentDetails(deploymentId);
            autoAllocState.setDeployment(deploymentDetails);
            autoAllocState.setNewStatusId(statusId);
            autoAllocState.setShowProgressDialog(true);
            await editState.updateProjectIntegration(friendlyName, statusId, deploymentId, connParams, verboseLogging);
            await integrationsService.currentIntegration.fetchData();
            await autoAllocState.waitForProgressDialogToClose();
        }else {
            await editState.updateProjectIntegration(friendlyName, statusId,null, null, verboseLogging);
            await integrationsService.currentIntegration.fetchData();
        }
    }

    if (!!editState){

        return <IntegrationForm currentIntegration={editState.currentIntegration}
                                onCancel={goBackToIntegrationDetails}
                                onSubmit={onSubmit}
                                onSubmitted={goBackToIntegrationDetails}
        />
    }
    return null;
})