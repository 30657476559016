import { DialogState } from "../core/dialog/DialogService";
import { observer } from "mobx-react-lite";
import React from "react";
import { useAppServices } from "../app/services";
import * as yup from "yup";
import { Form, Formik, FormikValues } from "formik";
import Box from "@mui/material/Box";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { CloseDialogButton, DialogTopBar } from "../core/dialog/DialogComponents";
import { FormCheckboxItem, FormTextField } from "../../common/form/FormComponents";
import { useCurrentProject } from "./CurrentProject";
import { ProgressTracker } from "../core/progress/ProgressTracker";
import { useCreateNewProject } from "./project_hooks";

// ======================
// ProjectInfoForm
// ======================

interface ProjectInfoFormProps {
    dialogState: DialogState;
    type: "create" | "edit";
}

export const ProjectInfoForm: React.FC<ProjectInfoFormProps> = observer((props) => {
    const { projectService, dialogService } = useAppServices();
    const { dialogState, type } = props;
    const project = useCurrentProject();
    const createNewProject = useCreateNewProject();

    let initialValues;

    const schema = yup.object({
        name: yup.string().required("project name is required").label("Name"),
        description: yup.string().label("Description").required(),
        emergencyEmail: yup.string().label("Emergency Email").email(),
        deleteLock: yup.boolean(),
    });

    let descriptionText: string;
    let dialogTitle: string;
    let submitText: string;
    let _submit: (values: FormikValues) => Promise<void>;

    if (type === "edit") {
        descriptionText = "Edit project details.";
        dialogTitle = "Edit Project Info";
        submitText = "Update Project";
        _submit = async (values: FormikValues) => {
            await projectService.updateProject(values.name, values.description, values.emergencyEmail, values.emergencyPhone, values.deleteLock);
            await projectService.currentProject.fetchData();
            await dialogService.addAlertDialog({
                title: "Project Updated",
                message: "Project details have been updated.",
            });
            dialogState.close();
        };
        initialValues = {
            name: project.getInfo().getName(),
            emergencyEmail: project.getInfo().getEmergencyEmail(),
            emergencyPhone: project.getInfo().getEmergencyPhone(),
            description: project.getInfo().getDescription(),
            deleteLock: project.getInfo().getDeleteLock(),
        };
    } else if (type === "create") {
        descriptionText =
            "Once a project is created, you will be able to add additional users to the project and register supported Cirrus products for remote management";
        dialogTitle = "Create New Project";
        submitText = "Create Project";
        _submit = async (values: FormikValues) => {
            const res = await createNewProject.mutateAsync({
                name: values.name,
                description: values.description,
                emergencyEmail: values.emergencyEmail,
                emergencyPhone: values.emergencyPhone,
                deleteLock: values.deleteLock,
            });
            //await projectService.createNewProject(values.name, values.description, values.emergencyEmail, values.emergencyPhone, values.deleteLock);

            if (res) {
                await projectService.myProjects.fetchData();
                dialogState.close();
            }
        };
        initialValues = {
            name: "",
            emergencyEmail: "",
            emergencyPhone: "",
            description: "",
            deleteLock: false,
        };
    }

    return (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={_submit}>
            {(props) => (
                <>
                    <Form>
                        <DialogTopBar dialogState={dialogState} title={dialogTitle} />
                        <DialogContent>
                            <DialogContentText>{descriptionText}</DialogContentText>
                        </DialogContent>
                        <DialogContent>
                            <Box pb={3}>
                                <FormTextField name={"name"} label={"Project Name"} variant={"filled"} required />
                            </Box>
                            <Box pb={3}>
                                <FormTextField name={"description"} label={"Description"} variant={"filled"} required />
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormTextField name={"emergencyEmail"} label={"Emergency Contact Email"} variant={"filled"} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormTextField name={"emergencyPhone"} label={"Emergency Contact Phone"} variant={"filled"} />
                                </Grid>
                            </Grid>
                            {type !== "edit" && (
                                <Box pb={3}>
                                    <FormCheckboxItem label={"Lock Project"} name={"deleteLock"} helperText={"Project cannot be deleted when locked"} />
                                </Box>
                            )}

                            <br />
                        </DialogContent>
                        <DialogActions>
                            <Box p={2}>
                                <Button type={"submit"} variant={"outlined"} size={"large"}>
                                    {submitText}
                                </Button>
                            </Box>
                        </DialogActions>
                    </Form>
                </>
            )}
        </Formik>
    );
});
