import { GRPCServices } from "../grpc/grpcapi";
import { ProjectService } from "../project/ProjectService";
import { DialogService } from "../core/dialog/DialogService";
import { ProgressService } from "../core/progress/ProgressService";
import { GalaxyMigrateDeploymentService } from "../galaxymigrate/GmDeploymentService";
import { makeAutoObservable } from "mobx";
import { DeploymentService } from "../deployment/DeploymentService";
import { ServerData } from "../core/data/ServerData";
import {
    GalaxyMigrateDeploymentDetails,
    GalaxyMigrateMigrationEvent,
    GalaxyMigrateMigrationSessionBasicInfo,
    GalaxyMigrateMigrationSessionDetails,
    GalaxyMigrateMigrationSessionInfo,
    GalaxyMigrateStorageConfig,
} from "../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import * as gmapipb from "../../_proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import {
    AutoAllocVolumes,
    CancelMigrationSession,
    CloneMigrationSessionSnapshot,
    CutoverMigrationSession,
    DeleteMigrationSession,
    DeleteSnapshots,
    FinalCutoverMigrationSession,
    GetAutoAllocStatusDetails,
    GetAzureRecommendation,
    GetMigrationSessionDetails,
    GetMigrationSessionVolQosRollingAvgLog,
    GetMigrationSessionVolQosState,
    GetMigrationVolumeChangesDistributionView,
    ListMigrationEvents,
    IsPreparedForAutoAlloc,
    ListAzureProducts,
    ListMigrationSessions,
    ListSnapshots,
    PrepareForAutoAlloc,
    RestartMigrationSession,
    RevertCutoverMigrationSession,
    StartMigrationSessionCutOverChecklist,
    SuspendMigrationSession,
    SyncMigrationSession,
    UpdateMigrationSession,
    UpdateMigrationSessionComputeSpec,
    GetAzurePrice,
    ListAzureHelpers,
    GetAzureHelperResourceSelections,
    ListVmwareHelpers,
    GetEligibleMigrationSessionCutOverChecklists,
    GetVmwareHelperResourceSelections,
} from "../../_proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import { ServerListData } from "../core/data/ListData";
import { GetMigrationSessionVolumeQosStateResponse, SyncQos } from "../../_proto/galaxymigratepb/galaxy_migrate_qos_pb";
import { Duration } from "google-protobuf/google/protobuf/duration_pb";
import {
    AllocateDestinationVolumesRequest,
    AllocateDestinationVolumesResponse,
    AutoAlloc,
    AutoAllocStatus,
    IsHostReadyForAllocationResponse,
    ListSnapshotsResponse,
} from "../../_proto/galaxymigratepb/galaxy_migrate_autoalloc_pb";
import { GetMigrationVolumeChangesDistributionViewResponse } from "../../_proto/galaxymigratepb/galaxy_migrate_migration_pb";
import { MTDIDeploymentInfo } from "../../_proto/galaxycompletepb/apipb/domainpb/mtdi_pb";
import { IntegrationConfigInfo, IntegrationModule } from "../../_proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { getStepsByAction, PostSyncAction, PostSyncActionConfig } from "./workflow/GmMigrationSessionPostSyncActions";
import { Workflow, WorkflowLog, WorkflowStage, WorkflowStatus } from "../../_proto/galaxycompletepb/apipb/domainpb/workflow_pb";
import { CancelWorkflowRun, GetRunLogs, GetWorkflowDetails, GetWorkflowRun, ListWorkflowRuns } from "../../_proto/galaxycompletepb/apipb/workflow_api_pb";
import { StepperState } from "../../common/stepper/StepperComponents";
import { Struct } from "google-protobuf/google/protobuf/struct_pb";
import { v4 as uuid } from "uuid";
import { ComputeMigrationSpec, GmHelperNodeInfo } from "../../_proto/galaxycompletepb/apipb/domainpb/compute_pb";
import { MigrationParametersType } from "./wizard/GmMigrationSessionParametersForm";
import { convertDateObjectToTimestamp } from "../../common/utils/formatter";
import { createMigrationQosSchedule } from "./MigrationCommon";
import { RecommendationPreference } from "../../_proto/galaxycompletepb/apipb/domainpb/recommendation_pb";
import { mockGmStorageConfig } from "../core/testutils/fixtures/FixturesCommon";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { AzureStorageProduct, AzureStorageRecommendation } from "../../_proto/galaxycompletepb/apipb/domainpb/azure_pb";
import { mockIntegration1, mockIntegration4 } from "../core/testutils/fixtures/MockIntegrationsService";
import { mockAzureRecommendationResponse, mockAzureStorageProduct, mockAzureStorageProduct2 } from "../core/testutils/fixtures/MockGmMigrationService";
import { ChecklistInfo } from "../../_proto/galaxycompletepb/apipb/domainpb/checklist_pb";

export class GalaxyMigrateMigrationService {
    protected readonly api: GRPCServices;
    private readonly dialogService: DialogService;
    private readonly progressService: ProgressService;
    protected readonly gmDeploymentService: GalaxyMigrateDeploymentService;
    protected readonly deploymentService: DeploymentService;
    protected readonly projectService: ProjectService;

    storageConfig = new ServerData<GalaxyMigrateStorageConfig>().setDataFetcher(this.fetchStorageConfig.bind(this));
    remoteStorageConfig = new ServerData<GalaxyMigrateStorageConfig>().setDataFetcher(this.fetchRemoteStorageConfig.bind(this));
    wizardState: GmMigrationWizardState;
    autoAllocationState: GmMigrationAutoAllocationState;
    currentSessionVolumeState: GmMigrationSessionVolumeState;
    currentSessionEditState: GmMigrationSessionEditState;
    sessions = new ServerListData<ListMigrationSessions.Response, GalaxyMigrateMigrationSessionBasicInfo>().setDataFetcher(this.fetchSessions.bind(this));
    currentSessionWorkflow = new ServerData<Workflow>().setDataFetcher(this.fetchCurrentSessionWorkflow.bind(this));
    currentSessionWorkflowRuns = new ServerListData<ListWorkflowRuns.Response, WorkflowStatus>(10).setDataFetcher(this.fetchWorkflowRuns.bind(this));
    currentWorkflowRunDetails = new ServerData<WorkflowStatus>().setDataFetcher(this.fetchWorkflowRunDetails.bind(this));
    currentWorkflowRunLogs = new ServerListData<GetRunLogs.Response, WorkflowLog>().setDataFetcher(this.fetchWorkflowRunLogs.bind(this));
    currentSessionID: string = null;
    currentSession = new ServerData<GalaxyMigrateMigrationSessionDetails>().setDataFetcher(this.fetchSessionDetails.bind(this));
    currentSessionVolumeSnapshots = new ServerListData<ListSnapshots.Response, ListSnapshotsResponse.Snapshot>().setDataFetcher(
        this.fetchVolumeSnapshots.bind(this)
    );
    currentSessionSelectedSnapshotVolume: GalaxyMigrateMigrationSessionInfo.Volume = null;
    currentSessionEligibleChecklists = new ServerData<ChecklistInfo.Descriptor.AsObject[]>().setDataFetcher(
        this.getEligibleCutOverChecklistsForSession.bind(this)
    );
    currentSessionMigrationEvents = new ServerData<ListMigrationEvents.Response.AsObject>().setDataFetcher(this.listMigrationEvents.bind(this));

    constructor(
        api: GRPCServices,
        dialogService: DialogService,
        progressService: ProgressService,
        gmDeploymentService: GalaxyMigrateDeploymentService,
        deploymentService: DeploymentService,
        projectService: ProjectService
    ) {
        makeAutoObservable(this);

        this.api = api;
        this.dialogService = dialogService;
        this.progressService = progressService;
        this.gmDeploymentService = gmDeploymentService;
        this.deploymentService = deploymentService;
        this.projectService = projectService;

        this.initWizard();
        this.initAutoAllocationWizard();
        this.initVolumeState();
        this.initEditState();
    }

    setCurrentSessionID(sessionID: string) {
        if (sessionID !== this.currentSessionID) {
            this.currentSessionID = sessionID;
            this.currentSession.resetData();
        }
    }

    get currentSessionCutoverChecklistId() {
        return this.currentSession.data?.getCutoverChecklistId();
    }

    initWizard() {
        this.wizardState = new GmMigrationWizardState(this.api, this.gmDeploymentService, this.deploymentService);
        return this.wizardState;
    }

    initAutoAllocationWizard(
        deploymentDetails?: GalaxyMigrateDeploymentDetails,
        sourceDevices?: GmMigrationWizardVolumeState[],
        allowSmallerDestinations?: boolean
    ) {
        if (!!sourceDevices) {
            sourceDevices = sourceDevices.map((d) => {
                d.autoAllocParams = new AutoAlloc.VolumeParams();
                return d;
            });
        }
        this.autoAllocationState = new GmMigrationAutoAllocationState(this.api, this, deploymentDetails, sourceDevices, allowSmallerDestinations);
        return this.autoAllocationState;
    }

    initVolumeState() {
        this.currentSessionVolumeState = new GmMigrationSessionVolumeState(this.api);
        return this.currentSessionVolumeState;
    }

    initEditState() {
        this.currentSessionEditState = new GmMigrationSessionEditState(this.api);
        return this.currentSessionEditState;
    }

    async createSession(req: gmapipb.CreateMigrationSession.Request) {
        return await this.progressService.track(this.api.gmService.createMigrationSession(req, null));
    }

    async fetchSessions(projectID?: string) {
        projectID = projectID || this.projectService?.currentProjectID;
        const req = new ListMigrationSessions.Request().setPageParams(this.sessions.pagerParam).setProjectId(projectID);
        //return mockMigrationSessionsList;
        return await this.api.gmService.listMigrationSessions(req, null);
    }

    async fetchSessionDetails(sessionID?: string) {
        sessionID = sessionID || this.currentSessionID;
        const req = new GetMigrationSessionDetails.Request().setSessionId(sessionID);

        const response = await this.api.gmService.getMigrationSessionDetails(req, null);
        return response.getSession();

        //return mockSessionDetails;
    }

    async fetchStorageConfig() {
        const deploymentId = this.currentSession.data?.getSessionInfo().getDeployment().getSystemId();
        //return mockGmStorageConfig;
        return this.gmDeploymentService.fetchStorageConfig(deploymentId, true);
    }

    async fetchRemoteStorageConfig() {
        const deploymentId = this.currentSession.data?.getSessionInfo().getDestinationDeployment().getSystemId();
        return this.gmDeploymentService.fetchStorageConfig(deploymentId, true);
    }

    async fetchCurrentSessionWorkflow(workflowId: number) {
        const req = new GetWorkflowDetails.Request().setWorkflowId(workflowId);

        const response = await this.api.workflowService.getWorkflowDetails(req, null);
        return response.getWorkflow();
    }

    async fetchWorkflowRuns(workflowId: number) {
        const req = new ListWorkflowRuns.Request().setWorkflowId(workflowId).setPageParams(this.currentSessionWorkflowRuns.pagerParam);

        return await this.api.workflowService.listWorkflowRuns(req, null);
    }

    async fetchWorkflowRunDetails(workflowRunId: number) {
        const req = new GetWorkflowRun.Request().setStatusId(workflowRunId);
        const result = await this.api.workflowService.getWorkflowRun(req, null);
        return result.getStatus();
    }

    async fetchWorkflowRunLogs(statusId: number) {
        const req = new GetRunLogs.Request().setPageParams(this.currentWorkflowRunLogs.pagerParam).setStatusId(statusId);
        return await this.api.workflowService.getRunLogs(req, null);
    }

    async cancelWorkflowRun(id: number) {
        const req = new CancelWorkflowRun.Request().setStatusId(id);
        return await this.progressService.track(this.api.workflowService.cancelWorkflowRun(req, null));
    }

    async fetchVolumeSnapshots(devicePath?: string, systemId?: string, integrationId?: number) {
        const statusId = uuid();
        const devPath = devicePath || this.currentSessionSelectedSnapshotVolume.getDestination().getDevicePath();
        const sysId =
            systemId ||
            this.currentSession.data?.getSessionInfo().getDestinationDeployment()?.getSystemId() ||
            this.currentSession.data?.getSessionInfo().getDeployment()?.getSystemId();
        const intId =
            integrationId ||
            this.currentSessionWorkflow.data
                .getStagesList()
                .find((s) => s.getAction() === WorkflowStage.Action.SNAPSHOT)
                .getIntegrationId();
        const req = new ListSnapshots.Request().setDevicePath(devPath).setSystemId(sysId).setIntegrationId(intId).setStatusId(statusId);
        return await this.api.gmService.listSnapshots(req, null);
    }

    setSelectedSnapshotVolume(vol: GalaxyMigrateMigrationSessionInfo.Volume) {
        this.currentSessionSelectedSnapshotVolume = vol;
    }

    async deleteSnapshots(ids: string[], systemId?: string, integrationId?: number) {
        const statusId = uuid();
        const intId =
            integrationId ||
            this.currentSessionWorkflow.data
                .getStagesList()
                .find((s) => s.getAction() === WorkflowStage.Action.SNAPSHOT)
                .getIntegrationId();
        const sysId = systemId || this.currentSession.data?.getSessionInfo().getDestinationDeployment().getSystemId();

        const req = new DeleteSnapshots.Request().setIntegrationId(intId).setSystemId(sysId).setSnapshotIdsList(ids).setStatusId(statusId);
        return await this.progressService.track(this.api.gmService.deleteSnapshots(req, null));
    }

    async cloneVolumeBackToHost(systemID: string, devicePath: string, snapshotID: string, integrationId?: number) {
        const statusId = uuid();
        integrationId =
            integrationId ||
            this.currentSessionWorkflow.data
                .getStagesList()
                .find((s) => s.getAction() === WorkflowStage.Action.SNAPSHOT)
                .getIntegrationId();
        const req = new CloneMigrationSessionSnapshot.Request()
            .setIntegrationId(integrationId)
            .setSystemId(systemID)
            .setTargetId(systemID)
            .setSnapshotId(snapshotID)
            .setDevicePath(devicePath)
            .setStatusId(statusId);
        return await this.api.gmService.cloneMigrationSessionSnapshot(req, null);
    }

    async updateSessionComputeSpec(spec: ComputeMigrationSpec) {
        const req = new UpdateMigrationSessionComputeSpec.Request().setComputeMigrationSpec(spec).setSessionId(this.currentSessionID);
        return await this.progressService.track(this.api.gmService.updateMigrationSessionComputeSpec(req, null));
    }

    async getEligibleCutOverChecklistsForSession(sessionId?: string) {
        const req = new GetEligibleMigrationSessionCutOverChecklists.Request().setSessionId(sessionId || this.currentSessionID);
        //return mockChecklistTypes;
        const res = await this.api.gmService.getEligibleMigrationSessionCutOverChecklists(req, null);
        return res.toObject().checklistDescriptorsList;
    }

    async startCutOverChecklist(type: string) {
        const req = new StartMigrationSessionCutOverChecklist.Request().setSessionId(this.currentSessionID).setType(type);
        return await this.progressService.track(this.api.gmService.startMigrationSessionCutOverChecklist(req, null));
    }

    async listMigrationEvents(sessionId: string, maxEvents: number, pageSequenceToken: number, volUuid?: string) {
        const req = new ListMigrationEvents.Request()
            .setSessionId(sessionId)
            .setMaxEvents(maxEvents)
            .setVolUuidFilter(volUuid)
            .setPageSequenceToken(pageSequenceToken);

        const res = await this.api.gmService.listMigrationEvents(req, null);
        return res.toObject();
    }

    async listAzureHelpers(projectId: string, onlyIfValidMigrationTargetFrom?: string) {
        const req = new ListAzureHelpers.Request().setProjectId(projectId).setOnlyIfValidMigrationTargetFrom(onlyIfValidMigrationTargetFrom);

        const res = await this.api.gmService.listAzureHelpers(req, null);
        return res.toObject();
    }
    async getAzureHelperResourceSelections(helperId: string) {
        const req = new GetAzureHelperResourceSelections.Request().setHelperId(helperId);

        const res = await this.api.gmService.getAzureHelperResourceSelections(req, null);
        return res.toObject();
    }

    async getVmwareHelperResourceSelections(helperId: string) {
        const req = new GetVmwareHelperResourceSelections.Request().setHelperId(helperId);
        const response = await this.api.gmService.getVmwareHelperResourceSelections(req, null);
        return response.toObject();
    }

    async deleteSession(sessionId: string) {
        const req = new DeleteMigrationSession.Request().setSessionId(sessionId);
        return await this.progressService.track(this.api.gmService.deleteMigrationSession(req, null));
    }

    async cutoverSession(sessionId: string) {
        const req = new CutoverMigrationSession.Request().setSessionId(sessionId);
        return await this.progressService.track(this.api.gmService.cutoverMigrationSession(req, null), "Performing Cutover");
    }

    async revertCutoverSession(sessionId: string) {
        const req = new RevertCutoverMigrationSession.Request().setSessionId(sessionId);
        return await this.progressService.track(this.api.gmService.revertCutoverMigrationSession(req, null));
    }

    async finalCutoverSession(sessionId: string) {
        const req = new FinalCutoverMigrationSession.Request().setSessionId(sessionId);
        return await this.progressService.track(this.api.gmService.finalCutoverMigrationSession(req, null));
    }

    async restartSession(sessionId: string) {
        const req = new RestartMigrationSession.Request().setSessionId(sessionId);
        return await this.progressService.track(this.api.gmService.restartMigrationSession(req, null));
    }

    async syncSession(sessionId: string) {
        const req = new SyncMigrationSession.Request().setSessionId(sessionId);
        return await this.progressService.track(this.api.gmService.syncMigrationSession(req, null));
    }
    async suspendSession(sessionId: string) {
        const req = new SuspendMigrationSession.Request().setSessionId(sessionId);
        return await this.progressService.track(this.api.gmService.suspendMigrationSession(req, null));
    }

    async cancelSession(sessionId: string) {
        const req = new CancelMigrationSession.Request().setSessionId(sessionId);
        return await this.progressService.track(this.api.gmService.cancelMigrationSession(req, null));
    }

    async completeSession(sessionId: string) {
        const req = new CancelMigrationSession.Request().setSessionId(sessionId);
        return await this.progressService.track(this.api.gmService.cancelMigrationSession(req, null));
    }
}

export enum GmMigrationWizardStep {
    SOURCE_DEPLOYMENT_SELECTION,
    MIGRATION_TYPE,
    COMPUTE_MIGRATION_SOURCE_PREP,
    COMPUTE_VMWARE_VM_CONFIG,
    COMPUTE_AZURE_VM_CONFIG,
    VOLUMES_SELECTION,
    MIGRATION_PARAMETERS,
}

export enum GmMigrationType {
    LOCAL,
    REMOTE,
    COMPUTE_VMWARE,
    COMPUTE_AZURE,
}

export enum GmMigrationVolumeType {
    DATA,
    BOOT,
    CLUSTER,
}

export const initialMigrationParameters: MigrationParametersType = {
    description: "",
    autoReSyncInterval: 1440,
    qosLevel: SyncQos.ImpactLevel.RELENTLESS,
    qosStartTime: new Date(),
    qosSchedule: {
        daily: [],
        weekends: [],
        fridays: [],
    },
    remoteBootMigrationPostCutoverScript: "",
};

export class GmMigrationWizardState {
    protected readonly gmDeploymentService: GalaxyMigrateDeploymentService;
    protected readonly deploymentService: DeploymentService;
    protected readonly api: GRPCServices;
    stepperState = new StepperState(0);

    deploymentId: string;
    deployment = new ServerData<GalaxyMigrateDeploymentDetails>().setDataFetcher(this.fetchDeploymentDetails.bind(this));
    remoteDeployment = new ServerData<GalaxyMigrateDeploymentDetails>().setDataFetcher(this.fetchRemoteDeploymentDetails.bind(this));
    storageConfig = new ServerData<GalaxyMigrateStorageConfig>().setDataFetcher(this.fetchStorageConfig.bind(this));
    remoteStorageConfig = new ServerData<GalaxyMigrateStorageConfig>().setDataFetcher(this.fetchRemoteStorageConfig.bind(this));
    selectedRemoteDestinationId: string;
    showLVMSourceSelectionDialog = false;
    selectedVolumes: { [key: string]: GmMigrationWizardVolumeState } = {};
    showBootVolumes: boolean = null;
    allowSmallerDestinations = false;
    migrationVolumeType: GmMigrationVolumeType = null;
    migrationParameters = initialMigrationParameters;
    postSyncActionsState: GmMigrationSessionPostSyncActionsState;
    vmwareState: GmMigrationWizardVmwareState;
    azureConfigState: GmMigrationWizardAzureConfigState;
    migrationType: GmMigrationType = null;

    constructor(api: GRPCServices, gmDeploymentService: GalaxyMigrateDeploymentService, deploymentService: DeploymentService) {
        this.api = api;
        this.gmDeploymentService = gmDeploymentService;
        this.deploymentService = deploymentService;
        this.initPostSyncActionsState();
        makeAutoObservable(this);
    }

    initPostSyncActionsState() {
        this.postSyncActionsState = new GmMigrationSessionPostSyncActionsState();
        this.postSyncActionsState.setWizardState(this);
    }

    initVmwareState() {
        this.vmwareState = new GmMigrationWizardVmwareState(this, this.api);
    }

    initAzureState(selectedAzureHelper?: GmHelperNodeInfo.Azure.AsObject) {
        this.azureConfigState = new GmMigrationWizardAzureConfigState(this, this.api, selectedAzureHelper);
    }

    resetAzureState() {
        this.azureConfigState = null;
    }

    private async fetchDeploymentDetails() {
        return this.deploymentService.fetchDeploymentDetails(this.deploymentId);
    }

    private async fetchRemoteDeploymentDetails() {
        return this.deploymentService.fetchDeploymentDetails(this.selectedRemoteDestinationId);
    }

    private async fetchStorageConfig() {
        return this.gmDeploymentService.fetchStorageConfig(this.deploymentId, true);
    }

    private async fetchRemoteStorageConfig() {
        return this.gmDeploymentService.fetchStorageConfig(this.selectedRemoteDestinationId, true);
    }

    public async initDeploymentId(deploymentId: string) {
        this.deploymentId = deploymentId;
        await this.deployment.fetchData();
    }

    public async selectDeployment(deploymentId: string) {
        this.deploymentId = deploymentId;
        await this.deployment.fetchData();
    }

    deselectDeployment() {
        this.deploymentId = null;
        this.deployment.resetData();
    }

    selectRemoteDestination(deploymentId: string) {
        this.selectedRemoteDestinationId = deploymentId;
    }

    deselectRemoteDestination() {
        this.selectedRemoteDestinationId = null;
    }

    get isDeploymentWindows() {
        return this.deployment.ready && this.deployment.data?.getInfo()?.getOsType() === MTDIDeploymentInfo.OSType.WINDOWS;
    }

    get destinationStorageConfig() {
        return this.isRemoteMigration ? this.remoteStorageConfig : this.storageConfig;
    }

    get isRemoteMigration() {
        return this.selectedMigrationType === GmMigrationType.REMOTE || this.isComputeMigration;
    }

    get isComputeMigration() {
        // if we support more compute migration type we should add to here as or
        return [GmMigrationType.COMPUTE_VMWARE, GmMigrationType.COMPUTE_AZURE].includes(this.selectedMigrationType);
    }

    get selectedDeploymentName() {
        return this.deployment?.data?.getInfo()?.getDeployment()?.getSystemName();
    }

    selectMigrationType(type: GmMigrationType) {
        this.migrationType = type;
    }

    get selectedMigrationType() {
        return this.migrationType;
    }

    get hasSelectedVolumes() {
        return Object.keys(this.selectedVolumes).length > 0;
    }

    setShowBootVolumes(show: boolean) {
        this.showBootVolumes = show;
    }
    setAllowSmallerDestinations(allow: boolean) {
        if (!allow) {
            // if not allow, then check and disable all current pair
            Object.values(this.selectedVolumes).forEach((pair) => {
                if (pair.source?.getBlockDevice()?.getCapacity() > pair.destination?.getBlockDevice()?.getCapacity()) {
                    pair.destination = null;
                }
            });
        }
        this.allowSmallerDestinations = !!allow;
    }

    get bootVolumeSelected() {
        return !!Object.values(this.selectedVolumes).find((v) => v.source.getBlockDevice().getBoot());
    }

    selectMigrationVolumeType(type: GmMigrationVolumeType) {
        if (type === GmMigrationVolumeType.BOOT) {
            this.migrationVolumeType = GmMigrationVolumeType.BOOT;
        } else if (type === GmMigrationVolumeType.CLUSTER) {
            this.migrationVolumeType = GmMigrationVolumeType.CLUSTER;
        } else if (type === GmMigrationVolumeType.DATA) {
            this.migrationVolumeType = GmMigrationVolumeType.DATA;
        }
    }

    get selectedMigrationVolumeType() {
        return this.migrationVolumeType;
    }

    addSelectedVolume(source: GalaxyMigrateStorageConfig.Device): void {
        this.selectedVolumes[source.getBlockDevice().getDeviceName()] = {
            source,
        };
    }

    removeSelectedVolume(source: GalaxyMigrateStorageConfig.Device): void {
        delete this.selectedVolumes[source.getBlockDevice().getDeviceName()];
    }

    selectDestinationVolume(sourceDeviceName: string, destinationPathOrLink: string) {
        const pair = this.selectedVolumes[sourceDeviceName];

        if (pair) {
            let destination: GalaxyMigrateStorageConfig.Device = null;
            for (const d of this.getDestinationCandidatesForSourceVolume(pair.source) || []) {
                if (`${d.getBlockDevice().getWindows() ? "" : "/dev/"}${d.getBlockDevice().getDeviceName()}` === destinationPathOrLink) {
                    destination = d;
                    break;
                }
                for (const link of d.getBlockDevice().getDeviceLinksList()) {
                    if (link === destinationPathOrLink) {
                        destination = d;
                        break;
                    }
                }
                if (this.isComputeMigration) {
                    if (d.getBlockDevice().getDeviceName() === destinationPathOrLink) {
                        destination = d;
                    }
                }
            }
            if (!destination) {
                throw new Error(`no destination candidates found matching ${destinationPathOrLink}`);
            }

            pair.destination = destination;
        } else {
            throw new Error(`no migration pair for source volume name ${sourceDeviceName} selected`);
        }
    }

    getDestinationCandidatesForSourceVolume(source: GalaxyMigrateStorageConfig.Device): GalaxyMigrateStorageConfig.Device[] {
        const blockDevice = source.getBlockDevice();

        const storageConfig = this.destinationStorageConfig;
        return storageConfig?.data?.getDevicesList().filter((d) => {
            const migrationSessionUuid = d.getRole()?.getMigrationSessionUuid();
            if (!!migrationSessionUuid) {
                return false;
            }
            if (d.getCanEnlistAsDestination() || d.getRole()?.getRole() === GalaxyMigrateStorageConfig.Device.RoleInfo.Role.DESTINATION) {
                // fixme tmp changes to allow migrate to smaller devices
                if (d.getBlockDevice().getCapacity() >= blockDevice.getCapacity() || !!this.allowSmallerDestinations) {
                    // only if it is not already selected
                    const destinationDeviceName = d.getBlockDevice().getDeviceName();
                    const notSelectedAsDestination = this.selectedDestinationBlockDevNames.indexOf(destinationDeviceName) === -1;
                    const notSelectedAsSource = this.selectedSourceBlockDevNames.indexOf(destinationDeviceName) === -1;
                    if (this.isRemoteMigration) {
                        return notSelectedAsDestination;
                    } else {
                        return notSelectedAsDestination && notSelectedAsSource;
                    }
                }
            }
            return false;
        });
    }

    get selectedDestinationBlockDevNames() {
        return Object.values(this.selectedVolumes)
            .filter((v) => !!v.destination)
            .map((v) => v.destination.getBlockDevice().getDeviceName());
    }

    get selectedSourceBlockDevNames() {
        return Object.keys(this.selectedVolumes);
    }

    get validVolumeSelections() {
        if (!Object.keys(this.selectedVolumes).length) {
            return false;
        } else {
            for (const v of Object.values(this.selectedVolumes)) {
                if (!v.destination || !v.source) {
                    return false;
                }
            }
        }
        return true;
    }

    confirmVolumeSelections() {
        this.postSyncActionsState.setMigrationVolumeNames(this.selectedDestinationBlockDevNames);
    }

    public async confirmDeploymentSelection() {
        await this.deployment.fetchData();
    }

    public async confirmMigrationTypeSelection() {
        if (this.isRemoteMigration) {
            await this.remoteStorageConfig.fetchData();
        }
        this.selectedVolumes = {};
    }

    get hasQosSchedule() {
        return (
            !!this.migrationParameters.qosSchedule.daily.length ||
            !!this.migrationParameters.qosSchedule.fridays.length ||
            !!this.migrationParameters.qosSchedule.weekends.length
        );
    }

    makeCreateSessionRequest() {
        const workflowList: WorkflowStage[] = this.postSyncActionsState.getWorkflowListForRequest();
        // with parameters already set
        let req = new gmapipb.CreateMigrationSession.Request()
            .setSystemId(this.deploymentId)
            .setAutoResyncInterval(new Duration().setSeconds((this.migrationParameters.autoReSyncInterval || 0) * 60))
            .setDescription(this.migrationParameters.description)
            .setPostSyncWorkflowList(workflowList)
            .setDestinationSystemId(this.selectedRemoteDestinationId)
            .setRemoteBootMigrationPostCutoverScript(this.migrationParameters.remoteBootMigrationPostCutoverScript);

        if (this.migrationType === GmMigrationType.COMPUTE_VMWARE) {
            req.setComputeMigrationSpec(this.vmwareState.vmwareSpec);
        }

        if (this.migrationType === GmMigrationType.COMPUTE_AZURE) {
            req.setComputeMigrationSpec(this.azureConfigState.azureSpec);
        }

        if (this.hasQosSchedule) {
            req.setQosStartTime(convertDateObjectToTimestamp(this.migrationParameters.qosStartTime))
                .setQosSchedule(createMigrationQosSchedule(this.migrationParameters.qosSchedule))
                .setQosLevel(SyncQos.ImpactLevel.NONE);
        } else if (!this.hasQosSchedule) {
            req.setQosLevel(this.migrationParameters.qosLevel);
        }

        Object.values(this.selectedVolumes).forEach((v) => {
            const volumeParam = new gmapipb.CreateMigrationSession.Request.VolumeParam()
                .setSourceDeviceName(v.source.getBlockDevice().getDeviceName())
                .setDestinationDeviceName(v.destination.getBlockDevice().getDeviceName());

            req.addVols(volumeParam);
        });
        return req;
    }
}

export class GmMigrationSessionVolumeState {
    private readonly api: GRPCServices;

    currentSessionID: string = null;
    currentSessionVolumeUUID: string = null;
    sessionVolumeQoSState = new ServerData<GetMigrationSessionVolumeQosStateResponse>().setDataFetcher(this.fetchMigrationSessionVolQoSState.bind(this));
    sessionVolumeQoSEntries = new ServerData<SyncQos.RollingAverageLogEntry[]>().setDataFetcher(this.fetchMigrationSessionVolQoSRollingAvgLog.bind(this));
    sessionVolumeBitmap = new ServerData<GetMigrationVolumeChangesDistributionViewResponse>().setDataFetcher(
        this.getMigrationVolumeChangesDistributionView.bind(this)
    );

    constructor(api: GRPCServices) {
        this.api = api;
        makeAutoObservable(this);
    }

    initVolumeState(volumeUUID: string, sessionID: string) {
        if (volumeUUID !== this.currentSessionVolumeUUID) {
            this.currentSessionVolumeUUID = volumeUUID;
            this.sessionVolumeQoSEntries.resetData();
            this.sessionVolumeQoSState.resetData();
        }
        if (sessionID !== this.currentSessionID) {
            this.currentSessionID = sessionID;
        }
    }

    async fetchMigrationSessionVolQoSState(sessionId?: string, volUUID?: string) {
        sessionId = sessionId || this.currentSessionID;
        volUUID = volUUID || this.currentSessionVolumeUUID;
        const req = new GetMigrationSessionVolQosState.Request().setSessionId(sessionId).setVolUuid(volUUID);
        const response = await this.api.gmService.getMigrationSessionVolQosState(req, null);
        return response.getQosStateResponse();
    }

    async fetchMigrationSessionVolQoSRollingAvgLog(sessionId?: string, volUUID?: string, maxRecentPoints: number = 300) {
        sessionId = sessionId || this.currentSessionID;
        volUUID = volUUID || this.currentSessionVolumeUUID;
        const req = new GetMigrationSessionVolQosRollingAvgLog.Request().setSessionId(sessionId).setVolUuid(volUUID).setMaxRecentPoints(maxRecentPoints);
        const response = await this.api.gmService.getMigrationSessionVolQosRollingAvgLog(req, null);
        return response.getEntriesList();
    }

    async getMigrationVolumeChangesDistributionView(sessionId?: string, volUuid?: string) {
        const id = sessionId || this.currentSessionID;
        const uuid = volUuid || this.currentSessionVolumeUUID;
        const req = new GetMigrationVolumeChangesDistributionView.Request().setVolUuid(uuid).setSessionId(id).setNumberOfPoints(5000);
        return await this.api.gmService.getMigrationVolumeChangesDistributionView(req, null);
    }
}

export interface AzureSpecType {
    hostName: string;
    hostDescription: string;
    resourceGroupName: string;
    machineType: string;
}

export class GmMigrationWizardAzureConfigState {
    azureSpec: ComputeMigrationSpec;
    wizardState: GmMigrationWizardState;
    selectedAzureHelper: GmHelperNodeInfo.Azure.AsObject;
    pairedNetworks: { [key: string]: ComputeMigrationSpec.Azure.Network } = {};

    protected readonly api: GRPCServices;

    constructor(wizardState: GmMigrationWizardState, api: GRPCServices, selectedAzureHelper?: GmHelperNodeInfo.Azure.AsObject) {
        this.wizardState = wizardState;
        this.api = api;
        this.selectedAzureHelper = selectedAzureHelper;
        //this.pairedNetworks = {};
        makeAutoObservable(this);
    }

    setAzureSpec(azureSpec: AzureSpecType) {
        this.azureSpec = new ComputeMigrationSpec().setAzureSpec(
            new ComputeMigrationSpec.Azure()
                .setHostName(azureSpec.hostName)
                .setHostDescription(azureSpec.hostDescription)
                .setResourceGroupName(azureSpec.resourceGroupName)
                .setMachineType(azureSpec.machineType)
                .setNetworksList(this.nicsList)
        );

        //set first in list as primary
        this.azureSpec
            .getAzureSpec()
            .getNetworksList()
            .find((n) => !!n)
            .setPrimary(true);
    }

    setAzureSpecFromSessionDetails(azureSpec: ComputeMigrationSpec) {
        this.azureSpec = azureSpec;
    }

    setSelectedAzureHelper(azureHelper: GmHelperNodeInfo.Azure.AsObject) {
        this.selectedAzureHelper = azureHelper;
    }

    setSourceNetwork(name: string) {
        this.pairedNetworks[name] = null;
    }

    removeSourceNetwork(sourceName: string) {
        delete this.pairedNetworks[sourceName];
    }

    pairSourceNetwork(sourceName: string, subnetId: string) {
        this.pairedNetworks[sourceName] = new ComputeMigrationSpec.Azure.Network().setName(sourceName).setSubnetId(subnetId);
    }

    removePairedNetworkFromSource(sourceName: string) {
        this.pairedNetworks[sourceName] = null;
    }

    getIsNetworkPaired(name: string) {
        return !!this.pairedNetworks[name];
    }

    getIsNetworkSelected(name: string) {
        return Object.keys(this.pairedNetworks).includes(name);
    }

    get isAnySourceNetworkUnpaired() {
        return Object.values(this.pairedNetworks).includes(null);
    }

    get hasSourceNetworks() {
        return Object.keys(this.pairedNetworks).length > 0;
    }

    get nicsList() {
        return Object.values(this.pairedNetworks);
    }
}

export interface VmSpecType {
    vmName: string;
    datastore: string;
    resourcePool: string;
    vmFolder: string;
    cpus: number;
    coresPerCpu: number;
    memory: number;
}

export class GmMigrationWizardVmwareState {
    pairedNetworks: { [key: string]: ComputeMigrationSpec.Vmware.Network };
    vmwareSpec: ComputeMigrationSpec;
    wizardState: GmMigrationWizardState;
    protected readonly api: GRPCServices;

    constructor(wizardState: GmMigrationWizardState, api: GRPCServices) {
        this.pairedNetworks = {};
        this.wizardState = wizardState;
        this.api = api;
        makeAutoObservable(this);
    }

    addSourceNetwork(name: string) {
        this.pairedNetworks[name] = null;
    }

    removeSourceNetwork(sourceName: string) {
        delete this.pairedNetworks[sourceName];
    }

    pairSourceNetwork(sourceName: string, matchingPath: string) {
        this.pairedNetworks[sourceName] = new ComputeMigrationSpec.Vmware.Network().setNetworkName(matchingPath);
    }

    removePairedNetworkFromSource(sourceName: string) {
        this.pairedNetworks[sourceName] = null;
    }

    getIsNetworkPaired(name: string) {
        return !!this.pairedNetworks[name];
    }

    getIsNetworkSelected(name: string) {
        return Object.keys(this.pairedNetworks).includes(name);
    }

    get isAnySourceNetworkUnpaired() {
        return Object.values(this.pairedNetworks).includes(null);
    }

    get hasSourceNetworks() {
        return Object.keys(this.pairedNetworks).length > 0;
    }

    get nicsList() {
        return Object.values(this.pairedNetworks);
    }

    setVmwareSpec(vmSpec: VmSpecType) {
        this.vmwareSpec = new ComputeMigrationSpec().setVmwareSpec(
            new ComputeMigrationSpec.Vmware()
                .setVmName(vmSpec.vmName)
                .setDatastore(vmSpec.datastore)
                .setResourcePool(vmSpec.resourcePool)
                .setVmFolder(vmSpec.vmFolder)
                .setMemoryMib(vmSpec.memory)
                .setCpuCount(vmSpec.cpus)
                .setCpuCoresPerSocket(vmSpec.coresPerCpu)
                .setNetworksList(this.nicsList)
        );
    }

    setVmSpecFromSessionDetails(vmSpec: ComputeMigrationSpec) {
        this.vmwareSpec = vmSpec;
    }

    async autoAllocateVmware() {
        const req = new gmapipb.AllocateDisksToVmwareHelper.Request()
            .setSourceDeviceNamesList(Object.values(this.wizardState.selectedVolumes).map((v) => v.source.getBlockDevice().getDeviceName()))
            .setDatastore(this.vmwareSpec.getVmwareSpec().getDatastore())
            .setHelperId(this.wizardState.selectedRemoteDestinationId)
            .setSourceSystemId(this.wizardState.deploymentId)
            .setPreferredFolderName(this.vmwareSpec.getVmwareSpec().getVmName());

        const response = await this.api.gmService.allocateDisksToVmwareHelper(req, null);
        return response.getNewDeviceNamesList();
    }

    async applyAllocatedVmVolumesToWizard(volumes: string[]) {
        // first rescan
        await this.wizardState.storageConfig.fetchData();
        if (this.wizardState.isRemoteMigration) {
            await this.wizardState.remoteStorageConfig.fetchData();
        }
        Object.values(this.wizardState.selectedVolumes).forEach((pair, i) => {
            this.wizardState.selectDestinationVolume(pair.source.getBlockDevice().getDeviceName(), volumes[i]);
        });
    }
}

export interface GmMigrationWizardVolumeState {
    source: GalaxyMigrateStorageConfig.Device;
    destination?: GalaxyMigrateStorageConfig.Device;
    autoAllocParams?: AutoAlloc.VolumeParams;
    autoAllocVolumeCapacity?: number;
}

export enum GmAutoAllocationStep {
    SELECT_INTEGRATION,
    PREPARE_HOST,
    ALLOCATE_VOLUMES,
}

export enum GmAutoAllocationIntegration {
    PURE_CBS = "PURE_CBS",
    AZURE = "AZURE",
    DGS = "DGS",
    AWS = "AWS",
}

export enum GmAutoAllocationActionType {
    CONNECT,
    VALIDATE,
    PREPARE,
    ALLOCATE,
}

export class GmMigrationAutoAllocationState {
    private api: GRPCServices;
    protected migrationService: GalaxyMigrateMigrationService;
    deploymentDetails: GalaxyMigrateDeploymentDetails;
    sourceDevices: GmMigrationWizardVolumeState[];
    selectedIntegration: IntegrationConfigInfo = null;

    currentStep = GmAutoAllocationStep.SELECT_INTEGRATION;
    currentAction: GmAutoAllocationActionType;
    currentProgress = new ServerData<AutoAllocStatus>().setDataFetcher(this.fetchCurrentProgressStatus.bind(this));
    showProgressDialog = false;
    progressDialogClosePromise: Promise<void> = null;
    progressDialogClosePromiseResolver: () => void = null;
    hostReadiness = new ServerData<IsHostReadyForAllocationResponse>().setDataFetcher(this.fetchHostReadiness.bind(this));
    statusId: string = null;
    azureRecommendation = new ServerData<GetAzureRecommendation.Response.AsObject>().setDataFetcher(this.getAzureRecommendation.bind(this));
    azureProducts = new ServerData<ListAzureProducts.Response.AsObject>().setDataFetcher(this.listAzureProducts.bind(this));
    allowSmallerDestinations = false;

    constructor(
        api: GRPCServices,
        migrationService: GalaxyMigrateMigrationService,
        deploymentDetails?: GalaxyMigrateDeploymentDetails,
        sourceDevices?: GmMigrationWizardVolumeState[],
        allowSmallerDestinations?: boolean
    ) {
        this.api = api;
        this.migrationService = migrationService;
        this.deploymentDetails = deploymentDetails;
        this.sourceDevices = sourceDevices;
        this.statusId = uuid();
        this.allowSmallerDestinations = allowSmallerDestinations;
        makeAutoObservable(this);
    }

    get systemID() {
        return this.deploymentDetails.getInfo().getDeployment().getSystemId();
    }

    setAws(sourceDevice: AutoAlloc.VolumeParams) {
        sourceDevice.setAws(new AutoAlloc.VolumeParams.AWS().setVolumeType(AutoAlloc.VolumeParams.AWS.VolumeType.GP2));
    }

    setAwsVolumeType(sourceDevice: AutoAlloc.VolumeParams.AWS, volumeType: AutoAlloc.VolumeParams.AWS.VolumeType) {
        sourceDevice.setVolumeType(volumeType);
    }

    getAwsVolumeType(sourceDevice: AutoAlloc.VolumeParams.AWS) {
        if (sourceDevice) {
            return sourceDevice.getVolumeType();
        }
        return null;
    }

    setDeployment(deployment: GalaxyMigrateDeploymentDetails) {
        this.deploymentDetails = deployment;
    }

    setNewStatusId(id: string) {
        this.statusId = id;
    }

    clearStatusId() {
        this.statusId = null;
    }

    setShowProgressDialog(show = false) {
        this.currentProgress.resetData();
        if (show) {
            this.progressDialogClosePromise = new Promise<void>((resolve) => {
                this.progressDialogClosePromiseResolver = resolve;
            });
        } else {
            // when closing
            if (this.progressDialogClosePromiseResolver) {
                this.progressDialogClosePromiseResolver();
            }
            this.progressDialogClosePromiseResolver = null;
            this.progressDialogClosePromise = null;
        }
        this.showProgressDialog = show;
    }

    async waitForProgressDialogToClose() {
        if (this.progressDialogClosePromise) {
            await this.progressDialogClosePromise;
        }
    }

    selectIntegration(integration: IntegrationConfigInfo) {
        this.selectedIntegration = integration;
        this.selectCurrentStep(GmAutoAllocationStep.PREPARE_HOST);
    }

    selectCurrentStep(step: GmAutoAllocationStep) {
        this.currentStep = step;
    }

    async fetchCurrentProgressStatus(systemID?: string) {
        systemID = systemID || this.systemID;

        const req = new GetAutoAllocStatusDetails.Request().setSystemId(systemID).setStatusId(this.statusId);

        const response = await this.api.gmService.getAutoAllocStatusDetails(req, null);
        return response.getDetailsResponse().getStatus();
    }

    async fetchHostReadiness(systemID?: string) {
        this.setNewStatusId(uuid());
        systemID = systemID || this.systemID;
        this.currentAction = GmAutoAllocationActionType.VALIDATE;
        const req = new IsPreparedForAutoAlloc.Request().setSystemId(systemID).setIntegrationId(this.selectedIntegration.getId()).setStatusId(this.statusId);
        const response = await this.api.gmService.isPreparedForAutoAlloc(req, null);
        return response.getReadinessResponse();
    }

    proceedFromHostPrepIfReady() {
        if (!!this.hostReadiness?.data?.getReady()) {
            this.selectCurrentStep(GmAutoAllocationStep.ALLOCATE_VOLUMES);
        }
    }

    async autoPrepareHost(systemID?: string) {
        this.setNewStatusId(uuid());
        systemID = systemID || this.systemID;
        this.currentAction = GmAutoAllocationActionType.PREPARE;
        const req = new PrepareForAutoAlloc.Request().setSystemId(systemID).setIntegrationId(this.selectedIntegration.getId()).setStatusId(this.statusId);
        await this.api.gmService.prepareForAutoAlloc(req, null);
    }

    async autoConfigure() {
        this.setShowProgressDialog(true);
        await this.autoPrepareHost();
        await this.waitForProgressDialogToClose();
        this.selectCurrentStep(GmAutoAllocationStep.ALLOCATE_VOLUMES);
    }

    async manualCheck() {
        this.setShowProgressDialog(true);
        await this.hostReadiness.fetchData();
        await this.waitForProgressDialogToClose();
        this.proceedFromHostPrepIfReady();
    }

    async getAzureRecommendation(
        devicePathsList: string[],
        windowSize: number,
        workloadGrowth: number,
        storageGrowth: number,
        recommendationPreference: RecommendationPreference
    ) {
        const req = new GetAzureRecommendation.Request()
            .setSystemId(this.migrationService.wizardState.deploymentId)
            .setDestinationSystemId(this.systemID)
            .setPreference(recommendationPreference)
            .setWorkloadGrowth(workloadGrowth)
            .setStorageGrowth(storageGrowth)
            .setIntegrationId(this.selectedIntegration.getId())
            .setDevicePathsList(this.sourceDevices.map((d) => d.source.getBlockDevice().getDeviceName()))
            .setWindowSize(windowSize);

        const res = await this.api.gmService.getAzureRecommendation(req, null);
        return res.toObject();
    }

    async getAzurePrice(
        capacity: number,
        iops: number,
        throughput: number,
        systemId: string,
        integrationId: number,
        storageType: AutoAlloc.VolumeParams.Azure.StorageType
    ) {
        const req = new GetAzurePrice.Request()
            .setCapacity(capacity)
            .setIops(iops)
            .setThroughput(throughput)
            .setStorageType(storageType)
            .setSystemId(systemId)
            .setIntegrationId(integrationId);

        const res = await this.api.gmService.getAzurePrice(req, null);
        return res.toObject();
    }

    async listAzureProducts() {
        const req = new ListAzureProducts.Request().setSystemId(this.systemID).setIntegrationId(this.selectedIntegration.getId());

        const res = await this.api.gmService.listAzureProducts(req, null);
        return res.toObject();
    }

    async allocateVolumes() {
        this.setNewStatusId(uuid());
        this.currentAction = GmAutoAllocationActionType.ALLOCATE;
        const sourceDeployment = this.migrationService.wizardState.selectedDeploymentName;
        const req = new AutoAllocVolumes.Request()
            .setSystemId(this.systemID)
            .setVolumesList(
                this.sourceDevices.map((d) => {
                    const requestVolume = new AllocateDestinationVolumesRequest.Volume()
                        .setUuid(uuid())
                        .setParams(new AutoAlloc.VolumeParams())
                        .setCapacity(d.autoAllocVolumeCapacity * Math.pow(1024, 3) || d.source.getBlockDevice().getCapacity())
                        .setName(`${this.deploymentDetails.getInfo().getDeployment().getSystemName()}_${d.source.getBlockDevice().getDeviceName()}`);
                    if (this.selectedIntegration.getModule() === IntegrationModule.AZURE) {
                        requestVolume.getParams().setAzure(d.autoAllocParams.getAzure());
                        requestVolume.setName(`${sourceDeployment}_${d.source.getBlockDevice().getDeviceName()}`);
                    } else if (this.selectedIntegration.getModule() === IntegrationModule.PURE) {
                        requestVolume.getParams().setPurecbs(new AutoAlloc.VolumeParams.PureCBS());
                    } else if (this.selectedIntegration.getModule() === IntegrationModule.DGS) {
                        requestVolume.getParams().setDgs(new AutoAlloc.VolumeParams.DGS());
                    } else if (this.selectedIntegration.getModule() === IntegrationModule.AWS) {
                        requestVolume.getParams().setAws(d.autoAllocParams.getAws());
                    } else if (
                        this.selectedIntegration.getModule() === IntegrationModule.NETAPP ||
                        this.selectedIntegration.getModule() === IntegrationModule.AWS_FSX_NETAPP
                    ) {
                        requestVolume.getParams().setNetapp(d.autoAllocParams.getNetapp());
                    } else if (
                        this.selectedIntegration.getModule() === IntegrationModule.FC_POWERSTORE ||
                        this.selectedIntegration.getModule() === IntegrationModule.POWERSTORE
                    ) {
                        requestVolume.getParams().setPowerstore(d.autoAllocParams.getPowerstore());
                    } else if (this.selectedIntegration.getModule() === IntegrationModule.FC_POWERMAX) {
                        requestVolume.getParams().setPowermax(d.autoAllocParams.getPowermax());
                    } else if (this.selectedIntegration.getModule() === IntegrationModule.GCP) {
                        requestVolume.getParams().setGcp(d.autoAllocParams.getGcp());
                    } else if (this.selectedIntegration.getModule() === IntegrationModule.ORACLE) {
                        requestVolume.getParams().setOracle(d.autoAllocParams.getOracle());
                    }
                    return requestVolume;
                })
            )
            .setIntegrationId(this.selectedIntegration.getId())
            .setStatusId(this.statusId);

        const response = await this.api.gmService.autoAllocVolumes(req, null);
        return response.getAllocateResponse().getAllocatedVolumesList();
    }

    async applyAllocatedVolumesToWizard(volumes: AllocateDestinationVolumesResponse.Volume[]) {
        // first rescan
        const wizardState = this.migrationService.wizardState;
        await wizardState.storageConfig.fetchData();
        if (wizardState.isRemoteMigration) {
            await wizardState.remoteStorageConfig.fetchData();
        }

        this.sourceDevices.forEach((pair, i) => {
            wizardState.selectDestinationVolume(pair.source.getBlockDevice().getDeviceName(), volumes[i].getDevicePath());
        });
    }
}

export class GmMigrationSessionEditState {
    private readonly api: GRPCServices;
    currentSessionID: string = null;
    deploymentId: string = null;
    sessionDetails: GalaxyMigrateMigrationSessionDetails;

    migrationParameters = initialMigrationParameters;

    postSyncActionsState: GmMigrationSessionPostSyncActionsState;

    initEditState(sessionID: string, sessionDetails: GalaxyMigrateMigrationSessionDetails) {
        if (sessionID !== this.currentSessionID) {
            this.currentSessionID = sessionID;
        }
        this.sessionDetails = sessionDetails;

        this.initPostSyncActionsState();
    }

    initPostSyncActionsState() {
        this.postSyncActionsState = new GmMigrationSessionPostSyncActionsState();
        this.postSyncActionsState.setEditState(this);
    }

    constructor(api: GRPCServices) {
        this.api = api;
        makeAutoObservable(this);
    }

    get hasQosSchedule() {
        return (
            !!this.migrationParameters.qosSchedule.daily.length ||
            !!this.migrationParameters.qosSchedule.fridays.length ||
            !!this.migrationParameters.qosSchedule.weekends.length
        );
    }

    public async updateMigrationSessionDetails(sessionId?: string) {
        const workflowList: WorkflowStage[] = this.postSyncActionsState.getWorkflowListForRequest();
        const id = sessionId || this.currentSessionID;
        const req = new UpdateMigrationSession.Request()
            .setSessionId(id)
            .setAutoResyncInterval(new Duration().setSeconds((this.migrationParameters.autoReSyncInterval || 0) * 60))
            .setDescription(this.migrationParameters.description)
            .setRemoteBootMigrationPostCutoverScript(this.migrationParameters.remoteBootMigrationPostCutoverScript)
            .setPostSyncWorkflowList(workflowList);

        if (!!this.migrationParameters.qosStartTime) {
            req.setQosStartTime(convertDateObjectToTimestamp(this.migrationParameters.qosStartTime));
        }

        if (this.hasQosSchedule) {
            req.setQosSchedule(createMigrationQosSchedule(this.migrationParameters.qosSchedule)).setQosLevel(SyncQos.ImpactLevel.NONE);
        } else if (!this.hasQosSchedule) {
            req.setQosLevel(this.migrationParameters.qosLevel);
        }

        return await this.api.gmService.updateMigrationSession(req, null);
    }
}

export class GmMigrationSessionPostSyncActionsState {
    actions: PostSyncAction[] = [];
    currentPostSyncActionConfig: PostSyncActionConfig;
    currentIntegrationId: number;
    currentProperties: any = {};
    currentSystemId: string;
    timeout: number = 3600;

    stepperState: StepperState;
    migrationWizardState: GmMigrationWizardState;
    migrationVolumeNames: string[] = [];
    migrationSessionEditState: GmMigrationSessionEditState;

    constructor() {
        this.stepperState = new StepperState();
        this.getWorkflowListForRequest.bind(this);
        makeAutoObservable(this);
    }

    setWizardState(state: GmMigrationWizardState) {
        this.migrationWizardState = state;
    }

    setEditState(state: GmMigrationSessionEditState) {
        this.migrationSessionEditState = state;
    }

    setMigrationVolumeNames(vols: string[]) {
        this.migrationVolumeNames = vols;
    }

    resetActionCreation() {
        this.currentPostSyncActionConfig = null;
        this.currentIntegrationId = null;
        this.currentProperties = {};
        this.currentSystemId = null;
        this.stepperState = new StepperState();
        this.timeout = 3600;
    }

    selectAction(a: PostSyncActionConfig, index?: number) {
        this.currentPostSyncActionConfig = a;

        const steps = getStepsByAction(a.action, this, index);
        this.stepperState.setStepConfigs(steps);
        this.stepperState.setTotalSteps(steps.length);

        if (a.action === WorkflowStage.Action.SNAPSHOT) {
            this.initSnapshotProperties();
            this.setSnapshotSystemId();
        }
        if (a.action === WorkflowStage.Action.EXECUTE) {
            this.initExecuteCommandProperties();
            this.setExecuteSystemId(this.destinationSystemId ? "" : this.sourceSystemId);
        }
        this.stepperState.goToNextStep();
    }

    get currentActionType() {
        return this.currentPostSyncActionConfig.action;
    }

    selectIntegration(id: number) {
        this.currentIntegrationId = id;
    }

    initSnapshotProperties() {
        this.currentProperties = {
            namePrefix: "snap",
        };
    }

    initExecuteCommandProperties() {
        this.currentProperties = {
            command: "",
            args: [],
        };
    }

    setSnapshotNamePrefix(name: string) {
        this.currentProperties.namePrefix = name;
    }

    setExecuteCommand(command: string) {
        this.currentProperties.command = command;
    }

    setActionTimeout(timeout: number) {
        this.timeout = timeout;
    }

    createSnapshotPropertiesStruct(a: PostSyncAction) {
        const properties: { volumes: { [key: string]: string } } = {
            volumes: {},
        };
        this.migrationVolumeNames.forEach((v) => {
            properties.volumes[v] = a.properties.namePrefix;
        });
        return properties;
    }

    createExecuteCommandStruct(a: PostSyncAction) {
        const properties: { command: string; args: string[] } = {
            command: a.properties.command,
            args: a.properties.args,
        };
        return properties;
    }

    createLogPropertiesStruct() {
        return {
            message: "Hello World",
        };
    }

    get isConfirmDisabled() {
        if (this.stepperState.isLastStep) {
            if (this.currentPostSyncActionConfig.action === WorkflowStage.Action.SNAPSHOT) {
                return !this.currentProperties.namePrefix;
            }
            if (this.currentPostSyncActionConfig.action === WorkflowStage.Action.EXECUTE) {
                return !(this.currentProperties.command && this.currentSystemId);
            }
            if (this.currentPostSyncActionConfig.action === WorkflowStage.Action.LOG) {
                return !this.stepperState.isLastStep;
            }
        }
        return true;
    }

    get sourceSystemId() {
        if (!!this.migrationWizardState) {
            return this.migrationWizardState.deploymentId;
        } else if (!!this.migrationSessionEditState) {
            return this.migrationSessionEditState.sessionDetails.getSessionInfo().getDeployment().getSystemId();
        }
        return undefined;
    }

    get sourceSystemName() {
        if (!!this.migrationWizardState) {
            return this.migrationWizardState.deployment.data.getInfo().getDeployment().getSystemName();
        } else if (!!this.migrationSessionEditState) {
            return this.migrationSessionEditState.sessionDetails.getSessionInfo().getDeployment().getSystemName();
        }
        return undefined;
    }

    get destinationSystemId() {
        if (!!this.migrationWizardState) {
            return this.migrationWizardState.selectedRemoteDestinationId;
        } else if (!!this.migrationSessionEditState) {
            return this.migrationSessionEditState.sessionDetails.getSessionInfo().getDestinationDeployment()?.getSystemId();
        }
        return undefined;
    }

    get destinationSystemName() {
        if (!!this.migrationWizardState) {
            return this.migrationWizardState.remoteDeployment.data.getInfo().getDeployment().getSystemName();
        } else if (!!this.migrationSessionEditState) {
            return this.migrationSessionEditState.sessionDetails.getSessionInfo().getDestinationDeployment()?.getSystemName();
        }
        return undefined;
    }

    setSnapshotSystemId() {
        this.currentSystemId = this.destinationSystemId ?? this.sourceSystemId;
    }

    setExecuteSystemId(id: string) {
        this.currentSystemId = id;
    }

    confirmAction(editActionIndex?: number) {
        const workflowStage = new WorkflowStage()
            .setAction(this.currentPostSyncActionConfig.action)
            .setIntegrationId(this.currentIntegrationId)
            .setTimeout(new Duration().setSeconds(this.timeout))
            .setSystemId(this.currentSystemId)
            .setPause(new Duration().setSeconds(0));

        const postSyncAction: PostSyncAction = {
            config: this.currentPostSyncActionConfig,
            workflowStage: workflowStage,
            properties: this.currentProperties,
        };

        if (editActionIndex >= 0) {
            this.editAction(postSyncAction, editActionIndex);
        } else {
            this.addAction(postSyncAction);
        }

        this.resetActionCreation();
    }

    setPropertiesByAction(a: PostSyncAction) {
        if (a.config.action === WorkflowStage.Action.SNAPSHOT) {
            a.workflowStage.setProperties(Struct.fromJavaScript(this.createSnapshotPropertiesStruct(a)));
        } else if (a.config.action === WorkflowStage.Action.LOG) {
            a.workflowStage.setProperties(Struct.fromJavaScript(this.createLogPropertiesStruct()));
        } else if (a.config.action === WorkflowStage.Action.EXECUTE) {
            a.workflowStage.setProperties(Struct.fromJavaScript(this.createExecuteCommandStruct(a)));
        }
    }

    getWorkflowListForRequest() {
        return this.actions.map((a) => {
            if (!!a.properties) {
                this.setPropertiesByAction(a);
            }
            return a.workflowStage;
        });
    }

    editAction(a: PostSyncAction, i: number) {
        this.actions[i] = a;
    }

    addAction(a: PostSyncAction) {
        this.actions.push(a);
    }

    moveActionUp(a: PostSyncAction, index: number) {
        const prevAction = this.actions[index - 1];
        this.actions[index] = prevAction;
        this.actions[index - 1] = a;
    }

    moveActionDown(a: PostSyncAction, index: number) {
        const prevAction = this.actions[index + 1];
        this.actions[index] = prevAction;
        this.actions[index + 1] = a;
    }

    removeAction(index: number) {
        this.actions.splice(index, 1);
    }
}
