// Project: GalaxyComplete
// Created: 9/27/20 by sammy
// File: CreateProjectScreen

import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {
    Button,
    Dialog,
} from '@mui/material';
import {DialogState, useDialogState, useShouldDialogFullScreen} from '../core/dialog/DialogService';
import {ProjectInfoForm} from "./ProjectInfoForm";
import {ScreenContainer} from "../layout/ScreenCommon";

// ======================
// CreateProjectButton
// ======================

interface CreateProjectButtonProps {
}

export const CreateProjectButton: React.FC<CreateProjectButtonProps> = observer((props) => {
    const createProjectDialogState = useDialogState();
    return <>
        <Button variant={'contained'} color={'secondary'} onClick={createProjectDialogState.open}>
            {'Create New Project'}
        </Button>
        <CreateProjectDialog dialogState={createProjectDialogState}/>
    </>
})

// ======================
// CreateProjectDialog
// ======================
interface CreateProjectDialogProps {
    dialogState: DialogState;
}

export const CreateProjectDialog: React.FC<CreateProjectDialogProps> = observer((p) => {
    const {dialogState} = p;
    return (<Dialog open={dialogState.isOpen}
                    fullScreen={useShouldDialogFullScreen()}
                    maxWidth={'sm'}
                    fullWidth
                    onClose={dialogState.close}>
        {dialogState.isOpen && <ProjectInfoForm dialogState={dialogState} type={'create'}/>}
    </Dialog>);
});