import {observer} from "mobx-react-lite";
import React from "react";
import {ScreenContainer, ScreenTitleBar} from "../../layout/ScreenCommon";
import {
    Box,
    Card,
    Divider,
    Grid, IconButton,
    LinearProgress,
    Link,
    ListSubheader,
    SvgIcon,
    Theme,
    Typography,
    useTheme
} from "@mui/material";
import {Link as RouterLink, useParams} from "react-router-dom";
import {
    renderServerDataWithLoadingBox,
    renderServerDataWithLoadingList,
    useInitData
} from "../../core/data/DataLoaderHooks";
import {useAppServices} from "../../app/services";
import {generateCdmPoliciesSubPath, generatePhoenixDeploymentDetailsPath} from "../ProjectProtectionCommon";
import {CDM_POLICY_DETAILS_SUBROUTE} from "../../app/AppRoutes";
import {CDMPolicy} from "../../../_proto/galaxycompletepb/apipb/domainpb/phoenix_pb";
import {RouterTabConfig, RouterTabs} from "../../../common/tabs/TabComponents";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {formatKnownDataType, KnownDataType} from "../../../common/utils/formatter";
import {KVTable} from "../../../common/table/KVTable";
import {useCurrentProject, useCurrentProjectID} from "../../project/CurrentProject";
import {FaAngleDoubleDown, FaAngleDoubleRight} from "react-icons/fa";
import {useIsDesktop} from "../../layout/MainLayout";
import {DialogState, useDialogState} from "../../core/dialog/DialogService";
import {AddCdmActionButton, CdmActionCard, CdmActionDialog} from "./CdmAction";
import {OperatorView} from "../../auth/AuthenticatedViews";
import {CdmPolicyMenuButton, CdmPolicyMenuDialog} from "./CdmPolicyMenu";
import {
    CdpIcon,
    renderPolicyModeName,
    renderPolicyTypeName,
    renderSyncStatusName,
    SnapshotIcon
} from "./CdmPolicyCommon";
import {AddVolumesDialogButton, RemoveVolumesDialogButton} from "./CdmPolicyDetailsVolumeManagementDialogs";


const useCdmPolicyDetailsStyles = () => {
    const t = useTheme();
    return {
        card: {
            height: '100%'
        },
        syncCard: {
            marginBottom: t.spacing(2),
        }
    }
}


// ======================
// CdmPolicyDetailsScreen
// ======================

interface CdmPolicyDetailsScreenProps {
}

export const CdmPolicyDetailsScreen: React.FC<CdmPolicyDetailsScreenProps> = observer((p) => {
    const {policyId, projectId} = useParams();
    const {cdmPolicyService} = useAppServices();
    const currentPath = generateCdmPoliciesSubPath(policyId, projectId);
    const actionDialogState = useDialogState();
    const menuDialogState = useDialogState();

    useInitData({
        poll: () => cdmPolicyService.policyDetails.fetchData(policyId),
        pollInterval: 3
    });

    const getActions = () => {
        return <OperatorView>
            <CdmPolicyMenuButton dialogState={menuDialogState}/>
        </OperatorView>
    }

    return renderServerDataWithLoadingBox(cdmPolicyService.policyDetails, data => {

        const tabsConfig: RouterTabConfig[] = [
            {
                path: '',
                label: 'Policy Info',
                renderer: () => <CdmPolicyInfoSection policy={data.getPolicyInfo()}/>,
                default: true
            },
            {
                path: CDM_POLICY_DETAILS_SUBROUTE.SNAPSHOTS,
                label: 'Snapshots',
                renderer: () => <CdmPolicySnapshotsSection policy={data.getPolicyInfo()}
                                                      actionDialogState={actionDialogState}/>
            },
        ];

        return <ScreenContainer>
            <ScreenTitleBar title={data.getPolicyInfo().getName()} actions={getActions()}/>
            <RouterTabs configs={tabsConfig} path={currentPath}/>
            <CdmActionDialog dialogState={actionDialogState}/>
            <CdmPolicyMenuDialog dialogState={menuDialogState} addCdmActionDialogState={actionDialogState}/>
        </ScreenContainer>
    })
});

// ======================
// CdmPolicyInfoSection
// ======================

interface CdmPolicyInfoSectionProps {
    policy: CDMPolicy
}

export const CdmPolicyInfoSection: React.FC<CdmPolicyInfoSectionProps> = observer((p) => {
    const theme = useTheme();
    const projectName = useCurrentProject().getInfo().getName();
    const styles = useCdmPolicyDetailsStyles();
    const projectId = useCurrentProjectID();

    return <Box pt={2}>
        <Typography variant={'h6'}>Overview</Typography>
        <Box pt={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                    <Card sx={styles.card}>
                        <Box>
                            <Box p={2}>
                                <Typography variant={'h4'} align={'center'}>
                                    Sync Status: {renderSyncStatusName(p.policy.getSyncStatus())}
                                </Typography>
                            </Box>
                            <Divider/>
                            <Box p={8} pt={1} pb={0} display={'flex'} justifyContent={'center'}>
                                <Box maxWidth={400}>
                                    <CircularProgressbar value={p.policy.getStats().getProgressPercentage()}
                                                         text={`${p.policy.getStats().getProgressPercentage()}%`}
                                                         strokeWidth={5} styles={buildStyles({
                                        pathColor: theme.palette.primary.light,
                                        textColor: theme.palette.primary.light,
                                    })}/>
                                </Box>
                            </Box>
                            <br/>
                            <Typography align={'center'} variant={'h6'}>
                                {`${formatKnownDataType(p.policy.getStats().getTotalSynced(), KnownDataType.CAPACITY)} / ${formatKnownDataType(p.policy.getTotalCapacity(), KnownDataType.CAPACITY)}`}
                            </Typography>
                            <Typography align={'center'} paragraph>
                                {'Synchronized'}
                            </Typography>
                            <br/>
                            <br/>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={9}>
                    <Box>
                        <Card>
                            <Box p={2}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant={'h6'}>
                                            {p.policy.getDisksCount()}
                                        </Typography>
                                        <Typography variant={'overline'}>
                                            volumes
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant={'h6'}>
                                            {formatKnownDataType(p.policy.getCreatedAt().toDate(), KnownDataType.DATE_RELATIVE)}                                    </Typography>
                                        <Typography variant={'overline'}>
                                            time created
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Card>
                    </Box>
                    <br/>
                    <Box>
                        <Card>
                            <ListSubheader>Policy Overview</ListSubheader>
                            <KVTable data={[
                                {
                                    key: 'Project',
                                    value: projectName,
                                },
                                {
                                    key: 'Policy Type',
                                    value: renderPolicyTypeName(p.policy.getPolicyType())
                                },
                                {
                                    key: 'Host & Protection Target',
                                    value: <Link component={RouterLink}
                                                 to={generatePhoenixDeploymentDetailsPath(p.policy.getDeployment().getSystemId(), projectId)}>{p.policy.getDeployment().getSystemName()}</Link>
                                },
                                {
                                    key: 'Policy Mode',
                                    value: renderPolicyModeName(p.policy.getSynchronizationMode()),
                                },
                                {
                                    key: 'Policy Description',
                                    value: p.policy.getDescription()
                                },
                                {
                                    key: 'Snapshots',
                                    value: p.policy.getSnapshotsCount()
                                },
                                {
                                    key: 'Created',
                                    value: `${formatKnownDataType(p.policy.getCreatedAt().toDate(), KnownDataType.DATE)}`
                                },
                            ]}/>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <br/>
        <ProtectionStatusSection policy={p.policy}/>
        <br/>
        <SyncProgressSection/>
    </Box>
});

// ======================
// ProtectionStatusSection
// ======================

interface ProtectionStatusSectionProps {
    policy: CDMPolicy
}

const ProtectionStatusSection: React.FC<ProtectionStatusSectionProps> = observer((p) => {

    return <Box>
        <Typography variant={'h6'}>Protection Status</Typography>
        <Box pt={2}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} p={4}>
                            <Box textAlign={'center'}>
                                <Box>
                                    <SnapshotIcon imageAttributes={{height: 64, width: 64}}/>
                                    <Typography variant={'h6'}>{p.policy.getSnapshotsCount()} Policy Snapshot(s)
                                        Available</Typography>
                                </Box>
                                {/*                                <Box>
                                    <Typography variant={'caption'} color={'textSecondary'}>Protecting the period of time from:</Typography>
                                </Box>
                                <Box>
                                    <Typography>{}</Typography>
                                </Box>*/}
                            </Box>
                        </Box>
                    </Card>
                </Grid>
                {/*<Grid item xs={12} md={6}>
                    <Card>
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} p={4}>
                            <Box textAlign={'center'}>
                                <Box>
                                    <CdpIcon imageAttributes={{height: 64, width: 64}}/>
                                    <Typography variant={'h6'}>CDP Protected Data</Typography>
                                </Box>
                                <Box>
                                    <Typography variant={'caption'} color={'textSecondary'}>Protecting the period of time from:</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Grid>*/}
            </Grid>
        </Box>
    </Box>
})

// ======================
// SyncProgressSection
// ======================

interface SyncProgressSectionProps {
}

const SyncProgressSection: React.FC<SyncProgressSectionProps> = observer((p) => {
    const styles = useCdmPolicyDetailsStyles();
    const isDesktop = useIsDesktop();
    const {cdmPolicyService, cpDeploymentService} = useAppServices();
    const data = cpDeploymentService.cpDeploymentPolicyDetails.data?.getPolicyDisksList();
    // useInitData({
    //     poll: () => cdmPolicyService.policyDisks.fetchData(cpDeploymentService.currentPolicyId),
    //     pollInterval: 3
    // });

    return <Box>
        <Box display={'flex'} justifyContent={'space-between'}>
            <Typography variant={'h6'}>Volume Synchronization Progress</Typography>
            <Box display={'flex'}>
                <Box pr={1}>
                    <AddVolumesDialogButton/>
                </Box>
                <Box>
                    <RemoveVolumesDialogButton/>
                </Box>
            </Box>
        </Box>
        {
            data.length === 0 &&
            <Card>
                <Box p={2} display={'flex'} justifyContent={'center'}>
                    <Typography variant={'h6'}>
                        No Volumes Found
                    </Typography>
                </Box>
            </Card>
        }
        {
            data.map((v, i) => {
                return <Box pt={2} key={i}>
                    <Card sx={styles.syncCard}>
                    <LinearProgress variant="determinate" value={v.getStats().getProgressPercentage()}/>
                    <Box pt={1}>
                        <Grid container alignItems={'center'} justifyContent={'space-around'}>
                            <Grid item xs={12} md={4}>
                                <Typography variant={'body1'} align={'center'} sx={{
                                    overflowWrap: 'break-word',
                                    fontWeight: 'bold'
                                }}>
                                    {v.getSourceDiskName() || v.getPolicyDiskName()}
                                </Typography>
                                <Typography variant={'caption'} align={'center'} paragraph>
                                    {formatKnownDataType(v.getSourceDiskCapacity() || v.getPolicyDiskCapacity(), KnownDataType.CAPACITY)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={1} justifyContent={'center'}>
                                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} pb={1}>
                                    <SvgIcon>
                                        {isDesktop &&
                                            <FaAngleDoubleRight/>
                                        }
                                        {!isDesktop &&
                                            <FaAngleDoubleDown/>
                                        }
                                    </SvgIcon>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant={'body1'} align={'center'} sx={{
                                    overflowWrap: 'break-word',
                                    fontWeight: 'bold'
                                }}>
                                    {v.getPolicyDiskName()}
                                </Typography>
                                <Typography variant={'caption'} align={'center'} paragraph>
                                    {formatKnownDataType(v.getPolicyDiskCapacity(), KnownDataType.CAPACITY)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Divider/>
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item xs={3}>
                            <Typography variant={'h5'} align={'center'}>
                                {renderSyncStatusName(v.getSyncStatus())}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Box p={2}>
                                <Typography variant={'caption'} color={'textSecondary'} component={'div'}>
                                    {'Sync Progress'} - {v.getStats().getProgressPercentage()}%
                                </Typography>
                                <Typography variant={'subtitle1'}>
                                    {formatKnownDataType(v.getStats().getTotalSynced(), KnownDataType.CAPACITY)} / {formatKnownDataType(v.getSourceDiskCapacity(), KnownDataType.CAPACITY)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box p={2}>
                                <Typography variant={'caption'} color={'textSecondary'} component={'div'}>
                                    {'Total Changes (New)'}
                                </Typography>
                                <Typography variant={'subtitle1'}>
                                    {formatKnownDataType(v.getStats().getCurrentChanged(), KnownDataType.CAPACITY)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box p={2}>
                                <Typography variant={'caption'} color={'textSecondary'} component={'div'}>
                                    {'Backup Rate'}
                                </Typography>
                                <Typography variant={'subtitle1'}>
                                    {formatKnownDataType(v.getStats().getCurrentThroughput(), KnownDataType.THROUGHPUT)}

                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box p={2}>
                                <Typography variant={'caption'} color={'textSecondary'} component={'div'}>
                                    {'Remaining'}
                                </Typography>
                                <Typography variant={'subtitle1'}>
                                    {formatKnownDataType(v.getStats().getTotalRemaining(), KnownDataType.CAPACITY)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
                </Box>

            })
        }
    </Box>

})

// ======================
// CdmPolicySnapshotsSection
// ======================

interface CdmPolicySnapshotsSectionProps {
    policy: CDMPolicy;
    actionDialogState: DialogState;

}

export const CdmPolicySnapshotsSection: React.FC<CdmPolicySnapshotsSectionProps> = observer((p) => {

    return <Box>
        <Box pt={2} display={'flex'} justifyContent={'space-between'}>
            <Typography variant={'h6'}>CDM Actions</Typography>
            <AddCdmActionButton dialogState={p.actionDialogState}/>
        </Box>
        <br/>
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <CdmActionCard dialogState={p.actionDialogState}/>
            </Grid>
        </Grid>
    </Box>
});




