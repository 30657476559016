import {observer} from "mobx-react-lite";
import {ScreenContainer, ScreenTitleBar} from "../../layout/ScreenCommon";
import React, {CSSProperties, useState} from "react";
import {useParams} from "react-router-dom";
import {BackButton} from "../../../common/CommonButtons";
import {useNavigateToMigrationSessionDetails} from "../MigrationCommon";
import {useAppServices} from "../../app/services";
import {renderServerDataWithLoadingBox, useInitData} from "../../core/data/DataLoaderHooks";
import {
    WorkflowLog, WorkflowStage,
    WorkflowStageStatus,
    WorkflowStatus
} from "../../../_proto/galaxycompletepb/apipb/domainpb/workflow_pb";
import {
    Badge,
    Box,
    Card, Checkbox,
    Divider, FormControlLabel,
    Grid,
    ListItem,
    ListItemText, SvgIcon,
    Theme,
    Typography,
    useTheme
} from "@mui/material";
import {ActionConfiguredPropertiesTable, getWorkflowRunStatusDisplayText} from "./PostSyncActionsCommon";
import {formatKnownDataType, KnownDataType} from "../../../common/utils/formatter";
import {CollapsibleCard} from "../../../common/collapsible/CollapsibleCard";
import {getActionDisplayName} from "./GmMigrationSessionPostSyncActions";
import {OperatorView} from "../../auth/AuthenticatedViews";
import {ActionConfig, ActionMenuButton} from "../../../common/actions/CommonActions";
import {TiCancel} from "react-icons/ti";


const useWorkflowRunDetailsStyles = (): Record<string, CSSProperties> => {
    const t = useTheme();
    return {
        card: {
            width: '100%'
        },
        logCard: {
            backgroundColor: t.palette.cirrus.main,
        },
        badge: {
            backgroundColor: 'rgba(0,0,0,.38)',
            height: t.spacing(3),
            width: t.spacing(3),
            borderRadius: t.spacing(3)

        },
        divider: {
            backgroundColor: 'rgba(0,0,0,.38)',
        },
        log: {
            wordWrap: 'break-word',
        },
        pre: {
            overflowX: 'auto',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
        }
    }

}

// ======================
// GmMigrationSessionWorkflowRunDetailsView
// ======================

interface GmMigrationSessionWorkflowRunDetailsViewProps {
}

export const GmMigrationSessionWorkflowRunDetailsView: React.FC<GmMigrationSessionWorkflowRunDetailsViewProps> = observer((p) => {
    const {workflowRunId} = useParams();
    const {gmMigrationService} = useAppServices();
    const backToMigrationSessionDetails = useNavigateToMigrationSessionDetails();

    useInitData({
        poll: () => gmMigrationService.currentWorkflowRunDetails.fetchData(workflowRunId),
        pollInterval: 3
    });

    const getActions = (): ActionConfig[] => {
        return [

            {
                id: 'cancel',
                name: 'Abort Task',
                icon: <SvgIcon><TiCancel/></SvgIcon>,
                action: () => gmMigrationService.cancelWorkflowRun(gmMigrationService.currentWorkflowRunDetails.data?.getId()),
                hidden: !gmMigrationService.currentWorkflowRunDetails.data?.getRunning()
            }

        ]
    }

    const actions = <Box display={'flex'} alignItems={'center'}>
        <OperatorView>
            <ActionMenuButton actions={getActions()}/>
        </OperatorView>
    </Box>

    return <ScreenContainer>
        <BackButton navFunction={backToMigrationSessionDetails} label={'Back To Migration Session Details'}/>
        <ScreenTitleBar title={`Activity Details`} actions={actions}/>
        {
            renderServerDataWithLoadingBox(gmMigrationService.currentWorkflowRunDetails, data => {
                return <WorkflowRunDetails data={data}/>
            })
        }
    </ScreenContainer>
});

// ======================
// WorkflowRunDetails
// ======================

interface WorkflowRunDetailsProps {
    data: WorkflowStatus
}

export const WorkflowRunDetails: React.FC<WorkflowRunDetailsProps> = observer((p) => {
    const t = useTheme();
    const [showDebugMessages, setShowDebugMessages] = useState(false);
    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowDebugMessages(event.target.checked)
    }

    return <Box>
        <Box>
            <Typography variant={'h6'}>Basic Information</Typography>
            {/*<Typography variant={'body1'}>Overview of this activity</Typography>*/}
            <br/>
            <Card>
                <Box p={2}>
                    <Grid container justifyContent={'flex-start'}>
                        <Grid item xs={12} md={4}>
                            <Box pb={2}>
                                <Typography variant={'h6'}>#{p.data.getId()}</Typography>
                                <Typography variant={'overline'}>Activity ID</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box pb={2}>
                                {getWorkflowRunStatusDisplayText(p.data, t, {variant: 'h6'})}
                                <Typography variant={'overline'}>Status</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box pb={2}>
                                <Typography
                                    variant={'h6'}>{formatKnownDataType(p.data.getStartedAt().toDate(), KnownDataType.DATE)}</Typography>
                                <Typography variant={'overline'}>Last Trigger Time</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
            <br/>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                    <Typography variant={'h6'}>Executed Tasks</Typography>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox checked={showDebugMessages} onChange={handleCheck} name={'showDebugMessages'} />}
                        label={'Show debug messages between tasks'}
                    />
                </Grid>
            </Grid>
            <br/>
            <ExecutedTasksTimeline data={p.data} showDebugMessages={showDebugMessages}/>
        </Box>
    </Box>
});

// ======================
// ExecutedTasksTimeline
// ======================

interface ExecutedTasksTimelineProps {
    data: WorkflowStatus,
    showDebugMessages: boolean
}

interface WorkflowLogTimelineType {
    log?: WorkflowLog,
    stage?: { status: WorkflowStageStatus, index: number, logs: WorkflowLog[] }
}

export const ExecutedTasksTimeline: React.FC<ExecutedTasksTimelineProps> = observer((p) => {
    const {gmMigrationService} = useAppServices();
    const stages = p.data.getStagesList();
    const styles = useWorkflowRunDetailsStyles();
    const theme = useTheme();

    useInitData(({
        poll: () => gmMigrationService.currentWorkflowRunLogs.fetchData(p.data.getId()),
        pollInterval: 3
    }))

    return renderServerDataWithLoadingBox(gmMigrationService.currentWorkflowRunLogs, data => {
        const getTimelineData = (): WorkflowLogTimelineType[] => {
            const timelineData: WorkflowLogTimelineType[] = [];

            const logs = data.getItemsList();
            logs.forEach((l, i) => {
                const existingStage = timelineData.find(v => v.stage?.index === l.getStageIndex());
                if (l.getStageIndex() === -1) {
                    if (p.showDebugMessages){
                        timelineData.push({
                            log: l
                        })
                    }
                } else if (!!existingStage) {
                    existingStage.stage.logs.push(l)
                } else {
                    timelineData.push({
                        stage: {
                            index: l.getStageIndex(),
                            logs: [l],
                            status: stages.find(v => v.getIndex() === l.getStageIndex())
                        }
                    })
                }
            });


            return timelineData;
        };

        return <>
            {getTimelineData().map((s, i) => {
                return <>
                    {s.log &&
                    <Box display={'flex'} alignItems={'center'} width={'100%'}>
                        <Card sx={styles.card}>
                            <Box p={2} width={'100%'}>
                                <Card sx={styles.logCard}>
                                    <Box p={2} width={'100%'}>
                                        <pre style={styles.pre}>[{formatKnownDataType(s.log.getTime().toDate(), KnownDataType.DATE)}] {s.log.getMessage()}</pre>
                                    </Box>
                                </Card>
                            </Box>
                        </Card>
                    </Box>
                    }
                    {s.stage &&
                    <Box display={'flex'} alignItems={'center'} width={'100%'}>
                        <CollapsibleCard
                            collapsibleElement={<WorkflowRunLogArea logs={s.stage.logs} workflowStage={s.stage.status.getInfo()}/>}
                            sx={styles.card}>
                            <WorkflowRunStageInfo stageStatus={s.stage.status} runStatus={p.data}/>
                        </CollapsibleCard>
                    </Box>
                    }
                    {(i !== getTimelineData().length - 1) &&
                    <svg width={'100%'} height={40}>
                        <line x1={'50%'} y1={5} x2={'50%'} y2={40} strokeWidth={3} stroke={'white'}/>
                    </svg>
                    }
                </>

            })}
        </>
    })
});

// ======================
// WorkflowRunStageInfo
// ======================

interface WorkflowRunStageInfoProps {
    stageStatus: WorkflowStageStatus,
    runStatus: WorkflowStatus
}

export const WorkflowRunStageInfo: React.FC<WorkflowRunStageInfoProps> = observer((p) => {
    const styles = useWorkflowRunDetailsStyles();
    const theme = useTheme();

    return <Box display={'flex'} alignItems={'center'} height={84} width={'100%'}
                justifyContent={'space-between'}>
        <Box display={'flex'}>
            <Box pl={4} pr={4} display={'flex'} alignItems={'center'}>
                <Badge badgeContent={p.stageStatus.getIndex() + 1} color={'primary'}
                       sx={{
                           '& .MuiBadge-badge': styles.badge
                       }}/>
            </Box>

            <Box height={84}>
                <Divider orientation={'vertical'} sx={styles.divider}/>
            </Box>
        </Box>
        <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={4}>
                <Box p={2}>
                    {getWorkflowStageStatusDisplayText(p.stageStatus, theme, p.runStatus)}
                    {p.stageStatus.getError() &&
                    <Box color={theme.palette.error.light}>
                        <Typography variant={'subtitle2'} color={"inherit"}>{p.stageStatus.getError()}</Typography>
                    </Box>
                    }

                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box>
                    <ListItem>
                        <ListItemText primary={getActionDisplayName(p.stageStatus.getInfo().getAction())}
                                      secondary={'Action'}/>
                    </ListItem>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box>
                    <ListItem>
                        <ListItemText
                            primary={p.stageStatus.getStartedAt().getSeconds() > 0 ? formatKnownDataType(p.stageStatus.getStartedAt().toDate(), KnownDataType.DATE): '--'}
                            secondary={'Trigger Time'}/>
                    </ListItem>
                </Box>
            </Grid>
        </Grid>
    </Box>
})

// ======================
// WorkflowRunLogArea
// ======================

interface WorkflowRunLogAreaProps {
    logs: WorkflowLog[],
    workflowStage: WorkflowStage
}

export const WorkflowRunLogArea: React.FC<WorkflowRunLogAreaProps> = observer((p) => {
    const styles = useWorkflowRunDetailsStyles();
    return <Box width={'100%'}>
        <Divider sx={styles.divider}/>
        <Box p={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={9}>
                    <Box>
                        <Card sx={styles.logCard}>
                            <Box p={2} width={'100%'} sx={styles.log}>
                                {p.logs.map((l, i) => {
                                    return <pre
                                        style={styles.pre}>[{formatKnownDataType(l.getTime().toDate(), KnownDataType.DATE)}] {l.getMessage()}</pre>
                                })}
                            </Box>
                        </Card>
                    </Box>
                </Grid>
                <Grid xs={12} sm={3}>
                    <Box width={'100%'} p={2}>
                        <Typography variant={'overline'}>Configured Properties</Typography>
                        <ActionConfiguredPropertiesTable workflowStage={p.workflowStage}/>
                    </Box>
                </Grid>
            </Grid>


        </Box>
    </Box>
})

export const getWorkflowStageStatusDisplayText = (status: WorkflowStageStatus, t: Theme, workflowStatus: WorkflowStatus) => {
    if (status.getPending()) {
        if (!!workflowStatus.getError()) {
            return <Typography color={'textSecondary'}>Canceled</Typography>
        }
        return <Typography color={'textSecondary'}>Pending</Typography>
    } else if (status.getError()) {
        return <Typography color={'error'}>Failed</Typography>
    } else if (status.getEndedAt().getSeconds() > 0) {
        return <Box color={t.palette.success.main}><Typography
            color={'inherit'}>Completed</Typography></Box>
    } else {
        return <Typography>In Progress</Typography>
    }
}