// source: galaxycompletepb/apipb/domainpb/galaxymigrate.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var galaxycompletepb_apipb_domainpb_project_pb = require('../../../galaxycompletepb/apipb/domainpb/project_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_project_pb);
var galaxycompletepb_apipb_domainpb_deployment_pb = require('../../../galaxycompletepb/apipb/domainpb/deployment_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_deployment_pb);
var galaxycompletepb_apipb_domainpb_compute_pb = require('../../../galaxycompletepb/apipb/domainpb/compute_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_compute_pb);
var galaxycompletepb_apipb_domainpb_mtdi_pb = require('../../../galaxycompletepb/apipb/domainpb/mtdi_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_mtdi_pb);
var galaxycompletepb_apipb_domainpb_user_pb = require('../../../galaxycompletepb/apipb/domainpb/user_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_user_pb);
var galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb);
var galaxymigratepb_galaxy_migrate_storage_pb = require('../../../galaxymigratepb/galaxy_migrate_storage_pb.js');
goog.object.extend(proto, galaxymigratepb_galaxy_migrate_storage_pb);
var galaxymigratepb_galaxy_migrate_qos_pb = require('../../../galaxymigratepb/galaxy_migrate_qos_pb.js');
goog.object.extend(proto, galaxymigratepb_galaxy_migrate_qos_pb);
var galaxymigratepb_galaxy_migrate_migration_pb = require('../../../galaxymigratepb/galaxy_migrate_migration_pb.js');
goog.object.extend(proto, galaxymigratepb_galaxy_migrate_migration_pb);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.EventLevel', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateStorageConfig', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.Role', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateStorageConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateStorageConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.displayName = 'proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    deployment: (f = msg.getDeployment()) && galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentInfo.toObject(includeInstance, f),
    osType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    kernel: jspb.Message.getFieldWithDefault(msg, 6, ""),
    osClass: jspb.Message.getFieldWithDefault(msg, 7, ""),
    memory: jspb.Message.getFieldWithDefault(msg, 8, 0),
    memoryUsed: jspb.Message.getFieldWithDefault(msg, 9, 0),
    cpu: jspb.Message.getFieldWithDefault(msg, 10, ""),
    mtdiVersion: jspb.Message.getFieldWithDefault(msg, 11, ""),
    helperNode: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    helperNodeType: (f = msg.getHelperNodeType()) && galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb.DeploymentHostEnvironment.toObject(includeInstance, f),
    helperNodeConfigured: jspb.Message.getBooleanFieldWithDefault(msg, 17, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo;
  return proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentInfo.deserializeBinaryFromReader);
      msg.setDeployment(value);
      break;
    case 5:
      var value = /** @type {!proto.galaxycomplete.domain.MTDIDeploymentInfo.OSType} */ (reader.readEnum());
      msg.setOsType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setKernel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOsClass(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMemory(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMemoryUsed(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCpu(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMtdiVersion(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHelperNode(value);
      break;
    case 16:
      var value = new galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb.DeploymentHostEnvironment;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb.DeploymentHostEnvironment.deserializeBinaryFromReader);
      msg.setHelperNodeType(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHelperNodeConfigured(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeployment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentInfo.serializeBinaryToWriter
    );
  }
  f = message.getOsType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getKernel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOsClass();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMemory();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getMemoryUsed();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getCpu();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMtdiVersion();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getHelperNode();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getHelperNodeType();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb.DeploymentHostEnvironment.serializeBinaryToWriter
    );
  }
  f = message.getHelperNodeConfigured();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
};


/**
 * optional DeploymentInfo deployment = 1;
 * @return {?proto.galaxycomplete.domain.DeploymentInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.getDeployment = function() {
  return /** @type{?proto.galaxycomplete.domain.DeploymentInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.DeploymentInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.setDeployment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.clearDeployment = function() {
  return this.setDeployment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.hasDeployment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MTDIDeploymentInfo.OSType os_type = 5;
 * @return {!proto.galaxycomplete.domain.MTDIDeploymentInfo.OSType}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.getOsType = function() {
  return /** @type {!proto.galaxycomplete.domain.MTDIDeploymentInfo.OSType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.MTDIDeploymentInfo.OSType} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.setOsType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string kernel = 6;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.getKernel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.setKernel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string os_class = 7;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.getOsClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.setOsClass = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint64 memory = 8;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.getMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.setMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 memory_used = 9;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.getMemoryUsed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.setMemoryUsed = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string cpu = 10;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.getCpu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.setCpu = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string mtdi_version = 11;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.getMtdiVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.setMtdiVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool helper_node = 15;
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.getHelperNode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.setHelperNode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional enums.DeploymentHostEnvironment helper_node_type = 16;
 * @return {?proto.galaxycomplete.domain.enums.DeploymentHostEnvironment}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.getHelperNodeType = function() {
  return /** @type{?proto.galaxycomplete.domain.enums.DeploymentHostEnvironment} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb.DeploymentHostEnvironment, 16));
};


/**
 * @param {?proto.galaxycomplete.domain.enums.DeploymentHostEnvironment|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.setHelperNodeType = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.clearHelperNodeType = function() {
  return this.setHelperNodeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.hasHelperNodeType = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool helper_node_configured = 17;
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.getHelperNodeConfigured = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.prototype.setHelperNodeConfigured = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.toObject(includeInstance, f),
    networkInterfacesList: jspb.Message.toObjectList(msg.getNetworkInterfacesList(),
    galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentNetworkInterfaceInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails;
  return proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentNetworkInterfaceInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentNetworkInterfaceInfo.deserializeBinaryFromReader);
      msg.addNetworkInterfaces(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo.serializeBinaryToWriter
    );
  }
  f = message.getNetworkInterfacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentNetworkInterfaceInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional GalaxyMigrateDeploymentInfo info = 1;
 * @return {?proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.prototype.getInfo = function() {
  return /** @type{?proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.GalaxyMigrateDeploymentInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated DeploymentNetworkInterfaceInfo network_interfaces = 2;
 * @return {!Array<!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo>}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.prototype.getNetworkInterfacesList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentNetworkInterfaceInfo, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo>} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.prototype.setNetworkInterfacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.prototype.addNetworkInterfaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.DeploymentNetworkInterfaceInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails.prototype.clearNetworkInterfacesList = function() {
  return this.setNetworkInterfacesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.toObject, includeInstance),
    lvmConfig: (f = msg.getLvmConfig()) && proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateStorageConfig;
  return proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device;
      reader.readMessage(value,proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 2:
      var value = new proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig;
      reader.readMessage(value,proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.deserializeBinaryFromReader);
      msg.setLvmConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.serializeBinaryToWriter
    );
  }
  f = message.getLvmConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockDevice: (f = msg.getBlockDevice()) && galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo.toObject(includeInstance, f),
    role: (f = msg.getRole()) && proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.toObject(includeInstance, f),
    canInsertAsSource: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    canEnlistAsDestination: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device;
  return proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo.deserializeBinaryFromReader);
      msg.setBlockDevice(value);
      break;
    case 2:
      var value = new proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanInsertAsSource(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanEnlistAsDestination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.serializeBinaryToWriter
    );
  }
  f = message.getCanInsertAsSource();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getCanEnlistAsDestination();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: jspb.Message.getFieldWithDefault(msg, 1, 0),
    deviceUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    migrationSessionUuid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    devicePathSelected: jspb.Message.getFieldWithDefault(msg, 4, ""),
    forRemoteSystemId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo;
  return proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMigrationSessionUuid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePathSelected(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setForRemoteSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDeviceUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMigrationSessionUuid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDevicePathSelected();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getForRemoteSystemId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.Role = {
  NONE: 0,
  SOURCE: 1,
  DESTINATION: 2
};

/**
 * optional Role role = 1;
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.Role}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.prototype.getRole = function() {
  return /** @type {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.Role} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.Role} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string device_uuid = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.prototype.getDeviceUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.prototype.setDeviceUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string migration_session_uuid = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.prototype.getMigrationSessionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.prototype.setMigrationSessionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string device_path_selected = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.prototype.getDevicePathSelected = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.prototype.setDevicePathSelected = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string for_remote_system_id = 5;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.prototype.getForRemoteSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.prototype.setForRemoteSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional galaxymigrate.BlockDeviceInfo block_device = 1;
 * @return {?proto.galaxymigrate.BlockDeviceInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.prototype.getBlockDevice = function() {
  return /** @type{?proto.galaxymigrate.BlockDeviceInfo} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo, 1));
};


/**
 * @param {?proto.galaxymigrate.BlockDeviceInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.prototype.setBlockDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.prototype.clearBlockDevice = function() {
  return this.setBlockDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.prototype.hasBlockDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RoleInfo role = 2;
 * @return {?proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.prototype.getRole = function() {
  return /** @type{?proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.prototype.hasRole = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool can_insert_as_source = 10;
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.prototype.getCanInsertAsSource = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.prototype.setCanInsertAsSource = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool can_enlist_as_destination = 11;
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.prototype.getCanEnlistAsDestination = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.prototype.setCanEnlistAsDestination = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    volumeGroupsList: jspb.Message.toObjectList(msg.getVolumeGroupsList(),
    proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig;
  return proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup;
      reader.readMessage(value,proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.deserializeBinaryFromReader);
      msg.addVolumeGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVolumeGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.repeatedFields_ = [10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    capacity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    freeCapacity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    uuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lvsList: jspb.Message.toObjectList(msg.getLvsList(),
    proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.toObject, includeInstance),
    pvsList: jspb.Message.toObjectList(msg.getPvsList(),
    proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup;
  return proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCapacity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFreeCapacity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 10:
      var value = new proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume;
      reader.readMessage(value,proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.deserializeBinaryFromReader);
      msg.addLvs(value);
      break;
    case 11:
      var value = new proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume;
      reader.readMessage(value,proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.deserializeBinaryFromReader);
      msg.addPvs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getFreeCapacity();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLvsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.serializeBinaryToWriter
    );
  }
  f = message.getPvsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 capacity = 2;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 free_capacity = 3;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.getFreeCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.setFreeCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string uuid = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated LogicalVolume lvs = 10;
 * @return {!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume>}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.getLvsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume, 10));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume>} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.setLvsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.addLvs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.clearLvsList = function() {
  return this.setLvsList([]);
};


/**
 * repeated PhysicalVolume pvs = 11;
 * @return {!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume>}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.getPvsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume, 11));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume>} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.setPvsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.addPvs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup.prototype.clearPvsList = function() {
  return this.setPvsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fullPath: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dmPath: jspb.Message.getFieldWithDefault(msg, 3, ""),
    uuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    capacity: jspb.Message.getFieldWithDefault(msg, 5, 0),
    fsUuid: jspb.Message.getFieldWithDefault(msg, 20, ""),
    fsType: jspb.Message.getFieldWithDefault(msg, 21, ""),
    mountPoint: jspb.Message.getFieldWithDefault(msg, 23, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume;
  return proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullPath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDmPath(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCapacity(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setFsUuid(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setFsType(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setMountPoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFullPath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDmPath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getFsUuid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getFsType();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getMountPoint();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string full_path = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.getFullPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.setFullPath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string dm_path = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.getDmPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.setDmPath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string uuid = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint64 capacity = 5;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string fs_uuid = 20;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.getFsUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.setFsUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string fs_type = 21;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.getFsType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.setFsType = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string mount_point = 23;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.getMountPoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.LogicalVolume.prototype.setMountPoint = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: jspb.Message.getFieldWithDefault(msg, 1, ""),
    capacity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    freeCapacity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    uuid: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume;
  return proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCapacity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFreeCapacity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getFreeCapacity();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string device = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.prototype.getDevice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.prototype.setDevice = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 capacity = 2;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 free_capacity = 3;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.prototype.getFreeCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.prototype.setFreeCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string uuid = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.PhysicalVolume.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated VolumeGroup volume_groups = 1;
 * @return {!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup>}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.prototype.getVolumeGroupsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup, 1));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup>} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.prototype.setVolumeGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.prototype.addVolumeGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.VolumeGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig.prototype.clearVolumeGroupsList = function() {
  return this.setVolumeGroupsList([]);
};


/**
 * repeated Device devices = 1;
 * @return {!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device>}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device, 1));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device>} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * optional LVMConfig lvm_config = 2;
 * @return {?proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.prototype.getLvmConfig = function() {
  return /** @type{?proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.LVMConfig|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.prototype.setLvmConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.prototype.clearLvmConfig = function() {
  return this.setLvmConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.prototype.hasLvmConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    capacity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    devicePath: jspb.Message.getFieldWithDefault(msg, 4, ""),
    role: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo;
  return proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCapacity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    case 10:
      var value = /** @type {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 capacity = 3;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string device_path = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional GalaxyMigrateStorageConfig.Device.RoleInfo.Role role = 10;
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.Role}
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.prototype.getRole = function() {
  return /** @type {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.Role} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device.RoleInfo.Role} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sessionVolumesList: jspb.Message.toObjectList(msg.getSessionVolumesList(),
    proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.toObject, includeInstance),
    sessionStats: (f = msg.getSessionStats()) && proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.toObject(includeInstance, f),
    errorText: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sessionStatus: jspb.Message.getFieldWithDefault(msg, 7, 0),
    lastSynchronizedTime: (f = msg.getLastSynchronizedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    bootVolumeSession: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    description: jspb.Message.getFieldWithDefault(msg, 10, ""),
    autoSyncMinutes: jspb.Message.getFieldWithDefault(msg, 11, 0),
    qosLevel: jspb.Message.getFieldWithDefault(msg, 12, 0),
    project: (f = msg.getProject()) && galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo.toObject(includeInstance, f),
    deployment: (f = msg.getDeployment()) && galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo.toObject(includeInstance, f),
    deploymentConnected: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
    destinationDeployment: (f = msg.getDestinationDeployment()) && galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo.toObject(includeInstance, f),
    postSyncWorkflowId: jspb.Message.getFieldWithDefault(msg, 35, 0),
    remoteBootMigrationPostCutoverScript: jspb.Message.getFieldWithDefault(msg, 36, ""),
    isComputeMigration: jspb.Message.getBooleanFieldWithDefault(msg, 40, false),
    computeMigrationType: jspb.Message.getFieldWithDefault(msg, 41, 0),
    computeMigrationSpec: (f = msg.getComputeMigrationSpec()) && galaxycompletepb_apipb_domainpb_compute_pb.ComputeMigrationSpec.toObject(includeInstance, f),
    qosSchedule: (f = msg.getQosSchedule()) && galaxymigratepb_galaxy_migrate_migration_pb.MigrationSession.ConfigParams.QosSchedule.toObject(includeInstance, f),
    qosStartTime: (f = msg.getQosStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo;
  return proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = new proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume;
      reader.readMessage(value,proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.deserializeBinaryFromReader);
      msg.addSessionVolumes(value);
      break;
    case 5:
      var value = new proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics;
      reader.readMessage(value,proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.deserializeBinaryFromReader);
      msg.setSessionStats(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorText(value);
      break;
    case 7:
      var value = /** @type {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status} */ (reader.readEnum());
      msg.setSessionStatus(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSynchronizedTime(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBootVolumeSession(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAutoSyncMinutes(value);
      break;
    case 12:
      var value = /** @type {!proto.galaxymigrate.SyncQos.ImpactLevel} */ (reader.readEnum());
      msg.setQosLevel(value);
      break;
    case 30:
      var value = new galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 31:
      var value = new galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo.deserializeBinaryFromReader);
      msg.setDeployment(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeploymentConnected(value);
      break;
    case 33:
      var value = new galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo.deserializeBinaryFromReader);
      msg.setDestinationDeployment(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPostSyncWorkflowId(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemoteBootMigrationPostCutoverScript(value);
      break;
    case 40:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsComputeMigration(value);
      break;
    case 41:
      var value = /** @type {!proto.galaxycomplete.domain.enums.DeploymentHostEnvironment.DeploymentHostEnvironment} */ (reader.readEnum());
      msg.setComputeMigrationType(value);
      break;
    case 42:
      var value = new galaxycompletepb_apipb_domainpb_compute_pb.ComputeMigrationSpec;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_compute_pb.ComputeMigrationSpec.deserializeBinaryFromReader);
      msg.setComputeMigrationSpec(value);
      break;
    case 43:
      var value = new galaxymigratepb_galaxy_migrate_migration_pb.MigrationSession.ConfigParams.QosSchedule;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_migration_pb.MigrationSession.ConfigParams.QosSchedule.deserializeBinaryFromReader);
      msg.setQosSchedule(value);
      break;
    case 44:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQosStartTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSessionVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.serializeBinaryToWriter
    );
  }
  f = message.getSessionStats();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.serializeBinaryToWriter
    );
  }
  f = message.getErrorText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSessionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getLastSynchronizedTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBootVolumeSession();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAutoSyncMinutes();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getQosLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo.serializeBinaryToWriter
    );
  }
  f = message.getDeployment();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo.serializeBinaryToWriter
    );
  }
  f = message.getDeploymentConnected();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getDestinationDeployment();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo.serializeBinaryToWriter
    );
  }
  f = message.getPostSyncWorkflowId();
  if (f !== 0) {
    writer.writeInt32(
      35,
      f
    );
  }
  f = message.getRemoteBootMigrationPostCutoverScript();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getIsComputeMigration();
  if (f) {
    writer.writeBool(
      40,
      f
    );
  }
  f = message.getComputeMigrationType();
  if (f !== 0.0) {
    writer.writeEnum(
      41,
      f
    );
  }
  f = message.getComputeMigrationSpec();
  if (f != null) {
    writer.writeMessage(
      42,
      f,
      galaxycompletepb_apipb_domainpb_compute_pb.ComputeMigrationSpec.serializeBinaryToWriter
    );
  }
  f = message.getQosSchedule();
  if (f != null) {
    writer.writeMessage(
      43,
      f,
      galaxymigratepb_galaxy_migrate_migration_pb.MigrationSession.ConfigParams.QosSchedule.serializeBinaryToWriter
    );
  }
  f = message.getQosStartTime();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status = {
  UNKNOWN: 0,
  IN_ERROR: 1,
  FAILED: 2,
  NEW: 3,
  TRACKING: 4,
  SYNCING: 5,
  HOLDING_IO: 6,
  SUSPENDED: 7,
  MIRRORING: 8,
  STANDING_IN: 9,
  CUTOVER: 10,
  COMPLETED: 11,
  CANCELLED: 12
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.toObject = function(includeInstance, msg) {
  var f, obj = {
    source: (f = msg.getSource()) && proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.toObject(includeInstance, f),
    destination: (f = msg.getDestination()) && proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.toObject(includeInstance, f),
    volumeStatus: jspb.Message.getFieldWithDefault(msg, 10, 0),
    volumeStats: (f = msg.getVolumeStats()) && proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.toObject(includeInstance, f),
    volumeErrorText: jspb.Message.getFieldWithDefault(msg, 12, ""),
    lastSynchronizedTime: (f = msg.getLastSynchronizedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume;
  return proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 2:
      var value = new proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    case 10:
      var value = /** @type {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status} */ (reader.readEnum());
      msg.setVolumeStatus(value);
      break;
    case 11:
      var value = new proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics;
      reader.readMessage(value,proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.deserializeBinaryFromReader);
      msg.setVolumeStats(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolumeErrorText(value);
      break;
    case 13:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSynchronizedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.serializeBinaryToWriter
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo.serializeBinaryToWriter
    );
  }
  f = message.getVolumeStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getVolumeStats();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.serializeBinaryToWriter
    );
  }
  f = message.getVolumeErrorText();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLastSynchronizedTime();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional GalaxyMigrateManagedVolumeInfo source = 1;
 * @return {?proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.getSource = function() {
  return /** @type{?proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.hasSource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GalaxyMigrateManagedVolumeInfo destination = 2;
 * @return {?proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.getDestination = function() {
  return /** @type{?proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.GalaxyMigrateManagedVolumeInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Status volume_status = 10;
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.getVolumeStatus = function() {
  return /** @type {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.setVolumeStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional Statistics volume_stats = 11;
 * @return {?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.getVolumeStats = function() {
  return /** @type{?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics, 11));
};


/**
 * @param {?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.setVolumeStats = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.clearVolumeStats = function() {
  return this.setVolumeStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.hasVolumeStats = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string volume_error_text = 12;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.getVolumeErrorText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.setVolumeErrorText = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional google.protobuf.Timestamp last_synchronized_time = 13;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.getLastSynchronizedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 13));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.setLastSynchronizedTime = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.clearLastSynchronizedTime = function() {
  return this.setLastSynchronizedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume.prototype.hasLastSynchronizedTime = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalData: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalSynced: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalChanged: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalBase: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalRemaining: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalThinData: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalTransferred: jspb.Message.getFieldWithDefault(msg, 7, 0),
    thinPercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    progressPercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    currentThroughput: jspb.Message.getFieldWithDefault(msg, 20, 0),
    currentTransferThroughput: jspb.Message.getFieldWithDefault(msg, 21, 0),
    recentThroughput: jspb.Message.getFieldWithDefault(msg, 22, 0),
    recentTransferThroughput: jspb.Message.getFieldWithDefault(msg, 23, 0),
    averageThroughput: jspb.Message.getFieldWithDefault(msg, 24, 0),
    averageTransferThroughput: jspb.Message.getFieldWithDefault(msg, 25, 0),
    currentDirty: jspb.Message.getFieldWithDefault(msg, 50, 0),
    currentChanged: jspb.Message.getFieldWithDefault(msg, 51, 0),
    totalSyncTime: (f = msg.getTotalSyncTime()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics;
  return proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalSynced(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalChanged(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalBase(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalRemaining(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalThinData(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalTransferred(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThinPercentage(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProgressPercentage(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentThroughput(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentTransferThroughput(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRecentThroughput(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRecentTransferThroughput(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAverageThroughput(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAverageTransferThroughput(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentDirty(value);
      break;
    case 51:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentChanged(value);
      break;
    case 60:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setTotalSyncTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalData();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTotalSynced();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getTotalChanged();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getTotalBase();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getTotalRemaining();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getTotalThinData();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getTotalTransferred();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getThinPercentage();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getProgressPercentage();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getCurrentThroughput();
  if (f !== 0) {
    writer.writeUint64(
      20,
      f
    );
  }
  f = message.getCurrentTransferThroughput();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
  f = message.getRecentThroughput();
  if (f !== 0) {
    writer.writeUint64(
      22,
      f
    );
  }
  f = message.getRecentTransferThroughput();
  if (f !== 0) {
    writer.writeUint64(
      23,
      f
    );
  }
  f = message.getAverageThroughput();
  if (f !== 0) {
    writer.writeUint64(
      24,
      f
    );
  }
  f = message.getAverageTransferThroughput();
  if (f !== 0) {
    writer.writeUint64(
      25,
      f
    );
  }
  f = message.getCurrentDirty();
  if (f !== 0) {
    writer.writeUint64(
      50,
      f
    );
  }
  f = message.getCurrentChanged();
  if (f !== 0) {
    writer.writeUint64(
      51,
      f
    );
  }
  f = message.getTotalSyncTime();
  if (f != null) {
    writer.writeMessage(
      60,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 total_data = 1;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getTotalData = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setTotalData = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 total_synced = 2;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getTotalSynced = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setTotalSynced = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 total_changed = 3;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getTotalChanged = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setTotalChanged = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 total_base = 4;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getTotalBase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setTotalBase = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 total_remaining = 5;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getTotalRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setTotalRemaining = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 total_thin_data = 6;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getTotalThinData = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setTotalThinData = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 total_transferred = 7;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getTotalTransferred = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setTotalTransferred = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional float thin_percentage = 10;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getThinPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setThinPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float progress_percentage = 11;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getProgressPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setProgressPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional uint64 current_throughput = 20;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getCurrentThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setCurrentThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint64 current_transfer_throughput = 21;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getCurrentTransferThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setCurrentTransferThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional uint64 recent_throughput = 22;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getRecentThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setRecentThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional uint64 recent_transfer_throughput = 23;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getRecentTransferThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setRecentTransferThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional uint64 average_throughput = 24;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getAverageThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setAverageThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional uint64 average_transfer_throughput = 25;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getAverageTransferThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setAverageTransferThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional uint64 current_dirty = 50;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getCurrentDirty = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setCurrentDirty = function(value) {
  return jspb.Message.setProto3IntField(this, 50, value);
};


/**
 * optional uint64 current_changed = 51;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getCurrentChanged = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 51, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setCurrentChanged = function(value) {
  return jspb.Message.setProto3IntField(this, 51, value);
};


/**
 * optional google.protobuf.Duration total_sync_time = 60;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.getTotalSyncTime = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 60));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.setTotalSyncTime = function(value) {
  return jspb.Message.setWrapperField(this, 60, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.clearTotalSyncTime = function() {
  return this.setTotalSyncTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.prototype.hasTotalSyncTime = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Volume session_volumes = 4;
 * @return {!Array<!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume>}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getSessionVolumesList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume, 4));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume>} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setSessionVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.addSessionVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Volume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.clearSessionVolumesList = function() {
  return this.setSessionVolumesList([]);
};


/**
 * optional Statistics session_stats = 5;
 * @return {?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getSessionStats = function() {
  return /** @type{?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics, 5));
};


/**
 * @param {?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setSessionStats = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.clearSessionStats = function() {
  return this.setSessionStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.hasSessionStats = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string error_text = 6;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getErrorText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setErrorText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Status session_status = 7;
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getSessionStatus = function() {
  return /** @type {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setSessionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp last_synchronized_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getLastSynchronizedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setLastSynchronizedTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.clearLastSynchronizedTime = function() {
  return this.setLastSynchronizedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.hasLastSynchronizedTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool boot_volume_session = 9;
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getBootVolumeSession = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setBootVolumeSession = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional uint32 auto_sync_minutes = 11;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getAutoSyncMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setAutoSyncMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional galaxymigrate.SyncQos.ImpactLevel qos_level = 12;
 * @return {!proto.galaxymigrate.SyncQos.ImpactLevel}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getQosLevel = function() {
  return /** @type {!proto.galaxymigrate.SyncQos.ImpactLevel} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.galaxymigrate.SyncQos.ImpactLevel} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setQosLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional ProjectIdentityInfo project = 30;
 * @return {?proto.galaxycomplete.domain.ProjectIdentityInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getProject = function() {
  return /** @type{?proto.galaxycomplete.domain.ProjectIdentityInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo, 30));
};


/**
 * @param {?proto.galaxycomplete.domain.ProjectIdentityInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.hasProject = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional DeploymentIdentityInfo deployment = 31;
 * @return {?proto.galaxycomplete.domain.DeploymentIdentityInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getDeployment = function() {
  return /** @type{?proto.galaxycomplete.domain.DeploymentIdentityInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo, 31));
};


/**
 * @param {?proto.galaxycomplete.domain.DeploymentIdentityInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setDeployment = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.clearDeployment = function() {
  return this.setDeployment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.hasDeployment = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional bool deployment_connected = 32;
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getDeploymentConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setDeploymentConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional DeploymentIdentityInfo destination_deployment = 33;
 * @return {?proto.galaxycomplete.domain.DeploymentIdentityInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getDestinationDeployment = function() {
  return /** @type{?proto.galaxycomplete.domain.DeploymentIdentityInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo, 33));
};


/**
 * @param {?proto.galaxycomplete.domain.DeploymentIdentityInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setDestinationDeployment = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.clearDestinationDeployment = function() {
  return this.setDestinationDeployment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.hasDestinationDeployment = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional int32 post_sync_workflow_id = 35;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getPostSyncWorkflowId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setPostSyncWorkflowId = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional string remote_boot_migration_post_cutover_script = 36;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getRemoteBootMigrationPostCutoverScript = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setRemoteBootMigrationPostCutoverScript = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional bool is_compute_migration = 40;
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getIsComputeMigration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 40, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setIsComputeMigration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 40, value);
};


/**
 * optional enums.DeploymentHostEnvironment.DeploymentHostEnvironment compute_migration_type = 41;
 * @return {!proto.galaxycomplete.domain.enums.DeploymentHostEnvironment.DeploymentHostEnvironment}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getComputeMigrationType = function() {
  return /** @type {!proto.galaxycomplete.domain.enums.DeploymentHostEnvironment.DeploymentHostEnvironment} */ (jspb.Message.getFieldWithDefault(this, 41, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.enums.DeploymentHostEnvironment.DeploymentHostEnvironment} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setComputeMigrationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 41, value);
};


/**
 * optional ComputeMigrationSpec compute_migration_spec = 42;
 * @return {?proto.galaxycomplete.domain.ComputeMigrationSpec}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getComputeMigrationSpec = function() {
  return /** @type{?proto.galaxycomplete.domain.ComputeMigrationSpec} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_compute_pb.ComputeMigrationSpec, 42));
};


/**
 * @param {?proto.galaxycomplete.domain.ComputeMigrationSpec|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setComputeMigrationSpec = function(value) {
  return jspb.Message.setWrapperField(this, 42, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.clearComputeMigrationSpec = function() {
  return this.setComputeMigrationSpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.hasComputeMigrationSpec = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional galaxymigrate.MigrationSession.ConfigParams.QosSchedule qos_schedule = 43;
 * @return {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getQosSchedule = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_migration_pb.MigrationSession.ConfigParams.QosSchedule, 43));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setQosSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 43, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.clearQosSchedule = function() {
  return this.setQosSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.hasQosSchedule = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional google.protobuf.Timestamp qos_start_time = 44;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.getQosStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 44));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.setQosStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 44, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.clearQosStartTime = function() {
  return this.setQosStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.prototype.hasQosStartTime = function() {
  return jspb.Message.getField(this, 44) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionInfo: (f = msg.getSessionInfo()) && proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.toObject(includeInstance, f),
    totalVolumes: jspb.Message.getFieldWithDefault(msg, 10, 0),
    totalCapacity: jspb.Message.getFieldWithDefault(msg, 11, 0),
    createdBy: (f = msg.getCreatedBy()) && galaxycompletepb_apipb_domainpb_user_pb.HubUser.toObject(includeInstance, f),
    cutoverChecklistId: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails;
  return proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalVolumes(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalCapacity(value);
      break;
    case 12:
      var value = new galaxycompletepb_apipb_domainpb_user_pb.HubUser;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_user_pb.HubUser.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCutoverChecklistId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalVolumes();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getTotalCapacity();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      galaxycompletepb_apipb_domainpb_user_pb.HubUser.serializeBinaryToWriter
    );
  }
  f = message.getCutoverChecklistId();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
};


/**
 * optional GalaxyMigrateMigrationSessionInfo session_info = 1;
 * @return {?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.getSessionInfo = function() {
  return /** @type{?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 total_volumes = 10;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.getTotalVolumes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.setTotalVolumes = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint64 total_capacity = 11;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.getTotalCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.setTotalCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional HubUser created_by = 12;
 * @return {?proto.galaxycomplete.domain.HubUser}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.getCreatedBy = function() {
  return /** @type{?proto.galaxycomplete.domain.HubUser} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_user_pb.HubUser, 12));
};


/**
 * @param {?proto.galaxycomplete.domain.HubUser|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional uint32 cutover_checklist_id = 13;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.getCutoverChecklistId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails.prototype.setCutoverChecklistId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    project: (f = msg.getProject()) && galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo.toObject(includeInstance, f),
    deployment: (f = msg.getDeployment()) && galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sessionStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sessionStats: (f = msg.getSessionStats()) && proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.toObject(includeInstance, f),
    totalVolumes: jspb.Message.getFieldWithDefault(msg, 10, 0),
    totalCapacity: jspb.Message.getFieldWithDefault(msg, 11, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    deploymentConnected: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    destinationDeployment: (f = msg.getDestinationDeployment()) && galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo.toObject(includeInstance, f),
    bootVolumeSession: jspb.Message.getBooleanFieldWithDefault(msg, 15, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo;
  return proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo.deserializeBinaryFromReader);
      msg.setDeployment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status} */ (reader.readEnum());
      msg.setSessionStatus(value);
      break;
    case 6:
      var value = new proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics;
      reader.readMessage(value,proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.deserializeBinaryFromReader);
      msg.setSessionStats(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalVolumes(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalCapacity(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeploymentConnected(value);
      break;
    case 14:
      var value = new galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo.deserializeBinaryFromReader);
      msg.setDestinationDeployment(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBootVolumeSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo.serializeBinaryToWriter
    );
  }
  f = message.getDeployment();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSessionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSessionStats();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics.serializeBinaryToWriter
    );
  }
  f = message.getTotalVolumes();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getTotalCapacity();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDeploymentConnected();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getDestinationDeployment();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo.serializeBinaryToWriter
    );
  }
  f = message.getBootVolumeSession();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ProjectIdentityInfo project = 2;
 * @return {?proto.galaxycomplete.domain.ProjectIdentityInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.getProject = function() {
  return /** @type{?proto.galaxycomplete.domain.ProjectIdentityInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.ProjectIdentityInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DeploymentIdentityInfo deployment = 3;
 * @return {?proto.galaxycomplete.domain.DeploymentIdentityInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.getDeployment = function() {
  return /** @type{?proto.galaxycomplete.domain.DeploymentIdentityInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo, 3));
};


/**
 * @param {?proto.galaxycomplete.domain.DeploymentIdentityInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.setDeployment = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.clearDeployment = function() {
  return this.setDeployment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.hasDeployment = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional GalaxyMigrateMigrationSessionInfo.Status session_status = 5;
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.getSessionStatus = function() {
  return /** @type {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.setSessionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional GalaxyMigrateMigrationSessionInfo.Statistics session_stats = 6;
 * @return {?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.getSessionStats = function() {
  return /** @type{?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics, 6));
};


/**
 * @param {?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Statistics|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.setSessionStats = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.clearSessionStats = function() {
  return this.setSessionStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.hasSessionStats = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 total_volumes = 10;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.getTotalVolumes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.setTotalVolumes = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint64 total_capacity = 11;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.getTotalCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.setTotalCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool deployment_connected = 13;
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.getDeploymentConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.setDeploymentConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional DeploymentIdentityInfo destination_deployment = 14;
 * @return {?proto.galaxycomplete.domain.DeploymentIdentityInfo}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.getDestinationDeployment = function() {
  return /** @type{?proto.galaxycomplete.domain.DeploymentIdentityInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo, 14));
};


/**
 * @param {?proto.galaxycomplete.domain.DeploymentIdentityInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.setDestinationDeployment = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.clearDestinationDeployment = function() {
  return this.setDestinationDeployment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.hasDestinationDeployment = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional bool boot_volume_session = 15;
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.getBootVolumeSession = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionBasicInfo.prototype.setBootVolumeSession = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    event: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    details: (f = msg.getDetails()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    volumeUuid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    eventLevel: jspb.Message.getFieldWithDefault(msg, 6, 0),
    localSequenceNumber: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent;
  return proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setDetails(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolumeUuid(value);
      break;
    case 6:
      var value = /** @type {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.EventLevel} */ (reader.readEnum());
      msg.setEventLevel(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLocalSequenceNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDetails();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEventLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getLocalSequenceNumber();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.EventLevel = {
  INFO: 0,
  ERROR: 1,
  SUCCESS: 2
};

/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string event = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.getEvent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.setEvent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Struct details = 4;
 * @return {?proto.google.protobuf.Struct}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.getDetails = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 4));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent} returns this
*/
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.setDetails = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.clearDetails = function() {
  return this.setDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.hasDetails = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string volume_uuid = 5;
 * @return {string}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.getVolumeUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.setVolumeUuid = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.clearVolumeUuid = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.hasVolumeUuid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional EventLevel event_level = 6;
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.EventLevel}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.getEventLevel = function() {
  return /** @type {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.EventLevel} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.EventLevel} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.setEventLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional uint64 local_sequence_number = 10;
 * @return {number}
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.getLocalSequenceNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent} returns this
 */
proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent.prototype.setLocalSequenceNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


goog.object.extend(exports, proto.galaxycomplete.domain);
