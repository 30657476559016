// Project: GalaxyComplete
// Created: 9/28/20 by sammy
// File: GalaxyMigrateCommon

import * as React from 'react';
import {
    GalaxyMigrateDeploymentDetails, GalaxyMigrateDeploymentInfo,
    GalaxyMigrateStorageConfig,
} from '../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb';
import {generatePath, useNavigate, useParams} from "react-router-dom";
import {DEPLOYMENT_DETAILS_SUBROUTE, getProjectSubRouteTemplate, MIGRATION_SUBROUTE, PROJECT_SUBROUTE} from "../app/AppRoutes";
import {PortNumbers} from "../../_proto/galaxymigratepb/galaxy_migrate_pb";
import {useAppServices} from "../app/services";
import {MTDIDeploymentInfo} from '../../_proto/galaxycompletepb/apipb/domainpb/mtdi_pb';
import {DeploymentHostEnvironment} from "../../_proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import {observer} from 'mobx-react-lite';
import {getDeploymentConnectionStyle, HostOSIcon} from '../deployment/DeploymentCommon';
import {Box} from '@mui/material';
import {useDownloadFileFromURL} from "../../common/misc";


export const getGmStorageConfigDeviceType = (d: GalaxyMigrateStorageConfig.Device) => {
    let deviceType = d.getBlockDevice().getDeviceType();
    if (d.getBlockDevice().getFsType()) {
        deviceType = `${deviceType} (${d.getBlockDevice().getFsType()})`;
    }
    return deviceType;
};

export const getGmStorageConfigDeviceFsInfo = (d: GalaxyMigrateStorageConfig.Device) => {
    let deviceType = d.getBlockDevice().getFsType() || '--'
    if (d.getBlockDevice().getLabel()) {
        deviceType = `${deviceType} | ${d.getBlockDevice().getLabel()}`;
    }
    if (d.getBlockDevice().getMountPoint()) {
        const mountPoint = d.getBlockDevice().getMountPoint();
        deviceType = `${deviceType} | ${mountPoint}`;
    }
    return deviceType;
};

export const useNavigateToDeploymentsList = () => {
    const {appGlobalService} = useAppServices();
    const navigate = useNavigate();
    const {projectId} = useParams();
    const p = generatePath(getProjectSubRouteTemplate(`${PROJECT_SUBROUTE.MIGRATIONS}/${MIGRATION_SUBROUTE.DEPLOYMENTS}`), {projectId})
    return () => {
        navigate(p)
        appGlobalService.setAppSubmenu(PROJECT_SUBROUTE.MIGRATIONS)
    }
};

export const useNavigateToDeploymentDetails = (deploymentId: string) => {
    const {appGlobalService} = useAppServices();
    const navigate = useNavigate();
    const {projectId} = useParams();
    const p = generatePath(getProjectSubRouteTemplate(`${PROJECT_SUBROUTE.MIGRATIONS}/${MIGRATION_SUBROUTE.DEPLOYMENTS}/${deploymentId}`), {projectId})
    return () => {
        navigate(p)
        appGlobalService.setAppSubmenu(PROJECT_SUBROUTE.MIGRATIONS);
    }
}

export const useNavigateToDeploymentLiveLog = (deploymentId: string) => {
    const {projectId} = useParams();
    const navigate = useNavigate();
    const p = `${generateDeploymentDetailsPath(deploymentId, projectId)}/${DEPLOYMENT_DETAILS_SUBROUTE.LOG}`
    return ()=> {
        navigate(p)
    }
}

export const useNavigateToDeploymentXrays = (deploymentId: string) => {
    const {projectId} = useParams();
    const navigate = useNavigate();
    const p = `${generateDeploymentDetailsPath(deploymentId, projectId)}/${DEPLOYMENT_DETAILS_SUBROUTE.XRAYS}`
    return ()=> {
        navigate(p)
    }
}

export const useDownloadXray = () => {
    const { supportService } = useAppServices();
    const downloadFile = useDownloadFileFromURL();

    return async (xrayId: string) => {
        const res = await supportService.downloadXray(xrayId)
        if (res){
            const url = res.downloadPath;
            const fileName = res.fileName;
            await downloadFile(url, fileName);
        }
    }
}

export const generateDeploymentDetailsPath = (deploymentId: string, projectId: string) => {
    return generatePath(getProjectSubRouteTemplate(`${PROJECT_SUBROUTE.MIGRATIONS}/${MIGRATION_SUBROUTE.DEPLOYMENTS}/${deploymentId}`), {projectId})
}

export const formatServerAddressWithoutDefaultPort = (address: string) => {
    return address.replace(`:${PortNumbers.REMOTE_GM_SERVER}`, '')
}

export const formatServerAddressWithoutPort = (address: string) => {
    return address.split(':')[0]
}

export const getSystemDevPathByNamePrefix = (d: GalaxyMigrateDeploymentDetails) => {
    return d?.getInfo()?.getOsType() === MTDIDeploymentInfo.OSType.WINDOWS ? '' : "/dev/"
}

export const getHostEnvDisplayName = (e: DeploymentHostEnvironment.DeploymentHostEnvironment): string => {
    if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.AWS) {
        return 'AWS'
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.AZURE) {
        return 'Azure'
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.GCP) {
        return 'GCP'
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.DO) {
        return 'DigitalOcean'
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE) {
        return 'VMware'
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.HYPERV) {
        return 'Hyper-V'
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.SOFTLAYER) {
        return 'SoftLayer'
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.OPENSTACK) {
        return 'OpenStack'
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.VULTR) {
        return 'Vultr'
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.UNKNOWN) {
        return 'Unknown'
    } else if (e === DeploymentHostEnvironment.DeploymentHostEnvironment.OTHER) {
        return 'On-Prem'
    } else {
        return null;
    }
}

export const getGalaxyMigrateHelperNodeOSName = (env: DeploymentHostEnvironment.DeploymentHostEnvironment)=>{
    return `CMC Helper for ${getHostEnvDisplayName(env)}`
}

