/**
 * @fileoverview gRPC-Web generated client stub for galaxycomplete.api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: galaxycompletepb/apipb/integration_api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as galaxycompletepb_apipb_integration_api_pb from '../../galaxycompletepb/apipb/integration_api_pb';


export class IntegrationServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetAllProjectIntegrations = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.IntegrationService/GetAllProjectIntegrations',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_integration_api_pb.GetAllProjectIntegrations.Request,
    galaxycompletepb_apipb_integration_api_pb.GetAllProjectIntegrations.Response,
    (request: galaxycompletepb_apipb_integration_api_pb.GetAllProjectIntegrations.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_integration_api_pb.GetAllProjectIntegrations.Response.deserializeBinary
  );

  getAllProjectIntegrations(
    request: galaxycompletepb_apipb_integration_api_pb.GetAllProjectIntegrations.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_integration_api_pb.GetAllProjectIntegrations.Response>;

  getAllProjectIntegrations(
    request: galaxycompletepb_apipb_integration_api_pb.GetAllProjectIntegrations.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_integration_api_pb.GetAllProjectIntegrations.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_integration_api_pb.GetAllProjectIntegrations.Response>;

  getAllProjectIntegrations(
    request: galaxycompletepb_apipb_integration_api_pb.GetAllProjectIntegrations.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_integration_api_pb.GetAllProjectIntegrations.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.IntegrationService/GetAllProjectIntegrations',
        request,
        metadata || {},
        this.methodDescriptorGetAllProjectIntegrations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.IntegrationService/GetAllProjectIntegrations',
    request,
    metadata || {},
    this.methodDescriptorGetAllProjectIntegrations);
  }

  methodDescriptorGetEnabledIntegration = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.IntegrationService/GetEnabledIntegration',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_integration_api_pb.GetEnabledIntegration.Request,
    galaxycompletepb_apipb_integration_api_pb.GetEnabledIntegration.Response,
    (request: galaxycompletepb_apipb_integration_api_pb.GetEnabledIntegration.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_integration_api_pb.GetEnabledIntegration.Response.deserializeBinary
  );

  getEnabledIntegration(
    request: galaxycompletepb_apipb_integration_api_pb.GetEnabledIntegration.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_integration_api_pb.GetEnabledIntegration.Response>;

  getEnabledIntegration(
    request: galaxycompletepb_apipb_integration_api_pb.GetEnabledIntegration.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_integration_api_pb.GetEnabledIntegration.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_integration_api_pb.GetEnabledIntegration.Response>;

  getEnabledIntegration(
    request: galaxycompletepb_apipb_integration_api_pb.GetEnabledIntegration.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_integration_api_pb.GetEnabledIntegration.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.IntegrationService/GetEnabledIntegration',
        request,
        metadata || {},
        this.methodDescriptorGetEnabledIntegration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.IntegrationService/GetEnabledIntegration',
    request,
    metadata || {},
    this.methodDescriptorGetEnabledIntegration);
  }

  methodDescriptorAddProjectIntegration = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.IntegrationService/AddProjectIntegration',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_integration_api_pb.AddProjectIntegration.Request,
    galaxycompletepb_apipb_integration_api_pb.AddProjectIntegration.Response,
    (request: galaxycompletepb_apipb_integration_api_pb.AddProjectIntegration.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_integration_api_pb.AddProjectIntegration.Response.deserializeBinary
  );

  addProjectIntegration(
    request: galaxycompletepb_apipb_integration_api_pb.AddProjectIntegration.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_integration_api_pb.AddProjectIntegration.Response>;

  addProjectIntegration(
    request: galaxycompletepb_apipb_integration_api_pb.AddProjectIntegration.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_integration_api_pb.AddProjectIntegration.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_integration_api_pb.AddProjectIntegration.Response>;

  addProjectIntegration(
    request: galaxycompletepb_apipb_integration_api_pb.AddProjectIntegration.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_integration_api_pb.AddProjectIntegration.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.IntegrationService/AddProjectIntegration',
        request,
        metadata || {},
        this.methodDescriptorAddProjectIntegration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.IntegrationService/AddProjectIntegration',
    request,
    metadata || {},
    this.methodDescriptorAddProjectIntegration);
  }

  methodDescriptorUpdateProjectIntegration = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.IntegrationService/UpdateProjectIntegration',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_integration_api_pb.UpdateProjectIntegration.Request,
    galaxycompletepb_apipb_integration_api_pb.UpdateProjectIntegration.Response,
    (request: galaxycompletepb_apipb_integration_api_pb.UpdateProjectIntegration.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_integration_api_pb.UpdateProjectIntegration.Response.deserializeBinary
  );

  updateProjectIntegration(
    request: galaxycompletepb_apipb_integration_api_pb.UpdateProjectIntegration.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_integration_api_pb.UpdateProjectIntegration.Response>;

  updateProjectIntegration(
    request: galaxycompletepb_apipb_integration_api_pb.UpdateProjectIntegration.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_integration_api_pb.UpdateProjectIntegration.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_integration_api_pb.UpdateProjectIntegration.Response>;

  updateProjectIntegration(
    request: galaxycompletepb_apipb_integration_api_pb.UpdateProjectIntegration.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_integration_api_pb.UpdateProjectIntegration.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.IntegrationService/UpdateProjectIntegration',
        request,
        metadata || {},
        this.methodDescriptorUpdateProjectIntegration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.IntegrationService/UpdateProjectIntegration',
    request,
    metadata || {},
    this.methodDescriptorUpdateProjectIntegration);
  }

  methodDescriptorRemoveProjectIntegration = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.IntegrationService/RemoveProjectIntegration',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_integration_api_pb.RemoveProjectIntegration.Request,
    galaxycompletepb_apipb_integration_api_pb.RemoveProjectIntegration.Response,
    (request: galaxycompletepb_apipb_integration_api_pb.RemoveProjectIntegration.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_integration_api_pb.RemoveProjectIntegration.Response.deserializeBinary
  );

  removeProjectIntegration(
    request: galaxycompletepb_apipb_integration_api_pb.RemoveProjectIntegration.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_integration_api_pb.RemoveProjectIntegration.Response>;

  removeProjectIntegration(
    request: galaxycompletepb_apipb_integration_api_pb.RemoveProjectIntegration.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_integration_api_pb.RemoveProjectIntegration.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_integration_api_pb.RemoveProjectIntegration.Response>;

  removeProjectIntegration(
    request: galaxycompletepb_apipb_integration_api_pb.RemoveProjectIntegration.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_integration_api_pb.RemoveProjectIntegration.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.IntegrationService/RemoveProjectIntegration',
        request,
        metadata || {},
        this.methodDescriptorRemoveProjectIntegration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.IntegrationService/RemoveProjectIntegration',
    request,
    metadata || {},
    this.methodDescriptorRemoveProjectIntegration);
  }

  methodDescriptorTestProjectIntegration = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.IntegrationService/TestProjectIntegration',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_integration_api_pb.TestProjectIntegration.Request,
    galaxycompletepb_apipb_integration_api_pb.TestProjectIntegration.Response,
    (request: galaxycompletepb_apipb_integration_api_pb.TestProjectIntegration.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_integration_api_pb.TestProjectIntegration.Response.deserializeBinary
  );

  testProjectIntegration(
    request: galaxycompletepb_apipb_integration_api_pb.TestProjectIntegration.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_integration_api_pb.TestProjectIntegration.Response>;

  testProjectIntegration(
    request: galaxycompletepb_apipb_integration_api_pb.TestProjectIntegration.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_integration_api_pb.TestProjectIntegration.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_integration_api_pb.TestProjectIntegration.Response>;

  testProjectIntegration(
    request: galaxycompletepb_apipb_integration_api_pb.TestProjectIntegration.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_integration_api_pb.TestProjectIntegration.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.IntegrationService/TestProjectIntegration',
        request,
        metadata || {},
        this.methodDescriptorTestProjectIntegration,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.IntegrationService/TestProjectIntegration',
    request,
    metadata || {},
    this.methodDescriptorTestProjectIntegration);
  }

}

