import {observer} from "mobx-react-lite";
import React from "react";
import {ScreenContainer} from "../layout/ScreenCommon";
import {useEffectOnce} from "react-use";
import {useAppServices} from "../app/services";

// ======================
// SignOutScreen
// ======================


interface SignOutScreenProps{
}

export const SignOutScreen: React.FC<SignOutScreenProps> = observer((p)=>{

    const { authService } = useAppServices();

    useEffectOnce(()=> {
        const requestLogOut = async() => {
            const confirmed = await authService.logout();
            if (!confirmed){
                window.history.back()
            }
        }
        requestLogOut()
    })

return <ScreenContainer/>
})