import React from 'react';
import './index.css';
import App from './modules/app/App';
import * as serviceWorker from './serviceWorker';
import {configureMobx} from './modules/app/services';
import {initLoggers} from './common/logger';
import '@fontsource/nunito-sans/';
import '@fontsource/inter/variable.css';
import {createRoot} from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';


// init
configureMobx();
initLoggers();

if (!!process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_PRIVATE_EDITION !== '1')
{
    Sentry.init({
                    dsn         : process.env.REACT_APP_SENTRY_DSN,
                    integrations: [new BrowserTracing()],
                    environment : process.env.REACT_APP_ENVIRONMENT,
                    release     : process.env.REACT_APP_VERSION,
                    // Set tracesSampleRate to 1.0 to capture 100%
                    // of transactions for performance monitoring.
                    // We recommend adjusting this value in production
                    tracesSampleRate: 1.0,
                });
}

const container = document.getElementById('root');
const root      = createRoot(container);
root.render(<App/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
