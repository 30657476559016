// source: galaxymigratepb/galaxy_migrate_qos.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
goog.exportSymbol('proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.SyncQos', null, global);
goog.exportSymbol('proto.galaxymigrate.SyncQos.ImpactLevel', null, global);
goog.exportSymbol('proto.galaxymigrate.SyncQos.RollingAverageLogEntry', null, global);
goog.exportSymbol('proto.galaxymigrate.SyncQos.StateSnapshot', null, global);
goog.exportSymbol('proto.galaxymigrate.SyncQos.StateSnapshot.Action', null, global);
goog.exportSymbol('proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState', null, global);
goog.exportSymbol('proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.Result', null, global);
goog.exportSymbol('proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState', null, global);
goog.exportSymbol('proto.galaxymigrate.SyncQosConfig', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.SyncQosConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.SyncQosConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.SyncQosConfig.displayName = 'proto.galaxymigrate.SyncQosConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.SyncQos = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.SyncQos, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.SyncQos.displayName = 'proto.galaxymigrate.SyncQos';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.SyncQos.StateSnapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.SyncQos.StateSnapshot.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.SyncQos.StateSnapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.SyncQos.StateSnapshot.displayName = 'proto.galaxymigrate.SyncQos.StateSnapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.displayName = 'proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.displayName = 'proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.SyncQos.StateSnapshot.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.SyncQos.StateSnapshot.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.SyncQos.StateSnapshot.Action.displayName = 'proto.galaxymigrate.SyncQos.StateSnapshot.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.SyncQos.RollingAverageLogEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.SyncQos.RollingAverageLogEntry.displayName = 'proto.galaxymigrate.SyncQos.RollingAverageLogEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest.displayName = 'proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.displayName = 'proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest.displayName = 'proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.displayName = 'proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest.displayName = 'proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse.displayName = 'proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.displayName = 'proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse.displayName = 'proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.SyncQosConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.SyncQosConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.SyncQosConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncQosConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    pioRollingAveragePeriod: (f = msg.getPioRollingAveragePeriod()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    pioSlopeCalculationPeriod: (f = msg.getPioSlopeCalculationPeriod()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    pioSlopeStabilityPeriod: (f = msg.getPioSlopeStabilityPeriod()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    pioSlopeStabilityFactor: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    changedPioRateTrigger: jspb.Message.getFieldWithDefault(msg, 5, 0),
    samplePeriod: (f = msg.getSamplePeriod()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    cioRollingAveragePeriod: (f = msg.getCioRollingAveragePeriod()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    percentUpCio: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    absoluteMaxCioRate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    absoluteMinCioRate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    minTestTimeGap: (f = msg.getMinTestTimeGap()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    maxTestTimeGap: (f = msg.getMaxTestTimeGap()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    tolerancePercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    forceCioRate: jspb.Message.getFieldWithDefault(msg, 14, 0),
    minSamplePeriod: (f = msg.getMinSamplePeriod()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    impactLevelToleranceMinimum: jspb.Message.getFloatingPointFieldWithDefault(msg, 50, 0.0),
    impactLevelToleranceModerate: jspb.Message.getFloatingPointFieldWithDefault(msg, 51, 0.0),
    impactLevelToleranceAggressive: jspb.Message.getFloatingPointFieldWithDefault(msg, 52, 0.0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 100, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.SyncQosConfig}
 */
proto.galaxymigrate.SyncQosConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.SyncQosConfig;
  return proto.galaxymigrate.SyncQosConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.SyncQosConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.SyncQosConfig}
 */
proto.galaxymigrate.SyncQosConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setPioRollingAveragePeriod(value);
      break;
    case 2:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setPioSlopeCalculationPeriod(value);
      break;
    case 3:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setPioSlopeStabilityPeriod(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPioSlopeStabilityFactor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChangedPioRateTrigger(value);
      break;
    case 6:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setSamplePeriod(value);
      break;
    case 7:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setCioRollingAveragePeriod(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPercentUpCio(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAbsoluteMaxCioRate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAbsoluteMinCioRate(value);
      break;
    case 11:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setMinTestTimeGap(value);
      break;
    case 12:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setMaxTestTimeGap(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTolerancePercent(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setForceCioRate(value);
      break;
    case 15:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setMinSamplePeriod(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setImpactLevelToleranceMinimum(value);
      break;
    case 51:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setImpactLevelToleranceModerate(value);
      break;
    case 52:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setImpactLevelToleranceAggressive(value);
      break;
    case 100:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.SyncQosConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.SyncQosConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.SyncQosConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncQosConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPioRollingAveragePeriod();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getPioSlopeCalculationPeriod();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getPioSlopeStabilityPeriod();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getPioSlopeStabilityFactor();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getChangedPioRateTrigger();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getSamplePeriod();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getCioRollingAveragePeriod();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getPercentUpCio();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getAbsoluteMaxCioRate();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getAbsoluteMinCioRate();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getMinTestTimeGap();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getMaxTestTimeGap();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getTolerancePercent();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getForceCioRate();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
  f = message.getMinSamplePeriod();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getImpactLevelToleranceMinimum();
  if (f !== 0.0) {
    writer.writeFloat(
      50,
      f
    );
  }
  f = message.getImpactLevelToleranceModerate();
  if (f !== 0.0) {
    writer.writeFloat(
      51,
      f
    );
  }
  f = message.getImpactLevelToleranceAggressive();
  if (f !== 0.0) {
    writer.writeFloat(
      52,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      100,
      f
    );
  }
};


/**
 * optional google.protobuf.Duration pio_rolling_average_period = 1;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getPioRollingAveragePeriod = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 1));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
*/
proto.galaxymigrate.SyncQosConfig.prototype.setPioRollingAveragePeriod = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.clearPioRollingAveragePeriod = function() {
  return this.setPioRollingAveragePeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQosConfig.prototype.hasPioRollingAveragePeriod = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Duration pio_slope_calculation_period = 2;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getPioSlopeCalculationPeriod = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 2));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
*/
proto.galaxymigrate.SyncQosConfig.prototype.setPioSlopeCalculationPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.clearPioSlopeCalculationPeriod = function() {
  return this.setPioSlopeCalculationPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQosConfig.prototype.hasPioSlopeCalculationPeriod = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Duration pio_slope_stability_period = 3;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getPioSlopeStabilityPeriod = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 3));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
*/
proto.galaxymigrate.SyncQosConfig.prototype.setPioSlopeStabilityPeriod = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.clearPioSlopeStabilityPeriod = function() {
  return this.setPioSlopeStabilityPeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQosConfig.prototype.hasPioSlopeStabilityPeriod = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float pio_slope_stability_factor = 4;
 * @return {number}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getPioSlopeStabilityFactor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.setPioSlopeStabilityFactor = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional uint64 changed_pio_rate_trigger = 5;
 * @return {number}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getChangedPioRateTrigger = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.setChangedPioRateTrigger = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional google.protobuf.Duration sample_period = 6;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getSamplePeriod = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 6));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
*/
proto.galaxymigrate.SyncQosConfig.prototype.setSamplePeriod = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.clearSamplePeriod = function() {
  return this.setSamplePeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQosConfig.prototype.hasSamplePeriod = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Duration cio_rolling_average_period = 7;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getCioRollingAveragePeriod = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 7));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
*/
proto.galaxymigrate.SyncQosConfig.prototype.setCioRollingAveragePeriod = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.clearCioRollingAveragePeriod = function() {
  return this.setCioRollingAveragePeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQosConfig.prototype.hasCioRollingAveragePeriod = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional float percent_up_cio = 8;
 * @return {number}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getPercentUpCio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.setPercentUpCio = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional uint64 absolute_max_cio_rate = 9;
 * @return {number}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getAbsoluteMaxCioRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.setAbsoluteMaxCioRate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint64 absolute_min_cio_rate = 10;
 * @return {number}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getAbsoluteMinCioRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.setAbsoluteMinCioRate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional google.protobuf.Duration min_test_time_gap = 11;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getMinTestTimeGap = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 11));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
*/
proto.galaxymigrate.SyncQosConfig.prototype.setMinTestTimeGap = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.clearMinTestTimeGap = function() {
  return this.setMinTestTimeGap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQosConfig.prototype.hasMinTestTimeGap = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Duration max_test_time_gap = 12;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getMaxTestTimeGap = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 12));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
*/
proto.galaxymigrate.SyncQosConfig.prototype.setMaxTestTimeGap = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.clearMaxTestTimeGap = function() {
  return this.setMaxTestTimeGap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQosConfig.prototype.hasMaxTestTimeGap = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional float tolerance_percent = 13;
 * @return {number}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getTolerancePercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.setTolerancePercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional uint64 force_cio_rate = 14;
 * @return {number}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getForceCioRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.setForceCioRate = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional google.protobuf.Duration min_sample_period = 15;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getMinSamplePeriod = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 15));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
*/
proto.galaxymigrate.SyncQosConfig.prototype.setMinSamplePeriod = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.clearMinSamplePeriod = function() {
  return this.setMinSamplePeriod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQosConfig.prototype.hasMinSamplePeriod = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional float impact_level_tolerance_minimum = 50;
 * @return {number}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getImpactLevelToleranceMinimum = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 50, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.setImpactLevelToleranceMinimum = function(value) {
  return jspb.Message.setProto3FloatField(this, 50, value);
};


/**
 * optional float impact_level_tolerance_moderate = 51;
 * @return {number}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getImpactLevelToleranceModerate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 51, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.setImpactLevelToleranceModerate = function(value) {
  return jspb.Message.setProto3FloatField(this, 51, value);
};


/**
 * optional float impact_level_tolerance_aggressive = 52;
 * @return {number}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getImpactLevelToleranceAggressive = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 52, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.setImpactLevelToleranceAggressive = function(value) {
  return jspb.Message.setProto3FloatField(this, 52, value);
};


/**
 * optional bool enabled = 100;
 * @return {boolean}
 */
proto.galaxymigrate.SyncQosConfig.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 100, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.SyncQosConfig} returns this
 */
proto.galaxymigrate.SyncQosConfig.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.SyncQos.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.SyncQos.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.SyncQos} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncQos.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.SyncQos}
 */
proto.galaxymigrate.SyncQos.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.SyncQos;
  return proto.galaxymigrate.SyncQos.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.SyncQos} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.SyncQos}
 */
proto.galaxymigrate.SyncQos.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.SyncQos.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.SyncQos.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.SyncQos} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncQos.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.galaxymigrate.SyncQos.ImpactLevel = {
  RELENTLESS: 0,
  MINIMUM: 1,
  MODERATE: 2,
  AGGRESSIVE: 3,
  NONE: 4
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.SyncQos.StateSnapshot.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.SyncQos.StateSnapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.SyncQos.StateSnapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncQos.StateSnapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentAction: (f = msg.getCurrentAction()) && proto.galaxymigrate.SyncQos.StateSnapshot.Action.toObject(includeInstance, f),
    pioRateStability: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    pioRateChangesSinceAdjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    recentPioRollingAveragesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    recentPioRollingAverageSlopesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 6)) == null ? undefined : f,
    testDecision: (f = msg.getTestDecision()) && proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.toObject(includeInstance, f),
    lastCompletedTestTime: (f = msg.getLastCompletedTestTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    currentTestCycle: (f = msg.getCurrentTestCycle()) && proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.toObject(includeInstance, f),
    previousTestCycle: (f = msg.getPreviousTestCycle()) && proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.toObject(includeInstance, f),
    throttleSettingOutstanding: jspb.Message.getFieldWithDefault(msg, 20, 0),
    throttleSettingMaxThroughput: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.SyncQos.StateSnapshot;
  return proto.galaxymigrate.SyncQos.StateSnapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.SyncQos.StateSnapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.SyncQos.StateSnapshot.Action;
      reader.readMessage(value,proto.galaxymigrate.SyncQos.StateSnapshot.Action.deserializeBinaryFromReader);
      msg.setCurrentAction(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPioRateStability(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPioRateChangesSinceAdjustment(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRecentPioRollingAverages(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRecentPioRollingAverageSlopes(values[i]);
      }
      break;
    case 9:
      var value = new proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState;
      reader.readMessage(value,proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.deserializeBinaryFromReader);
      msg.setTestDecision(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastCompletedTestTime(value);
      break;
    case 11:
      var value = new proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState;
      reader.readMessage(value,proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.deserializeBinaryFromReader);
      msg.setCurrentTestCycle(value);
      break;
    case 12:
      var value = new proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState;
      reader.readMessage(value,proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.deserializeBinaryFromReader);
      msg.setPreviousTestCycle(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setThrottleSettingOutstanding(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setThrottleSettingMaxThroughput(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.SyncQos.StateSnapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.SyncQos.StateSnapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncQos.StateSnapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentAction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.SyncQos.StateSnapshot.Action.serializeBinaryToWriter
    );
  }
  f = message.getPioRateStability();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getPioRateChangesSinceAdjustment();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getRecentPioRollingAveragesList();
  if (f.length > 0) {
    writer.writePackedUint64(
      5,
      f
    );
  }
  f = message.getRecentPioRollingAverageSlopesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      6,
      f
    );
  }
  f = message.getTestDecision();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.serializeBinaryToWriter
    );
  }
  f = message.getLastCompletedTestTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCurrentTestCycle();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.serializeBinaryToWriter
    );
  }
  f = message.getPreviousTestCycle();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.serializeBinaryToWriter
    );
  }
  f = message.getThrottleSettingOutstanding();
  if (f !== 0) {
    writer.writeUint32(
      20,
      f
    );
  }
  f = message.getThrottleSettingMaxThroughput();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.toObject = function(includeInstance, msg) {
  var f, obj = {
    cycleNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.galaxymigrate.SyncQos.StateSnapshot.Action.toObject, includeInstance),
    averageCioRateBeforeTest: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pioRateChangesSinceTest: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    throttleThroughputBefore: jspb.Message.getFieldWithDefault(msg, 10, 0),
    throttleThroughputAfter: jspb.Message.getFieldWithDefault(msg, 11, 0),
    pioDegradeTolerance: jspb.Message.getFieldWithDefault(msg, 20, 0),
    result: jspb.Message.getFieldWithDefault(msg, 100, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState;
  return proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCycleNumber(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 3:
      var value = new proto.galaxymigrate.SyncQos.StateSnapshot.Action;
      reader.readMessage(value,proto.galaxymigrate.SyncQos.StateSnapshot.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAverageCioRateBeforeTest(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPioRateChangesSinceTest(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setThrottleThroughputBefore(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setThrottleThroughputAfter(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPioDegradeTolerance(value);
      break;
    case 100:
      var value = /** @type {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.Result} */ (reader.readEnum());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCycleNumber();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.galaxymigrate.SyncQos.StateSnapshot.Action.serializeBinaryToWriter
    );
  }
  f = message.getAverageCioRateBeforeTest();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getPioRateChangesSinceTest();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getThrottleThroughputBefore();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getThrottleThroughputAfter();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = message.getPioDegradeTolerance();
  if (f !== 0) {
    writer.writeUint64(
      20,
      f
    );
  }
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(
      100,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.Result = {
  UNKNOWN: 0,
  INCREASE: 1,
  DECREASE: 2,
  COMPLETE: 3
};

/**
 * optional uint32 cycle_number = 1;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.getCycleNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.setCycleNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} returns this
*/
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.hasStart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Action actions = 3;
 * @return {!Array<!proto.galaxymigrate.SyncQos.StateSnapshot.Action>}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.galaxymigrate.SyncQos.StateSnapshot.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.SyncQos.StateSnapshot.Action, 3));
};


/**
 * @param {!Array<!proto.galaxymigrate.SyncQos.StateSnapshot.Action>} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} returns this
*/
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.galaxymigrate.SyncQos.StateSnapshot.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.Action}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.galaxymigrate.SyncQos.StateSnapshot.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional uint64 average_cio_rate_before_test = 4;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.getAverageCioRateBeforeTest = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.setAverageCioRateBeforeTest = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double pio_rate_changes_since_test = 5;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.getPioRateChangesSinceTest = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.setPioRateChangesSinceTest = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional uint64 throttle_throughput_before = 10;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.getThrottleThroughputBefore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.setThrottleThroughputBefore = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint64 throttle_throughput_after = 11;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.getThrottleThroughputAfter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.setThrottleThroughputAfter = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint64 pio_degrade_tolerance = 20;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.getPioDegradeTolerance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.setPioDegradeTolerance = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional Result result = 100;
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.Result}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.getResult = function() {
  return /** @type {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.Result} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/**
 * @param {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.Result} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState.prototype.setResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    durationSinceLastTest: (f = msg.getDurationSinceLastTest()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    pioRateStability: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    pioRateChangesSinceAdjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    decidedToTest: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    decisionReason: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState;
  return proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 2:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setDurationSinceLastTest(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPioRateStability(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPioRateChangesSinceAdjustment(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDecidedToTest(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDecisionReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDurationSinceLastTest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getPioRateStability();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getPioRateChangesSinceAdjustment();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDecidedToTest();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDecisionReason();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState} returns this
*/
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Duration duration_since_last_test = 2;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.getDurationSinceLastTest = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 2));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState} returns this
*/
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.setDurationSinceLastTest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.clearDurationSinceLastTest = function() {
  return this.setDurationSinceLastTest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.hasDurationSinceLastTest = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double pio_rate_stability = 3;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.getPioRateStability = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.setPioRateStability = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double pio_rate_changes_since_adjustment = 4;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.getPioRateChangesSinceAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.setPioRateChangesSinceAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool decided_to_test = 5;
 * @return {boolean}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.getDecidedToTest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.setDecidedToTest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string decision_reason = 6;
 * @return {string}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.getDecisionReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState.prototype.setDecisionReason = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.SyncQos.StateSnapshot.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.SyncQos.StateSnapshot.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncQos.StateSnapshot.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.Action}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.SyncQos.StateSnapshot.Action;
  return proto.galaxymigrate.SyncQos.StateSnapshot.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.SyncQos.StateSnapshot.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.Action}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.SyncQos.StateSnapshot.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.SyncQos.StateSnapshot.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncQos.StateSnapshot.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.Action.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.Action} returns this
*/
proto.galaxymigrate.SyncQos.StateSnapshot.Action.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.Action} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.Action.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.Action.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.Action.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot.Action} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.Action.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Action current_action = 1;
 * @return {?proto.galaxymigrate.SyncQos.StateSnapshot.Action}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.getCurrentAction = function() {
  return /** @type{?proto.galaxymigrate.SyncQos.StateSnapshot.Action} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.SyncQos.StateSnapshot.Action, 1));
};


/**
 * @param {?proto.galaxymigrate.SyncQos.StateSnapshot.Action|undefined} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
*/
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.setCurrentAction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.clearCurrentAction = function() {
  return this.setCurrentAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.hasCurrentAction = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double pio_rate_stability = 3;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.getPioRateStability = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.setPioRateStability = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double pio_rate_changes_since_adjustment = 4;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.getPioRateChangesSinceAdjustment = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.setPioRateChangesSinceAdjustment = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * repeated uint64 recent_pio_rolling_averages = 5;
 * @return {!Array<number>}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.getRecentPioRollingAveragesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.setRecentPioRollingAveragesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.addRecentPioRollingAverages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.clearRecentPioRollingAveragesList = function() {
  return this.setRecentPioRollingAveragesList([]);
};


/**
 * repeated double recent_pio_rolling_average_slopes = 6;
 * @return {!Array<number>}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.getRecentPioRollingAverageSlopesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.setRecentPioRollingAverageSlopesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.addRecentPioRollingAverageSlopes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.clearRecentPioRollingAverageSlopesList = function() {
  return this.setRecentPioRollingAverageSlopesList([]);
};


/**
 * optional TestDecisionState test_decision = 9;
 * @return {?proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.getTestDecision = function() {
  return /** @type{?proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState, 9));
};


/**
 * @param {?proto.galaxymigrate.SyncQos.StateSnapshot.TestDecisionState|undefined} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
*/
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.setTestDecision = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.clearTestDecision = function() {
  return this.setTestDecision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.hasTestDecision = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp last_completed_test_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.getLastCompletedTestTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
*/
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.setLastCompletedTestTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.clearLastCompletedTestTime = function() {
  return this.setLastCompletedTestTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.hasLastCompletedTestTime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional TestCycleState current_test_cycle = 11;
 * @return {?proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.getCurrentTestCycle = function() {
  return /** @type{?proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState, 11));
};


/**
 * @param {?proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState|undefined} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
*/
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.setCurrentTestCycle = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.clearCurrentTestCycle = function() {
  return this.setCurrentTestCycle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.hasCurrentTestCycle = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional TestCycleState previous_test_cycle = 12;
 * @return {?proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.getPreviousTestCycle = function() {
  return /** @type{?proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState, 12));
};


/**
 * @param {?proto.galaxymigrate.SyncQos.StateSnapshot.TestCycleState|undefined} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
*/
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.setPreviousTestCycle = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.clearPreviousTestCycle = function() {
  return this.setPreviousTestCycle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.hasPreviousTestCycle = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional uint32 throttle_setting_outstanding = 20;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.getThrottleSettingOutstanding = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.setThrottleSettingOutstanding = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint64 throttle_setting_max_throughput = 21;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.getThrottleSettingMaxThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.StateSnapshot} returns this
 */
proto.galaxymigrate.SyncQos.StateSnapshot.prototype.setThrottleSettingMaxThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.SyncQos.RollingAverageLogEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    cioCurrent: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cioAverage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pioCurrent: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pioAverage: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pioSlope: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    pioStability: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    pioRateChanges: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    currentCioThrottleSetting: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry}
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.SyncQos.RollingAverageLogEntry;
  return proto.galaxymigrate.SyncQos.RollingAverageLogEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry}
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCioCurrent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCioAverage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPioCurrent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPioAverage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPioSlope(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPioStability(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPioRateChanges(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentCioThrottleSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.SyncQos.RollingAverageLogEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCioCurrent();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getCioAverage();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getPioCurrent();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getPioAverage();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getPioSlope();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getPioStability();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getPioRateChanges();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getCurrentCioThrottleSetting();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry} returns this
*/
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry} returns this
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 cio_current = 2;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.getCioCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry} returns this
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.setCioCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 cio_average = 3;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.getCioAverage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry} returns this
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.setCioAverage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 pio_current = 4;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.getPioCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry} returns this
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.setPioCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 pio_average = 5;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.getPioAverage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry} returns this
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.setPioAverage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double pio_slope = 6;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.getPioSlope = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry} returns this
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.setPioSlope = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double pio_stability = 7;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.getPioStability = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry} returns this
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.setPioStability = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double pio_rate_changes = 8;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.getPioRateChanges = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry} returns this
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.setPioRateChanges = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional uint64 current_cio_throttle_setting = 9;
 * @return {number}
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.getCurrentCioThrottleSetting = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry} returns this
 */
proto.galaxymigrate.SyncQos.RollingAverageLogEntry.prototype.setCurrentCioThrottleSetting = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    maxRecentPoints: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest;
  return proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceUuid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxRecentPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMaxRecentPoints();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string source_uuid = 1;
 * @return {string}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest.prototype.getSourceUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest} returns this
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest.prototype.setSourceUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 max_recent_points = 2;
 * @return {number}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest.prototype.getMaxRecentPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest} returns this
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogRequest.prototype.setMaxRecentPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.galaxymigrate.SyncQos.RollingAverageLogEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse;
  return proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.SyncQos.RollingAverageLogEntry;
      reader.readMessage(value,proto.galaxymigrate.SyncQos.RollingAverageLogEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxymigrate.SyncQos.RollingAverageLogEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SyncQos.RollingAverageLogEntry entries = 1;
 * @return {!Array<!proto.galaxymigrate.SyncQos.RollingAverageLogEntry>}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.SyncQos.RollingAverageLogEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.SyncQos.RollingAverageLogEntry, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.SyncQos.RollingAverageLogEntry>} value
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse} returns this
*/
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.SyncQos.RollingAverageLogEntry}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.SyncQos.RollingAverageLogEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse} returns this
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosRollingAverageLogResponse.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceUuid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest;
  return proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string source_uuid = 1;
 * @return {string}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest.prototype.getSourceUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest} returns this
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateRequest.prototype.setSourceUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    qosStateSnapshot: (f = msg.getQosStateSnapshot()) && proto.galaxymigrate.SyncQos.StateSnapshot.toObject(includeInstance, f),
    currentConfig: (f = msg.getCurrentConfig()) && proto.galaxymigrate.SyncQosConfig.toObject(includeInstance, f),
    pioCurrent: jspb.Message.getFieldWithDefault(msg, 10, 0),
    pioRollingAverage: jspb.Message.getFieldWithDefault(msg, 11, 0),
    cioCurrent: jspb.Message.getFieldWithDefault(msg, 12, 0),
    cioRollingAverage: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse;
  return proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.SyncQos.StateSnapshot;
      reader.readMessage(value,proto.galaxymigrate.SyncQos.StateSnapshot.deserializeBinaryFromReader);
      msg.setQosStateSnapshot(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.SyncQosConfig;
      reader.readMessage(value,proto.galaxymigrate.SyncQosConfig.deserializeBinaryFromReader);
      msg.setCurrentConfig(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPioCurrent(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPioRollingAverage(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCioCurrent(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCioRollingAverage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQosStateSnapshot();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.SyncQos.StateSnapshot.serializeBinaryToWriter
    );
  }
  f = message.getCurrentConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.SyncQosConfig.serializeBinaryToWriter
    );
  }
  f = message.getPioCurrent();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getPioRollingAverage();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = message.getCioCurrent();
  if (f !== 0) {
    writer.writeUint64(
      12,
      f
    );
  }
  f = message.getCioRollingAverage();
  if (f !== 0) {
    writer.writeUint64(
      13,
      f
    );
  }
};


/**
 * optional SyncQos.StateSnapshot qos_state_snapshot = 1;
 * @return {?proto.galaxymigrate.SyncQos.StateSnapshot}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.getQosStateSnapshot = function() {
  return /** @type{?proto.galaxymigrate.SyncQos.StateSnapshot} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.SyncQos.StateSnapshot, 1));
};


/**
 * @param {?proto.galaxymigrate.SyncQos.StateSnapshot|undefined} value
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse} returns this
*/
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.setQosStateSnapshot = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse} returns this
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.clearQosStateSnapshot = function() {
  return this.setQosStateSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.hasQosStateSnapshot = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SyncQosConfig current_config = 2;
 * @return {?proto.galaxymigrate.SyncQosConfig}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.getCurrentConfig = function() {
  return /** @type{?proto.galaxymigrate.SyncQosConfig} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.SyncQosConfig, 2));
};


/**
 * @param {?proto.galaxymigrate.SyncQosConfig|undefined} value
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse} returns this
*/
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.setCurrentConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse} returns this
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.clearCurrentConfig = function() {
  return this.setCurrentConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.hasCurrentConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 pio_current = 10;
 * @return {number}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.getPioCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse} returns this
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.setPioCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint64 pio_rolling_average = 11;
 * @return {number}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.getPioRollingAverage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse} returns this
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.setPioRollingAverage = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint64 cio_current = 12;
 * @return {number}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.getCioCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse} returns this
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.setCioCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint64 cio_rolling_average = 13;
 * @return {number}
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.getCioRollingAverage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse} returns this
 */
proto.galaxymigrate.GetMigrationSessionVolumeQosStateResponse.prototype.setCioRollingAverage = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    config: (f = msg.getConfig()) && proto.galaxymigrate.SyncQosConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest}
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest;
  return proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest}
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.SyncQosConfig;
      reader.readMessage(value,proto.galaxymigrate.SyncQosConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.SyncQosConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional SyncQosConfig config = 1;
 * @return {?proto.galaxymigrate.SyncQosConfig}
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest.prototype.getConfig = function() {
  return /** @type{?proto.galaxymigrate.SyncQosConfig} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.SyncQosConfig, 1));
};


/**
 * @param {?proto.galaxymigrate.SyncQosConfig|undefined} value
 * @return {!proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest} returns this
*/
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest} returns this
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosRequest.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse}
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse;
  return proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse}
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ConfigureDefaultMigrationSessionQosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    config: (f = msg.getConfig()) && proto.galaxymigrate.SyncQosConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest}
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest;
  return proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest}
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceUuid(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.SyncQosConfig;
      reader.readMessage(value,proto.galaxymigrate.SyncQosConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.SyncQosConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string source_uuid = 1;
 * @return {string}
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.prototype.getSourceUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest} returns this
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.prototype.setSourceUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SyncQosConfig config = 2;
 * @return {?proto.galaxymigrate.SyncQosConfig}
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.prototype.getConfig = function() {
  return /** @type{?proto.galaxymigrate.SyncQosConfig} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.SyncQosConfig, 2));
};


/**
 * @param {?proto.galaxymigrate.SyncQosConfig|undefined} value
 * @return {!proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest} returns this
*/
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest} returns this
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosRequest.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse}
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse;
  return proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse}
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ConfigureMigrationSessionVolumeQosResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.galaxymigrate);
