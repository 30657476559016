
/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: util
 */

export const isDevelopment = ()=> process.env.NODE_ENV === 'development';

// ==========================
// Functions
// ==========================
export const emptyFn = () => {
    return;
};

export function identityFn<T>(arg: T): T {
    return arg;
}

// ==========================
// sleep
// ==========================
export const sleepMS = (duration = 0) => {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, duration);
    });
};

export const sleep = (duration = 0) => sleepMS(duration * 1000);

export function groupBy<T>(listOfObjects: T[], f: (obj: T) => string | number) {
    return listOfObjects.reduce((r, v, i, a, k = f(v)) => {
        (r[k] || (r[k] = [])).push(v);
        return r;
    }, {} as { [k: string]: T[] });
}

export function randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}