/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AppView
 */
import * as React from "react";
import { observer } from "mobx-react-lite";
import { APP_ROUTES, AUTH_ROUTES, CALLBACK_SUBROUTE, LICENSE_SUBROUTE } from "./AppRoutes";
import { Navigate, Route, Routes, useMatch } from "react-router-dom";
import { useAppServices } from "./services";
import { MainFrameLayout, useIsDesktop } from "../layout/MainLayout";
import { LoginView } from "../auth/LoginScreen";
import { useEffectOnce } from "react-use";
import { ProjectListScreen } from "../project/ProjectListScreen";
import { ProjectDetailsScreen } from "../project/ProjectDetailsScreen";
import { OnboardScreen } from "../auth/OnboardScreen";
import { UserSettings } from "../user/UserSettings";
import { SetNewPasswordScreen } from "../auth/SetNewPasswordScreen";
import { SignOutScreen } from "../auth/SignOutScreen";
import { UserSignUpScreen } from "../auth/UserSignUpScreen";
import { UserLicenseScreen } from "../license/UserLicenseScreen";
import { PurchaseLicenseScreen } from "../license/PurchaseLicenseScreen";
import { CallbackScreen } from "../callback/CallbackScreen";
import { getCallbackConfig } from "../callback/CallbackConfigs";
import { AwsMpConnectAccountScreen } from "../auth/AwsMarketplaceConnectAccountScreen";
import { useState } from "react";
import { getIsPrivateEdition, HideInPrivateEdition } from "../auth/PrivateEditionView";
import { SupportProjectListScreen } from "../support/SupportProjectListScreen";
import { FullScreenLoading } from "../core/data/DataLoaders";
import { RefreshTokenOnAuthError } from "../auth/RefreshTokenOnAuthError";
import { ViewReportScreen } from "../reports/ViewReportScreen";
import { LoginCustomerCenter } from "../help/CustomerCenter";
import { useIsAuthenticated, useIsOnboardCompleted } from "../auth/auth_hooks";
import { AzureMpConnectAccountScreen } from "../auth/AzureMarketplaceConnectAccountScreen";
import { useAuthState } from "../auth/AuthState";

interface AppViewProps {}

export const AppView: React.FC<AppViewProps> = observer(({ ...p }) => {
    const awsSignUp = useMatch(`${AUTH_ROUTES.CONNECT_AWS}/:token`);

    const authenticated = useIsAuthenticated();
    const onboardCompleted = useIsOnboardCompleted();
    const azureSignUp = useMatch(`${AUTH_ROUTES.CONNECT_AZURE}`);
    if (awsSignUp) {
        return <AwsSignUpView />;
        // } else if (!hasActiveToken() && !!currentUser) {
        //     return <RefreshTokenOnAuthError />;
    } else if (azureSignUp) {
        return <AzureSignUpView />;
    } else if (!authenticated) {
        return <UnauthenticatedView />;
    } else if (!onboardCompleted) {
        return <OnboardScreen />;
    } else {
        return <AuthenticatedView />;
    }
});

interface AuthenticatedViewProps {}

const AuthenticatedView: React.FC<AuthenticatedViewProps> = observer(({ ...p }) => {
    const isDesktop = useIsDesktop();
    const isPrivateEdition = getIsPrivateEdition();

    return (
        <MainFrameLayout isDesktop={isDesktop}>
            <Routes>
                <Route path={"/"} element={<Navigate to={APP_ROUTES.PROJECTS} replace />} />
                <Route path={APP_ROUTES.PROJECTS}>
                    <Route index element={<ProjectListScreen />} />
                    <Route path={":projectId*"} element={<ProjectDetailsScreen />} />
                </Route>
                <Route path={APP_ROUTES.SUPPORT_PROJECTS}>
                    <Route index element={<SupportProjectListScreen />} />
                    <Route path={":projectId*"} element={<ProjectDetailsScreen />} />
                </Route>
                <Route path={APP_ROUTES.USER} element={<UserSettings />} />
                {!isPrivateEdition && (
                    <Route path={APP_ROUTES.USER_LICENSE}>
                        <Route index element={<UserLicenseScreen />} />
                        <Route path={LICENSE_SUBROUTE.PURCHASE} element={<PurchaseLicenseScreen />} />
                    </Route>
                )}
                <Route path={APP_ROUTES.CALLBACKS}>
                    <Route
                        path={`${CALLBACK_SUBROUTE.LICENSE_PURCHASE_SUCCESS}/*`}
                        element={<CallbackScreen config={getCallbackConfig(CALLBACK_SUBROUTE.LICENSE_PURCHASE_SUCCESS)} />}
                    />
                    <Route
                        path={`${CALLBACK_SUBROUTE.LICENSE_PURCHASE_CANCELLED}/*`}
                        element={<CallbackScreen config={getCallbackConfig(CALLBACK_SUBROUTE.LICENSE_PURCHASE_CANCELLED)} />}
                    />
                </Route>
                <Route path={`${AUTH_ROUTES.SET_PASSWORD}/*`} element={<SignOutScreen />} />
                <Route path={APP_ROUTES.REPORTS}>
                    <Route path={":reportId*"} element={<ViewReportScreen />} />
                </Route>
                <Route path={APP_ROUTES.CUSTOMER_CENTER} element={<LoginCustomerCenter />} />
            </Routes>
        </MainFrameLayout>
    );
});

// ======================
// UnauthenticatedView
// ======================

interface UnauthenticatedViewProps {}

export const UnauthenticatedView: React.FC<UnauthenticatedViewProps> = observer((p) => {
    return (
        <Routes>
            <Route path={"*"} element={<LoginView />} />
            <Route path={`${AUTH_ROUTES.SET_PASSWORD}/*`} element={<SetNewPasswordScreen />} />
            <Route path={AUTH_ROUTES.SIGN_UP} element={<UserSignUpScreen />} />
        </Routes>
    );
});

// ======================
// AwsSignUpView
// ======================

interface AwsSignUpViewProps {}

export const AwsSignUpView: React.FC<AwsSignUpViewProps> = observer((p) => {
    return (
        <Routes>
            <Route path={AUTH_ROUTES.CONNECT_AWS}>
                <Route path={":awsToken"} element={<AwsMpConnectAccountScreen />} />
            </Route>
        </Routes>
    );
});
interface AzureSignUpViewProps {}

export const AzureSignUpView: React.FC<AzureSignUpViewProps> = observer((p) => {
    return <AzureMpConnectAccountScreen />;
});
