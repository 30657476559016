// ======================
// AzureMpConnectAccountScreen
// ======================

import { observer } from "mobx-react-lite";
import { CirrusGradient } from "../app/AppTheme";
import { Alert, alpha, Button, Card, CardContent, CardHeader, Grid, Link, List, ListItem, ListItemText, SvgIcon, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Logo from "../../assets/Cirrus_Data_Cloud_Logo_Large.png";
import { UnauthenticatedViewFooter } from "../layout/Footer";
import React, { useEffect, useMemo, useState } from "react";
import { SelectableCard } from "../../common/card/SelectableCard";
import { RiUserAddLine } from "react-icons/ri";
import { CgLink } from "react-icons/cg";
import { UserSignUpScreen } from "./UserSignUpScreen";
import { LoginView } from "./LoginScreen";
import { useEffectOnce } from "react-use";
import { useAppServices } from "../app/services";
import { useNavigate, useParams } from "react-router-dom";
import { useIsDesktop } from "../layout/MainLayout";
import { useQueryParams } from "../app/AppRoutes";
import { useQuery } from "@tanstack/react-query";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import { ResolveAzureMarketplaceSubscription } from "../../_proto/galaxycompletepb/apipb/auth_api_pb";
import Azure from "../../assets/integration_logos/azure.png";
import { KeyValuePair } from "../../common/text/CommonTypeFormats";

interface AzureMpConnectAccountScreenProps {}

export const AzureMpConnectAccountScreen: React.FC<AzureMpConnectAccountScreenProps> = observer((p) => {
    const [signUp, setSignUp] = useState(false);
    const [logIn, setLogIn] = useState(false);

    const query = useQueryParams();
    const azureToken = query.get("token");
    const { authService } = useAppServices();

    const resolveSubscriptionTokenResult = useQuery({
        queryKey: ["resolveAzureMarketplaceSubscription"],
        queryFn: async () => await authService.resolveAzureMarketplaceSubscription(azureToken),
        // make it call once only regardless
        enabled: false,
        staleTime: Infinity,
        retry: false,
    });

    useEffectOnce(() => {
        resolveSubscriptionTokenResult.refetch();
    });

    useEffect(() => {
        if (authService.authenticated && !authService.azureAuthenticated) {
            authService.logout(true);
        }
    }, [authService.authenticated, authService.azureAuthenticated, authService]);

    const onSelectSignUp = () => {
        setLogIn(false);
        setSignUp(true);
    };

    const onSelectLogIn = () => {
        setSignUp(false);
        setLogIn(true);
    };

    const navigateBackToSelectionScreen = () => {
        setLogIn(false);
        setSignUp(false);
    };

    const signupEmail = useMemo(() => {
        return resolveSubscriptionTokenResult.data?.existingUser ? "" : resolveSubscriptionTokenResult.data?.email;
    }, [resolveSubscriptionTokenResult.data]);

    return (
        <QueryResultWrapper queryResult={resolveSubscriptionTokenResult}>
            {signUp ? (
                <UserSignUpScreen
                    loginRedirectFunction={onSelectLogIn}
                    azureToken={azureToken}
                    navigateBackFunction={navigateBackToSelectionScreen}
                    prefilledEmail={signupEmail}
                />
            ) : logIn ? (
                <LoginView
                    signUpRedirectFunction={onSelectSignUp}
                    azureToken={azureToken}
                    navigateBackFunction={navigateBackToSelectionScreen}
                    prefilledEmail={resolveSubscriptionTokenResult.data?.email}
                />
            ) : (
                <ConfirmationScreen subscriptionData={resolveSubscriptionTokenResult.data} onSelectLogIn={onSelectLogIn} onSelectSignUp={onSelectSignUp} />
            )}
        </QueryResultWrapper>
    );
});

// ======================
// SelectionScreen
// ======================

interface SelectionScreenProps {
    onSelectSignUp: () => void;
    onSelectLogIn: () => void;
    subscriptionData: ResolveAzureMarketplaceSubscription.Response.AsObject;
}

export const ConfirmationScreen: React.FC<SelectionScreenProps> = observer((p) => {
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    return (
        <Box
            sx={{
                background: CirrusGradient,
                height: isDesktop ? "100vh" : "",
                minHeight: isDesktop ? "" : "100vh",
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}
        >
            <Box height={"100%"} flexGrow={1}>
                <Box height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                    <Grid container justifyContent={"center"} alignItems={"center"} direction={"row"}>
                        <Grid item xs={8} sm={6} lg={4} xl={3}>
                            <Box sx={{}}>
                                <Box p={3} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Box sx={{ maxWidth: "330px" }}>
                                        <img src={Logo} style={{ width: "100%" }} alt={"logo"} />
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography variant={"h5"} textAlign={"center"}>
                                        Thank you for subscribing to Cirrus Data Cloud via Microsoft Azure Marketplace.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container justifyContent={"center"} alignItems={"center"} spacing={3} padding={4}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ height: "100%" }}>
                                <Box p={4}>
                                    <Box display={"flex"} justifyContent={"center"} width={"100%"} pt={2} pb={2}>
                                        <Box width={100}>
                                            <img src={Azure} style={{ width: "100%" }} alt={"Microsoft Azure"} />
                                        </Box>
                                    </Box>
                                    <Typography paragraph variant={"h5"} textAlign={"center"}>
                                        Subscription Activation
                                    </Typography>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        By continuing, your Azure Marketplace SaaS subscription below will be activated and connected to your Cirrus Data Cloud
                                        account.
                                    </Typography>
                                    <br />
                                    <Card sx={{ backgroundColor: theme.palette.cirrus.main }}>
                                        <CardContent>
                                            <KeyValuePair label={"Email"} value={p.subscriptionData.email} />
                                            <KeyValuePair label={"Tenant"} value={p.subscriptionData.tenantId} />
                                            <KeyValuePair label={"Subscription ID"} value={p.subscriptionData.subscriptionId} />
                                            <KeyValuePair
                                                label={"Subscribed Offer / Plan"}
                                                value={`${p.subscriptionData.offerId} / ${p.subscriptionData.planId}`}
                                            />
                                        </CardContent>
                                    </Card>
                                    <br />
                                    {!p.subscriptionData.existingUser && (
                                        <Card sx={{ backgroundColor: theme.palette.cirrus.dark, border: "2px solid", borderColor: alpha("#fff", 0.2) }}>
                                            <CardContent>
                                                <Box width={"100%"} display={"flex"} alignItems={"center"}>
                                                    <Box>
                                                        <RiUserAddLine size={"4em"} />
                                                    </Box>
                                                    <Box pl={2}>
                                                        <Typography variant={"h6"}>{`New User`}</Typography>
                                                        <Typography variant={"body2"} color={"textSecondary"}>
                                                            {`Create a new Cirrus Data Cloud account to get started. Usage from this account will be billed via Microsoft Azure Marketplace.`}
                                                        </Typography>
                                                        <br />
                                                    </Box>
                                                </Box>
                                                <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                                                    <Button variant={"contained"} onClick={() => p.onSelectSignUp()}>
                                                        {`Create New Account`}
                                                    </Button>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    )}
                                    {p.subscriptionData.existingUser && (
                                        <Card sx={{ backgroundColor: theme.palette.cirrus.dark, border: "2px solid", borderColor: alpha("#fff", 0.2) }}>
                                            <CardContent>
                                                <Box width={"100%"} display={"flex"} alignItems={"center"}>
                                                    <Box>
                                                        <CgLink size={"4em"} />
                                                    </Box>
                                                    <Box pl={2}>
                                                        <Typography variant={"h6"}>
                                                            {`Welcome back! You already have an existing Cirrus Data Cloud account.`}
                                                        </Typography>
                                                        <Typography variant={"body2"} color={"textSecondary"}>
                                                            {`Connect your current Cirrus Data Cloud account to this Microsoft Azure Marketplace Subscription.`}
                                                        </Typography>
                                                        <br />
                                                    </Box>
                                                </Box>
                                                <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                                                    <Box textAlign={"center"}>
                                                        <Box pb={2}>
                                                            <Button
                                                                variant={"contained"}
                                                                onClick={() => p.onSelectLogIn()}
                                                                disabled={p.subscriptionData.existingActiveSubscription}
                                                            >
                                                                {`Connect Account`}
                                                            </Button>
                                                        </Box>
                                                        {p.subscriptionData.existingActiveSubscription && (
                                                            <Alert severity={"warning"} sx={{ mb: 1 }}>
                                                                <Typography variant={"body2"}>
                                                                    {`Your user account ${p.subscriptionData.email} is already connected to another active Azure Marketplace Subscription`}
                                                                </Typography>
                                                            </Alert>
                                                        )}
                                                        <Link onClick={() => p.onSelectSignUp()} variant={"body2"}>
                                                            {`Sign up for a new account instead`}
                                                        </Link>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    )}
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <UnauthenticatedViewFooter />
        </Box>
    );
});
