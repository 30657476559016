import { isDevelopment } from "../../common/utils/util";
import { AuthenticationServiceClient, LoginServiceClient } from "../../_proto/galaxycompletepb/apipb/Auth_apiServiceClientPb";
import { UnaryInterceptor, UnaryResponse, Request, RpcError, StatusCode } from "grpc-web";
import { AuthService } from "../auth/AuthService";
import { AuthenticatedUserInfo, Login, RefreshToken } from "../../_proto/galaxycompletepb/apipb/auth_api_pb";
import { makeAutoObservable, makeObservable, observable } from "mobx";
import { ProjectServiceClient } from "../../_proto/galaxycompletepb/apipb/Project_apiServiceClientPb";
import { DeploymentServiceClient } from "../../_proto/galaxycompletepb/apipb/Deployment_apiServiceClientPb";
import { GalaxyMigrateServiceClient } from "../../_proto/galaxycompletepb/apipb/gmapipb/Galaxymigrate_apiServiceClientPb";
import { HelpServiceClient } from "../../_proto/galaxycompletepb/apipb/Help_apiServiceClientPb";
import { IntegrationServiceClient } from "../../_proto/galaxycompletepb/apipb/Integration_apiServiceClientPb";
import { GeneralServiceClient } from "../../_proto/galaxycompletepb/apipb/ApiServiceClientPb";
import { getApiHost, getApiEndpoint, GRPC_API_HOST } from "./grpcCommon";
import { WorkflowServiceClient } from "../../_proto/galaxycompletepb/apipb/Workflow_apiServiceClientPb";
import { PhoenixServiceClient } from "../../_proto/galaxycompletepb/apipb/phoenixapipb/Phoenix_apiServiceClientPb";
import { CirrusProtectServiceClient } from "../../_proto/galaxycompletepb/apipb/cpapipb/Cirrusprotect_apiServiceClientPb";
import { ChecklistServiceClient } from "../../_proto/galaxycompletepb/apipb/Checklist_apiServiceClientPb";
import { LicenseBillingServiceClient } from "../../_proto/galaxycompletepb/apipb/License_apiServiceClientPb";
import { SupportServiceClient } from "../../_proto/galaxycompletepb/apipb/Support_apiServiceClientPb";
import { ReportServiceClient } from "../../_proto/galaxycompletepb/apipb/Report_apiServiceClientPb";
import { ProjectAdminServiceClient } from "../../_proto/galaxycompletepb/apipb/adminapi/Admin_projectServiceClientPb";
import { PrivateEditionLocalServiceClient } from "../../_proto/galaxycompletepb/apipb/Pelocal_apiServiceClientPb";

// https://grpc.io/blog/grpc-web-interceptor/#unary-interceptor-example

export const GRPC_API_STAGE_HOST = `webapi.stage1.nonprod.cloud.cirrusdata.com`;
const GRPC_API_PROD_HOST = "webapi.cloud.cirrusdata.com";

export class GRPCServices {
    private addr: string = `${getApiEndpoint()}`;

    private activeToken: string = null;

    generalService: GeneralServiceClient;
    loginService: LoginServiceClient;
    authService: AuthenticationServiceClient;
    projectService: ProjectServiceClient;
    deploymentService: DeploymentServiceClient;
    helpService: HelpServiceClient;
    // galaxy migrate
    gmService: GalaxyMigrateServiceClient;
    integrationService: IntegrationServiceClient;
    workflowService: WorkflowServiceClient;
    phoenixService: PhoenixServiceClient;
    cpService: CirrusProtectServiceClient;
    checklistService: ChecklistServiceClient;
    licenseBillingService: LicenseBillingServiceClient;
    supportService: SupportServiceClient;
    privateEditionLocalService: PrivateEditionLocalServiceClient;
    reportService: ReportServiceClient;

    constructor() {
        // interceptor in reverse order
        const baseOptions = {
            //withCredentials: true,
            unaryInterceptors: [isDevelopment() ? new reqResPrinterInterceptor() : null, this.makeAuthInterceptor()].filter((a) => !!a),
        };

        const addr = this.addr;
        this.generalService = new GeneralServiceClient(addr, null, { ...baseOptions });
        this.loginService = new LoginServiceClient(addr, null, { ...baseOptions });
        this.authService = new AuthenticationServiceClient(addr, null, { ...baseOptions });
        this.projectService = new ProjectServiceClient(addr, null, { ...baseOptions });
        this.deploymentService = new DeploymentServiceClient(addr, null, { ...baseOptions });
        this.gmService = new GalaxyMigrateServiceClient(addr, null, { ...baseOptions });
        this.helpService = new HelpServiceClient(addr, null, { ...baseOptions });
        this.integrationService = new IntegrationServiceClient(addr, null, { ...baseOptions });
        this.workflowService = new WorkflowServiceClient(addr, null, { ...baseOptions });
        this.phoenixService = new PhoenixServiceClient(addr, null, { ...baseOptions });
        this.cpService = new CirrusProtectServiceClient(addr, null, { ...baseOptions });
        this.checklistService = new ChecklistServiceClient(addr, null, { ...baseOptions });
        this.licenseBillingService = new LicenseBillingServiceClient(addr, null, { ...baseOptions });
        this.supportService = new SupportServiceClient(addr, null, { ...baseOptions });
        this.privateEditionLocalService = new PrivateEditionLocalServiceClient(addr, null, { ...baseOptions });
        this.reportService = new ReportServiceClient(addr, null, { ...baseOptions });
        makeAutoObservable(this);
    }

    private makeAuthInterceptor(): UnaryInterceptor<any, any> {
        const tokenGetter = async () => this.activeToken;
        const onAuthError = (e: Error) => {
            console.debug("authentication error detected. clearing token");
            this.setActiveToken(null);
        };

        return new authInterceptor(tokenGetter, onAuthError);
    }

    getAddress() {
        return this.addr;
    }

    hasToken() {
        return !!this.activeToken;
    }

    setActiveToken(value: string) {
        this.activeToken = value;
    }

    getActiveToken() {
        return this.activeToken;
    }
}

export class authInterceptor<REQ = any, RESP = any> implements UnaryInterceptor<REQ, RESP> {
    private readonly tokenGetter: () => Promise<string>;
    private onAuthenticationError: (e: Error) => void;

    constructor(tokenGetter: () => Promise<string>, onAuthenticationError: (e: Error) => void) {
        this.tokenGetter = tokenGetter;
        this.onAuthenticationError = onAuthenticationError;
    }

    async intercept(
        request: Request<REQ, RESP>,
        invoker: (request: Request<REQ, RESP>) => Promise<UnaryResponse<REQ, RESP>>
    ): Promise<UnaryResponse<REQ, RESP>> {
        const token = await this.tokenGetter();
        const metadata = request.getMetadata();

        if (!!token) {
            metadata["authorization"] = "Bearer " + token;
        }
        try {
            return await invoker(request);
        } catch (e) {
            if ((e as RpcError).code === StatusCode.UNAUTHENTICATED) {
                this.onAuthenticationError(e as RpcError);
            }
            throw e;
        }
    }
}

export class reqResPrinterInterceptor<REQ = any, RESP = any> implements UnaryInterceptor<REQ, RESP> {
    async intercept(
        request: Request<REQ, RESP>,
        invoker: (request: Request<REQ, RESP>) => Promise<UnaryResponse<REQ, RESP>>
    ): Promise<UnaryResponse<REQ, RESP>> {
        const enabled = isDevelopment();
        try {
            const res = await invoker(request);
            if (enabled) {
                // @ts-ignore
                console.groupCollapsed(`gRPC Done - ${res.getStatus().code} - ${request.getMethodDescriptor().name}`);
                // @ts-ignore
                console.debug("request", request.getRequestMessage().toObject());
                // @ts-ignore
                console.debug("response", res.getResponseMessage().toObject());
                console.groupEnd();
            }
            return res;
        } catch (e) {
            // @ts-ignore
            console.groupCollapsed(`gRPC Done w/Error ${request.getMethodDescriptor().name}: ${e.message}`);
            // @ts-ignore
            console.debug("request", request.getRequestMessage().toObject());
            console.groupEnd();
            throw e;
        }
    }
}
