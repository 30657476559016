import React, {useEffect, useState} from 'react';
import Clipboard from 'clipboard';
import {observer} from "mobx-react-lite";
import {
    Button, ButtonProps,
    IconButton,
    SvgIcon,
    SvgIconProps,
    Tooltip,
    Typography,
    TypographyProps
} from "@mui/material";
import {MdContentCopy} from "react-icons/md";

// ======================
// ClipboardText
// ======================

interface ClipboardTextProps {
    clipboardId: string;
}

export const ClipboardText: React.FC<ClipboardTextProps & Partial<TypographyProps>> = observer((props) => {
    const { clipboardId, ...rest} = props;
    return <Typography {...rest} id={clipboardId} component={'span'}>
        {props.children}
    </Typography>
})

// ======================
// ClipboardButton
// ======================

interface ClipboardButtonProps {
    clipboardId: string;
    iconButton?: boolean;
    label?: string;
}

export const ClipboardButton: React.FC<ClipboardButtonProps & Partial<ButtonProps>> = observer((props) => {
    const {clipboardId, iconButton, label, ...rest} = props;

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipText, setTooltipText] = useState('');

    const showTooltip = (result: string) => {
        if (result === 'success') {
            setTooltipText('Copied!');
            setTooltipOpen(true);
            setTimeout(() => setTooltipOpen(false), 500)
        } else if (result === 'error') {
            setTooltipText('Copy Unsuccessful. Use CTRL+C to copy text.');
            setTooltipOpen(true);
            setTimeout(() => setTooltipOpen(false), 500);

        }
    }
    useEffect(() => {
        const clipboard = new Clipboard(`#${clipboardId}`);
        clipboard.on('success', () => {
            showTooltip('success')

        })
        clipboard.on('error', (e) => {
            showTooltip('error')
            console.error('Action:', e.action);
            console.error('Trigger:', e.trigger);
            console.error(e.text)
        })
        return () => clipboard.destroy();
    }, [clipboardId])

        if (!iconButton) {
            return <Tooltip title={tooltipText} arrow={true} open={tooltipOpen} leaveDelay={0}
                            placement={'top'} id={clipboardId}
            >
                <Button {...rest} variant={'outlined'} id={clipboardId}
                        data-clipboard-target={`#${clipboardId}`}>{label ? label : `Copy To Clipboard`}</Button>
            </Tooltip>
        } else {
            return <Tooltip title={tooltipText || 'Copy To Clipboard'} arrow={true}  leaveDelay={0}
                            onClose={()=>setTimeout(() => setTooltipText(''), 500)}
                            placement={'top'} id={clipboardId}>
                    <IconButton id={clipboardId}
                                data-clipboard-target={`#${clipboardId}`}><CopyToClipIcon/></IconButton>
            </Tooltip>
        }
});

const CopyToClipIcon: React.FC<Partial<SvgIconProps>> = observer((props)=>{

    return <SvgIcon {...props}>
        <MdContentCopy/>
    </SvgIcon>
})


