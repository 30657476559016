/**
 * @fileoverview gRPC-Web generated client stub for galaxycomplete.api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: galaxycompletepb/apipb/license_api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as galaxycompletepb_apipb_license_api_pb from '../../galaxycompletepb/apipb/license_api_pb';


export class LicenseBillingServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateLicensePurchaseSession = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.LicenseBillingService/CreateLicensePurchaseSession',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_license_api_pb.CreateLicensePurchaseSession.Request,
    galaxycompletepb_apipb_license_api_pb.CreateLicensePurchaseSession.Response,
    (request: galaxycompletepb_apipb_license_api_pb.CreateLicensePurchaseSession.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_license_api_pb.CreateLicensePurchaseSession.Response.deserializeBinary
  );

  createLicensePurchaseSession(
    request: galaxycompletepb_apipb_license_api_pb.CreateLicensePurchaseSession.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_license_api_pb.CreateLicensePurchaseSession.Response>;

  createLicensePurchaseSession(
    request: galaxycompletepb_apipb_license_api_pb.CreateLicensePurchaseSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_license_api_pb.CreateLicensePurchaseSession.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_license_api_pb.CreateLicensePurchaseSession.Response>;

  createLicensePurchaseSession(
    request: galaxycompletepb_apipb_license_api_pb.CreateLicensePurchaseSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_license_api_pb.CreateLicensePurchaseSession.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.LicenseBillingService/CreateLicensePurchaseSession',
        request,
        metadata || {},
        this.methodDescriptorCreateLicensePurchaseSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.LicenseBillingService/CreateLicensePurchaseSession',
    request,
    metadata || {},
    this.methodDescriptorCreateLicensePurchaseSession);
  }

  methodDescriptorCreateBillingManagementSession = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.LicenseBillingService/CreateBillingManagementSession',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_license_api_pb.CreateBillingManagementSession.Request,
    galaxycompletepb_apipb_license_api_pb.CreateBillingManagementSession.Response,
    (request: galaxycompletepb_apipb_license_api_pb.CreateBillingManagementSession.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_license_api_pb.CreateBillingManagementSession.Response.deserializeBinary
  );

  createBillingManagementSession(
    request: galaxycompletepb_apipb_license_api_pb.CreateBillingManagementSession.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_license_api_pb.CreateBillingManagementSession.Response>;

  createBillingManagementSession(
    request: galaxycompletepb_apipb_license_api_pb.CreateBillingManagementSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_license_api_pb.CreateBillingManagementSession.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_license_api_pb.CreateBillingManagementSession.Response>;

  createBillingManagementSession(
    request: galaxycompletepb_apipb_license_api_pb.CreateBillingManagementSession.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_license_api_pb.CreateBillingManagementSession.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.LicenseBillingService/CreateBillingManagementSession',
        request,
        metadata || {},
        this.methodDescriptorCreateBillingManagementSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.LicenseBillingService/CreateBillingManagementSession',
    request,
    metadata || {},
    this.methodDescriptorCreateBillingManagementSession);
  }

  methodDescriptorGetLicensePurchaseCatalog = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.LicenseBillingService/GetLicensePurchaseCatalog',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_license_api_pb.GetLicensePurchaseCatalog.Request,
    galaxycompletepb_apipb_license_api_pb.GetLicensePurchaseCatalog.Response,
    (request: galaxycompletepb_apipb_license_api_pb.GetLicensePurchaseCatalog.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_license_api_pb.GetLicensePurchaseCatalog.Response.deserializeBinary
  );

  getLicensePurchaseCatalog(
    request: galaxycompletepb_apipb_license_api_pb.GetLicensePurchaseCatalog.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_license_api_pb.GetLicensePurchaseCatalog.Response>;

  getLicensePurchaseCatalog(
    request: galaxycompletepb_apipb_license_api_pb.GetLicensePurchaseCatalog.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_license_api_pb.GetLicensePurchaseCatalog.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_license_api_pb.GetLicensePurchaseCatalog.Response>;

  getLicensePurchaseCatalog(
    request: galaxycompletepb_apipb_license_api_pb.GetLicensePurchaseCatalog.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_license_api_pb.GetLicensePurchaseCatalog.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.LicenseBillingService/GetLicensePurchaseCatalog',
        request,
        metadata || {},
        this.methodDescriptorGetLicensePurchaseCatalog,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.LicenseBillingService/GetLicensePurchaseCatalog',
    request,
    metadata || {},
    this.methodDescriptorGetLicensePurchaseCatalog);
  }

  methodDescriptorPurchaseLicenseFromAwsMarketplaceSubscription = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.LicenseBillingService/PurchaseLicenseFromAwsMarketplaceSubscription',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAwsMarketplaceSubscription.Request,
    galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAwsMarketplaceSubscription.Response,
    (request: galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAwsMarketplaceSubscription.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAwsMarketplaceSubscription.Response.deserializeBinary
  );

  purchaseLicenseFromAwsMarketplaceSubscription(
    request: galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAwsMarketplaceSubscription.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAwsMarketplaceSubscription.Response>;

  purchaseLicenseFromAwsMarketplaceSubscription(
    request: galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAwsMarketplaceSubscription.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAwsMarketplaceSubscription.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAwsMarketplaceSubscription.Response>;

  purchaseLicenseFromAwsMarketplaceSubscription(
    request: galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAwsMarketplaceSubscription.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAwsMarketplaceSubscription.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.LicenseBillingService/PurchaseLicenseFromAwsMarketplaceSubscription',
        request,
        metadata || {},
        this.methodDescriptorPurchaseLicenseFromAwsMarketplaceSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.LicenseBillingService/PurchaseLicenseFromAwsMarketplaceSubscription',
    request,
    metadata || {},
    this.methodDescriptorPurchaseLicenseFromAwsMarketplaceSubscription);
  }

  methodDescriptorPurchaseLicenseFromAzureMarketplaceSubscription = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.LicenseBillingService/PurchaseLicenseFromAzureMarketplaceSubscription',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAzureMarketplaceSubscription.Request,
    galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAzureMarketplaceSubscription.Response,
    (request: galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAzureMarketplaceSubscription.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAzureMarketplaceSubscription.Response.deserializeBinary
  );

  purchaseLicenseFromAzureMarketplaceSubscription(
    request: galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAzureMarketplaceSubscription.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAzureMarketplaceSubscription.Response>;

  purchaseLicenseFromAzureMarketplaceSubscription(
    request: galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAzureMarketplaceSubscription.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAzureMarketplaceSubscription.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAzureMarketplaceSubscription.Response>;

  purchaseLicenseFromAzureMarketplaceSubscription(
    request: galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAzureMarketplaceSubscription.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_license_api_pb.PurchaseLicenseFromAzureMarketplaceSubscription.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.LicenseBillingService/PurchaseLicenseFromAzureMarketplaceSubscription',
        request,
        metadata || {},
        this.methodDescriptorPurchaseLicenseFromAzureMarketplaceSubscription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.LicenseBillingService/PurchaseLicenseFromAzureMarketplaceSubscription',
    request,
    metadata || {},
    this.methodDescriptorPurchaseLicenseFromAzureMarketplaceSubscription);
  }

}

