import { GRPCServices } from "../grpc/grpcapi";
import { DialogService } from "../core/dialog/DialogService";
import { ProgressService } from "../core/progress/ProgressService";
import { makeAutoObservable } from "mobx";
import { ServerData } from "../core/data/ServerData";
import {
    CreateProject,
    DeleteProject,
    GetMyProjectRole,
    GetProjectDetails,
    ListProjects,
    UpdateProject,
} from "../../_proto/galaxycompletepb/apipb/project_api_pb";
import { ServerListData } from "../core/data/ListData";
import { ProjectDetails, ProjectListItem, ProjectMemberInfo } from "../../_proto/galaxycompletepb/apipb/domainpb/project_pb";
import { FilterParams, PagerParams } from "../../_proto/galaxycompletepb/commonpb/datafilter_pb";
import { getProjectListFilterConfig } from "./ProjectCommon";

export class ProjectService {
    private api: GRPCServices;
    dialogService: DialogService;
    progressService: ProgressService;

    myProjects = new ServerListData<ListProjects.Response.AsObject, ProjectListItem.AsObject, ListProjects.Request>(
        10,
        getProjectListFilterConfig()
    ).setDataFetcher(this.fetchMyProjects.bind(this));
    currentProject = new ServerData<ProjectDetails>().setDataFetcher(this.fetchCurrentProjectDetails.bind(this));
    myProjectRole = new ServerData<ProjectMemberInfo.Role>().setDataFetcher(this.fetchMyProjectRole.bind(this));

    constructor(api: GRPCServices, dialogService: DialogService, progressService: ProgressService) {
        this.api = api;
        this.dialogService = dialogService;
        this.progressService = progressService;
        makeAutoObservable(this);
    }

    async fetchMyProjects(isAdmin: boolean = false, pagerParams?: PagerParams) {
        let request = new ListProjects.Request()
            .setPageParams(pagerParams || this.myProjects.pagerParam)
            .setSortParams(this.myProjects.sortParam || null)
            .setIsAdmin(isAdmin);

        this.myProjects.addFilterParamsToRequest(request);
        request.addFilterParams(
            new ListProjects.Request.Filter().setIsSupporting(new FilterParams.BoolFilter().setValue(false).setOp(FilterParams.BoolFilter.Op.EQUAL))
        );
        //return mockProjectsListData2.toObject()

        const res = await this.api.projectService.listProjects(request, null);
        return res.toObject();
    }

    async fetchCurrentProjectDetails(projectId?: string) {
        projectId = projectId || this.currentProjectID;
        const request = new GetProjectDetails.Request().setProjectId(projectId);
        const res = await this.api.projectService.getProjectDetails(request, null);
        return res.getProjectDetails();
    }

    async fetchMyProjectRole(projectId?: string) {
        projectId = projectId || this.currentProjectID;
        const req = new GetMyProjectRole.Request().setProjectId(projectId);
        const res = await this.api.projectService.getMyProjectRole(req, null);
        return res.getMyRole();
    }

    get currentProjectRole() {
        return this.myProjectRole.ready ? this.myProjectRole.data : null;
    }

    clearCurrentProject() {
        this.currentProject.resetData();
    }

    get currentProjectID() {
        return this.currentProject.ready ? this.currentProject.data.getInfo().getProjectId() : null;
    }

    public async createNewProject(name: string, description: string, emergencyEmail: string, emergencyPhone: string, deleteLock: boolean) {
        const req = new CreateProject.Request()
            .setName(name)
            .setEmergencyEmail(emergencyEmail)
            .setEmergencyPhone(emergencyPhone)
            .setDeleteLock(deleteLock)
            .setDescription(description);
        await this.progressService.track(this.api.projectService.createProject(req, null));
        await this.myProjects.fetchData();
    }
    async createNewProjectWithHook(req: CreateProject.Request) {
        return await this.api.projectService.createProject(req, null);
    }

    public async updateProject(name: string, description: string, emergencyEmail: string, emergencyPhone: string, deleteLock: boolean) {
        const req = new UpdateProject.Request()
            .setDescription(description)
            .setEmergencyEmail(emergencyEmail)
            .setEmergencyPhone(emergencyPhone)
            .setName(name)
            .setProjectId(this.currentProjectID)
            .setDeleteLock(deleteLock);

        return await this.progressService.track(this.api.projectService.updateProject(req, null));
    }

    public async setProjectEmailNotifications(enableLicenseTransactionEmail: boolean, enableNewHostEmail: boolean) {
        const req = new UpdateProject.Request()
            .setProjectId(this.currentProjectID)
            .setDescription(this.currentProject.data?.getInfo().getDescription())
            .setName(this.currentProject.data?.getInfo().getName())
            .setEnableLicenseTransactionEmailNotification(enableLicenseTransactionEmail)
            .setEnableNewHostEmailNotification(enableNewHostEmail)
            .setDeleteLock(this.currentProject.data?.getInfo().getDeleteLock());
        return await this.progressService.track(this.api.projectService.updateProject(req, null));
    }

    public async setSupportPermissions(allowSupport: boolean) {
        const req = new UpdateProject.Request()
            .setProjectId(this.currentProjectID)
            .setDescription(this.currentProject.data?.getInfo().getDescription())
            .setName(this.currentProject.data?.getInfo().getName())
            .setDeleteLock(this.currentProject.data?.getInfo().getDeleteLock())
            .setAllowSupportStaff(allowSupport);
        return await this.progressService.track(this.api.projectService.updateProject(req, null));
    }
}
