/**
 * @fileoverview gRPC-Web generated client stub for galaxycomplete.api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: galaxycompletepb/apipb/support_api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as galaxycompletepb_apipb_support_api_pb from '../../galaxycompletepb/apipb/support_api_pb';
import * as galaxycompletepb_apipb_project_api_pb from '../../galaxycompletepb/apipb/project_api_pb';


export class SupportServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListSupportableProject = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.SupportService/ListSupportableProject',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_project_api_pb.ListProjects.Request,
    galaxycompletepb_apipb_project_api_pb.ListProjects.Response,
    (request: galaxycompletepb_apipb_project_api_pb.ListProjects.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_project_api_pb.ListProjects.Response.deserializeBinary
  );

  listSupportableProject(
    request: galaxycompletepb_apipb_project_api_pb.ListProjects.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_project_api_pb.ListProjects.Response>;

  listSupportableProject(
    request: galaxycompletepb_apipb_project_api_pb.ListProjects.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ListProjects.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_project_api_pb.ListProjects.Response>;

  listSupportableProject(
    request: galaxycompletepb_apipb_project_api_pb.ListProjects.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_project_api_pb.ListProjects.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.SupportService/ListSupportableProject',
        request,
        metadata || {},
        this.methodDescriptorListSupportableProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.SupportService/ListSupportableProject',
    request,
    metadata || {},
    this.methodDescriptorListSupportableProject);
  }

  methodDescriptorJoinProject = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.SupportService/JoinProject',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_support_api_pb.JoinProject.Request,
    galaxycompletepb_apipb_support_api_pb.JoinProject.Response,
    (request: galaxycompletepb_apipb_support_api_pb.JoinProject.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_support_api_pb.JoinProject.Response.deserializeBinary
  );

  joinProject(
    request: galaxycompletepb_apipb_support_api_pb.JoinProject.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_support_api_pb.JoinProject.Response>;

  joinProject(
    request: galaxycompletepb_apipb_support_api_pb.JoinProject.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_support_api_pb.JoinProject.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_support_api_pb.JoinProject.Response>;

  joinProject(
    request: galaxycompletepb_apipb_support_api_pb.JoinProject.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_support_api_pb.JoinProject.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.SupportService/JoinProject',
        request,
        metadata || {},
        this.methodDescriptorJoinProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.SupportService/JoinProject',
    request,
    metadata || {},
    this.methodDescriptorJoinProject);
  }

  methodDescriptorListXrays = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.SupportService/ListXrays',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_support_api_pb.ListXrays.Request,
    galaxycompletepb_apipb_support_api_pb.ListXrays.Response,
    (request: galaxycompletepb_apipb_support_api_pb.ListXrays.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_support_api_pb.ListXrays.Response.deserializeBinary
  );

  listXrays(
    request: galaxycompletepb_apipb_support_api_pb.ListXrays.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_support_api_pb.ListXrays.Response>;

  listXrays(
    request: galaxycompletepb_apipb_support_api_pb.ListXrays.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_support_api_pb.ListXrays.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_support_api_pb.ListXrays.Response>;

  listXrays(
    request: galaxycompletepb_apipb_support_api_pb.ListXrays.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_support_api_pb.ListXrays.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.SupportService/ListXrays',
        request,
        metadata || {},
        this.methodDescriptorListXrays,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.SupportService/ListXrays',
    request,
    metadata || {},
    this.methodDescriptorListXrays);
  }

  methodDescriptorDownloadXrays = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.SupportService/DownloadXrays',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_support_api_pb.DownloadXrays.Request,
    galaxycompletepb_apipb_support_api_pb.DownloadXrays.Response,
    (request: galaxycompletepb_apipb_support_api_pb.DownloadXrays.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_support_api_pb.DownloadXrays.Response.deserializeBinary
  );

  downloadXrays(
    request: galaxycompletepb_apipb_support_api_pb.DownloadXrays.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_support_api_pb.DownloadXrays.Response>;

  downloadXrays(
    request: galaxycompletepb_apipb_support_api_pb.DownloadXrays.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_support_api_pb.DownloadXrays.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_support_api_pb.DownloadXrays.Response>;

  downloadXrays(
    request: galaxycompletepb_apipb_support_api_pb.DownloadXrays.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_support_api_pb.DownloadXrays.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.SupportService/DownloadXrays',
        request,
        metadata || {},
        this.methodDescriptorDownloadXrays,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.SupportService/DownloadXrays',
    request,
    metadata || {},
    this.methodDescriptorDownloadXrays);
  }

  methodDescriptorDeleteXray = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.SupportService/DeleteXray',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_support_api_pb.DeleteXray.Request,
    galaxycompletepb_apipb_support_api_pb.DeleteXray.Response,
    (request: galaxycompletepb_apipb_support_api_pb.DeleteXray.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_support_api_pb.DeleteXray.Response.deserializeBinary
  );

  deleteXray(
    request: galaxycompletepb_apipb_support_api_pb.DeleteXray.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_support_api_pb.DeleteXray.Response>;

  deleteXray(
    request: galaxycompletepb_apipb_support_api_pb.DeleteXray.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_support_api_pb.DeleteXray.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_support_api_pb.DeleteXray.Response>;

  deleteXray(
    request: galaxycompletepb_apipb_support_api_pb.DeleteXray.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_support_api_pb.DeleteXray.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.SupportService/DeleteXray',
        request,
        metadata || {},
        this.methodDescriptorDeleteXray,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.SupportService/DeleteXray',
    request,
    metadata || {},
    this.methodDescriptorDeleteXray);
  }

}

