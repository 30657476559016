// Project: GalaxyComplete
// Created: 10/21/20 by sammy
// File: CommonIcons

import * as React from 'react';
import {Button, ButtonProps, Icon, IconProps, SvgIcon, SvgIconProps} from '@mui/material';
import {GrStatusUnknown, GrVmware} from "react-icons/gr";
import {AiFillTool} from "react-icons/ai";
import {
    MdChevronLeft,
    MdChevronRight,
    MdClose,
    MdDelete,
    MdDescription,
    MdEdit,
    MdRefresh,
    MdVpnKey
} from "react-icons/md";
import {FaUserCog} from "react-icons/fa";
import {FiExternalLink, FiHardDrive, FiLogOut} from "react-icons/fi";
import {IoMdHelpCircleOutline} from "react-icons/io";
import CirrusPngIcon from '../assets/cirrus_icon_white.png';
import {
    DeploymentHostEnvironment
} from "../_proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import {observer} from "mobx-react-lite";
import {
    SiAmazonaws,
    SiDigitalocean,
    SiGooglecloud,
    SiIbm,
    SiMicrosoft,
    SiMicrosoftazure,
    SiOpenstack,
    SiVultr
} from "react-icons/si";
import {HiOutlineServer, HiOutlineClipboardList} from "react-icons/hi";

export const UnknownIcons: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <GrStatusUnknown/>
    </SvgIcon>;
};

export const GenericActionIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <AiFillTool/>
    </SvgIcon>;
};

export const DeleteIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <MdDelete/>
    </SvgIcon>;
};

export const EditIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <MdEdit/>
    </SvgIcon>;
};

export const CloseIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <MdClose/>
    </SvgIcon>;
};

export const LeftArrow: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <MdChevronLeft/>
    </SvgIcon>;
};

export const RightArrow: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <MdChevronRight/>
    </SvgIcon>;
};

export const UserSettingsIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <FaUserCog/>
    </SvgIcon>;
};

export const LogOutIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <FiLogOut/>
    </SvgIcon>
};

export const ExternalLinkIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <FiExternalLink/>
    </SvgIcon>
};

export const HelpIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <IoMdHelpCircleOutline/>
    </SvgIcon>
}

export const LicenseIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <MdVpnKey/>
    </SvgIcon>
}

export const RefreshIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <MdRefresh/>
    </SvgIcon>
}
export const RefreshButton: React.FC<Partial<ButtonProps>> = (p) => {
    return <Button {...p} startIcon={<MdRefresh/>}>{p.children || 'Refresh'}</Button>
}

export const LiveLogIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <MdDescription/>
    </SvgIcon>
}

export const ReportIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <HiOutlineClipboardList/>
    </SvgIcon>
}
// ======================
// HostEnvironmentIcon
// ======================

interface HostEnvironmentIconProps {
    env: DeploymentHostEnvironment.DeploymentHostEnvironment;
}

export const HostEnvironmentIcon: React.FC<HostEnvironmentIconProps> = observer((p) => {
    const {env, ...props} = p;

    const sizeStyle = {
        size: 20
    }

    return (<>
        {
            env === (DeploymentHostEnvironment.DeploymentHostEnvironment.AWS) ? <SiAmazonaws {...sizeStyle}/> :
                env === (DeploymentHostEnvironment.DeploymentHostEnvironment.AZURE) ?
                    <SiMicrosoftazure {...sizeStyle}/> :
                    env === (DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE) ? <GrVmware {...sizeStyle}/> :
                        env === (DeploymentHostEnvironment.DeploymentHostEnvironment.OPENSTACK) ?
                            <SiOpenstack {...sizeStyle}/> :
                            env === (DeploymentHostEnvironment.DeploymentHostEnvironment.SOFTLAYER) ?
                                <SiIbm {...sizeStyle}/> :
                                env === (DeploymentHostEnvironment.DeploymentHostEnvironment.DO) ?
                                    <SiDigitalocean {...sizeStyle}/> :
                                    env === (DeploymentHostEnvironment.DeploymentHostEnvironment.VULTR) ?
                                        <SiVultr {...sizeStyle}/> :
                                        env === (DeploymentHostEnvironment.DeploymentHostEnvironment.HYPERV) ?
                                            <SiMicrosoft {...sizeStyle}/> :
                                            env === (DeploymentHostEnvironment.DeploymentHostEnvironment.GCP) ?
                                                <SiGooglecloud {...sizeStyle}/> : <HiOutlineServer {...sizeStyle}/>
        }
    </>);
});


// ================
// Storage Entities
// ================

export const DiskIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return <SvgIcon {...p}>
        <FiHardDrive/>
    </SvgIcon>;
};

// ======================
// CirrusIcon
// ======================

export const CirrusIcon: React.FC<Partial<IconProps>> = (p) => {

    return <Icon sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }} {...p}>
        <img src={CirrusPngIcon} alt={'Cirrus Data'} height={'100%'}/>
    </Icon>
}


