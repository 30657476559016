/**
 * @fileoverview gRPC-Web generated client stub for galaxycomplete.api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: galaxycompletepb/apipb/deployment_api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as galaxycompletepb_apipb_deployment_api_pb from '../../galaxycompletepb/apipb/deployment_api_pb';


export class DeploymentServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorPingDeployment = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.DeploymentService/PingDeployment',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_deployment_api_pb.PingDeployment.Request,
    galaxycompletepb_apipb_deployment_api_pb.PingDeployment.Response,
    (request: galaxycompletepb_apipb_deployment_api_pb.PingDeployment.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_deployment_api_pb.PingDeployment.Response.deserializeBinary
  );

  pingDeployment(
    request: galaxycompletepb_apipb_deployment_api_pb.PingDeployment.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_deployment_api_pb.PingDeployment.Response>;

  pingDeployment(
    request: galaxycompletepb_apipb_deployment_api_pb.PingDeployment.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.PingDeployment.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_deployment_api_pb.PingDeployment.Response>;

  pingDeployment(
    request: galaxycompletepb_apipb_deployment_api_pb.PingDeployment.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.PingDeployment.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.DeploymentService/PingDeployment',
        request,
        metadata || {},
        this.methodDescriptorPingDeployment,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.DeploymentService/PingDeployment',
    request,
    metadata || {},
    this.methodDescriptorPingDeployment);
  }

  methodDescriptorListGalaxyMigrateDeployments = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.DeploymentService/ListGalaxyMigrateDeployments',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_deployment_api_pb.ListGalaxyMigrateDeployments.Request,
    galaxycompletepb_apipb_deployment_api_pb.ListGalaxyMigrateDeployments.Response,
    (request: galaxycompletepb_apipb_deployment_api_pb.ListGalaxyMigrateDeployments.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_deployment_api_pb.ListGalaxyMigrateDeployments.Response.deserializeBinary
  );

  listGalaxyMigrateDeployments(
    request: galaxycompletepb_apipb_deployment_api_pb.ListGalaxyMigrateDeployments.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_deployment_api_pb.ListGalaxyMigrateDeployments.Response>;

  listGalaxyMigrateDeployments(
    request: galaxycompletepb_apipb_deployment_api_pb.ListGalaxyMigrateDeployments.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.ListGalaxyMigrateDeployments.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_deployment_api_pb.ListGalaxyMigrateDeployments.Response>;

  listGalaxyMigrateDeployments(
    request: galaxycompletepb_apipb_deployment_api_pb.ListGalaxyMigrateDeployments.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.ListGalaxyMigrateDeployments.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.DeploymentService/ListGalaxyMigrateDeployments',
        request,
        metadata || {},
        this.methodDescriptorListGalaxyMigrateDeployments,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.DeploymentService/ListGalaxyMigrateDeployments',
    request,
    metadata || {},
    this.methodDescriptorListGalaxyMigrateDeployments);
  }

  methodDescriptorGetGalaxyMigrateDeploymentDetails = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.DeploymentService/GetGalaxyMigrateDeploymentDetails',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_deployment_api_pb.GetGalaxyMigrateDeploymentDetails.Request,
    galaxycompletepb_apipb_deployment_api_pb.GetGalaxyMigrateDeploymentDetails.Response,
    (request: galaxycompletepb_apipb_deployment_api_pb.GetGalaxyMigrateDeploymentDetails.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_deployment_api_pb.GetGalaxyMigrateDeploymentDetails.Response.deserializeBinary
  );

  getGalaxyMigrateDeploymentDetails(
    request: galaxycompletepb_apipb_deployment_api_pb.GetGalaxyMigrateDeploymentDetails.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_deployment_api_pb.GetGalaxyMigrateDeploymentDetails.Response>;

  getGalaxyMigrateDeploymentDetails(
    request: galaxycompletepb_apipb_deployment_api_pb.GetGalaxyMigrateDeploymentDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.GetGalaxyMigrateDeploymentDetails.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_deployment_api_pb.GetGalaxyMigrateDeploymentDetails.Response>;

  getGalaxyMigrateDeploymentDetails(
    request: galaxycompletepb_apipb_deployment_api_pb.GetGalaxyMigrateDeploymentDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.GetGalaxyMigrateDeploymentDetails.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.DeploymentService/GetGalaxyMigrateDeploymentDetails',
        request,
        metadata || {},
        this.methodDescriptorGetGalaxyMigrateDeploymentDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.DeploymentService/GetGalaxyMigrateDeploymentDetails',
    request,
    metadata || {},
    this.methodDescriptorGetGalaxyMigrateDeploymentDetails);
  }

  methodDescriptorListCirrusProtectDeployments = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.DeploymentService/ListCirrusProtectDeployments',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_deployment_api_pb.ListCirrusProtectDeployments.Request,
    galaxycompletepb_apipb_deployment_api_pb.ListCirrusProtectDeployments.Response,
    (request: galaxycompletepb_apipb_deployment_api_pb.ListCirrusProtectDeployments.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_deployment_api_pb.ListCirrusProtectDeployments.Response.deserializeBinary
  );

  listCirrusProtectDeployments(
    request: galaxycompletepb_apipb_deployment_api_pb.ListCirrusProtectDeployments.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_deployment_api_pb.ListCirrusProtectDeployments.Response>;

  listCirrusProtectDeployments(
    request: galaxycompletepb_apipb_deployment_api_pb.ListCirrusProtectDeployments.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.ListCirrusProtectDeployments.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_deployment_api_pb.ListCirrusProtectDeployments.Response>;

  listCirrusProtectDeployments(
    request: galaxycompletepb_apipb_deployment_api_pb.ListCirrusProtectDeployments.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.ListCirrusProtectDeployments.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.DeploymentService/ListCirrusProtectDeployments',
        request,
        metadata || {},
        this.methodDescriptorListCirrusProtectDeployments,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.DeploymentService/ListCirrusProtectDeployments',
    request,
    metadata || {},
    this.methodDescriptorListCirrusProtectDeployments);
  }

  methodDescriptorGetCirrusProtectDeploymentDetails = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.DeploymentService/GetCirrusProtectDeploymentDetails',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_deployment_api_pb.GetCirrusProtectDeploymentDetails.Request,
    galaxycompletepb_apipb_deployment_api_pb.GetCirrusProtectDeploymentDetails.Response,
    (request: galaxycompletepb_apipb_deployment_api_pb.GetCirrusProtectDeploymentDetails.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_deployment_api_pb.GetCirrusProtectDeploymentDetails.Response.deserializeBinary
  );

  getCirrusProtectDeploymentDetails(
    request: galaxycompletepb_apipb_deployment_api_pb.GetCirrusProtectDeploymentDetails.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_deployment_api_pb.GetCirrusProtectDeploymentDetails.Response>;

  getCirrusProtectDeploymentDetails(
    request: galaxycompletepb_apipb_deployment_api_pb.GetCirrusProtectDeploymentDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.GetCirrusProtectDeploymentDetails.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_deployment_api_pb.GetCirrusProtectDeploymentDetails.Response>;

  getCirrusProtectDeploymentDetails(
    request: galaxycompletepb_apipb_deployment_api_pb.GetCirrusProtectDeploymentDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.GetCirrusProtectDeploymentDetails.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.DeploymentService/GetCirrusProtectDeploymentDetails',
        request,
        metadata || {},
        this.methodDescriptorGetCirrusProtectDeploymentDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.DeploymentService/GetCirrusProtectDeploymentDetails',
    request,
    metadata || {},
    this.methodDescriptorGetCirrusProtectDeploymentDetails);
  }

  methodDescriptorListPhoenixDeployments = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.DeploymentService/ListPhoenixDeployments',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_deployment_api_pb.ListPhoenixDeployments.Request,
    galaxycompletepb_apipb_deployment_api_pb.ListPhoenixDeployments.Response,
    (request: galaxycompletepb_apipb_deployment_api_pb.ListPhoenixDeployments.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_deployment_api_pb.ListPhoenixDeployments.Response.deserializeBinary
  );

  listPhoenixDeployments(
    request: galaxycompletepb_apipb_deployment_api_pb.ListPhoenixDeployments.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_deployment_api_pb.ListPhoenixDeployments.Response>;

  listPhoenixDeployments(
    request: galaxycompletepb_apipb_deployment_api_pb.ListPhoenixDeployments.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.ListPhoenixDeployments.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_deployment_api_pb.ListPhoenixDeployments.Response>;

  listPhoenixDeployments(
    request: galaxycompletepb_apipb_deployment_api_pb.ListPhoenixDeployments.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.ListPhoenixDeployments.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.DeploymentService/ListPhoenixDeployments',
        request,
        metadata || {},
        this.methodDescriptorListPhoenixDeployments,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.DeploymentService/ListPhoenixDeployments',
    request,
    metadata || {},
    this.methodDescriptorListPhoenixDeployments);
  }

  methodDescriptorGetPhoenixDeploymentDetails = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.DeploymentService/GetPhoenixDeploymentDetails',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_deployment_api_pb.GetPhoenixDeploymentDetails.Request,
    galaxycompletepb_apipb_deployment_api_pb.GetPhoenixDeploymentDetails.Response,
    (request: galaxycompletepb_apipb_deployment_api_pb.GetPhoenixDeploymentDetails.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_deployment_api_pb.GetPhoenixDeploymentDetails.Response.deserializeBinary
  );

  getPhoenixDeploymentDetails(
    request: galaxycompletepb_apipb_deployment_api_pb.GetPhoenixDeploymentDetails.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_deployment_api_pb.GetPhoenixDeploymentDetails.Response>;

  getPhoenixDeploymentDetails(
    request: galaxycompletepb_apipb_deployment_api_pb.GetPhoenixDeploymentDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.GetPhoenixDeploymentDetails.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_deployment_api_pb.GetPhoenixDeploymentDetails.Response>;

  getPhoenixDeploymentDetails(
    request: galaxycompletepb_apipb_deployment_api_pb.GetPhoenixDeploymentDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.GetPhoenixDeploymentDetails.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.DeploymentService/GetPhoenixDeploymentDetails',
        request,
        metadata || {},
        this.methodDescriptorGetPhoenixDeploymentDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.DeploymentService/GetPhoenixDeploymentDetails',
    request,
    metadata || {},
    this.methodDescriptorGetPhoenixDeploymentDetails);
  }

  methodDescriptorRemoveDeployment = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.DeploymentService/RemoveDeployment',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_deployment_api_pb.RemoveDeployment.Request,
    galaxycompletepb_apipb_deployment_api_pb.RemoveDeployment.Response,
    (request: galaxycompletepb_apipb_deployment_api_pb.RemoveDeployment.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_deployment_api_pb.RemoveDeployment.Response.deserializeBinary
  );

  removeDeployment(
    request: galaxycompletepb_apipb_deployment_api_pb.RemoveDeployment.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_deployment_api_pb.RemoveDeployment.Response>;

  removeDeployment(
    request: galaxycompletepb_apipb_deployment_api_pb.RemoveDeployment.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.RemoveDeployment.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_deployment_api_pb.RemoveDeployment.Response>;

  removeDeployment(
    request: galaxycompletepb_apipb_deployment_api_pb.RemoveDeployment.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.RemoveDeployment.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.DeploymentService/RemoveDeployment',
        request,
        metadata || {},
        this.methodDescriptorRemoveDeployment,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.DeploymentService/RemoveDeployment',
    request,
    metadata || {},
    this.methodDescriptorRemoveDeployment);
  }

  methodDescriptorRemoveOfflineDeployments = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.DeploymentService/RemoveOfflineDeployments',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_deployment_api_pb.RemoveOfflineDeployments.Request,
    galaxycompletepb_apipb_deployment_api_pb.RemoveOfflineDeployments.Response,
    (request: galaxycompletepb_apipb_deployment_api_pb.RemoveOfflineDeployments.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_deployment_api_pb.RemoveOfflineDeployments.Response.deserializeBinary
  );

  removeOfflineDeployments(
    request: galaxycompletepb_apipb_deployment_api_pb.RemoveOfflineDeployments.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_deployment_api_pb.RemoveOfflineDeployments.Response>;

  removeOfflineDeployments(
    request: galaxycompletepb_apipb_deployment_api_pb.RemoveOfflineDeployments.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.RemoveOfflineDeployments.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_deployment_api_pb.RemoveOfflineDeployments.Response>;

  removeOfflineDeployments(
    request: galaxycompletepb_apipb_deployment_api_pb.RemoveOfflineDeployments.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.RemoveOfflineDeployments.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.DeploymentService/RemoveOfflineDeployments',
        request,
        metadata || {},
        this.methodDescriptorRemoveOfflineDeployments,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.DeploymentService/RemoveOfflineDeployments',
    request,
    metadata || {},
    this.methodDescriptorRemoveOfflineDeployments);
  }

  methodDescriptorListHostLicenseTransactions = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.DeploymentService/ListHostLicenseTransactions',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_deployment_api_pb.ListHostLicenseTransactions.Request,
    galaxycompletepb_apipb_deployment_api_pb.ListHostLicenseTransactions.Response,
    (request: galaxycompletepb_apipb_deployment_api_pb.ListHostLicenseTransactions.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_deployment_api_pb.ListHostLicenseTransactions.Response.deserializeBinary
  );

  listHostLicenseTransactions(
    request: galaxycompletepb_apipb_deployment_api_pb.ListHostLicenseTransactions.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_deployment_api_pb.ListHostLicenseTransactions.Response>;

  listHostLicenseTransactions(
    request: galaxycompletepb_apipb_deployment_api_pb.ListHostLicenseTransactions.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.ListHostLicenseTransactions.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_deployment_api_pb.ListHostLicenseTransactions.Response>;

  listHostLicenseTransactions(
    request: galaxycompletepb_apipb_deployment_api_pb.ListHostLicenseTransactions.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.ListHostLicenseTransactions.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.DeploymentService/ListHostLicenseTransactions',
        request,
        metadata || {},
        this.methodDescriptorListHostLicenseTransactions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.DeploymentService/ListHostLicenseTransactions',
    request,
    metadata || {},
    this.methodDescriptorListHostLicenseTransactions);
  }

  methodDescriptorDeleteHostLicense = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.DeploymentService/DeleteHostLicense',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_deployment_api_pb.DeleteHostLicense.Request,
    galaxycompletepb_apipb_deployment_api_pb.DeleteHostLicense.Response,
    (request: galaxycompletepb_apipb_deployment_api_pb.DeleteHostLicense.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_deployment_api_pb.DeleteHostLicense.Response.deserializeBinary
  );

  deleteHostLicense(
    request: galaxycompletepb_apipb_deployment_api_pb.DeleteHostLicense.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_deployment_api_pb.DeleteHostLicense.Response>;

  deleteHostLicense(
    request: galaxycompletepb_apipb_deployment_api_pb.DeleteHostLicense.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.DeleteHostLicense.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_deployment_api_pb.DeleteHostLicense.Response>;

  deleteHostLicense(
    request: galaxycompletepb_apipb_deployment_api_pb.DeleteHostLicense.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_deployment_api_pb.DeleteHostLicense.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.DeploymentService/DeleteHostLicense',
        request,
        metadata || {},
        this.methodDescriptorDeleteHostLicense,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.DeploymentService/DeleteHostLicense',
    request,
    metadata || {},
    this.methodDescriptorDeleteHostLicense);
  }

}

