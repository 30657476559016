// Project: GalaxyComplete
// Created: 9/27/20 by sammy
// File: CommonActions

import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {ReactElement, ReactNode, useState} from 'react';
import {
    Box, Button, ButtonProps, Chip,
    Dialog,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    SvgIcon, Typography
} from '@mui/material';
import {useDialogState, useShouldDialogFullScreen} from '../../modules/core/dialog/DialogService';
import {HiOutlineDotsVertical} from 'react-icons/hi';
import {GenericActionIcon, LiveLogIcon} from "../CommonIcons";
import {CloseDialogButton} from "../../modules/core/dialog/DialogComponents";
import {useIsSupportUser} from "../../modules/support/SupportCommon";


export interface ActionConfig {
    id: string;
    name: string;
    action: (() => Promise<any>) | (() => void);

    desc?: string;
    disabled?: boolean;
    hidden?: boolean;
    icon?: React.ReactNode;
    buttonProps?: Partial<ButtonProps>,
    supportOnly?: boolean,
    supportAndUser?: boolean
}


// ======================
// ActionMenuButton
// ======================
interface ActionMenuButtonProps {
    actions: ActionConfig[],
    title?: string,
    buttonProps?: Partial<ButtonProps>
    buttonLabel?: string;
    icon?: ReactNode;
}

export const ActionMenuButton: React.FC<ActionMenuButtonProps> = observer((p) => {
    const dialogState = useDialogState();
    const fullScreen = useShouldDialogFullScreen();
    const isSupportUser = useIsSupportUser();

    const hasNoActions = p.actions.filter(a => !a.hidden).length === 0;
    const supportChip = <Chip variant={'outlined'} color={'primary'} size={'small'} label={'Support'}/>


    const dialog = <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullScreen={fullScreen} maxWidth={'xs'}
                           fullWidth>
        <List>
            <Box display={'flex'} justifyContent={'space-between'}>
                <ListSubheader>
                    {p.title ? p.title : 'Actions'}
                </ListSubheader>
                {fullScreen &&
                    <CloseDialogButton dialogState={dialogState} size={'small'}/>
                }

            </Box>
            <Divider/>
            {p.actions.filter(a => !a.hidden).length === 0 &&
                <Box p={2}>
                    <Typography variant={'body1'}>No Actions</Typography>
                </Box>
            }
            {p.actions.filter(a => {
                if (isSupportUser){
                    return !a.hidden
                }
                else {
                    return !a.hidden && !a.supportOnly
                }
            }).map((a, i) => {
                return <ListItem button key={a.id || i} disabled={!!a.disabled} onClick={async () => {
                    await a.action();
                    dialogState.close();
                }
                }>
                    <ListItemIcon>
                        {a.icon ? a.icon : <GenericActionIcon/>}
                    </ListItemIcon>
                    <ListItemText primary={<Box display={'flex'} justifyContent={'space-between'}>
                        <Typography>
                            {a.name}
                        </Typography>
                        {a.supportOnly &&
                            supportChip
                        }
                        {a.supportAndUser && isSupportUser &&
                            supportChip
                        }
                    </Box>
                    }/>
                </ListItem>
            })}
        </List>
    </Dialog>;
    const button = !p.buttonProps ? <IconButton onClick={dialogState.open} disabled={hasNoActions}>
        <SvgIcon>
            {!!p.icon ? p.icon : <HiOutlineDotsVertical/>}
        </SvgIcon>
    </IconButton> : <Button onClick={dialogState.open} {...p.buttonProps} disabled={hasNoActions}
    >{p.buttonLabel}</Button>

    return (<>
        {button}

        {dialog}
    </>);
});
