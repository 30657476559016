import {useAppServices} from "../app/services";

export const useIsLicenseActivated = () => {
    const {privateEditionService} = useAppServices();

    if (privateEditionService.privateEditionStatus.ready){
        return privateEditionService.privateEditionStatus.data.status.licenseActivated
    }

    return false;
}

export const useHasPendingLicenseRequest = () => {
    const {privateEditionService} = useAppServices();

    if (privateEditionService.pendingLicenseRequest.ready){
        return !!privateEditionService.pendingLicenseRequest.data.hasGeneratedRequest();
    }

    return false;
}