import * as React from "react";
import {observer} from "mobx-react-lite";
import {useAppServices} from "../app/services";
import {useInitData} from "../core/data/DataLoaderHooks";
import {PROJECT_SUBROUTE} from "../app/AppRoutes";
import {getOsDisplayName, HostOSIcon, useNavigateToProjectDetails} from "../project/ProjectCommon";
import {ScreenContainer, ScreenTitleBar} from "../layout/ScreenCommon";
import {Box, Button, Card, Chip, Grid, Link, SvgIcon, Tooltip, Typography} from "@mui/material";
import {ProjectCard, ProjectListTable} from "../project/ProjectListScreen";
import {TabConfig, TabGroup} from "../../common/tabs/TabComponents";
import {ProjectListItem} from "../../_proto/galaxycompletepb/apipb/domainpb/project_pb";
import {useIsDesktop} from "../layout/MainLayout";
import {useCallback} from "react";

import {MdOutlineTimer, MdPersonOutline} from "react-icons/md";
import {AiOutlineCloudServer} from "react-icons/ai";
import {getHostEnvDisplayName} from "../galaxymigrate/GalaxyMigrateCommon";
import {HostEnvironmentIcon} from "../../common/CommonIcons";
import {convertTimestampObjectToDate, formatKnownDataType, KnownDataType} from "../../common/utils/formatter";

interface SupportProjectListScreenProps {
}

export const SupportProjectListScreen: React.FC<SupportProjectListScreenProps> = observer((p) => {
    const {supportService, projectService, appGlobalService} = useAppServices();
    const poll = async() => {
        await supportService.mySupportProjects.fetchData();
        await supportService.supportableProjects.fetchData();
    }
    useInitData({
        poll: poll,
        pollInterval: 10,
        init: () => {
            projectService.clearCurrentProject();
            appGlobalService.setAppSubmenu(PROJECT_SUBROUTE.MANAGEMENT);
        },
    });

    const tabConfigs: TabConfig[] = [
        {
            label: 'My Support Projects',
            renderer: () => <Box pt={2}>
                <ProjectListTable
                serverData={supportService.mySupportProjects}
                listComponent={ProjectCard}
                supportProjects={true}/>
            </Box>
        },
        {
            label: 'Join Available Projects',
            renderer: () => <Box pt={2}>
                <ProjectListTable
                serverData={supportService.supportableProjects}
                listComponent={SupportableProjectCard}
                supportProjects={true}/>
            </Box>
        }
    ]

    return (<ScreenContainer>
        <ScreenTitleBar title={`Support Projects`}/>
        <TabGroup configs={tabConfigs}/>
    </ScreenContainer>);
});

// ======================
// SupportableProjectCard
// ======================

interface SupportableProjectCardProps{
    data: ProjectListItem.AsObject;
}

export const SupportableProjectCard: React.FC<SupportableProjectCardProps> = observer((p)=>{
    const isDesktop = useIsDesktop();

    return <Box pt={2}>
        <Card>
            <Box p={2}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item xs={12} md={9}>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box pr={1}>
                                <Typography variant={'h6'}>{p.data.projectInfo.name}</Typography>
                            </Box>
                            <Tooltip arrow title={`${p.data.totalMembers} Project Members`}>
                                <Box display={'flex'} alignItems={'center'}>
                                    <MdPersonOutline/>
                                    {p.data.totalMembers}
                                </Box>
                            </Tooltip>
                        </Box>
                        <Box pt={1}>
                            <Typography variant={'body2'} color={'textSecondary'}>
                                {p.data.projectInfo.description}
                            </Typography>
                        </Box>
                        <Grid container pt={1} alignItems={'center'}>
                            <Grid item alignItems={'center'}>
                                <Tooltip title={<Box>
                                    <Box display={'flex'}>
                                        <Typography variant={'caption'} color={'textSecondary'}>Total Hosts
                                            Registered:&nbsp;</Typography>
                                        <Typography variant={'caption'}>{p.data.totalDeployments}</Typography>
                                    </Box>
                                    <Box display={'flex'}>
                                        <Typography variant={'caption'} color={'textSecondary'}>Offline
                                            Hosts:&nbsp;</Typography>
                                        <Typography variant={'caption'}>{p.data.totalOfflineDeployments}</Typography>
                                    </Box>
                                </Box>} arrow>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Box pr={1} pt={1}>
                                            <SvgIcon>
                                                <AiOutlineCloudServer/>
                                            </SvgIcon>
                                        </Box>
                                        <Box>
                                            <Typography
                                                color={'textPrimary'}>{p.data.totalDeployments} Host{(p.data.totalDeployments !== 1) ? 's' : ''}</Typography>
                                        </Box>
                                        {!!p.data.totalOfflineDeployments &&
                                            <Box>
                                                <Typography
                                                    color={'textSecondary'}>&nbsp;({p.data.totalOfflineDeployments} Offline)</Typography>
                                            </Box>
                                        }
                                    </Box>
                                </Tooltip>
                            </Grid>
                            {!!p.data.gmOsTypesList.length &&
                                <Grid item alignItems={'center'}>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Box pr={2} pl={2}>
                                            <Typography color={'textSecondary'}>|</Typography>
                                        </Box>
                                        <Tooltip title={<Box>
                                            <Typography variant={'caption'}>OS</Typography>
                                            <br/>
                                            <Typography variant={'caption'} color={'textSecondary'}>
                                                {p.data.gmOsTypesList.map(os => getOsDisplayName(os)).join(', ')}
                                            </Typography>
                                        </Box>}>
                                            <Box display={'flex'} alignItems={'center'}>
                                                {
                                                    p.data.gmOsTypesList.map((os, i) => {
                                                        return <Box key={i} pr={1}>
                                                            <HostOSIcon os={os}/>
                                                        </Box>;
                                                    })
                                                }
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                            }
                            {!!p.data.deploymentHostEnvironmentsList.length &&
                                <Grid item alignItems={'center'}>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Box pr={2} pl={2}>
                                            <Typography color={'textSecondary'}>|</Typography>
                                        </Box>
                                        <Tooltip title={<Box>
                                            <Typography variant={'caption'}>Host Environments</Typography>
                                            <br/>
                                            <Typography variant={'caption'} color={'textSecondary'}>
                                                {p.data.deploymentHostEnvironmentsList.map(env => getHostEnvDisplayName(env)).join(', ')}
                                            </Typography>
                                        </Box>}>
                                            <Box display={'flex'} alignItems={'center'}>
                                                {
                                                    p.data.deploymentHostEnvironmentsList.map((env, i) => {
                                                        return <Box key={i} pr={1} pt={1}>
                                                            <HostEnvironmentIcon env={env}/>
                                                        </Box>;
                                                    })
                                                }
                                            </Box>
                                        </Tooltip>
                                    </Box>

                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} >
                        {isDesktop &&
                            <Box display={'flex'} justifyContent={'flex-end'} width={'100%'} pb={1}>
                                <JoinProjectButton projectId={p.data.projectInfo.projectId} projectName={p.data.projectInfo.name}/>
                            </Box>
                        }

                        <Box display={'flex'} justifyContent={isDesktop ? 'flex-end' : 'flex-start'} alignItems={'center'}>
                            <Box pr={1} pt={1}>
                                <SvgIcon>
                                    <MdOutlineTimer/>
                                </SvgIcon>
                            </Box>
                            <Box>
                                <Tooltip arrow
                                         title={formatKnownDataType(convertTimestampObjectToDate(p.data.projectInfo.createdAt), KnownDataType.DATE)}>
                                    <Typography color={'textSecondary'}>
                                        Created&nbsp;
                                        {formatKnownDataType(convertTimestampObjectToDate(p.data.projectInfo.createdAt), KnownDataType.DATE_RELATIVE)}
                                    </Typography>
                                </Tooltip>

                            </Box>
                        </Box>
                        {!isDesktop &&
                            <Box display={'flex'} justifyContent={'center'} width={'100%'} pb={1}>
                                <JoinProjectButton projectId={p.data.projectInfo.projectId} projectName={p.data.projectInfo.name}/>
                            </Box>
                        }
                    </Grid>
                </Grid>

            </Box>

        </Card>
    </Box>;
})

// ======================
// JoinProjectButton
// ======================

interface JoinProjectButtonProps{
    projectId: string,
    projectName: string
}

const JoinProjectButton: React.FC<JoinProjectButtonProps> = observer((p)=>{
    const { supportService, dialogService } = useAppServices();
    const navToProject = useNavigateToProjectDetails(p.projectId)
    const joinProject = useCallback(async() => {
        const confirmed = await dialogService.addConfirmDialog({
            title: 'Join Project as a Support Representative',
            message: <Box pb={2}>
                By continuing, you will be joining project <em>{p.projectName}</em> as a Support Representative and all existing project members will receive an email notification.
            </Box>,
            autoConfirmationQuestionLine: true
        })
        if (confirmed){
            await supportService.joinProjectAsSupport(p.projectId);
            navToProject()
        }
    }, [supportService, dialogService, navToProject, p.projectId, p.projectName])
return <Button variant={'contained'} color={'secondary'} onClick={joinProject}>Join</Button>

})