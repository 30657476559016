import { observer } from "mobx-react-lite";
import React from "react";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { renderServerDataWithLoadingBox, useInitData } from "../core/data/DataLoaderHooks";
import { useAppServices } from "../app/services";
import { IntegrationConfigInfo, IntegrationModule } from "../../_proto/galaxycompletepb/apipb/domainpb/integration_pb";
import {
    getIntegrationCategoryDisplayName,
    getIntegrationModuleDefs,
    getModuleDefByModule,
    IntegrationCard,
    IntegrationCategory,
    useNavigateToEditIntegrationForm,
    useNavigateToEnabledIntegrations,
} from "./IntegrationsCommon";
import { ActionConfig } from "../../common/actions/CommonActions";
import { DeleteIcon, EditIcon } from "../../common/CommonIcons";
import { IntegrationsIcon } from "../project/ProjectCommon";
import { useDialogState } from "../core/dialog/DialogService";
import { TestIntegrationDialog } from "./EnabledIntegrationsDetails";
import { GalaxyMigrateAutoAllocationProgressDialog } from "../migration/autoallocation/GmAutoAllocationProgress";
import { GetAllProjectIntegrations } from "../../_proto/galaxycompletepb/apipb/integration_api_pb";
import { Box, Grid, Typography } from "@mui/material";
import { IntegrationsGoodiesSection } from "./ProjectIntegrationsScreens";
import { useIsOperatorView } from "../auth/AuthenticatedViews";
import { useIsDesktop } from "../layout/MainLayout";

// ======================
// EnabledIntegrations
// ======================

interface EnabledIntegrationsProps {}

export const EnabledIntegrationsList: React.FC<EnabledIntegrationsProps> = observer((p) => {
    const { integrationsService, dialogService } = useAppServices();
    const goBackToEnabledIntegrations = useNavigateToEnabledIntegrations();
    const goToEditForm = useNavigateToEditIntegrationForm();

    const testIntegrationDialogState = useDialogState();

    const refresh = () => integrationsService.projectIntegrations.fetchData();

    const isOperator = useIsOperatorView();

    useInitData({
        poll: refresh,
        pollInterval: 3,
        deinit: () => integrationsService.projectIntegrations.resetData(),
    });

    const cardActionsGetter = (i: IntegrationConfigInfo): ActionConfig[] => [
        {
            id: "edit",
            name: "Edit Integration",
            icon: <EditIcon />,
            action: async () => {
                await integrationsService.currentIntegration.fetchData(i.getId());
                integrationsService.initEditIntegrationState();
                goToEditForm(i.getId().toString());
            },
            hidden: !isOperator,
        },
        {
            id: "test",
            name: "Test Integration",
            action: () => {
                testIntegrationDialogState.setDialogProps({ id: i.getId() });
                testIntegrationDialogState.open();
            },
            icon: <IntegrationsIcon />,
            hidden: !isOperator,
        },
        {
            id: "remove",
            name: "Remove Integration",
            icon: <DeleteIcon />,
            action: async () => {
                const confirmed = await dialogService.addConfirmDialog({
                    message: "Are you sure you want to remove this integration?",
                    autoConfirmationQuestionLine: false,
                });
                if (confirmed) {
                    await integrationsService.removeProjectIntegration(i.getId());
                    await integrationsService.projectIntegrations.fetchData();
                    goBackToEnabledIntegrations();
                }
            },
            hidden: !isOperator,
        },
    ];

    return (
        <ScreenContainer>
            <ScreenTitleBar title={"Enabled Integrations"} />
            {renderServerDataWithLoadingBox(integrationsService.projectIntegrations, (data: GetAllProjectIntegrations.Response) => {
                const publicCloudIntList = [
                    IntegrationModule.AWS,
                    IntegrationModule.AZURE,
                    IntegrationModule.GCP,
                    IntegrationModule.ORACLE,
                    IntegrationModule.DIGITALOCEAN,
                ];

                const iscsiIntList = [
                    IntegrationModule.PURE,
                    IntegrationModule.DGS,
                    IntegrationModule.NETAPP,
                    IntegrationModule.AWS_FSX_NETAPP,
                    IntegrationModule.POWERSTORE,
                    IntegrationModule.SILKSDP,
                ];

                const fcIntList = [IntegrationModule.FC_POWERMAX, IntegrationModule.FC_PURE, IntegrationModule.FC_POWERSTORE];

                const alertIntegrations = getIntegrationModuleDefs().filter((i) => i.category === IntegrationCategory.ALERT);
                const publicCloudIntegrations = data.getItemsList().filter((i) => publicCloudIntList.includes(i.getModule()));
                const iscsiStorageArrayIntegrations = data.getItemsList().filter((i) => iscsiIntList.includes(i.getModule()));
                const fcStorageArrayIntegrations = data.getItemsList().filter((i) => fcIntList.includes(i.getModule()));

                return (
                    <>
                        <IntegrationsGoodiesSection title={getIntegrationCategoryDisplayName(IntegrationCategory.ALERT)} integrationsList={alertIntegrations} />
                        <EnabledIntegrationsSection
                            title={getIntegrationCategoryDisplayName(IntegrationCategory.PUBLIC_CLOUD)}
                            integrationsList={publicCloudIntegrations}
                            actionsGetter={cardActionsGetter}
                        />
                        <EnabledIntegrationsSection
                            title={getIntegrationCategoryDisplayName(IntegrationCategory.ISCSI_STORAGE_ARRAY)}
                            integrationsList={iscsiStorageArrayIntegrations}
                            actionsGetter={cardActionsGetter}
                        />
                        <EnabledIntegrationsSection
                            title={getIntegrationCategoryDisplayName(IntegrationCategory.FC_STORAGE_ARRAY)}
                            integrationsList={fcStorageArrayIntegrations}
                            actionsGetter={cardActionsGetter}
                        />
                    </>
                );
            })}
            <TestIntegrationDialog dialogState={testIntegrationDialogState} />
            <GalaxyMigrateAutoAllocationProgressDialog actionLabel={"Close"} />
        </ScreenContainer>
    );
});

// ======================
// IntegrationsSection
// ======================

interface EnabledIntegrationsSectionProps {
    title: string;
    integrationsList: IntegrationConfigInfo[];
    actionsGetter?: (i: IntegrationConfigInfo) => ActionConfig[];
}

const EnabledIntegrationsSection: React.FC<EnabledIntegrationsSectionProps> = observer((p) => {
    const isDesktop = useIsDesktop();
    const numOfIntegrations = p.integrationsList.length;
    if (numOfIntegrations > 0) {
        return (
            <Box pb={3}>
                <>
                    <Typography variant={"h6"}>{`${p.title} (${numOfIntegrations})`}</Typography>
                    <br />
                </>

                <Grid container spacing={2}>
                    {p.integrationsList.map((i, index) => {
                        return (
                            <Grid item key={index} xs={isDesktop ? false : 12}>
                                <IntegrationCard
                                    cardActionsGetter={p.actionsGetter}
                                    module={getModuleDefByModule(i.getModule())}
                                    instance={i}
                                    cardProps={{ sx: { width: isDesktop ? 325 : "100%" } }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        );
    }
    return null;
});
