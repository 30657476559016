// source: galaxycompletepb/apipb/domainpb/compute.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
goog.exportSymbol('proto.galaxycomplete.domain.ComputeMigrationSpec', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ComputeMigrationSpec.Azure', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ComputeMigrationSpec.SpecCase', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ComputeResourceSelection', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ComputeResourceSelection.Azure', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GmHelperNodeInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GmHelperNodeInfo.Azure', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ComputeMigrationSpec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.galaxycomplete.domain.ComputeMigrationSpec.oneofGroups_);
};
goog.inherits(proto.galaxycomplete.domain.ComputeMigrationSpec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ComputeMigrationSpec.displayName = 'proto.galaxycomplete.domain.ComputeMigrationSpec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.displayName = 'proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.displayName = 'proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.ComputeMigrationSpec.Azure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.displayName = 'proto.galaxycomplete.domain.ComputeMigrationSpec.Azure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.displayName = 'proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GmHelperNodeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.GmHelperNodeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GmHelperNodeInfo.displayName = 'proto.galaxycomplete.domain.GmHelperNodeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.displayName = 'proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.GmHelperNodeInfo.Azure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.displayName = 'proto.galaxycomplete.domain.GmHelperNodeInfo.Azure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ComputeResourceSelection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.ComputeResourceSelection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ComputeResourceSelection.displayName = 'proto.galaxycomplete.domain.ComputeResourceSelection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.ComputeResourceSelection.Azure.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.ComputeResourceSelection.Azure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ComputeResourceSelection.Azure.displayName = 'proto.galaxycomplete.domain.ComputeResourceSelection.Azure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup.displayName = 'proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.displayName = 'proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.displayName = 'proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.displayName = 'proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.displayName = 'proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.SpecCase = {
  SPEC_NOT_SET: 0,
  VMWARE_SPEC: 1,
  AZURE_SPEC: 2
};

/**
 * @return {proto.galaxycomplete.domain.ComputeMigrationSpec.SpecCase}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.prototype.getSpecCase = function() {
  return /** @type {proto.galaxycomplete.domain.ComputeMigrationSpec.SpecCase} */(jspb.Message.computeOneofCase(this, proto.galaxycomplete.domain.ComputeMigrationSpec.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ComputeMigrationSpec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.toObject = function(includeInstance, msg) {
  var f, obj = {
    vmwareSpec: (f = msg.getVmwareSpec()) && proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.toObject(includeInstance, f),
    azureSpec: (f = msg.getAzureSpec()) && proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ComputeMigrationSpec;
  return proto.galaxycomplete.domain.ComputeMigrationSpec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware;
      reader.readMessage(value,proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.deserializeBinaryFromReader);
      msg.setVmwareSpec(value);
      break;
    case 2:
      var value = new proto.galaxycomplete.domain.ComputeMigrationSpec.Azure;
      reader.readMessage(value,proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.deserializeBinaryFromReader);
      msg.setAzureSpec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ComputeMigrationSpec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVmwareSpec();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.serializeBinaryToWriter
    );
  }
  f = message.getAzureSpec();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.toObject = function(includeInstance, msg) {
  var f, obj = {
    vmName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vmDescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    datastore: jspb.Message.getFieldWithDefault(msg, 5, ""),
    vmFolder: jspb.Message.getFieldWithDefault(msg, 6, ""),
    resourcePool: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cpuCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    cpuCoresPerSocket: jspb.Message.getFieldWithDefault(msg, 11, 0),
    memoryMib: jspb.Message.getFieldWithDefault(msg, 12, 0),
    networksList: jspb.Message.toObjectList(msg.getNetworksList(),
    proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware;
  return proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVmName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVmDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatastore(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVmFolder(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourcePool(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCpuCount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCpuCoresPerSocket(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMemoryMib(value);
      break;
    case 20:
      var value = new proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network;
      reader.readMessage(value,proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.deserializeBinaryFromReader);
      msg.addNetworks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVmName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVmDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDatastore();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVmFolder();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getResourcePool();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCpuCount();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getCpuCoresPerSocket();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getMemoryMib();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getNetworksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.toObject = function(includeInstance, msg) {
  var f, obj = {
    networkName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customMacAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adapterType: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network;
  return proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetworkName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomMacAddress(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdapterType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNetworkName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomMacAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdapterType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string network_name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.prototype.getNetworkName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.prototype.setNetworkName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string custom_mac_address = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.prototype.getCustomMacAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.prototype.setCustomMacAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string adapter_type = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.prototype.getAdapterType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network.prototype.setAdapterType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string vm_name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.getVmName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.setVmName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vm_description = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.getVmDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.setVmDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string datastore = 5;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.getDatastore = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.setDatastore = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string vm_folder = 6;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.getVmFolder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.setVmFolder = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string resource_pool = 7;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.getResourcePool = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.setResourcePool = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 cpu_count = 10;
 * @return {number}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.getCpuCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.setCpuCount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 cpu_cores_per_socket = 11;
 * @return {number}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.getCpuCoresPerSocket = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.setCpuCoresPerSocket = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 memory_mib = 12;
 * @return {number}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.getMemoryMib = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.setMemoryMib = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated Network networks = 20;
 * @return {!Array<!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network>}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.getNetworksList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network, 20));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network>} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware} returns this
*/
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.setNetworksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.addNetworks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.Network, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware.prototype.clearNetworksList = function() {
  return this.setNetworksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hostDescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    resourceGroupName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    machineType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    networksList: jspb.Message.toObjectList(msg.getNetworksList(),
    proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ComputeMigrationSpec.Azure;
  return proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceGroupName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMachineType(value);
      break;
    case 20:
      var value = new proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network;
      reader.readMessage(value,proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.deserializeBinaryFromReader);
      msg.addNetworks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHostDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResourceGroupName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMachineType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNetworksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.toObject = function(includeInstance, msg) {
  var f, obj = {
    networkInterfaceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createPublicIp: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    subnetId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    networkSecurityGroupId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    primary: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network;
  return proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetworkInterfaceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreatePublicIp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubnetId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetworkSecurityGroupId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrimary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNetworkInterfaceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatePublicIp();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSubnetId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNetworkSecurityGroupId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrimary();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string network_interface_id = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.prototype.getNetworkInterfaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.prototype.setNetworkInterfaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool create_public_ip = 3;
 * @return {boolean}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.prototype.getCreatePublicIp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.prototype.setCreatePublicIp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string subnet_id = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.prototype.getSubnetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.prototype.setSubnetId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string network_security_group_id = 5;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.prototype.getNetworkSecurityGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.prototype.setNetworkSecurityGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool primary = 6;
 * @return {boolean}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.prototype.getPrimary = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network.prototype.setPrimary = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string host_name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.prototype.getHostName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.prototype.setHostName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string host_description = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.prototype.getHostDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.prototype.setHostDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string resource_group_name = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.prototype.getResourceGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.prototype.setResourceGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string machine_type = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.prototype.getMachineType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.prototype.setMachineType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Network networks = 20;
 * @return {!Array<!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network>}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.prototype.getNetworksList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network, 20));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network>} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure} returns this
*/
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.prototype.setNetworksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.prototype.addNetworks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.Network, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec.Azure} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.Azure.prototype.clearNetworksList = function() {
  return this.setNetworksList([]);
};


/**
 * optional Vmware vmware_spec = 1;
 * @return {?proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.prototype.getVmwareSpec = function() {
  return /** @type{?proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.ComputeMigrationSpec.Vmware|undefined} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec} returns this
*/
proto.galaxycomplete.domain.ComputeMigrationSpec.prototype.setVmwareSpec = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.galaxycomplete.domain.ComputeMigrationSpec.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.prototype.clearVmwareSpec = function() {
  return this.setVmwareSpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.prototype.hasVmwareSpec = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Azure azure_spec = 2;
 * @return {?proto.galaxycomplete.domain.ComputeMigrationSpec.Azure}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.prototype.getAzureSpec = function() {
  return /** @type{?proto.galaxycomplete.domain.ComputeMigrationSpec.Azure} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.ComputeMigrationSpec.Azure, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.ComputeMigrationSpec.Azure|undefined} value
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec} returns this
*/
proto.galaxycomplete.domain.ComputeMigrationSpec.prototype.setAzureSpec = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.galaxycomplete.domain.ComputeMigrationSpec.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ComputeMigrationSpec} returns this
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.prototype.clearAzureSpec = function() {
  return this.setAzureSpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ComputeMigrationSpec.prototype.hasAzureSpec = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GmHelperNodeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GmHelperNodeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GmHelperNodeInfo;
  return proto.galaxycomplete.domain.GmHelperNodeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GmHelperNodeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GmHelperNodeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GmHelperNodeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.toObject = function(includeInstance, msg) {
  var f, obj = {
    vmName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vmPath: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vmFile: jspb.Message.getFieldWithDefault(msg, 3, ""),
    virtualMachineUuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    resourcePool: jspb.Message.getFieldWithDefault(msg, 5, ""),
    datacenter: jspb.Message.getFieldWithDefault(msg, 6, ""),
    vmdkCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    hostSystem: jspb.Message.getFieldWithDefault(msg, 8, ""),
    systemId: jspb.Message.getFieldWithDefault(msg, 100, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware;
  return proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVmName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVmPath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVmFile(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setVirtualMachineUuid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourcePool(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatacenter(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVmdkCount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostSystem(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVmName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVmPath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVmFile();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVirtualMachineUuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getResourcePool();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDatacenter();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getVmdkCount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getHostSystem();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
};


/**
 * optional string vm_name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.getVmName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.setVmName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vm_path = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.getVmPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.setVmPath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vm_file = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.getVmFile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.setVmFile = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string virtual_machine_uuid = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.getVirtualMachineUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.setVirtualMachineUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string resource_pool = 5;
 * @return {string}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.getResourcePool = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.setResourcePool = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string datacenter = 6;
 * @return {string}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.getDatacenter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.setDatacenter = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 vmdk_count = 7;
 * @return {number}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.getVmdkCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.setVmdkCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string host_system = 8;
 * @return {string}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.getHostSystem = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.setHostSystem = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string system_id = 100;
 * @return {string}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Vmware.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.toObject = function(includeInstance, msg) {
  var f, obj = {
    hostName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    integrationId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resourceGroupName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    location: jspb.Message.getFieldWithDefault(msg, 4, ""),
    zones: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ultraSsdEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    systemId: jspb.Message.getFieldWithDefault(msg, 100, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.GmHelperNodeInfo.Azure;
  return proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntegrationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceGroupName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setZones(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUltraSsdEnabled(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHostName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntegrationId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getResourceGroupName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getZones();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUltraSsdEnabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
};


/**
 * optional string host_name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.getHostName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.setHostName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 integration_id = 2;
 * @return {number}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.getIntegrationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string resource_group_name = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.getResourceGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.setResourceGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string location = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string zones = 5;
 * @return {string}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.getZones = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.setZones = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool ultra_ssd_enabled = 6;
 * @return {boolean}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.getUltraSsdEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.setUltraSsdEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string system_id = 100;
 * @return {string}
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.GmHelperNodeInfo.Azure} returns this
 */
proto.galaxycomplete.domain.GmHelperNodeInfo.Azure.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ComputeResourceSelection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeResourceSelection.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ComputeResourceSelection;
  return proto.galaxycomplete.domain.ComputeResourceSelection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ComputeResourceSelection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeResourceSelection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ComputeResourceSelection.Azure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceGroupsList: jspb.Message.toObjectList(msg.getResourceGroupsList(),
    proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup.toObject, includeInstance),
    machineTypesList: jspb.Message.toObjectList(msg.getMachineTypesList(),
    proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.toObject, includeInstance),
    networkInterfacesList: jspb.Message.toObjectList(msg.getNetworkInterfacesList(),
    proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.toObject, includeInstance),
    securityGroupsList: jspb.Message.toObjectList(msg.getSecurityGroupsList(),
    proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.toObject, includeInstance),
    subnetsList: jspb.Message.toObjectList(msg.getSubnetsList(),
    proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ComputeResourceSelection.Azure;
  return proto.galaxycomplete.domain.ComputeResourceSelection.Azure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup;
      reader.readMessage(value,proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup.deserializeBinaryFromReader);
      msg.addResourceGroups(value);
      break;
    case 2:
      var value = new proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType;
      reader.readMessage(value,proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.deserializeBinaryFromReader);
      msg.addMachineTypes(value);
      break;
    case 3:
      var value = new proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface;
      reader.readMessage(value,proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.deserializeBinaryFromReader);
      msg.addNetworkInterfaces(value);
      break;
    case 4:
      var value = new proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup;
      reader.readMessage(value,proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.deserializeBinaryFromReader);
      msg.addSecurityGroups(value);
      break;
    case 5:
      var value = new proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet;
      reader.readMessage(value,proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.deserializeBinaryFromReader);
      msg.addSubnets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ComputeResourceSelection.Azure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup.serializeBinaryToWriter
    );
  }
  f = message.getMachineTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.serializeBinaryToWriter
    );
  }
  f = message.getNetworkInterfacesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.serializeBinaryToWriter
    );
  }
  f = message.getSecurityGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.serializeBinaryToWriter
    );
  }
  f = message.getSubnetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup;
  return proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cpu: jspb.Message.getFieldWithDefault(msg, 2, 0),
    memoryMb: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxDataDisks: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType;
  return proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCpu(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMemoryMb(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxDataDisks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCpu();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMemoryMb();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMaxDataDisks();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 cpu = 2;
 * @return {number}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.prototype.getCpu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.prototype.setCpu = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 memory_mb = 3;
 * @return {number}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.prototype.getMemoryMb = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.prototype.setMemoryMb = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 max_data_disks = 4;
 * @return {number}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.prototype.getMaxDataDisks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType.prototype.setMaxDataDisks = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface;
  return proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup;
  return proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cidrBlock: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet;
  return proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCidrBlock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCidrBlock();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cidr_block = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.prototype.getCidrBlock = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet.prototype.setCidrBlock = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated ResourceGroup resource_groups = 1;
 * @return {!Array<!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup>}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.getResourceGroupsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup, 1));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup>} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure} returns this
*/
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.setResourceGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.addResourceGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxycomplete.domain.ComputeResourceSelection.Azure.ResourceGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.clearResourceGroupsList = function() {
  return this.setResourceGroupsList([]);
};


/**
 * repeated MachineType machine_types = 2;
 * @return {!Array<!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType>}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.getMachineTypesList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType>} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure} returns this
*/
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.setMachineTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.addMachineTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.ComputeResourceSelection.Azure.MachineType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.clearMachineTypesList = function() {
  return this.setMachineTypesList([]);
};


/**
 * repeated NetworkInterface network_interfaces = 3;
 * @return {!Array<!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface>}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.getNetworkInterfacesList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface, 3));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface>} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure} returns this
*/
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.setNetworkInterfacesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.addNetworkInterfaces = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkInterface, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.clearNetworkInterfacesList = function() {
  return this.setNetworkInterfacesList([]);
};


/**
 * repeated NetworkSecurityGroup security_groups = 4;
 * @return {!Array<!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup>}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.getSecurityGroupsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup, 4));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup>} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure} returns this
*/
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.setSecurityGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.addSecurityGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.galaxycomplete.domain.ComputeResourceSelection.Azure.NetworkSecurityGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.clearSecurityGroupsList = function() {
  return this.setSecurityGroupsList([]);
};


/**
 * repeated Subnet subnets = 5;
 * @return {!Array<!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet>}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.getSubnetsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet, 5));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet>} value
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure} returns this
*/
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.setSubnetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet}
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.addSubnets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.galaxycomplete.domain.ComputeResourceSelection.Azure.Subnet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.ComputeResourceSelection.Azure} returns this
 */
proto.galaxycomplete.domain.ComputeResourceSelection.Azure.prototype.clearSubnetsList = function() {
  return this.setSubnetsList([]);
};


goog.object.extend(exports, proto.galaxycomplete.domain);
