// ======================
// CreateNewIntegration
// ======================

import {observer} from "mobx-react-lite";
import {
    getIntegrationTypeFromRoute, IntegrationRedirectAlert, INTEGRATIONS_TYPE_SUBROUTE,
    useNavigateToEnabledIntegrations,
    useNavigateToIntegrations
} from "../IntegrationsCommon";
import {useParams} from "react-router-dom";
import {useAppServices} from "../../app/services";
import {IntegrationForm} from "./IntegrationForm";
import React from "react";
import {IntegrationModule} from "../../../_proto/galaxycompletepb/apipb/domainpb/integration_pb";
import {IntegrationParams} from "../../../_proto/galaxycompletepb/apipb/integration_api_pb";
import {useGmAutoAllocationState} from "../../migration/autoallocation/GmAutoAllocationCommon";
import {v4 as uuid} from 'uuid';

interface CreateNewIntegrationProps {
}

export const CreateNewIntegration: React.FC<CreateNewIntegrationProps> = observer((p) => {
    const goBackToMainIntegrationsScreen = useNavigateToIntegrations();
    const goToEnabledIntegrations = useNavigateToEnabledIntegrations();

    const {integrationType} = useParams();
    const {integrationsService, deploymentService, progressService} = useAppServices();
    const redirectState = integrationsService.integrationRedirectState;
    const autoAllocationState = useGmAutoAllocationState();

    const type = getIntegrationTypeFromRoute(integrationType as INTEGRATIONS_TYPE_SUBROUTE);

    const onSubmit = async (module: IntegrationModule, friendlyName: string, deploymentId: string, connParams?: IntegrationParams.ConnParams, verboseLogging?: boolean) => {
        const statusId = uuid();
        autoAllocationState.setNewStatusId(statusId);
        const deploymentDetails = await deploymentService.fetchDeploymentDetails(deploymentId);
        autoAllocationState.setDeployment(deploymentDetails);
        autoAllocationState.setShowProgressDialog(true);
        await integrationsService.addProjectIntegration(module, friendlyName, deploymentId, statusId, connParams, verboseLogging);
        await autoAllocationState.waitForProgressDialogToClose();
    }

    const onSubmitted = () => {
        if (!!redirectState) {
            redirectState.redirectFunc();
            integrationsService.resetIntegrationRedirectState();
        } else {
            goToEnabledIntegrations()
        }
    }

    return <>
        {!!redirectState &&
        <IntegrationRedirectAlert alertConfig={redirectState.alertConfig} boxProps={{pb: 2, pr: 2, pl: 2}}/>
        }
        <IntegrationForm onCancel={goBackToMainIntegrationsScreen}
                         onSubmit={onSubmit}
                         newIntegrationType={type}
                         onSubmitted={onSubmitted}
        />
    </>
})