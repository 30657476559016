// ======================
// LicenseActivationScreen
// ======================

import {observer} from "mobx-react-lite";
import {ScreenContainer, ScreenTitleBar} from "../../layout/ScreenCommon";
import {Alert, Box, Button, Card, IconButton, Link, SvgIcon, TextField, Typography} from "@mui/material";
import {
    LicenseVaultCounterType
} from "../../../_proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";
import React, {useCallback, useState} from "react";
import {SelectableBox} from "../../../common/card/SelectableCard";
import {getCounterTypeDisplayValue, useCurrentProjectLicenseModel} from "../LicenseCommon";
import {FiMinus} from "react-icons/fi";
import {MdAdd} from "react-icons/md";
import {useNavigateToExternalLink} from "../../help/HelpCommon";
import {ClipboardButton, ClipboardText} from "../../../common/clipboard/ClipboardComponents";
// ======================
// RequestLicenseButton
// ======================
import {useHasPendingLicenseRequest, useIsLicenseActivated} from "../../privateEdition/PrivateEditionCommon";
import {
    useNavigateToLicenseRequestScreen,
    useNavigateToProjectLicenseDetailsScreen
} from "../../management/ProjectManagementCommon";
import {useAppServices} from "../../app/services";
import {TransactionCounterInfo} from "../../../_proto/galaxycompletepb/apipb/domainpb/license_pb";
import {renderServerDataWithLoadingBox, useInitData} from "../../core/data/DataLoaderHooks";
import {LicenseModel} from "../../../_proto/galaxycompletepb/apipb/domainpb/enumpb/license_model_pb";

interface RequestLicenseButtonProps {
}

export const RequestLicenseButton: React.FC<RequestLicenseButtonProps> = observer((p) => {
    const hasPendingLicenseRequest = useHasPendingLicenseRequest();
    const isLicenseActivated = useIsLicenseActivated();
    const navToRequestLicensePage = useNavigateToLicenseRequestScreen();

    if (hasPendingLicenseRequest || !isLicenseActivated) {
        return null;
    }
    return <Button onClick={navToRequestLicensePage} variant={'contained'} color={'secondary'}>
        Request License
    </Button>
})

interface LicenseRequestScreenProps {
}

export const LicenseRequestScreen: React.FC<LicenseRequestScreenProps> = observer((p) => {
        const isLicenseActivated = useIsLicenseActivated();
        const hasPendingLicense = useHasPendingLicenseRequest();
        const licenseModel = useCurrentProjectLicenseModel();
        const [licenseRequested, setLicenseRequested] = useState(hasPendingLicense);
        const {privateEditionService} = useAppServices();

        const getInitialCounterState = () => {
            const counterState = new Map();

            if (licenseModel === LicenseModel.LicenseModel.HOST_BASED){
                counterState.set(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE, 0);
                counterState.set(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION, 0);
                counterState.set(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE30_DAYS_EXTENSION, 0);
                counterState.set(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE60_DAYS_EXTENSION, 0);
            }

            if (licenseModel === LicenseModel.LicenseModel.CAPACITY_BASED){
                counterState.set(LicenseVaultCounterType.LicenseVaultCounterType.GALAXY_MIGRATE_LOCAL_MIGRATION, 0)
            }

            return counterState;
        }
        const [counterState, setCounterState] = React.useState(getInitialCounterState())

        const onChangeQuantity = useCallback((licenseType: LicenseVaultCounterType.LicenseVaultCounterType, newValue: number) => {
            const newCounterState = new Map(counterState.set(licenseType, newValue))
            setCounterState(newCounterState)
        }, [setCounterState, counterState])

        const onLicenseRequested = useCallback(async () => {
            const counters = Array.from(counterState.keys()).map(c => {
                return new TransactionCounterInfo()
                    .setCounterType(new LicenseVaultCounterType().setValue(c))
                    .setAmount(counterState.get(c))
            })
            const requestExtension = !isLicenseActivated
            await privateEditionService.generatedPeLicenseRequest.fetchData(counters, requestExtension)
            setLicenseRequested(true)
        }, [counterState, isLicenseActivated, privateEditionService.generatedPeLicenseRequest])

        return <ScreenContainer>
            <ScreenTitleBar title={isLicenseActivated ? 'Request License' : 'Activate License'}/>
            {!isLicenseActivated &&
                <Box pt={2}>
                    <Typography variant={'h5'}>
                        1. Request Activation License
                    </Typography>
                    <Box pt={1}>
                        <Alert severity={'info'} variant={'outlined'}>
                            You are requesting a CIRRUS DATA CLOUD - PRIVATE EDITION LICENSE. This license will expire in 30
                            days.
                        </Alert>
                    </Box>
                    <br/>
                </Box>
            }

            {!licenseRequested ? <SelectLicenseTypesSection counterState={counterState}
                                                            licenseModel={licenseModel}
                                                            isLicenseActivated={isLicenseActivated}
                                                            onChangeQuantity={onChangeQuantity}
                                                            onLicenseRequested={onLicenseRequested}/>
                :
                <GeneratedLicenseRequestSection counterState={counterState}/>
            }
        </ScreenContainer>
    }
)

// ======================
// SelectLicenseTypesSection
// ======================

interface SelectLicenseTypesSectionProps {
    licenseModel: LicenseModel.LicenseModel;
    isLicenseActivated: boolean;
    onLicenseRequested: () => void;
    counterState: Map<LicenseVaultCounterType.LicenseVaultCounterType, number>;
    onChangeQuantity: (licenseType: LicenseVaultCounterType.LicenseVaultCounterType, newValue: number) => void;
}

export const SelectLicenseTypesSection: React.FC<SelectLicenseTypesSectionProps> = observer((p) => {
    const {counterState, onChangeQuantity, onLicenseRequested, isLicenseActivated, licenseModel} = p;
    const isCounterStateEmpty = !Array.from(counterState.values()).find(v=>v!== 0);

    return <Box pt={2}>

        <Typography variant={'h5'}>
            {!isLicenseActivated ? '2. Select Additional Licenses' : 'Select Licenses'}
        </Typography>
        <Box pt={1}>
            <Typography color={'textSecondary'}>
                Please select from the following licenses to create your activation request. Upon activation, your
                machine
                will store these licenses in the license key to be consumed upon migration.
            </Typography>
        </Box>
        {licenseModel === LicenseModel.LicenseModel.HOST_BASED &&
            <Box pt={2}>
                <Typography variant={'h6'}>
                    Host Migration License
                </Typography>
                <LicenseActivationCounterCard
                    licenseType={LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE}
                    onChangeQuantity={onChangeQuantity}
                    counterState={counterState}/>
                <br/>
                <Typography variant={'h6'}>
                    Migration Capacity Quota Add-On
                </Typography>
                <LicenseActivationCounterCard
                    licenseType={LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION}
                    onChangeQuantity={onChangeQuantity}
                    counterState={counterState}/>
                <br/>
                <Typography variant={'h6'}>
                    Host Migration License Extension
                </Typography>
                <LicenseActivationCounterCard
                    licenseType={LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE30_DAYS_EXTENSION}
                    onChangeQuantity={onChangeQuantity}
                    counterState={counterState}/>
                <LicenseActivationCounterCard
                    licenseType={LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE60_DAYS_EXTENSION}
                    onChangeQuantity={onChangeQuantity}
                    counterState={counterState}/>

            </Box>
        }
        {licenseModel === LicenseModel.LicenseModel.CAPACITY_BASED &&
            <Box pt={2}>
                <LicenseActivationCounterCard licenseType={LicenseVaultCounterType.LicenseVaultCounterType.GALAXY_MIGRATE_LOCAL_MIGRATION}
                                              onChangeQuantity={onChangeQuantity}
                                              counterState={counterState} />
            </Box>
        }
        <Box pt={4} display={'flex'} justifyContent={'center'}>
            <Button disabled={isCounterStateEmpty} variant={'contained'} onClick={onLicenseRequested}>
                Generate Request
            </Button>
        </Box>
    </Box>
})

// ======================
// LicenseActivationCounterCard
// ======================

interface LicenseActivationCounterCardProps {
    licenseType: LicenseVaultCounterType.LicenseVaultCounterType,
    onChangeQuantity: (licenseType: LicenseVaultCounterType.LicenseVaultCounterType, newValue: number) => void;
    counterState: Map<LicenseVaultCounterType.LicenseVaultCounterType, number>
}

export const LicenseActivationCounterCard: React.FC<LicenseActivationCounterCardProps> = observer((p) => {
    const currentQuantity = p.counterState.get(p.licenseType)
    const decreaseQuantity = () => {
        p.onChangeQuantity(p.licenseType, currentQuantity - 1)
    }

    const increaseQuantity = () => {
        p.onChangeQuantity(p.licenseType, currentQuantity + 1)
    }

    return <Box pt={1} pb={1}>
        <SelectableBox selected={currentQuantity > 0} disableHoverEffects>
            <Box p={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Box>
                    <Typography>
                        {getCounterTypeDisplayValue(p.licenseType)}
                    </Typography>
                    <Typography variant={'body2'} color={'textSecondary'}>

                    </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <Box width={'100%'} pr={1}>
                        <IconButton onClick={decreaseQuantity} disabled={currentQuantity === 0}>
                            <SvgIcon>
                                <FiMinus/>
                            </SvgIcon>
                        </IconButton>
                    </Box>
                    <Box width={'100%'}>
                        <TextField inputProps={{
                            style: {textAlign: 'center'},
                            min: 0
                        }}
                                   variant={'filled'}
                                   sx={{width: 50}}
                                   value={currentQuantity}
                                   onChange={(e) => {
                                       if (!!e.target.value) {
                                           p.onChangeQuantity(p.licenseType, parseInt(e.target.value))
                                       } else {
                                           p.onChangeQuantity(p.licenseType, null)
                                       }

                                   }
                                   }
                        />
                    </Box>

                    <Box width={'100%'} pl={1}>
                        <IconButton onClick={increaseQuantity}>
                            <SvgIcon>
                                <MdAdd/>
                            </SvgIcon>
                        </IconButton>
                    </Box>

                </Box>
            </Box>
        </SelectableBox>
    </Box>
})

// ======================
// GeneratedLicenseRequestSection
// ======================

interface GeneratedLicenseRequestSectionProps {
    counterState: Map<LicenseVaultCounterType.LicenseVaultCounterType, number>
}

export const GeneratedLicenseRequestSection: React.FC<GeneratedLicenseRequestSectionProps> = observer((p) => {
    const navigateToCDC = useNavigateToExternalLink('https://cloud.cirrusdata.com/');
    const licenseActivationRequest = 'generatedRequest';
    const {privateEditionService} = useAppServices();
    const [receipt, setReceipt] = useState('')
    const navToLicensePage = useNavigateToProjectLicenseDetailsScreen();
    const isLicenseActivated = useIsLicenseActivated();

    useInitData({
        poll: () => privateEditionService.pendingLicenseRequest.fetchData(),
        pollInterval: 30
    })

    const activateLicense = async () => {
        await privateEditionService.completePeLicenseTransaction(receipt);
        navToLicensePage()
    }

    return renderServerDataWithLoadingBox(privateEditionService.pendingLicenseRequest, data => {
        const serverData = data.toObject();
        return <Box pt={2}>
            <Typography>
                License Request Summary:
            </Typography>
            <br/>
            <Card variant={'outlined'}>
                <Box p={2}>
                    {serverData.generatedRequest.licenseExtension &&
                        <Box>
                            <Typography variant={'overline'} color={'textSecondary'}>
                                {isLicenseActivated ? 'Private Edition Base License 30 Day Extension – 1' : 'Private Edition Base License (30 Days) – 1'}
                            </Typography>
                        </Box>
                    }
                    {serverData.generatedRequest.countersList.map((c: TransactionCounterInfo.AsObject, i) => {
                        return <Box key={i}>
                            <Typography key={i} variant={'overline'} color={'textSecondary'}>
                                {getCounterTypeDisplayValue(c.counterType.value)} – {c.amount}
                            </Typography>
                        </Box>
                    })}
                </Box>
            </Card>
            <br/>
            <Typography variant={'h5'}>
                1. Upload Activation Request on Cirrus Data Cloud (SaaS)
            </Typography>
            <Typography color={'textSecondary'}>
                Copy the generated License Activation Request below, and upload it on Cirrus Data Cloud (SaaS) <Link
                onClick={navigateToCDC}>https://cloud.cirrusdata.com/</Link>
            </Typography>
            <br/>

            <Box pb={2}>
                <Card sx={{backgroundColor: '#000', width: '100%', wordBreak: 'break-word'}}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Box p={2}>
                            <ClipboardText clipboardId={licenseActivationRequest}>
                                {serverData.generatedRequest.signedRequest.toString()}
                            </ClipboardText>
                        </Box>
                        <Box p={2}>
                            <ClipboardButton clipboardId={licenseActivationRequest} iconButton/>
                        </Box>
                    </Box>

                </Card>
            </Box>
            <Typography variant={'h5'}>
                2. Upload License Receipt
            </Typography>
            <Typography color={'textSecondary'}>
                After retrieving the License Receipt from Cirrus Data Cloud (SaaS), please paste it below to complete
                activation.
            </Typography>
            <br/>
            <TextField fullWidth multiline minRows={20} variant={'filled'}
                       label={'License Receipt'}
                       value={receipt}
                       onChange={(e) => {
                           setReceipt(e.target.value)}}/>
            <Box pt={2} pb={2}>
                <Alert severity={'warning'}> Warning: Please DO NOT turn off machine during license request. If you need
                    further assistance, please contact our team for support.</Alert>
            </Box>
            <Typography variant={'caption'} color={'textSecondary'}>
                If you wish to change this request license type or amount, please Discard Request in order to start a
                new License Request.
            </Typography>
            <Box display={'flex'} justifyContent={'center'} pt={2}>
                <Box pr={1}>
                    <Button variant={'outlined'} color={'error'}>Discard Request</Button>
                </Box>
                <Box pl={1}>
                    <Button variant={'contained'} onClick={activateLicense}>Activate License</Button>
                </Box>
            </Box>
        </Box>
    })

})