// source: galaxycompletepb/apipb/domainpb/checklist.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var galaxycompletepb_apipb_domainpb_user_pb = require('../../../galaxycompletepb/apipb/domainpb/user_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_user_pb);
var galaxycompletepb_apipb_domainpb_enumpb_checklist_task_status_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/checklist_task_status_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_checklist_task_status_pb);
goog.exportSymbol('proto.galaxycomplete.domain.ChecklistInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ChecklistInfo.Descriptor', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ChecklistInfo.Task', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Icon', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ChecklistTaskUIAction', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ChecklistInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.ChecklistInfo.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.ChecklistInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ChecklistInfo.displayName = 'proto.galaxycomplete.domain.ChecklistInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.ChecklistInfo.Descriptor.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.ChecklistInfo.Descriptor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ChecklistInfo.Descriptor.displayName = 'proto.galaxycomplete.domain.ChecklistInfo.Descriptor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ChecklistInfo.Task = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.ChecklistInfo.Task, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ChecklistInfo.Task.displayName = 'proto.galaxycomplete.domain.ChecklistInfo.Task';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.displayName = 'proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.displayName = 'proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.displayName = 'proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.ChecklistInfo.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ChecklistInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ChecklistInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ChecklistInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ChecklistInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    checklistId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    checklistDescriptor: (f = msg.getChecklistDescriptor()) && proto.galaxycomplete.domain.ChecklistInfo.Descriptor.toObject(includeInstance, f),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.galaxycomplete.domain.ChecklistInfo.Task.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo}
 */
proto.galaxycomplete.domain.ChecklistInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ChecklistInfo;
  return proto.galaxycomplete.domain.ChecklistInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ChecklistInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo}
 */
proto.galaxycomplete.domain.ChecklistInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChecklistId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = new proto.galaxycomplete.domain.ChecklistInfo.Descriptor;
      reader.readMessage(value,proto.galaxycomplete.domain.ChecklistInfo.Descriptor.deserializeBinaryFromReader);
      msg.setChecklistDescriptor(value);
      break;
    case 10:
      var value = new proto.galaxycomplete.domain.ChecklistInfo.Task;
      reader.readMessage(value,proto.galaxycomplete.domain.ChecklistInfo.Task.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ChecklistInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ChecklistInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ChecklistInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ChecklistInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChecklistId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getChecklistDescriptor();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.galaxycomplete.domain.ChecklistInfo.Descriptor.serializeBinaryToWriter
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.galaxycomplete.domain.ChecklistInfo.Task.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ChecklistInfo.Descriptor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Descriptor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Descriptor}
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ChecklistInfo.Descriptor;
  return proto.galaxycomplete.domain.ChecklistInfo.Descriptor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Descriptor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Descriptor}
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ChecklistInfo.Descriptor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Descriptor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Descriptor} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Descriptor} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Descriptor} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string tags = 4;
 * @return {!Array<string>}
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Descriptor} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Descriptor} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Descriptor} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Descriptor.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ChecklistInfo.Task.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taskDescriptor: (f = msg.getTaskDescriptor()) && proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    finished: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    finishedBy: (f = msg.getFinishedBy()) && galaxycompletepb_apipb_domainpb_user_pb.HubUser.toObject(includeInstance, f),
    finishedAt: (f = msg.getFinishedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    notes: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ChecklistInfo.Task;
  return proto.galaxycomplete.domain.ChecklistInfo.Task.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTaskId(value);
      break;
    case 2:
      var value = new proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor;
      reader.readMessage(value,proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.deserializeBinaryFromReader);
      msg.setTaskDescriptor(value);
      break;
    case 5:
      var value = /** @type {!proto.galaxycomplete.domain.enums.ChecklistTaskStatus.ChecklistTaskStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFinished(value);
      break;
    case 7:
      var value = new galaxycompletepb_apipb_domainpb_user_pb.HubUser;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_user_pb.HubUser.deserializeBinaryFromReader);
      msg.setFinishedBy(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFinishedAt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ChecklistInfo.Task.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTaskDescriptor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getFinished();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getFinishedBy();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      galaxycompletepb_apipb_domainpb_user_pb.HubUser.serializeBinaryToWriter
    );
  }
  f = message.getFinishedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.repeatedFields_ = [5,100];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subtitle: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.toObject, includeInstance),
    icon: jspb.Message.getFieldWithDefault(msg, 50, 0),
    color: jspb.Message.getFieldWithDefault(msg, 51, ""),
    uiActionsList: jspb.Message.toObjectList(msg.getUiActionsList(),
    proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor;
  return proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = new proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link;
      reader.readMessage(value,proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    case 50:
      var value = /** @type {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Icon} */ (reader.readEnum());
      msg.setIcon(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 100:
      var value = new proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction;
      reader.readMessage(value,proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.deserializeBinaryFromReader);
      msg.addUiActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubtitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.serializeBinaryToWriter
    );
  }
  f = message.getIcon();
  if (f !== 0.0) {
    writer.writeEnum(
      50,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getUiActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      100,
      f,
      proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Icon = {
  ICON_DEFAULT: 0
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link;
  return proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, 0),
    manual: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction;
  return proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.galaxycomplete.domain.ChecklistTaskUIAction} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManual(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getManual();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional ChecklistTaskUIAction action = 1;
 * @return {!proto.galaxycomplete.domain.ChecklistTaskUIAction}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.prototype.getAction = function() {
  return /** @type {!proto.galaxycomplete.domain.ChecklistTaskUIAction} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.ChecklistTaskUIAction} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool manual = 2;
 * @return {boolean}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.prototype.getManual = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction.prototype.setManual = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string subtitle = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.getSubtitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.setSubtitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Link links = 5;
 * @return {!Array<!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link>}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link, 5));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link>} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor} returns this
*/
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};


/**
 * optional Icon icon = 50;
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Icon}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.getIcon = function() {
  return /** @type {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Icon} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.Icon} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.setIcon = function(value) {
  return jspb.Message.setProto3EnumField(this, 50, value);
};


/**
 * optional string color = 51;
 * @return {string}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * repeated UIAction ui_actions = 100;
 * @return {!Array<!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction>}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.getUiActionsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction, 100));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction>} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor} returns this
*/
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.setUiActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 100, value);
};


/**
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.addUiActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 100, opt_value, proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.UIAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor.prototype.clearUiActionsList = function() {
  return this.setUiActionsList([]);
};


/**
 * optional uint32 task_id = 1;
 * @return {number}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Descriptor task_descriptor = 2;
 * @return {?proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.getTaskDescriptor = function() {
  return /** @type{?proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.ChecklistInfo.Task.Descriptor|undefined} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task} returns this
*/
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.setTaskDescriptor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.clearTaskDescriptor = function() {
  return this.setTaskDescriptor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.hasTaskDescriptor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional enums.ChecklistTaskStatus.ChecklistTaskStatus status = 5;
 * @return {!proto.galaxycomplete.domain.enums.ChecklistTaskStatus.ChecklistTaskStatus}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.getStatus = function() {
  return /** @type {!proto.galaxycomplete.domain.enums.ChecklistTaskStatus.ChecklistTaskStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.enums.ChecklistTaskStatus.ChecklistTaskStatus} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool finished = 6;
 * @return {boolean}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.getFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.setFinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional HubUser finished_by = 7;
 * @return {?proto.galaxycomplete.domain.HubUser}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.getFinishedBy = function() {
  return /** @type{?proto.galaxycomplete.domain.HubUser} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_user_pb.HubUser, 7));
};


/**
 * @param {?proto.galaxycomplete.domain.HubUser|undefined} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task} returns this
*/
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.setFinishedBy = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.clearFinishedBy = function() {
  return this.setFinishedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.hasFinishedBy = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp finished_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.getFinishedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task} returns this
*/
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.setFinishedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.clearFinishedAt = function() {
  return this.setFinishedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.hasFinishedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string notes = 10;
 * @return {string}
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.Task.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional uint32 checklist_id = 1;
 * @return {number}
 */
proto.galaxycomplete.domain.ChecklistInfo.prototype.getChecklistId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.prototype.setChecklistId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.ChecklistInfo.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo} returns this
*/
proto.galaxycomplete.domain.ChecklistInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ChecklistInfo.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Descriptor checklist_descriptor = 3;
 * @return {?proto.galaxycomplete.domain.ChecklistInfo.Descriptor}
 */
proto.galaxycomplete.domain.ChecklistInfo.prototype.getChecklistDescriptor = function() {
  return /** @type{?proto.galaxycomplete.domain.ChecklistInfo.Descriptor} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.ChecklistInfo.Descriptor, 3));
};


/**
 * @param {?proto.galaxycomplete.domain.ChecklistInfo.Descriptor|undefined} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo} returns this
*/
proto.galaxycomplete.domain.ChecklistInfo.prototype.setChecklistDescriptor = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.prototype.clearChecklistDescriptor = function() {
  return this.setChecklistDescriptor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ChecklistInfo.prototype.hasChecklistDescriptor = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Task tasks = 10;
 * @return {!Array<!proto.galaxycomplete.domain.ChecklistInfo.Task>}
 */
proto.galaxycomplete.domain.ChecklistInfo.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.ChecklistInfo.Task>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.ChecklistInfo.Task, 10));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.ChecklistInfo.Task>} value
 * @return {!proto.galaxycomplete.domain.ChecklistInfo} returns this
*/
proto.galaxycomplete.domain.ChecklistInfo.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.galaxycomplete.domain.ChecklistInfo.Task=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ChecklistInfo.Task}
 */
proto.galaxycomplete.domain.ChecklistInfo.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.galaxycomplete.domain.ChecklistInfo.Task, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.ChecklistInfo} returns this
 */
proto.galaxycomplete.domain.ChecklistInfo.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};


/**
 * @enum {number}
 */
proto.galaxycomplete.domain.ChecklistTaskUIAction = {
  UNKNOWN_UI_ACTION: 0,
  GM_TRIGGER_CMOTION: 1,
  GM_FINALIZE_CUTOVER: 2,
  GM_COMPLETE_SESSION: 3,
  GM_GENERATE_MIGRATION_SESSION_REPORT: 4,
  GM_TRIGGER_SYNC: 5
};

goog.object.extend(exports, proto.galaxycomplete.domain);
