// Project: GalaxyComplete
// Created: 9/25/20 by sammy

import * as React from 'react';
import {useAppServices} from '../app/services';

export const useCurrentProject = () => {
    const {projectService} = useAppServices();
    if (projectService.currentProject.ready)
    {
        return projectService.currentProject.data;
    }
    return null;
};


export const useCurrentProjectID = () => {
    const {projectService} = useAppServices();
    if (projectService.currentProject.ready)
    {
        return projectService.currentProject.data?.getInfo()?.getProjectId();
    }
    return null;
};


