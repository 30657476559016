// ======================
// TooltipText
// ======================

import {observer} from "mobx-react-lite";
import {Tooltip} from "@mui/material";
import React, {CSSProperties, HTMLAttributes} from "react";

interface TooltipTextProps{
    tooltipText: string,
    label: string
    style: CSSProperties
}

export const TooltipText: React.FC<TooltipTextProps> = observer((p)=>{

return <Tooltip title={p.tooltipText} arrow>
    <span style={{textDecoration: 'underline', cursor: 'pointer', ...p.style}}>{p.label}</span>
</Tooltip>
})