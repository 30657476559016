// Project: GalaxyComplete
// Created: 9/24/20 by sammy
// File: ScreenCommon

import * as React from "react";
import { observer } from "mobx-react-lite";
import { Box, Container, ContainerProps, Grid, Typography, useTheme } from "@mui/material";

import { useIsDesktop } from "./MainLayout";
import { getSideMenuWidth } from "./SideMenu";

// ======================
// ScreenFrame
// ======================

const useScreenFrameStyles = () => {
    const t = useTheme();

    return {
        paddingTop: t.spacing(1),
        paddingBottom: t.spacing(10),
        minWidth: 600, //sm is minimum,
        maxWidth: 1920,
        marginLeft: 0,
    };
};

interface ScreenContainerProps {}

export const ScreenContainer: React.FC<ScreenContainerProps & Partial<ContainerProps>> = observer((p) => {
    const styles = useScreenFrameStyles();
    return (
        <Container sx={styles} maxWidth={false} {...p}>
            {p.children}
        </Container>
    );
});

// ======================
// ScreenTitleBar
// ======================

const useScreenTitleBarStyles = () => {
    const t = useTheme();
    return {
        root: {
            paddingBottom: t.spacing(2),
        },
    };
};

interface ScreenTitleBarProps {
    title: React.ReactNode;
    actions?: React.ReactNode;
}

export const ScreenTitleBar: React.FC<ScreenTitleBarProps> = observer((p) => {
    const styles = useScreenTitleBarStyles();
    const isDesktop = useIsDesktop();
    if (isDesktop) {
        return (
            <Grid container justifyContent={"space-between"} alignItems={"center"} sx={styles.root}>
                <Typography variant={"h3"}>{p.title}</Typography>
                {p.actions && <div>{p.actions}</div>}
            </Grid>
        );
    } else {
        return (
            <Box pb={2}>
                <Typography variant={"h3"}>{p.title}</Typography>
                {p.actions && (
                    <Box display={"flex"} justifyContent={"center"} pt={2}>
                        {p.actions}
                    </Box>
                )}
            </Box>
        );
    }
});
