// source: galaxycompletepb/apipb/pelocal_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var galaxycompletepb_apipb_domainpb_license_pb = require('../../galaxycompletepb/apipb/domainpb/license_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_license_pb);
var galaxycompletepb_apipb_domainpb_enumpb_license_vault_counter_type_pb = require('../../galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_license_vault_counter_type_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_api_annotations_pb = require('../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
goog.exportSymbol('proto.galaxycomplete.api.CompletePeLicenseTransaction', null, global);
goog.exportSymbol('proto.galaxycomplete.api.CompletePeLicenseTransaction.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.CompletePeLicenseTransaction.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GeneratePeLicenseRequest', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GeneratePeLicenseRequest.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GeneratePeLicenseRequest.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GeneratedPeLicenseRequest', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetPendingPeLicenseRequest', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetPrivateEditionLocalStatus', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.PrivateEditionLocalStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.CompletePeLicenseTransaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.CompletePeLicenseTransaction.displayName = 'proto.galaxycomplete.api.CompletePeLicenseTransaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.CompletePeLicenseTransaction.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.CompletePeLicenseTransaction.Request.displayName = 'proto.galaxycomplete.api.CompletePeLicenseTransaction.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.CompletePeLicenseTransaction.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.CompletePeLicenseTransaction.Response.displayName = 'proto.galaxycomplete.api.CompletePeLicenseTransaction.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GeneratePeLicenseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GeneratePeLicenseRequest.displayName = 'proto.galaxycomplete.api.GeneratePeLicenseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.GeneratePeLicenseRequest.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.displayName = 'proto.galaxycomplete.api.GeneratePeLicenseRequest.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GeneratePeLicenseRequest.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GeneratePeLicenseRequest.Response.displayName = 'proto.galaxycomplete.api.GeneratePeLicenseRequest.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetPendingPeLicenseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetPendingPeLicenseRequest.displayName = 'proto.galaxycomplete.api.GetPendingPeLicenseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request.displayName = 'proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response.displayName = 'proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.GeneratedPeLicenseRequest.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.GeneratedPeLicenseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GeneratedPeLicenseRequest.displayName = 'proto.galaxycomplete.api.GeneratedPeLicenseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetPrivateEditionLocalStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetPrivateEditionLocalStatus.displayName = 'proto.galaxycomplete.api.GetPrivateEditionLocalStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request.displayName = 'proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response.displayName = 'proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.PrivateEditionLocalStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.PrivateEditionLocalStatus.displayName = 'proto.galaxycomplete.api.PrivateEditionLocalStatus';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.CompletePeLicenseTransaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.CompletePeLicenseTransaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.CompletePeLicenseTransaction}
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.CompletePeLicenseTransaction;
  return proto.galaxycomplete.api.CompletePeLicenseTransaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.CompletePeLicenseTransaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.CompletePeLicenseTransaction}
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.CompletePeLicenseTransaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.CompletePeLicenseTransaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.CompletePeLicenseTransaction.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.CompletePeLicenseTransaction.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    signedReceipt: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.CompletePeLicenseTransaction.Request}
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.CompletePeLicenseTransaction.Request;
  return proto.galaxycomplete.api.CompletePeLicenseTransaction.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.CompletePeLicenseTransaction.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.CompletePeLicenseTransaction.Request}
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignedReceipt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.CompletePeLicenseTransaction.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.CompletePeLicenseTransaction.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSignedReceipt();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string signed_receipt = 1;
 * @return {string}
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Request.prototype.getSignedReceipt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.CompletePeLicenseTransaction.Request} returns this
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Request.prototype.setSignedReceipt = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.CompletePeLicenseTransaction.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.CompletePeLicenseTransaction.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.CompletePeLicenseTransaction.Response}
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.CompletePeLicenseTransaction.Response;
  return proto.galaxycomplete.api.CompletePeLicenseTransaction.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.CompletePeLicenseTransaction.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.CompletePeLicenseTransaction.Response}
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.CompletePeLicenseTransaction.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.CompletePeLicenseTransaction.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.CompletePeLicenseTransaction.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GeneratePeLicenseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GeneratePeLicenseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GeneratePeLicenseRequest}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GeneratePeLicenseRequest;
  return proto.galaxycomplete.api.GeneratePeLicenseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GeneratePeLicenseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GeneratePeLicenseRequest}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GeneratePeLicenseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GeneratePeLicenseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GeneratePeLicenseRequest.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    countersList: jspb.Message.toObjectList(msg.getCountersList(),
    galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo.toObject, includeInstance),
    requestExtension: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GeneratePeLicenseRequest.Request}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GeneratePeLicenseRequest.Request;
  return proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GeneratePeLicenseRequest.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GeneratePeLicenseRequest.Request}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo.deserializeBinaryFromReader);
      msg.addCounters(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequestExtension(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GeneratePeLicenseRequest.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo.serializeBinaryToWriter
    );
  }
  f = message.getRequestExtension();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated galaxycomplete.domain.TransactionCounterInfo counters = 1;
 * @return {!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.prototype.getCountersList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo, 1));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>} value
 * @return {!proto.galaxycomplete.api.GeneratePeLicenseRequest.Request} returns this
*/
proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.prototype.setCountersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxycomplete.domain.TransactionCounterInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.TransactionCounterInfo}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.prototype.addCounters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxycomplete.domain.TransactionCounterInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.GeneratePeLicenseRequest.Request} returns this
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.prototype.clearCountersList = function() {
  return this.setCountersList([]);
};


/**
 * optional bool request_extension = 2;
 * @return {boolean}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.prototype.getRequestExtension = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.GeneratePeLicenseRequest.Request} returns this
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Request.prototype.setRequestExtension = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GeneratePeLicenseRequest.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GeneratePeLicenseRequest.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    generatedRequest: (f = msg.getGeneratedRequest()) && proto.galaxycomplete.api.GeneratedPeLicenseRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GeneratePeLicenseRequest.Response}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GeneratePeLicenseRequest.Response;
  return proto.galaxycomplete.api.GeneratePeLicenseRequest.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GeneratePeLicenseRequest.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GeneratePeLicenseRequest.Response}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.api.GeneratedPeLicenseRequest;
      reader.readMessage(value,proto.galaxycomplete.api.GeneratedPeLicenseRequest.deserializeBinaryFromReader);
      msg.setGeneratedRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GeneratePeLicenseRequest.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GeneratePeLicenseRequest.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneratedRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.api.GeneratedPeLicenseRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneratedPeLicenseRequest generated_request = 1;
 * @return {?proto.galaxycomplete.api.GeneratedPeLicenseRequest}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Response.prototype.getGeneratedRequest = function() {
  return /** @type{?proto.galaxycomplete.api.GeneratedPeLicenseRequest} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.GeneratedPeLicenseRequest, 1));
};


/**
 * @param {?proto.galaxycomplete.api.GeneratedPeLicenseRequest|undefined} value
 * @return {!proto.galaxycomplete.api.GeneratePeLicenseRequest.Response} returns this
*/
proto.galaxycomplete.api.GeneratePeLicenseRequest.Response.prototype.setGeneratedRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.GeneratePeLicenseRequest.Response} returns this
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Response.prototype.clearGeneratedRequest = function() {
  return this.setGeneratedRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.GeneratePeLicenseRequest.Response.prototype.hasGeneratedRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetPendingPeLicenseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetPendingPeLicenseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetPendingPeLicenseRequest}
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetPendingPeLicenseRequest;
  return proto.galaxycomplete.api.GetPendingPeLicenseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetPendingPeLicenseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetPendingPeLicenseRequest}
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetPendingPeLicenseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetPendingPeLicenseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request}
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request;
  return proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request}
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    generatedRequest: (f = msg.getGeneratedRequest()) && proto.galaxycomplete.api.GeneratedPeLicenseRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response}
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response;
  return proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response}
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.api.GeneratedPeLicenseRequest;
      reader.readMessage(value,proto.galaxycomplete.api.GeneratedPeLicenseRequest.deserializeBinaryFromReader);
      msg.setGeneratedRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneratedRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.api.GeneratedPeLicenseRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneratedPeLicenseRequest generated_request = 1;
 * @return {?proto.galaxycomplete.api.GeneratedPeLicenseRequest}
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response.prototype.getGeneratedRequest = function() {
  return /** @type{?proto.galaxycomplete.api.GeneratedPeLicenseRequest} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.GeneratedPeLicenseRequest, 1));
};


/**
 * @param {?proto.galaxycomplete.api.GeneratedPeLicenseRequest|undefined} value
 * @return {!proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response} returns this
*/
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response.prototype.setGeneratedRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response} returns this
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response.prototype.clearGeneratedRequest = function() {
  return this.setGeneratedRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.GetPendingPeLicenseRequest.Response.prototype.hasGeneratedRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GeneratedPeLicenseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GeneratedPeLicenseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    licenseExtension: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    countersList: jspb.Message.toObjectList(msg.getCountersList(),
    galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo.toObject, includeInstance),
    requestTime: (f = msg.getRequestTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    signedRequest: jspb.Message.getFieldWithDefault(msg, 50, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GeneratedPeLicenseRequest}
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GeneratedPeLicenseRequest;
  return proto.galaxycomplete.api.GeneratedPeLicenseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GeneratedPeLicenseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GeneratedPeLicenseRequest}
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseExtension(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo.deserializeBinaryFromReader);
      msg.addCounters(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRequestTime(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignedRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GeneratedPeLicenseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GeneratedPeLicenseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLicenseExtension();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCountersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo.serializeBinaryToWriter
    );
  }
  f = message.getRequestTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSignedRequest();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
};


/**
 * optional bool license_extension = 1;
 * @return {boolean}
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.prototype.getLicenseExtension = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.GeneratedPeLicenseRequest} returns this
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.prototype.setLicenseExtension = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated galaxycomplete.domain.TransactionCounterInfo counters = 2;
 * @return {!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>}
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.prototype.getCountersList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>} value
 * @return {!proto.galaxycomplete.api.GeneratedPeLicenseRequest} returns this
*/
proto.galaxycomplete.api.GeneratedPeLicenseRequest.prototype.setCountersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.TransactionCounterInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.TransactionCounterInfo}
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.prototype.addCounters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.TransactionCounterInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.GeneratedPeLicenseRequest} returns this
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.prototype.clearCountersList = function() {
  return this.setCountersList([]);
};


/**
 * optional google.protobuf.Timestamp request_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.prototype.getRequestTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.api.GeneratedPeLicenseRequest} returns this
*/
proto.galaxycomplete.api.GeneratedPeLicenseRequest.prototype.setRequestTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.GeneratedPeLicenseRequest} returns this
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.prototype.clearRequestTime = function() {
  return this.setRequestTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.prototype.hasRequestTime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string signed_request = 50;
 * @return {string}
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.prototype.getSignedRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.GeneratedPeLicenseRequest} returns this
 */
proto.galaxycomplete.api.GeneratedPeLicenseRequest.prototype.setSignedRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetPrivateEditionLocalStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus}
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetPrivateEditionLocalStatus;
  return proto.galaxycomplete.api.GetPrivateEditionLocalStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus}
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetPrivateEditionLocalStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request}
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request;
  return proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request}
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && proto.galaxycomplete.api.PrivateEditionLocalStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response}
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response;
  return proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response}
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.api.PrivateEditionLocalStatus;
      reader.readMessage(value,proto.galaxycomplete.api.PrivateEditionLocalStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.api.PrivateEditionLocalStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional PrivateEditionLocalStatus status = 1;
 * @return {?proto.galaxycomplete.api.PrivateEditionLocalStatus}
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response.prototype.getStatus = function() {
  return /** @type{?proto.galaxycomplete.api.PrivateEditionLocalStatus} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.PrivateEditionLocalStatus, 1));
};


/**
 * @param {?proto.galaxycomplete.api.PrivateEditionLocalStatus|undefined} value
 * @return {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response} returns this
*/
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response} returns this
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.GetPrivateEditionLocalStatus.Response.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.PrivateEditionLocalStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.PrivateEditionLocalStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    systemVersion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    licenseActivated: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    licenseExpirationTime: (f = msg.getLicenseExpirationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.PrivateEditionLocalStatus}
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.PrivateEditionLocalStatus;
  return proto.galaxycomplete.api.PrivateEditionLocalStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.PrivateEditionLocalStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.PrivateEditionLocalStatus}
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemVersion(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLicenseActivated(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLicenseExpirationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.PrivateEditionLocalStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.PrivateEditionLocalStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSystemVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLicenseActivated();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getLicenseExpirationTime();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string system_name = 1;
 * @return {string}
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus.prototype.getSystemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.PrivateEditionLocalStatus} returns this
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus.prototype.setSystemName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string system_version = 2;
 * @return {string}
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus.prototype.getSystemVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.PrivateEditionLocalStatus} returns this
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus.prototype.setSystemVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool license_activated = 10;
 * @return {boolean}
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus.prototype.getLicenseActivated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.PrivateEditionLocalStatus} returns this
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus.prototype.setLicenseActivated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional google.protobuf.Timestamp license_expiration_time = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus.prototype.getLicenseExpirationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.api.PrivateEditionLocalStatus} returns this
*/
proto.galaxycomplete.api.PrivateEditionLocalStatus.prototype.setLicenseExpirationTime = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.PrivateEditionLocalStatus} returns this
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus.prototype.clearLicenseExpirationTime = function() {
  return this.setLicenseExpirationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.PrivateEditionLocalStatus.prototype.hasLicenseExpirationTime = function() {
  return jspb.Message.getField(this, 11) != null;
};


goog.object.extend(exports, proto.galaxycomplete.api);
