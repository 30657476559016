// source: galaxymigratepb/galaxy_migrate_mtdi.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var galaxymigratepb_galaxy_migrate_storage_pb = require('../galaxymigratepb/galaxy_migrate_storage_pb.js');
goog.object.extend(proto, galaxymigratepb_galaxy_migrate_storage_pb);
goog.exportSymbol('proto.galaxymigrate.GetIOStatsLiveRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.GetIOStatsLiveResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.GetIOStatsRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.GetIOStatsResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.GetInsertedVolumesRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.GetInsertedVolumesResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.GetInsertionCandidatesRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.GetInsertionCandidatesResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.InsertDeviceRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.InsertDeviceResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.InsertedDevice', null, global);
goog.exportSymbol('proto.galaxymigrate.InsertedVolume', null, global);
goog.exportSymbol('proto.galaxymigrate.RestartMTdiDaemonRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.RestartMTdiDaemonResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.UnInsertDeviceRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.UnInsertDeviceResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetIOStatsLiveRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetIOStatsLiveRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetIOStatsLiveRequest.displayName = 'proto.galaxymigrate.GetIOStatsLiveRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetIOStatsLiveResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetIOStatsLiveResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetIOStatsLiveResponse.displayName = 'proto.galaxymigrate.GetIOStatsLiveResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.UnInsertDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.UnInsertDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.UnInsertDeviceRequest.displayName = 'proto.galaxymigrate.UnInsertDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.UnInsertDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.UnInsertDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.UnInsertDeviceResponse.displayName = 'proto.galaxymigrate.UnInsertDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetIOStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.GetIOStatsRequest.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.GetIOStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetIOStatsRequest.displayName = 'proto.galaxymigrate.GetIOStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetIOStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetIOStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetIOStatsResponse.displayName = 'proto.galaxymigrate.GetIOStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetInsertedVolumesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetInsertedVolumesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetInsertedVolumesRequest.displayName = 'proto.galaxymigrate.GetInsertedVolumesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetInsertedVolumesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.GetInsertedVolumesResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.GetInsertedVolumesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetInsertedVolumesResponse.displayName = 'proto.galaxymigrate.GetInsertedVolumesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetInsertionCandidatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetInsertionCandidatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetInsertionCandidatesRequest.displayName = 'proto.galaxymigrate.GetInsertionCandidatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetInsertionCandidatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.GetInsertionCandidatesResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.GetInsertionCandidatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetInsertionCandidatesResponse.displayName = 'proto.galaxymigrate.GetInsertionCandidatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.InsertDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.InsertDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.InsertDeviceRequest.displayName = 'proto.galaxymigrate.InsertDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.InsertDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.InsertDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.InsertDeviceResponse.displayName = 'proto.galaxymigrate.InsertDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.RestartMTdiDaemonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.RestartMTdiDaemonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.RestartMTdiDaemonRequest.displayName = 'proto.galaxymigrate.RestartMTdiDaemonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.RestartMTdiDaemonResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.RestartMTdiDaemonResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.RestartMTdiDaemonResponse.displayName = 'proto.galaxymigrate.RestartMTdiDaemonResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.InsertedDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.InsertedDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.InsertedDevice.displayName = 'proto.galaxymigrate.InsertedDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.InsertedVolume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.InsertedVolume.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.InsertedVolume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.InsertedVolume.displayName = 'proto.galaxymigrate.InsertedVolume';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetIOStatsLiveRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetIOStatsLiveRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetIOStatsLiveRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetIOStatsLiveRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetIOStatsLiveRequest}
 */
proto.galaxymigrate.GetIOStatsLiveRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetIOStatsLiveRequest;
  return proto.galaxymigrate.GetIOStatsLiveRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetIOStatsLiveRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetIOStatsLiveRequest}
 */
proto.galaxymigrate.GetIOStatsLiveRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetIOStatsLiveRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetIOStatsLiveRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetIOStatsLiveRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetIOStatsLiveRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetIOStatsLiveResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetIOStatsLiveResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetIOStatsLiveResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetIOStatsLiveResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    volumeStatsMap: (f = msg.getVolumeStatsMap()) ? f.toObject(includeInstance, proto.galaxymigrate.IOStatsCollector.LiveDataPoint.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetIOStatsLiveResponse}
 */
proto.galaxymigrate.GetIOStatsLiveResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetIOStatsLiveResponse;
  return proto.galaxymigrate.GetIOStatsLiveResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetIOStatsLiveResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetIOStatsLiveResponse}
 */
proto.galaxymigrate.GetIOStatsLiveResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getVolumeStatsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.galaxymigrate.IOStatsCollector.LiveDataPoint.deserializeBinaryFromReader, "", new proto.galaxymigrate.IOStatsCollector.LiveDataPoint());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetIOStatsLiveResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetIOStatsLiveResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetIOStatsLiveResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetIOStatsLiveResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVolumeStatsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.galaxymigrate.IOStatsCollector.LiveDataPoint.serializeBinaryToWriter);
  }
};


/**
 * map<string, IOStatsCollector.LiveDataPoint> volume_stats = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.galaxymigrate.IOStatsCollector.LiveDataPoint>}
 */
proto.galaxymigrate.GetIOStatsLiveResponse.prototype.getVolumeStatsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.galaxymigrate.IOStatsCollector.LiveDataPoint>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.galaxymigrate.IOStatsCollector.LiveDataPoint));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.galaxymigrate.GetIOStatsLiveResponse} returns this
 */
proto.galaxymigrate.GetIOStatsLiveResponse.prototype.clearVolumeStatsMap = function() {
  this.getVolumeStatsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.UnInsertDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.UnInsertDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.UnInsertDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.UnInsertDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicePath: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.UnInsertDeviceRequest}
 */
proto.galaxymigrate.UnInsertDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.UnInsertDeviceRequest;
  return proto.galaxymigrate.UnInsertDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.UnInsertDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.UnInsertDeviceRequest}
 */
proto.galaxymigrate.UnInsertDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.UnInsertDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.UnInsertDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.UnInsertDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.UnInsertDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_path = 1;
 * @return {string}
 */
proto.galaxymigrate.UnInsertDeviceRequest.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.UnInsertDeviceRequest} returns this
 */
proto.galaxymigrate.UnInsertDeviceRequest.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.UnInsertDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.UnInsertDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.UnInsertDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.UnInsertDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.UnInsertDeviceResponse}
 */
proto.galaxymigrate.UnInsertDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.UnInsertDeviceResponse;
  return proto.galaxymigrate.UnInsertDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.UnInsertDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.UnInsertDeviceResponse}
 */
proto.galaxymigrate.UnInsertDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.UnInsertDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.UnInsertDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.UnInsertDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.UnInsertDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.GetIOStatsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetIOStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetIOStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetIOStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetIOStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeDomain: (f = msg.getTimeDomain()) && galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.TimeDomain.toObject(includeInstance, f),
    domainsList: jspb.Message.toObjectList(msg.getDomainsList(),
    galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.Domain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetIOStatsRequest}
 */
proto.galaxymigrate.GetIOStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetIOStatsRequest;
  return proto.galaxymigrate.GetIOStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetIOStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetIOStatsRequest}
 */
proto.galaxymigrate.GetIOStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.TimeDomain;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.TimeDomain.deserializeBinaryFromReader);
      msg.setTimeDomain(value);
      break;
    case 2:
      var value = new galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.Domain;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.Domain.deserializeBinaryFromReader);
      msg.addDomains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetIOStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetIOStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetIOStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetIOStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeDomain();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.TimeDomain.serializeBinaryToWriter
    );
  }
  f = message.getDomainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.Domain.serializeBinaryToWriter
    );
  }
};


/**
 * optional IOStatsDataSet.TimeDomain time_domain = 1;
 * @return {?proto.galaxymigrate.IOStatsDataSet.TimeDomain}
 */
proto.galaxymigrate.GetIOStatsRequest.prototype.getTimeDomain = function() {
  return /** @type{?proto.galaxymigrate.IOStatsDataSet.TimeDomain} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.TimeDomain, 1));
};


/**
 * @param {?proto.galaxymigrate.IOStatsDataSet.TimeDomain|undefined} value
 * @return {!proto.galaxymigrate.GetIOStatsRequest} returns this
*/
proto.galaxymigrate.GetIOStatsRequest.prototype.setTimeDomain = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetIOStatsRequest} returns this
 */
proto.galaxymigrate.GetIOStatsRequest.prototype.clearTimeDomain = function() {
  return this.setTimeDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetIOStatsRequest.prototype.hasTimeDomain = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated IOStatsDataSet.Domain domains = 2;
 * @return {!Array<!proto.galaxymigrate.IOStatsDataSet.Domain>}
 */
proto.galaxymigrate.GetIOStatsRequest.prototype.getDomainsList = function() {
  return /** @type{!Array<!proto.galaxymigrate.IOStatsDataSet.Domain>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.Domain, 2));
};


/**
 * @param {!Array<!proto.galaxymigrate.IOStatsDataSet.Domain>} value
 * @return {!proto.galaxymigrate.GetIOStatsRequest} returns this
*/
proto.galaxymigrate.GetIOStatsRequest.prototype.setDomainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxymigrate.IOStatsDataSet.Domain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.IOStatsDataSet.Domain}
 */
proto.galaxymigrate.GetIOStatsRequest.prototype.addDomains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxymigrate.IOStatsDataSet.Domain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.GetIOStatsRequest} returns this
 */
proto.galaxymigrate.GetIOStatsRequest.prototype.clearDomainsList = function() {
  return this.setDomainsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetIOStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetIOStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetIOStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetIOStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetIOStatsResponse}
 */
proto.galaxymigrate.GetIOStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetIOStatsResponse;
  return proto.galaxymigrate.GetIOStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetIOStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetIOStatsResponse}
 */
proto.galaxymigrate.GetIOStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetIOStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetIOStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetIOStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetIOStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional IOStatsDataSet data = 1;
 * @return {?proto.galaxymigrate.IOStatsDataSet}
 */
proto.galaxymigrate.GetIOStatsResponse.prototype.getData = function() {
  return /** @type{?proto.galaxymigrate.IOStatsDataSet} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_storage_pb.IOStatsDataSet, 1));
};


/**
 * @param {?proto.galaxymigrate.IOStatsDataSet|undefined} value
 * @return {!proto.galaxymigrate.GetIOStatsResponse} returns this
*/
proto.galaxymigrate.GetIOStatsResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetIOStatsResponse} returns this
 */
proto.galaxymigrate.GetIOStatsResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetIOStatsResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetInsertedVolumesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetInsertedVolumesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetInsertedVolumesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetInsertedVolumesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetInsertedVolumesRequest}
 */
proto.galaxymigrate.GetInsertedVolumesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetInsertedVolumesRequest;
  return proto.galaxymigrate.GetInsertedVolumesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetInsertedVolumesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetInsertedVolumesRequest}
 */
proto.galaxymigrate.GetInsertedVolumesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetInsertedVolumesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetInsertedVolumesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetInsertedVolumesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetInsertedVolumesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.GetInsertedVolumesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetInsertedVolumesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetInsertedVolumesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetInsertedVolumesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetInsertedVolumesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    volumesList: jspb.Message.toObjectList(msg.getVolumesList(),
    proto.galaxymigrate.InsertedVolume.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetInsertedVolumesResponse}
 */
proto.galaxymigrate.GetInsertedVolumesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetInsertedVolumesResponse;
  return proto.galaxymigrate.GetInsertedVolumesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetInsertedVolumesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetInsertedVolumesResponse}
 */
proto.galaxymigrate.GetInsertedVolumesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.InsertedVolume;
      reader.readMessage(value,proto.galaxymigrate.InsertedVolume.deserializeBinaryFromReader);
      msg.addVolumes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetInsertedVolumesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetInsertedVolumesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetInsertedVolumesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetInsertedVolumesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxymigrate.InsertedVolume.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InsertedVolume volumes = 1;
 * @return {!Array<!proto.galaxymigrate.InsertedVolume>}
 */
proto.galaxymigrate.GetInsertedVolumesResponse.prototype.getVolumesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.InsertedVolume>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.InsertedVolume, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.InsertedVolume>} value
 * @return {!proto.galaxymigrate.GetInsertedVolumesResponse} returns this
*/
proto.galaxymigrate.GetInsertedVolumesResponse.prototype.setVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.InsertedVolume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.InsertedVolume}
 */
proto.galaxymigrate.GetInsertedVolumesResponse.prototype.addVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.InsertedVolume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.GetInsertedVolumesResponse} returns this
 */
proto.galaxymigrate.GetInsertedVolumesResponse.prototype.clearVolumesList = function() {
  return this.setVolumesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetInsertionCandidatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetInsertionCandidatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetInsertionCandidatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetInsertionCandidatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetInsertionCandidatesRequest}
 */
proto.galaxymigrate.GetInsertionCandidatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetInsertionCandidatesRequest;
  return proto.galaxymigrate.GetInsertionCandidatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetInsertionCandidatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetInsertionCandidatesRequest}
 */
proto.galaxymigrate.GetInsertionCandidatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetInsertionCandidatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetInsertionCandidatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetInsertionCandidatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetInsertionCandidatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.GetInsertionCandidatesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetInsertionCandidatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetInsertionCandidatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetInsertionCandidatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetInsertionCandidatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetInsertionCandidatesResponse}
 */
proto.galaxymigrate.GetInsertionCandidatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetInsertionCandidatesResponse;
  return proto.galaxymigrate.GetInsertionCandidatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetInsertionCandidatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetInsertionCandidatesResponse}
 */
proto.galaxymigrate.GetInsertionCandidatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetInsertionCandidatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetInsertionCandidatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetInsertionCandidatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetInsertionCandidatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BlockDeviceInfo devices = 1;
 * @return {!Array<!proto.galaxymigrate.BlockDeviceInfo>}
 */
proto.galaxymigrate.GetInsertionCandidatesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.BlockDeviceInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.BlockDeviceInfo>} value
 * @return {!proto.galaxymigrate.GetInsertionCandidatesResponse} returns this
*/
proto.galaxymigrate.GetInsertionCandidatesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.BlockDeviceInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.BlockDeviceInfo}
 */
proto.galaxymigrate.GetInsertionCandidatesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.BlockDeviceInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.GetInsertionCandidatesResponse} returns this
 */
proto.galaxymigrate.GetInsertionCandidatesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.InsertDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.InsertDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.InsertDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.InsertDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicePath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    windowsUseScsiAddress: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.InsertDeviceRequest}
 */
proto.galaxymigrate.InsertDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.InsertDeviceRequest;
  return proto.galaxymigrate.InsertDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.InsertDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.InsertDeviceRequest}
 */
proto.galaxymigrate.InsertDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWindowsUseScsiAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.InsertDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.InsertDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.InsertDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.InsertDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWindowsUseScsiAddress();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string device_path = 1;
 * @return {string}
 */
proto.galaxymigrate.InsertDeviceRequest.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.InsertDeviceRequest} returns this
 */
proto.galaxymigrate.InsertDeviceRequest.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool windows_use_scsi_address = 2;
 * @return {boolean}
 */
proto.galaxymigrate.InsertDeviceRequest.prototype.getWindowsUseScsiAddress = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.InsertDeviceRequest} returns this
 */
proto.galaxymigrate.InsertDeviceRequest.prototype.setWindowsUseScsiAddress = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.InsertDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.InsertDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.InsertDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.InsertDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    insertedDevice: (f = msg.getInsertedDevice()) && proto.galaxymigrate.InsertedDevice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.InsertDeviceResponse}
 */
proto.galaxymigrate.InsertDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.InsertDeviceResponse;
  return proto.galaxymigrate.InsertDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.InsertDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.InsertDeviceResponse}
 */
proto.galaxymigrate.InsertDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.InsertedDevice;
      reader.readMessage(value,proto.galaxymigrate.InsertedDevice.deserializeBinaryFromReader);
      msg.setInsertedDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.InsertDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.InsertDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.InsertDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.InsertDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInsertedDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.InsertedDevice.serializeBinaryToWriter
    );
  }
};


/**
 * optional InsertedDevice inserted_device = 1;
 * @return {?proto.galaxymigrate.InsertedDevice}
 */
proto.galaxymigrate.InsertDeviceResponse.prototype.getInsertedDevice = function() {
  return /** @type{?proto.galaxymigrate.InsertedDevice} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.InsertedDevice, 1));
};


/**
 * @param {?proto.galaxymigrate.InsertedDevice|undefined} value
 * @return {!proto.galaxymigrate.InsertDeviceResponse} returns this
*/
proto.galaxymigrate.InsertDeviceResponse.prototype.setInsertedDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.InsertDeviceResponse} returns this
 */
proto.galaxymigrate.InsertDeviceResponse.prototype.clearInsertedDevice = function() {
  return this.setInsertedDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.InsertDeviceResponse.prototype.hasInsertedDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.RestartMTdiDaemonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.RestartMTdiDaemonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.RestartMTdiDaemonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.RestartMTdiDaemonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.RestartMTdiDaemonRequest}
 */
proto.galaxymigrate.RestartMTdiDaemonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.RestartMTdiDaemonRequest;
  return proto.galaxymigrate.RestartMTdiDaemonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.RestartMTdiDaemonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.RestartMTdiDaemonRequest}
 */
proto.galaxymigrate.RestartMTdiDaemonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.RestartMTdiDaemonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.RestartMTdiDaemonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.RestartMTdiDaemonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.RestartMTdiDaemonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.RestartMTdiDaemonResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.RestartMTdiDaemonResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.RestartMTdiDaemonResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.RestartMTdiDaemonResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.RestartMTdiDaemonResponse}
 */
proto.galaxymigrate.RestartMTdiDaemonResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.RestartMTdiDaemonResponse;
  return proto.galaxymigrate.RestartMTdiDaemonResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.RestartMTdiDaemonResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.RestartMTdiDaemonResponse}
 */
proto.galaxymigrate.RestartMTdiDaemonResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.RestartMTdiDaemonResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.RestartMTdiDaemonResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.RestartMTdiDaemonResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.RestartMTdiDaemonResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.InsertedDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.InsertedDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.InsertedDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.InsertedDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    insertionUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    devicePath: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deviceInfo: (f = msg.getDeviceInfo()) && galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.InsertedDevice}
 */
proto.galaxymigrate.InsertedDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.InsertedDevice;
  return proto.galaxymigrate.InsertedDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.InsertedDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.InsertedDevice}
 */
proto.galaxymigrate.InsertedDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInsertionUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    case 4:
      var value = new galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo.deserializeBinaryFromReader);
      msg.setDeviceInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.InsertedDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.InsertedDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.InsertedDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.InsertedDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInsertionUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeviceInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string node_id = 1;
 * @return {string}
 */
proto.galaxymigrate.InsertedDevice.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.InsertedDevice} returns this
 */
proto.galaxymigrate.InsertedDevice.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string insertion_uuid = 2;
 * @return {string}
 */
proto.galaxymigrate.InsertedDevice.prototype.getInsertionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.InsertedDevice} returns this
 */
proto.galaxymigrate.InsertedDevice.prototype.setInsertionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string device_path = 3;
 * @return {string}
 */
proto.galaxymigrate.InsertedDevice.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.InsertedDevice} returns this
 */
proto.galaxymigrate.InsertedDevice.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional BlockDeviceInfo device_info = 4;
 * @return {?proto.galaxymigrate.BlockDeviceInfo}
 */
proto.galaxymigrate.InsertedDevice.prototype.getDeviceInfo = function() {
  return /** @type{?proto.galaxymigrate.BlockDeviceInfo} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_storage_pb.BlockDeviceInfo, 4));
};


/**
 * @param {?proto.galaxymigrate.BlockDeviceInfo|undefined} value
 * @return {!proto.galaxymigrate.InsertedDevice} returns this
*/
proto.galaxymigrate.InsertedDevice.prototype.setDeviceInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.InsertedDevice} returns this
 */
proto.galaxymigrate.InsertedDevice.prototype.clearDeviceInfo = function() {
  return this.setDeviceInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.InsertedDevice.prototype.hasDeviceInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.InsertedVolume.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.InsertedVolume.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.InsertedVolume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.InsertedVolume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.InsertedVolume.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pathsList: jspb.Message.toObjectList(msg.getPathsList(),
    proto.galaxymigrate.InsertedDevice.toObject, includeInstance),
    usedByPolicy: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.InsertedVolume}
 */
proto.galaxymigrate.InsertedVolume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.InsertedVolume;
  return proto.galaxymigrate.InsertedVolume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.InsertedVolume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.InsertedVolume}
 */
proto.galaxymigrate.InsertedVolume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.InsertedDevice;
      reader.readMessage(value,proto.galaxymigrate.InsertedDevice.deserializeBinaryFromReader);
      msg.addPaths(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsedByPolicy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.InsertedVolume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.InsertedVolume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.InsertedVolume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.InsertedVolume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPathsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.galaxymigrate.InsertedDevice.serializeBinaryToWriter
    );
  }
  f = message.getUsedByPolicy();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.galaxymigrate.InsertedVolume.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.InsertedVolume} returns this
 */
proto.galaxymigrate.InsertedVolume.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated InsertedDevice paths = 2;
 * @return {!Array<!proto.galaxymigrate.InsertedDevice>}
 */
proto.galaxymigrate.InsertedVolume.prototype.getPathsList = function() {
  return /** @type{!Array<!proto.galaxymigrate.InsertedDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.InsertedDevice, 2));
};


/**
 * @param {!Array<!proto.galaxymigrate.InsertedDevice>} value
 * @return {!proto.galaxymigrate.InsertedVolume} returns this
*/
proto.galaxymigrate.InsertedVolume.prototype.setPathsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxymigrate.InsertedDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.InsertedDevice}
 */
proto.galaxymigrate.InsertedVolume.prototype.addPaths = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxymigrate.InsertedDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.InsertedVolume} returns this
 */
proto.galaxymigrate.InsertedVolume.prototype.clearPathsList = function() {
  return this.setPathsList([]);
};


/**
 * optional string used_by_policy = 10;
 * @return {string}
 */
proto.galaxymigrate.InsertedVolume.prototype.getUsedByPolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.InsertedVolume} returns this
 */
proto.galaxymigrate.InsertedVolume.prototype.setUsedByPolicy = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


goog.object.extend(exports, proto.galaxymigrate);
