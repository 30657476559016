// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: DeploymentCommon

import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Card, SvgIcon, SvgIconProps, Theme, Typography, useTheme} from '@mui/material';
import {UnknownIcons} from '../../common/CommonIcons';
import {GalaxyMigrateDeploymentDetails} from '../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb';
import semverGte from 'semver/functions/gte';
import {IoIosLink} from 'react-icons/io';
import {SiWindows, SiUbuntu, SiLinux, SiRedhat, SiAmazonaws, SiCentos} from 'react-icons/si';
import {useAppServices} from '../app/services';
import {useIsTableEmpty} from '../../common/table/TableCommon';
import Relay from '../../assets/deployment/relay.png';
import Direct from '../../assets/deployment/direct.png';
import {intervalToDuration, isAfter} from 'date-fns';
import xbytes from 'xbytes';
import {generatePath, useNavigate, useParams} from "react-router-dom";
import {getProjectSubRouteTemplate, PROJECT_SUBROUTE} from "../app/AppRoutes";

export const DEFAULT_PORTAL_HOST = `portal.cloud.cirrusdata.com`;

// ======================
// RelayIcon
// ======================

interface RelayIconProps {
}

export const RelayIcon: React.FC<RelayIconProps> = (p) => {

    return <img alt={'Deployment via Management Relay'} src={Relay} height={'100px'} width={'auto'}/>;
};

// ======================
// DirectIcon
// ======================

interface DirectIconProps {
}

export const DirectIcon: React.FC<DirectIconProps> = (p) => {

    return <img alt={'Direct Deployment'} src={Direct} height={'100px'} width={'auto'}/>;
};

// ======================
// LinuxOSIcon
// ======================
interface HostOSIconProps {
    os: string;
}

export const HostOSIcon: React.FC<HostOSIconProps & Partial<SvgIconProps>> = observer((p) => {
    const {os, ...props} = p;
    return (<SvgIcon {...props}>
        {
            os.includes('amazon linux') ? <SiAmazonaws/> :
            os.includes('centos') ? <SiCentos/> :
            os.includes('redhat') ? <SiRedhat/> :
            os.includes('ubuntu') ? <SiUbuntu/> :
            os.includes('linux') ? <SiLinux/> :
            os.toLowerCase().includes('microsoft windows') ? <SiWindows/> :
            <UnknownIcons/>

        }
    </SvgIcon>);
});

// ======================
// GalaxyMigrateLinkIcon
// ======================

interface GalaxyMigrateLinkIconProps {
}

export const GalaxyMigrateLinkIcon: React.FC<GalaxyMigrateLinkIconProps & Partial<SvgIconProps>> = observer((props) => {

    return <SvgIcon {...props}>
        <IoIosLink/>
    </SvgIcon>;
});

export const isDeploymentGteVersion = (deployment: GalaxyMigrateDeploymentDetails, minVersion: string) => {
    const ver = deployment?.getInfo()?.getDeployment()?.getVersion();
    if (!ver)
    {
        return false;
    }
    if (ver === '0.0.1')
    {
        return true;
    }
    return semverGte(ver, minVersion);
};

export const isMinMtdiVersion = (deployment: GalaxyMigrateDeploymentDetails, minVersion: string) => {
    const ver = deployment?.getInfo()?.getMtdiVersion();
    if (!ver)
    {
        return false;
    }
    return semverGte(ver, minVersion);
};

export const isDeploymentConnected = (deployment: GalaxyMigrateDeploymentDetails) => {
    return deployment.getInfo().getDeployment().getConnected();
};

export const renderIfConnectedDeployment = (deployment: GalaxyMigrateDeploymentDetails, renderConnected: React.ReactNode | Function, renderNotConnected?: React.ReactNode) => {
    if (isDeploymentConnected(deployment))
    {
        return renderConnected;
    }
    else
    {
        if (!!renderNotConnected)
        {
            return renderNotConnected;
        }
        else
        {
            return <Card>
                <Box pb={4} pt={2} pr={2} pl={2} display={'flex'} justifyContent={'center'}>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} p={1}>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            Unavailable when host is offline.
                        </Typography>
                    </Box>

                </Box>
            </Card>;
        }
    }
};

export const getDeploymentConnectionStyle = (connected: boolean) => {
    if (connected)
    {
        return {};
    }
    else
    {
        return {
            color: (t: Theme) => t.palette.grey['600'],
        };
    }
};

export const useHasDeployments = () => {
    const {deploymentService} = useAppServices();
    return useIsTableEmpty(deploymentService.galaxyMigrateDeployments);
};

export const useHasH2HConnections = () => {
    const {gmDeploymentService} = useAppServices();
    return useIsTableEmpty(gmDeploymentService.galaxyMigrateLinks);
};

export const parseCpuCountsFromCpuString = (s: string) => {
    const res = {cores: 0, cpus: 0};
    if (!s)
    {
        return res;
    }

    // new format: Intel(R) Xeon(R) CPU E5-2650 v2 @ 2.60GHz [3C/4S]
    // old: Intel(R) Xeon(R) CPU E5-2650 v2 @ 2.60GHz (4 Cores)
    const matchNew = s.match(/^.*\[(\d*)C\/(\d*)S\]/s);
    if (matchNew)
    {
        res.cores = parseInt(matchNew[1]) || 0;
        res.cpus  = parseInt(matchNew[2]) || 0;
        return res;
    }

    const matchOld = s.match(/^.*\((\d*) Cores\)/s);
    if (matchOld)
    {
        res.cpus  = 1;
        res.cores = parseInt(matchOld[1]) || 0;
    }

    return res;
};

export const useNavigateToDeploymentDetails = (deploymentId: string) => {
    const navigate = useNavigate();
    const {projectId} = useParams();
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATIONS) +`/${PROJECT_SUBROUTE.DEPLOYMENTS}/${deploymentId}`, {projectId});
    return () => navigate(p);
}