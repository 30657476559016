
// ======================
// PaginationInfo
// ======================
import {PagerMeta} from "../../_proto/galaxycompletepb/commonpb/datafilter_pb";
import {observer} from "mobx-react-lite";
import {useState} from "react";
import {Box, Grid, IconButton, MenuItem, Select, SelectChangeEvent, SvgIcon, Typography} from "@mui/material";
import {MdFirstPage, MdLastPage} from "react-icons/md";
import {LeftArrow, RightArrow} from "../CommonIcons";
import * as React from "react";
import {TableState} from "./DataTable";

interface PaginationInfoProps<RowData> {
    state: TableState<RowData>;
    onTableStateChange?: (state: TableState<RowData>) => void;
    pagerMeta?: PagerMeta.AsObject;
}

export const PaginationControl = observer(<RowData,>(p: PaginationInfoProps<RowData>) => {

    if (!p.pagerMeta) {
        return null;
    }
    const page = p.pagerMeta.page;
    const perPage = p.pagerMeta.perPage;
    const total = p.pagerMeta.totalItems;
    const to = Math.min(page * perPage, total);
    const from = Math.max((to - perPage) + 1, 1);

    const [selectedPage, setSelectedPage] = useState(page);

    const handleSelect = (event: SelectChangeEvent<number>) => {
        const newPage = event.target.value as number;
        setSelectedPage(newPage);
        p.state.pager.setPage(newPage);
        p.onTableStateChange(p.state)
    };

    const getPageSelectionList = (totalPages: number) => {
        const pageList = [];
        if (totalPages===0){
            return [1]
        }
        for (let i = 1; i <= totalPages; i++) {
            pageList.push(i)
        }
        return pageList;
    }

    const goToNextPage = () => {
        p.state.pager.setPage(p.pagerMeta.nextPage);
        p.onTableStateChange(p.state)
    }

    const goToPrevPage = () => {
        p.state.pager.setPage(p.pagerMeta.prevPage);
        p.onTableStateChange(p.state)
    }

    const goToFirstPage = () => {
        p.state.pager.setPage(1);
        p.onTableStateChange(p.state)
    }

    const goToLastPage = () => {
        p.state.pager.setPage(p.pagerMeta.totalPages);
        p.onTableStateChange(p.state)
    }

    return (<Box p={2}>
        <Grid container justifyContent={'flex-end'} alignItems={'center'}>
            <Grid item>
                <Box display={'flex'} alignItems={'center'} mr={2}>
                    <Box mr={1}>
                        <Typography variant={'caption'}>
                            Go To Page:
                        </Typography>
                    </Box>
                    <Box pt={1}>
                        <Select value={selectedPage}
                                variant={'outlined'}
                                onChange={handleSelect}
                                sx={{height:'35px',
                                    lineHeight:0,
                                    width:'55px'}}
                                renderValue={(value: unknown) =>
                                    <Typography variant={'caption'}>{value as React.ReactNode}</Typography>}
                        >
                            {getPageSelectionList(p.pagerMeta.totalPages).map((p,i) => {
                                return <MenuItem key={i} value={p}>{p}</MenuItem>
                            })}
                        </Select>
                    </Box>

                </Box>

            </Grid>
            <Grid item>
                <Typography variant={'caption'}>
                    {from} - {to} / {total}
                </Typography>
            </Grid>
            <Grid item>
                <IconButton size={'small'} disabled={page <= 1} onClick={goToFirstPage}>
                    <SvgIcon>
                        <MdFirstPage/>
                    </SvgIcon>
                </IconButton>
                <IconButton size={'small'} disabled={page <= 1} onClick={goToPrevPage}>
                    <LeftArrow/>
                </IconButton>
                <IconButton size={'small'} disabled={!p.pagerMeta.hasMore} onClick={goToNextPage}>
                    <RightArrow/>
                </IconButton>
                <IconButton size={'small'} disabled={!p.pagerMeta.hasMore} onClick={goToLastPage}>
                    <SvgIcon>
                        <MdLastPage/>
                    </SvgIcon>
                </IconButton>
            </Grid>
        </Grid>
    </Box>);
});