/**
 * @fileoverview gRPC-Web generated client stub for galaxycomplete.api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: galaxycompletepb/apipb/checklist_api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as galaxycompletepb_apipb_checklist_api_pb from '../../galaxycompletepb/apipb/checklist_api_pb';


export class ChecklistServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorMarkTaskCompleted = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ChecklistService/MarkTaskCompleted',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_checklist_api_pb.MarkTaskCompleted.Request,
    galaxycompletepb_apipb_checklist_api_pb.MarkTaskCompleted.Response,
    (request: galaxycompletepb_apipb_checklist_api_pb.MarkTaskCompleted.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_checklist_api_pb.MarkTaskCompleted.Response.deserializeBinary
  );

  markTaskCompleted(
    request: galaxycompletepb_apipb_checklist_api_pb.MarkTaskCompleted.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_checklist_api_pb.MarkTaskCompleted.Response>;

  markTaskCompleted(
    request: galaxycompletepb_apipb_checklist_api_pb.MarkTaskCompleted.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_checklist_api_pb.MarkTaskCompleted.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_checklist_api_pb.MarkTaskCompleted.Response>;

  markTaskCompleted(
    request: galaxycompletepb_apipb_checklist_api_pb.MarkTaskCompleted.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_checklist_api_pb.MarkTaskCompleted.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ChecklistService/MarkTaskCompleted',
        request,
        metadata || {},
        this.methodDescriptorMarkTaskCompleted,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ChecklistService/MarkTaskCompleted',
    request,
    metadata || {},
    this.methodDescriptorMarkTaskCompleted);
  }

  methodDescriptorMarkTaskSkipped = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ChecklistService/MarkTaskSkipped',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_checklist_api_pb.MarkTaskSkipped.Request,
    galaxycompletepb_apipb_checklist_api_pb.MarkTaskSkipped.Response,
    (request: galaxycompletepb_apipb_checklist_api_pb.MarkTaskSkipped.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_checklist_api_pb.MarkTaskSkipped.Response.deserializeBinary
  );

  markTaskSkipped(
    request: galaxycompletepb_apipb_checklist_api_pb.MarkTaskSkipped.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_checklist_api_pb.MarkTaskSkipped.Response>;

  markTaskSkipped(
    request: galaxycompletepb_apipb_checklist_api_pb.MarkTaskSkipped.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_checklist_api_pb.MarkTaskSkipped.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_checklist_api_pb.MarkTaskSkipped.Response>;

  markTaskSkipped(
    request: galaxycompletepb_apipb_checklist_api_pb.MarkTaskSkipped.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_checklist_api_pb.MarkTaskSkipped.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ChecklistService/MarkTaskSkipped',
        request,
        metadata || {},
        this.methodDescriptorMarkTaskSkipped,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ChecklistService/MarkTaskSkipped',
    request,
    metadata || {},
    this.methodDescriptorMarkTaskSkipped);
  }

  methodDescriptorGetChecklist = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ChecklistService/GetChecklist',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_checklist_api_pb.GetChecklist.Request,
    galaxycompletepb_apipb_checklist_api_pb.GetChecklist.Response,
    (request: galaxycompletepb_apipb_checklist_api_pb.GetChecklist.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_checklist_api_pb.GetChecklist.Response.deserializeBinary
  );

  getChecklist(
    request: galaxycompletepb_apipb_checklist_api_pb.GetChecklist.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_checklist_api_pb.GetChecklist.Response>;

  getChecklist(
    request: galaxycompletepb_apipb_checklist_api_pb.GetChecklist.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_checklist_api_pb.GetChecklist.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_checklist_api_pb.GetChecklist.Response>;

  getChecklist(
    request: galaxycompletepb_apipb_checklist_api_pb.GetChecklist.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_checklist_api_pb.GetChecklist.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ChecklistService/GetChecklist',
        request,
        metadata || {},
        this.methodDescriptorGetChecklist,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ChecklistService/GetChecklist',
    request,
    metadata || {},
    this.methodDescriptorGetChecklist);
  }

}

