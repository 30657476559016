/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AuthService
 */
import { computed, observable, extendObservable, makeAutoObservable, runInAction } from "mobx";
import { add as addDate, sub as subtractDate, formatDistanceToNow, isAfter } from "date-fns";
import {
    AuthenticatedUserInfo,
    ChangeMyPassword,
    CompleteOnboard,
    DisableMfaTotp,
    EnableMfaTotp,
    ForgotPassword,
    GenerateMfaTotpSecret,
    GenerateRecoveryCodes,
    Login,
    RefreshToken,
    RequestMfaToken,
    ResetPassword,
    ResolveAzureMarketplaceSubscription,
    Signup,
} from "../../_proto/galaxycompletepb/apipb/auth_api_pb";
import { GRPCServices } from "../grpc/grpcapi";
import { DialogService } from "../core/dialog/DialogService";
import { RpcError, StatusCode } from "grpc-web";
import SuperTokensLock from "browser-tabs-lock";
import { randomInteger, sleepMS } from "../../common/utils/util";
import { StepperState } from "../../common/stepper/StepperComponents";
import { generate2FaStepConfigs } from "./TwoFactorAuth";
import { ServerData } from "../core/data/ServerData";

const _STORAGE_KEYS = {
    ACCESS_TOKEN: "Bn3cp*n6NaiqKz9q8X",
    REFRESH_TOKEN: "ePA3MT!g#H7re$eAmh9M",
    NEXT_REFRESH_TIME: "scUktc8AUhyy3Ys7&QYroj",
    CURRENT_USER: "94N$@y5*CjLfp%!U$!bTn",
};
const _refresh_token_lock_key = "refresh-token-lock";

export class AuthService {
    api: GRPCServices;
    currentUser: AuthenticatedUserInfo = null;
    dialogService: DialogService;
    twoFactorAuthState: TwoFactorAuthState;

    private locks = new SuperTokensLock();
    private nextRefreshTime: Date = null;
    private storage: Storage = window.localStorage;

    loginMethod = new ServerData<RequestMfaToken.Response>().setDataFetcher(this.requestCode.bind(this));

    awsAuthenticated = false;
    azureAuthenticated = false;

    constructor(api: GRPCServices, dialogService: DialogService) {
        this.dialogService = dialogService;
        this.api = api;
        makeAutoObservable(this);
    }

    initTwoFactorAuthState() {
        this.twoFactorAuthState = new TwoFactorAuthState(this.api, this.dialogService);
    }

    setAwsAuthenticated(authenticated: boolean) {
        this.awsAuthenticated = authenticated;
    }
    setAzureAuthenticated(authenticated: boolean) {
        this.azureAuthenticated = authenticated;
    }

    async logout(skipConfirm?: boolean) {
        if (!skipConfirm) {
            const confirmed = await this.dialogService.addConfirmDialog({
                message: "Are you sure you want to log out?",
                autoConfirmationQuestionLine: false,
            });
            if (confirmed) {
                this.currentUser = null;
                this.onAccessTokenUpdated(null);
                this._storeRefreshToken(null);
                this.setAwsAuthenticated(false);
                this.setAzureAuthenticated(false);
            }
            return confirmed;
        } else {
            this.currentUser = null;
            this.onAccessTokenUpdated(null);
            this._storeRefreshToken(null);
            this.setAwsAuthenticated(false);
            this.setAzureAuthenticated(false);
        }
    }

    async signUp(firstName: string, lastName: string, email: string, companyName: string, jobTitle: string, awsToken: string, azureToken?: string) {
        const req = new Signup.Request()
            .setFirstName(firstName)
            .setLastName(lastName)
            .setEmail(email)
            .setCompanyName(companyName)
            .setJobTitle(jobTitle)
            .setAwsMarketplacePairToken(awsToken)
            .setAzureMarketplacePairToken(azureToken);

        return await this.api.loginService.signup(req, null);
    }

    async login(user: string, password: string, mfaToken: string, awsToken: string, azureToken?: string) {
        const now = new Date();
        const request = new Login.Request()
            .setUser(user)
            .setPassword(password)
            .setMfaToken(mfaToken)
            .setAwsMarketplacePairToken(awsToken)
            .setAzureMarketplacePairToken(azureToken);
        const res = await this.api.loginService.login(request, null);
        this.onCurrentUserUpdated(res.getUserInfo());
        this.onAccessTokenUpdated(res.getJwtToken());
        this._storeRefreshToken(res.getRefreshToken());
        this.markNextRefreshTime(now, res.getJwtValidDuration().toObject());
        return res;
    }

    private markNextRefreshTime(requestTime: Date, validDuration: Duration) {
        const nextRefreshTime = subtractDate(addDate(requestTime, validDuration), { minutes: 1, seconds: randomInteger(0, 5) });
        this.onNextTokenRefreshTimeUpdated(nextRefreshTime);
        console.debug(`next token refresh time set to ${nextRefreshTime} (${formatDistanceToNow(nextRefreshTime, { addSuffix: true })})`);
    }

    async requestCode(user: string, password: string) {
        const req = new RequestMfaToken.Request().setUser(user).setPassword(password);
        return await this.api.loginService.requestMfaToken(req, null);
    }

    async requestResetPassword(user: string) {
        const req = new ForgotPassword.Request().setUser(user);

        return await this.api.loginService.forgotPassword(req, null);
    }

    async setNewPassword(user: string, password: string, token: string) {
        const req = new ResetPassword.Request().setUser(user).setNewPassword(password).setResetPwdToken(token);
        return await this.api.loginService.resetPassword(req, null);
    }
    async resolveAzureMarketplaceSubscription(token: string) {
        const req = new ResolveAzureMarketplaceSubscription.Request().setToken(token);
        const response = await this.api.loginService.resolveAzureMarketplaceSubscription(req, null);
        return response.toObject();
        // return new ResolveAzureMarketplaceSubscription.Response()
        //     .setSubscriptionId("ab")
        //     .setExistingUser(false)
        //     .setExistingActiveSubscription(false)
        //     .setOfferId("offer")
        //     .setEmail("adsfasd@adsfa.com")
        //     .setPlanId("plan")
        //     .setTenantId("tenant tenant tenant")
        //     .toObject();
    }

    async changeMyPassword(currentPassword: string, newPassword: string) {
        const req = new ChangeMyPassword.Request().setNewPassword(newPassword).setOldPassword(currentPassword);

        return await this.api.authService.changeMyPassword(req, null);
    }

    get authenticated() {
        return this.api.hasToken() && !!this.currentUser;
    }

    get hasJwtToken() {
        return this.api.hasToken();
    }

    get unauthenticated() {
        return !this.api.hasToken() && !this.currentUser;
    }

    get onboardCompleted() {
        return this.authenticated && !!this.currentUser.getActivated() && !!this.currentUser.getLastName() && this.currentUser.getLastName();
    }

    // should never be called more than once per page load
    async initAuthentication() {
        console.debug("initializing authentication");

        window.addEventListener(
            "storage",
            (event) => {
                if (Object.values(_STORAGE_KEYS).includes(event.key)) {
                    this._loadPersistentEntitiesFromStorage();
                }
            },
            false
        );

        // first load up data from storage
        this._loadPersistentEntitiesFromStorage();

        // then check  if we should refresh or just wait for next refresh
        const refreshToken = this.getRefreshToken();
        console.debug(`existing refresh token loaded`, refreshToken);
        if (refreshToken) {
            if (!!this.nextRefreshTime) {
                await this.refreshTokenIfExpireSoon();
            } else {
                await this.refreshToken();
            }
        }

        // then also setup auto refresh
        console.debug("setup auto refresh token");
        setInterval(() => this.refreshTokenIfExpireSoon(true), 1000 * 60);
        console.debug("initialized authentication");
    }

    private async refreshTokenIfExpireSoon(addJitter = false) {
        if (addJitter) {
            await sleepMS(randomInteger(0, 100));
        }
        if (!!this.currentUser && !!this.nextRefreshTime && isAfter(new Date(), this.nextRefreshTime)) {
            await this.refreshToken();
        } else {
            console.debug("token not expire soon no need to refresh");
        }
    }

    async refreshToken(bestEffort = true) {
        const lock = await this.locks.acquireLock(_refresh_token_lock_key, 15000);
        if (lock) {
            try {
                await this._refreshToken(bestEffort);
            } finally {
                await this.locks.releaseLock(_refresh_token_lock_key);
            }
        } else {
            throw new Error("failed to refresh token. cannot obtain lock after 15 seconds");
        }
    }

    async _refreshToken(bestEffort = true) {
        const refreshToken = this.getRefreshToken();
        if (!refreshToken) {
            this.currentUser = null;
            return;
        }

        try {
            console.debug(`refreshing token`);
            const now = new Date();
            const res = await this.api.loginService.refreshToken(new RefreshToken.Request().setRefreshToken(refreshToken), null);
            this.onAccessTokenUpdated(res.getJwtToken());
            this.onCurrentUserUpdated(res.getUserInfo());
            this._storeRefreshToken(res.getRefreshToken());
            console.debug(`completed refreshing token`);
            this.markNextRefreshTime(now, res.getJwtValidDuration().toObject());
        } catch (e) {
            if (bestEffort) {
                this.currentUser = null;
                if ((e as RpcError).code === StatusCode.UNAUTHENTICATED) {
                    console.debug("refresh failed, clearing refresh token", e);
                    this._storeRefreshToken(null);
                }
            } else {
                throw e;
            }
        }
    }

    private getRefreshToken() {
        return this.storage.getItem(_STORAGE_KEYS.REFRESH_TOKEN) || null;
    }

    private onNextTokenRefreshTimeUpdated(t: Date) {
        this.nextRefreshTime = t;
        if (t) {
            this.storage.setItem(_STORAGE_KEYS.NEXT_REFRESH_TIME, `${t}`);
        } else {
            this.storage.removeItem(_STORAGE_KEYS.NEXT_REFRESH_TIME);
        }
    }

    private _storeRefreshToken(token: string) {
        if (token) {
            console.debug(`storing refresh token to active storage`, token);
            this.storage.setItem(_STORAGE_KEYS.REFRESH_TOKEN, token);
        } else {
            console.debug(`clearing refresh token`);
            this.storage.removeItem(_STORAGE_KEYS.REFRESH_TOKEN);
        }
    }

    private onAccessTokenUpdated(token: string) {
        this.api.setActiveToken(token);
        if (token) {
            this.storage.setItem(_STORAGE_KEYS.ACCESS_TOKEN, token);
        } else {
            this.storage.removeItem(_STORAGE_KEYS.ACCESS_TOKEN);
        }
    }

    private onCurrentUserUpdated(user: AuthenticatedUserInfo) {
        this.currentUser = user;
        if (user) {
            this.storage.setItem(_STORAGE_KEYS.CURRENT_USER, JSON.stringify(Array.from(user.serializeBinary())));
        } else {
            this.storage.removeItem(_STORAGE_KEYS.CURRENT_USER);
        }
    }

    private _loadPersistentEntitiesFromStorage() {
        const accessToken = this.storage.getItem(_STORAGE_KEYS.ACCESS_TOKEN);
        this.api.setActiveToken(accessToken);
        const nextRefreshTimeVal = this.storage.getItem(_STORAGE_KEYS.NEXT_REFRESH_TIME);
        this.nextRefreshTime = nextRefreshTimeVal ? new Date(nextRefreshTimeVal) : null;
        const currentUserVal = this.storage.getItem(_STORAGE_KEYS.CURRENT_USER);
        this.currentUser = currentUserVal ? AuthenticatedUserInfo.deserializeBinary(new Uint8Array(JSON.parse(currentUserVal))) : null;
    }

    public async activateUser(req: CompleteOnboard.Request) {
        const res = await this.api.authService.completeOnboard(req, null);
        this.api.setActiveToken(res.getJwtToken());
        await this.refreshToken(false);
    }

    public async disable2FA() {
        const req = new DisableMfaTotp.Request();
        return await this.api.authService.disableMfaTotp(req, null);
    }

    public updateAccessTokenFromAuthState(token: string) {
        this.api.setActiveToken(token);
    }
    public updateCurrentUserFromAuthState(user: AuthenticatedUserInfo) {
        this.currentUser = user;
    }
}

export class TwoFactorAuthState {
    private api: GRPCServices;
    dialogService: DialogService;

    enable2FAStepperState = new StepperState(0, generate2FaStepConfigs(this));

    twoFACode = new ServerData<GenerateMfaTotpSecret.Response>().setDataFetcher(this.fetch2FACode.bind(this));
    recoveryCodes = new ServerData<GenerateRecoveryCodes.Response>().setDataFetcher(this.generateRecoveryCodes.bind(this));

    constructor(api: GRPCServices, dialogService: DialogService) {
        this.api = api;
        this.dialogService = dialogService;
        makeAutoObservable(this);
    }

    verificationCode: string = null;

    setVerificationCode(token: string) {
        this.verificationCode = token;
    }

    async enable2Fa() {
        const req = new EnableMfaTotp.Request().setCode(this.verificationCode).setSecret(this.twoFACode.data?.getSecret());

        await this.dialogService.catchAndAlertError(this.api.authService.enableMfaTotp(req, null));
    }

    async fetch2FACode() {
        const req = new GenerateMfaTotpSecret.Request();
        return await this.api.authService.generateMfaTotpSecret(req, null);
    }

    async generateRecoveryCodes() {
        const req = new GenerateRecoveryCodes.Request();
        return await this.api.authService.generateRecoveryCodes(req, null);
    }
}
