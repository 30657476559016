export const getApiHost     = () => GRPC_API_HOST;
export const getApiEndpoint = () => {
    if (GRPC_API_HOST)
    {
        return `https://${GRPC_API_HOST}`;
    }
    // return /webapi for private edition or in the future if we use cookie
    return `/webapi`;
};
export const GRPC_API_HOST  = process.env.REACT_APP_WEB_API_HOST;
