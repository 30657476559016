/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AppRoutes
 */
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const APP_ROUTES = {
    PROJECTS: "/projects",
    USER: "/profile",
    USER_LICENSE: "/user-license",
    CALLBACKS: "/callbacks", //for hooks, oid, saml, etc.
    SUPPORT_PROJECTS: "/support-projects",
    REPORTS: "/reports",
    CUSTOMER_CENTER: "/customer-center",
};

export const AUTH_ROUTES = {
    SET_PASSWORD: "/set-password",
    SIGN_UP: "/sign-up",
    CONNECT_AWS: "/aws-mp-signup",
    CONNECT_AZURE: "/azure-mp-signup",
};

export enum LICENSE_SUBROUTE {
    PURCHASE = "purchase",
    REQUEST = "request",
}

export enum PROJECT_SUBROUTE {
    DASHBOARD = "dashboard",
    DEPLOYMENTS = "deployments",
    MIGRATIONS = "migrations",
    MANAGEMENT = "management",
    PROTECTION = "protection",
    REPORTS = "reports",
    SETTINGS = "project-settings",
    INTEGRATIONS = "integrations",
}

export enum MANAGEMENT_SUBROUTE {
    LICENSE = "license",
}

export enum INTEGRATIONS_SUBROUTE {
    CREATE = "create",
    ENABLED = "enabled",
}

export enum MIGRATION_SUBROUTE {
    DEPLOYMENTS = "deployments",
    SESSIONS = "sessions",
    CONNECTIONS = "connections",
    WIZARD = "wizard",
    AUTO_ALLOCATION = "auto-allocation",
    VOLUMES = "volumes", //under session id,
    WORKFLOW_RUNS = "workflow-runs", //under session id
    QOS = "qos", // under volumes
    EDIT = "edit",
    EDIT_VM_CONFIG = "edit-vm-config",
}

export enum PROTECTION_SUBROUTE {
    DEPLOYMENTS = "deployments",
    SERVERS = "targets",
    POLICIES = "cdm-policies",
}

export enum POLICIES_SUBROUTE {
    CREATE = "create",
}

export enum DEPLOYMENT_SUBROUTE {
    DEPLOYED = "deployed",
    CONNECTIONS = "connections",
}

export enum DEPLOYMENT_DETAILS_SUBROUTE {
    HOST_INFO = "host-info",
    SETTINGS = "deployment-settings",
    RELAY = "management-relay",
    LOG = "live-log",
    LICENSE = "license",
    XRAYS = "xrays",
    PERFORMANCE = "performance",
}

export enum PROTECTION_HOST_DETAILS_SUBROUTE {
    SETTINGS = "settings",
    CREATE_NEW_POLICY = "create-new-policy",
    POLICY_INFO = "policy-info",
    LOG = "live-log",
}

export enum PHOENIX_DEPLOYMENT_DETAILS_SUBROUTE {
    PHOENIX_STORAGE = "phoenix-storage",
    DATA_SOURCE = "data-source",
}

export enum CDM_POLICY_DETAILS_SUBROUTE {
    SNAPSHOTS = "snapshots",
    TIMEWALKER = "timewalker",
}

export enum CALLBACK_SUBROUTE {
    LICENSE_PURCHASE_SUCCESS = "purchase-success",
    LICENSE_PURCHASE_CANCELLED = "purchase-cancelled",
}

export const getProjectSubRouteTemplate = (sub: string) => {
    return `${APP_ROUTES.PROJECTS}/:projectId/${sub}`;
};

export const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
};
