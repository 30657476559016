import {observer} from "mobx-react-lite";
import React from "react";
import {ScreenContainer, ScreenTitleBar} from "../../layout/ScreenCommon";
import {DialogState, useDialogState} from "../../core/dialog/DialogService";
import {AlertTitle, Box, Button, Grid, Link, SvgIcon, Theme, Typography} from "@mui/material";
import {OperatorView, useIsOperatorView} from "../../auth/AuthenticatedViews";
import {useAppServices} from "../../app/services";
import {renderServerDataWithLoadingList, useInitData} from "../../core/data/DataLoaderHooks";
import {ColumnDef, DataTable} from "../../../common/table/DataTable";
import {CirrusProtectDeploymentInfo} from "../../../_proto/galaxycompletepb/apipb/domainpb/cirrusprotect_pb";
import {Link as RouterLink} from 'react-router-dom';
import {useCurrentProjectID} from "../../project/CurrentProject";
import {
    generateCirrusProtectDeploymentDetailsPath,
    generatePhoenixDeploymentDetailsPath
} from "../ProjectProtectionCommon";
import {HostOSIcon} from "../../deployment/DeploymentCommon";
import {DeploymentHostEnvironment} from "../../../_proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import {getHostEnvDisplayName} from "../../galaxymigrate/GalaxyMigrateCommon";
import {formatKnownDataType, KnownDataType} from "../../../common/utils/formatter";
import {
    CpDeploymentConnectToPhoenixButton,
    CpDeploymentConnectToPhoenixDialog
} from "./CirrusProtectDeploymentConnectToPhoenix";
import {CirrusProtectDeploymentButton} from "./CirrusProtectNewDeploymentInstructions";
import {useIsTableEmpty} from "../../../common/table/TableCommon";
import {MdWarningAmber} from 'react-icons/md';
import {GalaxyMigrateDeploymentInfo} from "../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import {DeleteIcon} from "../../../common/CommonIcons";

// ======================
// ProtectionHostsListScreen
// ======================

interface CirrusProtectDeploymentsListScreenProps {
}

export const CirrusProtectDeploymentsListScreen: React.FC<CirrusProtectDeploymentsListScreenProps> = observer((p) => {
    const connectToPhoenixDialogState = useDialogState();
    const {cpDeploymentService} = useAppServices();
    const hasDeployments = useIsTableEmpty(cpDeploymentService.cpDeployments);

    const getActions = () => (<OperatorView>
        {
            (hasDeployments) &&
            <CirrusProtectDeploymentButton/>

        }
    </OperatorView>);


    return <ScreenContainer>
        <ScreenTitleBar title={'Protection Hosts'} actions={getActions()}/>

        <CirrusProtectUnregisteredDeploymentsList connectToPhoenixDialogState={connectToPhoenixDialogState}/>
        <CirrusProtectDeploymentsList/>
        <CpDeploymentConnectToPhoenixDialog dialogState={connectToPhoenixDialogState}/>
    </ScreenContainer>
});





// ======================
// CirrusProtectDeploymentsList
// ======================

interface CirrusProtectDeploymentsListProps {
}

const CirrusProtectDeploymentsList: React.FC<CirrusProtectDeploymentsListProps> = observer((p) => {
    const {cpDeploymentService, dialogService} = useAppServices();
    const projectId = useCurrentProjectID();

    const isOperator = useIsOperatorView();

    useInitData({
        poll: () => cpDeploymentService.cpDeployments.fetchData(false, false, true),
        pollInterval: 3
    });

    return renderServerDataWithLoadingList(cpDeploymentService.cpDeployments, data => {
        const cols: ColumnDef<CirrusProtectDeploymentInfo>[] = [
            {
                id: 'hostName',
                label: 'Host Name',
                getter: (r) => r.getDeployment().getSystemName(),
                renderer: (v, r) => {
                    return <Link component={RouterLink}
                                 to={generateCirrusProtectDeploymentDetailsPath(r.getDeployment().getSystemId(), projectId)}>{v}</Link>
                }

            },
            {
                id: 'version',
                label: 'Version',
                getter: (r) => r.getMtdiVersion(),
                renderer: (v, r) => <Typography
                    sx={{
                        color: r.getDeployment().getConnected() ? 'white' : '#757575'
                    }}>{v}</Typography>
            },
            {
                id: 'os',
                label: 'OS',
                getter: r => `${r.getOsClass()} - ${r.getKernel()}`,
                renderer: (os: string, r) => {
                    return <>
                        <Box display={'flex'}
                             sx={{
                                 color: r.getDeployment().getConnected() ? 'white' : '#757575'
                             }}>
                            <HostOSIcon os={os}/> &nbsp;&nbsp;&nbsp;&nbsp;
                            <div>{os}</div>
                        </Box>
                    </>
                }
            },
            {
                id: 'environment',
                label: 'Host Environment',
                getter: r => r.getDeployment().getHostEnvironment().getValue(),
                renderer: (env: DeploymentHostEnvironment.DeploymentHostEnvironment, r) => {
                    return <Typography
                        sx={{
                            color: r.getDeployment().getConnected() ? 'white' : '#757575'
                        }}>
                    {getHostEnvDisplayName(env)}
                    </Typography>
                }
            },
            {
                id: 'connected',
                label: 'Connected',
                getter: r => r.getDeployment().getConnected(),
                renderer: (connected, r) => {
                    return <Typography sx={{
                        color: r.getDeployment().getConnected() ? 'white' : '#757575'
                    }}>
                        {formatKnownDataType(connected, KnownDataType.BOOL)}
                    </Typography>
                },
            },
            {
                id: 'latency',
                label: 'Latency',
                getter: r => r.getDeployment().getConnectionLatency(),
                renderer: (v, r) => {
                    return <Typography sx={{
                        color: r.getDeployment().getConnected() ? 'white' : '#757575'
                    }}>
                        {formatKnownDataType(v, KnownDataType.DURATION_MILLISECONDS)}
                    </Typography>
                },
            },
            {
                id: 'target',
                label: 'Protection Target',
                getter: r => r,
                renderer: (v, r) => {
                    if (!!r.getRegisteredPhoenixDeployment()){
                        return <Link component={RouterLink}
                                     to={generatePhoenixDeploymentDetailsPath(r.getRegisteredPhoenixDeployment().getDeployment().getSystemId(), projectId)}>{r.getRegisteredPhoenixDeployment().getDeployment().getSystemName()}</Link>
                    }
                    return ''

                }
            }

        ];

        const getRowActions = (r: CirrusProtectDeploymentInfo) => ([
            {
                id: 'remove',
                name: `Remove Host From Project`,
                action: async () => {
                    const confirmed = await dialogService.addConfirmDialog({
                        message: 'Are you sure you want to remove this host from the project?',
                        autoConfirmationQuestionLine: false
                    })
                    if (confirmed){
                        const removed = await cpDeploymentService.removeCpDeployment(r.getDeployment().getSystemId())
                        if (removed) {
                            await cpDeploymentService.cpDeployments.fetchData();
                        }
                    }
                },
                hidden: !isOperator,
                icon: <DeleteIcon/>,
            }
        ])

        return <Box pt={2}>
            <DataTable state={cpDeploymentService.cpDeployments.tableState}
                       emptyTableTitle={'No Protection Hosts Found'}
                       emptyTableActionButton={<CirrusProtectDeploymentButton/>}
                       pagerMeta={cpDeploymentService.cpDeployments.data.getPagerMeta().toObject()}
                       rowActions={(r)=>getRowActions(r)}
                       rows={data.getItemsList()} cols={cols}/>
        </Box>
    })
});

// ======================
// CirrusProtectUnregisteredDeploymentsList
// ======================

interface CirrusProtectUnregisteredDeploymentsListProps {
    connectToPhoenixDialogState: DialogState;

}

const CirrusProtectUnregisteredDeploymentsList: React.FC<CirrusProtectUnregisteredDeploymentsListProps> = observer((p) => {
    const {cpDeploymentService} = useAppServices();
    const projectId = useCurrentProjectID();

    useInitData({
        poll: () => cpDeploymentService.unregisteredDeployments.fetchData(),
        pollInterval: 3
    });

    return renderServerDataWithLoadingList(cpDeploymentService.unregisteredDeployments, data => {
        if (data.getItemsList().length === 0) {
            return null;
        };

        const getCpDeployments = () => {
            const selectedDeployments = cpDeploymentService.unregisteredDeployments.tableState.selectedRows;
            const deployments: {id: string, name: string}[] = [];
            for (const d in selectedDeployments){
                const deployment = {
                    id: selectedDeployments[d].getDeployment().getSystemId(),
                    name: selectedDeployments[d].getDeployment().getSystemName()
                }
                deployments.push(deployment)
            }
            return deployments;
        }

        const selectedActions = <Box><Grid container spacing={2}>
                <Grid item>
                    <CpDeploymentConnectToPhoenixButton
                        cpDeployments={getCpDeployments()}
                        dialogState={p.connectToPhoenixDialogState} label={'Pair Protection Target'}
                        variant={'outlined'} color={'primary'}/>
                </Grid>
                <Grid item>
                    <Button variant={'outlined'} disabled>Use Hosts For Data Migration</Button>
                </Grid>
            </Grid>
        </Box>

        const cols: ColumnDef<CirrusProtectDeploymentInfo>[] = [
            {
                id: 'hostName',
                label: 'Host Name',
                getter: (r) => r.getDeployment().getSystemName(),
                renderer: (v, r) => {
                    return <Link component={RouterLink}
                                 to={generateCirrusProtectDeploymentDetailsPath(r.getDeployment().getSystemId(), projectId)}>{v}</Link>
                }

            },
            {
                id: 'version',
                label: 'Version',
                getter: (r) => r.getMtdiVersion(),
                renderer: (v, r) => <Typography
                    sx={{
                        color: r.getDeployment().getConnected() ? 'white' : '#757575'
                    }}>{v}</Typography>
            },
            {
                id: 'os',
                label: 'OS',
                getter: r => `${r.getOsClass()} - ${r.getKernel()}`,
                renderer: (os: string, r) => {
                    return <>
                        <Box display={'flex'}
                             sx={{
                                 color: r.getDeployment().getConnected() ? 'white' : '#757575'
                             }}>
                            <HostOSIcon os={os}/> &nbsp;&nbsp;&nbsp;&nbsp;
                            <div>{os}</div>
                        </Box>
                    </>
                }
            },
            {
                id: 'environment',
                label: 'Host Environment',
                getter: r => r.getDeployment().getHostEnvironment().getValue(),
                renderer: (env: DeploymentHostEnvironment.DeploymentHostEnvironment, r) => {
                    return <Typography
                        sx={{
                            color: r.getDeployment().getConnected() ? 'white' : '#757575'
                        }}>
                        {getHostEnvDisplayName(env)}
                    </Typography>
                }
            },
            {
                id: 'connected',
                label: 'Connected',
                getter: r => r.getDeployment().getConnected(),
                renderer: (connected, r) => {
                    return <Typography sx={{
                        color: r.getDeployment().getConnected() ? 'white' : '#757575'
                    }}>
                        {formatKnownDataType(connected, KnownDataType.BOOL)}
                    </Typography>
                },
            },
            {
                id: 'latency',
                label: 'Latency',
                getter: r => r.getDeployment().getConnectionLatency(),
                renderer: (v, r) => {
                    return <Typography sx={{
                        color: r.getDeployment().getConnected() ? 'white' : '#757575'
                    }}>
                        {formatKnownDataType(v, KnownDataType.DURATION_MILLISECONDS)}
                    </Typography>
                },
            },
            {
                id: 'target',
                label: 'Protection Target',
                getter: r => r,
                renderer: (v, r) => {
                    return <CpDeploymentConnectToPhoenixButton
                        disabled={!r.getDeployment().getConnected()}
                        cpDeployments={[{id: r.getDeployment().getSystemId(), name: r.getDeployment().getSystemName()}]}
                                                               dialogState={p.connectToPhoenixDialogState}
                                                               label={'Pair'} color={'secondary'}
                                                               variant={'outlined'}/>
                }
            }

        ];

        return <Box pt={2} pb={4}>
            <Box sx={{
                backgroundColor: 'rgb(25, 18, 7)',
                borderRadius: '5px',
                outline: '1px solid',
                outlineColor: (theme: Theme) => theme.palette.warning.main
            }}>
                <Box display={'flex'} p={2}>
                    <Box pr={1}>
                        <SvgIcon color={'warning'}>
                            <MdWarningAmber/>
                        </SvgIcon>
                    </Box>
                    <Box>
                        <AlertTitle>Incomplete Installation ({data.getItemsList().length})</AlertTitle>
                        A protection target must be connected to the following deployed hosts
                    </Box>
                </Box>

                <Box width={'100%'}>
                    <DataTable state={cpDeploymentService.unregisteredDeployments.tableState}
                               pagerMeta={cpDeploymentService.unregisteredDeployments.data.getPagerMeta().toObject()}
                               selectedActions={selectedActions}
                               rowItemLabel={'Hosts'}
                               rows={data.getItemsList()} cols={cols}
                               tableComponent={Box}
                    />
                </Box>
</Box>

            <br/>

        </Box>
    })
});


