import {observer} from "mobx-react-lite";
import React from "react";
import {Box, BoxProps, Card, Grid, Step, StepLabel, Stepper, Theme, SvgIcon, StepIcon, alpha} from "@mui/material";
import {makeAutoObservable} from "mobx";
import {MdCheck, MdCheckCircle} from "react-icons/md";

// ======================
// StepperWrapper
// ======================

export interface StepConfig {
    id: string,
    label: string,
    getDescription?: () => string,
    renderer: (stepConfigs?: StepConfig[], stepperState?: StepperState) => React.ReactNode,
    onClickNext?: Function;
}

interface StepperWrapperProps {
    stepConfigs: StepConfig[],
    stepperState: StepperState
    wrapperBoxProps?: Partial<BoxProps>
}

export const DynamicVerticalStepper: React.FC<StepperWrapperProps> = observer((p) => {

    return <Box {...p.wrapperBoxProps}>
        <Grid container spacing={3} sx={{height: '100%'}}>
            <Grid item xs={3}>
                <Card elevation={0} sx={{
                    backgroundColor: (t: Theme) => t.palette.cirrus.main
                }}>
                    <Box p={2}>
                        <Stepper activeStep={p.stepperState.activeStep} orientation={'vertical'} sx={{
                            backgroundColor: (t: Theme) => t.palette.cirrus.main
                        }}>
                            {p.stepConfigs.map((s, i) => {
                                return <Step key={i}>
                                    <StepLabel StepIconProps={{
                                        sx: {'&.Mui-completed': {
                                                color: (t: Theme)=> alpha(t.palette.primary.main, .5)
                                                }
                                        }
                                    }}>
                                        {s.label}
                                        <br/>
                                        {s.getDescription ? s.getDescription() : null}
                                    </StepLabel>
                                </Step>
                            })}
                        </Stepper>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={9}>
                {p.stepConfigs.find(v => p.stepConfigs.indexOf(v) === p.stepperState.activeStep).renderer(p.stepConfigs, p.stepperState)}
            </Grid>
        </Grid>
    </Box>
});

// ======================
// DynamicHorizontalStepper
// ======================

export const DynamicHorizontalStepper: React.FC<StepperWrapperProps> = observer((p) => {

    return <Box {...p.wrapperBoxProps}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card elevation={0} sx={{
                    backgroundColor: (t: Theme) => t.palette.cirrus.main
                }}>
                    <Box p={2}>
                        <Stepper activeStep={p.stepperState.activeStep} orientation={'horizontal'} sx={{
                            backgroundColor: (t: Theme) => t.palette.cirrus.main
                        }}>
                            {p.stepConfigs.map((s, i) => {
                                return <Step key={i}>
                                    <StepLabel StepIconProps={{
                                        color:'green',
                                        sx: {
                                            '& .MuiStepIcon-root': {
                                                '&.Mui-completed': {
                                                    color: 'red'
                                                }
                                            }
                                        }
                                    }}>
                                        {s.label}
                                        <br/>
                                        {s.getDescription ? s.getDescription() : null}
                                    </StepLabel>
                                </Step>
                            })}
                        </Stepper>
                    </Box>
                </Card>
            </Grid>
            <Grid item xs={12}>
                {p.stepConfigs.find(v => p.stepConfigs.indexOf(v) === p.stepperState.activeStep).renderer(p.stepConfigs, p.stepperState)}
            </Grid>
        </Grid>
    </Box>
})

export class StepperState {
    activeStep: number;
    stepConfigs: StepConfig[] = [];
    startingStep: number;
    totalSteps: number;

    constructor(startingStep?: number, stepConfigs?: StepConfig[], totalSteps?: number) {
        this.startingStep = startingStep || 0;
        this.activeStep = startingStep || 0;
        this.totalSteps = stepConfigs?.length || totalSteps;
        this.setStepConfigs(stepConfigs);
        makeAutoObservable(this)
    }

    setStartingStepIndex(index: number) {
        this.startingStep = index;
        this.activeStep = index;
    }

    goToNextStep() {
        this.activeStep = this.activeStep + 1
    }

    goBackOneStep() {
        this.activeStep = this.activeStep - 1
    }

    setStepConfigs(configs: StepConfig[]) {
        this.stepConfigs = configs;
        if (!!configs){
            this.setTotalSteps(configs.length)
        }
    }

    setTotalSteps(steps: number) {
        this.totalSteps = steps;
    }

    resetStepper() {
        this.activeStep = this.startingStep;
    }

    get isLastStep() {
        return this.activeStep === this.totalSteps - 1
    }

    get hasPreviousStep() {
        return this.activeStep - 1 >= this.startingStep;
    }
}