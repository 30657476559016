// source: galaxycompletepb/apipb/domainpb/cirrusprotect.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var galaxycompletepb_apipb_domainpb_project_pb = require('../../../galaxycompletepb/apipb/domainpb/project_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_project_pb);
var galaxycompletepb_apipb_domainpb_mtdi_pb = require('../../../galaxycompletepb/apipb/domainpb/mtdi_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_mtdi_pb);
var galaxycompletepb_apipb_domainpb_deployment_pb = require('../../../galaxycompletepb/apipb/domainpb/deployment_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_deployment_pb);
var galaxycompletepb_apipb_domainpb_phoenix_pb = require('../../../galaxycompletepb/apipb/domainpb/phoenix_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_phoenix_pb);
goog.exportSymbol('proto.galaxycomplete.domain.CirrusProtectDeploymentDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.CirrusProtectDeploymentInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.CirrusProtectDeviceInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.CirrusProtectDeploymentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.displayName = 'proto.galaxycomplete.domain.CirrusProtectDeploymentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.CirrusProtectDeploymentDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.CirrusProtectDeploymentDetails.displayName = 'proto.galaxycomplete.domain.CirrusProtectDeploymentDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.CirrusProtectDeviceInfo.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.CirrusProtectDeviceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.CirrusProtectDeviceInfo.displayName = 'proto.galaxycomplete.domain.CirrusProtectDeviceInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    deployment: (f = msg.getDeployment()) && galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentInfo.toObject(includeInstance, f),
    osType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    kernel: jspb.Message.getFieldWithDefault(msg, 6, ""),
    osClass: jspb.Message.getFieldWithDefault(msg, 7, ""),
    memory: jspb.Message.getFieldWithDefault(msg, 8, 0),
    memoryUsed: jspb.Message.getFieldWithDefault(msg, 9, 0),
    cpu: jspb.Message.getFieldWithDefault(msg, 10, ""),
    mtdiVersion: jspb.Message.getFieldWithDefault(msg, 11, ""),
    registeredPhoenixSystemId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    phoenixConnectionError: jspb.Message.getFieldWithDefault(msg, 21, ""),
    phoenixConnectionLatency: (f = msg.getPhoenixConnectionLatency()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    phoenixRegistrationUrl: jspb.Message.getFieldWithDefault(msg, 23, ""),
    phoenixRegistrationTime: (f = msg.getPhoenixRegistrationTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    registeredPhoenixDeployment: (f = msg.getRegisteredPhoenixDeployment()) && galaxycompletepb_apipb_domainpb_phoenix_pb.PhoenixDeploymentInfo.toObject(includeInstance, f),
    cdmPolicyId: jspb.Message.getFieldWithDefault(msg, 26, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.CirrusProtectDeploymentInfo;
  return proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentInfo.deserializeBinaryFromReader);
      msg.setDeployment(value);
      break;
    case 5:
      var value = /** @type {!proto.galaxycomplete.domain.MTDIDeploymentInfo.OSType} */ (reader.readEnum());
      msg.setOsType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setKernel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOsClass(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMemory(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMemoryUsed(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCpu(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMtdiVersion(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegisteredPhoenixSystemId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoenixConnectionError(value);
      break;
    case 22:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setPhoenixConnectionLatency(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoenixRegistrationUrl(value);
      break;
    case 24:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPhoenixRegistrationTime(value);
      break;
    case 25:
      var value = new galaxycompletepb_apipb_domainpb_phoenix_pb.PhoenixDeploymentInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_phoenix_pb.PhoenixDeploymentInfo.deserializeBinaryFromReader);
      msg.setRegisteredPhoenixDeployment(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCdmPolicyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeployment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentInfo.serializeBinaryToWriter
    );
  }
  f = message.getOsType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getKernel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOsClass();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMemory();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getMemoryUsed();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getCpu();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMtdiVersion();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRegisteredPhoenixSystemId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPhoenixConnectionError();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPhoenixConnectionLatency();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getPhoenixRegistrationUrl();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getPhoenixRegistrationTime();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRegisteredPhoenixDeployment();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      galaxycompletepb_apipb_domainpb_phoenix_pb.PhoenixDeploymentInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 26));
  if (f != null) {
    writer.writeInt32(
      26,
      f
    );
  }
};


/**
 * optional DeploymentInfo deployment = 1;
 * @return {?proto.galaxycomplete.domain.DeploymentInfo}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.getDeployment = function() {
  return /** @type{?proto.galaxycomplete.domain.DeploymentInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.DeploymentInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
*/
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.setDeployment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.clearDeployment = function() {
  return this.setDeployment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.hasDeployment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MTDIDeploymentInfo.OSType os_type = 5;
 * @return {!proto.galaxycomplete.domain.MTDIDeploymentInfo.OSType}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.getOsType = function() {
  return /** @type {!proto.galaxycomplete.domain.MTDIDeploymentInfo.OSType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.MTDIDeploymentInfo.OSType} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.setOsType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string kernel = 6;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.getKernel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.setKernel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string os_class = 7;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.getOsClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.setOsClass = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint64 memory = 8;
 * @return {number}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.getMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.setMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 memory_used = 9;
 * @return {number}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.getMemoryUsed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.setMemoryUsed = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string cpu = 10;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.getCpu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.setCpu = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string mtdi_version = 11;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.getMtdiVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.setMtdiVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string registered_phoenix_system_id = 20;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.getRegisteredPhoenixSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.setRegisteredPhoenixSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string phoenix_connection_error = 21;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.getPhoenixConnectionError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.setPhoenixConnectionError = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional google.protobuf.Duration phoenix_connection_latency = 22;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.getPhoenixConnectionLatency = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 22));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
*/
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.setPhoenixConnectionLatency = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.clearPhoenixConnectionLatency = function() {
  return this.setPhoenixConnectionLatency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.hasPhoenixConnectionLatency = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional string phoenix_registration_url = 23;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.getPhoenixRegistrationUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.setPhoenixRegistrationUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional google.protobuf.Timestamp phoenix_registration_time = 24;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.getPhoenixRegistrationTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 24));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
*/
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.setPhoenixRegistrationTime = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.clearPhoenixRegistrationTime = function() {
  return this.setPhoenixRegistrationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.hasPhoenixRegistrationTime = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional PhoenixDeploymentInfo registered_phoenix_deployment = 25;
 * @return {?proto.galaxycomplete.domain.PhoenixDeploymentInfo}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.getRegisteredPhoenixDeployment = function() {
  return /** @type{?proto.galaxycomplete.domain.PhoenixDeploymentInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_phoenix_pb.PhoenixDeploymentInfo, 25));
};


/**
 * @param {?proto.galaxycomplete.domain.PhoenixDeploymentInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
*/
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.setRegisteredPhoenixDeployment = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.clearRegisteredPhoenixDeployment = function() {
  return this.setRegisteredPhoenixDeployment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.hasRegisteredPhoenixDeployment = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional int32 cdm_policy_id = 26;
 * @return {number}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.getCdmPolicyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.setCdmPolicyId = function(value) {
  return jspb.Message.setField(this, 26, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.clearCdmPolicyId = function() {
  return jspb.Message.setField(this, 26, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.prototype.hasCdmPolicyId = function() {
  return jspb.Message.getField(this, 26) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.CirrusProtectDeploymentDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.CirrusProtectDeploymentDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentDetails}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.CirrusProtectDeploymentDetails;
  return proto.galaxycomplete.domain.CirrusProtectDeploymentDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.CirrusProtectDeploymentDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentDetails}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.CirrusProtectDeploymentInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.CirrusProtectDeploymentDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.CirrusProtectDeploymentDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.domain.CirrusProtectDeploymentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional CirrusProtectDeploymentInfo info = 1;
 * @return {?proto.galaxycomplete.domain.CirrusProtectDeploymentInfo}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentDetails.prototype.getInfo = function() {
  return /** @type{?proto.galaxycomplete.domain.CirrusProtectDeploymentInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.CirrusProtectDeploymentInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.CirrusProtectDeploymentInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentDetails} returns this
*/
proto.galaxycomplete.domain.CirrusProtectDeploymentDetails.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeploymentDetails} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentDetails.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CirrusProtectDeploymentDetails.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.CirrusProtectDeviceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicePath: jspb.Message.getFieldWithDefault(msg, 1, ""),
    device: jspb.Message.getFieldWithDefault(msg, 2, ""),
    capacity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    persistentIdentity: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deviceType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fsType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    label: jspb.Message.getFieldWithDefault(msg, 7, ""),
    mountPoint: jspb.Message.getFieldWithDefault(msg, 8, ""),
    vendor: jspb.Message.getFieldWithDefault(msg, 9, ""),
    model: jspb.Message.getFieldWithDefault(msg, 10, ""),
    serial: jspb.Message.getFieldWithDefault(msg, 11, ""),
    parentDevicesList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    existingClusterVolumeName: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.CirrusProtectDeviceInfo;
  return proto.galaxycomplete.domain.CirrusProtectDeviceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevice(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCapacity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersistentIdentity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFsType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMountPoint(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendor(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerial(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addParentDevices(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setExistingClusterVolumeName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.CirrusProtectDeviceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevice();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getPersistentIdentity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeviceType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFsType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMountPoint();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getVendor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSerial();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getParentDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getExistingClusterVolumeName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string device_path = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.getDevice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.setDevice = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 capacity = 3;
 * @return {number}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string persistent_identity = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.getPersistentIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.setPersistentIdentity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string device_type = 5;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.getDeviceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string fs_type = 6;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.getFsType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.setFsType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string label = 7;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string mount_point = 8;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.getMountPoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.setMountPoint = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string vendor = 9;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.getVendor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.setVendor = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string model = 10;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string serial = 11;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.getSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.setSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated string parent_devices = 12;
 * @return {!Array<string>}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.getParentDevicesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.setParentDevicesList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.addParentDevices = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.clearParentDevicesList = function() {
  return this.setParentDevicesList([]);
};


/**
 * optional string existing_cluster_volume_name = 13;
 * @return {string}
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.getExistingClusterVolumeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo} returns this
 */
proto.galaxycomplete.domain.CirrusProtectDeviceInfo.prototype.setExistingClusterVolumeName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


goog.object.extend(exports, proto.galaxycomplete.domain);
