// source: galaxycompletepb/apipb/cpapipb/cirrusprotect_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var galaxycompletepb_commonpb_datafilter_pb = require('../../../galaxycompletepb/commonpb/datafilter_pb.js');
goog.object.extend(proto, galaxycompletepb_commonpb_datafilter_pb);
var galaxycompletepb_apipb_domainpb_phoenix_pb = require('../../../galaxycompletepb/apipb/domainpb/phoenix_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_phoenix_pb);
var galaxycompletepb_apipb_domainpb_cirrusprotect_pb = require('../../../galaxycompletepb/apipb/domainpb/cirrusprotect_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_cirrusprotect_pb);
var google_api_annotations_pb = require('../../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var validate_validate_pb = require('../../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.CreatePolicy', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.CDMPolicyMode', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.Deregister', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.Deregister.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.Deregister.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.displayName = 'proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.displayName = 'proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response.displayName = 'proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.Deregister = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.Deregister, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.Deregister.displayName = 'proto.galaxycomplete.api.cirrusprotect.Deregister';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.Deregister.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.Deregister.Request.displayName = 'proto.galaxycomplete.api.cirrusprotect.Deregister.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.Deregister.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.Deregister.Response.displayName = 'proto.galaxycomplete.api.cirrusprotect.Deregister.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.displayName = 'proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request.displayName = 'proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.displayName = 'proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.CreatePolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.CreatePolicy.displayName = 'proto.galaxycomplete.api.cirrusprotect.CreatePolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.displayName = 'proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response.displayName = 'proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.displayName = 'proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.displayName = 'proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response.displayName = 'proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.displayName = 'proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.displayName = 'proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response.displayName = 'proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.displayName = 'proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request.displayName = 'proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.displayName = 'proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix}
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix;
  return proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix}
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    cirrusProtectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoenixId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phoenixAddress: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request}
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request;
  return proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request}
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCirrusProtectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoenixId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoenixAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCirrusProtectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoenixId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhoenixAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string cirrus_protect_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.prototype.getCirrusProtectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.prototype.setCirrusProtectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phoenix_id = 2;
 * @return {string}
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.prototype.getPhoenixId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.prototype.setPhoenixId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phoenix_address = 3;
 * @return {string}
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.prototype.getPhoenixAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Request.prototype.setPhoenixAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response}
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response;
  return proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response}
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.RegisterToPhoenix.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.Deregister.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.Deregister} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.Deregister}
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.Deregister;
  return proto.galaxycomplete.api.cirrusprotect.Deregister.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.Deregister} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.Deregister}
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.Deregister.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.Deregister} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.Deregister.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.Deregister.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    cirrusProtectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    force: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.Deregister.Request}
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.Deregister.Request;
  return proto.galaxycomplete.api.cirrusprotect.Deregister.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.Deregister.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.Deregister.Request}
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCirrusProtectId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForce(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.Deregister.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.Deregister.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCirrusProtectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getForce();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string cirrus_protect_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Request.prototype.getCirrusProtectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.Deregister.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Request.prototype.setCirrusProtectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool force = 2;
 * @return {boolean}
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Request.prototype.getForce = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.Deregister.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Request.prototype.setForce = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.Deregister.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.Deregister.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.Deregister.Response}
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.Deregister.Response;
  return proto.galaxycomplete.api.cirrusprotect.Deregister.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.Deregister.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.Deregister.Response}
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.Deregister.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.Deregister.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.Deregister.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates}
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates;
  return proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates}
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    cirrusProtectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request}
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request;
  return proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request}
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCirrusProtectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCirrusProtectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string cirrus_protect_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request.prototype.getCirrusProtectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Request.prototype.setCirrusProtectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    galaxycompletepb_apipb_domainpb_cirrusprotect_pb.CirrusProtectDeviceInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response}
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response;
  return proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response}
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_cirrusprotect_pb.CirrusProtectDeviceInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_cirrusprotect_pb.CirrusProtectDeviceInfo.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_cirrusprotect_pb.CirrusProtectDeviceInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated galaxycomplete.domain.CirrusProtectDeviceInfo devices = 1;
 * @return {!Array<!proto.galaxycomplete.domain.CirrusProtectDeviceInfo>}
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.CirrusProtectDeviceInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_cirrusprotect_pb.CirrusProtectDeviceInfo, 1));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.CirrusProtectDeviceInfo>} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response} returns this
*/
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.CirrusProtectDeviceInfo}
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxycomplete.domain.CirrusProtectDeviceInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response} returns this
 */
proto.galaxycomplete.api.cirrusprotect.ListInsertionCandidates.Response.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.CreatePolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy}
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.CreatePolicy;
  return proto.galaxycomplete.api.cirrusprotect.CreatePolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy}
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.CreatePolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    cirrusProtectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request}
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request;
  return proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request}
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCirrusProtectId(value);
      break;
    case 2:
      var value = /** @type {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.CDMPolicyMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCirrusProtectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.CDMPolicyMode = {
  PERIODIC: 0,
  CONTINUOUS: 1,
  CONTINUOUS_ASYNC: 2
};

/**
 * optional string cirrus_protect_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.prototype.getCirrusProtectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.prototype.setCirrusProtectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CDMPolicyMode mode = 2;
 * @return {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.CDMPolicyMode}
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.prototype.getMode = function() {
  return /** @type {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.CDMPolicyMode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.CDMPolicyMode} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Request.prototype.setMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response}
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response;
  return proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response}
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.CreatePolicy.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy}
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy;
  return proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy}
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    cirrusProtectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    devicePathsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request}
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request;
  return proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request}
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCirrusProtectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDevicePaths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCirrusProtectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevicePathsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string cirrus_protect_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.prototype.getCirrusProtectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.prototype.setCirrusProtectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string device_paths = 2;
 * @return {!Array<string>}
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.prototype.getDevicePathsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.prototype.setDevicePathsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.prototype.addDevicePaths = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Request.prototype.clearDevicePathsList = function() {
  return this.setDevicePathsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response}
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response;
  return proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response}
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.AddDisksToPolicy.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy}
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy;
  return proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy}
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    cirrusProtectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    devicePathsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request}
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request;
  return proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request}
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCirrusProtectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDevicePaths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCirrusProtectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevicePathsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string cirrus_protect_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.prototype.getCirrusProtectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.prototype.setCirrusProtectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string device_paths = 2;
 * @return {!Array<string>}
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.prototype.getDevicePathsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.prototype.setDevicePathsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.prototype.addDevicePaths = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Request.prototype.clearDevicePathsList = function() {
  return this.setDevicePathsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response}
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response;
  return proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response}
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.RemoveDisksFromPolicy.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails;
  return proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    cirrusProtectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request;
  return proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCirrusProtectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCirrusProtectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string cirrus_protect_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request.prototype.getCirrusProtectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request} returns this
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Request.prototype.setCirrusProtectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    policy: (f = msg.getPolicy()) && galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDetails.toObject(includeInstance, f),
    policyDisksList: jspb.Message.toObjectList(msg.getPolicyDisksList(),
    galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDisk.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response;
  return proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDetails;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDetails.deserializeBinaryFromReader);
      msg.setPolicy(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDisk;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDisk.deserializeBinaryFromReader);
      msg.addPolicyDisks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDetails.serializeBinaryToWriter
    );
  }
  f = message.getPolicyDisksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDisk.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.CDMPolicyDetails policy = 1;
 * @return {?proto.galaxycomplete.domain.CDMPolicyDetails}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.prototype.getPolicy = function() {
  return /** @type{?proto.galaxycomplete.domain.CDMPolicyDetails} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDetails, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.CDMPolicyDetails|undefined} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response} returns this
*/
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.prototype.setPolicy = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response} returns this
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.prototype.clearPolicy = function() {
  return this.setPolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.prototype.hasPolicy = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated galaxycomplete.domain.CDMPolicyDisk policy_disks = 2;
 * @return {!Array<!proto.galaxycomplete.domain.CDMPolicyDisk>}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.prototype.getPolicyDisksList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.CDMPolicyDisk>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_phoenix_pb.CDMPolicyDisk, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.CDMPolicyDisk>} value
 * @return {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response} returns this
*/
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.prototype.setPolicyDisksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.CDMPolicyDisk=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk}
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.prototype.addPolicyDisks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.CDMPolicyDisk, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response} returns this
 */
proto.galaxycomplete.api.cirrusprotect.GetPolicyDetails.Response.prototype.clearPolicyDisksList = function() {
  return this.setPolicyDisksList([]);
};


goog.object.extend(exports, proto.galaxycomplete.api.cirrusprotect);
