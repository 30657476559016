import React from "react";
import {observer} from "mobx-react-lite";
import {Box, Button, Card, CardContent, Dialog, DialogContent, DialogContentText, Divider, Theme} from "@mui/material";
import {ClipboardButton, ClipboardText} from "../../../common/clipboard/ClipboardComponents";
import {DialogState, useDialogState} from "../../core/dialog/DialogService";
import {useIsFeatureEnabled} from "../../core/featureflag/FeatureFlags";
import {FeatureFlag} from "../../app/AppGlobalService";
import {TabConfig, TabGroup} from "../../../common/tabs/TabComponents";
import {DiLinux, DiWindows} from "react-icons/di";
import {DialogTopBar} from "../../core/dialog/DialogComponents";
import {getApiHost} from "../../grpc/grpcCommon";
import {generateDeploymentCommand} from "../../deployment/NewDeploymentInstructions";
import {useCurrentProject} from "../../project/CurrentProject";

// ======================
// RegisterNewDeploymentButton
// ======================

interface CirrusProtectDeploymentButtonProps {
}

export const CirrusProtectDeploymentButton: React.FC<CirrusProtectDeploymentButtonProps> = observer((p) => {
    const mtdiDeploymentDialogState = useDialogState();

    return <><Button variant={'contained'} color={'secondary'} onClick={mtdiDeploymentDialogState.open}>
        Deploy Cirrus Protect Cloud
    </Button>
        <CirrusProtectDeploymentDialog dialogState={mtdiDeploymentDialogState}/>
    </>
})

// ======================
// MtdiDeploymenttDialogProps
// ======================

interface CirrusProtectDeploymentDialogProps {
    dialogState: DialogState;
}

const CirrusProtectDeploymentDialog: React.FC<CirrusProtectDeploymentDialogProps> = observer((p) => {
    const systemRegistrationCode = useCurrentProject().getInfo().getSystemRegistrationCode();


    const tabConfigs: TabConfig[] = [
        {
            label: 'Linux',
            icon: <DiLinux/>,
            renderer: () => {
                return <LinuxMtdiDeploymentInstructions registrationCode={systemRegistrationCode}/>
            }
        },
        {
            label: 'Windows',
            icon: <DiWindows/>,
            renderer: () => {
                return <WindowsMtdiDeploymentInstructions registrationCode={systemRegistrationCode}/>
            },
        }
    ]

    return <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} fullWidth maxWidth={'md'}>
        <DialogTopBar dialogState={p.dialogState} title={'Deploy mTDI'}/>
        <Divider/>
        <TabGroup configs={tabConfigs}
                  tabsProps={{variant: 'fullWidth', indicatorColor: 'primary', textColor: 'primary'}}/>
    </Dialog>
});

// ======================
// LinuxMtdiDeploymentInstructions
// ======================



interface LinuxMtdiDeploymentInstructionsProps {
    registrationCode: string
}

const LinuxMtdiDeploymentInstructions: React.FC<LinuxMtdiDeploymentInstructionsProps> = observer((p) => {
    const newRegisterId = 'registerMtdiDeploymentLinuxCommand';
    const uninstallId = 'uninstallMtdiLinuxCommand';

    return <DialogContent>
        <DialogContentText variant={'h4'} color={'textPrimary'}>
            Installation - Linux
        </DialogContentText>
        <DialogContentText variant={'caption'} color={'textPrimary'}>
            Run the following Command to register a Phoenix Server:
        </DialogContentText>
        <br/>
        <Card sx={{backgroundColor: (t:Theme)=>t.palette.background.default}}>
            <CardContent>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <ClipboardText clipboardId={newRegisterId}>
                        {generateDeploymentCommand('linux', 'install', p.registrationCode, getApiHost(), true)}
                    </ClipboardText>
                    <ClipboardButton clipboardId={newRegisterId} iconButton/>
                </Box>
            </CardContent>
        </Card>
        <br/>
        <DialogContentText variant={'h4'} color={'textPrimary'}>
            Uninstall - Linux
        </DialogContentText>
        <DialogContentText variant={'caption'} color={'textPrimary'}>
            Run the following Command to register a Phoenix Server:
        </DialogContentText>
        <br/>
        <Card sx={{backgroundColor: (t:Theme)=>t.palette.background.default}}>
            <CardContent>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <ClipboardText clipboardId={uninstallId}>
                        {generateDeploymentCommand('linux', 'uninstall', p.registrationCode, getApiHost(), true)}
                    </ClipboardText>
                    <ClipboardButton clipboardId={uninstallId} iconButton/>
                </Box>
            </CardContent>
        </Card>
    </DialogContent>
});

// ======================
// WindowsMtdiDeploymentInstructions
// ======================

interface WindowsMtdiDeploymentInstructionsProps {
    registrationCode: string

}

const WindowsMtdiDeploymentInstructions: React.FC<WindowsMtdiDeploymentInstructionsProps> = observer((p) => {

    const newRegisterId = 'registerMtdiDeploymentWindowsCommand';
    const uninstallId = 'uninstallMtdiWindowsCommand';

    return <DialogContent>
        <DialogContentText variant={'h4'} color={'textPrimary'}>
            Installation - Windows
        </DialogContentText>
        <DialogContentText variant={'caption'} color={'textPrimary'}>
            Run the following Command to register a Phoenix Server:
        </DialogContentText>
        <br/>
        <Card sx={{backgroundColor: (t:Theme)=>t.palette.background.default}}>
            <CardContent>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <ClipboardText clipboardId={newRegisterId}>
                        {generateDeploymentCommand('windows', 'install', p.registrationCode, getApiHost(), true)}
                    </ClipboardText>
                    <ClipboardButton clipboardId={newRegisterId} iconButton/>
                </Box>
            </CardContent>
        </Card>
        <br/>
        <DialogContentText variant={'h4'} color={'textPrimary'}>
            Uninstall - Windows
        </DialogContentText>
        <DialogContentText variant={'caption'} color={'textPrimary'}>
            Run the following Command to register a Phoenix Server:
        </DialogContentText>
        <br/>
        <Card sx={{backgroundColor: (t:Theme)=>t.palette.background.default}}>
            <CardContent>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <ClipboardText clipboardId={uninstallId}>
                        {generateDeploymentCommand('windows', 'uninstall', p.registrationCode, getApiHost(), true)}
                    </ClipboardText>
                    <ClipboardButton clipboardId={uninstallId} iconButton/>
                </Box>
            </CardContent>
        </Card>
    </DialogContent>
});