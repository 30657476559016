import {GRPCServices} from "../../grpc/grpcapi";
import {makeAutoObservable} from "mobx";
import {StepperState} from "../../../common/stepper/StepperComponents";
import {getCdmPolicyWizardStepConfigs} from "./wizard/CreateCdmPolicyWizard";
import {ServerListData} from "../../core/data/ListData";
import {
    GetCDMPolicyDetails,
    ListCDMPolicies,
    ListCDMPolicyDisks
} from "../../../_proto/galaxycompletepb/apipb/phoenixapipb/phoenix_api_pb";
import {CDMPolicy, CDMPolicyDetails, CDMPolicyDisk} from "../../../_proto/galaxycompletepb/apipb/domainpb/phoenix_pb";
import {ProjectService} from "../../project/ProjectService";
import {ServerData} from "../../core/data/ServerData";
import {mockCdmPolicy1, mockCdmPolicyList} from "../../core/testutils/fixtures/MockCdmPolicyService";
import {DialogService} from "../../core/dialog/DialogService";
import {ProgressService} from "../../core/progress/ProgressService";
import {
    CirrusProtectDeploymentInfo,
    CirrusProtectDeviceInfo
} from "../../../_proto/galaxycompletepb/apipb/domainpb/cirrusprotect_pb";
import {
    AddDisksToPolicy,
    CreatePolicy, GetPolicyDetails,
    ListInsertionCandidates
} from "../../../_proto/galaxycompletepb/apipb/cpapipb/cirrusprotect_api_pb";
import {GetCirrusProtectDeploymentDetails} from "../../../_proto/galaxycompletepb/apipb/deployment_api_pb";

export class CdmPolicyService{
    private api: GRPCServices;
    private projectService: ProjectService;
    private dialogService: DialogService;
    private progressService: ProgressService;

    cdmPolicies = new ServerListData<ListCDMPolicies.Response, CDMPolicy>().setDataFetcher(this.fetchCdmPolicies.bind(this));

    policyDetails = new ServerData<CDMPolicyDetails>().setDataFetcher(this.fetchCdmPolicyDetails.bind(this));

    policyDisks = new ServerListData<ListCDMPolicyDisks.Response, CDMPolicyDisk>().setDataFetcher(this.fetchCdmPolicyDisks.bind(this));

    constructor(api: GRPCServices, projectService: ProjectService, dialogService: DialogService, progressService: ProgressService){
        this.api = api;
        this.projectService = projectService;
        this.dialogService = dialogService;
        this.progressService = progressService;

        this.initWizardState();
        this.initTimeWalkerWizardState(this.currentPolicyId);
        makeAutoObservable(this)
    }

    policyWizardState: CdmPolicyWizardState;
    timeWalkerWizardState: CdmTimeWalkerWizardState;

    get currentPolicyId(){
        return this.policyDetails.data?.getPolicyInfo().getPolicyId()
    }

    initWizardState(startingStep?: number){
        this.policyWizardState = new CdmPolicyWizardState(this.api, startingStep);
        this.policyWizardState.stepperState.resetStepper();
        return this.policyWizardState;
    }

    initTimeWalkerWizardState(policyId: string){
        this.timeWalkerWizardState = new CdmTimeWalkerWizardState(this.api, policyId);
        return this.timeWalkerWizardState;
    }

    async fetchCdmPolicies(sysId?: string){
        const req = new ListCDMPolicies.Request()
            .setProjectId(this.projectService.currentProjectID)
            .setPageParams(this.cdmPolicies.pagerParam);

        // const req = new ListHostCDMPolicies.Request()
        //     .setSystemId(sysId)
        //return mockCdmPolicyList;
        return await this.api.phoenixService.listCDMPolicies(req, null);
    }

    async fetchCdmPolicyDetails(policyId: string){
        const req = new GetCDMPolicyDetails.Request()
            .setPolicyId(policyId)

        const response = await this.api.phoenixService.getCDMPolicyDetails(req, null);
        return response.getPolicy();
    };

    async fetchCdmPolicyDisks(policyId: string){
        const req = new ListCDMPolicyDisks.Request()
            .setPageParams(this.policyDisks.pagerParam)
            .setPolicyId(policyId)
        return await this.api.phoenixService.listCDMPolicyDisks(req, null);
    }

    async fetchCdmPolicySnapshots(policyId: string){
    }

    async deleteCdmPolicy(policyId: string){
        const req = new CreatePolicy()
    }

};

export class CdmPolicyWizardState{
    private api: GRPCServices;

    stepperState: StepperState;
    selectedServer: string;
    policyName: string = '';
    policyDescription: string = '';
    syncMode: CreatePolicy.Request.CDMPolicyMode;

    selectedHost: CirrusProtectDeploymentInfo;

    hostDetails = new ServerData<CirrusProtectDeploymentInfo>().setDataFetcher(this.getHostDetails.bind(this))

    insertionCandidates = new ServerListData<ListInsertionCandidates.Response, CirrusProtectDeviceInfo>().setDataFetcher(this.listInsertionCandidates.bind(this))
    selectedVolumes: {[key: string]: CirrusProtectDeviceInfo} = {};

    constructor (api: GRPCServices, startingStep?: number){
        this.stepperState = new StepperState(startingStep || 1, getCdmPolicyWizardStepConfigs(this) );
        this.api = api;
        makeAutoObservable(this)
    }

    setSelectedHost(deploymentInfo: CirrusProtectDeploymentInfo){
        this.selectedHost = deploymentInfo;
    }

    get selectedHostName(){
        return this.selectedHost?.getDeployment()?.getSystemName()
    }

    get selectedHostId(){
        return this.selectedHost?.getDeployment().getSystemId()
    }

    async getHostDetails(deploymentId: string){
        const req = new GetCirrusProtectDeploymentDetails.Request()
            .setSystemId(deploymentId)
        const response = await this.api.deploymentService.getCirrusProtectDeploymentDetails(req, null);
        return response.getDeployment().getInfo();
    }

    addSelectedVolume(vol: CirrusProtectDeviceInfo){
        this.selectedVolumes[vol.getDevicePath()] = vol;
    }

    removeSelectedVolume(vol: CirrusProtectDeviceInfo){
        delete this.selectedVolumes[vol.getDevicePath()]
    }

    setServer(serverId: string){
        this.selectedServer = serverId;
    }

    setPolicyName(name: string){
        this.policyName = name;
    }

    setPolicyDescription(description: string){
        this.policyDescription = description;
    }

    setSyncMode(mode: CreatePolicy.Request.CDMPolicyMode){
        this.syncMode = mode;
    }

    async listInsertionCandidates(deploymentId?: string){
        const req = new ListInsertionCandidates.Request()
            .setCirrusProtectId(deploymentId || this.selectedHost?.getDeployment()?.getSystemId())

        return await this.api.cpService.listInsertionCandidates(req, null);
    }

    async createPolicy(cirrusProtectId: string){
        const createPolicyReq = new CreatePolicy.Request()
            .setCirrusProtectId(cirrusProtectId || this.selectedHostId)
            .setMode(this.syncMode)

        const addDisksReq = new AddDisksToPolicy.Request()
            .setCirrusProtectId(cirrusProtectId || this.selectedHostId)
            .setDevicePathsList(Object.keys(this.selectedVolumes))
        await this.api.cpService.createPolicy(createPolicyReq, null);
        await this.api.cpService.addDisksToPolicy(addDisksReq, null)
    }

};

export enum TimewalkerWizardStep {
    COPY_METHOD_SELECTION,
    VOLUME_SELECTION,
    DATA_IMAGE_TYPE,
    SNAPSHOT_DATA,
    CDP_PROTECTED_DATA,
    SOURCE_VOLUME_RESTORATION,
    ROLLBACK_FINAL_REVIEW,
    VOLUMEWIKI_FINAL_REVIEW

}

export class CdmTimeWalkerWizardState {
    private api: GRPCServices;

    stepperState: StepperState;
    selectedCopyMethod: any = null;
    selectedVolumes: {[key:string]: CDMPolicyDisk} = {};
    selectedDataImageType: any = null;
    currentStep: TimewalkerWizardStep;
    fullSourceVolumesRestore: boolean = false;
    selectedSnapshot: any = null;
    selectedCdpDataPoint: any = null;
    policyId: string;

    constructor (api: GRPCServices, policyId?: string){
        this.currentStep = TimewalkerWizardStep.COPY_METHOD_SELECTION;
        //this.stepperState = new StepperState(0, null);
        this.api = api;
        this.policyId = policyId;
        makeAutoObservable(this);
    }

    setPolicyId(id: string){
        this.policyId = id;
    }

    goToVolumeSelection(){
        this.currentStep = TimewalkerWizardStep.VOLUME_SELECTION
    }

    goToCopyMethod(){
        this.currentStep = TimewalkerWizardStep.COPY_METHOD_SELECTION
    }

    goToDataImageType(){
        this.currentStep = TimewalkerWizardStep.DATA_IMAGE_TYPE
    }

    goToSnapshotData(){
        this.currentStep = TimewalkerWizardStep.SNAPSHOT_DATA
    }

    goToCdpProtectedData(){
        this.currentStep = TimewalkerWizardStep.CDP_PROTECTED_DATA
    }

    goToSourceVolumeRestoration(){
        this.currentStep = TimewalkerWizardStep.SOURCE_VOLUME_RESTORATION
    }

    goToRollbackFinalReview(){
        this.currentStep = TimewalkerWizardStep.ROLLBACK_FINAL_REVIEW
    }

    goToVolumeWikiFinalReview(){
        this.currentStep = TimewalkerWizardStep.VOLUMEWIKI_FINAL_REVIEW
    }

    selectCopyMethod(method: any){
        this.selectedCopyMethod = method;
    }

    selectVolume(volume: CDMPolicyDisk){
        this.selectedVolumes[volume.getPolicyDiskUuid()] = volume;
    }

    selectDataImageType(type: any){
        this.selectedDataImageType = type;
    }

    selectPolicySnapshot(snapshot: any){
        this.selectedSnapshot = snapshot;
    }

    selectCdpDataPoint(time: any){
        this.selectedCdpDataPoint = time;
    }

    toggleFullSourceVolumesRestore(){
        this.fullSourceVolumesRestore = !this.fullSourceVolumesRestore
    }


}