// source: galaxymigratepb/galaxy_migrate_storage.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.galaxymigrate.BlockDeviceInfo', null, global);
goog.exportSymbol('proto.galaxymigrate.EnlistLocalDeviceRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.EnlistLocalDeviceResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.EnlistedStorageResourceVolume', null, global);
goog.exportSymbol('proto.galaxymigrate.GetEnlistedStorageResourcesRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.GetEnlistedStorageResourcesResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.GetLvmConfigRawOutputsRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.GetLvmConfigRawOutputsResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.GetRemoteStorageResourcesRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.GetRemoteStorageResourcesResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.IOStatsCollector', null, global);
goog.exportSymbol('proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint', null, global);
goog.exportSymbol('proto.galaxymigrate.IOStatsCollector.LiveDataPoint', null, global);
goog.exportSymbol('proto.galaxymigrate.IOStatsCollector.Stats', null, global);
goog.exportSymbol('proto.galaxymigrate.IOStatsDataSet', null, global);
goog.exportSymbol('proto.galaxymigrate.IOStatsDataSet.Domain', null, global);
goog.exportSymbol('proto.galaxymigrate.IOStatsDataSet.Point', null, global);
goog.exportSymbol('proto.galaxymigrate.IOStatsDataSet.Series', null, global);
goog.exportSymbol('proto.galaxymigrate.IOStatsDataSet.Stats', null, global);
goog.exportSymbol('proto.galaxymigrate.IOStatsDataSet.TimeDomain', null, global);
goog.exportSymbol('proto.galaxymigrate.ListMyRemoteStorageResourcesRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.ListMyRemoteStorageResourcesResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.ReleaseLocalDeviceRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.ReleaseLocalDeviceResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetLvmConfigRawOutputsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetLvmConfigRawOutputsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetLvmConfigRawOutputsRequest.displayName = 'proto.galaxymigrate.GetLvmConfigRawOutputsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetLvmConfigRawOutputsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetLvmConfigRawOutputsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetLvmConfigRawOutputsResponse.displayName = 'proto.galaxymigrate.GetLvmConfigRawOutputsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest.displayName = 'proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.displayName = 'proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetEnlistedStorageResourcesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetEnlistedStorageResourcesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetEnlistedStorageResourcesRequest.displayName = 'proto.galaxymigrate.GetEnlistedStorageResourcesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetEnlistedStorageResourcesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.GetEnlistedStorageResourcesResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.GetEnlistedStorageResourcesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetEnlistedStorageResourcesResponse.displayName = 'proto.galaxymigrate.GetEnlistedStorageResourcesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.EnlistedStorageResourceVolume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.EnlistedStorageResourceVolume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.EnlistedStorageResourceVolume.displayName = 'proto.galaxymigrate.EnlistedStorageResourceVolume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.EnlistLocalDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.EnlistLocalDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.EnlistLocalDeviceRequest.displayName = 'proto.galaxymigrate.EnlistLocalDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.EnlistLocalDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.EnlistLocalDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.EnlistLocalDeviceResponse.displayName = 'proto.galaxymigrate.EnlistLocalDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.ReleaseLocalDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.ReleaseLocalDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.ReleaseLocalDeviceRequest.displayName = 'proto.galaxymigrate.ReleaseLocalDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.ReleaseLocalDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.ReleaseLocalDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.ReleaseLocalDeviceResponse.displayName = 'proto.galaxymigrate.ReleaseLocalDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetRemoteStorageResourcesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetRemoteStorageResourcesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetRemoteStorageResourcesRequest.displayName = 'proto.galaxymigrate.GetRemoteStorageResourcesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetRemoteStorageResourcesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.GetRemoteStorageResourcesResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.GetRemoteStorageResourcesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetRemoteStorageResourcesResponse.displayName = 'proto.galaxymigrate.GetRemoteStorageResourcesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.ListMyRemoteStorageResourcesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.ListMyRemoteStorageResourcesRequest.displayName = 'proto.galaxymigrate.ListMyRemoteStorageResourcesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.ListMyRemoteStorageResourcesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.displayName = 'proto.galaxymigrate.ListMyRemoteStorageResourcesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.displayName = 'proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse.displayName = 'proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.BlockDeviceInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.BlockDeviceInfo.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.BlockDeviceInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.BlockDeviceInfo.displayName = 'proto.galaxymigrate.BlockDeviceInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.IOStatsCollector = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.IOStatsCollector, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.IOStatsCollector.displayName = 'proto.galaxymigrate.IOStatsCollector';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.IOStatsCollector.LiveDataPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.IOStatsCollector.LiveDataPoint.displayName = 'proto.galaxymigrate.IOStatsCollector.LiveDataPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.displayName = 'proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.IOStatsCollector.Stats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.IOStatsCollector.Stats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.IOStatsCollector.Stats.displayName = 'proto.galaxymigrate.IOStatsCollector.Stats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.IOStatsDataSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.IOStatsDataSet.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.IOStatsDataSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.IOStatsDataSet.displayName = 'proto.galaxymigrate.IOStatsDataSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.IOStatsDataSet.Series = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.IOStatsDataSet.Series.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.IOStatsDataSet.Series, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.IOStatsDataSet.Series.displayName = 'proto.galaxymigrate.IOStatsDataSet.Series';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.IOStatsDataSet.TimeDomain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.IOStatsDataSet.TimeDomain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.IOStatsDataSet.TimeDomain.displayName = 'proto.galaxymigrate.IOStatsDataSet.TimeDomain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.IOStatsDataSet.Domain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.IOStatsDataSet.Domain.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.IOStatsDataSet.Domain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.IOStatsDataSet.Domain.displayName = 'proto.galaxymigrate.IOStatsDataSet.Domain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.IOStatsDataSet.Point = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.IOStatsDataSet.Point, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.IOStatsDataSet.Point.displayName = 'proto.galaxymigrate.IOStatsDataSet.Point';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.IOStatsDataSet.Stats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.IOStatsDataSet.Stats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.IOStatsDataSet.Stats.displayName = 'proto.galaxymigrate.IOStatsDataSet.Stats';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetLvmConfigRawOutputsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetLvmConfigRawOutputsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetLvmConfigRawOutputsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetLvmConfigRawOutputsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetLvmConfigRawOutputsRequest}
 */
proto.galaxymigrate.GetLvmConfigRawOutputsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetLvmConfigRawOutputsRequest;
  return proto.galaxymigrate.GetLvmConfigRawOutputsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetLvmConfigRawOutputsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetLvmConfigRawOutputsRequest}
 */
proto.galaxymigrate.GetLvmConfigRawOutputsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetLvmConfigRawOutputsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetLvmConfigRawOutputsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetLvmConfigRawOutputsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetLvmConfigRawOutputsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetLvmConfigRawOutputsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetLvmConfigRawOutputsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetLvmConfigRawOutputsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetLvmConfigRawOutputsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vgs: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lvs: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pvs: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetLvmConfigRawOutputsResponse}
 */
proto.galaxymigrate.GetLvmConfigRawOutputsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetLvmConfigRawOutputsResponse;
  return proto.galaxymigrate.GetLvmConfigRawOutputsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetLvmConfigRawOutputsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetLvmConfigRawOutputsResponse}
 */
proto.galaxymigrate.GetLvmConfigRawOutputsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVgs(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLvs(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPvs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetLvmConfigRawOutputsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetLvmConfigRawOutputsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetLvmConfigRawOutputsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetLvmConfigRawOutputsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVgs();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLvs();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPvs();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string vgs = 1;
 * @return {string}
 */
proto.galaxymigrate.GetLvmConfigRawOutputsResponse.prototype.getVgs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetLvmConfigRawOutputsResponse} returns this
 */
proto.galaxymigrate.GetLvmConfigRawOutputsResponse.prototype.setVgs = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string lvs = 2;
 * @return {string}
 */
proto.galaxymigrate.GetLvmConfigRawOutputsResponse.prototype.getLvs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetLvmConfigRawOutputsResponse} returns this
 */
proto.galaxymigrate.GetLvmConfigRawOutputsResponse.prototype.setLvs = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pvs = 3;
 * @return {string}
 */
proto.galaxymigrate.GetLvmConfigRawOutputsResponse.prototype.getPvs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetLvmConfigRawOutputsResponse} returns this
 */
proto.galaxymigrate.GetLvmConfigRawOutputsResponse.prototype.setPvs = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest}
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest;
  return proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest}
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    localBlockDevicesList: jspb.Message.toObjectList(msg.getLocalBlockDevicesList(),
    proto.galaxymigrate.BlockDeviceInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse}
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse;
  return proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse}
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.BlockDeviceInfo;
      reader.readMessage(value,proto.galaxymigrate.BlockDeviceInfo.deserializeBinaryFromReader);
      msg.addLocalBlockDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocalBlockDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxymigrate.BlockDeviceInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BlockDeviceInfo local_block_devices = 1;
 * @return {!Array<!proto.galaxymigrate.BlockDeviceInfo>}
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.prototype.getLocalBlockDevicesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.BlockDeviceInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.BlockDeviceInfo, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.BlockDeviceInfo>} value
 * @return {!proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse} returns this
*/
proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.prototype.setLocalBlockDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.BlockDeviceInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.BlockDeviceInfo}
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.prototype.addLocalBlockDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.BlockDeviceInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse} returns this
 */
proto.galaxymigrate.GetStorageResourceEnlistCandidatesResponse.prototype.clearLocalBlockDevicesList = function() {
  return this.setLocalBlockDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetEnlistedStorageResourcesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetEnlistedStorageResourcesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetEnlistedStorageResourcesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetEnlistedStorageResourcesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetEnlistedStorageResourcesRequest}
 */
proto.galaxymigrate.GetEnlistedStorageResourcesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetEnlistedStorageResourcesRequest;
  return proto.galaxymigrate.GetEnlistedStorageResourcesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetEnlistedStorageResourcesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetEnlistedStorageResourcesRequest}
 */
proto.galaxymigrate.GetEnlistedStorageResourcesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetEnlistedStorageResourcesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetEnlistedStorageResourcesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetEnlistedStorageResourcesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetEnlistedStorageResourcesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.GetEnlistedStorageResourcesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetEnlistedStorageResourcesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetEnlistedStorageResourcesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetEnlistedStorageResourcesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetEnlistedStorageResourcesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    volumesList: jspb.Message.toObjectList(msg.getVolumesList(),
    proto.galaxymigrate.EnlistedStorageResourceVolume.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetEnlistedStorageResourcesResponse}
 */
proto.galaxymigrate.GetEnlistedStorageResourcesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetEnlistedStorageResourcesResponse;
  return proto.galaxymigrate.GetEnlistedStorageResourcesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetEnlistedStorageResourcesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetEnlistedStorageResourcesResponse}
 */
proto.galaxymigrate.GetEnlistedStorageResourcesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.EnlistedStorageResourceVolume;
      reader.readMessage(value,proto.galaxymigrate.EnlistedStorageResourceVolume.deserializeBinaryFromReader);
      msg.addVolumes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetEnlistedStorageResourcesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetEnlistedStorageResourcesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetEnlistedStorageResourcesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetEnlistedStorageResourcesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxymigrate.EnlistedStorageResourceVolume.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EnlistedStorageResourceVolume volumes = 1;
 * @return {!Array<!proto.galaxymigrate.EnlistedStorageResourceVolume>}
 */
proto.galaxymigrate.GetEnlistedStorageResourcesResponse.prototype.getVolumesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.EnlistedStorageResourceVolume>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.EnlistedStorageResourceVolume, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.EnlistedStorageResourceVolume>} value
 * @return {!proto.galaxymigrate.GetEnlistedStorageResourcesResponse} returns this
*/
proto.galaxymigrate.GetEnlistedStorageResourcesResponse.prototype.setVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.EnlistedStorageResourceVolume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.EnlistedStorageResourceVolume}
 */
proto.galaxymigrate.GetEnlistedStorageResourcesResponse.prototype.addVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.EnlistedStorageResourceVolume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.GetEnlistedStorageResourcesResponse} returns this
 */
proto.galaxymigrate.GetEnlistedStorageResourcesResponse.prototype.clearVolumesList = function() {
  return this.setVolumesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.EnlistedStorageResourceVolume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.EnlistedStorageResourceVolume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    devicePath: jspb.Message.getFieldWithDefault(msg, 3, ""),
    blockDevice: (f = msg.getBlockDevice()) && proto.galaxymigrate.BlockDeviceInfo.toObject(includeInstance, f),
    forSystemId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fromSystemId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    usedByPolicy: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.EnlistedStorageResourceVolume}
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.EnlistedStorageResourceVolume;
  return proto.galaxymigrate.EnlistedStorageResourceVolume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.EnlistedStorageResourceVolume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.EnlistedStorageResourceVolume}
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    case 4:
      var value = new proto.galaxymigrate.BlockDeviceInfo;
      reader.readMessage(value,proto.galaxymigrate.BlockDeviceInfo.deserializeBinaryFromReader);
      msg.setBlockDevice(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setForSystemId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromSystemId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsedByPolicy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.EnlistedStorageResourceVolume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.EnlistedStorageResourceVolume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBlockDevice();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.galaxymigrate.BlockDeviceInfo.serializeBinaryToWriter
    );
  }
  f = message.getForSystemId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFromSystemId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUsedByPolicy();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string node_id = 1;
 * @return {string}
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.EnlistedStorageResourceVolume} returns this
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uuid = 2;
 * @return {string}
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.EnlistedStorageResourceVolume} returns this
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string device_path = 3;
 * @return {string}
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.EnlistedStorageResourceVolume} returns this
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional BlockDeviceInfo block_device = 4;
 * @return {?proto.galaxymigrate.BlockDeviceInfo}
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.getBlockDevice = function() {
  return /** @type{?proto.galaxymigrate.BlockDeviceInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.BlockDeviceInfo, 4));
};


/**
 * @param {?proto.galaxymigrate.BlockDeviceInfo|undefined} value
 * @return {!proto.galaxymigrate.EnlistedStorageResourceVolume} returns this
*/
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.setBlockDevice = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.EnlistedStorageResourceVolume} returns this
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.clearBlockDevice = function() {
  return this.setBlockDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.hasBlockDevice = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string for_system_id = 5;
 * @return {string}
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.getForSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.EnlistedStorageResourceVolume} returns this
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.setForSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string from_system_id = 6;
 * @return {string}
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.getFromSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.EnlistedStorageResourceVolume} returns this
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.setFromSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string used_by_policy = 10;
 * @return {string}
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.getUsedByPolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.EnlistedStorageResourceVolume} returns this
 */
proto.galaxymigrate.EnlistedStorageResourceVolume.prototype.setUsedByPolicy = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.EnlistLocalDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.EnlistLocalDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.EnlistLocalDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.EnlistLocalDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    devicePath: jspb.Message.getFieldWithDefault(msg, 2, ""),
    remoteSystemId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.EnlistLocalDeviceRequest}
 */
proto.galaxymigrate.EnlistLocalDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.EnlistLocalDeviceRequest;
  return proto.galaxymigrate.EnlistLocalDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.EnlistLocalDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.EnlistLocalDeviceRequest}
 */
proto.galaxymigrate.EnlistLocalDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNodeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemoteSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.EnlistLocalDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.EnlistLocalDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.EnlistLocalDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.EnlistLocalDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRemoteSystemId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string node_id = 1;
 * @return {string}
 */
proto.galaxymigrate.EnlistLocalDeviceRequest.prototype.getNodeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.EnlistLocalDeviceRequest} returns this
 */
proto.galaxymigrate.EnlistLocalDeviceRequest.prototype.setNodeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string device_path = 2;
 * @return {string}
 */
proto.galaxymigrate.EnlistLocalDeviceRequest.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.EnlistLocalDeviceRequest} returns this
 */
proto.galaxymigrate.EnlistLocalDeviceRequest.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string remote_system_id = 3;
 * @return {string}
 */
proto.galaxymigrate.EnlistLocalDeviceRequest.prototype.getRemoteSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.EnlistLocalDeviceRequest} returns this
 */
proto.galaxymigrate.EnlistLocalDeviceRequest.prototype.setRemoteSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.EnlistLocalDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.EnlistLocalDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.EnlistLocalDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.EnlistLocalDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    volume: (f = msg.getVolume()) && proto.galaxymigrate.EnlistedStorageResourceVolume.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.EnlistLocalDeviceResponse}
 */
proto.galaxymigrate.EnlistLocalDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.EnlistLocalDeviceResponse;
  return proto.galaxymigrate.EnlistLocalDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.EnlistLocalDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.EnlistLocalDeviceResponse}
 */
proto.galaxymigrate.EnlistLocalDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.EnlistedStorageResourceVolume;
      reader.readMessage(value,proto.galaxymigrate.EnlistedStorageResourceVolume.deserializeBinaryFromReader);
      msg.setVolume(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.EnlistLocalDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.EnlistLocalDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.EnlistLocalDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.EnlistLocalDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVolume();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.EnlistedStorageResourceVolume.serializeBinaryToWriter
    );
  }
};


/**
 * optional EnlistedStorageResourceVolume volume = 1;
 * @return {?proto.galaxymigrate.EnlistedStorageResourceVolume}
 */
proto.galaxymigrate.EnlistLocalDeviceResponse.prototype.getVolume = function() {
  return /** @type{?proto.galaxymigrate.EnlistedStorageResourceVolume} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.EnlistedStorageResourceVolume, 1));
};


/**
 * @param {?proto.galaxymigrate.EnlistedStorageResourceVolume|undefined} value
 * @return {!proto.galaxymigrate.EnlistLocalDeviceResponse} returns this
*/
proto.galaxymigrate.EnlistLocalDeviceResponse.prototype.setVolume = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.EnlistLocalDeviceResponse} returns this
 */
proto.galaxymigrate.EnlistLocalDeviceResponse.prototype.clearVolume = function() {
  return this.setVolume(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.EnlistLocalDeviceResponse.prototype.hasVolume = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.ReleaseLocalDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.ReleaseLocalDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.ReleaseLocalDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ReleaseLocalDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicePath: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.ReleaseLocalDeviceRequest}
 */
proto.galaxymigrate.ReleaseLocalDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.ReleaseLocalDeviceRequest;
  return proto.galaxymigrate.ReleaseLocalDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.ReleaseLocalDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.ReleaseLocalDeviceRequest}
 */
proto.galaxymigrate.ReleaseLocalDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.ReleaseLocalDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.ReleaseLocalDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.ReleaseLocalDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ReleaseLocalDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicePath();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_path = 1;
 * @return {string}
 */
proto.galaxymigrate.ReleaseLocalDeviceRequest.prototype.getDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.ReleaseLocalDeviceRequest} returns this
 */
proto.galaxymigrate.ReleaseLocalDeviceRequest.prototype.setDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.ReleaseLocalDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.ReleaseLocalDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.ReleaseLocalDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ReleaseLocalDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.ReleaseLocalDeviceResponse}
 */
proto.galaxymigrate.ReleaseLocalDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.ReleaseLocalDeviceResponse;
  return proto.galaxymigrate.ReleaseLocalDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.ReleaseLocalDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.ReleaseLocalDeviceResponse}
 */
proto.galaxymigrate.ReleaseLocalDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.ReleaseLocalDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.ReleaseLocalDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.ReleaseLocalDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ReleaseLocalDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetRemoteStorageResourcesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetRemoteStorageResourcesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetRemoteStorageResourcesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetRemoteStorageResourcesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    remoteSystemIdFilter: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetRemoteStorageResourcesRequest}
 */
proto.galaxymigrate.GetRemoteStorageResourcesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetRemoteStorageResourcesRequest;
  return proto.galaxymigrate.GetRemoteStorageResourcesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetRemoteStorageResourcesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetRemoteStorageResourcesRequest}
 */
proto.galaxymigrate.GetRemoteStorageResourcesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemoteSystemIdFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetRemoteStorageResourcesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetRemoteStorageResourcesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetRemoteStorageResourcesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetRemoteStorageResourcesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRemoteSystemIdFilter();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string remote_system_id_filter = 1;
 * @return {string}
 */
proto.galaxymigrate.GetRemoteStorageResourcesRequest.prototype.getRemoteSystemIdFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetRemoteStorageResourcesRequest} returns this
 */
proto.galaxymigrate.GetRemoteStorageResourcesRequest.prototype.setRemoteSystemIdFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.GetRemoteStorageResourcesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetRemoteStorageResourcesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetRemoteStorageResourcesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetRemoteStorageResourcesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetRemoteStorageResourcesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    volumesList: jspb.Message.toObjectList(msg.getVolumesList(),
    proto.galaxymigrate.EnlistedStorageResourceVolume.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetRemoteStorageResourcesResponse}
 */
proto.galaxymigrate.GetRemoteStorageResourcesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetRemoteStorageResourcesResponse;
  return proto.galaxymigrate.GetRemoteStorageResourcesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetRemoteStorageResourcesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetRemoteStorageResourcesResponse}
 */
proto.galaxymigrate.GetRemoteStorageResourcesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.EnlistedStorageResourceVolume;
      reader.readMessage(value,proto.galaxymigrate.EnlistedStorageResourceVolume.deserializeBinaryFromReader);
      msg.addVolumes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetRemoteStorageResourcesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetRemoteStorageResourcesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetRemoteStorageResourcesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetRemoteStorageResourcesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxymigrate.EnlistedStorageResourceVolume.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EnlistedStorageResourceVolume volumes = 1;
 * @return {!Array<!proto.galaxymigrate.EnlistedStorageResourceVolume>}
 */
proto.galaxymigrate.GetRemoteStorageResourcesResponse.prototype.getVolumesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.EnlistedStorageResourceVolume>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.EnlistedStorageResourceVolume, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.EnlistedStorageResourceVolume>} value
 * @return {!proto.galaxymigrate.GetRemoteStorageResourcesResponse} returns this
*/
proto.galaxymigrate.GetRemoteStorageResourcesResponse.prototype.setVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.EnlistedStorageResourceVolume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.EnlistedStorageResourceVolume}
 */
proto.galaxymigrate.GetRemoteStorageResourcesResponse.prototype.addVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.EnlistedStorageResourceVolume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.GetRemoteStorageResourcesResponse} returns this
 */
proto.galaxymigrate.GetRemoteStorageResourcesResponse.prototype.clearVolumesList = function() {
  return this.setVolumesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.ListMyRemoteStorageResourcesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.ListMyRemoteStorageResourcesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.ListMyRemoteStorageResourcesRequest}
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.ListMyRemoteStorageResourcesRequest;
  return proto.galaxymigrate.ListMyRemoteStorageResourcesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.ListMyRemoteStorageResourcesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.ListMyRemoteStorageResourcesRequest}
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.ListMyRemoteStorageResourcesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.ListMyRemoteStorageResourcesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.ListMyRemoteStorageResourcesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    volumesList: jspb.Message.toObjectList(msg.getVolumesList(),
    proto.galaxymigrate.EnlistedStorageResourceVolume.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.ListMyRemoteStorageResourcesResponse}
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.ListMyRemoteStorageResourcesResponse;
  return proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.ListMyRemoteStorageResourcesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.ListMyRemoteStorageResourcesResponse}
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.EnlistedStorageResourceVolume;
      reader.readMessage(value,proto.galaxymigrate.EnlistedStorageResourceVolume.deserializeBinaryFromReader);
      msg.addVolumes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.ListMyRemoteStorageResourcesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxymigrate.EnlistedStorageResourceVolume.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EnlistedStorageResourceVolume volumes = 1;
 * @return {!Array<!proto.galaxymigrate.EnlistedStorageResourceVolume>}
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.prototype.getVolumesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.EnlistedStorageResourceVolume>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.EnlistedStorageResourceVolume, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.EnlistedStorageResourceVolume>} value
 * @return {!proto.galaxymigrate.ListMyRemoteStorageResourcesResponse} returns this
*/
proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.prototype.setVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.EnlistedStorageResourceVolume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.EnlistedStorageResourceVolume}
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.prototype.addVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.EnlistedStorageResourceVolume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.ListMyRemoteStorageResourcesResponse} returns this
 */
proto.galaxymigrate.ListMyRemoteStorageResourcesResponse.prototype.clearVolumesList = function() {
  return this.setVolumesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    volUuidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    policyUuid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest}
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest;
  return proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest}
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addVolUuids(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVolUuidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getPolicyUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated string vol_uuids = 1;
 * @return {!Array<string>}
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.prototype.getVolUuidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest} returns this
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.prototype.setVolUuidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest} returns this
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.prototype.addVolUuids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest} returns this
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.prototype.clearVolUuidsList = function() {
  return this.setVolUuidsList([]);
};


/**
 * optional string policy_uuid = 2;
 * @return {string}
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.prototype.getPolicyUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest} returns this
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyRequest.prototype.setPolicyUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse}
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse;
  return proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse}
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MarkStorageResourcesUsedByPolicyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.BlockDeviceInfo.repeatedFields_ = [10,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.BlockDeviceInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.BlockDeviceInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.BlockDeviceInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    capacity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deviceType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fsType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    mountPoint: jspb.Message.getFieldWithDefault(msg, 5, ""),
    label: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fsUuid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    busAddress: jspb.Message.getFieldWithDefault(msg, 8, ""),
    wwn: jspb.Message.getFieldWithDefault(msg, 9, ""),
    deviceLinksList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    inUse: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    hardwareId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    serialId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    boot: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    childMountPointsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    persistentIdentity: jspb.Message.getFieldWithDefault(msg, 30, ""),
    windows: jspb.Message.getBooleanFieldWithDefault(msg, 31, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.BlockDeviceInfo}
 */
proto.galaxymigrate.BlockDeviceInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.BlockDeviceInfo;
  return proto.galaxymigrate.BlockDeviceInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.BlockDeviceInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.BlockDeviceInfo}
 */
proto.galaxymigrate.BlockDeviceInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCapacity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFsType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMountPoint(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFsUuid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusAddress(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setWwn(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeviceLinks(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInUse(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialId(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoot(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addChildMountPoints(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersistentIdentity(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWindows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.BlockDeviceInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.BlockDeviceInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.BlockDeviceInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCapacity();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getDeviceType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFsType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMountPoint();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFsUuid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBusAddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getWwn();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDeviceLinksList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getInUse();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getHardwareId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSerialId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBoot();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getChildMountPointsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getPersistentIdentity();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getWindows();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
};


/**
 * optional string device_name = 1;
 * @return {string}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getDeviceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setDeviceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 capacity = 2;
 * @return {number}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string device_type = 3;
 * @return {string}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getDeviceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fs_type = 4;
 * @return {string}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getFsType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setFsType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string mount_point = 5;
 * @return {string}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getMountPoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setMountPoint = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string label = 6;
 * @return {string}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string fs_uuid = 7;
 * @return {string}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getFsUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setFsUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string bus_address = 8;
 * @return {string}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getBusAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setBusAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string wwn = 9;
 * @return {string}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getWwn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setWwn = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated string device_links = 10;
 * @return {!Array<string>}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getDeviceLinksList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setDeviceLinksList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.addDeviceLinks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.clearDeviceLinksList = function() {
  return this.setDeviceLinksList([]);
};


/**
 * optional bool in_use = 11;
 * @return {boolean}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getInUse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setInUse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string hardware_id = 12;
 * @return {string}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getHardwareId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setHardwareId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string serial_id = 13;
 * @return {string}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getSerialId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setSerialId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool boot = 14;
 * @return {boolean}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getBoot = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setBoot = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * repeated string child_mount_points = 15;
 * @return {!Array<string>}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getChildMountPointsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setChildMountPointsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.addChildMountPoints = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.clearChildMountPointsList = function() {
  return this.setChildMountPointsList([]);
};


/**
 * optional string persistent_identity = 30;
 * @return {string}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getPersistentIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setPersistentIdentity = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional bool windows = 31;
 * @return {boolean}
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.getWindows = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.BlockDeviceInfo} returns this
 */
proto.galaxymigrate.BlockDeviceInfo.prototype.setWindows = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.IOStatsCollector.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.IOStatsCollector.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.IOStatsCollector} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsCollector.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.IOStatsCollector}
 */
proto.galaxymigrate.IOStatsCollector.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.IOStatsCollector;
  return proto.galaxymigrate.IOStatsCollector.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.IOStatsCollector} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.IOStatsCollector}
 */
proto.galaxymigrate.IOStatsCollector.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.IOStatsCollector.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.IOStatsCollector.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.IOStatsCollector} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsCollector.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.IOStatsCollector.LiveDataPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.IOStatsCollector.LiveDataPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    read: (f = msg.getRead()) && proto.galaxymigrate.IOStatsCollector.Stats.toObject(includeInstance, f),
    write: (f = msg.getWrite()) && proto.galaxymigrate.IOStatsCollector.Stats.toObject(includeInstance, f),
    pending: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.IOStatsCollector.LiveDataPoint}
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.IOStatsCollector.LiveDataPoint;
  return proto.galaxymigrate.IOStatsCollector.LiveDataPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.IOStatsCollector.LiveDataPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.IOStatsCollector.LiveDataPoint}
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 5:
      var value = new proto.galaxymigrate.IOStatsCollector.Stats;
      reader.readMessage(value,proto.galaxymigrate.IOStatsCollector.Stats.deserializeBinaryFromReader);
      msg.setRead(value);
      break;
    case 6:
      var value = new proto.galaxymigrate.IOStatsCollector.Stats;
      reader.readMessage(value,proto.galaxymigrate.IOStatsCollector.Stats.deserializeBinaryFromReader);
      msg.setWrite(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.IOStatsCollector.LiveDataPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.IOStatsCollector.LiveDataPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRead();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.galaxymigrate.IOStatsCollector.Stats.serializeBinaryToWriter
    );
  }
  f = message.getWrite();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.galaxymigrate.IOStatsCollector.Stats.serializeBinaryToWriter
    );
  }
  f = message.getPending();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.IOStatsCollector.LiveDataPoint} returns this
*/
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.IOStatsCollector.LiveDataPoint} returns this
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Stats read = 5;
 * @return {?proto.galaxymigrate.IOStatsCollector.Stats}
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.getRead = function() {
  return /** @type{?proto.galaxymigrate.IOStatsCollector.Stats} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.IOStatsCollector.Stats, 5));
};


/**
 * @param {?proto.galaxymigrate.IOStatsCollector.Stats|undefined} value
 * @return {!proto.galaxymigrate.IOStatsCollector.LiveDataPoint} returns this
*/
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.setRead = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.IOStatsCollector.LiveDataPoint} returns this
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.clearRead = function() {
  return this.setRead(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.hasRead = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Stats write = 6;
 * @return {?proto.galaxymigrate.IOStatsCollector.Stats}
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.getWrite = function() {
  return /** @type{?proto.galaxymigrate.IOStatsCollector.Stats} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.IOStatsCollector.Stats, 6));
};


/**
 * @param {?proto.galaxymigrate.IOStatsCollector.Stats|undefined} value
 * @return {!proto.galaxymigrate.IOStatsCollector.LiveDataPoint} returns this
*/
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.setWrite = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.IOStatsCollector.LiveDataPoint} returns this
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.clearWrite = function() {
  return this.setWrite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.hasWrite = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 pending = 7;
 * @return {number}
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.getPending = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.IOStatsCollector.LiveDataPoint} returns this
 */
proto.galaxymigrate.IOStatsCollector.LiveDataPoint.prototype.setPending = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    readAvg: (f = msg.getReadAvg()) && proto.galaxymigrate.IOStatsCollector.Stats.toObject(includeInstance, f),
    writeAvg: (f = msg.getWriteAvg()) && proto.galaxymigrate.IOStatsCollector.Stats.toObject(includeInstance, f),
    avgPending: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint}
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint;
  return proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint}
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 5:
      var value = new proto.galaxymigrate.IOStatsCollector.Stats;
      reader.readMessage(value,proto.galaxymigrate.IOStatsCollector.Stats.deserializeBinaryFromReader);
      msg.setReadAvg(value);
      break;
    case 6:
      var value = new proto.galaxymigrate.IOStatsCollector.Stats;
      reader.readMessage(value,proto.galaxymigrate.IOStatsCollector.Stats.deserializeBinaryFromReader);
      msg.setWriteAvg(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAvgPending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReadAvg();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.galaxymigrate.IOStatsCollector.Stats.serializeBinaryToWriter
    );
  }
  f = message.getWriteAvg();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.galaxymigrate.IOStatsCollector.Stats.serializeBinaryToWriter
    );
  }
  f = message.getAvgPending();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp timestamp = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint} returns this
*/
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint} returns this
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Stats read_avg = 5;
 * @return {?proto.galaxymigrate.IOStatsCollector.Stats}
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.getReadAvg = function() {
  return /** @type{?proto.galaxymigrate.IOStatsCollector.Stats} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.IOStatsCollector.Stats, 5));
};


/**
 * @param {?proto.galaxymigrate.IOStatsCollector.Stats|undefined} value
 * @return {!proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint} returns this
*/
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.setReadAvg = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint} returns this
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.clearReadAvg = function() {
  return this.setReadAvg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.hasReadAvg = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Stats write_avg = 6;
 * @return {?proto.galaxymigrate.IOStatsCollector.Stats}
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.getWriteAvg = function() {
  return /** @type{?proto.galaxymigrate.IOStatsCollector.Stats} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.IOStatsCollector.Stats, 6));
};


/**
 * @param {?proto.galaxymigrate.IOStatsCollector.Stats|undefined} value
 * @return {!proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint} returns this
*/
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.setWriteAvg = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint} returns this
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.clearWriteAvg = function() {
  return this.setWriteAvg(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.hasWriteAvg = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 avg_pending = 7;
 * @return {number}
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.getAvgPending = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint} returns this
 */
proto.galaxymigrate.IOStatsCollector.ConsolidatedDataPoint.prototype.setAvgPending = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.IOStatsCollector.Stats.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.IOStatsCollector.Stats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.IOStatsCollector.Stats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsCollector.Stats.toObject = function(includeInstance, msg) {
  var f, obj = {
    iops: jspb.Message.getFieldWithDefault(msg, 1, 0),
    throughput: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalLatency: jspb.Message.getFieldWithDefault(msg, 5, 0),
    avgIoSize: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.IOStatsCollector.Stats}
 */
proto.galaxymigrate.IOStatsCollector.Stats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.IOStatsCollector.Stats;
  return proto.galaxymigrate.IOStatsCollector.Stats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.IOStatsCollector.Stats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.IOStatsCollector.Stats}
 */
proto.galaxymigrate.IOStatsCollector.Stats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setIops(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setThroughput(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalLatency(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAvgIoSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.IOStatsCollector.Stats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.IOStatsCollector.Stats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.IOStatsCollector.Stats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsCollector.Stats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIops();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getThroughput();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getTotalLatency();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getAvgIoSize();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint64 iops = 1;
 * @return {number}
 */
proto.galaxymigrate.IOStatsCollector.Stats.prototype.getIops = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.IOStatsCollector.Stats} returns this
 */
proto.galaxymigrate.IOStatsCollector.Stats.prototype.setIops = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 throughput = 2;
 * @return {number}
 */
proto.galaxymigrate.IOStatsCollector.Stats.prototype.getThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.IOStatsCollector.Stats} returns this
 */
proto.galaxymigrate.IOStatsCollector.Stats.prototype.setThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 total_latency = 5;
 * @return {number}
 */
proto.galaxymigrate.IOStatsCollector.Stats.prototype.getTotalLatency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.IOStatsCollector.Stats} returns this
 */
proto.galaxymigrate.IOStatsCollector.Stats.prototype.setTotalLatency = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 avg_io_size = 6;
 * @return {number}
 */
proto.galaxymigrate.IOStatsCollector.Stats.prototype.getAvgIoSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.IOStatsCollector.Stats} returns this
 */
proto.galaxymigrate.IOStatsCollector.Stats.prototype.setAvgIoSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.IOStatsDataSet.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.IOStatsDataSet.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.IOStatsDataSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.IOStatsDataSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsDataSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeDomain: (f = msg.getTimeDomain()) && proto.galaxymigrate.IOStatsDataSet.TimeDomain.toObject(includeInstance, f),
    seriesListList: jspb.Message.toObjectList(msg.getSeriesListList(),
    proto.galaxymigrate.IOStatsDataSet.Series.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.IOStatsDataSet}
 */
proto.galaxymigrate.IOStatsDataSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.IOStatsDataSet;
  return proto.galaxymigrate.IOStatsDataSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.IOStatsDataSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.IOStatsDataSet}
 */
proto.galaxymigrate.IOStatsDataSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.IOStatsDataSet.TimeDomain;
      reader.readMessage(value,proto.galaxymigrate.IOStatsDataSet.TimeDomain.deserializeBinaryFromReader);
      msg.setTimeDomain(value);
      break;
    case 10:
      var value = new proto.galaxymigrate.IOStatsDataSet.Series;
      reader.readMessage(value,proto.galaxymigrate.IOStatsDataSet.Series.deserializeBinaryFromReader);
      msg.addSeriesList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.IOStatsDataSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.IOStatsDataSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.IOStatsDataSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsDataSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeDomain();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.IOStatsDataSet.TimeDomain.serializeBinaryToWriter
    );
  }
  f = message.getSeriesListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.galaxymigrate.IOStatsDataSet.Series.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.IOStatsDataSet.Series.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.IOStatsDataSet.Series.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.IOStatsDataSet.Series.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.IOStatsDataSet.Series} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsDataSet.Series.toObject = function(includeInstance, msg) {
  var f, obj = {
    domain: (f = msg.getDomain()) && proto.galaxymigrate.IOStatsDataSet.Domain.toObject(includeInstance, f),
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.galaxymigrate.IOStatsDataSet.Point.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.IOStatsDataSet.Series}
 */
proto.galaxymigrate.IOStatsDataSet.Series.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.IOStatsDataSet.Series;
  return proto.galaxymigrate.IOStatsDataSet.Series.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.IOStatsDataSet.Series} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.IOStatsDataSet.Series}
 */
proto.galaxymigrate.IOStatsDataSet.Series.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.IOStatsDataSet.Domain;
      reader.readMessage(value,proto.galaxymigrate.IOStatsDataSet.Domain.deserializeBinaryFromReader);
      msg.setDomain(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.IOStatsDataSet.Point;
      reader.readMessage(value,proto.galaxymigrate.IOStatsDataSet.Point.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.IOStatsDataSet.Series.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.IOStatsDataSet.Series.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.IOStatsDataSet.Series} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsDataSet.Series.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDomain();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.IOStatsDataSet.Domain.serializeBinaryToWriter
    );
  }
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.galaxymigrate.IOStatsDataSet.Point.serializeBinaryToWriter
    );
  }
};


/**
 * optional Domain domain = 1;
 * @return {?proto.galaxymigrate.IOStatsDataSet.Domain}
 */
proto.galaxymigrate.IOStatsDataSet.Series.prototype.getDomain = function() {
  return /** @type{?proto.galaxymigrate.IOStatsDataSet.Domain} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.IOStatsDataSet.Domain, 1));
};


/**
 * @param {?proto.galaxymigrate.IOStatsDataSet.Domain|undefined} value
 * @return {!proto.galaxymigrate.IOStatsDataSet.Series} returns this
*/
proto.galaxymigrate.IOStatsDataSet.Series.prototype.setDomain = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.IOStatsDataSet.Series} returns this
 */
proto.galaxymigrate.IOStatsDataSet.Series.prototype.clearDomain = function() {
  return this.setDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.IOStatsDataSet.Series.prototype.hasDomain = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Point points = 2;
 * @return {!Array<!proto.galaxymigrate.IOStatsDataSet.Point>}
 */
proto.galaxymigrate.IOStatsDataSet.Series.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.galaxymigrate.IOStatsDataSet.Point>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.IOStatsDataSet.Point, 2));
};


/**
 * @param {!Array<!proto.galaxymigrate.IOStatsDataSet.Point>} value
 * @return {!proto.galaxymigrate.IOStatsDataSet.Series} returns this
*/
proto.galaxymigrate.IOStatsDataSet.Series.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxymigrate.IOStatsDataSet.Point=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.IOStatsDataSet.Point}
 */
proto.galaxymigrate.IOStatsDataSet.Series.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxymigrate.IOStatsDataSet.Point, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.IOStatsDataSet.Series} returns this
 */
proto.galaxymigrate.IOStatsDataSet.Series.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.IOStatsDataSet.TimeDomain.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.IOStatsDataSet.TimeDomain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.IOStatsDataSet.TimeDomain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsDataSet.TimeDomain.toObject = function(includeInstance, msg) {
  var f, obj = {
    live: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    points: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.IOStatsDataSet.TimeDomain}
 */
proto.galaxymigrate.IOStatsDataSet.TimeDomain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.IOStatsDataSet.TimeDomain;
  return proto.galaxymigrate.IOStatsDataSet.TimeDomain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.IOStatsDataSet.TimeDomain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.IOStatsDataSet.TimeDomain}
 */
proto.galaxymigrate.IOStatsDataSet.TimeDomain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLive(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.IOStatsDataSet.TimeDomain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.IOStatsDataSet.TimeDomain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.IOStatsDataSet.TimeDomain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsDataSet.TimeDomain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPoints();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional bool live = 1;
 * @return {boolean}
 */
proto.galaxymigrate.IOStatsDataSet.TimeDomain.prototype.getLive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.IOStatsDataSet.TimeDomain} returns this
 */
proto.galaxymigrate.IOStatsDataSet.TimeDomain.prototype.setLive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 points = 2;
 * @return {number}
 */
proto.galaxymigrate.IOStatsDataSet.TimeDomain.prototype.getPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.IOStatsDataSet.TimeDomain} returns this
 */
proto.galaxymigrate.IOStatsDataSet.TimeDomain.prototype.setPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.IOStatsDataSet.Domain.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.IOStatsDataSet.Domain.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.IOStatsDataSet.Domain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.IOStatsDataSet.Domain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsDataSet.Domain.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicePathsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    includeReads: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    includeWrites: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.IOStatsDataSet.Domain}
 */
proto.galaxymigrate.IOStatsDataSet.Domain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.IOStatsDataSet.Domain;
  return proto.galaxymigrate.IOStatsDataSet.Domain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.IOStatsDataSet.Domain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.IOStatsDataSet.Domain}
 */
proto.galaxymigrate.IOStatsDataSet.Domain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDevicePaths(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeReads(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeWrites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.IOStatsDataSet.Domain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.IOStatsDataSet.Domain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.IOStatsDataSet.Domain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsDataSet.Domain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicePathsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getIncludeReads();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIncludeWrites();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated string device_paths = 1;
 * @return {!Array<string>}
 */
proto.galaxymigrate.IOStatsDataSet.Domain.prototype.getDevicePathsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxymigrate.IOStatsDataSet.Domain} returns this
 */
proto.galaxymigrate.IOStatsDataSet.Domain.prototype.setDevicePathsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.IOStatsDataSet.Domain} returns this
 */
proto.galaxymigrate.IOStatsDataSet.Domain.prototype.addDevicePaths = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.IOStatsDataSet.Domain} returns this
 */
proto.galaxymigrate.IOStatsDataSet.Domain.prototype.clearDevicePathsList = function() {
  return this.setDevicePathsList([]);
};


/**
 * optional bool include_reads = 2;
 * @return {boolean}
 */
proto.galaxymigrate.IOStatsDataSet.Domain.prototype.getIncludeReads = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.IOStatsDataSet.Domain} returns this
 */
proto.galaxymigrate.IOStatsDataSet.Domain.prototype.setIncludeReads = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool include_writes = 3;
 * @return {boolean}
 */
proto.galaxymigrate.IOStatsDataSet.Domain.prototype.getIncludeWrites = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.IOStatsDataSet.Domain} returns this
 */
proto.galaxymigrate.IOStatsDataSet.Domain.prototype.setIncludeWrites = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.IOStatsDataSet.Point.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.IOStatsDataSet.Point.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.IOStatsDataSet.Point} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsDataSet.Point.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    stats: (f = msg.getStats()) && proto.galaxymigrate.IOStatsDataSet.Stats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.IOStatsDataSet.Point}
 */
proto.galaxymigrate.IOStatsDataSet.Point.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.IOStatsDataSet.Point;
  return proto.galaxymigrate.IOStatsDataSet.Point.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.IOStatsDataSet.Point} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.IOStatsDataSet.Point}
 */
proto.galaxymigrate.IOStatsDataSet.Point.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.IOStatsDataSet.Stats;
      reader.readMessage(value,proto.galaxymigrate.IOStatsDataSet.Stats.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.IOStatsDataSet.Point.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.IOStatsDataSet.Point.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.IOStatsDataSet.Point} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsDataSet.Point.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.IOStatsDataSet.Stats.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.IOStatsDataSet.Point.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.IOStatsDataSet.Point} returns this
*/
proto.galaxymigrate.IOStatsDataSet.Point.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.IOStatsDataSet.Point} returns this
 */
proto.galaxymigrate.IOStatsDataSet.Point.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.IOStatsDataSet.Point.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Stats stats = 2;
 * @return {?proto.galaxymigrate.IOStatsDataSet.Stats}
 */
proto.galaxymigrate.IOStatsDataSet.Point.prototype.getStats = function() {
  return /** @type{?proto.galaxymigrate.IOStatsDataSet.Stats} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.IOStatsDataSet.Stats, 2));
};


/**
 * @param {?proto.galaxymigrate.IOStatsDataSet.Stats|undefined} value
 * @return {!proto.galaxymigrate.IOStatsDataSet.Point} returns this
*/
proto.galaxymigrate.IOStatsDataSet.Point.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.IOStatsDataSet.Point} returns this
 */
proto.galaxymigrate.IOStatsDataSet.Point.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.IOStatsDataSet.Point.prototype.hasStats = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.IOStatsDataSet.Stats.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.IOStatsDataSet.Stats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.IOStatsDataSet.Stats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsDataSet.Stats.toObject = function(includeInstance, msg) {
  var f, obj = {
    iops: jspb.Message.getFieldWithDefault(msg, 1, 0),
    throughput: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pending: jspb.Message.getFieldWithDefault(msg, 3, 0),
    avgLatency: jspb.Message.getFieldWithDefault(msg, 5, 0),
    avgIoSize: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.IOStatsDataSet.Stats}
 */
proto.galaxymigrate.IOStatsDataSet.Stats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.IOStatsDataSet.Stats;
  return proto.galaxymigrate.IOStatsDataSet.Stats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.IOStatsDataSet.Stats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.IOStatsDataSet.Stats}
 */
proto.galaxymigrate.IOStatsDataSet.Stats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setIops(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setThroughput(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPending(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAvgLatency(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAvgIoSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.IOStatsDataSet.Stats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.IOStatsDataSet.Stats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.IOStatsDataSet.Stats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.IOStatsDataSet.Stats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIops();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getThroughput();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPending();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getAvgLatency();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getAvgIoSize();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
};


/**
 * optional uint64 iops = 1;
 * @return {number}
 */
proto.galaxymigrate.IOStatsDataSet.Stats.prototype.getIops = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.IOStatsDataSet.Stats} returns this
 */
proto.galaxymigrate.IOStatsDataSet.Stats.prototype.setIops = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 throughput = 2;
 * @return {number}
 */
proto.galaxymigrate.IOStatsDataSet.Stats.prototype.getThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.IOStatsDataSet.Stats} returns this
 */
proto.galaxymigrate.IOStatsDataSet.Stats.prototype.setThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 pending = 3;
 * @return {number}
 */
proto.galaxymigrate.IOStatsDataSet.Stats.prototype.getPending = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.IOStatsDataSet.Stats} returns this
 */
proto.galaxymigrate.IOStatsDataSet.Stats.prototype.setPending = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 avg_latency = 5;
 * @return {number}
 */
proto.galaxymigrate.IOStatsDataSet.Stats.prototype.getAvgLatency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.IOStatsDataSet.Stats} returns this
 */
proto.galaxymigrate.IOStatsDataSet.Stats.prototype.setAvgLatency = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 avg_io_size = 6;
 * @return {number}
 */
proto.galaxymigrate.IOStatsDataSet.Stats.prototype.getAvgIoSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.IOStatsDataSet.Stats} returns this
 */
proto.galaxymigrate.IOStatsDataSet.Stats.prototype.setAvgIoSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional TimeDomain time_domain = 1;
 * @return {?proto.galaxymigrate.IOStatsDataSet.TimeDomain}
 */
proto.galaxymigrate.IOStatsDataSet.prototype.getTimeDomain = function() {
  return /** @type{?proto.galaxymigrate.IOStatsDataSet.TimeDomain} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.IOStatsDataSet.TimeDomain, 1));
};


/**
 * @param {?proto.galaxymigrate.IOStatsDataSet.TimeDomain|undefined} value
 * @return {!proto.galaxymigrate.IOStatsDataSet} returns this
*/
proto.galaxymigrate.IOStatsDataSet.prototype.setTimeDomain = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.IOStatsDataSet} returns this
 */
proto.galaxymigrate.IOStatsDataSet.prototype.clearTimeDomain = function() {
  return this.setTimeDomain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.IOStatsDataSet.prototype.hasTimeDomain = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Series series_list = 10;
 * @return {!Array<!proto.galaxymigrate.IOStatsDataSet.Series>}
 */
proto.galaxymigrate.IOStatsDataSet.prototype.getSeriesListList = function() {
  return /** @type{!Array<!proto.galaxymigrate.IOStatsDataSet.Series>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.IOStatsDataSet.Series, 10));
};


/**
 * @param {!Array<!proto.galaxymigrate.IOStatsDataSet.Series>} value
 * @return {!proto.galaxymigrate.IOStatsDataSet} returns this
*/
proto.galaxymigrate.IOStatsDataSet.prototype.setSeriesListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.galaxymigrate.IOStatsDataSet.Series=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.IOStatsDataSet.Series}
 */
proto.galaxymigrate.IOStatsDataSet.prototype.addSeriesList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.galaxymigrate.IOStatsDataSet.Series, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.IOStatsDataSet} returns this
 */
proto.galaxymigrate.IOStatsDataSet.prototype.clearSeriesListList = function() {
  return this.setSeriesListList([]);
};


goog.object.extend(exports, proto.galaxymigrate);
