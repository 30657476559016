import React from "react";
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import {useAppServices} from "../../app/services";
import {useDialogState} from "../../core/dialog/DialogService";
import {renderServerDataWithLoadingBox, useInitData} from "../../core/data/DataLoaderHooks";
import {OperatorView} from "../../auth/AuthenticatedViews";
import {CdmPolicyMenuButton, CdmPolicyMenuDialog} from "../policies/CdmPolicyMenu";
import {ScreenContainer, ScreenTitleBar} from "../../layout/ScreenCommon";
import {SubTabGroup, TabConfig} from "../../../common/tabs/TabComponents";
import {CdmActionDialog} from "../policies/CdmAction";
import {CdmPolicyInfoSection, CdmPolicySnapshotsSection} from "../policies/CdmPolicyDetails";
import {Box, Card, Typography} from "@mui/material";
import {BiShieldQuarter} from "react-icons/bi";
import {ProtectHostButton} from "./CirrusProtectDeploymentDetails";
import {renderOfflineDeploymentMessage} from "./CirrusProtectDeploymentCommon";

interface CirrusProtectDeploymentDetailsPolicyScreenProps {
}

export const CirrusProtectDeploymentDetailsPolicyScreen: React.FC<CirrusProtectDeploymentDetailsPolicyScreenProps> = observer((p) => {
    const {deploymentId, projectId} = useParams();
    const {cpDeploymentService} = useAppServices();
    const actionDialogState = useDialogState();
    const menuDialogState = useDialogState();

    useInitData({
        init: async() => {
            if (!cpDeploymentService.currentDeploymentId){
                await cpDeploymentService.cpDeploymentDetails.fetchData(deploymentId)
            }
        },
        poll: () => cpDeploymentService.cpDeploymentPolicyDetails.fetchData(deploymentId),
        pollInterval: 3
    });

    const getActions = () => {
        return <OperatorView>
            <CdmPolicyMenuButton dialogState={menuDialogState}/>
        </OperatorView>
    }

    if (cpDeploymentService.cpDeploymentPolicyDetails.inError){
        return <Box pt={4}>
            <Card>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} pt={4} pb={4} width={'100%'}>
                    <Box>
                        <Box display={'flex'} justifyContent={'center'}>
                            <BiShieldQuarter size={64}/>
                        </Box>
                        <Box textAlign={'center'}>
                            <Typography variant={'h4'}>
                                To Get Started, Create a New Protection Policy
                            </Typography>
                            <Typography variant={'caption'}>
                                Protect host volumes by starting a new Protection Policy
                            </Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'center'} pt={2}>
                            <ProtectHostButton cpDeploymentInfo={cpDeploymentService.cpDeploymentDetails.data}/>
                        </Box>
                    </Box>
                </Box>
                {renderOfflineDeploymentMessage(cpDeploymentService.cpDeploymentDetails.data)}
            </Card>
        </Box>
    }

    return renderServerDataWithLoadingBox(cpDeploymentService.cpDeploymentPolicyDetails, data => {
        const tabsConfig: TabConfig[] = [
            {
                label: 'Policy Info',
                renderer: ()=> <CdmPolicyInfoSection policy={data.getPolicy().getPolicyInfo()}/>,
            },
            {
                label: 'Snapshots',
                renderer: ()=> <CdmPolicySnapshotsSection policy={data.getPolicy().getPolicyInfo()}
                                                      actionDialogState={actionDialogState}/>,
                disabled: true
            },
        ];

        return <ScreenContainer>
            <ScreenTitleBar title={data.getPolicy().getPolicyInfo().getName()}/>
                <SubTabGroup configs={tabsConfig} rightAction={getActions()}/>
            <CdmActionDialog dialogState={actionDialogState}/>
            {menuDialogState.isOpen &&
                <CdmPolicyMenuDialog dialogState={menuDialogState} addCdmActionDialogState={actionDialogState}/>
            }
        </ScreenContainer>
    })
});