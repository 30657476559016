import {observer} from "mobx-react-lite";
import * as React from "react";
import {Route, Link, useLocation, Routes} from "react-router-dom";
import {Box, Button, Tab, TabProps, Tabs, TabsProps} from "@mui/material";
import {useState} from "react";

// ======================
// TabGroup
// ======================

export interface TabConfig {
    //id: string,
    label: string,
    renderer: () => React.ReactNode,
    icon?: React.ReactElement,
    disabled?: boolean
    hidden?: boolean,
    index?: number
}

interface TabGroupProps {
    configs: TabConfig[],
    tabsProps?: Partial<TabsProps>,
    border?: boolean,
    tabProps?: Partial<TabProps>,
    onChange?: Function,
    handleChangeTab?: (event: React.ChangeEvent<{}>, newValue: string) => void,
    value?: string;
}

export const TabGroup: React.FC<TabGroupProps> = observer((p) => {
    const {configs, tabsProps, tabProps, onChange} = p;
    const [value, setValue] = useState('0');
    const hasPermanentIndex = configs[0].index === 0;

    const tabValue = p.value ? p.value : value;

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
        if (!!p.handleChangeTab) {
            p.handleChangeTab(event, newValue)
        } else {
            setValue(newValue);
        }
        if (!!onChange) {
            onChange()
        }
    };

    const renderTabs = (configs: TabConfig[]) => {
        return configs.filter(t => !t.hidden).map((t, i) => {
            const index = t.index >= 0 ? t.index.toString() : i.toString()
            return <Tab icon={t.icon ? t.icon : null} key={index} label={t.label} value={index}
                        disabled={t.disabled} {...tabProps}/>
        })
    };

    const renderTabContent = (value: string) => {
        const index = parseInt(value);
        if (hasPermanentIndex) {
            return <>
                {
                    value === tabValue &&
                    configs.find(c => c.index === index).renderer()

                }
            </>
        }
        return <>
            {
                value === tabValue &&
                configs.filter(t => !t.hidden)[index].renderer()

            }
        </>
    }

    return <>
        <Box sx={{
            borderBottom: p.border ? `1px solid #000000` : 'none'
        }}>
            <Tabs value={tabValue} onChange={handleChangeTab} {...tabsProps}>
                {renderTabs(configs)}
            </Tabs>
        </Box>
        <Box pt={1}>
            {renderTabContent(tabValue)}
        </Box>
    </>
})

// ======================
// SubTabGroup
// ======================

interface SubTabGroupProps {
    configs: TabConfig[],
    onChange?: Function,
    rightAction?: React.ReactNode
}

export const SubTabGroup: React.FC<SubTabGroupProps> = observer((p) => {
    const {configs, onChange, rightAction} = p;

    const [value, setValue] = useState('0');

    const renderTabContent = (tabValue: string) => {
        const index = parseInt(tabValue);
        return <>
            {
                tabValue === value &&
                configs[index].renderer()

            }
        </>
    }

    const renderTabs = (configs: TabConfig[]) => {
        return configs.filter(t => !t.hidden).map((t, i) => {
            return <Box pl={i === 0 ? 0 : 1} pr={1} key={i}>
                <Button variant={'outlined'}
                        disabled={t.disabled}
                        color={i.toString() === value ? 'primary' : 'neutral'}
                        onClick={() => {
                            setValue(i.toString());
                            if (!!onChange) {
                                onChange()
                            }
                        }}
                        sx={{
                            borderRadius: '6px'
                        }}
                >
                    {t.label}
                </Button>
            </Box>
        })
    };

    return <>
        <Box display={'flex'} justifyContent={'space-between'}>
            <Box display={'flex'}>
                {renderTabs(configs)}
            </Box>
            {
                !!rightAction &&
                <Box>
                    {rightAction}
                </Box>
            }
        </Box>
        <Box pt={1}>
            {renderTabContent(value)}
        </Box>
    </>
})

// ======================
// RouterTabs
// ======================


interface RouterTabsProps {
    path?: string
    configs: RouterTabConfig[]
}

export interface RouterTabConfig extends TabConfig {
    path: string,
    default?: boolean
}

export const RouterTabs: React.FC<RouterTabsProps> = observer((props) => {
    const {path, configs} = props;
    const initialPath = useLocation().pathname;
    const [tab, setTab] = useState(initialPath);

    const getRoutePath = (c: RouterTabConfig) => {
        if (c.default) {
            return path;
        }
        return `${path}/${c.path}`;
    }

    return <>
        <Box borderBottom={`1px solid #000000`}>
            <Tabs value={tab} indicatorColor={'primary'}>
                {configs.filter(t => !t.hidden).map((t, i) => {
                    const routePath = getRoutePath(t);
                    return <Tab
                        icon={t.icon ? t.icon : null}
                        onClick={() => {
                            setTab(routePath)
                        }}
                        value={routePath} key={i}
                        disabled={t.disabled}
                        label={t.label}
                        component={Link} to={routePath}/>
                })}
            </Tabs>

        </Box>
        <Box>
            {configs.map((t, i) => {
                    return <Box key={i}>
                        {getRoutePath(t) === tab &&
                            t.renderer()
                        }
                    </Box>
                }
            )}
        </Box>
    </>


})