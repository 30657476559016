// source: galaxycompletepb/apipb/domainpb/report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var galaxycompletepb_apipb_domainpb_galaxymigrate_pb = require('../../../galaxycompletepb/apipb/domainpb/galaxymigrate_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_galaxymigrate_pb);
var galaxycompletepb_apipb_domainpb_deployment_pb = require('../../../galaxycompletepb/apipb/domainpb/deployment_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_deployment_pb);
var galaxycompletepb_apipb_domainpb_project_pb = require('../../../galaxycompletepb/apipb/domainpb/project_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_project_pb);
var galaxycompletepb_apipb_domainpb_user_pb = require('../../../galaxycompletepb/apipb/domainpb/user_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_user_pb);
var galaxycompletepb_apipb_domainpb_enumpb_report_type_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/report_type_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_report_type_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.galaxycomplete.domain.MigrationSessionReport', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.MigrationSessionReport.Data', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.MigrationSessionReport.Metadata', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ReportData', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ReportData.DataCase', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ReportInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ReportInfo.MetadataCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ReportInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.galaxycomplete.domain.ReportInfo.oneofGroups_);
};
goog.inherits(proto.galaxycomplete.domain.ReportInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ReportInfo.displayName = 'proto.galaxycomplete.domain.ReportInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ReportData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.galaxycomplete.domain.ReportData.oneofGroups_);
};
goog.inherits(proto.galaxycomplete.domain.ReportData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ReportData.displayName = 'proto.galaxycomplete.domain.ReportData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.MigrationSessionReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.MigrationSessionReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.MigrationSessionReport.displayName = 'proto.galaxycomplete.domain.MigrationSessionReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.MigrationSessionReport.Metadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.MigrationSessionReport.Metadata.displayName = 'proto.galaxycomplete.domain.MigrationSessionReport.Metadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.MigrationSessionReport.Data.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.MigrationSessionReport.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.MigrationSessionReport.Data.displayName = 'proto.galaxycomplete.domain.MigrationSessionReport.Data';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.galaxycomplete.domain.ReportInfo.oneofGroups_ = [[50]];

/**
 * @enum {number}
 */
proto.galaxycomplete.domain.ReportInfo.MetadataCase = {
  METADATA_NOT_SET: 0,
  MSR_META: 50
};

/**
 * @return {proto.galaxycomplete.domain.ReportInfo.MetadataCase}
 */
proto.galaxycomplete.domain.ReportInfo.prototype.getMetadataCase = function() {
  return /** @type {proto.galaxycomplete.domain.ReportInfo.MetadataCase} */(jspb.Message.computeOneofCase(this, proto.galaxycomplete.domain.ReportInfo.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ReportInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ReportInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ReportInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ReportInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reportType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createdBy: (f = msg.getCreatedBy()) && galaxycompletepb_apipb_domainpb_user_pb.HubUser.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userNotes: jspb.Message.getFieldWithDefault(msg, 5, ""),
    systemNotes: jspb.Message.getFieldWithDefault(msg, 6, ""),
    msrMeta: (f = msg.getMsrMeta()) && proto.galaxycomplete.domain.MigrationSessionReport.Metadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ReportInfo}
 */
proto.galaxycomplete.domain.ReportInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ReportInfo;
  return proto.galaxycomplete.domain.ReportInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ReportInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ReportInfo}
 */
proto.galaxycomplete.domain.ReportInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReportId(value);
      break;
    case 2:
      var value = /** @type {!proto.galaxycomplete.domain.enums.ReportType.ReportType} */ (reader.readEnum());
      msg.setReportType(value);
      break;
    case 3:
      var value = new galaxycompletepb_apipb_domainpb_user_pb.HubUser;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_user_pb.HubUser.deserializeBinaryFromReader);
      msg.setCreatedBy(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserNotes(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemNotes(value);
      break;
    case 50:
      var value = new proto.galaxycomplete.domain.MigrationSessionReport.Metadata;
      reader.readMessage(value,proto.galaxycomplete.domain.MigrationSessionReport.Metadata.deserializeBinaryFromReader);
      msg.setMsrMeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ReportInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ReportInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ReportInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ReportInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getReportType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCreatedBy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      galaxycompletepb_apipb_domainpb_user_pb.HubUser.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserNotes();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSystemNotes();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMsrMeta();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.galaxycomplete.domain.MigrationSessionReport.Metadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 report_id = 1;
 * @return {number}
 */
proto.galaxycomplete.domain.ReportInfo.prototype.getReportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.ReportInfo} returns this
 */
proto.galaxycomplete.domain.ReportInfo.prototype.setReportId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional enums.ReportType.ReportType report_type = 2;
 * @return {!proto.galaxycomplete.domain.enums.ReportType.ReportType}
 */
proto.galaxycomplete.domain.ReportInfo.prototype.getReportType = function() {
  return /** @type {!proto.galaxycomplete.domain.enums.ReportType.ReportType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.enums.ReportType.ReportType} value
 * @return {!proto.galaxycomplete.domain.ReportInfo} returns this
 */
proto.galaxycomplete.domain.ReportInfo.prototype.setReportType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional HubUser created_by = 3;
 * @return {?proto.galaxycomplete.domain.HubUser}
 */
proto.galaxycomplete.domain.ReportInfo.prototype.getCreatedBy = function() {
  return /** @type{?proto.galaxycomplete.domain.HubUser} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_user_pb.HubUser, 3));
};


/**
 * @param {?proto.galaxycomplete.domain.HubUser|undefined} value
 * @return {!proto.galaxycomplete.domain.ReportInfo} returns this
*/
proto.galaxycomplete.domain.ReportInfo.prototype.setCreatedBy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ReportInfo} returns this
 */
proto.galaxycomplete.domain.ReportInfo.prototype.clearCreatedBy = function() {
  return this.setCreatedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ReportInfo.prototype.hasCreatedBy = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.ReportInfo.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.ReportInfo} returns this
*/
proto.galaxycomplete.domain.ReportInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ReportInfo} returns this
 */
proto.galaxycomplete.domain.ReportInfo.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ReportInfo.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string user_notes = 5;
 * @return {string}
 */
proto.galaxycomplete.domain.ReportInfo.prototype.getUserNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ReportInfo} returns this
 */
proto.galaxycomplete.domain.ReportInfo.prototype.setUserNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string system_notes = 6;
 * @return {string}
 */
proto.galaxycomplete.domain.ReportInfo.prototype.getSystemNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ReportInfo} returns this
 */
proto.galaxycomplete.domain.ReportInfo.prototype.setSystemNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional MigrationSessionReport.Metadata msr_meta = 50;
 * @return {?proto.galaxycomplete.domain.MigrationSessionReport.Metadata}
 */
proto.galaxycomplete.domain.ReportInfo.prototype.getMsrMeta = function() {
  return /** @type{?proto.galaxycomplete.domain.MigrationSessionReport.Metadata} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.MigrationSessionReport.Metadata, 50));
};


/**
 * @param {?proto.galaxycomplete.domain.MigrationSessionReport.Metadata|undefined} value
 * @return {!proto.galaxycomplete.domain.ReportInfo} returns this
*/
proto.galaxycomplete.domain.ReportInfo.prototype.setMsrMeta = function(value) {
  return jspb.Message.setOneofWrapperField(this, 50, proto.galaxycomplete.domain.ReportInfo.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ReportInfo} returns this
 */
proto.galaxycomplete.domain.ReportInfo.prototype.clearMsrMeta = function() {
  return this.setMsrMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ReportInfo.prototype.hasMsrMeta = function() {
  return jspb.Message.getField(this, 50) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.galaxycomplete.domain.ReportData.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.galaxycomplete.domain.ReportData.DataCase = {
  DATA_NOT_SET: 0,
  MSR: 1
};

/**
 * @return {proto.galaxycomplete.domain.ReportData.DataCase}
 */
proto.galaxycomplete.domain.ReportData.prototype.getDataCase = function() {
  return /** @type {proto.galaxycomplete.domain.ReportData.DataCase} */(jspb.Message.computeOneofCase(this, proto.galaxycomplete.domain.ReportData.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ReportData.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ReportData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ReportData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ReportData.toObject = function(includeInstance, msg) {
  var f, obj = {
    msr: (f = msg.getMsr()) && proto.galaxycomplete.domain.MigrationSessionReport.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ReportData}
 */
proto.galaxycomplete.domain.ReportData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ReportData;
  return proto.galaxycomplete.domain.ReportData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ReportData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ReportData}
 */
proto.galaxycomplete.domain.ReportData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.MigrationSessionReport.Data;
      reader.readMessage(value,proto.galaxycomplete.domain.MigrationSessionReport.Data.deserializeBinaryFromReader);
      msg.setMsr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ReportData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ReportData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ReportData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ReportData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMsr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.domain.MigrationSessionReport.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional MigrationSessionReport.Data msr = 1;
 * @return {?proto.galaxycomplete.domain.MigrationSessionReport.Data}
 */
proto.galaxycomplete.domain.ReportData.prototype.getMsr = function() {
  return /** @type{?proto.galaxycomplete.domain.MigrationSessionReport.Data} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.MigrationSessionReport.Data, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.MigrationSessionReport.Data|undefined} value
 * @return {!proto.galaxycomplete.domain.ReportData} returns this
*/
proto.galaxycomplete.domain.ReportData.prototype.setMsr = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.galaxycomplete.domain.ReportData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ReportData} returns this
 */
proto.galaxycomplete.domain.ReportData.prototype.clearMsr = function() {
  return this.setMsr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ReportData.prototype.hasMsr = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.MigrationSessionReport.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.MigrationSessionReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.MigrationSessionReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.MigrationSessionReport.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport}
 */
proto.galaxycomplete.domain.MigrationSessionReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.MigrationSessionReport;
  return proto.galaxycomplete.domain.MigrationSessionReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.MigrationSessionReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport}
 */
proto.galaxycomplete.domain.MigrationSessionReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.MigrationSessionReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.MigrationSessionReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.MigrationSessionReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.MigrationSessionReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.MigrationSessionReport.Metadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.MigrationSessionReport.Metadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sourceSystemName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sourceSystemId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    destSystemName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    destSystemId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sessionId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sessionDesc: jspb.Message.getFieldWithDefault(msg, 8, ""),
    sessionStatus: jspb.Message.getFieldWithDefault(msg, 9, 0),
    sessionVolumes: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Metadata}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.MigrationSessionReport.Metadata;
  return proto.galaxycomplete.domain.MigrationSessionReport.Metadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.MigrationSessionReport.Metadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Metadata}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceSystemName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceSystemId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestSystemName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestSystemId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionDesc(value);
      break;
    case 9:
      var value = /** @type {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status} */ (reader.readEnum());
      msg.setSessionStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSessionVolumes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.MigrationSessionReport.Metadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.MigrationSessionReport.Metadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSourceSystemName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSourceSystemId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDestSystemName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDestSystemId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSessionDesc();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSessionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getSessionVolumes();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
};


/**
 * optional string project_name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Metadata} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string project_id = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Metadata} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string source_system_name = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.getSourceSystemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Metadata} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.setSourceSystemName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string source_system_id = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.getSourceSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Metadata} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.setSourceSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string dest_system_name = 5;
 * @return {string}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.getDestSystemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Metadata} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.setDestSystemName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string dest_system_id = 6;
 * @return {string}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.getDestSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Metadata} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.setDestSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string session_id = 7;
 * @return {string}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Metadata} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string session_desc = 8;
 * @return {string}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.getSessionDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Metadata} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.setSessionDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional GalaxyMigrateMigrationSessionInfo.Status session_status = 9;
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.getSessionStatus = function() {
  return /** @type {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionInfo.Status} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Metadata} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.setSessionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional uint32 session_volumes = 10;
 * @return {number}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.getSessionVolumes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Metadata} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Metadata.prototype.setSessionVolumes = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.repeatedFields_ = [5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.MigrationSessionReport.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.MigrationSessionReport.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    project: (f = msg.getProject()) && galaxycompletepb_apipb_domainpb_project_pb.ProjectDetails.toObject(includeInstance, f),
    sourceSystem: (f = msg.getSourceSystem()) && galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentDetails.toObject(includeInstance, f),
    destSystem: (f = msg.getDestSystem()) && galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentDetails.toObject(includeInstance, f),
    session: (f = msg.getSession()) && galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationSessionDetails.toObject(includeInstance, f),
    allEventsList: jspb.Message.toObjectList(msg.getAllEventsList(),
    galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationEvent.toObject, includeInstance),
    sourceVolumesList: jspb.Message.toObjectList(msg.getSourceVolumesList(),
    galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateStorageConfig.Device.toObject, includeInstance),
    destinationVolumesList: jspb.Message.toObjectList(msg.getDestinationVolumesList(),
    galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateStorageConfig.Device.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.MigrationSessionReport.Data;
  return proto.galaxycomplete.domain.MigrationSessionReport.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.MigrationSessionReport.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_project_pb.ProjectDetails;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_project_pb.ProjectDetails.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentDetails;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentDetails.deserializeBinaryFromReader);
      msg.setSourceSystem(value);
      break;
    case 3:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentDetails;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentDetails.deserializeBinaryFromReader);
      msg.setDestSystem(value);
      break;
    case 4:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationSessionDetails;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationSessionDetails.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 5:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationEvent;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationEvent.deserializeBinaryFromReader);
      msg.addAllEvents(value);
      break;
    case 6:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateStorageConfig.Device;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateStorageConfig.Device.deserializeBinaryFromReader);
      msg.addSourceVolumes(value);
      break;
    case 7:
      var value = new galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateStorageConfig.Device;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateStorageConfig.Device.deserializeBinaryFromReader);
      msg.addDestinationVolumes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.MigrationSessionReport.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.MigrationSessionReport.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_project_pb.ProjectDetails.serializeBinaryToWriter
    );
  }
  f = message.getSourceSystem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentDetails.serializeBinaryToWriter
    );
  }
  f = message.getDestSystem();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentDetails.serializeBinaryToWriter
    );
  }
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationSessionDetails.serializeBinaryToWriter
    );
  }
  f = message.getAllEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationEvent.serializeBinaryToWriter
    );
  }
  f = message.getSourceVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateStorageConfig.Device.serializeBinaryToWriter
    );
  }
  f = message.getDestinationVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateStorageConfig.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProjectDetails project = 1;
 * @return {?proto.galaxycomplete.domain.ProjectDetails}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.getProject = function() {
  return /** @type{?proto.galaxycomplete.domain.ProjectDetails} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_project_pb.ProjectDetails, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.ProjectDetails|undefined} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data} returns this
*/
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.hasProject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GalaxyMigrateDeploymentDetails source_system = 2;
 * @return {?proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.getSourceSystem = function() {
  return /** @type{?proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentDetails, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails|undefined} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data} returns this
*/
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.setSourceSystem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.clearSourceSystem = function() {
  return this.setSourceSystem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.hasSourceSystem = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GalaxyMigrateDeploymentDetails dest_system = 3;
 * @return {?proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.getDestSystem = function() {
  return /** @type{?proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateDeploymentDetails, 3));
};


/**
 * @param {?proto.galaxycomplete.domain.GalaxyMigrateDeploymentDetails|undefined} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data} returns this
*/
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.setDestSystem = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.clearDestSystem = function() {
  return this.setDestSystem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.hasDestSystem = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional GalaxyMigrateMigrationSessionDetails session = 4;
 * @return {?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.getSession = function() {
  return /** @type{?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationSessionDetails, 4));
};


/**
 * @param {?proto.galaxycomplete.domain.GalaxyMigrateMigrationSessionDetails|undefined} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data} returns this
*/
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.hasSession = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated GalaxyMigrateMigrationEvent all_events = 5;
 * @return {!Array<!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent>}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.getAllEventsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateMigrationEvent, 5));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent>} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data} returns this
*/
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.setAllEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.addAllEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.galaxycomplete.domain.GalaxyMigrateMigrationEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.clearAllEventsList = function() {
  return this.setAllEventsList([]);
};


/**
 * repeated GalaxyMigrateStorageConfig.Device source_volumes = 6;
 * @return {!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device>}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.getSourceVolumesList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateStorageConfig.Device, 6));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device>} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data} returns this
*/
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.setSourceVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.addSourceVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.clearSourceVolumesList = function() {
  return this.setSourceVolumesList([]);
};


/**
 * repeated GalaxyMigrateStorageConfig.Device destination_volumes = 7;
 * @return {!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device>}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.getDestinationVolumesList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_galaxymigrate_pb.GalaxyMigrateStorageConfig.Device, 7));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device>} value
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data} returns this
*/
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.setDestinationVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device}
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.addDestinationVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.galaxycomplete.domain.GalaxyMigrateStorageConfig.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.MigrationSessionReport.Data} returns this
 */
proto.galaxycomplete.domain.MigrationSessionReport.Data.prototype.clearDestinationVolumesList = function() {
  return this.setDestinationVolumesList([]);
};


goog.object.extend(exports, proto.galaxycomplete.domain);
