import {generatePath, useNavigate, useParams} from "react-router-dom";
import { ReportType } from "../../_proto/galaxycompletepb/apipb/domainpb/enumpb/report_type_pb";
import {APP_ROUTES, getProjectSubRouteTemplate, PROJECT_SUBROUTE} from "../app/AppRoutes";

export const useNavigateToReports = () => {
    const navigate = useNavigate();
    const {projectId} = useParams();
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.REPORTS), {projectId});
    return () => navigate(p);
}

export const viewReport = (reportId: number) => {
    const p = generatePath(`${APP_ROUTES.REPORTS}/${reportId}`);

    window.open(p, '_blank', 'toolbar=no,titlebar=no,location=0,scrollbars=yes,resizable=yes')
}

export const getReportTypeLabel = (reportType: ReportType.ReportType) => {
    if (reportType === ReportType.ReportType.HOST_CONFIGURATIONS_REPORT){
        return 'Host Configurations Report';
    }
    else if (reportType === ReportType.ReportType.PROJECT_MIGRATION_REPORT){
        return 'Project Migration Report';
    }
    else if (reportType === ReportType.ReportType.MIGRATION_SESSION_REPORT){
        return 'Migration Session Report';
    }
    else if (reportType === ReportType.ReportType.HOST_PERFORMANCE_REPORT){
        return 'Host Performance Report';
    }
    else if (reportType === ReportType.ReportType.PROJECT_LICENSE_REPORT){
        return 'Project License Report';
    }
    else if (reportType === ReportType.ReportType.PERFORMANCE_SUMMARY_REPORT){
        return 'Performance Summary Report';
    }
    else if (reportType === ReportType.ReportType.PROJECT_SUMMARY_REPORT){
        return 'Project Summary Report';
    }
    else {
        return 'Unknown';
    }
}