import { UnaryInterceptor } from "grpc-web";
import { GeneralServiceClient } from "../../_proto/galaxycompletepb/apipb/ApiServiceClientPb";
import { AuthenticationServiceClient, LoginServiceClient } from "../../_proto/galaxycompletepb/apipb/Auth_apiServiceClientPb";
import { ProjectServiceClient } from "../../_proto/galaxycompletepb/apipb/Project_apiServiceClientPb";
import { DeploymentServiceClient } from "../../_proto/galaxycompletepb/apipb/Deployment_apiServiceClientPb";
import { HelpServiceClient } from "../../_proto/galaxycompletepb/apipb/Help_apiServiceClientPb";
import { GalaxyMigrateServiceClient } from "../../_proto/galaxycompletepb/apipb/gmapipb/Galaxymigrate_apiServiceClientPb";
import { IntegrationServiceClient } from "../../_proto/galaxycompletepb/apipb/Integration_apiServiceClientPb";
import { WorkflowServiceClient } from "../../_proto/galaxycompletepb/apipb/Workflow_apiServiceClientPb";
import { PhoenixServiceClient } from "../../_proto/galaxycompletepb/apipb/phoenixapipb/Phoenix_apiServiceClientPb";
import { CirrusProtectServiceClient } from "../../_proto/galaxycompletepb/apipb/cpapipb/Cirrusprotect_apiServiceClientPb";
import { ChecklistServiceClient } from "../../_proto/galaxycompletepb/apipb/Checklist_apiServiceClientPb";
import { LicenseBillingServiceClient } from "../../_proto/galaxycompletepb/apipb/License_apiServiceClientPb";
import { SupportServiceClient } from "../../_proto/galaxycompletepb/apipb/Support_apiServiceClientPb";
import { PrivateEditionLocalServiceClient } from "../../_proto/galaxycompletepb/apipb/Pelocal_apiServiceClientPb";
import { ReportServiceClient } from "../../_proto/galaxycompletepb/apipb/Report_apiServiceClientPb";
import { create } from "zustand";
import { getApiEndpoint } from "./grpcCommon";
import { isDevelopment } from "../../common/utils/util";
import { authInterceptor, reqResPrinterInterceptor } from "./grpcapi";

export interface GrpcApiStore {
    activeToken?: string;
    setActiveToken?: (value: string) => void;
    getHasActiveToken?: () => boolean;
    generalService?: GeneralServiceClient;
    loginService?: LoginServiceClient;
    authService?: AuthenticationServiceClient;
    projectService?: ProjectServiceClient;
    deploymentService?: DeploymentServiceClient;
    helpService?: HelpServiceClient;
    gmService?: GalaxyMigrateServiceClient;
    integrationService?: IntegrationServiceClient;
    workflowService?: WorkflowServiceClient;
    phoenixService?: PhoenixServiceClient;
    cpService?: CirrusProtectServiceClient;
    checklistService?: ChecklistServiceClient;
    licenseBillingService?: LicenseBillingServiceClient;
    supportService?: SupportServiceClient;
    privateEditionService?: PrivateEditionLocalServiceClient;
    reportService?: ReportServiceClient;
}

const addr = getApiEndpoint();

const makeAuthInterceptor = (tokenGetter: () => Promise<string>, tokenSetter: (token: string) => void) => {
    const onAuthError = (e: Error) => {
        console.debug("authentication error detected. clearing token");
        tokenSetter(null);
    };
    return new authInterceptor(tokenGetter, onAuthError);
};

const getBaseOptions = (tokenGetter: () => Promise<string>, tokenSetter: (token: string) => void) => {
    return {
        unaryInterceptors: [isDevelopment() ? new reqResPrinterInterceptor() : null, makeAuthInterceptor(tokenGetter, tokenSetter)].filter((a) => !!a),
    };
};

export const useGrpcApiStore = create<GrpcApiStore>((set, get) => ({
    activeToken: null,

    setActiveToken: (value: string) => {
        set({
            activeToken: value,
        });
    },

    getHasActiveToken: () => !!get().activeToken,

    generalService: new GeneralServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
    loginService: new LoginServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
    authService: new AuthenticationServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
    projectService: new ProjectServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
    deploymentService: new DeploymentServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
    gmService: new GalaxyMigrateServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
    helpService: new HelpServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
    integrationService: new IntegrationServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
    workflowService: new WorkflowServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
    phoenixService: new PhoenixServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
    cpService: new CirrusProtectServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
    checklistService: new ChecklistServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
    licenseBillingService: new LicenseBillingServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
    supportService: new SupportServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
    privateEditionService: new PrivateEditionLocalServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
    reportService: new ReportServiceClient(addr, null, {
        ...getBaseOptions(
            async () => get().activeToken,
            (token: string) => {
                set({ activeToken: token });
            }
        ),
    }),
}));
