import {GRPCServices} from '../grpc/grpcapi';
import {DialogService} from '../core/dialog/DialogService';
import {ProgressService} from '../core/progress/ProgressService';
import {ProjectService} from '../project/ProjectService';
import {makeAutoObservable} from 'mobx';
import {ServerListData} from '../core/data/ListData';
import {ProjectMemberInfo} from '../../_proto/galaxycompletepb/apipb/domainpb/project_pb';
import {
    ChangeProjectMemberRole, GetProjectLicenseVaultDetails,
    InviteNewProjectMembers, LeaveProject,
    ListProjectActivities, ListProjectLicenseTransactions,
    ListProjectMembers,
    RemoveProjectMember, UpdateProject
} from '../../_proto/galaxycompletepb/apipb/project_api_pb';
import {PagerParams} from '../../_proto/galaxycompletepb/commonpb/datafilter_pb';
import {ActivityInfo} from '../../_proto/galaxycompletepb/apipb/domainpb/activity_pb';
import {getProjectMemberUserFullName} from '../settings/ProjectUsers';
import {ProjectMemberRole} from "../../_proto/galaxycompletepb/apipb/domainpb/enumpb/project_member_role_pb";

export class ProjectManagementService {
    private api: GRPCServices;
    protected dialogService: DialogService;
    protected progressService: ProgressService;
    protected projectService: ProjectService;

    public projectMembers = new ServerListData<ListProjectMembers.Response, ProjectMemberInfo>(10).setDataFetcher(this.fetchCurrentProjectMembers.bind(this));
    public projectActivities = new ServerListData<ListProjectActivities.Response, ActivityInfo>(30).setDataFetcher(this.fetchCurrentProjectActivities.bind(this));

    constructor(api: GRPCServices, dialogService: DialogService, progressService: ProgressService, projectService: ProjectService) {
        this.api = api;
        this.dialogService = dialogService;
        this.progressService = progressService;
        this.projectService = projectService;
        makeAutoObservable(this);
    }

    public async fetchCurrentProjectMembers(projectID?: string, pageParams?: PagerParams) {
        projectID = projectID || this.projectService.currentProjectID;
        pageParams = pageParams || this.projectMembers.pagerParam;
        const req = new ListProjectMembers.Request().setProjectId(projectID).setPageParams(pageParams);
        return await this.api.projectService.listProjectMembers(req, null);
    }

    public async fetchCurrentProjectActivities(projectID?: string, pageParams?: PagerParams) {
        projectID = projectID || this.projectService.currentProjectID;
        pageParams = pageParams || this.projectActivities.pagerParam;
        const req = new ListProjectActivities.Request().setProjectId(projectID).setPageParams(pageParams);
        return await this.api.projectService.listProjectActivities(req, null);
    }

    public async inviteNewMemberToCurrentProject(emails: string[], role: ProjectMemberInfo.Role) {
        const req = new InviteNewProjectMembers.Request().setEmailsList(emails)
            .setProjectId(this.projectService.currentProjectID)
            .setRole(role)
        return await this.progressService.track(this.api.projectService.inviteNewProjectMembers(req, null));
    }

    public async removeMemberFromCurrentProject(user: ProjectMemberInfo) {
        const req = new RemoveProjectMember.Request().setUserId(user.getUser().getUserId()).setProjectId(this.projectService.currentProjectID);
            try {
                return await this.api.projectService.removeProjectMember(req, null);
            } catch (e) {
                if ((e as Error).message === "can not remove the last admin from the project") {
                    await this.dialogService.addAlertDialog({
                        title:'Cannot Remove Member',
                        message: `Each project must have at least one admin. Please transfer admin privilege to another project member and try again.`
                })
                } else {
                    await this.dialogService.addErrorAlertDialog(e as Error)
                }
                throw(e)
            }

    }

    public async changeProjectMemberRole(projectID: string, userID: number, role: ProjectMemberInfo.Role) {
        const req = new ChangeProjectMemberRole.Request()
            .setNewRole(role)
            .setProjectId(projectID)
            .setUserId(userID)
        const confirmationMessage = () => {
            if (role === ProjectMemberInfo.Role.ADMIN) {
                return `Changing to Admin status will grant this user privileges to add and delete project members. Are you sure you want to continue?`
            } else if (role === ProjectMemberInfo.Role.MONITOR) {
                return `Changing to Monitor status will remove operational privileges from this user. Are you sure you want to continue?`
            } else if (role === ProjectMemberInfo.Role.OPERATOR) {
                return `Are you sure you want to change this user's role to Operator?`
            }
        }
        const confirmed = await this.dialogService.addConfirmDialog({message: confirmationMessage(), autoConfirmationQuestionLine: false});
        if (confirmed) {
            try {
                return await this.api.projectService.changeProjectMemberRole(req, null);
            } catch (e) {
                if ((e as Error).message === "can not remove the last admin from the project") {
                    await this.dialogService.addAlertDialog({title: 'Cannot Change Role', message:`Each project must have at least one admin. Please transfer admin privilege to another project member and try again.`})
                } else {
                    await this.dialogService.addErrorAlertDialog(e as Error)
                }
                throw(e)
            }
        }
    }

    public async leaveProject() {
        const req = new LeaveProject.Request()
            .setProjectId(this.projectService.currentProjectID);
        try {
            return await this.api.projectService.leaveProject(req, null);
        } catch (e) {
            if ((e as Error).message === "can not remove the last admin from the project") {
                await this.dialogService.addAlertDialog({title: 'Cannot Leave Project', message:`Each project must have at least one admin. Please transfer admin privilege to another project member and try again.`})
            } else {
                await this.dialogService.addErrorAlertDialog(e as Error)
            }
            throw(e)
        }

    }

}