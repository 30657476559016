// source: galaxycompletepb/apipb/domainpb/phoenix.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var galaxycompletepb_apipb_domainpb_deployment_pb = require('../../../galaxycompletepb/apipb/domainpb/deployment_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_deployment_pb);
var galaxycompletepb_apipb_domainpb_project_pb = require('../../../galaxycompletepb/apipb/domainpb/project_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_project_pb);
var galaxycompletepb_apipb_domainpb_enumpb_cdm_policy_mode_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/cdm_policy_mode_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_cdm_policy_mode_pb);
var galaxycompletepb_apipb_domainpb_enumpb_cdm_policy_status_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/cdm_policy_status_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_cdm_policy_status_pb);
var galaxycompletepb_apipb_domainpb_enumpb_cdm_policy_sync_status_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/cdm_policy_sync_status_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_cdm_policy_sync_status_pb);
var galaxycompletepb_apipb_domainpb_enumpb_cdm_policy_type_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/cdm_policy_type_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_cdm_policy_type_pb);
var galaxycompletepb_apipb_domainpb_enumpb_phoenix_deployment_status_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/phoenix_deployment_status_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_phoenix_deployment_status_pb);
var galaxycompletepb_apipb_domainpb_enumpb_phoenix_system_health_status_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/phoenix_system_health_status_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_phoenix_system_health_status_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.galaxycomplete.domain.CDMPolicy', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.CDMPolicyDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.CDMPolicyDisk', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.CDMPolicyIdentityInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.CDMPolicyStats', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.CDMPolicySyncErrorInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.PhoenixDeploymentDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.PhoenixDeploymentInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.PhoenixDeploymentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.PhoenixDeploymentInfo.displayName = 'proto.galaxycomplete.domain.PhoenixDeploymentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.PhoenixDeploymentDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.PhoenixDeploymentDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.PhoenixDeploymentDetails.displayName = 'proto.galaxycomplete.domain.PhoenixDeploymentDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.CDMPolicyDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.CDMPolicyDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.CDMPolicyDetails.displayName = 'proto.galaxycomplete.domain.CDMPolicyDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.CDMPolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.CDMPolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.CDMPolicy.displayName = 'proto.galaxycomplete.domain.CDMPolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.CDMPolicySyncErrorInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.displayName = 'proto.galaxycomplete.domain.CDMPolicySyncErrorInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.displayName = 'proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.CDMPolicyIdentityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.CDMPolicyIdentityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.CDMPolicyIdentityInfo.displayName = 'proto.galaxycomplete.domain.CDMPolicyIdentityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.CDMPolicyStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.CDMPolicyStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.CDMPolicyStats.displayName = 'proto.galaxycomplete.domain.CDMPolicyStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.CDMPolicyDisk = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.CDMPolicyDisk, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.CDMPolicyDisk.displayName = 'proto.galaxycomplete.domain.CDMPolicyDisk';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.PhoenixDeploymentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    deployment: (f = msg.getDeployment()) && galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentInfo.toObject(includeInstance, f),
    cpu: jspb.Message.getFieldWithDefault(msg, 2, ""),
    model: jspb.Message.getFieldWithDefault(msg, 3, ""),
    memory: jspb.Message.getFieldWithDefault(msg, 4, 0),
    memoryUsed: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gateway: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    totalStorage: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalStorageUsed: jspb.Message.getFieldWithDefault(msg, 8, 0),
    totalData: jspb.Message.getFieldWithDefault(msg, 9, 0),
    reductionRatio: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    totalVolumes: jspb.Message.getFieldWithDefault(msg, 11, 0),
    totalSnapshot: jspb.Message.getFieldWithDefault(msg, 12, 0),
    mtdiPaired: jspb.Message.getFieldWithDefault(msg, 13, 0),
    tdiPaired: jspb.Message.getFieldWithDefault(msg, 14, 0),
    healthStatus: jspb.Message.getFieldWithDefault(msg, 15, 0),
    healthMessage: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.PhoenixDeploymentInfo;
  return proto.galaxycomplete.domain.PhoenixDeploymentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentInfo.deserializeBinaryFromReader);
      msg.setDeployment(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCpu(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMemory(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMemoryUsed(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGateway(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalStorage(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalStorageUsed(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalData(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setReductionRatio(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalVolumes(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalSnapshot(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMtdiPaired(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTdiPaired(value);
      break;
    case 15:
      var value = /** @type {!proto.galaxycomplete.domain.enums.PhoenixSystemHealthStatus.PhoenixSystemHealthStatus} */ (reader.readEnum());
      msg.setHealthStatus(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setHealthMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.PhoenixDeploymentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeployment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentInfo.serializeBinaryToWriter
    );
  }
  f = message.getCpu();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMemory();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getMemoryUsed();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getGateway();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTotalStorage();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getTotalStorageUsed();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getTotalData();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getReductionRatio();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getTotalVolumes();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getTotalSnapshot();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getMtdiPaired();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getTdiPaired();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getHealthStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getHealthMessage();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional DeploymentInfo deployment = 1;
 * @return {?proto.galaxycomplete.domain.DeploymentInfo}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getDeployment = function() {
  return /** @type{?proto.galaxycomplete.domain.DeploymentInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.DeploymentInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
*/
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setDeployment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.clearDeployment = function() {
  return this.setDeployment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.hasDeployment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string cpu = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getCpu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setCpu = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string model = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 memory = 4;
 * @return {number}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 memory_used = 5;
 * @return {number}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getMemoryUsed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setMemoryUsed = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool gateway = 6;
 * @return {boolean}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getGateway = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setGateway = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional uint64 total_storage = 7;
 * @return {number}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getTotalStorage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setTotalStorage = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 total_storage_used = 8;
 * @return {number}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getTotalStorageUsed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setTotalStorageUsed = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 total_data = 9;
 * @return {number}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getTotalData = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setTotalData = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional float reduction_ratio = 10;
 * @return {number}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getReductionRatio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setReductionRatio = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional int32 total_volumes = 11;
 * @return {number}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getTotalVolumes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setTotalVolumes = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 total_snapshot = 12;
 * @return {number}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getTotalSnapshot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setTotalSnapshot = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 mtdi_paired = 13;
 * @return {number}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getMtdiPaired = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setMtdiPaired = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 tdi_paired = 14;
 * @return {number}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getTdiPaired = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setTdiPaired = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional enums.PhoenixSystemHealthStatus.PhoenixSystemHealthStatus health_status = 15;
 * @return {!proto.galaxycomplete.domain.enums.PhoenixSystemHealthStatus.PhoenixSystemHealthStatus}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getHealthStatus = function() {
  return /** @type {!proto.galaxycomplete.domain.enums.PhoenixSystemHealthStatus.PhoenixSystemHealthStatus} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.enums.PhoenixSystemHealthStatus.PhoenixSystemHealthStatus} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setHealthStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional string health_message = 16;
 * @return {string}
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.getHealthMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentInfo} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentInfo.prototype.setHealthMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.PhoenixDeploymentDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.PhoenixDeploymentDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.PhoenixDeploymentDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.PhoenixDeploymentDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.galaxycomplete.domain.PhoenixDeploymentInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentDetails}
 */
proto.galaxycomplete.domain.PhoenixDeploymentDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.PhoenixDeploymentDetails;
  return proto.galaxycomplete.domain.PhoenixDeploymentDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.PhoenixDeploymentDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentDetails}
 */
proto.galaxycomplete.domain.PhoenixDeploymentDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.PhoenixDeploymentInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.PhoenixDeploymentInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.PhoenixDeploymentDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.PhoenixDeploymentDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.PhoenixDeploymentDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.PhoenixDeploymentDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.domain.PhoenixDeploymentInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional PhoenixDeploymentInfo info = 1;
 * @return {?proto.galaxycomplete.domain.PhoenixDeploymentInfo}
 */
proto.galaxycomplete.domain.PhoenixDeploymentDetails.prototype.getInfo = function() {
  return /** @type{?proto.galaxycomplete.domain.PhoenixDeploymentInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.PhoenixDeploymentInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.PhoenixDeploymentInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentDetails} returns this
*/
proto.galaxycomplete.domain.PhoenixDeploymentDetails.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.PhoenixDeploymentDetails} returns this
 */
proto.galaxycomplete.domain.PhoenixDeploymentDetails.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.PhoenixDeploymentDetails.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.CDMPolicyDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.CDMPolicyDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.CDMPolicyDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CDMPolicyDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyInfo: (f = msg.getPolicyInfo()) && proto.galaxycomplete.domain.CDMPolicy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.CDMPolicyDetails}
 */
proto.galaxycomplete.domain.CDMPolicyDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.CDMPolicyDetails;
  return proto.galaxycomplete.domain.CDMPolicyDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.CDMPolicyDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.CDMPolicyDetails}
 */
proto.galaxycomplete.domain.CDMPolicyDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.CDMPolicy;
      reader.readMessage(value,proto.galaxycomplete.domain.CDMPolicy.deserializeBinaryFromReader);
      msg.setPolicyInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.CDMPolicyDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.CDMPolicyDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.CDMPolicyDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CDMPolicyDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.domain.CDMPolicy.serializeBinaryToWriter
    );
  }
};


/**
 * optional CDMPolicy policy_info = 1;
 * @return {?proto.galaxycomplete.domain.CDMPolicy}
 */
proto.galaxycomplete.domain.CDMPolicyDetails.prototype.getPolicyInfo = function() {
  return /** @type{?proto.galaxycomplete.domain.CDMPolicy} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.CDMPolicy, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.CDMPolicy|undefined} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyDetails} returns this
*/
proto.galaxycomplete.domain.CDMPolicyDetails.prototype.setPolicyInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CDMPolicyDetails} returns this
 */
proto.galaxycomplete.domain.CDMPolicyDetails.prototype.clearPolicyInfo = function() {
  return this.setPolicyInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicyDetails.prototype.hasPolicyInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.CDMPolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.CDMPolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CDMPolicy.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    localPolicyId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    synchronizationMode: jspb.Message.getFieldWithDefault(msg, 6, 0),
    policyType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    activeSchedules: jspb.Message.getFieldWithDefault(msg, 10, 0),
    disksCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    totalCapacity: jspb.Message.getFieldWithDefault(msg, 12, 0),
    snapshotsCount: jspb.Message.getFieldWithDefault(msg, 13, 0),
    deploymentStatus: jspb.Message.getFieldWithDefault(msg, 20, 0),
    status: jspb.Message.getFieldWithDefault(msg, 21, 0),
    syncStatus: jspb.Message.getFieldWithDefault(msg, 22, 0),
    syncErrors: (f = msg.getSyncErrors()) && proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.toObject(includeInstance, f),
    standin: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    nextSnapshotTime: (f = msg.getNextSnapshotTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    cdmSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    project: (f = msg.getProject()) && galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo.toObject(includeInstance, f),
    deployment: (f = msg.getDeployment()) && galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo.toObject(includeInstance, f),
    deploymentConnected: jspb.Message.getBooleanFieldWithDefault(msg, 42, false),
    stats: (f = msg.getStats()) && proto.galaxycomplete.domain.CDMPolicyStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.CDMPolicy}
 */
proto.galaxycomplete.domain.CDMPolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.CDMPolicy;
  return proto.galaxycomplete.domain.CDMPolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.CDMPolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.CDMPolicy}
 */
proto.galaxycomplete.domain.CDMPolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocalPolicyId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {!proto.galaxycomplete.domain.enums.CdmPolicyMode.CdmPolicyMode} */ (reader.readEnum());
      msg.setSynchronizationMode(value);
      break;
    case 7:
      var value = /** @type {!proto.galaxycomplete.domain.enums.CdmPolicyType.CdmPolicyType} */ (reader.readEnum());
      msg.setPolicyType(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setActiveSchedules(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDisksCount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalCapacity(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSnapshotsCount(value);
      break;
    case 20:
      var value = /** @type {!proto.galaxycomplete.domain.enums.PhoenixDeploymentStatus.PhoenixDeploymentStatus} */ (reader.readEnum());
      msg.setDeploymentStatus(value);
      break;
    case 21:
      var value = /** @type {!proto.galaxycomplete.domain.enums.CdmPolicyStatus.CdmPolicyStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 22:
      var value = /** @type {!proto.galaxycomplete.domain.enums.CdmPolicySyncStatus.CdmPolicySyncStatus} */ (reader.readEnum());
      msg.setSyncStatus(value);
      break;
    case 23:
      var value = new proto.galaxycomplete.domain.CDMPolicySyncErrorInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.deserializeBinaryFromReader);
      msg.setSyncErrors(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStandin(value);
      break;
    case 25:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setNextSnapshotTime(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCdmSuspended(value);
      break;
    case 40:
      var value = new galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 41:
      var value = new galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo.deserializeBinaryFromReader);
      msg.setDeployment(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeploymentConnected(value);
      break;
    case 50:
      var value = new proto.galaxycomplete.domain.CDMPolicyStats;
      reader.readMessage(value,proto.galaxycomplete.domain.CDMPolicyStats.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.CDMPolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.CDMPolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CDMPolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocalPolicyId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSynchronizationMode();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPolicyType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getActiveSchedules();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getDisksCount();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = message.getTotalCapacity();
  if (f !== 0) {
    writer.writeUint64(
      12,
      f
    );
  }
  f = message.getSnapshotsCount();
  if (f !== 0) {
    writer.writeUint64(
      13,
      f
    );
  }
  f = message.getDeploymentStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getSyncStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getSyncErrors();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.serializeBinaryToWriter
    );
  }
  f = message.getStandin();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getNextSnapshotTime();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCdmSuspended();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo.serializeBinaryToWriter
    );
  }
  f = message.getDeployment();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo.serializeBinaryToWriter
    );
  }
  f = message.getDeploymentConnected();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.galaxycomplete.domain.CDMPolicyStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional string policy_id = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getPolicyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setPolicyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 local_policy_id = 2;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getLocalPolicyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setLocalPolicyId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
*/
proto.galaxycomplete.domain.CDMPolicy.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional enums.CdmPolicyMode.CdmPolicyMode synchronization_mode = 6;
 * @return {!proto.galaxycomplete.domain.enums.CdmPolicyMode.CdmPolicyMode}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getSynchronizationMode = function() {
  return /** @type {!proto.galaxycomplete.domain.enums.CdmPolicyMode.CdmPolicyMode} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.enums.CdmPolicyMode.CdmPolicyMode} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setSynchronizationMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional enums.CdmPolicyType.CdmPolicyType policy_type = 7;
 * @return {!proto.galaxycomplete.domain.enums.CdmPolicyType.CdmPolicyType}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getPolicyType = function() {
  return /** @type {!proto.galaxycomplete.domain.enums.CdmPolicyType.CdmPolicyType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.enums.CdmPolicyType.CdmPolicyType} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setPolicyType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional uint64 active_schedules = 10;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getActiveSchedules = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setActiveSchedules = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint64 disks_count = 11;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getDisksCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setDisksCount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint64 total_capacity = 12;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getTotalCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setTotalCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint64 snapshots_count = 13;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getSnapshotsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setSnapshotsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional enums.PhoenixDeploymentStatus.PhoenixDeploymentStatus deployment_status = 20;
 * @return {!proto.galaxycomplete.domain.enums.PhoenixDeploymentStatus.PhoenixDeploymentStatus}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getDeploymentStatus = function() {
  return /** @type {!proto.galaxycomplete.domain.enums.PhoenixDeploymentStatus.PhoenixDeploymentStatus} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.enums.PhoenixDeploymentStatus.PhoenixDeploymentStatus} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setDeploymentStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional enums.CdmPolicyStatus.CdmPolicyStatus status = 21;
 * @return {!proto.galaxycomplete.domain.enums.CdmPolicyStatus.CdmPolicyStatus}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getStatus = function() {
  return /** @type {!proto.galaxycomplete.domain.enums.CdmPolicyStatus.CdmPolicyStatus} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.enums.CdmPolicyStatus.CdmPolicyStatus} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional enums.CdmPolicySyncStatus.CdmPolicySyncStatus sync_status = 22;
 * @return {!proto.galaxycomplete.domain.enums.CdmPolicySyncStatus.CdmPolicySyncStatus}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getSyncStatus = function() {
  return /** @type {!proto.galaxycomplete.domain.enums.CdmPolicySyncStatus.CdmPolicySyncStatus} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.enums.CdmPolicySyncStatus.CdmPolicySyncStatus} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setSyncStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional CDMPolicySyncErrorInfo sync_errors = 23;
 * @return {?proto.galaxycomplete.domain.CDMPolicySyncErrorInfo}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getSyncErrors = function() {
  return /** @type{?proto.galaxycomplete.domain.CDMPolicySyncErrorInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.CDMPolicySyncErrorInfo, 23));
};


/**
 * @param {?proto.galaxycomplete.domain.CDMPolicySyncErrorInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
*/
proto.galaxycomplete.domain.CDMPolicy.prototype.setSyncErrors = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.clearSyncErrors = function() {
  return this.setSyncErrors(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.hasSyncErrors = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional bool standin = 24;
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getStandin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setStandin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional google.protobuf.Timestamp next_snapshot_time = 25;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getNextSnapshotTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 25));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
*/
proto.galaxycomplete.domain.CDMPolicy.prototype.setNextSnapshotTime = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.clearNextSnapshotTime = function() {
  return this.setNextSnapshotTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.hasNextSnapshotTime = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional bool cdm_suspended = 26;
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getCdmSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setCdmSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional ProjectIdentityInfo project = 40;
 * @return {?proto.galaxycomplete.domain.ProjectIdentityInfo}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getProject = function() {
  return /** @type{?proto.galaxycomplete.domain.ProjectIdentityInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_project_pb.ProjectIdentityInfo, 40));
};


/**
 * @param {?proto.galaxycomplete.domain.ProjectIdentityInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
*/
proto.galaxycomplete.domain.CDMPolicy.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.hasProject = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional DeploymentIdentityInfo deployment = 41;
 * @return {?proto.galaxycomplete.domain.DeploymentIdentityInfo}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getDeployment = function() {
  return /** @type{?proto.galaxycomplete.domain.DeploymentIdentityInfo} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_deployment_pb.DeploymentIdentityInfo, 41));
};


/**
 * @param {?proto.galaxycomplete.domain.DeploymentIdentityInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
*/
proto.galaxycomplete.domain.CDMPolicy.prototype.setDeployment = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.clearDeployment = function() {
  return this.setDeployment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.hasDeployment = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional bool deployment_connected = 42;
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getDeploymentConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.setDeploymentConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};


/**
 * optional CDMPolicyStats stats = 50;
 * @return {?proto.galaxycomplete.domain.CDMPolicyStats}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.getStats = function() {
  return /** @type{?proto.galaxycomplete.domain.CDMPolicyStats} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.CDMPolicyStats, 50));
};


/**
 * @param {?proto.galaxycomplete.domain.CDMPolicyStats|undefined} value
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
*/
proto.galaxycomplete.domain.CDMPolicy.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CDMPolicy} returns this
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicy.prototype.hasStats = function() {
  return jspb.Message.getField(this, 50) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    mirrorDisengage: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorsList: jspb.Message.toObjectList(msg.getErrorsList(),
    proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo}
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.CDMPolicySyncErrorInfo;
  return proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo}
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMirrorDisengage(value);
      break;
    case 2:
      var value = new proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error;
      reader.readMessage(value,proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.deserializeBinaryFromReader);
      msg.addErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMirrorDisengage();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    cdsInitiator: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error}
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error;
  return proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error}
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCdsInitiator(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCdsInitiator();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string cds_initiator = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.prototype.getCdsInitiator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error} returns this
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.prototype.setCdsInitiator = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error} returns this
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error} returns this
*/
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error} returns this
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool mirror_disengage = 1;
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.prototype.getMirrorDisengage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo} returns this
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.prototype.setMirrorDisengage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated Error errors = 2;
 * @return {!Array<!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error>}
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.prototype.getErrorsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error>} value
 * @return {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo} returns this
*/
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.prototype.setErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error}
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.prototype.addErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.Error, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.CDMPolicySyncErrorInfo} returns this
 */
proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.prototype.clearErrorsList = function() {
  return this.setErrorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.CDMPolicyIdentityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.CDMPolicyIdentityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.CDMPolicyIdentityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CDMPolicyIdentityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    policyName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.CDMPolicyIdentityInfo}
 */
proto.galaxycomplete.domain.CDMPolicyIdentityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.CDMPolicyIdentityInfo;
  return proto.galaxycomplete.domain.CDMPolicyIdentityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.CDMPolicyIdentityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.CDMPolicyIdentityInfo}
 */
proto.galaxycomplete.domain.CDMPolicyIdentityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.CDMPolicyIdentityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.CDMPolicyIdentityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.CDMPolicyIdentityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CDMPolicyIdentityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPolicyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string policy_id = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.CDMPolicyIdentityInfo.prototype.getPolicyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyIdentityInfo} returns this
 */
proto.galaxycomplete.domain.CDMPolicyIdentityInfo.prototype.setPolicyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string policy_name = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.CDMPolicyIdentityInfo.prototype.getPolicyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyIdentityInfo} returns this
 */
proto.galaxycomplete.domain.CDMPolicyIdentityInfo.prototype.setPolicyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.CDMPolicyStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.CDMPolicyStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CDMPolicyStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalBase: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalData: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalSynced: jspb.Message.getFieldWithDefault(msg, 3, 0),
    currentDirty: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalChanged: jspb.Message.getFieldWithDefault(msg, 5, 0),
    recentSeconds: jspb.Message.getFieldWithDefault(msg, 6, 0),
    currentChanged: jspb.Message.getFieldWithDefault(msg, 7, 0),
    thinPercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalRemaining: jspb.Message.getFieldWithDefault(msg, 9, 0),
    recentThroughput: jspb.Message.getFieldWithDefault(msg, 10, 0),
    currentThroughput: jspb.Message.getFieldWithDefault(msg, 11, 0),
    progressPercentage: jspb.Message.getFieldWithDefault(msg, 12, 0),
    recentTransferThroughput: jspb.Message.getFieldWithDefault(msg, 13, 0),
    currentTransferThroughput: jspb.Message.getFieldWithDefault(msg, 14, 0),
    recentAvgSourceResponseTime: jspb.Message.getFieldWithDefault(msg, 15, 0),
    currentAvgSourceResponseTime: jspb.Message.getFieldWithDefault(msg, 16, 0),
    recentAvgDestinationResponseTime: jspb.Message.getFieldWithDefault(msg, 17, 0),
    currentAvgDestinationResponseTime: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats}
 */
proto.galaxycomplete.domain.CDMPolicyStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.CDMPolicyStats;
  return proto.galaxycomplete.domain.CDMPolicyStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.CDMPolicyStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats}
 */
proto.galaxycomplete.domain.CDMPolicyStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalBase(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalData(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalSynced(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentDirty(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalChanged(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecentSeconds(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentChanged(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThinPercentage(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalRemaining(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRecentThroughput(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentThroughput(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgressPercentage(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRecentTransferThroughput(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentTransferThroughput(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecentAvgSourceResponseTime(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrentAvgSourceResponseTime(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecentAvgDestinationResponseTime(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCurrentAvgDestinationResponseTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.CDMPolicyStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.CDMPolicyStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CDMPolicyStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalBase();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTotalData();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getTotalSynced();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getCurrentDirty();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getTotalChanged();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getRecentSeconds();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getCurrentChanged();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getThinPercentage();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getTotalRemaining();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getRecentThroughput();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getCurrentThroughput();
  if (f !== 0) {
    writer.writeUint64(
      11,
      f
    );
  }
  f = message.getProgressPercentage();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getRecentTransferThroughput();
  if (f !== 0) {
    writer.writeUint64(
      13,
      f
    );
  }
  f = message.getCurrentTransferThroughput();
  if (f !== 0) {
    writer.writeUint64(
      14,
      f
    );
  }
  f = message.getRecentAvgSourceResponseTime();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getCurrentAvgSourceResponseTime();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getRecentAvgDestinationResponseTime();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getCurrentAvgDestinationResponseTime();
  if (f !== 0) {
    writer.writeUint32(
      18,
      f
    );
  }
};


/**
 * optional uint64 total_base = 1;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getTotalBase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setTotalBase = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 total_data = 2;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getTotalData = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setTotalData = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 total_synced = 3;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getTotalSynced = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setTotalSynced = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 current_dirty = 4;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getCurrentDirty = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setCurrentDirty = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 total_changed = 5;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getTotalChanged = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setTotalChanged = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 recent_seconds = 6;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getRecentSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setRecentSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 current_changed = 7;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getCurrentChanged = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setCurrentChanged = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional float thin_percentage = 8;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getThinPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setThinPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional uint64 total_remaining = 9;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getTotalRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setTotalRemaining = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint64 recent_throughput = 10;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getRecentThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setRecentThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint64 current_throughput = 11;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getCurrentThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setCurrentThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 progress_percentage = 12;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getProgressPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setProgressPercentage = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint64 recent_transfer_throughput = 13;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getRecentTransferThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setRecentTransferThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint64 current_transfer_throughput = 14;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getCurrentTransferThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setCurrentTransferThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint32 recent_avg_source_response_time = 15;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getRecentAvgSourceResponseTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setRecentAvgSourceResponseTime = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint32 current_avg_source_response_time = 16;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getCurrentAvgSourceResponseTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setCurrentAvgSourceResponseTime = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint32 recent_avg_destination_response_time = 17;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getRecentAvgDestinationResponseTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setRecentAvgDestinationResponseTime = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional uint32 current_avg_destination_response_time = 18;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.getCurrentAvgDestinationResponseTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyStats} returns this
 */
proto.galaxycomplete.domain.CDMPolicyStats.prototype.setCurrentAvgDestinationResponseTime = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.CDMPolicyDisk.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.CDMPolicyDisk} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CDMPolicyDisk.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyDiskUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    policy: (f = msg.getPolicy()) && proto.galaxycomplete.domain.CDMPolicyIdentityInfo.toObject(includeInstance, f),
    syncDirection: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sourceDiskName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sourceDiskUuid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sourceDiskCapacity: jspb.Message.getFieldWithDefault(msg, 7, 0),
    policyDiskName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    policyDiskCapacity: jspb.Message.getFieldWithDefault(msg, 9, 0),
    policyDiskClientNames: jspb.Message.getFieldWithDefault(msg, 10, ""),
    mtdiDevicePath: jspb.Message.getFieldWithDefault(msg, 11, ""),
    syncStatus: jspb.Message.getFieldWithDefault(msg, 50, 0),
    syncErrors: (f = msg.getSyncErrors()) && proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.toObject(includeInstance, f),
    stats: (f = msg.getStats()) && proto.galaxycomplete.domain.CDMPolicyStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.CDMPolicyDisk;
  return proto.galaxycomplete.domain.CDMPolicyDisk.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.CDMPolicyDisk} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyDiskUuid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 3:
      var value = new proto.galaxycomplete.domain.CDMPolicyIdentityInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.CDMPolicyIdentityInfo.deserializeBinaryFromReader);
      msg.setPolicy(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSyncDirection(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceDiskName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceDiskUuid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSourceDiskCapacity(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyDiskName(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPolicyDiskCapacity(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyDiskClientNames(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMtdiDevicePath(value);
      break;
    case 50:
      var value = /** @type {!proto.galaxycomplete.domain.enums.CdmPolicySyncStatus.CdmPolicySyncStatus} */ (reader.readEnum());
      msg.setSyncStatus(value);
      break;
    case 51:
      var value = new proto.galaxycomplete.domain.CDMPolicySyncErrorInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.deserializeBinaryFromReader);
      msg.setSyncErrors(value);
      break;
    case 52:
      var value = new proto.galaxycomplete.domain.CDMPolicyStats;
      reader.readMessage(value,proto.galaxycomplete.domain.CDMPolicyStats.deserializeBinaryFromReader);
      msg.setStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.CDMPolicyDisk.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.CDMPolicyDisk} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.CDMPolicyDisk.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyDiskUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPolicy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.galaxycomplete.domain.CDMPolicyIdentityInfo.serializeBinaryToWriter
    );
  }
  f = message.getSyncDirection();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSourceDiskName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSourceDiskUuid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSourceDiskCapacity();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPolicyDiskName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPolicyDiskCapacity();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getPolicyDiskClientNames();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMtdiDevicePath();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSyncStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      50,
      f
    );
  }
  f = message.getSyncErrors();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.galaxycomplete.domain.CDMPolicySyncErrorInfo.serializeBinaryToWriter
    );
  }
  f = message.getStats();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      proto.galaxycomplete.domain.CDMPolicyStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional string policy_disk_uuid = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.getPolicyDiskUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.setPolicyDiskUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
*/
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CDMPolicyIdentityInfo policy = 3;
 * @return {?proto.galaxycomplete.domain.CDMPolicyIdentityInfo}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.getPolicy = function() {
  return /** @type{?proto.galaxycomplete.domain.CDMPolicyIdentityInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.CDMPolicyIdentityInfo, 3));
};


/**
 * @param {?proto.galaxycomplete.domain.CDMPolicyIdentityInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
*/
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.setPolicy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.clearPolicy = function() {
  return this.setPolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.hasPolicy = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string sync_direction = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.getSyncDirection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.setSyncDirection = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string source_disk_name = 5;
 * @return {string}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.getSourceDiskName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.setSourceDiskName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string source_disk_uuid = 6;
 * @return {string}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.getSourceDiskUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.setSourceDiskUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 source_disk_capacity = 7;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.getSourceDiskCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.setSourceDiskCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string policy_disk_name = 8;
 * @return {string}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.getPolicyDiskName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.setPolicyDiskName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 policy_disk_capacity = 9;
 * @return {number}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.getPolicyDiskCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.setPolicyDiskCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string policy_disk_client_names = 10;
 * @return {string}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.getPolicyDiskClientNames = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.setPolicyDiskClientNames = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string mtdi_device_path = 11;
 * @return {string}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.getMtdiDevicePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.setMtdiDevicePath = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional enums.CdmPolicySyncStatus.CdmPolicySyncStatus sync_status = 50;
 * @return {!proto.galaxycomplete.domain.enums.CdmPolicySyncStatus.CdmPolicySyncStatus}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.getSyncStatus = function() {
  return /** @type {!proto.galaxycomplete.domain.enums.CdmPolicySyncStatus.CdmPolicySyncStatus} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.enums.CdmPolicySyncStatus.CdmPolicySyncStatus} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.setSyncStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 50, value);
};


/**
 * optional CDMPolicySyncErrorInfo sync_errors = 51;
 * @return {?proto.galaxycomplete.domain.CDMPolicySyncErrorInfo}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.getSyncErrors = function() {
  return /** @type{?proto.galaxycomplete.domain.CDMPolicySyncErrorInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.CDMPolicySyncErrorInfo, 51));
};


/**
 * @param {?proto.galaxycomplete.domain.CDMPolicySyncErrorInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
*/
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.setSyncErrors = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.clearSyncErrors = function() {
  return this.setSyncErrors(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.hasSyncErrors = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional CDMPolicyStats stats = 52;
 * @return {?proto.galaxycomplete.domain.CDMPolicyStats}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.getStats = function() {
  return /** @type{?proto.galaxycomplete.domain.CDMPolicyStats} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.CDMPolicyStats, 52));
};


/**
 * @param {?proto.galaxycomplete.domain.CDMPolicyStats|undefined} value
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
*/
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.setStats = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.CDMPolicyDisk} returns this
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.clearStats = function() {
  return this.setStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.CDMPolicyDisk.prototype.hasStats = function() {
  return jspb.Message.getField(this, 52) != null;
};


goog.object.extend(exports, proto.galaxycomplete.domain);
