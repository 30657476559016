/**
 * @fileoverview gRPC-Web generated client stub for galaxycomplete.api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: galaxycompletepb/apipb/api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as galaxycompletepb_apipb_api_pb from '../../galaxycompletepb/apipb/api_pb';
import * as galaxycompletepb_commonpb_common_pb from '../../galaxycompletepb/commonpb/common_pb';


export class GeneralServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorEcho = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.GeneralService/Echo',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_commonpb_common_pb.Echo.Request,
    galaxycompletepb_commonpb_common_pb.Echo.Response,
    (request: galaxycompletepb_commonpb_common_pb.Echo.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_commonpb_common_pb.Echo.Response.deserializeBinary
  );

  echo(
    request: galaxycompletepb_commonpb_common_pb.Echo.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_commonpb_common_pb.Echo.Response>;

  echo(
    request: galaxycompletepb_commonpb_common_pb.Echo.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_commonpb_common_pb.Echo.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_commonpb_common_pb.Echo.Response>;

  echo(
    request: galaxycompletepb_commonpb_common_pb.Echo.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_commonpb_common_pb.Echo.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.GeneralService/Echo',
        request,
        metadata || {},
        this.methodDescriptorEcho,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.GeneralService/Echo',
    request,
    metadata || {},
    this.methodDescriptorEcho);
  }

  methodDescriptorGetCurrentTime = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.GeneralService/GetCurrentTime',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_api_pb.GetCurrentTime.Request,
    galaxycompletepb_apipb_api_pb.GetCurrentTime.Response,
    (request: galaxycompletepb_apipb_api_pb.GetCurrentTime.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_api_pb.GetCurrentTime.Response.deserializeBinary
  );

  getCurrentTime(
    request: galaxycompletepb_apipb_api_pb.GetCurrentTime.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_api_pb.GetCurrentTime.Response>;

  getCurrentTime(
    request: galaxycompletepb_apipb_api_pb.GetCurrentTime.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_api_pb.GetCurrentTime.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_api_pb.GetCurrentTime.Response>;

  getCurrentTime(
    request: galaxycompletepb_apipb_api_pb.GetCurrentTime.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_api_pb.GetCurrentTime.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.GeneralService/GetCurrentTime',
        request,
        metadata || {},
        this.methodDescriptorGetCurrentTime,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.GeneralService/GetCurrentTime',
    request,
    metadata || {},
    this.methodDescriptorGetCurrentTime);
  }

  methodDescriptorGetUIFeatures = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.GeneralService/GetUIFeatures',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_api_pb.GetUIFeatures.Request,
    galaxycompletepb_apipb_api_pb.GetUIFeatures.Response,
    (request: galaxycompletepb_apipb_api_pb.GetUIFeatures.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_api_pb.GetUIFeatures.Response.deserializeBinary
  );

  getUIFeatures(
    request: galaxycompletepb_apipb_api_pb.GetUIFeatures.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_api_pb.GetUIFeatures.Response>;

  getUIFeatures(
    request: galaxycompletepb_apipb_api_pb.GetUIFeatures.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_api_pb.GetUIFeatures.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_api_pb.GetUIFeatures.Response>;

  getUIFeatures(
    request: galaxycompletepb_apipb_api_pb.GetUIFeatures.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_api_pb.GetUIFeatures.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.GeneralService/GetUIFeatures',
        request,
        metadata || {},
        this.methodDescriptorGetUIFeatures,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.GeneralService/GetUIFeatures',
    request,
    metadata || {},
    this.methodDescriptorGetUIFeatures);
  }

}

