import {observer} from "mobx-react-lite";
import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Divider,
    SvgIcon,
    Typography,
    Grid,
    Card,
    List,
    ListSubheader,
    ListItem,
    ListItemText, Theme
} from "@mui/material";
import {BsGearFill} from "react-icons/bs";
import {DialogState, useDialogState} from "../../core/dialog/DialogService";
import {DialogTopBar} from "../../core/dialog/DialogComponents";
import {useNavigateToTimewalker} from "../ProjectProtectionCommon";
import {useAppServices} from "../../app/services";
import {AddVolumesDialog, RemoveVolumesDialog} from "./CdmPolicyDetailsVolumeManagementDialogs";


// ======================
// CdmPolicyMenuButton
// ======================


interface CdmPolicyMenuButtonProps {
    dialogState: DialogState;
}

export const CdmPolicyMenuButton: React.FC<CdmPolicyMenuButtonProps> = observer((p) => {
    return <>
        <Button endIcon={<SvgIcon><BsGearFill/></SvgIcon>}
                onClick={p.dialogState.open}
                variant={'contained'}
                color={'secondary'}>
            Policy Actions
        </Button>
    </>

});

// ======================
// CdmPolicyMenuDialog
// ======================

interface CdmPolicyMenuDialogProps {
    dialogState: DialogState;
    addCdmActionDialogState: DialogState;
}

export const CdmPolicyMenuDialog: React.FC<CdmPolicyMenuDialogProps> = observer((p) => {
    const addVolumesDialogState = useDialogState();
    const removeVolumesDialogState = useDialogState();

    const {cdmPolicyService} = useAppServices();

    const addNewCdmAction = () => {
        p.dialogState.setDialogProps({
            type: 'create'
        });
        p.addCdmActionDialogState.open();
    }

    const goToTimewalkerWizard = useNavigateToTimewalker();

    const startTimewalkerWizard = () => {
        cdmPolicyService.initTimeWalkerWizardState(cdmPolicyService.currentPolicyId);
        goToTimewalkerWizard();
    }
    const getCardStyle = () => ({
        backgroundColor: (t: Theme) => t.palette.background.default,
        height: '100%'
    })

    return <>
        <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} maxWidth={'md'} fullWidth>
        <DialogTopBar dialogState={p.dialogState} title={'Policy Actions Portal'}/>
        <Divider/>
        <DialogContent>
            <Box>
                <Typography variant={'h6'}>Data Protection & Recovery</Typography>
                <Typography variant={'body1'}>Select a quick action</Typography>
            </Box>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Card sx={getCardStyle()}>
                        <List>
                            <ListSubheader>General Policy Actions</ListSubheader>
                            <ListItem button disabled>
                                <ListItemText primary={'Edit Policy'}/>
                            </ListItem>
                            <Divider/>
                            <ListItem button disabled>
                                <ListItemText primary={'Delete Policy'}/>
                            </ListItem>
                            <Divider/>
                            <ListItem button onClick={addVolumesDialogState.open}>
                                <ListItemText primary={'Add Policy Volume(s)'}/>
                            </ListItem>
                            <ListItem button onClick={removeVolumesDialogState.open}>
                                <ListItemText primary={'Remove Policy Volume(s)'}/>
                            </ListItem>
                        </List>

                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={getCardStyle()}>
                        <List>
                            <ListSubheader>Snapshots Actions</ListSubheader>
                            <ListItem button disabled>
                                <ListItemText primary={'Add New Policy Workflow'} onClick={addNewCdmAction}/>
                            </ListItem>
                            <Divider/>
                            <ListItem button disabled>
                                <ListItemText primary={'Enable All Scheduled Workflows'}
                                              secondary={'Toggle on all scheduled actions'}/>
                            </ListItem>
                            <Divider/>
                            <ListItem button disabled>
                                <ListItemText primary={'Disable All Scheduled Workflows'}
                                              secondary={'Toggle off all scheduled actions'}/>
                            </ListItem>
                        </List>

                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card sx={getCardStyle()}>
                        <List>
                            <ListSubheader>Recovery Actions</ListSubheader>
                            <ListItem disabled button onClick={startTimewalkerWizard}>
                                <ListItemText primary={'TimeWalker™'}
                                              secondary={'Restore Data from Previous Points-in-time'}/>
                            </ListItem>
                        </List>
                    </Card>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
        {addVolumesDialogState.isOpen &&
            <AddVolumesDialog dialogState={addVolumesDialogState}/>
        }
        {
            removeVolumesDialogState.isOpen &&
            <RemoveVolumesDialog dialogState={removeVolumesDialogState}/>
        }
        </>
})
