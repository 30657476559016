// Project: GalaxyComplete
// Created: 9/24/20 by sammy
// File: TopBar

import * as React from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, Drawer, IconButton, SvgIcon, Toolbar, Slide, useScrollTrigger, AppBar, Card, Theme, useTheme } from "@mui/material";
import { CurrentUserInfo } from "./CurrentUserInfo";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import { useAppServices } from "../app/services";
import { CDSLogo, LightModeIcon } from "./LayoutCommon";
import { MdMenu } from "react-icons/md";
import { useHelpDrawerWidth } from "./MainLayout";

// ======================
// TopBar
// ======================

interface TopBarProps {
    isDesktop: boolean;
    isPrivateEdition: boolean;
    isHelpDrawerOpen: boolean;
}

export const TopBar: React.FC<TopBarProps> = observer(({ ...p }) => {
    const { isDesktop, isHelpDrawerOpen } = p;
    const theme = useTheme();
    const helpDrawerWidth = useHelpDrawerWidth();
    const getTopbarStyles = useCallback(() => {
        if (isDesktop) {
            return {
                background: (t: Theme) => t.palette.cirrus.main,
                zIndex: (t: Theme) => t.zIndex.appBar,
                height: (t: Theme) => t.spacing(9),
            };
        } else {
            return {
                background: (t: Theme) => t.palette.cirrus.medium,
                zIndex: (t: Theme) => t.zIndex.appBar,
            };
        }
    }, [isDesktop]);

    return (
        <AppBar
            elevation={2}
            sx={{
                transition: theme.transitions.create(["margin", "width"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                ...(isHelpDrawerOpen && {
                    width: `calc(100% - ${helpDrawerWidth}px)`,
                    marginRight: `${helpDrawerWidth}px`,
                    transition: theme.transitions.create(["margin", "width"], {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                }),
            }}
        >
            <Toolbar sx={getTopbarStyles()}>
                <Box flexGrow={1} alignItems={"center"} display={"flex"}>
                    {!isDesktop && (
                        <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
                            <Box pr={1}>
                                <MobileMenuIcon />
                            </Box>
                            <CDSLogo
                                imageAttributes={{
                                    width: "200px",
                                    height: "100%",
                                }}
                            />
                        </Box>
                    )}
                </Box>
                <CurrentUserInfo isDesktop={isDesktop} />
            </Toolbar>
        </AppBar>
    );
});

// ======================
// CurrentTimeDisplay
// ======================
interface CurrentTimeDisplayProps {}

const CurrentTimeDisplay: React.FC<CurrentTimeDisplayProps> = observer((p) => {
    const [currentTime, setTime] = useState(new Date());

    useEffectOnce(() => {
        let interval = window.setInterval(() => setTime(new Date()), 1000);
        return () => {
            if (interval) {
                window.clearInterval(interval);
            }
        };
    });

    return (
        <Box>
            <Typography>{format(currentTime, "PPPPpppp")}</Typography>
        </Box>
    );
});

// ======================
// MobileMenuIcon
// ======================

interface MobileMenuIconProps {}

export const MobileMenuIcon: React.FC<MobileMenuIconProps> = observer((props) => {
    const { appGlobalService } = useAppServices();

    return (
        <IconButton onClick={() => appGlobalService.openSideBar()} aria-label={"mobileSideMenu"}>
            <SvgIcon>
                <MdMenu />
            </SvgIcon>
        </IconButton>
    );
});
