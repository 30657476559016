import {GRPCServices} from "../../grpc/grpcapi";
import {makeAutoObservable} from "mobx";
import {ServerListData} from "../../core/data/ListData";
import {ProjectService} from "../../project/ProjectService";
import {
    GetPhoenixDeploymentDetails,
    ListPhoenixDeployments, RemoveDeployment
} from "../../../_proto/galaxycompletepb/apipb/deployment_api_pb";
import {
    PhoenixDeploymentDetails,
    PhoenixDeploymentInfo
} from "../../../_proto/galaxycompletepb/apipb/domainpb/phoenix_pb";
import {
    mockPhoenixDeploymentDetails,
    mockPhoenixDeploymentsList
} from "../../core/testutils/fixtures/MockPhoenixDeploymentService";
import {ServerData} from "../../core/data/ServerData";
import {DialogService} from "../../core/dialog/DialogService";
import {ProgressService} from "../../core/progress/ProgressService";

export class PhoenixDeploymentService{
    private api: GRPCServices;
    private projectService: ProjectService;
    private dialogService: DialogService;
    private progressService: ProgressService;

    constructor (api: GRPCServices, projectService: ProjectService, dialogService: DialogService, progressService: ProgressService){
        this.api = api;
        this.projectService = projectService;
        this.dialogService = dialogService;
        this.progressService = progressService;
        makeAutoObservable(this);
    }

    phoenixDeployments = new ServerListData<ListPhoenixDeployments.Response, PhoenixDeploymentInfo>().setDataFetcher(this.fetchPhoenixDeployments.bind(this));

    phoenixDeploymentDetails = new ServerData<PhoenixDeploymentDetails>().setDataFetcher(this.fetchPhoenixDeploymentDetails.bind(this));

    async fetchPhoenixDeployments(onlyConnected: boolean = false){
        const req = new ListPhoenixDeployments.Request()
            .setPageParams(this.phoenixDeployments.pagerParam)
            .setProjectId(this.projectService.currentProjectID)
            .setOnlyConnected(onlyConnected);

        return await this.api.deploymentService.listPhoenixDeployments(req, null);
        //return mockPhoenixDeploymentsList;
    }

    async fetchPhoenixDeploymentDetails(systemId: string){
        const req = new GetPhoenixDeploymentDetails.Request()
            .setSystemId(systemId);

        const response = await this.api.deploymentService.getPhoenixDeploymentDetails(req, null);
        return response.getDeployment();
        //return mockPhoenixDeploymentDetails;
    }

    async removeDeployment(deploymentId: string) {
        const req       = new RemoveDeployment.Request()
            .setSystemId(deploymentId);
        const confirmed = await this.dialogService.addConfirmDialog({
            message: `Are you sure you want to remove this host from this project?`,
            autoConfirmationQuestionLine: false
    });
        if (confirmed)
        {
            return await this.progressService.track(this.api.deploymentService.removeDeployment(req, null));
        }
    }

}