import { GRPCServices } from "../grpc/grpcapi";
import { makeAutoObservable } from "mobx";
import { DialogService } from "../core/dialog/DialogService";
import { ProgressService } from "../core/progress/ProgressService";
import { TransactionCounterInfo } from "../../_proto/galaxycompletepb/apipb/domainpb/license_pb";
import {
    CompletePeLicenseTransaction,
    GeneratedPeLicenseRequest,
    GeneratePeLicenseRequest,
    GetPendingPeLicenseRequest,
    GetPrivateEditionLocalStatus,
} from "../../_proto/galaxycompletepb/apipb/pelocal_api_pb";
import { ServerData } from "../core/data/ServerData";
import { LicenseVaultCounterType } from "../../_proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { mockGeneratedLicenseRequest } from "../core/testutils/fixtures/MockPrivateEditionService";

export class PrivateEditionService {
    private api: GRPCServices;
    private dialogService: DialogService;
    private progressService: ProgressService;

    constructor(api: GRPCServices, dialogService: DialogService, progressService: ProgressService) {
        this.api = api;
        this.dialogService = dialogService;
        this.progressService = progressService;
        makeAutoObservable(this);
    }

    privateEditionStatus = new ServerData<GetPrivateEditionLocalStatus.Response.AsObject>().setDataFetcher(this.getPrivateEditionStatus.bind(this));
    generatedPeLicenseRequest = new ServerData<GeneratedPeLicenseRequest.AsObject>().setDataFetcher(this.generatePeLicenseRequest.bind(this));
    pendingLicenseRequest = new ServerData<GetPendingPeLicenseRequest.Response>().setDataFetcher(this.getPendingLicenseRequest.bind(this));

    async getPrivateEditionStatus() {
        const req = new GetPrivateEditionLocalStatus.Request();
        // return new GetPrivateEditionStatus.Response()
        //     .setStatus(new PrivateEditionStatus()
        //         .setSystemName('system name')
        //         .setLicenseExpirationTime(new Timestamp().setSeconds(new Date('July 2, 2022').getTime()/1000))
        //         .setLicenseActivated(true)
        //         .setSystemVersion('0.0.0')
        //     ).toObject()
        const res = await this.api.privateEditionLocalService.getPrivateEditionLocalStatus(req, null);
        return res.toObject();
    }

    async generatePeLicenseRequest(counters: TransactionCounterInfo[], requestExtension: boolean) {
        const req = new GeneratePeLicenseRequest.Request().setRequestExtension(requestExtension).setCountersList(counters);

        const res = await this.api.privateEditionLocalService.generatePeLicenseRequest(req, null);
        //return mockGeneratedLicenseRequest.toObject()

        return res.getGeneratedRequest().toObject();
    }

    async completePeLicenseTransaction(receipt: string) {
        const req = new CompletePeLicenseTransaction.Request().setSignedReceipt(receipt);

        return await this.progressService.track(this.api.privateEditionLocalService.completePeLicenseTransaction(req, null));
    }

    async getPendingLicenseRequest() {
        const req = new GetPendingPeLicenseRequest.Request();
        return await this.api.privateEditionLocalService.getPendingPeLicenseRequest(req, null);

        // return new GetPendingPeLicenseRequest.Response()
        //     .setGeneratedRequest(mockGeneratedLicenseRequest)
    }
}
