// source: galaxymigratepb/galaxy_migrate_migration.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var galaxymigratepb_galaxy_migrate_mtdi_pb = require('../galaxymigratepb/galaxy_migrate_mtdi_pb.js');
goog.object.extend(proto, galaxymigratepb_galaxy_migrate_mtdi_pb);
var galaxymigratepb_galaxy_migrate_qos_pb = require('../galaxymigratepb/galaxy_migrate_qos_pb.js');
goog.object.extend(proto, galaxymigratepb_galaxy_migrate_qos_pb);
var galaxymigratepb_galaxy_migrate_storage_pb = require('../galaxymigratepb/galaxy_migrate_storage_pb.js');
goog.object.extend(proto, galaxymigratepb_galaxy_migrate_storage_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.galaxymigrate.CancelMigrationSessionRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.CancelMigrationSessionResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.CreateMigrationSessionRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.CreateMigrationSessionResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.CutoverMigrationSessionRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.CutoverMigrationSessionResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.DeleteMigrationSessionRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.DeleteMigrationSessionResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.FinalCutoverMigrationSessionRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.FinalCutoverMigrationSessionResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.GetMigrationSessionDetailsRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.GetMigrationSessionDetailsResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.HelloRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.HelloResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.ListMigrationSessionsRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.ListMigrationSessionsResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.MigrationSession', null, global);
goog.exportSymbol('proto.galaxymigrate.MigrationSession.ConfigParams', null, global);
goog.exportSymbol('proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule', null, global);
goog.exportSymbol('proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule', null, global);
goog.exportSymbol('proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval', null, global);
goog.exportSymbol('proto.galaxymigrate.MigrationSession.Statistics', null, global);
goog.exportSymbol('proto.galaxymigrate.MigrationSession.Status', null, global);
goog.exportSymbol('proto.galaxymigrate.MigrationSession.Volume', null, global);
goog.exportSymbol('proto.galaxymigrate.MigrationSession.Volume.Destination', null, global);
goog.exportSymbol('proto.galaxymigrate.MigrationSession.Volume.Source', null, global);
goog.exportSymbol('proto.galaxymigrate.MigrationSessionVolumeParam', null, global);
goog.exportSymbol('proto.galaxymigrate.RestartMigrationSessionRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.RestartMigrationSessionResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.RevertCutoverMigrationSessionRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.RevertCutoverMigrationSessionResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.SessionHook', null, global);
goog.exportSymbol('proto.galaxymigrate.SessionHook.Action', null, global);
goog.exportSymbol('proto.galaxymigrate.SetPostMigrationRoutineScriptRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.SetPostMigrationRoutineScriptResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.SuspendMigrationSessionRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.SuspendMigrationSessionResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.SyncMigrationSessionRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.SyncMigrationSessionResponse', null, global);
goog.exportSymbol('proto.galaxymigrate.UpdateMigrationSessionRequest', null, global);
goog.exportSymbol('proto.galaxymigrate.UpdateMigrationSessionResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.HelloRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.HelloRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.HelloRequest.displayName = 'proto.galaxymigrate.HelloRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.HelloResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.HelloResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.HelloResponse.displayName = 'proto.galaxymigrate.HelloResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.SetPostMigrationRoutineScriptRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.SetPostMigrationRoutineScriptRequest.displayName = 'proto.galaxymigrate.SetPostMigrationRoutineScriptRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.SetPostMigrationRoutineScriptResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.SetPostMigrationRoutineScriptResponse.displayName = 'proto.galaxymigrate.SetPostMigrationRoutineScriptResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest.displayName = 'proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse.displayName = 'proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.CutoverMigrationSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.CutoverMigrationSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.CutoverMigrationSessionRequest.displayName = 'proto.galaxymigrate.CutoverMigrationSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.CutoverMigrationSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.CutoverMigrationSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.CutoverMigrationSessionResponse.displayName = 'proto.galaxymigrate.CutoverMigrationSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.FinalCutoverMigrationSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.FinalCutoverMigrationSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.FinalCutoverMigrationSessionRequest.displayName = 'proto.galaxymigrate.FinalCutoverMigrationSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.FinalCutoverMigrationSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.FinalCutoverMigrationSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.FinalCutoverMigrationSessionResponse.displayName = 'proto.galaxymigrate.FinalCutoverMigrationSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.RevertCutoverMigrationSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.RevertCutoverMigrationSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.RevertCutoverMigrationSessionRequest.displayName = 'proto.galaxymigrate.RevertCutoverMigrationSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.RevertCutoverMigrationSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.RevertCutoverMigrationSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.RevertCutoverMigrationSessionResponse.displayName = 'proto.galaxymigrate.RevertCutoverMigrationSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.CancelMigrationSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.CancelMigrationSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.CancelMigrationSessionRequest.displayName = 'proto.galaxymigrate.CancelMigrationSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.CancelMigrationSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.CancelMigrationSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.CancelMigrationSessionResponse.displayName = 'proto.galaxymigrate.CancelMigrationSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.RestartMigrationSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.RestartMigrationSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.RestartMigrationSessionRequest.displayName = 'proto.galaxymigrate.RestartMigrationSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.RestartMigrationSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.RestartMigrationSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.RestartMigrationSessionResponse.displayName = 'proto.galaxymigrate.RestartMigrationSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.SyncMigrationSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.SyncMigrationSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.SyncMigrationSessionRequest.displayName = 'proto.galaxymigrate.SyncMigrationSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.SyncMigrationSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.SyncMigrationSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.SyncMigrationSessionResponse.displayName = 'proto.galaxymigrate.SyncMigrationSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.SuspendMigrationSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.SuspendMigrationSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.SuspendMigrationSessionRequest.displayName = 'proto.galaxymigrate.SuspendMigrationSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.SuspendMigrationSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.SuspendMigrationSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.SuspendMigrationSessionResponse.displayName = 'proto.galaxymigrate.SuspendMigrationSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetMigrationSessionDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetMigrationSessionDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetMigrationSessionDetailsRequest.displayName = 'proto.galaxymigrate.GetMigrationSessionDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetMigrationSessionDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetMigrationSessionDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetMigrationSessionDetailsResponse.displayName = 'proto.galaxymigrate.GetMigrationSessionDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.DeleteMigrationSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.DeleteMigrationSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.DeleteMigrationSessionRequest.displayName = 'proto.galaxymigrate.DeleteMigrationSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.DeleteMigrationSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.DeleteMigrationSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.DeleteMigrationSessionResponse.displayName = 'proto.galaxymigrate.DeleteMigrationSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.UpdateMigrationSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.UpdateMigrationSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.UpdateMigrationSessionRequest.displayName = 'proto.galaxymigrate.UpdateMigrationSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.UpdateMigrationSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.UpdateMigrationSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.UpdateMigrationSessionResponse.displayName = 'proto.galaxymigrate.UpdateMigrationSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.displayName = 'proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.displayName = 'proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.ListMigrationSessionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.ListMigrationSessionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.ListMigrationSessionsRequest.displayName = 'proto.galaxymigrate.ListMigrationSessionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.ListMigrationSessionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.ListMigrationSessionsResponse.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.ListMigrationSessionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.ListMigrationSessionsResponse.displayName = 'proto.galaxymigrate.ListMigrationSessionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.CreateMigrationSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.CreateMigrationSessionRequest.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.CreateMigrationSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.CreateMigrationSessionRequest.displayName = 'proto.galaxymigrate.CreateMigrationSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.CreateMigrationSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.CreateMigrationSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.CreateMigrationSessionResponse.displayName = 'proto.galaxymigrate.CreateMigrationSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.MigrationSessionVolumeParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.MigrationSessionVolumeParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.MigrationSessionVolumeParam.displayName = 'proto.galaxymigrate.MigrationSessionVolumeParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.MigrationSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.MigrationSession.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.MigrationSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.MigrationSession.displayName = 'proto.galaxymigrate.MigrationSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.MigrationSession.ConfigParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.MigrationSession.ConfigParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.MigrationSession.ConfigParams.displayName = 'proto.galaxymigrate.MigrationSession.ConfigParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.displayName = 'proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.repeatedFields_, null);
};
goog.inherits(proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.displayName = 'proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.displayName = 'proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.MigrationSession.Volume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.MigrationSession.Volume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.MigrationSession.Volume.displayName = 'proto.galaxymigrate.MigrationSession.Volume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.MigrationSession.Volume.Source = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.MigrationSession.Volume.Source, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.MigrationSession.Volume.Source.displayName = 'proto.galaxymigrate.MigrationSession.Volume.Source';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.MigrationSession.Volume.Destination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.MigrationSession.Volume.Destination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.MigrationSession.Volume.Destination.displayName = 'proto.galaxymigrate.MigrationSession.Volume.Destination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.MigrationSession.Statistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.MigrationSession.Statistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.MigrationSession.Statistics.displayName = 'proto.galaxymigrate.MigrationSession.Statistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxymigrate.SessionHook = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxymigrate.SessionHook, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxymigrate.SessionHook.displayName = 'proto.galaxymigrate.SessionHook';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.HelloRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.HelloRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.HelloRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.HelloRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.HelloRequest}
 */
proto.galaxymigrate.HelloRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.HelloRequest;
  return proto.galaxymigrate.HelloRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.HelloRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.HelloRequest}
 */
proto.galaxymigrate.HelloRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.HelloRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.HelloRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.HelloRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.HelloRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.HelloResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.HelloResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.HelloResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.HelloResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.HelloResponse}
 */
proto.galaxymigrate.HelloResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.HelloResponse;
  return proto.galaxymigrate.HelloResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.HelloResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.HelloResponse}
 */
proto.galaxymigrate.HelloResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.HelloResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.HelloResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.HelloResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.HelloResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.SetPostMigrationRoutineScriptRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.SetPostMigrationRoutineScriptRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    scriptContent: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.SetPostMigrationRoutineScriptRequest}
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.SetPostMigrationRoutineScriptRequest;
  return proto.galaxymigrate.SetPostMigrationRoutineScriptRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.SetPostMigrationRoutineScriptRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.SetPostMigrationRoutineScriptRequest}
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScriptContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.SetPostMigrationRoutineScriptRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.SetPostMigrationRoutineScriptRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScriptContent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string script_content = 1;
 * @return {string}
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptRequest.prototype.getScriptContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.SetPostMigrationRoutineScriptRequest} returns this
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptRequest.prototype.setScriptContent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.SetPostMigrationRoutineScriptResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.SetPostMigrationRoutineScriptResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.SetPostMigrationRoutineScriptResponse}
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.SetPostMigrationRoutineScriptResponse;
  return proto.galaxymigrate.SetPostMigrationRoutineScriptResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.SetPostMigrationRoutineScriptResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.SetPostMigrationRoutineScriptResponse}
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.SetPostMigrationRoutineScriptResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.SetPostMigrationRoutineScriptResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SetPostMigrationRoutineScriptResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    localPolicyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest}
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest;
  return proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest}
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocalPolicyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocalPolicyId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 local_policy_id = 1;
 * @return {number}
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest.prototype.getLocalPolicyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest} returns this
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDRequest.prototype.setLocalPolicyId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse}
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse;
  return proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse}
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse} returns this
 */
proto.galaxymigrate.FindMigrationSessionUUIDByLocalIDResponse.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.CutoverMigrationSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.CutoverMigrationSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.CutoverMigrationSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.CutoverMigrationSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.CutoverMigrationSessionRequest}
 */
proto.galaxymigrate.CutoverMigrationSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.CutoverMigrationSessionRequest;
  return proto.galaxymigrate.CutoverMigrationSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.CutoverMigrationSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.CutoverMigrationSessionRequest}
 */
proto.galaxymigrate.CutoverMigrationSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.CutoverMigrationSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.CutoverMigrationSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.CutoverMigrationSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.CutoverMigrationSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxymigrate.CutoverMigrationSessionRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.CutoverMigrationSessionRequest} returns this
 */
proto.galaxymigrate.CutoverMigrationSessionRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.CutoverMigrationSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.CutoverMigrationSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.CutoverMigrationSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.CutoverMigrationSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.CutoverMigrationSessionResponse}
 */
proto.galaxymigrate.CutoverMigrationSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.CutoverMigrationSessionResponse;
  return proto.galaxymigrate.CutoverMigrationSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.CutoverMigrationSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.CutoverMigrationSessionResponse}
 */
proto.galaxymigrate.CutoverMigrationSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.CutoverMigrationSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.CutoverMigrationSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.CutoverMigrationSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.CutoverMigrationSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.FinalCutoverMigrationSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.FinalCutoverMigrationSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.FinalCutoverMigrationSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.FinalCutoverMigrationSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.FinalCutoverMigrationSessionRequest}
 */
proto.galaxymigrate.FinalCutoverMigrationSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.FinalCutoverMigrationSessionRequest;
  return proto.galaxymigrate.FinalCutoverMigrationSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.FinalCutoverMigrationSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.FinalCutoverMigrationSessionRequest}
 */
proto.galaxymigrate.FinalCutoverMigrationSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.FinalCutoverMigrationSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.FinalCutoverMigrationSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.FinalCutoverMigrationSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.FinalCutoverMigrationSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxymigrate.FinalCutoverMigrationSessionRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.FinalCutoverMigrationSessionRequest} returns this
 */
proto.galaxymigrate.FinalCutoverMigrationSessionRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.FinalCutoverMigrationSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.FinalCutoverMigrationSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.FinalCutoverMigrationSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.FinalCutoverMigrationSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.FinalCutoverMigrationSessionResponse}
 */
proto.galaxymigrate.FinalCutoverMigrationSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.FinalCutoverMigrationSessionResponse;
  return proto.galaxymigrate.FinalCutoverMigrationSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.FinalCutoverMigrationSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.FinalCutoverMigrationSessionResponse}
 */
proto.galaxymigrate.FinalCutoverMigrationSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.FinalCutoverMigrationSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.FinalCutoverMigrationSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.FinalCutoverMigrationSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.FinalCutoverMigrationSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.RevertCutoverMigrationSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.RevertCutoverMigrationSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.RevertCutoverMigrationSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.RevertCutoverMigrationSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.RevertCutoverMigrationSessionRequest}
 */
proto.galaxymigrate.RevertCutoverMigrationSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.RevertCutoverMigrationSessionRequest;
  return proto.galaxymigrate.RevertCutoverMigrationSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.RevertCutoverMigrationSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.RevertCutoverMigrationSessionRequest}
 */
proto.galaxymigrate.RevertCutoverMigrationSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.RevertCutoverMigrationSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.RevertCutoverMigrationSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.RevertCutoverMigrationSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.RevertCutoverMigrationSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxymigrate.RevertCutoverMigrationSessionRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.RevertCutoverMigrationSessionRequest} returns this
 */
proto.galaxymigrate.RevertCutoverMigrationSessionRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.RevertCutoverMigrationSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.RevertCutoverMigrationSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.RevertCutoverMigrationSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.RevertCutoverMigrationSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.RevertCutoverMigrationSessionResponse}
 */
proto.galaxymigrate.RevertCutoverMigrationSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.RevertCutoverMigrationSessionResponse;
  return proto.galaxymigrate.RevertCutoverMigrationSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.RevertCutoverMigrationSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.RevertCutoverMigrationSessionResponse}
 */
proto.galaxymigrate.RevertCutoverMigrationSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.RevertCutoverMigrationSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.RevertCutoverMigrationSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.RevertCutoverMigrationSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.RevertCutoverMigrationSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.CancelMigrationSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.CancelMigrationSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.CancelMigrationSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.CancelMigrationSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.CancelMigrationSessionRequest}
 */
proto.galaxymigrate.CancelMigrationSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.CancelMigrationSessionRequest;
  return proto.galaxymigrate.CancelMigrationSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.CancelMigrationSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.CancelMigrationSessionRequest}
 */
proto.galaxymigrate.CancelMigrationSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.CancelMigrationSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.CancelMigrationSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.CancelMigrationSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.CancelMigrationSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxymigrate.CancelMigrationSessionRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.CancelMigrationSessionRequest} returns this
 */
proto.galaxymigrate.CancelMigrationSessionRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.CancelMigrationSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.CancelMigrationSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.CancelMigrationSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.CancelMigrationSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.CancelMigrationSessionResponse}
 */
proto.galaxymigrate.CancelMigrationSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.CancelMigrationSessionResponse;
  return proto.galaxymigrate.CancelMigrationSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.CancelMigrationSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.CancelMigrationSessionResponse}
 */
proto.galaxymigrate.CancelMigrationSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.CancelMigrationSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.CancelMigrationSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.CancelMigrationSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.CancelMigrationSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.RestartMigrationSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.RestartMigrationSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.RestartMigrationSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.RestartMigrationSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.RestartMigrationSessionRequest}
 */
proto.galaxymigrate.RestartMigrationSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.RestartMigrationSessionRequest;
  return proto.galaxymigrate.RestartMigrationSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.RestartMigrationSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.RestartMigrationSessionRequest}
 */
proto.galaxymigrate.RestartMigrationSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.RestartMigrationSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.RestartMigrationSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.RestartMigrationSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.RestartMigrationSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxymigrate.RestartMigrationSessionRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.RestartMigrationSessionRequest} returns this
 */
proto.galaxymigrate.RestartMigrationSessionRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.RestartMigrationSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.RestartMigrationSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.RestartMigrationSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.RestartMigrationSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.RestartMigrationSessionResponse}
 */
proto.galaxymigrate.RestartMigrationSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.RestartMigrationSessionResponse;
  return proto.galaxymigrate.RestartMigrationSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.RestartMigrationSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.RestartMigrationSessionResponse}
 */
proto.galaxymigrate.RestartMigrationSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.RestartMigrationSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.RestartMigrationSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.RestartMigrationSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.RestartMigrationSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.SyncMigrationSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.SyncMigrationSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.SyncMigrationSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncMigrationSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.SyncMigrationSessionRequest}
 */
proto.galaxymigrate.SyncMigrationSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.SyncMigrationSessionRequest;
  return proto.galaxymigrate.SyncMigrationSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.SyncMigrationSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.SyncMigrationSessionRequest}
 */
proto.galaxymigrate.SyncMigrationSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.SyncMigrationSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.SyncMigrationSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.SyncMigrationSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncMigrationSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxymigrate.SyncMigrationSessionRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.SyncMigrationSessionRequest} returns this
 */
proto.galaxymigrate.SyncMigrationSessionRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.SyncMigrationSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.SyncMigrationSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.SyncMigrationSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncMigrationSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.SyncMigrationSessionResponse}
 */
proto.galaxymigrate.SyncMigrationSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.SyncMigrationSessionResponse;
  return proto.galaxymigrate.SyncMigrationSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.SyncMigrationSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.SyncMigrationSessionResponse}
 */
proto.galaxymigrate.SyncMigrationSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.SyncMigrationSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.SyncMigrationSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.SyncMigrationSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SyncMigrationSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.SuspendMigrationSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.SuspendMigrationSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.SuspendMigrationSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SuspendMigrationSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.SuspendMigrationSessionRequest}
 */
proto.galaxymigrate.SuspendMigrationSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.SuspendMigrationSessionRequest;
  return proto.galaxymigrate.SuspendMigrationSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.SuspendMigrationSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.SuspendMigrationSessionRequest}
 */
proto.galaxymigrate.SuspendMigrationSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.SuspendMigrationSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.SuspendMigrationSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.SuspendMigrationSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SuspendMigrationSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxymigrate.SuspendMigrationSessionRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.SuspendMigrationSessionRequest} returns this
 */
proto.galaxymigrate.SuspendMigrationSessionRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.SuspendMigrationSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.SuspendMigrationSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.SuspendMigrationSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SuspendMigrationSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.SuspendMigrationSessionResponse}
 */
proto.galaxymigrate.SuspendMigrationSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.SuspendMigrationSessionResponse;
  return proto.galaxymigrate.SuspendMigrationSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.SuspendMigrationSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.SuspendMigrationSessionResponse}
 */
proto.galaxymigrate.SuspendMigrationSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.SuspendMigrationSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.SuspendMigrationSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.SuspendMigrationSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SuspendMigrationSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetMigrationSessionDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetMigrationSessionDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetMigrationSessionDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationSessionDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetMigrationSessionDetailsRequest}
 */
proto.galaxymigrate.GetMigrationSessionDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetMigrationSessionDetailsRequest;
  return proto.galaxymigrate.GetMigrationSessionDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetMigrationSessionDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetMigrationSessionDetailsRequest}
 */
proto.galaxymigrate.GetMigrationSessionDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetMigrationSessionDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetMigrationSessionDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetMigrationSessionDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationSessionDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxymigrate.GetMigrationSessionDetailsRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetMigrationSessionDetailsRequest} returns this
 */
proto.galaxymigrate.GetMigrationSessionDetailsRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetMigrationSessionDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetMigrationSessionDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetMigrationSessionDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationSessionDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && proto.galaxymigrate.MigrationSession.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetMigrationSessionDetailsResponse}
 */
proto.galaxymigrate.GetMigrationSessionDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetMigrationSessionDetailsResponse;
  return proto.galaxymigrate.GetMigrationSessionDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetMigrationSessionDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetMigrationSessionDetailsResponse}
 */
proto.galaxymigrate.GetMigrationSessionDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.MigrationSession;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetMigrationSessionDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetMigrationSessionDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetMigrationSessionDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationSessionDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.MigrationSession.serializeBinaryToWriter
    );
  }
};


/**
 * optional MigrationSession session = 1;
 * @return {?proto.galaxymigrate.MigrationSession}
 */
proto.galaxymigrate.GetMigrationSessionDetailsResponse.prototype.getSession = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession, 1));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession|undefined} value
 * @return {!proto.galaxymigrate.GetMigrationSessionDetailsResponse} returns this
*/
proto.galaxymigrate.GetMigrationSessionDetailsResponse.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetMigrationSessionDetailsResponse} returns this
 */
proto.galaxymigrate.GetMigrationSessionDetailsResponse.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetMigrationSessionDetailsResponse.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.DeleteMigrationSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.DeleteMigrationSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.DeleteMigrationSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.DeleteMigrationSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    keepInserted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.DeleteMigrationSessionRequest}
 */
proto.galaxymigrate.DeleteMigrationSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.DeleteMigrationSessionRequest;
  return proto.galaxymigrate.DeleteMigrationSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.DeleteMigrationSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.DeleteMigrationSessionRequest}
 */
proto.galaxymigrate.DeleteMigrationSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKeepInserted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.DeleteMigrationSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.DeleteMigrationSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.DeleteMigrationSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.DeleteMigrationSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKeepInserted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxymigrate.DeleteMigrationSessionRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.DeleteMigrationSessionRequest} returns this
 */
proto.galaxymigrate.DeleteMigrationSessionRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool keep_inserted = 2;
 * @return {boolean}
 */
proto.galaxymigrate.DeleteMigrationSessionRequest.prototype.getKeepInserted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.DeleteMigrationSessionRequest} returns this
 */
proto.galaxymigrate.DeleteMigrationSessionRequest.prototype.setKeepInserted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.DeleteMigrationSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.DeleteMigrationSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.DeleteMigrationSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.DeleteMigrationSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.DeleteMigrationSessionResponse}
 */
proto.galaxymigrate.DeleteMigrationSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.DeleteMigrationSessionResponse;
  return proto.galaxymigrate.DeleteMigrationSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.DeleteMigrationSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.DeleteMigrationSessionResponse}
 */
proto.galaxymigrate.DeleteMigrationSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.DeleteMigrationSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.DeleteMigrationSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.DeleteMigrationSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.DeleteMigrationSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.UpdateMigrationSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.UpdateMigrationSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.UpdateMigrationSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.UpdateMigrationSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    configParams: (f = msg.getConfigParams()) && proto.galaxymigrate.MigrationSession.ConfigParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.UpdateMigrationSessionRequest}
 */
proto.galaxymigrate.UpdateMigrationSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.UpdateMigrationSessionRequest;
  return proto.galaxymigrate.UpdateMigrationSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.UpdateMigrationSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.UpdateMigrationSessionRequest}
 */
proto.galaxymigrate.UpdateMigrationSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.MigrationSession.ConfigParams;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.ConfigParams.deserializeBinaryFromReader);
      msg.setConfigParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.UpdateMigrationSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.UpdateMigrationSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.UpdateMigrationSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.UpdateMigrationSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfigParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.MigrationSession.ConfigParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.galaxymigrate.UpdateMigrationSessionRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.UpdateMigrationSessionRequest} returns this
 */
proto.galaxymigrate.UpdateMigrationSessionRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional MigrationSession.ConfigParams config_params = 2;
 * @return {?proto.galaxymigrate.MigrationSession.ConfigParams}
 */
proto.galaxymigrate.UpdateMigrationSessionRequest.prototype.getConfigParams = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.ConfigParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession.ConfigParams, 2));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.ConfigParams|undefined} value
 * @return {!proto.galaxymigrate.UpdateMigrationSessionRequest} returns this
*/
proto.galaxymigrate.UpdateMigrationSessionRequest.prototype.setConfigParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.UpdateMigrationSessionRequest} returns this
 */
proto.galaxymigrate.UpdateMigrationSessionRequest.prototype.clearConfigParams = function() {
  return this.setConfigParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.UpdateMigrationSessionRequest.prototype.hasConfigParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.UpdateMigrationSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.UpdateMigrationSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.UpdateMigrationSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.UpdateMigrationSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.UpdateMigrationSessionResponse}
 */
proto.galaxymigrate.UpdateMigrationSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.UpdateMigrationSessionResponse;
  return proto.galaxymigrate.UpdateMigrationSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.UpdateMigrationSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.UpdateMigrationSessionResponse}
 */
proto.galaxymigrate.UpdateMigrationSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.UpdateMigrationSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.UpdateMigrationSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.UpdateMigrationSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.UpdateMigrationSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionVolumeUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromBytes: jspb.Message.getFieldWithDefault(msg, 3, 0),
    toBytes: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numberOfPoints: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest;
  return proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionVolumeUuid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromBytes(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setToBytes(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionVolumeUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromBytes();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getToBytes();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getNumberOfPoints();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional string session_volume_uuid = 2;
 * @return {string}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.prototype.getSessionVolumeUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest} returns this
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.prototype.setSessionVolumeUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 from_bytes = 3;
 * @return {number}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.prototype.getFromBytes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest} returns this
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.prototype.setFromBytes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 to_bytes = 4;
 * @return {number}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.prototype.getToBytes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest} returns this
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.prototype.setToBytes = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 number_of_points = 5;
 * @return {number}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.prototype.getNumberOfPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest} returns this
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewRequest.prototype.setNumberOfPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    numberOfPoints: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bytesPerPoint: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fromLocation: jspb.Message.getFieldWithDefault(msg, 3, 0),
    toLocation: jspb.Message.getFieldWithDefault(msg, 4, 0),
    timestamp: (f = msg.getTimestamp()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pointsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse;
  return proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumberOfPoints(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBytesPerPoint(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromLocation(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setToLocation(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPoints(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumberOfPoints();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBytesPerPoint();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getFromLocation();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getToLocation();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      6,
      f
    );
  }
};


/**
 * optional uint32 number_of_points = 1;
 * @return {number}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.getNumberOfPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse} returns this
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.setNumberOfPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 bytes_per_point = 2;
 * @return {number}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.getBytesPerPoint = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse} returns this
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.setBytesPerPoint = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 from_location = 3;
 * @return {number}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.getFromLocation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse} returns this
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.setFromLocation = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 to_location = 4;
 * @return {number}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.getToLocation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse} returns this
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.setToLocation = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp timestamp = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.getTimestamp = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse} returns this
*/
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse} returns this
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated uint32 points = 6;
 * @return {!Array<number>}
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.getPointsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse} returns this
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.setPointsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse} returns this
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.addPoints = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse} returns this
 */
proto.galaxymigrate.GetMigrationVolumeChangesDistributionViewResponse.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.ListMigrationSessionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.ListMigrationSessionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.ListMigrationSessionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListMigrationSessionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.ListMigrationSessionsRequest}
 */
proto.galaxymigrate.ListMigrationSessionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.ListMigrationSessionsRequest;
  return proto.galaxymigrate.ListMigrationSessionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.ListMigrationSessionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.ListMigrationSessionsRequest}
 */
proto.galaxymigrate.ListMigrationSessionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.ListMigrationSessionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.ListMigrationSessionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.ListMigrationSessionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListMigrationSessionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.ListMigrationSessionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.ListMigrationSessionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.ListMigrationSessionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.ListMigrationSessionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListMigrationSessionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionsList: jspb.Message.toObjectList(msg.getSessionsList(),
    proto.galaxymigrate.MigrationSession.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.ListMigrationSessionsResponse}
 */
proto.galaxymigrate.ListMigrationSessionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.ListMigrationSessionsResponse;
  return proto.galaxymigrate.ListMigrationSessionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.ListMigrationSessionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.ListMigrationSessionsResponse}
 */
proto.galaxymigrate.ListMigrationSessionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.MigrationSession;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.deserializeBinaryFromReader);
      msg.addSessions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.ListMigrationSessionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.ListMigrationSessionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.ListMigrationSessionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.ListMigrationSessionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxymigrate.MigrationSession.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MigrationSession sessions = 1;
 * @return {!Array<!proto.galaxymigrate.MigrationSession>}
 */
proto.galaxymigrate.ListMigrationSessionsResponse.prototype.getSessionsList = function() {
  return /** @type{!Array<!proto.galaxymigrate.MigrationSession>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.MigrationSession, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.MigrationSession>} value
 * @return {!proto.galaxymigrate.ListMigrationSessionsResponse} returns this
*/
proto.galaxymigrate.ListMigrationSessionsResponse.prototype.setSessionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.MigrationSession=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.MigrationSession}
 */
proto.galaxymigrate.ListMigrationSessionsResponse.prototype.addSessions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.MigrationSession, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.ListMigrationSessionsResponse} returns this
 */
proto.galaxymigrate.ListMigrationSessionsResponse.prototype.clearSessionsList = function() {
  return this.setSessionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.CreateMigrationSessionRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.CreateMigrationSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.CreateMigrationSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.CreateMigrationSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.CreateMigrationSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    configParams: (f = msg.getConfigParams()) && proto.galaxymigrate.MigrationSession.ConfigParams.toObject(includeInstance, f),
    volumesList: jspb.Message.toObjectList(msg.getVolumesList(),
    proto.galaxymigrate.MigrationSessionVolumeParam.toObject, includeInstance),
    remoteSystemId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.CreateMigrationSessionRequest}
 */
proto.galaxymigrate.CreateMigrationSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.CreateMigrationSessionRequest;
  return proto.galaxymigrate.CreateMigrationSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.CreateMigrationSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.CreateMigrationSessionRequest}
 */
proto.galaxymigrate.CreateMigrationSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.MigrationSession.ConfigParams;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.ConfigParams.deserializeBinaryFromReader);
      msg.setConfigParams(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.MigrationSessionVolumeParam;
      reader.readMessage(value,proto.galaxymigrate.MigrationSessionVolumeParam.deserializeBinaryFromReader);
      msg.addVolumes(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemoteSystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.CreateMigrationSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.CreateMigrationSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.CreateMigrationSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.CreateMigrationSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigParams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.MigrationSession.ConfigParams.serializeBinaryToWriter
    );
  }
  f = message.getVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.galaxymigrate.MigrationSessionVolumeParam.serializeBinaryToWriter
    );
  }
  f = message.getRemoteSystemId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional MigrationSession.ConfigParams config_params = 1;
 * @return {?proto.galaxymigrate.MigrationSession.ConfigParams}
 */
proto.galaxymigrate.CreateMigrationSessionRequest.prototype.getConfigParams = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.ConfigParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession.ConfigParams, 1));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.ConfigParams|undefined} value
 * @return {!proto.galaxymigrate.CreateMigrationSessionRequest} returns this
*/
proto.galaxymigrate.CreateMigrationSessionRequest.prototype.setConfigParams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.CreateMigrationSessionRequest} returns this
 */
proto.galaxymigrate.CreateMigrationSessionRequest.prototype.clearConfigParams = function() {
  return this.setConfigParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.CreateMigrationSessionRequest.prototype.hasConfigParams = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated MigrationSessionVolumeParam volumes = 2;
 * @return {!Array<!proto.galaxymigrate.MigrationSessionVolumeParam>}
 */
proto.galaxymigrate.CreateMigrationSessionRequest.prototype.getVolumesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.MigrationSessionVolumeParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.MigrationSessionVolumeParam, 2));
};


/**
 * @param {!Array<!proto.galaxymigrate.MigrationSessionVolumeParam>} value
 * @return {!proto.galaxymigrate.CreateMigrationSessionRequest} returns this
*/
proto.galaxymigrate.CreateMigrationSessionRequest.prototype.setVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxymigrate.MigrationSessionVolumeParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.MigrationSessionVolumeParam}
 */
proto.galaxymigrate.CreateMigrationSessionRequest.prototype.addVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxymigrate.MigrationSessionVolumeParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.CreateMigrationSessionRequest} returns this
 */
proto.galaxymigrate.CreateMigrationSessionRequest.prototype.clearVolumesList = function() {
  return this.setVolumesList([]);
};


/**
 * optional string remote_system_id = 10;
 * @return {string}
 */
proto.galaxymigrate.CreateMigrationSessionRequest.prototype.getRemoteSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.CreateMigrationSessionRequest} returns this
 */
proto.galaxymigrate.CreateMigrationSessionRequest.prototype.setRemoteSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.CreateMigrationSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.CreateMigrationSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.CreateMigrationSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.CreateMigrationSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && proto.galaxymigrate.MigrationSession.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.CreateMigrationSessionResponse}
 */
proto.galaxymigrate.CreateMigrationSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.CreateMigrationSessionResponse;
  return proto.galaxymigrate.CreateMigrationSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.CreateMigrationSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.CreateMigrationSessionResponse}
 */
proto.galaxymigrate.CreateMigrationSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.MigrationSession;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.CreateMigrationSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.CreateMigrationSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.CreateMigrationSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.CreateMigrationSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.MigrationSession.serializeBinaryToWriter
    );
  }
};


/**
 * optional MigrationSession session = 1;
 * @return {?proto.galaxymigrate.MigrationSession}
 */
proto.galaxymigrate.CreateMigrationSessionResponse.prototype.getSession = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession, 1));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession|undefined} value
 * @return {!proto.galaxymigrate.CreateMigrationSessionResponse} returns this
*/
proto.galaxymigrate.CreateMigrationSessionResponse.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.CreateMigrationSessionResponse} returns this
 */
proto.galaxymigrate.CreateMigrationSessionResponse.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.CreateMigrationSessionResponse.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.MigrationSessionVolumeParam.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.MigrationSessionVolumeParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.MigrationSessionVolumeParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSessionVolumeParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    destinationUuid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.MigrationSessionVolumeParam}
 */
proto.galaxymigrate.MigrationSessionVolumeParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.MigrationSessionVolumeParam;
  return proto.galaxymigrate.MigrationSessionVolumeParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.MigrationSessionVolumeParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.MigrationSessionVolumeParam}
 */
proto.galaxymigrate.MigrationSessionVolumeParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationUuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.MigrationSessionVolumeParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.MigrationSessionVolumeParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.MigrationSessionVolumeParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSessionVolumeParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDestinationUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string source_uuid = 1;
 * @return {string}
 */
proto.galaxymigrate.MigrationSessionVolumeParam.prototype.getSourceUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.MigrationSessionVolumeParam} returns this
 */
proto.galaxymigrate.MigrationSessionVolumeParam.prototype.setSourceUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string destination_uuid = 2;
 * @return {string}
 */
proto.galaxymigrate.MigrationSessionVolumeParam.prototype.getDestinationUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.MigrationSessionVolumeParam} returns this
 */
proto.galaxymigrate.MigrationSessionVolumeParam.prototype.setDestinationUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.MigrationSession.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.MigrationSession.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.MigrationSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.MigrationSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionUuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    managedByNodeUuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    localPolicyId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    configParams: (f = msg.getConfigParams()) && proto.galaxymigrate.MigrationSession.ConfigParams.toObject(includeInstance, f),
    remoteSystemId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sessionVolumesList: jspb.Message.toObjectList(msg.getSessionVolumesList(),
    proto.galaxymigrate.MigrationSession.Volume.toObject, includeInstance),
    bootVolumeSession: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    status: jspb.Message.getFieldWithDefault(msg, 20, 0),
    statistics: (f = msg.getStatistics()) && proto.galaxymigrate.MigrationSession.Statistics.toObject(includeInstance, f),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastSynchronizedTime: (f = msg.getLastSynchronizedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.MigrationSession}
 */
proto.galaxymigrate.MigrationSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.MigrationSession;
  return proto.galaxymigrate.MigrationSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.MigrationSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.MigrationSession}
 */
proto.galaxymigrate.MigrationSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagedByNodeUuid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLocalPolicyId(value);
      break;
    case 4:
      var value = new proto.galaxymigrate.MigrationSession.ConfigParams;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.ConfigParams.deserializeBinaryFromReader);
      msg.setConfigParams(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemoteSystemId(value);
      break;
    case 10:
      var value = new proto.galaxymigrate.MigrationSession.Volume;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.Volume.deserializeBinaryFromReader);
      msg.addSessionVolumes(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBootVolumeSession(value);
      break;
    case 20:
      var value = /** @type {!proto.galaxymigrate.MigrationSession.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 22:
      var value = new proto.galaxymigrate.MigrationSession.Statistics;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.Statistics.deserializeBinaryFromReader);
      msg.setStatistics(value);
      break;
    case 23:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 24:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 25:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSynchronizedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.MigrationSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.MigrationSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.MigrationSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getManagedByNodeUuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocalPolicyId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getConfigParams();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.galaxymigrate.MigrationSession.ConfigParams.serializeBinaryToWriter
    );
  }
  f = message.getRemoteSystemId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSessionVolumesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.galaxymigrate.MigrationSession.Volume.serializeBinaryToWriter
    );
  }
  f = message.getBootVolumeSession();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getStatistics();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.galaxymigrate.MigrationSession.Statistics.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastSynchronizedTime();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxymigrate.MigrationSession.Status = {
  UNKNOWN: 0,
  IN_ERROR: 1,
  FAILED: 2,
  NEW: 3,
  TRACKING: 4,
  SYNCING: 5,
  HOLDING_IO: 6,
  SUSPENDED: 7,
  MIRRORING: 8,
  STANDING_IN: 9,
  CUTOVER: 10,
  COMPLETED: 11,
  CANCELLED: 12
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.MigrationSession.ConfigParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.MigrationSession.ConfigParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.ConfigParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    autoResyncInterval: (f = msg.getAutoResyncInterval()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    qosLevel: jspb.Message.getFieldWithDefault(msg, 3, 0),
    postSyncHook: (f = msg.getPostSyncHook()) && proto.galaxymigrate.SessionHook.toObject(includeInstance, f),
    maxSyncThroughput: jspb.Message.getFieldWithDefault(msg, 20, 0),
    qosSchedule: (f = msg.getQosSchedule()) && proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.toObject(includeInstance, f),
    qosStartTime: (f = msg.getQosStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.MigrationSession.ConfigParams;
  return proto.galaxymigrate.MigrationSession.ConfigParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.MigrationSession.ConfigParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setAutoResyncInterval(value);
      break;
    case 3:
      var value = /** @type {!proto.galaxymigrate.SyncQos.ImpactLevel} */ (reader.readEnum());
      msg.setQosLevel(value);
      break;
    case 10:
      var value = new proto.galaxymigrate.SessionHook;
      reader.readMessage(value,proto.galaxymigrate.SessionHook.deserializeBinaryFromReader);
      msg.setPostSyncHook(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMaxSyncThroughput(value);
      break;
    case 21:
      var value = new proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.deserializeBinaryFromReader);
      msg.setQosSchedule(value);
      break;
    case 22:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setQosStartTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.MigrationSession.ConfigParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.MigrationSession.ConfigParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.ConfigParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAutoResyncInterval();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getQosLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPostSyncHook();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.galaxymigrate.SessionHook.serializeBinaryToWriter
    );
  }
  f = message.getMaxSyncThroughput();
  if (f !== 0) {
    writer.writeUint64(
      20,
      f
    );
  }
  f = message.getQosSchedule();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.serializeBinaryToWriter
    );
  }
  f = message.getQosStartTime();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    monday: (f = msg.getMonday()) && proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.toObject(includeInstance, f),
    tuesday: (f = msg.getTuesday()) && proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.toObject(includeInstance, f),
    wednesday: (f = msg.getWednesday()) && proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.toObject(includeInstance, f),
    thursday: (f = msg.getThursday()) && proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.toObject(includeInstance, f),
    friday: (f = msg.getFriday()) && proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.toObject(includeInstance, f),
    saturday: (f = msg.getSaturday()) && proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.toObject(includeInstance, f),
    sunday: (f = msg.getSunday()) && proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule;
  return proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.deserializeBinaryFromReader);
      msg.setMonday(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.deserializeBinaryFromReader);
      msg.setTuesday(value);
      break;
    case 3:
      var value = new proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.deserializeBinaryFromReader);
      msg.setWednesday(value);
      break;
    case 4:
      var value = new proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.deserializeBinaryFromReader);
      msg.setThursday(value);
      break;
    case 5:
      var value = new proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.deserializeBinaryFromReader);
      msg.setFriday(value);
      break;
    case 6:
      var value = new proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.deserializeBinaryFromReader);
      msg.setSaturday(value);
      break;
    case 7:
      var value = new proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.deserializeBinaryFromReader);
      msg.setSunday(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonday();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.serializeBinaryToWriter
    );
  }
  f = message.getTuesday();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.serializeBinaryToWriter
    );
  }
  f = message.getWednesday();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.serializeBinaryToWriter
    );
  }
  f = message.getThursday();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.serializeBinaryToWriter
    );
  }
  f = message.getFriday();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.serializeBinaryToWriter
    );
  }
  f = message.getSaturday();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.serializeBinaryToWriter
    );
  }
  f = message.getSunday();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    intervalsList: jspb.Message.toObjectList(msg.getIntervalsList(),
    proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule;
  return proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.deserializeBinaryFromReader);
      msg.addIntervals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntervalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, 0),
    end: jspb.Message.getFieldWithDefault(msg, 2, 0),
    qosLevel: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval;
  return proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEnd(value);
      break;
    case 3:
      var value = /** @type {!proto.galaxymigrate.SyncQos.ImpactLevel} */ (reader.readEnum());
      msg.setQosLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEnd();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getQosLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int32 start = 1;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 end = 2;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.prototype.getEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.prototype.setEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional SyncQos.ImpactLevel qos_level = 3;
 * @return {!proto.galaxymigrate.SyncQos.ImpactLevel}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.prototype.getQosLevel = function() {
  return /** @type {!proto.galaxymigrate.SyncQos.ImpactLevel} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.galaxymigrate.SyncQos.ImpactLevel} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval.prototype.setQosLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated Interval intervals = 1;
 * @return {!Array<!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval>}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.prototype.getIntervalsList = function() {
  return /** @type{!Array<!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval, 1));
};


/**
 * @param {!Array<!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval>} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule} returns this
*/
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.prototype.setIntervalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.prototype.addIntervals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.Interval, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule.prototype.clearIntervalsList = function() {
  return this.setIntervalsList([]);
};


/**
 * optional DailySchedule monday = 1;
 * @return {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.getMonday = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule, 1));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} returns this
*/
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.setMonday = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.clearMonday = function() {
  return this.setMonday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.hasMonday = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DailySchedule tuesday = 2;
 * @return {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.getTuesday = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule, 2));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} returns this
*/
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.setTuesday = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.clearTuesday = function() {
  return this.setTuesday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.hasTuesday = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DailySchedule wednesday = 3;
 * @return {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.getWednesday = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule, 3));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} returns this
*/
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.setWednesday = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.clearWednesday = function() {
  return this.setWednesday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.hasWednesday = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DailySchedule thursday = 4;
 * @return {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.getThursday = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule, 4));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} returns this
*/
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.setThursday = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.clearThursday = function() {
  return this.setThursday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.hasThursday = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional DailySchedule friday = 5;
 * @return {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.getFriday = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule, 5));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} returns this
*/
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.setFriday = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.clearFriday = function() {
  return this.setFriday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.hasFriday = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional DailySchedule saturday = 6;
 * @return {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.getSaturday = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule, 6));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} returns this
*/
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.setSaturday = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.clearSaturday = function() {
  return this.setSaturday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.hasSaturday = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DailySchedule sunday = 7;
 * @return {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.getSunday = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule, 7));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.DailySchedule|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} returns this
*/
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.setSunday = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.clearSunday = function() {
  return this.setSunday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule.prototype.hasSunday = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Duration auto_resync_interval = 2;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.getAutoResyncInterval = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 2));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams} returns this
*/
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.setAutoResyncInterval = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.clearAutoResyncInterval = function() {
  return this.setAutoResyncInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.hasAutoResyncInterval = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SyncQos.ImpactLevel qos_level = 3;
 * @return {!proto.galaxymigrate.SyncQos.ImpactLevel}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.getQosLevel = function() {
  return /** @type {!proto.galaxymigrate.SyncQos.ImpactLevel} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.galaxymigrate.SyncQos.ImpactLevel} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.setQosLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional SessionHook post_sync_hook = 10;
 * @return {?proto.galaxymigrate.SessionHook}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.getPostSyncHook = function() {
  return /** @type{?proto.galaxymigrate.SessionHook} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.SessionHook, 10));
};


/**
 * @param {?proto.galaxymigrate.SessionHook|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams} returns this
*/
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.setPostSyncHook = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.clearPostSyncHook = function() {
  return this.setPostSyncHook(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.hasPostSyncHook = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional uint64 max_sync_throughput = 20;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.getMaxSyncThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.setMaxSyncThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional QosSchedule qos_schedule = 21;
 * @return {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.getQosSchedule = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule, 21));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.ConfigParams.QosSchedule|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams} returns this
*/
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.setQosSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.clearQosSchedule = function() {
  return this.setQosSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.hasQosSchedule = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional google.protobuf.Timestamp qos_start_time = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.getQosStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams} returns this
*/
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.setQosStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.ConfigParams} returns this
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.clearQosStartTime = function() {
  return this.setQosStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.ConfigParams.prototype.hasQosStartTime = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.MigrationSession.Volume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.MigrationSession.Volume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.Volume.toObject = function(includeInstance, msg) {
  var f, obj = {
    param: (f = msg.getParam()) && proto.galaxymigrate.MigrationSessionVolumeParam.toObject(includeInstance, f),
    source: (f = msg.getSource()) && proto.galaxymigrate.MigrationSession.Volume.Source.toObject(includeInstance, f),
    destination: (f = msg.getDestination()) && proto.galaxymigrate.MigrationSession.Volume.Destination.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    errortext: jspb.Message.getFieldWithDefault(msg, 11, ""),
    statistics: (f = msg.getStatistics()) && proto.galaxymigrate.MigrationSession.Statistics.toObject(includeInstance, f),
    finalcutover: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    lastSynchronizedTime: (f = msg.getLastSynchronizedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.MigrationSession.Volume}
 */
proto.galaxymigrate.MigrationSession.Volume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.MigrationSession.Volume;
  return proto.galaxymigrate.MigrationSession.Volume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.MigrationSession.Volume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.MigrationSession.Volume}
 */
proto.galaxymigrate.MigrationSession.Volume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxymigrate.MigrationSessionVolumeParam;
      reader.readMessage(value,proto.galaxymigrate.MigrationSessionVolumeParam.deserializeBinaryFromReader);
      msg.setParam(value);
      break;
    case 2:
      var value = new proto.galaxymigrate.MigrationSession.Volume.Source;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.Volume.Source.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 3:
      var value = new proto.galaxymigrate.MigrationSession.Volume.Destination;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.Volume.Destination.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    case 10:
      var value = /** @type {!proto.galaxymigrate.MigrationSession.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrortext(value);
      break;
    case 12:
      var value = new proto.galaxymigrate.MigrationSession.Statistics;
      reader.readMessage(value,proto.galaxymigrate.MigrationSession.Statistics.deserializeBinaryFromReader);
      msg.setStatistics(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFinalcutover(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSynchronizedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.MigrationSession.Volume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.MigrationSession.Volume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.Volume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParam();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxymigrate.MigrationSessionVolumeParam.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.galaxymigrate.MigrationSession.Volume.Source.serializeBinaryToWriter
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.galaxymigrate.MigrationSession.Volume.Destination.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getErrortext();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStatistics();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.galaxymigrate.MigrationSession.Statistics.serializeBinaryToWriter
    );
  }
  f = message.getFinalcutover();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getLastSynchronizedTime();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.MigrationSession.Volume.Source.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.MigrationSession.Volume.Source.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.MigrationSession.Volume.Source} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.Volume.Source.toObject = function(includeInstance, msg) {
  var f, obj = {
    insertedVolume: (f = msg.getInsertedVolume()) && galaxymigratepb_galaxy_migrate_mtdi_pb.InsertedVolume.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.MigrationSession.Volume.Source}
 */
proto.galaxymigrate.MigrationSession.Volume.Source.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.MigrationSession.Volume.Source;
  return proto.galaxymigrate.MigrationSession.Volume.Source.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.MigrationSession.Volume.Source} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.MigrationSession.Volume.Source}
 */
proto.galaxymigrate.MigrationSession.Volume.Source.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_mtdi_pb.InsertedVolume;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_mtdi_pb.InsertedVolume.deserializeBinaryFromReader);
      msg.setInsertedVolume(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.MigrationSession.Volume.Source.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.MigrationSession.Volume.Source.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.MigrationSession.Volume.Source} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.Volume.Source.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInsertedVolume();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_mtdi_pb.InsertedVolume.serializeBinaryToWriter
    );
  }
};


/**
 * optional InsertedVolume inserted_volume = 1;
 * @return {?proto.galaxymigrate.InsertedVolume}
 */
proto.galaxymigrate.MigrationSession.Volume.Source.prototype.getInsertedVolume = function() {
  return /** @type{?proto.galaxymigrate.InsertedVolume} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_mtdi_pb.InsertedVolume, 1));
};


/**
 * @param {?proto.galaxymigrate.InsertedVolume|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.Volume.Source} returns this
*/
proto.galaxymigrate.MigrationSession.Volume.Source.prototype.setInsertedVolume = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.Volume.Source} returns this
 */
proto.galaxymigrate.MigrationSession.Volume.Source.prototype.clearInsertedVolume = function() {
  return this.setInsertedVolume(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.Volume.Source.prototype.hasInsertedVolume = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.MigrationSession.Volume.Destination.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.MigrationSession.Volume.Destination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.MigrationSession.Volume.Destination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.Volume.Destination.toObject = function(includeInstance, msg) {
  var f, obj = {
    storageResourceVolume: (f = msg.getStorageResourceVolume()) && galaxymigratepb_galaxy_migrate_storage_pb.EnlistedStorageResourceVolume.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.MigrationSession.Volume.Destination}
 */
proto.galaxymigrate.MigrationSession.Volume.Destination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.MigrationSession.Volume.Destination;
  return proto.galaxymigrate.MigrationSession.Volume.Destination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.MigrationSession.Volume.Destination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.MigrationSession.Volume.Destination}
 */
proto.galaxymigrate.MigrationSession.Volume.Destination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxymigratepb_galaxy_migrate_storage_pb.EnlistedStorageResourceVolume;
      reader.readMessage(value,galaxymigratepb_galaxy_migrate_storage_pb.EnlistedStorageResourceVolume.deserializeBinaryFromReader);
      msg.setStorageResourceVolume(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.MigrationSession.Volume.Destination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.MigrationSession.Volume.Destination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.MigrationSession.Volume.Destination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.Volume.Destination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStorageResourceVolume();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxymigratepb_galaxy_migrate_storage_pb.EnlistedStorageResourceVolume.serializeBinaryToWriter
    );
  }
};


/**
 * optional EnlistedStorageResourceVolume storage_resource_volume = 1;
 * @return {?proto.galaxymigrate.EnlistedStorageResourceVolume}
 */
proto.galaxymigrate.MigrationSession.Volume.Destination.prototype.getStorageResourceVolume = function() {
  return /** @type{?proto.galaxymigrate.EnlistedStorageResourceVolume} */ (
    jspb.Message.getWrapperField(this, galaxymigratepb_galaxy_migrate_storage_pb.EnlistedStorageResourceVolume, 1));
};


/**
 * @param {?proto.galaxymigrate.EnlistedStorageResourceVolume|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.Volume.Destination} returns this
*/
proto.galaxymigrate.MigrationSession.Volume.Destination.prototype.setStorageResourceVolume = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.Volume.Destination} returns this
 */
proto.galaxymigrate.MigrationSession.Volume.Destination.prototype.clearStorageResourceVolume = function() {
  return this.setStorageResourceVolume(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.Volume.Destination.prototype.hasStorageResourceVolume = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MigrationSessionVolumeParam param = 1;
 * @return {?proto.galaxymigrate.MigrationSessionVolumeParam}
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.getParam = function() {
  return /** @type{?proto.galaxymigrate.MigrationSessionVolumeParam} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSessionVolumeParam, 1));
};


/**
 * @param {?proto.galaxymigrate.MigrationSessionVolumeParam|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.Volume} returns this
*/
proto.galaxymigrate.MigrationSession.Volume.prototype.setParam = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.Volume} returns this
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.clearParam = function() {
  return this.setParam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.hasParam = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Source source = 2;
 * @return {?proto.galaxymigrate.MigrationSession.Volume.Source}
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.getSource = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.Volume.Source} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession.Volume.Source, 2));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.Volume.Source|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.Volume} returns this
*/
proto.galaxymigrate.MigrationSession.Volume.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.Volume} returns this
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.hasSource = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Destination destination = 3;
 * @return {?proto.galaxymigrate.MigrationSession.Volume.Destination}
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.getDestination = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.Volume.Destination} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession.Volume.Destination, 3));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.Volume.Destination|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.Volume} returns this
*/
proto.galaxymigrate.MigrationSession.Volume.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.Volume} returns this
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Status status = 10;
 * @return {!proto.galaxymigrate.MigrationSession.Status}
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.getStatus = function() {
  return /** @type {!proto.galaxymigrate.MigrationSession.Status} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.galaxymigrate.MigrationSession.Status} value
 * @return {!proto.galaxymigrate.MigrationSession.Volume} returns this
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string errorText = 11;
 * @return {string}
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.getErrortext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.MigrationSession.Volume} returns this
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.setErrortext = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional Statistics statistics = 12;
 * @return {?proto.galaxymigrate.MigrationSession.Statistics}
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.getStatistics = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.Statistics} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession.Statistics, 12));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.Statistics|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.Volume} returns this
*/
proto.galaxymigrate.MigrationSession.Volume.prototype.setStatistics = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.Volume} returns this
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.clearStatistics = function() {
  return this.setStatistics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.hasStatistics = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool finalCutover = 13;
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.getFinalcutover = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.MigrationSession.Volume} returns this
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.setFinalcutover = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp last_synchronized_time = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.getLastSynchronizedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.Volume} returns this
*/
proto.galaxymigrate.MigrationSession.Volume.prototype.setLastSynchronizedTime = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.Volume} returns this
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.clearLastSynchronizedTime = function() {
  return this.setLastSynchronizedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.Volume.prototype.hasLastSynchronizedTime = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.MigrationSession.Statistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.MigrationSession.Statistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.Statistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalData: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalSynced: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalChanged: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalBase: jspb.Message.getFieldWithDefault(msg, 4, 0),
    totalRemaining: jspb.Message.getFieldWithDefault(msg, 5, 0),
    totalTransferred: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalThinData: jspb.Message.getFieldWithDefault(msg, 7, 0),
    thinPercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    progressPercentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    currentThroughput: jspb.Message.getFieldWithDefault(msg, 20, 0),
    currentTransferThroughput: jspb.Message.getFieldWithDefault(msg, 21, 0),
    currentAvgSourceResponseTime: jspb.Message.getFieldWithDefault(msg, 22, 0),
    currentAvgDestinationResponseTime: jspb.Message.getFieldWithDefault(msg, 23, 0),
    recentActiveThroughput: jspb.Message.getFieldWithDefault(msg, 24, 0),
    recentActiveTransferThroughput: jspb.Message.getFieldWithDefault(msg, 25, 0),
    totalActiveTime: (f = msg.getTotalActiveTime()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    currentDirty: jspb.Message.getFieldWithDefault(msg, 50, 0),
    currentChanged: jspb.Message.getFieldWithDefault(msg, 51, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.MigrationSession.Statistics}
 */
proto.galaxymigrate.MigrationSession.Statistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.MigrationSession.Statistics;
  return proto.galaxymigrate.MigrationSession.Statistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.MigrationSession.Statistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.MigrationSession.Statistics}
 */
proto.galaxymigrate.MigrationSession.Statistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalData(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalSynced(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalChanged(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalBase(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalRemaining(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalTransferred(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalThinData(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setThinPercentage(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setProgressPercentage(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentThroughput(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentTransferThroughput(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentAvgSourceResponseTime(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentAvgDestinationResponseTime(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRecentActiveThroughput(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRecentActiveTransferThroughput(value);
      break;
    case 30:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setTotalActiveTime(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentDirty(value);
      break;
    case 51:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCurrentChanged(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.MigrationSession.Statistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.MigrationSession.Statistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.MigrationSession.Statistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalData();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTotalSynced();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getTotalChanged();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getTotalBase();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getTotalRemaining();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getTotalTransferred();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getTotalThinData();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getThinPercentage();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getProgressPercentage();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getCurrentThroughput();
  if (f !== 0) {
    writer.writeUint64(
      20,
      f
    );
  }
  f = message.getCurrentTransferThroughput();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
  f = message.getCurrentAvgSourceResponseTime();
  if (f !== 0) {
    writer.writeUint64(
      22,
      f
    );
  }
  f = message.getCurrentAvgDestinationResponseTime();
  if (f !== 0) {
    writer.writeUint64(
      23,
      f
    );
  }
  f = message.getRecentActiveThroughput();
  if (f !== 0) {
    writer.writeUint64(
      24,
      f
    );
  }
  f = message.getRecentActiveTransferThroughput();
  if (f !== 0) {
    writer.writeUint64(
      25,
      f
    );
  }
  f = message.getTotalActiveTime();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getCurrentDirty();
  if (f !== 0) {
    writer.writeUint64(
      50,
      f
    );
  }
  f = message.getCurrentChanged();
  if (f !== 0) {
    writer.writeUint64(
      51,
      f
    );
  }
};


/**
 * optional uint64 total_data = 1;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getTotalData = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setTotalData = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 total_synced = 2;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getTotalSynced = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setTotalSynced = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 total_changed = 3;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getTotalChanged = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setTotalChanged = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 total_base = 4;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getTotalBase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setTotalBase = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 total_remaining = 5;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getTotalRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setTotalRemaining = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 total_transferred = 6;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getTotalTransferred = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setTotalTransferred = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint64 total_thin_data = 7;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getTotalThinData = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setTotalThinData = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional float thin_percentage = 10;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getThinPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setThinPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float progress_percentage = 11;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getProgressPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setProgressPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional uint64 current_throughput = 20;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getCurrentThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setCurrentThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint64 current_transfer_throughput = 21;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getCurrentTransferThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setCurrentTransferThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional uint64 current_avg_source_response_time = 22;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getCurrentAvgSourceResponseTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setCurrentAvgSourceResponseTime = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional uint64 current_avg_destination_response_time = 23;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getCurrentAvgDestinationResponseTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setCurrentAvgDestinationResponseTime = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional uint64 recent_active_throughput = 24;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getRecentActiveThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setRecentActiveThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional uint64 recent_active_transfer_throughput = 25;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getRecentActiveTransferThroughput = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setRecentActiveTransferThroughput = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional google.protobuf.Duration total_active_time = 30;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getTotalActiveTime = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 30));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
*/
proto.galaxymigrate.MigrationSession.Statistics.prototype.setTotalActiveTime = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.clearTotalActiveTime = function() {
  return this.setTotalActiveTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.hasTotalActiveTime = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional uint64 current_dirty = 50;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getCurrentDirty = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setCurrentDirty = function(value) {
  return jspb.Message.setProto3IntField(this, 50, value);
};


/**
 * optional uint64 current_changed = 51;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.getCurrentChanged = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 51, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession.Statistics} returns this
 */
proto.galaxymigrate.MigrationSession.Statistics.prototype.setCurrentChanged = function(value) {
  return jspb.Message.setProto3IntField(this, 51, value);
};


/**
 * optional string session_uuid = 1;
 * @return {string}
 */
proto.galaxymigrate.MigrationSession.prototype.getSessionUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.MigrationSession} returns this
 */
proto.galaxymigrate.MigrationSession.prototype.setSessionUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string managed_by_node_uuid = 2;
 * @return {string}
 */
proto.galaxymigrate.MigrationSession.prototype.getManagedByNodeUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.MigrationSession} returns this
 */
proto.galaxymigrate.MigrationSession.prototype.setManagedByNodeUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 local_policy_id = 3;
 * @return {number}
 */
proto.galaxymigrate.MigrationSession.prototype.getLocalPolicyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxymigrate.MigrationSession} returns this
 */
proto.galaxymigrate.MigrationSession.prototype.setLocalPolicyId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional ConfigParams config_params = 4;
 * @return {?proto.galaxymigrate.MigrationSession.ConfigParams}
 */
proto.galaxymigrate.MigrationSession.prototype.getConfigParams = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.ConfigParams} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession.ConfigParams, 4));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.ConfigParams|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession} returns this
*/
proto.galaxymigrate.MigrationSession.prototype.setConfigParams = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession} returns this
 */
proto.galaxymigrate.MigrationSession.prototype.clearConfigParams = function() {
  return this.setConfigParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.prototype.hasConfigParams = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string remote_system_id = 5;
 * @return {string}
 */
proto.galaxymigrate.MigrationSession.prototype.getRemoteSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxymigrate.MigrationSession} returns this
 */
proto.galaxymigrate.MigrationSession.prototype.setRemoteSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Volume session_volumes = 10;
 * @return {!Array<!proto.galaxymigrate.MigrationSession.Volume>}
 */
proto.galaxymigrate.MigrationSession.prototype.getSessionVolumesList = function() {
  return /** @type{!Array<!proto.galaxymigrate.MigrationSession.Volume>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxymigrate.MigrationSession.Volume, 10));
};


/**
 * @param {!Array<!proto.galaxymigrate.MigrationSession.Volume>} value
 * @return {!proto.galaxymigrate.MigrationSession} returns this
*/
proto.galaxymigrate.MigrationSession.prototype.setSessionVolumesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.galaxymigrate.MigrationSession.Volume=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxymigrate.MigrationSession.Volume}
 */
proto.galaxymigrate.MigrationSession.prototype.addSessionVolumes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.galaxymigrate.MigrationSession.Volume, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxymigrate.MigrationSession} returns this
 */
proto.galaxymigrate.MigrationSession.prototype.clearSessionVolumesList = function() {
  return this.setSessionVolumesList([]);
};


/**
 * optional bool boot_volume_session = 11;
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.prototype.getBootVolumeSession = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxymigrate.MigrationSession} returns this
 */
proto.galaxymigrate.MigrationSession.prototype.setBootVolumeSession = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional Status status = 20;
 * @return {!proto.galaxymigrate.MigrationSession.Status}
 */
proto.galaxymigrate.MigrationSession.prototype.getStatus = function() {
  return /** @type {!proto.galaxymigrate.MigrationSession.Status} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.galaxymigrate.MigrationSession.Status} value
 * @return {!proto.galaxymigrate.MigrationSession} returns this
 */
proto.galaxymigrate.MigrationSession.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional Statistics statistics = 22;
 * @return {?proto.galaxymigrate.MigrationSession.Statistics}
 */
proto.galaxymigrate.MigrationSession.prototype.getStatistics = function() {
  return /** @type{?proto.galaxymigrate.MigrationSession.Statistics} */ (
    jspb.Message.getWrapperField(this, proto.galaxymigrate.MigrationSession.Statistics, 22));
};


/**
 * @param {?proto.galaxymigrate.MigrationSession.Statistics|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession} returns this
*/
proto.galaxymigrate.MigrationSession.prototype.setStatistics = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession} returns this
 */
proto.galaxymigrate.MigrationSession.prototype.clearStatistics = function() {
  return this.setStatistics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.prototype.hasStatistics = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.Timestamp start_time = 23;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.MigrationSession.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 23));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession} returns this
*/
proto.galaxymigrate.MigrationSession.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession} returns this
 */
proto.galaxymigrate.MigrationSession.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 24;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.MigrationSession.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 24));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession} returns this
*/
proto.galaxymigrate.MigrationSession.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession} returns this
 */
proto.galaxymigrate.MigrationSession.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional google.protobuf.Timestamp last_synchronized_time = 25;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxymigrate.MigrationSession.prototype.getLastSynchronizedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 25));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxymigrate.MigrationSession} returns this
*/
proto.galaxymigrate.MigrationSession.prototype.setLastSynchronizedTime = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxymigrate.MigrationSession} returns this
 */
proto.galaxymigrate.MigrationSession.prototype.clearLastSynchronizedTime = function() {
  return this.setLastSynchronizedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxymigrate.MigrationSession.prototype.hasLastSynchronizedTime = function() {
  return jspb.Message.getField(this, 25) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxymigrate.SessionHook.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxymigrate.SessionHook.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxymigrate.SessionHook} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SessionHook.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxymigrate.SessionHook}
 */
proto.galaxymigrate.SessionHook.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxymigrate.SessionHook;
  return proto.galaxymigrate.SessionHook.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxymigrate.SessionHook} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxymigrate.SessionHook}
 */
proto.galaxymigrate.SessionHook.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.galaxymigrate.SessionHook.Action} */ (reader.readEnum());
      msg.setAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxymigrate.SessionHook.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxymigrate.SessionHook.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxymigrate.SessionHook} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxymigrate.SessionHook.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxymigrate.SessionHook.Action = {
  NOOP: 0,
  CALL_GALAXY_COMPLETE: 1
};

/**
 * optional Action action = 1;
 * @return {!proto.galaxymigrate.SessionHook.Action}
 */
proto.galaxymigrate.SessionHook.prototype.getAction = function() {
  return /** @type {!proto.galaxymigrate.SessionHook.Action} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.galaxymigrate.SessionHook.Action} value
 * @return {!proto.galaxymigrate.SessionHook} returns this
 */
proto.galaxymigrate.SessionHook.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


goog.object.extend(exports, proto.galaxymigrate);
