/**
 * @fileoverview gRPC-Web generated client stub for galaxycomplete.api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: galaxycompletepb/apipb/report_api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as galaxycompletepb_apipb_report_api_pb from '../../galaxycompletepb/apipb/report_api_pb';


export class ReportServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListProjectReports = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ReportService/ListProjectReports',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_report_api_pb.ListProjectReports.Request,
    galaxycompletepb_apipb_report_api_pb.ListProjectReports.Response,
    (request: galaxycompletepb_apipb_report_api_pb.ListProjectReports.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_report_api_pb.ListProjectReports.Response.deserializeBinary
  );

  listProjectReports(
    request: galaxycompletepb_apipb_report_api_pb.ListProjectReports.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_report_api_pb.ListProjectReports.Response>;

  listProjectReports(
    request: galaxycompletepb_apipb_report_api_pb.ListProjectReports.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_report_api_pb.ListProjectReports.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_report_api_pb.ListProjectReports.Response>;

  listProjectReports(
    request: galaxycompletepb_apipb_report_api_pb.ListProjectReports.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_report_api_pb.ListProjectReports.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ReportService/ListProjectReports',
        request,
        metadata || {},
        this.methodDescriptorListProjectReports,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ReportService/ListProjectReports',
    request,
    metadata || {},
    this.methodDescriptorListProjectReports);
  }

  methodDescriptorGetReportInfo = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ReportService/GetReportInfo',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_report_api_pb.GetReportInfo.Request,
    galaxycompletepb_apipb_report_api_pb.GetReportInfo.Response,
    (request: galaxycompletepb_apipb_report_api_pb.GetReportInfo.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_report_api_pb.GetReportInfo.Response.deserializeBinary
  );

  getReportInfo(
    request: galaxycompletepb_apipb_report_api_pb.GetReportInfo.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_report_api_pb.GetReportInfo.Response>;

  getReportInfo(
    request: galaxycompletepb_apipb_report_api_pb.GetReportInfo.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_report_api_pb.GetReportInfo.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_report_api_pb.GetReportInfo.Response>;

  getReportInfo(
    request: galaxycompletepb_apipb_report_api_pb.GetReportInfo.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_report_api_pb.GetReportInfo.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ReportService/GetReportInfo',
        request,
        metadata || {},
        this.methodDescriptorGetReportInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ReportService/GetReportInfo',
    request,
    metadata || {},
    this.methodDescriptorGetReportInfo);
  }

  methodDescriptorGetReportData = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ReportService/GetReportData',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_report_api_pb.GetReportData.Request,
    galaxycompletepb_apipb_report_api_pb.GetReportData.Response,
    (request: galaxycompletepb_apipb_report_api_pb.GetReportData.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_report_api_pb.GetReportData.Response.deserializeBinary
  );

  getReportData(
    request: galaxycompletepb_apipb_report_api_pb.GetReportData.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_report_api_pb.GetReportData.Response>;

  getReportData(
    request: galaxycompletepb_apipb_report_api_pb.GetReportData.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_report_api_pb.GetReportData.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_report_api_pb.GetReportData.Response>;

  getReportData(
    request: galaxycompletepb_apipb_report_api_pb.GetReportData.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_report_api_pb.GetReportData.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ReportService/GetReportData',
        request,
        metadata || {},
        this.methodDescriptorGetReportData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ReportService/GetReportData',
    request,
    metadata || {},
    this.methodDescriptorGetReportData);
  }

  methodDescriptorCreateMigrationSessionReport = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.ReportService/CreateMigrationSessionReport',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_report_api_pb.CreateMigrationSessionReport.Request,
    galaxycompletepb_apipb_report_api_pb.CreateMigrationSessionReport.Response,
    (request: galaxycompletepb_apipb_report_api_pb.CreateMigrationSessionReport.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_report_api_pb.CreateMigrationSessionReport.Response.deserializeBinary
  );

  createMigrationSessionReport(
    request: galaxycompletepb_apipb_report_api_pb.CreateMigrationSessionReport.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_report_api_pb.CreateMigrationSessionReport.Response>;

  createMigrationSessionReport(
    request: galaxycompletepb_apipb_report_api_pb.CreateMigrationSessionReport.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_report_api_pb.CreateMigrationSessionReport.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_report_api_pb.CreateMigrationSessionReport.Response>;

  createMigrationSessionReport(
    request: galaxycompletepb_apipb_report_api_pb.CreateMigrationSessionReport.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_report_api_pb.CreateMigrationSessionReport.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.ReportService/CreateMigrationSessionReport',
        request,
        metadata || {},
        this.methodDescriptorCreateMigrationSessionReport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.ReportService/CreateMigrationSessionReport',
    request,
    metadata || {},
    this.methodDescriptorCreateMigrationSessionReport);
  }

}

