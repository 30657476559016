import * as React from "react";
import { Link, useMatch } from "react-router-dom";
import { APP_ROUTES } from "../app/AppRoutes";
import { observer } from "mobx-react-lite";
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SvgIcon,
    Theme,
    Toolbar,
    Typography,
    Link as MuiLink,
} from "@mui/material";
import { ProjectIcon, SupportIcon, useNavigateToProjectDetails, useNavigateToProjectListScreen } from "../project/ProjectCommon";
import { CurrentProjectSideMenu } from "./CurrentProjectSideMenu";
import { useAppServices } from "../app/services";
import { CDSLogo, CDSPrivateEditionLogo, getSideMenuListItemStyle } from "./LayoutCommon";
import { HelpIcon } from "../../common/CommonIcons";
import { ApiLink, EULALink, HELP_CENTER_URL, PrivacyPolicyLink, TermsOfServiceLink, useNavigateToExternalLink } from "../../modules/help/HelpCommon";
import { MdClose } from "react-icons/md";
import { getIsPrivateEdition, HideInPrivateEdition } from "../auth/PrivateEditionView";
import { useCurrentProjectID } from "../project/CurrentProject";
import { CdsSupportStaffView } from "../support/SupportCommon";

// ======================
// SideMenu
// ======================

export const getSideMenuWidth = (t: Theme) => t.spacing(32);

const SIDE_MENU_ROOT_STYLE = {
    width: getSideMenuWidth,
    flexShrink: 0,
};

interface SideMenuProps {
    isDesktop: boolean;
    isPrivateEdition: boolean;
}

export const SideMenu: React.FC<SideMenuProps> = observer(({ ...p }) => {
    const { isDesktop, isPrivateEdition } = p;

    const goBackToProjectPage = useNavigateToProjectListScreen();

    if (!isDesktop) {
        return <MobileSideMenu />;
    }

    return (
        <Drawer
            variant={"permanent"}
            sx={{
                ...SIDE_MENU_ROOT_STYLE,
                "& .MuiDrawer-paper": {
                    width: getSideMenuWidth,
                    zIndex: (t: Theme) => t.zIndex.appBar + 1,
                    background: (t: Theme) => t.palette.cirrus.medium,
                    borderRightWidth: 2,
                    borderRightColor: (t: Theme) => t.palette.cirrus.light,
                    borderRightStyle: "solid",
                    marginTop: 0,
                    //position: 'relative'
                },
            }}
            elevation={0}
            anchor="left"
        >
            <Toolbar>
                {isPrivateEdition ? (
                    <MuiLink onClick={goBackToProjectPage}>
                        <CDSPrivateEditionLogo imageAttributes={{ width: "100%" }} />
                    </MuiLink>
                ) : (
                    <CDSLogo imageAttributes={{ width: "100%" }} />
                )}
            </Toolbar>
            <SideMenuItems />
        </Drawer>
    );
});

// ======================
// MobileSideMenu
// ======================

interface MobileSideMenuProps {}

export const MobileSideMenu: React.FC<MobileSideMenuProps> = observer((props) => {
    const { appGlobalService } = useAppServices();
    return (
        <Drawer
            variant={"temporary"}
            open={appGlobalService.sideBarOpen}
            sx={{
                ...SIDE_MENU_ROOT_STYLE,
                "&. MuiDrawer-paper": {
                    width: (t: Theme) => t.spacing(32),
                    zIndex: (t: Theme) => t.zIndex.appBar + 1,
                    background: (t: Theme) => t.palette.cirrus.medium,

                    border: 0,
                },
            }}
            ModalProps={{ onBackdropClick: () => appGlobalService.closeSideBar() }}
            elevation={0}
            anchor="left"
        >
            <Box display={"flex"} justifyContent={"flex-end"} p={1}>
                <IconButton onClick={() => appGlobalService.closeSideBar()}>
                    <SvgIcon>
                        <MdClose />
                    </SvgIcon>
                </IconButton>
            </Box>
            <SideMenuItems />
        </Drawer>
    );
});

// ======================
// SideMenuItems
// ======================

interface SideMenuItemsProps {}

export const SideMenuItems: React.FC<SideMenuItemsProps> = observer((props) => {
    const isPrivateEdition = getIsPrivateEdition();

    const helpDividerStyle = {
        backgroundColor: (t: Theme) => t.palette.cirrus.main,
        borderTop: "2px solid",
        borderTopColor: (t: Theme) => t.palette.cirrus.main,
    };
    return (
        <>
            <List>
                {!isPrivateEdition && (
                    <>
                        <ListItem button component={Link} to={APP_ROUTES.PROJECTS} selected={!!useMatch(APP_ROUTES.PROJECTS)} sx={getSideMenuListItemStyle()}>
                            <ListItemIcon>
                                <ProjectIcon />
                            </ListItemIcon>
                            <ListItemText primary={"My Projects"} />
                        </ListItem>
                        <CdsSupportStaffView>
                            <ListItem
                                button
                                component={Link}
                                to={APP_ROUTES.SUPPORT_PROJECTS}
                                selected={!!useMatch(APP_ROUTES.SUPPORT_PROJECTS)}
                                sx={getSideMenuListItemStyle()}
                            >
                                <ListItemIcon>
                                    <SupportIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Support Projects"} />
                            </ListItem>
                        </CdsSupportStaffView>
                    </>
                )}

                <CurrentProjectSideMenu />
            </List>
            <Divider sx={helpDividerStyle} />
            <SupportLink />
            <Divider sx={helpDividerStyle} />
            <LegalLinks />
        </>
    );
});

// ======================
// LegalLinks
// ======================

interface LegalLinksProps {}

export const LegalLinks: React.FC<LegalLinksProps> = observer((props) => {
    const currentYear = new Date(Date.now()).getFullYear();
    const navigateToCdsWebsite = useNavigateToExternalLink("https://www.cirrusdata.com/");

    return (
        <Box pt={2} pl={2}>
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
                <Box>
                    <TermsOfServiceLink variant={"caption"} color={"textSecondary"} label={"ToS"} />
                </Box>
                &nbsp; | &nbsp;
                <Box>
                    <PrivacyPolicyLink variant={"caption"} color={"textSecondary"} />
                </Box>
                &nbsp; | &nbsp;
                <Box>
                    <EULALink variant={"caption"} color={"textSecondary"} />
                </Box>
                <HideInPrivateEdition>
                    <>
                        &nbsp; | &nbsp;
                        <Box>
                            <ApiLink variant={"caption"} color={"textSecondary"} />
                        </Box>
                    </>
                </HideInPrivateEdition>
            </Box>
            <Box display={"flex"} justifyContent={"flex-start"}>
                <MuiLink onClick={navigateToCdsWebsite} color={"textSecondary"}>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        © {currentYear} Cirrus Data Solutions Inc.
                    </Typography>
                </MuiLink>
            </Box>
        </Box>
    );
});

// ======================
// SupportLink
// ======================

interface SupportLinkProps {}

export const SupportLink: React.FC<SupportLinkProps> = observer((props) => {
    const nav = useNavigateToExternalLink(HELP_CENTER_URL);

    return (
        <List>
            <ListItem button onClick={nav} sx={getSideMenuListItemStyle()}>
                <ListItemIcon>
                    <HelpIcon />
                </ListItemIcon>
                <ListItemText primary={"Help Center"} />
            </ListItem>
        </List>
    );
});
