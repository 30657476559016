import {observer} from "mobx-react-lite";
import React, {ChangeEvent} from "react";
import {Box, Card, Grid, SvgIcon, Switch, Typography} from "@mui/material";
import {Theme} from "@mui/material/styles";

// ======================
// SwitchCard
// ======================

interface SwitchCardProps {
    selected: boolean,
    disabled?: boolean,
    onSelect: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void,
    cardContent: React.ReactNode
}

export const SwitchCard: React.FC<SwitchCardProps> = observer((p) => {
    const {selected, disabled, onSelect, cardContent} = p;
    return <Card variant={'outlined'} sx={{
        backgroundColor: 'rgba(0,0,0,0)',
        border: '2px solid',
        borderColor: (t: Theme) => selected ? t.palette.primary.main : t.palette.cirrus.light,
        marginBottom: 2,
    }
    }>
        <Box display={'flex'} alignItems={'center'} p={2}>
            <Grid container alignItems={'center'}>
                <Grid item xs={1}>
                    <Switch color={'secondary'} checked={selected} disabled={disabled} onChange={onSelect}/>
                </Grid>
                <Grid item xs={11}>
                    {cardContent}
                </Grid>
            </Grid>
        </Box>

    </Card>;
})