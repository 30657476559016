import xbytes from 'xbytes';
import {formatDistance, formatDistanceStrict, formatDuration, formatISO9075, formatRelative, isAfter} from 'date-fns';
import {Duration as ProtoDuration} from "google-protobuf/google/protobuf/duration_pb";
import { Duration } from 'date-fns';
import {Timestamp} from "google-protobuf/google/protobuf/timestamp_pb";

export const formatBool = (b: boolean) => !!b ? 'Yes' : 'No';


function numberWithDecimal(num = 0, decimalPlace = 0) {
    if ((typeof decimalPlace === 'undefined' || decimalPlace === null))
    {
        return num;
    }
    else
    {
        let _num = num.toFixed(decimalPlace);
        return parseFloat(_num);
    }
}

function numberWithDecimalS(num = 0, decimalPlace = 0) {
    const n = numberWithDecimal(num, decimalPlace);
    return n ? n.toString() : n;
}


export function formatMicroseconds(us: number) {
    let microSecond       = us;
    const microSecondUnit = 'μs';
    if (typeof (microSecond) === 'undefined')
    {
        return 0;
    }
    if (microSecond < 1000)
    {
        if (microSecond !== 0)
        {
            return (numberWithDecimalS(microSecond, null) + ' μs');
        }
        else
        {
            return ('0 μs');
        }
    }
    let millisecond = microSecond / 1000.0;
    if (millisecond < 1000)
    {
        return (numberWithDecimalS(millisecond, 3) + ' ms');
    }
    let second = millisecond / 1000.0;
    return (numberWithDecimalS(second, 3) + ' s');
}


export enum KnownDataType {
    GENERIC,
    NUMBER,
    BOOL,
    CAPACITY,
    THROUGHPUT,
    DATE,
    DATE_RELATIVE,
    PERCENT,
    DURATION,
    SIMPLE_STRING,
    ENUM,
    DURATION_MILLISECONDS,
    DURATION_MICROSECONDS
}

export const convertTimestampObjectToDate = (timestamp: Timestamp.AsObject) => {
    const t = new Timestamp()
        .setSeconds(timestamp.seconds)
        .setNanos(timestamp.nanos)
    return t.toDate()
}

export const convertDateObjectToTimestamp = (date: Date): Timestamp => {
    return new Timestamp()
        .setSeconds(Math.floor(date.getTime()/1000))
}

export const formatKnownDataType = (v: any, type: KnownDataType) => {
    if (v===undefined ){
        return 'N/A'
    }
    if (type === KnownDataType.BOOL)
    {
        return formatBool(v);
    }
    if (type === KnownDataType.CAPACITY)
    {
        return xbytes(v, {iec: true});
    }
    if (type === KnownDataType.THROUGHPUT)
    {
        return `${xbytes(v, {iec: true})}/s`;
    }
    if (type === KnownDataType.DATE)
    {
        return formatISO9075(v);
    }
    if (type === KnownDataType.DATE_RELATIVE)
    {
        return formatDistanceStrict(v, new Date(), {
            addSuffix: true
        });
    }
    if (type === KnownDataType.PERCENT)
    {
        return `${parseFloat(`${v || 0}`).toFixed(2)}%`;
    }
    if (type === KnownDataType.DURATION_MILLISECONDS){
        const seconds = v.getSeconds()
        const nanos = v.getNanos()
        return `${((seconds*1000) + (nanos/1000/1000)).toFixed(2)}ms`
    }
    return v;
};

export const formatProtoDuration = (v: ProtoDuration): string => {
    return formatDuration(convertProtoDurationToDuration(v))
}

export const formatDurationFromSeconds = (v: number): string => {
    const min = 60;
    const hr = min*60
    const day = hr*24;
    const days = Math.floor(v / day)
    const hours = Math.floor((v % day) / hr)
    const minutes = Math.floor(((v % day) % hr) / min)
    const seconds = ((v % day) % hr) % min
    const duration = {
        days,
        hours,
        minutes,
        seconds
    }

    return formatDuration(duration);
}

const convertProtoDurationToDuration = (v: ProtoDuration): Duration =>{
    const duration = {
        seconds: v.getSeconds()
    }

    return duration;
}

export const formatTitleCase = (string: string): string => {
    return `${string[0].toUpperCase()}${string.slice(1).toLowerCase()}`
}