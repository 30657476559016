import {observer} from "mobx-react-lite";
import {useAppServices} from "../app/services";
import {renderServerDataWithLoadingList, useInitData} from "../core/data/DataLoaderHooks";
import * as React from "react";
import {ColumnDef, DataTable} from "../../common/table/DataTable";
import {
    Box,
    Button,
    Card,

    Link,
    Theme,
    Typography
} from "@mui/material";
import {generatePath, useNavigate, useParams, Link as RouterLink} from "react-router-dom";
import {formatKnownDataType, KnownDataType} from "../../common/utils/formatter";
import {DeleteIcon, EditIcon, RefreshIcon} from "../../common/CommonIcons";
import {GMLinkInfo} from "../../_proto/galaxycompletepb/apipb/domainpb/galaxymigratelink_pb";
import {
    formatServerAddressWithoutDefaultPort, formatServerAddressWithoutPort,
    generateDeploymentDetailsPath,
} from "./GalaxyMigrateCommon";
import {DialogState, useDialogState, useShouldDialogFullScreen} from "../core/dialog/DialogService";
import {useIsOperatorView} from "../auth/AuthenticatedViews";
import {EditConnectionDialog} from "./links/EditGalaxyMigrateLink";
import {
    GalaxyMigrateLinkIcon,
    getDeploymentConnectionStyle,
} from "../deployment/DeploymentCommon";
import {CreateGalaxyMigrateLinkButton} from "./links/CreateGalaxyMigrateLinkForm";



// ======================
// GalaxyMigrateConnectionsList
// ======================


interface GalaxyMigrateConnectionsListProps {
}

export const GalaxyMigrateConnectionsList: React.FC<GalaxyMigrateConnectionsListProps> = observer((props) => {
    const {gmDeploymentService} = useAppServices();
    useInitData({
        poll: () => gmDeploymentService.galaxyMigrateLinks.fetchData(),
        pollInterval: 10,
    });


    return renderServerDataWithLoadingList(gmDeploymentService.galaxyMigrateLinks, (data) => {
        return <Card elevation={2}>
            <GalaxyMigrateConnectionsTable/>
        </Card>
            ;
    });
})

// ======================
// GalaxyMigrateConnectionsTable
// ======================

interface GalaxyMigrateConnectionsTableProps {
}

export const GalaxyMigrateConnectionsTable: React.FC<GalaxyMigrateConnectionsTableProps> = observer((props) => {
    const {gmDeploymentService} = useAppServices();
    const serverData = gmDeploymentService.galaxyMigrateLinks;
    const {projectId, deploymentID} = useParams();
    const editDialogState = useDialogState();
    const isOperator = useIsOperatorView();
    const [currentEditRow, setCurrentEditRow] = React.useState(null);
    const cols: ColumnDef<GMLinkInfo>[] = [
        {
            id: 'client',
            label: 'From Host',
            getter: (r) => r.getClient().getSystemName(),
            renderer: (systemName, r) => {
                const p = generateDeploymentDetailsPath(r.getClient().getSystemId(), projectId);
                const hostLinkDisabled = deploymentID ? deploymentID === r.getClient().getSystemId() : false;
                if (hostLinkDisabled){
                    return <Typography variant={'body2'} sx={getDeploymentConnectionStyle(r.getConnected())}>{systemName}</Typography>
                }
                return <Link component={RouterLink}
                             to={p}
                >
                    {systemName}
                </Link>;
            },
        },
        {
            id: 'server',
            label: 'To Host',
            getter: (r) => r.getServer().getSystemName(),
            renderer: (systemName, r) => {
                const p = generateDeploymentDetailsPath(r.getServer().getSystemId(), projectId)
                const hostLinkDisabled = deploymentID ? deploymentID === r.getServer().getSystemId() : false;
                if (hostLinkDisabled){
                    return <Typography variant={'body2'} sx={getDeploymentConnectionStyle(r.getConnected())}>{systemName}</Typography>
                }
                return <Link component={RouterLink}
                             to={p}>
                    {systemName}
                </Link>;
            }
        },
        {
            id: 'connectionAddress',
            label: 'Connection Address',
            getter: r => r.getLastserveraddress(),
            renderer: (address, r) => {
                return <Typography sx={getDeploymentConnectionStyle(r.getConnected())}>
                    {formatServerAddressWithoutDefaultPort(address)}
                </Typography>
            }
        },
        {
            id: 'connectionStatus',
            label: 'Connected',
            getter: r => r.getConnected(),
            renderer: (connection, r) => {
                return <Typography sx={getDeploymentConnectionStyle(r.getConnected())}>
                    {formatKnownDataType(connection, KnownDataType.BOOL)}
                </Typography>
            },
            //dataType: KnownDataType.BOOL,
        },
        {
            id: 'latency',
            label: 'Latency',
            getter: r => r.getLatency(),
            renderer: (latency: Duration, r) => {
                return <Typography sx={getDeploymentConnectionStyle(r.getConnected())}>
                    {formatKnownDataType(latency, KnownDataType.DURATION_MILLISECONDS)}
                </Typography>
            },
        },
    ];

    const getRowActions = (r: GMLinkInfo) => ([
        {
            id: 'edit',
            name: 'Edit Connection Information',
            action: async () => {
                setCurrentEditRow(r)
                editDialogState.open()
            },
            icon: <EditIcon/>,
            hidden: !isOperator
        },
        {
            id: 'retry',
            name: 'Retry Connection',
            action: async()=> {
                const address = formatServerAddressWithoutPort(r.getLastserveraddress())
                const serverPort = !!r.getLastserveraddress().split(':')[1] ? parseInt(r.getLastserveraddress().split(':')[1]) : 0;
                await gmDeploymentService.reconnectGalaxyMigrateLink(r.getLinkid(), address, serverPort)
            },
            icon: <GalaxyMigrateLinkIcon/>,
            hidden: r.getConnected()
        },
        {
            id: 'refresh',
            name: 'Refresh Connection',
            action: async()=> {
                await gmDeploymentService.refreshGalaxyMigrateLink(r.getLinkid());
            },
            icon: <RefreshIcon/>,
            hidden: !r.getConnected()
        },
        {
            id: 'remove',
            name: `Remove Connection`,
            action: async () => {
                await gmDeploymentService.removeGalaxyMigrateLink(r.getLinkid());
                await gmDeploymentService.galaxyMigrateLinks.fetchData();
            },
            icon: <DeleteIcon/>,
            hidden: !isOperator
        },

    ])
    return (<>
            <DataTable rows={serverData?.data?.getItemsList()}
                       cols={cols}
                       emptyTableTitle={'No Connections Found'}
                       emptyTableActionButton={<CreateGalaxyMigrateLinkButton variant={'contained'} color={'secondary'}/>}
                       state={serverData.tableState}
                       pagerMeta={serverData?.data?.getPagerMeta().toObject()}
                       loading={serverData.loading}
                       rowActions={(r) => getRowActions(r)}
                       onTableStateChange={() => serverData.fetchData()}/>
        <EditConnectionDialog dialogState={editDialogState} linkInfo={currentEditRow}/>

    </>);
});

