import { create } from "zustand";
import { AuthenticatedUserInfo, RefreshToken } from "../../_proto/galaxycompletepb/apipb/auth_api_pb";
import SuperTokensLock from "browser-tabs-lock";
import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { add as addDate, formatDistanceToNow, isAfter, sub as subtractDate } from "date-fns";
import { randomInteger, sleepMS } from "../../common/utils/util";
import { RpcError, StatusCode } from "grpc-web";
import { useAppServices } from "../app/services";

const _STORAGE_KEYS = {
    ACCESS_TOKEN: "Bn3cp*n6NaiqKz9q8X",
    REFRESH_TOKEN: "ePA3MT!g#H7re$eAmh9M",
    NEXT_REFRESH_TIME: "scUktc8AUhyy3Ys7&QYroj",
    CURRENT_USER: "94N$@y5*CjLfp%!U$!bTn",
};
const _refresh_token_lock_key = "refresh-token-lock";

export interface AuthState {
    currentUser?: AuthenticatedUserInfo;
    locks?: SuperTokensLock;
    nextRefreshTime?: Date;
    isAwsAuthenticated?: boolean;
    setAwsAuthenticated?: (authenticated: boolean) => void;
    isAzureAuthenticated?: boolean;
    setAzureAuthenticated?: (authenticated: boolean) => void;
    logOut?: () => void;
    storage?: Storage;
    loadPersistentEntitiesFromStorage?: () => { accessToken: string; currentUser: AuthenticatedUserInfo };
    updateNextTokenRefreshTime?: (t: Date) => void;
    storeRefreshToken?: (token: string) => void;
    updateAccessToken?: (token: string) => void;
    updateCurrentUser?: (user: AuthenticatedUserInfo) => void;
    markNextRefreshTime?: (requestTime: Date, validDuration: Duration) => void;
    getLock?: () => Promise<boolean>;
    releaseLock?: () => Promise<void>;
    getRefreshToken?: () => string;
}

export const useAuthState = create<AuthState>((set, get) => ({
    currentUser: null,
    locks: new SuperTokensLock(),
    nextRefreshTime: null,
    isAwsAuthenticated: false,
    isAzureAuthenticated: false,
    storage: window.localStorage,
    setAwsAuthenticated: (authenticated: boolean) => {
        set({
            isAwsAuthenticated: authenticated,
        });
    },
    setAzureAuthenticated: (authenticated: boolean) => {
        set({
            isAzureAuthenticated: authenticated,
        });
    },
    logOut: () => {
        set({
            currentUser: null,
            isAwsAuthenticated: false,
        });
        get().storage.removeItem(_STORAGE_KEYS.ACCESS_TOKEN);
        get().storage.removeItem(_STORAGE_KEYS.CURRENT_USER);
        get().storage.removeItem(_STORAGE_KEYS.NEXT_REFRESH_TIME);
        get().storage.removeItem(_STORAGE_KEYS.REFRESH_TOKEN);
    },
    loadPersistentEntitiesFromStorage: () => {
        const accessToken = get().storage.getItem(_STORAGE_KEYS.ACCESS_TOKEN);
        const nextRefreshTimeVal = get().storage.getItem(_STORAGE_KEYS.NEXT_REFRESH_TIME);
        const currentUserVal = get().storage.getItem(_STORAGE_KEYS.CURRENT_USER);
        const currentUser = !!currentUserVal ? AuthenticatedUserInfo.deserializeBinary(new Uint8Array(JSON.parse(currentUserVal))) : null;
        set({
            nextRefreshTime: nextRefreshTimeVal ? new Date(nextRefreshTimeVal) : null,
            currentUser: currentUser,
        });
        return { accessToken, currentUser };
    },

    updateNextTokenRefreshTime: (t: Date) => {
        set({
            nextRefreshTime: t,
        });
        if (!!t) {
            get().storage.setItem(_STORAGE_KEYS.NEXT_REFRESH_TIME, `${t}`);
        } else {
            get().storage.removeItem(_STORAGE_KEYS.NEXT_REFRESH_TIME);
        }
    },
    storeRefreshToken: (token: string) => {
        if (token) {
            console.debug(`storing refresh token to active storage`, token);
            get().storage.setItem(_STORAGE_KEYS.REFRESH_TOKEN, token);
        } else {
            console.debug(`clearing refresh token`);
            get().storage.removeItem(_STORAGE_KEYS.REFRESH_TOKEN);
        }
    },
    updateAccessToken: (token: string) => {
        if (token) {
            get().storage.setItem(_STORAGE_KEYS.ACCESS_TOKEN, token);
        } else {
            get().storage.removeItem(_STORAGE_KEYS.ACCESS_TOKEN);
        }
    },
    updateCurrentUser: (user: AuthenticatedUserInfo) => {
        set({
            currentUser: user,
        });
        if (user) {
            get().storage.setItem(_STORAGE_KEYS.CURRENT_USER, JSON.stringify(Array.from(user.serializeBinary())));
        } else {
            get().storage.removeItem(_STORAGE_KEYS.CURRENT_USER);
        }
    },
    markNextRefreshTime: (requestTime: Date, validDuration: Duration) => {
        const nextRefreshTime = subtractDate(addDate(requestTime, validDuration), { minutes: 1, seconds: randomInteger(0, 5) });
        set({
            nextRefreshTime: nextRefreshTime,
        });
        if (!!nextRefreshTime) {
            get().storage.setItem(_STORAGE_KEYS.NEXT_REFRESH_TIME, `${nextRefreshTime}`);
        } else {
            get().storage.removeItem(_STORAGE_KEYS.NEXT_REFRESH_TIME);
        }
        console.debug(`next token refresh time set to ${nextRefreshTime} (${formatDistanceToNow(nextRefreshTime, { addSuffix: true })})`);
    },

    getLock: async () => await get().locks.acquireLock(_refresh_token_lock_key, 15000),

    releaseLock: async () => await get().locks.releaseLock(_refresh_token_lock_key),

    getRefreshToken: () => get().storage.getItem(_STORAGE_KEYS.REFRESH_TOKEN) || null,
}));
