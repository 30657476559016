import {
    AuthenticatedUserInfo,
    ChangeMyPassword,
    CompleteOnboard,
    DisableMfaTotp,
    ForgotPassword,
    Login,
    RefreshToken,
    RequestMfaToken,
    ResetPassword,
    Signup,
} from "../../_proto/galaxycompletepb/apipb/auth_api_pb";
import { useMutationTrackAndAlertError, useQueryAlertError } from "../core/data/useHooksWithErrorHandling";
import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { useAuthState } from "./AuthState";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import { APP_ROUTES } from "../app/AppRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { FcCheckmark } from "react-icons/fc";
import { Typography } from "@mui/material";
import React, { FormEvent, useCallback } from "react";
import { useAppServices } from "../app/services";
import { RpcError, StatusCode } from "grpc-web";
import { randomInteger, sleepMS } from "../../common/utils/util";
import { isAfter } from "date-fns";

export const useSignUp = () => {
    const apis = useGrpcApiStore();

    return useMutationTrackAndAlertError({
        mutationKey: ["SignUp"],
        mutationFn: async (req: Signup.Request) => await apis.loginService.signup(req, null),
    });
};

export const useRequestMfaToken = (user?: string, password?: string) => {
    const apis = useGrpcApiStore();
    const req = new RequestMfaToken.Request().setUser(user).setPassword(password);

    const queryFn = async (): Promise<RequestMfaToken.Response> => {
        return await apis.loginService.requestMfaToken(req, null);
    };
    return useQueryAlertError<RequestMfaToken.Response>({
        queryKey: ["RequestMfaToken"],
        queryFn: queryFn,
        enabled: !!user && !!password,
        initialData: new RequestMfaToken.Response(),
    });
};

export const useNewLogIn = () => {
    const { loginService, setActiveToken } = useGrpcApiStore();
    const globalDialogState = useGlobalDialogState();
    const { setAwsAuthenticated, storeRefreshToken, markNextRefreshTime } = useAuthState();

    const updateCurrentUser = useUpdateCurrentAuthUser();
    const updateAccessToken = useUpdateAccessToken();

    const navigate = useNavigate();
    const location = useLocation();

    return useMutationTrackAndAlertError({
        mutationKey: ["LogIn"],
        mutationFn: async (req: Login.Request) => {
            const getPostLoginAwsSuccessMessage = async () => {
                const getMessageBody = () => (
                    <Box display={"flex"} alignItems={"center"}>
                        <Box pr={2}>
                            <FcCheckmark size={"1.5em"} />
                        </Box>
                        <Typography>Congratulations! {req.getUser()} is successfully connected with your AWS Marketplace Subscription.</Typography>
                    </Box>
                );
                await globalDialogState.addAlertDialog({
                    title: "Account is connected to AWS Marketplace Subscription",
                    renderAdditionalContent: getMessageBody,
                    okButtonLabel: "Done",
                });
            };

            let confirmed;
            if (!!req.getAwsMarketplacePairToken()) {
                confirmed = await globalDialogState.addConfirmDialog({
                    title: "Connect to AWS Marketplace Subscription Confirmation",
                    message: `THIS ACTION IS IRREVERSIBLE. By continuing, your existing account ${req.getUser()} will be connected to your AWS Marketplace Subscription.`,
                });
            } else {
                confirmed = true;
            }

            if (confirmed) {
                const result = await loginService.login(req, null);
                if (!!result) {
                    if (!!req.getAwsMarketplacePairToken()) {
                        setAwsAuthenticated(true);
                    }
                    if (location.pathname === "/") {
                        navigate(APP_ROUTES.PROJECTS);
                    }
                    if (!!req.getAwsMarketplacePairToken()) {
                        await getPostLoginAwsSuccessMessage();
                    }

                    return result;
                }
            }
        },
        onSuccess: (res: Login.Response) => {
            updateCurrentUser(res.getUserInfo());
            updateAccessToken(res.getJwtToken());
            storeRefreshToken(res.getRefreshToken());
            markNextRefreshTime(new Date(), res.getJwtValidDuration().toObject());
        },
    });
};

export const useRequestResetPassword = () => {
    const loginService = useGrpcApiStore((s) => s.loginService);
    const addAlertDialog = useGlobalDialogState((s) => s.addAlertDialog);

    return useMutationTrackAndAlertError({
        mutationKey: ["RequestResetPassword"],
        mutationFn: async (req: ForgotPassword.Request) => await loginService.forgotPassword(req, null),
        onSuccess: async (data, variables, context) => {
            return await addAlertDialog({
                title: "New Password Instructions Sent",
                message: `We've sent a link to set your new password to ${variables.getUser()}. Please check your email inbox.`,
            });
        },
    });
};

export const useSetNewPassword = () => {
    const loginService = useGrpcApiStore((s) => s.loginService);
    const addAlertDialog = useGlobalDialogState((s) => s.addAlertDialog);

    return useMutationTrackAndAlertError({
        mutationKey: ["SetNewPassword"],
        mutationFn: async (req: ResetPassword.Request) => await loginService.resetPassword(req, null),
        onSuccess: async (data, variables, context) => {
            return await addAlertDialog({
                title: "Password Set Successfully",
                message: "You can now use your new password to log in.",
            });
        },
    });
};

export const useChangeMyPassword = () => {
    const authService = useGrpcApiStore((s) => s.authService);
    const addAlertDialog = useGlobalDialogState((s) => s.addAlertDialog);

    return useMutationTrackAndAlertError({
        mutationKey: ["ChangeMyPassword"],
        mutationFn: async (req: ChangeMyPassword.Request) => await authService.changeMyPassword(req, null),
        onSuccess: async (data, variables, context) => {
            return await addAlertDialog({
                title: "Password Set Successfully",
                message: "You can now use your new password to log in.",
            });
        },
    });
};

export const useActivateUser = () => {
    const apiAuthService = useGrpcApiStore((s) => s.authService);
    const setActiveToken = useGrpcApiStore((s) => s.setActiveToken);
    const refreshToken = useRefreshToken();
    const { authService } = useAppServices();
    return useMutationTrackAndAlertError({
        mutationKey: ["ActivateUser"],
        mutationFn: async (req: CompleteOnboard.Request) => await apiAuthService.completeOnboard(req, null),
        onSuccess: async (res) => {
            setActiveToken(res.getJwtToken());
            authService.updateAccessTokenFromAuthState(res.getJwtToken());
            authService.updateCurrentUserFromAuthState(res.getUserInfo());
            await refreshToken(false);
        },
    });
};

export const useDisableMfaTotp = () => {
    const authService = useGrpcApiStore((s) => s.authService);

    return useMutationTrackAndAlertError({
        mutationKey: ["DisableMfaTotp"],
        mutationFn: async (req: DisableMfaTotp.Request) => await authService.disableMfaTotp(req, null),
    });
};

export const useUpdateAccessToken = () => {
    const setActiveToken = useGrpcApiStore((s) => s.setActiveToken);
    const updateAccessToken = useAuthState((s) => s.updateAccessToken);
    const { authService } = useAppServices();

    return (token: string) => {
        setActiveToken(token);
        updateAccessToken(token);
        authService.updateAccessTokenFromAuthState(token);
    };
};

export const useUpdateCurrentAuthUser = () => {
    const updateCurrentUser = useAuthState((s) => s.updateCurrentUser);
    const { authService } = useAppServices();

    return (user: AuthenticatedUserInfo) => {
        updateCurrentUser(user);
        authService.updateCurrentUserFromAuthState(user);
    };
};

export const useIsAuthenticated = () => {
    const { authService } = useAppServices();
    const getHasActiveToken = useGrpcApiStore((s) => s.getHasActiveToken);
    const currentUser = useAuthState((s) => s.currentUser);
    return authService.authenticated && getHasActiveToken() && !!currentUser;
};

export const useIsOnboardCompleted = () => {
    const authenticated = useIsAuthenticated();
    const currentUser = useAuthState((s) => s.currentUser);

    return authenticated && currentUser.getActivated() && !!currentUser.getLastName() && !!currentUser.getFirstName();
};

export const useLogOut = () => {
    const setActiveToken = useGrpcApiStore((s) => s.setActiveToken);
    const { authService } = useAppServices();
    const logOut = useAuthState((s) => s.logOut);

    return () => {
        setActiveToken(null);
        logOut();
        authService.logout(true);
    };
};

export const useLoadPersistentEntitiesFromStorage = () => {
    const updateCurrentUser = useUpdateCurrentAuthUser();
    const updateCurrentAccessToken = useUpdateAccessToken();

    const loadPersistentEntitiesFromStorage = useAuthState((s) => s.loadPersistentEntitiesFromStorage);
    return () => {
        const { accessToken, currentUser } = loadPersistentEntitiesFromStorage();
        updateCurrentAccessToken(accessToken);
        updateCurrentUser(currentUser);
    };
};

export const useRefreshToken = () => {
    const updateAccessToken = useUpdateAccessToken();
    const updateCurrentUser = useUpdateCurrentAuthUser();
    const { getLock, releaseLock, markNextRefreshTime, storeRefreshToken, getRefreshToken } = useAuthState();
    const loginService = useGrpcApiStore((s) => s.loginService);
    const { authService } = useAppServices();

    return async (bestEffort = false) => {
        const lock = getLock();
        if (!!lock) {
            //if no refresh token, clear credentials
            const currentRefreshToken = getRefreshToken();
            if (!currentRefreshToken) {
                updateCurrentUser(null);
                return;
            }
            try {
                console.debug(`refreshing token`);
                const now = new Date();
                const res = await loginService.refreshToken(new RefreshToken.Request().setRefreshToken(currentRefreshToken), null);

                authService.updateAccessTokenFromAuthState(res.getJwtToken());
                updateAccessToken(res.getJwtToken());
                updateCurrentUser(res.getUserInfo());
                storeRefreshToken(res.getRefreshToken());
                console.debug(`completed refreshing token`);
                markNextRefreshTime(now, res.getJwtValidDuration().toObject());
            } catch (e) {
                console.debug(e);
                if (!!bestEffort) {
                    updateCurrentUser(null);
                    console.debug(e);
                    if ((e as RpcError).code === StatusCode.UNAUTHENTICATED) {
                        console.debug("refresh failed, clearing refresh token", e);
                        storeRefreshToken(null);
                    }
                } else {
                    throw e;
                }
            } finally {
                await releaseLock();
            }
        } else {
            throw new Error("failed to refresh token. cannot obtain lock after 15 seconds");
        }
    };
};

export const useRefreshTokenIfExpireSoon = () => {
    const { nextRefreshTime, currentUser } = useAuthState();
    const refreshToken = useRefreshToken();
    return useCallback(async () => {
        const now = new Date();
        if (!!currentUser && !!nextRefreshTime) {
            if (isAfter(now, nextRefreshTime)) {
                console.debug("token expires soon, refreshing");
                await refreshToken(true);
            } else {
                console.debug(now, "token not expiring soon no need to refresh");
            }
        }
    }, [currentUser, nextRefreshTime, refreshToken]);
};

export const useInitAuthentication = () => {
    const loadPersistentEntitiesFromStorage = useLoadPersistentEntitiesFromStorage();
    const { getRefreshToken, nextRefreshTime, currentUser } = useAuthState();
    const refreshToken = useRefreshToken();

    const refreshTokenIfExpireSoon = useRefreshTokenIfExpireSoon();

    return useCallback(async () => {
        // first load up data from storage
        loadPersistentEntitiesFromStorage();

        // then check  if we should refresh or just wait for next refresh
        const currentRefreshToken = getRefreshToken();
        if (!!currentRefreshToken) {
            console.debug(`existing refresh token loaded`, currentRefreshToken);
            if (!!nextRefreshTime) {
                await refreshTokenIfExpireSoon();
            } else {
                await refreshToken(true);
            }
        }
    }, [refreshTokenIfExpireSoon, loadPersistentEntitiesFromStorage, getRefreshToken, nextRefreshTime, refreshToken]);
};
