import {observer} from "mobx-react-lite";
import React from "react";
import {DynamicHorizontalStepper, StepConfig, StepperState} from "../../../../common/stepper/StepperComponents";
import {useAppServices} from "../../../app/services";
import {ScreenContainer, ScreenTitleBar} from "../../../layout/ScreenCommon";
import {Box, Button, Card, Checkbox, Chip, Grid, Link, Switch, TextField, Typography} from "@mui/material";
import {CdmPolicyWizardState} from "../CdmPolicyService";
import {
    renderServerDataWithLoadingBox,
    renderServerDataWithLoadingList,
    useInitData
} from "../../../core/data/DataLoaderHooks";
import {ColumnDef, DataTable} from "../../../../common/table/DataTable";
import {
    CirrusProtectDeploymentInfo,
    CirrusProtectDeviceInfo
} from "../../../../_proto/galaxycompletepb/apipb/domainpb/cirrusprotect_pb";
import {Link as RouterLink, useParams} from "react-router-dom";
import {
    generateCirrusProtectDeploymentDetailsPath,
    generatePhoenixDeploymentDetailsPath, renderChipInfo, useNavigateToCdmPolicyList, useNavigateToCpDeploymentDetails
} from "../../ProjectProtectionCommon";
import {HostOSIcon} from "../../../deployment/DeploymentCommon";
import {
    DeploymentHostEnvironment
} from "../../../../_proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import {getHostEnvDisplayName} from "../../../galaxymigrate/GalaxyMigrateCommon";
import {formatKnownDataType, KnownDataType} from "../../../../common/utils/formatter";
import {Theme} from "@mui/material/styles";
import {SelectableCard} from "../../../../common/card/SelectableCard";
import {getInsertionCandidateDeviceType, RollbackIcon} from "../CdmPolicyCommon";
import {CreatePolicy} from "../../../../_proto/galaxycompletepb/apipb/cpapipb/cirrusprotect_api_pb";
import {PROTECTION_HOST_DETAILS_SUBROUTE} from "../../../app/AppRoutes";


export const getCdmPolicyWizardStepConfigs = (wizardState: CdmPolicyWizardState): StepConfig[] => ([
    {
        id: 'host',
        label: `Host Selection ${wizardState?.selectedHost ? `: ${wizardState?.selectedHost?.getDeployment().getSystemName()}` : ''}`,
        renderer: (configs, state) => <HostSelectionStep stepperState={state}/>
    },
    {
        id: 'volume',
        label: 'Volumes Selection',
        renderer: (configs, state) => <VolumesSelectionStep stepperState={state} wizardState={wizardState}/>
    },
    {
        id: 'finalize',
        label: 'Finalize Protection Setup',
        renderer: (configs, state) => <FinalizePolicyStep stepperState={state} wizardState={wizardState}/>
    }

])

// ======================
// CdmPolicyWizard
// ======================

interface CdmPolicyWizardProps {
}

export const CdmPolicyWizard: React.FC<CdmPolicyWizardProps> = observer((p) => {

    const {cdmPolicyService, cpDeploymentService} = useAppServices();
    const {deploymentId} = useParams();

    const init = async () => {
            await cpDeploymentService.cpDeploymentDetails.fetchData(deploymentId);
            cdmPolicyService.policyWizardState.setSelectedHost(cpDeploymentService.cpDeploymentDetails.data)
    }

    useInitData({
        init: init
    })

    return <ScreenContainer>
        <ScreenTitleBar title={'New CDM Policy'}/>
        {renderServerDataWithLoadingBox(cpDeploymentService.cpDeploymentDetails, data => {
            return <DynamicHorizontalStepper
                stepConfigs={getCdmPolicyWizardStepConfigs(cdmPolicyService.policyWizardState)}
                stepperState={cdmPolicyService.policyWizardState.stepperState}/>
        })
        }

    </ScreenContainer>
        ;

});

// ======================
// HostSelectionStep
// ======================

interface HostSelectionStepProps {
    stepperState: StepperState,
    wizardState?: CdmPolicyWizardState
}

const HostSelectionStep: React.FC<HostSelectionStepProps> = observer((p) => {

    return <>
        <Typography variant={'h5'}>Select a Host</Typography>
        <Typography variant={'body2'}>Note: Only hosts with complete installation will be listed below</Typography>
        <br/>
        <SelectDeploymentHostTable/>
        <br/>
        <Box display={'flex'} justifyContent={'center'}>
            <Button variant={'contained'} onClick={() => p.stepperState.goToNextStep()}>Continue</Button>
        </Box>
    </>
});

// ======================
// SelectDeploymentHostTable
// ======================

interface SelectDeploymentHostTableProps {
}

const SelectDeploymentHostTable: React.FC<SelectDeploymentHostTableProps> = observer((p) => {
    const {cpDeploymentService, cdmPolicyService} = useAppServices();
    const {projectId} = useParams();
    const wizardState = cdmPolicyService.policyWizardState;

    useInitData({
        poll: () => cpDeploymentService.cpDeployments.fetchData(true, true, true),
        pollInterval: 30
    });

    return renderServerDataWithLoadingList(cpDeploymentService.cpDeployments, data => {
        const cols: ColumnDef<CirrusProtectDeploymentInfo>[] = [
            {
                id: 'Select',
                label: 'Select',
                getter: (r) => r,
                renderer: (v, r) => {
                    return <Checkbox color={'secondary'}/>
                }
            },
            {
                id: 'hostName',
                label: 'Host Name',
                getter: (r) => r.getDeployment().getSystemName(),
                renderer: (v, r) => {
                    return <Link component={RouterLink}
                                 to={generateCirrusProtectDeploymentDetailsPath(r.getDeployment().getSystemId(), projectId)}>{v}</Link>
                }

            },
            {
                id: 'version',
                label: 'Version',
                getter: (r) => r.getMtdiVersion(),
                renderer: (v, r) => <Typography
                    sx={{
                        color: r.getDeployment().getConnected() ? 'white' : '#757575'
                    }}>{v}</Typography>
            },
            {
                id: 'os',
                label: 'OS',
                getter: r => `${r.getOsClass()} - ${r.getKernel()}`,
                renderer: (os: string, r) => {
                    return <>
                        <Box display={'flex'}
                             sx={{
                                 color: r.getDeployment().getConnected() ? 'white' : '#757575'
                             }}>
                            <HostOSIcon os={os}/> &nbsp;&nbsp;&nbsp;&nbsp;
                            <div>{os}</div>
                        </Box>
                    </>
                }
            },
            {
                id: 'environment',
                label: 'Host Environment',
                getter: r => r.getDeployment().getHostEnvironment().getValue(),
                renderer: (env: DeploymentHostEnvironment.DeploymentHostEnvironment, r) => {
                    return getHostEnvDisplayName(env);
                }
            },
            {
                id: 'connected',
                label: 'Connected',
                getter: r => r.getDeployment().getConnected(),
                renderer: (connected, r) => {
                    return <Typography sx={{
                        color: r.getDeployment().getConnected() ? 'white' : '#757575'
                    }}>
                        {formatKnownDataType(connected, KnownDataType.BOOL)}
                    </Typography>
                },
            },
            {
                id: 'latency',
                label: 'Latency',
                getter: r => r.getDeployment().getConnectionLatency(),
                renderer: (latency: number, r) => {
                    return <Typography sx={{
                        color: r.getDeployment().getConnected() ? 'white' : '#757575'
                    }}>
                        {(latency / 1000 / 1000).toFixed(2)}ms
                    </Typography>
                },
            },
            {
                id: 'target',
                label: 'Protection Target',
                getter: r => r,
                renderer: (v, r) => {
                    if (!!r.getRegisteredPhoenixDeployment()) {
                        return <Link component={RouterLink}
                                     to={generatePhoenixDeploymentDetailsPath(r.getRegisteredPhoenixDeployment().getDeployment().getSystemId(), projectId)}>{r.getRegisteredPhoenixDeployment().getDeployment().getSystemName()}</Link>
                    }
                    return ''

                }
            }

        ];
        return <DataTable state={cpDeploymentService.cpDeployments.tableState} rows={data.getItemsList()} cols={cols}/>
    })
})

// ======================
// VolumesSelectionStep
// ======================

interface VolumesSelectionStepProps {
    stepperState: StepperState,
    wizardState?: CdmPolicyWizardState

}

const VolumesSelectionStep: React.FC<VolumesSelectionStepProps> = observer((p) => {
    const { deploymentId } = useParams();
    const wizardState = p.wizardState

    useInitData({
        poll: () => wizardState.insertionCandidates.fetchData(deploymentId),
        pollInterval: 30
    });

    return <>
        <Typography variant={'h5'}>Select Volumes</Typography>
        <Typography variant={'body2'}>Select Volumes to be included in this CDM Policy</Typography>
        <br/>
        <Box>
            {renderServerDataWithLoadingList(wizardState.insertionCandidates, data=> {
                return <Box>
                    {data.getDevicesList().map(d=> {
                        return <VolumeSelectionCard device={d} wizardState={wizardState}/>
                    })}
                </Box>
            })
            }
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
            <Box pl={1}>
                <Button variant={'contained'}
                        disabled={Object.keys(wizardState.selectedVolumes).length===0}
                        onClick={() => p.stepperState.goToNextStep()}>Continue</Button>
            </Box>
        </Box>
    </>
});

// ======================
// VolumeSelectionCard
// ======================

interface VolumeSelectionCardProps{
    device: CirrusProtectDeviceInfo
    wizardState: CdmPolicyWizardState
}

const VolumeSelectionCard: React.FC<VolumeSelectionCardProps> = observer((p)=>{
    const device = p.device;
    const selected = !!p.wizardState.selectedVolumes[device.getDevicePath()];
    const onSelect = () => {
        if (selected){
            p.wizardState.removeSelectedVolume(device);
        } else {
            p.wizardState.addSelectedVolume(device);
        }
    };

    return <Card variant={'outlined'} sx={{
        backgroundColor: 'rgba(0,0,0,0)',
        border: '2px solid',
        borderColor: (t: Theme) => selected ? t.palette.primary.main : t.palette.cirrus.light,
        marginBottom: 2
    }}>
        <Box display={'flex'} alignItems={'center'} p={2}>
            <Grid container alignItems={'center'}>
                <Grid item xs={1}>
                    <Switch color={'secondary'}
                            checked={selected}
                            onChange={onSelect}/>
                </Grid>
                <Grid item xs={4}>
                    <Box display={'flex'} justifyContent={'flex-start'}>
                        <Box textAlign={'left'}>
                            <Box pb={1}>
                                <Typography variant={'body1'}>
                                    {device.getDevice()} ({formatKnownDataType(device.getCapacity(), KnownDataType.CAPACITY)})
                                </Typography>
                            </Box>
                            <Grid container spacing={1}>
                                {renderChipInfo(getInsertionCandidateDeviceType(device))}
                                {renderChipInfo(device.getFsType())}
                                {renderChipInfo(device.getLabel())}
                                {renderChipInfo(device.getMountPoint())}
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    </Card>
})

// ======================
// FinalizePolicyStep
// ======================

interface FinalizePolicyStepProps {
    stepperState: StepperState;
    wizardState?: CdmPolicyWizardState

}

const FinalizePolicyStep: React.FC<FinalizePolicyStepProps> = observer((p) => {
    const {progressService} = useAppServices();
    const wizardState = p.wizardState;
    //const goToPoliciesList = useNavigateToCdmPolicyList();
    const goToPolicyPage = useNavigateToCpDeploymentDetails(`${wizardState.selectedHostId}/${PROTECTION_HOST_DETAILS_SUBROUTE.POLICY_INFO}`)
    const { deploymentId } = useParams();

    const createPolicy = async() => {
        await progressService.track(wizardState.createPolicy(deploymentId), 'Creating Policy...');
        goToPolicyPage()
    }

    return <>
{/*        <Typography variant={'h5'}>1. Basic Information</Typography>
        <Typography variant={'body2'}>Please describe this CDM Policy accordingly</Typography>
        <br/>
        <Box pb={2}>
            <TextField variant={'filled'} fullWidth label={'Policy Name'} required value={wizardState.policyName}
                       onChange={(e) => wizardState.setPolicyName(e.target.value)}/>
        </Box>
        <Box pb={2}>
            <TextField variant={'filled'} fullWidth label={'Policy Description'} value={wizardState.policyDescription}
                       onChange={(e) => wizardState.setPolicyDescription(e.target.value)}/>
        </Box>
        <br/>*/}
        <Typography variant={'h5'}>Copy Data Synchronization Mode</Typography>
        <Typography variant={'body2'}>Select a synchronization mode for the backups in this CDM Policy</Typography>
        <br/>
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <SelectableCard selected={wizardState.syncMode === CreatePolicy.Request.CDMPolicyMode.PERIODIC}
                                    onSelect={()=>wizardState.setSyncMode(CreatePolicy.Request.CDMPolicyMode.PERIODIC)}
                                    title={'Periodic Synchronization'}
                                    sx={{height: '100%'}}
                                    description={'Periodically copy the newly written data to the backup disks in a differential manner'}
                                    icon={<RollbackIcon imageAttributes={{width: 64, height: 64}}/> }
                    />

                </Grid>
                <Grid item xs={12} md={4}>
                    <SelectableCard selected={wizardState.syncMode === CreatePolicy.Request.CDMPolicyMode.CONTINUOUS_ASYNC}
                                    onSelect={()=>wizardState.setSyncMode(CreatePolicy.Request.CDMPolicyMode.CONTINUOUS_ASYNC)}
                                    title={'Continuous Synchronization'}
                                    sx={{height: '100%'}}
                                    description={'Newly written data will be a asynchronously mirrored to the backup volume with I/O order preserved'}
                                    icon={<RollbackIcon imageAttributes={{width: 64, height: 64}}/> }
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <SelectableCard selected={wizardState.syncMode === CreatePolicy.Request.CDMPolicyMode.CONTINUOUS}
                                    onSelect={()=>wizardState.setSyncMode(CreatePolicy.Request.CDMPolicyMode.CONTINUOUS)}
                                    title={'Synchronous Mirroring'}
                                    sx={{height: '100%'}}
                                    description={'Mirror data synchrnously to the backup volumes'}
                                    icon={<RollbackIcon imageAttributes={{width: 64, height: 64}}/> }
                    />
                </Grid>
            </Grid>

            </Box>
        {/*<Box>
            <RadioGroup value={wizardState.syncMode} onChange={(e) => wizardState.setSyncMode(e.target.value)}>
                <Box pb={2}>
                    <Card>
                        <Box p={2}>
                            <FormControlLabel value={'periodic'} control={<Radio color={'secondary'}/>} label={<Box>
                                <Typography variant={'h6'}>Periodic Synchronization</Typography>
                                <Typography variant={'body2'}>Periodically copy the newly written data to the backup
                                    disks in a differential manner</Typography>
                            </Box>}/>
                        </Box>
                    </Card>
                </Box>
                <Box pb={2}>
                    <Card>
                        <Box p={2}>
                            <FormControlLabel value={'continuous'} control={<Radio color={'secondary'}/>} label={<Box>
                                <Typography variant={'h6'}>Continuous Synchronization</Typography>
                                <Typography variant={'body2'}>Newly written data will be a asynchronously mirrored to
                                    the backup volume with I/O order preserved</Typography>
                            </Box>}/>
                        </Box>
                    </Card>
                </Box>
                <Box pb={2}>
                    <Card>
                        <Box p={2}>
                            <FormControlLabel value={'mirroring'} control={<Radio color={'secondary'}/>} label={<Box>
                                <Typography variant={'h6'}>Synchronous Mirroring</Typography>
                                <Typography variant={'body2'}>Mirror data synchrnously to the backup
                                    volumes</Typography>
                            </Box>}/>
                        </Box>
                    </Card>
                </Box>
            </RadioGroup>
        </Box>*/}
        <br/>
        <Box display={'flex'} justifyContent={'center'}>
            <Box pr={1}>
                <Button variant={'outlined'} color={'neutral'} onClick={() => p.stepperState.goBackOneStep()}>Back</Button>
            </Box>
            <Box pl={1}>
                <Button variant={'contained'} disabled={p.wizardState.syncMode === undefined} onClick={createPolicy}>Create Policy</Button>
            </Box>
        </Box>
    </>
})

