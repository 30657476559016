import {observer} from "mobx-react-lite";
import {useAppServices} from "../app/services";
import {useCurrentUser} from "../auth/AuthCommon";
import {APP_ROUTES} from "../app/AppRoutes";
import {useMatch} from "react-router-dom";

export const useIsSupportProject = () => {
    return !!useMatch(`${APP_ROUTES.SUPPORT_PROJECTS}/:projectId*`)
}

export const useIsSupportUser = () => {
    const currentUser = useCurrentUser();
    return currentUser.getCdsiSupportUser()
}

// ======================
// CdsSupportStaffView
// ======================


interface CdsSupportStaffViewProps {
    children: React.ReactNode;
}

export const CdsSupportStaffView: React.FC<CdsSupportStaffViewProps> = observer((p) => {
    const currentUser = useCurrentUser();
    if (currentUser.getCdsiSupportUser()) {
        return <>
            {p.children}
        </>
    }
    return null;
})