// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: DeploymentListScreen

import * as React from 'react';
import {ProjectDetails} from '../../_proto/galaxycompletepb/apipb/domainpb/project_pb';
import {observer} from 'mobx-react-lite';
import {ScreenContainer, ScreenTitleBar} from '../layout/ScreenCommon';
import {
    GalaxyMigrateDeploymentsList,
    GalaxyMmigrateCmcRelayEnabledDeploymentsList
} from '../galaxymigrate/GalaxyMigrateDeploymentsList';
import {useInitData, useServerDataWithLoadingBox} from '../core/data/DataLoaderHooks';
import {Route, Routes, useParams} from 'react-router-dom';
import {useAppServices} from '../app/services';
import {GalaxyMigrateDeploymentDetailsScreen} from '../galaxymigrate/GalaxyMigrateDeploymentDetails';
import {NewDeploymentButton} from './NewDeploymentInstructions';
import {Box} from "@mui/material";
import {OperatorView} from "../auth/AuthenticatedViews";
import {ActionConfig, ActionMenuButton} from "../../common/actions/CommonActions";
import {DeleteIcon} from "../../common/CommonIcons";
import {CreateGalaxyMigrateLinkButton} from "../galaxymigrate/links/CreateGalaxyMigrateLinkForm";
import {GalaxyMigrateConnectionsList} from "../galaxymigrate/GalaxyMigrateConnectionsList";
import {DEPLOYMENT_DETAILS_SUBROUTE, DEPLOYMENT_SUBROUTE} from "../app/AppRoutes";
import {FullScreenLoading} from "../core/data/DataLoaders";
import {isMinMtdiVersion, useHasDeployments, useHasH2HConnections} from "./DeploymentCommon";
import {GalaxyMigrateDeploymentLiveLog} from "../galaxymigrate/details/GalaxyMigrateDeploymentLiveLog";
import {TabConfig, TabGroup} from "../../common/tabs/TabComponents";
import {useIsFeatureEnabled} from "../core/featureflag/FeatureFlags";
import {FeatureFlag} from "../app/AppGlobalService";
import {GmDeploymentXrayListScreen} from "../galaxymigrate/details/GalaxyMigrateDeploymentXrayManagement";

// ======================
// ProjectDeploymentsScreen
// ======================
interface ProjectDeploymentsScreenProps {
    project: ProjectDetails
}

export const ProjectDeploymentsScreens: React.FC<ProjectDeploymentsScreenProps> = observer((p) => {

    return <Routes>
        <Route index element={<MigrationDeploymentsListScreen project={p.project}/>}/>
        <Route path={DEPLOYMENT_SUBROUTE.CONNECTIONS} element={<DeploymentConnectionsListScreen/>}/>
        <Route path={':deploymentID'}>
            <Route index element={<DeploymentDetailsScreen/>}/>
            <Route path={DEPLOYMENT_DETAILS_SUBROUTE.SETTINGS} element={<DeploymentDetailsScreen/>}/>
            <Route path={DEPLOYMENT_DETAILS_SUBROUTE.LOG} element={<GalaxyMigrateDeploymentLiveLog/>}/>
            <Route path={DEPLOYMENT_DETAILS_SUBROUTE.XRAYS} element={<GmDeploymentXrayListScreen/>}/>

        </Route>
    </Routes>;
});


// ======================
// DeploymentsListScreen
// ======================
interface MigrationDeploymentsListScreenProps {
    project: ProjectDetails
}

export const MigrationDeploymentsListScreen: React.FC<MigrationDeploymentsListScreenProps> = observer((p) => {
    const { deploymentService } = useAppServices();
    const hasDeployments = useHasDeployments();

    const getActions = (): ActionConfig[] => {
        return [
            {
                name: 'Remove All Offline Deployments',
                id: 'remove',
                action: async () => {
                    await deploymentService.removeOfflineDeployments();
                    await deploymentService.galaxyMigrateDeployments.fetchData();
                },
                icon: <DeleteIcon/>
            },
        ]
    }

    const tabs: TabConfig[] = [
        {
            label: 'All Hosts',
            renderer: () => <GalaxyMigrateDeploymentsList/>
        },
        {
            label: 'Management Relays',
            renderer: () => <GalaxyMmigrateCmcRelayEnabledDeploymentsList/>,
        }
    ]

    const actions = <Box display={'flex'} alignItems={'center'}>
        <OperatorView>
            {hasDeployments &&
            <Box pr={1}>
                <NewDeploymentButton/>
            </Box>
            }
            <ActionMenuButton actions={getActions()}/>
        </OperatorView>
    </Box>
    return (<ScreenContainer>
        <ScreenTitleBar title={`Hosts Deployed`} actions={actions}/>
        <TabGroup configs={tabs}/>
    </ScreenContainer>);
});

// ======================
// DeploymentConnectionsListScreen
// ======================

interface DeploymentConnectionsListScreenProps{
}

export const DeploymentConnectionsListScreen: React.FC<DeploymentConnectionsListScreenProps> = observer((props)=>{
    const hasLinks = useHasH2HConnections();
    const actions = <Box display={'flex'} alignItems={'center'}>
        {hasLinks &&
            <CreateGalaxyMigrateLinkButton color={'secondary'} variant={'contained'}/>
        }
    </Box>
    return <ScreenContainer>
        <ScreenTitleBar title={`Host-to-Host Connections`} actions={actions}/>
        <GalaxyMigrateConnectionsList/>
    </ScreenContainer>
})

// ======================
// DeploymentDetailsScreen
// ======================
interface DeploymentDetailsScreenProps {
}

export const DeploymentDetailsScreen: React.FC<DeploymentDetailsScreenProps> = observer((p) => {
    const {deploymentService} = useAppServices();
    const {deploymentID}      = useParams();

    React.useEffect(()=> {
        deploymentService.setCurrentDeploymentID(deploymentID);
        deploymentService.currentDeployment.fetchData()
        return ()=> deploymentService.setCurrentDeploymentID(null);
    }, [deploymentID, deploymentService])

    useInitData({
                    init        : ()=>deploymentService.setCurrentDeploymentID(deploymentID),
                    deinit      : () => deploymentService.setCurrentDeploymentID(null),
                    pollInterval: 3,
                    poll        : () => deploymentService.currentDeployment.fetchData(),
                });
    return useServerDataWithLoadingBox(deploymentService.currentDeployment, (data) => {
        // display based on type in the future
        if (deploymentService.currentDeployment.loading){
            return <FullScreenLoading/>
        }
        return <GalaxyMigrateDeploymentDetailsScreen deployment={data}/>;
    });
});

