import * as React from "react";
import { observer } from "mobx-react-lite";
import { useAppServices } from "../app/services";
import { useInitData, useServerDataWithLoadingBox } from "../core/data/DataLoaderHooks";
import { Alert, AlertTitle, Box, Button, Card, CardContent, Grid, ListSubheader, Theme, Typography } from "@mui/material";

import { useNavigateToProjectLicenseDetailsScreen, useNavigateToProjectManagementMainScreen } from "../management/ProjectManagementCommon";
import { LicenseKeyMainScreen } from "./LicenseKeyMainScreen";
import { useIsDesktop } from "../layout/MainLayout";
import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { getLicenseModelLabel, useCurrentProjectLicenseModel } from "./LicenseCommon";
import { LicenseModel } from "../../_proto/galaxycompletepb/apipb/domainpb/enumpb/license_model_pb";
import { VaultDetails } from "../../_proto/galaxycompletepb/apipb/domainpb/license_pb";

// ======================
// MigrationLicenseCard
// ======================

interface MigrationLicenseCardProps {
    projectId: string;
}

export const MigrationLicenseCard: React.FC<MigrationLicenseCardProps> = observer((props) => {
    const { licenseService } = useAppServices();
    const isDesktop = useIsDesktop();
    const projectLicenseModel = useCurrentProjectLicenseModel();

    useInitData({
        poll: () => licenseService.projectLicenseDetails.fetchData(),
        pollInterval: 300,
    });

    const goToProjectLicenseDetails = useNavigateToProjectLicenseDetailsScreen();

    return useServerDataWithLoadingBox(licenseService.projectLicenseDetails, (data) => {
        return (
            <Card
                sx={{
                    height: "100%",
                    width: "100%",
                }}
            >
                <ListSubheader>
                    {"Migration License Management"}{" "}
                    {projectLicenseModel !== LicenseModel.LicenseModel.UNDECIDED &&
                        projectLicenseModel !== LicenseModel.LicenseModel.UNKNOWN &&
                        `(${getLicenseModelLabel(projectLicenseModel)} Licensing)`}
                </ListSubheader>
                <CardContent>
                    <Card variant={"outlined"}>
                        <Box p={2}>
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant={"h6"}>{data.identity.vaultSerial}</Typography>
                                    <Typography variant={"overline"}>License Key</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box display={"flex"} justifyContent={isDesktop ? "flex-end" : "center"}>
                                        <Button variant={"outlined"} onClick={goToProjectLicenseDetails}>
                                            View License Info
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </CardContent>
            </Card>
        );
    });
});

// ======================
// MigrationLicenseDetailScreen
// ======================

interface MigrationLicenseDetailScreenProps {}

export const MigrationLicenseDetailScreen: React.FC<MigrationLicenseDetailScreenProps> = observer((p) => {
    const { licenseService } = useAppServices();

    const refresh = async () => await licenseService.projectLicenseDetails.fetchData();
    const isPrivateEdition = getIsPrivateEdition();

    useInitData({
        poll: refresh,
        pollInterval: 30,
        deinit: () => licenseService.projectLicenseDetails.resetData(),
    });

    useInitData({
        poll: () => licenseService.projectLicenseTransactions.fetchData(),
        pollInterval: 3,
    });

    const goBackToManagementScreen = useNavigateToProjectManagementMainScreen();

    return <LicenseKeyMainScreen type={"project"} backButtonNavFunction={goBackToManagementScreen} refresh={refresh} isPrivateEdition={isPrivateEdition} />;
});
