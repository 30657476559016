import * as React from "react";
import {observer} from "mobx-react-lite";
import {
    VendorAllocateVolumesStepProps,
} from "../GmAutoAllocationCommon";
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography
} from "@mui/material";
import {FieldArray, Form, Formik, FormikValues} from "formik";
import {FormSelect, FormTextField} from "../../../../common/form/FormComponents";
import {useEffectOnce} from "react-use";
import {AutoAlloc} from "../../../../_proto/galaxymigratepb/galaxy_migrate_autoalloc_pb";
import {formatKnownDataType, KnownDataType} from "../../../../common/utils/formatter";
import * as yup from "yup";


export const AWSAllocateVolumesStep: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {
    const state = p.state;
    const allocateNow = p.allocateFunc;

    useEffectOnce(() => {
        for (let device of state.sourceDevices) {
            device.autoAllocParams.setAws(new AutoAlloc.VolumeParams.AWS().setVolumeType(AutoAlloc.VolumeParams.AWS.VolumeType.GP2)
            )
        }
    })

    return <>
        <Typography color={'textSecondary'}>
            {`AWS Data Disks matching the following source volumes will be created using the specified parameters and attached to the destination host`}
        </Typography>
        <br/>
        <AwsVolumeTableForm state={state} allocateFunc={allocateNow}/>
    </>


});

// ======================
// VolumeTableForm
// ======================


export const AwsVolumeTableForm: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {

    const getTableCellStyle = () => ({
        verticalAlign: 'top',
        paddingTop: 2
    })

    const getInitialValues = () => {
        const vols = p.state.sourceDevices.map(d => {
            return {
                volumeType: AutoAlloc.VolumeParams.AWS.VolumeType.GP2,
                iops: 'Not Available'
            }
        })
        return {
            vols: vols
        }
    };

    const validationSchema = yup.object({
        vols: yup.array(yup.object({
            volumeType: yup.mixed(),
            iops: yup.mixed()
                .when(
                    ['volumeType'], {
                        is: AutoAlloc.VolumeParams.AWS.VolumeType.GP3,
                        then: yup.number().typeError('Enter a valid number')//.max(16000, 'IOPS must be less than or equal to 16000').min(3000, 'IOPS must be greater than or equal to 3000')
                    }
                )
                .when(
                    ['volumeType'], {
                        is: AutoAlloc.VolumeParams.AWS.VolumeType.IO1,
                        then: yup.number().typeError('Enter a valid number')//.max(64000, 'IOPS must be less than or equal to 64000').min(3000, 'IOPS must be greater than or equal to 3000')
                    }
                )
                .when(
                    ['volumeType'], {
                        is: AutoAlloc.VolumeParams.AWS.VolumeType.IO2,
                        then: yup.number().typeError('Enter a valid number')//.max(64000, 'IOPS must be less than or equal to 64000').min(3000, 'IOPS must be greater than or equal to 3000')
                    }
                )
        }))
    });


    return <Box pt={2}>
        <Formik initialValues={getInitialValues()} validationSchema={validationSchema}
                onSubmit={async (values: FormikValues) => {
                    p.state.sourceDevices.forEach((d, i) => {
                        d.autoAllocParams.getAws()
                            .setVolumeType(values.vols[i].volumeType)

                        const iops = parseInt(values.vols[i].iops);
                        if (!isNaN(iops)) {
                            d.autoAllocParams.getAws().setIops(iops)
                        }
                    });
                    await p.allocateFunc()
                }
                }>
            {props => {
                const getDefaultIopsValue = (i: number) => {
                    if (props.values.vols[i].volumeType === AutoAlloc.VolumeParams.AWS.VolumeType.GP3 || props.values.vols[i].volumeType === AutoAlloc.VolumeParams.AWS.VolumeType.IO1 || props.values.vols[i].volumeType === AutoAlloc.VolumeParams.AWS.VolumeType.IO2) {
                        return 3000;
                    } else {
                        return 'Not Available';
                    }
                };

                const getIopsDisabled = (i: number) => {
                    return props.values.vols[i].volumeType !== AutoAlloc.VolumeParams.AWS.VolumeType.GP3 && props.values.vols[i].volumeType !== AutoAlloc.VolumeParams.AWS.VolumeType.IO1 && props.values.vols[i].volumeType !== AutoAlloc.VolumeParams.AWS.VolumeType.IO2
                };


                return <Form>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Source Volume
                                    </TableCell>
                                    <TableCell>
                                        Capacity
                                    </TableCell>
                                    <TableCell>
                                        New Disk Class
                                    </TableCell>
                                    <TableCell>
                                        IOPS
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FieldArray name={'vols'} render={helpers => {
                                    return p.state.sourceDevices.map((d, i) => {
                                        return <TableRow>
                                            <TableCell>
                                                {d.source.getBlockDevice().getDeviceName()}
                                            </TableCell>
                                            <TableCell>
                                                {formatKnownDataType(d.source.getBlockDevice().getCapacity(), KnownDataType.CAPACITY)}
                                            </TableCell>
                                            <TableCell sx={getTableCellStyle()}>

                                                <FormSelect label={'Disk Class'} name={`vols[${i}].volumeType`}
                                                            onClose={() => props.setFieldTouched(`vols[${i}].iops`, false)}
                                                            selectionList={volumeTypesSelection}
                                                            defaultValue={volumeTypesSelection[0]}/>

                                            </TableCell>
                                            <TableCell sx={getTableCellStyle()}>
                                                <FormTextField label={'IOPS'} name={`vols[${i}].iops`}
                                                               defaultValue={getDefaultIopsValue(i)}
                                                               overrideInitialValue
                                                               disabled={getIopsDisabled(i)}
                                                               type={getIopsDisabled(i) ? 'string' : 'number'}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    })
                                }}/>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box pt={2} pb={2}>
                        <Button color={'primary'} variant={'contained'}
                                type={'submit'}
                        >
                            {`Allocate Volumes (${p.state.sourceDevices.length})`}
                        </Button>
                    </Box>
                </Form>
            }
            }
        </Formik>
    </Box>
})

const getVolumeTypeLabel = (type: AutoAlloc.VolumeParams.AWS.VolumeType) => {
    if (type === AutoAlloc.VolumeParams.AWS.VolumeType.GP2) {
        return 'General Purpose SSD Gen 2 (GP2)';
    } else if (type === AutoAlloc.VolumeParams.AWS.VolumeType.GP3) {
        return 'General Purpose SSD Gen 3 (GP3)';
    } else if (type === AutoAlloc.VolumeParams.AWS.VolumeType.IO1) {
        return 'Provisioned IOPS SSD Gen 1 (IO1)';
    } else if (type === AutoAlloc.VolumeParams.AWS.VolumeType.IO2) {
        return 'Provisioned IOPS SSD Gen 2 (IO2)';
    } else if (type === AutoAlloc.VolumeParams.AWS.VolumeType.ST1) {
        return 'Throughput Optimized HDD (ST1)';
    } else if (type === AutoAlloc.VolumeParams.AWS.VolumeType.SC1) {
        return 'Cold HDD (SC1)';
    } else if (type === AutoAlloc.VolumeParams.AWS.VolumeType.STANDARD) {
        return 'Magnetic (Standard)'
    }
}

const volumeTypesSelection = [
    {
        label: getVolumeTypeLabel(AutoAlloc.VolumeParams.AWS.VolumeType.GP2),
        value: AutoAlloc.VolumeParams.AWS.VolumeType.GP2
    },
    {
        label: getVolumeTypeLabel(AutoAlloc.VolumeParams.AWS.VolumeType.GP3),
        value: AutoAlloc.VolumeParams.AWS.VolumeType.GP3
    },
    {
        label: getVolumeTypeLabel(AutoAlloc.VolumeParams.AWS.VolumeType.IO1),
        value: AutoAlloc.VolumeParams.AWS.VolumeType.IO1
    },

    {
        label: getVolumeTypeLabel(AutoAlloc.VolumeParams.AWS.VolumeType.IO2),
        value: AutoAlloc.VolumeParams.AWS.VolumeType.IO2
    },
    {
        label: getVolumeTypeLabel(AutoAlloc.VolumeParams.AWS.VolumeType.ST1),
        value: AutoAlloc.VolumeParams.AWS.VolumeType.ST1
    },
    {
        label: getVolumeTypeLabel(AutoAlloc.VolumeParams.AWS.VolumeType.SC1),
        value: AutoAlloc.VolumeParams.AWS.VolumeType.SC1
    },
    {
        label: getVolumeTypeLabel(AutoAlloc.VolumeParams.AWS.VolumeType.STANDARD),
        value: AutoAlloc.VolumeParams.AWS.VolumeType.STANDARD
    },
]