// source: galaxycompletepb/apipb/domainpb/workflow.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var galaxycompletepb_apipb_domainpb_enumpb_workflow_log_level_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/workflow_log_level_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_workflow_log_level_pb);
goog.exportSymbol('proto.galaxycomplete.domain.Workflow', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.WorkflowLog', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.WorkflowParameters', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.WorkflowStage', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.WorkflowStage.Action', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.WorkflowStageStatus', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.WorkflowStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.WorkflowStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.WorkflowStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.WorkflowStage.displayName = 'proto.galaxycomplete.domain.WorkflowStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.WorkflowParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.WorkflowParameters.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.WorkflowParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.WorkflowParameters.displayName = 'proto.galaxycomplete.domain.WorkflowParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.Workflow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.Workflow.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.Workflow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.Workflow.displayName = 'proto.galaxycomplete.domain.Workflow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.WorkflowStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.WorkflowStatus.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.WorkflowStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.WorkflowStatus.displayName = 'proto.galaxycomplete.domain.WorkflowStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.WorkflowStageStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.WorkflowStageStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.WorkflowStageStatus.displayName = 'proto.galaxycomplete.domain.WorkflowStageStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.WorkflowLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.WorkflowLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.WorkflowLog.displayName = 'proto.galaxycomplete.domain.WorkflowLog';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.WorkflowStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.WorkflowStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.WorkflowStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 2, 0),
    integrationId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    systemId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    properties: (f = msg.getProperties()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    pause: (f = msg.getPause()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    timeout: (f = msg.getTimeout()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.WorkflowStage}
 */
proto.galaxycomplete.domain.WorkflowStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.WorkflowStage;
  return proto.galaxycomplete.domain.WorkflowStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.WorkflowStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.WorkflowStage}
 */
proto.galaxycomplete.domain.WorkflowStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {!proto.galaxycomplete.domain.WorkflowStage.Action} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntegrationId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemId(value);
      break;
    case 5:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    case 6:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setPause(value);
      break;
    case 7:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setTimeout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.WorkflowStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.WorkflowStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.WorkflowStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getIntegrationId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSystemId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getPause();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getTimeout();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxycomplete.domain.WorkflowStage.Action = {
  LOG: 0,
  SNAPSHOT: 1,
  EXECUTE: 2,
  UNKNOWN: 1000
};

/**
 * optional Action action = 2;
 * @return {!proto.galaxycomplete.domain.WorkflowStage.Action}
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.getAction = function() {
  return /** @type {!proto.galaxycomplete.domain.WorkflowStage.Action} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.WorkflowStage.Action} value
 * @return {!proto.galaxycomplete.domain.WorkflowStage} returns this
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 integration_id = 3;
 * @return {number}
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.getIntegrationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.WorkflowStage} returns this
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string system_id = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.getSystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.WorkflowStage} returns this
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.setSystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Struct properties = 5;
 * @return {?proto.google.protobuf.Struct}
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.getProperties = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 5));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.galaxycomplete.domain.WorkflowStage} returns this
*/
proto.galaxycomplete.domain.WorkflowStage.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.WorkflowStage} returns this
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Duration pause = 6;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.getPause = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 6));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxycomplete.domain.WorkflowStage} returns this
*/
proto.galaxycomplete.domain.WorkflowStage.prototype.setPause = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.WorkflowStage} returns this
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.clearPause = function() {
  return this.setPause(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.hasPause = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Duration timeout = 7;
 * @return {?proto.google.protobuf.Duration}
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.getTimeout = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 7));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.galaxycomplete.domain.WorkflowStage} returns this
*/
proto.galaxycomplete.domain.WorkflowStage.prototype.setTimeout = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.WorkflowStage} returns this
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.clearTimeout = function() {
  return this.setTimeout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.WorkflowStage.prototype.hasTimeout = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.WorkflowParameters.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.WorkflowParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.WorkflowParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.WorkflowParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.WorkflowParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stagesList: jspb.Message.toObjectList(msg.getStagesList(),
    proto.galaxycomplete.domain.WorkflowStage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.WorkflowParameters}
 */
proto.galaxycomplete.domain.WorkflowParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.WorkflowParameters;
  return proto.galaxycomplete.domain.WorkflowParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.WorkflowParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.WorkflowParameters}
 */
proto.galaxycomplete.domain.WorkflowParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 10:
      var value = new proto.galaxycomplete.domain.WorkflowStage;
      reader.readMessage(value,proto.galaxycomplete.domain.WorkflowStage.deserializeBinaryFromReader);
      msg.addStages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.WorkflowParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.WorkflowParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.WorkflowParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.WorkflowParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.galaxycomplete.domain.WorkflowStage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.WorkflowParameters.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.WorkflowParameters} returns this
 */
proto.galaxycomplete.domain.WorkflowParameters.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.WorkflowParameters.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.WorkflowParameters} returns this
 */
proto.galaxycomplete.domain.WorkflowParameters.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated WorkflowStage stages = 10;
 * @return {!Array<!proto.galaxycomplete.domain.WorkflowStage>}
 */
proto.galaxycomplete.domain.WorkflowParameters.prototype.getStagesList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.WorkflowStage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.WorkflowStage, 10));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.WorkflowStage>} value
 * @return {!proto.galaxycomplete.domain.WorkflowParameters} returns this
*/
proto.galaxycomplete.domain.WorkflowParameters.prototype.setStagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.galaxycomplete.domain.WorkflowStage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.WorkflowStage}
 */
proto.galaxycomplete.domain.WorkflowParameters.prototype.addStages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.galaxycomplete.domain.WorkflowStage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.WorkflowParameters} returns this
 */
proto.galaxycomplete.domain.WorkflowParameters.prototype.clearStagesList = function() {
  return this.setStagesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.Workflow.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.Workflow.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.Workflow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.Workflow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.Workflow.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    projectId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    stagesList: jspb.Message.toObjectList(msg.getStagesList(),
    proto.galaxycomplete.domain.WorkflowStage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.Workflow}
 */
proto.galaxycomplete.domain.Workflow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.Workflow;
  return proto.galaxycomplete.domain.Workflow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.Workflow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.Workflow}
 */
proto.galaxycomplete.domain.Workflow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 20:
      var value = new proto.galaxycomplete.domain.WorkflowStage;
      reader.readMessage(value,proto.galaxycomplete.domain.WorkflowStage.deserializeBinaryFromReader);
      msg.addStages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.Workflow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.Workflow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.Workflow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.Workflow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.galaxycomplete.domain.WorkflowStage.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.galaxycomplete.domain.Workflow.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.Workflow} returns this
 */
proto.galaxycomplete.domain.Workflow.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string project_id = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.Workflow.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.Workflow} returns this
 */
proto.galaxycomplete.domain.Workflow.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.galaxycomplete.domain.Workflow.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.Workflow} returns this
 */
proto.galaxycomplete.domain.Workflow.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.Workflow.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.Workflow} returns this
 */
proto.galaxycomplete.domain.Workflow.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.Workflow.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.Workflow} returns this
*/
proto.galaxycomplete.domain.Workflow.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.Workflow} returns this
 */
proto.galaxycomplete.domain.Workflow.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.Workflow.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated WorkflowStage stages = 20;
 * @return {!Array<!proto.galaxycomplete.domain.WorkflowStage>}
 */
proto.galaxycomplete.domain.Workflow.prototype.getStagesList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.WorkflowStage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.WorkflowStage, 20));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.WorkflowStage>} value
 * @return {!proto.galaxycomplete.domain.Workflow} returns this
*/
proto.galaxycomplete.domain.Workflow.prototype.setStagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.galaxycomplete.domain.WorkflowStage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.WorkflowStage}
 */
proto.galaxycomplete.domain.Workflow.prototype.addStages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.galaxycomplete.domain.WorkflowStage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.Workflow} returns this
 */
proto.galaxycomplete.domain.Workflow.prototype.clearStagesList = function() {
  return this.setStagesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.WorkflowStatus.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.WorkflowStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.WorkflowStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.WorkflowStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    running: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    startedAt: (f = msg.getStartedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endedAt: (f = msg.getEndedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 5, ""),
    currentStage: jspb.Message.getFieldWithDefault(msg, 6, 0),
    stagesList: jspb.Message.toObjectList(msg.getStagesList(),
    proto.galaxycomplete.domain.WorkflowStageStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.WorkflowStatus}
 */
proto.galaxycomplete.domain.WorkflowStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.WorkflowStatus;
  return proto.galaxycomplete.domain.WorkflowStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.WorkflowStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.WorkflowStatus}
 */
proto.galaxycomplete.domain.WorkflowStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRunning(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartedAt(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentStage(value);
      break;
    case 20:
      var value = new proto.galaxycomplete.domain.WorkflowStageStatus;
      reader.readMessage(value,proto.galaxycomplete.domain.WorkflowStageStatus.deserializeBinaryFromReader);
      msg.addStages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.WorkflowStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.WorkflowStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.WorkflowStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRunning();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getStartedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCurrentStage();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getStagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.galaxycomplete.domain.WorkflowStageStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.WorkflowStatus} returns this
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool running = 2;
 * @return {boolean}
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.getRunning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.WorkflowStatus} returns this
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.setRunning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp started_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.getStartedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.WorkflowStatus} returns this
*/
proto.galaxycomplete.domain.WorkflowStatus.prototype.setStartedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.WorkflowStatus} returns this
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.clearStartedAt = function() {
  return this.setStartedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.hasStartedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp ended_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.getEndedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.WorkflowStatus} returns this
*/
proto.galaxycomplete.domain.WorkflowStatus.prototype.setEndedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.WorkflowStatus} returns this
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.clearEndedAt = function() {
  return this.setEndedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.hasEndedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string error = 5;
 * @return {string}
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.WorkflowStatus} returns this
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 current_stage = 6;
 * @return {number}
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.getCurrentStage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.WorkflowStatus} returns this
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.setCurrentStage = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated WorkflowStageStatus stages = 20;
 * @return {!Array<!proto.galaxycomplete.domain.WorkflowStageStatus>}
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.getStagesList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.WorkflowStageStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.domain.WorkflowStageStatus, 20));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.WorkflowStageStatus>} value
 * @return {!proto.galaxycomplete.domain.WorkflowStatus} returns this
*/
proto.galaxycomplete.domain.WorkflowStatus.prototype.setStagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.galaxycomplete.domain.WorkflowStageStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.WorkflowStageStatus}
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.addStages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.galaxycomplete.domain.WorkflowStageStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.WorkflowStatus} returns this
 */
proto.galaxycomplete.domain.WorkflowStatus.prototype.clearStagesList = function() {
  return this.setStagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.WorkflowStageStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.WorkflowStageStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.WorkflowStageStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    startedAt: (f = msg.getStartedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endedAt: (f = msg.getEndedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 4, ""),
    info: (f = msg.getInfo()) && proto.galaxycomplete.domain.WorkflowStage.toObject(includeInstance, f),
    pending: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.WorkflowStageStatus}
 */
proto.galaxycomplete.domain.WorkflowStageStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.WorkflowStageStatus;
  return proto.galaxycomplete.domain.WorkflowStageStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.WorkflowStageStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.WorkflowStageStatus}
 */
proto.galaxycomplete.domain.WorkflowStageStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartedAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 5:
      var value = new proto.galaxycomplete.domain.WorkflowStage;
      reader.readMessage(value,proto.galaxycomplete.domain.WorkflowStage.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.WorkflowStageStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.WorkflowStageStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.WorkflowStageStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStartedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.galaxycomplete.domain.WorkflowStage.serializeBinaryToWriter
    );
  }
  f = message.getPending();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.WorkflowStageStatus} returns this
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp started_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.getStartedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.WorkflowStageStatus} returns this
*/
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.setStartedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.WorkflowStageStatus} returns this
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.clearStartedAt = function() {
  return this.setStartedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.hasStartedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp ended_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.getEndedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.WorkflowStageStatus} returns this
*/
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.setEndedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.WorkflowStageStatus} returns this
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.clearEndedAt = function() {
  return this.setEndedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.hasEndedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string error = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.WorkflowStageStatus} returns this
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional WorkflowStage info = 5;
 * @return {?proto.galaxycomplete.domain.WorkflowStage}
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.getInfo = function() {
  return /** @type{?proto.galaxycomplete.domain.WorkflowStage} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.WorkflowStage, 5));
};


/**
 * @param {?proto.galaxycomplete.domain.WorkflowStage|undefined} value
 * @return {!proto.galaxycomplete.domain.WorkflowStageStatus} returns this
*/
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.WorkflowStageStatus} returns this
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool pending = 6;
 * @return {boolean}
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.getPending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.WorkflowStageStatus} returns this
 */
proto.galaxycomplete.domain.WorkflowStageStatus.prototype.setPending = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.WorkflowLog.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.WorkflowLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.WorkflowLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.WorkflowLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    stageIndex: jspb.Message.getFieldWithDefault(msg, 1, 0),
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    level: (f = msg.getLevel()) && galaxycompletepb_apipb_domainpb_enumpb_workflow_log_level_pb.WorkflowLogLevel.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.WorkflowLog}
 */
proto.galaxycomplete.domain.WorkflowLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.WorkflowLog;
  return proto.galaxycomplete.domain.WorkflowLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.WorkflowLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.WorkflowLog}
 */
proto.galaxycomplete.domain.WorkflowLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStageIndex(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 3:
      var value = new galaxycompletepb_apipb_domainpb_enumpb_workflow_log_level_pb.WorkflowLogLevel;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_enumpb_workflow_log_level_pb.WorkflowLogLevel.deserializeBinaryFromReader);
      msg.setLevel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.WorkflowLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.WorkflowLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.WorkflowLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.WorkflowLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStageIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLevel();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      galaxycompletepb_apipb_domainpb_enumpb_workflow_log_level_pb.WorkflowLogLevel.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 stage_index = 1;
 * @return {number}
 */
proto.galaxycomplete.domain.WorkflowLog.prototype.getStageIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.WorkflowLog} returns this
 */
proto.galaxycomplete.domain.WorkflowLog.prototype.setStageIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.WorkflowLog.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.WorkflowLog} returns this
*/
proto.galaxycomplete.domain.WorkflowLog.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.WorkflowLog} returns this
 */
proto.galaxycomplete.domain.WorkflowLog.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.WorkflowLog.prototype.hasTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional enums.WorkflowLogLevel level = 3;
 * @return {?proto.galaxycomplete.domain.enums.WorkflowLogLevel}
 */
proto.galaxycomplete.domain.WorkflowLog.prototype.getLevel = function() {
  return /** @type{?proto.galaxycomplete.domain.enums.WorkflowLogLevel} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_enumpb_workflow_log_level_pb.WorkflowLogLevel, 3));
};


/**
 * @param {?proto.galaxycomplete.domain.enums.WorkflowLogLevel|undefined} value
 * @return {!proto.galaxycomplete.domain.WorkflowLog} returns this
*/
proto.galaxycomplete.domain.WorkflowLog.prototype.setLevel = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.WorkflowLog} returns this
 */
proto.galaxycomplete.domain.WorkflowLog.prototype.clearLevel = function() {
  return this.setLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.WorkflowLog.prototype.hasLevel = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.galaxycomplete.domain.WorkflowLog.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.WorkflowLog} returns this
 */
proto.galaxycomplete.domain.WorkflowLog.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


goog.object.extend(exports, proto.galaxycomplete.domain);
