import { useAppServices } from "../app/services";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const UserQueryKeys = {
    listMyUserSessions: "ListMyUserSessions",
};

export const useListUserSessions = () => {
    const { userService } = useAppServices();
    const queryFn = async () => await userService.listUserSessions();

    return useQuery({
        queryKey: [UserQueryKeys.listMyUserSessions],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useRevokeMyUserSession = () => {
    const { userService } = useAppServices();
    const mutationFn = async (sessionId: number) => await userService.revokeUserSession(sessionId);
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [`revokeUserSession`],
        mutationFn: mutationFn,
        onSuccess: async () => {
            await queryClient.refetchQueries([UserQueryKeys.listMyUserSessions]);
        },
    });
};
