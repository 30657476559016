import { useAppServices } from "../app/services";
import { GetAllProjectIntegrations } from "../../_proto/galaxycompletepb/apipb/integration_api_pb";
import { useQuery } from "@tanstack/react-query";

const IntegrationsQueryKeys = {
    GetAllProjectIntegrations: "GetAllProjectIntegrations",
    GetEnabledIntegrationDetails: "GetEnabledIntegrationDetails",
};

export const useListIntegrations = () => {
    const { integrationsService } = useAppServices();
    const queryFn = async () => await integrationsService.fetchProjectIntegrations();

    return useQuery({
        queryKey: [IntegrationsQueryKeys.GetAllProjectIntegrations],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useGetEnabledIntegrationDetails = (integrationId: number) => {
    const { integrationsService } = useAppServices();
    const queryFn = async () => await integrationsService.fetchIntegrationDetails(integrationId);

    return useQuery({
        queryKey: [IntegrationsQueryKeys.GetEnabledIntegrationDetails],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
        enabled: !!integrationId,
    });
};
