// Project: GalaxyComplete
// Created: 9/28/20 by sammy
// File: GmMigrationSessionDetails

import * as React from "react";
import { useMemo, useState } from "react";
import { useAppServices } from "../../app/services";
import { observer } from "mobx-react-lite";
import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import { renderServerDataWithLoadingBox, renderServerDataWithLoadingList, useInitData } from "../../core/data/DataLoaderHooks";
import {
    GalaxyMigrateDeploymentDetails,
    GalaxyMigrateMigrationSessionDetails,
    GalaxyMigrateMigrationSessionInfo,
} from "../../../_proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Dialog,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    linearProgressClasses,
    Link,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    SvgIcon,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import {
    ActionTriggeredState,
    CancelIcon,
    CompleteIcon,
    CutoverIcon,
    getChangeDataMapDisabledForSession,
    getMigrationDestinationVolumeLabel,
    getMigrationSourceVolumeLabel,
    getStorageConfigBlockDevice,
    renderGMSessionStatus,
    RestartIcon,
    SuspendSynchronizeIcon,
    SynchronizeIcon,
    useCutoverMigrationSession,
    useDeleteMigrationSession,
    useFinalizeCutoverMigrationSession,
    useNavigateToQosScreen,
    useNavigateToSessionEditScreen,
    useNavigateToSessionEditVmConfigScreen,
} from "../MigrationCommon";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { formatDurationFromSeconds, formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { KVTable } from "../../../common/table/KVTable";
import { FaAngleDoubleDown, FaAngleDoubleRight } from "react-icons/fa";
import { BsFillHddNetworkFill, BsGearFill } from "react-icons/bs";
import { Link as RouterLink, useParams } from "react-router-dom";
import { ActionConfig, ActionMenuButton } from "../../../common/actions/CommonActions";
import { getUserFullName } from "../../settings/ProjectUsers";
import { DeleteIcon, EditIcon } from "../../../common/CommonIcons";
import { SyncQos } from "../../../_proto/galaxymigratepb/galaxy_migrate_qos_pb";
import { OperatorView } from "../../auth/AuthenticatedViews";
import { FormattedDisplay } from "../../../common/FormattedDisplay";
import { BitmapIcon, GmVolumeBitmapDialog } from "./GmMigrationSessionVolumeBitmap";
import { DialogService, DialogState, useDialogState } from "../../core/dialog/DialogService";
import { IQosIcon } from "./qosCharts/GmSessionVolumeQosScreen";
import { LightDivider } from "../../../common/misc";
import { useIsDesktop } from "../../layout/MainLayout";
import { isDeploymentGteVersion, isMinMtdiVersion } from "../../deployment/DeploymentCommon";
import { generateDeploymentDetailsPath } from "../../galaxymigrate/GalaxyMigrateCommon";
import { TabConfig, TabGroup } from "../../../common/tabs/TabComponents";
import { ColumnDef, DataTable } from "../../../common/table/DataTable";

import { SessionDetailsPostSyncActionsSection } from "../workflow/GmMigrationSessionDetailsPostSyncActions";
import { AiFillDatabase, AiOutlineInfoCircle } from "react-icons/ai";
import { MTDIDeploymentInfo } from "../../../_proto/galaxycompletepb/apipb/domainpb/mtdi_pb";
import { HintButton, KnownArticle } from "../../help/HelpCommon";
import { WorkflowStage } from "../../../_proto/galaxycompletepb/apipb/domainpb/workflow_pb";
import { SelectableBox } from "../../../common/card/SelectableCard";
import { ListSnapshotsResponse } from "../../../_proto/galaxymigratepb/galaxy_migrate_autoalloc_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { GalaxyMigrateAutoAllocationProgressDialog } from "../autoallocation/GmAutoAllocationProgress";
import { useGmAutoAllocationState } from "../autoallocation/GmAutoAllocationCommon";
import { DeploymentHostEnvironment } from "../../../_proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import { GalaxyMigrateMigrationService } from "../GmMigrationService";
import { StartMigrationCutoverChecklistBanner } from "./checklist/GmMigrationCutoverCreateChecklist";
import { GmMigrationCutoverChecklistView } from "./checklist/GmMigrationCutoverChecklist";
import { getAxesMaxValue } from "../../../common/utils/statsUtil";
import { KeyValuePair } from "../../../common/text/CommonTypeFormats";
import { RiQuestionLine } from "react-icons/ri";
import { GalaxyMigrateMigrationSessionDetailsQosCalendar } from "./qosCalendar/GmMigrationSessionDetailsQosCalendar";
import { GmMigrationSessionDetailsEventsTimeline } from "./GmMigrationSessionDetailsEventsTimeline";
import { alpha } from "@mui/material/styles";
import { GmMigrationSessionActionsButton } from "./GmMigrationSessionActions";
import { GmMigrationSessionVolumeSnapshots } from "./GmMigrationSessionDetailsSnapshots";
import { GmMigrationSessionVmConfig } from "./GmMigrationSessionDetailsVmConfig";
import { GmMigrationSessionVolumesProgressInfo } from "./GmMigrationSessionDetailsVolumeInfo";
import { useOpenHelpArticle } from "../../help/hooks/help_hooks";

// ======================
// GmMigrationSessionDetailsScreen
// ======================
interface GmMigrationSessionDetailsScreenProps {}

export const GmMigrationSessionDetailsScreen: React.FC<GmMigrationSessionDetailsScreenProps> = observer((p) => {
    const { gmMigrationService, deploymentService, checklistService } = useAppServices();
    const { sessionId } = useParams();
    const [actionTriggered, setActionTriggered] = useState(false);
    const actionTriggeredState = useMemo(() => {
        return { actionTriggered, setActionTriggered };
    }, [actionTriggered, setActionTriggered]);
    const init = async () => {
        gmMigrationService.setCurrentSessionID(sessionId);
        await gmMigrationService.currentSession.fetchData();
        if (!!gmMigrationService.currentSession.data.getSessionInfo().getDestinationDeployment()) {
            await gmMigrationService.remoteStorageConfig.fetchData();
        }
        await gmMigrationService.storageConfig.fetchData();
        if (!!gmMigrationService.currentSession?.data?.getSessionInfo()?.getPostSyncWorkflowId()) {
            await gmMigrationService.currentSessionWorkflow.fetchData(gmMigrationService.currentSession.data.getSessionInfo().getPostSyncWorkflowId());
        }
        if (!!gmMigrationService.currentSession.data?.getCutoverChecklistId()) {
            checklistService.setCurrentChecklistId(gmMigrationService.currentSession.data.getCutoverChecklistId());
        }
        await deploymentService.currentDeployment.fetchData(gmMigrationService.currentSession.data?.getSessionInfo().getDeployment().getSystemId());
    };

    useInitData({
        init: init,
        poll: () => gmMigrationService.currentSession.fetchData(sessionId),
        pollInterval: 3,
        deinit: () => {
            gmMigrationService.setCurrentSessionID(null);
            gmMigrationService.currentSession.resetData();
            gmMigrationService.currentSessionWorkflow.resetData();
            gmMigrationService.currentSessionSelectedSnapshotVolume = null;
        },
    });

    return renderServerDataWithLoadingBox(gmMigrationService.currentSession, (data) => {
        return (
            <ScreenContainer>
                <ScreenTitleBar
                    title={`Cirrus Migrate Cloud Session`}
                    actions={
                        <>
                            <GmMigrationSessionActionsButton actionTriggeredState={actionTriggeredState} />
                        </>
                    }
                />
                <SessionDetails data={data} actionTriggeredState={actionTriggeredState} />
            </ScreenContainer>
        );
    });
});

enum SessionDetailsTabLabels {
    VOLUMES = "Volumes",
    ACTIONS = "Post-Sync Actions",
    CHECKLIST = "Cutover Guide",
    SNAPSHOTS = "Snapshots",
    VM_CONFIG = "VM Configuration",
    SCHEDULE = "iQoS Schedule",
    EVENTS = "Timeline",
}

enum SessionDetailsTabs {
    CHECKLIST,
    VOLUMES,
    SCHEDULE,
    ACTIONS,
    SNAPSHOTS,
    VM_CONFIG,
    EVENTS,
}

// ======================
// SessionDetails
// ======================
interface SessionDetailsProps {
    data: GalaxyMigrateMigrationSessionDetails;
    actionTriggeredState: ActionTriggeredState;
}

const SessionDetails: React.FC<SessionDetailsProps> = observer((p) => {
    const { gmMigrationService, deploymentService } = useAppServices();
    const sessionInfo = p.data.getSessionInfo();
    const { projectId } = useParams();
    const stats = sessionInfo.getSessionStats();
    const status = sessionInfo.getSessionStatus();
    const deployment = sessionInfo.getDeployment();
    const sessionOverviewSectionHeight = "640px";
    const timelineMinVersion = "5.1.0";
    const isTimelineMinVersion = isDeploymentGteVersion(deploymentService.currentDeployment.data, timelineMinVersion);
    const openHelpArticle = useOpenHelpArticle();

    const tabs: TabConfig[] = [
        {
            index: SessionDetailsTabs.CHECKLIST,
            label: SessionDetailsTabLabels.CHECKLIST,
            renderer: () => <GmMigrationCutoverChecklistView actionTriggeredState={p.actionTriggeredState} sessionDetails={p.data} />,
            hidden: !p.data.getCutoverChecklistId(),
        },
        {
            index: SessionDetailsTabs.VOLUMES,
            label: SessionDetailsTabLabels.VOLUMES,
            renderer: () => <GmMigrationSessionVolumesProgressInfo data={p.data} />,
        },
        {
            index: SessionDetailsTabs.SCHEDULE,
            label: SessionDetailsTabLabels.SCHEDULE,
            renderer: () => (
                <GalaxyMigrateMigrationSessionDetailsQosCalendar
                    qosSchedule={p.data.getSessionInfo().getQosSchedule()}
                    timezone={deploymentService.currentDeployment.data?.getInfo().getDeployment().getSystemTimezone()}
                />
            ),
            hidden: !p.data.getSessionInfo().hasQosSchedule(),
        },
        {
            index: SessionDetailsTabs.ACTIONS,
            label: SessionDetailsTabLabels.ACTIONS,
            renderer: () => <SessionDetailsPostSyncActionsSection data={p.data} />,
            hidden: !gmMigrationService.currentSessionWorkflow.data,
        },
        {
            index: SessionDetailsTabs.SNAPSHOTS,
            label: SessionDetailsTabLabels.SNAPSHOTS,
            renderer: () => <GmMigrationSessionVolumeSnapshots data={p.data} />,
            hidden: !gmMigrationService.currentSessionWorkflow.data?.getStagesList().find((s) => s.getAction() === WorkflowStage.Action.SNAPSHOT),
        },
        {
            index: SessionDetailsTabs.VM_CONFIG,
            label: SessionDetailsTabLabels.VM_CONFIG,
            renderer: () => <GmMigrationSessionVmConfig sessionInfo={sessionInfo} deploymentInfo={deploymentService.currentDeployment.data} />,
            hidden: !sessionInfo.getIsComputeMigration(),
        },
        {
            index: SessionDetailsTabs.EVENTS,
            label: SessionDetailsTabLabels.EVENTS,
            renderer: () => <GmMigrationSessionDetailsEventsTimeline sessionInfo={sessionInfo} />,
            hidden: !isTimelineMinVersion,
        },
    ];

    const [tabValue, setTabValue] = useState(p.data.getCutoverChecklistId() ? SessionDetailsTabs.CHECKLIST.toString() : SessionDetailsTabs.VOLUMES.toString());

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
        setTabValue(newValue);
    };

    const goToChecklistTab = () => {
        setTabValue(SessionDetailsTabs.CHECKLIST.toString());
    };

    const getHostInfo = () => {
        if (!!sessionInfo.getDestinationDeployment()) {
            return (
                <span>
                    <Link component={RouterLink} to={generateDeploymentDetailsPath(deployment.getSystemId(), projectId)}>
                        {deployment.getSystemName()}
                    </Link>
                    &nbsp; &nbsp;
                    {`>>`}
                    &nbsp; &nbsp;
                    <Link component={RouterLink} to={generateDeploymentDetailsPath(sessionInfo.getDestinationDeployment().getSystemId(), projectId)}>
                        {sessionInfo.getDestinationDeployment().getSystemName()}
                    </Link>
                </span>
            );
        } else {
            return (
                <Link component={RouterLink} to={generateDeploymentDetailsPath(deployment.getSystemId(), projectId)}>
                    {deployment.getSystemName()}
                </Link>
            );
        }
    };

    return (
        <Box pb={6}>
            {status === GalaxyMigrateMigrationSessionInfo.Status.COMPLETED && !sessionInfo.getBootVolumeSession() && (
                <Box pb={2}>
                    <Alert severity="success">
                        <AlertTitle>{`Migration Completed`}</AlertTitle>
                        {`If all migration sessions for this host have been completed, you may uninstall Cirrus Migrate Cloud now.`}
                    </Alert>
                </Box>
            )}
            {status === GalaxyMigrateMigrationSessionInfo.Status.COMPLETED && sessionInfo.getBootVolumeSession() && (
                <Box pb={2}>
                    <Alert severity="success">
                        <AlertTitle>{`Action Required --- Boot Volume Cutover Completed`}</AlertTitle>
                        {`Migration is now completed. New changes to the volumes in this sessions are no longer tracked. You can shutdown the host (${deployment.getSystemName()}) now, remove the source volume and boot using the new volume`}
                    </Alert>
                </Box>
            )}
            {status === GalaxyMigrateMigrationSessionInfo.Status.CUTOVER && (
                <Box pb={2}>
                    <Alert severity="info">
                        <AlertTitle>{`Final Cutover Completed`}</AlertTitle>
                        {`I/O to the source volumes will continue to be redirected by cMotion™. `}
                        {!sessionInfo.getBootVolumeSession() ? "" : "Host must immediately be reconfigured to boot from new volume."}
                    </Alert>
                </Box>
            )}
            {status === GalaxyMigrateMigrationSessionInfo.Status.CUTOVER && sessionInfo.getBootVolumeSession() && (
                <Box pb={2}>
                    <Alert
                        severity="warning"
                        action={
                            <Button color={"inherit"} onClick={() => openHelpArticle(KnownArticle.MIGRATING_BOOT_VOLUMES)}>
                                {"Learn More"}
                            </Button>
                        }
                    >
                        <AlertTitle>{`ACTION REQUIRED`}</AlertTitle>
                        {
                            "Source boot volume is now being redirected and will no longer be automatically bootable. Host must immediately be reconfigured to boot from new volume."
                        }
                    </Alert>
                </Box>
            )}
            {!!sessionInfo.getQosStartTime() && (
                <Box pb={2}>
                    <Alert severity="info">
                        Migration session scheduled to start at {formatKnownDataType(sessionInfo.getQosStartTime().toDate(), KnownDataType.DATE)}.
                    </Alert>
                </Box>
            )}
            <StartMigrationCutoverChecklistBanner
                migrationSessionStatus={status}
                goToChecklistTab={goToChecklistTab}
                hasExistingChecklist={!!p.data.getCutoverChecklistId()}
            />
            <Grid container spacing={2} height={{ lg: sessionOverviewSectionHeight }}>
                <Grid item xs={12} lg={3} pb={2}>
                    <ProgressStatusCard data={p.data} isNewStatsMinVersion={isTimelineMinVersion} />
                </Grid>
                <Grid item xs={12} lg={9} height={"100%"}>
                    <Grid container height={"100%"} spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <SessionStats stats={stats} isNewStatsMinVersion={isTimelineMinVersion} />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Card sx={{ height: "100%" }}>
                                <ListSubheader>{`Session Configuration`}</ListSubheader>
                                <KVTable
                                    data={[
                                        {
                                            key: "Volumes",
                                            value: p.data.getTotalVolumes(),
                                        },
                                        {
                                            key: "Description",
                                            value: sessionInfo.getDescription() || "No Description for this session",
                                        },
                                        { key: "Project", value: sessionInfo.getProject().getName() },
                                        {
                                            key: "Host",
                                            value: getHostInfo(),
                                        },
                                        {
                                            key: "Created",
                                            value: `${formatKnownDataType(sessionInfo.getCreatedAt().toDate(), KnownDataType.DATE)} by ${
                                                getUserFullName(p.data.getCreatedBy()) || "unknown"
                                            }`,
                                        },
                                        {
                                            key: "Current iQOS Level",
                                            value:
                                                (sessionInfo.getQosLevel() === SyncQos.ImpactLevel.NONE
                                                    ? "WAITING"
                                                    : Object.keys(SyncQos.ImpactLevel)[sessionInfo.getQosLevel()]) || "N/A",
                                        },
                                        { key: "Re-sync Interval", value: sessionInfo.getAutoSyncMinutes() + " Minutes" },
                                    ]}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <TabGroup configs={tabs} tabsProps={{ indicatorColor: "primary" }} value={tabValue} handleChangeTab={handleChangeTab} />
        </Box>
    );
});

// ======================
// SessionStats
// ======================

interface SessionStatsProps {
    stats: GalaxyMigrateMigrationSessionInfo.Statistics;
    isNewStatsMinVersion: boolean;
}

const SessionStats: React.FC<SessionStatsProps> = observer((p) => {
    const rootProgressStyle = {
        backgroundColor: (t: Theme) => t.palette.cirrus.main,
        height: 8,
        borderRadius: 5,
        marginBottom: "4px",
    };

    const maxThroughputValue = p.isNewStatsMinVersion
        ? getAxesMaxValue(KnownDataType.THROUGHPUT, [
              p.stats.getCurrentThroughput(),
              p.stats.getAverageThroughput(),
              p.stats.getAverageTransferThroughput(),
              p.stats.getCurrentTransferThroughput(),
          ])
        : getAxesMaxValue(KnownDataType.THROUGHPUT, [p.stats.getCurrentThroughput()]);
    return (
        <Box height={"100%"} display={"flex"} flexDirection={"column"}>
            <Box pb={2}>
                <Card>
                    <CardContent>
                        <Box pb={2}>
                            <KeyValuePair
                                label={"Estimated Time Remaining"}
                                value={
                                    p.stats.getCurrentThroughput()
                                        ? formatDurationFromSeconds(Math.round(p.stats.getTotalRemaining() / p.stats.getCurrentThroughput()))
                                        : `N/A`
                                }
                            />
                        </Box>
                        <Box>
                            <Box>
                                <Typography fontWeight={500}>Migration Rate</Typography>
                                <Box pt={1} pb={1}>
                                    <Tooltip title={`Current Migration Rate: ${formatKnownDataType(p.stats.getCurrentThroughput(), KnownDataType.THROUGHPUT)}`}>
                                        <LinearProgress
                                            sx={rootProgressStyle}
                                            variant={"determinate"}
                                            color={"primary"}
                                            value={maxThroughputValue ? (p.stats.getCurrentThroughput() / maxThroughputValue) * 100 : 0}
                                        />
                                    </Tooltip>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Box display={"flex"}>
                                            <Typography variant={"caption"}>
                                                {formatKnownDataType(p.stats.getCurrentThroughput(), KnownDataType.THROUGHPUT)}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                &nbsp; (Current)
                                            </Typography>
                                        </Box>
                                        {!p.isNewStatsMinVersion && (
                                            <Box>
                                                <Typography variant={"caption"} color={"textSecondary"}>
                                                    {formatKnownDataType(maxThroughputValue, KnownDataType.THROUGHPUT)}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                                {p.isNewStatsMinVersion && (
                                    <>
                                        <Tooltip
                                            title={`Average Migration Rate: ${formatKnownDataType(p.stats.getAverageThroughput(), KnownDataType.THROUGHPUT)}`}
                                        >
                                            <LinearProgress
                                                sx={{
                                                    ...rootProgressStyle,
                                                    [`& .${linearProgressClasses.bar}`]: {
                                                        backgroundColor: (t: Theme) => alpha(t.palette.primary.main, 0.5),
                                                    },
                                                }}
                                                variant={"determinate"}
                                                value={maxThroughputValue ? (p.stats.getAverageThroughput() / maxThroughputValue) * 100 : 0}
                                            />
                                        </Tooltip>
                                        <Box display={"flex"}>
                                            <Typography variant={"caption"}>
                                                {formatKnownDataType(p.stats.getAverageThroughput(), KnownDataType.THROUGHPUT)}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                &nbsp; (Average)
                                            </Typography>
                                        </Box>
                                    </>
                                )}
                            </Box>
                            <br />
                            <Typography fontWeight={500}>Transfer Rate</Typography>
                            <Box pt={1}>
                                <Box pb={1}>
                                    <Tooltip
                                        title={`Current Transfer Rate: ${formatKnownDataType(
                                            p.stats.getCurrentTransferThroughput(),
                                            KnownDataType.THROUGHPUT
                                        )}`}
                                    >
                                        <LinearProgress
                                            sx={rootProgressStyle}
                                            variant={"determinate"}
                                            color={"secondary"}
                                            value={(p.stats.getCurrentTransferThroughput() / maxThroughputValue) * 100}
                                        />
                                    </Tooltip>
                                    <Box display={"flex"} justifyContent={"space-between"}>
                                        <Box display={"flex"}>
                                            <Typography variant={"caption"}>
                                                {formatKnownDataType(p.stats.getCurrentTransferThroughput(), KnownDataType.THROUGHPUT)}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                &nbsp; (Current)
                                            </Typography>
                                        </Box>
                                        {!p.isNewStatsMinVersion && (
                                            <Box>
                                                <Typography variant={"caption"} color={"textSecondary"}>
                                                    {formatKnownDataType(maxThroughputValue, KnownDataType.THROUGHPUT)}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                                {p.isNewStatsMinVersion && (
                                    <>
                                        <Tooltip
                                            title={`Average Transfer Rate: ${formatKnownDataType(
                                                p.stats.getAverageTransferThroughput(),
                                                KnownDataType.THROUGHPUT
                                            )}`}
                                        >
                                            <LinearProgress
                                                variant={"determinate"}
                                                sx={{
                                                    ...rootProgressStyle,
                                                    [`& .${linearProgressClasses.bar}`]: {
                                                        backgroundColor: (t: Theme) => alpha(t.palette.secondary.main, 0.5),
                                                    },
                                                }}
                                                value={(p.stats.getAverageTransferThroughput() / maxThroughputValue) * 100}
                                            />
                                        </Tooltip>
                                        <Box display={"flex"} justifyContent={"space-between"}>
                                            <Box display={"flex"}>
                                                <Typography variant={"caption"}>
                                                    {formatKnownDataType(p.stats.getAverageTransferThroughput(), KnownDataType.THROUGHPUT)}
                                                </Typography>
                                                <Typography variant={"caption"} color={"textSecondary"}>
                                                    &nbsp; (Average)
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant={"caption"} color={"textSecondary"}>
                                                    {formatKnownDataType(maxThroughputValue, KnownDataType.THROUGHPUT)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Box pb={2}>
                <Card>
                    <CardContent>
                        <Box pb={2}>
                            <LinearProgress sx={rootProgressStyle} variant={"determinate"} color={"primary"} value={p.stats.getThinPercentage()} />
                        </Box>
                        <Box display={"flex"}>
                            <KeyValuePair
                                label={"Thin Data Ratio"}
                                value={`${formatKnownDataType(p.stats.getThinPercentage(), KnownDataType.PERCENT)} ${
                                    p.isNewStatsMinVersion ? `(${formatKnownDataType(p.stats.getTotalThinData(), KnownDataType.CAPACITY)} Reduced)` : ""
                                }`}
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Box flexGrow={1}>
                <Card sx={{ height: "100%" }}>
                    <CardContent>
                        <Box pb={2}>
                            <LinearProgress
                                sx={{
                                    ...rootProgressStyle,
                                    backgroundColor: (t: Theme) => t.palette.secondary.main,
                                    [`& .${linearProgressClasses.bar}`]: {
                                        backgroundColor: (t: Theme) => t.palette.primary.light,
                                        borderRight: "3px solid white",
                                    },
                                }}
                                variant={"determinate"}
                                value={
                                    p.stats.getTotalBase() + p.stats.getTotalChanged()
                                        ? (p.stats.getTotalBase() / (p.stats.getTotalBase() + p.stats.getTotalChanged())) * 100
                                        : 0
                                }
                            />
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <KeyValuePair label={"Base Data"} value={formatKnownDataType(p.stats.getTotalBase(), KnownDataType.CAPACITY)} />
                            <KeyValuePair label={"Total Changed Data"} value={formatKnownDataType(p.stats.getTotalChanged(), KnownDataType.CAPACITY)} />
                        </Box>
                        <Box pt={3}>
                            <KeyValuePair label={"Current Changed Data"} value={formatKnownDataType(p.stats.getCurrentChanged(), KnownDataType.CAPACITY)} />
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
});

// ======================
// ProgressStatusCard
// ======================
interface ProgressStatusCardProps {
    data: GalaxyMigrateMigrationSessionDetails;
    isNewStatsMinVersion: boolean;
}

const ProgressStatusCard: React.FC<ProgressStatusCardProps> = observer((p) => {
    const sessionInfo = p.data.getSessionInfo();
    const stats = sessionInfo.getSessionStats();

    return (
        <Card sx={{ height: "100%" }}>
            <Box p={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Typography variant={"h4"} align={"center"}>
                    {renderGMSessionStatus(sessionInfo.getSessionStatus())}
                </Typography>
                {sessionInfo.getSessionStatus() === GalaxyMigrateMigrationSessionInfo.Status.IN_ERROR && (
                    <Box pl={1}>
                        <HintButton
                            articleID={KnownArticle.MIGRATION_SESSION_IN_ERROR}
                            icon={<RiQuestionLine />}
                            tooltipTitle={"Learn more about Migration Errors"}
                        />
                    </Box>
                )}
            </Box>
            <Divider />
            <br />
            <Box p={8} pt={1} pb={0} display={"flex"} justifyContent={"center"}>
                <Box maxWidth={240}>
                    <ProgressCircle data={p.data} />
                </Box>
            </Box>
            <br />
            <Typography align={"center"} variant={"h6"}>
                {`${formatKnownDataType(stats.getTotalSynced(), KnownDataType.CAPACITY)} / ${formatKnownDataType(
                    stats.getTotalData(),
                    KnownDataType.CAPACITY
                )}`}
            </Typography>
            <Typography align={"center"} paragraph>
                {"Migrated"}
            </Typography>
            <br />
            <Typography align={"center"} variant={"subtitle2"}>
                {!!sessionInfo.getLastSynchronizedTime()?.getSeconds() ? (
                    <>
                        {"Last sync completed"}{" "}
                        <FormattedDisplay dataType={KnownDataType.DATE_RELATIVE} value={sessionInfo.getLastSynchronizedTime()?.toDate()} />
                    </>
                ) : (
                    "Initial Synchronization"
                )}
            </Typography>
            <br />
            {p.isNewStatsMinVersion && (
                <Typography align={"center"} variant={"subtitle2"}>
                    {"Total Sync Time"} {formatDurationFromSeconds(sessionInfo.getSessionStats()?.getTotalSyncTime()?.getSeconds())}
                </Typography>
            )}

            <br />
        </Card>
    );
});

// ======================
// ProgressCircle
// ======================
interface ProgressCircleProps {
    data: GalaxyMigrateMigrationSessionDetails;
}

const ProgressCircle: React.FC<ProgressCircleProps> = observer((p) => {
    const percentage = p.data.getSessionInfo().getSessionStats().getProgressPercentage();
    const theme = useTheme();
    return (
        <CircularProgressbar
            value={percentage}
            text={`${percentage.toFixed(0)}%`}
            strokeWidth={5}
            styles={buildStyles({
                pathColor: theme.palette.primary.light,
                textColor: theme.palette.primary.light,
            })}
        />
    );
});
