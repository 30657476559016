// Project: GalaxyComplete
// Created: 9/29/20 by sammy
// File: ProjectUsers

import * as React from 'react';
import {Avatar, Button, Card, CardHeader, Theme, Tooltip, Typography, useTheme} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {HubUser} from '../../_proto/galaxycompletepb/apipb/domainpb/user_pb';
import {ProjectMemberInfo} from '../../_proto/galaxycompletepb/apipb/domainpb/project_pb';

import {useAppServices} from "../app/services";


// ======================
// UserAvatar
// ======================
const useUserAvatarStyles = () => {
    const t = useTheme();
    return {
        avatar: {
            backgroundColor: t.palette.secondary.main,
        },
    }

}


interface UserAvatarProps {
    user: HubUser.AsObject;
    tooltip?: boolean;
}

export const UserAvatar: React.FC<UserAvatarProps> = observer((p) => {
    const styles = useUserAvatarStyles();
    const avatar = <Avatar color={'secondary'} sx={styles.avatar} src={`${p.user.avatar}`}>
        {p.user.firstName[0]}{p.user.lastName[0]}
    </Avatar>;

    const userFullName = getUserFullNameFromObject(p.user)

    if (p.tooltip) {
        return <Tooltip arrow title={userFullName}>
            {avatar}
        </Tooltip>;
    } else {
        return avatar;
    }

});


export const renderProjectUserRole = (r: ProjectMemberInfo.Role): React.ReactNode => {

    const text = {
        [ProjectMemberInfo.Role.MONITOR.valueOf()]: 'Monitor',
        [ProjectMemberInfo.Role.OPERATOR.valueOf()]: 'Operator',
        [ProjectMemberInfo.Role.ADMIN.valueOf()]: 'Admin',
    }[r] || '---';
    return text;
};

export const getProjectMemberUserFullName = (r: ProjectMemberInfo): string => {
    return getUserFullName(r.getUser())
};

export const getUserFullName = (u: HubUser): string => {
    if (!u) {
        return null;
    } else {
        return `${u.getFirstName()} ${u.getLastName()}`;
    }
};

export const getUserFullNameFromObject = (u: HubUser.AsObject): string => {
    if (!u) {
        return null;
    } else {
        return `${u.firstName} ${u.lastName}`;
    }
};


