// source: galaxycompletepb/apipb/project_api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var galaxycompletepb_commonpb_datafilter_pb = require('../../galaxycompletepb/commonpb/datafilter_pb.js');
goog.object.extend(proto, galaxycompletepb_commonpb_datafilter_pb);
var galaxycompletepb_apipb_domainpb_activity_pb = require('../../galaxycompletepb/apipb/domainpb/activity_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_activity_pb);
var galaxycompletepb_apipb_domainpb_project_pb = require('../../galaxycompletepb/apipb/domainpb/project_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_project_pb);
var galaxycompletepb_apipb_domainpb_license_pb = require('../../galaxycompletepb/apipb/domainpb/license_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_license_pb);
var galaxycompletepb_apipb_domainpb_managementtag_pb = require('../../galaxycompletepb/apipb/domainpb/managementtag_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_managementtag_pb);
var google_api_annotations_pb = require('../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.galaxycomplete.api.ChangeProjectMemberRole', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ChangeProjectMemberRole.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ChangeProjectMemberRole.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.CreateProject', null, global);
goog.exportSymbol('proto.galaxycomplete.api.CreateProject.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.CreateProject.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.DeleteProject', null, global);
goog.exportSymbol('proto.galaxycomplete.api.DeleteProject.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.DeleteProject.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetMyProjectRole', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetMyProjectRole.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetMyProjectRole.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetProjectDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetProjectDetails.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetProjectDetails.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetProjectLicenseVaultDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.InviteNewProjectMembers', null, global);
goog.exportSymbol('proto.galaxycomplete.api.InviteNewProjectMembers.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.InviteNewProjectMembers.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.LeaveProject', null, global);
goog.exportSymbol('proto.galaxycomplete.api.LeaveProject.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.LeaveProject.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListAllProjectManagementTagNames', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListManagementTags', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListManagementTags.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListManagementTags.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjectActivities', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjectActivities.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjectActivities.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjectLicenseTransactions', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjectLicenseTransactions.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjectLicenseTransactions.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjectMembers', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjectMembers.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjectMembers.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjects', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjects.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjects.Request.Filter', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjects.Request.Filter.FieldCase', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjects.Request.Sort', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjects.Request.Sort.Field', null, global);
goog.exportSymbol('proto.galaxycomplete.api.ListProjects.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.RegenerateRegistrationCode', null, global);
goog.exportSymbol('proto.galaxycomplete.api.RegenerateRegistrationCode.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.RegenerateRegistrationCode.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.RemoveProjectMember', null, global);
goog.exportSymbol('proto.galaxycomplete.api.RemoveProjectMember.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.RemoveProjectMember.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.TransferLicenseFromProject', null, global);
goog.exportSymbol('proto.galaxycomplete.api.TransferLicenseFromProject.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.TransferLicenseFromProject.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.TransferLicenseToProject', null, global);
goog.exportSymbol('proto.galaxycomplete.api.TransferLicenseToProject.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.TransferLicenseToProject.Response', null, global);
goog.exportSymbol('proto.galaxycomplete.api.UpdateProject', null, global);
goog.exportSymbol('proto.galaxycomplete.api.UpdateProject.Request', null, global);
goog.exportSymbol('proto.galaxycomplete.api.UpdateProject.Response', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListProjects = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListProjects, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListProjects.displayName = 'proto.galaxycomplete.api.ListProjects';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListProjects.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.ListProjects.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.ListProjects.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListProjects.Request.displayName = 'proto.galaxycomplete.api.ListProjects.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListProjects.Request.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.galaxycomplete.api.ListProjects.Request.Filter.oneofGroups_);
};
goog.inherits(proto.galaxycomplete.api.ListProjects.Request.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListProjects.Request.Filter.displayName = 'proto.galaxycomplete.api.ListProjects.Request.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListProjects.Request.Sort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListProjects.Request.Sort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListProjects.Request.Sort.displayName = 'proto.galaxycomplete.api.ListProjects.Request.Sort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListProjects.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.ListProjects.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.ListProjects.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListProjects.Response.displayName = 'proto.galaxycomplete.api.ListProjects.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.CreateProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.CreateProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.CreateProject.displayName = 'proto.galaxycomplete.api.CreateProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.CreateProject.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.CreateProject.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.CreateProject.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.CreateProject.Request.displayName = 'proto.galaxycomplete.api.CreateProject.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.CreateProject.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.CreateProject.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.CreateProject.Response.displayName = 'proto.galaxycomplete.api.CreateProject.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.RegenerateRegistrationCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.RegenerateRegistrationCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.RegenerateRegistrationCode.displayName = 'proto.galaxycomplete.api.RegenerateRegistrationCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.RegenerateRegistrationCode.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.RegenerateRegistrationCode.Request.displayName = 'proto.galaxycomplete.api.RegenerateRegistrationCode.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.RegenerateRegistrationCode.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.RegenerateRegistrationCode.Response.displayName = 'proto.galaxycomplete.api.RegenerateRegistrationCode.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.DeleteProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.DeleteProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.DeleteProject.displayName = 'proto.galaxycomplete.api.DeleteProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.DeleteProject.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.DeleteProject.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.DeleteProject.Request.displayName = 'proto.galaxycomplete.api.DeleteProject.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.DeleteProject.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.DeleteProject.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.DeleteProject.Response.displayName = 'proto.galaxycomplete.api.DeleteProject.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetProjectDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetProjectDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetProjectDetails.displayName = 'proto.galaxycomplete.api.GetProjectDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetProjectDetails.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetProjectDetails.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetProjectDetails.Request.displayName = 'proto.galaxycomplete.api.GetProjectDetails.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetProjectDetails.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetProjectDetails.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetProjectDetails.Response.displayName = 'proto.galaxycomplete.api.GetProjectDetails.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.UpdateProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.UpdateProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.UpdateProject.displayName = 'proto.galaxycomplete.api.UpdateProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.UpdateProject.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.UpdateProject.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.UpdateProject.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.UpdateProject.Request.displayName = 'proto.galaxycomplete.api.UpdateProject.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.UpdateProject.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.UpdateProject.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.UpdateProject.Response.displayName = 'proto.galaxycomplete.api.UpdateProject.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetMyProjectRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetMyProjectRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetMyProjectRole.displayName = 'proto.galaxycomplete.api.GetMyProjectRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetMyProjectRole.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetMyProjectRole.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetMyProjectRole.Request.displayName = 'proto.galaxycomplete.api.GetMyProjectRole.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetMyProjectRole.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetMyProjectRole.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetMyProjectRole.Response.displayName = 'proto.galaxycomplete.api.GetMyProjectRole.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.InviteNewProjectMembers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.InviteNewProjectMembers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.InviteNewProjectMembers.displayName = 'proto.galaxycomplete.api.InviteNewProjectMembers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.InviteNewProjectMembers.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.InviteNewProjectMembers.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.InviteNewProjectMembers.Request.displayName = 'proto.galaxycomplete.api.InviteNewProjectMembers.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.InviteNewProjectMembers.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.InviteNewProjectMembers.Response.displayName = 'proto.galaxycomplete.api.InviteNewProjectMembers.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.RemoveProjectMember = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.RemoveProjectMember, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.RemoveProjectMember.displayName = 'proto.galaxycomplete.api.RemoveProjectMember';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.RemoveProjectMember.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.RemoveProjectMember.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.RemoveProjectMember.Request.displayName = 'proto.galaxycomplete.api.RemoveProjectMember.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.RemoveProjectMember.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.RemoveProjectMember.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.RemoveProjectMember.Response.displayName = 'proto.galaxycomplete.api.RemoveProjectMember.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.LeaveProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.LeaveProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.LeaveProject.displayName = 'proto.galaxycomplete.api.LeaveProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.LeaveProject.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.LeaveProject.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.LeaveProject.Request.displayName = 'proto.galaxycomplete.api.LeaveProject.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.LeaveProject.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.LeaveProject.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.LeaveProject.Response.displayName = 'proto.galaxycomplete.api.LeaveProject.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ChangeProjectMemberRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ChangeProjectMemberRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ChangeProjectMemberRole.displayName = 'proto.galaxycomplete.api.ChangeProjectMemberRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ChangeProjectMemberRole.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ChangeProjectMemberRole.Request.displayName = 'proto.galaxycomplete.api.ChangeProjectMemberRole.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ChangeProjectMemberRole.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ChangeProjectMemberRole.Response.displayName = 'proto.galaxycomplete.api.ChangeProjectMemberRole.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListProjectMembers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListProjectMembers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListProjectMembers.displayName = 'proto.galaxycomplete.api.ListProjectMembers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListProjectMembers.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListProjectMembers.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListProjectMembers.Request.displayName = 'proto.galaxycomplete.api.ListProjectMembers.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListProjectMembers.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.ListProjectMembers.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.ListProjectMembers.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListProjectMembers.Response.displayName = 'proto.galaxycomplete.api.ListProjectMembers.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListProjectActivities = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListProjectActivities, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListProjectActivities.displayName = 'proto.galaxycomplete.api.ListProjectActivities';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListProjectActivities.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListProjectActivities.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListProjectActivities.Request.displayName = 'proto.galaxycomplete.api.ListProjectActivities.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListProjectActivities.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.ListProjectActivities.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.ListProjectActivities.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListProjectActivities.Response.displayName = 'proto.galaxycomplete.api.ListProjectActivities.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetProjectLicenseVaultDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetProjectLicenseVaultDetails.displayName = 'proto.galaxycomplete.api.GetProjectLicenseVaultDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request.displayName = 'proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response.displayName = 'proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListProjectLicenseTransactions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListProjectLicenseTransactions.displayName = 'proto.galaxycomplete.api.ListProjectLicenseTransactions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListProjectLicenseTransactions.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.displayName = 'proto.galaxycomplete.api.ListProjectLicenseTransactions.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.ListProjectLicenseTransactions.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.displayName = 'proto.galaxycomplete.api.ListProjectLicenseTransactions.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.TransferLicenseToProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.TransferLicenseToProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.TransferLicenseToProject.displayName = 'proto.galaxycomplete.api.TransferLicenseToProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.TransferLicenseToProject.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.TransferLicenseToProject.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.TransferLicenseToProject.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.TransferLicenseToProject.Request.displayName = 'proto.galaxycomplete.api.TransferLicenseToProject.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.TransferLicenseToProject.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.TransferLicenseToProject.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.TransferLicenseToProject.Response.displayName = 'proto.galaxycomplete.api.TransferLicenseToProject.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.TransferLicenseFromProject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.TransferLicenseFromProject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.TransferLicenseFromProject.displayName = 'proto.galaxycomplete.api.TransferLicenseFromProject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.TransferLicenseFromProject.Request.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.TransferLicenseFromProject.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.TransferLicenseFromProject.Request.displayName = 'proto.galaxycomplete.api.TransferLicenseFromProject.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.TransferLicenseFromProject.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.TransferLicenseFromProject.Response.displayName = 'proto.galaxycomplete.api.TransferLicenseFromProject.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListManagementTags = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListManagementTags, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListManagementTags.displayName = 'proto.galaxycomplete.api.ListManagementTags';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListManagementTags.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListManagementTags.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListManagementTags.Request.displayName = 'proto.galaxycomplete.api.ListManagementTags.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListManagementTags.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.ListManagementTags.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.ListManagementTags.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListManagementTags.Response.displayName = 'proto.galaxycomplete.api.ListManagementTags.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListAllProjectManagementTagNames, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListAllProjectManagementTagNames.displayName = 'proto.galaxycomplete.api.ListAllProjectManagementTagNames';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request.displayName = 'proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.displayName = 'proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListProjects.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListProjects.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListProjects} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjects.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListProjects}
 */
proto.galaxycomplete.api.ListProjects.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListProjects;
  return proto.galaxycomplete.api.ListProjects.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListProjects} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListProjects}
 */
proto.galaxycomplete.api.ListProjects.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListProjects.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListProjects.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListProjects} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjects.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.ListProjects.Request.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListProjects.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListProjects.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListProjects.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjects.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageParams: (f = msg.getPageParams()) && galaxycompletepb_commonpb_datafilter_pb.PagerParams.toObject(includeInstance, f),
    filterParamsList: jspb.Message.toObjectList(msg.getFilterParamsList(),
    proto.galaxycomplete.api.ListProjects.Request.Filter.toObject, includeInstance),
    sortParams: (f = msg.getSortParams()) && proto.galaxycomplete.api.ListProjects.Request.Sort.toObject(includeInstance, f),
    isAdmin: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    tag: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListProjects.Request}
 */
proto.galaxycomplete.api.ListProjects.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListProjects.Request;
  return proto.galaxycomplete.api.ListProjects.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListProjects.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListProjects.Request}
 */
proto.galaxycomplete.api.ListProjects.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerParams;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerParams.deserializeBinaryFromReader);
      msg.setPageParams(value);
      break;
    case 2:
      var value = new proto.galaxycomplete.api.ListProjects.Request.Filter;
      reader.readMessage(value,proto.galaxycomplete.api.ListProjects.Request.Filter.deserializeBinaryFromReader);
      msg.addFilterParams(value);
      break;
    case 3:
      var value = new proto.galaxycomplete.api.ListProjects.Request.Sort;
      reader.readMessage(value,proto.galaxycomplete.api.ListProjects.Request.Sort.deserializeBinaryFromReader);
      msg.setSortParams(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdmin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListProjects.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListProjects.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListProjects.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjects.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageParams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerParams.serializeBinaryToWriter
    );
  }
  f = message.getFilterParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.galaxycomplete.api.ListProjects.Request.Filter.serializeBinaryToWriter
    );
  }
  f = message.getSortParams();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.galaxycomplete.api.ListProjects.Request.Sort.serializeBinaryToWriter
    );
  }
  f = message.getIsAdmin();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTag();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.FieldCase = {
  FIELD_NOT_SET: 0,
  NAME: 1,
  DESCRIPTION: 2,
  CREATION_TIME: 3,
  PROJECT_MEMBER: 4,
  DEPLOYMENT_NAME: 5,
  IS_SUPPORTING: 6
};

/**
 * @return {proto.galaxycomplete.api.ListProjects.Request.Filter.FieldCase}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.getFieldCase = function() {
  return /** @type {proto.galaxycomplete.api.ListProjects.Request.Filter.FieldCase} */(jspb.Message.computeOneofCase(this, proto.galaxycomplete.api.ListProjects.Request.Filter.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListProjects.Request.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListProjects.Request.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && galaxycompletepb_commonpb_datafilter_pb.FilterParams.StringFilter.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && galaxycompletepb_commonpb_datafilter_pb.FilterParams.StringFilter.toObject(includeInstance, f),
    creationTime: (f = msg.getCreationTime()) && galaxycompletepb_commonpb_datafilter_pb.FilterParams.DateFilter.toObject(includeInstance, f),
    projectMember: (f = msg.getProjectMember()) && galaxycompletepb_commonpb_datafilter_pb.FilterParams.SimpleStringFilter.toObject(includeInstance, f),
    deploymentName: (f = msg.getDeploymentName()) && galaxycompletepb_commonpb_datafilter_pb.FilterParams.StringFilter.toObject(includeInstance, f),
    isSupporting: (f = msg.getIsSupporting()) && galaxycompletepb_commonpb_datafilter_pb.FilterParams.BoolFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Filter}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListProjects.Request.Filter;
  return proto.galaxycomplete.api.ListProjects.Request.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListProjects.Request.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Filter}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.FilterParams.StringFilter;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.FilterParams.StringFilter.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new galaxycompletepb_commonpb_datafilter_pb.FilterParams.StringFilter;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.FilterParams.StringFilter.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 3:
      var value = new galaxycompletepb_commonpb_datafilter_pb.FilterParams.DateFilter;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.FilterParams.DateFilter.deserializeBinaryFromReader);
      msg.setCreationTime(value);
      break;
    case 4:
      var value = new galaxycompletepb_commonpb_datafilter_pb.FilterParams.SimpleStringFilter;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.FilterParams.SimpleStringFilter.deserializeBinaryFromReader);
      msg.setProjectMember(value);
      break;
    case 5:
      var value = new galaxycompletepb_commonpb_datafilter_pb.FilterParams.StringFilter;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.FilterParams.StringFilter.deserializeBinaryFromReader);
      msg.setDeploymentName(value);
      break;
    case 6:
      var value = new galaxycompletepb_commonpb_datafilter_pb.FilterParams.BoolFilter;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.FilterParams.BoolFilter.deserializeBinaryFromReader);
      msg.setIsSupporting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListProjects.Request.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListProjects.Request.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.FilterParams.StringFilter.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_commonpb_datafilter_pb.FilterParams.StringFilter.serializeBinaryToWriter
    );
  }
  f = message.getCreationTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      galaxycompletepb_commonpb_datafilter_pb.FilterParams.DateFilter.serializeBinaryToWriter
    );
  }
  f = message.getProjectMember();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      galaxycompletepb_commonpb_datafilter_pb.FilterParams.SimpleStringFilter.serializeBinaryToWriter
    );
  }
  f = message.getDeploymentName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      galaxycompletepb_commonpb_datafilter_pb.FilterParams.StringFilter.serializeBinaryToWriter
    );
  }
  f = message.getIsSupporting();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      galaxycompletepb_commonpb_datafilter_pb.FilterParams.BoolFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.common.FilterParams.StringFilter name = 1;
 * @return {?proto.galaxycomplete.common.FilterParams.StringFilter}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.getName = function() {
  return /** @type{?proto.galaxycomplete.common.FilterParams.StringFilter} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.FilterParams.StringFilter, 1));
};


/**
 * @param {?proto.galaxycomplete.common.FilterParams.StringFilter|undefined} value
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Filter} returns this
*/
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.setName = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.galaxycomplete.api.ListProjects.Request.Filter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Filter} returns this
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional galaxycomplete.common.FilterParams.StringFilter description = 2;
 * @return {?proto.galaxycomplete.common.FilterParams.StringFilter}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.getDescription = function() {
  return /** @type{?proto.galaxycomplete.common.FilterParams.StringFilter} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.FilterParams.StringFilter, 2));
};


/**
 * @param {?proto.galaxycomplete.common.FilterParams.StringFilter|undefined} value
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Filter} returns this
*/
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.setDescription = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.galaxycomplete.api.ListProjects.Request.Filter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Filter} returns this
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional galaxycomplete.common.FilterParams.DateFilter creation_time = 3;
 * @return {?proto.galaxycomplete.common.FilterParams.DateFilter}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.getCreationTime = function() {
  return /** @type{?proto.galaxycomplete.common.FilterParams.DateFilter} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.FilterParams.DateFilter, 3));
};


/**
 * @param {?proto.galaxycomplete.common.FilterParams.DateFilter|undefined} value
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Filter} returns this
*/
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.setCreationTime = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.galaxycomplete.api.ListProjects.Request.Filter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Filter} returns this
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.clearCreationTime = function() {
  return this.setCreationTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.hasCreationTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional galaxycomplete.common.FilterParams.SimpleStringFilter project_member = 4;
 * @return {?proto.galaxycomplete.common.FilterParams.SimpleStringFilter}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.getProjectMember = function() {
  return /** @type{?proto.galaxycomplete.common.FilterParams.SimpleStringFilter} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.FilterParams.SimpleStringFilter, 4));
};


/**
 * @param {?proto.galaxycomplete.common.FilterParams.SimpleStringFilter|undefined} value
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Filter} returns this
*/
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.setProjectMember = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.galaxycomplete.api.ListProjects.Request.Filter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Filter} returns this
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.clearProjectMember = function() {
  return this.setProjectMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.hasProjectMember = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional galaxycomplete.common.FilterParams.StringFilter deployment_name = 5;
 * @return {?proto.galaxycomplete.common.FilterParams.StringFilter}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.getDeploymentName = function() {
  return /** @type{?proto.galaxycomplete.common.FilterParams.StringFilter} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.FilterParams.StringFilter, 5));
};


/**
 * @param {?proto.galaxycomplete.common.FilterParams.StringFilter|undefined} value
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Filter} returns this
*/
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.setDeploymentName = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.galaxycomplete.api.ListProjects.Request.Filter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Filter} returns this
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.clearDeploymentName = function() {
  return this.setDeploymentName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.hasDeploymentName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional galaxycomplete.common.FilterParams.BoolFilter is_supporting = 6;
 * @return {?proto.galaxycomplete.common.FilterParams.BoolFilter}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.getIsSupporting = function() {
  return /** @type{?proto.galaxycomplete.common.FilterParams.BoolFilter} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.FilterParams.BoolFilter, 6));
};


/**
 * @param {?proto.galaxycomplete.common.FilterParams.BoolFilter|undefined} value
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Filter} returns this
*/
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.setIsSupporting = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.galaxycomplete.api.ListProjects.Request.Filter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Filter} returns this
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.clearIsSupporting = function() {
  return this.setIsSupporting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjects.Request.Filter.prototype.hasIsSupporting = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListProjects.Request.Sort.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListProjects.Request.Sort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListProjects.Request.Sort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjects.Request.Sort.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, 0),
    desc: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Sort}
 */
proto.galaxycomplete.api.ListProjects.Request.Sort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListProjects.Request.Sort;
  return proto.galaxycomplete.api.ListProjects.Request.Sort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListProjects.Request.Sort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Sort}
 */
proto.galaxycomplete.api.ListProjects.Request.Sort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.galaxycomplete.api.ListProjects.Request.Sort.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDesc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListProjects.Request.Sort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListProjects.Request.Sort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListProjects.Request.Sort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjects.Request.Sort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDesc();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxycomplete.api.ListProjects.Request.Sort.Field = {
  NAME: 0,
  CREATION_TIME: 1
};

/**
 * optional Field field = 1;
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Sort.Field}
 */
proto.galaxycomplete.api.ListProjects.Request.Sort.prototype.getField = function() {
  return /** @type {!proto.galaxycomplete.api.ListProjects.Request.Sort.Field} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.galaxycomplete.api.ListProjects.Request.Sort.Field} value
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Sort} returns this
 */
proto.galaxycomplete.api.ListProjects.Request.Sort.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool desc = 2;
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjects.Request.Sort.prototype.getDesc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Sort} returns this
 */
proto.galaxycomplete.api.ListProjects.Request.Sort.prototype.setDesc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional galaxycomplete.common.PagerParams page_params = 1;
 * @return {?proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.api.ListProjects.Request.prototype.getPageParams = function() {
  return /** @type{?proto.galaxycomplete.common.PagerParams} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerParams, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerParams|undefined} value
 * @return {!proto.galaxycomplete.api.ListProjects.Request} returns this
*/
proto.galaxycomplete.api.ListProjects.Request.prototype.setPageParams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListProjects.Request} returns this
 */
proto.galaxycomplete.api.ListProjects.Request.prototype.clearPageParams = function() {
  return this.setPageParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjects.Request.prototype.hasPageParams = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Filter filter_params = 2;
 * @return {!Array<!proto.galaxycomplete.api.ListProjects.Request.Filter>}
 */
proto.galaxycomplete.api.ListProjects.Request.prototype.getFilterParamsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.api.ListProjects.Request.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.galaxycomplete.api.ListProjects.Request.Filter, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.api.ListProjects.Request.Filter>} value
 * @return {!proto.galaxycomplete.api.ListProjects.Request} returns this
*/
proto.galaxycomplete.api.ListProjects.Request.prototype.setFilterParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.api.ListProjects.Request.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.ListProjects.Request.Filter}
 */
proto.galaxycomplete.api.ListProjects.Request.prototype.addFilterParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.api.ListProjects.Request.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.ListProjects.Request} returns this
 */
proto.galaxycomplete.api.ListProjects.Request.prototype.clearFilterParamsList = function() {
  return this.setFilterParamsList([]);
};


/**
 * optional Sort sort_params = 3;
 * @return {?proto.galaxycomplete.api.ListProjects.Request.Sort}
 */
proto.galaxycomplete.api.ListProjects.Request.prototype.getSortParams = function() {
  return /** @type{?proto.galaxycomplete.api.ListProjects.Request.Sort} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.api.ListProjects.Request.Sort, 3));
};


/**
 * @param {?proto.galaxycomplete.api.ListProjects.Request.Sort|undefined} value
 * @return {!proto.galaxycomplete.api.ListProjects.Request} returns this
*/
proto.galaxycomplete.api.ListProjects.Request.prototype.setSortParams = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListProjects.Request} returns this
 */
proto.galaxycomplete.api.ListProjects.Request.prototype.clearSortParams = function() {
  return this.setSortParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjects.Request.prototype.hasSortParams = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_admin = 4;
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjects.Request.prototype.getIsAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.ListProjects.Request} returns this
 */
proto.galaxycomplete.api.ListProjects.Request.prototype.setIsAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string tag = 5;
 * @return {string}
 */
proto.galaxycomplete.api.ListProjects.Request.prototype.getTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.ListProjects.Request} returns this
 */
proto.galaxycomplete.api.ListProjects.Request.prototype.setTag = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.ListProjects.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListProjects.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListProjects.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListProjects.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjects.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerMeta: (f = msg.getPagerMeta()) && galaxycompletepb_commonpb_datafilter_pb.PagerMeta.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    galaxycompletepb_apipb_domainpb_project_pb.ProjectListItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListProjects.Response}
 */
proto.galaxycomplete.api.ListProjects.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListProjects.Response;
  return proto.galaxycomplete.api.ListProjects.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListProjects.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListProjects.Response}
 */
proto.galaxycomplete.api.ListProjects.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerMeta;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerMeta.deserializeBinaryFromReader);
      msg.setPagerMeta(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_project_pb.ProjectListItem;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_project_pb.ProjectListItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListProjects.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListProjects.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListProjects.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjects.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerMeta.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_project_pb.ProjectListItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.common.PagerMeta pager_meta = 1;
 * @return {?proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.api.ListProjects.Response.prototype.getPagerMeta = function() {
  return /** @type{?proto.galaxycomplete.common.PagerMeta} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerMeta, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerMeta|undefined} value
 * @return {!proto.galaxycomplete.api.ListProjects.Response} returns this
*/
proto.galaxycomplete.api.ListProjects.Response.prototype.setPagerMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListProjects.Response} returns this
 */
proto.galaxycomplete.api.ListProjects.Response.prototype.clearPagerMeta = function() {
  return this.setPagerMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjects.Response.prototype.hasPagerMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated galaxycomplete.domain.ProjectListItem items = 2;
 * @return {!Array<!proto.galaxycomplete.domain.ProjectListItem>}
 */
proto.galaxycomplete.api.ListProjects.Response.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.ProjectListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_project_pb.ProjectListItem, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.ProjectListItem>} value
 * @return {!proto.galaxycomplete.api.ListProjects.Response} returns this
*/
proto.galaxycomplete.api.ListProjects.Response.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.ProjectListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ProjectListItem}
 */
proto.galaxycomplete.api.ListProjects.Response.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.ProjectListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.ListProjects.Response} returns this
 */
proto.galaxycomplete.api.ListProjects.Response.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.CreateProject.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.CreateProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.CreateProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.CreateProject.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.CreateProject}
 */
proto.galaxycomplete.api.CreateProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.CreateProject;
  return proto.galaxycomplete.api.CreateProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.CreateProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.CreateProject}
 */
proto.galaxycomplete.api.CreateProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.CreateProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.CreateProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.CreateProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.CreateProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.CreateProject.Request.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.CreateProject.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.CreateProject.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.CreateProject.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    emergencyEmail: jspb.Message.getFieldWithDefault(msg, 3, ""),
    emergencyPhone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deleteLock: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.CreateProject.Request}
 */
proto.galaxycomplete.api.CreateProject.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.CreateProject.Request;
  return proto.galaxycomplete.api.CreateProject.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.CreateProject.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.CreateProject.Request}
 */
proto.galaxycomplete.api.CreateProject.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmergencyEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmergencyPhone(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleteLock(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.CreateProject.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.CreateProject.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.CreateProject.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmergencyEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmergencyPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeleteLock();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.CreateProject.Request} returns this
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.CreateProject.Request} returns this
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string emergency_email = 3;
 * @return {string}
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.getEmergencyEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.CreateProject.Request} returns this
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.setEmergencyEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string emergency_phone = 4;
 * @return {string}
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.getEmergencyPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.CreateProject.Request} returns this
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.setEmergencyPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool delete_lock = 5;
 * @return {boolean}
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.getDeleteLock = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.CreateProject.Request} returns this
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.setDeleteLock = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated string tags = 6;
 * @return {!Array<string>}
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.api.CreateProject.Request} returns this
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.CreateProject.Request} returns this
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.CreateProject.Request} returns this
 */
proto.galaxycomplete.api.CreateProject.Request.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.CreateProject.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.CreateProject.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.CreateProject.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.CreateProject.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    newProjectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.CreateProject.Response}
 */
proto.galaxycomplete.api.CreateProject.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.CreateProject.Response;
  return proto.galaxycomplete.api.CreateProject.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.CreateProject.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.CreateProject.Response}
 */
proto.galaxycomplete.api.CreateProject.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.CreateProject.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.CreateProject.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.CreateProject.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.CreateProject.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string new_project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.CreateProject.Response.prototype.getNewProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.CreateProject.Response} returns this
 */
proto.galaxycomplete.api.CreateProject.Response.prototype.setNewProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.RegenerateRegistrationCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.RegenerateRegistrationCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.RegenerateRegistrationCode}
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.RegenerateRegistrationCode;
  return proto.galaxycomplete.api.RegenerateRegistrationCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.RegenerateRegistrationCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.RegenerateRegistrationCode}
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.RegenerateRegistrationCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.RegenerateRegistrationCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.RegenerateRegistrationCode.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.RegenerateRegistrationCode.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.RegenerateRegistrationCode.Request}
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.RegenerateRegistrationCode.Request;
  return proto.galaxycomplete.api.RegenerateRegistrationCode.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.RegenerateRegistrationCode.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.RegenerateRegistrationCode.Request}
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.RegenerateRegistrationCode.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.RegenerateRegistrationCode.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.RegenerateRegistrationCode.Request} returns this
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.RegenerateRegistrationCode.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.RegenerateRegistrationCode.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.RegenerateRegistrationCode.Response}
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.RegenerateRegistrationCode.Response;
  return proto.galaxycomplete.api.RegenerateRegistrationCode.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.RegenerateRegistrationCode.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.RegenerateRegistrationCode.Response}
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.RegenerateRegistrationCode.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.RegenerateRegistrationCode.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Response.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.RegenerateRegistrationCode.Response} returns this
 */
proto.galaxycomplete.api.RegenerateRegistrationCode.Response.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.DeleteProject.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.DeleteProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.DeleteProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.DeleteProject.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.DeleteProject}
 */
proto.galaxycomplete.api.DeleteProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.DeleteProject;
  return proto.galaxycomplete.api.DeleteProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.DeleteProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.DeleteProject}
 */
proto.galaxycomplete.api.DeleteProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.DeleteProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.DeleteProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.DeleteProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.DeleteProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.DeleteProject.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.DeleteProject.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.DeleteProject.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.DeleteProject.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.DeleteProject.Request}
 */
proto.galaxycomplete.api.DeleteProject.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.DeleteProject.Request;
  return proto.galaxycomplete.api.DeleteProject.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.DeleteProject.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.DeleteProject.Request}
 */
proto.galaxycomplete.api.DeleteProject.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.DeleteProject.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.DeleteProject.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.DeleteProject.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.DeleteProject.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.DeleteProject.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.DeleteProject.Request} returns this
 */
proto.galaxycomplete.api.DeleteProject.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.DeleteProject.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.DeleteProject.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.DeleteProject.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.DeleteProject.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.DeleteProject.Response}
 */
proto.galaxycomplete.api.DeleteProject.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.DeleteProject.Response;
  return proto.galaxycomplete.api.DeleteProject.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.DeleteProject.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.DeleteProject.Response}
 */
proto.galaxycomplete.api.DeleteProject.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.DeleteProject.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.DeleteProject.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.DeleteProject.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.DeleteProject.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetProjectDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetProjectDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetProjectDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetProjectDetails.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetProjectDetails}
 */
proto.galaxycomplete.api.GetProjectDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetProjectDetails;
  return proto.galaxycomplete.api.GetProjectDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetProjectDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetProjectDetails}
 */
proto.galaxycomplete.api.GetProjectDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetProjectDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetProjectDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetProjectDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetProjectDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetProjectDetails.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetProjectDetails.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetProjectDetails.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetProjectDetails.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetProjectDetails.Request}
 */
proto.galaxycomplete.api.GetProjectDetails.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetProjectDetails.Request;
  return proto.galaxycomplete.api.GetProjectDetails.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetProjectDetails.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetProjectDetails.Request}
 */
proto.galaxycomplete.api.GetProjectDetails.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetProjectDetails.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetProjectDetails.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetProjectDetails.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetProjectDetails.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.GetProjectDetails.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.GetProjectDetails.Request} returns this
 */
proto.galaxycomplete.api.GetProjectDetails.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetProjectDetails.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetProjectDetails.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetProjectDetails.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetProjectDetails.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectDetails: (f = msg.getProjectDetails()) && galaxycompletepb_apipb_domainpb_project_pb.ProjectDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetProjectDetails.Response}
 */
proto.galaxycomplete.api.GetProjectDetails.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetProjectDetails.Response;
  return proto.galaxycomplete.api.GetProjectDetails.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetProjectDetails.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetProjectDetails.Response}
 */
proto.galaxycomplete.api.GetProjectDetails.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_project_pb.ProjectDetails;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_project_pb.ProjectDetails.deserializeBinaryFromReader);
      msg.setProjectDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetProjectDetails.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetProjectDetails.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetProjectDetails.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetProjectDetails.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_project_pb.ProjectDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.ProjectDetails project_details = 2;
 * @return {?proto.galaxycomplete.domain.ProjectDetails}
 */
proto.galaxycomplete.api.GetProjectDetails.Response.prototype.getProjectDetails = function() {
  return /** @type{?proto.galaxycomplete.domain.ProjectDetails} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_project_pb.ProjectDetails, 2));
};


/**
 * @param {?proto.galaxycomplete.domain.ProjectDetails|undefined} value
 * @return {!proto.galaxycomplete.api.GetProjectDetails.Response} returns this
*/
proto.galaxycomplete.api.GetProjectDetails.Response.prototype.setProjectDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.GetProjectDetails.Response} returns this
 */
proto.galaxycomplete.api.GetProjectDetails.Response.prototype.clearProjectDetails = function() {
  return this.setProjectDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.GetProjectDetails.Response.prototype.hasProjectDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.UpdateProject.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.UpdateProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.UpdateProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.UpdateProject.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.UpdateProject}
 */
proto.galaxycomplete.api.UpdateProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.UpdateProject;
  return proto.galaxycomplete.api.UpdateProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.UpdateProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.UpdateProject}
 */
proto.galaxycomplete.api.UpdateProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.UpdateProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.UpdateProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.UpdateProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.UpdateProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.UpdateProject.Request.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.UpdateProject.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.UpdateProject.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.UpdateProject.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    emergencyEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    emergencyPhone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    deleteLock: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    enableNewHostEmailNotification: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    enableLicenseTransactionEmailNotification: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    allowSupportStaff: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.UpdateProject.Request}
 */
proto.galaxycomplete.api.UpdateProject.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.UpdateProject.Request;
  return proto.galaxycomplete.api.UpdateProject.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.UpdateProject.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.UpdateProject.Request}
 */
proto.galaxycomplete.api.UpdateProject.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmergencyEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmergencyPhone(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleteLock(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableNewHostEmailNotification(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableLicenseTransactionEmailNotification(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowSupportStaff(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.UpdateProject.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.UpdateProject.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.UpdateProject.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmergencyEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmergencyPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeleteLock();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.UpdateProject.Request} returns this
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.UpdateProject.Request} returns this
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.UpdateProject.Request} returns this
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string emergency_email = 4;
 * @return {string}
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.getEmergencyEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.UpdateProject.Request} returns this
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.setEmergencyEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string emergency_phone = 5;
 * @return {string}
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.getEmergencyPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.UpdateProject.Request} returns this
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.setEmergencyPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool delete_lock = 6;
 * @return {boolean}
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.getDeleteLock = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.UpdateProject.Request} returns this
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.setDeleteLock = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool enable_new_host_email_notification = 7;
 * @return {boolean}
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.getEnableNewHostEmailNotification = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.UpdateProject.Request} returns this
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.setEnableNewHostEmailNotification = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.galaxycomplete.api.UpdateProject.Request} returns this
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.clearEnableNewHostEmailNotification = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.hasEnableNewHostEmailNotification = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool enable_license_transaction_email_notification = 8;
 * @return {boolean}
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.getEnableLicenseTransactionEmailNotification = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.UpdateProject.Request} returns this
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.setEnableLicenseTransactionEmailNotification = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.galaxycomplete.api.UpdateProject.Request} returns this
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.clearEnableLicenseTransactionEmailNotification = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.hasEnableLicenseTransactionEmailNotification = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool allow_support_staff = 9;
 * @return {boolean}
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.getAllowSupportStaff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.api.UpdateProject.Request} returns this
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.setAllowSupportStaff = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.galaxycomplete.api.UpdateProject.Request} returns this
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.clearAllowSupportStaff = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.hasAllowSupportStaff = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated string tags = 10;
 * @return {!Array<string>}
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.api.UpdateProject.Request} returns this
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.UpdateProject.Request} returns this
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.UpdateProject.Request} returns this
 */
proto.galaxycomplete.api.UpdateProject.Request.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.UpdateProject.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.UpdateProject.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.UpdateProject.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.UpdateProject.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.UpdateProject.Response}
 */
proto.galaxycomplete.api.UpdateProject.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.UpdateProject.Response;
  return proto.galaxycomplete.api.UpdateProject.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.UpdateProject.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.UpdateProject.Response}
 */
proto.galaxycomplete.api.UpdateProject.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.UpdateProject.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.UpdateProject.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.UpdateProject.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.UpdateProject.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetMyProjectRole.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetMyProjectRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetMyProjectRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetMyProjectRole.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetMyProjectRole}
 */
proto.galaxycomplete.api.GetMyProjectRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetMyProjectRole;
  return proto.galaxycomplete.api.GetMyProjectRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetMyProjectRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetMyProjectRole}
 */
proto.galaxycomplete.api.GetMyProjectRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetMyProjectRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetMyProjectRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetMyProjectRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetMyProjectRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetMyProjectRole.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetMyProjectRole.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetMyProjectRole.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetMyProjectRole.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetMyProjectRole.Request}
 */
proto.galaxycomplete.api.GetMyProjectRole.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetMyProjectRole.Request;
  return proto.galaxycomplete.api.GetMyProjectRole.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetMyProjectRole.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetMyProjectRole.Request}
 */
proto.galaxycomplete.api.GetMyProjectRole.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetMyProjectRole.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetMyProjectRole.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetMyProjectRole.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetMyProjectRole.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.GetMyProjectRole.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.GetMyProjectRole.Request} returns this
 */
proto.galaxycomplete.api.GetMyProjectRole.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetMyProjectRole.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetMyProjectRole.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetMyProjectRole.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetMyProjectRole.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    myRole: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetMyProjectRole.Response}
 */
proto.galaxycomplete.api.GetMyProjectRole.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetMyProjectRole.Response;
  return proto.galaxycomplete.api.GetMyProjectRole.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetMyProjectRole.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetMyProjectRole.Response}
 */
proto.galaxycomplete.api.GetMyProjectRole.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.galaxycomplete.domain.ProjectMemberInfo.Role} */ (reader.readEnum());
      msg.setMyRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetMyProjectRole.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetMyProjectRole.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetMyProjectRole.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetMyProjectRole.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMyRole();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional galaxycomplete.domain.ProjectMemberInfo.Role my_role = 1;
 * @return {!proto.galaxycomplete.domain.ProjectMemberInfo.Role}
 */
proto.galaxycomplete.api.GetMyProjectRole.Response.prototype.getMyRole = function() {
  return /** @type {!proto.galaxycomplete.domain.ProjectMemberInfo.Role} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.ProjectMemberInfo.Role} value
 * @return {!proto.galaxycomplete.api.GetMyProjectRole.Response} returns this
 */
proto.galaxycomplete.api.GetMyProjectRole.Response.prototype.setMyRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.InviteNewProjectMembers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.InviteNewProjectMembers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.InviteNewProjectMembers.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.InviteNewProjectMembers}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.InviteNewProjectMembers;
  return proto.galaxycomplete.api.InviteNewProjectMembers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.InviteNewProjectMembers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.InviteNewProjectMembers}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.InviteNewProjectMembers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.InviteNewProjectMembers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.InviteNewProjectMembers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.InviteNewProjectMembers.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.InviteNewProjectMembers.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    emailsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    role: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.InviteNewProjectMembers.Request}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.InviteNewProjectMembers.Request;
  return proto.galaxycomplete.api.InviteNewProjectMembers.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.InviteNewProjectMembers.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.InviteNewProjectMembers.Request}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addEmails(value);
      break;
    case 3:
      var value = /** @type {!proto.galaxycomplete.domain.ProjectMemberInfo.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.InviteNewProjectMembers.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.InviteNewProjectMembers.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = /** @type {!proto.galaxycomplete.domain.ProjectMemberInfo.Role} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.InviteNewProjectMembers.Request} returns this
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string emails = 2;
 * @return {!Array<string>}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.prototype.getEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.api.InviteNewProjectMembers.Request} returns this
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.prototype.setEmailsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.InviteNewProjectMembers.Request} returns this
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.prototype.addEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.InviteNewProjectMembers.Request} returns this
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.prototype.clearEmailsList = function() {
  return this.setEmailsList([]);
};


/**
 * optional galaxycomplete.domain.ProjectMemberInfo.Role role = 3;
 * @return {!proto.galaxycomplete.domain.ProjectMemberInfo.Role}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.prototype.getRole = function() {
  return /** @type {!proto.galaxycomplete.domain.ProjectMemberInfo.Role} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.ProjectMemberInfo.Role} value
 * @return {!proto.galaxycomplete.api.InviteNewProjectMembers.Request} returns this
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.galaxycomplete.api.InviteNewProjectMembers.Request} returns this
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.prototype.clearRole = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Request.prototype.hasRole = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.InviteNewProjectMembers.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.InviteNewProjectMembers.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.InviteNewProjectMembers.Response}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.InviteNewProjectMembers.Response;
  return proto.galaxycomplete.api.InviteNewProjectMembers.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.InviteNewProjectMembers.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.InviteNewProjectMembers.Response}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.InviteNewProjectMembers.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.InviteNewProjectMembers.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.InviteNewProjectMembers.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.RemoveProjectMember.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.RemoveProjectMember.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.RemoveProjectMember} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveProjectMember.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.RemoveProjectMember}
 */
proto.galaxycomplete.api.RemoveProjectMember.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.RemoveProjectMember;
  return proto.galaxycomplete.api.RemoveProjectMember.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.RemoveProjectMember} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.RemoveProjectMember}
 */
proto.galaxycomplete.api.RemoveProjectMember.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.RemoveProjectMember.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.RemoveProjectMember.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.RemoveProjectMember} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveProjectMember.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.RemoveProjectMember.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.RemoveProjectMember.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.RemoveProjectMember.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveProjectMember.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.RemoveProjectMember.Request}
 */
proto.galaxycomplete.api.RemoveProjectMember.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.RemoveProjectMember.Request;
  return proto.galaxycomplete.api.RemoveProjectMember.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.RemoveProjectMember.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.RemoveProjectMember.Request}
 */
proto.galaxycomplete.api.RemoveProjectMember.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.RemoveProjectMember.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.RemoveProjectMember.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.RemoveProjectMember.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveProjectMember.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.RemoveProjectMember.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.RemoveProjectMember.Request} returns this
 */
proto.galaxycomplete.api.RemoveProjectMember.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 user_id = 2;
 * @return {number}
 */
proto.galaxycomplete.api.RemoveProjectMember.Request.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.RemoveProjectMember.Request} returns this
 */
proto.galaxycomplete.api.RemoveProjectMember.Request.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.RemoveProjectMember.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.RemoveProjectMember.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.RemoveProjectMember.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveProjectMember.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.RemoveProjectMember.Response}
 */
proto.galaxycomplete.api.RemoveProjectMember.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.RemoveProjectMember.Response;
  return proto.galaxycomplete.api.RemoveProjectMember.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.RemoveProjectMember.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.RemoveProjectMember.Response}
 */
proto.galaxycomplete.api.RemoveProjectMember.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.RemoveProjectMember.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.RemoveProjectMember.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.RemoveProjectMember.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.RemoveProjectMember.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.LeaveProject.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.LeaveProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.LeaveProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.LeaveProject.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.LeaveProject}
 */
proto.galaxycomplete.api.LeaveProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.LeaveProject;
  return proto.galaxycomplete.api.LeaveProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.LeaveProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.LeaveProject}
 */
proto.galaxycomplete.api.LeaveProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.LeaveProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.LeaveProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.LeaveProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.LeaveProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.LeaveProject.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.LeaveProject.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.LeaveProject.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.LeaveProject.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.LeaveProject.Request}
 */
proto.galaxycomplete.api.LeaveProject.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.LeaveProject.Request;
  return proto.galaxycomplete.api.LeaveProject.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.LeaveProject.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.LeaveProject.Request}
 */
proto.galaxycomplete.api.LeaveProject.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.LeaveProject.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.LeaveProject.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.LeaveProject.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.LeaveProject.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.LeaveProject.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.LeaveProject.Request} returns this
 */
proto.galaxycomplete.api.LeaveProject.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.LeaveProject.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.LeaveProject.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.LeaveProject.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.LeaveProject.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.LeaveProject.Response}
 */
proto.galaxycomplete.api.LeaveProject.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.LeaveProject.Response;
  return proto.galaxycomplete.api.LeaveProject.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.LeaveProject.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.LeaveProject.Response}
 */
proto.galaxycomplete.api.LeaveProject.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.LeaveProject.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.LeaveProject.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.LeaveProject.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.LeaveProject.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ChangeProjectMemberRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ChangeProjectMemberRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ChangeProjectMemberRole}
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ChangeProjectMemberRole;
  return proto.galaxycomplete.api.ChangeProjectMemberRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ChangeProjectMemberRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ChangeProjectMemberRole}
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ChangeProjectMemberRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ChangeProjectMemberRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ChangeProjectMemberRole.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ChangeProjectMemberRole.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    newRole: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ChangeProjectMemberRole.Request}
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ChangeProjectMemberRole.Request;
  return proto.galaxycomplete.api.ChangeProjectMemberRole.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ChangeProjectMemberRole.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ChangeProjectMemberRole.Request}
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {!proto.galaxycomplete.domain.ProjectMemberInfo.Role} */ (reader.readEnum());
      msg.setNewRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ChangeProjectMemberRole.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ChangeProjectMemberRole.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getNewRole();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.ChangeProjectMemberRole.Request} returns this
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 user_id = 2;
 * @return {number}
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Request.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.api.ChangeProjectMemberRole.Request} returns this
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Request.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional galaxycomplete.domain.ProjectMemberInfo.Role new_role = 3;
 * @return {!proto.galaxycomplete.domain.ProjectMemberInfo.Role}
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Request.prototype.getNewRole = function() {
  return /** @type {!proto.galaxycomplete.domain.ProjectMemberInfo.Role} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.ProjectMemberInfo.Role} value
 * @return {!proto.galaxycomplete.api.ChangeProjectMemberRole.Request} returns this
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Request.prototype.setNewRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ChangeProjectMemberRole.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ChangeProjectMemberRole.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ChangeProjectMemberRole.Response}
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ChangeProjectMemberRole.Response;
  return proto.galaxycomplete.api.ChangeProjectMemberRole.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ChangeProjectMemberRole.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ChangeProjectMemberRole.Response}
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ChangeProjectMemberRole.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ChangeProjectMemberRole.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ChangeProjectMemberRole.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListProjectMembers.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListProjectMembers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListProjectMembers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectMembers.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListProjectMembers}
 */
proto.galaxycomplete.api.ListProjectMembers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListProjectMembers;
  return proto.galaxycomplete.api.ListProjectMembers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListProjectMembers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListProjectMembers}
 */
proto.galaxycomplete.api.ListProjectMembers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListProjectMembers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListProjectMembers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListProjectMembers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectMembers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListProjectMembers.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListProjectMembers.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListProjectMembers.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectMembers.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageParams: (f = msg.getPageParams()) && galaxycompletepb_commonpb_datafilter_pb.PagerParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListProjectMembers.Request}
 */
proto.galaxycomplete.api.ListProjectMembers.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListProjectMembers.Request;
  return proto.galaxycomplete.api.ListProjectMembers.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListProjectMembers.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListProjectMembers.Request}
 */
proto.galaxycomplete.api.ListProjectMembers.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerParams;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerParams.deserializeBinaryFromReader);
      msg.setPageParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListProjectMembers.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListProjectMembers.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListProjectMembers.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectMembers.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.ListProjectMembers.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.ListProjectMembers.Request} returns this
 */
proto.galaxycomplete.api.ListProjectMembers.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.common.PagerParams page_params = 2;
 * @return {?proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.api.ListProjectMembers.Request.prototype.getPageParams = function() {
  return /** @type{?proto.galaxycomplete.common.PagerParams} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerParams, 2));
};


/**
 * @param {?proto.galaxycomplete.common.PagerParams|undefined} value
 * @return {!proto.galaxycomplete.api.ListProjectMembers.Request} returns this
*/
proto.galaxycomplete.api.ListProjectMembers.Request.prototype.setPageParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListProjectMembers.Request} returns this
 */
proto.galaxycomplete.api.ListProjectMembers.Request.prototype.clearPageParams = function() {
  return this.setPageParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjectMembers.Request.prototype.hasPageParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.ListProjectMembers.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListProjectMembers.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListProjectMembers.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListProjectMembers.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectMembers.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerMeta: (f = msg.getPagerMeta()) && galaxycompletepb_commonpb_datafilter_pb.PagerMeta.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    galaxycompletepb_apipb_domainpb_project_pb.ProjectMemberInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListProjectMembers.Response}
 */
proto.galaxycomplete.api.ListProjectMembers.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListProjectMembers.Response;
  return proto.galaxycomplete.api.ListProjectMembers.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListProjectMembers.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListProjectMembers.Response}
 */
proto.galaxycomplete.api.ListProjectMembers.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerMeta;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerMeta.deserializeBinaryFromReader);
      msg.setPagerMeta(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_project_pb.ProjectMemberInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_project_pb.ProjectMemberInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListProjectMembers.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListProjectMembers.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListProjectMembers.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectMembers.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerMeta.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_project_pb.ProjectMemberInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.common.PagerMeta pager_meta = 1;
 * @return {?proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.api.ListProjectMembers.Response.prototype.getPagerMeta = function() {
  return /** @type{?proto.galaxycomplete.common.PagerMeta} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerMeta, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerMeta|undefined} value
 * @return {!proto.galaxycomplete.api.ListProjectMembers.Response} returns this
*/
proto.galaxycomplete.api.ListProjectMembers.Response.prototype.setPagerMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListProjectMembers.Response} returns this
 */
proto.galaxycomplete.api.ListProjectMembers.Response.prototype.clearPagerMeta = function() {
  return this.setPagerMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjectMembers.Response.prototype.hasPagerMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated galaxycomplete.domain.ProjectMemberInfo items = 2;
 * @return {!Array<!proto.galaxycomplete.domain.ProjectMemberInfo>}
 */
proto.galaxycomplete.api.ListProjectMembers.Response.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.ProjectMemberInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_project_pb.ProjectMemberInfo, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.ProjectMemberInfo>} value
 * @return {!proto.galaxycomplete.api.ListProjectMembers.Response} returns this
*/
proto.galaxycomplete.api.ListProjectMembers.Response.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.ProjectMemberInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ProjectMemberInfo}
 */
proto.galaxycomplete.api.ListProjectMembers.Response.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.ProjectMemberInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.ListProjectMembers.Response} returns this
 */
proto.galaxycomplete.api.ListProjectMembers.Response.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListProjectActivities.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListProjectActivities.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListProjectActivities} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectActivities.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListProjectActivities}
 */
proto.galaxycomplete.api.ListProjectActivities.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListProjectActivities;
  return proto.galaxycomplete.api.ListProjectActivities.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListProjectActivities} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListProjectActivities}
 */
proto.galaxycomplete.api.ListProjectActivities.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListProjectActivities.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListProjectActivities.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListProjectActivities} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectActivities.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListProjectActivities.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListProjectActivities.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListProjectActivities.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectActivities.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageParams: (f = msg.getPageParams()) && galaxycompletepb_commonpb_datafilter_pb.PagerParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListProjectActivities.Request}
 */
proto.galaxycomplete.api.ListProjectActivities.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListProjectActivities.Request;
  return proto.galaxycomplete.api.ListProjectActivities.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListProjectActivities.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListProjectActivities.Request}
 */
proto.galaxycomplete.api.ListProjectActivities.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerParams;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerParams.deserializeBinaryFromReader);
      msg.setPageParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListProjectActivities.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListProjectActivities.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListProjectActivities.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectActivities.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.ListProjectActivities.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.ListProjectActivities.Request} returns this
 */
proto.galaxycomplete.api.ListProjectActivities.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.common.PagerParams page_params = 2;
 * @return {?proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.api.ListProjectActivities.Request.prototype.getPageParams = function() {
  return /** @type{?proto.galaxycomplete.common.PagerParams} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerParams, 2));
};


/**
 * @param {?proto.galaxycomplete.common.PagerParams|undefined} value
 * @return {!proto.galaxycomplete.api.ListProjectActivities.Request} returns this
*/
proto.galaxycomplete.api.ListProjectActivities.Request.prototype.setPageParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListProjectActivities.Request} returns this
 */
proto.galaxycomplete.api.ListProjectActivities.Request.prototype.clearPageParams = function() {
  return this.setPageParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjectActivities.Request.prototype.hasPageParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.ListProjectActivities.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListProjectActivities.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListProjectActivities.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListProjectActivities.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectActivities.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerMeta: (f = msg.getPagerMeta()) && galaxycompletepb_commonpb_datafilter_pb.PagerMeta.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    galaxycompletepb_apipb_domainpb_activity_pb.ActivityInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListProjectActivities.Response}
 */
proto.galaxycomplete.api.ListProjectActivities.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListProjectActivities.Response;
  return proto.galaxycomplete.api.ListProjectActivities.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListProjectActivities.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListProjectActivities.Response}
 */
proto.galaxycomplete.api.ListProjectActivities.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerMeta;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerMeta.deserializeBinaryFromReader);
      msg.setPagerMeta(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_activity_pb.ActivityInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_activity_pb.ActivityInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListProjectActivities.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListProjectActivities.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListProjectActivities.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectActivities.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerMeta.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_activity_pb.ActivityInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.common.PagerMeta pager_meta = 1;
 * @return {?proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.api.ListProjectActivities.Response.prototype.getPagerMeta = function() {
  return /** @type{?proto.galaxycomplete.common.PagerMeta} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerMeta, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerMeta|undefined} value
 * @return {!proto.galaxycomplete.api.ListProjectActivities.Response} returns this
*/
proto.galaxycomplete.api.ListProjectActivities.Response.prototype.setPagerMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListProjectActivities.Response} returns this
 */
proto.galaxycomplete.api.ListProjectActivities.Response.prototype.clearPagerMeta = function() {
  return this.setPagerMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjectActivities.Response.prototype.hasPagerMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated galaxycomplete.domain.ActivityInfo items = 2;
 * @return {!Array<!proto.galaxycomplete.domain.ActivityInfo>}
 */
proto.galaxycomplete.api.ListProjectActivities.Response.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.ActivityInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_activity_pb.ActivityInfo, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.ActivityInfo>} value
 * @return {!proto.galaxycomplete.api.ListProjectActivities.Response} returns this
*/
proto.galaxycomplete.api.ListProjectActivities.Response.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.ActivityInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ActivityInfo}
 */
proto.galaxycomplete.api.ListProjectActivities.Response.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.ActivityInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.ListProjectActivities.Response} returns this
 */
proto.galaxycomplete.api.ListProjectActivities.Response.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetProjectLicenseVaultDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails}
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetProjectLicenseVaultDetails;
  return proto.galaxycomplete.api.GetProjectLicenseVaultDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails}
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetProjectLicenseVaultDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request}
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request;
  return proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request}
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request} returns this
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    vaultDetails: (f = msg.getVaultDetails()) && galaxycompletepb_apipb_domainpb_license_pb.VaultDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response}
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response;
  return proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response}
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_license_pb.VaultDetails;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_license_pb.VaultDetails.deserializeBinaryFromReader);
      msg.setVaultDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVaultDetails();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_license_pb.VaultDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.domain.VaultDetails vault_details = 1;
 * @return {?proto.galaxycomplete.domain.VaultDetails}
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response.prototype.getVaultDetails = function() {
  return /** @type{?proto.galaxycomplete.domain.VaultDetails} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_license_pb.VaultDetails, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.VaultDetails|undefined} value
 * @return {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response} returns this
*/
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response.prototype.setVaultDetails = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response} returns this
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response.prototype.clearVaultDetails = function() {
  return this.setVaultDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.GetProjectLicenseVaultDetails.Response.prototype.hasVaultDetails = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListProjectLicenseTransactions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListProjectLicenseTransactions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListProjectLicenseTransactions}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListProjectLicenseTransactions;
  return proto.galaxycomplete.api.ListProjectLicenseTransactions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListProjectLicenseTransactions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListProjectLicenseTransactions}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListProjectLicenseTransactions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListProjectLicenseTransactions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageParams: (f = msg.getPageParams()) && galaxycompletepb_commonpb_datafilter_pb.PagerParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Request}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListProjectLicenseTransactions.Request;
  return proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Request}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerParams;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerParams.deserializeBinaryFromReader);
      msg.setPageParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Request} returns this
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.common.PagerParams page_params = 2;
 * @return {?proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.prototype.getPageParams = function() {
  return /** @type{?proto.galaxycomplete.common.PagerParams} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerParams, 2));
};


/**
 * @param {?proto.galaxycomplete.common.PagerParams|undefined} value
 * @return {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Request} returns this
*/
proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.prototype.setPageParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Request} returns this
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.prototype.clearPageParams = function() {
  return this.setPageParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Request.prototype.hasPageParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerMeta: (f = msg.getPagerMeta()) && galaxycompletepb_commonpb_datafilter_pb.PagerMeta.toObject(includeInstance, f),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    galaxycompletepb_apipb_domainpb_license_pb.TransactionDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Response}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListProjectLicenseTransactions.Response;
  return proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Response}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerMeta;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerMeta.deserializeBinaryFromReader);
      msg.setPagerMeta(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_license_pb.TransactionDetails;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_license_pb.TransactionDetails.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerMeta.serializeBinaryToWriter
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_license_pb.TransactionDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.common.PagerMeta pager_meta = 1;
 * @return {?proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.prototype.getPagerMeta = function() {
  return /** @type{?proto.galaxycomplete.common.PagerMeta} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerMeta, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerMeta|undefined} value
 * @return {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Response} returns this
*/
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.prototype.setPagerMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Response} returns this
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.prototype.clearPagerMeta = function() {
  return this.setPagerMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.prototype.hasPagerMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated galaxycomplete.domain.TransactionDetails items = 2;
 * @return {!Array<!proto.galaxycomplete.domain.TransactionDetails>}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.TransactionDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_license_pb.TransactionDetails, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.TransactionDetails>} value
 * @return {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Response} returns this
*/
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.TransactionDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.TransactionDetails}
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.TransactionDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.ListProjectLicenseTransactions.Response} returns this
 */
proto.galaxycomplete.api.ListProjectLicenseTransactions.Response.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.TransferLicenseToProject.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.TransferLicenseToProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.TransferLicenseToProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TransferLicenseToProject.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.TransferLicenseToProject}
 */
proto.galaxycomplete.api.TransferLicenseToProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.TransferLicenseToProject;
  return proto.galaxycomplete.api.TransferLicenseToProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.TransferLicenseToProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.TransferLicenseToProject}
 */
proto.galaxycomplete.api.TransferLicenseToProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.TransferLicenseToProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.TransferLicenseToProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.TransferLicenseToProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TransferLicenseToProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.TransferLicenseToProject.Request.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.TransferLicenseToProject.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.TransferLicenseToProject.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.TransferLicenseToProject.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TransferLicenseToProject.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    countersList: jspb.Message.toObjectList(msg.getCountersList(),
    galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.TransferLicenseToProject.Request}
 */
proto.galaxycomplete.api.TransferLicenseToProject.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.TransferLicenseToProject.Request;
  return proto.galaxycomplete.api.TransferLicenseToProject.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.TransferLicenseToProject.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.TransferLicenseToProject.Request}
 */
proto.galaxycomplete.api.TransferLicenseToProject.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo.deserializeBinaryFromReader);
      msg.addCounters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.TransferLicenseToProject.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.TransferLicenseToProject.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.TransferLicenseToProject.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TransferLicenseToProject.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.TransferLicenseToProject.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.TransferLicenseToProject.Request} returns this
 */
proto.galaxycomplete.api.TransferLicenseToProject.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.galaxycomplete.api.TransferLicenseToProject.Request.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.TransferLicenseToProject.Request} returns this
 */
proto.galaxycomplete.api.TransferLicenseToProject.Request.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated galaxycomplete.domain.TransactionCounterInfo counters = 3;
 * @return {!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>}
 */
proto.galaxycomplete.api.TransferLicenseToProject.Request.prototype.getCountersList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo, 3));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>} value
 * @return {!proto.galaxycomplete.api.TransferLicenseToProject.Request} returns this
*/
proto.galaxycomplete.api.TransferLicenseToProject.Request.prototype.setCountersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.galaxycomplete.domain.TransactionCounterInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.TransactionCounterInfo}
 */
proto.galaxycomplete.api.TransferLicenseToProject.Request.prototype.addCounters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.galaxycomplete.domain.TransactionCounterInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.TransferLicenseToProject.Request} returns this
 */
proto.galaxycomplete.api.TransferLicenseToProject.Request.prototype.clearCountersList = function() {
  return this.setCountersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.TransferLicenseToProject.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.TransferLicenseToProject.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.TransferLicenseToProject.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TransferLicenseToProject.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.TransferLicenseToProject.Response}
 */
proto.galaxycomplete.api.TransferLicenseToProject.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.TransferLicenseToProject.Response;
  return proto.galaxycomplete.api.TransferLicenseToProject.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.TransferLicenseToProject.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.TransferLicenseToProject.Response}
 */
proto.galaxycomplete.api.TransferLicenseToProject.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.TransferLicenseToProject.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.TransferLicenseToProject.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.TransferLicenseToProject.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TransferLicenseToProject.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.TransferLicenseFromProject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.TransferLicenseFromProject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TransferLicenseFromProject.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.TransferLicenseFromProject}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.TransferLicenseFromProject;
  return proto.galaxycomplete.api.TransferLicenseFromProject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.TransferLicenseFromProject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.TransferLicenseFromProject}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.TransferLicenseFromProject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.TransferLicenseFromProject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TransferLicenseFromProject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Request.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.TransferLicenseFromProject.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.TransferLicenseFromProject.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    countersList: jspb.Message.toObjectList(msg.getCountersList(),
    galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.TransferLicenseFromProject.Request}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.TransferLicenseFromProject.Request;
  return proto.galaxycomplete.api.TransferLicenseFromProject.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.TransferLicenseFromProject.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.TransferLicenseFromProject.Request}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo.deserializeBinaryFromReader);
      msg.addCounters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.TransferLicenseFromProject.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.TransferLicenseFromProject.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.TransferLicenseFromProject.Request} returns this
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Request.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.TransferLicenseFromProject.Request} returns this
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Request.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated galaxycomplete.domain.TransactionCounterInfo counters = 3;
 * @return {!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Request.prototype.getCountersList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_license_pb.TransactionCounterInfo, 3));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.TransactionCounterInfo>} value
 * @return {!proto.galaxycomplete.api.TransferLicenseFromProject.Request} returns this
*/
proto.galaxycomplete.api.TransferLicenseFromProject.Request.prototype.setCountersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.galaxycomplete.domain.TransactionCounterInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.TransactionCounterInfo}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Request.prototype.addCounters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.galaxycomplete.domain.TransactionCounterInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.TransferLicenseFromProject.Request} returns this
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Request.prototype.clearCountersList = function() {
  return this.setCountersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.TransferLicenseFromProject.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.TransferLicenseFromProject.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Response.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.TransferLicenseFromProject.Response}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.TransferLicenseFromProject.Response;
  return proto.galaxycomplete.api.TransferLicenseFromProject.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.TransferLicenseFromProject.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.TransferLicenseFromProject.Response}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.TransferLicenseFromProject.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.TransferLicenseFromProject.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.TransferLicenseFromProject.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListManagementTags.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListManagementTags.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListManagementTags} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListManagementTags.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListManagementTags}
 */
proto.galaxycomplete.api.ListManagementTags.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListManagementTags;
  return proto.galaxycomplete.api.ListManagementTags.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListManagementTags} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListManagementTags}
 */
proto.galaxycomplete.api.ListManagementTags.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListManagementTags.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListManagementTags.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListManagementTags} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListManagementTags.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListManagementTags.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListManagementTags.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListManagementTags.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListManagementTags.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageParams: (f = msg.getPageParams()) && galaxycompletepb_commonpb_datafilter_pb.PagerParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListManagementTags.Request}
 */
proto.galaxycomplete.api.ListManagementTags.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListManagementTags.Request;
  return proto.galaxycomplete.api.ListManagementTags.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListManagementTags.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListManagementTags.Request}
 */
proto.galaxycomplete.api.ListManagementTags.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerParams;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerParams.deserializeBinaryFromReader);
      msg.setPageParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListManagementTags.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListManagementTags.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListManagementTags.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListManagementTags.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.api.ListManagementTags.Request.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.api.ListManagementTags.Request} returns this
 */
proto.galaxycomplete.api.ListManagementTags.Request.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional galaxycomplete.common.PagerParams page_params = 2;
 * @return {?proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.api.ListManagementTags.Request.prototype.getPageParams = function() {
  return /** @type{?proto.galaxycomplete.common.PagerParams} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerParams, 2));
};


/**
 * @param {?proto.galaxycomplete.common.PagerParams|undefined} value
 * @return {!proto.galaxycomplete.api.ListManagementTags.Request} returns this
*/
proto.galaxycomplete.api.ListManagementTags.Request.prototype.setPageParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListManagementTags.Request} returns this
 */
proto.galaxycomplete.api.ListManagementTags.Request.prototype.clearPageParams = function() {
  return this.setPageParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListManagementTags.Request.prototype.hasPageParams = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.ListManagementTags.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListManagementTags.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListManagementTags.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListManagementTags.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListManagementTags.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerMeta: (f = msg.getPagerMeta()) && galaxycompletepb_commonpb_datafilter_pb.PagerMeta.toObject(includeInstance, f),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    galaxycompletepb_apipb_domainpb_managementtag_pb.ManagementTagInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListManagementTags.Response}
 */
proto.galaxycomplete.api.ListManagementTags.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListManagementTags.Response;
  return proto.galaxycomplete.api.ListManagementTags.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListManagementTags.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListManagementTags.Response}
 */
proto.galaxycomplete.api.ListManagementTags.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerMeta;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerMeta.deserializeBinaryFromReader);
      msg.setPagerMeta(value);
      break;
    case 2:
      var value = new galaxycompletepb_apipb_domainpb_managementtag_pb.ManagementTagInfo;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_managementtag_pb.ManagementTagInfo.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListManagementTags.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListManagementTags.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListManagementTags.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListManagementTags.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerMeta.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      galaxycompletepb_apipb_domainpb_managementtag_pb.ManagementTagInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.common.PagerMeta pager_meta = 1;
 * @return {?proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.api.ListManagementTags.Response.prototype.getPagerMeta = function() {
  return /** @type{?proto.galaxycomplete.common.PagerMeta} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerMeta, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerMeta|undefined} value
 * @return {!proto.galaxycomplete.api.ListManagementTags.Response} returns this
*/
proto.galaxycomplete.api.ListManagementTags.Response.prototype.setPagerMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListManagementTags.Response} returns this
 */
proto.galaxycomplete.api.ListManagementTags.Response.prototype.clearPagerMeta = function() {
  return this.setPagerMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListManagementTags.Response.prototype.hasPagerMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated galaxycomplete.domain.ManagementTagInfo tags = 2;
 * @return {!Array<!proto.galaxycomplete.domain.ManagementTagInfo>}
 */
proto.galaxycomplete.api.ListManagementTags.Response.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.galaxycomplete.domain.ManagementTagInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, galaxycompletepb_apipb_domainpb_managementtag_pb.ManagementTagInfo, 2));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.ManagementTagInfo>} value
 * @return {!proto.galaxycomplete.api.ListManagementTags.Response} returns this
*/
proto.galaxycomplete.api.ListManagementTags.Response.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.galaxycomplete.domain.ManagementTagInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ManagementTagInfo}
 */
proto.galaxycomplete.api.ListManagementTags.Response.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.galaxycomplete.domain.ManagementTagInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.ListManagementTags.Response} returns this
 */
proto.galaxycomplete.api.ListManagementTags.Response.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListAllProjectManagementTagNames.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListAllProjectManagementTagNames} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListAllProjectManagementTagNames}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListAllProjectManagementTagNames;
  return proto.galaxycomplete.api.ListAllProjectManagementTagNames.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListAllProjectManagementTagNames} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListAllProjectManagementTagNames}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListAllProjectManagementTagNames.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListAllProjectManagementTagNames} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageParams: (f = msg.getPageParams()) && galaxycompletepb_commonpb_datafilter_pb.PagerParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request;
  return proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerParams;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerParams.deserializeBinaryFromReader);
      msg.setPageParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageParams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional galaxycomplete.common.PagerParams page_params = 1;
 * @return {?proto.galaxycomplete.common.PagerParams}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request.prototype.getPageParams = function() {
  return /** @type{?proto.galaxycomplete.common.PagerParams} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerParams, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerParams|undefined} value
 * @return {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request} returns this
*/
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request.prototype.setPageParams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request} returns this
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request.prototype.clearPageParams = function() {
  return this.setPageParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Request.prototype.hasPageParams = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagerMeta: (f = msg.getPagerMeta()) && galaxycompletepb_commonpb_datafilter_pb.PagerMeta.toObject(includeInstance, f),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response;
  return proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_commonpb_datafilter_pb.PagerMeta;
      reader.readMessage(value,galaxycompletepb_commonpb_datafilter_pb.PagerMeta.deserializeBinaryFromReader);
      msg.setPagerMeta(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagerMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_commonpb_datafilter_pb.PagerMeta.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional galaxycomplete.common.PagerMeta pager_meta = 1;
 * @return {?proto.galaxycomplete.common.PagerMeta}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.prototype.getPagerMeta = function() {
  return /** @type{?proto.galaxycomplete.common.PagerMeta} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_commonpb_datafilter_pb.PagerMeta, 1));
};


/**
 * @param {?proto.galaxycomplete.common.PagerMeta|undefined} value
 * @return {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response} returns this
*/
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.prototype.setPagerMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response} returns this
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.prototype.clearPagerMeta = function() {
  return this.setPagerMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.prototype.hasPagerMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string tags = 2;
 * @return {!Array<string>}
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response} returns this
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response} returns this
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response} returns this
 */
proto.galaxycomplete.api.ListAllProjectManagementTagNames.Response.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


goog.object.extend(exports, proto.galaxycomplete.api);
