import {observer} from "mobx-react-lite";
import {useAppServices} from "../../modules/app/services";
import {BasicNotification} from "./NotificationComponents";
import React from "react";

// ======================
// NotificationViews
// ======================


interface NotificationViewsProps{
}

export const NotificationViews: React.FC<NotificationViewsProps> = observer((props)=>{
    const { notificationService } = useAppServices();

    return <>
        {notificationService.notifications.map((n,i)=>{
            return <BasicNotification config={n} key={i}/>
        })}
        </>
})