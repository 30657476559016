import { observer } from "mobx-react-lite";
import { CardProps, Card, Theme, alpha, Box, SvgIcon, Typography, Button, Alert, useTheme } from "@mui/material";
import React from "react";
import { MdCheckCircle, MdOutlineStorage } from "react-icons/md";
import { ActionConfig } from "../actions/CommonActions";
import { QuickTipButton } from "../../modules/help/HelpCommon";

// ======================
// SelectableBox
// ======================

interface SelectableBoxProps {
    selected: boolean;
    onSelect?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    disabled?: boolean;
    disableHoverEffects?: boolean;
    darkBorder?: boolean;
    checkmark?: boolean;
}

export const SelectableBox: React.FC<SelectableBoxProps & Partial<CardProps>> = observer((p) => {
    const { selected, onSelect, sx, disabled, darkBorder, checkmark, ...props } = p;

    return (
        <Card
            sx={{
                backgroundColor: "rgba(0,0,0,0)",
                border: "2px solid",
                borderColor: (t: Theme) => (selected ? t.palette.primary.main : darkBorder ? "rgba(255, 255, 255, .2)" : alpha("#fff", 0.2)),
                "&:hover":
                    disabled || p.disableHoverEffects
                        ? {}
                        : {
                              backgroundColor: (t: Theme) => alpha(t.palette.primary.main, 0.1),
                              borderColor: (t: Theme) => t.palette.primary.main,
                              cursor: "pointer",
                          },
                "&:active":
                    disabled || p.disableHoverEffects
                        ? {}
                        : {
                              backgroundColor: (t: Theme) => alpha(t.palette.primary.main, 0.2),
                              borderColor: (t: Theme) => t.palette.primary.main,
                              cursor: "pointer",
                          },
                ...sx,
            }}
            {...props}
            elevation={0}
            onClick={disabled ? () => {} : onSelect}
        >
            {p.children}
            {!!checkmark && (
                <Box display={"flex"} justifyContent={"center"} pb={4}>
                    <SvgIcon
                        sx={{
                            color: selected
                                ? (t: Theme) => t.palette.primary.main
                                : darkBorder
                                ? "rgba(255, 255, 255, .2)"
                                : (t: Theme) => t.palette.cirrus.light,
                        }}
                    >
                        <MdCheckCircle />
                    </SvgIcon>
                </Box>
            )}
        </Card>
    );
});

// ======================
// SelectableCard
// ======================

export interface SelectableCardProps extends SelectableBoxProps {
    icon?: React.ReactNode;
    title?: string;
    description?: string | React.ReactNode;
    tip?: React.ReactNode;
    actions?: ActionConfig[];
    warning?: string;
    chipLabel?: React.ReactNode;
    alert?: string;
    checkmark?: boolean;
    darkBorder?: boolean;
    header?: string | React.ReactNode;
}

export const SelectableCard: React.FC<SelectableCardProps & Partial<CardProps>> = observer((p) => {
    const { selected, onSelect, actions, disabled, tip, warning, description, title, icon, chipLabel, alert, checkmark, darkBorder, header, ...props } = p;

    const theme = useTheme();

    return (
        <SelectableBox onSelect={onSelect} selected={selected} disabled={disabled} darkBorder={darkBorder} checkmark={checkmark} {...props}>
            <Box display={"flex"} flexDirection={"column"} justifyContent={header ? "flex-start" : "center"} height={"100%"}>
                {chipLabel && (
                    <Box p={2} sx={{ opacity: disabled ? 0.2 : 1 }}>
                        {chipLabel}
                    </Box>
                )}
                {header && (
                    <Box sx={{ backgroundColor: theme.palette.cirrus.light, borderBottom: `2px solid ${alpha("#fff", 0.1)}` }} p={1}>
                        <Typography textAlign={"center"}>{header}</Typography>
                    </Box>
                )}
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} p={4} flexGrow={1}>
                    <Box textAlign={"center"}>
                        <Box sx={{ opacity: disabled ? 0.1 : 1 }}>{icon}</Box>
                        <Box p={2}>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                <Typography
                                    variant={"h6"}
                                    sx={{
                                        color: (t: Theme) => (disabled ? t.palette.cirrus.light : "white"),
                                    }}
                                >
                                    {p.title}
                                </Typography>
                                {tip && (
                                    <>
                                        <Typography>&nbsp; &nbsp;</Typography>
                                        <QuickTipButton title={tip} sx={{ opacity: disabled ? 0.2 : 1 }} disabled={disabled} />
                                    </>
                                )}
                            </Box>
                            <Typography
                                sx={{
                                    color: (t: Theme) => (disabled ? t.palette.cirrus.light : t.palette.text.secondary),
                                }}
                            >
                                {description}
                            </Typography>
                        </Box>
                        {actions && (
                            <Box sx={{ opacity: disabled ? 0.2 : 1 }}>
                                {actions.map((a) => (
                                    <Button
                                        {...a.buttonProps}
                                        key={a.id}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            a.action();
                                        }}
                                        disabled={disabled || a.disabled}
                                    >
                                        {a.name}
                                    </Button>
                                ))}
                            </Box>
                        )}
                        {warning && (
                            <Box pt={2}>
                                <Typography sx={{ color: (t: Theme) => (disabled ? t.palette.cirrus.light : t.palette.warning.main) }}>{warning}</Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
                {alert && (
                    <Box p={2}>
                        <Alert severity={"info"}>{alert}</Alert>
                    </Box>
                )}
            </Box>
        </SelectableBox>
    );
});
