// source: galaxycompletepb/apipb/domainpb/project.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var galaxycompletepb_apipb_domainpb_enumpb_license_model_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/license_model_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_license_model_pb);
var galaxycompletepb_apipb_domainpb_user_pb = require('../../../galaxycompletepb/apipb/domainpb/user_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_user_pb);
var galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_deployment_host_environment_pb);
var galaxycompletepb_apipb_domainpb_enumpb_project_member_role_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/project_member_role_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_project_member_role_pb);
var galaxycompletepb_apipb_domainpb_enumpb_mtdi_os_type_pb = require('../../../galaxycompletepb/apipb/domainpb/enumpb/mtdi_os_type_pb.js');
goog.object.extend(proto, galaxycompletepb_apipb_domainpb_enumpb_mtdi_os_type_pb);
goog.exportSymbol('proto.galaxycomplete.domain.ProjectDetails', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ProjectIdentityInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ProjectInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ProjectListItem', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ProjectMemberInfo', null, global);
goog.exportSymbol('proto.galaxycomplete.domain.ProjectMemberInfo.Role', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ProjectInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.ProjectInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ProjectInfo.displayName = 'proto.galaxycomplete.domain.ProjectInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ProjectListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.galaxycomplete.domain.ProjectListItem.repeatedFields_, null);
};
goog.inherits(proto.galaxycomplete.domain.ProjectListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ProjectListItem.displayName = 'proto.galaxycomplete.domain.ProjectListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ProjectDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.ProjectDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ProjectDetails.displayName = 'proto.galaxycomplete.domain.ProjectDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ProjectIdentityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.ProjectIdentityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ProjectIdentityInfo.displayName = 'proto.galaxycomplete.domain.ProjectIdentityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.galaxycomplete.domain.ProjectMemberInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.galaxycomplete.domain.ProjectMemberInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.galaxycomplete.domain.ProjectMemberInfo.displayName = 'proto.galaxycomplete.domain.ProjectMemberInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ProjectInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ProjectInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ProjectInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    licenseModel: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 10, ""),
    description: jspb.Message.getFieldWithDefault(msg, 11, ""),
    systemRegistrationCode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    emergencyPhone: jspb.Message.getFieldWithDefault(msg, 20, ""),
    emergencyEmail: jspb.Message.getFieldWithDefault(msg, 21, ""),
    deleteLock: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    enableNewHostEmailNotification: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    enableLicenseTransactionEmailNotification: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    allowSupportStaff: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    premiumSupportExpiration: (f = msg.getPremiumSupportExpiration()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ProjectInfo}
 */
proto.galaxycomplete.domain.ProjectInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ProjectInfo;
  return proto.galaxycomplete.domain.ProjectInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ProjectInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ProjectInfo}
 */
proto.galaxycomplete.domain.ProjectInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {!proto.galaxycomplete.domain.enums.LicenseModel.LicenseModel} */ (reader.readEnum());
      msg.setLicenseModel(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemRegistrationCode(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmergencyPhone(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmergencyEmail(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleteLock(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableNewHostEmailNotification(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableLicenseTransactionEmailNotification(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowSupportStaff(value);
      break;
    case 27:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPremiumSupportExpiration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ProjectInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ProjectInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ProjectInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLicenseModel();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSystemRegistrationCode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getEmergencyPhone();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getEmergencyEmail();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getDeleteLock();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getEnableNewHostEmailNotification();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getEnableLicenseTransactionEmailNotification();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getAllowSupportStaff();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getPremiumSupportExpiration();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ProjectInfo} returns this
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.ProjectInfo} returns this
*/
proto.galaxycomplete.domain.ProjectInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ProjectInfo} returns this
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional enums.LicenseModel.LicenseModel license_model = 3;
 * @return {!proto.galaxycomplete.domain.enums.LicenseModel.LicenseModel}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.getLicenseModel = function() {
  return /** @type {!proto.galaxycomplete.domain.enums.LicenseModel.LicenseModel} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.enums.LicenseModel.LicenseModel} value
 * @return {!proto.galaxycomplete.domain.ProjectInfo} returns this
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.setLicenseModel = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string name = 10;
 * @return {string}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ProjectInfo} returns this
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string description = 11;
 * @return {string}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ProjectInfo} returns this
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string system_registration_code = 12;
 * @return {string}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.getSystemRegistrationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ProjectInfo} returns this
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.setSystemRegistrationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string emergency_phone = 20;
 * @return {string}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.getEmergencyPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ProjectInfo} returns this
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.setEmergencyPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string emergency_email = 21;
 * @return {string}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.getEmergencyEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ProjectInfo} returns this
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.setEmergencyEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional bool delete_lock = 22;
 * @return {boolean}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.getDeleteLock = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.ProjectInfo} returns this
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.setDeleteLock = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool enable_new_host_email_notification = 23;
 * @return {boolean}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.getEnableNewHostEmailNotification = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.ProjectInfo} returns this
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.setEnableNewHostEmailNotification = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional bool enable_license_transaction_email_notification = 24;
 * @return {boolean}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.getEnableLicenseTransactionEmailNotification = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.ProjectInfo} returns this
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.setEnableLicenseTransactionEmailNotification = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional bool allow_support_staff = 25;
 * @return {boolean}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.getAllowSupportStaff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.ProjectInfo} returns this
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.setAllowSupportStaff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional google.protobuf.Timestamp premium_support_expiration = 27;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.getPremiumSupportExpiration = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 27));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.galaxycomplete.domain.ProjectInfo} returns this
*/
proto.galaxycomplete.domain.ProjectInfo.prototype.setPremiumSupportExpiration = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ProjectInfo} returns this
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.clearPremiumSupportExpiration = function() {
  return this.setPremiumSupportExpiration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ProjectInfo.prototype.hasPremiumSupportExpiration = function() {
  return jspb.Message.getField(this, 27) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.galaxycomplete.domain.ProjectListItem.repeatedFields_ = [53,54];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ProjectListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ProjectListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ProjectListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectInfo: (f = msg.getProjectInfo()) && proto.galaxycomplete.domain.ProjectInfo.toObject(includeInstance, f),
    totalMembers: jspb.Message.getFieldWithDefault(msg, 50, 0),
    totalDeployments: jspb.Message.getFieldWithDefault(msg, 51, 0),
    totalGmDeployments: jspb.Message.getFieldWithDefault(msg, 52, 0),
    deploymentHostEnvironmentsList: (f = jspb.Message.getRepeatedField(msg, 53)) == null ? undefined : f,
    gmOsTypesList: (f = jspb.Message.getRepeatedField(msg, 54)) == null ? undefined : f,
    myProjectRole: jspb.Message.getFieldWithDefault(msg, 55, 0),
    totalOfflineDeployments: jspb.Message.getFieldWithDefault(msg, 56, 0),
    asSupportStaff: jspb.Message.getBooleanFieldWithDefault(msg, 57, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ProjectListItem}
 */
proto.galaxycomplete.domain.ProjectListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ProjectListItem;
  return proto.galaxycomplete.domain.ProjectListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ProjectListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ProjectListItem}
 */
proto.galaxycomplete.domain.ProjectListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.ProjectInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.ProjectInfo.deserializeBinaryFromReader);
      msg.setProjectInfo(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalMembers(value);
      break;
    case 51:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalDeployments(value);
      break;
    case 52:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalGmDeployments(value);
      break;
    case 53:
      var values = /** @type {!Array<!proto.galaxycomplete.domain.enums.DeploymentHostEnvironment.DeploymentHostEnvironment>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDeploymentHostEnvironments(values[i]);
      }
      break;
    case 54:
      var values = /** @type {!Array<!proto.galaxycomplete.domain.enums.MtdiOsType.MtdiOsType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addGmOsTypes(values[i]);
      }
      break;
    case 55:
      var value = /** @type {!proto.galaxycomplete.domain.enums.ProjectMemberRole.ProjectMemberRole} */ (reader.readEnum());
      msg.setMyProjectRole(value);
      break;
    case 56:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalOfflineDeployments(value);
      break;
    case 57:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAsSupportStaff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ProjectListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ProjectListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ProjectListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.domain.ProjectInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalMembers();
  if (f !== 0) {
    writer.writeUint32(
      50,
      f
    );
  }
  f = message.getTotalDeployments();
  if (f !== 0) {
    writer.writeUint32(
      51,
      f
    );
  }
  f = message.getTotalGmDeployments();
  if (f !== 0) {
    writer.writeUint32(
      52,
      f
    );
  }
  f = message.getDeploymentHostEnvironmentsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      53,
      f
    );
  }
  f = message.getGmOsTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      54,
      f
    );
  }
  f = message.getMyProjectRole();
  if (f !== 0.0) {
    writer.writeEnum(
      55,
      f
    );
  }
  f = message.getTotalOfflineDeployments();
  if (f !== 0) {
    writer.writeUint32(
      56,
      f
    );
  }
  f = message.getAsSupportStaff();
  if (f) {
    writer.writeBool(
      57,
      f
    );
  }
};


/**
 * optional ProjectInfo project_info = 1;
 * @return {?proto.galaxycomplete.domain.ProjectInfo}
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.getProjectInfo = function() {
  return /** @type{?proto.galaxycomplete.domain.ProjectInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.ProjectInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.ProjectInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.ProjectListItem} returns this
*/
proto.galaxycomplete.domain.ProjectListItem.prototype.setProjectInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ProjectListItem} returns this
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.clearProjectInfo = function() {
  return this.setProjectInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.hasProjectInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 total_members = 50;
 * @return {number}
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.getTotalMembers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.ProjectListItem} returns this
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.setTotalMembers = function(value) {
  return jspb.Message.setProto3IntField(this, 50, value);
};


/**
 * optional uint32 total_deployments = 51;
 * @return {number}
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.getTotalDeployments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 51, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.ProjectListItem} returns this
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.setTotalDeployments = function(value) {
  return jspb.Message.setProto3IntField(this, 51, value);
};


/**
 * optional uint32 total_gm_deployments = 52;
 * @return {number}
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.getTotalGmDeployments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 52, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.ProjectListItem} returns this
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.setTotalGmDeployments = function(value) {
  return jspb.Message.setProto3IntField(this, 52, value);
};


/**
 * repeated enums.DeploymentHostEnvironment.DeploymentHostEnvironment deployment_host_environments = 53;
 * @return {!Array<!proto.galaxycomplete.domain.enums.DeploymentHostEnvironment.DeploymentHostEnvironment>}
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.getDeploymentHostEnvironmentsList = function() {
  return /** @type {!Array<!proto.galaxycomplete.domain.enums.DeploymentHostEnvironment.DeploymentHostEnvironment>} */ (jspb.Message.getRepeatedField(this, 53));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.enums.DeploymentHostEnvironment.DeploymentHostEnvironment>} value
 * @return {!proto.galaxycomplete.domain.ProjectListItem} returns this
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.setDeploymentHostEnvironmentsList = function(value) {
  return jspb.Message.setField(this, 53, value || []);
};


/**
 * @param {!proto.galaxycomplete.domain.enums.DeploymentHostEnvironment.DeploymentHostEnvironment} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ProjectListItem} returns this
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.addDeploymentHostEnvironments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 53, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.ProjectListItem} returns this
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.clearDeploymentHostEnvironmentsList = function() {
  return this.setDeploymentHostEnvironmentsList([]);
};


/**
 * repeated enums.MtdiOsType.MtdiOsType gm_os_types = 54;
 * @return {!Array<!proto.galaxycomplete.domain.enums.MtdiOsType.MtdiOsType>}
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.getGmOsTypesList = function() {
  return /** @type {!Array<!proto.galaxycomplete.domain.enums.MtdiOsType.MtdiOsType>} */ (jspb.Message.getRepeatedField(this, 54));
};


/**
 * @param {!Array<!proto.galaxycomplete.domain.enums.MtdiOsType.MtdiOsType>} value
 * @return {!proto.galaxycomplete.domain.ProjectListItem} returns this
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.setGmOsTypesList = function(value) {
  return jspb.Message.setField(this, 54, value || []);
};


/**
 * @param {!proto.galaxycomplete.domain.enums.MtdiOsType.MtdiOsType} value
 * @param {number=} opt_index
 * @return {!proto.galaxycomplete.domain.ProjectListItem} returns this
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.addGmOsTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 54, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.galaxycomplete.domain.ProjectListItem} returns this
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.clearGmOsTypesList = function() {
  return this.setGmOsTypesList([]);
};


/**
 * optional enums.ProjectMemberRole.ProjectMemberRole my_project_role = 55;
 * @return {!proto.galaxycomplete.domain.enums.ProjectMemberRole.ProjectMemberRole}
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.getMyProjectRole = function() {
  return /** @type {!proto.galaxycomplete.domain.enums.ProjectMemberRole.ProjectMemberRole} */ (jspb.Message.getFieldWithDefault(this, 55, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.enums.ProjectMemberRole.ProjectMemberRole} value
 * @return {!proto.galaxycomplete.domain.ProjectListItem} returns this
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.setMyProjectRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 55, value);
};


/**
 * optional uint32 total_offline_deployments = 56;
 * @return {number}
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.getTotalOfflineDeployments = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 56, 0));
};


/**
 * @param {number} value
 * @return {!proto.galaxycomplete.domain.ProjectListItem} returns this
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.setTotalOfflineDeployments = function(value) {
  return jspb.Message.setProto3IntField(this, 56, value);
};


/**
 * optional bool as_support_staff = 57;
 * @return {boolean}
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.getAsSupportStaff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 57, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.ProjectListItem} returns this
 */
proto.galaxycomplete.domain.ProjectListItem.prototype.setAsSupportStaff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 57, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ProjectDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ProjectDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ProjectDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ProjectDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.galaxycomplete.domain.ProjectInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ProjectDetails}
 */
proto.galaxycomplete.domain.ProjectDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ProjectDetails;
  return proto.galaxycomplete.domain.ProjectDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ProjectDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ProjectDetails}
 */
proto.galaxycomplete.domain.ProjectDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.galaxycomplete.domain.ProjectInfo;
      reader.readMessage(value,proto.galaxycomplete.domain.ProjectInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ProjectDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ProjectDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ProjectDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ProjectDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.galaxycomplete.domain.ProjectInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProjectInfo info = 1;
 * @return {?proto.galaxycomplete.domain.ProjectInfo}
 */
proto.galaxycomplete.domain.ProjectDetails.prototype.getInfo = function() {
  return /** @type{?proto.galaxycomplete.domain.ProjectInfo} */ (
    jspb.Message.getWrapperField(this, proto.galaxycomplete.domain.ProjectInfo, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.ProjectInfo|undefined} value
 * @return {!proto.galaxycomplete.domain.ProjectDetails} returns this
*/
proto.galaxycomplete.domain.ProjectDetails.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ProjectDetails} returns this
 */
proto.galaxycomplete.domain.ProjectDetails.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ProjectDetails.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ProjectIdentityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ProjectIdentityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ProjectIdentityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ProjectIdentityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ProjectIdentityInfo}
 */
proto.galaxycomplete.domain.ProjectIdentityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ProjectIdentityInfo;
  return proto.galaxycomplete.domain.ProjectIdentityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ProjectIdentityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ProjectIdentityInfo}
 */
proto.galaxycomplete.domain.ProjectIdentityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ProjectIdentityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ProjectIdentityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ProjectIdentityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ProjectIdentityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string project_id = 1;
 * @return {string}
 */
proto.galaxycomplete.domain.ProjectIdentityInfo.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ProjectIdentityInfo} returns this
 */
proto.galaxycomplete.domain.ProjectIdentityInfo.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.galaxycomplete.domain.ProjectIdentityInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.galaxycomplete.domain.ProjectIdentityInfo} returns this
 */
proto.galaxycomplete.domain.ProjectIdentityInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.galaxycomplete.domain.ProjectMemberInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.galaxycomplete.domain.ProjectMemberInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.galaxycomplete.domain.ProjectMemberInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ProjectMemberInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && galaxycompletepb_apipb_domainpb_user_pb.HubUser.toObject(includeInstance, f),
    role: jspb.Message.getFieldWithDefault(msg, 2, 0),
    asSupportStaff: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.galaxycomplete.domain.ProjectMemberInfo}
 */
proto.galaxycomplete.domain.ProjectMemberInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.galaxycomplete.domain.ProjectMemberInfo;
  return proto.galaxycomplete.domain.ProjectMemberInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.galaxycomplete.domain.ProjectMemberInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.galaxycomplete.domain.ProjectMemberInfo}
 */
proto.galaxycomplete.domain.ProjectMemberInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new galaxycompletepb_apipb_domainpb_user_pb.HubUser;
      reader.readMessage(value,galaxycompletepb_apipb_domainpb_user_pb.HubUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {!proto.galaxycomplete.domain.ProjectMemberInfo.Role} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAsSupportStaff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.galaxycomplete.domain.ProjectMemberInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.galaxycomplete.domain.ProjectMemberInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.galaxycomplete.domain.ProjectMemberInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.galaxycomplete.domain.ProjectMemberInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      galaxycompletepb_apipb_domainpb_user_pb.HubUser.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAsSupportStaff();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.galaxycomplete.domain.ProjectMemberInfo.Role = {
  MONITOR: 0,
  OPERATOR: 1,
  ADMIN: 2
};

/**
 * optional HubUser user = 1;
 * @return {?proto.galaxycomplete.domain.HubUser}
 */
proto.galaxycomplete.domain.ProjectMemberInfo.prototype.getUser = function() {
  return /** @type{?proto.galaxycomplete.domain.HubUser} */ (
    jspb.Message.getWrapperField(this, galaxycompletepb_apipb_domainpb_user_pb.HubUser, 1));
};


/**
 * @param {?proto.galaxycomplete.domain.HubUser|undefined} value
 * @return {!proto.galaxycomplete.domain.ProjectMemberInfo} returns this
*/
proto.galaxycomplete.domain.ProjectMemberInfo.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.galaxycomplete.domain.ProjectMemberInfo} returns this
 */
proto.galaxycomplete.domain.ProjectMemberInfo.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.galaxycomplete.domain.ProjectMemberInfo.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Role role = 2;
 * @return {!proto.galaxycomplete.domain.ProjectMemberInfo.Role}
 */
proto.galaxycomplete.domain.ProjectMemberInfo.prototype.getRole = function() {
  return /** @type {!proto.galaxycomplete.domain.ProjectMemberInfo.Role} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.galaxycomplete.domain.ProjectMemberInfo.Role} value
 * @return {!proto.galaxycomplete.domain.ProjectMemberInfo} returns this
 */
proto.galaxycomplete.domain.ProjectMemberInfo.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool as_support_staff = 3;
 * @return {boolean}
 */
proto.galaxycomplete.domain.ProjectMemberInfo.prototype.getAsSupportStaff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.galaxycomplete.domain.ProjectMemberInfo} returns this
 */
proto.galaxycomplete.domain.ProjectMemberInfo.prototype.setAsSupportStaff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


goog.object.extend(exports, proto.galaxycomplete.domain);
