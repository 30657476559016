// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: ProjectDetailsScreen

import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {Navigate, useParams, useRoutes} from 'react-router-dom';
import {useAppServices} from '../app/services';
import {ScreenContainer} from '../layout/ScreenCommon';
import {renderServerDataWithLoadingBox, useInitData} from '../core/data/DataLoaderHooks';
import {useCurrentProject} from './CurrentProject';
import {APP_ROUTES, PROJECT_SUBROUTE} from '../app/AppRoutes';
import {ProjectDashboardScreens} from '../dashboard/ProjectDashboardScreens';
import {ProjectMigrationsScreens} from '../migration/ProjectMigrationsScreens';
import {ProjectReportsListScreen, ProjectReportsScreens} from '../reports/ProjectReportsScreen';
import {ProjectManagementScreens} from '../management/ProjectManagementScreens';
import {ProjectSettingsScreens} from '../settings/ProjectSettingsScreens';
import {OperatorView} from "../auth/AuthenticatedViews";
import {ProjectIntegrationsScreens} from "../integrations/ProjectIntegrationsScreens";
import {ProjectProtectionScreens} from "../protection/ProjectProtectionScreens";
import {ProjectDeploymentsScreens} from "../deployment/MigrationDeploymentScreens";
import {
    PrivateEditionNotActivatedWarning,
} from "../license/LicenseCommon";
import {Alert, Box} from "@mui/material";

// ======================
// ProjectDetailsScreen
// ======================
interface ProjectDetailsScreenProps {
}

export const ProjectDetailsScreen: React.FC<ProjectDetailsScreenProps> = observer((p) => {
    const {projectId} = useParams();
    const {projectService, deploymentService, gmDeploymentService, gmMigrationService, projectManagementService, licenseService} = useAppServices();
    const init = () => {
        deploymentService.galaxyMigrateDeployments.resetData();
        gmDeploymentService.galaxyMigrateLinks.resetData();
        gmMigrationService.sessions.resetData();
        projectManagementService.projectMembers.resetData();
    }

    useInitData({
        init: init,
        pollInterval: 30,
        poll: async () => {
            await projectService.currentProject.fetchData(projectId);
            await projectService.myProjectRole.fetchData(projectId);
            await licenseService.projectLicenseDetails.fetchData(projectId);
        },
    });
    return (<>
        {renderServerDataWithLoadingBox(projectService.currentProject, (currentProject) => {
            return <>
                <PrivateEditionNotActivatedWarning/>
                <ProjectDetailsRoutes/>
            </>;
        })}
    </>);
});

// ======================
// ProjectDetailsRoutes
// ======================
interface ProjectDetailsRoutesProps {
}

const ProjectDetailsRoutes: React.FC<ProjectDetailsRoutesProps> = observer((p) => {
    const currentProject = useCurrentProject();

    // sub routes will be implemented underneath
    return useRoutes([
        {
            path: '/', element: <Navigate to={PROJECT_SUBROUTE.MANAGEMENT} replace/>
        },
        {
            path: PROJECT_SUBROUTE.MANAGEMENT + '*', element: <ProjectManagementScreens project={currentProject}/>,
        },
        {
            path: PROJECT_SUBROUTE.DASHBOARD + '*', element: <ProjectDashboardScreens project={currentProject}/>,
        },
        {
            path: PROJECT_SUBROUTE.DEPLOYMENTS + '*', element: <ProjectDeploymentsScreens project={currentProject}/>
        },
        {
            path: PROJECT_SUBROUTE.MIGRATIONS + '*', element: <ProjectMigrationsScreens project={currentProject}/>,
        },
        {
            path: PROJECT_SUBROUTE.PROTECTION + '*', element: <ProjectProtectionScreens project={currentProject}/>,
        },
        {
            path: PROJECT_SUBROUTE.REPORTS + '*', element: <ProjectReportsScreens/>,
        },
        {
            path: PROJECT_SUBROUTE.INTEGRATIONS + '*', element: <ProjectIntegrationsScreens project={currentProject}/>,
        },
        {
            path: PROJECT_SUBROUTE.SETTINGS + '*', element: <ProjectSettingsScreens project={currentProject}/>,
        },
    ]);
});