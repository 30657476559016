import {CirrusProtectDeploymentInfo} from "../../../_proto/galaxycompletepb/apipb/domainpb/cirrusprotect_pb";
import React from "react";
import {observer} from "mobx-react-lite";
import {Box, Card, Grid, Typography, Button} from "@mui/material";
import {OperatorView} from "../../auth/AuthenticatedViews";
import {useAppServices} from "../../app/services";
import {useParams} from "react-router-dom";
import { useNavigateToCpDeploymentsList } from "../ProjectProtectionCommon";
import {PhoenixDeploymentInfo} from "../../../_proto/galaxycompletepb/apipb/domainpb/phoenix_pb";

// ======================
// CirrusProtectDeploymentSettingsSection
// ======================


interface CirrusProtectDeploymentSettingsSectionProps {
    data: CirrusProtectDeploymentInfo;
}

export const CirrusProtectDeploymentSettingsSection: React.FC<CirrusProtectDeploymentSettingsSectionProps> = observer((p) => {

    return <Box pt={2}>
        <DeregisterDeploymentCard pairedDeployment={p.data.getRegisteredPhoenixDeployment()}/>
    </Box>
});

// ======================
// CirrusProtectSoftwareUpgradeCard
// ======================

interface CirrusProtectSoftwareUpgradeCardProps {
}

const CirrusProtectSoftwareUpgradeCard: React.FC<CirrusProtectSoftwareUpgradeCardProps> = observer((p) => {

    return <Card>
        <Box p={2}>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                    <Typography variant={'h6'}>
                        Cirrus Protect Software Upgrade
                    </Typography>
                    <Typography color={'textSecondary'}>
                        You can upgrade software online without interrupting protection
                    </Typography>
                </Grid>
                <Grid item>
                    <Box display={'flex'} pt={2} pb={2} justifyContent={'center'}>
                        <Box pr={1}>
                            <Button variant={'outlined'} color={'primary'}>Check For Upgrade</Button>
                        </Box>
                        <Box pl={1}>
                            <Button variant={'outlined'} color={'neutral'}>View Last Upgrade Status</Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Card>
});

// ======================
// DeregisterDeploymentCard
// ======================

interface DeregisterDeploymentCardProps {
    pairedDeployment: PhoenixDeploymentInfo;
}

const DeregisterDeploymentCard: React.FC<DeregisterDeploymentCardProps> = observer((p) => {
    const { dialogService, cpDeploymentService } = useAppServices();
    const { deploymentId } = useParams();
    const goBackToDeployments = useNavigateToCpDeploymentsList();

    const unpairHost = async() => {
        const confirmed = await dialogService.addConfirmDialog({
            message:'Are you sure  you want to unpair this host from protection target?',
            autoConfirmationQuestionLine: false
        })
        if (confirmed){
            const success = await cpDeploymentService.unpairCpDeploymentFromPhoenix(deploymentId);
            if (success){
                await dialogService.addAlertDialog({
                    title: 'Successfully Unpaired',
                    message: 'The host was successfully unpaired from the protection target.'
                })
            }
        }
    }

    if (!!p.pairedDeployment){
        return <OperatorView>
            <Card>
                <Box p={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box>
                        <Typography variant={'h6'}>Unpair Host</Typography>
                        <Typography color={'textSecondary'}>
                            Unpair the host from the protection target.
                        </Typography>
                    </Box>
                    <Button variant={'outlined'} color={'error'} onClick={unpairHost}>Unpair Protection Target</Button>
                </Box>
            </Card>
        </OperatorView>
    }
    return null;

})
