/**
 * @fileoverview gRPC-Web generated client stub for galaxycomplete.api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: galaxycompletepb/apipb/workflow_api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as galaxycompletepb_apipb_workflow_api_pb from '../../galaxycompletepb/apipb/workflow_api_pb';


export class WorkflowServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetWorkflowDetails = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.WorkflowService/GetWorkflowDetails',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_workflow_api_pb.GetWorkflowDetails.Request,
    galaxycompletepb_apipb_workflow_api_pb.GetWorkflowDetails.Response,
    (request: galaxycompletepb_apipb_workflow_api_pb.GetWorkflowDetails.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_workflow_api_pb.GetWorkflowDetails.Response.deserializeBinary
  );

  getWorkflowDetails(
    request: galaxycompletepb_apipb_workflow_api_pb.GetWorkflowDetails.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_workflow_api_pb.GetWorkflowDetails.Response>;

  getWorkflowDetails(
    request: galaxycompletepb_apipb_workflow_api_pb.GetWorkflowDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_workflow_api_pb.GetWorkflowDetails.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_workflow_api_pb.GetWorkflowDetails.Response>;

  getWorkflowDetails(
    request: galaxycompletepb_apipb_workflow_api_pb.GetWorkflowDetails.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_workflow_api_pb.GetWorkflowDetails.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.WorkflowService/GetWorkflowDetails',
        request,
        metadata || {},
        this.methodDescriptorGetWorkflowDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.WorkflowService/GetWorkflowDetails',
    request,
    metadata || {},
    this.methodDescriptorGetWorkflowDetails);
  }

  methodDescriptorListWorkflowRuns = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.WorkflowService/ListWorkflowRuns',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_workflow_api_pb.ListWorkflowRuns.Request,
    galaxycompletepb_apipb_workflow_api_pb.ListWorkflowRuns.Response,
    (request: galaxycompletepb_apipb_workflow_api_pb.ListWorkflowRuns.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_workflow_api_pb.ListWorkflowRuns.Response.deserializeBinary
  );

  listWorkflowRuns(
    request: galaxycompletepb_apipb_workflow_api_pb.ListWorkflowRuns.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_workflow_api_pb.ListWorkflowRuns.Response>;

  listWorkflowRuns(
    request: galaxycompletepb_apipb_workflow_api_pb.ListWorkflowRuns.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_workflow_api_pb.ListWorkflowRuns.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_workflow_api_pb.ListWorkflowRuns.Response>;

  listWorkflowRuns(
    request: galaxycompletepb_apipb_workflow_api_pb.ListWorkflowRuns.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_workflow_api_pb.ListWorkflowRuns.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.WorkflowService/ListWorkflowRuns',
        request,
        metadata || {},
        this.methodDescriptorListWorkflowRuns,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.WorkflowService/ListWorkflowRuns',
    request,
    metadata || {},
    this.methodDescriptorListWorkflowRuns);
  }

  methodDescriptorGetLatestWorkflowRun = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.WorkflowService/GetLatestWorkflowRun',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_workflow_api_pb.GetLatestWorkflowRun.Request,
    galaxycompletepb_apipb_workflow_api_pb.GetLatestWorkflowRun.Response,
    (request: galaxycompletepb_apipb_workflow_api_pb.GetLatestWorkflowRun.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_workflow_api_pb.GetLatestWorkflowRun.Response.deserializeBinary
  );

  getLatestWorkflowRun(
    request: galaxycompletepb_apipb_workflow_api_pb.GetLatestWorkflowRun.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_workflow_api_pb.GetLatestWorkflowRun.Response>;

  getLatestWorkflowRun(
    request: galaxycompletepb_apipb_workflow_api_pb.GetLatestWorkflowRun.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_workflow_api_pb.GetLatestWorkflowRun.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_workflow_api_pb.GetLatestWorkflowRun.Response>;

  getLatestWorkflowRun(
    request: galaxycompletepb_apipb_workflow_api_pb.GetLatestWorkflowRun.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_workflow_api_pb.GetLatestWorkflowRun.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.WorkflowService/GetLatestWorkflowRun',
        request,
        metadata || {},
        this.methodDescriptorGetLatestWorkflowRun,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.WorkflowService/GetLatestWorkflowRun',
    request,
    metadata || {},
    this.methodDescriptorGetLatestWorkflowRun);
  }

  methodDescriptorGetWorkflowRun = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.WorkflowService/GetWorkflowRun',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_workflow_api_pb.GetWorkflowRun.Request,
    galaxycompletepb_apipb_workflow_api_pb.GetWorkflowRun.Response,
    (request: galaxycompletepb_apipb_workflow_api_pb.GetWorkflowRun.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_workflow_api_pb.GetWorkflowRun.Response.deserializeBinary
  );

  getWorkflowRun(
    request: galaxycompletepb_apipb_workflow_api_pb.GetWorkflowRun.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_workflow_api_pb.GetWorkflowRun.Response>;

  getWorkflowRun(
    request: galaxycompletepb_apipb_workflow_api_pb.GetWorkflowRun.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_workflow_api_pb.GetWorkflowRun.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_workflow_api_pb.GetWorkflowRun.Response>;

  getWorkflowRun(
    request: galaxycompletepb_apipb_workflow_api_pb.GetWorkflowRun.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_workflow_api_pb.GetWorkflowRun.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.WorkflowService/GetWorkflowRun',
        request,
        metadata || {},
        this.methodDescriptorGetWorkflowRun,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.WorkflowService/GetWorkflowRun',
    request,
    metadata || {},
    this.methodDescriptorGetWorkflowRun);
  }

  methodDescriptorGetRunLogs = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.WorkflowService/GetRunLogs',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_workflow_api_pb.GetRunLogs.Request,
    galaxycompletepb_apipb_workflow_api_pb.GetRunLogs.Response,
    (request: galaxycompletepb_apipb_workflow_api_pb.GetRunLogs.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_workflow_api_pb.GetRunLogs.Response.deserializeBinary
  );

  getRunLogs(
    request: galaxycompletepb_apipb_workflow_api_pb.GetRunLogs.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_workflow_api_pb.GetRunLogs.Response>;

  getRunLogs(
    request: galaxycompletepb_apipb_workflow_api_pb.GetRunLogs.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_workflow_api_pb.GetRunLogs.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_workflow_api_pb.GetRunLogs.Response>;

  getRunLogs(
    request: galaxycompletepb_apipb_workflow_api_pb.GetRunLogs.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_workflow_api_pb.GetRunLogs.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.WorkflowService/GetRunLogs',
        request,
        metadata || {},
        this.methodDescriptorGetRunLogs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.WorkflowService/GetRunLogs',
    request,
    metadata || {},
    this.methodDescriptorGetRunLogs);
  }

  methodDescriptorCancelWorkflowRun = new grpcWeb.MethodDescriptor(
    '/galaxycomplete.api.WorkflowService/CancelWorkflowRun',
    grpcWeb.MethodType.UNARY,
    galaxycompletepb_apipb_workflow_api_pb.CancelWorkflowRun.Request,
    galaxycompletepb_apipb_workflow_api_pb.CancelWorkflowRun.Response,
    (request: galaxycompletepb_apipb_workflow_api_pb.CancelWorkflowRun.Request) => {
      return request.serializeBinary();
    },
    galaxycompletepb_apipb_workflow_api_pb.CancelWorkflowRun.Response.deserializeBinary
  );

  cancelWorkflowRun(
    request: galaxycompletepb_apipb_workflow_api_pb.CancelWorkflowRun.Request,
    metadata: grpcWeb.Metadata | null): Promise<galaxycompletepb_apipb_workflow_api_pb.CancelWorkflowRun.Response>;

  cancelWorkflowRun(
    request: galaxycompletepb_apipb_workflow_api_pb.CancelWorkflowRun.Request,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_workflow_api_pb.CancelWorkflowRun.Response) => void): grpcWeb.ClientReadableStream<galaxycompletepb_apipb_workflow_api_pb.CancelWorkflowRun.Response>;

  cancelWorkflowRun(
    request: galaxycompletepb_apipb_workflow_api_pb.CancelWorkflowRun.Request,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: galaxycompletepb_apipb_workflow_api_pb.CancelWorkflowRun.Response) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/galaxycomplete.api.WorkflowService/CancelWorkflowRun',
        request,
        metadata || {},
        this.methodDescriptorCancelWorkflowRun,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/galaxycomplete.api.WorkflowService/CancelWorkflowRun',
    request,
    metadata || {},
    this.methodDescriptorCancelWorkflowRun);
  }

}

