import {observer} from "mobx-react-lite";
import React, {useState} from "react";
import {Box, Typography, Card, CardContent, TextField, Button} from "@mui/material";

// ======================
// ProjectSettingsPrivateEditionSetupScreen
// ======================


interface ProjectSettingsPrivateEditionSetupScreenProps {
}

export const ProjectSettingsPrivateEditionSetupScreen: React.FC<ProjectSettingsPrivateEditionSetupScreenProps> = observer((p) => {

    return <Box pt={2}>
        <UploadRequestCard/>
        <br/>
        <PrivateEditionInstallationsTable/>
    </Box>
})

// ======================
// UploadRequestCard
// ======================

interface UploadRequestCardProps {
}

const UploadRequestCard: React.FC<UploadRequestCardProps> = observer((p) => {
    const [licenseRequest, setLicenseRequest] = useState('');

    return <Card>
        <CardContent>
            <Typography variant={'h5'}>
                License Request
            </Typography>
            <Typography color={'textSecondary'}>
                Upload Activation/License Request from your from Cirrus Data Cloud - Private Edition
            </Typography>
            <Box pt={2} pb={2}>
                <TextField variant={'filled'} label={'License Request'} multiline rows={10} value={licenseRequest}
                           fullWidth
                           onChange={(e) => {
                               setLicenseRequest(e.target.value)
                           }}/>
            </Box>

            <Button variant={'contained'} color={'secondary'}>Upload Request</Button>
        </CardContent>
    </Card>
})

// ======================
// PrivateEditionInstallationsTable
// ======================

interface PrivateEditionInstallationsTableProps {
}

const PrivateEditionInstallationsTable: React.FC<PrivateEditionInstallationsTableProps> = observer((p) => {

    return <Card>
        <CardContent>
            <Typography variant={'h5'}>
                Private Edition Installations
            </Typography>
            <Typography color={'textSecondary'}>
                List of Cirrus Data Cloud - Private Editions
            </Typography>
        </CardContent>
    </Card>
})