import {GRPCServices} from "../grpc/grpcapi";
import {DialogService} from "../core/dialog/DialogService";
import {ProgressService} from "../core/progress/ProgressService";
import {AuthService} from "../auth/AuthService";
import {makeAutoObservable} from "mobx";
import {
    CreateMigrationSessionReport,
    GetReportData,
    ListProjectReports
} from "../../_proto/galaxycompletepb/apipb/report_api_pb";
import {ServerListData} from "../core/data/ListData";
import {ReportInfo} from "../../_proto/galaxycompletepb/apipb/domainpb/report_pb";
import {ReportType} from "../../_proto/galaxycompletepb/apipb/domainpb/enumpb/report_type_pb";
import {PagerParams} from "../../_proto/galaxycompletepb/commonpb/datafilter_pb";

export class ReportService {
    private api: GRPCServices;
    protected dialogService: DialogService;
    private progressService: ProgressService;

    constructor(api: GRPCServices, dialogService: DialogService, progressService: ProgressService) {
        this.api             = api;
        this.dialogService   = dialogService;
        this.progressService = progressService;
        makeAutoObservable(this)
    }

    projectReports = new ServerListData<ListProjectReports.Response.AsObject, ReportInfo.AsObject, ListProjectReports.Request>(10)
        .setDataFetcher(this.listReports.bind(this))

    async createMigrationSessionReport(sessionId: string, reportNotes: string){
        const req = new CreateMigrationSessionReport.Request()
            .setSessionId(sessionId)
            .setUserNotes(reportNotes)

        const res = await this.progressService.track(this.api.reportService.createMigrationSessionReport(req, null), 'Generating Report...')
        return res.toObject();
    }

    async listReports(projectId: string, reportTypeFilter?: ReportType.ReportType, pagerParam?: PagerParams){
        const req = new ListProjectReports.Request()
            .setProjectId(projectId)
            .setPageParams(this.projectReports.pagerParam || pagerParam)
            .setReportTypeFilter(new ReportType().setValue(reportTypeFilter))

        const res = await this.api.reportService.listProjectReports(req, null);
        return res.toObject()
    }

    async getReportData(reportId: number){
        const req = new GetReportData.Request()
            .setReportId(reportId)

        const res = await this.api.reportService.getReportData(req, null);
        return res.toObject()
    }
}