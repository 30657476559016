import {GRPCServices} from '../grpc/grpcapi';
import {DialogService} from '../core/dialog/DialogService';
import {ProgressService} from '../core/progress/ProgressService';
import {ProjectService} from '../project/ProjectService';
import {makeAutoObservable} from 'mobx';
import {ServerListData} from '../core/data/ListData';
import {ServerData} from "../core/data/ServerData";
import {
    AddProjectIntegration,
    GetAllProjectIntegrations,
    GetEnabledIntegration,
    IntegrationParams,
    RemoveProjectIntegration,
    TestProjectIntegration,
    UpdateProjectIntegration
} from "../../_proto/galaxycompletepb/apipb/integration_api_pb";
import {mockIntegrationDetails, mockIntegrationsList} from "../core/testutils/fixtures/MockIntegrationsService";
import {IntegrationConfigInfo, IntegrationModule} from "../../_proto/galaxycompletepb/apipb/domainpb/integration_pb";
import {IntegrationAlertConfig} from "./IntegrationsCommon";

export class IntegrationsService{
    protected api: GRPCServices;
    private dialogService: DialogService;
    protected progressService: ProgressService;
    protected projectService: ProjectService;

    constructor(api: GRPCServices, dialogService: DialogService, progressService: ProgressService, projectService: ProjectService){
        this.api = api;
        this.dialogService = dialogService;
        this.progressService = progressService;
        this.projectService = projectService;
        makeAutoObservable(this)
    }

    public projectIntegrations = new ServerListData<GetAllProjectIntegrations.Response, IntegrationConfigInfo>().setDataFetcher(this.fetchProjectIntegrations.bind(this))
    public currentIntegration = new ServerData<GetEnabledIntegration.Response>().setDataFetcher(this.fetchIntegrationDetails.bind(this));
    public editIntegrationState: EditIntegrationState = null;
    public integrationRedirectState: IntegrationRedirectState;

    get currentIntegrationId(){
        return this.currentIntegration.data?.getInfo().getId()
    }

    initEditIntegrationState(){
        this.editIntegrationState = new EditIntegrationState(this.api, this.progressService, this.currentIntegration.data?.getInfo(), this.projectService.currentProjectID, this.currentIntegration.data?.getInfo().getId());

        return this.editIntegrationState;
    }

    initIntegrationRedirectState(redirectFunc: Function, alertConfig: IntegrationAlertConfig, preselectedHost?: string){
        this.integrationRedirectState = new IntegrationRedirectState(redirectFunc, alertConfig, preselectedHost);
        return this.integrationRedirectState;
    }

    resetIntegrationRedirectState(){
        this.integrationRedirectState = null;
    }

    async addProjectIntegration(module: IntegrationModule, friendlyName: string, deploymentId: string, statusId: string, connParams: IntegrationParams.ConnParams, verboseLogging: boolean){
        const req = new AddProjectIntegration.Request()
            .setConnParams(connParams)
            .setFriendlyName(friendlyName)
            .setProjectId(this.projectService.currentProjectID)
            .setModule(module)
            .setDeploymentIdForValidation(deploymentId)
            .setVerboseLogging(verboseLogging)
            .setStatusId(statusId)


        return await this.progressService.track(this.api.integrationService.addProjectIntegration(req, null));
    }


    async fetchProjectIntegrations(){
        const req = new GetAllProjectIntegrations.Request()
            .setProjectId(this.projectService.currentProjectID)

        return await this.api.integrationService.getAllProjectIntegrations(req, null);
    };

    async fetchIntegrationDetails(id?: number){
        const integrationId = this.currentIntegrationId ?? id;
        const req = new GetEnabledIntegration.Request()
            .setId(integrationId)
        //return Promise.resolve(mockIntegrationDetails)
        return await this.api.integrationService.getEnabledIntegration(req, null);
    }

    async removeProjectIntegration(id: number){
        const req = new RemoveProjectIntegration.Request()
            .setId(id);
        return await this.progressService.track(this.api.integrationService.removeProjectIntegration(req, null));
    }

    async testProjectIntegration(id: number, deploymentId: string, statusId: string, progressMessage?: string){
        const req = new TestProjectIntegration.Request()
            .setDeploymentId(deploymentId)
            .setId(id)
            .setStatusId(statusId)

        return await this.progressService.track(this.api.integrationService.testProjectIntegration(req, null), progressMessage);
    }

}

export class EditIntegrationState {
    public api: GRPCServices;
    public progressService: ProgressService;
    public currentIntegration: IntegrationConfigInfo;
    public currentProjectId: string;
    public currentIntegrationId: number;

    constructor(api: GRPCServices, progressService: ProgressService, currentIntegration: IntegrationConfigInfo, currentProjectId: string, currentIntegrationId: number){
        this.api = api;
        this.progressService = progressService;
        this.currentIntegration = currentIntegration;
        this.currentProjectId = currentProjectId;
        this.currentIntegrationId = currentIntegrationId;
        makeAutoObservable(this)
    }


    async updateProjectIntegration(friendlyName: string, statusId: string, deploymentId?: string, connParams?: IntegrationParams.ConnParams, verboseLogging?: boolean) {
        const req = new UpdateProjectIntegration.Request()
            .setFriendlyName(friendlyName)
            .setId(this.currentIntegrationId)
            .setVerboseLogging(verboseLogging)
            .setStatusId(statusId)

        if (!!connParams && !!deploymentId) {
            req.setConnParams(connParams)
            req.setDeploymentIdForValidation(deploymentId)
        }

        return await this.progressService.track(this.api.integrationService.updateProjectIntegration(req, null));
    }
}

export class IntegrationRedirectState {
    public redirectFunc: Function = null;
    public alertConfig: IntegrationAlertConfig = null;
    public preselectedHost: string = null;

    constructor(redirectFunc: Function, alertConfig: IntegrationAlertConfig, preselectedHost?: string){
        this.redirectFunc = redirectFunc;
        this.alertConfig = alertConfig;
        this.preselectedHost = preselectedHost;
        makeAutoObservable(this);
    }
}