// ======================
// StatCharts
// ======================

import { SyncQos } from "../../../../_proto/galaxymigratepb/galaxy_migrate_qos_pb";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { formatKnownDataType, KnownDataType } from "../../../../common/utils/formatter";
import { Box, Card, Typography, useTheme } from "@mui/material";
import { mockRollingAvgLog } from "../../../core/testutils/fixtures/MockGmMigrationService";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { getAxesMaxValue, getYAxisTickValues } from "../../../../common/utils/statsUtil";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { format } from "date-fns";
import xbytes from "xbytes";
import { getVolQosStyles, NoDataCard } from "./GmSessionVolumeQosCommon";

interface StatChartsProps {
    data: SyncQos.RollingAverageLogEntry[];
}

export const QosStatChartsGroup: React.FC<StatChartsProps> = observer((props) => {
    const styles = getVolQosStyles();
    const { data } = props;
    const theme = useTheme();

    const dataAsObject = data.map((d) => d.toObject());

    const LATEST_STATS = {
        currentPio: formatKnownDataType(0, KnownDataType.THROUGHPUT),
        avgPio: formatKnownDataType(0, KnownDataType.THROUGHPUT),
        currentCio: formatKnownDataType(0, KnownDataType.THROUGHPUT),
        throttleSet: formatKnownDataType(0, KnownDataType.THROUGHPUT),
        rateChange: formatKnownDataType(0, KnownDataType.THROUGHPUT),
        slope: formatKnownDataType(0, KnownDataType.CAPACITY),
        stability: formatKnownDataType(0, KnownDataType.CAPACITY),
    };

    if (data.length > 0) {
        const latestPoint = data[data.length - 1];
        LATEST_STATS.currentPio = formatKnownDataType(latestPoint.getPioCurrent(), KnownDataType.THROUGHPUT);
        LATEST_STATS.avgPio = formatKnownDataType(latestPoint.getPioAverage(), KnownDataType.THROUGHPUT);
        LATEST_STATS.currentCio = formatKnownDataType(latestPoint.getCioCurrent(), KnownDataType.THROUGHPUT);
        LATEST_STATS.throttleSet = formatKnownDataType(latestPoint.getCurrentCioThrottleSetting(), KnownDataType.THROUGHPUT);
        LATEST_STATS.rateChange = formatKnownDataType(latestPoint.getPioRateChanges(), KnownDataType.THROUGHPUT);
        LATEST_STATS.slope = formatKnownDataType(latestPoint.getPioSlope(), KnownDataType.CAPACITY);
        LATEST_STATS.stability = formatKnownDataType(latestPoint.getPioStability(), KnownDataType.CAPACITY);
    }

    const hundredMib = xbytes.parseSize("100MiB");
    const thirtyMib = xbytes.parseSize("30MiB");

    const pioStatChartMap = new Map()
        .set("pioCurrent", { label: "Current", color: theme.palette.secondary.main })
        .set("pioAverage", { label: "Average", color: theme.palette.secondary.light });

    const cioStatChartMap = new Map()
        .set("cioCurrent", { label: "Current IO", color: theme.palette.secondary.main })
        .set("currentCioThrottleSetting", { label: "Current Throttle Setting", color: theme.palette.secondary.light });

    const rateChangesStatChartMap = new Map().set("pioRateChanges", { label: "Rate Changes", color: theme.palette.secondary.main });

    const stabilityStatChartMap = new Map()
        .set("pioStability", { label: "Stability", color: theme.palette.secondary.main })
        .set("pioSlope", { label: "Slope", color: theme.palette.secondary.light });

    return (
        <Box>
            <Card>
                <Box p={2}>
                    <Box pl={2} pb={2}>
                        <Typography variant={"h6"}>Production IO</Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"flex-start"}>
                        <Box>
                            <LatestPointText point={{ label: "Current:", data: LATEST_STATS.currentPio }} />
                            <LatestPointText point={{ label: "Average:", data: LATEST_STATS.avgPio }} />
                        </Box>
                        <Box sx={styles.chartContainer}>
                            <QosStatChart points={dataAsObject} keyAttributesMap={pioStatChartMap} yMaxBasedTickValues={true} />
                        </Box>
                    </Box>
                </Box>
            </Card>
            <br />
            <Card>
                <Box p={2}>
                    <Box pl={2} pb={2}>
                        <Typography variant={"h6"}>Migration IO</Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"flex-start"}>
                        <Box>
                            <LatestPointText point={{ label: "Current:", data: LATEST_STATS.currentCio }} />
                            <LatestPointText point={{ label: "Throttle Set:", data: LATEST_STATS.throttleSet }} />
                        </Box>

                        <Box sx={styles.chartContainer}>
                            <QosStatChart points={dataAsObject} keyAttributesMap={cioStatChartMap} yMaxBasedTickValues={true} />
                        </Box>
                    </Box>
                </Box>
            </Card>
            <br />

            <Card>
                <Box p={2}>
                    <Box pl={2} pb={2}>
                        <Typography variant={"h6"}>Rate Changes</Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"flex-start"}>
                        <Box>
                            <LatestPointText point={{ label: "Rate Change:", data: LATEST_STATS.rateChange }} />
                        </Box>
                        <Box sx={styles.chartContainer}>
                            <QosStatChart
                                points={dataAsObject}
                                keyAttributesMap={rateChangesStatChartMap}
                                yTickValues={getYAxisTickValues(-hundredMib, hundredMib, 2)}
                            />
                        </Box>
                    </Box>
                </Box>
            </Card>
            <br />

            <Card>
                <Box p={2}>
                    <Box pl={2} pb={2}>
                        <Typography variant={"h6"}>Stability</Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"flex-start"}>
                        <Box>
                            <LatestPointText point={{ label: "Stability:", data: LATEST_STATS.stability }} />
                            <LatestPointText point={{ label: "Slope:", data: LATEST_STATS.slope }} />
                        </Box>

                        <Box sx={styles.chartContainer}>
                            <QosStatChart
                                points={dataAsObject}
                                keyAttributesMap={stabilityStatChartMap}
                                yTickValues={getYAxisTickValues(-thirtyMib, thirtyMib, 2)}
                            />
                        </Box>
                    </Box>
                </Box>
            </Card>
        </Box>
    );
});

// ======================
// QosStatChart
// ======================

interface QosStatChartProps {
    points: SyncQos.RollingAverageLogEntry.AsObject[];
    keyAttributesMap: Map<keyof SyncQos.RollingAverageLogEntry.AsObject, { color: string; label: string }>;
    yMaxBasedTickValues?: boolean;
    yTickValues?: number[];
}

export const QosStatChart: React.FC<QosStatChartProps> = observer((p) => {
    const { points, keyAttributesMap, yMaxBasedTickValues, yTickValues } = p;
    const allY: number[] = [];

    const theme = useTheme();
    if (!points || points.length === 0) {
        return <NoDataCard />;
    }

    let yMax;
    let yTicks;

    const keys = Array.from(keyAttributesMap.keys());

    const chartData = points.map((point) => {
        const data: { [key: string]: any } = {
            time: new Timestamp().setSeconds(point.time.seconds).toDate(),
        };
        keys.forEach((key) => {
            allY.push(point[key] as number);
            data[keyAttributesMap.get(key).label] = point[key] ?? 0;
        });
        return data;
    });

    if (yMaxBasedTickValues) {
        yMax = getAxesMaxValue(KnownDataType.THROUGHPUT, allY);
        yTicks = getYAxisTickValues(0, yMax, 4);
    }

    if (!!yTickValues) {
        yTicks = yTickValues;
    }

    return (
        <ResponsiveContainer width={"100%"} height={"100%"}>
            <LineChart data={chartData} margin={{ top: 10, right: 20, bottom: 20, left: 20 }} syncId={"qosStatChart"}>
                <XAxis
                    dataKey={"time"}
                    stroke={"rgb(255,255,255,.6)"}
                    tickSize={10}
                    fontSize={10}
                    fontFamily={theme.typography.fontFamily}
                    tickFormatter={(value: Date, index) => format(value, "HH:mm:ss")}
                    angle={-45}
                    tickMargin={15}
                    interval={30}
                />
                <YAxis
                    fontFamily={theme.typography.fontFamily}
                    ticks={yTicks}
                    color={"white"}
                    stroke={"rgb(255,255,255,.6)"}
                    scale={"linear"}
                    fontSize={10}
                    width={100}
                    type={"number"}
                    tickFormatter={(value: number) => formatKnownDataType(value, KnownDataType.THROUGHPUT)}
                    domain={[0, "dataMax"]}
                />
                <CartesianGrid stroke={"rgb(255,255,255,.4)"} />
                <Tooltip
                    cursor={{ stroke: "red", strokeWidth: 2 }}
                    wrapperStyle={{ fontFamily: theme.typography.fontFamily, outline: "none" }}
                    contentStyle={{ backgroundColor: theme.palette.cirrus.main }}
                    labelFormatter={(label) => format(label, "HH:mm:ss")}
                    formatter={(value, name, props) => formatKnownDataType(value, KnownDataType.THROUGHPUT)}
                />
                {keys.map((key) => {
                    return (
                        <Line
                            type="monotone"
                            dataKey={keyAttributesMap.get(key).label}
                            key={key}
                            activeDot={{ r: 4 }}
                            stroke={keyAttributesMap.get(key).color}
                            dot={false}
                            isAnimationActive={false}
                            strokeWidth={3}
                        />
                    );
                })}
            </LineChart>
        </ResponsiveContainer>
    );
});

// ======================
// PioStatChart
// ======================

interface PioStatChartProps {
    points: SyncQos.RollingAverageLogEntry.AsObject[];
}

export const PioStatChart: React.FC<PioStatChartProps> = observer((p) => {
    const { points } = p;
    const allY: number[] = [];
    const theme = useTheme();

    if (!points || points.length === 0) {
        return <NoDataCard />;
    }

    const chartData = points.map((point) => {
        allY.push(point.pioAverage);
        allY.push(point.pioCurrent);
        return {
            time: new Timestamp().setSeconds(point.time.seconds).toDate(),
            pioAverage: point.pioAverage || 0,
            pioCurrent: point.pioCurrent || 0,
        };
    });

    const yMax = getAxesMaxValue(KnownDataType.THROUGHPUT, allY);
    const yTicks = getYAxisTickValues(0, yMax, 4);

    return (
        <ResponsiveContainer width={"100%"} height={"100%"}>
            <LineChart data={chartData} margin={{ top: 10, right: 20, bottom: 20, left: 20 }}>
                <XAxis
                    dataKey={"time"}
                    stroke={"rgb(255,255,255,.6)"}
                    tickSize={10}
                    fontSize={10}
                    fontFamily={theme.typography.fontFamily}
                    tickFormatter={(value: Date, index) => format(value, "HH:mm:ss")}
                    angle={-45}
                    tickMargin={15}
                    interval={2}
                />
                <YAxis
                    fontFamily={theme.typography.fontFamily}
                    ticks={yTicks}
                    color={"white"}
                    stroke={"rgb(255,255,255,.6)"}
                    scale={"linear"}
                    fontSize={10}
                    width={100}
                    type={"number"}
                    tickFormatter={(value: number) => formatKnownDataType(value, KnownDataType.THROUGHPUT)}
                    domain={[0, "dataMax"]}
                />
                <CartesianGrid stroke={"rgb(255,255,255,.4)"} />
                <Tooltip
                    cursor={{ stroke: "red", strokeWidth: 2 }}
                    wrapperStyle={{ fontFamily: theme.typography.fontFamily, width: 200, outline: "none" }}
                    contentStyle={{ backgroundColor: theme.palette.cirrus.main }}
                    labelFormatter={(label) => format(label, "HH:mm:ss")}
                    formatter={(value, name, props) => formatKnownDataType(value, KnownDataType.THROUGHPUT)}
                />
                <Line
                    type="monotone"
                    dataKey="pioAverage"
                    activeDot={{ r: 4 }}
                    stroke={theme.palette.secondary.main}
                    isAnimationActive={false}
                    dot={false}
                    strokeWidth={3}
                />
                <Line
                    type="monotone"
                    dataKey="pioCurrent"
                    activeDot={{ r: 4 }}
                    stroke={theme.palette.secondary.light}
                    isAnimationActive={false}
                    dot={false}
                    strokeWidth={3}
                />
            </LineChart>
        </ResponsiveContainer>
    );
});

// ======================
// LatestPointText
// ======================

interface LatestPointTextProps {
    point: { label: string; data: string };
}

const LatestPointText: React.FC<LatestPointTextProps> = observer((props) => {
    const { point } = props;
    const styles = getVolQosStyles();
    return (
        <Box p={2}>
            <Typography variant={"overline"} sx={styles.overline}>
                <b>{point.label}</b>
            </Typography>
            <br />
            <Typography variant={"body1"}>{point.data}</Typography>
        </Box>
    );
});
