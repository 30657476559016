import {observer} from "mobx-react-lite";
import React from "react";
import {VendorAllocateVolumesStepProps} from "../GmAutoAllocationCommon";
import {useEffectOnce} from "react-use";
import {AutoAlloc} from "../../../../_proto/galaxymigratepb/galaxy_migrate_autoalloc_pb";
import {ColumnDef} from "../../../../common/table/DataTable";
import {GmMigrationWizardVolumeState} from "../../GmMigrationService";
import {KnownDataType} from "../../../../common/utils/formatter";
import {Box, Button, IconButton, MenuItem, Select, SelectChangeEvent, SvgIcon, Tooltip, Typography} from "@mui/material";
import {SimpleTable} from "../../../../common/table/SimpleTable";
import {MdInfo, MdInfoOutline} from "react-icons/md";

// ======================
// OracleAllocateVolumesStep
// ======================

export const OracleAllocateVolumesStep: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {
    const {allocateFunc, state} = p;

    useEffectOnce(()=> {
        for (let device of state.sourceDevices){
            device.autoAllocParams.setOracle(new AutoAlloc.VolumeParams.Oracle().setVpus(0))
        }
    })

    const getSelectOptions = () => {
        const selectOptions: React.ReactNode[] = [];
        for (let i=0; i<=120; i+=10){
            selectOptions.push(<MenuItem value={i}>{i}</MenuItem>)
        }
        return selectOptions;
    }

    const cols: ColumnDef<GmMigrationWizardVolumeState>[] = [
        {
            id: 'source',
            label: 'Source Volume',
            getter: d => `${d.source.getBlockDevice().getDeviceName()} (${d.source.getBlockDevice().getDeviceType()})`
        },
        {
            id: 'capacity',
            label: 'Capacity',
            getter: d=>d.source.getBlockDevice().getCapacity(),
            dataType: KnownDataType.CAPACITY,
        },
        {
            id: 'vpus',
            label: <Box display={'flex'} alignItems={'center'}>
                <Typography style={{fontSize: '0.875rem', fontWeight:500}}>
                    VPU
                </Typography>
                <Tooltip title={'Learn More'}>
                    <IconButton color={'primary'} onClick={()=>window.open('https://docs.oracle.com/en-us/iaas/Content/Block/Concepts/blockvolumeperformance.htm')}>
                        <SvgIcon>
                            <MdInfoOutline/>
                        </SvgIcon>
                    </IconButton>
                </Tooltip>

            </Box>
                ,
            getter: d => null,
            renderer: (v,r)=>{
                const onSelect = (e: SelectChangeEvent<AutoAlloc.VolumeParams.Azure.StorageType>) => {
                    r.autoAllocParams.getOracle()?.setVpus(e.target.value as number)
                }
                return <Box display={'flex'}>
                    <Select
                    defaultValue={0}
                    value={r.autoAllocParams.getOracle()?.getVpus()}
                    variant={'outlined'} onChange={onSelect}>
                    {
                        getSelectOptions()
                    }
                </Select>
                </Box>
            }
        },
    ]

    return (<>
        <Typography color={'textSecondary'}>
            {`Oracle Data Disks matching the following source volumes will be created using the specified parameters and attached to the destination host`}
        </Typography>
        <br/>
        <SimpleTable rows={state.sourceDevices} rowIdGetter={r => r.source.getBlockDevice().getDeviceName()} cols={cols}/>
        <Box pt={2} pb={2}>
            <Button color={'primary'} variant={'contained'} onClick={allocateFunc}>
                {`Allocate Volumes (${state.sourceDevices.length})`}
            </Button>
        </Box>
    </>);

});