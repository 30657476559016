// ======================
// RemoteDestinationSelectionTable
// ======================

import { GmMigrationType, GmMigrationWizardState, GmMigrationWizardVmwareState, VmSpecType } from "../../GmMigrationService";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useAppServices } from "../../../app/services";
import { renderServerDataWhenReady, renderServerDataWithLoadingList, useInitData } from "../../../core/data/DataLoaderHooks";
import { ColumnDef, DataTable } from "../../../../common/table/DataTable";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    ButtonGroup,
    Card,
    CircularProgress,
    Dialog,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    MenuItem,
    Radio,
    Select,
    SvgIcon,
    TextField,
    Typography,
} from "@mui/material";
import { parseCpuCountsFromCpuString } from "../../../deployment/DeploymentCommon";
import { GmHelperNodeInfo } from "../../../../_proto/galaxycompletepb/apipb/domainpb/compute_pb";
import { GetComputeMigrationSourceReadiness } from "../../../../_proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import * as yup from "yup";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { FormSelect, FormTextField } from "../../../../common/form/FormComponents";
import { AiFillTool } from "react-icons/ai";
import { CloseIcon, EditIcon, RefreshIcon } from "../../../../common/CommonIcons";
import { SwitchCard } from "../../../../common/card/SwitchCard";
import { FaAngleDoubleRight } from "react-icons/fa";
import { BsFillHddNetworkFill } from "react-icons/bs";
import { useDialogState } from "../../../core/dialog/DialogService";
import { VmwareCompute } from "../../../../_proto/galaxymigratepb/galaxy_migrate_compute_pb";
import { useNavigateToMigrationSessionDetails } from "../../MigrationCommon";
import { useEffectOnce } from "react-use";
import { useGetVmwareHelperResourceSelections } from "../../migration_hooks";
import { QueryResultWrapper } from "../../../core/data/QueryResultWrapper";
import { HostEnvironment } from "../../../../_proto/galaxycompletepb/commonpb/common_pb";
import { getHostEnvironmentFromMigrationType } from "../GmMigrationWizardUtils";
import { CreateGalaxyMigrateLinkButton } from "../../../galaxymigrate/links/CreateGalaxyMigrateLinkForm";
import { useQuery } from "@tanstack/react-query";
import { useQueryAlertError } from "../../../core/data/useHooksWithErrorHandling";

interface GmVmwareHelperSelectionTableProps {
    wizardState: GmMigrationWizardState;
}

export const GmVmwareHelperSelectionTable: React.FC<GmVmwareHelperSelectionTableProps> = observer((props) => {
    const { wizardState } = props;
    const { gmDeploymentService } = useAppServices();

    const poll = async () => {
        await gmDeploymentService.galaxyMigrateVmwareHelpers.fetchData(wizardState.deploymentId);
    };

    useInitData({
        poll: poll,
        pollInterval: 10,
    });

    const cols: ColumnDef<GmHelperNodeInfo.Vmware>[] = [
        {
            id: "select",
            label: "Select",
            getter: (r) => r,
            renderer: (_, r) => {
                const selected = wizardState.selectedRemoteDestinationId === r.getSystemId();
                const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (selected) {
                        wizardState.deselectRemoteDestination();
                    } else {
                        wizardState.selectRemoteDestination(r.getSystemId());
                    }
                };
                return <Radio color={"secondary"} checked={selected} onChange={onChange} />;
            },
        },
        {
            id: "dc",
            label: "Datacenter",
            getter: (r) => r.getDatacenter(),
        },
        {
            id: "hs",
            label: "VMware Host",
            getter: (r) => r.getHostSystem(),
        },
        {
            id: "vm",
            label: "Helper VM",
            getter: (r) => r.getVmFile(),
        },
        {
            id: "vmdks",
            label: "VMDKs",
            getter: (r) => r.getVmdkCount(),
            renderer: (count, r) => {
                // todo: show in different color if it is getting close
                return `${count} / 60`;
            },
        },
    ];
    return renderServerDataWithLoadingList(gmDeploymentService.galaxyMigrateVmwareHelpers, (data) => {
        const helpers = data.getHelpersList();

        return (
            <>
                <Card>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Box>
                            <ListSubheader>CMC Helpers</ListSubheader>
                        </Box>
                        <Box p={1}>
                            <CreateGalaxyMigrateLinkButton icon refetchFn={poll} />
                        </Box>
                    </Box>
                    <DataTable
                        rows={helpers}
                        rowIdGetter={(r) => r.getSystemId()}
                        state={gmDeploymentService.galaxyMigrateVmwareHelpers.tableState}
                        emptyTableTitle={"No Eligible CMC Helpers Found"}
                        onTableStateChange={poll}
                        emptyTableActionButton={<CreateGalaxyMigrateLinkButton variant={"contained"} color={"secondary"} refetchFn={poll} />}
                        cols={cols}
                    />
                </Card>
            </>
        );
    });
});

// ======================
// GmMigrationWizardComputeMigrationSourcePreparationStep
// ======================
interface GmMigrationWizardComputeMigrationSourcePreparationStepProps {
    wizardState: GmMigrationWizardState;
}

export const GmMigrationWizardComputeMigrationSourcePreparationStep: React.FC<GmMigrationWizardComputeMigrationSourcePreparationStepProps> = observer((p) => {
    const { gmDeploymentService } = useAppServices();

    const queryResult = useQueryAlertError({
        queryKey: ["GetComputeMigrationSourceReadiness"],
        queryFn: async () => {
            return await gmDeploymentService.getSourceVmReadiness(p.wizardState.deploymentId, getHostEnvironmentFromMigrationType(p.wizardState.migrationType));
        },
    });

    const checkSourceReadiness = async () => {
        await gmDeploymentService.galaxyMigrateComputeMigrationSourceReadiness.fetchData(
            p.wizardState.deploymentId,
            getHostEnvironmentFromMigrationType(p.wizardState.migrationType)
        );
    };

    const suggestedActions = (
        <ButtonGroup color={"primary"} variant={"outlined"}>
            <Button
                startIcon={
                    <SvgIcon>
                        <AiFillTool />
                    </SvgIcon>
                }
                onClick={async () => {
                    const res = await gmDeploymentService.prepareSourceForComputeMigration(
                        p.wizardState.deploymentId,
                        getHostEnvironmentFromMigrationType(p.wizardState.migrationType)
                    );
                    if (res) {
                        await checkSourceReadiness();
                    }
                }}
            >
                {`Perform Suggested Actions`}
            </Button>
            <Button startIcon={<RefreshIcon />} onClick={checkSourceReadiness}>
                {`Check Again`}
            </Button>
        </ButtonGroup>
    );

    const checkingSourceReadinessCard = (
        <>
            <Card>
                <Box p={6} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Box sx={{ textAlign: "center" }}>
                        <CircularProgress />
                        <br />
                        <Typography>Checking Source Readiness...</Typography>
                    </Box>
                </Box>
            </Card>
        </>
    );

    const getContinueDisabled = () => {
        return (
            gmDeploymentService.galaxyMigrateComputeMigrationSourceReadiness.data?.getUnsupported() ||
            !gmDeploymentService.galaxyMigrateComputeMigrationSourceReadiness.data?.getReady()
        );
    };

    const onContinue = () => {
        gmDeploymentService.galaxyMigrateComputeMigrationSourceReadiness.resetData();
        p.wizardState.stepperState.goToNextStep();
    };

    const goBack = () => {
        gmDeploymentService.galaxyMigrateComputeMigrationSourceReadiness.resetData();
        p.wizardState.stepperState.goBackOneStep();
    };

    return (
        <Box width={"100%"}>
            <Typography variant={"body1"}>Complete the following step to prepare source host</Typography>
            <br />
            <Typography variant={"h5"}>Source VM Migration Readiness Check</Typography>
            <br />
            <br />

            <QueryResultWrapper queryResult={queryResult} loadingElement={checkingSourceReadinessCard}>
                <SourceVmStatusCard wizardState={p.wizardState} data={queryResult.data} suggestedActions={suggestedActions} />;
            </QueryResultWrapper>
            <br />
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                <Box pr={1}>
                    <Button color={"neutral"} variant={"outlined"} onClick={goBack}>
                        Go Back
                    </Button>
                </Box>
                <Box pl={1}>
                    <Button disabled={getContinueDisabled()} color={"primary"} variant={"contained"} onClick={onContinue}>
                        Continue
                    </Button>
                </Box>
            </Box>
        </Box>
    );
});

// ======================
// SourceVmStatusCard
// ======================

interface SourceVmStatusCardProps {
    wizardState: GmMigrationWizardState;
    data: GetComputeMigrationSourceReadiness.Response;
    suggestedActions: React.ReactNode;
}

const SourceVmStatusCard: React.FC<SourceVmStatusCardProps> = observer((p) => {
    const { data, wizardState, suggestedActions } = p;

    const getTitleText = () => {
        if (data.getUnsupported()) {
            return "Unsupported";
        } else if (data.getReady()) {
            return "Ready For Migration";
        } else if (!data.getReady()) {
            return "Not Ready For Migration";
        }
    };
    return (
        <>
            <Alert severity={data.getUnsupported() || !data.getReady() ? "error" : "success"}>
                <AlertTitle>{getTitleText()}</AlertTitle>
                {data.getReason()}
            </Alert>
            {(data.getUnsupported() || !data.getReady()) && (
                <>
                    <br />
                    <br />
                    <br />
                    {suggestedActions}
                </>
            )}
        </>
    );
});

// ======================
// GmMigrationWizardVmwareVmConfigStep
// ======================
interface GmMigrationWizardVmwareVmConfigStepProps {
    wizardState: GmMigrationWizardState;
}

export const GmMigrationWizardVmwareVmConfigStep: React.FC<GmMigrationWizardVmwareVmConfigStepProps> = observer((p) => {
    return (
        <>
            <Typography variant={"body1"}>Please provide the following information for the configuration</Typography>
            <br />
            <Box>
                <VmwareVmConfigForm wizardState={p.wizardState} type={"create"} />
            </Box>
        </>
    );
});

// ======================
// VmConfigForm
// ======================

interface VmConfigFormProps {
    wizardState: GmMigrationWizardState;
    type: "edit" | "create";
}

export const VmwareVmConfigForm: React.FC<VmConfigFormProps> = observer((p) => {
    const { gmMigrationService } = useAppServices();
    const goBackToSessionDetails = useNavigateToMigrationSessionDetails();
    const defaultSelection = "Default";
    const vmwareState = p.wizardState.vmwareState;
    const vmwareSpec = vmwareState.vmwareSpec?.getVmwareSpec();
    const queryResult = useGetVmwareHelperResourceSelections(p.wizardState.selectedRemoteDestinationId);

    const schema = yup.object({
        vmName: yup.string().required().label("VM Name"),
        datastore: yup.string().required(),
        resourcePool: yup.string().required(),
        vmFolder: yup.string().required(),
        cpus: yup.number().required(),
        coresPerCpu: yup.number().required(),
        memory: yup
            .number()
            .required()
            .test(
                "is-multiple-of-4",
                (d) => `Must be a multiple of 4.`,
                (value) => Number.isInteger(value / 4)
            ),
    });

    const disableContinue = useMemo(() => {
        return vmwareState.isAnySourceNetworkUnpaired || !vmwareState.hasSourceNetworks;
    }, [vmwareState.isAnySourceNetworkUnpaired, vmwareState.hasSourceNetworks]);

    const sourceDeploymentInfo = p.wizardState.deployment?.data?.toObject()?.info;
    const sourceDeploymentCPUInfo = parseCpuCountsFromCpuString(sourceDeploymentInfo?.cpu);
    const initialValues: VmSpecType = {
        vmName: vmwareSpec?.getVmName() || p.wizardState.deployment.data?.getInfo().getDeployment().getSystemName(),
        datastore: vmwareSpec?.getDatastore() || "",
        resourcePool: vmwareSpec?.getResourcePool() || defaultSelection,
        vmFolder: vmwareSpec?.getVmFolder() || defaultSelection,
        cpus: vmwareSpec?.getCpuCount() || sourceDeploymentCPUInfo.cpus || 1,
        coresPerCpu: vmwareSpec?.getCpuCoresPerSocket() || Math.ceil(sourceDeploymentCPUInfo.cores / (sourceDeploymentCPUInfo.cpus || 1)) || 1,
        memory:
            vmwareSpec?.getMemoryMib() ||
            // round up to nearest gib
            Math.ceil(Math.round(sourceDeploymentInfo?.memory / 1024 / 1024) / 1024) * 1024 ||
            1024,
    };

    const onSubmit = async (values: FormikValues, formikHelpers: FormikHelpers<VmSpecType>) => {
        const validated = await formikHelpers.validateForm(values);
        if (validated) {
            const spec = values as VmSpecType;
            if (spec.resourcePool === defaultSelection) {
                spec.resourcePool = "";
            }
            if (spec.vmFolder === defaultSelection) {
                spec.vmFolder = "";
            }
            vmwareState.setVmwareSpec(spec);
            if (p.type === "edit") {
                await gmMigrationService.updateSessionComputeSpec(vmwareState.vmwareSpec);
                goBackToSessionDetails();
            } else {
                p.wizardState.stepperState.goToNextStep();
            }
        }
    };

    const datastoreSelections = useMemo(() => {
        return queryResult.data?.selections.datastoreListList.map((d) => d.name);
    }, [queryResult]);

    const resourcePoolSelections = useMemo(() => {
        return [defaultSelection].concat(queryResult.data?.selections.resourcePoolsList.map((d) => d.path));
    }, [queryResult]);

    const vmFolderSelections = useMemo(() => {
        return [defaultSelection].concat(queryResult.data?.selections.foldersList);
    }, [queryResult]);

    const getFormActions = () => {
        if (p.type === "edit") {
            return (
                <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                    <Box pr={1}>
                        <Button color={"neutral"} variant={"outlined"} onClick={goBackToSessionDetails}>
                            Cancel
                        </Button>
                    </Box>
                    <Box pl={1}>
                        <Button
                            color={"primary"}
                            variant={"contained"}
                            type={"submit"}
                            disabled={vmwareState.isAnySourceNetworkUnpaired || !vmwareState.hasSourceNetworks}
                        >
                            {`Save`}
                        </Button>
                    </Box>
                </Box>
            );
        }
        return (
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                <Box pr={1}>
                    <Button color={"neutral"} variant={"outlined"} onClick={() => p.wizardState.stepperState.goBackOneStep()}>
                        {`Go Back`}
                    </Button>
                </Box>
                <Box pl={1}>
                    <Button color={"primary"} variant={"contained"} type={"submit"} disabled={disableContinue}>
                        {`Continue`}
                    </Button>
                </Box>
            </Box>
        );
    };

    return (
        <QueryResultWrapper queryResult={queryResult}>
            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
                {(props) => {
                    return (
                        <Form>
                            <Box pb={2}>
                                <Typography variant={"h5"}>1. VM Information</Typography>
                                <Grid container pb={1} pt={2}>
                                    <Grid item xs={12}>
                                        <FormTextField label={"VM Name"} name={"vmName"} required />
                                    </Grid>
                                </Grid>
                                <Grid container pb={1} pt={1}>
                                    <Grid item xs={12}>
                                        <FormSelect label={"Datastore"} required name={"datastore"} selectionList={datastoreSelections} />
                                    </Grid>
                                </Grid>
                                <Grid container pt={1}>
                                    <Grid item xs={6} pr={1}>
                                        <FormSelect label={"Resource Pool"} name={"resourcePool"} selectionList={resourcePoolSelections} />
                                    </Grid>
                                    <Grid item xs={6} pl={1}>
                                        <FormSelect label={"VM Folder"} name={"vmFolder"} selectionList={vmFolderSelections} />
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box pb={2}>
                                <Typography variant={"h5"}>2. VM Hardware</Typography>
                                <Grid container pt={2} pb={1}>
                                    <Grid item xs={12} md={3} pr={1}>
                                        <FormTextField type={"number"} label={"Total Number of CPUs"} name={"cpus"} />
                                    </Grid>
                                    <Grid item xs={12} md={3} pl={1}>
                                        <FormTextField type={"number"} label={"Number of Cores per CPU"} name={"coresPerCpu"} />
                                    </Grid>
                                </Grid>
                                <Grid container pt={1}>
                                    <Grid item xs={12} md={6}>
                                        <FormTextField InputProps={{ endAdornment: <>MiB</> }} type={"number"} label={"Memory"} name={"memory"} />
                                    </Grid>
                                </Grid>
                            </Box>
                            <QueryResultWrapper queryResult={queryResult}>
                                <NetworkInterfacesSection type={p.type} wizardState={p.wizardState} networksList={queryResult.data?.selections.networksList} />
                            </QueryResultWrapper>
                            {getFormActions()}
                        </Form>
                    );
                }}
            </Formik>
        </QueryResultWrapper>
    );
});

// ======================
// NetworkInterfacesSection
// ======================

interface NetworkInterfacesSectionProps {
    wizardState: GmMigrationWizardState;
    networksList: Array<VmwareCompute.Network.AsObject>;
    type: "edit" | "create";
}

export const NetworkInterfacesSection: React.FC<NetworkInterfacesSectionProps> = observer((p) => {
    const vmwareState = p.wizardState.vmwareState;

    useEffectOnce(() => {
        if (p.type === "edit") {
            for (let network of vmwareState.vmwareSpec.getVmwareSpec().getNetworksList()) {
                if (!vmwareState.getIsNetworkPaired(network.getNetworkName())) {
                    vmwareState.pairSourceNetwork(network.getNetworkName(), network.getNetworkName());
                }
            }
        }
    });

    return (
        <Box pb={2}>
            <Typography variant={"h5"}>3. Network Interfaces</Typography>
            <br />
            {p.type === "create" ? (
                p.wizardState.deployment.data?.getNetworkInterfacesList().map((n, i) => {
                    const networkInfo = n.toObject();
                    const selected = vmwareState.getIsNetworkSelected(networkInfo.name);
                    return (
                        <SwitchCard
                            selected={selected}
                            onSelect={(e, c) => {
                                if (selected) {
                                    vmwareState.removeSourceNetwork(networkInfo.name);
                                } else {
                                    vmwareState.addSourceNetwork(networkInfo.name);
                                }
                            }}
                            cardContent={
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} lg={4}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <SvgIcon>
                                                    <BsFillHddNetworkFill />
                                                </SvgIcon>
                                            </ListItemIcon>
                                            <ListItemText primary={`${networkInfo.name} (${networkInfo.addr})`} secondary={networkInfo.mac} />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} lg={1}>
                                        {selected && (
                                            <Box>
                                                <SvgIcon>
                                                    <FaAngleDoubleRight />
                                                </SvgIcon>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} lg={5} alignItems={"center"}>
                                        {selected && (
                                            <MatchingNetworkSelectionMenu
                                                networkName={networkInfo.name}
                                                networkList={p.networksList.map((n) => n.path)}
                                                vmwareState={vmwareState}
                                                type={"create"}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12} lg={2} alignItems={"center"}>
                                        {selected && vmwareState.getIsNetworkPaired(networkInfo.name) && (
                                            <NetworkSelectionCustomizationOptions
                                                networkName={networkInfo.name}
                                                sourceMacAddress={networkInfo.mac}
                                                vmwareState={vmwareState}
                                                type={"create"}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            }
                            key={i}
                        />
                    );
                })
            ) : (
                <>
                    {vmwareState.vmwareSpec
                        .getVmwareSpec()
                        .getNetworksList()
                        .map((network, index) => {
                            return (
                                <Card variant={"outlined"} key={index}>
                                    <Grid container p={2} alignItems={"center"}>
                                        <Grid item xs={6}>
                                            <MatchingNetworkSelectionMenu
                                                networkList={p.networksList.map((n) => n.path)}
                                                networkName={network.getNetworkName()}
                                                vmwareState={vmwareState}
                                                type={"edit"}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {vmwareState.getIsNetworkPaired(network.getNetworkName()) && (
                                                <NetworkSelectionCustomizationOptions
                                                    networkName={network.getNetworkName()}
                                                    sourceMacAddress={network.getCustomMacAddress()}
                                                    vmwareState={vmwareState}
                                                    type={"edit"}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Card>
                            );
                        })}
                </>
            )}
        </Box>
    );
});

// ======================
// NetworkSelectionCustomizationOptions
// ======================
interface NetworkSelectionCustomizationOptionsProps {
    networkName: string;
    sourceMacAddress: string;
    vmwareState: GmMigrationWizardVmwareState;
    type: "edit" | "create";
}

const NetworkSelectionCustomizationOptions: React.FC<NetworkSelectionCustomizationOptionsProps> = observer((p) => {
    const [macAddress, setMacAddress] = useState(p.vmwareState.pairedNetworks[p.networkName]?.getCustomMacAddress() || "");
    const [adapterType, setAdapterType] = useState(p.vmwareState.pairedNetworks[p.networkName]?.getAdapterType() || "e1000");

    useEffect(() => {
        p.vmwareState.pairedNetworks[p.networkName]?.setCustomMacAddress(macAddress);
    }, [macAddress, p.networkName, p.vmwareState.pairedNetworks]);
    useEffect(() => {
        p.vmwareState.pairedNetworks[p.networkName]?.setAdapterType(adapterType);
    }, [adapterType, p.networkName, p.vmwareState.pairedNetworks]);

    return (
        <>
            <Box display={p.type === "edit" ? "flex" : null}>
                <FormControl fullWidth variant={"filled"} sx={p.type === "edit" ? { paddingRight: 2 } : {}}>
                    <InputLabel id={"adapter-type-" + p.networkName}>Adapter Type</InputLabel>
                    <Select
                        value={adapterType}
                        labelId={"adapter-type-" + p.networkName}
                        fullWidth
                        variant={"filled"}
                        defaultValue={""}
                        onChange={(e) => {
                            p.vmwareState.pairedNetworks[p.networkName].setAdapterType(e.target.value);
                            setAdapterType(e.target.value);
                        }}
                    >
                        {["e1000", "e1000e", "vmxnet2", "vmxnet3", "pcnet32", "sriov"].map((adapterType) => {
                            return (
                                <MenuItem value={adapterType} key={adapterType}>
                                    {adapterType}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <br />
                <TextField
                    helperText={`Leave Blank to Auto Generate`}
                    variant={"filled"}
                    value={macAddress}
                    onChange={(e) => {
                        setMacAddress(e.target.value);
                    }}
                    label={"MAC Address"}
                    fullWidth
                />
            </Box>
        </>
    );
});

// ======================
// MatchingNetworkSelectionMenu
// ======================

interface MatchingNetworkSelectionMenuProps {
    networkList: Array<string>;
    networkName: string;
    vmwareState: GmMigrationWizardVmwareState;
    type: "edit" | "create";
}

export const MatchingNetworkSelectionMenu: React.FC<MatchingNetworkSelectionMenuProps> = observer((p) => {
    const dialogState = useDialogState();
    return (
        <>
            {!p.vmwareState.getIsNetworkPaired(p.networkName) && (
                <Button variant={"outlined"} color={"secondary"} onClick={dialogState.open}>
                    Select Matching Network
                </Button>
            )}
            {p.vmwareState.getIsNetworkPaired(p.networkName) && (
                <Grid container spacing={3} alignItems={"center"}>
                    <Grid item>
                        <ListItem>
                            <ListItemIcon>
                                <SvgIcon>
                                    <BsFillHddNetworkFill />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText primary={p.vmwareState.pairedNetworks[p.networkName].getNetworkName()} />
                        </ListItem>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={dialogState.open}>
                            <EditIcon />
                        </IconButton>
                        {p.type === "create" && (
                            <IconButton onClick={() => p.vmwareState.removePairedNetworkFromSource(p.networkName)}>
                                <CloseIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            )}

            {dialogState.isOpen && (
                <Dialog fullWidth maxWidth={"sm"} open={dialogState.isOpen} onClose={dialogState.close}>
                    <ListSubheader>{"Select Matching Network"}</ListSubheader>
                    {p.networkList.map((n) => (
                        <ListItem
                            button
                            onClick={() => {
                                p.vmwareState.pairSourceNetwork(p.networkName, n);
                                console.debug(p.vmwareState.pairedNetworks);
                                dialogState.close();
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon>
                                    <BsFillHddNetworkFill />
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText primary={n} />
                        </ListItem>
                    ))}
                </Dialog>
            )}
        </>
    );
});
