// source: galaxymigratepb/galaxy_migrate.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.galaxymigrate.PortNumbers', null, global);
/**
 * @enum {number}
 */
proto.galaxymigrate.PortNumbers = {
  NIL: 0,
  GATEWAY: 4999,
  API: 4998,
  WEB: 4997,
  REMOTE_GM_SERVER: 4996,
  CDC_RELAY_HTTP: 4944,
  CDC_RELAY: 4943
};

goog.object.extend(exports, proto.galaxymigrate);
